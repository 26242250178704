
var serverKR ={}; 
serverKR['전체'] = 'all';
serverKR['카인'] = 'cain';
serverKR['디레지에'] = 'diregie';
serverKR['시로코'] = 'siroco';
serverKR['프레이'] = 'prey';
serverKR['카시야스'] = 'casillas';
serverKR['힐더'] = 'hilder';
serverKR['안톤'] = 'anton';
serverKR['바칼'] = 'bakal';
serverKR['모험단'] = 'adven';


var serverEN ={}; 
serverKR['all'] = '전체';
serverKR['cain'] = '카인';
serverKR['diregie'] = '디레지에';
serverKR['siroco'] = '시로코';
serverKR['prey'] = '프레이';
serverKR['casillas'] = '카시야스';
serverKR['hilder'] = '힐더';
serverKR['anton'] = '안톤';
serverKR['bakal'] = '바칼';
serverKR['adven'] = '모험단';


export {serverKR,serverEN}