import { createContext, useEffect, useState } from 'react';



import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom'



import Ad from '../ad';



import React from 'react';

import axios from 'axios';


import {serverKR} from "../var/server.js";
import Script from '../utill/Script';


function SearhResultMO(){


    let navigate = useNavigate();

    let [schbase, setSchBase] = useState();


    useEffect( () => { 

        
        var self = window;
        self.__VM = self.__VM || [];
        self.__VM.push(function (admanager, scope) {
            scope.Config.get('mobile_mpu').display('mo-ad-container-1');

        });

        return () =>
        {
            self.__VM = self.__VM || [];
            self.__VM.push((admanager, scope) => {
                admanager.removeAllPlacements();
                scope.Instances.pageManager.newPageSession();
            });
            
        };

    },[]);

    // useEffect( () => { 
    //     var placement = document.createElement("div");
    
    //     (window.top).__vm_add = (window.top).__vm_add || [];

    //     (function (success) {
    
    //         if (window.document.readyState !== "loading") {
    
    //             success();
    
    //         } else {
    
    //             window.document.addEventListener("DOMContentLoaded", function () {
    
    //                 success();
    
    //             });
    
    //         }
    
    //     })(function () {
    
    
    //         placement.setAttribute("class", "vm-placement");
    
    //         placement.setAttribute("data-id", "63358a7095a6d454fcf66d0d");
    
    //         document.querySelector("#mo-ad-container-1").appendChild(placement);
    
    //         (window.top).__vm_add.push(placement);
    
    //     });
        
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         //console.log("unmounting", placement);
    //         (window.top).__vm_remove.push(placement);


    //     };

    // },[]);

    
    const params = new URLSearchParams(window.location.search);
    
    let [schname, setSchName] = useState(params.get('name'));
    let [schserver, setServer] = useState(params.get('server'));

    
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    useEffect( () => { 
    
    axios.post('dat/searchData.jsp?&name='+encodeURIComponent(schname)+'&server='+schserver+''
    ,{}, { cancelToken: source.token  })
    .then((base)=>{
        let copy = base.data;
        setSchBase(copy);
    })
    
        
    return ()=>{

        source.cancel();
    }

    },[]);

      
    useEffect( () => { 
        
        var placement = document.createElement("div");
        window.top.__vm_add = window.top.__vm_add || [];
        (function (success) {
            if (window.document.readyState !== "loading") {
                success();
            } else {
                window.document.addEventListener("DOMContentLoaded", function () {
                    success();
                });
            }
        })(function () {

            placement.setAttribute("class", "vm-placement");
            placement.setAttribute("data-id", "63358aa395a6d454fcf66d11");
            document.querySelector("#rich-media-placement").appendChild(placement);
            window.top.__vm_add.push(placement);
        });
            
        return() => { 
            (window.top).__vm_remove_category = (window.top).__vm_remove_category || [];
            (window.top).__vm_remove_category.push("richmedia_all");

            //console.log("unmounting", (window.top).__vm_remove_category);

        };

            
    },[]);
  

    return (
        <div>
            <div id="rich-media-placement"></div>
            <section id="mobile-serach-page">
                
                <div class="search-result">
                    <div class="sr-top">
                        <div class="result-intro">
                            <ul>
                                <li class="sev">{serverKR[schserver]}</li>
                                <li class="re-nick">{schname}</li>
                                <span>검색 결과 입니다.</span>
                            </ul>
                        </div>
                    </div>

                    <div class="sr-con" >
                        <div class="sr-result">
                            {
                                schbase && schbase.characters.map((a, i) =>
                                {
                                    return(

                                        <div class="scon" key={i} >
                                            <div class="seh_abata">
                                                <div class="imgt">

                                                    <img onClick={() => navigate('../character?server='+schbase.characters[i].server+'&key='+schbase.characters[i].key)}
                                                    src={'https://img-api.neople.co.kr/df/servers/'+schbase.characters[i].server+'/characters/' +schbase.characters[i].key+ '?zoom=1.jpg&temp='+ Math.round(Math.random() * 10000)}  />
                                                </div>
                                            </div>
                                            <div class="seh_sever">
                                                <li class="sev">{serverKR[schbase.characters[i].server]}</li>
                                            </div>

                                            <div class="seh_job">
                                                <li class="sev">{schbase.characters[i].job}</li>
                                            </div>
                                            <div class="seh_bacalcount">
                                                <div class="sbccnt">
                                                    <ul class="bac_cont">
                                                        <li class="bacct-tit">
                                                            <div class="bcimg">
                                                                <div class="imgt">
                                                                <img src={"https://dundam.xyz/img/mu.png"}/>

                                                                </div>
                                                            </div>
                                                        </li>
                                                        <li class="bacct-num">
                                                            <div class="bacal-calculate">
                                                                <div class="bcimg">
                                                                    <div class="imgt">
                                                                    <img src={"mohum.png"}/>

                                                                    </div>
                                                                </div>
                                                                <div class="bcresult">
                                                                    <span>{schbase.characters[i].advenBakal}</span>
                                                                </div>

                                                            </div>
                                                            <div class="bacal-calculate">
                                                                <div class="bcimg">
                                                                    <div class="imgt">

                                                                        <img src={"job/"+schbase.characters[i].baseJob+".gif"}/>
                                                                    </div>
                                                                </div>
                                                                <div class="bcresult">
                                                                    <span>{schbase.characters[i].bakal}</span>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                            <div class="seh_name"  onClick={() => navigate('../character?server='+schbase.characters[i].server+'&key='+schbase.characters[i].key)}>
                                                <span class="name">{schbase.characters[i].name}
                                                    <span class="introd server" name="서버"> 
                                                        {schbase.characters[i].adventrueName}
                                                    </span>
                                                </span>
                                                <div class="level">
                                                    <span class="fpic">
                                                        <div class="imgt">
                                                        </div>
                                                    </span>
                                                    <span class="val">{schbase.characters[i].fame}</span>
                                                </div>

                                            </div>
                                            
                                            
                                            <div class="seh_stat">
                                                {schbase.characters[i].ozma != null ?
                                                
                                                    <ul className= {schbase.characters[i].buffScore == null ? 'stat_a' : 'off' }>
                                                        <li>
                                                            <div class="statc">
                                                                <span class="tl tfive">랭킹</span>
                                                                <span class="val">{schbase.characters[i].ozma.split('.')[0]}</span>
                                                            </div>
                                                        </li>
                                                        {/* <li>
                                                            <div class="statc">
                                                                <span class="tl fourt">25초</span>
                                                                <span class="val">{schbase.characters[i].gb.split('.')[0]}</span>
                                                            </div>
                                                        </li> */}
                                                        {/* <li>
                                                            <div class="statc">
                                                                <span class="tl ozma">40초</span>
                                                                <span class="val">{schbase.characters[i].yasin.split('.')[0]}</span>
                                                            </div>
                                                        </li> */}
                                                    </ul> :<></>
                                                }
                                               

                                                <ul className= {schbase.characters[i].ozma == null ? 'stat_b' : 'off' }>
                                                    <li>
                                                        <div class="statc">
                                                            <span class="tl">{schbase.characters[i].buffScore3 == null ? '버프점수': '2인' }</span>
                                                            <span class="val">{schbase.characters[i].buffScore}</span>
                                                        </div>
                                                    </li>
                                                    <li className= {schbase.characters[i].buffScore3 == null ? 'off' : '' }>
                                                        <div class="statc">
                                                            <span class="tl">3인</span>
                                                            <span class="val">{schbase.characters[i].buffScore3}</span>
                                                        </div>
                                                    </li>
                                                    <li className= {schbase.characters[i].buffScore4 == null ? 'off' : '' }>
                                                        <div class="statc">
                                                            <span class="tl">4인</span>
                                                            <span class="val">{schbase.characters[i].buffScore4}</span>
                                                        </div>
                                                    </li>

                                                </ul>
                                            </div>
                                        </div>
                                        )
                                    }
                                )
                            }
                        </div>
                    </div>
                </div>
			   <div id="mo-ad-container-1"></div>
            </section>
            
            <div class='coupang'>
            </div>
            <Script src="https://cp.edl.co.kr/cou/js.php?code=dundamdw1&flt=0&click_type=1&width=320&height=50&adid=&click_id="></Script>
        </div>

    )
}


export default SearhResultMO
