
import { createContext, useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom'


import React from 'react';
import Ad from '../ad';


import {serverKR} from '../var/server';
import axios from 'axios';

import Script from '../utill/Script';
import ExternalAd from '../utill/Coupang';



function MainMO(props){

    let [maindatabase, setMainDataBase] =  useState();

    const gradeToColor = {"중급":"#68D5ED","하급":"#8b8b8b","최하급":"#8b8b8b"};

    const [nick, setNick] = useState("");
    const [server, setServer] = useState("all");
    const [noticeNum, setNoticeNum] = useState(0);

    const handleOnClick = () => {
        navigate('search?server='+encodeURIComponent( server)+'&name='+encodeURIComponent( nick))
      };

    const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
            handleOnClick(); // Enter 입력이 되면 클릭 이벤트 실행
        }
    };

    
    const mainClick =()=>{
        var tempElem = document.createElement('textarea');
        tempElem.value = 'secura1228@gmail.com';  
        document.body.appendChild(tempElem);

        tempElem.select();
        document.execCommand("copy");
        document.body.removeChild(tempElem);
        
        alert('메일 주소가 복사됐습니다.');
    }
    
    const kakaoClick =()=>{
        window.open('http://pf.kakao.com/_vdxlKxj/chat','kaakoDundam');
    }
    
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const searchDelete = (order) =>
    {
        axios.post('dat/searchDelete.jsp?order='+order
        ,{}, { cancelToken: source.token  })
        .then((base)=>{
            axios.post('/dat/mainData.jsp'
            ,{}, { cancelToken: source.token  })
            .then((base)=>{
            let copy = base.data;
            setMainDataBase(copy);


            })
        })
    }

    useEffect( () => { 
      
        axios.post('dat/mainData.jsp'
        ,{}, { cancelToken: source.token  })
        .then((base)=>{
          let copy = base.data;
          setMainDataBase(copy);


          
        })
        
        
        return ()=>{

            source.cancel();
        }


      },[]);

    let navigate = useNavigate();
  



    return (
    
        <div className="mo-container main-mo">
            <div className="main-side">
                <div className="introduce">
                    <div className="main-logo">
                            <div className={"imgt " + (props.darkmodetype === 'dark' ? 'darklogo' : 'lightlogo') }> 
                        </div>
                    </div>
                </div>
                <div class="search">
                    <div class="searcbox">
                        <div class="searchbox_hug">
                            <div class="server" name="서버">
                                <select id="server" name="server" value={server}  onChange={(event) => setServer(event.target.value)}>
                                    <option value="all">전체</option>
                                    <option value="adven">모험단</option>
                                    <option value="cain">카인</option>
                                    <option value="diregie">디레지에</option>
                                    <option value="siroco">시로코</option>
                                    <option value="prey">프레이</option>
                                    <option value="casillas">카시야스</option>
                                    <option value="hilder">힐더</option>
                                    <option value="anton">안톤</option>
                                    <option value="bakal">바칼</option>
                                </select>
                            </div>
                            <div class="searchbox" name="검색창">
                            <input type="text" class="form-control" placeholder="캐릭터명" name="name" maxlength="20" value={nick} onKeyPress={handleOnKeyPress}  onChange={(event) => setNick(event.target.value)}></input>
                                </div>

                            <div class="searchbutton" name="검색버튼">
                                <button onClick={()=>{navigate('search?server='+encodeURIComponent( server)+'&name='+encodeURIComponent( nick))}}>

                                </button>
                            </div>

                        </div>
                    </div>
                    <div class="searchadd">
                        <div class="searchadd_hug">
                            <div class="schadd-tit">
                                <span>최근 검색</span>
                            </div>
                            <div class="scadd-box">
                                <div class="scadbox">
                                    <ul>
                                    {
                                            maindatabase && maindatabase.cookie.map((a, i) => 
                                            {
                                                if(i< 5)
                                                {
                                                    return(
                                                    <li>
                                                        <div class="scb_clickside" onClick={()=>{navigate('search?server='+serverKR[a.name.split(' - ')[0]]+'&name='+a.name.split(' - ')[1])}} >
                                                            <span class="scb_sever">{a.name.split(' - ')[0]}</span>
                                                            <span class="scb_name">{a.name.split(' - ')[1]}</span>
                                                        </div>
                                                        <div class="scb_deleteside">
                                                            <span class="scb_delete"  onClick={()=>searchDelete(a.order)}></span>
                                                        </div>
                                                    </li>
                                                    )
                                                }

                                            })
                                        }
                                        
                                      
                                    </ul>
                                </div>
                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="bot-notifi">
                <div class="bnot-con">
                    <div class="bont-head">
                        <div class="b-head-mark">
                        <span>!</span>
                        </div>
                    </div>
                    <div class="bnot-text">
                        <span> 서버 점검중 입니다 17:00 ~ 24:00  </span>
                    </div>
                </div>
            </div>  */}
            
            <div class="bot-side">
                <div class="tgcv">
                    <div class="today-grade">
                        <ul class="notice-tit">
        
                            <li class="titlen"> 
                                오늘의 등급
                            </li>
                            <li class="upd"> 
                                { maindatabase && maindatabase.date }
                            </li>
                        
                        </ul>
                        <ul class="tgd-rt">
                            <li>
                                {
                                maindatabase ? 
                                <span style={gradeToColor[maindatabase.grade] != null ? {"color": gradeToColor[maindatabase.grade]} : {}}>
                                    { maindatabase && maindatabase.grade }
                                </span> :<></>
                                }
                            </li>
                        </ul>
                    </div>
                    
                </div>
                <div class="noti-cover">
                    <div class="mnotice" name="업데이트 사항 탭">
                            
                        <ul class="notice-tit">
        
                            <li class="titlen"> 
                                공지 / 업데이트사항
                                <span class="new"></span>
                            </li>
                        
                        </ul>
                        
                        
                        <ul class="accordion" >
                        {
                                maindatabase ? maindatabase.notices.map((a,i)=>
                                {
                                    return(
                                    <li class="item" name="업데이트 사항 구분" onClick={()=>setNoticeNum(i+1)}>
                                        <h2 class={"accordionTitle " + (i+1 == noticeNum ? "accordionTitleActive" : "" )}  name="업데이트 사항 타이틀">
                                            {a.title}
                                            <span class={"accIcon " + (i+1 == noticeNum ? "anime" : "" )} ></span>
                                        </h2>
                                        <div class={"text " + (i+1 == noticeNum ? "show" : "" )} name="업데이트 사항 내용"> 
                                            {a.notice.split('\\n').map(el => {
                                                return <>{el + '\n'}</>;
                                            })}
                                        </div>
                                    </li>

                                    )
                                }) : ""
                            }

                        </ul>
                        
                    
                    </div>
                </div>
                <div class="kakao_qna">

                    
                    <div class="cta__button kakao" onClick={kakaoClick}>
                    </div>
                    <div class="cta__button gmail" onClick={mainClick}>
                    </div>
                    <div class="hn">
                    오류와 건의사항 문의
                    </div>
                </div>

            </div> 
            <div class='coupang'>
            </div>
            <Script src="https://cp.edl.co.kr/cou/js.php?code=dundamdw1&flt=0&click_type=1&width=320&height=50&adid=&click_id="></Script>
            <Script async={true} src="../js/notice.js"/>

        </div>
        )
}



    export default MainMO
