
import { createContext, useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Outlet, Navigate } from 'react-router-dom'
import Radio from "../utill/Radio.js";
import RadioJob from "../utill/Radio_job.js";
import DamageRadioJob from "../utill/Radio_job_damage.js";
import DamageRadio from "../utill/Radio_damage.js";
import {serverKR} from "../var/server.js";
import {lv105OptionMap,lv105WeaponOptionMap,lv105CustomOptionMap,lv105WeaponThirdOptionMap,lv105ItemId} from '../var/editVar';
import {weaponImage,lv105WeaponImage,lv105EquipImage} from '../var/image';


import itemId from '../var/itemVar';
import React from 'react';

import axios from 'axios';
import Ad from "../ad";



let setItemList =[
    //상의
    [
        "지휘관의 명주 상의",
        "총사령관의 제복 상의",
        "미지의 근섬유 상의",
        "순례자의 해진 바람 막이"
    ],
    //하의
    [
        "전략가의 정교한 전투 바지",
        "총사령관의 제복 하의",
        "미지의 금속 각반",
        "순례자의 카고 바지"
    ],
    //어깨
    [
        "연합의 지휘자 망토",
        "총사령관의 의무",
        "미지의 보석 숄더",
        "순례자의 어깨 보호대"
    ],
    //벨트
    [
        "신뢰의 황금 복대",
        "총사령관의 지휘",
        "미지의 기계 장치 벨트",
        "순례자의 나무 요대"
    ],
    //신발
    [
        "결의의 전쟁 부츠",
        "총사령관의 결단",
        "미지의 관절 신발",
        "순례자의 사막 부츠"
    ],
    //팔찌
    [
        "무한한 영감",
        "총사령관의 섬멸 작전",
        "미지의 비늘 암릿",
        "순례자를 짓누르는 무게" 
    ],

    //목걸이
    [
        "포지 코어 펜던트",
        "총사령관의 비장의 한발",
        "미지의 눈동자 네클리스",
        "순례자를 얽매는 고난"

    ],
    //반지
    [
        "조화의 이치",
        "총사령관의 초심",
        "미지의 골격 링",
        "순례자를 억압하는 시련"
    ],
    //보장
    [
        "무회",
        "총사령관의 전술 지도",
        "미지의 에너지 스피어",
        "순례자의 미래를 보는 시선"
    ],
    //마법석
    [
        "무결한 마음",
        "총사령관의 독도법 나침반",
        "미지의 바이탈 시그널",
        "순례자의 길을 비추는 빛"

    ],
    //귀걸이
    [
        "흔적이 새겨진 돌",
        "총사령관의 전우가 남긴 유품",
        "미지의 파워 사이드",
        "순례자의 나아가는 의지" 
    ]


];
const names = [
	'불가침의 영역 - 상의', "숲속의 마녀 상의", "블루 베릴 아머",  "엔트 정령의 상의", '딥 다이버 슈트', '블루 파이렛 블라우스', ...setItemList[0] ,  "아머드 파워 상의", "죽음에 잠식된 갑주", "플래티넘 클래스 재킷", "리버시블 레더 코트", "탐험가의 노련한 코트", "쉐도우블랙 슈트", "어릴적 꿈꿔온 무대", "흙으로 빚은 자연", "천지를 흔드는 석갑",
	"천상을 수호하는 윙 아머", "하이테크 전술지휘 아머", "미련이 남은 녹슨 원혼", "격동하는 마음",  "천재 기술자의 전문 작업복 상의", "드래곤 슬레이어", "얼티밋 제너레이터", "어비스 리액터",
	"옥화의 망령 흉갑", "고귀한 신의", '컨퓨즈드 코어 슈트', '냉정한 지략가의 상의',
	'희생으로 맞이한 여명', '무너진 세계의 틈', '상상을 담은 엔지니어 블라우스','안개의 뜻을 따르는 자의 상의',
	'브레이브 나이트 아머','계곡을 감시하는 주시','근위대의 맹세 갑주',
	
	'불가침의 영역 - 어깨', "숲속의 마녀 망토",  "블루 베릴 보호대", "엔트 정령의 어깨", '딥 다이버 에어팩', '블루 파이렛 숄더', ...setItemList[2], "데저트 컨실멘트 숄더", "끝을 바라보는 시선", "자신감의 백색 망토", "마그네틱 서치 스캔", "근력 보강 숄더", "블랙 캣 헬멧", "화려한 청색의 음율",
	"피어나는 자연의 삶", "오염된 빙석 견갑", "자유를 수호하는 윙 숄더", "하이테크 바디 프로텍트 숄더", "주체할 수 없는 낡은 규칙", "빛을 잃은 진실",  "천재 기술자의 보호 마스크", "드래곤 헌터",
	"헥타곤 임펄스 브릭", "매니퓰레이션", "옥화의 망령 상박갑", "맹렬한 위세", '메탈기어 암 숄더', '유쾌함 속의 열망',
	'아픔을 감싸는 위엄', '손에 닿은 기억', '상상이 실현된 자유','잠식된 신수의 그릇된 망토',
	'페이션트 나이트 숄더','청연을 수호하는 의지','근위대의 맹세 상박갑',
	
	'불가침의 영역 - 하의',"숲속의 마녀 하의", "블루 베릴 하의",  "엔트 정령의 하의", '딥 다이버 팬츠', '블루 파이렛 팬츠' , ...setItemList[1] ,"데저트 테크놀로지 팬츠", "혼돈을 두른 장막", "네오 화이트 클래스 팬츠", "라이트 어댑터 팬츠", "지치지 않는 여정의 하의",
	"벤타블랙 팬츠", "무대의 화려함", "숨쉬는 자연의 생명", "압도하는 힘의 하갑", "평화를 수호하는 윙 레깅스", "하이테크 전술보조 각반", "깨지지 않는 빛바랜 신념", "침식되는 이성", 
	"천재 기술자의 멀티박스 팬츠", "드래곤 라이더", "언리밋 사이버네틱", "스톰라이더", "옥화의 망령 대퇴갑", "굳건한 믿음", '로보티카 컴뱃 팬츠', '승리로 이끄는 정의',
	'악몽을 이겨낸 승전보', '풍운과 안개를 걷는 빛','상상을 담은 엔지니어 팬츠','안개의 뜻을 따르는 자의 하의',
	'포이즈드 나이트 팬츠','계곡을 감시하는 활보','근위대의 맹세 하갑',
	
	'불가침의 영역 - 신발' ,"숲속의 마녀 신발", "블루 베릴 부츠", "엔트 정령의 신발", '딥 다이버 슈즈' ,'블루 파이렛 부츠' , ...setItemList[4] , "HEM 리인포스 부츠", "절망의 발소리", "신비함의 백색 구두",
	"일렉트릭 프루프 부츠", "대지를 딛는 부츠", "검은 발자국", "소망을 전하는 편지", "순환하는 자연의 섭리", "어둠에 삼켜진 돌굽", "하늘을 수호하는 윙 부츠", "하이테크 고기동 강화 부츠", "움직이는 쇠약한 집착", "폭주하는 육신",
	"천재 기술자의 두터운 보호부츠", "드래곤 패스파인더", "스팀펑크 소닉 디스럽터", "엑셀러레이터", "옥화의 망령 각갑", "내딛는 용기", '사이버틱 스피드 부츠', '소망을 향한 움직임',
	'과거를 뒤로 한 전진', '경계를 넘어선 차원','상상을 담은 엔지니어의 작업 신발', '안개의 뜻을 따르는 자의 신발',
	'패셔네이트 나이트 부츠','약속을 지키는 발걸음','근위대의 맹세 각갑',
	
	'불가침의 영역 - 벨트',"숲속의 마녀 벨트",  "블루 베릴 벨트", "엔트 정령의 벨트", '딥 다이버 벨트','블루 파이렛 벨트', ...setItemList[3], "익스펜션 서플라이 벨트",
	"옭아매는 공포", "고고함의 백색 벨트", "썬더 레지스트 벨트", "별을 담는 벨트", "가이드 라인 벨트", "음율에 담은 소망", "순수한 자연의 이치", "불변의 부유석 벨트", "대지를 수호하는 윙 벨트", "하이테크 서플라이 벨트",
	"마주할 수 없는 부러진 긍지", "파괴된 신념", "천재 기술자의 멀티툴 벨트", "드래곤 스케빈저", "얼터레이션 다이얼 벨트", "파워 플랜트", "옥화의 망령 요갑", "마땅한 본분",'쿨링 서포트 벨트',
	'영면을 위한 준비','상처를 동여맨 다짐' , '기억으로 새겨진 별자리', '상상을 담은 엔지니어의 작업 벨트','안개를 탐구하는 자의 벨트',
	'디보티드 나이트 벨트','약속을 다짐하는 벨트','근위대의 맹세 요갑'

];

const accNames = [ 
	'불가침의 영역 - 목걸이',"숲속의 마녀 목걸이","블루 베릴 네클레스", "엔트 정령의 목걸이",'딥 다이버 네클레스', '블루 파이렛 네클레스', ...setItemList[6] , "냉혹한 현실의 목걸이", "머신 컨트롤러 리모트", "디젯 퓨즈 초크", "고양된 분노의 목걸이", "잠겨진 영역", "기사의 속죄",  "약동하는 생명의 고동",
	"푸른 자연의 씨앗", "절대감각의 방위구", "임펄스 트리거", "잔잔한 선율", "검은 별", "솔저 호너 네클리스", "퍼스트 스페이드 - 노블레스", "수호룡의 비호 - 용기", "골렘의 심장 목걸이", "기품의 금빛 장신구",
	"테크놀로지 바디캠 네클리스", "죄어오는 풍화된 악의" , "아토믹 코어 네클레스" , "올곧은 자의 이념" ,  "폭룡왕의 지배 - 공포", "운명이 이끄는 방향",
	'기계 공학의 심장 목걸이','안개를 탐구하는 자의 목걸이','적을 향한 신중','기록자의 목걸이','근위대의 맹세 목 보호대',
	
	'불가침의 영역 - 팔찌',"숲속의 마녀 팔찌","블루 베릴 암릿", "엔트 정령의 팔찌",'딥 다이버 워치','블루 파이렛 암릿', ...setItemList[5], "흑화의 구속 팔찌", "리플레이서", "아크 블라스터 소스", "억제된 마력의 팔찌", "수확하는 옥수", "기사의 구원",
	"생명이 담긴 가죽 토시", "작은 풀잎의 순수함", "이온화조정 팔찌", "미니어쳐 헤드셋 암릿", "화음하는 음색", "포인트 레이더 암릿", "구속된 자유", "세컨드 스페이드 - 어쏘러티", "수호룡의 비호 - 자비",
	"골렘의 핵 팔찌", "찬란한 금장 팔찌", "전술 드론 콘트롤러 암릿", "무뎌지는 둔화된 변화" , "파워 네비게이트 팩" , "오랜 시간 품어온 소원" , "폭룡왕의 지배 - 무력", "찰나를 스치는 흐름",
	'시공을 담은 팔찌','그릇된 힘에 잠식된 팔찌','새겨지는 맹세','전령에 깃든 축복','근위대의 맹세 수갑',

	'불가침의 영역 - 반지',"숲속의 마녀 반지", "블루 베릴 링", "엔트 정령의 반지",'딥 다이버 네비게이션 링','블루 파이렛 링', ...setItemList[7], "핏빛의 결정 반지","원터치 스마트 리모콘", "오버커런트 마그넷 링", "멈추지 않는 운명",
	"이동하는 요새", "기사의 긍지", "빛을 발하는 생명", "개화하는 신비의 꽃", "자기장 탐지링", "어댑터블 투톤 링", "반짝이는 음율", "레드 라이프 링", "고통의 상처",
	"서드 스페이드 - 데스", "수호룡의 비호 - 축복", "골렘의 중추석 반지", "골드 윙 반지", "전술 레이더망 링", "에너지 서치 링" , "뜨거운 열망의 증표", "뚜렷해지는 소멸된 사념" , "폭룡왕의 지배 - 압제", "세계가 담긴 축",
	'밤을 바라보는 공학자의 시선','안개를 탐구하는 자의 반지','압도하는 용맹','파수꾼의 증표','근위대의 맹세 증표'

	];


const speNames = [
	'불가침의 영역 - 보조장비',"숲속의 마녀 바구니", "블루 베릴 퍼퓸", "엔트 정령의 성배",'딥 다이버 오리발','블루 파이렛 프로텍터', ...setItemList[8], "내면의 얼굴","터치 컨트롤 패널", "데카 가이던스 디바이스", "죽음을 부르는 관", "불길한 데칼코마니 석판", "여명의 성배", "생명의 근원이 담긴 배낭",
	"찰랑이는 생명수", "굴착 강화 파츠", "홀로그램 콜", "꿈같은 환호성", "버츄얼 사이트 글래스", "파괴된 생명", "올 오어 원 매직박스", "용살자의 증표 - 용골 뿔피리", "미지의 문명 - 마스크 스톤", "정의의 기사 가면",
	"공중형 : 전술 프롭 드론", "드러나는 흐릿한 지식",'옵티컬 컴뱃 글래스',
	'무모하지만 합리적인 작전','생명이 담긴 잔','멈추어진 지식','샤를의 가면','잠식된 신수의 비통한 면사포','전진하는 기세','청연을 지키는 힘','근위대의 맹세 지령서',

	
	'불가침의 영역 - 마법석',"숲속의 마녀 정령석", "블루 베릴 젬스톤", "엔트 정령의 심장",'딥 다이버 마스크', '블루 파이렛 쥬얼', ...setItemList[9],"피어오르는 광기","미니 배터리 팩", "도데카 홀로그램", "어둠을 먹는 심장", "억류된 혼의 령주", "자정의 성역",
	"생명이 깃든 초록빛 액체", "자연에 녹아드는 이슬", "미지의 황금비석", "잿빛의 묘상석", "두근거리는 열정", "언비튼 메달", "저주받은 마음", "거짓 속의 거짓", "용살자의 증표 - 용심 가공석",
	"미지의 문명 - 스타 스톤", "평화를 위한 투쟁", "지상형 : 전술 차륜 드론", "선회하는 흐려진 혜안",'완성형 동력 제어장치',
	'승리가 약속된 시간','숨결을 삼킨 용옥','잊혀진 기록','기계 공학의 정수','잠식된 고원의 정수','수호하는 인내','기록자의 보석','근위대의 맹세 등불',
	
	'불가침의 영역 - 귀걸이',"숲속의 마녀 귀걸이", "블루 베릴 이어링", "엔트 정령의 귀걸이",'딥 다이버 이어링', '블루 파이렛 이어링', ...setItemList[10], "저주받은 굴레", "부스팅 펄스 튜브", "엔데카 코어 칩", "폭풍을 삼킨 에너지",
	"배회하는 혼령의 향로", "황혼의 성단",  "생명을 키우는 코발트 스틱", "청명한 아침의 새싹", "인력의 법칙", "아크로매틱 룸버스", "마음을 전달하는 소리", "오퍼레이션 오더", "타락한 영혼",
	"시각의 관점", "용살자의 증표 - 용린 귀걸이", "미지의 문명 - 트윈 스톤", "하늘에 휘날리는 깃털", "폭발형 : 소형 전술 플레어", "들이치는 희미한 탄식",'초소형 GPS',
	'영원을 끝낼 오랜 염원','눈물이 스민 보석','아득한 각인','시간 공학의 시간','잠식된 신수의 눈물','포기하지 않는 용기','계곡을 감시하는 표식','근위대의 맹세 충심'
	];

    
let itemCount = accNames.length / 3;
let speCount = speNames.length / 3;
let armourCount = names.length / 5;

let [itemSearch,setItemSearch] =  [null,null];
let [itemSearchTemp,setItemSearchTemp] =  [null,null];
let [characterData, setCharacterData] = [null,null];
let [banList, setBanList] = [null,null];
let [jobtype, setJobType] = [null,null];
let [realgaksungname, setRealGaksungName]  = [null,null];
let [selectWeaponType,setSelectWeaponType] = [null,null];

let DameageJobArray = [
    {id:"0", basejob: "귀검사(남)", job: ['眞 웨펀마스터','眞 버서커','眞 소울브링어','眞 아수라','眞 검귀']},
    {id:"1", basejob: "격투가(남)",job: ["眞 넨마스터", "眞 스트리트파이터", "眞 그래플러", "眞 스트라이커" ]},
    {id:"2", basejob: "거너(남)",job:["眞 레인저", "眞 메카닉", "眞 런처", "眞 스핏파이어" , '眞 어썰트' ]},
    {id:"3", basejob: "마법사(남)",job: ["眞 블러드 메이지", "眞 엘레멘탈 바머", "眞 빙결사", "眞 디멘션워커" , "眞 스위프트 마스터" ]},
    {id:"4", basejob: "프리스트(남)",job: ["眞 크루세이더", "眞 퇴마사", "眞 인파이터", "眞 어벤저" ]},
    {id:"5", basejob:"귀검사(여)",job:["眞 소드마스터", "眞 데몬슬레이어", "眞 다크템플러", "眞 베가본드", "眞 블레이드" ]},
    {id:"6", basejob:"격투가(여)",job:["眞 넨마스터", "眞 스트리트파이터", "眞 그래플러", "眞 스트라이커"]},
    {id:"7", basejob:"거너(여)",job:["眞 레인저", "眞 메카닉", "眞 런처", "眞 스핏파이어"]},
    {id:"8", basejob:"마법사(여)",job:["眞 엘레멘탈마스터", "眞 마도학자", "眞 소환사", "眞 배틀메이지", "眞 인챈트리스"]},
    {id:"9", basejob:"프리스트(여)",job:["眞 크루세이더", "眞 이단심판관", "眞 미스트리스", "眞 무녀"]},
    {id:"10", basejob:"도적",job:["眞 로그", "眞 쿠노이치", "眞 섀도우댄서", "眞 사령술사"]},
    {id:"11", basejob:"나이트",job:["眞 엘븐나이트", "眞 카오스", "眞 드래곤나이트", "眞 팔라딘"]},
    {id:"12", basejob:"마창사",job:["眞 뱅가드", "眞 듀얼리스트", "眞 다크 랜서", "眞 드래고니안 랜서" ]},
    {id:"13", basejob:"총검사",job:["眞 요원", "眞 트러블 슈터","眞 히트맨","眞 스페셜리스트"]},
    {id:"14", basejob:"외전",job:["眞 다크나이트", "眞 크리에이터"]},
    {id:"15", basejob:"아처",job:["眞 뮤즈", "眞 트래블러" , "眞 헌터" , "眞 비질란테"]},

        ];

function DamageRank(){

    let navigate = useNavigate();

    [selectWeaponType,setSelectWeaponType]  = useState(0);
    [itemSearch,setItemSearch] = useState('');
    [itemSearchTemp,setItemSearchTemp] = useState('');
    
    let [damagebase, setDamagebase] = useState();
    let [buffrankerbase, setBuffRankerbase] = useState();
    let [bufftype, setBufftype] = useState(1);

    
    let [equipfilter, setEquipFilter] = useState(false);



    let rankingArray = [0,0,0,0,0,0,0,0];
    let rankinglistArray = [0,0,0,0,0,0,0,0,0,0];
    let [listedit,setListEdit] = useState(1);


    //기본직업 리스트 만들기
    
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();


    const params = new URLSearchParams(window.location.search);
    let pageParam = params.get('page') != null ? params.get('page') : 1;
    let typeParam = params.get('type') != null ? params.get('type') : 7;
    let realgaksungnameParam = params.get('job') != null ? params.get('job') : "眞 웨펀마스터" ;
    let realjobnameParam = params.get('baseJob') != null ? params.get('baseJob') : "귀검사(남)" ;
    let jobTypeParam = 0;
    let gaksungtypeParam = 0;
    

    let temp = "";
    for(var [key, value] of params.entries()) {
        if(key != 'page' 
            && key != 'type'  
            && key != 'job'  
            && key != 'baseJob'  
            && key.indexOf('item10') == -1
            && key != 'banList'  
            && key != 'weaponType'  
            && key != 'weaponDetail'  
            )
        {
            temp += '&'+key+ '='+ value; 
        }
    }

    if(window.location.href.indexOf("#") != -1)
    {
        temp += "#"+window.location.href.split("#")[1];
    }
    
    
     
    for(let loop of DameageJobArray)
    {
        if(loop.basejob == realjobnameParam)
        {
            jobTypeParam = loop.id;
            for(let count = 0 ; count < loop.job.length ; count++)
            {
                if(realgaksungnameParam == loop.job[count])
                {
                    gaksungtypeParam = count;
                    break;
                }
            }
            break;
        }
    }
    let characterDataParam = {};
    for(let count = 0 ; count < 13 ; count++)
    {
        if(params.get('item10'+count) != null)
        {
            characterDataParam['item10'+count] = params.get('item10'+count);
        }

    }
    let banListParam = [];
    if(params.get('banList') != null)
    {
        for(let str of params.get('banList').split(','))
        {
            banListParam.push(str);
        }
    }

    [characterData, setCharacterData] = useState(characterDataParam);
    [banList, setBanList] = useState(banListParam);

    let [page, setPage] = useState(pageParam);
    const [type, setType] = useState(typeParam);
    [jobtype, setJobType] = useState(jobTypeParam);
    const [gaksungtype, setGaksungType] = useState(gaksungtypeParam);
    const [realjobname, setRealJobName]  = useState(realjobnameParam);
    [realgaksungname, setRealGaksungName]  = useState(realgaksungnameParam);


      
    useEffect( () => { 
      
        if(Object.keys( characterData).length == 0 && (banList.length == 0 ))
        {
            window.history.replaceState("",null,
            "?"+ 'page='+page+'&type='+type+'&job='+encodeURIComponent( realgaksungname)+'&baseJob='+encodeURIComponent( realjobname)+'&weaponType=전체'+'&weaponDetail=전체' + temp );
            
            
            axios.post('/dat/dealerRankingData.jsp?page='+page+'&type='+type+'&job='+realgaksungname+'&baseJob='+realjobname+'&weaponType=전체'+'&weaponDetail=전체', 
            {}, { cancelToken: source.token  })
            .then((base)=>{
                
                let copy = base.data;
                setDamagebase(copy);
            })

        }
        else
        {
            detailSearch();
        }

        return ()=>{
            
            source.cancel();
        }
    },[type,page,jobtype,gaksungtype,realjobname,realgaksungname]);

    
    const detailSearch =  () => 
    {
        window.history.replaceState("",null,
        "?"+ 'page='
        +page+'&type='+type+'&job='+encodeURIComponent(realgaksungname)+'&baseJob='+encodeURIComponent(realjobname)+'&weaponType=전체'+'&weaponDetail=전체&'+encodeQueryData(characterData)
        +"&banList="+banListEncodeQueryData() + temp );
        


        axios.post('/dat/dealerRankingDetailData.jsp?page='
        +page+'&type='+type+'&job='+realgaksungname+'&baseJob='+realjobname+'&weaponType=전체'+'&weaponDetail=전체&'+encodeQueryData(characterData)
        +"&banList="+banListEncodeQueryData() , {}, { cancelToken: source.token  } )
            .then((base)=>{
            //console.log(base.data);

            let copy = base.data;
            setDamagebase(copy);
            });
    }

    const searchF =  () => 
    {
        window.history.replaceState("",null,
        "?" +'page='+page+'&type='+type+'&job='+encodeURIComponent( realgaksungname)+'&baseJob='+encodeURIComponent( realjobname)+'&weaponType=전체'+'&weaponDetail=전체' + temp );
        

        axios.post('/dat/dealerRankingData.jsp?page='+page+'&type='+type+'&job='+realgaksungname+'&baseJob='+realjobname+'&weaponType=전체'+'&weaponDetail=전체' , 
        {}, { cancelToken: source.token  })
        .then((base)=>{
             let copy = base.data;
            setDamagebase(copy);
        });
    }

    
    useEffect( () => { 
        
        var self = window;
        self.__VM = self.__VM || [];
        
        self.__VM.push(function (admanager, scope) {
            scope.Config.get('billboard').display('ad-container-2');
            scope.Config.get('desktop_takeover').display('ad-container-1');
            scope.Config.verticalSticky().display();
    

        });

        return () =>
        {
            self.__VM = self.__VM || [];
            self.__VM.push((admanager, scope) => {
                admanager.removeAllPlacements();
                scope.Instances.pageManager.newPageSession();
            });
            
        };
        
            
    },[]);

    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     (window.top).__vm_add = (window.top).__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {
    //         //console.log("mounting2", placement); 

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358a82bf161d63ec3c8e8a");
    //         document.querySelector("#ad-container-2").appendChild(placement);
    //         (window.top).__vm_add.push(placement);
    //     });

            
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         //console.log("unmounting2", placement);
    //         (window.top).__vm_remove.push(placement);

    //     };

            
    // },[]);

      

    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     (window.top).__vm_add = (window.top).__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {
    //         //console.log("mounting", placement); 

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358a82bf161d63ec3c8e8a");
    //         placement.setAttribute("data-display-type", "hybrid-banner");
    //         document.querySelector("#ad-container-1").appendChild(placement);
    //         (window.top).__vm_add.push(placement);
    //        
    //     });
            
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         //console.log("unmounting", placement);
    //         (window.top).__vm_remove.push(placement);


    //     };

            
    // },[]);

    
    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     window.__vm_add = window.__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358aa395a6d454fcf66d11");
    //         document.querySelector("#rich-media-placement").appendChild(placement);
    //         window.top.__vm_add.push(placement);
    //     });
            
    //     return() => { 
    //         (window).__vm_remove_category = (window).__vm_remove_category || [];
    //         (window).__vm_remove_category.push("richmedia_all");

    //         //console.log("unmounting", (window.top).__vm_remove_category);

    //     };



            
    // },[]);

    return (

    <div className="ranking">
        
        <div id="rich-media-placement"></div>
        <div id="ad-container-1" >
        </div>

        <div className='rkcont damagerank container' id="content-container">

            <div className="rktb drank" name="랭킹 선택 창">
                        <div className="ch">

                            <div className="ch_rk tble" name="버프 랭킹 선택 '각성선택'">
                                <div className="th">
                                    <div className="tit_hug">
                                        <span>랭킹</span>
                                    </div>
                                </div>
                                <div className="td">
                                    <div className="radio_hug">
                                        <Radio name="Stat" value="7" present={type} 
                                        setType={setType}>
                                        랭킹
                                        </Radio>
                                        <Radio name="Attack" value="8" present={type}
                                        setType={setType}>
                                        명성
                                        </Radio>
                                        <Radio name="Point" value="9" present={type}
                                        setType={setType}>
                                        명성 전직업
                                        </Radio>
                                    </div>
                                </div>
                            </div>

                            <div className="ch_job damage tble" name="버프 랭킹선택 '직업'">

                                <div className="th">
                                    <div className="tit_hug">
                                        <span>직업</span>
                                    </div>
                                </div>
                                <div className="td">
                                    <div className="radio_hug">
                                    
                                          {
                                            DameageJobArray.map((a, i) =>
                                            
                                            {
                                                return(
                                                    <DamageRadioJob value={DameageJobArray[i].id} name={DameageJobArray[i].basejob} present={jobtype} 
                                                    setType={setJobType} 
                                                    setRealJobName = {setRealJobName}
                                                    setGaksungType = {setGaksungType}
                                                    setRealGaksungName = {setRealGaksungName}
                                                    DameageJobArray={DameageJobArray}   key={i}
                                                    characterData = {characterData}
                                                    setCharacterData = {setCharacterData}>
                                                    </DamageRadioJob>
                                                )
                                            }
                                        )}
                                    </div>
                                </div>

                            </div>

                            {

                            <div className="ch_gaksung tble" name="버프 랭킹 선택 '각성선택'">
                                <div className="th">
                                    <div className="tit_hug">
                                        <span>각성선택</span>
                                    </div>
                                </div>
                                <div className="td">
                                    <div className="radio_hug">
                                     {

                                            DameageJobArray[jobtype].job && DameageJobArray[jobtype].job.map((a, i) =>
                                            
                                            {                                   

                                                return(
                                            <DamageRadio  key={i} value={DameageJobArray[i].id} name={DameageJobArray[jobtype].job[i]} present={gaksungtype} 
                                            setType={setGaksungType} setRealGaksungName = {setRealGaksungName} 
                                            characterData = {characterData}
                                            setCharacterData = {setCharacterData}>
                                            </DamageRadio>

                                            )
                                         }
                                    )}
                                    
                                    </div>
                                </div>
                            </div>
                            }

                        </div>
                
            </div>

            <div class="rkdetail-prev" style={(Object.keys( characterData).length != 0 || banList.length != 0  ? {"position": "relative"} : {"display":"none"})}>
                <div class="rkdetailList" name="현재필터">

                    <RecentList/>

                </div>
                
                <div class="rkdetailList" name="현재필터">
                    <BanList/>
                </div>


                <div class="rkde-reset" onClick={()=>{reset({})}}>
                    <li class="rkd-rebut" onClick= {() =>{searchF()}}><span>초기화</span></li>
                </div>
            </div>
            
            <div className="rktb-quipcahnge" onClick={ ()=> {setEquipFilter(true)}}>
                <div class="rktb-qc_hug">
                    <div class="rktb-qc-but">
                        <span>장비필터</span>
                    </div>
                </div>
            </div>
       

            <div className="rklist drank">
                <div className="rk-ranker" name="랭커 소개">
                    <div className="rker-dq">
                        {
                            page == 1 && damagebase && rankingArray.map((a, i) =>
                                
                                {

                                    if(damagebase.ranking[i] != null)
                                    return(
                                        
                                    <div className="rker-card" key={i}>
                                        <div className="rkerc-num">
                                            <span className="rkerc-numbering">{damagebase.ranking[i].rank}</span>
                                            <span className="medal-img">
                                                <div className="imgt">
                                                </div>
                                            </span>
                                        </div>
                                        <div className="rkerc-abata" onClick={() => navigate('../character?server='+damagebase.ranking[i].server+'&key='+damagebase.ranking[i].key)}>
                                            <div className="imgt">
                                                <img src={'https://img-api.neople.co.kr/df/servers/'+damagebase.ranking[i].server+'/characters/' +damagebase.ranking[i].key+ '?zoom=1.jpg&temp='+ Math.round(Math.random() * 10000)}  />
                                            </div>
                                            <div className="rkerc-round">
                                                
                                            </div>
                                        </div>
                                        <div className="level rker-lv">
                                            <span className="fpic">
                                                <div className="imgt">
                                                </div>
                                            </span>
                                            <span className="val">{damagebase.ranking[i].fame}</span>
                                        </div>
                                        <div className="rker-sever">
                                            <div className="rkrsv">
                                                <span className="svname">{serverKR[damagebase.ranking[i].server]}</span>
                                            </div>
                                        </div>
                                        <div className="rker-urname">
                                            <div className="rkrur">
                                                <span className="urname">{damagebase.ranking[i].nick}</span>
                                            </div>
                                        </div>
                                        <div className="rker-point">
                                            <div className="rkerp">
                                                <div className="rkwepn">
                                                    <span className="weapon" name="착용 무기">
                                                        <div class="imgt">
                                                            <img src={lv105ItemId[damagebase.ranking[i].weapon] != null
                                                                ? "https://dundam.xyz/img/item/"+lv105ItemId[damagebase.ranking[i].weapon] : ""}/>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div className="rkpo">
                                                    <span>{damagebase.ranking[i].damage}</span>
                                                </div>
                            
                                            </div>
                                        </div>
                                    </div>
                                    )
                                }
                        )}
                    </div>
                </div>
                <div className="rk-table damage" name="랭킹표">
                    <div className="rkt-thead" name="랭킹표 th">
                        <div className="rkt-tr">
                                <div className="th rking">순위</div>
                                <div className="th name">닉네임</div>
                                <div class="th dameged">데미지</div>

                        </div>
                    </div>
                    
                    <div className="rkt-tbody damage" name="랭킹 검색 tbody">
                    {
                        damagebase && damagebase.ranking.map((a, i) =>
                        {
                            return(

                            <div className="rkt-tr" key={i}>

                                <div className="td rking">
                                    <span>{damagebase.ranking[i].rank}</span>
                                </div>                                
                                <div className="td name new" onClick={() => navigate('../character?server='+damagebase.ranking[i].server+'&key='+damagebase.ranking[i].key)}>

                                    <span className="abata">
                                        <div className="imgt" >
                                        <img src={'https://img-api.neople.co.kr/df/servers/'+damagebase.ranking[i].server+'/characters/' +damagebase.ranking[i].key+ '?zoom=1.jpg&temp='+ Math.round(Math.random() * 10000)}  />

                                        </div>
                                    </span>
                                    <span className="nik">{damagebase.ranking[i].nick}</span>
                                    <div className="tb_fsev">
                                        <div className="level rker-lv">
                                            <span className="fpic">
                                                <div className="imgt">
                                                </div>
                                            </span>
                                            <span className="val">{damagebase.ranking[i].fame}</span>
                                        </div>
                                        <div className="rker-sever">
                                            <div className="rkrsv">
                                                <span className="svname">{serverKR[damagebase.ranking[i].server]}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="td dameged">
                                    <span class="weapon" name="착용 무기">
                                        <div class="imgt">
                                            <img src={lv105ItemId[damagebase.ranking[i].weapon] != null
                                                ? "https://dundam.xyz/img/item/"+lv105ItemId[damagebase.ranking[i].weapon] : ""}/>
                                        </div>
                                    </span>
                                    <span class="damegen" name="데미지">{damagebase.ranking[i].damage}</span>
                                    <span class="more">
                                
                                    </span>

                                </div>

                                


                            </div>
                            
                            )  
                        }
                    )}
                    </div>
                </div>
            </div>
            

            <div className="page-guide">
                <div className="list">
                        <ul className="list-number">
                            <li className="back" onClick={()=> {setListEdit((listedit) <= 1 ? 1 : (listedit)-10)}}>
                                <span>
                                </span>
                            </li>
                        {
                            page &&  rankinglistArray.map((a,i) => 
                            {
                                return(
                                
                                        <li key={i} className={page == listedit+i ? "is-active" : ""} onClick={()=>{setPage((listedit)+i)}}><span>{(listedit)+i}</span></li>
                                    
                                        
    
                                    
                                )
                            }
                            
                            )
                        }
                        <li className="next" onClick={()=> {setListEdit((listedit) + 10)}}>
                            <span>
                            </span>
                        </li>


                    </ul>
                </div>
            </div>
        </div>

        {
           equipfilter  == true ? 
                <div class="drnk-eiupfliter">
                    <div class="modal_setting_v1">
                        <div class="drnk-ef">
                            <div class="def-select-tab">
                                <div class="scroll-mode">
                                    <div class="rkdt-c">
                                        <div class="tab-wrap" name="장비 필터">
                                            <input type="radio" id="tab1" name="tabGroup2" class="tab" defaultChecked></input>
                                            <div class="tab__content" name="105제">

                                                
                                                <div class="epiup-mainweapontype-select">
                                                    <div class="ems-content-hug">
                                                        <div class="ecslect-con">
                                                            <div class="ems-sel-tit">
                                                                <span>무기</span>
                                                            </div>
                                                            <div class="ems-sel-list">
                                                                <div class="eslist-con-hug">
                                                                    <div class="eslist-con">
                                                                        <ul>
                                                                            
                                                                            {
                                                                                getWeaponList(realgaksungname,DameageJobArray[jobtype].basejob).map((a,i) =>
                                                                                {
                                                                                    return(
                                                                                        <li class={selectWeaponType == i ? "is-active" : "" } onClick={()=> {setSelectWeaponType(i)}}><span>{a}</span></li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        
                                                                            

                                                                        </ul>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <WeaponList id={selectWeaponType} detail={getWeaponList(realgaksungname,DameageJobArray[jobtype].basejob)[selectWeaponType] ? getWeaponList(realgaksungname,DameageJobArray[jobtype].basejob)[selectWeaponType] : getWeaponList(realgaksungname,DameageJobArray[jobtype].basejob)[0] }/>
                                                            
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="cus_search e105s">
                                                    <div class="e105_shc">
                                                        <div class="searchbox" name="검색창">
                                                            <input type="text" onChange={(event)=>setItemSearchTemp(event.target.value)} onKeyUp={(event)=>{ event.key == 'Enter' ? setItemSearch(event.target.value) : <></>}} class="form-control js-bound" placeholder="옵션검색" name="name" maxlength="11"></input>
                                                        </div>
                                                        
                                                        <div class="searchbut105" onClick={()=>setItemSearch(itemSearchTemp)}>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="cus_epdt e105ep">
                                                        <div class="cus_elst">
                                                    <div class="cus_eltit_v2 e-m-set">
                                                        <span>방어구</span>
                                                    </div>

                                                    {
                                                        names.map((a, i) =>
                                                            {
                                                                if(i < armourCount)
                                                                {
                                                                    let totalCheck = false;
                                                                    let check = [false,false,false,false,false];

                                                                    ////console.log(itemSearch.replace(/ /gi,'').replace(/속강/gi,'속성강화').replace(/스증/gi,'스킬공격력'));

                                                                    for(let loop = 0 ; loop < 5 ; loop++)
                                                                    {
                                                                        if(names[i+armourCount*loop].replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'')) != -1)
                                                                        {
                                                                            check[loop] = true;
                                                                            totalCheck = true;
                                                                        }
                                                                        

                                                                        if(lv105OptionMap[names[i+armourCount*loop]] != null)
                                                                        if(lv105OptionMap[names[i+armourCount*loop]].replace(/,/gi,'').replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'').replace(/속강/gi,'속성강화').replace(/스증/gi,'스킬공격력')) != -1)
                                                                        {
                                                                            check[loop] = true;
                                                                            totalCheck = true;
                                                                        }
                                                                    }

                                                                    if(totalCheck)
                                                                    return(
                                                                            <ul key={'armour'+i}>
                                                                                {
                                                                                check[0] ? 
                                                                                (i > 5 && i < 10 ? <ItemList name={names[i+armourCount*0]} slot={2}/>:
                                                                                <Equip name={names[i+armourCount*0]} slot={2}/>) : <li></li>
                                                                                }
                                                                                {
                                                                                check[2] ? 
                                                                            
                                                                                (i > 5 && i < 10 ? <ItemList name={names[i+armourCount*2]} slot={4}/>:
                                                                                <Equip name={names[i+armourCount*2]} slot={4}/>) : <li></li>
                                                                                }
                                                                                {
                                                                                check[1] ? 
                                                                            
                                                                                (i > 5 && i < 10 ? <ItemList name={names[i+armourCount*1]} slot={3}/>:
                                                                                <Equip name={names[i+armourCount*1]} slot={3}/>) : <li></li>
                                                                                }
                                                                                
                                                                                {
                                                                                check[4] ?
                                                                                (i > 5 && i < 10 ? <ItemList name={names[i+armourCount*4]} slot={6}/>:
                                                                                <Equip name={names[i+armourCount*4]} slot={6}/>) : <li></li>
                                                                            
                                                                                }
                                                                                {
                                                                                check[3] ?
                                                                                
                                                                                (i > 5 && i < 10 ? <ItemList name={names[i+armourCount*3]} slot={5}/>:
                                                                                <Equip name={names[i+armourCount*3]} slot={5}/>) : <li></li>
                                                                            
                                                                            
                                                                                }
                                                                            </ul>

                                                                    )
                                                                }
                                                            } 
                                                        ) 
                                                    }
                                                </div>
                                                <div class="cus_elst">
                                                    <div class="cus_eltit_v2 e-m-set">
                                                        <span>악세서리</span>
                                                    </div>

                                                    {
                                                        accNames.map((a, i) =>
                                                            {
                                                                if(i < itemCount)
                                                                {
                                                                    let totalCheck = false;
                                                                    let check = [false,false,false];

                                                                    for(let loop = 0 ; loop < 3 ; loop++)
                                                                    {
                                                                        if(names[i+itemCount*loop].replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'')) != -1)
                                                                        {
                                                                            check[loop] = true;
                                                                            totalCheck = true;
                                                                        }

                                                                        if(lv105OptionMap[names[i+itemCount*loop]] != null)
                                                                        if(lv105OptionMap[names[i+itemCount*loop]].replace(/,/gi,'').replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'').replace(/속강/gi,'속성강화').replace(/스증/gi,'스킬공격력')) != -1)
                                                                        {
                                                                            check[loop] = true;
                                                                            totalCheck = true;
                                                                        }
                                                                    }

                                                                    if(totalCheck)
                                                                    return(
                                                                        <ul key={i}>
                                                                            
                                                                            {
                                                                                check[1] ?
                                                                                
                                                                                (i > 5 && i < 10 ? <ItemList name={accNames[i+itemCount*1]} slot={8}/>:
                                                                                <Equip name={accNames[i+itemCount*1]} slot={8}/>) : <li></li>
                                                                            
                                                                            }
                                                                            {
                                                                                check[0] ?
                                                                                (i > 5 && i < 10 ? <ItemList name={accNames[i+itemCount*0]} slot={7}/>:
                                                                                <Equip name={accNames[i+itemCount*0]} slot={7}/>) : <li></li>
                                                                            
                                                                            }
                                                                            {
                                                                                check[2] ?
                                                                                (i > 5 && i < 10 ? <ItemList name={accNames[i+itemCount*2]} slot={9}/>:
                                                                                <Equip name={accNames[i+itemCount*2]} slot={9}/>) : <li></li>
                                                                            
                                                                            }
                                                                            
                                                                        </ul>

                                                                    )
                                                                }
                                                                
                                                            } 
                                                        ) 
                                                    }
                                                </div>
                                                    <div class="cus_elst">
                                                        <div class="cus_eltit_v2 e-m-set">
                                                            <span>특수장비</span>
                                                        </div>

                                                        {
                                                            speNames.map((a, i) =>
                                                                {
                                                                    if(i < speCount)
                                                                    {
                                                                        let totalCheck = false;
                                                                        let check = [false,false,false];

                                                                        for(let loop = 0 ; loop < 3 ; loop++)
                                                                        {
                                                                            if(names[i+speCount*loop].replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'')) != -1)
                                                                            {
                                                                                check[loop] = true;
                                                                                totalCheck = true;
                                                                            }

                                                                            if(lv105OptionMap[names[i+speCount*loop]] != null)
                                                                            if(lv105OptionMap[names[i+speCount*loop]].replace(/,/gi,'').replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'').replace(/속강/gi,'속성강화').replace(/스증/gi,'스킬공격력')) != -1)
                                                                            {
                                                                                check[loop] = true;
                                                                                totalCheck = true;
                                                                            }
                                                                        }

                                                                        if(totalCheck)
                                                                        return(
                                                                            <ul key={i}>
                                                                                {
                                                                                    check[0] ?
                                                                                    (i > 5 && i < 10 ? <ItemList name={speNames[i+speCount*0]} slot={10}/>:
                                                                                    <Equip name={speNames[i+speCount*0]} slot={10}/>) : <li></li>
                                                                                }
                                                                                {
                                                                                    check[1] ?
                                                                                    (i > 5 && i < 10 ? <ItemList name={speNames[i+speCount*1]} slot={11}/>:
                                                                                    <Equip name={speNames[i+speCount*1]} slot={11}/>) : <li></li>
                                                                                }
                                                                                {
                                                                                    check[2] ?
                                                                                    (i > 5 && i < 10 ? <ItemList name={speNames[i+speCount*2]} slot={12}/>:
                                                                                    <Equip name={speNames[i+speCount*2]} slot={12}/>) : <li></li>
                                                                                }
                                                                        
                                                                            </ul>

                                                                        )
                                                                    }
                                                                }
                                                            )
                                                        }
                                                    </div>

                                                    
                                                    </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="def-apply-but">
                                <ul>
                                    <li class="rkdtb_apply" onClick={ () => {setEquipFilter(!equipfilter); detailSearch()}}><span>적용</span></li>
                                    <li class="rkdtb_cancel" onClick={ ()=> {setEquipFilter(!equipfilter)}}><span>닫기</span></li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> : ""
        }
        
        <div id="ad-container-2" />
    </div>

)}

function change(name,value,index = 0)
{
    
    let temp = {...characterData};

    if(index == 0)
    {
        temp[name] = value;
    }
    
    setCharacterData(temp);
    

}


function ItemOption(props)
{
    return (
        
        <>
        </>
    
                        
        
        
    )
}


function classCheck(slot,itemName)
{
    if(banList.includes(itemName))
    {
        return 'ban';
    }

    if(characterData[slot] == itemName)
    {
        return 'on'
    }

    return ''

}
function filterClick(slot,itemName)
{

    if(characterData[slot] == null)
    {
        change(slot,itemName);
        return;
    }

    
    if(banList.includes(itemName))
    {
        let temp = [];
        for(let loop of banList)
        {
            if(loop != itemName)
            {
                temp.push(loop);
            }
        }
        setBanList(temp);
        //console.log('cancle');
        return;
    }

    if(characterData[slot].indexOf(itemName) != -1)
    {
        //console.log('ban');
        let temp = [...banList];
        temp.push(itemName);
        setBanList(temp);
        change(slot,"");
        return;
    }
    else
    {
        change(slot,itemName);
        return;
    }
    



}

function encodeQueryData(data) {
    const ret = [];
    for (let d in data)
    if(data[d] != '')
    {
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    }
    return ret.join('&');
 }

 
function banListEncodeQueryData() {
    const ret = [];
    for (let d of banList)
    if(d != '')
    {
      ret.push(d);
    }
    //console.log(ret.join(','));
    return ret.join(',');
 }

function RecentList()
{

    console.log(characterData);
    return(
        
        Object.keys( characterData).map((a,i) => {

            ////console.log(a);
            let name = characterData[a];
            if(name =="")
            {
                return(<></>)
            }
            let number = weaponImage[lv105ItemId[name]];
            let style = {};
            if(number == null)
            {
                number = lv105WeaponImage[name];
                style = {"background-image":"url('https://dundam.xyz/img/sprite/lv105WeaponImage.png')"};
            }
            if(number == null)
            {
                number = lv105EquipImage[name];
                style = {"background-image":"url('https://dundam.xyz/img/sprite/lv105EquipImage.png')"};
            }

            if(number == null)
            {
                
                console.log(a);
                console.log(lv105ItemId[characterData[a]]);
                return(
                <div class="imgt" slot={a.replace("item",'')}>
                    <img name={characterData[a] } src={"https://dundam.xyz/img/item/"+lv105ItemId[characterData[a]] }/>
                </div>
                )
            }
            else
            {
                let x = 0;
                let y = 0;
                if(number != null)
                {
                    x = (number-1) % 9;
                    y = Math.trunc((number-1)/ 9) ;
                }
    
                const constStyle = {"zoom":"1.35714"
                , "width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"};
                style = { ...style,...constStyle};
    
                if(number != null)
                {
                    return(
                        <div  name={ name} className={"weaponSprite"} loading="lazy" 
                        style={style}></div>
                        /*
                        <div class="imgt" slot={a.replace("item",'')}>
                            <img name={characterData[a] } src={"https://dundam.xyz/img/item/"+lv105ItemId[characterData[a]] }/>
                        </div>
                        */
    
                    )
                }
            }

           
        } )
    )
    
    
}
function BanList()
{
    return(
        banList.map((a,i)=>{
            let name = a;
            if(name =="")
            {
                return(<></>)
            }
            let number = weaponImage[lv105ItemId[name]];
            let style = {};
            if(number == null)
            {
                number = lv105WeaponImage[name];
                style = {"background-image":"url('https://dundam.xyz/img/sprite/lv105WeaponImage.png')"};
            }
            if(number == null)
            {
                number = lv105EquipImage[name];
                style = {"background-image":"url('https://dundam.xyz/img/sprite/lv105EquipImage.png')"};
            }

            if(number == null)
            {
                console.log(name);
                console.log(lv105ItemId[name]);
                return(
                <div class="imgt">
                    <img name={name } src={"https://dundam.xyz/img/item/"+lv105ItemId[name] }/>
                </div>
                )
            }
            else
            {

                let x = 0;
                let y = 0;
                if(number != null)
                {
                    x = (number-1) % 9;
                    y = Math.trunc((number-1)/ 9) ;
                }
                
                const constStyle = {"zoom":"1.35714",
                'filter': 'grayscale(0.8)', "width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"};
                style = { ...style,...constStyle};

                if(number != null)
                {
                    return(
                        <div  name={ name} className={"weaponSprite"} loading="lazy" 
                        style={style}></div>
                        /*
                        <div class="imgt" slot={a.replace("item",'')}>
                            <img name={characterData[a] } src={"https://dundam.xyz/img/item/"+lv105ItemId[characterData[a]] }/>
                        </div>
                        */

                    )
                }
            }

        })

    )
}

function reset()
{
    setCharacterData({});
    setBanList([]);
}

function getWeaponList(jobInput,baseJob)
{

    let job = jobInput;
    let weaponList = [];
    let weaponListTemp = [];
    switch(job)
    {
        case "眞 소드마스터": job = "마제스티"; break;
        case "眞 데몬슬레이어": job = "디어사이드"; break;
        case "眞 베가본드": job = "검제"; break;
        case "眞 다크템플러": job = "네메시스"; break;
        case "眞 블레이드": job = "벤데타"; break;
        
        case "眞 넨마스터": if(baseJob.indexOf("(여)") != -1) job = "염제 폐월수화"; else job = "염황 광풍제월"; break;
        case "眞 그래플러": if(baseJob.indexOf("(여)") != -1) job = "얼티밋 디바"; else job = "그랜드 마스터"; break;
        case "眞 스트리트파이터": if(baseJob.indexOf("(여)") != -1) job = "용독문주";else  job = "명왕";  break;
        case "眞 스트라이커": if(baseJob.indexOf("(여)") != -1) job = "카이저"; else job = "패황"; break;
        
        case "眞 웨펀마스터": job = "검신"; break;
        case "眞 버서커": job = "블러드 이블"; break;
        case "眞 아수라": job = "인다라천"; break;
        case "眞 소울브링어": job = "다크로드"; break;
        case "眞 검귀": job = "악귀나찰"; break;
        

        case "眞 레인저": if(baseJob.indexOf("(여)") != -1) job = "크림슨 로제"; else job = "레이븐"; break;
        case "眞 런처": if(baseJob.indexOf("(여)") != -1) job = "스톰 트루퍼"; else job = "디스트로이어"; break;
        case "眞 메카닉": if(baseJob.indexOf("(여)") != -1) job = "옵티머스"; else job = "프라임"; break;
        case "眞 스핏파이어":if(baseJob.indexOf("(여)") != -1) job = "프레이야"; else  job = "커맨더"; break;
        case "眞 어썰트": job = "엑스마키나"; break;


        case "眞 크루세이더": if(baseJob.indexOf("(여)") != -1) job = "세라핌"; else job = "세인트"; break;
        case "眞 인파이터": job = "저스티스"; break;
        case "眞 퇴마사": job = "태을선인"; break;
        case "眞 어벤저": job = "이모탈"; break;
        
        case "眞 엘레멘탈마스터": job = "오버마인드"; break;
        case "眞 배틀메이지": job = "아슈타르테"; break;
        case "眞 마도학자": job = "지니위즈"; break;
        case "眞 소환사": job = "이클립스"; break;
        case "眞 인챈트리스": job = "헤카테"; break;
        case "블랙 메이든": job = "헤카테"; break;
        
        case "眞 미스트리스": job = "리디머"; break;
        case "眞 무녀": job = "천선낭랑"; break;
        case "眞 이단심판관": job = "인페르노"; break;
        
        case "眞 로그": job = "알키오네"; break;
        case "眞 사령술사": job = "타나토스"; break;
        case "眞 섀도우댄서": job = "그림리퍼"; break;
        case "眞 쿠노이치": job = "시라누이"; break;
        
        case "眞 스위프트 마스터": job = "아이올로스"; break;
        case "眞 블러드 메이지": job = "뱀파이어 로드"; break;
        case "眞 엘레멘탈 바머": job = "오블리비언"; break;
        case "眞 디멘션워커": job = "어센션"; break;
        case "眞 빙결사": job = "이터널"; break;
        

        case "眞 팔라딘": job = "세이비어"; break;
        case "眞 엘븐나이트": job = "가이아"; break;
        case "眞 드래곤나이트": job = "드레드노트"; break;
        case "眞 카오스": job = "마신"; break;
        

        case "眞 뱅가드": job = "워로드"; break;
        case "眞 듀얼리스트": job = "듀란달"; break;
        case "眞 다크 랜서": job = "에레보스"; break;
        case "眞 드래고니안 랜서": job = "제노사이더"; break;
        
        case "眞 요원": job ="레퀴엠"; break;
        case "眞 트러블 슈터": job = "언터처블"; break;
        case "眞 스페셜리스트":job = "패스파인더"; break;
        case "眞 히트맨": job = "갓파더"; break;
    
        case "眞 다크나이트": job = "다크나이트(자각2)"; break;
        case "眞 크리에이터": job = "크리에이터(자각2)"; break;
        
        case "眞 뮤즈": job ="트렌드세터"; break;
        case "眞 트래블러": job ="셀레스티얼"; break;
        case "眞 헌터": job ="메이븐"; break;
        case "眞 비질란테": job ="프레데터"; break;
        
        
    }

    switch(job)
	{
		//귀검사 시작
		case "검신": case "眞 웨펀마스터":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검", "광검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "블러드 이블": case "眞 버서커":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "인다라천": case "眞 아수라":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "다크로드": case "眞 소울브링어":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		case "악귀나찰": case "眞 검귀":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
	
		case "트렌드세터": case "眞 뮤즈":
		{
			 weaponListTemp = ["선현궁"]
			weaponList = weaponListTemp;
			break;
		}
		case "셀레스티얼": case "眞 트래블러":
		{
			 weaponListTemp = ["장궁"];
			weaponList = weaponListTemp;
			break;
		}
        case "메이븐": case "眞 헌터":
		{
			 weaponListTemp = ["크로스슈터"];
			weaponList = weaponListTemp;
			break;
		}
        case "프레데터": case "眞 비질란테":
		{
			 weaponListTemp = ["에테리얼 보우"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		//귀검사 끝
		
		//여귀검사 시작
	
		
		case "마제스티": case "眞 소드마스터":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "네메시스": case "眞 다크템플러":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "검제": case "眞 베가본드":
		{	
			 weaponListTemp = ["소검", "도" ,"둔기", "대검", "광검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "디어사이드": case "眞 데몬슬레이어":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		case "벤데타": case "眞 블레이드":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
	
		//여귀검사 끝
		
		//여격투가 시작
		
		case "염제 폐월수화":
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "카이저":
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파", "권투글러브"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "용독문주": // 중호
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "얼티밋 디바": // 내계정
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		
		//여격투가 끝
		
		//남격투가 시작
		
		case "명왕":  case "眞 스트리트파이터": case "眞 스트리트 파이터":// 뱀파님
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		case "그랜드 마스터":  case "眞 그래플러": // 
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		case "패황":  case "眞 스트라이커":
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파", "권투글러브"];
			weaponList = weaponListTemp;
			break;
		}
		case "염황 광풍제월":  case "眞 넨마스터"://뱀파님
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		//남격투가 끝
		
		//남거너 시작
		case "프라임": case "眞 메카닉":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "디스트로이어":  case "眞 런처":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "레이븐":   case "眞 레인저":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;	
		}
		
		case "커맨더": case "眞 스핏파이어":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		case "엑스마키나": case "眞 어썰트":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
	
	
		//남거너 끝
		//여거너 시작
		case "스톰 트루퍼":
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		case "프레이야":
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		case "크림슨 로제":
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		case "옵티머스":
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		//여거너 끝
		
		//여마법사 시작
		case "아슈타르테": case "眞 배틀메이지":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		
	
		case "이클립스": case "眞 소환사":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "오버마인드": case "眞 엘레멘탈마스터":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "지니위즈": case "眞 마도학자":// 내 계정
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭", "빗자루"];
			weaponList = weaponListTemp;
			break;
		}
		case "헤카테": case "眞 인챈트리스":// 내 계정
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭", "빗자루"];
			weaponList = weaponListTemp;
			break;
		}
		//여마법사 끝
		//남마법사 시작
	
		case "아이올로스":
		case "眞 스위프트 마스터":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
					
		case "뱀파이어 로드":
		case "眞 블러드 메이지":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
			
		}
		
		case "오블리비언":
		case "眞 엘레멘탈 바머":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		case "이터널":
		case "眞 빙결사":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		case "어센션": // 내꺼든 중호꺼든
		case "眞 디멘션워커":
			
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭", "빗자루"];
			weaponList = weaponListTemp;
			break;
		}
		
		//남마법사 끝
		//남프리스트 시작
		case "세인트":  case "眞 크루세이더":// 내꺼
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		case "저스티스":  case "眞 인파이터":
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		case "태을선인":  case "眞 퇴마사": // ?
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		case "이모탈":  case "眞 어벤저": // 뱀파님
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		//남프리스트 끝
		//여프리스트 시작
		
		case "세라핌": 
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		case "리디머": case "眞 미스트리스":
		{
			weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
	
		case "천선낭랑":  case "眞 무녀":
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "인페르노": case "眞 이단심판관":
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		
		
		
		//여프리스트 끝
		//나이트 시작
		case "眞 엘븐나이트": case "가이아": // 내꺼
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		case "眞 카오스": case "마신": // ?
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "眞 드래곤나이트": case "드레드노트":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
	
		case "眞 팔라딘": case "세이비어":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		//나이트 끝
		//여도적 시작
		case "알키오네": case "眞 로그":// 중호
		{
			 weaponListTemp = ["단검", "쌍검" ,"완드", "차크라 웨펀"];
			weaponList = weaponListTemp;
			break;
		}
		case "시라누이": case "眞 쿠노이치": // 내꺼
		{
			 weaponListTemp = ["단검", "쌍검" ,"완드", "차크라 웨펀"];
			weaponList = weaponListTemp;
			break;
		}
		case "그림리퍼": case "眞 섀도우댄서":
		{
			 weaponListTemp = ["단검", "쌍검" ,"완드", "차크라 웨펀"];
			weaponList = weaponListTemp;
			break;
		}
		case "타나토스": case "眞 사령술사":// ?
		{
			 weaponListTemp = ["단검", "쌍검" ,"완드", "차크라 웨펀"];
			weaponList = weaponListTemp;
			break;
		}
		//여도적 끝
		//마창사 시작
		case "듀란달": case "眞 듀얼리스트": // ?
		{
			 weaponListTemp = ["장창", "미늘창" ,"광창", "투창"];
			weaponList = weaponListTemp;
			break;
		}
		case "워로드": case "眞 뱅가드":
		{
			 weaponListTemp = ["장창", "미늘창" ,"광창", "투창"];
			weaponList = weaponListTemp;
			break;
		}
		case "에레보스":  case "眞 다크 랜서": // ..?
		{
			 weaponListTemp = ["장창", "미늘창" ,"광창", "투창"];
			weaponList = weaponListTemp;
			break;
		}
		case "제노사이더":  case "眞 드래고니안 랜서": // 볼배님
		{
			 weaponListTemp = ["장창", "미늘창" ,"광창", "투창"];
			weaponList = weaponListTemp;
			break;
		}
		//마창사 끝
		//외전 시작
		
		case "크리에이터(자각2)": case "眞 크리에이터":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭", "빗자루"];
			weaponList = weaponListTemp;
			break;
			
			
		}
		case "다크나이트(자각2)": case "眞 다크나이트":// 볼배님
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검", "광검"];
			weaponList = weaponListTemp;
			break;
		}
		case "레퀴엠": case "眞 요원": // 참수님
		{
			 weaponListTemp = ["장도", "소태도" ,"중검", "코어블레이드"];
			weaponList = weaponListTemp;
			break;
		}
		case "언터처블": case "眞 트러블 슈터": // 참수님
		{
			 weaponListTemp = ["장도", "소태도" ,"중검", "코어블레이드"];
			weaponList = weaponListTemp;
			break;
		}
		case "패스파인더": case "眞 스페셜리스트": // 참수님
		{
			 weaponListTemp = ["장도", "소태도" ,"중검", "코어블레이드"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "갓파더": case "眞 히트맨":
		{
			 weaponListTemp = ["장도", "소태도" ,"중검", "코어블레이드"];
			weaponList = weaponListTemp;
			break;
		}
		
	}
    
    return weaponList;
}

function Weapon(props)
{
    /*
    
        <li className={(characterData['equipName0'] == props.name ? "on" : "" )}>
            <div className="imgt" onClick={()=>{change("equipName0", props.name)}}>
                <img  name={ props.name}  src={"img/item/" + lv105ItemId[ props.name] } loading="lazy"></img>
            </div>
        </li>
    */
    let number = weaponImage[lv105ItemId[props.name]];

    let x = 0;
    let y = 0;
    if(number != null)
    {
        x = (number-1) % 9;
        y = Math.trunc((number-1)/ 9) ;
    }
    
    return(
        
        <li className={classCheck('item100', props.name)} onClick={()=>{filterClick("item100",props.name)}}>
            <div className="imgt">
                <div  name={ props.name} className={"weaponSprite"} loading="lazy" style={{"width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"} }></div>
            </div>
        </li>
    )

}

function Lv105Weapon(props)
{
    /*
    
        <li className={(characterData['equipName0'] == props.name ? "on" : "" )}>
            <div className="imgt" onClick={()=>{change("equipName0", props.name)}}>
                <img  name={ props.name}  src={"img/item/" + lv105ItemId[ props.name] } loading="lazy"></img>
            </div>
        </li>
    */
    let temp = props.name;
    //console.log(temp);

    if(temp.includes("결전의 "))
    {
        temp = temp.split(' - ')[0];
    }
    //console.log(temp);

    let number = lv105WeaponImage[temp];

    //console.log(props.name)
    //console.log(number);

    let x = 0;
    let y = 0;
    if(number != null)
    {
        x = (number-1) % 9;
        y = Math.trunc((number-1)/ 9) ;
    }
    //console.log(x);
    //console.log(y);
    
    return(
        
        <li className={classCheck('item100', props.name)} onClick={()=>{filterClick("item100",props.name)}}>
            <div className="imgt">
                <div  name={ props.name} className={"weaponSprite"} loading="lazy" 
                style={{"background-image":"url('https://dundam.xyz/img/sprite/lv105WeaponImage.png')" , "width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"} }></div>
            </div>
        </li>
    )



}


function WeaponList(props)
{

    let weaponName = props.detail ? props.detail.replace("스탭","스태프") : "";
    weaponName = weaponName.replace("코어블레이드","코어 블레이드");
    let gender = "";

    if(weaponName == "")
    {
        return "";
    }
    //console.log(characterObj);
    if(DameageJobArray[jobtype].basejob.indexOf("격투가") != -1 || DameageJobArray[jobtype].basejob.indexOf("거너") != -1  || realgaksungname.indexOf("크루세이더")  != -1 )
    if(DameageJobArray[jobtype].basejob.indexOf("(남)") != -1)
    {
        if(!realgaksungname.indexOf("어썰트") != -1)
        {
            gender = "(남)";
        }
        
    }
    else if(DameageJobArray[jobtype].basejob.indexOf("(여)") != -1)
    {
        gender = "(여)";
    }
    
    return(

            <>  
            <ul class="ems-choice-wapon">
                
{/*                         
                <li className={(characterData['equipName0'] == "근원을 삼킨 "+weaponName ? "on" : "" )}>
                    <div className="imgt" onClick={()=>{change("equipName0","근원을 삼킨 "+weaponName)}}>
                        <img name={"근원을 삼킨 "+weaponName} src={"img/item/" + lv105ItemId["근원을 삼킨 "+ weaponName] } loading="lazy"></img>
                    </div>
                </li>
                <li className={(characterData['equipName0'] == "얼어붙은 저항의 "+weaponName ) ? "on":""}>
                    <div className="imgt" onClick={()=>{change("equipName0","얼어붙은 저항의 "+weaponName)}}>
                        <img name={"얼어붙은 저항의 "+weaponName} src={"img/item/" + lv105ItemId["얼어붙은 저항의 "+ weaponName] } loading="lazy"></img>
                    </div>
                </li>
                <li className={(characterData['equipName0'] == "광폭화된 전의의 "+weaponName ) ? "on":""}>
                    <div className={"imgt"} onClick={()=>{change("equipName0","광폭화된 전의의 "+weaponName)}}>
                        <img name={"광폭화된 전의의 "+weaponName} src={"img/item/" + lv105ItemId["광폭화된 전의의 "+ weaponName] } loading="lazy"></img>
                    </div>
                </li>
                <li className={(characterData['equipName0'] == "사멸하는 신뢰의 "+weaponName ) ? "on":""}>
                    <div class="imgt" onClick={()=>{change("equipName0","사멸하는 신뢰의 "+weaponName)}}>
                        <img name={"사멸하는 신뢰의 "+weaponName} src={"img/item/" + lv105ItemId["사멸하는 신뢰의 "+ weaponName] } loading="lazy"></img>
                    </div>
                </li>
                <li className={(characterData['equipName0'] == "내딛은 자의 "+weaponName) ? "on":"" }>
                    <div class="imgt"  onClick={()=>{change("equipName0","내딛은 자의 "+weaponName)}}>
                        <img name={"내딛은 자의 "+weaponName} src={"img/item/" + lv105ItemId["내딛은 자의 "+weaponName] } loading="lazy"></img>
                    </div>
                </li>
                    */}

                <Lv105Weapon name={"근원을 삼킨 "+weaponName }/>
                <Lv105Weapon name={"얼어붙은 저항의 "+weaponName }/>
                <Lv105Weapon name={"내딛은 자의 "+weaponName }/>
                <Lv105Weapon name={"광폭화된 전의의 "+weaponName }/>
                <Lv105Weapon name={"사멸하는 신뢰의 "+weaponName }/>
                <Lv105Weapon name={"결전의 "+weaponName+" - "+realgaksungname.replace('眞 ','')+gender }/>
                    {/* <li className={(characterData['equipName0'] == "결전의 "+weaponName+" - "+characterDataTemp['jinJob'].replace('眞 ','')+gender ? "on" : "") }>
                        <div className="imgt"  onClick={()=>{change("equipName0","결전의 "+weaponName+" - "+characterDataTemp['jinJob'].replace('眞 ','')+gender)}}>
                            <img name={"결전의 "+weaponName+" - "+characterDataTemp['jinJob'].replace('眞 ','')+gender} 
                            src={"img/item/" + lv105ItemId[ "결전의 "+weaponName+" - "+characterDataTemp['jinJob'].replace('眞 ','')+gender] } loading="lazy"></img>
                        </div>

                    </li> */}
            </ul>
            {/* <ul>
                <li className={(characterData['equipName0'] == "불가침의 영역 - "+ weaponName ? "on" : "" )}>
                    <div className={"imgt"}  onClick={()=>{change("equipName0","불가침의 영역 - "+ weaponName)}}>
                        <img name={"불가침의 영역 - "+ weaponName} src={"img/item/" + lv105ItemId["불가침의 영역 - "+ weaponName] } loading="lazy"></img>
                    </div>
                </li>
            </ul> */}
            <ul class="ems-choice-wapon">
                    
                <Weapon name={"火 : 불타는 고난의 "+weaponName}/>
                <Weapon name={"水 : 오염된 눈의 "+weaponName}/>
                <Weapon name={"木 : 그늘진 새벽의 "+weaponName}/>
                <Weapon name={"金 : 각인된 상처의 "+weaponName}/>
                <Weapon name={"土 : 따뜻한 봄날의 "+weaponName}/>
                <Weapon name={"부조화 : 무너진 경계의 "+weaponName}/>
                
            </ul>
            <div className="non-aggression-side">
                <ul class="only-non-agression">
                    <li className={classCheck('item100', "불가침의 영역 - "+ weaponName)} onClick={()=>{filterClick("item100","불가침의 영역 - "+ weaponName)}}>
                        <div className={"imgt"}  >
                            <img name={"불가침의 영역 - "+ weaponName} src={"img/item/" + lv105ItemId["불가침의 영역 - "+ weaponName] } loading="lazy"></img>
                        </div>
                    </li>
                </ul>
                <ul>
                    
                    <Weapon name={"火 : 넘어선 기억의 "+weaponName}/>
                    <Weapon name={"水 : 넘어선 기억의 "+weaponName}/>
                    <Weapon name={"木 : 넘어선 기억의 "+weaponName}/>
                    <Weapon name={"金 : 넘어선 기억의 "+weaponName}/>
                    <Weapon name={"土 : 넘어선 기억의 "+weaponName}/>
                    
                    
                </ul>
            </div>
        </>
    )
    
}

function Equip(props)
    {

        let temp = props.name;
        let number = lv105EquipImage[temp];

        //console.log(props.name)
        //console.log(number);

        let x = 0;
        let y = 0;
        if(number != null)
        {
            x = (number-1) % 9;
            y = Math.trunc((number-1)/ 9) ;
        }
        //console.log(props.slot);
        //console.log(y);

        return(
            <li className={classCheck('item10'+props.slot,props.name) } onClick={() => filterClick('item10'+props.slot,props.name)}>
                <div class="imgt">
                    <div  name={ props.name} className={"weaponSprite"} loading="lazy" 
                    style={{"zoom":"1.35714","background-image":"url('https://dundam.xyz/img/sprite/lv105EquipImage.png')"
                     , "width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"} }></div>
                </div>
            </li>
        )
    }

    
    function ItemList(props)
    {
        
        return(
            <li className={classCheck('item10'+props.slot,props.name) } onClick={() => filterClick('item10'+props.slot,props.name)}>
                <div class="imgt">
                    <img src={'https://dundam.xyz/img/item/'+lv105ItemId[props.name] }  />
                </div>
            </li>
        )
    }


export default DamageRank
