

import { createContext, useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom'


import React from 'react';

import {serverKR} from '../var/server';
import axios from 'axios'
import Ad from "../ad";



function Main(props){

    const [value, setValue] = useState("");
    const [nick, setNick] = useState("");
    const [server, setServer] = useState("all");
    const [noticeNum, setNoticeNum] = useState(0);
    const gradeToColor = {"중급":"#68D5ED","하급":"#8b8b8b","최하급":"#8b8b8b"};
    const handleOnClick = () => {
        navigate('search?server='+encodeURIComponent( server)+'&name='+encodeURIComponent( nick))
      };

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    
    const handleOnKeyPress = e => {
        if (e.key === 'Enter') {
            handleOnClick(); // Enter 입력이 되면 클릭 이벤트 실행
        }
    };

    const searchDelete = (order) =>
    {
        axios.post('dat/searchDelete.jsp?order='+order, {}, { cancelToken: source.token  })
        .then((base)=>{
            axios.post('/dat/mainData.jsp', {}, { cancelToken: source.token  })
            .then((base)=>{
            let copy = base.data;
            setMainDataBase(copy);


            })
        })
    }

    const mainClick =()=>{
        var tempElem = document.createElement('textarea');
        tempElem.value = 'secura1228@gmail.com';  
        document.body.appendChild(tempElem);

        tempElem.select();
        document.execCommand("copy");
        document.body.removeChild(tempElem);
        
        alert('메일 주소가 복사됐습니다.');
    }
    
    const kakaoClick =()=>{
        window.open('http://pf.kakao.com/_vdxlKxj/chat','kaakoDundam');
    }



    let [maindatabase, setMainDataBase] =  useState();

    useEffect( () => { 
      
        axios.post('/dat/mainData.jsp', {}, { cancelToken: source.token  })
        .then((base)=>{
          let copy = base.data;
          setMainDataBase(copy);


        })

        return ()=>{
            
            source.cancel();
        }
      },[]);
  

    let navigate = useNavigate();

    useEffect( () => { 
        var self = window;
        self.__VM = self.__VM || [];
        self.__VM.push(function (admanager, scope) {
            scope.Config.get('video').display('vm-av');
        scope.Config.verticalSticky().display();

        });

        return () =>
        {
            self.__VM = self.__VM || [];
            self.__VM.push((admanager, scope) => {
                admanager.removeAllPlacements();
                scope.Instances.pageManager.newPageSession();
            });
            
        };

            
    },[]);
  
    
    return (
        
        <div className="container main">
            <div id="rich-media-placement"></div>


            <div className="main-side">
                <div className="introduce">
                    <div className="main-logo">
                        <div className={"imgt " + (props.darkmodetype === 'dark' ? 'darklogo' : 'lightlogo') }> 
                        </div>
                    </div>
                    <div class="search">
                        <div class="searcbox">
                            <div class="searchbox_hug">
                                <div class="server" name="서버">
                                    <select id="server" name="server" value={server}  onChange={(event) => setServer(event.target.value)}>
                                        <option value="all">전체</option>
                                        <option value="adven">모험단</option>
                                        <option value="cain">카인</option>
                                        <option value="diregie">디레지에</option>
                                        <option value="siroco">시로코</option>
                                        <option value="prey">프레이</option>
                                        <option value="casillas">카시야스</option>
                                        <option value="hilder">힐더</option>
                                        <option value="anton">안톤</option>
                                        <option value="bakal">바칼</option>
                                    </select>
                                </div>
                                <div class="searchbox" name="검색창">
                                <input type="text" class="form-control" placeholder="캐릭터명" name="name" maxlength="20" value={nick} onKeyPress={handleOnKeyPress} onChange={(event) => {setNick(event.target.value)}}></input>
                                </div>


                                <div class="searchbutton" name="검색버튼">
                                    <button onClick={()=>{navigate('search?server='+encodeURIComponent( server)+'&name='+encodeURIComponent( nick))}}>

                                    </button>
                                </div>

                            </div>
                        </div>
                        <div class="searchadd">
                            <div class="searchadd_hug">
                                <div class="schadd-tit">
                                    <span>최근 검색</span>
                                </div>
                                <div class="scadd-box">
                                    <div class="scadbox">
                                        {
                                            ////console.log(maindatabase && maindatabase.cookie)

                                        }
                                        <ul>
                                            {
                                                maindatabase && maindatabase.cookie.map((a, i) => 
                                                {
                                                    return(
                                                    <li>
                                                        <div class="scb_clickside" onClick={()=>{navigate('search?server='+serverKR[a.name.split(' - ')[0]]+'&name='+a.name.split(' - ')[1])}} >
                                                            <span class="scb_sever">{a.name.split(' - ')[0]}</span>
                                                            <span class="scb_name">{a.name.split(' - ')[1]}</span>
                                                        </div>
                                                        <div class="scb_deleteside">
                                                            <span class="scb_delete" onClick={()=>searchDelete(a.order)}></span>
                                                        </div>
                                                    </li>
                                                    )

                                                })
                                            }

                                            
                                        </ul>
                                    </div>
                          
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            {/* <div class="bot-notifi">
                <div class="bnot-con">
                    <div class="bont-head">
                        <div class="b-head-mark">
                        <span>!</span>
                        </div>
                    </div>
                    <div class="bnot-text">
                        <span> 서버 점검중 입니다 17:00 ~ 24:00  </span>
                    </div>
                </div>
            </div>  */}
            <div class="bot-side">
                <div class="tgcv">
                    <div class="today-grade">
                        <ul class="notice-tit">
        
                            <li class="titlen"> 
                                오늘의 등급
                            </li>
                            <li class="upd"> 
                                { maindatabase && maindatabase.date }
                            </li>
                        
                        </ul>
                        <ul class="tgd-rt">
                            <li>
                                {
                                maindatabase ? 
                                <span style={gradeToColor[maindatabase.grade] != null ? {"color": gradeToColor[maindatabase.grade]} : {}}>
                                    { maindatabase && maindatabase.grade }
                                </span> :<></>
                                }
                                
                            </li>
                        </ul>
                    </div>
                    <div class="instv-ad">
                   	 <div class='vm-placement' id='vm-av' data-format='isvideo'></div> 
                    </div>
                    
                </div>
                <div class="noti-cover">
                    <div class="mnotice" name="업데이트 사항 탭">
                            
                        <ul class="notice-tit">
        
                            <li class="titlen"> 
                                공지 / 업데이트사항
                                <span class="new"></span>
                            </li>
                        
                        </ul>
                        <ul class="accordion" >

                            {
                                maindatabase ? maindatabase.notices.map((a,i)=>
                                {
                                    return(
                                    <li class="item" name="업데이트 사항 구분" onClick={()=>setNoticeNum(i+1)}>
                                        <h2 class={"accordionTitle " + (i+1 == noticeNum ? "accordionTitleActive" : "" )}  name="업데이트 사항 타이틀">
                                            {a.title}
                                            <span class={"accIcon " + (i+1 == noticeNum ? "anime" : "" )} ></span>
                                        </h2>
                                        <div class={"text " + (i+1 == noticeNum ? "show" : "" )} name="업데이트 사항 내용"> 
                                        {a.notice.split('\\n').map(el => {
                                            return <>{el + '\n'}</>;
                                        })}
                                        </div>
                                    </li>

                                    )
                                }) : ""
                            }

                            {
                            /* <li class="item" name="업데이트 사항 구분">
                                <h2 class="accordionTitle" name="업데이트 사항 타이틀">
                                    04/13 수정 내용
                                    <span class="accIcon"></span>
                                </h2>
                                <div class="text" name="업데이트 사항 내용"> 
                                    쿠노이치 - 화염의 인 화상 데미지의 얼녹 공식에 오류가 있어 수정했습니다.
                                </div>
                            </li>
                            <li class="item" name="업데이트 사항 구분">
                                <h2 class="accordionTitle" name="업데이트 사항 타이틀">
                                    03/18 수정 내용
                                    <span class="accIcon"></span>
                                </h2>
                                <div class="text" name="업데이트 사항 내용"> 
                                    소환사 - 블랙 루나틱 탈리스만 오적용 수정.
                                    {'\n'}
                                    헌터, 비질란테 - 지역버프 코드 누락으로 인한 금룡 딜 오차 수정.
                                    {'\n'}
                                    장비변경 - 불가침 착용시 105레벨로 고정.
                                    {'\n'}
                                    옭아매는 공포 - 일부 스킬의 오적용 수정.
                                    {'\n'}
                                    남그래플러 - 피어스 탈리 계산식 변경
                                    {'\n'}
                                    기존{")"} 쿨 * 쿨감 / 쿨회복 - 4.5
                                    {'\n'}
                                    변경{")"} (쿨 * 쿨감 - 4.5) / 쿨회복 
                                </div>
                            </li>
                            <li class="item" name="업데이트 사항 구분">
                                <h2 class="accordionTitle" name="업데이트 사항 타이틀">
                                    얼녹 계산식 변경 안내
                                    <span class="accIcon"></span>
                                </h2>
                                <div class="text" name="업데이트 사항 내용"> 
                                    변환딜이 복리가 아닌 단리로 적용돼 수정했습니다.
                                    {'\n'}{'\n'}
                                    또한 기존 얼녹 딜 계산에 문제가 있어 추가적으로 조정했습니다.
                                    {'\n'}
                                    기존 : 화상딜 * 변환증뎀 * 조정값 * 1.1
                                    {'\n'}
                                    변경 : 화상딜 * (1 - 화상시간 - 1.5 / 화상시간) + 화상딜 * (변환증뎀 + 0.1) * (화상시간 - 1.5 / 화상시간) 
                                    
                                </div>
                            </li>
                            <li class="item" name="업데이트 사항 구분">
                                <h2 class="accordionTitle" name="업데이트 사항 타이틀">
                                    장비 특성에 대해
                                    <span class="accIcon"></span>
                                </h2>
                                <div class="text" name="업데이트 사항 내용"> 
                                    분석 및 연구가 필요해 적용에 시간이 걸릴 것 같습니다.
                                    {'\n'}
                                    현재는 [강력한 일격](최종뎀 증가), [명상](쿨감) 만 적용중입니다.
                                    {'\n'}
                                    최대한 빠르게 적용할 수 있도록 노력하겠습니다.
                                </div>
                            </li>
                            <li class="item" name="업데이트 사항 구분">
                                <h2 class="accordionTitle" name="업데이트 사항 타이틀">
                                    02/22 업데이트 안내 (연장)
                                    <span class="accIcon"></span>
                                </h2>
                                <div class="text" name="업데이트 사항 내용"> 
                                    10:00 ~ 14:00
                                    {'\n'}
                                    -업데이트 내용-
                                    {'\n'}
                                    장비특성 수정 및 적용
                                    {'\n'}
                                    아이템 텍스트 변경 적용
                                    
                                </div>
                            </li> */}
                 
                        </ul>
                   
                    </div>
                </div>
                <div class="kakao_qna">

                    <div class="cta__button kakao" onClick={kakaoClick}>
                    </div>
                    <div class="cta__button gmail" onClick={mainClick}>
                    </div>
                    <div class="hn">
                    오류와 건의사항 문의
                    </div>
                </div>

            </div>
            
            <Script src="js/notice.js"/>
            
        </div>
        
    )
}


function Script(props) {

    //재활용 하려면 src를 받아야하는거고
    //src 

    const script = document.createElement("script");
    useEffect(() => {
      script.src = props.src;
      script.async = true;
      document.body.appendChild(script);

    },[]);
  

    return (<></>);
  }


    

export default Main
