
import { createContext, useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Outlet } from 'react-router-dom'


function Script(props) {

    //재활용 하려면 src를 받아야하는거고
    //src
    window.srcTemp = window.srcTemp || [];
      
    useEffect(() => {
        if(window.srcTemp.indexOf(props.src) == -1)
        {
            if(props.src.indexOf("cp.edl.co.kr")  == -1)
            { 
                const script = document.createElement("script");
                script.src = props.src;
                script.async = props.async;

                window.srcTemp.push(props.src);
            
                document.body.appendChild(script);
                
                return ()=>
                {
                    document.body.removeChild(script);
                    window.srcTemp = [];
                }
            }
            else
            {
                // const script = document.createElement("script");
                // script.src = props.src;
                // script.async = props.async;

                // window.srcTemp.push(props.src);
            
                // console.log(document.currentScript);
                // document.querySelector('.coupangTemp').innerHTML ="";
                // document.querySelector('.coupangTemp').appendChild(script);
                // script.onload =(event)=>{
                //     console.log(document.querySelector('.coupangTemp center'));
                //     document.querySelector(".coupang").appendChild(document.querySelector('.coupangTemp center'));
                // }

                
            }

        }
    },[]);
   
  

    return (<></>);
  }

  export default Script;