
import { createContext, useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Outlet ,Navigate} from 'react-router-dom'
import Radio from "../utill/Radio.js";
import RadioJob from "../utill/Radio_job.js";
import {serverKR} from "../var/server.js";


import React from 'react';

import axios from 'axios';
import Ad from "../ad";
import BannerAd from "../BannerAd";

function BuffRank(){

    let navigate = useNavigate();


    let [buffbase, setBuffbase] = useState();
    let [buffrankerbase, setBuffRankerbase] = useState();
    let [bufftype, setBufftype] = useState(1);

    
    const params = new URLSearchParams(window.location.search);
    let pageParam = params.get('page') != null ? params.get('page') : 1;
    let typeParam = params.get('type') != null ? params.get('type') : 3;
    let realgaksungnameParam = params.get('job') != null ? params.get('job') : "j1" ;
    let lovetypeParam = params.get('favor') != null ? params.get('favor') : "l2" ;


    let [page, setPage] = useState(pageParam);
    const [type, setType] = useState(typeParam);
    const [jobtype, setJobType] = useState(realgaksungnameParam);
    const [lovetype, setLoveType] = useState(lovetypeParam);

    let rankingArray = [0,0,0,0,0,0,0,0];
    let rankinglistArray = [0,0,0,0,0,0,0,0,0,0];
    let [listedit,setListEdit] = useState(1);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    
    let temp = "";
    for(var [key, value] of params.entries()) {
        if(key != 'page' 
            && key != 'type'  
            && key != 'job'  
            && key != 'baseJob'  
            && key != 'favor'  
            )
        {
            temp += '&'+key+ '='+ value; 
        }
    }
    
    if(window.location.href.indexOf("#") != -1)
    {
        temp += "#"+window.location.href.split("#")[1];
    }
    

    useEffect( () => { 
        window.history.replaceState("",null,
        '?page='+page+'&job='+jobtype+'&type='+type+'&favor='+lovetype + temp );
        

      axios.post('/dat/bufferRankingData.jsp?page='+page+'&job='+jobtype.replace('j','')+'&type='+type+'&favor='+lovetype.replace('l',''), 
      {}, { cancelToken: source.token  })
      .then((base)=>{

        let copy = base.data;
        setBuffbase(copy);
      })

      
      return ()=>{
            
            source.cancel();
        }
    },[type,page,jobtype,lovetype]);

    
    useEffect( () => { 
        
        
        var self = window;
        self.__VM = self.__VM || [];
        
        self.__VM.push(function (admanager, scope) {
            scope.Config.get('billboard').display('ad-container-2');
            scope.Config.get('desktop_takeover').display('ad-container-1');
            scope.Config.verticalSticky().display();
    
        });

        return () =>
        {
            self.__VM = self.__VM || [];
            self.__VM.push((admanager, scope) => {
                admanager.removeAllPlacements();
                scope.Instances.pageManager.newPageSession();
            });
            
        };
            
    },[]);

    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     (window.top).__vm_add = (window.top).__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {
    //         //console.log("mounting2", placement); 

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358a82bf161d63ec3c8e8a");
    //         document.querySelector("#ad-container-2").appendChild(placement);
    //         (window.top).__vm_add.push(placement);
    //     });

            
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         //console.log("unmounting2", placement);
    //         (window.top).__vm_remove.push(placement);

    //     };

            
    // },[]);

      

    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     (window.top).__vm_add = (window.top).__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {
    //         //console.log("mounting", placement); 

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358a82bf161d63ec3c8e8a");
    //         placement.setAttribute("data-display-type", "hybrid-banner");
    //         document.querySelector("#ad-container-1").appendChild(placement);
    //         (window.top).__vm_add.push(placement);
    //        
    //     });
            
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         //console.log("unmounting", placement);
    //         (window.top).__vm_remove.push(placement);


    //     };

            
    // },[]);

    
    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     window.__vm_add = window.__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358aa395a6d454fcf66d11");
    //         document.querySelector("#rich-media-placement").appendChild(placement);
    //         window.top.__vm_add.push(placement);
    //     });
            
    //     return() => { 
    //         (window.top).__vm_remove_category = (window).__vm_remove_category || [];
    //         (window.top).__vm_remove_category.push("richmedia_all");

    //         //console.log("unmounting", (window.top).__vm_remove_category);

    //     };



            
    // },[]);

    



    return (

        
        <div className="ranking">
            <div id="rich-media-placement"></div>
            <div id="ad-container-1" />
            <div className='rkcont buffrank container' id="content-container">
            {
            /*
                <Table striped>
                <thead>
                    <tr>
                    <th>순위</th>
                    <th>닉네임</th>
                    <th>서버</th>
                    <th>스탯</th>
                    <th>공격력</th>
                    <th>점수</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    buffbase && buffbase.ranking.map((a, i) =>
                    {
                        //console.log(a);
                        return(
                        
                        <tr key={i}>
                        <td>{buffbase.ranking[i].rank}</td>
                        <td>{buffbase.ranking[i].nick}</td>
                        <td>{buffbase.ranking[i].server}</td>
                        <td>{buffbase.ranking[i].stat}</td>
                        <td>{buffbase.ranking[i].attack}</td>
                        <td>{buffbase.ranking[i].score}</td>
                        </tr>
                        )
                    }
                    )  
                    }
                </tbody>
                </Table> */
                }
      
        {/* <button onClick={ () =>{ setPage(page+1)} }> page Up
        </button>

        <button onClick={ () =>{ setPage(page-1 <= 1 ? 1 : page-1 )} }> page Down
        </button>
                 */}
                 
                <div className="rktb drank" name="랭킹 선택 창">
                    <div className="ch">

                        <div className="ch_rk tble" name="버프 랭킹 선택 '각성선택'">
                            <div className="th">
                                <div className="tit_hug">
                                    <span>랭킹</span>
                                </div>
                            </div>
                            <div className="td">
                                <div className="radio_hug">
                                    <Radio name="Stat" value="1" present={type} 
                                    setType={setType}>
                                    스탯
                                    </Radio>
                                    <Radio name="Attack" value="2" present={type}
                                    setType={setType}>
                                    공격력
                                    </Radio>
                                    <Radio name="Point" value="3" present={type}
                                    setType={setType}>
                                    점수
                                    </Radio>
                                </div>
                            </div>
                        </div>

                        <div className="ch_job buff tble" name="버프 랭킹선택 '직업'">

                            <div className="th">
                                <div className="tit_hug">
                                    <span>직업</span>
                                </div>
                            </div>
                            <div className="td">
                                <div className="radio_hug">
                                 
                                    <Radio name="JAll" value="j1" present={jobtype} 
                                    setType={setJobType}>
                                    전체
                                    </Radio>
                                    <RadioJob name="JAl2" value="j2" present={jobtype} 
                                    setType={setJobType}>
                                    眞 크루세이더 (여)
                                    </RadioJob>
                                    <RadioJob name="JAl3" value="j3" present={jobtype} 
                                    setType={setJobType}>
                                    眞 크루세이더 (남)
                                    </RadioJob>
                                    <RadioJob name="JAl4" value="j4" present={jobtype} 
                                    setType={setJobType}>
                                    眞 인챈트리스
                                    </RadioJob>
                                    <RadioJob name="JAl5" value="j5" present={jobtype} 
                                    setType={setJobType}>
                                    眞 뮤즈
                                    </RadioJob>

                                </div>
                            </div>

                        </div>
                        <div className="ch_love tble" name="버프 랭킹 선택 '편애'">
                            <div className="th">
                                <div className="tit_hug">
                                    <span>편애</span>
                                </div>
                            </div>
                            <div className="td">
                                <div className="radio_hug">
                                    <Radio name="Love1" value="l1" present={lovetype}
                                    setType={setLoveType}>
                                    O
                                    </Radio>
                                    <Radio name="Love2" value="l2" present={lovetype}
                                    setType={setLoveType}>
                                    X
                                    </Radio>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
              
                <div className="rklist drank">

                    <div className="rk-ranker" name="랭커 소개">
                        <div className="rker-dq">
                            {
                                page == 1 && buffbase && rankingArray.map((a, i) =>
                                    
                                    {

                                        return(
                                            
                                        <div className="rker-card" key={i}>
                                            <div className="rkerc-num">
                                                <span className="rkerc-numbering">{buffbase.ranking[i].rank}</span>
                                                <span className="medal-img">
                                                    <div className="imgt">
                                                    </div>
                                                </span>
                                            </div>
                                            <div className="rkerc-abata"  onClick={() => navigate('../character?server='+buffbase.ranking[i].server+'&key='+buffbase.ranking[i].key)}>
                                                <div className="imgt">
                                                    <img src={
                                                    'https://img-api.neople.co.kr/df/servers/'+buffbase.ranking[i].server+'/characters/' +buffbase.ranking[i].key+ '?zoom=1.jpg&temp='+ Math.round(Math.random() * 10000)
                                                    
                                                    }  />
                                                </div>
                                                <div className="rkerc-round">
                                                    
                                                </div>
                                            </div>
                                            <div className="level rker-lv">
                                                <span className="fpic">
                                                    <div className="imgt">
                                                    </div>
                                                </span>
                                                <span className="val">{buffbase.ranking[i].fame}</span>
                                            </div>
                                            <div className="rker-sever">
                                                <div className="rkrsv">
                                                    <span className="svname">{serverKR[ buffbase.ranking[i].server ]}</span>
                                                </div>
                                            </div>
                                            <div className="rker-urname">
                                                <div className="rkrur">
                                                    <span className="urname">{buffbase.ranking[i].nick}</span>
                                                </div>
                                            </div>
                                            <div className="rker-point">
                                                <div className="rkerp">
                                                    <div className="rkwepn">
                                                        <span className="weapon" name="착용 무기">
                                                            <div className="imgt">
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div className="rkpo">
                                                        <span>{buffbase.ranking[i].score}</span>
                                                    </div>
                                
                                                </div>
                                            </div>
                                        </div>
                                        )
                                    }
                            )}
                        </div>
                    </div>
                    <div className="rk-table buff" name="랭킹표">
                        <div className="rkt-thead" name="랭킹표 th">
                            <div className="rkt-tr">
                                    <div className="th rking">순위</div>
                                    <div className="th name">닉네임</div>
                                    <div className="th stat">스탯</div>
                                    <div className="th attack">공격력</div>
                                    <div className="th point">점수</div>

                            </div>
                        </div>
                        <div className="rkt-tbody buff" name="랭킹 검색 tbody">
                        {
                            buffbase && buffbase.ranking.map((a, i) =>
                            {
                                return(

                                <div className="rkt-tr" key={i}>

                                    <div className="td rking">
                                        <span>{buffbase.ranking[i].rank}</span>
                                    </div>                                
                                    <div className="td name" onClick={() => navigate('../character?server='+buffbase.ranking[i].server+'&key='+buffbase.ranking[i].key)}>

                                        <span className="abata"> 
                                            <div className="imgt">
                                            <img src={
                                            
                                            'https://img-api.neople.co.kr/df/servers/'+buffbase.ranking[i].server+'/characters/' +buffbase.ranking[i].key+ '?zoom=1.jpg&temp='+ Math.round(Math.random() * 10000)
                                            
                                            }  />

                                            </div>
                                        </span>
                                        <span className="nik">{buffbase.ranking[i].nick}</span>
                                        <div className="tb_fsev">
                                            <div className="level rker-lv">
                                                <span className="fpic">
                                                    <div className="imgt">
                                                    </div>
                                                </span>
                                                <span className="val">{buffbase.ranking[i].fame}</span>
                                            </div>
                                            <div className="rker-sever">
                                                <div className="rkrsv">
                                                    <span className="svname">{serverKR[ buffbase.ranking[i].server ]}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="td stat"><span>{buffbase.ranking[i].stat}</span></div>
                                    <div className="td attack"><span>{buffbase.ranking[i].attack}</span></div>
                                    <div className="td point"><span>{buffbase.ranking[i].score}</span></div>


                                </div>
                                
                                )  
                            }
                        )}
                        </div>
                    </div>

                    <div className="page-guide">
                        <div className="list">
                                <ul className="list-number">
                                    <li className="back" onClick={()=> {setListEdit((listedit) <= 1 ? 1 : (listedit)-10)}}>
                                        <span>
                                        </span>
                                    </li>

                                {
                                   page &&  rankinglistArray.map((a,i) => 
                                    {
                                        return(
                                        
                                                <li key={i} className={page == listedit+i ? "is-active" : ""} onClick={()=>{setPage((listedit)+i)}}><span>{(listedit)+i}</span></li>
                                            
                                                
            
                                            
                                        )
                                    }
                                    
                                    )
                                }
                                <li className="next" onClick={()=> {setListEdit((listedit) + 10)}}>
                                    <span>
                                    </span>
                                </li>


                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>

            <div id="ad-container-2" >
            </div>
        </div>
        
    )}



    



      

      


export default BuffRank
