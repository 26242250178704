
import { createContext, useEffect, useState } from 'react';
import { Routes, Route, Link, useNavigate, Outlet , useLocation  } from 'react-router-dom'

import {names,speNames,accNames,lv105OptionMap,lv105WeaponOptionMap,lv105CustomOptionMap} from '../var/editVar';
import list from '../var/itemVar';
var characterDataTemp = {};
var [characterData, setCharacterData] = [null,null];


function Edit()
{
    let equipArray = Array.from({length: 13}, (v, i) => i);
    let navigate = useNavigate();
    let [navigateValue,setNavigateValue] = useState();
    const location = useLocation();
    const character = { ...location.state };
    let characterObj = character.character;
    [characterData, setCharacterData] = useState();
    ////console.log(characterObj);

    //선언부
    
    for(let loop = 0 ; loop < characterObj.equip.length ; loop++)
    {
        let equip = characterObj.equip[loop];
        characterDataTemp['equipName'+loop] = equip.name;
        characterDataTemp['equipReinforceNum'+loop] = equip.reinforceNum.replace('+','');
        characterDataTemp['equipReinforceType'+loop] = equip.reinforceType;
        characterDataTemp['equipEnchant'+loop] = equip.enchant;
        let count = 0;
        for(let lvLoop of  equip.lv105Leveling.split(' <br> ')[0].replace(/ \/ /gi,',').split(','))
        {
            characterDataTemp['equipLevel'+loop +'C'+(count++)] = lvLoop;
        }
        characterDataTemp['siroco'+loop] = equip.siroco;
        //characterDataTemp['equipOption'+loop] = equip.siroco;
    }
    for(let loop = 0 ; loop < characterObj.avatars.length ; loop++)
    {
        let ava = characterObj.avatars[loop];
        characterDataTemp['aba'+loop] = ava.name; //레어,캐스팅 속도 14.0% 증가,s17,s17
        
        //characterDataTemp['equipOption'+loop] = equip.siroco;
    }

    for(let loop = 0 ; loop < characterObj.talismans.length ; loop++)
    {
        let talisman = characterObj.talismans[loop];
        characterDataTemp['talis'+(loop+1)] = talisman.talisman;
        characterDataTemp['rune'+(loop*3+1)] = talisman['rune1'];
        characterDataTemp['rune'+(loop*3+2)] = talisman['rune2'];
        characterDataTemp['rune'+(loop*3+3)] = talisman['rune3'];
        
        //characterDataTemp['equipOption'+loop] = equip.siroco;
    }
    //console.log(characterDataTemp);

    
    useEffect( () => { 
        setCharacterData(characterDataTemp);
    },[]);

    
    //console.log(characterData);
    /*
    useEffect( () => { 
    change()
    },[characterDataTemp]);
    */
    return(
        <div>

            
            <section id="character" name="캐릭터 정보" style={{display:'inline-block',
              width:'100%',
              height:'2000px'}}>
                <div style={{display:'inline-block',
                width:'100%',
                height:'200px',
                margin:'auto'}}>
                </div>
                <div style={{display:'inline-block',
                width:'100%',
                height:'200px',
                marginLeft:'45%'}}>
                    <div>
                        {characterObj.adventure}
                    </div>
                    <div>
                        
                        <button onClick={() => allEquipLvAllChange(60)}> all equip 60</button>
                        <button onClick={() => allEquipLvAllChange(70)}> all equip 70</button>
                        <button onClick={() => allEquipLvAllChange(80)}> all equip 80</button>
                        {
                        equipArray.map((a, i) =>
                            {
                                if(characterData != null)
                                {
                                return(
                                <div key={i}>
                                    
                                    <div>
                                        <div class="wimg" style={{display:'inline-block',
                                            width:'30px',
                                            height:'30px'}}>
                                            <img src={'https://img-api.neople.co.kr/df/items/'+list[characterData['equipName'+i]]}  />
                                        </div> 
                                        +{characterData['equipReinforceNum'+i]} {characterData['equipReinforceType'+i]}  {characterData['equipName'+i]} 
                                         
                                        {characterData['equipLevel'+i+'C0'] != "" ? 
                                         characterData['equipLevel'+i+'C0']+','+characterData['equipLevel'+i+'C1'] +','+ characterData['equipLevel'+i+'C2'] +','+characterData['equipLevel'+i+'C3'] 
                                        : "" }
                                        
                                    </div>

                                    <div style={{'white-space': 'pre-line'}}>
                                    {
                                        lv105OptionMap[characterData['equipName'+i]] != null ?  
                                        lv105OptionMap[characterData['equipName'+i]][0].replace(/<br>/gi,'\n').split("@@")[0] : "" //1옵션 
                                        
                                    }
                                    <br/>
                                    <br/>
                                    {
                                        
                                        lv105OptionMap[characterData['equipName'+i]] != null ?  
                                        lv105OptionMap[characterData['equipName'+i]][1].replace(/<br>/gi,'\n').split("@@")[0] : "" //2옵션
                                    }
                                    <br/>
                                    <br/>
                                    {
                                        
                                        lv105OptionMap[characterData['equipName'+i]] != null ?  
                                        lv105OptionMap[characterData['equipName'+i]][2].replace(/<br>/gi,'\n').split("@@")[0] : "" //3옵션
                                    }
                                    <br/>
                                    <br/>
                                    {
                                        lv105OptionMap[characterData['equipName'+i]] != null ?  
                                        lv105OptionMap[characterData['equipName'+i]][3].replace(/<br>/gi,'\n').split("@@")[0] : "" //4옵션

                                        //밑에는 레벨 조정

                                    }
                                    </div>


                                    <input type='number' min={0} max={20} value={characterData['equipReinforceNum'+i]} onChange={(event) => event.target.value <= 20 ? change('equipReinforceNum'+i,event.target.value) : 20}></input>
                                    <input type='range' min={0} max={20} value={characterData['equipReinforceNum'+i]} onChange={(event) => event.target.value <= 20 ? change('equipReinforceNum'+i,event.target.value) : 20}></input>
                                    <button onClick={() => change('equipReinforceType'+i,"강화")}> 강화</button>
                                    <button onClick={() => change('equipReinforceType'+i,"증폭")}> 증폭</button>
                                    
                                    <br/>
                                    
                                    <button onClick={() => lvAllChange('equipLevel'+i,80)}> all 80</button>
                                    <input type='number' min={1} max={80} value={characterData['equipLevel'+i+'C0']} onChange={(event) => event.target.value <= 80 ? change('equipLevel'+i+'C0',event.target.value) : 80}></input>
                                    <input type='range' min={1} max={80} value={characterData['equipLevel'+i+'C0']} onChange={(event) => event.target.value <= 80 ? change('equipLevel'+i+'C0',event.target.value) : 80}></input>
                                    
                                    <input type='number' min={1} max={80} value={characterData['equipLevel'+i+'C1']} onChange={(event) => event.target.value <= 80 ? change('equipLevel'+i+'C1',event.target.value) : 80}></input>
                                    <input type='range' min={1} max={80} value={characterData['equipLevel'+i+'C1']} onChange={(event) => event.target.value <= 80 ? change('equipLevel'+i+'C1',event.target.value) : 80}></input>
                                    
                                    <input type='number' min={1} max={80} value={characterData['equipLevel'+i+'C2']} onChange={(event) => event.target.value <= 80 ? change('equipLevel'+i+'C2',event.target.value) : 80}></input>
                                    <input type='range' min={1} max={80} value={characterData['equipLevel'+i+'C2']} onChange={(event) => event.target.value <= 80 ? change('equipLevel'+i+'C2',event.target.value) : 80}></input>
                                    
                                    <input type='number' min={1} max={80} value={characterData['equipLevel'+i+'C3']} onChange={(event) => event.target.value <= 80 ? change('equipLevel'+i+'C3',event.target.value) : 80}></input>
                                    <input type='range' min={1} max={80} value={characterData['equipLevel'+i+'C3']} onChange={(event) => event.target.value <= 80 ? change('equipLevel'+i+'C3',event.target.value) : 80}></input>
                                    
                                </div>
                                )
                                }
                            }
                        )
                        }
                    </div>
                    <div style={{width:'900px',height:'1000px', display:'inline-flex'}}>
                    <div style={{width:'300px' }}>
                        {
                        names.map((a, i) =>
                            {
                                if(i < 22)
                                {
                                    return(
                                        
                                            <div style={{width:'300px', display:'inline-flex'
                                            }}>
                                            <span class="wimg" style={{display:'inline-block',
                                                width:'30px',
                                                height:'30px'}}>
                                                <img src={'https://img-api.neople.co.kr/df/items/'+list[names[i]] }  onClick={() => change('equipName2',names[i])} />
                                            </span> 
                                            <br/>
                                            
                                            
                                            <span class="wimg" style={{display:'inline-block',
                                                width:'30px',
                                                height:'30px'}}>
                                                <img src={'https://img-api.neople.co.kr/df/items/'+list[names[i+22]]} onClick={() => change('equipName3',names[i+22])} />
                                            </span> 
                                            <br/>
                                            
                                            
                                            <span class="wimg" style={{display:'inline-block',
                                                width:'30px',
                                                height:'30px'}}>
                                                <img src={'https://img-api.neople.co.kr/df/items/'+list[names[i+22*2]]} onClick={() => change('equipName4',names[i+22 * 2])}  />
                                            </span> 
                                            <br/>

                                            
                                            <span class="wimg" style={{display:'inline-block',
                                                width:'30px',
                                                height:'30px'}}>
                                                <img src={'https://img-api.neople.co.kr/df/items/'+list[names[i+22*3]]} onClick={() => change('equipName5',names[i+22 * 3])} />
                                            </span> 
                                            <br/>

                                            
                                            <span class="wimg" style={{display:'inline-block',
                                                width:'30px',
                                                height:'30px'}}>
                                                <img src={'https://img-api.neople.co.kr/df/items/'+list[names[i+22*4]]} onClick={() => change('equipName6',names[i+22 * 4])} />
                                            </span> 
                                            <br/>
                                        
                                            </div> 
                                        
                                    )
                                }
                            } 
                        )
                        }
                    </div>

                    <div style={{width:'300px' }}>
                        {
                        accNames.map((a, i) =>
                            {
                                if(i < 22)
                                {
                                    return(
                                        
                                            <div style={{width:'300px', display:'inline-flex'
                                            }}>
                                            <span class="wimg" style={{display:'inline-block',
                                                width:'30px',
                                                height:'30px'}}>
                                                <img src={'https://img-api.neople.co.kr/df/items/'+list[accNames[i]]} onClick={() => change('equipName7',accNames[i])}  />
                                            </span> 
                                            <br/>
                                            
                                            <span class="wimg" style={{display:'inline-block',
                                                width:'30px',
                                                height:'30px'}}>
                                                <img src={'https://img-api.neople.co.kr/df/items/'+list[accNames[i+22]]} onClick={() => change('equipName8',accNames[i+22 * 1])} />
                                            </span> 
                                            <br/>
                                            
                                            
                                            <span class="wimg" style={{display:'inline-block',
                                                width:'30px',
                                                height:'30px'}}>
                                                <img src={'https://img-api.neople.co.kr/df/items/'+list[accNames[i+22*2]]} onClick={() => change('equipName9',accNames[i+22 * 2])} />
                                            </span> 
                                            <br/>

                                            </div> 
                                        
                                    )
                                }
                            } 
                        )
                        }
                    </div>

                    <div style={{width:'300px' }}>
                        {
                        speNames.map((a, i) =>
                            {
                                if(i < 22)
                                {
                                    return(
                                        
                                            <div style={{width:'300px', display:'inline-flex'
                                            }}>
                                            <span class="wimg" style={{display:'inline-block',
                                                width:'30px',
                                                height:'30px'}}>
                                                <img src={'https://img-api.neople.co.kr/df/items/'+list[speNames[i]]} onClick={() => change('equipName10',speNames[i+22 * 0])} />
                                            </span> 
                                            <br/>
                                            
                                            <span class="wimg" style={{display:'inline-block',
                                                width:'30px',
                                                height:'30px'}}>
                                                <img src={'https://img-api.neople.co.kr/df/items/'+list[speNames[i+22]]} onClick={() => change('equipName11',speNames[i+22 * 1])} />
                                            </span> 
                                            <br/>
                                            
                                            
                                            <span class="wimg" style={{display:'inline-block',
                                                width:'30px',
                                                height:'30px'}}>
                                                <img src={'https://img-api.neople.co.kr/df/items/'+list[speNames[i+22*2]]} onClick={() => change('equipName12',speNames[i+22 * 2])} />
                                            </span> 
                                            <br/>

                                            </div> 
                                        
                                    )
                                }
                            } 
                        )
                        }
                    </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

/**
변수 변경 ex) 장비 이름 변경시 change('equipName0','불가침의 영역 - 소검');
@param {*} name 변경할 변수 이름 - equip : name,level,reinforceNum,reinforceType,enchant
@param {*} value 변경 값 
*/
function change(name,value,index = 0)
{
    
    let temp = {...characterData};

    if(index == 0)
    {
        temp[name] = value;
    }
    
    setCharacterData(temp);

}

/**
장비 한 부위 모든 레벨 수정
@param {*} name 'equipLevel0' ~ 'equipLevel12' 
@param {*} value '원하는 레벨값'
*/
function lvAllChange(name,value)
{
    let temp = {...characterData};

    for(let loop = 0 ; loop < 4 ; loop++)
    {
        temp[name+'C'+loop] = value;
    }
    
    setCharacterData(temp);

}

/**
전체장비 모든 레벨 수정
@param {*} value '원하는 레벨값'
*/
function allEquipLvAllChange(value)
{
    
    let temp = {...characterData};
    for(let loop = 0 ; loop < 13 ; loop++)
    {
        if(loop == 1) continue;

        for(let lvloop = 0 ; lvloop < 4 ; lvloop++)
        {
            temp['equipLevel'+loop+'C'+lvloop] = value;
        }
    }
    
    setCharacterData(temp);

}


export default Edit;
