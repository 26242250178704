
console.log("아이템 코드");
var list ={};

list['결의의 전쟁 부츠'] = '54434a07c7440d002caf63348564e792';
list['무결한 마음'] = '4a2c34a19752cc33ea05719a1a1af22b';
list['무한한 영감'] = '6a9b0de6edb0cf582458e60e4d4e0ad4';
list['무회'] = '99595a440c8bc4d4e3ecd2b31bcb9ae8';
list['미지의 골격 링'] = 'c5f00b9187c24835bdee14435ad0e60b';
list['미지의 관절 신발'] = 'ab074e516b0ecdbb6a2a51ac9c6fdd6f';
list['미지의 근섬유 상의'] = '630cee68d05a48d75241a2001fa1e420';
list['미지의 금속 각반'] = 'ccb37b54a27cce218ebfb9f0330cb77e';
list['미지의 기계 장치 벨트'] = '5d7f67ad1702fb304e99ad183a246ae1';
list['미지의 눈동자 네클리스'] = '88a2212d316e3961c647ffd60b437dad';
list['미지의 바이탈 시그널'] = '8499c250c24f9d8f53016d0f6870d669';
list['미지의 보석 숄더'] = '899f1f5e8a407d8bf933a53b3fe97a9f';
list['미지의 비늘 암릿'] = 'b585194bfa558e19d8d0f60be4a9f0b5';
list['미지의 에너지 스피어'] = '8b84ff87d5622d23c662f8b1c3ba9174';
list['미지의 파워 사이드'] = 'ded72f0a644f4635622f9d13295ae5ef';
list['순례자를 억압하는 시련'] = '21a80519f85b65c37ac649993feef9fd';
list['순례자를 얽매는 고난'] = 'cc7b53fb9baf760f36a676093fed4e9c';
list['순례자를 짓누르는 무게'] = 'b8612c9cb653813d5374509814c8302a';
list['순례자의 길을 비추는 빛'] = '54647a0b31116f5de1280cbe8ac4c331';
list['순례자의 나무 요대'] = '6a566161ad56225743a4a963f0c98988';
list['순례자의 나아가는 의지'] = '74121e6767adeff56c70aebc4d33951d';
list['순례자의 미래를 보는 시선'] = 'fbc47f171d1c439a6facb8556b6c760b';
list['순례자의 사막 부츠'] = 'ce0ad0fc10f1a250fa65b04064bc6fd0';
list['순례자의 어깨 보호대'] = 'fc1f196449a6cb926c3cb5d5ed34311f';
list['순례자의 카고 바지'] = 'b6c01a4d3c205f2efd69f1e854807dc4';
list['순례자의 해진 바람 막이'] = 'c1e0b48b93bfa5f2827061e436d8eae3';
list['신뢰의 황금 복대'] = '0c6f3021af852992193db36566518f34';
list['연합의 지휘자 망토'] = 'f48b32aeb5d299962d22594c330659e5';
list['전략가의 정교한 전투 바지'] = '9c2146d90b3e650fcebeca17933191a4';
list['조화의 이치'] = 'af771a91c229122b3e063f0ba9742404';
list['지휘관의 명주 상의'] = 'c36306968cbf790e10a71788db04eabc';
list['총사령관의 결단'] = '2aa9f3d419d4fbaa00aa105e60bac5dc';
list['총사령관의 독도법 나침반'] = '256e2b481f5219ee3cb0f06bd50a8914';
list['총사령관의 비장의 한발'] = '49741786ddd9f079e4e5ad031b495829';
list['총사령관의 섬멸 작전'] = '5d20e2e5b42a7d1c4c4b91f00548540c';
list['총사령관의 의무'] = 'abbc3650c2f9c8d339de83f1d6013b70';
list['총사령관의 전술 지도'] = 'f5c175720b3cb28f324a6ba587ee3987';
list['총사령관의 전우가 남긴 유품'] = '5d7769bb38e6ce5a3ceac9b27472972a';
list['총사령관의 제복 상의'] = '50ad1eb38bbd4eba87cdaeba1d9cde6a';
list['총사령관의 제복 하의'] = '2cb99a73be07bcb60d475f0d486609cf';
list['총사령관의 지휘'] = '5b83936113ac64b18c31a4f4a6908119';
list['총사령관의 초심'] = 'c668543905d27252dd1e54850593ba2f';
list['포지 코어 펜던트'] = '11407b3eb8e4290c674061ddf02de3ef';
list['흔적이 새겨진 돌'] = 'bf9650ac2229688d3e00aaed8dd513ed';
list['희생으로 맞이한 여명'] = '34cdd4e7dec539fb66392dafc73eab81';
list['흙으로 빚은 자연'] = '28c36859083988e74ef3da8109085a67';
list['흑화의 구속 팔찌'] = '5ca915f03d8a5a625465366e58bdc67f';
list['흑룡 : 주시하는 용의 눈동자'] = '27843216918f310d010f1014661c81bd';
list['흑룡 : 어두운 힘의 근본'] = 'db3a4579b25e7dbdca33f56195ce259f';
list['흑룡 : 끝없는 암흑의 굴레'] = '1fd082cd996a9eb02645be09c5e89d41';
list['황혼의 성단'] = '7b590f832a97ee8eacc17a54e3fe2d4a';
list['화음하는 음색'] = 'fbd0ead8da84de2d9200184d3139f9af';
list['화룡 : 폭발하는 힘의 근원'] = '0e79738a52d19e87cf910fc16537887b';
list['화룡 : 타오르는 불의 분노'] = '6f388dd09d7149044abfd92fed63b4a6';
list['화룡 : 끓어오르는 용의 숨결'] = '73f46c965770a5680576ad52219da4a5';
list['화려한 청색의 음율'] = '08192ecf513c7a8b18063be883a6356b';
list['홀로그램 콜'] = 'e9eeb7d5eee037dd736aa65a590d31aa';
list['혼돈을 두른 장막'] = '07d1f5aea662f1b0b8eae7317b6924d9';
list['헥타곤 임펄스 브릭'] = '4907ea859a1e0624dfd5edec21c655ac';
list['하이테크 전술지휘 아머'] = '1eca6016d59310cbd35123bfd5014615';
list['하이테크 전술보조 각반'] = '084129f7a8aaa34e9ad0dd52ea3a6422';
list['하이테크 서플라이 벨트'] = '3402f770e2fe1a9a60d71be7d9d43834';
list['하이테크 바디 프로텍트 숄더'] = '003ba9ed5b693c349cad3fb7c2ad42ff';
list['하이테크 고기동 강화 부츠'] = '416dadb6dac6678654b9ecfc9788851a';
list['하늘을 수호하는 윙 부츠'] = '039682c96bb1e461e20f0669fde8ef91';
list['하늘에 휘날리는 깃털'] = '50f6813155b213d33ce36306c911b34f';
list['핏빛의 결정 반지'] = '1a17f4ecc09e755ede2b92d8ad399654';
list['피어오르는 광기'] = '50e34031bfb458d7a43ed56d2a36db1d';
list['피어나는 자연의 삶'] = '3e63f9705e6592a52814f399c85e37d4';
list['플래티넘 클래스 재킷'] = '01f668b6a225cfd1681a1ebfc40245fe';
list['풍운과 안개를 걷는 빛'] = '09df23f5ef3e5d7f6e20e5e11eb2c60c';
list['푸른 자연의 씨앗'] = '44dfc7da6982301231dcd9ee9c22e979';
list['폭풍을 삼킨 에너지'] = 'c0ef77f4ab5c758765326bf5a8b8f23f';
list['폭주하는 육신'] = '8a9e32d0f3870cbb7a7cb3b0285ab143';
list['폭발형 : 소형 전술 플레어'] = '3fc32317db0aa13b835cc2d598cbd57b';
list['폭룡왕의 지배 - 압제'] = '4bac78be2b1a6d41b7cb5bee6a9cd70b';
list['폭룡왕의 지배 - 무력'] = 'f70b1f49079b2f4ed80cb6b3f15c6af6';
list['폭룡왕의 지배 - 공포'] = 'c9478e22a6d836cdce8a73cf61d2ec64';
list['포인트 레이더 암릿'] = 'e762baddf99f8501ef911af68eea533a';
list['포식 : 헤집어진 상처'] = '5432b890b5d2f750c67951a8817c4823';
list['포식 : 추적하는 사냥꾼'] = '06a75e0f3cf3b8ad358289b3faf79303';
list['포식 : 조여오는 올가미'] = '3ce72504c5b4353c5fb82a74e7ddebaf';
list['포식 : 자비없는 습격'] = '18ddc7fb78f1230f14ee4654cc22cc20';
list['포식 : 사그라진 고동'] = '33e8f7122831fa045f6e84313da64e31';
list['평화를 위한 투쟁'] = '5e082f686e62f05d6630303ddc92d955';
list['평화를 수호하는 윙 레깅스'] = '0a14a9ba60a35c72d29e84df2dea0c89';
list['퍼스트 스페이드 - 노블레스'] = '76497fb8b232f1a56e96de7511ed2b0d';
list['파워 플랜트'] = '32ca7d7da53d0e9b2aafacfb5ec57dcf';
list['파워 네비게이트 팩'] = '8d0bdc6feed6adc7e74c2201292dec93';
list['파괴된 신념'] = '0d22229f6e5f7332a4be95f19a152b76';
list['파괴된 생명'] = '7f807013fd7ffcb5cd7cfd82bf984c52';
list['테크놀로지 바디캠 네클리스'] = '426bf11b7f8aa69b2565da4be9b59de1';
list['터치 컨트롤 패널'] = '3bab4b93da1f7d2fcc6da6c98015eae1';
list['탐험가의 노련한 코트'] = '05eff736fa865f538981e7a3ea3bfd67';
list['타락한 영혼'] = 'b16348f86d01e99a541a2af2a21a015b';
list['쿨링 서포트 벨트'] = '485e441bbfacd1b843c4c9c3c3cb7027';
list['컨퓨즈드 코어 슈트'] = '012e5eaf2e5c3db2e9cb777c56d72fa4';
list['침식되는 이성'] = '2766b493412bd6d14191ee3471c3a8e3';
list['초소형 GPS'] = '4fce013abf06e5834a391e150732f410';
list['청명한 아침의 새싹'] = 'e65dbf7aff427f7714696a2b3cf01417';
list['천지를 흔드는 석갑'] = '1bdd1170b2edbe9b241c3592cd0030b4';
list['천재 기술자의 전문 작업복 상의'] = '074aba3de9a06488cba89bcd74ca4c66';
list['천재 기술자의 보호 마스크'] = '435b31746441376a525d943d1e5fcf39';
list['천재 기술자의 멀티툴 벨트'] = '12fde00e523a6e1e3a389a27d08b1655';
list['천재 기술자의 멀티박스 팬츠'] = '3a90df330ecc42b3aa3ceca52c5b94cd';
list['천재 기술자의 두터운 보호부츠'] = '5fc5efd565cdd61e59361fe71ed5f6e1';
list['천상을 수호하는 윙 아머'] = '24f1e7fb637a63f9c755dc926e4d8f63';
list['천계 연합군 : 밝게 빛나는 희망'] = 'f0683ab71a21f9166ccb3423fa0a928c';
list['천계 연합군 : 모든 이를 위한 기도'] = '3b62a91abc21df1a2609af53cb15d432';
list['천계 연합군 : 꺾이지 않는 의지'] = 'cb41d2e497f1a7ac1ae2711670da9861';
list['찰랑이는 생명수'] = '19324f712cecd5acf393a66395cecfc7';
list['찰나를 스치는 흐름'] = 'd3b603c289e9e4dfcfe1b4da7ce9b4ab';
list['찬란한 금장 팔찌'] = 'd93969035f2f366e4bac3beffb260c02';
list['진룡 : 잔혹한 용의 발톱'] = '568018f4d9ed013f352d412331ab67ba';
list['진룡 : 압도하는 힘의 원천'] = 'cc64795c07f57cb9e52967063276f795';
list['진룡 : 무거운 철의 투지'] = '6255f92b7b56a7ddd76f566a47ada800';
list['지치지 않는 여정의 하의'] = '01d323c464b2e620b17f32c37f028e52';
list['지상형 : 전술 차륜 드론'] = '55c669c5fb7a64523cd22273e1e1581a';
list['죽음을 부르는 관'] = '8590a244ae403f30491ed6e62563e7ba';
list['죽음에 잠식된 갑주'] = '17e2b07728ff9a4ba2ba0bf62a97e7f8';
list['주체할 수 없는 낡은 규칙'] = '2ec6efb126d88e85da31c34bb2b71b6f';
list['죄어오는 풍화된 악의'] = '2df3b394f3d4b03153524b2d22502c78';
list['죄악의 지배 : 태초가 선사하는 공포'] = '5f8b29a66f1c13f6e3a47169af5cc598';
list['죄악의 지배 : 절망을 뒤덮는 공포'] = '47b419903a0b8542416e528e1079e29e';
list['죄악의 지배 : 절규가 매달리는 공포'] = '49e7e73cd1ec8573ed0c0ba9c127b350';
list['정의의 기사 가면'] = '5ba03f421362470ea60f590418d7ce6e';
list['절망의 발소리'] = '62ce0161bd947bf034dfe128fdbd305a';
list['절대감각의 방위구'] = '9f86e896dc473690eaa072bd093325f5';
list['전술 레이더망 링'] = 'e30f4eaf0037b5b9f748213a14dc26f5';
list['전술 드론 콘트롤러 암릿'] = 'b08e68947d0bbd53e50d6c5165d22ff1';
list['저주받은 마음'] = '46059223889628399c07dbb53ec4f3a1';
list['저주받은 굴레'] = 'ce1cf7f9b7c8a799800af70201d0d04c';
list['잿빛의 묘상석'] = 'bb36704c7fcdf607177c80b28267cf41';
list['잠겨진 영역'] = 'd6fd0607a7a56808824dfcd65267862c';
list['잔잔한 선율'] = '9e6946fd3d88ef7d33f1c5d983064929';
list['작은 풀잎의 순수함'] = '5e221b9892f817f3cbaa14f730d7ec3f';
list['자정의 성역'] = '9ba8b68cf83caca35308e90dbadcfec0';
list['자유를 수호하는 윙 숄더'] = '03beca8db56d3881b0724f276e76804d';
list['자연에 녹아드는 이슬'] = '37522f61ef37c5b5251900c3345af96a';
list['자신감의 백색 망토'] = '434b4f5693710959e0ee29d92933059d';
list['자기장 탐지링'] = '49ff8f4240b7f3ec42d6989f4fc57f09';
list['잊혀진 기록'] = 'ee22dd87e6196cb6f90bc18fd8250c0e';
list['임펄스 트리거'] = '88fce89d89665d2948dde039042c91bc';
list['일렉트릭 프루프 부츠'] = '4e2dc852a54d3c6c704b4b80188c6650';
list['인력의 법칙'] = '69e9b0361cbd23ad0bb17e2b25a2a1e2';
list['익스펜션 서플라이 벨트'] = '29ef2acd2a9c62873cd88499d01a1571';
list['이온화조정 팔찌'] = '474be2a11a50893461caddd14d383f1f';
list['이동하는 요새'] = 'bf82008ceba94e2933928b6460865370';
list['음율에 담은 소망'] = '913687be8275f7d2323434440673d653';
list['유쾌함 속의 열망'] = '2aebbccb5dedbbf41d5a484f01232c82';
list['원터치 스마트 리모콘'] = '001b114bde67db7c3f0dc31c69df6119';
list['움직이는 쇠약한 집착'] = '32eb0e0193b575d9abfee5d92ef05794';
list['운명이 이끄는 방향'] = 'ba244c69b95eefcfd4ab8feb85a89e83';
list['용살자의 증표 - 용심 가공석'] = '09db8d8812162f16cc31df0903af3ed4';
list['용살자의 증표 - 용린 귀걸이'] = 'e3b71bb48ef6f9d0b9f6c6d084a6bbd9';
list['용살자의 증표 - 용골 뿔피리'] = 'a16a3c99ec8fa5222816a51bf4bc6ef9';
list['완성형 동력 제어장치'] = '2849ef416e30404723f0f5689b9aaa2b';
list['옵티컬 컴뱃 글래스'] = 'bc4199484eedfd4282a2e31733fd8aef';
list['옭아매는 공포'] = '0d364fbe6c14575e23c927a85bffe11d';
list['올곧은 자의 이념'] = '52b530f45e9c9696573feb607ffefe4f';
list['올 오어 원 매직박스'] = '9bf4445165a20d7e5a2466b0dc6aec57';
list['옥화의 망령 흉갑'] = '30b66937d2112e5e344c70709dba078d';
list['옥화의 망령 요갑'] = '1de39f4a48b3524cf768479c8f4c7b19';
list['옥화의 망령 상박갑'] = '14e643db6d20c8f78f2cf417f5919716';
list['옥화의 망령 대퇴갑'] = '24db159d4b8a9470b1ef591e926c7f87';
list['옥화의 망령 각갑'] = '1984f8a7c0a85f9773d7a43b3230bc1e';
list['오퍼레이션 오더'] = '48543193774f538d227a61381acd329e';
list['오염된 빙석 견갑'] = '0288215d10faa4a1f3797cc2dc7b7bc3';
list['오버커런트 마그넷 링'] = '3987cd6773aa22eb97957dbf1de92285';
list['오랜 시간 품어온 소원'] = '84627aee4e995e9117796f1a9f28a0ed';
list['영혼의 구속 : 지배하는 권능'] = '332409131f5fcbb418bff7a0a5dd8a13';
list['영혼의 구속 : 무거운 업보'] = '6e1dc4f395dd54d7750ac47b2631518c';
list['영혼의 구속 : 거스를 수 없는 봉인'] = 'e0e357b5cce04625dc8b0538bd5c8d01';
list['영원을 끝낼 오랜 염원'] = '1854a7d3b785db5363030143746a4a29';
list['영면을 위한 준비'] = '5eb26df384a636f0598371415c6edf41';
list['여명의 성배'] = '64396f744a9abc7f125d85e384c07b9a';
list['엔트 정령의 하의'] = '01a493387c7dcbb2c66a1aa54b7354f7';
list['엔트 정령의 팔찌'] = '9e3029a34e4c0a4ec4b4a064cf1485e5';
list['엔트 정령의 어깨'] = '1a7240e61f61fd56e6a0106e68021032';
list['엔트 정령의 심장'] = 'a18edcc238802a9f6b3e494ea30ef1ca';
list['엔트 정령의 신발'] = '6ddc12ba86e14aaa9620cfc0eec38c57';
list['엔트 정령의 성배'] = '573399fba4997923936a56fe15d50d6f';
list['엔트 정령의 상의'] = '3067ecee4c801accfe4bb0ac87e125bd';
list['엔트 정령의 벨트'] = '0b097b274b5161c94a2d8ac0e4fa9b0f';
list['엔트 정령의 반지'] = '71be2ffba89052de0994976e173030c0';
list['엔트 정령의 목걸이'] = '53dff026d8600429a488ad25095b9692';
list['엔트 정령의 귀걸이'] = '2e1b235fd35aa26bda52427c07e795ec';
list['엔데카 코어 칩'] = '193e16cf78fb893d6d1942e9c2b53f93';
list['엑셀러레이터'] = '3f119d5fe09aece6b1af2170dc1f0ce3';
list['에너지 서치 링'] = 'd4a33a5e046bc02ba6bcf30d5acc4ae9';
list['얼티밋 제너레이터'] = '11b464f53794d4e94d1b674cc22cd28e';
list['얼터레이션 다이얼 벨트'] = '154e671453750d1926bf0577c9024708';
list['얼어붙은 저항의 핸드캐넌'] = 'e87c877258cb3332df2de47b51a1c912';
list['얼어붙은 저항의 투창'] = 'f067ceeee71fa0043951e63d0654c8be';
list['얼어붙은 저항의 통파'] = '879efcefcccbf9d3ac3e10de86a1101a';
list['얼어붙은 저항의 토템'] = '4346828ef37a59de03e23acfb22bd12f';
list['얼어붙은 저항의 클로'] = '68f2293dfc4082d20ea4db0cca59cce0';
list['얼어붙은 저항의 코어 블레이드'] = 'd3878e2e43915a2d261aa78425329150';
list['얼어붙은 저항의 창'] = '4d417208dfe59d33c94229fc833aea64';
list['얼어붙은 저항의 차크라 웨펀'] = '25049e5f6710ca788e9bfe80de96243d';
list['얼어붙은 저항의 중검'] = '669c065c6246ea32473f76d553386b0f';
list['얼어붙은 저항의 장창'] = '2990114431703591f3de76b8e2588a5d';
list['얼어붙은 저항의 장도'] = '6e3da1f981efe4d2484e45cc83886220';
list['얼어붙은 저항의 장궁'] = '26341e036783969742f28dc6162a1126';
list['얼어붙은 저항의 자동권총'] = 'c655a30bb0feb5fa291ed6e1f7aad66c';
list['얼어붙은 저항의 완드'] = '0a8a82bdf02854692d464d9e972c4e74';
list['얼어붙은 저항의 염주'] = '7d4bbbe48babe2e102c8db921a4c2566';
list['얼어붙은 저항의 쌍검'] = '6a29f0ba4f8b56ba563c0a815c8cc986';
list['얼어붙은 저항의 십자가'] = 'ccd204a27f657ff93889436ee63203e6';
list['얼어붙은 저항의 스태프'] = '37e55a21aac666db444646c09d9c664d';
list['얼어붙은 저항의 소태도'] = '7d14f0c255d9d9979ffd37ea5683f2d7';
list['얼어붙은 저항의 소검'] = 'be8d8bde9b6d9ab08086af30037036b1';
list['얼어붙은 저항의 선현궁'] = 'f7cddb905976c4ffcf0c4e1b7267ba11';
list['얼어붙은 저항의 빗자루'] = '007d4718131eabb2359f574c77d1fdf6';
list['얼어붙은 저항의 봉'] = '1f3fccd21542e2807bb4ffcb2376b89e';
list['얼어붙은 저항의 보우건'] = 'ae8e951c43768df267230dffe207674f';
list['얼어붙은 저항의 배틀액스'] = '3d2aca331b2f570727197e7bf91b4f5f';
list['얼어붙은 저항의 미늘창'] = 'e8591239f9ecc3acb3dcab4c59a5b5f6';
list['얼어붙은 저항의 머스켓'] = '81be4647ad0a711ceae810b1f4f9c97e';
list['얼어붙은 저항의 리볼버'] = 'ab0e09d7b9b7cae925a33a921952678b';
list['얼어붙은 저항의 로드'] = '94c0290190c17a3094ee326a248528f2';
list['얼어붙은 저항의 둔기'] = 'b20078a382471ccae6334261b9571ca2';
list['얼어붙은 저항의 도'] = '1749e1e16483211fedba20d38463c8a6';
list['얼어붙은 저항의 대검'] = '110c9b5864d309ad2f7a974fc1fae73c';
list['얼어붙은 저항의 단검'] = '5647b4e8e27a21a02659fb4393846b2b';
list['얼어붙은 저항의 너클'] = '749303a7afac95b807efc7f156776006';
list['얼어붙은 저항의 낫'] = '0fdc480ad4114b29fb1c56d0e64d71f2';
list['얼어붙은 저항의 권투글러브'] = 'd03717b42cc2ceedd01ad461cb2b46c1';
list['얼어붙은 저항의 광창'] = 'f89f5236a3b59f53a7408e1647df9b4d';
list['얼어붙은 저항의 광검'] = 'eff35eb35fc0739d01414567a64477d9';
list['얼어붙은 저항의 건틀릿'] = 'ffdeff0e78e7325e039c8b0ffa7a627b';
list['언비튼 메달'] = '9f30cf8ea43c6090797cb210d0d7723c';
list['언리밋 사이버네틱'] = '44c35b2563e55de97d8f765062fd5f65';
list['억제된 마력의 팔찌'] = 'fd19e43acb9bf998f94df0db7729be06';
list['억류된 혼의 령주'] = '05528a5369db4309b9b6791780c07a97';
list['어비스 리액터'] = '15c482a1117e69591170a56cb19974a1';
list['어릴적 꿈꿔온 무대'] = '08f0e19b36c8d5e4c4a9bd9f153c9deb';
list['어둠을 먹는 심장'] = 'ee401af5ff045eb29da3a5f6794bbdbb';
list['어둠에 삼켜진 돌굽'] = '1ff342e50d7e56dd0ddbd733b027b948';
list['어댑터블 투톤 링'] = '13ec4223a5649e83e09d95a87ab61cb4';
list['약동하는 생명의 고동'] = '4a2a60d8d9277044476939a0322f3d53';
list['압도하는 힘의 하갑'] = '42835caa0d5fd1f4f113f7acfee07ced';
list['악몽을 이겨낸 승전보'] = '011a747e591102a8e85c0546fb475051';
list['아픔을 감싸는 위엄'] = '0fc3bff272f60ed5d4d999f52366438c';
list['아토믹 코어 네클레스'] = '064715eb64c51a8cd840e001563219e3';
list['아크로매틱 룸버스'] = 'fbd153de08e38753316ba906bcd0a180';
list['아크 블라스터 소스'] = '7767981f839a0083a3d734199cb0deda';
list['아머드 파워 상의'] = '60d9ea0d86e5b69765f1798d654f78f4';
list['아득한 각인'] = '52ec0bfd326d5475b588c893fb08d34f';
list['썬더 레지스트 벨트'] = '0690d318a85803d80cc38993a9de0351';
list['심연의 결집 : 정의되지 않는 존재'] = '56422d257499f7c60c7dad02d0f43db2';
list['심연의 결집 : 무한한 수축'] = '2950e00eced48e19bf4c161b3bc7df7e';
list['심연의 결집 : 무정형의 힘'] = '1ba6221619f88691ed8f0c3adba4db7a';
list['신비함의 백색 구두'] = '6a0717652a47458a054758c61677a4fa';
list['시각의 관점'] = '922575ac85da5f999815024b6b2ec2a7';
list['승리로 이끄는 정의'] = '1790007e83e6f513e5bcfc51b8e8bfd9';
list['승리가 약속된 시간'] = '5e597f850be761e18324dd8627f31855';
list['스팀펑크 소닉 디스럽터'] = '482349c7923625405fc4ce3d91ada730';
list['스톰라이더'] = '137e00b2550a315e44eb5cf7d119aa0e';
list['슈페리어 : 코스믹 이어링'] = '70558b17d3d49fc8599f8e0d751bce9d';
list['슈페리어 : 오리진 오브 더 마이어'] = 'a6baa41de89c3a9bf280b65ca5b24b58';
list['슈페리어 : 매직 서클로'] = '323e201088055e1ed622be84b7479103';
list['쉐도우블랙 슈트'] = '5d2ff86601234a405197dfaa39253ad3';
list['숲속의 마녀 하의'] = '1d2ba73eb876ae3a51cd984957e3a8c6';
list['숲속의 마녀 팔찌'] = '2727b58be5152b2fba6d5a8c80337d5e';
list['숲속의 마녀 정령석'] = 'f53f14f757d1389fcdc2213f146e488b';
list['숲속의 마녀 신발'] = '20d7eb7c17183bff06758a2f6df63bb6';
list['숲속의 마녀 상의'] = '096631070847893ce9d522215b3c4054';
list['숲속의 마녀 벨트'] = '1077ecd6be9f88f0274ddeb54c996a4d';
list['숲속의 마녀 반지'] = 'a6b0fdcae5a5021307ed340c71a788e9';
list['숲속의 마녀 바구니'] = 'be6c1e5923222563027a392c859ae995';
list['숲속의 마녀 목걸이'] = '14a424fbc1bbd0b45e39732bbc065572';
list['숲속의 마녀 망토'] = '30f70bd3ff991f7343c166d8a8819eed';
list['숲속의 마녀 귀걸이'] = '089aa9cba8ce4d68f3101c827f3daa38';
list['숨쉬는 자연의 생명'] = '0aa9c73bdb86ed17356234f2c0af1fc2';
list['숨결을 삼킨 용옥'] = 'dab2cee8ec27e4b0962afe5ca7078a26';
list['순환하는 자연의 섭리'] = '04e57598f0ca64f8fd687e528c8718d5';
list['순수한 자연의 이치'] = '02465c3bbbf1757e04d79122a4703f2b';
list['수확하는 옥수'] = '75e882cdd821aa67748eb24ff1f5be59';
list['수호룡의 비호 - 축복'] = '1d31aaae6d692a76a6da48a79c66a3f9';
list['수호룡의 비호 - 자비'] = '96ebf9909c2782f9469c246ae87da26d';
list['수호룡의 비호 - 용기'] = '2238cdafe92a638f92eadb691d2256c2';
list['솔저 호너 네클리스'] = 'd860eff0b483a562e0b6886a93f09ec8';
list['손에 닿은 기억'] = '13de6a9bdef797aefc576a1c67dbee20';
list['소망을 향한 움직임'] = '0db7afd06a14268eb3ebeee3423ea30c';
list['소망을 전하는 편지'] = '5d33206011cb8668627b14a8f2845819';
list['세컨드 스페이드 - 어쏘러티'] = '8ead84aa6b74d38b4a851a001d28a0c9';
list['세계가 담긴 축'] = 'ec8ce99638ed1abccbc8012e02109a84';
list['성장하는 모험가의 흑요석 팬던트'] = '4746f1587697bbc9979797d347445079';
list['성장하는 모험가의 흑요석 귀걸이'] = 'a31c2d93d69c64edfbd7101e11c310dc';
list['성장하는 모험가의 훈장'] = '1159e9b9c1f374af60edc5746bc9ba79';
list['성장하는 모험가의 판금 허리'] = '1bfb16192b14392796bf81bab4cc0e86';
list['성장하는 모험가의 판금 하의'] = '2e3843eb7f2665f5fd0b9bd581d69e7f';
list['성장하는 모험가의 판금 어깨'] = '26a3f346f4d3c2d07fc2089c5e709fb1';
list['성장하는 모험가의 판금 신발'] = '2ed6324e765c8f639d7a9f5817b08c9c';
list['성장하는 모험가의 판금 상의'] = '85be7d6b87a69b458dda6dc72d791c4e';
list['성장하는 모험가의 천 허리'] = '8aa0e6de3ded4e1bd96415ce6cdff644';
list['성장하는 모험가의 천 하의'] = '6731c4bbb0a13055b060336f9a82674e';
list['성장하는 모험가의 천 어깨'] = 'e78b3b570225acb66ff6cba027773159';
list['성장하는 모험가의 천 신발'] = '4a86ddfe1341b6fc281615a7372b19bc';
list['성장하는 모험가의 천 상의'] = '5ea1ab1b07eb2eda87200cc7dd83c47c';
list['성장하는 모험가의 중갑 허리'] = '8d7d7d8f511f53c82f7cd12f354d3fa5';
list['성장하는 모험가의 중갑 하의'] = '71815b99ed125130316983391a5ffc1b';
list['성장하는 모험가의 중갑 어깨'] = '36bd393e7ef0c14566dbb2d25468279c';
list['성장하는 모험가의 중갑 신발'] = '88feb9644df095c5fbdc1642b3e77d1a';
list['성장하는 모험가의 중갑 상의'] = '546c0b50487963293285bcbb9c36d4b9';
list['성장하는 모험가의 인장 반지'] = '8ca6d486809b36bb94eb6a464a4ebbe2';
list['성장하는 모험가의 십자 자수 토시'] = '429ea115bb74308b9c2441784cc841de';
list['성장하는 모험가의 낡은 메달'] = '074990bbe6162ee6b0468bc7cca0321f';
list['성장하는 모험가의 경갑 허리'] = '80a6497189207feac657e0a1220fbc4e';
list['성장하는 모험가의 경갑 하의'] = '19527751a37c7142d902fa41a5fc79e2';
list['성장하는 모험가의 경갑 어깨'] = 'ac73a14bee50ace84e0df9c41678e579';
list['성장하는 모험가의 경갑 신발'] = '863be02978dea55ede869f8027b07382';
list['성장하는 모험가의 경갑 상의'] = '35830ed3f24dbb0a40efe4db1bdbd005';
list['성장하는 모험가의 가죽 허리'] = '1fd7066146e1ed36c1fc1ccf35e3effb';
list['성장하는 모험가의 가죽 하의'] = '3372e33385565ad56de79307c437a8bc';
list['성장하는 모험가의 가죽 어깨'] = '3778fb34f173289ec559bc4a0d9fa489';
list['성장하는 모험가의 가죽 신발'] = 'a1c5641bb8961ab2cbf30bbcf1032b08';
list['성장하는 모험가의 가죽 상의'] = '35c7ef4f3c95ae778a7a9a1c6caba319';
list['선회하는 흐려진 혜안'] = '2da044e940cf61a4d178790399ed478a';
list['서드 스페이드 - 데스'] = 'f245d1893a0380a456edd0844c332fb5';
list['생명이 담긴 잔'] = '3a2a4a8b1ab39b690ce28d875198e3b9';
list['생명이 담긴 가죽 토시'] = 'efc736cc907c60072bb92146ae006257';
list['생명이 깃든 초록빛 액체'] = '62ef7d57f74415849dc809a02916cc69';
list['생명의 근원이 담긴 배낭'] = 'c8aedd82444ed4c34ed038df8a2673c7';
list['생명을 키우는 코발트 스틱'] = 'd664db344fb2ab71bb13be9dd716cd42';
list['상처를 동여맨 다짐'] = '7852e3280ad4794eab35948937e8161b';
list['사이버틱 스피드 부츠'] = '088092996a8d1deaa9ed991383553ebd';
list['사멸하는 신뢰의 핸드캐넌'] = '1d394e3fe9357db2e25ea6f72b055fa8';
list['사멸하는 신뢰의 투창'] = '3c2ab144a4393cb3d995e71324300597';
list['사멸하는 신뢰의 통파'] = '8b177f87e19dd77e07f5f718df036683';
list['사멸하는 신뢰의 토템'] = '157fe7597ba71507d22deaa7904494a7';
list['사멸하는 신뢰의 클로'] = '42e58da97a81890214fcddcd31c1845a';
list['사멸하는 신뢰의 코어 블레이드'] = '9dddae23098f16e24baabeac1718d3be';
list['사멸하는 신뢰의 창'] = 'fe8b859ce8fb3de422266eb9ed638023';
list['사멸하는 신뢰의 차크라 웨펀'] = '55d4452026d872676e13d71e8b93e8b0';
list['사멸하는 신뢰의 중검'] = '0a07caeaab234b0da6462cbf8bd2fd19';
list['사멸하는 신뢰의 장창'] = '5ef1126a182518b617aba60163ecf48b';
list['사멸하는 신뢰의 장도'] = 'b41585776eab8404a00273e8ff0bdcef';
list['사멸하는 신뢰의 장궁'] = '37ced3ff14363a62e727f40055a37859';
list['사멸하는 신뢰의 자동권총'] = '0b22fd4183997ad8a45d468ffe9260a4';
list['사멸하는 신뢰의 완드'] = 'c644246a357e233276630f8f0f7b8eea';
list['사멸하는 신뢰의 염주'] = '7f9cb27330e5d285db0a4330766c3089';
list['사멸하는 신뢰의 쌍검'] = '22c1ca322b0215230fb1f8a1af00ac84';
list['사멸하는 신뢰의 십자가'] = 'dc0f8b9734ab0c1236fbd7cff016412a';
list['사멸하는 신뢰의 스태프'] = '9df3d9afc3cd3d6dd67f7bf63337d27b';
list['사멸하는 신뢰의 소태도'] = '02b8fe0f1731d5d63c29308d8c54a638';
list['사멸하는 신뢰의 소검'] = '944c114b30c6b4ca0e31af02f6580f0f';
list['사멸하는 신뢰의 선현궁'] = '005aa96c3e2ae344a8ba86625febbcec';
list['사멸하는 신뢰의 빗자루'] = '42cd2c08cc21224631dad9d64a15cfba';
list['사멸하는 신뢰의 봉'] = '4fed49aba835ad2459525fb4e88e8cd2';
list['사멸하는 신뢰의 보우건'] = '6d46c0f41674d0162ca3a298169b987a';
list['사멸하는 신뢰의 배틀액스'] = '0f19356167fe5e85729478ae6bb546df';
list['사멸하는 신뢰의 미늘창'] = '9bbe22c6f825015c5ef431d644562475';
list['사멸하는 신뢰의 머스켓'] = 'e7255895698ebfad170e105c56ce8dd3';
list['사멸하는 신뢰의 리볼버'] = 'a88de4d904c129edcae31c7eba68db6f';
list['사멸하는 신뢰의 로드'] = '426aed8519e9d7432d793526a234b323';
list['사멸하는 신뢰의 둔기'] = '8bc24f3b326da2ba31d06f3c48a1cb00';
list['사멸하는 신뢰의 도'] = 'db0e404a14826df37f0f054ab8427970';
list['사멸하는 신뢰의 대검'] = '3cac041bd6de56770fe8bf8ef295fd8d';
list['사멸하는 신뢰의 단검'] = 'e5cb1ed8be1529e9218a8087d57a3b38';
list['사멸하는 신뢰의 너클'] = '19d4b7857d2908823d798d051b3d9d82';
list['사멸하는 신뢰의 낫'] = 'bbe3cb4059f2472a1b4ab14debbaa0bf';
list['사멸하는 신뢰의 권투글러브'] = 'a0700fef2124261d3baec3dd86c75e1b';
list['사멸하는 신뢰의 광창'] = '097c3ce239a5794c872d49a7e3c5bc19';
list['사멸하는 신뢰의 광검'] = '3f9a8679d69773a3551139dcc5774af6';
list['사멸하는 신뢰의 건틀릿'] = 'af04ad69279e63657108d4f0b87dd0c1';
list['빛을 잃은 진실'] = '6de69cc379bcafdc904edba19cdea495';
list['빛을 발하는 생명'] = 'c9aaffefbd086af9cb9e54ffef487051';
list['빙화 : 피어난 꽃잎'] = '240853586bd8ae7296378e1a6b7745d5';
list['빙화 : 펼쳐진 동토'] = '0fd9a42095299db7c936b07544da52ac';
list['빙화 : 얽히는 서리'] = 'aac73f110fc4ef6963f083cde4fc9850';
list['빙화 : 살에는 빙설'] = '1452dd4dde85795b5bcd8e9bd13fcbe6';
list['빙화 : 가혹한 한기'] = '5cdf68192c0f5838a3f3f0c0bef41ab4';
list['블루 베릴 하의'] = '4a3100f32c0eb8ed71eac2d81228b91f';
list['블루 베릴 퍼퓸'] = 'e2ab555a05e8de69133e0bfa4ab5bfd1';
list['블루 베릴 젬스톤'] = 'a239e1e2c6d3074bc15bdb9bbac3abd1';
list['블루 베릴 이어링'] = '13784e929edd0cb8cfdcb38d629cfc79';
list['블루 베릴 암릿'] = 'a4d57b6413617409456b7fae42ba8023';
list['블루 베릴 아머'] = '26874c5e8731893d0510d19130b69c82';
list['블루 베릴 부츠'] = '370f6b09c57e68bc02850026255ddd97';
list['블루 베릴 보호대'] = '0b0e6d14ab144ca08c7c80ab71848056';
list['블루 베릴 벨트'] = '304f5bce8bff48088d9070625a4b1c3d';
list['블루 베릴 링'] = '61b11bf9de582675393ec342fac6b7b8';
list['블루 베릴 네클레스'] = 'd4611183c02aaf2c96da65125778985f';
list['블랙 캣 헬멧'] = '1cfffdb0f3fa619a94fb09caa743c5f5';
list['불변의 부유석 벨트'] = '4de4c9382425e6fb8931fb15d99b4818';
list['불길한 데칼코마니 석판'] = '4ebe756da29721b7a93c7752320de8a9';
list['부스팅 펄스 튜브'] = '43c511645914d15da2464c26ec863f27';
list['별을 담는 벨트'] = '0adff49f605d822d72d2afc73cbfa192';
list['벤타블랙 팬츠'] = '38680847217ae3b8d46abd30108eab42';
list['버츄얼 사이트 글래스'] = '0d416780271ff2281e5e8d023c8deafe';
list['배회하는 혼령의 향로'] = '0f63eccb7d33ad89e615c2da1239c874';
list['반짝이는 음율'] = 'b6df9f390bc57f5bcb2fd49a6065c536';
list['미지의 황금비석'] = 'ae71fa231a05101bf87dec168c442ce0';
list['미지의 문명 - 트윈 스톤'] = 'dd57a830c1b71e58aa9a33feae415f8f';
list['미지의 문명 - 스타 스톤'] = '56417dbb8db44ae21eba15ed38cee28f';
list['미지의 문명 - 마스크 스톤'] = '72e6886ad80d5ae4e6576105da6e03c0';
list['미련이 남은 녹슨 원혼'] = '2b536e222b63f749a6f25d5e17f04b59';
list['미니어쳐 헤드셋 암릿'] = '26384ca42de8c7b3487729d3d9e9868c';
list['미니 배터리 팩'] = '751c0ba167e2704956176b72e63fe89d';
list['무모하지만 합리적인 작전'] = 'df5b0caa0c120fe898d58d70e2d07ef9';
list['무뎌지는 둔화된 변화'] = 'cbd037a31b281da9b5064c9bae6df1c4';
list['무대의 화려함'] = '03bacfa3c23b56f447a5cd18851219f0';
list['무너진 세계의 틈'] = '52866d32d7aa2600b522783562cf109d';
list['메탈기어 암 숄더'] = '6cf0c3f15305048df3f881db85cf1877';
list['멈추지 않는 운명'] = '61276b1795ab47f1e8813b8600c01b5a';
list['멈추어진 지식'] = 'ff9aab6f5b64341bc6bd87259182b330';
list['머신 컨트롤러 리모트'] = 'b3b32a1eb7a209958c7835c8b42b0e34';
list['맹렬한 위세'] = '13e64a09c0c8b91949ec6dac3afb5687';
list['매니퓰레이션'] = '480452079c1c187796105e3790e595bc';
list['마지막 불의 숨결 : 핸드캐넌'] = 'a68f2eb348e65ac31ab11e041809f17a';
list['마지막 불의 숨결 : 토템'] = '621059c574f7c2a976067f3a5f5ea438';
list['마지막 불의 숨결 : 클로'] = '92f6abd8d83548a0fc7dd3ba635892e2';
list['마지막 불의 숨결 : 창'] = '61f54a83b7fa5e2335a03a595a2f5c6c';
list['마지막 불의 숨결 : 차크라 웨펀'] = '7d8d395d5e9f479ae20cd339e02f1b26';
list['마지막 불의 숨결 : 자동권총'] = '2b8f9b54a6332123c67a30bca925522e';
list['마지막 불의 숨결 : 완드'] = '35bfa750c05408029d3b9363af12008d';
list['마지막 불의 숨결 : 쌍검'] = '97baa49e1d223f846e9006cc89f0cf34';
list['마지막 불의 숨결 : 십자가'] = '51738482931b48d737d9df6344330f3a';
list['마지막 불의 숨결 : 스태프'] = '27c92b82e1c07336f990246b1574e7e9';
list['마지막 불의 숨결 : 소검'] = '56342553007c8993acd7dcd9bb82a20d';
list['마지막 불의 숨결 : 봉'] = 'bb2544e5bb14d9feecf4230f4c5fcc8d';
list['마지막 불의 숨결 : 머스켓'] = '710cc53835b4e8ad34d6b404c0b6306b';
list['마지막 불의 숨결 : 리볼버'] = '593d234f0bcc9af1e103aa1afd869536';
list['마지막 불의 숨결 : 로드'] = '2669667f12e09c71a4719b4f71cfab32';
list['마지막 불의 숨결 : 도'] = 'c3bff0bcb8ac26252d170fe6a99c627d';
list['마지막 불의 숨결 : 너클'] = '1b1ec4675fec5e6822f4de09d35ceef6';
list['마지막 불의 숨결 : 낫'] = 'a21f4c6ddeb45e9455cbd90b5cd9e034';
list['마지막 불의 숨결 : 권투글러브'] = '380b107c10a114170c42e356d24a9770';
list['마지막 불의 숨결 : 광검'] = 'dacc40505f4423162f47faf71e60562d';
list['마지막 불의 숨결 : 건틀릿'] = 'eea1084ef545f0439b477e128b02f92f';
list['마주할 수 없는 부러진 긍지'] = '3a41efcbfef0e112276c723115063a92';
list['마음을 전달하는 소리'] = 'f8399898174aa69bff247d87cea6a140';
list['마땅한 본분'] = '1751cf4529331916565fffaef9d9c2d7';
list['마그네틱 서치 스캔'] = '2eef901281c1b3194a4ef35f4796b6b9';
list['리플레이서'] = '2dc6d6233b142a632d4808a452f4c636';
list['리버시블 레더 코트'] = '293c809ed91a18f9ff1f50400a5ae86a';
list['로보티카 컴뱃 팬츠'] = '0106839ef87aba76428ec574c78b167c';
list['레드 라이프 링'] = 'b9e6fd403736597f1260662dc808121a';
list['라이트 어댑터 팬츠'] = '57f7365395686d8f23b78885ab1ab1bc';
list['뜨거운 열망의 증표'] = '363127da64902fd62e8c5fb9595a196e';
list['뚜렷해지는 소멸된 사념'] = '509508d2bdb68a333a7e095ab6f86011';
list['딥 다이버 팬츠'] = '0af27a16319b2c4c785b5f338ec68655';
list['딥 다이버 이어링'] = '93aacfaa1296200fc022bababcb68d5d';
list['딥 다이버 워치'] = 'c7629a5b062cf8131e4c3bfe6ca8d5ef';
list['딥 다이버 오리발'] = '289a755f5262c80cc04b20d58756ee45';
list['딥 다이버 에어팩'] = '021e7b3d6301e8ea9758e09346cf126f';
list['딥 다이버 슈트'] = '6f0af148b4a6b44f9f9810b4fc28fe5a';
list['딥 다이버 슈즈'] = '03ecfbbd75f59fe8454a229bb89a8562';
list['딥 다이버 벨트'] = '013ee730f9ebeb63df5f051e301c5b36';
list['딥 다이버 밸트'] = '894a396a5fd25b43790be007a737debc';
list['딥 다이버 마스크'] = '05c7d8fece7ee8469c76fa0e0cbf935e';
list['딥 다이버 네클레스'] = '7d0175039686c0a3e06665f95cdb3184';
list['딥 다이버 네비게이션 링'] = 'd48cae439d3d23c1d60885b45c7ff15b';
list['디젯 퓨즈 초크'] = 'fd5ffa03827b83e0109a3eb1862798eb';
list['들이치는 희미한 탄식'] = '5670a44246fc1610b6d1fef45f9041ac';
list['드러나는 흐릿한 지식'] = 'c622f49d9f7ab3f96f910ef2cea43613';
list['드래곤 헌터'] = '12498edcb8dbab9229c0024410b59fb2';
list['드래곤 패스파인더'] = '279bd2467bc32917733ac934a41ebddc';
list['드래곤 슬레이어'] = '02ec8b60e2cfa5d4c84d831329b70c4f';
list['드래곤 스케빈저'] = '0dc6f055906735f2f2eb23670e3284dd';
list['드래곤 라이더'] = '39429c89ffc72260381d75db915d6144';
list['두근거리는 열정'] = '1e7ade53a86f1d3589082304002ce4bc';
list['도데카 홀로그램'] = '2ed40a5e4bc69485f9acd316fc5891af';
list['데카 가이던스 디바이스'] = '9a66757f985f48fc84a8199f1855445f';
list['데저트 테크놀로지 팬츠'] = '252e944381ed054025d0c18fcdc9dd65';
list['데저트 컨실멘트 숄더'] = '59b756d84bf9478eaca58bed8f74762f';
list['대지를 수호하는 윙 벨트'] = '462d8d3f41b8815abb7c39a9f0dc5d08';
list['대지를 딛는 부츠'] = '527d176d03fae5626cd7c80ae0b53280';
list['눈물이 스민 보석'] = '817511895db6b80652ca041fdf1a423a';
list['뇌광 : 천둥을 품은 용심'] = '281c2640178dd5cbcc5c58b5f1bdcaa4';
list['뇌광 : 우레를 감싼 용익'] = '424a5caa037bcf098cb50b70bc3b3117';
list['뇌광 : 벼락을 감은 용조'] = 'b90db09edec467e4630ccf6426bd1d6c';
list['뇌광 : 번개를 담은 용린'] = 'b4f01254eea851567184b39616d40b6e';
list['뇌광 : 낙뢰를 내린 용각'] = '311f77c82a2c5d84e7ef85dc9599e75e';
list['네오 화이트 클래스 팬츠'] = '2154534c6906a4beead3a474a6a3a8b3';
list['냉혹한 현실의 목걸이'] = '689669772092589ba47a7d6adbb10023';
list['냉정한 지략가의 상의'] = '66ccc2d7c5eae9f95b69a71d44536492';
list['내면의 얼굴'] = 'a10fb66fa4ac53012cdce77fb3cbbeee';
list['내딛는 용기'] = '0b2087744bee0479b6847a97c7d7d2ab';
list['끝을 바라보는 시선'] = '074bfbabfb1869d7b65f71911a488ad4';
list['꿈같은 환호성'] = '148b87e4558b32884365ac2566cb2c6e';
list['깨지지 않는 빛바랜 신념'] = '1f4842f48c789643edfa8e2460c642c2';
list['기품의 금빛 장신구'] = '91e668c19b5b1957befc8f4ca4dd5098';
list['기억으로 새겨진 별자리'] = '3c60578ebe4d6ce2679543ffd64e3484';
list['기사의 속죄'] = '403dcc99cc1fb678bd7e191597e6ca0c';
list['기사의 긍지'] = '1160d523e09c57173a62f1b337eb6b67';
list['기사의 구원'] = 'df1ce86a441d7f8bd313c4ee3a720f2a';
list['금룡 : 화려한 힘의 기원'] = '5285d14664a9aa41559ca68f440c3909';
list['금룡 : 찬란한 용의 비늘'] = 'eeb469cdd183e41318837d2dbd98dd42';
list['금룡 : 무한한 빛의 결계'] = '52688ab6a97da9368e86343d0a3ac4a5';
list['근위대의 맹세 하갑'] = 'aafa34c4bef0583cfbe336cedef4a665';
list['근위대의 맹세 충심'] = '81d7f2a7d56b600356f343d5cdd6c622';
list['근위대의 맹세 지령서'] = 'c1e23b7e053f781152fe2e92f9c79623';
list['근위대의 맹세 증표'] = '4c601b1e1f0f8c1fddccd90ae4d38eb2';
list['근위대의 맹세 요갑'] = 'f2042ff7d303f6b44558be157e2b35a1';
list['근위대의 맹세 수갑'] = '7741aebd4cfba1b54440836704ae27b3';
list['근위대의 맹세 상박갑'] = '380a9f33e13bf185bdb3e10f4fb68651';
list['근위대의 맹세 목 보호대'] = '04720413b4adb4cddb7d90b66d25471f';
list['근위대의 맹세 등불'] = '59cb34cf4cb68fec6e94040f6ac3031a';
list['근위대의 맹세 갑주'] = 'eb17e589124c27d407fdd6993d205682';
list['근위대의 맹세 각갑'] = '0573652d493d3d4194516c0807e37139';
list['근원을 삼킨 핸드캐넌'] = '0615c137045d4eafc47fcb65decfa2ea';
list['근원을 삼킨 투창'] = 'a868646ab7f047097eb79a68fae37c9b';
list['근원을 삼킨 통파'] = 'b73de65023d879bcdb81d762c269f5df';
list['근원을 삼킨 토템'] = 'a4f4624a3387a0e163190394d7abc300';
list['근원을 삼킨 클로'] = '078ef4c689fdf4ffcf6ffcc2d09f5dae';
list['근원을 삼킨 코어 블레이드'] = '633712fc7d0d77297261be0359f2785e';
list['근원을 삼킨 창'] = 'ad4561247bf5b7e9a0c80dbaaa42c968';
list['근원을 삼킨 차크라 웨펀'] = '083ebd835ad489da04df5117c1e5fb08';
list['근원을 삼킨 중검'] = '12776c72a541cd265e4df92fb300c7b5';
list['근원을 삼킨 장창'] = '598028afca28c228faa4607406b28a63';
list['근원을 삼킨 장도'] = '9d92806f94e5a4a42c4402dbb0c18245';
list['근원을 삼킨 장궁'] = 'bd3c319e4a33fe0660c3cb3bc60ee926';
list['근원을 삼킨 자동권총'] = 'f1356e3338ba5bc427614e50753f5ad9';
list['근원을 삼킨 완드'] = 'c7705c82d68209fa32c59b7a9d960e5b';
list['근원을 삼킨 염주'] = '855126e0e5b8d9b26a5ffc04a25f53b2';
list['근원을 삼킨 쌍검'] = 'a6166434e4244480a0eef198296b848f';
list['근원을 삼킨 십자가'] = '1aa5c0faf9bcd968077f5787ac3a430b';
list['근원을 삼킨 스태프'] = '38c0dffd2566095aa462a8962e9f9737';
list['근원을 삼킨 소태도'] = 'b385c4c58dbae060943e9b1b9a2d31bd';
list['근원을 삼킨 소검'] = '6250a5bf265d32114371e2c22bb33358';
list['근원을 삼킨 선현궁'] = '83574c5d07f2e4cd596ee751bca6fd83';
list['근원을 삼킨 빗자루'] = 'cde24e89de1344351eb45c0246d1cd03';
list['근원을 삼킨 봉'] = 'd26f10ac26a500afb133bb586afe9f03';
list['근원을 삼킨 보우건'] = '5677fd1686c2b78b22ea36beb98d41ca';
list['근원을 삼킨 배틀액스'] = 'a4917f40cb62a4f023187bae3f8d8494';
list['근원을 삼킨 미늘창'] = '08a1ea5583085b8463c8883d954497cf';
list['근원을 삼킨 머스켓'] = 'b3d7f35491ac7d0ce977a02b9c6f9a01';
list['근원을 삼킨 리볼버'] = '2e5e9ad15b651a3f458f528093d59092';
list['근원을 삼킨 로드'] = '029448375a5e13be023b99a532f2494a';
list['근원을 삼킨 둔기'] = 'a372832f294111edad003d303bfddfa8';
list['근원을 삼킨 도'] = 'fc6ddf0aff039886c3abc1ee6dbb0bb8';
list['근원을 삼킨 대검'] = 'fa27b13ba163a2dd54917524882b4f43';
list['근원을 삼킨 단검'] = '73edc1664aeb5a1b31a57b4ee9a30fe0';
list['근원을 삼킨 너클'] = 'ac51ae355f08cc933a6eaf954303a67a';
list['근원을 삼킨 낫'] = 'df25bbdf1a270ab3ee3959e35db676bf';
list['근원을 삼킨 권투글러브'] = 'f47b5673881833e976f2249c43188663';
list['근원을 삼킨 광창'] = '60e8b7e1489d1764fc11d6872d47b049';
list['근원을 삼킨 광검'] = '2bd38a36e75ffcda8551c21d39d6bdd9';
list['근원을 삼킨 건틀릿'] = '4276148819c09ecf3bb813bfdf4974e4';
list['근력 보강 숄더'] = '69a9cabeca43c5507f8864f6278b4a37';
list['굴착 강화 파츠'] = 'fb7a79e708279d7a5cfbad71b2f5c618';
list['굳건한 믿음'] = '0df27e01e5151bc1d62185ff804a5bf8';
list['구속된 자유'] = 'c3755118b4e3b8ee50a3bfc6f8c027c9';
list['교감 : 보호하는 온기'] = '127f13f9aaf04f83c78f0134fb1b015d';
list['교감 : 동행하는 대지'] = 'c0665715c5c4b1d6de9f4ca60f130551';
list['교감 : 내려앉는 이슬'] = '5e52a97492afdd06e0a2dbdeece2c6d8';
list['교감 : 공존하는 생명'] = '1bb154696f9191e6c0a4abdf74948d41';
list['교감 : 감싸안는 햇살'] = '26b72a48c54e98296ff3df20a52d4c16';
list['광휘 : 전해지는 지혜'] = 'ea1a209be12a78a2a6c872e75ab0970c';
list['광휘 : 멈출 수 없는 의지'] = '7dc30234ecc008bc2ddc550ad8fb22a5';
list['광휘 : 고귀한 사명'] = '078ddee1101ba2dd3f62f7b2841a260d';
list['광폭화된 전의의 핸드캐넌'] = '37d92832be9e76c3dd8b538f52274029';
list['광폭화된 전의의 투창'] = '02bd7d3ce763965e3270a9715144a0fb';
list['광폭화된 전의의 통파'] = '5e2813d3f165891dee994f30d5184329';
list['광폭화된 전의의 토템'] = '060a7fc85bab63b33152af7420789ec0';
list['광폭화된 전의의 클로'] = '4fd0988539f3e0af6cb035f4bec98fc5';
list['광폭화된 전의의 코어 블레이드'] = '58c6d98f9d548ab09d0230f778a1c066';
list['광폭화된 전의의 창'] = 'dd9c91d5fdef18b3a7d44c97165b27f3';
list['광폭화된 전의의 차크라 웨펀'] = '5c855d9b70be93f41aae4952f1419602';
list['광폭화된 전의의 중검'] = 'f4aa942a38332cda474794303081097d';
list['광폭화된 전의의 장창'] = '44f9934330bad326e53b159f9e4508d4';
list['광폭화된 전의의 장도'] = '252dc06234336c1b28ff8e4aac7d69ec';
list['광폭화된 전의의 장궁'] = 'f30c08e26711b477d7929799127f2b9b';
list['광폭화된 전의의 자동권총'] = 'd3352e2f2160f9bd6cf4e7513ec217fe';
list['광폭화된 전의의 완드'] = '1e51692d272690bb2ff600fe2d2fc055';
list['광폭화된 전의의 염주'] = 'ceea33b99f5b1be4630d861463783ff4';
list['광폭화된 전의의 쌍검'] = '3673e0266b980053cbbc3497af7d229b';
list['광폭화된 전의의 십자가'] = 'ee7393351cca6c52bf06ab514875ee8d';
list['광폭화된 전의의 스태프'] = 'a7d20c36dba10300dd94b232a622cd9e';
list['광폭화된 전의의 소태도'] = 'c1f3a8ca9b31c1342323e4f430055e25';
list['광폭화된 전의의 소검'] = 'acef541e8d7849ac25f0fcba21c4e2c7';
list['광폭화된 전의의 선현궁'] = '85476270194a715e0ffd4021b7cd66fc';
list['광폭화된 전의의 빗자루'] = 'b955bf797cd0cb7ff6c07429213193b8';
list['광폭화된 전의의 봉'] = 'ee4846609ad91444f3a953b4de95539b';
list['광폭화된 전의의 보우건'] = '369b843da71e9f7338daa7fd295788b7';
list['광폭화된 전의의 배틀액스'] = 'ece398c460f93fad0d1da73f47725ac9';
list['광폭화된 전의의 미늘창'] = 'cdeae4fa87be43bf5a4343b257308e73';
list['광폭화된 전의의 머스켓'] = '56b10da13bec7688c9168a47f5df0f93';
list['광폭화된 전의의 리볼버'] = '95a9cdefaebc92f751b369638b78f7a6';
list['광폭화된 전의의 로드'] = '0e78fea02eee9858b5a549d6cb154829';
list['광폭화된 전의의 둔기'] = '60d938b517be6d3b2325bb5031ea362d';
list['광폭화된 전의의 도'] = '11bd2af6fdaf1ddd2de93ba3df1b81bd';
list['광폭화된 전의의 대검'] = 'cab73346f3cf368f50a4d633c809106c';
list['광폭화된 전의의 단검'] = '5fe4e4dba6975588ce4d13de87d0f18f';
list['광폭화된 전의의 너클'] = 'c9eff9d40cb818826bf92e9da89d8d9a';
list['광폭화된 전의의 낫'] = '3548bcbb89a3dbb8b5fe5777d29c469c';
list['광폭화된 전의의 권투글러브'] = '5053083000b6065e22110940858d80f5';
list['광폭화된 전의의 광창'] = '63fffa269b95fe4b5c20731d06ab5912';
list['광폭화된 전의의 광검'] = '4f66ac8497d16950343f4974965fdd16';
list['광폭화된 전의의 건틀릿'] = 'a33a52ac1f60adf98ed3038a6fc28983';
list['과거를 뒤로 한 전진'] = '0b956e1734d551c828d2b980ef8a615a';
list['공중형 : 전술 프롭 드론'] = '698a56642e05d261f17bba5c87233cf4';
list['공명하는 에너지 하의'] = '22fb02f5603a037079a5ba59a98223c7';
list['공명하는 에너지 팔찌'] = 'be02b6cec49bea23bff388bd9a62d2f2';
list['공명하는 에너지 어깨'] = '49745aabf2b3e0719ed044fe22a11bf1';
list['공명하는 에너지 신발'] = '8c75a15ec8fe745b4be1639fa42f6588';
list['공명하는 에너지 상의'] = '738965a105c6c04dda76950c621818ad';
list['공명하는 에너지 보조장비'] = '1f27743ddfa45afb77d82806cb5bf4ab';
list['공명하는 에너지 벨트'] = '95d9c1cf529331b8dddab9ff0b6d0b9a';
list['공명하는 에너지 반지'] = '8ad24a6b1376cb8b6782f60992690bd6';
list['공명하는 에너지 목걸이'] = '76806e96a81a295a98a4edbb05b0e43d';
list['공명하는 에너지 마법석'] = '950cbb104a2b9ba74143e787d92731cd';
list['공명하는 에너지 귀걸이'] = '6f410f07c93b733ef932d84133d02810';
list['골렘의 핵 팔찌'] = 'f7bfda17172325889c99158880f1702a';
list['골렘의 중추석 반지'] = '639dedaa8b9218023da86ff44f80662f';
list['골렘의 심장 목걸이'] = 'b81947e0883e69054da2c3cc02b7eebd';
list['골드 윙 반지'] = 'b92733a01bd3e559a2ed7c61306f2946';
list['고통의 상처'] = '86e492eb2c66b42f490f73a8fcd193dc';
list['고양된 분노의 목걸이'] = '967e5d84f11c310b316f075c92316cd5';
list['고귀한 신의'] = '476df057a837cdca0b902c8f3bf86677';
list['고고함의 백색 벨트'] = '19776f2e9cd9be54e84609e4c0aa1105';
list['경계를 넘어선 차원'] = '16e1ceee6aa14af5f032f85bbb37f201';
list['결전의 핸드캐넌 - 어썰트'] = '78783ed45208bdbd02dbb2cd020fa064';
list['결전의 핸드캐넌 - 스핏파이어(여)'] = 'fe6d5167ce12c67caec0c124d8275e8d';
list['결전의 핸드캐넌 - 메카닉(여)'] = '2408ac00b53923d0db2c3d961005c98d';
list['결전의 핸드캐넌 - 레인저(여)'] = 'c8a0a781eb6256b6df28e1470b810af1';
list['결전의 핸드캐넌 - 레인저(남)'] = 'c4953cbcf674a6907ee8603f393c8827';
list['결전의 핸드캐넌 - 런처(여)'] = '418cbae359038b267bcf35ee02d86c31';
list['결전의 핸드캐넌 - 런처(남)'] = '76a338605a14b6167e04193a9401d244';
list['결전의 투창 - 뱅가드'] = '748d312e9293c039299ef1a7209cc37e';
list['결전의 투창 - 드래고니안 랜서'] = 'f898fc77d94ad7a778ac3a745305e312';
list['결전의 투창 - 듀얼리스트'] = '9bc2ac16cd6c8f7caa357a08a3b1c85f';
list['결전의 투창 - 다크 랜서'] = '06ad5e66a95d76786a0fbe2ab06a707c';
list['결전의 통파 - 스트리트파이터(여)'] = '4a99f631df3de58795a2e312259e4cf2';
list['결전의 통파 - 스트라이커(여)'] = 'd3f2a29104bd7b826ab291cd14db7c90';
list['결전의 통파 - 스트라이커(남)'] = '6e42ba6ebf9822bd180816d9cf7212c6';
list['결전의 통파 - 넨마스터(여)'] = 'd3d58921b8ea6b802815489a495178c5';
list['결전의 통파 - 넨마스터(남)'] = 'b206fb9dc51e2ae52069f3275df569bf';
list['결전의 통파 - 그래플러(여)'] = '2e8c00dc0d922666a7d8a0873867ab65';
list['결전의 통파 - 그래플러(남)'] = '05d51461904c5ead623660f8ac219737';
list['결전의 토템 - 크루세이더(남)'] = '1414507d9ae1821534ad598d7c63dd90';
list['결전의 토템 - 인파이터'] = '15b85785f8375e9153a9066e0b6e6b2e';
list['결전의 클로 - 스트리트파이터(여)'] = '4ec7ed0abae414664bddba6bfa5ea11b';
list['결전의 클로 - 스트리트파이터(남)'] = 'e03585196d75efd48e903f2cd9874aa9';
list['결전의 클로 - 스트라이커(여)'] = 'ab9aa27481df2c416b0223939902fcc5';
list['결전의 클로 - 넨마스터(여)'] = '463cf3632f67db7dcc59202c46fa6ccb';
list['결전의 클로 - 넨마스터(남)'] = 'a9577313f6f5e61ffabb75b0220832b1';
list['결전의 클로 - 그래플러(여)'] = '9e8e8b65ab8e1e08b5032b48fb76d703';
list['결전의 클로 - 그래플러(남)'] = '601b43064e7ea208a7d3c556016816a7';
list['결전의 코어 블레이드 - 히트맨'] = '6e8d003d4047dc03103ba3a14804bf58';
list['결전의 코어 블레이드 - 스페셜리스트'] = '4b049b5bffde050b54a26554f3d7eed0';
list['결전의 창 - 인챈트리스'] = '33eb6b2f0ce84edb6213a1d9dd919447';
list['결전의 창 - 엘레멘탈마스터'] = '32a01e757b779723f617ca28f8b1f78d';
list['결전의 창 - 스위프트 마스터'] = 'abe88aa10fe8b787972625109027098a';
list['결전의 창 - 소환사'] = 'ed13ebee16b76c049c95ba38c6d26806';
list['결전의 창 - 블러드 메이지'] = '6a6933f394d6a53e94d2799be63ee4c3';
list['결전의 창 - 배틀메이지'] = '468f8dd1e00ee18ab307acb1f673bb6d';
list['결전의 차크라 웨펀 - 쿠노이치'] = '2a2608e0ea07d330a222712bf0dd447d';
list['결전의 중검 - 히트맨'] = '89f9c7e0396a7dc61351afd4f8fbc6b5';
list['결전의 중검 - 트러블 슈터'] = 'bf9de3a5104c2c91932deaaa75c03dca';
list['결전의 중검 - 요원'] = '1758dfbdf2e9ad3a171f60f89dbcf426';
list['결전의 중검 - 스페셜리스트'] = '907f2b190f961b18baa18d3856a86f2f';
list['결전의 장창 - 드래고니안 랜서'] = '9738428e14e973e1cb5fcbadc4389d57';
list['결전의 장창 - 듀얼리스트'] = '7f368ce4aabb289685e684f46610fe44';
list['결전의 장도 - 히트맨'] = '93036620fc9fb1ee27837d9b94ba7e45';
list['결전의 장도 - 요원'] = 'b4865a4b6f96e85aa59c55063ecc4e45';
list['결전의 장궁 - 트래블러'] = 'b162259a948462438d665b4a0d0d6f18';
list['결전의 자동권총 - 어썰트'] = 'f9978d9e02e8b8792f1977fe32250474';
list['결전의 자동권총 - 스핏파이어(남)'] = '840199110b3c7c698e143b83656fa77b';
list['결전의 자동권총 - 메카닉(여)'] = '2a14c81dded018d148023cb3e66ff9d4';
list['결전의 자동권총 - 메카닉(남)'] = 'a79f4588349dc17c3d131d2d92547632';
list['결전의 자동권총 - 레인저(여)'] = '74fda6278a2a397b2b99a708d13e367c';
list['결전의 자동권총 - 레인저(남)'] = '811a860dc0599b4408132207a0aaa601';
list['결전의 자동권총 - 런처(여)'] = '4b92971b1254bb165b7af589fe3069b7';
list['결전의 자동권총 - 런처(남)'] = '3edbb7aaf77b82062c47ae7ca4c79f41';
list['결전의 완드 - 사령술사'] = '79837453cbc46356f60990bb5bf96c27';
list['결전의 염주 - 퇴마사'] = 'e4cf223e6c769569f9ff975d65f6c1b9';
list['결전의 염주 - 크루세이더(여)'] = '2dddc4b985e9b179fc95adbbdff8c93c';
list['결전의 염주 - 크루세이더(남)'] = '505aec8e464bb2a88c7519803bb78a87';
list['결전의 염주 - 인파이터'] = '1c209be775ef3c1269e0e94ecc49f963';
list['결전의 염주 - 무녀'] = '4a11cbca2623592e3095ac4b03a65339';
list['결전의 쌍검 - 쿠노이치'] = '239efe44b7f1ff35e697807dc1752b5f';
list['결전의 쌍검 - 로그'] = '1769d4fccbed558d63b1bdc5e5316080';
list['결전의 십자가 - 퇴마사'] = '10bb56529f396bb4993bb68c808c9c85';
list['결전의 십자가 - 크루세이더(여)'] = 'c314c114228115ce15abef0e11ff09d4';
list['결전의 십자가 - 크루세이더(남)'] = '46921b3b77f967c86442173fcf778bf7';
list['결전의 십자가 - 인파이터'] = 'b7e64d8ceb31f4243679d358c5786ba6';
list['결전의 십자가 - 이단심판관'] = 'f400202e744f2c8db76b273654c6d410';
list['결전의 십자가 - 미스트리스'] = '3d600aa6d433993b4380b5e5aa94d37c';
list['결전의 스태프 - 크리에이터'] = '9d23c89793a87a5f97818577537f6c9c';
list['결전의 스태프 - 인챈트리스'] = '3c58781938be47582b5de02eb6fb8b0c';
list['결전의 스태프 - 엘레멘탈마스터'] = '6de779a99f072fe6f4751135c075a3cd';
list['결전의 스태프 - 엘레멘탈 바머'] = 'aeab8e833a810f08273b80cce56556bd';
list['결전의 스태프 - 스위프트 마스터'] = '2a2fd3b6363682c92598911b2953abc4';
list['결전의 스태프 - 소환사'] = 'b71fbf2b9a932df35f168419e3284b69';
list['결전의 스태프 - 빙결사'] = '29596e90c345114b1780480718cd1650';
list['결전의 스태프 - 마도학자'] = 'eb2650736a327fe97951f7231d24f133';
list['결전의 스태프 - 디멘션 워커'] = '67720e014b1efab7aedd2568f32e3bc3';
list['결전의 소태도 - 트러블 슈터'] = '5276d097bd8252dbdd443f0e046fc22b';
list['결전의 소태도 - 요원'] = '1941358bb6904f91b3fd8f890c329057';
list['결전의 소검 - 카오스'] = '237f141ec341f5e72717e87bd2d71a5c';
list['결전의 소검 - 웨펀마스터'] = 'ab35afd8f1dd8a3a4127c599cece40de';
list['결전의 소검 - 아수라'] = '7c3b4c751b306d1b52ac1a7a5a15e3a9';
list['결전의 소검 - 소울브링어'] = 'c39a4f3b5213e7f77ed2a0627b5b024d';
list['결전의 소검 - 소드마스터'] = '5b6c824cbcc1dca38056596f6aa125c5';
list['결전의 소검 - 블레이드'] = 'eb617a561085dcf5c393448c882711b3';
list['결전의 소검 - 버서커'] = '67c6a6945910f1475705eabfa68091f9';
list['결전의 소검 - 데몬슬레이어'] = '7f3c930eb14e9da1661d4bdf262e208c';
list['결전의 소검 - 다크템플러'] = 'c01d67ff5aa0e417685befff8b49e46e';
list['결전의 소검 - 다크나이트'] = 'b8017b856700f06e157c70f2b631a655';
list['결전의 소검 - 검귀'] = 'c24505fa6eb1233a2a5f5a373fbfb827';
list['결전의 선현궁 - 뮤즈'] = 'a348b7d4f74c979d3f3d23275bc4829e';
list['결전의 빗자루 - 크리에이터'] = '576189d2e238c4753f28837461c46af2';
list['결전의 빗자루 - 인챈트리스'] = 'ba8c13a72e13e5bbdc30a282e3bf8fb1';
list['결전의 빗자루 - 마도학자'] = '34ae751ee9ea1311c57b8a81d3ae2830';
list['결전의 빗자루 - 디멘션워커'] = '3b7d6bb2b872859e8881cf914d07da74';
list['결전의 봉 - 인챈트리스'] = '7e1129a75bb19f45f5a6dd0fd33b2f6d';
list['결전의 봉 - 엘레멘탈마스터'] = 'c487e8cb65a34e592a552c1c1ee8fb8f';
list['결전의 봉 - 엘레멘탈 바머'] = 'bbf9ba5e5ad830228ab466e2bc6f4e5d';
list['결전의 봉 - 스위프트 마스터'] = '89a2eb7d8fdaa202745b99df2f939a48';
list['결전의 봉 - 소환사'] = 'd8e965f94b2af321214e07b57d532346';
list['결전의 봉 - 빙결사'] = '1f74d0daff3085ab774780c5efb20a40';
list['결전의 봉 - 블러드 메이지'] = 'd271891aca11ea3ab70aac9016fbd4f4';
list['결전의 봉 - 배틀메이지'] = 'a647592eff952cb3b11fd101aff01dbc';
list['결전의 봉 - 마도학자'] = '6ad587f14b4f0aba5faaba218bdc2325';
list['결전의 보우건 - 스핏파이어(여)'] = 'b0d4c2c994ae6106ed75f629311b5a77';
list['결전의 보우건 - 스핏파이어(남)'] = '8cc7d6d2657108cd8c0726005079afb4';
list['결전의 보우건 - 레인저(여)'] = '9bd5f78cfb8a894f67a121ec93a67fd4';
list['결전의 보우건 - 레인저(남)'] = '6dce09b076fc82b012a2d73fc9046dc6';
list['결전의 보우건 - 런처(여)'] = 'f39096a1750add0ca33e055ea14a11fa';
list['결전의 배틀액스 - 퇴마사'] = '94a46963183c21867e518abda4f2a818';
list['결전의 배틀액스 - 크루세이더(여)'] = '894b8df37a7c03a500ed128080af7b39';
list['결전의 배틀액스 - 크루세이더(남)'] = 'bd66a8cb751cfdf95b23e56fa0c63966';
list['결전의 배틀액스 - 인파이터'] = '014cd73bfc684e61421da10a080dcaf2';
list['결전의 배틀액스 - 이단심판관'] = '44dab88fff2bc28386f5d2dbdb37ef25';
list['결전의 배틀액스 - 어벤저'] = '1e8e2fed72bf5230d2912a18a94d4f39';
list['결전의 배틀액스 - 미스트리스'] = 'ecb64a1bd140ee3022dcfc520786e173';
list['결전의 미늘창 - 뱅가드'] = 'd11a805f7e437d61f3f9a836ca21f99a';
list['결전의 머스켓 - 스핏파이어(여)'] = '747e728e0d47c02d0516f4effd110815';
list['결전의 머스켓 - 스핏파이어(남)'] = '5f13dc8d42577ef9032cb961ce034f9a';
list['결전의 머스켓 - 메카닉(여)'] = 'c6f76bcf8eb42c6aa6736f8a5502ee1f';
list['결전의 머스켓 - 레인저(여)'] = '2fec7c9a99d899e284bceab13ff01cbd';
list['결전의 머스켓 - 레인저(남)'] = '2689557c3f125474b4df96f7a33f6433';
list['결전의 머스켓 - 런처(여)'] = 'bf460ad935564441b6a2099e07eab9d0';
list['결전의 리볼버 - 어썰트'] = 'f9936ac6d67a93aa3458fe018d07999c';
list['결전의 리볼버 - 메카닉(여)'] = '24f233af3580404a498565154e8bd54d';
list['결전의 리볼버 - 메카닉(남)'] = '22092d81763b2d925bae50c7d324a426';
list['결전의 리볼버 - 레인저(여)'] = '5fe372670cc3f3ebbfe00aedebde2426';
list['결전의 리볼버 - 레인저(남)'] = '0d7a3b3ae556ab5f2f74a57c1c8faa24';
list['결전의 리볼버 - 런처(여)'] = '253bdeac9d6fd3a136ff260d0fb04424';
list['결전의 리볼버 - 런처(남)'] = '63e64ecdb9f4947ef2eb6b441b8dee89';
list['결전의 로드 - 크리에이터'] = '9296d3f97f5e0850e15420e8bfe73964';
list['결전의 로드 - 인챈트리스'] = '500f04533c294a1acb2adde21aa93ebe';
list['결전의 로드 - 엘레멘탈마스터'] = 'a58624f6e474430ad9371bcb66edd50c';
list['결전의 로드 - 엘레멘탈 바머'] = 'd727e21aded1e136da69d4bbea823269';
list['결전의 로드 - 스위프트 마스터'] = 'da90a29538d3ead791797af61ca99cca';
list['결전의 로드 - 소환사'] = 'f1d650922f5eeca7d56421971ee77d9d';
list['결전의 로드 - 빙결사'] = '8a1e26a24e419baa7510834ccec77899';
list['결전의 로드 - 블러드 메이지'] = '0da043770fc6ef048065e0f8d38c2b08';
list['결전의 로드 - 배틀메이지'] = 'd408810c89ef0653038a5dcf04806149';
list['결전의 로드 - 마도학자'] = 'bd2eb41403a95dc3fde1011481c2ea75';
list['결전의 로드 - 디멘션 워커'] = 'c400a12f63e9fe5284dba2c1f02bcd96';
list['결전의 둔기 - 팔라딘'] = 'f3726cb58f4d8faa03c6fff6580830d3';
list['결전의 둔기 - 카오스'] = '1b4f1ff4b4e42c8af24c799d2fd7146c';
list['결전의 둔기 - 웨펀마스터'] = 'c74f3067c5168ecb28ab2c755cdb3171';
list['결전의 둔기 - 엘븐나이트'] = '452a01be805c3cbf13183f5a51c6cc45';
list['결전의 둔기 - 아수라'] = '63f9e8b03eb9f32112f9d95f16f936fb';
list['결전의 둔기 - 소울브링어'] = '90e8048f28ebf193250959ba2a13fb42';
list['결전의 둔기 - 소드마스터'] = 'c9a2619c52894c3be343422d2e94332b';
list['결전의 둔기 - 베가본드'] = '66dfb0fd641afb47e08d6988322672db';
list['결전의 둔기 - 버서커'] = 'b16e05f642e688c8c47e840831bbcfe0';
list['결전의 둔기 - 데몬슬레이어'] = '63246c6f2b9a33a4b0c430210c902e11';
list['결전의 둔기 - 다크나이트'] = '458550af432dd0956885cd5afd271568';
list['결전의 도 - 팔라딘'] = '61b5576a6e84ee3aa0db081e59731f61';
list['결전의 도 - 카오스'] = '410a627dce856ccbb225e9428a602fca';
list['결전의 도 - 웨펀마스터'] = '50589d2237217f17d00cd1ff381ec51a';
list['결전의 도 - 엘븐나이트'] = 'bc80f5597f9fb96abdb75d933e8fadc8';
list['결전의 도 - 아수라'] = 'fb797e99cf3fd91ad4e7675d60151d19';
list['결전의 도 - 소울브링어'] = '18407b015af4aa840d1c26b7834ed98a';
list['결전의 도 - 소드마스터'] = 'a3d02f52ddd301d307db1933fefeb452';
list['결전의 도 - 블레이드'] = '758bc0a7f3af620b2dd22984d3ce2f1b';
list['결전의 도 - 베가본드'] = '86a654b4a3ec0964017f16992f2b0b7d';
list['결전의 도 - 버서커'] = 'e3106b77505482dba9e177dd4d95e43f';
list['결전의 도 - 드래곤나이트'] = 'ecb3f729baeb497343f95d5d5612d495';
list['결전의 도 - 데몬슬레이어'] = '7d3a90fda94dbafdd77478206651cd18';
list['결전의 도 - 다크템플러'] = 'c1e43e8dee17e1666d25b39dcd0d41af';
list['결전의 도 - 다크나이트'] = 'bd115943c6454c898fd72d83bcf61d9c';
list['결전의 도 - 검귀'] = '5262287485a370d1d41ae4760c36cc92';
list['결전의 대검 - 팔라딘'] = '7576c4169f959c162739e7cf78772f70';
list['결전의 대검 - 카오스'] = '5d14daf6187c7fe575d09fee029152b1';
list['결전의 대검 - 웨펀마스터'] = 'd9a3be447e9cee70d304c480a9cec02c';
list['결전의 대검 - 엘븐나이트'] = '7b08ff253db0cf304c8733df798a92db';
list['결전의 대검 - 아수라'] = '854c267305cba5c5c4f4ac5239f0f398';
list['결전의 대검 - 소울브링어'] = '0a5eabd58bd39deba79e7e85a8728375';
list['결전의 대검 - 소드마스터'] = '39db2360df46dc646076d040b8d58525';
list['결전의 대검 - 베가본드'] = '0c75d8d42a7acfb0d3aca07d1ce962b0';
list['결전의 대검 - 버서커'] = '9466d47afba4763d850785e89b8b392c';
list['결전의 대검 - 데몬슬레이어'] = '7ae5468e0d81b999be9aa6d864f044ff';
list['결전의 대검 - 다크템플러'] = '0dca697fc78d15efb39f705c449710d1';
list['결전의 대검 - 다크나이트'] = '06ffc203717f4083cdd7bd51b254010d';
list['결전의 대검 - 검귀'] = '642c5153a4621094812cfe3551f0bc3d';
list['결전의 단검 - 섀도우댄서'] = 'f7734990441a9397a7302b93286ff375';
list['결전의 단검 - 사령술사'] = '448c44507db3fec8174c441d565777ad';
list['결전의 단검 - 로그'] = '5068d459dd3fd8c8b1d2ec9e0d27e19a';
list['결전의 너클 - 스트리트파이터(여)'] = 'b698400ac22995040b7e2a518dc13a49';
list['결전의 너클 - 스트리트파이터(남)'] = '473900960d250a328c3bd9856896f5e4';
list['결전의 너클 - 스트라이커(여)'] = '2ff8c928b81beb13537e9ff6693637a1';
list['결전의 너클 - 스트라이커(남)'] = '925b44e9984d42f3e8423c382f51d71b';
list['결전의 너클 - 넨마스터(여)'] = '23adf1c1ad2be5bb3a79196976e5ef45';
list['결전의 너클 - 넨마스터(남)'] = '21a04e5c7fa1137266c1f8978d4278a9';
list['결전의 너클 - 그래플러(여)'] = 'a6d69424877c757046c00418be7873d3';
list['결전의 너클 - 그래플러(남)'] = '0db0dc25c296e69b735d529dee97e9a8';
list['결전의 낫 - 퇴마사'] = '1844fa3f66460e65dcb532740d53e661';
list['결전의 낫 - 크루세이더(여)'] = '4aa4baff4d565aed0461738bcc9d05d3';
list['결전의 낫 - 크루세이더(남)'] = '0502245a7a34f41e6179e3b887a6c252';
list['결전의 낫 - 인파이터'] = '3f06f384d1f20baf1dc792b747c1c242';
list['결전의 낫 - 이단심판관'] = 'a03894821d876669117ecf652226f554';
list['결전의 낫 - 어벤저'] = '932751f76d587dbbd60f3e6cb7fa262d';
list['결전의 낫 - 미스트리스'] = 'd5afdabf163836045a06b7e3f0796ef1';
list['결전의 낫 - 무녀'] = '562d41525b98e819666aa0a4087e0374';
list['결전의 권투글러브 - 스트라이커(여)'] = 'd8d84003b643b2ce7c3070ad16fa8563';
list['결전의 권투글러브 - 스트라이커(남)'] = '8571b03bf8c4dea87215ab5e90180a96';
list['결전의 광창 - 드래고니안 랜서'] = 'b7787339972e8ccd34040028c2b5d389';
list['결전의 광창 - 듀얼리스트'] = '1373627f085196fab83b9a2347e54925';
list['결전의 광창 - 다크 랜서'] = '2f9e97914447b5c4d42da63fee73f10f';
list['결전의 광검 - 웨펀마스터'] = '09e0ada505d32faf730ba8af86fc083e';
list['결전의 광검 - 베가본드'] = 'fad84154f25fe29ccdcbf8d5b12c1bf9';
list['결전의 광검 - 다크나이트'] = '157f98722489ea9d279e08e7fa47df9b';
list['결전의 건틀릿 - 스트리트파이터(여)'] = '96d7379276065c67345edd1b5c8cf8ca';
list['결전의 건틀릿 - 스트라이커(여)'] = '5a2122f47ef77b69e4bba75617b7d42e';
list['결전의 건틀릿 - 스트라이커(남)'] = 'cc24df88d49730bf0805b3e9bb41ee68';
list['결전의 건틀릿 - 넨마스터(여)'] = '2ee9940f4371cd412d6c9afbcaa2b652';
list['결전의 건틀릿 - 그래플러(여)'] = 'e439e8274957c3b34fde38f98d43d0e7';
list['결전의 건틀릿 - 그래플러(남)'] = '30ce27a418e877d62bc8157dd0b156e2';
list['격동하는 마음'] = '073a49a592516fef6eb217c24fc85f75';
list['격노 : 파괴를 행한 맹목'] = '054774144a5aab6828500305f71425ec';
list['격노 : 최후에 삼킨 숨결'] = '113436105b0365c4ee0ed0c542f30710';
list['격노 : 세상을 탐한 욕구'] = 'c11bb733312c6612d6fd2cd9196862c8';
list['격노 : 방향을 잃은 갈망'] = 'f2c4246d0b59619eea5d03bb5b76cc91';
list['격노 : 무한히 증적한 식탐'] = '5b3976c605e6d96878a9b94ad5fbd449';
list['검은 별'] = 'bbd070d70a5168fabecaef168427487d';
list['검은 발자국'] = '0600576a470adb7226ea5e00e964d806';
list['거짓 속의 거짓'] = 'bfffff3faa424f90a6b674b4689bbd0a';
list['개화하는 신비의 꽃'] = '4704c80a314a7ddf6942f961254bd51d';
list['가이드 라인 벨트'] = '28a7317a09c9b4c82632b576d290ef28';
list['HEM 리인포스 부츠'] = '2c1b7ed9846a9c620c6b67d4966d3ba7';

list['불가침의 영역 - 광검'] = '0469d88bf1362c05c1b3ad70c013367c';
list['불가침의 영역 - 봉'] = '057a5accce8ab9d9c90fcf5f02b51797';
list['불가침의 영역 - 판금 하의'] = '0c69e26f193f13dceb622af48de4ba44';
list['불가침의 영역 - 보우건'] = '0db184c929a76cb5ba5af91c51f04a72';
list['불가침의 영역 - 소태도'] = '0f475ef2f44a28c3ff0464b135933d4e';
list['불가침의 영역 - 머스켓'] = '12b29a4c9bedb47ba40ec52c43309662';
list['불가침의 영역 - 가죽 어깨'] = '153d20b882ff15e51e3bdefd1226af60';
list['불가침의 영역 - 통파'] = '1799afe4c3f677f0ce05bd4b5ad136b0';
list['불가침의 영역 - 천 벨트'] = '1ccac9965353062c04b7da144244b544';
list['불가침의 영역 - 창'] = '1d1dd6b928665b37309c905b3dcc215d';
list['불가침의 영역 - 클로'] = '1f95f7980f58c0d51f8d1df5d1a1e77b';
list['불가침의 영역 - 낫'] = '22f7904512dbefe4a97d4881d4db1a61';
list['불가침의 영역 - 선현궁'] = '2686f28fa28a61d39a7fee8dfd04aed5';
list['불가침의 영역 - 너클'] = '2d093f0423cd164870d49346438cf359';
list['불가침의 영역 - 중갑 신발'] = '32c0a6e8e69fc1d3b77fbbf50a9ec671';
list['불가침의 영역 - 장궁'] = '37ee433a07a88aacc4fba00c356b4115';
list['불가침의 영역 - 귀걸이'] = '385396a185ab79722e1e3ec4a9344619';
list['불가침의 영역 - 마법석'] = '3d34177c6442f0fd32ebaa1b8f1068f6';
list['불가침의 영역 - 자동권총'] = '3fb93c81e07cef5b2bbd2a2f018ff2ad';
list['불가침의 영역 - 중검'] = '4be82c2c36fdefe11267eecce4d49901';
list['불가침의 영역 - 판금 신발'] = '4c69caec80da7520fcc9429fe981338f';
list['불가침의 영역 - 중갑 어깨'] = '4d021bc06c49cd2113626d6260dd98a6';
list['불가침의 영역 - 투창'] = '4d1273fc38c0cb068a3f735c3f358027';
list['불가침의 영역 - 판금 상의'] = '4d8237d7bec29aaa94b9c44731490496';
list['불가침의 영역 - 소검'] = '4fc0ce254ae4c711ca4476a50e7ee92e';
list['불가침의 영역 - 빗자루'] = '5468f31bf24b421de643fb8879e8af15';
list['불가침의 영역 - 경갑 상의'] = '5d9a9702948c95ccd3fb549389e91c85';
list['불가침의 영역 - 중갑 하의'] = '5f2ff7676223a2c0cfdf815499d72eb4';
list['불가침의 영역 - 경갑 벨트'] = '5fbc9dd32bc9b7c298f3d1f3fd97c991';
list['불가침의 영역 - 천 하의'] = '611c95e68f13fe37e11a193adbaa85cc';
list['불가침의 영역 - 반지'] = '61f1671e5e802efb3325f653ffbb28da';
list['불가침의 영역 - 장창'] = '62842343c592305ea860db6fe09232c0';
list['불가침의 영역 - 천 상의'] = '640a2234a58face06c572ab9757ed0d0';
list['불가침의 영역 - 보조장비'] = '68c290f2443b61eb70e7366a381d5bb9';
list['불가침의 영역 - 십자가'] = '68cb4a64d6bcc557c8a6b8f696d9c433';
list['불가침의 영역 - 가죽 벨트'] = '70585624d932c1400f854e73726963e1';
list['불가침의 영역 - 권투글러브'] = '78104fdf5a700efc2e2661c4bcb39aee';
list['불가침의 영역 - 천 어깨'] = '7a6dd8a7f539725e50f7b36b2101f6d1';
list['불가침의 영역 - 쌍검'] = '84909008d7aed86fc39fc1ddb10f24cd';
list['불가침의 영역 - 코어 블레이드'] = '930e631cb4a9d1d79388afe40b6e03ff';
list['불가침의 영역 - 가죽 하의'] = '971ddaea8435a7543038759505e7e7ea';
list['불가침의 영역 - 경갑 하의'] = '988357b6eb261b34ee8ac49a270c8c29';
list['불가침의 영역 - 건틀릿'] = '999af014587bc3d9a53a94d0e16905af';
list['불가침의 영역 - 핸드캐넌'] = '9ccc7e7588663f2931732af12ea0aff0';
list['불가침의 영역 - 대검'] = '9e6e8142fdd6818d364f2e93b9daddca';
list['불가침의 영역 - 배틀액스'] = 'a04a64e9f2deda3a979b962ecb1b7489';
list['불가침의 영역 - 토템'] = 'a66923253a25246da52f02a839eb37f1';
list['불가침의 영역 - 완드'] = 'a86bb4586a2373fc905f8b2fc2c2fd8e';
list['불가침의 영역 - 판금 어깨'] = 'abb6ce8a8098017f1037b977479ecbf2';
list['불가침의 영역 - 둔기'] = 'abfe04018c3f70c9b09c2a765f53412d';
list['불가침의 영역 - 중갑 벨트'] = 'ad7485d98a073f10de4c69ceb77beb49';
list['불가침의 영역 - 목걸이'] = 'afcd6aee7169edbb6fb4520db62d50b5';
list['불가침의 영역 - 리볼버'] = 'b092723dfea1806f897cff0241f7b76a';
list['불가침의 영역 - 경갑 어깨'] = 'b524eed3bf5f2dfad9ebc359e689d96c';
list['불가침의 영역 - 천 신발'] = 'b7ca34a556abe0fe45b522e37047b933';
list['불가침의 영역 - 팔찌'] = 'ba905b122ba9521b13111441dbea7ba2';
list['불가침의 영역 - 미늘창'] = 'c4e16e120cd705c4cb952a0367131619';
list['불가침의 영역 - 도'] = 'ccb3696eba4e3be0c63f11127631f4d7';
list['불가침의 영역 - 단검'] = 'cde2e123e1a27ad139f87e25011344bb';
list['불가침의 영역 - 판금 벨트'] = 'cde5b30ec0434f8132f066d46ea5f2e6';
list['불가침의 영역 - 장도'] = 'd1960903f36a829d69185b9546ba245c';
list['불가침의 영역 - 스태프'] = 'd25a411fb1b825a7bb801365aab984a8';
list['불가침의 영역 - 염주'] = 'd6e746ebaabee4a89b84baf10bd866af';
list['불가침의 영역 - 가죽 신발'] = 'd71817b42b6e72612542fd650b0bf1f7';
list['불가침의 영역 - 경갑 신발'] = 'db0e7df2a3ebbfc496387b419e4acab1';
list['불가침의 영역 - 가죽 상의'] = 'de85547bccb1695aaed16b63ae5fc485';
list['불가침의 영역 - 광창'] = 'e26f91ef2d62e26d6566f6d12e156cd4';
list['불가침의 영역 - 중갑 상의'] = 'e540e4d787c1b48fca3e7f97f1af649d';
list['불가침의 영역 - 로드'] = 'f780622440752096c7e5d5709042eac3';
list['불가침의 영역 - 차크라 웨펀'] = 'fe76ef4830e99d6daf7bf5213274a771';

list['내딛은 자의 차크라 웨펀'] = '03ecea39f6a29ada17e6b3cfc193324e';
list['내딛은 자의 선현궁'] = '0539a0591e1471d73ca1e104d71b0509';
list['내딛은 자의 완드'] = '0e7020331c4d15c119ff741261cdc040';
list['내딛은 자의 염주'] = '0f23308f100c89df8feca4277bbf3f2c';
list['내딛은 자의 로드'] = '11290ea142ab1fe61bcc022c9ceff487';
list['내딛은 자의 미늘창'] = '13feaea7e8625db8baca42f5cbf3816d';
list['내딛은 자의 권투글러브'] = '1d1ede995cbc19ceb939051a9fac5583';
list['내딛은 자의 통파'] = '2241528d6e25034bccfc29969dc2f28b';
list['내딛은 자의 차크라 웨펀'] = '227d9f1801cd8c3aad8cab41e827a83f';
list['내딛은 자의 클로'] = '24c0ba594b7d1f143de556c727e9e6cd';
list['내딛은 자의 로드'] = '33b917b56907efb0eae6dcb4d122cd25';
list['내딛은 자의 낫'] = '3b7f183dd10bf8f5965b74ca6cfb09c0';
list['내딛은 자의 창'] = '3bb2cc2b8f1e91174a050cf7a74d6266';
list['내딛은 자의 낫'] = '3be17bb3c4e742015e8f5f4bd04eb987';
list['내딛은 자의 광검'] = '417e31d6a9cf9bd48d0cd42a6e4aadde';
list['내딛은 자의 십자가'] = '44d9a5f031cd9e7c2aebc5e505ce76f7';
list['내딛은 자의 장도'] = '4aa0dc4f0df3e363e5d0861979317c32';
list['내딛은 자의 클로'] = '4b0a758e3f8ec69d5517aede90ff45b3';
list['내딛은 자의 보우건'] = '4bc8858c272fe936826c18581a72c91f';
list['내딛은 자의 너클'] = '50f05690de35cf411cc5f65d2263bd24';
list['내딛은 자의 장궁'] = '5216a77d6c998cb20bd7d39c229d91bf';
list['내딛은 자의 단검'] = '5607105ab414e758323c79d5d2af85c2';
list['내딛은 자의 소태도'] = '563b50408d9395d1e5fd3833a31745a4';
list['내딛은 자의 창'] = '5e871d94b3e43ecff21914ba4b3418dc';
list['내딛은 자의 둔기'] = '620a5d6e3a573b289aa8a7571b7a9e54';
list['내딛은 자의 광창'] = '67380c52402dda23f18393eb2334ca14';
list['내딛은 자의 배틀액스'] = '69d49ab7c461be614f16693bbf40a908';
list['내딛은 자의 완드'] = '6aa75e2e41f6c1718f1843ca9f145498';
list['내딛은 자의 광검'] = '6bf8ad6cadde81416722cdade90a7219';
list['내딛은 자의 소태도'] = '6e976ca7ef75a0b7c86d809d60a444a5';
list['내딛은 자의 핸드캐넌'] = '70d6abf6827fb77bb5319382b0a834c5';
list['내딛은 자의 봉'] = '7250e255b07bad50d2e735d92a0ab0b3';
list['내딛은 자의 스태프'] = '73a28ec611b186727d40ebd3afb76ec7';
list['내딛은 자의 건틀릿'] = '7eb82ddbf965aa8b977a3c90a35efa73';
list['내딛은 자의 머스켓'] = '84a5d6b97b2de355d5848670489e514d';
list['내딛은 자의 중검'] = '8983f120c217e07f29c6ba716430a546';
list['내딛은 자의 단검'] = '8d6c144cd7b5d4045e6eb8433072fb62';
list['내딛은 자의 장창'] = '8db657419606f61f99d65a33160b6f2d';
list['내딛은 자의 미늘창'] = '91761da7ddfdaf65655970c67198782f';
list['내딛은 자의 너클'] = '96d600df1da1c8bccbc8797871a7665c';
list['내딛은 자의 통파'] = '9923abd18e422132ddb645fff07bb0c3';
list['내딛은 자의 쌍검'] = '9ce3446034dd0166b1624e41959b5df5';
list['내딛은 자의 대검'] = 'a0f1b0185e2de0a730876a2e4b30efc4';
list['내딛은 자의 자동권총'] = 'a2fa0a5c521791fd4faf4ddb6bf2e9c0';
list['내딛은 자의 자동권총'] = 'a2fd9406d6f3fab91c79cdb7fb0be03d';
list['내딛은 자의 광창'] = 'a40ff31c4a42fd388c715588f1f0b310';
list['내딛은 자의 투창'] = 'a6ce3570d897bc4ba6b2ff7d9cbb05cc';
list['내딛은 자의 봉'] = 'a8de3a32d1e7129c0b339a680f8e0464';
list['내딛은 자의 장궁'] = 'a94b771f890f4c649802ce3e9b129370';
list['내딛은 자의 코어 블레이드'] = 'a9bc9fa75ada95efc911668bc5f2ddf7';
list['내딛은 자의 장도'] = 'aa6e02d6f18e5f88cc9255e645988d2f';
list['내딛은 자의 토템'] = 'aaee94f9149957353a543c5eda83c696';
list['내딛은 자의 리볼버'] = 'ada9cce75f351749e25dc15fba68291a';
list['내딛은 자의 리볼버'] = 'b3258fb2e9eb5b9287f50baf213f7769';
list['내딛은 자의 십자가'] = 'b572e0462d0138a64ace237f2d6029c7';
list['내딛은 자의 핸드캐넌'] = 'b6d65864948c6d3e7ada7c2c8bdf9d41';
list['내딛은 자의 도'] = 'b8a51d7c294187e0062c59dc2a57bed8';
list['내딛은 자의 쌍검'] = 'bc066d2933579d53907f97e182037dea';
list['내딛은 자의 권투글러브'] = 'bceb987b34ccafd30baa2a2b946a82f1';
list['내딛은 자의 중검'] = 'bf2efbb61a3072c80fa51f061f62bea4';
list['내딛은 자의 염주'] = 'c1d99cc81e2860db1a627194f012ed7e';
list['내딛은 자의 대검'] = 'c8ed15d751ef7affb5c016dd3de870a6';
list['내딛은 자의 건틀릿'] = 'ce6bfd63a29640ba8cf5173947e13989';
list['내딛은 자의 소검'] = 'd46979700e78a28246f18e3284e2f142';
list['내딛은 자의 배틀액스'] = 'da1779e5fefb357579e11f9d642df606';
list['내딛은 자의 장창'] = 'dcbba45155090ac4ed7209faebb47387';
list['내딛은 자의 빗자루'] = 'dfab100a5c6b2f4acdb734ead0215aa9';
list['내딛은 자의 보우건'] = 'dff46902aac94ee9b15c5e36938fb31b';
list['내딛은 자의 토템'] = 'e1389c71519f724e7e7a933a16b94f41';
list['내딛은 자의 코어 블레이드'] = 'e81d270d505e6729378425849d0d46f1';
list['내딛은 자의 스태프'] = 'e8bec55411431e642be015a368b877a7';
list['내딛은 자의 투창'] = 'ebe48ff9e9b5fbc9f4f986bb879cc774';
list['내딛은 자의 둔기'] = 'ed42c25efd1418fcac34c4262e8ef7c6';
list['내딛은 자의 선현궁'] = 'ef82bed534c98f67a9f55453e7e64c6b';
list['내딛은 자의 소검'] = 'f0f945c1945e0df7ddbf8a8b9d2120ff';
list['내딛은 자의 도'] = 'f88c0859bb76970d3b7d58cdc6e812e5';
list['내딛은 자의 빗자루'] = 'fb08a9bf49960f79dbac47c30622f6e3';
list['내딛은 자의 머스켓'] = 'ffc0412ef74f376bf7f8e669c3d125ff';


export default list;
