function DamageRadio({ children, value, name, defaultChecked, disabled, present, setType,setRealGaksungName,characterData,setCharacterData }) {


    return (
      <div className='rkcbox'>
        <input
          type="radio"
          value={value}
          name={name}
          defaultChecked={defaultChecked}
          disabled={disabled}
          checked={present == value}
          id={"option02"+value}
          
          onClick = {()=>{setType(value);setRealGaksungName(name); weaponDelete(characterData,setCharacterData);}}
          />
        
        <label for={"option02"+value}>
        {name}
        </label>
      </div>
    );
  }

function weaponDelete(characterData,setCharacterData)
{
  let temp = {...characterData};

    if(temp['item100'] != null)
    {
      delete temp.item100;
    }
    
    setCharacterData(temp);
}

  export default DamageRadio
