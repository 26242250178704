import * as React from "react";

export default class BannerAd extends React.Component {
  constructor(props) {
    super(props);
    this.adRef = React.createRef();
  }

  componentDidMount() {
    console.log("mounting", this.adRef.current); 
    (window.top).__vm_add = (window.top).__vm_add || [];
    (window.top).__vm_add.push(this.adRef.current);
  }

  componentWillUnmount() {
    console.log("unmounting", this.adRef.current);
    (window.top).__vm_remove = (window.top).__vm_remove || [];
    (window.top).__vm_remove.push(this.adRef.current);
  }


  render() {
    const { placementId  } = this.props;
    if (!placementId) {
      return null;
    }
    return (
      <div>
        <div
          className="vm-placement"
          data-id={placementId}
          data-display-type="hybrid-banner"
          ref={this.adRef}
        ></div>
      </div>
    );
    
  
  }
}
