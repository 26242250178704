function Checkbox({ children, disabled, checked, onChange, defaultChecked ,value, setType,id,present}) {
    return (

        <div class="rkcbox">
            <input
            type="checkbox"
            id={id}
            
            value={value}
            checked={checked}
            onClick = {()=>{setType(value)}}

            />
            <label for={id}>
            {children}
            </label>
            
        </div>
    );
  }


  export default Checkbox
