function editSetting(characterObj,characterDataTemp,props)
{
    let traitList = [
        [
            111,112,113,114,
            121,122,123,124,
            131,132,133,
            141,142,143
        ],
        [
            211,212,214,
            221,222,224,
            231,232,
            241,242,
        ],
        [
            312,314,
            321,323,324,
            332,
            341,343,
            351,353
        ],
        [
            412,414,
            421,422,423,424,
            431,432,433,
            442,
            451,452,453
        ]
    ];

    let {talismanList,talismanSkillList,selectTalisType,selectTalisSkill,talismanToSkillName,setSelectSpecType,avatarTypeList} = props;
    let baseJob = characterObj.baseJob;
    let job = characterObj.job;
    switch(characterObj.job)
    {
        case "眞 소드마스터": job = "마제스티"; break;
        case "眞 데몬슬레이어": job = "디어사이드"; break;
        case "眞 베가본드": job = "검제"; break;
        case "眞 다크템플러": job = "네메시스"; break;
        case "眞 블레이드": job = "벤데타"; break;
        
        case "眞 넨마스터": if(baseJob.indexOf("(여)") != -1) job = "염제 폐월수화"; else job = "염황 광풍제월"; break;
        case "眞 그래플러": if(baseJob.indexOf("(여)") != -1) job = "얼티밋 디바"; else job = "그랜드 마스터"; break;
        case "眞 스트리트파이터": if(baseJob.indexOf("(여)") != -1) job = "용독문주";else  job = "명왕";  break;
        case "眞 스트라이커": if(baseJob.indexOf("(여)") != -1) job = "카이저"; else job = "패황"; break;
        
        case "眞 웨펀마스터": job = "검신"; break;
        case "眞 버서커": job = "블러드 이블"; break;
        case "眞 아수라": job = "인다라천"; break;
        case "眞 소울브링어": job = "다크로드"; break;
        case "眞 검귀": job = "악귀나찰"; break;
        

        case "眞 레인저": if(baseJob.indexOf("(여)") != -1) job = "크림슨 로제"; else job = "레이븐"; break;
        case "眞 런처": if(baseJob.indexOf("(여)") != -1) job = "스톰 트루퍼"; else job = "디스트로이어"; break;
        case "眞 메카닉": if(baseJob.indexOf("(여)") != -1) job = "옵티머스"; else job = "프라임"; break;
        case "眞 스핏파이어":if(baseJob.indexOf("(여)") != -1) job = "프레이야"; else  job = "커맨더"; break;
        case "眞 어썰트": job = "엑스마키나"; break;


        case "眞 크루세이더": if(baseJob.indexOf("(여)") != -1) job = "세라핌"; else job = "세인트"; break;
        case "眞 인파이터": job = "저스티스"; break;
        case "眞 퇴마사": job = "태을선인"; break;
        case "眞 어벤저": job = "이모탈"; break;
        
        case "眞 엘레멘탈마스터": job = "오버마인드"; break;
        case "眞 배틀메이지": job = "아슈타르테"; break;
        case "眞 마도학자": job = "지니위즈"; break;
        case "眞 소환사": job = "이클립스"; break;
        case "眞 인챈트리스": job = "헤카테"; break;
        case "블랙 메이든": job = "헤카테"; break;
        
        case "眞 미스트리스": job = "리디머"; break;
        case "眞 무녀": job = "천선낭랑"; break;
        case "眞 이단심판관": job = "인페르노"; break;
        
        case "眞 로그": job = "알키오네"; break;
        case "眞 사령술사": job = "타나토스"; break;
        case "眞 섀도우댄서": job = "그림리퍼"; break;
        case "眞 쿠노이치": job = "시라누이"; break;
        
        case "眞 스위프트 마스터": job = "아이올로스"; break;
        case "眞 블러드 메이지": job = "뱀파이어 로드"; break;
        case "眞 엘레멘탈 바머": job = "오블리비언"; break;
        case "眞 디멘션워커": job = "어센션"; break;
        case "眞 빙결사": job = "이터널"; break;
        

        case "眞 팔라딘": job = "세이비어"; break;
        case "眞 엘븐나이트": job = "가이아"; break;
        case "眞 드래곤나이트": job = "드레드노트"; break;
        case "眞 카오스": job = "마신"; break;
        

        case "眞 뱅가드": job = "워로드"; break;
        case "眞 듀얼리스트": job = "듀란달"; break;
        case "眞 다크 랜서": job = "에레보스"; break;
        case "眞 드래고니안 랜서": job = "제노사이더"; break;
        
        case "眞 요원": job ="레퀴엠"; break;
        case "眞 트러블 슈터": job = "언터처블"; break;
        case "眞 스페셜리스트":job = "패스파인더"; break;
        case "眞 히트맨": job = "갓파더"; break;
    
        case "眞 다크나이트": job = "다크나이트(자각2)"; break;
        case "眞 크리에이터": job = "크리에이터(자각2)"; break;
        
        case "眞 뮤즈": job ="트렌드세터"; break;
        case "眞 트래블러": job ="셀레스티얼"; break;
        case "眞 헌터": job ="메이븐"; break;
        case "眞 비질란테": job ="프레데터"; break;
        
        
    }

    //성단
    console.log(characterObj.cluster);
    console.log(characterObj.cluster <= 0);

    if(characterObj.cluster == null)
    {
        characterDataTemp['cluster'] = 1;
    }
    else
    {
        characterDataTemp['cluster'] = characterObj.cluster <= 0 ? 1 : characterObj.cluster;

    }
    if(characterObj.clusterEnd == null)
    {
        characterDataTemp['clusterEnd'] = 0;
    }
    else
    {
        characterDataTemp['clusterEnd'] = characterObj.clusterEnd;
    }


    let category = 1;

    for(let list of traitList)
    {
        for(let trait of list)
        {
            if(characterObj.trait[trait] != null)
            {
                characterDataTemp['spec'+trait] = characterObj.trait[trait];
                category = Math.floor(trait/100);
            }
            else
            {
                characterDataTemp['spec'+trait] = 0;
            }
        }
    }
    setSelectSpecType(category);
    
    characterDataTemp['job'] = job;
    characterDataTemp['jinJob'] = characterObj.job;

    //탈리스만 스킬 셋팅
    let talisCount = 0 ;
    for(let loop = 0 ; loop < talismanList[job].length ; loop++)
    {
        if( talismanList[job][loop].indexOf('성흔의 탈리스만[') != -1)
        {
            talismanSkillList[talisCount++] = talismanList[job][loop].split('[')[1].split(']')[0];
        }
    }

    //크리쳐 셋팅
    characterDataTemp['creaName'] = "없음";

    if(characterObj.crea == null)
    {
        characterObj.crea = "";
    }

    if(characterObj.crea.indexOf("% ") != -1 || characterObj.crea.indexOf("정령왕") != -1  )
    {
        console.log(characterObj.crea.split("%")[0].split(" ")[0].replace(" 크리쳐",""));

        switch(characterObj.crea.split("%")[0].split(" ")[0].replace(" 크리쳐",""))
        {
            case "피증" : characterDataTemp['creaName'] = "피해 증가"; characterDataTemp['creaValue'] = characterObj.crea.split("%")[0].split(" ")[1]; break;
            case "크증" : characterDataTemp['creaName'] = "크리티컬 증가 데미지"; characterDataTemp['creaValue'] = characterObj.crea.split("%")[0].split(" ")[1]; break;
            case "증뎀" : characterDataTemp['creaName'] = "데미지 증가"; characterDataTemp['creaValue'] = characterObj.crea.split("%")[0].split(" ")[1]; break;
            case "정령왕" : characterDataTemp['creaName'] = "정령왕"; break;
            case "물마독" : characterDataTemp['creaName'] = "물리 마법 독립 공격력 증가"; characterDataTemp['creaValue'] = characterObj.crea.split("%")[0].split(" ")[1]; break;
            case "모공" : characterDataTemp['creaName'] = "모든 공격력 증가"; break;
            
        }


    }
    else if(characterObj.crea.indexOf("모공 ") != -1)
    {
        characterDataTemp['creaName'] = "모든 공격력 증가";
        characterDataTemp['creaValue'] = 15;
    }

    
    let equipTemp = [...characterObj.equip];


    let statSkill = "";
    let awakeSkill = "";

    if(characterDataTemp['job'].indexOf("세라핌") != -1)
    {
        statSkill = "계시 : 아리아";
        awakeSkill = '신실한 열정';
    }
    else if(characterDataTemp['job'].indexOf("세인트") != -1)
    {
        statSkill = "수호의 은총";
        awakeSkill = '신념의 오라';
    }
    else if(characterDataTemp['job'].indexOf("트렌드세터") != -1)
    {
        statSkill = "센세이션";
        awakeSkill = '유명세';
    }
    else
    {
        statSkill = "퍼페티어";
        awakeSkill = '소악마';
    }

   
    let title = false;
    for(let loop = 0 ; loop < equipTemp.length; loop++)
    {
        if(equipTemp[loop].slot == "칭호")
        {
            title = true;
            break;
        }
    }

    if(characterObj.title == null || !title)
    {
        equipTemp.splice(1,0,{"slot":"칭호"});
        characterObj.title = "";
        

    }

    
    for(let loop = 0 ; loop < equipTemp.length ; loop++)
    {
        if(equipTemp[loop].slot == '보조무기')
        {
            
            if(job == '검제')
            {
                characterDataTemp['subWeapon'] = "근원을 삼킨 광검";
                characterDataTemp['subWeaponReinforceType'] = equipTemp[loop].reinforceType;
                characterDataTemp['subWeaponReinforce'] = equipTemp[loop].reinforceNum.replace('+','');
            }
            
            equipTemp[loop] = equipTemp[loop+1];
            equipTemp[loop+1] = null;
            break;
        }
    }


    for(let loop = 0 ; loop < equipTemp.length; loop++)
    {
        
        let equip = equipTemp[loop];
        
        if(equip == null)
        {
            continue;
        }
        

        
        if(equip.slot == "칭호")
        {
            if(characterObj.title == "")
            {
                characterDataTemp['equipName'+loop] = '없음';
                continue;
            }
            
            if(characterObj.buffCal != null)
            {
                characterDataTemp['equipName'+loop] = '없음';
                if(characterObj.title.indexOf("18% 피증칭호") != -1)
                {
                    characterDataTemp['equipName'+loop] = '버프력 + 2%';
                }
                else if(equip.enchant.indexOf('+ 2\n') != -1 && equip.enchant.indexOf('속강') == -1 )
                {
                    characterDataTemp['equipName'+loop] = '50Lv + 2';
                }
                else if(equip.enchant.indexOf('+ 1\n') != -1 && equip.enchant.indexOf('속강') == -1 )
                {
                    characterDataTemp['equipName'+loop] = '50Lv + 1';
                }

                if(characterObj.title.indexOf("세리아의 풍성한 선물") != -1)
                {
                    characterDataTemp['equipName'+loop] = '세리아의 풍성한 선물';
                }

                
                
            }
            else
            {
                //console.log(characterObj.title);
                //console.log(characterObj.title.indexOf("% "));
                if(characterObj.title.indexOf("% ") != -1)
                {
                    characterDataTemp['equipName'+loop] = characterObj.title.split("% ")[1];

                    switch(characterDataTemp['equipName'+loop])
                    {
                        case "피증칭호": characterDataTemp['equipName'+loop] = "피해 증가"; break;
                        case "증뎀칭호": characterDataTemp['equipName'+loop] = "데미지 증가"; break;
                    }
                    
                    characterDataTemp['equipValue'+loop] = characterObj.title.split("% ")[0];

                }
                else if(characterObj.title.indexOf("속강칭호") != -1)
                {
                    characterDataTemp['equipValue'+loop] = characterObj.title.replace(" 속강칭호","");
                    characterDataTemp['equipName'+loop] = "속성 강화";
                }
                else
                {
                    characterDataTemp['equipName'+loop] = characterObj.title;
                }

                //레벨 증뎀

                if(equip.name.indexOf("Lv]") != -1)
                {
                    //console.log(equip.name);
                    
                    characterDataTemp['equip1Skill'] = equip.name.split('[')[1].replace('Lv]','');
                }
                else
                {
                    characterDataTemp['equip1Skill'] = 0;
                }
            }
            

        }
        else
        {
            if(equip.name.indexOf("불가침의 영역") != -1 )
            {
                characterDataTemp['equipName'+loop] = equip.name.replace("仙 : ","")
                .replace("판금 ","")
                .replace("천 ","")
                .replace("경갑 ","")
                .replace("중갑 ","")
                .replace("가죽 ","");

            }
            else
            {
                characterDataTemp['equipName'+loop] = equip.name.replace("仙 : ","");
            }

        }

        if(equip.mistGear != null)
        {
            characterDataTemp['mistGear'+loop] = equip.mistGear;
        }

        characterDataTemp['itemLevel'+loop] = equip.itemLevel;
        if(equip.refine != null)
        {
            characterDataTemp['refine'+loop] = equip.refine;

        } //재련

        characterDataTemp['equipReinforceNum'+loop] = equip.reinforceNum.replace('+','');
        characterDataTemp['equipReinforceType'+loop] = equip.reinforceType;
        characterDataTemp['equipEnchant'+loop] = equip.enchant;

        characterDataTemp['equipEnchantStat'+loop] = 0;
        characterDataTemp['equipEnchantAttack'+loop] = 0;
        characterDataTemp['equipEnchantEle'+loop] = 0;
        characterDataTemp['equipEnchantEtc'+loop] = 0;

        if(characterObj['equipOption'+loop] != null)
        {
            for(let optionLoop = 0 ; optionLoop < 4 ; optionLoop++)
            {
                characterDataTemp['equipCustom'+loop+'C'+optionLoop] = characterObj['equipOption'+loop].split(",")[optionLoop];
            }
        }


        for(let enchant of equip.enchant.split("\n"))
        {
            if(enchant.indexOf("스탯 + ") != -1)
            {
                characterDataTemp['equipEnchantStat'+loop] = enchant.replace("스탯 + ","").trim();
            }
            else if(enchant.indexOf("공격력 + ") != -1)
            {
                characterDataTemp['equipEnchantAttack'+loop] = enchant.replace("공격력 + ","").trim();
            }
            else
            {
                if(enchant.indexOf("속강 + ") != -1)
                {
                    characterDataTemp['equipEnchantEle'+loop] = enchant.split("속강 + ")[1];
                }
                if(enchant.indexOf("스증 + ") != -1)
                {
                    characterDataTemp['equipEnchantEtc'+loop] = enchant.split("스증 + ")[1].replace("%","");
                }
                if(enchant.indexOf(statSkill) != -1 )
                {
                    characterDataTemp['equipEnchantEtc'+loop] = enchant.split(statSkill+" + ")[1];
                }
                if(enchant.indexOf(awakeSkill) != -1 )
                {
                    characterDataTemp['equipEnchantEtc'+loop] = enchant.split(awakeSkill+" + ")[1];
                }
                if(enchant.indexOf("1각패") != -1 )
                {
                    characterDataTemp['equipEnchantEtc'+loop] = 2;
                }
                
                //characterDataTemp['equipEnchantEtc'+loop] = enchant.replace("스증 + ","").trim();
            }
        }
        
        let count = 0;
        for(let lvLoop of  equip.lv105Leveling.split(' <br> ')[0].replace(/ \/ /gi,',').split(','))
        {
            characterDataTemp['equipLevel'+loop +'C'+(count++)] = lvLoop;
        }

        if(equip.siroco.indexOf("仙") != -1)
        {
            characterDataTemp['siroco'+loop] = equip.siroco.replace("仙","") + "仙";
        }
        else
        {
            if(equip.sirocoReal != null)
            if(equip.sirocoReal.indexOf(" : ") != -1 && equip.sirocoReal.indexOf("수호 : 혼") == -1  )
            {
                characterDataTemp['siroco'+loop] = equip.siroco;
            }
            else
            {
                characterDataTemp['siroco'+loop] = equip.sirocoReal;
                
                
                if(equip.sirocoReal.includes("영원의 조각 -"))
                {
                    characterDataTemp['legendStone'+loop+"_1"] = characterObj['legendStone'+loop+'_1'];
                    characterDataTemp['legendStone'+loop+"_2"] = characterObj['legendStone'+loop+'_2'];
                    characterDataTemp['legendStone'+loop+"_3"] = characterObj['legendStone'+loop+'_3'];
                }
            }

            for(let upLoop = 0 ; upLoop < 3 ; upLoop++)
            {
                console.log(equip['sirocoUp'+upLoop]);
                if(equip['sirocoUp'+upLoop]!= null)
                {
                    characterDataTemp['siroco'+loop+'up'+upLoop] = equip['sirocoUp'+upLoop];

                }
            }

            console.log(characterDataTemp);
        }

        //characterDataTemp['equipOption'+loop] = equip.siroco;

    }
    
    //엠블렘 init
    for(let tempLoop = 0 ; tempLoop < avatarTypeList.length ; tempLoop++)
    {
        for(let emblemLoop = 0 ; emblemLoop < 2 ; emblemLoop++)
        {
            characterDataTemp['emblemStat'+emblemLoop+tempLoop] = 0;
            characterDataTemp['emblemAS'+emblemLoop+tempLoop] = 0;
            characterDataTemp['emblemResist'+emblemLoop+tempLoop] = 0;
        }
    }
    characterDataTemp['abaOption10'] ="없음";

    for(let loop = 0 ; loop < characterObj.avatars.length ; loop++)
    {
        let ava = characterObj.avatars[loop];

        let type = 50;

        for(let tempLoop = 0 ; tempLoop < avatarTypeList.length ; tempLoop++)
        {
            if(ava.slot == avatarTypeList[tempLoop])
            {
                type = tempLoop;
                break;
            }
        }
        
        if(type == 50)
        {
            continue;
        }

        characterDataTemp['aba'+type] = ava.name; //레어,캐스팅 속도 14.0% 증가,s17,s17
        characterDataTemp['abaRarity'+type] = ava.rarity.replace("커먼","상급"); //레어,캐스팅 속도 14.0% 증가,s17,s17
        characterDataTemp['abaOption'+type] = ava.option;
        
        if(type == 10)
        {

            //characterDataTemp['aba'+type] = ava.cloneName; //레어,캐스팅 속도 14.0% 증가,s17,s17
            characterDataTemp['abaOption'+type] = ava.option.replace("지능","스탯")
            .replace("체력","스탯")
            .replace("정신력","스탯")
            .replace("힘","스탯");

            if(characterDataTemp['abaOption'+type] == "")
            {
                characterDataTemp['abaOption'+type] = "없음";
            }
            
            //무기아바타
            if(ava.cloneName != null)
            {
                if(ava.cloneName.indexOf('Lv]') != -1)
                {
                    characterDataTemp['aba10Skill'] = ava.cloneName.split("[")[1].split('Lv]')[0];
                }
                else
                {
                    characterDataTemp['aba10Skill'] = 0;
                }
            }
            else
            {
                if(ava.name.indexOf('Lv]') != -1)
                {
                    characterDataTemp['aba10Skill'] = ava.name.split("[")[1].split('Lv]')[0];
                }
                else
                {
                    characterDataTemp['aba10Skill'] = 0;
                }
            }
        }

        if(type == 3 || type == 4)
        {
            characterDataTemp['emblemSkill'+type] = ava.emblem1;
        }

        if(type == 9)
        {
            //console.log(characterObj.auraAva);

            if(characterObj.auraAva.indexOf(" ") != -1)
            {
                for(let aura of characterObj.auraAva.split(" "))
                {
                    switch(aura.split("+")[0])
                    {
                        case "스탯": characterDataTemp['aba9Stat'] = aura.split("+")[1]; break;
                        case "속강": characterDataTemp['aba9Element'] = aura.split("+")[1]; break;
                        case "공격력": characterDataTemp['aba9Attack'] = aura.split("+")[1]; break;
                    }
                    
                }
            }
        }

        for(let emblemLoop = 0 ; emblemLoop < 2 ; emblemLoop++)
        {
            let temp = "";

            switch(emblemLoop)
            {
                case 0 : temp = ava.emblem2; break;
                case 1 : temp = ava.emblem3; break;
            }
            characterDataTemp['emblemStat'+emblemLoop+type] = 0;
            characterDataTemp['emblemAS'+emblemLoop+type] = 0;
            characterDataTemp['emblemResist'+emblemLoop+type] = 0;

            if(temp.indexOf("스탯 + ") != -1)
            {
                characterDataTemp['emblemStat'+emblemLoop+type] = temp.replace("스탯 + ","");
            }
            else if(temp.indexOf("모속저 + ") != -1)
            {
                characterDataTemp['emblemResist'+emblemLoop+type] = temp.replace("모속저 + ","");
            }
            else if(temp.indexOf("공속 + ") != -1)
            {
                characterDataTemp['emblemAS'+emblemLoop+type] = temp.replace("공속 + ","");
            }

            else if(temp.indexOf("듀얼") != -1)
            {
                if(temp.indexOf("힘") != -1
                || temp.indexOf("지능") != -1
                || temp.indexOf("정신력") != -1
                || temp.indexOf("체력") != -1)
                {
                    if(temp.indexOf("찬란한") != -1)
                    {
                        characterDataTemp['emblemStat'+emblemLoop+type] = 15;
                    }
                    else if(temp.indexOf("화려한") != -1)
                    {
                        characterDataTemp['emblemStat'+emblemLoop+type] = 10;
                    }
                }

                else if(temp.indexOf("공격속도") != -1)
                {
                    if(temp.indexOf("찬란한") != -1 )
                    {
                        characterDataTemp['emblemAS'+emblemLoop+type] = 0.8;
                    }
                    else if(temp.indexOf("화려한") != -1)
                    {
                        characterDataTemp['emblemAS'+emblemLoop+type] = 0.6;
                    }

                }
            }
            else if(temp.indexOf("그린") != -1 || temp.indexOf("옐로우") != -1 )
            {
                
                if(temp.indexOf("힘") != -1
                || temp.indexOf("지능") != -1
                || temp.indexOf("정신력") != -1
                || temp.indexOf("체력") != -1)
                {
                    if(temp.indexOf("찬란한") != -1)
                    {
                        characterDataTemp['emblemStat'+emblemLoop+type] = 15;
                    }
                    else if(temp.indexOf("화려한") != -1)
                    {
                        characterDataTemp['emblemStat'+emblemLoop+type] = 10;
                    }
                }
            }
            else
            {
                if(temp.indexOf("힘") != -1
                || temp.indexOf("지능") != -1
                || temp.indexOf("정신력") != -1
                || temp.indexOf("체력") != -1)
                {
                    if(temp.indexOf("찬란한") != -1)
                    {
                        characterDataTemp['emblemStat'+emblemLoop+type] = 25;
                    }
                    else if(temp.indexOf("화려한") != -1)
                    {
                        characterDataTemp['emblemStat'+emblemLoop+type] = 17;
                    }
                    else if(temp.indexOf("빛나는") != -1)
                    {
                        characterDataTemp['emblemStat'+emblemLoop+type] = 10;
                    }
                }

                else if(temp.indexOf("모든속성저항") != -1)
                {
                    if(temp.indexOf("찬란한") != -1)
                    {
                        characterDataTemp['emblemResist'+emblemLoop+type] = 5;
                    }
                    else if(temp.indexOf("화려한") != -1)
                    {
                        characterDataTemp['emblemResist'+emblemLoop+type] = 3;
                    }
                    else if(temp.indexOf("빛나는") != -1)
                    {
                        characterDataTemp['emblemResist'+emblemLoop+type] = 2;
                    }
                }

                else if(temp.indexOf("공격속도") != -1)
                {
                    if(temp.indexOf("찬란한") != -1)
                    {
                        characterDataTemp['emblemAS'+emblemLoop+type] = 1.5;
                    }
                    else if(temp.indexOf("화려한") != -1)
                    {
                        characterDataTemp['emblemAS'+emblemLoop+type] = 1.1;
                    }
                    else if(temp.indexOf("빛나는") != -1)
                    {
                        characterDataTemp['emblemAS'+emblemLoop+type] = 0.8;
                    }
                }
            }
        }

    



        //characterDataTemp['equipOption'+loop] = equip.siroco;
    }

    for(let loop = 0 ; loop < characterObj.talismans.length ; loop++)
    {

        let talisman = characterObj.talismans[loop];
        ////console.log(talisman);
        if(talisman.talisman == null)
        {
            continue;
        }
        //characterDataTemp['talis'+(loop+1)] = talisman.talisman;

        selectTalisSkill[loop] = talismanToSkillName(job,talisman.talisman);

        if(talisman.talisman.indexOf('카펠라의 성흔') != -1)
        {
            selectTalisType[loop] = 3;
        }
        else if(talisman.talisman.indexOf('성흔의 탈리스만') != -1)
        {
            selectTalisType[loop] = 1;
        }
        else if(talisman.talisman.indexOf('온전한 탈리스만') != -1)
        {
            selectTalisType[loop] = 0;
        }
        else
        {
            selectTalisType[loop] = 2;
        }
        

        for(let runeLoop = 0 ; runeLoop < 3 ; runeLoop++)
        {
            
            if(talisman['rune'+(runeLoop+1)] == null)
            {
                
                characterDataTemp['rune'+(loop*3+runeLoop+1)+"type"] = "";
                characterDataTemp['rune'+(loop*3+runeLoop+1)+"Skill"] = '';
                characterDataTemp['rune'+(loop*3+runeLoop+1)+"rarity"] = '';
                continue;
            }
            if(talisman['rune'+(runeLoop+1)].indexOf("테라코타") != -1)
            {
                characterDataTemp['rune'+(loop*3+runeLoop+1)+"type"] = "테라코타";
            }
            else if(talisman['rune'+(runeLoop+1)].indexOf("세컨드 팩트") != -1)
            {
                characterDataTemp['rune'+(loop*3+runeLoop+1)+"type"] = "세컨드 팩트";
            }
            else if(talisman['rune'+(runeLoop+1)].indexOf("서클 메이지") != -1)
            {
                characterDataTemp['rune'+(loop*3+runeLoop+1)+"type"] = "서클 메이지";
            }
            else if(talisman['rune'+(runeLoop+1)].indexOf("수호자들") != -1)
            {
                characterDataTemp['rune'+(loop*3+runeLoop+1)+"type"] = "수호자들";
            }
            else if(talisman['rune'+(runeLoop+1)].indexOf("고대 도서관") != -1)
            {
                characterDataTemp['rune'+(loop*3+runeLoop+1)+"type"] = "고대 도서관";
            }
            
            characterDataTemp['rune'+(loop*3+runeLoop+1)+"Skill"] = talisman['rune'+(runeLoop+1)].split("[")[1].split(']')[0];

            characterDataTemp['rune'+(loop*3+runeLoop+1)+"rarity"] = talisman['rune'+(runeLoop+1)].split(" ")[0];
        }
        

        

        //characterDataTemp['equipOption'+loop] = equip.siroco;
    }
    //console.log(selectTalisSkill);
    
    //무기융합
    
    characterDataTemp['weapon1'] = characterObj['weapon1'];
    characterDataTemp['weapon2'] = characterObj['weapon2'];
    characterDataTemp['weapon3'] = characterObj['weapon3'];

    //휘장 잼

    characterDataTemp['flag'] = characterObj['flag'];
    characterDataTemp['flagReinforceNum'] = characterObj['flagReinforceNum'];
    for(let loop = 0 ; loop < 4 ; loop++)
    {
        characterDataTemp['gem'+(loop+1)] = characterObj['gem'].split(',')[loop];
    }

    for(let loop = 0 ; loop < 3 ; loop++)
    {
        characterDataTemp['afti'+(loop)] = characterObj['afti'+(loop)];
    }

    // for(let loop = 0 ; loop < 3 ; loop++)
    // {
    //     //console.log('특성 : ' + characterObj['spec'+(loop)] );
    //     if(characterObj['spec'+(loop)] != null)
    //     {
    //         if(loop == 0)
    //         {
    //             setSelectSpecType(parseInt(characterObj['spec'+(loop)]/100));
    //         }
    //         characterDataTemp['spec'+(loop)] = characterObj['spec'+(loop)];
    //     }
    //     else
    //     {
    //         characterDataTemp['spec'+(loop)] = 0;
    //     }
    // }

    // for(let loop = 4 ; loop < 6 ; loop++)
    // {
    //     //console.log('특성 : ' + characterObj['spec'+(loop)] );
    //     if(characterObj['spec'+(loop)] != null)
    //     {
    //         if(loop == 0)
    //         {
    //             setSelectSpecType(parseInt(characterObj['spec'+(loop)]/100));
    //         }
    //         characterDataTemp['spec'+(loop)] = characterObj['spec'+(loop)];
    //     }
    //     else
    //     {
    //         characterDataTemp['spec'+(loop)] = 0;
    //     }
    // }


    characterDataTemp['adventure'] = characterObj['adventureLevel'];

    //버퍼 버프강화

    if(characterObj.buffCal != null)
    {
        if(characterObj['bufferAva'] != null)
        {
            characterDataTemp['buffTitle'] =  characterObj['buffTitle'];

            characterDataTemp['abaOption113'] =  characterObj['bufferAva']['option113'];

            characterDataTemp['emblemSkill113'] =  characterObj['bufferAva']['emblem1130'];
            characterDataTemp['emblemStat0113'] =  characterObj['bufferAva']['emblem1131'];
            characterDataTemp['emblemStat1113'] =  characterObj['bufferAva']['emblem1132'];

            characterDataTemp['emblemSkill114'] =  characterObj['bufferAva']['emblem1140'];
            characterDataTemp['emblemStat0114'] =  characterObj['bufferAva']['emblem1141'];
            characterDataTemp['emblemStat1114'] =  characterObj['bufferAva']['emblem1142'];

        }
    }
    
    //console.log("init");
            
}


let avatarTypeList = ["모자 아바타", "머리 아바타", "얼굴 아바타", "상의 아바타" ,"하의 아바타","신발 아바타","목가슴 아바타","허리 아바타","스킨 아바타","오라 아바타","무기 아바타"]; 

const gradeToNum = {"에픽":"5","레전더리":"4","유니크":"3","레어":"2","언커먼":"1","커먼":"0"};

let talismanList = {
    '네메시스' : ['카펠라의 성흔 : 리인포스 베리어','리인포스 베리어','온전한 탈리스만[블랙 미러]','성흔의 탈리스만[블랙 미러]','카펠라의 성흔 : 쉐도우 템페스트','쉐도우 템페스트','온전한 탈리스만[데들리 케이프]','성흔의 탈리스만[데들리 케이프]','카펠라의 성흔 : 익스터미네이트','익스터미네이트','온전한 탈리스만[디스트로이어]','성흔의 탈리스만[디스트로이어]','카펠라의 성흔 : 쉐도우 스웜','쉐도우 스웜','온전한 탈리스만[스타폴]','성흔의 탈리스만[스타폴]','카펠라의 성흔 : 오퍼드 세크리파이스','오퍼드 세크리파이스','온전한 탈리스만[이몰레이션]','성흔의 탈리스만[이몰레이션]','카펠라의 성흔 : 폴리그라마 레이','성흔의 탈리스만[데우스 엑스 레이]','카펠라의 성흔 : 위대한 성전의 검','성흔의 탈리스만[지하드]'],
    '디어사이드' : ['카펠라의 성흔 : 광란의 여왕','광란의 여왕','온전한 탈리스만[혈화난무]','성흔의 탈리스만[혈화난무]','카펠라의 성흔 : 마인의 군대','마인의 군대','온전한 탈리스만[혈지군무]','성흔의 탈리스만[혈지군무]','카펠라의 성흔 : 콜 다이무스','콜 다이무스','온전한 탈리스만[검마격살]','성흔의 탈리스만[검마격살]','카펠라의 성흔 : 마인 감옥','마인 감옥','온전한 탈리스만[역천의 프놈]','성흔의 탈리스만[역천의 프놈]','카펠라의 성흔 : 혈마연격','혈마연격','온전한 탈리스만[혈마검]','성흔의 탈리스만[혈마검]','카펠라의 성흔 : 메가 슬래쉬','성흔의 탈리스만[암연격 : 기가슬래쉬]','카펠라의 성흔 : 처형외도 : 연계','성흔의 탈리스만[비인외도 : 극]'],
    '검제' : ['카펠라의 성흔 : 일화통암','일화통암','온전한 탈리스만[난화검]','성흔의 탈리스만[난화검]','카펠라의 성흔 : 파적공','파적공','온전한 탈리스만[폭멸장]','성흔의 탈리스만[폭멸장]','카펠라의 성흔 : 만리일섬','만리일섬','온전한 탈리스만[혜성만리]','성흔의 탈리스만[혜성만리]','카펠라의 성흔 : 일몰여평섬','일몰여평섬','온전한 탈리스만[십자검]','성흔의 탈리스만[십자검]','카펠라의 성흔 : 무형장','무형장','온전한 탈리스만[백보신장]','성흔의 탈리스만[백보신장]','카펠라의 성흔 : 무비일검','성흔의 탈리스만[연화검무]','카펠라의 성흔 : 이형환위섬','성흔의 탈리스만[연화섬]'],
    '마제스티' : ['카펠라의 성흔 : 문플래쉬','문플래쉬','온전한 탈리스만[반월]','성흔의 탈리스만[반월]','카펠라의 성흔 : 폭축집중','폭축집중','온전한 탈리스만[폭명기검]','성흔의 탈리스만[폭명기검]','카펠라의 성흔 : 데빌슬레이어','데빌슬레이어','온전한 탈리스만[악즉참]','성흔의 탈리스만[악즉참]','카펠라의 성흔 : 피어싱 블레이드','피어싱 블레이드','온전한 탈리스만[용성락]','성흔의 탈리스만[용성락]','카펠라의 성흔 : 엑스트라 익스플로젼','엑스트라 익스플로젼','온전한 탈리스만[참마검]','성흔의 탈리스만[참마검]','카펠라의 성흔 : 블레이드 템페스트','성흔의 탈리스만[마검방출]','카펠라의 성흔 : 올 포 원','성흔의 탈리스만[극 귀검술 : 파검무]'],
    '가이아' : ['카펠라의 성흔 : 뱅가드 스플래쉬','뱅가드 스플래쉬','온전한 탈리스만[뱅가드 스트랏슈]','성흔의 탈리스만[뱅가드 스트랏슈]','카펠라의 성흔 : 자연의 바람','자연의 바람','온전한 탈리스만[런지 에볼루션]','성흔의 탈리스만[런지 에볼루션]','카펠라의 성흔 : 뉴트럴 헌팅','뉴트럴 헌팅','온전한 탈리스만[분쇄]','성흔의 탈리스만[분쇄]','카펠라의 성흔 : 생명의 온기','생명의 온기','온전한 탈리스만[생명의 전조]','성흔의 탈리스만[생명의 전조]','카펠라의 성흔 : 에이션트 유니언','에이션트 유니언','온전한 탈리스만[신뢰의 돌파]','성흔의 탈리스만[신뢰의 돌파]','카펠라의 성흔 : 위드 투 시드','성흔의 탈리스만[체인 스트라이크]','카펠라의 성흔 : 앵거 오브 카멜리나','성흔의 탈리스만[자연의 분노]'],
    '갓파더' : ['카펠라의 성흔 : 시크릿 서비스','시크릿 서비스','온전한 탈리스만[알파 스트라이크]','성흔의 탈리스만[알파 스트라이크]','카펠라의 성흔 : 광풍노도','광풍노도','온전한 탈리스만[휩쓸기]','성흔의 탈리스만[휩쓸기]','카펠라의 성흔 : 황혼의 검사','황혼의 검사','온전한 탈리스만[최후의 일격]','성흔의 탈리스만[최후의 일격]','카펠라의 성흔 : 와일드 헌트','와일드 헌트','온전한 탈리스만[포인트 블랭크]','성흔의 탈리스만[포인트 블랭크]','카펠라의 성흔 : 천벌','천벌','온전한 탈리스만[헬릭스 다이브]','성흔의 탈리스만[헬릭스 다이브]','카펠라의 성흔 : 리트릿 파이어','성흔의 탈리스만[택티컬 오펜시브]','카펠라의 성흔 : 롤랑 스페셜','성흔의 탈리스만[벽파일섬]'],
    '검신' : ['카펠라의 성흔 : 명가의 손놀림','명가의 손놀림','온전한 탈리스만[발도]','성흔의 탈리스만[발도]','카펠라의 성흔 : 맹룡선풍참','맹룡선풍참','온전한 탈리스만[맹룡단공참]','성흔의 탈리스만[맹룡단공참]','카펠라의 성흔 : 그림자 유희','그림자 유희','온전한 탈리스만[환영검무]','성흔의 탈리스만[환영검무]','카펠라의 성흔 : 유성의 심판','유성의 심판','온전한 탈리스만[극 귀검술 : 유성락]','성흔의 탈리스만[극 귀검술 : 유성락]','카펠라의 성흔 : 쾌속발도','쾌속발도','온전한 탈리스만[극초발도]','성흔의 탈리스만[극초발도]','카펠라의 성흔 : 일합잔상','성흔의 탈리스만[극 귀검술 : 심검]','카펠라의 성흔 : 최후일각','성흔의 탈리스만[극 발검술 : 섬단]'],
    '그랜드 마스터' : ['카펠라의 성흔 : 뇌천파쇄','뇌천파쇄','온전한 탈리스만[매그넘설트]','성흔의 탈리스만[매그넘설트]','카펠라의 성흔 : 사이클론 스파이크','사이클론 스파이크','온전한 탈리스만[롤링 스파이크]','성흔의 탈리스만[롤링 스파이크]','카펠라의 성흔 : 와일드 크러셔','와일드 크러셔','온전한 탈리스만[와일드 캐넌 스파이크]','성흔의 탈리스만[와일드 캐넌 스파이크]','카펠라의 성흔 : 콤비네이션 러쉬','콤비네이션 러쉬','온전한 탈리스만[피어스]','성흔의 탈리스만[피어스]','카펠라의 성흔 : 강맹격파','강맹격파','온전한 탈리스만[한놈만 팬다!!]','성흔의 탈리스만[한놈만 팬다!!]','카펠라의 성흔 : 완전제압','성흔의 탈리스만[흑진광풍]','카펠라의 성흔 : 페이스 투 페이스','성흔의 탈리스만[오의 : 질풍노도]'],
    '그림리퍼' : ['카펠라의 성흔 : 칼날폭풍','칼날폭풍','온전한 탈리스만[칼날돌풍]','성흔의 탈리스만[칼날돌풍]','카펠라의 성흔 : 춤추는 그림자','춤추는 그림자','온전한 탈리스만[파이널 디스트럭션]','성흔의 탈리스만[파이널 디스트럭션]','카펠라의 성흔 : 암살의 정석 : 침착','암살의 정석 : 침착','온전한 탈리스만[암살]','성흔의 탈리스만[암살]','카펠라의 성흔 : 퍼펙트 어쌔신','퍼펙트 어쌔신','온전한 탈리스만[단검투척]','성흔의 탈리스만[단검투척]','카펠라의 성흔 : 버스트 스팅','버스트 스팅','온전한 탈리스만[섀도우 스팅]','성흔의 탈리스만[섀도우 스팅]','카펠라의 성흔 : 석세시브 데스','성흔의 탈리스만[죽음의 연무]','카펠라의 성흔 : 섀도우 블리츠','성흔의 탈리스만[페이탈 블리츠]'],
    '다크나이트(자각2)' : ['카펠라의 성흔 : 다크 데몰리션','다크 데몰리션','온전한 탈리스만[다크 폴]','성흔의 탈리스만[다크 폴]','카펠라의 성흔 : 다크 블리스터링 소드','다크 블리스터링 소드','온전한 탈리스만[다크 플레임 소드]','성흔의 탈리스만[다크 플레임 소드]','카펠라의 성흔 : 언리미티드 콤보','언리미티드 콤보','온전한 탈리스만[일루젼 슬래쉬]','성흔의 탈리스만[일루젼 슬래쉬]','카펠라의 성흔 : 스플리트 세컨드','성흔의 탈리스만[팬텀 소드]','카펠라의 성흔 : 다크 리버레이션','다크 리버레이션','온전한 탈리스만[다크 웨이브 폴]','성흔의 탈리스만[다크 웨이브 폴]','카펠라의 성흔 : 모멘트 익스플로전','모멘트 익스플로전','온전한 탈리스만[차지 익스플로전]','성흔의 탈리스만[차지 익스플로전]','카펠라의 성흔 : 디멘션 버스트','성흔의 탈리스만[다크 버스트]'],
    '다크로드' : ['카펠라의 성흔 : 역병아귀','역병아귀','온전한 탈리스만[역병의 라사]','성흔의 탈리스만[역병의 라사]','카펠라의 성흔 : 영혼분쇄','영혼분쇄','온전한 탈리스만[귀참 : 광폭]','성흔의 탈리스만[귀참 : 광폭]','카펠라의 성흔 : 흑염 : 일섬','흑염 : 일섬','온전한 탈리스만[흑염검]','성흔의 탈리스만[흑염검]','카펠라의 성흔 : 파고드는 고통','파고드는 고통','온전한 탈리스만[귀참 : 나락]','성흔의 탈리스만[귀참 : 나락]','카펠라의 성흔 : 백귀야행','백귀야행','온전한 탈리스만[툼스톤 스웜프]','성흔의 탈리스만[툼스톤 스웜프]','카펠라의 성흔 : 망령의 진격','성흔의 탈리스만[블레이드 팬텀]','카펠라의 성흔 : 망령의 송곳니','성흔의 탈리스만[검귀강령 : 식]'],
    '듀란달' : ['카펠라의 성흔 : 미라지 크로스','미라지 크로스','온전한 탈리스만[공아]','성흔의 탈리스만[공아]','카펠라의 성흔 : 더 슈퍼셀','더 슈퍼셀','온전한 탈리스만[메조싸이클론]','성흔의 탈리스만[메조싸이클론]','카펠라의 성흔 : 초뇌격 극혈섬','초뇌격 극혈섬','온전한 탈리스만[뇌격점혈섬]','성흔의 탈리스만[뇌격점혈섬]','카펠라의 성흔 : 레피드 스피어 벌컨','레피드 스피어 벌컨','온전한 탈리스만[롤링 벌컨]','성흔의 탈리스만[롤링 벌컨]','카펠라의 성흔 : 크레센트 문 슬래셔','크레센트 문 슬래셔','온전한 탈리스만[삼일참월]','성흔의 탈리스만[삼일참월]','카펠라의 성흔 : 미라지 디스로케이션','성흔의 탈리스만[쿼드러플 드라이브]','카펠라의 성흔 : 서든 프로미넌스','성흔의 탈리스만[미스트 플레어]'],
    '드레드노트' : ['카펠라의 성흔 : 콤비네이션 너클','콤비네이션 너클','온전한 탈리스만[드래곤 스매쉬]','성흔의 탈리스만[드래곤 스매쉬]','카펠라의 성흔 : 용제비무 : 천뢰','용제비무 : 천뢰','온전한 탈리스만[태도 용제무쌍]','성흔의 탈리스만[태도 용제무쌍]','카펠라의 성흔 : 드래곤 테이머','드래곤 테이머','온전한 탈리스만[폭풍의 숨결]','성흔의 탈리스만[폭풍의 숨결]','카펠라의 성흔 : 용악쇄','용악쇄','온전한 탈리스만[용왕파천]','성흔의 탈리스만[용왕파천]','카펠라의 성흔 : 굉룡패천격','굉룡패천격','온전한 탈리스만[비룡천상]','성흔의 탈리스만[비룡천상]','카펠라의 성흔 : 초토화','성흔의 탈리스만[미티어 버스트]','카펠라의 성흔 : 천위강룡제','성흔의 탈리스만[용제출진]'],
    '디스트로이어' : ['카펠라의 성흔 : 디 오버워크','디 오버워크','온전한 탈리스만[FM-31 그레네이드 런처]','성흔의 탈리스만[FM-31 그레네이드 런처]','카펠라의 성흔 : 콜 더 퀀텀 스톰','콜 더 퀀텀 스톰','온전한 탈리스만[양자 폭탄]','성흔의 탈리스만[양자 폭탄]','카펠라의 성흔 : 더 서클 아웃버스트','더 서클 아웃버스트','온전한 탈리스만[X-1 익스트루더]','성흔의 탈리스만[X-1 익스트루더]','카펠라의 성흔 : 에드 온 레이저','에드 온 레이저','온전한 탈리스만[플라즈마 부스터]','성흔의 탈리스만[플라즈마 부스터]','카펠라의 성흔 : 스커드 익스플로젼','스커드 익스플로젼','온전한 탈리스만[FM-92 스팅어 SW]','성흔의 탈리스만[FM-92 스팅어 SW]','카펠라의 성흔 : 어스퀘이크 크러쉬','성흔의 탈리스만[사이즈믹 웨이브]','카펠라의 성흔 : 에어 버스트-7','성흔의 탈리스만[MSC-7]'],
    '레이븐' : ['카펠라의 성흔 : 엑셀레이션 리볼버','엑셀레이션 리볼버','온전한 탈리스만[난사]','성흔의 탈리스만[난사]','카펠라의 성흔 : 와이드 아웃','와이드 아웃','온전한 탈리스만[멀티 헤드샷]','성흔의 탈리스만[멀티 헤드샷]','카펠라의 성흔 : 더블 그라인더','더블 그라인더','온전한 탈리스만[더블 건호크]','성흔의 탈리스만[더블 건호크]','카펠라의 성흔 : 먹이사냥','먹이사냥','온전한 탈리스만[데들리 어프로치]','성흔의 탈리스만[데들리 어프로치]','카펠라의 성흔 : 매거진 핸들링','매거진 핸들링','온전한 탈리스만[제압 사격]','성흔의 탈리스만[제압 사격]','카펠라의 성흔 : 듀얼 리액션','성흔의 탈리스만[패스트 드로우]','카펠라의 성흔 : 불릿 스톰','성흔의 탈리스만[와이프 아웃]'],
    '레퀴엠' : ['카펠라의 성흔 : 잠행의 정수','잠행의 정수','온전한 탈리스만[암월비보]','성흔의 탈리스만[암월비보]','카펠라의 성흔 : 임무 - 목표 말살','임무 - 목표 말살','온전한 탈리스만[목표 사격]','성흔의 탈리스만[목표 사격]','카펠라의 성흔 : 블레이드 스웜프','블레이드 스웜프','온전한 탈리스만[블레이드 팰컨]','성흔의 탈리스만[블레이드 팰컨]','카펠라의 성흔 : 엑스트라 듀티','엑스트라 듀티','온전한 탈리스만[목표 포착]','성흔의 탈리스만[목표 포착]','카펠라의 성흔 : 슈퍼문 페이즈 쉬프트','슈퍼문 페이즈 쉬프트','온전한 탈리스만[문페이즈]','성흔의 탈리스만[문페이즈]','카펠라의 성흔 : 죽음의 올가미','성흔의 탈리스만[월광진혼곡]','카펠라의 성흔 : 임무 - 인멸','성흔의 탈리스만[섬멸]'],
    '리디머' : ['카펠라의 성흔 : 엔드리스 그리드','엔드리스 그리드','온전한 탈리스만[그리드 더 러커]','성흔의 탈리스만[그리드 더 러커]','카펠라의 성흔 : 어나이어레이터','어나이어레이터','온전한 탈리스만[사이드 매서커]','성흔의 탈리스만[사이드 매서커]','카펠라의 성흔 : 컨트롤 더 라스','컨트롤 더 라스','온전한 탈리스만[스커드 더 라스]','성흔의 탈리스만[스커드 더 라스]','카펠라의 성흔 : 패시네이션','패시네이션','온전한 탈리스만[엔비어스 키스]','성흔의 탈리스만[엔비어스 키스]','카펠라의 성흔 : 크루얼 인테이크','크루얼 인테이크','온전한 탈리스만[글러트니 바밋]','성흔의 탈리스만[글러트니 바밋]','카펠라의 성흔 : 익스트리미스트','성흔의 탈리스만[세컨드 소울]','카펠라의 성흔 : 컨퓨즈 티어','성흔의 탈리스만[서드 립]'],
    '마신' : ['카펠라의 성흔 : 더 블랙 볼트','더 블랙 볼트','온전한 탈리스만[천공검]','성흔의 탈리스만[천공검]','카펠라의 성흔 : 트레직 로켓','트레직 로켓','온전한 탈리스만[제노사이드 크러쉬]','성흔의 탈리스만[제노사이드 크러쉬]','카펠라의 성흔 : 서든 피어','서든 피어','온전한 탈리스만[파이널 어택]','성흔의 탈리스만[파이널 어택]','카펠라의 성흔 : 인투 더 헬','인투 더 헬','온전한 탈리스만[검은 충격]','성흔의 탈리스만[검은 충격]','카펠라의 성흔 : 나이트메어','나이트메어','온전한 탈리스만[미드나잇 카니발]','성흔의 탈리스만[미드나잇 카니발]','카펠라의 성흔 : 레인보우 판타지아','성흔의 탈리스만[매시브 파이어]','카펠라의 성흔 : 엔들리스 워','성흔의 탈리스만[대난투]'],
    '명왕' : ['카펠라의 성흔 : 백스트리트 태클','백스트리트 태클','온전한 탈리스만[그라운드 태클]','성흔의 탈리스만[그라운드 태클]','카펠라의 성흔 : 베놈 익스플로전','베놈 익스플로전','온전한 탈리스만[베놈 마인]','성흔의 탈리스만[베놈 마인]','카펠라의 성흔 : 와일리 트릭', '와일리 트릭', '온전한 탈리스만[비셔스 래리어트]', '성흔의 탈리스만[비셔스 래리어트]','카펠라의 성흔 : 킥 애스','킥 애스','온전한 탈리스만[더티 배럴]','성흔의 탈리스만[더티 배럴]','카펠라의 성흔 : 스카페이스','스카페이스','온전한 탈리스만[광폭혈쇄]','성흔의 탈리스만[광폭혈쇄]','카펠라의 성흔 : 버닝 드라이브','성흔의 탈리스만[체인 드라이브]','카펠라의 성흔 : 아발란체','성흔의 탈리스만[만천화우]'],
    '뱀파이어 로드' : ['카펠라의 성흔 : 블러드 필드','블러드 필드','온전한 탈리스만[토먼트]','성흔의 탈리스만[토먼트]','카펠라의 성흔 : 블러드 소울 번','블러드 소울 번','온전한 탈리스만[소울 스틸]','성흔의 탈리스만[소울 스틸]','카펠라의 성흔 : 커럽션 오브 다크블러드','커럽션 오브 다크블러드','온전한 탈리스만[커럽션]','성흔의 탈리스만[커럽션]','카펠라의 성흔 : 스웜프 오브 헬독','스웜프 오브 헬독','온전한 탈리스만[럼블독]','성흔의 탈리스만[럼블독]','카펠라의 성흔 : 블러드 둠','블러드 둠','온전한 탈리스만[머로더]','성흔의 탈리스만[머로더]','카펠라의 성흔 : 헬 하운드','성흔의 탈리스만[팬텀 베일]','카펠라의 성흔 : 페이탈 레이크','성흔의 탈리스만[페이탈리티]'],
    '벤데타' : ['카펠라의 성흔 : 슈터 칵테일','슈터 칵테일','온전한 탈리스만[셰이커 블래스트]','성흔의 탈리스만[셰이커 블래스트]','카펠라의 성흔 : 콜 오브 나이트','콜 오브 나이트','온전한 탈리스만[나이트폴]','성흔의 탈리스만[나이트폴]','카펠라의 성흔 : 크레센도','크레센도','온전한 탈리스만[라스트 오더]','성흔의 탈리스만[라스트 오더]','카펠라의 성흔 : 블랙잭','블랙잭','온전한 탈리스만[오버페이스]','성흔의 탈리스만[오버페이스]','카펠라의 성흔 : 패스트 트래커','패스트 트래커','온전한 탈리스만[불스아이]','성흔의 탈리스만[불스아이]','카펠라의 성흔 : 오버 더 락','성흔의 탈리스만[스트레이트 샷]','카펠라의 성흔 : 퍼들','성흔의 탈리스만[카타르시스]'],
    '블러드 이블' : ['카펠라의 성흔 : 광전사의 분노','광전사의 분노','온전한 탈리스만[격노]','성흔의 탈리스만[격노]','카펠라의 성흔 : 멈추지 않는 갈증','멈추지 않는 갈증','온전한 탈리스만[블러드 소드]','성흔의 탈리스만[블러드 소드]','카펠라의 성흔 : 블러드 퀘이크','블러드 퀘이크','온전한 탈리스만[아웃레이지 브레이크]','성흔의 탈리스만[아웃레이지 브레이크]','카펠라의 성흔 : 포스 오브 블러드','포스 오브 블러드','온전한 탈리스만[블러드 스내치]','성흔의 탈리스만[블러드 스내치]','카펠라의 성흔 : 인세인 브루탈리티','인세인 브루탈리티','온전한 탈리스만[브루탈 크러셔]','성흔의 탈리스만[브루탈 크러셔]','카펠라의 성흔 : 끓어오르는 피의 욕망','성흔의 탈리스만[블러드 붐]','카펠라의 성흔 : 피의 축제','성흔의 탈리스만[페이탈 블러드]'],
    '세라핌' : ['카펠라의 성흔 : 천상에 울려퍼지는 아리아','천상에 울려퍼지는 아리아','온전한 탈리스만[용맹의 아리아]','성흔의 탈리스만[용맹의 아리아]','카펠라의 성흔 : 미라클 오브 썬더','미라클 오브 썬더','온전한 탈리스만[정화의 번개]','성흔의 탈리스만[정화의 번개]','카펠라의 성흔 : 그랜드 세인트 크로스','그랜드 세인트 크로스','온전한 탈리스만[그랜드 크로스 크래쉬]','성흔의 탈리스만[그랜드 크로스 크래쉬]','카펠라의 성흔 : 찬란한 빛의 구원자','찬란한 빛의 구원자','온전한 탈리스만[리펜텐스 스매시]','성흔의 탈리스만[리펜텐스 스매시]','카펠라의 성흔 : 천상의 활력소','천상의 활력소','온전한 탈리스만[대천사의 축복]','성흔의 탈리스만[대천사의 축복]','카펠라의 성흔 : 우리엘의 가호','성흔의 탈리스만[우리엘의 결계]','카펠라의 성흔 : 미카엘의 성광구','성흔의 탈리스만[미라클 샤인]'],
    '세이비어' : ['카펠라의 성흔 : 죄의 추적자','죄의 추적자','온전한 탈리스만[컨페션]','성흔의 탈리스만[컨페션]','카펠라의 성흔 : 고해성사','고해성사','온전한 탈리스만[이스트레인지먼트]','성흔의 탈리스만[이스트레인지먼트]','카펠라의 성흔 : 가중처벌','가중처벌','온전한 탈리스만[컨실리에이션]','성흔의 탈리스만[컨실리에이션]','카펠라의 성흔 : 제압의 빛','제압의 빛','온전한 탈리스만[인테그레이션]','성흔의 탈리스만[인테그레이션]','카펠라의 성흔 : 천상의 심판','천상의 심판','온전한 탈리스만[멘데이트 오브 헤븐]','성흔의 탈리스만[멘데이트 오브 헤븐]','카펠라의 성흔 : 회개의 빛무리','성흔의 탈리스만[옵스트럭션]','카펠라의 성흔 : 빛의 해방자','성흔의 탈리스만[인게이지먼트]'],
    '세인트' : ['카펠라의 성흔 : 앱솔루트 배리어','앱솔루트 배리어','온전한 탈리스만[플래시 글로브]','성흔의 탈리스만[플래시 글로브]','카펠라의 성흔 : 라이프 오브 배슬','라이프 오브 배슬','온전한 탈리스만[참회의 망치]','성흔의 탈리스만[참회의 망치]','카펠라의 성흔 : 미카엘라의 심판','미카엘라의 심판','온전한 탈리스만[정의의 심판]','성흔의 탈리스만[정의의 심판]','카펠라의 성흔 : 기적의 침례자','기적의 침례자','온전한 탈리스만[미러클 스플리터]','성흔의 탈리스만[미러클 스플리터]','카펠라의 성흔 : 찬란한 빛의 조력자','찬란한 빛의 조력자','온전한 탈리스만[운명의 창]','성흔의 탈리스만[운명의 창]','카펠라의 성흔 : 생츄어리 오브 헤븐','성흔의 탈리스만[홀리 생츄어리]','카펠라의 성흔 : 디바인 프로텍트','성흔의 탈리스만[디바인 플래쉬]'],
    '셀레스티얼' : ['카펠라의 성흔 : 스테빌라이저','스테빌라이저','온전한 탈리스만[기어 드라이브]','성흔의 탈리스만[기어 드라이브]','카펠라의 성흔 : 스펙터클 스콜','스펙터클 스콜','온전한 탈리스만[안개폭우]','성흔의 탈리스만[안개폭우]','카펠라의 성흔 : 신무변조','신무변조','온전한 탈리스만[신무병장 유성]','성흔의 탈리스만[신무병장 유성]','카펠라의 성흔 : 엑스트라 옵션','엑스트라 옵션','온전한 탈리스만[스크램블 앵커]','성흔의 탈리스만[스크램블 앵커]','카펠라의 성흔 : 요화맹진','요화맹진','온전한 탈리스만[셋 업 : 신무병장 요수광란]','성흔의 탈리스만[셋 업 : 신무병장 요수광란]','카펠라의 성흔 : 스톰 브레이크','성흔의 탈리스만[헤이즈 스톰]','카펠라의 성흔 : 축연','성흔의 탈리스만[셋 업 : 불꽃놀이]'],
    '스톰 트루퍼' : ['카펠라의 성흔 : M102-CERM 탄약','M102-CERM 탄약','온전한 탈리스만[FM-31 그레네이드 런처]','성흔의 탈리스만[FM-31 그레네이드 런처]','카펠라의 성흔 : 라인 배커','라인 배커','온전한 탈리스만[양자 폭탄]','성흔의 탈리스만[양자 폭탄]','카펠라의 성흔 : X-2.Sub 급속 냉각 파츠','X-2.Sub 급속 냉각 파츠','온전한 탈리스만[X-2 헬리오스]','성흔의 탈리스만[X-2 헬리오스]','카펠라의 성흔 : 고출력 플라즈마','고출력 플라즈마','온전한 탈리스만[플라즈마 부스터]','성흔의 탈리스만[플라즈마 부스터]','카펠라의 성흔 : MIBC유도장치','MIBC유도장치','온전한 탈리스만[FM-92 mk2 랜서 SW]','성흔의 탈리스만[FM-92 mk2 랜서 SW]','카펠라의 성흔 : 라이트웨이트','성흔의 탈리스만[FSC-7]','카펠라의 성흔 : PT-15 테라 모듈','성흔의 탈리스만[PT-15 프로토타입]'],
    '시라누이' : ['카펠라의 성흔 : 업화의 구슬','업화의 구슬','온전한 탈리스만[열화천도]','성흔의 탈리스만[열화천도]','카펠라의 성흔 : 거대 두꺼비의 전설','거대 두꺼비의 전설','온전한 탈리스만[두꺼비유염탄]','성흔의 탈리스만[두꺼비유염탄]','카펠라의 성흔 : 불을 먹고 만개하는 꽃','불을 먹고 만개하는 꽃','온전한 탈리스만[비기 : 염무개화]','성흔의 탈리스만[비기 : 염무개화]','카펠라의 성흔 : 인술 비전 : 신출귀몰','인술 비전 : 신출귀몰','온전한 탈리스만[암기 : 비연수리검]','성흔의 탈리스만[암기 : 비연수리검]','카펠라의 성흔 : 염화 나선 수리검','염화 나선 수리검','온전한 탈리스만[마환 풍마수리검]','성흔의 탈리스만[마환 풍마수리검]','카펠라의 성흔 : 흉사지염옥','성흔의 탈리스만[야마타오로치]','카펠라의 성흔 : 작열하는 광명의 태양','성흔의 탈리스만[아마테라스]'],
    '아슈타르테' : ['카펠라의 성흔 : 언리밋 프레스','언리밋 프레스','온전한 탈리스만[체이서 프레스]','성흔의 탈리스만[체이서 프레스]','카펠라의 성흔 : 패황유성권','패황유성권','온전한 탈리스만[강습유성타]','성흔의 탈리스만[강습유성타]','카펠라의 성흔 : 황룡유희','황룡유희','온전한 탈리스만[황룡천공]','성흔의 탈리스만[황룡천공]','카펠라의 성흔 : 홈런 퀸','홈런 퀸','온전한 탈리스만[트윙클 스매쉬]','성흔의 탈리스만[트윙클 스매쉬]','카펠라의 성흔 : 황룡재림','황룡재림','온전한 탈리스만[황룡강림]','성흔의 탈리스만[황룡강림]','카펠라의 성흔 : 빅 뱅 클러스터','성흔의 탈리스만[체이서 클러스터]','카펠라의 성흔 : 섬멸의 춤','성흔의 탈리스만[사도의 춤]'],
    '아이올로스' : ['카펠라의 성흔 : 스톰 익스플로전','스톰 익스플로전','온전한 탈리스만[폭풍의 눈]','성흔의 탈리스만[폭풍의 눈]','카펠라의 성흔 : 메가 토네이도','메가 토네이도','온전한 탈리스만[휘몰아치는 바람]','성흔의 탈리스만[휘몰아치는 바람]','카펠라의 성흔 : 소닉붐','소닉붐','온전한 탈리스만[스톰 스트라이크]','성흔의 탈리스만[스톰 스트라이크]','카펠라의 성흔 : 격풍회류','격풍회류','온전한 탈리스만[질풍가도]','성흔의 탈리스만[질풍가도]','카펠라의 성흔 : 레이징 템페스트','레이징 템페스트','온전한 탈리스만[스톰 퀘이커]','성흔의 탈리스만[스톰 퀘이커]','카펠라의 성흔 : 굉풍격','성흔의 탈리스만[스파이럴 프레스]','카펠라의 성흔 : 스네이크 게일','성흔의 탈리스만[바이올런트 스톰]'],
    '악귀나찰' : ['카펠라의 성흔 : 분쇄참 : 혼','분쇄참 : 혼','온전한 탈리스만[혼백참]','성흔의 탈리스만[혼백참]','카펠라의 성흔 : 회천 : 광폭','회천 : 광폭','온전한 탈리스만[원귀 : 회천]','성흔의 탈리스만[원귀 : 회천]','카펠라의 성흔 : 천쇄참수','천쇄참수','온전한 탈리스만[악귀참수]','성흔의 탈리스만[악귀참수]','카펠라의 성흔 : 최후의 집행귀','최후의 집행귀','온전한 탈리스만[원귀 : 격살]','성흔의 탈리스만[원귀 : 격살]','카펠라의 성흔 : 나락참 : 극','나락참 : 극','온전한 탈리스만[합격 : 나락참]','성흔의 탈리스만[합격 : 나락참]','카펠라의 성흔 : 원귀 : 흑풍섬(逆)','성흔의 탈리스만[원귀 : 신풍]','카펠라의 성흔 : 귀멸악섬','성흔의 탈리스만[악귀연무]'],
    '알키오네' : ['카펠라의 성흔 : 할로우 아웃','할로우 아웃','온전한 탈리스만[버티컬 스파이럴]','성흔의 탈리스만[버티컬 스파이럴]','카펠라의 성흔 : 프리 무브먼트','프리 무브먼트','온전한 탈리스만[소닉 어설트]','성흔의 탈리스만[소닉 어설트]','카펠라의 성흔 : 엔드 쓰라이스','엔드 쓰라이스','온전한 탈리스만[엑셀 스트라이크]','성흔의 탈리스만[엑셀 스트라이크]','카펠라의 성흔 : 로테이팅 나이프','로테이팅 나이프','온전한 탈리스만[체인소 러시]','성흔의 탈리스만[체인소 러시]','카펠라의 성흔 : 임팩트 스파이럴','임팩트 스파이럴','온전한 탈리스만[어슬랜트 스파이럴]','성흔의 탈리스만[어슬랜트 스파이럴]','카펠라의 성흔 : 고스트 레이드','성흔의 탈리스만[엑조틱 램펀트]','카펠라의 성흔 : 브루탈 어썰트','성흔의 탈리스만[디스트리뷰션]'],
    '어센션' : ['카펠라의 성흔 : 임계점 조작','임계점 조작','온전한 탈리스만[차원 : 시공폭뢰]','성흔의 탈리스만[차원 : 시공폭뢰]','카펠라의 성흔 : 고통의 안내자','고통의 안내자','온전한 탈리스만[괴리 : 패러사이트 스웜]','성흔의 탈리스만[괴리 : 패러사이트 스웜]','카펠라의 성흔 : 그라비톤 블래스트','그라비톤 블래스트','온전한 탈리스만[차원 : 포지트론 블래스트]','성흔의 탈리스만[차원 : 포지트론 블래스트]','카펠라의 성흔 : 역장왜곡해석','역장왜곡해석','온전한 탈리스만[차원 : 패러다임 디토네이션]','성흔의 탈리스만[차원 : 패러다임 디토네이션]','카펠라의 성흔 : 애프터 케어','애프터 케어','온전한 탈리스만[괴리 : 칙 잇]','성흔의 탈리스만[괴리 : 칙 잇]','카펠라의 성흔 : 각인영창','성흔의 탈리스만[차원 : 싱귤래리티 매니플레이션]','카펠라의 성흔 : 저편의 단말마','성흔의 탈리스만[괴리 : 저편의 자장가]'],
    '언터처블' : ['카펠라의 성흔 : 오프레션 월','오프레션 월','온전한 탈리스만[오프레션]','성흔의 탈리스만[오프레션]','카펠라의 성흔 : 크래셔블 퀘이크','크래셔블 퀘이크','온전한 탈리스만[디스트러블 퀘이크]','성흔의 탈리스만[디스트러블 퀘이크]','카펠라의 성흔 : 스페셜 프레전트','스페셜 프레전트','온전한 탈리스만[서프라이즈 프레전트]','성흔의 탈리스만[서프라이즈 프레전트]','카펠라의 성흔 : 퍼펙트 스핀','퍼펙트 스핀','온전한 탈리스만[퍼펙트 배팅]','성흔의 탈리스만[퍼펙트 배팅]','카펠라의 성흔 : 레이지 온 파이어','레이지 온 파이어','온전한 탈리스만[겟 온 파이어]','성흔의 탈리스만[겟 온 파이어]','카펠라의 성흔 : 익스트림 스매쉬','성흔의 탈리스만[바이올런트 스매쉬]','카펠라의 성흔 : 굿 초이스','성흔의 탈리스만[더 나인 배럴]'],
    '얼티밋 디바' : ['카펠라의 성흔 : 액셀레이트 스파이어','액셀레이트 스파이어','온전한 탈리스만[스파이어]','성흔의 탈리스만[스파이어]','카펠라의 성흔 : 래피드 무브 싸이클론','래피드 무브 싸이클론','온전한 탈리스만[수플렉스 싸이클론]','성흔의 탈리스만[수플렉스 싸이클론]','카펠라의 성흔 : 하이퍼 캐넌 스파이크','하이퍼 캐넌 스파이크','온전한 탈리스만[와일드 캐넌 스파이크]','성흔의 탈리스만[와일드 캐넌 스파이크]','카펠라의 성흔 : 스카이 엠프레스 클러치','스카이 엠프레스 클러치','온전한 탈리스만[에어클러치]','성흔의 탈리스만[에어클러치]','카펠라의 성흔 : 맥스 허리케인 어택','맥스 허리케인 어택','온전한 탈리스만[싸이클론 어택]','성흔의 탈리스만[싸이클론 어택]','카펠라의 성흔 : 자이언트 허리케인','성흔의 탈리스만[자이언트 스윙]','카펠라의 성흔 : 익스트림 소닉 붐','성흔의 탈리스만[토르베지노]'],
    '에레보스' : ['카펠라의 성흔 : 스파이럴 볼텍스','스파이럴 볼텍스','온전한 탈리스만[스파이럴 러쉬]','성흔의 탈리스만[스파이럴 러쉬]','카펠라의 성흔 : 작렬하는 흑뢰','작렬하는 흑뢰','온전한 탈리스만[다크 스타]','성흔의 탈리스만[다크 스타]','카펠라의 성흔 : 극 : 멸광폭살','극 : 멸광폭살','온전한 탈리스만[흑광폭살]','성흔의 탈리스만[흑광폭살]','카펠라의 성흔 : 조여오는 어둠','조여오는 어둠','온전한 탈리스만[다크 디비젼]','성흔의 탈리스만[다크 디비젼]','카펠라의 성흔 : 일점집중','일점집중','온전한 탈리스만[바이올런트 피어스]','성흔의 탈리스만[바이올런트 피어스]','카펠라의 성흔 : 공허의 저편','성흔의 탈리스만[폴링 데스]','카펠라의 성흔 : 데들리 모스','성흔의 탈리스만[다크 임펄스]'],
    '엑스마키나' : ['카펠라의 성흔 : 새비지 테크닉','새비지 테크닉','온전한 탈리스만[부스트 : 브리칭 다운]','성흔의 탈리스만[부스트 : 브리칭 다운]','카펠라의 성흔 : 스테이블 택틱스','스테이블 택틱스','온전한 탈리스만[스코치 택틱스]','성흔의 탈리스만[스코치 택틱스]','카펠라의 성흔 : 이모션 킬러','이모션 킬러','온전한 탈리스만[다이렉트 피드백]','성흔의 탈리스만[다이렉트 피드백]','카펠라의 성흔 : 패트리어트 킥','패트리어트 킥','온전한 탈리스만[부스트 : 다이너마이트 킥]','성흔의 탈리스만[부스트 : 다이너마이트 킥]','카펠라의 성흔 : 앱솔루트 포트리스','앱솔루트 포트리스','온전한 탈리스만[포트리스 블리츠]','성흔의 탈리스만[포트리스 블리츠]','카펠라의 성흔 : 리스키 토이','성흔의 탈리스만[AT-SO Walker]','카펠라의 성흔 : 세이프티 액시던트','성흔의 탈리스만[레이시온 크리트]'],
    '염제 폐월수화' : ['카펠라의 성흔 : 대지일갈','대지일갈','온전한 탈리스만[사자후]','성흔의 탈리스만[사자후]','카펠라의 성흔 : 기공방출 : 쾌','기공방출 : 쾌','온전한 탈리스만[기공장]','성흔의 탈리스만[기공장]','카펠라의 성흔 : 나선의 대칭점','나선의 대칭점','온전한 탈리스만[광충노도]','성흔의 탈리스만[광충노도]','카펠라의 성흔 : 진 : 광룡강림','진 : 광룡강림','온전한 탈리스만[광룡십삼장]','성흔의 탈리스만[광룡십삼장]','카펠라의 성흔 : 천지일갈','천지일갈','온전한 탈리스만[태사자후]','성흔의 탈리스만[태사자후]','카펠라의 성흔 : 세이지 일루전','성흔의 탈리스만[세인트 일루전]','카펠라의 성흔 : 환영기공파','성흔의 탈리스만[흡기공탄]'],
    '염황 광풍제월' : ['카펠라의 성흔 : 음공의 대가','음공의 대가','온전한 탈리스만[사자후]','성흔의 탈리스만[사자후]','카펠라의 성흔 : 나선기공장','나선기공장','온전한 탈리스만[기공장]','성흔의 탈리스만[기공장]','카펠라의 성흔 : 호랑이 울음','호랑이 울음','온전한 탈리스만[기호지세]','성흔의 탈리스만[기호지세]','카펠라의 성흔 : 샤이닝 스피어','샤이닝 스피어','온전한 탈리스만[넨 스피어]','성흔의 탈리스만[넨 스피어]','카펠라의 성흔 : 청천벽력','청천벽력','온전한 탈리스만[기공환]','성흔의 탈리스만[기공환]','카펠라의 성흔 : 제황뇌격권','성흔의 탈리스만[제황나선격]','카펠라의 성흔 : 월광진폭','성흔의 탈리스만[월광대지]'],
    '오버마인드' : ['카펠라의 성흔 : 썬더 엘리미네이트','썬더 엘리미네이트','온전한 탈리스만[썬더 스트라이크]','성흔의 탈리스만[썬더 스트라이크]','카펠라의 성흔 : 다크 매터','다크 매터','온전한 탈리스만[나이트 할로우]','성흔의 탈리스만[나이트 할로우]','카펠라의 성흔 : 포스 오브 오버마인드','포스 오브 오버마인드','온전한 탈리스만[핼로윈 버스터]','성흔의 탈리스만[핼로윈 버스터]','카펠라의 성흔 : 서프레션 이펙트','서프레션 이펙트','온전한 탈리스만[엘레멘탈 커튼]','성흔의 탈리스만[엘레멘탈 커튼]','카펠라의 성흔 : 플레임 게이저','플레임 게이저','온전한 탈리스만[엘레멘탈 퀘이크]','성흔의 탈리스만[엘레멘탈 퀘이크]','카펠라의 성흔 : 트랜센던스','성흔의 탈리스만[초월의 크리스탈]','카펠라의 성흔 : 디 임팩트','성흔의 탈리스만[더 게이트]'],
    '오블리비언' : ['카펠라의 성흔 : 서클 익스플로젼','서클 익스플로젼','온전한 탈리스만[플레임 서클]','성흔의 탈리스만[플레임 서클]','카펠라의 성흔 : 일렉트릭 트레인','일렉트릭 트레인','온전한 탈리스만[라이트닝 월]','성흔의 탈리스만[라이트닝 월]','카펠라의 성흔 : 엘레멘탈 오브','엘레멘탈 오브','온전한 탈리스만[엘레멘탈 레인]','성흔의 탈리스만[엘레멘탈 레인]','카펠라의 성흔 : 듀플리케이트','듀플리케이트','온전한 탈리스만[컨센트레이트]','성흔의 탈리스만[컨센트레이트]','카펠라의 성흔 : 엘레멘탈 게더링','엘레멘탈 게더링','온전한 탈리스만[엘레멘탈 스트라이크]','성흔의 탈리스만[엘레멘탈 스트라이크]','카펠라의 성흔 : 원소 폭풍','성흔의 탈리스만[엘레멘탈 필드]','카펠라의 성흔 : 데들리 거터 볼','성흔의 탈리스만[컨버전스 캐넌]'],
    '옵티머스' : ['카펠라의 성흔 : 얼티밋 게일 포스','얼티밋 게일 포스','온전한 탈리스만[공중 전폭 메카 : 게일 포스]','성흔의 탈리스만[공중 전폭 메카 : 게일 포스]','카펠라의 성흔 : 폭격 개시','폭격 개시','온전한 탈리스만[메카 드롭]','성흔의 탈리스만[메카 드롭]','카펠라의 성흔 : 스패로우 : 요격 모드','스패로우 : 요격 모드','온전한 탈리스만[스패로우 팩토리]','성흔의 탈리스만[스패로우 팩토리]','카펠라의 성흔 : 이볼빙 시스템','이볼빙 시스템','온전한 탈리스만[HS-12 플라즈마]','카펠라의 성흔 : 스피닝 블레이드','성흔의 탈리스만[HS-12 플라즈마]','스피닝 블레이드','온전한 탈리스만[프리스비]','성흔의 탈리스만[프리스비]','카펠라의 성흔 : 에너지 순환 시스템','성흔의 탈리스만[G-S.P. 팔콘]','카펠라의 성흔 : 네오디뮴 마그넷','성흔의 탈리스만[마그네틱 필드]'],
    '용독문주' : ['카펠라의 성흔 : 백스트리트 킥','백스트리트 킥','온전한 탈리스만[그라운드 킥]','성흔의 탈리스만[그라운드 킥]','카펠라의 성흔 : 바이퍼 마인','바이퍼 마인','온전한 탈리스만[베놈 마인]','성흔의 탈리스만[베놈 마인]','카펠라의 성흔 : 대방출','대방출','온전한 탈리스만[정크 스핀]','성흔의 탈리스만[정크 스핀]','카펠라의 성흔 : 들끓는 독수','들끓는 독수','온전한 탈리스만[맹독 일발화약성]','성흔의 탈리스만[맹독 일발화약성]','카펠라의 성흔 : 버스터 봄','버스터 봄','온전한 탈리스만[블록 봄]','성흔의 탈리스만[블록 봄]','카펠라의 성흔 : 비각술','성흔의 탈리스만[비기 : 폭독]','카펠라의 성흔 : 오버 더 리미트','성흔의 탈리스만[만병지왕 - 파진포]'],
    '워로드' : ['카펠라의 성흔 : 나선마환창','나선마환창','온전한 탈리스만[드레드보어]','성흔의 탈리스만[드레드보어]','카펠라의 성흔 : 광풍마창','광풍마창','온전한 탈리스만[블레이드 스톰]','성흔의 탈리스만[블레이드 스톰]','카펠라의 성흔 : 사이클론 데모닉 스피어','사이클론 데모닉 스피어','온전한 탈리스만[디베스테이트]','성흔의 탈리스만[디베스테이트]','카펠라의 성흔 : 파워 스트라이크','파워 스트라이크','온전한 탈리스만[런지 스트라이크]','성흔의 탈리스만[런지 스트라이크]','카펠라의 성흔 : 둠 슬래쉬','둠 슬래쉬','온전한 탈리스만[둠 글레이브]','성흔의 탈리스만[둠 글레이브]','카펠라의 성흔 : 크루얼 매서커','성흔의 탈리스만[데들리 매서커]','카펠라의 성흔 : 버스트 스매쉬','성흔의 탈리스만[버스트 슬래쉬]'],
    '이모탈' : ['카펠라의 성흔 : 데스 클로 디포메이션','데스 클로 디포메이션','온전한 탈리스만[스피닝 디포메이션]','성흔의 탈리스만[스피닝 디포메이션]','카펠라의 성흔 : 악몽의 씨앗','악몽의 씨앗','온전한 탈리스만[데빌 피스트]','성흔의 탈리스만[데빌 피스트]','카펠라의 성흔 : 오버파워 피날레','오버파워 피날레','온전한 탈리스만[어둠의 권능]','성흔의 탈리스만[어둠의 권능]','카펠라의 성흔 : 팬텀 게이트','팬텀 게이트','온전한 탈리스만[복마전]','성흔의 탈리스만[복마전]','카펠라의 성흔 : 디제스터 랩소디','디제스터 랩소디','온전한 탈리스만[재앙]','성흔의 탈리스만[재앙]','카펠라의 성흔 : 악의의 절규','성흔의 탈리스만[다크 하울링]','카펠라의 성흔 : 언스토퍼블 레이지','성흔의 탈리스만[파멸의 강타]'],
    '이클립스' : ['카펠라의 성흔 : 대마법사 루이즈','대마법사 루이즈','온전한 탈리스만[계약소환 : 루이즈 언니!]','성흔의 탈리스만[계약소환 : 루이즈 언니!]','카펠라의 성흔 : 스피릿 디비젼','스피릿 디비젼','온전한 탈리스만[정령소환 : 정령왕 에체베리아]','성흔의 탈리스만[정령소환 : 정령왕 에체베리아]','카펠라의 성흔 : 진정한 타우킹','진정한 타우킹','온전한 탈리스만[계약소환 : 타우킹 쿠루타]','성흔의 탈리스만[계약소환 : 타우킹 쿠루타]','카펠라의 성흔 : 정령의 속삭임','정령의 속삭임','온전한 탈리스만[정령소환 : 융합소환 헤일롬]','성흔의 탈리스만[정령소환 : 융합소환 헤일롬]','카펠라의 성흔 : 극한의 분노','극한의 분노','온전한 탈리스만[분노의 난타]','성흔의 탈리스만[분노의 난타]','카펠라의 성흔 : 검은 달의 광기','성흔의 탈리스만[블랙 루나틱]','카펠라의 성흔 : 델라리온의 마물','성흔의 탈리스만[전설소환 : 월영]'],
    '이터널' : ['카펠라의 성흔 : 냉기의 조율자','냉기의 조율자','온전한 탈리스만[아이스 빅 해머]','성흔의 탈리스만[아이스 빅 해머]','카펠라의 성흔 : 프리즈 브레이크','프리즈 브레이크','온전한 탈리스만[아이스맨]','성흔의 탈리스만[아이스맨]','카펠라의 성흔 : 빙룡승천파','빙룡승천파','온전한 탈리스만[블리자드 스톰]','성흔의 탈리스만[블리자드 스톰]','카펠라의 성흔 : 창백한 쐐기','창백한 쐐기','온전한 탈리스만[아이스 오브]','성흔의 탈리스만[아이스 오브]','카펠라의 성흔 : 서릿발','서릿발','온전한 탈리스만[아이스 필드]','성흔의 탈리스만[아이스 필드]','카펠라의 성흔 : 아이스버그 하보크','성흔의 탈리스만[샤드 매그넘]','카펠라의 성흔 : 아몰포스 웨이브','성흔의 탈리스만[오버프리즈 익스텐션]'],
    '인다라천' : ['카펠라의 성흔 : 절제된 파동검','절제된 파동검','온전한 탈리스만[무쌍파]','성흔의 탈리스만[무쌍파]','카펠라의 성흔 : 파동연격','파동연격','온전한 탈리스만[작열 파동진]','성흔의 탈리스만[작열 파동진]','카펠라의 성흔 : 광명부동진','광명부동진','온전한 탈리스만[부동명왕진]','성흔의 탈리스만[부동명왕진]','카펠라의 성흔 : 극빙검: 절대 영도','극빙검: 절대 영도','온전한 탈리스만[극파동검 빙인]','성흔의 탈리스만[극파동검 빙인]','카펠라의 성흔 : 극화진폭검','극화진폭검','온전한 탈리스만[극파동검 폭염]','성흔의 탈리스만[극파동검 폭염]','카펠라의 성흔 : 파동폭뢰','성흔의 탈리스만[파동검 : 인다라망]','카펠라의 성흔 : 뇌운천진','성흔의 탈리스만[천뇌 바주라]'],
    '인페르노' : ['카펠라의 성흔 : 단호한 심판','단호한 심판','온전한 탈리스만[즉결심판]','성흔의 탈리스만[즉결심판]','카펠라의 성흔 : 왜곡된 자비','왜곡된 자비','온전한 탈리스만[고결한 격노]','성흔의 탈리스만[고결한 격노]','카펠라의 성흔 : 이교도의 최후','이교도의 최후','온전한 탈리스만[참수]','성흔의 탈리스만[참수]','카펠라의 성흔 : 율법의 전도자','율법의 전도자','온전한 탈리스만[업화의 소용돌이]','성흔의 탈리스만[업화의 소용돌이]','카펠라의 성흔 : 즉각처분', '즉각처분', '온전한 탈리스만[역죄단멸]', '성흔의 탈리스만[역죄단멸]','카펠라의 성흔 : 멸악대천벌','성흔의 탈리스만[신벌]','카펠라의 성흔 : 분쇄차륜형','성흔의 탈리스만[차륜형]'],
    '저스티스' : ['카펠라의 성흔 : 코크 스크류 허리케인','코크 스크류 허리케인','온전한 탈리스만[코크 스크류 블로우]','성흔의 탈리스만[코크 스크류 블로우]','카펠라의 성흔 : 신격권의 계승자','신격권의 계승자','온전한 탈리스만[헤븐리 컴비네이션]','성흔의 탈리스만[헤븐리 컴비네이션]','카펠라의 성흔 : 태풍의 눈','태풍의 눈','온전한 탈리스만[허리케인 롤]','성흔의 탈리스만[허리케인 롤]','카펠라의 성흔 : 압도적인 힘','압도적인 힘','온전한 탈리스만[개틀링 펀치]','성흔의 탈리스만[개틀링 펀치]','카펠라의 성흔 : 디바인 체이서','디바인 체이서','온전한 탈리스만[데몰리션 펀치]','성흔의 탈리스만[데몰리션 펀치]','카펠라의 성흔 : 사상 최강의 펀치','성흔의 탈리스만[핵 펀치]','카펠라의 성흔 : 격동하는 대지','성흔의 탈리스만[아토믹 쵸퍼]'],
    '제노사이더' : ['카펠라의 성흔 : 바투 서포트','바투 서포트','온전한 탈리스만[번 로커스트]','성흔의 탈리스만[번 로커스트]','카펠라의 성흔 : 초지니 : 강습','초지니 : 강습','온전한 탈리스만[페니트레잇 헤드]','성흔의 탈리스만[페니트레잇 헤드]','카펠라의 성흔 : 카타스트로피','카타스트로피','온전한 탈리스만[스케일 그라인더]','성흔의 탈리스만[스케일 그라인더]','카펠라의 성흔 : 산군투멸','산군투멸','온전한 탈리스만[팔티잔 스큐어]','성흔의 탈리스만[팔티잔 스큐어]','카펠라의 성흔 : 타이얼러스','타이얼러스','온전한 탈리스만[어스웜 헌트]','성흔의 탈리스만[어스웜 헌트]','카펠라의 성흔 : 헌팅 샬리트','성흔의 탈리스만[에이븐 헌팅]','카펠라의 성흔 : 세컨드 그라인더','성흔의 탈리스만[데보닝 슬래셔]'],
    '지니위즈' : ['카펠라의 성흔 : 개량형 플로레 컬라이더','개량형 플로레 컬라이더','온전한 탈리스만[플로레 컬라이더]','성흔의 탈리스만[플로레 컬라이더]','카펠라의 성흔 : 후끈한 열기 폭풍','후끈한 열기 폭풍','온전한 탈리스만[성난 불길 가열로]','성흔의 탈리스만[성난 불길 가열로]','카펠라의 성흔 : 메가 드릴 Mk-2','메가 드릴 Mk-2','온전한 탈리스만[메가 드릴]','성흔의 탈리스만[메가 드릴]','카펠라의 성흔 : 압축 파리채 풀스윙','압축 파리채 풀스윙','온전한 탈리스만[대왕 파리채]','성흔의 탈리스만[대왕 파리채]','카펠라의 성흔 : 자가 발전형 전기토끼','자가 발전형 전기토끼','온전한 탈리스만[플로레 전기토끼]','성흔의 탈리스만[플로레 전기토끼]','카펠라의 성흔 : 따끔한 맛 특제 빙수','성흔의 탈리스만[잭프로스트 빙수]','카펠라의 성흔 : 비터스위트 롤리팝','성흔의 탈리스만[롤리팝 크러쉬]'],
    '천선낭랑' : ['카펠라의 성흔 : 심혼폭류','심혼폭류','온전한 탈리스만[심혼흡성부]','성흔의 탈리스만[심혼흡성부]','카펠라의 성흔 : 무진어주술','무진어주술','온전한 탈리스만[백팔염주]','성흔의 탈리스만[백팔염주]','카펠라의 성흔 : 주박술의 극의','주박술의 극의','온전한 탈리스만[부동주박진]','성흔의 탈리스만[부동주박진]','카펠라의 성흔 : 나포마귀진','나포마귀진','온전한 탈리스만[시폭염화부]','성흔의 탈리스만[시폭염화부]','카펠라의 성흔 : 신룡승천제','신룡승천제','온전한 탈리스만[탈명대염진]','성흔의 탈리스만[탈명대염진]','카펠라의 성흔 : 마혼폭염진','성흔의 탈리스만[마혼부 : 퇴마음양옥]','카펠라의 성흔 : 벽력부태주','성흔의 탈리스만[염폭모대주]'],
    '카이저' : ['카펠라의 성흔 : 일격일살','일격일살','온전한 탈리스만[원인치 펀치]','성흔의 탈리스만[원인치 펀치]','카펠라의 성흔 : 섬광의 춤','섬광의 춤','온전한 탈리스만[라이트닝 댄스]','성흔의 탈리스만[라이트닝 댄스]','카펠라의 성흔 : 슈페리어 비트','슈페리어 비트','온전한 탈리스만[비트 드라이브]','성흔의 탈리스만[비트 드라이브]','카펠라의 성흔 : 파쇄권 : 멸','파쇄권 : 멸','온전한 탈리스만[파쇄권]','성흔의 탈리스만[파쇄권]','카펠라의 성흔 : 섬극연환오의','섬극연환오의','온전한 탈리스만[일주연환격]','성흔의 탈리스만[일주연환격]','카펠라의 성흔 : 맹호복초','성흔의 탈리스만[호격권]','카펠라의 성흔 : 두발당성','성흔의 탈리스만[무영각]'],
    '커맨더' : ['카펠라의 성흔 : 래피드 익스팅션','래피드 익스팅션','온전한 탈리스만[C4]','성흔의 탈리스만[C4]','카펠라의 성흔 : 빅 밤','빅 밤','온전한 탈리스만[네이팜 탄]','성흔의 탈리스만[네이팜 탄]','카펠라의 성흔 : 대룡저격소총 ADO-22', '대룡저격소총 ADO-22','온전한 탈리스만[리턴드 스나이퍼]','성흔의 탈리스만[리턴드 스나이퍼]','카펠라의 성흔 : 스쿼시 그라비티','스쿼시 그라비티','온전한 탈리스만[G-61 중력류탄]','성흔의 탈리스만[G-61 중력류탄]','카펠라의 성흔 : 이오나이저','이오나이저','온전한 탈리스만[고전압탄 : 맥스웰]','성흔의 탈리스만[고전압탄 : 맥스웰]','카펠라의 성흔 : 킵 어시스턴스','성흔의 탈리스만[데인저 클로즈]','카펠라의 성흔 : G-13EV 열압력 반응류탄','성흔의 탈리스만[G-38ARG 반응류탄]'],
    '크리에이터(자각2)' : ['카펠라의 성흔 : 수호의 양면성','수호의 양면성','온전한 탈리스만[플레임 허리케인]','성흔의 탈리스만[플레임 허리케인]','카펠라의 성흔 : 갈래 바람','갈래 바람','온전한 탈리스만[윈드 프레스]','성흔의 탈리스만[윈드 프레스]','카펠라의 성흔 : 스프링 클리닝','성흔의 탈리스만[윈드 스톰]','카펠라의 성흔 : 블랙홀','블랙홀','온전한 탈리스만[웜홀]','성흔의 탈리스만[웜홀]','카펠라의 성흔 : 혹한의 길','혹한의 길','온전한 탈리스만[빙하시대]','성흔의 탈리스만[빙하시대]','카펠라의 성흔 : 소울 링크스','소울 링크스','온전한 탈리스만[링크]','성흔의 탈리스만[링크]','카펠라의 성흔 : 타임 엑셀레이트','성흔의 탈리스만[타임 포워드]'],
    '크림슨 로제' : ['카펠라의 성흔 : 블레이드 댄싱','블레이드 댄싱','온전한 탈리스만[권총의 춤]','성흔의 탈리스만[권총의 춤]','카펠라의 성흔 : 스타일리쉬 헤드샷','스타일리쉬 헤드샷','온전한 탈리스만[멀티 헤드샷]','성흔의 탈리스만[멀티 헤드샷]','카펠라의 성흔 : 스피닝 탑 건호크','스피닝 탑 건호크','온전한 탈리스만[더블 건호크]','성흔의 탈리스만[더블 건호크]','카펠라의 성흔 : 블러디 스타','블러디 스타','온전한 탈리스만[블러디 스파이크]','성흔의 탈리스만[블러디 스파이크]','카펠라의 성흔 : 블레이드 스톰','블레이드 스톰','온전한 탈리스만[제압 사격]','성흔의 탈리스만[제압 사격]','카펠라의 성흔 : 핀 포인트','성흔의 탈리스만[킬 포인트]','카펠라의 성흔 : 윈드 디바이더','성흔의 탈리스만[체인 디바이더]'],
    '타나토스' : ['카펠라의 성흔 : 살의','살의','온전한 탈리스만[학살의 발라크르 강령]','성흔의 탈리스만[학살의 발라크르 강령]','카펠라의 성흔 : 위압','위압','온전한 탈리스만[발라크르의 야망]','성흔의 탈리스만[발라크르의 야망]','카펠라의 성흔 : 갈퀴손','갈퀴손','온전한 탈리스만[기요틴]','성흔의 탈리스만[기요틴]','카펠라의 성흔 : 응어리','응어리','온전한 탈리스만[망자의 집념]','성흔의 탈리스만[망자의 집념]','카펠라의 성흔 : 미련','미련','온전한 탈리스만[헤이트풀]','성흔의 탈리스만[헤이트풀]','카펠라의 성흔 : 추억거리','성흔의 탈리스만[블랙 아라크니아]','카펠라의 성흔 : 굶주린 집행자','성흔의 탈리스만[익스큐서너 슬래쉬]'],
    '태을선인' : ['카펠라의 성흔 : 백호 - 合','백호 - 合','온전한 탈리스만[공의 식신 - 백호]','성흔의 탈리스만[공의 식신 - 백호]','카펠라의 성흔 : 대회전격 - 熱','대회전격 - 熱','온전한 탈리스만[대회전격]','성흔의 탈리스만[대회전격]','카펠라의 성흔 : 무쌍격 - 蒼','무쌍격 - 蒼','온전한 탈리스만[무쌍격]','성흔의 탈리스만[무쌍격]','카펠라의 성흔 : 창룡 - 波','창룡 - 波','온전한 탈리스만[해의 식신 - 창룡]','성흔의 탈리스만[해의 식신 - 창룡]','카펠라의 성흔 : 역린격 - 地','역린격 - 地','온전한 탈리스만[역린격]','성흔의 탈리스만[역린격]','카펠라의 성흔 : 혼신의 일격 - 劈','성흔의 탈리스만[역전의 칠조격]','카펠라의 성흔 : 황룡부 - 滅','성흔의 탈리스만[황룡진]'],
    '트렌드세터' : ['카펠라의 성흔 : 기분파','기분파','온전한 탈리스만[다 카포]','성흔의 탈리스만[다 카포]','카펠라의 성흔 : 포르티시모','포르티시모','온전한 탈리스만[파워 포르테]','성흔의 탈리스만[파워 포르테]','카펠라의 성흔 : 최고의 무대','최고의 무대','온전한 탈리스만[사운드 오브 뮤즈]','성흔의 탈리스만[사운드 오브 뮤즈]','카펠라의 성흔 : 에너제틱 비트','에너제틱 비트','온전한 탈리스만[드랍 더 비트]','성흔의 탈리스만[드랍 더 비트]','카펠라의 성흔 : 쇼맨십','쇼맨십','온전한 탈리스만[눈부신 광경]','성흔의 탈리스만[눈부신 광경]','카펠라의 성흔 : 퍼펙트 오프닝','성흔의 탈리스만[스페셜 오프닝]','카펠라의 성흔 : 시즌 그리팅','성흔의 탈리스만[빛의 변주]'],
    '패스파인더' : ['카펠라의 성흔 : 익세시브 차징','익세시브 차징','온전한 탈리스만[어트랙트 에뮤니션]','성흔의 탈리스만[어트랙트 에뮤니션]','카펠라의 성흔 : 페이탈 슬래쉬','페이탈 슬래쉬','온전한 탈리스만[라이트닝 슬래쉬]','성흔의 탈리스만[라이트닝 슬래쉬]','카펠라의 성흔 : 에너지 업히브','에너지 업히브','온전한 탈리스만[디바이드 어쓰]','성흔의 탈리스만[디바이드 어쓰]','카펠라의 성흔 : 에너지 리액션','에너지 리액션','온전한 탈리스만[에너지 바운스]','성흔의 탈리스만[에너지 바운스]','카펠라의 성흔 : 래피드 디스펄션','래피드 디스펄션','온전한 탈리스만[디스펄션]','성흔의 탈리스만[디스펄션]','카펠라의 성흔 : 라이트 램페이지','성흔의 탈리스만[라이트 브랜디쉬]','카펠라의 성흔 : 오버서플라이','성흔의 탈리스만[CEAB-2]'],
    '패황' : ['카펠라의 성흔 : 폭렬! 홍염 질풍각','폭렬! 홍염 질풍각','온전한 탈리스만[홍염 질풍각]','성흔의 탈리스만[홍염 질풍각]','카펠라의 성흔 : 눈보다 빠르게','눈보다 빠르게','온전한 탈리스만[라이트닝 댄스]','성흔의 탈리스만[라이트닝 댄스]','카펠라의 성흔 : 불꽃 맹습','불꽃 맹습','온전한 탈리스만[헥토파스칼 킥]','성흔의 탈리스만[헥토파스칼 킥]','카펠라의 성흔 : 한마리 제비처럼','한마리 제비처럼','온전한 탈리스만[비연선풍]','성흔의 탈리스만[비연선풍]','카펠라의 성흔 : 태산 가르기','태산 가르기','온전한 탈리스만[스핀 킥]','성흔의 탈리스만[스핀 킥]','카펠라의 성흔 : 꺼지지 않는 불','성흔의 탈리스만[낙화]','카펠라의 성흔 : 멸절','성흔의 탈리스만[아토믹 캐넌]'],
    '프라임' : ['카펠라의 성흔 : 얼티밋 템페스터','얼티밋 템페스터','온전한 탈리스만[공중 전투 메카 : 템페스터]','성흔의 탈리스만[공중 전투 메카 : 템페스터]','카펠라의 성흔 : 언카운터블 메카 드롭','언카운터블 메카 드롭','온전한 탈리스만[메카 드롭]','성흔의 탈리스만[메카 드롭]','카펠라의 성흔 : 스패로우 : 격추 모드','스패로우 : 격추 모드','온전한 탈리스만[스패로우 팩토리]','성흔의 탈리스만[스패로우 팩토리]','카펠라의 성흔 : 카운트다운 스타트','카운트다운 스타트','온전한 탈리스만[Ez-10 카운터어택]','성흔의 탈리스만[Ez-10 카운터어택]','카펠라의 성흔 : 능동 방호 체계','능동 방호 체계','온전한 탈리스만[Ex-S 바이퍼 Zero]','성흔의 탈리스만[Ex-S 바이퍼 Zero]','카펠라의 성흔 : 커버링 파이어','성흔의 탈리스만[TX-80 디멘션 러너]','카펠라의 성흔 : 프리즈!','성흔의 탈리스만[TX-45 A-Team]'],
    '프레이야' : ['카펠라의 성흔 : 일렉트릭쇼크','일렉트릭쇼크','온전한 탈리스만[C4]','성흔의 탈리스만[C4]','카펠라의 성흔 : 빅마마','빅마마','온전한 탈리스만[네이팜 탄]','성흔의 탈리스만[네이팜 탄]','카펠라의 성흔 : 노 머시','노 머시','온전한 탈리스만[록 온 서포트]','성흔의 탈리스만[록 온 서포트]','카펠라의 성흔 : 매시브 그라비티','매시브 그라비티','온전한 탈리스만[G-61 중력류탄]','성흔의 탈리스만[G-61 중력류탄]','카펠라의 성흔 : 그랜드 플로우','그랜드 플로우','온전한 탈리스만[초진공탄 : 첼리]','성흔의 탈리스만[초진공탄 : 첼리]','카펠라의 성흔 : 스트랫 제트','성흔의 탈리스만[오픈 파이어]','카펠라의 성흔 : 마그넷 컴프레서','성흔의 탈리스만[포토빌라이저]'],
    '헤카테' : ['카펠라의 성흔 : 흑마술의 대가','흑마술의 대가','온전한 탈리스만[점핑 베어 프레스]','성흔의 탈리스만[점핑 베어 프레스]','카펠라의 성흔 : 퍼펫 왈츠','퍼펫 왈츠','온전한 탈리스만[데스티니 퍼펫]','성흔의 탈리스만[데스티니 퍼펫]','카펠라의 성흔 : 주인 의식','주인 의식','온전한 탈리스만[매드 더 빅]','성흔의 탈리스만[매드 더 빅]','카펠라의 성흔 : 일어나, 춤 출 시간이야','일어나, 춤 출 시간이야','온전한 탈리스만[헥스]','성흔의 탈리스만[헥스]','카펠라의 성흔 : 흑암의 구원자','흑암의 구원자','온전한 탈리스만[테러블 로어]','성흔의 탈리스만[테러블 로어]','카펠라의 성흔 : 마녀의 정원','성흔의 탈리스만[고통의 정원]','카펠라의 성흔 : 차별주의자','성흔의 탈리스만[커스 오브 테러베어]'],
    '메이븐' : ['카펠라의 성흔 : 플레어 데토네이터','플레어 데토네이터','온전한 탈리스만[플레어]','성흔의 탈리스만[플레어]','카펠라의 성흔 : 볼트 스콜','볼트 스콜','온전한 탈리스만[볼트 레인]','성흔의 탈리스만[볼트 레인]','카펠라의 성흔 : 핀포인트 쓰리','핀포인트 쓰리','온전한 탈리스만[쓰리 인 어 베드]','성흔의 탈리스만[쓰리 인 어 베드]','카펠라의 성흔 : 기어 부스터','기어 부스터','온전한 탈리스만[조인트 : 카펫 바밍]','성흔의 탈리스만[조인트 : 카펫 바밍]','카펠라의 성흔 : 부메랑 커터','부메랑 커터','온전한 탈리스만[팔케 : 윙 커터]','성흔의 탈리스만[팔케 : 윙 커터]','카펠라의 성흔 : 페이탈 피어싱','성흔의 탈리스만[F.D. 피어서]','카펠라의 성흔 : 택티컬 무브먼트','성흔의 탈리스만[팔케 : 체이싱클러]'],
    '프레데터' : ['카펠라의 성흔 : 고블 몬스터','고블 몬스터','온전한 탈리스만[디바우러]','성흔의 탈리스만[디바우러]','카펠라의 성흔 : 괴생풍체','괴생풍체','온전한 탈리스만[도깨비 바람]','성흔의 탈리스만[도깨비 바람]','카펠라의 성흔 : 심플 헌팅','심플 헌팅','온전한 탈리스만[크루얼 슬러터]','성흔의 탈리스만[크루얼 슬러터]','카펠라의 성흔 : 광란의 축제','광란의 축제','온전한 탈리스만[요요만찬]','성흔의 탈리스만[요요만찬]','카펠라의 성흔 : 내추럴 매드니스','내추럴 매드니스','온전한 탈리스만[램페이지]','성흔의 탈리스만[램페이지]','카펠라의 성흔 : 빠르게, 고통 없이','성흔의 탈리스만[발골]','카펠라의 성흔 : 핵 앤 펀치','성흔의 탈리스만[피스 피어서]',]

}

let emblemSkillOption = 
{
    "가이아" : ['플래티넘 엠블렘[강인한 신념]','플래티넘 엠블렘[런지 에볼루션]','플래티넘 엠블렘[분쇄]','플래티넘 엠블렘[생명의 전조]','플래티넘 엠블렘[신록의 검]','플래티넘 엠블렘[신뢰의 돌파]','플래티넘 엠블렘[압도]','플래티넘 엠블렘[엘비쉬 점프]','플래티넘 엠블렘[워크라이]','플래티넘 엠블렘[체인러쉬]'],
"갓파더" : ['플래티넘 엠블렘[숙련된 전문가]','플래티넘 엠블렘[알파 스트라이크]','플래티넘 엠블렘[장도 마스터리]','플래티넘 엠블렘[전술 지휘]','플래티넘 엠블렘[집중 사격]','플래티넘 엠블렘[최후의 일격]','플래티넘 엠블렘[카라콜]','플래티넘 엠블렘[포인트 블랭크]','플래티넘 엠블렘[헬릭스 다이브]','플래티넘 엠블렘[휩쓸기]'],
"거너(남)" : ['플래티넘 엠블렘[더블 건호크]','플래티넘 엠블렘[슈타이어 대전차포]','플래티넘 엠블렘[스타일리쉬]','플래티넘 엠블렘[잭스파이크+라이징샷]','플래티넘 엠블렘[패스티스트 건]','플래티넘 엠블렘[회심의 랜드러너]'],
"거너(여)" : ['플래티넘 엠블렘[건가드]','플래티넘 엠블렘[마릴린 로즈+라이징샷]','플래티넘 엠블렘[미라클 비전]','플래티넘 엠블렘[슈타이어 대전차포]','플래티넘 엠블렘[패스티스트 건]'],
"검신" : ['플래티넘 엠블렘[검귀의 도 마스터리]','플래티넘 엠블렘[검기상인]','플래티넘 엠블렘[광검 사용 가능]','플래티넘 엠블렘[극 귀검술 : 유성락]','플래티넘 엠블렘[극초발도]','플래티넘 엠블렘[류심 강]','플래티넘 엠블렘[류심 승]','플래티넘 엠블렘[류심 충]','플래티넘 엠블렘[류심 쾌]','플래티넘 엠블렘[리 귀검술]','플래티넘 엠블렘[맹룡단공참]','플래티넘 엠블렘[무기의 극의]','플래티넘 엠블렘[발도]','플래티넘 엠블렘[오버드라이브]','플래티넘 엠블렘[일루젼 슬래쉬]','플래티넘 엠블렘[차지 버스트]','플래티넘 엠블렘[청혈투귀]','플래티넘 엠블렘[환영검무]'],
"검제" : ['플래티넘 엠블렘[거압]','플래티넘 엠블렘[난화검]','플래티넘 엠블렘[백보신장]','플래티넘 엠블렘[비연장]','플래티넘 엠블렘[삼화취정]','플래티넘 엠블렘[승천검+암흑참]','플래티넘 엠블렘[십자검]','플래티넘 엠블렘[오기조원]','플래티넘 엠블렘[일화도강]','플래티넘 엠블렘[폭검]','플래티넘 엠블렘[폭멸장]','플래티넘 엠블렘[혜성만리]'],
"그랜드 마스터" : ['플래티넘 엠블렘[강렬한 테이크 다운]','플래티넘 엠블렘[넥 스냅]','플래티넘 엠블렘[롤링 스파이크]','플래티넘 엠블렘[매그넘설트]','플래티넘 엠블렘[반드시 잡는다!]','플래티넘 엠블렘[브레이크 다운]','플래티넘 엠블렘[블로우 스트라이크]','플래티넘 엠블렘[와일드 캐넌 스파이크]','플래티넘 엠블렘[임팩트 콤비네이션]','플래티넘 엠블렘[피어스]','플래티넘 엠블렘[한놈만 팬다!!]'],
"그림리퍼" : ['플래티넘 엠블렘[날카로운 단검]','플래티넘 엠블렘[단검투척]','플래티넘 엠블렘[섀도우 스팅]','플래티넘 엠블렘[심장 찌르기]','플래티넘 엠블렘[암살]','플래티넘 엠블렘[암살자의 마음가짐]','플래티넘 엠블렘[칼날돌풍]','플래티넘 엠블렘[파이널 디스트럭션]'],
"네메시스" : ['플래티넘 엠블렘[데들리 케이프]','플래티넘 엠블렘[디스트로이어]','플래티넘 엠블렘[리버레이트 - 매드니스]','플래티넘 엠블렘[리버레이트 - 벅샷]','플래티넘 엠블렘[마검발현]','플래티넘 엠블렘[블랙 미러]','플래티넘 엠블렘[소울드레인]','플래티넘 엠블렘[쉐도우 퍼펫]','플래티넘 엠블렘[쉐도우 하베스트]','플래티넘 엠블렘[스타폴]','플래티넘 엠블렘[승천검+암흑참]','플래티넘 엠블렘[우시르의 저주]','플래티넘 엠블렘[이몰레이션]','플래티넘 엠블렘[이보브]','플래티넘 엠블렘[컨제스트]'],
"다크나이트(자각2)" : ['플래티넘 엠블렘[다크 브레이크]','플래티넘 엠블렘[다크 웨이브 폴]','플래티넘 엠블렘[다크 플레임 소드]','플래티넘 엠블렘[래피드 무브]','플래티넘 엠블렘[리 귀검술]','플래티넘 엠블렘[어둠의 광검 마스터리]','플래티넘 엠블렘[어둠의 대검 마스터리]','플래티넘 엠블렘[어둠의 도 마스터리]','플래티넘 엠블렘[어둠의 둔기 마스터리]','플래티넘 엠블렘[어둠의 소검 마스터리]','플래티넘 엠블렘[오버플로우]','플래티넘 엠블렘[웨이브 스핀]','플래티넘 엠블렘[일루젼 슬래쉬]','플래티넘 엠블렘[차지 익스플로전]','플래티넘 엠블렘[툼스톤 스웜프]','플래티넘 엠블렘[프렌지]'],
"다크로드" : ['플래티넘 엠블렘[귀검편]','플래티넘 엠블렘[귀영섬]','플래티넘 엠블렘[귀참 : 광폭]','플래티넘 엠블렘[귀참 : 나락]','플래티넘 엠블렘[냉기의 사야]','플래티넘 엠블렘[달의 커튼]','플래티넘 엠블렘[몽롱한 눈의 브레멘]','플래티넘 엠블렘[사슬 해제]','플래티넘 엠블렘[역병의 라사]','플래티넘 엠블렘[잔영의 케이가]','플래티넘 엠블렘[툼스톤 스웜프]','플래티넘 엠블렘[툼스톤]','플래티넘 엠블렘[파동각인]','플래티넘 엠블렘[흑염의 칼라]'],
"듀란달" : ['플래티넘 엠블렘[뇌격점혈섬]','플래티넘 엠블렘[라이징 랜서]','플래티넘 엠블렘[롤링 벌컨]','플래티넘 엠블렘[메조싸이클론]','플래티넘 엠블렘[미라지 부스트]','플래티넘 엠블렘[삼일참월]','플래티넘 엠블렘[슈트롬]','플래티넘 엠블렘[스윌링 스피어]','플래티넘 엠블렘[스파이럴 웨이브]','플래티넘 엠블렘[오러 랜스]','플래티넘 엠블렘[장창 숙련]','플래티넘 엠블렘[파동연환창]'],
"드레드노트" : ['플래티넘 엠블렘[너클 블래스터]','플래티넘 엠블렘[대식가]','플래티넘 엠블렘[드래곤 스매쉬]','플래티넘 엠블렘[드래곤 팽]','플래티넘 엠블렘[비룡천상]','플래티넘 엠블렘[스피닝 레이드]','플래티넘 엠블렘[슬레이어]','플래티넘 엠블렘[아스트라 소환]','플래티넘 엠블렘[용왕파천]','플래티넘 엠블렘[태도 용제무쌍]','플래티넘 엠블렘[폭음폭식]','플래티넘 엠블렘[폭풍의 숨결]'],
"디스트로이어" : ['플래티넘 엠블렘[FM-31 그레네이드 런처]','플래티넘 엠블렘[FM-92 스팅어 SW]','플래티넘 엠블렘[FM-92 스팅어]','플래티넘 엠블렘[X-1 익스트루더/X-2 헬리오스]','플래티넘 엠블렘[X-1 익스트루더]','플래티넘 엠블렘[레이저 라이플]','플래티넘 엠블렘[미라클 비전]','플래티넘 엠블렘[슈타이어 대전차포]','플래티넘 엠블렘[양자 폭탄]','플래티넘 엠블렘[오버부스트 팩]','플래티넘 엠블렘[잭스파이크+라이징샷]','플래티넘 엠블렘[중화기 마스터리]','플래티넘 엠블렘[충전 레이저 라이플]','플래티넘 엠블렘[플라즈마 부스터]','플래티넘 엠블렘[화염 강타]'],
"디어사이드" : ['플래티넘 엠블렘[검마격살]','플래티넘 엠블렘[광폭화]','플래티넘 엠블렘[마검 제어]','플래티넘 엠블렘[분마검]','플래티넘 엠블렘[사복검 - 무]','플래티넘 엠블렘[사복검 - 발]','플래티넘 엠블렘[사복검 - 조]','플래티넘 엠블렘[사형조수]','플래티넘 엠블렘[역천의 프놈]','플래티넘 엠블렘[파천세]','플래티넘 엠블렘[폭류나선]','플래티넘 엠블렘[혈마검]','플래티넘 엠블렘[혈지군무]','플래티넘 엠블렘[혈화난무]'],
"레이븐" : ['플래티넘 엠블렘[난사]','플래티넘 엠블렘[더블 건호크]','플래티넘 엠블렘[데들리 어프로치]','플래티넘 엠블렘[데스 바이 리볼버]','플래티넘 엠블렘[리볼버 강화]','플래티넘 엠블렘[리턴드 스나이퍼/록 온 서포트]','플래티넘 엠블렘[멀티 헤드샷]','플래티넘 엠블렘[스타일리쉬]','플래티넘 엠블렘[양자 폭탄]','플래티넘 엠블렘[이동사격]','플래티넘 엠블렘[제압 사격]','플래티넘 엠블렘[트리플 탭]','플래티넘 엠블렘[패스티스트 건]','플래티넘 엠블렘[헤드샷]'],
"레퀴엠" : ['플래티넘 엠블렘[목표 사격]','플래티넘 엠블렘[목표 포착]','플래티넘 엠블렘[문페이즈]','플래티넘 엠블렘[보름달 베기]','플래티넘 엠블렘[블레이드 팰컨]','플래티넘 엠블렘[소태도 마스터리]','플래티넘 엠블렘[암월비보]','플래티넘 엠블렘[요원의 전투술]','플래티넘 엠블렘[임무 시작]','플래티넘 엠블렘[조준 사격]'],
"리디머" : ['플래티넘 엠블렘[그리드 더 러커]','플래티넘 엠블렘[글러트니 바밋]','플래티넘 엠블렘[러스트 핸드]','플래티넘 엠블렘[사이드 매서커]','플래티넘 엠블렘[스커드 더 라스]','플래티넘 엠블렘[슬로스 바디]','플래티넘 엠블렘[엔비어스 키스]','플래티넘 엠블렘[일곱개의 대죄]','플래티넘 엠블렘[죄를 깨우는 손길]','플래티넘 엠블렘[죄업에 짓눌린 육신]','플래티넘 엠블렘[짊어진 자의 낫 마스터리]','플래티넘 엠블렘[프라이드 악셀]'],
"마법사(여)" : ['플래티넘 엠블렘[계약소환 : 프리트]','플래티넘 엠블렘[고대의 도서관]','플래티넘 엠블렘[금단의 저주]','플래티넘 엠블렘[더블 스윙]','플래티넘 엠블렘[매직 미사일+천격]','플래티넘 엠블렘[쇼타임]'],
"마신" : ['플래티넘 엠블렘[검은 충격]','플래티넘 엠블렘[군단 정렬]','플래티넘 엠블렘[미드나잇 카니발]','플래티넘 엠블렘[브레인 스톰]','플래티넘 엠블렘[제노사이드 크러쉬]','플래티넘 엠블렘[천공검]','플래티넘 엠블렘[카리스마]','플래티넘 엠블렘[파성추]','플래티넘 엠블렘[파이널 어택]','플래티넘 엠블렘[팬시 트위스트]','플래티넘 엠블렘[혼혈]','플래티넘 엠블렘[희생폭탄]','플래티넘 엠블렘[힘의 논리]'],
"마제스티" : ['플래티넘 엠블렘[견고의 대검 마스터리]','플래티넘 엠블렘[디스트로이어]','플래티넘 엠블렘[마검발현]','플래티넘 엠블렘[마검일체]','플래티넘 엠블렘[반월]','플래티넘 엠블렘[비연참]','플래티넘 엠블렘[빈티지]','플래티넘 엠블렘[섬광]','플래티넘 엠블렘[속성의 소검 마스터리]','플래티넘 엠블렘[승천]','플래티넘 엠블렘[신검합일]','플래티넘 엠블렘[악즉참]','플래티넘 엠블렘[오기조원]','플래티넘 엠블렘[용성락]','플래티넘 엠블렘[참마검]','플래티넘 엠블렘[쾌속검]','플래티넘 엠블렘[쾌속의 도 마스터리]','플래티넘 엠블렘[파쇄의 둔기 마스터리]','플래티넘 엠블렘[폭명기검]','플래티넘 엠블렘[환검]'],
"명왕" : ['플래티넘 엠블렘[광폭혈쇄]','플래티넘 엠블렘[그라운드 태클]','플래티넘 엠블렘[비셔스 래리어트]','플래티넘 엠블렘[더티 배럴]','플래티넘 엠블렘[도발]','플래티넘 엠블렘[독병 투척]','플래티넘 엠블렘[뒷골목 싸움법]','플래티넘 엠블렘[바늘 투척]','플래티넘 엠블렘[베놈 마인]','플래티넘 엠블렘[크레이지 발칸]','플래티넘 엠블렘[클로 마스터리]'],
"뱀파이어 로드" : ['플래티넘 엠블렘[럼블독]','플래티넘 엠블렘[릴로이]','플래티넘 엠블렘[머로더]','플래티넘 엠블렘[블러드 번]','플래티넘 엠블렘[블러드 혼]','플래티넘 엠블렘[블러드]','플래티넘 엠블렘[소울 스틸]','플래티넘 엠블렘[쉬카리]','플래티넘 엠블렘[오퍼링]','플래티넘 엠블렘[커럽션]','플래티넘 엠블렘[토먼트]','플래티넘 엠블렘[팽]','플래티넘 엠블렘[헬베루즈]'],
"벤데타" : ['플래티넘 엠블렘[나이트폴]','플래티넘 엠블렘[데인저러스 훅]','플래티넘 엠블렘[딥 더스크]','플래티넘 엠블렘[라스트 오더]','플래티넘 엠블렘[불스아이]','플래티넘 엠블렘[블랙아웃]','플래티넘 엠블렘[블렌딩 페인]','플래티넘 엠블렘[빈티지]','플래티넘 엠블렘[섀터드 글래스]','플래티넘 엠블렘[셰이커 블래스트]','플래티넘 엠블렘[오버페이스]','플래티넘 엠블렘[올드 패션드]','플래티넘 엠블렘[와일드 플라워]','플래티넘 엠블렘[트레이스]','플래티넘 엠블렘[행오버]'],
"블러드 이블" : ['플래티넘 엠블렘[갈증]','플래티넘 엠블렘[격노]','플래티넘 엠블렘[레이징 퓨리]','플래티넘 엠블렘[버스트 퓨리]','플래티넘 엠블렘[블러드 소드]','플래티넘 엠블렘[블러드 스내치]','플래티넘 엠블렘[블러드러스트]','플래티넘 엠블렘[블러디 레이브]','플래티넘 엠블렘[수라 진공참]','플래티넘 엠블렘[아웃레이지 브레이크]','플래티넘 엠블렘[어둠의 대검 마스터리]','플래티넘 엠블렘[오버플로우]','플래티넘 엠블렘[파동각인]','플래티넘 엠블렘[폭주]','플래티넘 엠블렘[프렌지]','플래티넘 엠블렘[혈십자]'],
"세라핌" : ['플래티넘 엠블렘[계시 : 아리아]','플래티넘 엠블렘[공력의 구]','플래티넘 엠블렘[그랜드 크로스 크래쉬]','플래티넘 엠블렘[대천사의 축복]','플래티넘 엠블렘[라이징 슬래쉬+홀리 스매쉬]','플래티넘 엠블렘[리펜텐스 스매시]','플래티넘 엠블렘[부활]','플래티넘 엠블렘[수호의 축복]','플래티넘 엠블렘[십자가 마스터리]','플래티넘 엠블렘[용맹의 아리아]','플래티넘 엠블렘[용맹의 축복]','플래티넘 엠블렘[일곱개의 대죄]','플래티넘 엠블렘[재생의 아리아]','플래티넘 엠블렘[죄업에 짓눌린 육신]','플래티넘 엠블렘[짊어진 자의 낫 마스터리]','플래티넘 엠블렘[참수]'],
"세이비어" : ['플래티넘 엠블렘[레쓰]','플래티넘 엠블렘[멘데이트 오브 헤븐]','플래티넘 엠블렘[세라픽 페더]','플래티넘 엠블렘[세라픽 폴]','플래티넘 엠블렘[위핑 웨이브]','플래티넘 엠블렘[이스트레인지먼트]','플래티넘 엠블렘[인테그레이션]','플래티넘 엠블렘[컨실리에이션]','플래티넘 엠블렘[컨페션]','플래티넘 엠블렘[페이스풀]'],
"세인트" : ['플래티넘 엠블렘[공참타+스매셔]','플래티넘 엠블렘[굳건한 의지]','플래티넘 엠블렘[디플렉트 월]','플래티넘 엠블렘[무쌍격]','플래티넘 엠블렘[미러클 스플리터]','플래티넘 엠블렘[보호의 징표]','플래티넘 엠블렘[빛의 복수]','플래티넘 엠블렘[생명의 원천]','플래티넘 엠블렘[성령의 메이스]','플래티넘 엠블렘[수호의 은총]','플래티넘 엠블렘[영광의 축복]','플래티넘 엠블렘[용기의 은총]','플래티넘 엠블렘[운명의 창]','플래티넘 엠블렘[정의의 심판]','플래티넘 엠블렘[지의 식신 - 현무]','플래티넘 엠블렘[참회의 망치]','플래티넘 엠블렘[천상의 하모니]','플래티넘 엠블렘[크로스 크래쉬]','플래티넘 엠블렘[플래시 글로브]','플래티넘 엠블렘[합티즘]','플래티넘 엠블렘[힐 윈드]'],
"스톰 트루퍼" : ['플래티넘 엠블렘[APG-63]','플래티넘 엠블렘[FM-31 그레네이드 런처]','플래티넘 엠블렘[FM-92 mk2 랜서 SW]','플래티넘 엠블렘[FM-92 mk2 랜서]','플래티넘 엠블렘[X-1 익스트루더]','플래티넘 엠블렘[데스 바이 리볼버]','플래티넘 엠블렘[레이저 라이플]','플래티넘 엠블렘[미라클 비전]','플래티넘 엠블렘[슈타이어 대전차포]','플래티넘 엠블렘[양자 폭탄]','플래티넘 엠블렘[중화기 마스터리]','플래티넘 엠블렘[충전 레이저 라이플]','플래티넘 엠블렘[플라즈마 부스터]','플래티넘 엠블렘[화염 강타]'],
"시라누이" : ['플래티넘 엠블렘[나선 대차륜 쿠나이]','플래티넘 엠블렘[두꺼비유염탄]','플래티넘 엠블렘[마환 풍마수리검]','플래티넘 엠블렘[비기 : 염무개화]','플래티넘 엠블렘[암기 : 비연수리검]','플래티넘 엠블렘[암영술]','플래티넘 엠블렘[열화천도]','플래티넘 엠블렘[인법 : 바꿔치기]','플래티넘 엠블렘[인법 : 육도윤회]','플래티넘 엠블렘[인법 : 잔영 남기기]','플래티넘 엠블렘[차크라 웨펀 숙련]','플래티넘 엠블렘[화둔 : 홍염]','플래티넘 엠블렘[화염구]','플래티넘 엠블렘[화염의 인]'],
"아슈타르테" : ['플래티넘 엠블렘[배틀메이지의 무기 마스터리]','플래티넘 엠블렘[강습유성타]','플래티넘 엠블렘[금단의 저주]','플래티넘 엠블렘[뇌연격]','플래티넘 엠블렘[니우의 전투술]','플래티넘 엠블렘[더블 스윙]','플래티넘 엠블렘[빙하시대]','플래티넘 엠블렘[쇄패]','플래티넘 엠블렘[오토 체이서]','플래티넘 엠블렘[전장의 여신]','플래티넘 엠블렘[황룡강림]','플래티넘 엠블렘[체이서 프레스]','플래티넘 엠블렘[체이서]','플래티넘 엠블렘[타이머 밤]','플래티넘 엠블렘[트윙클 스매쉬]','플래티넘 엠블렘[슈퍼 체이서]','플래티넘 엠블렘[황룡천공]'],
"아이올로스" : ['플래티넘 엠블렘[대진공]','플래티넘 엠블렘[스톰 스트라이크]','플래티넘 엠블렘[스톰 퀘이커]','플래티넘 엠블렘[싹쓸바람]','플래티넘 엠블렘[윈드 블래스터]','플래티넘 엠블렘[윈드니스]','플래티넘 엠블렘[질풍가도]','플래티넘 엠블렘[칼날 바람]','플래티넘 엠블렘[태풍의 전조]','플래티넘 엠블렘[폭풍의 눈]','플래티넘 엠블렘[휘몰아치는 바람]','플래티넘 엠블렘[휘몰아치는 질풍의 봉 마스터리]'],
"악귀나찰" : ['플래티넘 엠블렘[검귀의 도 마스터리]','플래티넘 엠블렘[귀신보]','플래티넘 엠블렘[귀연참]','플래티넘 엠블렘[귀혼일체]','플래티넘 엠블렘[블러드 소드]','플래티넘 엠블렘[수라 진공참]','플래티넘 엠블렘[악귀참수]','플래티넘 엠블렘[연아]','플래티넘 엠블렘[원귀 : 격살]','플래티넘 엠블렘[원귀 : 연참]','플래티넘 엠블렘[원귀 : 회천]','플래티넘 엠블렘[청혈투귀]','플래티넘 엠블렘[합격 : 나락참]','플래티넘 엠블렘[합격 : 월령참]','플래티넘 엠블렘[합격 : 이혼일섬]','플래티넘 엠블렘[혼백참]'],
"알키오네" : ['플래티넘 엠블렘[단검 마스터리]','플래티넘 엠블렘[라이징 컷+다이빙 애로우]','플래티넘 엠블렘[라이트닝 애로우]','플래티넘 엠블렘[셰이크 다운]','플래티넘 엠블렘[소닉 어설트]','플래티넘 엠블렘[쌍검 마스터리]','플래티넘 엠블렘[어슬랜트 스파이럴]','플래티넘 엠블렘[엑셀 스트라이크]','플래티넘 엠블렘[허리케인]','플래티넘 엠블렘[히트엔드]'],
"어센션" : ['플래티넘 엠블렘[경계망상]','플래티넘 엠블렘[괴리 : 금단의 니알리]','플래티넘 엠블렘[괴리 : 디멘션 할로우]','플래티넘 엠블렘[괴리 : 칙 잇]','플래티넘 엠블렘[괴리 : 패러사이트 스웜]','플래티넘 엠블렘[도그마 디바이스]','플래티넘 엠블렘[차원 : 시공폭뢰]','플래티넘 엠블렘[차원 : 차원광]','플래티넘 엠블렘[차원 : 차원회랑]','플래티넘 엠블렘[차원 : 칼레이도스코프]','플래티넘 엠블렘[차원 : 패러다임 디토네이션]','플래티넘 엠블렘[차원 : 포지트론 블래스트]'],
"언터처블" : ['플래티넘 엠블렘[G-CTF]','플래티넘 엠블렘[겟 온 파이어]','플래티넘 엠블렘[더블 배럴]','플래티넘 엠블렘[디스트러블 퀘이크]','플래티넘 엠블렘[디퓨전 버스트]','플래티넘 엠블렘[번 투 슬래쉬]','플래티넘 엠블렘[서프라이즈 프레전트]','플래티넘 엠블렘[역전의 승부사]','플래티넘 엠블렘[오프레션]','플래티넘 엠블렘[중검 마스터리]','플래티넘 엠블렘[퍼펙트 배팅]','플래티넘 엠블렘[화약 다루기]'],
"얼티밋 디바" : ['플래티넘 엠블렘[강렬한 테이크 다운]','플래티넘 엠블렘[넥 스냅]','플래티넘 엠블렘[반드시 잡는다!]','플래티넘 엠블렘[브레이킹 엘보]','플래티넘 엠블렘[숄더 태클]','플래티넘 엠블렘[수플렉스 싸이클론]','플래티넘 엠블렘[스파이어]','플래티넘 엠블렘[싸이클론 어택]','플래티넘 엠블렘[에어슈타이너]','플래티넘 엠블렘[에어클러치]','플래티넘 엠블렘[와일드 캐넌 스파이크]','플래티넘 엠블렘[파워 슬램]'],
"에레보스" : ['플래티넘 엠블렘[다크 디비젼]','플래티넘 엠블렘[다크 베리얼]','플래티넘 엠블렘[다크 스타]','플래티넘 엠블렘[다크니스]','플래티넘 엠블렘[디스페어]','플래티넘 엠블렘[디퓨젼]','플래티넘 엠블렘[마창 폭주]','플래티넘 엠블렘[바이올런트 피어스]','플래티넘 엠블렘[스파이럴 러쉬]','플래티넘 엠블렘[암흑나선창]','플래티넘 엠블렘[어둠의 투창 마스터리]','플래티넘 엠블렘[잠식]','플래티넘 엠블렘[절명창]','플래티넘 엠블렘[흑광폭살]'],
"엑스마키나" : ['플래티넘 엠블렘[네오디뮴 퓨지]','플래티넘 엠블렘[다이렉트 피드백]','플래티넘 엠블렘[맥피스]','플래티넘 엠블렘[버스트 밤]','플래티넘 엠블렘[부스트 : 다이너마이트 킥]','플래티넘 엠블렘[부스트 : 바디 벙커]','플래티넘 엠블렘[부스트 : 브리칭 다운]','플래티넘 엠블렘[센서빌리티]','플래티넘 엠블렘[스코치 택틱스]','플래티넘 엠블렘[액티베이션]','플래티넘 엠블렘[어썰트의 자동권총 마스터리]','플래티넘 엠블렘[트리플 건샷]','플래티넘 엠블렘[포트리스 블리츠]'],
"염제 폐월수화" : ['플래티넘 엠블렘[광룡십삼장]','플래티넘 엠블렘[광충노도]','플래티넘 엠블렘[기공장]','플래티넘 엠블렘[나선의 넨]','플래티넘 엠블렘[뇌명 : 사나운 빛의 넨수]','플래티넘 엠블렘[뇌전격]','플래티넘 엠블렘[비룡나선파]','플래티넘 엠블렘[사자후]','플래티넘 엠블렘[카이]','플래티넘 엠블렘[크레이지 발칸]','플래티넘 엠블렘[태사자후]','플래티넘 엠블렘[태양의 숨결]','플래티넘 엠블렘[환영폭쇄]'],
"염황 광풍제월" : ['플래티넘 엠블렘[기공장]','플래티넘 엠블렘[기공환]','플래티넘 엠블렘[기호지세]','플래티넘 엠블렘[나선의 넨 : 주]','플래티넘 엠블렘[나선의 넨]','플래티넘 엠블렘[넨 스피어]','플래티넘 엠블렘[뇌명 : 사나운 빛의 넨수]','플래티넘 엠블렘[뇌전각]','플래티넘 엠블렘[머슬 시프트]','플래티넘 엠블렘[사자후]','플래티넘 엠블렘[역혈기공]','플래티넘 엠블렘[환영폭쇄]'],
"오버마인드" : ['플래티넘 엠블렘[나이트 할로우]','플래티넘 엠블렘[메모라이즈]','플래티넘 엠블렘[보이드]','플래티넘 엠블렘[속성 마스터리]','플래티넘 엠블렘[쇼타임]','플래티넘 엠블렘[썬더 콜링]','플래티넘 엠블렘[아크틱 피스트]','플래티넘 엠블렘[엘레멘탈 번]','플래티넘 엠블렘[엘레멘탈 커튼]','플래티넘 엠블렘[엘레멘탈 퀘이크]','플래티넘 엠블렘[칠링 팬스]','플래티넘 엠블렘[플레임 스트라이크]','플래티넘 엠블렘[핼로윈 버스터]'],
"오블리비언" : ['플래티넘 엠블렘[다크니스 멘틀]','플래티넘 엠블렘[라이트닝 월]','플래티넘 엠블렘[마나 폭주]','플래티넘 엠블렘[아이스 크리스탈 샤워]','플래티넘 엠블렘[암전]','플래티넘 엠블렘[엘레멘탈 레인]','플래티넘 엠블렘[엘레멘탈 스트라이크]','플래티넘 엠블렘[엘레멘탈 실드]','플래티넘 엠블렘[원소 융합]','플래티넘 엠블렘[원소의 이해]','플래티넘 엠블렘[체인라이트닝]','플래티넘 엠블렘[컨센트레이트]','플래티넘 엠블렘[크리스탈 어택]','플래티넘 엠블렘[파이어로드]','플래티넘 엠블렘[플레임 서클]'],
"옵티머스" : ['플래티넘 엠블렘[Ex-S 바이퍼]','플래티넘 엠블렘[G-1 코로나]','플래티넘 엠블렘[HS-12 플라즈마]','플래티넘 엠블렘[공중 전폭 메카 : 게일 포스]','플래티넘 엠블렘[로보틱스]','플래티넘 엠블렘[메카 드롭]','플래티넘 엠블렘[병기 숙련]','플래티넘 엠블렘[스패로우 팩토리]','플래티넘 엠블렘[오버 차지]','플래티넘 엠블렘[트랜스포메이션]','플래티넘 엠블렘[트랜스폼 : G-2 롤링썬더]','플래티넘 엠블렘[트랜스폼 : G-3 랩터]','플래티넘 엠블렘[프리스비]','플래티넘 엠블렘[하이테크놀로지]'],
"용독문주" : ['플래티넘 엠블렘[그라운드 킥]','플래티넘 엠블렘[도발]','플래티넘 엠블렘[독 바르기]','플래티넘 엠블렘[마운트]','플래티넘 엠블렘[맹독 일발화약성]','플래티넘 엠블렘[반드시 잡는다!]','플래티넘 엠블렘[베놈 마인]','플래티넘 엠블렘[블록 봄]','플래티넘 엠블렘[일발화약성]','플래티넘 엠블렘[정크 스핀]','플래티넘 엠블렘[천라지망]','플래티넘 엠블렘[클로 마스터리]','플래티넘 엠블렘[히든 스팅]'],
"워로드" : ['플래티넘 엠블렘[둠 글레이브]','플래티넘 엠블렘[드레드보어]','플래티넘 엠블렘[디베스테이트]','플래티넘 엠블렘[런지 스트라이크]','플래티넘 엠블렘[마창 제어]','플래티넘 엠블렘[마창 해방]','플래티넘 엠블렘[블레이드 스톰]','플래티넘 엠블렘[비헤드]','플래티넘 엠블렘[임팩트 스매쉬]','플래티넘 엠블렘[크레센트 슬래쉬]'],
"이모탈" : ['플래티넘 엠블렘[낫 마스터리]','플래티넘 엠블렘[데빌 클러치]','플래티넘 엠블렘[데빌 피스트]','플래티넘 엠블렘[복마전]','플래티넘 엠블렘[복수의 가시]','플래티넘 엠블렘[스피닝 디포메이션]','플래티넘 엠블렘[어둠의 권능]','플래티넘 엠블렘[재앙]','플래티넘 엠블렘[추락하는 영혼]','플래티넘 엠블렘[환청]'],
"이클립스" : ['플래티넘 엠블렘[계약소환 : 검은기사 산도르]','플래티넘 엠블렘[계약소환 : 루이즈 언니!]','플래티넘 엠블렘[계약소환 : 마계화 아우쿠소]','플래티넘 엠블렘[계약소환 : 타우킹 쿠루타]','플래티넘 엠블렘[교감]','플래티넘 엠블렘[분노의 난타]','플래티넘 엠블렘[스위트 캔디바]','플래티넘 엠블렘[영원한 소유]','플래티넘 엠블렘[정령 희생]','플래티넘 엠블렘[정령소환 : 글레어린]','플래티넘 엠블렘[정령소환 : 데드 멀커]','플래티넘 엠블렘[정령소환 : 아퀘리스]','플래티넘 엠블렘[정령소환 : 융합정령 헤일롬]','플래티넘 엠블렘[정령소환 : 정령왕 에체베리아]','플래티넘 엠블렘[정령소환 : 플레임 헐크]','플래티넘 엠블렘[황룡강림]','플래티넘 엠블렘[화끈한 애정]','플래티넘 엠블렘[환수 강화 오라]','플래티넘 엠블렘[환수 폭주]'],
"이터널" : ['플래티넘 엠블렘[공명]','플래티넘 엠블렘[라이트닝 월]','플래티넘 엠블렘[발현]','플래티넘 엠블렘[브로큰 애로우]','플래티넘 엠블렘[블리자드 스톰]','플래티넘 엠블렘[아이스 로드]','플래티넘 엠블렘[아이스 빅 해머]','플래티넘 엠블렘[아이스 오브]','플래티넘 엠블렘[아이스 크래쉬]','플래티넘 엠블렘[아이스 필드]','플래티넘 엠블렘[아이스맨]','플래티넘 엠블렘[체인라이트닝]','플래티넘 엠블렘[프로즌웨폰 마스터리]','플래티넘 엠블렘[피어스 오브 아이스]','플래티넘 엠블렘[회전투창]'],
"인다라천" : ['플래티넘 엠블렘[귀문반]','플래티넘 엠블렘[극파동검 빙인]','플래티넘 엠블렘[극파동검 폭염]','플래티넘 엠블렘[무쌍파]','플래티넘 엠블렘[부동명왕진]','플래티넘 엠블렘[사슬 해제]','플래티넘 엠블렘[살의의 파동]','플래티넘 엠블렘[수라 진공참]','플래티넘 엠블렘[작열 파동진]','플래티넘 엠블렘[정신이 번쩍!]','플래티넘 엠블렘[진공참]','플래티넘 엠블렘[툼스톤 스웜프]','플래티넘 엠블렘[파동 해제]','플래티넘 엠블렘[파동각인]','플래티넘 엠블렘[파동검 빙인]','플래티넘 엠블렘[파동검 폭염]','플래티넘 엠블렘[흑염의 칼라]'],
"인페르노" : ['플래티넘 엠블렘[고결한 격노]','플래티넘 엠블렘[광적인 믿음]','플래티넘 엠블렘[배틀액스 마스터리]','플래티넘 엠블렘[성화]','플래티넘 엠블렘[세례의 불길]','플래티넘 엠블렘[심판의 일격]','플래티넘 엠블렘[업화의 소용돌이]','플래티넘 엠블렘[역죄단멸]','플래티넘 엠블렘[즉결심판]','플래티넘 엠블렘[징벌의 일격]','플래티넘 엠블렘[참수]','플래티넘 엠블렘[플레게의 정수]','플래티넘 엠블렘[플레게의 화염병]'],
"저스티스" : ['플래티넘 엠블렘[개틀링 펀치]','플래티넘 엠블렘[고저스 컴비네이션]','플래티넘 엠블렘[더킹 바디블로]','플래티넘 엠블렘[더킹 어퍼]','플래티넘 엠블렘[데몰리션 펀치]','플래티넘 엠블렘[머신건 잽]','플래티넘 엠블렘[섀도우 박서]','플래티넘 엠블렘[윌 드라이버]','플래티넘 엠블렘[초핑 해머]','플래티넘 엠블렘[코크 스크류 블로우]','플래티넘 엠블렘[테크니컬 마스터리]','플래티넘 엠블렘[허리케인 롤]','플래티넘 엠블렘[헤븐리 컴비네이션]'],
"제노사이더" : ['플래티넘 엠블렘[광창 마스터리]','플래티넘 엠블렘[마나 익스트랙트]','플래티넘 엠블렘[서번트 랜스 : 인게이저]','플래티넘 엠블렘[서번트 랜스]','플래티넘 엠블렘[스케일 그라인더]','플래티넘 엠블렘[어스웜 헌트]','플래티넘 엠블렘[팔티잔 스큐어]','플래티넘 엠블렘[페니트레잇 헤드]','플래티넘 엠블렘[헌터즈 샤프니스]'],
"지니위즈" : ['플래티넘 엠블렘[고대의 도서관]','플래티넘 엠블렘[대왕 파리채]','플래티넘 엠블렘[도발 인형 : 슈르르]','플래티넘 엠블렘[메가 드릴]','플래티넘 엠블렘[반중력 기동장치]','플래티넘 엠블렘[변이 파리채]','플래티넘 엠블렘[빗자루 스핀]','플래티넘 엠블렘[성난 불길 가열로]','플래티넘 엠블렘[쇼타임]','플래티넘 엠블렘[스위트 캔디바]','플래티넘 엠블렘[애시드 클라우드]','플래티넘 엠블렘[용암지대 생성물약]','플래티넘 엠블렘[퍼밀리어와 친하게 지내기]','플래티넘 엠블렘[플로레 전기토끼]','플래티넘 엠블렘[플로레 컬라이더]'],
"천선낭랑" : ['플래티넘 엠블렘[공력의 구]','플래티넘 엠블렘[라이징 슬래쉬+홀리 스매쉬]','플래티넘 엠블렘[목환자경]','플래티넘 엠블렘[백팔염주]','플래티넘 엠블렘[법력 강화]','플래티넘 엠블렘[부동주박진]','플래티넘 엠블렘[시폭염화부]','플래티넘 엠블렘[신탁의 기원]','플래티넘 엠블렘[심혼흡성부]','플래티넘 엠블렘[용의 격노]','플래티넘 엠블렘[천녀기우제]','플래티넘 엠블렘[탈명대염진]'],
"카이저" : ['플래티넘 엠블렘[강권]','플래티넘 엠블렘[급소 지정]','플래티넘 엠블렘[라이징 너클]','플래티넘 엠블렘[라이트닝 댄스]','플래티넘 엠블렘[머슬 시프트]','플래티넘 엠블렘[본 크러셔]','플래티넘 엠블렘[붕권]','플래티넘 엠블렘[비트 드라이브]','플래티넘 엠블렘[원인치 펀치]','플래티넘 엠블렘[일주연환격]','플래티넘 엠블렘[철산고]','플래티넘 엠블렘[카이]','플래티넘 엠블렘[파쇄권]'],
"커맨더" : ['플래티넘 엠블렘[C4]','플래티넘 엠블렘[G-18C 빙결류탄]','플래티넘 엠블렘[G-35L 섬광류탄]','플래티넘 엠블렘[G-61 중력류탄]','플래티넘 엠블렘[X-1 익스트루더]','플래티넘 엠블렘[강화탄]','플래티넘 엠블렘[고전압탄 : 맥스웰]','플래티넘 엠블렘[교차 사격]','플래티넘 엠블렘[네이팜 탄]','플래티넘 엠블렘[록 온 서포트]','플래티넘 엠블렘[리턴드 스나이퍼/록 온 서포트]','플래티넘 엠블렘[리턴드 스나이퍼]','플래티넘 엠블렘[매거진 드럼]','플래티넘 엠블렘[버스터 샷]','플래티넘 엠블렘[병기 숙련]','플래티넘 엠블렘[아크리액터]','플래티넘 엠블렘[오버 차지]','플래티넘 엠블렘[유탄 마스터리]','플래티넘 엠블렘[폭발탄]','플래티넘 엠블렘[피스톨 카빈]'],
"크리에이터(자각2)" : ['플래티넘 엠블렘[계열강화]','플래티넘 엠블렘[링크]','플래티넘 엠블렘[빙하시대]','플래티넘 엠블렘[상상력]','플래티넘 엠블렘[아이스 스톤]','플래티넘 엠블렘[아이스 실드]','플래티넘 엠블렘[아이스 플레이트]','플래티넘 엠블렘[웜홀]','플래티넘 엠블렘[윈드 스톰]','플래티넘 엠블렘[윈드 프레스]','플래티넘 엠블렘[증폭]'],
"크림슨 로제" : ['플래티넘 엠블렘[권총의 춤]','플래티넘 엠블렘[더블 건호크]','플래티넘 엠블렘[데스 바이 리볼버]','플래티넘 엠블렘[리볼버 강화]','플래티넘 엠블렘[멀티 헤드샷]','플래티넘 엠블렘[블러디 스파이크]','플래티넘 엠블렘[스타일리쉬]','플래티넘 엠블렘[이동사격]','플래티넘 엠블렘[제압 사격]'],
"타나토스" : ['플래티넘 엠블렘[기요틴]','플래티넘 엠블렘[니콜라스 강령]','플래티넘 엠블렘[리빙데드]','플래티넘 엠블렘[발라크르의 맹약]','플래티넘 엠블렘[발라크르의 야망]','플래티넘 엠블렘[암흑의 의식]','플래티넘 엠블렘[어둠의 고리]','플래티넘 엠블렘[좀비 강령]','플래티넘 엠블렘[학살의 발라크르 강령]','플래티넘 엠블렘[흑마법서 : 망자의 혼]','플래티넘 엠블렘[흑사진]','플래티넘 엠블렘[흡기암경파]'],
"태을선인" : ['플래티넘 엠블렘[거병 마스터리]','플래티넘 엠블렘[거선풍]','플래티넘 엠블렘[공참타+스매셔]','플래티넘 엠블렘[봉화연마]','플래티넘 엠블렘[난격]','플래티넘 엠블렘[대회전격]','플래티넘 엠블렘[멸혼부]','플래티넘 엠블렘[무쌍격]','플래티넘 엠블렘[역린격]','플래티넘 엠블렘[퇴마의 서]'],
"패스파인더" : ['플래티넘 엠블렘[디바이드 어쓰]','플래티넘 엠블렘[디스펄션]','플래티넘 엠블렘[라이트닝 슬래쉬]','플래티넘 엠블렘[스퀘어리]','플래티넘 엠블렘[에너지 바운스]','플래티넘 엠블렘[이미션]','플래티넘 엠블렘[인젝션]','플래티넘 엠블렘[일렉트릭 필러]','플래티넘 엠블렘[코어 블레이드 마스터리]','플래티넘 엠블렘[코어 프렉시스]'],
"패황" : ['플래티넘 엠블렘[강권]','플래티넘 엠블렘[라이트닝 댄스]','플래티넘 엠블렘[머슬 시프트]','플래티넘 엠블렘[본 크러셔]','플래티넘 엠블렘[비연선풍]','플래티넘 엠블렘[스핀 킥]','플래티넘 엠블렘[철산고]','플래티넘 엠블렘[클로즈 킥]','플래티넘 엠블렘[헥토파스칼 킥]','플래티넘 엠블렘[홍염 질풍각]'],
"프라임" : ['플래티넘 엠블렘[Ex-S 바이퍼 Zero]','플래티넘 엠블렘[Ex-S 바이퍼]','플래티넘 엠블렘[Ez-10 카운터어택]','플래티넘 엠블렘[Ez-8 카운트다운]','플래티넘 엠블렘[RX-60 트랩러너]','플래티넘 엠블렘[공중 전투 메카 : 템페스터]','플래티넘 엠블렘[로보틱스]','플래티넘 엠블렘[로봇 엔지니어링]','플래티넘 엠블렘[로봇 전폭]','플래티넘 엠블렘[메카 드롭]','플래티넘 엠블렘[스패로우 팩토리]','플래티넘 엠블렘[아크리액터]'],
"프레이야" : ['플래티넘 엠블렘[C4]','플래티넘 엠블렘[G-18C 빙결류탄]','플래티넘 엠블렘[G-35L 섬광류탄]','플래티넘 엠블렘[G-61 중력류탄]','플래티넘 엠블렘[M18 클레이모어]','플래티넘 엠블렘[강화탄]','플래티넘 엠블렘[관통탄]','플래티넘 엠블렘[교차 사격]','플래티넘 엠블렘[네이팜 탄]','플래티넘 엠블렘[니트로 모터]','플래티넘 엠블렘[록 온 서포트]','플래티넘 엠블렘[버스터 샷]','플래티넘 엠블렘[병기 숙련]','플래티넘 엠블렘[스타일리쉬]','플래티넘 엠블렘[오버 차지]','플래티넘 엠블렘[유탄 마스터리]','플래티넘 엠블렘[폭발탄]','플래티넘 엠블렘[피스톨 카빈]'],
"헤카테" : ['플래티넘 엠블렘[구속의 가시넝쿨]','플래티넘 엠블렘[금단의 저주]','플래티넘 엠블렘[꼼꼼한 바느질]','플래티넘 엠블렘[데스티니 퍼펫]','플래티넘 엠블렘[매드 더 빅]','플래티넘 엠블렘[사랑의 응급조치]','플래티넘 엠블렘[사악한 호기심]','플래티넘 엠블렘[썬더 콜링]','플래티넘 엠블렘[엘레멘탈 번]','플래티넘 엠블렘[점핑 베어 프레스]','플래티넘 엠블렘[체이서 프레스]','플래티넘 엠블렘[테러블 로어]','플래티넘 엠블렘[퍼밀리어와 친하게 지내기]','플래티넘 엠블렘[퍼페티어]','플래티넘 엠블렘[편애]','플래티넘 엠블렘[하베스팅]','플래티넘 엠블렘[헥스]'],
'셀레스티얼' : 	['플래티넘 엠블렘[여행자의 직감]','플래티넘 엠블렘[익사이팅]','플래티넘 엠블렘[장궁 숙련]'],
'트렌드세터' : 	['플래티넘 엠블렘[다 카포]','플래티넘 엠블렘[러블리 템포]','플래티넘 엠블렘[센세이션]','플래티넘 엠블렘[오늘의 주인공]','플래티넘 엠블렘[팬 서비스]'],
'메이븐' : ['플래티넘 엠블렘[사냥감 식별]','플래티넘 엠블렘[크로스슈터 마스터리]','플래티넘 엠블렘[사냥을 시작해볼까!]','플래티넘 엠블렘[세컨드 부스트]'],
'프레데터' : ['플래티넘 엠블렘[특이체질]','플래티넘 엠블렘[부여된 야성]','플래티넘 엠블렘[하악질]']
}

let customEquip = 
{
    '딥 다이버 네비게이션 링' : 	[160,159,249,107,103,353,111,105,400,258,156,247,303,217,293,296,301,305,302,345,295,418,304,87,140,297,299,268,396,298,91,104,294,300,263,417,255,90,264,337,71,335,215,259,394,338,151,216,85,161,131,266,402,236,137,351,308,214,74,70,239,72,343,244,391,248,75,62,261,401,157,269],
    '딥 다이버 네클레스' : 	[160,413,11,250,353,307,159,9,258,217,156,303,337,268,263,400,140,90,255,293,264,10,301,153,302,247,345,396,299,294,305,87,295,266,298,300,259,304,244,91,85,239,161,296,414,215,297,351,391,308,137,157,151,131,236,338,75,402,248,71,261,401,394,70,214,72,343,335,74,269,216,62],
    '딥 다이버 마스크' : 	[357,355,398,89,347,265,77,160,159,209,206,258,156,247,400,421,233,113,122,237,91,125,123,217,266,86,422,93,161,216,395,148,339,133,214,240,256,74,348,215,75,72,138,152,401,132,259,70,309,157,248,402,262,71,338,335,62,121,115,116,120,117,114,147,146,392,118,124,149,245,119,269],
    '딥 다이버 벨트' : 	[366,409,319,160,50,159,361,410,312,258,156,400,247,46,96,217,365,25,48,45,193,30,27,177,173,31,28,26,33,367,32,29,170,341,197,47,91,181,94,358,266,234,382,189,185,216,252,167,214,70,215,161,219,401,162,385,269,335,71,338,164,157,62,75,74,386,72,383,259,248,384,402],
    '딥 다이버 슈즈' : 	[241,411,128,126,129,112,160,50,159,258,400,156,318,217,247,46,193,25,173,96,27,47,181,45,177,48,29,189,31,30,358,91,234,197,215,33,32,26,341,28,216,252,412,170,266,75,70,167,161,72,248,71,259,62,401,382,219,162,94,214,185,164,335,74,385,338,384,402,386,269,157,383],
    '딥 다이버 슈트' : 	[1,106,60,160,50,159,200,400,403,201,156,202,258,217,247,96,46,25,48,45,27,30,32,28,197,47,33,29,31,341,177,193,358,181,26,94,234,173,215,266,170,91,216,161,252,382,189,404,167,164,75,214,219,74,401,338,72,386,157,185,402,162,70,269,71,62,248,259,335,383,385,384],
    '딥 다이버 에어팩' : 	[16,160,317,313,316,320,159,50,258,156,247,400,349,96,91,193,173,197,46,181,177,25,48,170,358,45,189,185,217,216,341,252,74,167,214,75,266,382,162,215,234,161,72,164,219,94,29,33,402,26,28,30,32,27,401,386,157,259,335,248,338,47,385,383,70,407,384,71,31,62,269,408],
    '딥 다이버 오리발' : 	[355,398,89,347,265,77,160,159,204,210,207,258,156,400,247,352,356,344,237,113,122,217,91,125,123,148,266,133,240,93,214,161,138,152,216,339,147,309,149,215,256,262,132,348,74,75,146,157,70,72,401,245,392,71,121,116,259,62,402,115,120,117,124,338,335,248,118,114,119,419,420,269],
    '딥 다이버 워치' : 	[14,15,315,321,251,160,314,416,217,415,140,268,337,90,255,214,264,159,263,394,131,266,343,215,137,151,91,71,87,70,304,156,402,298,308,296,261,351,244,345,302,293,247,236,400,391,239,305,85,396,295,258,299,353,301,300,297,74,62,401,303,338,294,216,269,161,72,259,157,335,248,75],
    '딥 다이버 이어링' : 	[231,355,398,89,347,265,77,160,159,205,203,211,208,258,156,247,400,424,423,145,237,122,113,91,125,148,123,217,339,266,133,161,216,256,93,240,348,214,215,74,75,152,132,401,259,157,309,138,70,147,72,248,335,262,121,402,71,117,146,115,116,62,338,114,119,120,149,118,124,392,269,245],
    '딥 다이버 팬츠' : 	[192,194,196,198,160,159,195,50,199,193,156,258,197,247,400,96,46,25,45,181,48,406,177,189,358,217,91,173,170,266,341,382,185,74,216,161,405,214,252,28,94,167,29,215,234,162,75,164,219,30,26,33,27,32,72,47,402,157,338,259,401,248,70,386,71,31,335,385,384,383,62,269],
    '블루 베릴 네클레스' : 	[276,413,272,160,353,159,275,381,380,303,156,258,247,400,302,293,305,295,296,297,300,294,301,345,298,87,304,299,268,263,396,414,140,217,264,337,255,85,236,90,394,377,351,91,216,75,161,215,266,343,261,72,131,401,214,151,157,70,269,239,248,74,402,308,71,391,259,338,244,335,137,62],
    '블루 베릴 링' : 	[135,225,345,160,212,224,159,353,258,277,302,278,303,247,156,400,417,305,295,293,297,87,298,299,300,304,217,294,296,301,268,396,91,140,263,255,264,215,418,214,351,151,343,394,337,236,266,259,161,308,137,216,244,90,401,70,157,391,85,402,335,75,62,248,131,261,72,338,239,71,269,74],
    '블루 베릴 벨트' : 	[184,186,188,160,190,50,187,96,191,159,217,48,45,25,46,409,258,247,156,189,185,47,400,26,33,32,27,29,358,31,28,181,30,197,173,234,215,177,91,214,248,410,382,216,341,167,75,266,193,385,161,170,74,219,402,162,252,386,72,94,62,338,269,70,401,71,164,259,384,335,157,383],
    '블루 베릴 보호대' : 	[242,49,98,160,50,159,258,156,247,400,46,193,173,45,197,25,390,177,48,181,96,323,170,189,185,358,91,94,322,217,341,162,266,161,167,216,214,407,382,215,164,252,30,234,219,28,75,29,74,33,27,401,70,32,259,71,26,386,157,47,385,248,402,62,72,335,31,383,338,384,408,269],
    '블루 베릴 부츠' : 	[166,411,130,160,168,167,159,50,258,46,99,400,163,156,247,96,25,217,170,162,248,193,181,165,164,48,189,157,338,28,94,266,45,91,214,401,177,29,358,173,32,216,197,33,27,26,185,215,30,269,71,234,335,161,70,219,383,341,252,31,72,382,75,384,74,386,62,47,402,412,385,259],
    '블루 베릴 아머' : 	[354,160,127,159,50,258,156,101,247,400,346,46,88,96,403,193,397,173,94,358,177,170,197,45,181,161,25,48,341,214,91,185,215,189,266,234,382,216,167,217,162,252,219,259,30,71,157,70,164,401,28,29,248,72,385,62,386,33,402,74,383,75,32,26,27,384,47,335,404,338,31,269],
    '블루 베릴 암릿' : 	[324,325,416,271,160,415,274,273,150,217,159,140,303,268,255,263,337,294,302,90,264,400,258,353,156,304,300,298,247,215,299,296,75,214,305,266,261,244,295,391,236,62,137,308,85,335,71,151,338,216,161,297,72,396,301,401,394,248,87,157,269,259,239,131,91,402,293,343,345,74,70,351],
    '블루 베릴 이어링' : 	[355,398,89,265,347,77,160,218,159,340,222,134,258,156,247,400,220,424,423,378,237,113,148,122,240,125,91,217,161,266,216,93,123,339,215,256,133,138,348,214,152,146,117,402,248,147,149,132,262,120,75,71,309,259,157,124,62,401,74,245,114,72,116,115,70,392,121,118,338,335,119,269],
    '블루 베릴 젬스톤' : 	[355,398,89,265,347,77,160,159,156,258,229,400,247,228,421,217,237,12,7,113,232,122,91,125,5,148,256,240,133,138,123,392,339,262,146,309,348,245,149,152,215,266,147,132,93,161,422,216,214,75,74,115,157,71,248,119,124,70,401,118,335,72,120,114,62,259,116,117,402,121,338,269,3],
    '블루 베릴 퍼퓸' : 	[355,265,398,89,347,226,77,160,227,159,223,379,258,156,247,400,76,217,221,237,113,148,122,91,125,240,123,266,133,339,93,216,256,215,161,214,132,138,309,348,152,262,147,146,149,392,74,245,259,157,402,70,75,248,419,72,71,120,62,121,401,335,114,115,117,118,124,338,116,119,269,420],
    '블루 베릴 하의' : 	[160,260,159,50,281,280,279,282,100,258,156,247,400,406,217,405,46,96,45,25,48,193,173,197,358,177,91,181,94,167,31,26,170,185,29,27,28,33,32,341,30,189,47,215,214,234,252,161,216,70,266,382,74,259,72,164,75,71,162,219,386,157,385,401,384,335,402,383,62,338,269,248],
    '블루 파이렛 네클레스' : 	[462,413,160,414,353,463,159,303,78,396,258,461,156,293,247,400,297,464,305,298,294,302,295,301,466,345,300,87,296,304,268,299,90,263,351,264,394,337,91,259,216,401,215,255,217,402,239,343,161,236,391,244,214,151,269,75,266,85,131,74,70,248,308,157,140,72,71,137,261,335],
    '블루 파이렛 링' : 	[467,471,472,160,159,258,156,247,303,400,418,305,293,302,468,295,294,301,296,297,300,304,298,299,469,417,353,268,263,255,264,90,217,470,75,91,140,216,161,74,266,351,214,215,72,87,396,151,269,236,137,343,239,261,338,308,259,345,337,85,244,394,157,402,131,248,401,391,71,62,335,70],
    '블루 파이렛 벨트' : 	[450,451,454,160,50,159,258,400,453,449,247,156,193,217,46,452,409,48,25,181,45,173,197,91,170,31,341,266,382,185,358,215,177,189,96,410,214,161,216,30,28,94,29,75,47,167,70,157,234,32,252,338,72,33,162,402,248,26,269,71,259,164,74,62,401,384,386,27,385,219,335,383],
    '블루 파이렛 부츠' : 	[446,444,412,448,160,50,159,447,411,247,445,400,156,48,258,443,25,46,197,177,193,266,29,173,358,45,216,384,217,189,161,164,170,162,91,33,28,219,215,181,30,248,386,382,157,185,94,335,31,47,401,167,96,269,70,402,341,72,32,71,74,27,252,62,214,385,338,75,234,383,259,26],
    '블루 파이렛 블라우스' : 	[160,430,403,425,50,429,159,426,334,427,156,404,258,247,400,46,96,217,91,30,181,170,189,167,28,25,197,185,341,32,177,384,234,173,94,215,193,45,161,358,71,216,402,383,74,70,48,29,72,157,266,164,386,33,252,47,75,27,248,219,162,401,385,269,382,338,62,31,214],
    '블루 파이렛 숄더' : 	[436,435,433,434,160,50,159,407,258,156,247,400,432,46,408,193,431,173,48,197,25,177,45,181,96,170,189,185,266,91,341,358,94,161,216,217,167,29,215,164,214,33,252,234,162,382,74,30,28,26,72,27,338,401,70,31,402,75,157,47,71,32,248,386,219,383,269,384,335,259,62,385],
    '블루 파이렛 암릿' : 	[457,455,416,460,415,459,456,160,458,159,300,297,295,156,296,304,294,305,299,301,140,258,293,298,302,264,216,303,268,400,91,90,261,71,263,236,87,353,217,269,394,391,247,215,396,255,343,335,131,62,401,259,308,157,75,345,337,137,74,70,266,244,214,151,85,72,248,351,239,338,161],
    '블루 파이렛 이어링' : 	[355,398,89,347,77,490,423,487,160,159,424,265,486,258,156,400,247,489,237,488,485,339,133,348,256,122,113,216,152,123,132,215,148,91,214,266,217,75,74,125,116,392,121,161,146,402,338,262,70,117,114,138,245,259,248,115,309,401,335,124,118,149,72,240,120,62,71,147,269,119,157,93],
    '블루 파이렛 쥬얼' : 	[355,398,89,347,77,484,160,482,483,159,421,479,265,481,156,247,258,400,480,422,237,217,256,339,93,266,138,123,91,216,133,125,148,348,240,149,113,122,214,392,132,152,124,116,335,121,147,309,117,259,115,70,161,157,146,215,120,72,74,114,62,338,262,401,269,119,71,402,118,248,75,245],
    '블루 파이렛 팬츠' : 	[160,439,441,437,159,50,442,405,247,400,438,258,156,406,48,46,193,440,25,45,170,197,173,177,96,189,181,358,94,167,217,214,185,161,91,29,266,341,215,338,28,33,382,216,164,30,401,219,71,269,162,32,234,335,26,70,252,27,62,157,74,383,248,402,47,75,31,386,385,384,72,259],
    '블루 파이렛 프로텍터' : 	[355,398,347,89,77,160,159,265,477,478,476,258,156,400,247,237,475,217,420,474,339,133,256,419,348,240,148,216,147,138,152,123,113,392,146,266,93,91,122,309,125,161,149,132,473,118,262,401,119,214,248,124,338,75,74,121,62,120,117,335,114,402,245,116,269,215,157,72,71,70,259],
    '숲속의 마녀 귀걸이' : 	[355,398,89,347,265,77,160,230,159,258,156,247,400,22,424,423,52,326,327,237,113,122,125,91,123,148,217,133,266,161,339,348,216,240,256,152,401,262,147,138,72,118,215,132,93,309,117,149,75,70,74,335,120,245,124,248,157,146,238,121,115,214,114,62,392,116,269,338,259,119,402,71],
    '숲속의 마녀 망토' : 	[176,178,2,160,171,169,159,50,177,179,258,247,400,156,170,46,96,45,193,173,25,48,94,197,358,189,91,341,217,161,266,181,185,216,382,214,215,219,167,162,164,28,252,407,30,157,234,29,33,74,27,32,75,401,26,402,31,70,384,248,386,72,383,335,385,259,62,47,71,408,269,338],
    '숲속의 마녀 목걸이' : 	[109,413,353,160,159,303,258,156,247,400,293,305,297,296,302,285,299,295,294,298,300,301,304,342,328,268,263,270,217,90,264,337,255,345,140,236,396,139,87,343,351,214,151,266,239,91,401,71,394,215,74,216,338,161,131,414,261,137,308,75,70,391,72,244,269,85,62,402,335,259,157,248],
    '숲속의 마녀 바구니' : 	[355,398,89,347,265,77,160,159,258,156,400,247,102,4,8,332,13,217,237,122,113,91,125,123,240,266,93,148,133,216,161,152,215,339,146,214,309,147,256,348,132,262,138,149,392,75,245,71,70,259,401,157,74,243,72,338,248,402,117,335,62,116,115,114,124,120,121,119,419,269,118,420],
    '숲속의 마녀 반지' : 	[235,331,160,353,159,428,284,303,156,258,247,400,110,418,305,293,302,295,296,304,298,300,297,301,294,299,236,396,283,268,417,87,263,345,90,264,255,217,266,140,131,91,351,151,214,239,308,394,85,216,161,343,137,337,74,261,391,70,338,401,259,402,157,248,72,335,75,71,215,269,62,244],
    '숲속의 마녀 벨트' : 	[41,333,329,43,409,160,50,159,258,156,400,247,46,410,350,193,25,48,45,96,173,197,177,217,3,181,170,29,185,189,358,26,30,28,47,33,266,32,31,27,167,341,161,91,382,94,234,214,216,215,162,164,70,402,219,252,338,71,386,75,259,74,248,157,269,401,385,384,72,62,335,383],
    '숲속의 마녀 상의' : 	[66,154,160,364,368,362,363,50,159,258,400,156,247,403,96,46,25,193,48,45,217,94,197,177,181,173,358,185,341,189,170,214,91,266,382,215,29,161,30,234,27,28,33,26,216,32,167,71,252,70,219,162,47,31,164,402,401,72,74,248,386,62,75,335,259,157,385,383,384,404,338,269],
    '숲속의 마녀 신발' : 	[160,393,50,34,39,411,37,36,159,217,38,25,45,48,96,193,46,156,30,47,33,189,31,28,247,167,266,27,412,173,258,219,170,26,29,234,386,94,338,185,385,384,164,181,91,252,358,161,71,400,341,248,382,72,214,197,402,215,177,32,75,259,383,70,162,62,157,335,74,216,401,269],
    '숲속의 마녀 정령석' : 	[355,398,89,347,265,77,160,159,258,156,400,247,19,6,217,421,237,306,122,113,17,148,240,256,330,91,147,133,161,348,125,309,123,339,152,422,93,149,138,132,216,262,392,146,266,215,136,214,245,157,74,248,116,402,75,121,72,114,259,117,118,124,115,120,335,119,62,71,338,70,401,269],
    '숲속의 마녀 팔찌' : 	[160,217,42,255,140,268,263,264,35,40,90,44,337,108,84,156,214,236,215,415,159,266,394,131,91,239,351,345,87,391,216,308,300,353,137,71,293,304,74,269,338,401,258,247,299,296,261,259,161,297,294,302,343,85,301,303,396,244,335,298,62,305,402,248,157,72,75,400,70,295,151,416],
    '숲속의 마녀 하의' : 	[172,174,180,182,160,159,50,175,258,173,183,247,181,400,156,96,46,217,48,45,193,25,91,177,170,406,197,358,341,29,266,33,189,185,30,28,382,27,214,216,32,47,94,405,26,161,31,215,252,167,234,74,162,75,219,164,259,157,72,385,70,383,402,248,71,401,338,386,335,62,269,384],
    '엔트 정령의 귀걸이' : 	[355,398,89,347,265,77,465,160,81,159,156,258,247,400,286,80,79,82,424,423,237,113,122,123,91,148,125,161,217,216,133,266,240,215,256,147,74,93,339,152,149,146,348,214,117,401,309,262,124,121,157,138,115,132,75,335,72,402,248,62,71,70,120,245,118,114,119,259,116,338,392,269],
    '엔트 정령의 목걸이' : 	[160,155,257,353,399,246,413,159,156,258,400,217,345,247,87,303,268,263,140,90,264,255,396,302,337,293,305,300,297,299,296,294,301,298,414,304,295,161,69,215,70,351,91,239,137,131,266,71,214,401,343,151,261,236,244,394,216,308,85,259,62,391,74,338,402,335,157,75,269,248,72,73],
    '엔트 정령의 반지' : 	[143,353,160,291,159,142,258,156,247,400,303,141,345,144,287,87,396,302,293,305,295,296,297,300,298,301,294,304,299,268,351,161,91,266,217,263,214,216,215,343,140,394,151,85,70,259,264,131,157,401,308,236,71,137,261,248,239,255,402,391,62,90,244,72,75,335,74,338,337,417,269,418],
    '엔트 정령의 벨트' : 	[373,310,54,160,159,50,258,51,45,311,247,156,400,185,96,46,217,48,193,25,375,409,177,197,94,28,30,161,358,173,26,32,91,341,33,47,27,31,266,181,170,214,29,215,234,382,162,189,164,252,216,157,70,219,410,259,386,167,401,385,71,383,338,74,384,248,72,335,62,75,402,269],
    '엔트 정령의 상의' : 	[63,83,160,159,50,258,156,247,400,61,96,97,59,193,197,173,91,181,46,177,170,189,48,25,358,266,217,185,45,74,341,252,75,216,167,214,382,215,403,234,162,161,72,164,219,29,28,402,33,30,95,26,27,259,32,401,157,47,94,335,386,338,248,385,383,31,384,404,70,71,62,269],
    '엔트 정령의 성배' : 	[68,355,77,398,265,89,347,65,160,288,159,67,258,156,247,400,91,64,113,122,217,123,125,216,290,266,237,161,93,74,214,215,75,133,148,72,240,339,256,401,152,259,157,348,402,138,248,309,132,335,262,121,70,115,116,71,338,124,117,120,149,114,392,146,119,147,118,62,245,269,420,419],
    '엔트 정령의 신발' : 	[253,58,57,160,193,159,50,156,411,258,247,400,46,56,25,96,45,55,48,173,197,181,170,91,94,177,358,189,341,266,267,214,216,217,167,161,252,382,215,234,74,29,164,185,33,162,75,157,26,28,71,402,27,248,72,385,259,219,384,31,30,386,32,47,401,70,335,62,383,269,412,338],
    '엔트 정령의 심장' : 	[355,398,89,265,347,77,160,370,372,371,376,159,156,258,247,400,289,237,217,421,113,148,122,91,240,256,125,123,146,339,152,266,149,133,309,348,147,262,138,161,132,93,245,216,214,392,215,422,74,259,72,157,118,120,70,117,402,401,75,121,248,335,71,114,338,62,124,336,116,269,119,115],
    '엔트 정령의 어깨' : 	[360,213,160,159,53,254,50,258,156,247,400,24,193,170,96,46,173,181,197,25,91,48,167,358,177,45,94,341,162,266,161,214,189,217,185,216,382,215,234,292,252,164,219,74,75,29,259,401,157,72,30,33,402,71,407,70,32,28,248,385,383,26,386,62,27,335,338,47,384,31,269,408],
    '엔트 정령의 팔찌' : 	[387,388,160,217,158,140,359,264,389,337,90,263,268,394,255,214,215,343,351,236,91,70,151,137,87,131,85,261,239,161,391,71,159,415,345,266,353,244,156,396,308,304,402,401,259,338,62,157,74,294,296,301,298,295,299,400,216,300,297,75,92,303,293,72,302,258,248,269,247,305,335,416],
    '엔트 정령의 하의' : 	[374,160,159,23,20,50,258,156,247,400,21,46,18,369,173,193,177,96,170,25,197,181,94,45,48,358,189,161,341,91,214,185,266,167,215,162,217,382,234,216,30,164,252,405,219,70,259,71,157,401,29,28,248,33,27,386,385,26,72,402,383,62,32,74,47,75,384,338,406,31,335,269]
    
    
}

let avatarSkillOption = 
{
    "가이아" : ['강인한 신념 스킬Lv +1','대자연의 가호 스킬Lv +1','런지 에볼루션 스킬Lv +1','뱅가드 스트랏슈 스킬Lv +1','분쇄 스킬Lv +1','생명의 전조 스킬Lv +1','신록의 검 스킬Lv +1','압도 스킬Lv +1','용기의 방패 스킬Lv +1','워크라이 스킬Lv +1','천마 섬광 스킬Lv +1','체인 스트라이크 스킬Lv +1','체인러쉬 스킬Lv +1','트와일라잇 유니콘 스킬Lv +1'],
    "갓파더" : ['B.C 트레이닝 스킬Lv +1','B.G 파이어툴 스킬Lv +1','벽파일섬 스킬Lv +1','블리츠 크리그 스킬Lv +1','숙련된 전문가 스킬Lv +1','알파 스트라이크 스킬Lv +1','장도 마스터리 스킬Lv +1','전술 지휘 스킬Lv +1','집중 사격 스킬Lv +1','최후의 일격 스킬Lv +1','택티컬 오펜시브 스킬Lv +1','황혼의 날개 스킬Lv +1'],
    "검신" : ['검기상인 스킬Lv +1','광검 사용 가능 스킬Lv +1','극 귀검술 : 심검 스킬Lv +1','극 귀검술 : 유성락 스킬Lv +1','극 귀검술 : 참철식 스킬Lv +1','극 귀검술 : 폭풍식 스킬Lv +1','극 발검술 : 섬단 스킬Lv +1','극초발도 스킬Lv +1','극한의 경지 스킬Lv +1','류심 강 스킬Lv +1','류심 충 스킬Lv +1','류심 쾌 스킬Lv +1','맹룡단공참 스킬Lv +1','무기의 극의 스킬Lv +1','발도 스킬Lv +1','오버드라이브 스킬Lv +1','이기어검술 스킬Lv +1','환영검무 스킬Lv +1'],
    "검제" : ['거압 스킬Lv +1','경전지화 스킬Lv +1','난화검 스킬Lv +1','등봉조극 스킬Lv +1','백보신장 스킬Lv +1','비연장 스킬Lv +1','삼매진화 스킬Lv +1','삼화취정 스킬Lv +1','십자검 스킬Lv +1','연화검무 스킬Lv +1','연화섬 스킬Lv +1','오기조원 스킬Lv +1','월광비무 스킬Lv +1','일화도강 스킬Lv +1','폭검 스킬Lv +1','폭멸장 스킬Lv +1','혜성만리 스킬Lv +1'],
    "그랜드 마스터" : ['가장 강한 녀석은 누구냐? 스킬Lv +1','강렬한 테이크 다운 스킬Lv +1','나의 공격리듬은!! 스킬Lv +1','롤링 스파이크 스킬Lv +1','반격 스킬Lv +1','반드시 잡는다! 스킬Lv +1','브레이크 다운 스킬Lv +1','블로우 스트라이크 스킬Lv +1','수플렉스 싸이클론 스킬Lv +1','에어슈타이너 스킬Lv +1','오의 : 질풍노도 스킬Lv +1','옷깃 잡기 스킬Lv +1','와일드 캐넌 스파이크 스킬Lv +1','일자전승 : 극의 진천노호 스킬Lv +1','임팩트 콤비네이션 스킬Lv +1','자이언트 스윙 스킬Lv +1','체인 그랩 스킬Lv +1','파워 슬램 스킬Lv +1','피어스 스킬Lv +1','한놈만 팬다!! 스킬Lv +1','허리케인 슈퍼 플렉스 스킬Lv +1','흑진광풍 스킬Lv +1'],
    "그림리퍼" : ['날카로운 단검 스킬Lv +1','배후습격 스킬Lv +1','베일드 어택 스킬Lv +1','심장 찌르기 스킬Lv +1','암살 스킬Lv +1','암살기술 스킬Lv +1','암살자의 마음가짐 스킬Lv +1','절명의 그림자 스킬Lv +1','체인 리액션 스킬Lv +1','칼날돌풍 스킬Lv +1','파이널 디스트럭션 스킬Lv +1','페이탈 블리츠 스킬Lv +1'],
    "네메시스" : ['데들리 케이프 스킬Lv +1','데우스 엑스 레이 스킬Lv +1','둠스데이 스킬Lv +1','디스트로이어 스킬Lv +1','리버레이트 - 매드니스 스킬Lv +1','리버레이트 - 벅샷 스킬Lv +1','베스퍼스 스킬Lv +1','블랙 미러 스킬Lv +1','소울드레인 스킬Lv +1','쉐도우 퍼펫 스킬Lv +1','쉐도우 하베스트 스킬Lv +1','우시르의 저주 스킬Lv +1','이몰레이션 스킬Lv +1','이보브 스킬Lv +1','지하드 스킬Lv +1','충실한 종 스킬Lv +1','컨제스트 스킬Lv +1','퍼니쉬먼트 스킬Lv +1'],
    "다크나이트(자각2)" : ['다크 레이브 스킬Lv +1','다크 버스트 스킬Lv +1','다크 브레이크 스킬Lv +1','다크 소드 스킬Lv +1','다크 웨이브 스킬Lv +1','다크 웨이브 폴 스킬Lv +1','다크 플레임 소드 스킬Lv +1','오버플로우 스킬Lv +1','웨이브 스핀 스킬Lv +1','일루젼 슬래쉬 스킬Lv +1','차원일치 스킬Lv +1','차지 익스플로전 스킬Lv +1','타임 브레이크 스킬Lv +1','타임 스톱 스킬Lv +1','팬텀 소드 스킬Lv +1'],
    "다크로드" : ['검귀강령 : 식 스킬Lv +1','귀검편 스킬Lv +1','귀영섬 스킬Lv +1','귀참 : 광폭 스킬Lv +1','귀참 : 나락 스킬Lv +1','냉기의 사야 스킬Lv +1','달의 커튼 스킬Lv +1','몽롱한 눈의 브레멘 스킬Lv +1','블레이드 팬텀 스킬Lv +1','사슬 해제 스킬Lv +1','역병의 라사 스킬Lv +1','잔영의 케이가 스킬Lv +1','제 7의 귀신 : 광폭의 블라슈 스킬Lv +1','지그 강림 스킬Lv +1','칠흑의 공포 스킬Lv +1','툼스톤 스웜프 스킬Lv +1','툼스톤 스킬Lv +1','팬텀 마스터리 스킬Lv +1','흑염의 칼라 스킬Lv +1'],
    "듀란달" : ['뇌격점혈섬 스킬Lv +1','라이징 랜서 스킬Lv +1','롤링 벌컨 스킬Lv +1','메조싸이클론 스킬Lv +1','무형의 창술사 스킬Lv +1','미라지 부스트 스킬Lv +1','미라지 스트림 스킬Lv +1','살극쌍연무 스킬Lv +1','오러 랜스 스킬Lv +1','오러 포스 재블린 스킬Lv +1','장창 숙련 스킬Lv +1','쿼드러플 드라이브 스킬Lv +1','트윈 펀토 스킬Lv +1','파동연환창 스킬Lv +1'],
    "드레드노트" : ['계승자 스킬Lv +1','대식가 스킬Lv +1','드래곤포스 스킬Lv +1','미티어 버스트 스킬Lv +1','비룡천상 스킬Lv +1','슬레이어 스킬Lv +1','아스트라 소환 스킬Lv +1','용제출진 스킬Lv +1','울부짖는 뇌광 스킬Lv +1','종언을 고하는 빛 스킬Lv +1','태도 용제무쌍 스킬Lv +1','폭음폭식 스킬Lv +1','폭풍의 숨결 스킬Lv +1'],
    "디스트로이어" : ['APG-63 스킬Lv +1','FM-31 그레네이드 런처 스킬Lv +1','FM-92 mk2 랜서 SW 스킬Lv +1','FM-92 스팅어 SW 스킬Lv +1','MSC-7 스킬Lv +1','PT-15 프로토타입 스킬Lv +1','X-1 익스트루더 스킬Lv +1','레이저 라이플 스킬Lv +1','미라클 비전 스킬Lv +1','버스터 빔 스킬Lv +1','사이즈믹 웨이브 스킬Lv +1','새틀라이트 빔 스킬Lv +1','슈타이어 대전차포 스킬Lv +1','스펙트럴 서치 아이 스킬Lv +1','알파 서포트 스킬Lv +1','양자 폭탄 스킬Lv +1','오버 히트 스킬Lv +1','오버부스트 팩 스킬Lv +1','오퍼레이션 레이즈 스킬Lv +1','중화기 개조 스킬Lv +1','중화기 마스터리 스킬Lv +1','충전 레이저 라이플 스킬Lv +1','플라즈마 부스터 스킬Lv +1','화염 강타 스킬Lv +1'],
    "디어사이드" : ['검마격살 스킬Lv +1','광폭화 스킬Lv +1','마검 제어 스킬Lv +1','마인의 검세 스킬Lv +1','분마검 스킬Lv +1','비인외도 : 극 스킬Lv +1','사복검 - 발 스킬Lv +1','사복검 - 조 스킬Lv +1','암연검 : 기가 블레이드 스킬Lv +1','암연격 : 기가슬래쉬 스킬Lv +1','역천의 프놈 스킬Lv +1','탐욕의 번제 스킬Lv +1','파계검 : 라그나로크 스킬Lv +1','파천세 스킬Lv +1','폭류나선 스킬Lv +1','혈마검 스킬Lv +1','혈지군무 스킬Lv +1','혈화난무 스킬Lv +1'],
    "레이븐" : ['권총의 춤 스킬Lv +1','난사 스킬Lv +1','더블 건호크 스킬Lv +1','데들리 어프로치 스킬Lv +1','데스 바이 리볼버 스킬Lv +1','리볼버 강화 스킬Lv +1','멀티 헤드샷 스킬Lv +1','베일드 컷 스킬Lv +1','블러드 앤 체인 스킬Lv +1','사격술 스킬Lv +1','세븐스 플로우 스킬Lv +1','스커드 제노사이드 스킬Lv +1','스타일리쉬 스킬Lv +1','쏘우 블레이드 스킬Lv +1','와이프 아웃 스킬Lv +1','이동사격 스킬Lv +1','제압 사격 스킬Lv +1','죽음의 표식 스킬Lv +1','킬 포인트 스킬Lv +1','패스트 드로우 스킬Lv +1','패스티스트 건 스킬Lv +1','헤드샷 스킬Lv +1'],
    "레퀴엠" : ['결의 스킬Lv +1','냉혈 스킬Lv +1','목표 사격 스킬Lv +1','문페이즈 스킬Lv +1','보름달 베기 스킬Lv +1','섬멸 스킬Lv +1','소태도 마스터리 스킬Lv +1','암월비보 스킬Lv +1','요원의 전투술 스킬Lv +1','요인 암살 스킬Lv +1','월광진혼곡 스킬Lv +1','월식 스킬Lv +1','임무 시작 스킬Lv +1','조준 사격 스킬Lv +1'],
    "리디머" : ['그리드 더 러커 스킬Lv +1','글러트니 바밋 스킬Lv +1','라스트 제네시스 스킬Lv +1','사이드 매서커 스킬Lv +1','서드 립 스킬Lv +1','세컨드 소울 스킬Lv +1','스커드 더 라스 스킬Lv +1','일곱개의 대죄 스킬Lv +1','죄를 고하는자 스킬Lv +1','죄를 깨우는 손길 스킬Lv +1','죄업에 짓눌린 육신 스킬Lv +1','짊어진 자의 낫 마스터리 스킬Lv +1','타락의 칠죄종 스킬Lv +1','퍼스트 프라나 : 금단의 결정체 스킬Lv +1'],
    "마신" : ['검은 충격 스킬Lv +1','대난투 스킬Lv +1','매시브 파이어 스킬Lv +1','미드나잇 카니발 스킬Lv +1','브레인 스톰 스킬Lv +1','아텐 현신 스킬Lv +1','아텐의 화신 스킬Lv +1','악신난무 스킬Lv +1','제노사이드 크러쉬 스킬Lv +1','착취 스킬Lv +1','천공검 스킬Lv +1','카리스마 스킬Lv +1','파이널 어택 스킬Lv +1','팬시 트위스트 스킬Lv +1','혼혈 스킬Lv +1','희생폭탄 스킬Lv +1','힘의 논리 스킬Lv +1'],
    "마제스티" : ['견고의 대검 마스터리 스킬Lv +1','고대의 맹약 스킬Lv +1','궁극의 검 : 레바테인 스킬Lv +1','극 귀검술 : 시공섬 스킬Lv +1','극 귀검술 : 파검무 스킬Lv +1','마검발현 스킬Lv +1','마검방출 스킬Lv +1','마검일체 스킬Lv +1','반월 스킬Lv +1','비연참 스킬Lv +1','섬광 스킬Lv +1','속성변환 스킬Lv +1','속성의 소검 마스터리 스킬Lv +1','승천 스킬Lv +1','신검합일 스킬Lv +1','악즉참 스킬Lv +1','용성락 스킬Lv +1','참격혼 스킬Lv +1','참마검 스킬Lv +1','쾌속검 스킬Lv +1','쾌속의 도 마스터리 스킬Lv +1','파쇄의 둔기 마스터리 스킬Lv +1','폭명기검 스킬Lv +1','환검 스킬Lv +1'],
    "명왕" : ['개조형 파진포 : 연화 스킬Lv +1','광폭혈쇄 스킬Lv +1','그라운드 킥 스킬Lv +1','그라운드 태클 스킬Lv +1','비셔스 래리어트 스킬Lv +1','더티 배럴 스킬Lv +1','도발 스킬Lv +1','독 바르기 스킬Lv +1','독무 스킬Lv +1','독문무공 스킬Lv +1','독병 투척 스킬Lv +1','뒷골목 싸움법 스킬Lv +1','룰 브레이크 스킬Lv +1','만천화우 스킬Lv +1','맹독 일발화약성 스킬Lv +1','바늘 투척 스킬Lv +1','베놈 마인 스킬Lv +1','블럭 투척 스킬Lv +1','블록 버스터 스킬Lv +1','용독술 스킬Lv +1','일발화약성 스킬Lv +1','천붕지괴 스킬Lv +1','천수천안 스킬Lv +1','체인 드라이브 스킬Lv +1','크레이지 발칸 스킬Lv +1','클로 마스터리 스킬Lv +1','혈관을 흐르는 맹독 스킬Lv +1'],
    "뱀파이어 로드" : ['럼블독 스킬Lv +1','릴로이 스킬Lv +1','머로더 스킬Lv +1','블러드 번 스킬Lv +1','블러드 스킬Lv +1','블러드 스트림 스킬Lv +1','블러드 혼 스킬Lv +1','소울 스틸 스킬Lv +1','어밴던 스킬Lv +1','체페슈 스킬Lv +1','커럽션 스킬Lv +1','토먼트 스킬Lv +1','트루 블러드 스킬Lv +1','팬텀 베일 스킬Lv +1','팽 스킬Lv +1','페이탈리티 스킬Lv +1','헬베루즈 스킬Lv +1'],
    "벤데타" : ['나이트폴 스킬Lv +1','데이브레이크 스킬Lv +1','딥 더스크 스킬Lv +1','라스트 오더 스킬Lv +1','라이선스 스킬Lv +1','불스아이 스킬Lv +1','블랙아웃 스킬Lv +1','빈티지 스킬Lv +1','섀터드 글래스 스킬Lv +1','셰이커 블래스트 스킬Lv +1','쇼다운 스킬Lv +1','스트레이트 샷 스킬Lv +1','오버페이스 스킬Lv +1','올드 패션드 스킬Lv +1','와일드 플라워 스킬Lv +1','인퍼머스 스킬Lv +1','카타르시스 스킬Lv +1','트레이스 스킬Lv +1','행오버 스킬Lv +1'],
    "블랙 메이든" : ['금단의 저주 스킬Lv +1','마리오네트 스킬Lv +1'],
    "블러드 이블" : ['갈증 스킬Lv +1','격노 스킬Lv +1','레이징 퓨리 스킬Lv +1','버스트 퓨리 스킬Lv +1','블러드 리븐 스킬Lv +1','블러드 붐 스킬Lv +1','블러드 소드 스킬Lv +1','블러드 스내치 스킬Lv +1','블러드 인카넷 스킬Lv +1','블러드러스트 스킬Lv +1','블러디 레이브 스킬Lv +1','선혈의 추억 스킬Lv +1','아웃레이지 브레이크 스킬Lv +1','익스트림 오버킬 스킬Lv +1','페이탈 블러드 스킬Lv +1','폭주 스킬Lv +1','프렌지 스킬Lv +1','혈십자 스킬Lv +1'],
    "세라핌" : ['계시 : 아리아 스킬Lv +1','그랜드 크로스 크래쉬 스킬Lv +1','대천사의 축복 스킬Lv +1','라파엘의 축복 스킬Lv +1','성창 : 브류나크 스킬Lv +1','신실한 열정 스킬Lv +1','십자가 마스터리 스킬Lv +1','용맹의 아리아 스킬Lv +1','용맹의 축복 스킬Lv +1','우리엘의 결계 스킬Lv +1','크럭스 오브 빅토리아 스킬Lv +1'],
    "세이비어" : ['글로리 오브 더 라이트 스킬Lv +1','레쓰 스킬Lv +1','멘데이트 오브 헤븐 스킬Lv +1','세라픽 페더 스킬Lv +1','세라픽 폴 스킬Lv +1','옵스트럭션 스킬Lv +1','위핑 웨이브 스킬Lv +1','이스트레인지먼트 스킬Lv +1','인게이지먼트 스킬Lv +1','인테그레이션 스킬Lv +1','저지먼트 콜 스킬Lv +1','커맨드먼트 스킬Lv +1','컨실리에이션 스킬Lv +1','컨페션 스킬Lv +1','페이스풀 스킬Lv +1','프라이스 오브 미스트러스트 스킬Lv +1'],
    "세인트" : ['굳건한 의지 스킬Lv +1','디바인 퍼니쉬먼트 스킬Lv +1','디바인 플래쉬 스킬Lv +1','디플렉트 월 스킬Lv +1','미러클 스플리터 스킬Lv +1','보호의 징표 스킬Lv +1','빛의 복수 스킬Lv +1','성령의 메이스 스킬Lv +1','수호의 은총 스킬Lv +1','신념의 오라 스킬Lv +1','썬더 해머 : 유피테르 스킬Lv +1','아포칼립스 스킬Lv +1','영광의 축복 스킬Lv +1','용기의 은총 스킬Lv +1','운명의 창 스킬Lv +1','정의의 심판 스킬Lv +1','참회의 망치 스킬Lv +1','크로스 크래쉬 스킬Lv +1','플래시 글로브 스킬Lv +1','홀리 생츄어리 스킬Lv +1'],
    "셀레스티얼" : ['가랑비 스킬Lv +1','고원의 안개꽃 스킬Lv +1','기어 드라이브 스킬Lv +1','서바이벌 스킬Lv +1','셋 업 : 불꽃놀이 스킬Lv +1','셋 업 : 신무병장 요수광란 스킬Lv +1','스크램블 앵커 스킬Lv +1','신무병장 유성 스킬Lv +1','신무병장 폴라리스 스킬Lv +1','여행자의 직감 스킬Lv +1','와일드 파이어 스킬Lv +1','유랑하는 별의 문장 스킬Lv +1','익사이팅 스킬Lv +1','장궁 숙련 스킬Lv +1','제트스트림 스킬Lv +1','헤이즈 스톰 스킬Lv +1'],
    "스톰 트루퍼" : ['APG-63 스킬Lv +1','FM-31 그레네이드 런처 스킬Lv +1','FM-92 mk2 랜서 SW 스킬Lv +1','FM-92 mk2 랜서 스킬Lv +1','FSC-7 스킬Lv +1','PT-15 프로토타입 스킬Lv +1','X-1 익스트루더 스킬Lv +1','레이저 라이플 스킬Lv +1','미라클 비전 스킬Lv +1','슈타이어 대전차포 스킬Lv +1','알파 서포트 스킬Lv +1','양자 폭탄 스킬Lv +1','에인션트 트리거 스킬Lv +1','오버 히트 스킬Lv +1','오퍼레이션 레이즈 스킬Lv +1','중화기 마스터리 스킬Lv +1','충전 레이저 라이플 스킬Lv +1','플라즈마 부스터 스킬Lv +1','화염 강타 스킬Lv +1'],
    "시라누이" : ['나선 대차륜 쿠나이 스킬Lv +1','두꺼비유염탄 스킬Lv +1','마환 풍마수리검 스킬Lv +1','비기 : 염무개화 스킬Lv +1','아마테라스 스킬Lv +1','암영술 스킬Lv +1','야마타오로치 스킬Lv +1','야타의 거울 스킬Lv +1','열화천도 스킬Lv +1','인법 : 바꿔치기 스킬Lv +1','인법 : 육도윤회 스킬Lv +1','인법 : 잔영 남기기 스킬Lv +1','차크라 웨펀 숙련 스킬Lv +1','초열혼폭술 스킬Lv +1','쿠사나기의 검 스킬Lv +1','화둔 : 홍염 스킬Lv +1','화염구 스킬Lv +1','화염의 인 스킬Lv +1','환영 다중 수리검 스킬Lv +1','흉멸인법진 스킬Lv +1'],
    "아슈타르테" : ['배틀메이지의 무기 마스터리 스킬Lv +1','강습유성타 스킬Lv +1','뇌연격 스킬Lv +1','니우의 전투술 스킬Lv +1','더블 스윙 스킬Lv +1','문무겸비 스킬Lv +1','배틀 그루브 스킬Lv +1','사도의 춤 스킬Lv +1','쇄패 스킬Lv +1','일기당천 쇄패 스킬Lv +1','전장의 여신 스킬Lv +1','황룡강림 스킬Lv +1','체이서 스킬Lv +1','체이서 클러스터 스킬Lv +1','체이서 프레스 스킬Lv +1','퀘이사 익스플로젼 스킬Lv +1','타이머 밤 스킬Lv +1','트윙클 스매쉬 스킬Lv +1','슈퍼 체이서 스킬Lv +1','황룡천공 스킬Lv +1'],
    "아이올로스" : ['대진공 스킬Lv +1','볼텍스 허리케인 스킬Lv +1','세컨드 윈드 스킬Lv +1','소닉 무브 스킬Lv +1','스톰 스트라이크 스킬Lv +1','스톰 엘리미네이트 스킬Lv +1','스톰 퀘이커 스킬Lv +1','스파이럴 프레스 스킬Lv +1','싹쓸바람 스킬Lv +1','윈드니스 스킬Lv +1','질풍가도 스킬Lv +1','태풍의 전조 스킬Lv +1','폭풍의 눈 스킬Lv +1','풍신의 힘 스킬Lv +1','회오리 바람 스킬Lv +1','휘몰아치는 바람 스킬Lv +1','휘몰아치는 질풍의 봉 마스터리 스킬Lv +1'],
    "악귀나찰" : ['검귀의 도 마스터리 스킬Lv +1','귀신보 스킬Lv +1','귀연참 스킬Lv +1','귀천살(鬼天殺) 스킬Lv +1','귀혼일체 스킬Lv +1','악귀연무 스킬Lv +1','악귀참수 스킬Lv +1','악귀현신 스킬Lv +1','야차라 불리는 자 스킬Lv +1','원귀 : 격살 스킬Lv +1','원귀 : 신풍 스킬Lv +1','원귀 : 회천 스킬Lv +1','참살(慘殺) 스킬Lv +1','청혈투귀 스킬Lv +1','합격 : 나락참 스킬Lv +1','합격 : 월령참 스킬Lv +1','합격 : 이혼일섬 스킬Lv +1','혼백참 스킬Lv +1'],
    "알키오네" : ['노바 램넌트 스킬Lv +1','단검 마스터리 스킬Lv +1','디스트리뷰션 스킬Lv +1','라이트닝 애로우 스킬Lv +1','문아크 스킬Lv +1','셰이크 다운 스킬Lv +1','소닉 어설트 스킬Lv +1','실버스트림 스킬Lv +1','쌍검 마스터리 스킬Lv +1','어슬랜트 스파이럴 스킬Lv +1','엑셀 스트라이크 스킬Lv +1','엑조틱 램펀트 스킬Lv +1','체인소 스킬Lv +1','허리케인 스킬Lv +1','히트 블리드 스킬Lv +1','히트엔드 스킬Lv +1'],
    "어센션" : ['SSTH 스킬Lv +1','경계망상 스킬Lv +1','괴리 : 금단의 니알리 스킬Lv +1','괴리 : 디멘션 할로우 스킬Lv +1','괴리 : 저편의 자장가 스킬Lv +1','괴리 : 칙 잇 스킬Lv +1','괴리 : 패러사이트 스웜 스킬Lv +1','괴리 : 형용할 수 없는 공포 스킬Lv +1','도그마 디바이스 스킬Lv +1','운명왜곡 스킬Lv +1','차원 : 차원회랑 스킬Lv +1','차원 : 패러다임 디토네이션 스킬Lv +1','차원 : 포지트론 블래스트 스킬Lv +1','차원괴리 : 금단의 연회 스킬Lv +1'],
    "언터처블" : ['G-CTF 스킬Lv +1','G-Widow 스킬Lv +1','겟 온 파이어 스킬Lv +1','더 나인 배럴 스킬Lv +1','더블 배럴 스킬Lv +1','디스트러블 퀘이크 스킬Lv +1','디퓨전 버스트 스킬Lv +1','리인포스 파이어 스킬Lv +1','서프라이즈 프레전트 스킬Lv +1','역전의 승부사 스킬Lv +1','오프레션 스킬Lv +1','인크레더블 스킬Lv +1','중검 마스터리 스킬Lv +1','화약 다루기 스킬Lv +1','황야의 무법자 스킬Lv +1'],
    "얼티밋 디바" : ['강렬한 테이크 다운 스킬Lv +1','기간틱 타이푼 스킬Lv +1','넥 스냅 스킬Lv +1','반드시 잡는다! 스킬Lv +1','숄더 태클 스킬Lv +1','수플렉스 싸이클론 스킬Lv +1','스파이어 스킬Lv +1','싸이클론 어택 스킬Lv +1','에어슈타이너 스킬Lv +1','옷깃 잡기 스킬Lv +1','와일드 캐넌 스파이크 스킬Lv +1','자이언트 스윙 스킬Lv +1','토르베지노 스킬Lv +1','파워 슬램 스킬Lv +1','허리케인 슈퍼 플렉스 스킬Lv +1'],
    "에레보스" : ['광폭 : 흑화연창 스킬Lv +1','다크 스타 스킬Lv +1','다크 임펄스 스킬Lv +1','다크니스 스킬Lv +1','디스페어 스킬Lv +1','마창 폭주 스킬Lv +1','멸광천투 스킬Lv +1','심연에 드리운 어둠 스킬Lv +1','어둠의 지배자 스킬Lv +1','어둠의 투창 마스터리 스킬Lv +1','잠식 스킬Lv +1','폴링 데스 스킬Lv +1','흑광폭살 스킬Lv +1'],
    "엑스마키나" : ['AT-SO Walker 스킬Lv +1','네오디뮴 퓨지 스킬Lv +1','다이렉트 피드백 스킬Lv +1','레이시온 크리트 스킬Lv +1','레트로피트 스킬Lv +1','리컨스트럭션 바디 스킬Lv +1','부스트 : 다이너마이트 킥 스킬Lv +1','부스트 : 바디 벙커 스킬Lv +1','부스트 : 이퀄리스-MR 스킬Lv +1','센서빌리티 스킬Lv +1','스코치 택틱스 스킬Lv +1','액티베이션 스킬Lv +1','어썰트의 자동권총 마스터리 스킬Lv +1','트리플 건샷 스킬Lv +1'],
    "염제 폐월수화" : ['광룡십삼장 스킬Lv +1','광충노도 스킬Lv +1','광휘의 넨 스킬Lv +1','기공장 스킬Lv +1','나선의 넨 스킬Lv +1','넨화 스킬Lv +1','뇌명 : 사나운 빛의 넨수 스킬Lv +1','비룡나선파 스킬Lv +1','사자후 스킬Lv +1','축기 스킬Lv +1','축염포 스킬Lv +1','카이 스킬Lv +1','태사자후 스킬Lv +1','환영폭쇄 스킬Lv +1','흡기공탄 스킬Lv +1','흩날리는 천염화 스킬Lv +1'],
    "염황 광풍제월" : ['광룡십삼장 스킬Lv +1','광풍나선력 스킬Lv +1','광휘의 넨 스킬Lv +1','금뇌호 : 심판의 넨수 스킬Lv +1','기공장 스킬Lv +1','기호지세 스킬Lv +1','나선의 넨 : 주 스킬Lv +1','나선의 넨 스킬Lv +1','넨의 극의 스킬Lv +1','넨화 스킬Lv +1','뇌명 : 사나운 빛의 넨수 스킬Lv +1','비룡나선파 스킬Lv +1','사자후 스킬Lv +1','역혈기공 스킬Lv +1','월광대지 스킬Lv +1','제황나선격 스킬Lv +1','축기 스킬Lv +1','축염포 스킬Lv +1','카이 스킬Lv +1','태사자후 스킬Lv +1','환영폭쇄 스킬Lv +1','휘광의 성체 스킬Lv +1','흡기공탄 스킬Lv +1','흩날리는 천염화 스킬Lv +1'],
    "오버마인드" : ['나이트 할로우 스킬Lv +1','더 게이트 스킬Lv +1','마력 증폭 스킬Lv +1','메모라이즈 스킬Lv +1','보이드 스킬Lv +1','속성 마스터리 스킬Lv +1','썬더 콜링 스킬Lv +1','아크틱 피스트 스킬Lv +1','애스트럴 스톰 스킬Lv +1','엘레멘탈 번 스킬Lv +1','엘레멘탈 커튼 스킬Lv +1','엘레멘탈 퀘이크 스킬Lv +1','엘레멘탈 포텐셜 스킬Lv +1','제6원소 스킬Lv +1','초월의 크리스탈 스킬Lv +1','칠링 팬스 스킬Lv +1','플레임 스트라이크 스킬Lv +1','핼로윈 버스터 스킬Lv +1'],
    "오블리비언" : ['검은 눈 스킬Lv +1','다크니스 멘틀 스킬Lv +1','라이트닝 월 스킬Lv +1','마나 폭주 스킬Lv +1','아마겟돈 스트라이크 스킬Lv +1','아이스 크리스탈 샤워 스킬Lv +1','암전 스킬Lv +1','엘레멘탈 레인 스킬Lv +1','엘레멘탈 버스터 스킬Lv +1','엘레멘탈 스트라이크 스킬Lv +1','엘레멘탈 실드 스킬Lv +1','엘레멘탈 캐넌 스킬Lv +1','엘레멘탈 포스 스킬Lv +1','엘레멘탈 필드 스킬Lv +1','원소 융합 스킬Lv +1','원소의 이해 스킬Lv +1','체인라이트닝 스킬Lv +1','컨버전스 캐넌 스킬Lv +1','컨센트레이트 스킬Lv +1','파이어로드 스킬Lv +1','페이탈 엘레멘트 스킬Lv +1','플레임 서클 스킬Lv +1'],
    "옵티머스" : ['Ex-S 바이퍼 스킬Lv +1','G 익스텐션 스킬Lv +1','G-1 코로나 스킬Lv +1','G-S.P. 팔콘 스킬Lv +1','G-오퍼레이터 스킬Lv +1','고기동 메카 : 볼트 MX 스킬Lv +1','공중 전폭 메카 : 게일 포스 스킬Lv +1','로보틱스 스킬Lv +1','마그네틱 필드 스킬Lv +1','메카 드롭 스킬Lv +1','스패로우 팩토리 스킬Lv +1','트랜스 폼 : G-0 배틀로이드 스킬Lv +1','트랜스포메이션 스킬Lv +1','트랜스폼 : G-2 롤링썬더 스킬Lv +1','트랜스폼 : G-3 랩터 스킬Lv +1','프리스비 스킬Lv +1','하이테크놀로지 스킬Lv +1'],
    "용독문주" : ['그라운드 킥 스킬Lv +1','도발 스킬Lv +1','독 바르기 스킬Lv +1','독무 스킬Lv +1','독문무공 스킬Lv +1','마운트 스킬Lv +1','만병지왕 - 파진포 스킬Lv +1','맹독 일발화약성 스킬Lv +1','베놈 마인 스킬Lv +1','블록 봄 스킬Lv +1','비기 : 폭독 스킬Lv +1','용독술 스킬Lv +1','일발화약성 스킬Lv +1','정크 스핀 스킬Lv +1','크레이지 발칸 스킬Lv +1','클로 마스터리 스킬Lv +1','혈관을 흐르는 맹독 스킬Lv +1'],
    "워로드" : ['데모닉 인페르노 스킬Lv +1','둠 글레이브 스킬Lv +1','드레드보어 스킬Lv +1','디베스테이트 스킬Lv +1','런지 스트라이크 스킬Lv +1','마창 각성 스킬Lv +1','마창 제어 스킬Lv +1','마창 지배 스킬Lv +1','마창 해방 스킬Lv +1','버스트 슬래쉬 스킬Lv +1','유 다이드 스킬Lv +1','임팩트 스매쉬 스킬Lv +1'],
    "이모탈" : ['낫 마스터리 스킬Lv +1','다크 하울링 스킬Lv +1','데빌 클러치 스킬Lv +1','복수의 가시 스킬Lv +1','스피닝 디포메이션 스킬Lv +1','스핀커터 스킬Lv +1','악마화 스킬Lv +1','악몽 스킬Lv +1','어둠의 권능 스킬Lv +1','언홀리 퓨리 스킬Lv +1','이블 스트라이커 스킬Lv +1','재앙 스킬Lv +1','추락하는 영혼 스킬Lv +1','파멸의 강타 스킬Lv +1','환청 스킬Lv +1'],
    "이클립스" : ['계약소환 : 검은기사 산도르 스킬Lv +1','계약소환 : 루이즈 언니! 스킬Lv +1','계약소환 : 마계화 아우쿠소 스킬Lv +1','계약소환 : 정복자 카시야스 스킬Lv +1','계약소환 : 타우킹 쿠루타 스킬Lv +1','교감 스킬Lv +1','다크문 스킬Lv +1','블랙 루나틱 스킬Lv +1','스피릿 본드 스킬Lv +1','이클립스 하이브 스킬Lv +1','전설소환 : 갈애의 라모스 스킬Lv +1','전설소환 : 월영 스킬Lv +1','정령 희생 스킬Lv +1','정령소환 : 데드 멀커 스킬Lv +1','정령소환 : 아퀘리스 스킬Lv +1','정령소환 : 융합정령 헤일롬 스킬Lv +1','정령소환 : 정령왕 에체베리아 스킬Lv +1','정령소환 : 플레임 헐크 스킬Lv +1','환수 강화 오라 스킬Lv +1','환수 폭주 스킬Lv +1'],
    "이터널" : ['공명 스킬Lv +1','발현 스킬Lv +1','브로큰 애로우 스킬Lv +1','블리자드 스톰 스킬Lv +1','빙류환 스킬Lv +1','샤드 매그넘 스킬Lv +1','아이스 로드 스킬Lv +1','아이스 빅 해머 스킬Lv +1','아이스 오브 스킬Lv +1','아이스 크래쉬 스킬Lv +1','아이스크래프트 스킬Lv +1','오버프리즈 익스텐션 스킬Lv +1','쥬데카 디 이터널 스킬Lv +1','툰드라의 가호 스킬Lv +1','프로즌웨폰 마스터리 스킬Lv +1','피어스 오브 아이스 스킬Lv +1','회전투창 스킬Lv +1'],
    "인다라천" : ['귀문반 스킬Lv +1','극파동검 빙인 스킬Lv +1','극파동검 폭염 스킬Lv +1','뇌신 스킬Lv +1','뇌신의 기운 스킬Lv +1','무쌍파 스킬Lv +1','부동명왕진 스킬Lv +1','사슬 해제 스킬Lv +1','살의의 파동 스킬Lv +1','수라 진공참 스킬Lv +1','심안 스킬Lv +1','작열 파동진 스킬Lv +1','정신이 번쩍! 스킬Lv +1','진공참 스킬Lv +1','천뇌 바주라 스킬Lv +1','파동 해제 스킬Lv +1','파동각인 스킬Lv +1','파동검 : 인다라망 스킬Lv +1','파동검 빙인 스킬Lv +1','파동검 폭염 스킬Lv +1','파동의 눈 스킬Lv +1'],
    "인페르노" : ['고결한 격노 스킬Lv +1','광적인 믿음 스킬Lv +1','배틀액스 마스터리 스킬Lv +1','불지옥 스킬Lv +1','성화 스킬Lv +1','세례의 불길 스킬Lv +1','세큐리스 말레피카룸 스킬Lv +1','신벌 스킬Lv +1','심판의 일격 스킬Lv +1','업화의 소용돌이 스킬Lv +1','이단의 낙인 스킬Lv +1','역죄단멸 스킬Lv +1','즉결심판 스킬Lv +1','차륜형 스킬Lv +1','참수 스킬Lv +1','플레게의 정수 스킬Lv +1','화형 스킬Lv +1'],
    "저스티스" : ['개틀링 펀치 스킬Lv +1','고저스 컴비네이션 스킬Lv +1','더킹 바디블로 스킬Lv +1','더킹 스트레이트 스킬Lv +1','더킹 어퍼 스킬Lv +1','데몰리션 펀치 스킬Lv +1','데스블로우 스킬Lv +1','드라이아웃 스킬Lv +1','머신건 잽 스킬Lv +1','빅뱅 펀치 스킬Lv +1','섀도우 박서 스킬Lv +1','신의 분노 스킬Lv +1','아토믹 쵸퍼 스킬Lv +1','윌 드라이버 스킬Lv +1','초핑 해머 스킬Lv +1','테크니컬 마스터리 스킬Lv +1','핵 펀치 스킬Lv +1','허리케인 롤 스킬Lv +1','헤븐리 컴비네이션 스킬Lv +1'],
    "제노사이더" : ['광창 마스터리 스킬Lv +1','날개 꿰기 스킬Lv +1','데보닝 슬래셔 스킬Lv +1','데스 차지 스킬Lv +1','드래곤 슬레이어 스킬Lv +1','마나 익스트랙트 스킬Lv +1','미라지 부스트 스킬Lv +1','버팔로 폴 스킬Lv +1','서번트 랜스 : 인게이저 스킬Lv +1','서번트 랜스 스킬Lv +1','스케일 그라인더 스킬Lv +1','어스웜 헌트 스킬Lv +1','에이븐 헌팅 스킬Lv +1','잔류 감각 스킬Lv +1','페니트레잇 헤드 스킬Lv +1','헌터즈 샤프니스 스킬Lv +1','힘의 근원 스킬Lv +1'],
    "지니위즈" : ['고대의 도서관 스킬Lv +1','고출력 매직 미사일 스킬Lv +1','대왕 파리채 스킬Lv +1','도발 인형 : 슈르르 스킬Lv +1','롤리팝 크러쉬 스킬Lv +1','메가 드릴 스킬Lv +1','반중력 기동장치 스킬Lv +1','변이 파리채 스킬Lv +1','성공 예감 스킬Lv +1','성난 불길 가열로 스킬Lv +1','스위트 캔디바 스킬Lv +1','애시드 클라우드 스킬Lv +1','용암지대 생성물약 스킬Lv +1','우로보로스 스킬Lv +1','퍼밀리어와 친하게 지내기 스킬Lv +1','퓨전 크래프트 스킬Lv +1','플로레 전기토끼 스킬Lv +1','플로레 컬라이더 스킬Lv +1','현자의 돌 스킬Lv +1'],
    "천선낭랑" : ['공력의 구 스킬Lv +1','마혼부 : 퇴마음양옥 스킬Lv +1','목환자경 스킬Lv +1','백팔염주 스킬Lv +1','법력 강화 스킬Lv +1','부동주박진 스킬Lv +1','신룡의 여의주 스킬Lv +1','신탁 : 신룡뇌우제 스킬Lv +1','신탁의 기원 스킬Lv +1','염폭모대주 스킬Lv +1','용신의 힘 스킬Lv +1','용의 격노 스킬Lv +1','천녀기우제 스킬Lv +1','탈명대염진 스킬Lv +1','현신 : 신룡진노 스킬Lv +1'],
    "카이저" : ['강권 스킬Lv +1','극진 : 패황단공권 스킬Lv +1','급소 지정 스킬Lv +1','라이징 너클 스킬Lv +1','라이트닝 댄스 스킬Lv +1','리미트 브레이크 스킬Lv +1','머슬 시프트 스킬Lv +1','무영각 스킬Lv +1','본 크러셔 스킬Lv +1','붕권 스킬Lv +1','비트 드라이브 스킬Lv +1','사상최강의 로킥 스킬Lv +1','스탬피드 스킬Lv +1','원인치 펀치 스킬Lv +1','일주연환격 스킬Lv +1','철산고 스킬Lv +1','파쇄권 스킬Lv +1','호격권 스킬Lv +1'],
    "커맨더" : ['C4 스킬Lv +1','EMP 스톰 스킬Lv +1','G-18C 빙결류탄 스킬Lv +1','G-35L 섬광류탄 스킬Lv +1','G-38ARG 반응류탄 스킬Lv +1','M18 클레이모어 스킬Lv +1','강화탄 스킬Lv +1','교차 사격 스킬Lv +1','네이팜 탄 스킬Lv +1','니트로 모터 스킬Lv +1','데인저 클로즈 스킬Lv +1','듀얼 플리커 스킬Lv +1','디-데이 스킬Lv +1','록 온 서포트 스킬Lv +1','리턴드 스나이퍼 스킬Lv +1','매거진 드럼 스킬Lv +1','버스터 샷 스킬Lv +1','병기 숙련 스킬Lv +1','병기강화 스킬Lv +1','슈퍼 노바 스킬Lv +1','오버 차지 스킬Lv +1','유탄 마스터리 스킬Lv +1','전장의 영웅 스킬Lv +1','증명의 열쇠 스킬Lv +1',"특수기동전대 '블랙 로즈' 스킬Lv +1",'포토빌라이저 스킬Lv +1','폭발탄 스킬Lv +1'],
    "크리에이터(자각2)" : ['계열 강화 스킬Lv +1','링크 스킬Lv +1','빙하시대 스킬Lv +1','상상력 스킬Lv +1','아이스 실드 스킬Lv +1','웜홀 스킬Lv +1','윈드 스톰 스킬Lv +1','윈드 프레스 스킬Lv +1','이해 스킬Lv +1','증폭 스킬Lv +1','창조의 공간 스킬Lv +1','천지창조 스킬Lv +1','타임 포워드 스킬Lv +1'],
    "크림슨 로제" : ['권총의 춤 스킬Lv +1','더블 건호크 스킬Lv +1','데스 바이 리볼버 스킬Lv +1','리볼버 강화 스킬Lv +1','멀티 헤드샷 스킬Lv +1','베일드 컷 스킬Lv +1','블러드 앤 체인 스킬Lv +1','블러디 카니발 스킬Lv +1','소닉 스파이크 스킬Lv +1','스타일리쉬 스킬Lv +1','쏘우 블레이드 스킬Lv +1','이동사격 스킬Lv +1','제압 사격 스킬Lv +1','체인 디바이더 스킬Lv +1','체인 스내치 스킬Lv +1','킬 포인트 스킬Lv +1'],
    "타나토스" : ['기요틴 스킬Lv +1','니콜라스 강령 스킬Lv +1','리빙데드 스킬Lv +1','발라크르 현신 스킬Lv +1','발라크르의 맹약 스킬Lv +1','발라크르의 야망 스킬Lv +1','블랙 아라크니아 스킬Lv +1','암흑의 의식 스킬Lv +1','어둠의 고리 스킬Lv +1','어둠의 손톱 스킬Lv +1','언홀리 마스터리 스킬Lv +1','익스큐서너 슬래쉬 스킬Lv +1','좀비 강령 스킬Lv +1','팬텀스트롬 스킬Lv +1','학살의 발라크르 강령 스킬Lv +1','학살자의 공포 스킬Lv +1','흑마법서 : 망자의 혼 스킬Lv +1','흑사진 스킬Lv +1'],
    "태을선인" : ['거병 마스터리 스킬Lv +1','해의 식신 - 창룡 스킬Lv +1','봉화연마 스킬Lv +1','공의 식신 - 백호 스킬Lv +1','난격 스킬Lv +1','대회전격 스킬Lv +1','무쌍격 스킬Lv +1','지의 식신 - 현무 스킬Lv +1','신선의 경지 스킬Lv +1','결의 식신 - 황룡 스킬Lv +1','역전의 칠조격 스킬Lv +1','열정의 챠크라 스킬Lv +1','열의 식신 - 주작 스킬Lv +1','주작부 스킬Lv +1','진격(陣擊)의 황룡 스킬Lv +1','사좌천태문 스킬Lv +1','퇴마의 서 스킬Lv +1','투기 발산 스킬Lv +1'],
    "트렌드세터" : ['INTRO : 샤인 온 미 스킬Lv +1','눈부신 광경 스킬Lv +1','드랍 더 비트 스킬Lv +1','러블리 템포 스킬Lv +1','브랜드 뉴 스킬Lv +1','사운드 오브 뮤즈 스킬Lv +1','센세이션 스킬Lv +1','소리의 향연 스킬Lv +1','스페셜 오프닝 스킬Lv +1','오늘의 주인공 스킬Lv +1','온 더 스테이지 스킬Lv +1','유명세 스킬Lv +1','파핑파핑 스펙트럼 스킬Lv +1'],
    "패스파인더" : ['버스트 필드 스킬Lv +1','앰플리파이 스킬Lv +1','어트랙트 에뮤니션 스킬Lv +1','익시드 스킬Lv +1','인젝션 스킬Lv +1','코어 블레이드 마스터리 스킬Lv +1','코어 패러다임 스킬Lv +1','코어 프렉시스 스킬Lv +1'],
    "패황" : ['강권 스킬Lv +1','극진 : 패황단공권 스킬Lv +1','급소 지정 스킬Lv +1','낙화 스킬Lv +1','라이트닝 댄스 스킬Lv +1','리미트 브레이크 스킬Lv +1','머슬 시프트 스킬Lv +1','무영각 스킬Lv +1','본 크러셔 스킬Lv +1','비연선풍 스킬Lv +1','사상최강의 로킥 스킬Lv +1','스탬피드 스킬Lv +1','아토믹 캐넌 스킬Lv +1','원인치 펀치 스킬Lv +1','철산고 스킬Lv +1','클로즈 킥 스킬Lv +1','패황연격 스킬Lv +1','헥토파스칼 킥 스킬Lv +1','홍염 질풍각 스킬Lv +1','화력개방 스킬Lv +1','화염의 각 스킬Lv +1','효율적인 연소 스킬Lv +1'],
    "프라임" : ['Ex-S 바이퍼 Zero 스킬Lv +1','Ex-S 바이퍼 스킬Lv +1','Ez-10 카운터어택 스킬Lv +1','Ez-8 카운트다운 스킬Lv +1','G 익스텐션 스킬Lv +1','G-1 코로나 스킬Lv +1','G-S.P. 팔콘 스킬Lv +1','G-오퍼레이터 스킬Lv +1','HS-1 친구들 스킬Lv +1','TX-45 A-Team 스킬Lv +1','TX-80 디멘션 러너 스킬Lv +1','게이볼그 펀치 스킬Lv +1','고기동 메카 : 볼트 MX 스킬Lv +1','공중 전투 메카 : 템페스터 스킬Lv +1','공중 전폭 메카 : 게일 포스 스킬Lv +1','로보틱스 스킬Lv +1','로봇 엔지니어링 스킬Lv +1','메카 드롭 스킬Lv +1','스패로우 팩토리 스킬Lv +1','아크리액터 스킬Lv +1','최후의 투지 스킬Lv +1','트랜스 폼 : G-0 배틀로이드 스킬Lv +1','트랜스포메이션 스킬Lv +1','트랜스폼 : G-2 롤링썬더 스킬Lv +1','트랜스폼 : G-3 랩터 스킬Lv +1','하이테크놀로지 스킬Lv +1','헬 마치 스킬Lv +1'],
    "프레이야" : ['C4 스킬Lv +1','EMP 스톰 스킬Lv +1','G-18C 빙결류탄 스킬Lv +1','G-35L 섬광류탄 스킬Lv +1','G-96 열압력유탄 스킬Lv +1','강화탄 스킬Lv +1','교차 사격 스킬Lv +1','네이팜 탄 스킬Lv +1','니트로 모터 스킬Lv +1','디-데이 스킬Lv +1','록 온 서포트 스킬Lv +1','버스터 샷 스킬Lv +1','병기 숙련 스킬Lv +1','병기강화 스킬Lv +1','오버 차지 스킬Lv +1','오픈 파이어 스킬Lv +1','유탄 마스터리 스킬Lv +1','증명의 열쇠 스킬Lv +1','폭발탄 스킬Lv +1'],
    "헤카테" : ['고통의 정원 스킬Lv +1','구속의 가시넝쿨 스킬Lv +1','금단의 저주 스킬Lv +1','데스티니 퍼펫 스킬Lv +1','마리오네트 스킬Lv +1','매드 더 빅 스킬Lv +1','사악한 호기심 스킬Lv +1','소악마 스킬Lv +1','어둠에 피는 장미 스킬Lv +1','인형의 숲 스킬Lv +1','커스 오브 테러베어 스킬Lv +1','테러블 로어 스킬Lv +1','퍼페티어 스킬Lv +1','편애 스킬Lv +1','하베스팅 스킬Lv +1'],
    "메이븐" :['사냥감 식별 스킬Lv +1','스프링클러 스킬Lv +1','팔케 : 블레이드 페더 스킬Lv +1','하이프레셔 스킬Lv +1','패스트 트리거 스킬Lv +1','크로스슈터 마스터리 스킬Lv +1','매시브 피어서 스킬Lv +1','사냥을 시작해볼까! 스킬Lv +1','워닝 샷건 스킬Lv +1','팔케 : 컨퓨징 무브 스킬Lv +1','플레어 스킬Lv +1','세컨드 부스트 스킬Lv +1','볼트 레인 스킬Lv +1','팔케 : 웹 드롭 스킬Lv +1','쓰리 인 어 베드 스킬Lv +1','놓치지마, 팔케! 스킬Lv +1','조인트 : 라운드업 스킬Lv +1','조인트 : 카펫 바밍 스킬Lv +1','팔케 : 윙 커터 스킬Lv +1','기어 업그레이드! 스킬Lv +1','F.D. 피어서 스킬Lv +1','팔케 : 체이싱클러 스킬Lv +1','퍼펙트 에이밍 스킬Lv +1'],
    "프레데터" : ['특이체질 스킬Lv +1','동족 사냥꾼 스킬Lv +1','페인 브링어 스킬Lv +1','안면 할퀴기 스킬Lv +1','하악질 스킬Lv +1','넥 펀처 스킬Lv +1','확인 사살 스킬Lv +1','발톱 꺼내기 스킬Lv +1','부여된 야성 스킬Lv +1','쫓는 뱀 스킬Lv +1','디바우러 스킬Lv +1','도깨비 바람 스킬Lv +1','크루얼 슬러터 스킬Lv +1','숨을 탐하는 자 스킬Lv +1','산 제물의 연회 스킬Lv +1','요요만찬 스킬Lv +1','램페이지 스킬Lv +1','인요합의 스킬Lv +1','발골 스킬Lv +1','피스 피어서 스킬Lv +1','아웃버스트 스킬Lv +1']
}

let runeSkill =
{
   
    "가이아" : ['가디언 라이딩','런지 에볼루션','뱅가드 스트랏슈','분쇄','생명의 전조','선고','신록의 검','신뢰의 돌파','압도','에이션트 디어','에이션트 보어','엘비쉬 점프','자연의 분노','자연의 속박','철벽방어','체인 스트라이크'],
    "갓파더" : ['기습타격','데드 식스','벽파일섬','빌 드릴','서프라이즈 컷','신속 기동','알파 스트라이크','원호 사격','집중 사격','최후의 일격','카라콜','택티컬 오펜시브','포인트 블랭크','헬릭스 다이브','휩쓸기'],
    "검신" : ['극 귀검술 : 심검','극 귀검술 : 유성락','극 발검술 : 섬단','극초발도','류심 승','류심 충','류심 쾌','맹룡단공참','발도','이면 뒤집기','차지 버스트','차지 크래시','환영검무'],
    "검제" : ['거압','극검','난화검','백보신장','비상','비연장','삼재검','십자검','연화검무','연화섬','일화도강','폭검','폭멸장','혜성만리','회륜','흡기'],
    "그랜드 마스터" : ['넥 스냅','로플링','롤링 스파이크','매그넘설트','브레이크 다운','블로우 스트라이크','숄더 태클','스냅 샷','에어슈타이너','오의 : 질풍노도','와일드 캐넌 스파이크','피어스','한놈만 팬다!!','흑진광풍'],
    "그림리퍼" : ['나락 떨구기','넥 디바이더','단검투척','섀도우 스팅','심장 찌르기','심장찌르기','암살','죽음의 연무','칼날돌풍','코버트 액션','파이널 디스트럭션','페이탈 블리츠','홀드업!'],
    "네메시스" : ['데들리 케이프','데우스 엑스 레이','디스트로이어','리버레이트 - 매드니스','리버레이트 - 벅샷','블랙 미러','소울드레인','쉐도우 랜서','쉐도우 바인딩','쉐도우 퍼펫','쉐도우 하베스트','스카폴드','스타폴','이몰레이션','지하드'],
    "다크나이트(자각2)" : ['다크 러스트','다크 레이브','다크 버스트','다크 볼','다크 브레이크','다크 소드','다크 웨이브','다크 웨이브 폴','다크 익스플로젼','다크 크래셔','다크 폴','다크 플레임 소드','드로우 소드','래피드 무브','모멘터리 슬래쉬','쇼크 웨이브','웨이브 스핀','윕 어택','일루젼 슬래쉬','차지 익스플로전','팬텀 소드'],
    "다크로드" : ['검귀강령 : 식','귀검편','귀영섬','귀참 : 광폭','귀참 : 나락','냉기의 사야','블레이드 팬텀','역병의 라사','툼스톤','툼스톤 스웜프','흑염검','흑염의 칼라 평타 1사이클'],
    "듀란달" : ['공아','그라운드 씨커','뇌격점혈섬','라이징 랜서','롤링 벌컨','매조싸이클론','메조싸이클론','미스트 플레어','삼일참월','슈트롬','스윌링 스피어','스파이럴 웨이브','쿼드러플 드라이브','트윈 펀토','파동연환창'],
    "드레드노트" : ['너클 블래스터','드래곤 스매쉬','드래곤 팽','미티어 버스트','버스트 혼','블릿츠 스트라이크','비룡천상','스피닝 레이드','아스트라 소환','용왕파천','용제출진','태도 용제무쌍','폭풍의 숨결'],
    "디스트로이어" : ['FM-31 그레네이드 런처','FM-92 스팅어','FM-92 스팅어 SW','MSC-7','X-1 익스트루더','레이저','레이저 라이플','사이즈믹 웨이브','슈타이어 대전차포','양자 폭탄','캐넌볼','플라즈마 부스터','화염 강타'],
    "디어사이드" : ['검마격살','분마검','비인외도 ','비인외도 : 극','사복검 - 무','사복검 - 발','사복검 - 조','사형조수','암연격 : 기가슬래쉬','역천의 프놈','폭류나선','혈마검','혈지군무','혈화난무'],
    "레이븐" : ['난사','더블 건호크','데들리 어프로치','리벤저','멀티 헤드샷','에어레이드','와이프 아웃','이동 사격','이동사격','제압 사격','트리플 탭','패스','패스트 드로우','헤드샷'],
    "레퀴엠" : ['달빛 가르기','목표 사격','목표 포착','문페이즈','보름달 베기','블레이드 팰컨','블레이드 펠컨','섬멸','쌍현참','암월비보','연속 사격','월광진혼곡','조준 사격'],
    "리디머" : ['그리드 더 러커','글러트니 바밋','길로틴','듀얼 슬래쉬','러스트 핸드','롤링 러쉬','사이드 매서커','서드 립','세컨드 소울','스커드 더 라스','슬로스 바디','엔비어스 키스','오라 휘광 8힛','죄를 깨우는 손길','프라이드 악셀'],
    "마신" : ['검은 충격','군단 정렬','대난투','매시브 파이어','미드나잇 카니발','제노사이드 크러쉬','천공검','파성추','파이널 어택','팬시 트위스트','희생폭탄'],
    "마제스티" : ['극 귀검술 : 파검무','마검발현','마검방출','반격','반월','비연참','섬광','승천','악즉참','용성락','제국검술','참마검','쾌속검','폭명기검','환검'],
    "명왕" : ['광폭혈쇄','그라운드 태클','그물 투척','비셔스 래리어트','더티 배럴','독병 투척','마운트','만천화우','바늘 투척','베놈 마인','블럭 투척','일발화약성','체인 드라이브'],
    "뱀파이어 로드" : ['럼블독','릴로이','머로더','블러드 윙','소울 스틸','쉬카리','오퍼링','커럽션','토먼트','팬텀 베일','팽','페이탈리티','헬베루즈'],
    "벤데타" : ['나이트폴','데인저러스 훅','딥 더스크','라스트 오더','불스아이','블랙아웃','블렌딩 페인','섀터드 글래스','셰이커 블래스트','스트레이트 샷','오버페이스','와일드 플라워','카타르시스','행오버'],
    "블래스터" : ['FM-31 그레네이드 런처','FM-92 스팅어','FM-92 스팅어 SW','X-1 익스트루더','슈타이어 대전차포','화염 강타'],
    "블랙 메이든" : ['구속의 가시넝쿨','꼼꼼한 바느질','매드 스트레이트','매드매드 슬래쉬','매디가드','사랑의 응급조치','점핑 베어 프레스','하베스팅'],
    "블러드 이블" : ['격노','레이징 퓨리','브루탈 크러셔','블러드 붐','블러드 소드','블러드 스내치','블러드러스트','블러디 레이브','아웃레이지 브레이크','페이탈 블러드','프렌지 평타 1사이클'],
    "빙결사" : ['브로큰 애로우','아이스 크래쉬','아이스 필드'],
    "세라핌" : ['그랜드 크로스 크래쉬','대천사의 축복','리펜텐스 스매시','미라클 샤인','샤인 크로스','세례의 빛','세인트 월','승리의 창','신성한 빛','용맹의 아리아','우라엘의 결계','우리엘의 결계','재생의 아리아','정화의 번개','치유의 기도','홀리 써클릿','홀리 플래쉬'],
    "세이비어" : ['디스럽션','레쓰','레트리뷰션','멘데이트 오브 헤븐','실드 브레이크','옵','옵스트럭션','위핑 웨이브','이스트레인지먼트','인게이지먼트','인테그레이션','컨실리에이션','컨페션','포기브니스','퓨리파잉'],
    "세인트" : ['디','디바인 플래쉬','디플렉트 월','미라클 스플리터','미러클 스플리터','빛의 복수','순백의 칼날','슬로우 힐','승리의 창','운명의 창','정의의 심판','참회의 망치','크로스 크래쉬','플래시 글로브','합티즘','홀리 생츄어리','힐 윈드'],
    "셀레스티얼" : ['가랑비','고원의 안개꽃','기어 드라이브','독충 쫓기','셋 업 : 불꽃놀이','셋 업 : 신무병장 요수광란','스크램블 앵커','신무병장 유성','안개폭우','제트스트림','트리플악셀','헤이즈 스톰'],
    "스톰 트루퍼" : ['FM-31 그레네이드 런처','FM-92 mk2 랜서','FM-92 mk2 랜서 SW','FSC-7','PT-15 프로토타입','X-1 익스트루더','X-2 헬리오스','레이저 라이플','슈타이어 대전차포','양자 폭탄','캐넌볼','플라즈마 부스터','화염 강타'],
    "시라누이" : ['나선 대차륜 쿠나이','두꺼비유염탄','마환 풍마수리검','비기 : 염무개화','아마테라스','암기 : 비연수리검','야마타오로치','열화천도','인법 : 바꿔치기','화염구','화염선풍','환영 다중 수리검'],
    "아슈타르테" : ['강습유성타','뇌연격','더블 스윙','사도의 춤','쇄패','원무곤','황룡강림','체이서 40히트','체이서 클러스터','체이서 프레스','타이머 밤','트윙클 스매쉬','슈퍼 체이서','황룡난무','황룡천공'],
    "아이올로스" : ['대진공','바이올런트 스톰','삭풍','소닉 무브','스톰 스트라이크','스톰 퀘이커','스파이럴 프레스','싹쓸바람','윈드 블래스터','질풍가도','칼날 바람','폭풍의 눈','회오리 바람','휘몰아치는 바람'],
    "악귀나찰" : ['귀신보','귀연참','악귀연무','악귀참수','연아','원귀 : 격살','원귀 : 신풍','원귀 : 연참','원귀 : 일섬','원귀 : 회천','합격 : 나락참','합격 : 월령참','합격 : 이혼일섬','혼백참'],
    "알키오네" : ['더블 피어스','디스트리뷰션','라이트닝 애로우','밴디트컷','버티컬 스파이럴','소닉 어설트','소드 댄스','어슬랜트 스파이럴','엑셀 스트라이크','엑조틱 램펀트','체인소','체인소 러시','허리케인'],
    "어센션" : ['괴리 : 저편의 자장가','괴리 : 칙 잇','괴리 : 패러사이트 스웜','괴리 : 할리퀸','차원 : 디멘션 리프','차원 : 시공폭뢰','차원 : 실루엣','차원 : 싱귤래리티 매니플레이션','차원 : 차원광','차원 : 차원회랑','차원 : 칼레이도스코프','차원 : 패러다임 디토네이션','차원 : 포지트론 블래스트'],
    "언터처블" : ['G-CTF','겟 온 파이어','더 나인 배','더 나인 배럴','더블 배럴','디스트러블 퀘이크','디퓨전 버스트','바이올런트 스매쉬','번 투 슬래쉬','브레이크 샷','서프라이즈 프레전트','소드 밤','오프레션','퍼펙트 배팅','퍼펙트 베팅'],
    "얼티밋 디바" : ['넥 스냅','로플링','브레이킹 엘보','숄더 태클','수플렉스 싸이클론','스파이어','싸이클론 어택','에어슈타이너','에어클러치','와일드 캐넌 스파이크','자이언트 스윙','조인트 스매쉬','토르베지노'],
    "에레보스" : ['다크 디비젼','다크 베리얼','다크 스타','다크 엠비션','다크 임펄스','더블 쓰로우','디스페어','디퓨젼','바이올런트 피어스','스파이럴 러쉬','암흑나선창','절명창','폴링 데스','피어싱 드라이브','흑광폭살'],
    "엑스마키나" : ['AT-SO Walker','네오디뮴 퓨지','다이렉트 피드백','레이시온 크리트','맥피스','버스트 밤','부스트 : 다이너마이트 킥','부스트 : 바디 벙커','부스트 : 브리칭 다운','스코치 택틱스','트리플 건샷','포트리스 블리츠'],
    "염제 폐월수화" : ['광룡십삼장','광충노도','기공장','기옥탄','뇌전격','비룡나선파','사자후','세인트 일루전','질풍각','태사자후','환영폭쇄','흡기공탄'],
    "염황 광풍제월" : ['기공장','기공환','기호지세','나선의 넨 : 주','나선의 넨 : 주 100히트','나선의 넨 : 출','넨 스피어','뇌전각','뇌전포','사자후','월광대지','제황나선격','천뢰호각','환영폭쇄'],
    "오버마인드" : ['나이트 할로우','더 게이트','보이드','썬더 스트라이크','썬더 콜링','썬버스트','아크틱 피스트','엘레멘탈 커튼','엘레멘탈 퀘이크','초월의 크리스탈','칠링 팬스','플레임 스트라이크','핼로윈 버스터'],
    "오블리비언" : ['다크니스 맨틀','다크니스 멘틀','라이트닝 월','아이스 크리스탈 샤워','암전','엘레멘탈 레인','엘레멘탈 스트라이크','엘레멘탈 필드','체인라이트닝','컨버전스 캐넌','컨센트레이트','크리스탈 어택','파이어로드','플레임 서클'],
    "옵티머스" : ['Ex-S 바이퍼','Ez-8 카운트다운','G-S.P. 팔콘','HS-12 플라즈마','공중 전폭 메카 : 게일 포스','마그네틱 필드','메카 드롭','스패로우 팩토리','프리스비'],
    "용독문주" : ['그라운드 킥','마운트','마운트 크레이지 발칸','만병지왕 - 파진포','맹독 일발화약성','베놈 마인','블록 버스터','블록 봄','비기 : 폭독','일발화약성','정크 스핀','천라지망','히든 스팅'],
    "워로드" : ['데들리 매서커','둠 글레이브','드레드보어','디베스테이트','런지 스트라이크','버스트 슬래쉬','브랜디쉬','블레이드 스톰','비헤드','임팩트 스매쉬 5회','크레센트 슬래쉬','헤드온 챠지'],
    "이모탈" : ['다크 하울링','데빌 클러치','데빌 피스트','데스 사이드','리퍼','복마전','복수의 가시','스피닝 디포메이션','스핀커터','어둠의 권능','재앙','지뢰진','파멸의 강타'],
    "이클립스" : ['계약소환 : 검은기사 산도르','계약소환 : 루이즈 언니!','계약소환 : 마계화 아우쿠소','계약소환 : 타우킹 쿠루타','계약소환 : 프리트','끈끈이 투척','다크문','분노의 난타','블랙 루나틱','저놈 잡아라!','전설소환 : 월영','정령 희생','정령소환 : 글레어린','정령소환 : 데드 멀커','정령소환 : 아퀘리스','정령소환 : 정령왕 에체베리아','정령소환 : 플레임 헐크','하급 정령 소환'],
    "이터널" : ['브로큰 애로우','블리자드 스톰','샤드 매그넘','설화연창','아이스 빅 해머','아이스 오브','아이스 크래쉬','아이스 필드','아이스맨','오버프리즈 익스텐션','피어스 오브 아이스','핀포인트 러쉬','회전투창'],
    "인다라천" : ['귀문반','극파동검 빙인','극파동검 폭염','무쌍파','부동명왕진','작열 파동진','진공참','천뇌 바주라','천뇌 바주라 잔딜','파동 해제','파동검 : 인다라망','파동검 빙인','파동검 폭염'],
    "인페르노" : ['고결한 격노','성화','세례의 불길','신벌','심판의 일격','업화의 소용돌이','역죄단멸','즉결심판','즉결심판 소각','징벌의 일격','차륜형','참수','플레게의 정수','플레게의 화염병','성염의 쇄도'],
    "저스티스" : ['개틀링 펀치','고저스 컴비네이션','더킹 바디블로','더킹 스트레이트','더킹 어퍼','데몰리션 펀치','디바인 크러시','머신건 잽','사이드 와인드','아토믹 쵸퍼','초핑 해머','코크 스크류 블로우','핵 펀치','허리케인 롤','헤븐리 컴비네이션'],
    "제노사이더" : ['광룡분쇄창','날개 꿰기','니들러','데보닝 슬래셔','두더지 사냥','램 펜스','버팔로 폴','번 로커스트','서번트 랜스 8사출','서번트 랜스 : 어썰트','서번트 랜스 : 인게이저','스케일 그라인더','어스웜 헌트','에이븐 헌팅','팔티잔 스큐어','페니트레잇 헤드'],
    "지니위즈" : ['고출력 매직 미사일','대왕 파리채','도발 인형 : 슈르르','롤리팝 크러쉬','메가 드릴','반중력 기동장치','변이 파리채','블랙 망토','빗자루 스핀','성난 불길 가열로','애시드 클라우드','용암지대 생성물약','잭프로스트 빙수','플로레 전기토끼','플로레 컬라이더'],
    "천선낭랑" : ['공력의 구','구속제압부','마혼부 : 퇴마음양옥','마혼제령술','목환자경','백팔염주','부동주박진','시폭염화부','신룡의 여의주 14히트','심혼흡성부','염폭모대주','용의 격노','탈명대염진'],
    "카이저" : ['라이징 너클','라이트닝 댄스','무영각','본 크러셔','붕권','비트 드라이브','원인치 펀치','일주연환격','질풍각','철산고','파쇄권','호격권'],
    "커맨더" : ['C4','G-18C 빙결류탄','G-35L 섬광류탄','G-38ARG 반응류탄','G-61 중력류탄','M18 클레이모어','고전압탄 : 맥스웰','교차 사격','네이팜 탄','데인저 클로즈','록 온 서포트','리턴드 스나이퍼','버스터 샷','피스톨 카빈'],
    "크리에이터(자각2)" : ['링크','빙하시대','아이스 스톤','아이스 실드','아이스 플레이트','운석 낙하','웜홀','윈드 스톰','윈드 프레스','타임 포워드','파이어월','플레임 허리케인'],
    "크림슨 로제" : ['권총의 춤','더블 건호크','멀티 헤드샷','블러디 스파이크','소닉 스파이크','이동사격','제압 사격','체인 디바이더','체인 스내치','킬 포인트','평타 1탄창','헤드샷'],
    "타나토스" : ['기요틴','리빙데드','망자의 원한','망자의 집념','발라크르의 야망','분기암경파','블랙 아라크니아','야행혼','어둠의 손톱','익스큐서너 슬래쉬','좀비 강령','헤이트풀','흑사진','흡기암경파'],
    "태을선인" : ["열의 식신 - 주작","지의 식신 - 현무","해의 식신 - 창룡",'공의 식신 - 백호','난격','대회전격','황룡진','무쌍격','역린격','역전의 칠조격','저 하늘의 별','질풍타'],
    "트렌드세터" : ['거리 공연','눈부신 광경','다 카포','드랍 더 비트','밤과 꿈','비비드','빛의 변주','사운드 오브 뮤즈','스페셜 오프닝','컬쳐 쇼크','파워 포르테','파핑파핑 스펙트럼'],
    "패스파인더" : ['CEAB-2','디바이드 어쓰','디스펄션','라이트 브랜디쉬','라이트닝 슬래쉬','로테이션 웨이브','스퀘어리','어트랙트 에뮤니션','에너지 바운스','엑소셋','이미션','일렉트릭 필러','테이저'],
    "패황" : ['낙화','뇌격','라이트닝 댄스','본 크러셔','비연선풍','섬격','스핀 킥','아토믹 캐넌','철산고','클로즈 킥','헥토파스칼 킥','홍염 질풍각'],
    "프라임" : ['Ex-S 바이퍼','Ex-S 바이퍼 Zero','Ez-10 카운터어택','Ez-8 카운트다운','RX-60 트랩러너','TX-45 A-Team','TX-80 디멘션 러너','공중 전투 메카 : 템페스터','메카 드롭','스패로우 팩토리'],
    "프레이야" : ['C4','G-18C 빙결류탄','G-35L 섬광류탄','G-61 중력류탄','G-96 열압력유탄','M18 클레이모어','교차 사격','네이팜 탄','록 온 서포트','버스터 샷','오픈 파이어','초진공탄 : 첼리','포토빌라이저','폭발탄 1탄창','피스톨 카빈'],
    "헤카테" : ['고통의 정원','구속의 가시넝쿨','꼼꼼한 바느질','데스티니 퍼펫','매드 더 빅','매드 스트레이트','매드매드 슬래쉬','매디가드','사랑의 응급조치','점핑 베어 프레스','커스 오브 테러베어','테러블 로어','하베스팅','헥스'],
    "메이븐" : ['스프링클러','팔케 : 블레이드 페더','하이프레셔','패스트 트리거','매시브 피어서','워닝 샷건','팔케 : 컨퓨징 무브','플레어','볼트 레인','팔케 : 웹 드롭','쓰리 인 어 베드','조인트 : 카펫 바밍','팔케 : 윙 커터','F.D. 피어서','팔케 : 체이싱클러'],
    "프레데터" : ['요수조','동족 사냥꾼','페인 브링어','안면 할퀴기','넥 펀처','확인 사살','발톱 꺼내기','쫓는 뱀','디바우러','도깨비 바람','크루얼 슬러터','요요만찬','램페이지','발골','피스 피어서']
}


let avatarOption = 
{ "0" : 
[ 
	
		
		[
			
"HP 1분당 36 회복",

"MP 1분당 36 회복",

"정신력 45 증가",

"지능 45 증가",

"캐스팅 속도 12.0% 증가"
	
	],
	
[

		"HP 1분당 45 회복",

"MP 1분당 45 회복",

"정신력 55 증가",

"지능 55 증가",

"캐스팅 속도 14.0% 증가"
				
				]
				
	]
    ,
    "1":[[
			
        "HP 1분당 36 회복",

"MP 1분당 36 회복",

"정신력 45 증가",

"지능 45 증가",

"캐스팅 속도 12.0% 증가"

],

[

"HP 1분당 45 회복",

"MP 1분당 45 회복",

"정신력 55 증가",

"지능 55 증가",

"캐스팅 속도 14.0% 증가"
    
    ]],
    "2" :
    [ 
		
		
            [
            
                    "공격 속도 5.0% 증가",

    "마법 방어력 660 증가",

    "모든 상태 이상 내성 2.6% 증가",

    "히트리커버리 160 증가"

    ],

    [

        "공격 속도 6.0% 증가",

    "마법 방어력 900 증가",

    "모든 상태 이상 내성 3.6% 증가",

    "히트리커버리 200 증가"
                
                ]
                
    ],
    "4" :
    [ 
		
		
        [
        
                "G-14 파열류탄 스킬Lv +1",

"G-Bomb 스킬Lv +1",

"HP MAX 280 증가",

"M-137 개틀링건 스킬Lv +1",

"M-3 화염방사기 스킬Lv +1",

"MP MAX 280 증가",

"RX-78 랜드러너 스킬Lv +1",

"가드 스킬Lv +1",

"강습 스킬Lv +1",

"검막 스킬Lv +1",

"계약소환 : 기갑 호도르 스킬Lv +1",

"고각도 슬라이딩 스킬Lv +1",

"고대의 기억 스킬Lv +1",

"고어 크로스 스킬Lv +1",

"고통의 희열 스킬Lv +1",

"공격 속도 3.0% 증가",

"공중 밟기 스킬Lv +1",

"공중 연속 베기 스킬Lv +1",

"공중사격 스킬Lv +1",

"공참타 스킬Lv +1",

"귀참 스킬Lv +1",

"금강쇄 스킬Lv +1",

"기본기 숙련 스킬Lv +1",

"낙봉추 스킬Lv +1",

"낙화장 스킬Lv +1",

"날다람쥐 스킬Lv +1",

"날벼락부 스킬Lv +1",

"넨탄 스킬Lv +1",

"니들 소배트 스킬Lv +1",

"다크 소울 스킬Lv +1",

"단공참 스킬Lv +1",

"달빛 베기 스킬Lv +1",

"대검 마스터리 스킬Lv +1",

"데드리프트 스킬Lv +1",

"도 마스터리 스킬Lv +1",

"도약 스킬Lv +1",

"둔기 마스터리 스킬Lv +1",

"뒷목 가격 스킬Lv +1",

"디스인챈트 스킬Lv +1",

"라이징 슬래쉬 스킬Lv +1",

"라이징 컷 스킬Lv +1",

"라이징샷 스킬Lv +1",

"랜턴 파이어 스킬Lv +1",

"럭키 스트레이트 스킬Lv +1",

"로망 대시 스킬Lv +1",

"로킥 스킬Lv +1",

"리탈 펀토 스킬Lv +1",

"마릴린 로즈 스킬Lv +1",

"마하킥 스킬Lv +1",

"매직 미사일 스킬Lv +1",

"무거운 죄업 스킬Lv +1",

"무기 막기 스킬Lv +1",

"무릎 찍기 스킬Lv +1",

"무즈 어퍼 스킬Lv +1",

"물리 방어력 660 증가",

"바베~큐 스킬Lv +1",

"발목 타격 스킬Lv +1",

"방패 방어 스킬Lv +1",

"본 실드 스킬Lv +1",

"분노의 움켜쥠 스킬Lv +1",

"분신 스킬Lv +1",

"불굴의 의지 스킬Lv +1",

"붕산격 스킬Lv +1",

"브리프 컷 스킬Lv +1",

"빔 슬래쉬 스킬Lv +1",

"빙백검 스킬Lv +1",

"사이드 슬래쉬 스킬Lv +1",

"샤이닝컷 스킬Lv +1",

"섀도우컷 스킬Lv +1",

"서라운드 브레이크 스킬Lv +1",

"세컨드 어퍼 스킬Lv +1",

"소검 마스터리 스킬Lv +1",

"쇼타임 스킬Lv +1",

"수플렉스 스킬Lv +1",

"스프리건 스킬Lv +1",

"스핀 어택 스킬Lv +1",

"슬로우 힐 스킬Lv +1",

"승천검 스킬Lv +1",

"시륜검 스킬Lv +1",

"실드 스타크 스킬Lv +1",

"쓰러스트 스킬Lv +1",

"아이스 스톤 스킬Lv +1",

"아이스 플레이트 스킬Lv +1",

"암흑참 스킬Lv +1",

"앵글 샷 스킬Lv +1",

"앵클 컷 스킬Lv +1",

"어둠의 둔기 마스터리 스킬Lv +1",

"어퍼 슬래쉬 스킬Lv +1",

"에쉔 포크 스킬Lv +1",

"연속발사 스킬Lv +1",

"연환격 스킬Lv +1",

"열파참 스킬Lv +1",

"염화참 스킬Lv +1",

"오라 실드 스킬Lv +1",

"올려차기 스킬Lv +1",

"용아 스킬Lv +1",

"운명의 수레바퀴 스킬Lv +1",

"웨이브 스킬Lv +1",

"윈드 스트라이크 스킬Lv +1",

"윈드밀 스킬Lv +1",

"은탄 스킬Lv +1",

"이레이저 스킬Lv +1",

"인듀어 스킬Lv +1",

"인법 : 허물 벗기 스킬Lv +1",

"인터셉트 스킬Lv +1",

"적중률을 3.0% 증가",

"정신력 20 증가",

"지능 20 증가",

"질풍각 스킬Lv +1",

"채찍질 스킬Lv +1",

"천격 스킬Lv +1",

"철금강 스킬Lv +1",

"체력 20 증가",

"치유의 기도 스킬Lv +1",

"카잔 스킬Lv +1",

"캐스팅 속도 5.0% 증가",

"커스 스피어 스킬Lv +1",

"컷 인 대쉬 스킬Lv +1",

"코어 실드 스킬Lv +1",

"쿠나이 던지기 스킬Lv +1",

"퀵 스탠딩 스킬Lv +1",

"퀵 펀토 스킬Lv +1",

"큐어 스킬Lv +1",

"크라우치 스킬Lv +1",

"탑스핀 스킬Lv +1",

"태산압정 스킬Lv +1",

"투척 마스터리 스킬Lv +1",

"파동검 지열 스킬Lv +1",

"파르티잔 슈트 스킬Lv +1",

"패러트루퍼 스킬Lv +1",

"퍼니셔 스킬Lv +1",

"퍼펙트 가드 스킬Lv +1",

"프로스트 헤드 스킬Lv +1",

"플로레 비비기 스킬Lv +1",

"플루토 스킬Lv +1",

"피니쉬! 스킬Lv +1",

"해머 킥 스킬Lv +1",

"헬름 샷 스킬Lv +1",

"헬터 스켈터 스킬Lv +1",

"현월참 스킬Lv +1",

"호신연격 스킬Lv +1",

"홀리 스매쉬 스킬Lv +1",

"홉 스매쉬 스킬Lv +1",

"회전격 스킬Lv +1",

"훅 앤 쓰로우 스킬Lv +1",

"힘 20 증가"

],

[

    "HP MAX 400 증가",

"MP MAX 400 증가",

"물리 방어력 900 증가",

"어둠의 광검 마스터리 스킬Lv +1",

"어둠의 대검 마스터리 스킬Lv +1",

"어둠의 도 마스터리 스킬Lv +1",

"어둠의 소검 마스터리 스킬Lv +1"
            
            ]
            
],
"5" :[ 
		
		
    [
    
            "이동 속도 5.0% 증가",

"점프력 40 증가",

"체력 45 증가",

"힘 45 증가"

],

[

"이동 속도 6.0% 증가",

"점프력 48 증가",

"체력 55 증가",

"힘 55 증가"
        
        ]
        
],
"6":[ 
		
	
    [
            
        "공격 속도 5.0% 증가",

"마법 방어력 660 증가",

"모든 상태 이상 내성 2.6% 증가",

"히트리커버리 160 증가"

],

[

"공격 속도 6.0% 증가",

"마법 방어력 900 증가",

"모든 상태 이상 내성 3.6% 증가",

"히트리커버리 200 증가"
    
    ]
        
],
"7":[ 
		
		
    [
    
            "명속성 저항 25 증가",

"수속성 저항 25 증가",

"암속성 저항 25 증가",

"최대 무게 10.000kg 증가",

"최대 무게 9.000kg 증가",

"화속성 저항 25 증가",

"회피율 4.0% 증가"

],

[

"명속성 저항 35 증가",

"수속성 저항 35 증가",

"암속성 저항 35 증가",

"최대 무게 14.000kg 증가",

"화속성 저항 35 증가",

"회피율 5.5% 증가"
    
    ]
    
]



};

export {editSetting,avatarOption,avatarSkillOption,avatarTypeList,emblemSkillOption,gradeToNum,runeSkill,talismanList,customEquip};