function DamageRadioJob({ children, value, name, defaultChecked, disabled, present, setType, setRealJobName,setGaksungType,setRealGaksungName,DameageJobArray,characterData,setCharacterData  }) {


    return (
      <div className='rkcbox'>
        <input
          type="radio"
          value={value}
          name={name}
          defaultChecked={defaultChecked}
          disabled={disabled}
          checked={present == value} //true, false
          id={"option01"+value}
          onClick = {()=>{setType(value);setRealJobName(name);setGaksungType(0);setRealGaksungName(DameageJobArray[value].job[0]);weaponDelete(characterData,setCharacterData);}}
          />
        
        <label for={"option01"+value}>
            <span>
                <img src={"img/job/"+name+".gif"}/>
            </span>  
            
          {name}
        </label>
      </div>
    );
  }

  function weaponDelete(characterData,setCharacterData)
{
  let temp = {...characterData};

    if(temp['item100'] != null)
    {
      delete temp.item100;
    }
    
    setCharacterData(temp);
}



  export default DamageRadioJob
