
import { createContext, useEffect, useState, onChange } from 'react';
import { Routes, Route, Link, useNavigate, Outlet ,Navigate} from 'react-router-dom'
import Radio from "../utill/Radio.js";
import RadioMO from "../utill/Radio_MO";
import Checkbox from "../utill/Checkbox.js";
import RadioJob from "../utill/Radio_job.js";

import {serverKR} from "../var/server.js";
import Ad from '../ad';



import React from 'react';

import axios from 'axios';
import Script from '../utill/Script';



function BuffRankMO(){

    let navigate = useNavigate();

    let [mobuffbase, setMoBuffbase] = useState();
    let [buffrankerbase, setBuffRankerbase] = useState();
    let [bufftype, setBufftype] = useState(1);

    
    const params = new URLSearchParams(window.location.search);
    let pageParam = params.get('page') != null ? params.get('page') : 1;
    let typeParam = params.get('type') != null ? params.get('type') : 3;
    let realgaksungnameParam = params.get('job') != null ? params.get('job') : "j1" ;
    let lovetypeParam = params.get('lovetype') != null ? params.get('lovetype') : "l2" ;


    let [page, setPage] = useState(pageParam);
    const [type, setType] = useState(typeParam);
    const [jobtype, setJobType] = useState(realgaksungnameParam);
    const [lovetype, setLoveType] = useState(lovetypeParam);


    let rankingArray = [0,0,0,0,0,0,0,0];
    let rankinglistArray = [0,0,0,0,0,];
    let [listedit,setListEdit] = useState(1);

    let [typetab,setTypeTab] = useState(0);


    
    let temp = "";
    for(var [key, value] of params.entries()) {
        if(key != 'page' 
            && key != 'type'  
            && key != 'job'  
            && key != 'baseJob'  
            && key != 'favor'  
            )
        {
            temp += '&'+key+ '='+ value; 
        }
    }

    if(window.location.href.indexOf("#") != -1)
    {
        temp += "#"+window.location.href.split("#")[1];
    }
    

    useEffect( () => { 

      window.history.replaceState("",null,'?page='+page+'&job='+jobtype+'&type='+type+'&favor='+lovetype+temp);
     
        
      axios.post('/dat/bufferRankingData.jsp?page='+page+'&job='+jobtype.replace('j','')+'&type='+type+'&favor='+lovetype.replace('l','')
      ,{}, { cancelToken: source.token  })
      .then((base)=>{
        let copy2 = base.data;
        setMoBuffbase(copy2);
        })

        
        
        return ()=>{

            source.cancel();
        }

    },[type,jobtype,page]);

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    useEffect( () => { 

        var self = window;
        self.__VM = self.__VM || [];
        self.__VM.push(function (admanager, scope) {
            scope.Config.get('mobile_mpu').display('mo-ad-container-1');
            scope.Config.get('mobile_banner').display('ad-mo-1');

        });

        return () =>
        {
            self.__VM = self.__VM || [];
            self.__VM.push((admanager, scope) => {
                admanager.removeAllPlacements();
                scope.Instances.pageManager.newPageSession();
            });
            
        };
    },[]);

    // useEffect( () => { 
    //     var placement = document.createElement("div");
    
    //     (window.top).__vm_add = (window.top).__vm_add || [];

    //     (function (success) {
    
    //         if (window.document.readyState !== "loading") {
    
    //             success();
    
    //         } else {
    
    //             window.document.addEventListener("DOMContentLoaded", function () {
    
    //                 success();
    
    //             });
    
    //         }
    
    //     })(function () {
    
    
    //         placement.setAttribute("class", "vm-placement");
    
    //         placement.setAttribute("data-id", "63358a7095a6d454fcf66d0d");
    
    //         document.querySelector("#mo-ad-container-1").appendChild(placement);
    
    //         window.__vm_add.push(placement);
    
    //     });
        
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         (window.top).__vm_remove.push(placement);
    //         //console.log("unmounting", placement);

    //     };

    // },[]);

    
    // useEffect( () => { 
    //     var placement = document.createElement("div");
    
    //     (window.top).__vm_add = (window.top).__vm_add || [];

    //     (function (success) {
    
    //         if (window.document.readyState !== "loading") {
    
    //             success();
    
    //         } else {
    
    //             window.document.addEventListener("DOMContentLoaded", function () {
    
    //                 success();
    
    //             });
    
    //         }
    
    //     })(function () {
    
    
    //         placement.setAttribute("class", "vm-placement");
    
    //         placement.setAttribute("data-id", "63358a9cbf161d63ec3c8e8e");
    
    //         document.querySelector("#ad-mo-1").appendChild(placement);
    
    //         (window.top).__vm_add.push(placement);


            
    //         //console.log("mounting", placement);
    
    //     });
        
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         (window.top).__vm_remove.push(placement);
    //         //console.log("unmounting", placement);


    //     };

    // },[]);


    
    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     window.top.__vm_add = window.top.__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358aa395a6d454fcf66d11");
    //         document.querySelector("#rich-media-placement").appendChild(placement);
    //         window.top.__vm_add.push(placement);
    //     });
            
    //     return() => { 
    //         (window.top).__vm_remove_category = (window.top).__vm_remove_category || [];
    //         (window.top).__vm_remove_category.push("richmedia_all");

    //         //console.log("unmounting", (window.top).__vm_remove_category);
    //     };

            
    // },[]);

    return (
        
        <div class="rankingMO">
            <div id="rich-media-placement"></div>
            <div className="mo-container ranking-mo">
                <div class="rkcont mbuff"> 
                    <div class="ch">
                        <div class="ch_rk tble" name="랭킹선택 '랭킹'">
                            <div class="rksty">
                                <div className="radio_hug">
                                    <RadioMO name="Stat" value="1" present={type} 
                                    setType={setType}>
                                    스탯
                                    </RadioMO>
                                    <RadioMO name="Attack" value="2" present={type}
                                    setType={setType}>
                                    공격력
                                    </RadioMO>
                                    <RadioMO name="Point" value="3" present={type}
                                    setType={setType}>
                                    점수
                                    </RadioMO>
                                </div>
                            </div>

                            
                            <div class="rkmgak_wep">
                                <div class="rksall">

                                    <div class="rkrk" name="직업">
                                        <select id="rk-choice" name="직업"
                                         onChange={(e)=>{ setJobType(e.target.value) }}>
                                            <option value="j1">전체</option>
                                            <option value="j3">眞 크루세이더(남)</option>
                                            <option value="j2">眞 크루세이더(여)</option>
                                            <option value="j4">眞 인챈트리스</option>
                                            <option value="j5">眞 뮤즈</option>
                                        </select>
                                    </div>
                                    <Checkbox id="optionlove" value={lovetype} type={lovetype} setType={setLoveType}>
                                        편애
                                    </Checkbox>
                              
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div id='ad-mo-1'></div>
                

                <div class="rklist drank mbuff" name="랭킹 검색 결과">


                    <div class="rk-table" name="랭킹표">

                        <div class="rkt-tbody" name="랭킹 검색 tbody">
                            
                            {
                                
                                mobuffbase && mobuffbase.ranking.map((a, i) =>
                                {
                                
                                    return(

                            
                                <div class="rkt-tr"  onClick={() => navigate('../character?server='+mobuffbase.ranking[i].server+'&key='+mobuffbase.ranking[i].key)}>
                                    <div class="td name new">
                                        <span class="abata">
                                            <div className="imgt">
                                                <img src={'https://img-api.neople.co.kr/df/servers/'+mobuffbase.ranking[i].server+'/characters/' +mobuffbase.ranking[i].key+ '?zoom=1.jpg&temp='+ Math.round(Math.random() * 10000)}  />
                                            </div>
                                        </span>

                                        <span class="nik" name="닉네임">
                                            {mobuffbase.ranking[i].nick}
                                        </span>
                                        <div class="tb_fsev">
                                            <div class="rker-sever">
                                                <div class="rkrsv">
                                                    <span class="svname">{serverKR[mobuffbase.ranking[i].server]}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <li class="mrknum">
                                            <span class="mrkm">
                                            {mobuffbase.ranking[i].rank}
                                    
                                            </span>
                                        </li>
                                    </div>
                                    <div class="td dameged buff">

                                        <span class="damegen">
                                            <div class="buffstatall">
                                                <div class="bstat">
                                                    <span>{mobuffbase.ranking[i].stat}</span>
                                                </div>
                                                <div class="battck active">
                                                    <span>{mobuffbase.ranking[i].attack}</span>
                                                </div>
                                                <div class="bpoint">
                                                    <span>{mobuffbase.ranking[i].score}</span>
                                                </div>
                                                <div class="bfame">
                                                <span>{mobuffbase.ranking[i].fame}</span>
                                                </div>
                                            </div>
                                            </span>


                                    </div>

                                </div>
                                    )
                                }

                            )}
                        </div>
                    </div>
                
                </div>

                <div className="page-guide">
                    <div className="list">
                            <ul className="list-number">
                                <li className="back" onClick={()=> {setListEdit((listedit) <= 1 ? 1 : (listedit)-5)}}>
                                    <span>
                                    </span>
                                </li>
                            {
                                page &&  rankinglistArray.map((a,i) => 
                                {
                                    return(
                                    
                                            <li key={i} className={page == listedit+i ? "is-active" : ""} onClick={()=>{setPage((listedit)+i)}}><span>{(listedit)+i}</span></li>
                                        
                                            
        
                                        
                                    )
                                }
                                
                                )
                            }
                            <li className="next" onClick={()=> {setListEdit((listedit) + 5)}}>
                                <span>
                                </span>
                            </li>


                        </ul>
                    </div>
                </div>

                <div id="mo-ad-container-1"></div>
            </div>
            
            <div class='coupang'>
            </div>
            <Script src="https://cp.edl.co.kr/cou/js.php?code=dundamdw1&flt=0&click_type=1&width=320&height=50&adid=&click_id="></Script>
        </div>




    
        )
    }
    



    



      

      


    export default BuffRankMO