function RadioMO({ children, value, name, defaultChecked, disabled, present, setType }) {



    return (
      <div className='rktbox'>
        <input
          type="radio"
          value={value}
          name={name}
          defaultChecked={defaultChecked}
          disabled={disabled}
          checked={present == value}
          id={"option"+value}
          onClick = {()=>{setType(value)}}
          />
        
        <label for={"option"+value}>
          {children}
        </label>
      </div>
    );
  }


  export default RadioMO
