const legendStone = [
'최종 데미지 1%',
'최종 데미지 2%',
'최종 데미지 3%',
'최종 데미지 4%',
'공격력 증가 +350.0%',
'공격력 증가 +700.0%',
'공격력 증가 +1050.0%',
'공격력 증가 +1400.0%',
'모든 속성 강화 +6',
'모든 속성 강화 +10',
'모든 속성 강화 +14',
'모든 속성 강화 +18',
'화속성 강화 +8',
'화속성 강화 +12',
'화속성 강화 +16',
'화속성 강화 +20',
'수속성 강화 +8',
'수속성 강화 +12',
'수속성 강화 +16',
'수속성 강화 +20',
'명속성 강화 +8',
'명속성 강화 +12',
'명속성 강화 +16',
'명속성 강화 +20',
'암속성 강화 +8',
'암속성 강화 +12',
'암속성 강화 +16',
'암속성 강화 +20',
'모든 속도 +3%',
'모든 속도 +6%',
'모든 속도 +9%',
'모든 속도 +12%',
'모든 속성 저항 +8',
'모든 속성 저항 +12',
'모든 속성 저항 +16',
'모든 속성 저항 +20',
'스킬 범위 8% 증가',
'스킬 범위 12% 증가',
'스킬 범위 16% 증가',
'스킬 범위 20% 증가',
'버프력 750',
'버프력 900',
'버프력 1050',
'버프력 1200'
]



let setItemList =[
    //상의
    [
        "지휘관의 명주 상의",
        "총사령관의 제복 상의",
        "미지의 근섬유 상의",
        "순례자의 해진 바람 막이"
    ],
    //하의
    [
        "전략가의 정교한 전투 바지",
        "총사령관의 제복 하의",
        "미지의 금속 각반",
        "순례자의 카고 바지"
    ],
    //어깨
    [
        "연합의 지휘자 망토",
        "총사령관의 의무",
        "미지의 보석 숄더",
        "순례자의 어깨 보호대"
    ],
    //벨트
    [
        "신뢰의 황금 복대",
        "총사령관의 지휘",
        "미지의 기계 장치 벨트",
        "순례자의 나무 요대"
    ],
    //신발
    [
        "결의의 전쟁 부츠",
        "총사령관의 결단",
        "미지의 관절 신발",
        "순례자의 사막 부츠"
    ],
    //팔찌
    [
        "무한한 영감",
        "총사령관의 섬멸 작전",
        "미지의 비늘 암릿",
        "순례자를 짓누르는 무게" 
    ],

    //목걸이
    [
        "포지 코어 펜던트",
        "총사령관의 비장의 한발",
        "미지의 눈동자 네클리스",
        "순례자를 얽매는 고난"

    ],
    //반지
    [
        "조화의 이치",
        "총사령관의 초심",
        "미지의 골격 링",
        "순례자를 억압하는 시련"
    ],
    //보장
    [
        "무회",
        "총사령관의 전술 지도",
        "미지의 에너지 스피어",
        "순례자의 미래를 보는 시선"
    ],
    //마법석
    [
        "무결한 마음",
        "총사령관의 독도법 나침반",
        "미지의 바이탈 시그널",
        "순례자의 길을 비추는 빛"

    ],
    //귀걸이
    [
        "흔적이 새겨진 돌",
        "총사령관의 전우가 남긴 유품",
        "미지의 파워 사이드",
        "순례자의 나아가는 의지" 
    ]


];

const names = [
	'불가침의 영역 - 상의', "숲속의 마녀 상의", "블루 베릴 아머",  "엔트 정령의 상의", '딥 다이버 슈트', '블루 파이렛 블라우스',  "아머드 파워 상의", "죽음에 잠식된 갑주", "플래티넘 클래스 재킷", "리버시블 레더 코트", "탐험가의 노련한 코트", "쉐도우블랙 슈트", "어릴적 꿈꿔온 무대", "흙으로 빚은 자연", "천지를 흔드는 석갑",
	"천상을 수호하는 윙 아머", "하이테크 전술지휘 아머", "미련이 남은 녹슨 원혼", "격동하는 마음",  "천재 기술자의 전문 작업복 상의", "드래곤 슬레이어", "얼티밋 제너레이터", "어비스 리액터",
	"옥화의 망령 흉갑", "고귀한 신의", '컨퓨즈드 코어 슈트', '냉정한 지략가의 상의',
	'희생으로 맞이한 여명', '무너진 세계의 틈', '상상을 담은 엔지니어 블라우스','안개의 뜻을 따르는 자의 상의',
	'브레이브 나이트 아머','계곡을 감시하는 주시','근위대의 맹세 갑주',
	
	'불가침의 영역 - 어깨', "숲속의 마녀 망토",  "블루 베릴 보호대", "엔트 정령의 어깨", '딥 다이버 에어팩', '블루 파이렛 숄더', "데저트 컨실멘트 숄더", "끝을 바라보는 시선", "자신감의 백색 망토", "마그네틱 서치 스캔", "근력 보강 숄더", "블랙 캣 헬멧", "화려한 청색의 음율",
	"피어나는 자연의 삶", "오염된 빙석 견갑", "자유를 수호하는 윙 숄더", "하이테크 바디 프로텍트 숄더", "주체할 수 없는 낡은 규칙", "빛을 잃은 진실",  "천재 기술자의 보호 마스크", "드래곤 헌터",
	"헥타곤 임펄스 브릭", "매니퓰레이션", "옥화의 망령 상박갑", "맹렬한 위세", '메탈기어 암 숄더', '유쾌함 속의 열망',
	'아픔을 감싸는 위엄', '손에 닿은 기억', '상상이 실현된 자유','잠식된 신수의 그릇된 망토',
	'페이션트 나이트 숄더','청연을 수호하는 의지','근위대의 맹세 상박갑',
	
	'불가침의 영역 - 하의',"숲속의 마녀 하의", "블루 베릴 하의",  "엔트 정령의 하의", '딥 다이버 팬츠', '블루 파이렛 팬츠'  ,"데저트 테크놀로지 팬츠", "혼돈을 두른 장막", "네오 화이트 클래스 팬츠", "라이트 어댑터 팬츠", "지치지 않는 여정의 하의",
	"벤타블랙 팬츠", "무대의 화려함", "숨쉬는 자연의 생명", "압도하는 힘의 하갑", "평화를 수호하는 윙 레깅스", "하이테크 전술보조 각반", "깨지지 않는 빛바랜 신념", "침식되는 이성", 
	"천재 기술자의 멀티박스 팬츠", "드래곤 라이더", "언리밋 사이버네틱", "스톰라이더", "옥화의 망령 대퇴갑", "굳건한 믿음", '로보티카 컴뱃 팬츠', '승리로 이끄는 정의',
	'악몽을 이겨낸 승전보', '풍운과 안개를 걷는 빛','상상을 담은 엔지니어 팬츠','안개의 뜻을 따르는 자의 하의',
	'포이즈드 나이트 팬츠','계곡을 감시하는 활보','근위대의 맹세 하갑',
	
	'불가침의 영역 - 신발' ,"숲속의 마녀 신발", "블루 베릴 부츠", "엔트 정령의 신발", '딥 다이버 슈즈' ,'블루 파이렛 부츠'  , "HEM 리인포스 부츠", "절망의 발소리", "신비함의 백색 구두",
	"일렉트릭 프루프 부츠", "대지를 딛는 부츠", "검은 발자국", "소망을 전하는 편지", "순환하는 자연의 섭리", "어둠에 삼켜진 돌굽", "하늘을 수호하는 윙 부츠", "하이테크 고기동 강화 부츠", "움직이는 쇠약한 집착", "폭주하는 육신",
	"천재 기술자의 두터운 보호부츠", "드래곤 패스파인더", "스팀펑크 소닉 디스럽터", "엑셀러레이터", "옥화의 망령 각갑", "내딛는 용기", '사이버틱 스피드 부츠', '소망을 향한 움직임',
	'과거를 뒤로 한 전진', '경계를 넘어선 차원','상상을 담은 엔지니어의 작업 신발', '안개의 뜻을 따르는 자의 신발',
	'패셔네이트 나이트 부츠','약속을 지키는 발걸음','근위대의 맹세 각갑',
	
	'불가침의 영역 - 벨트',"숲속의 마녀 벨트",  "블루 베릴 벨트", "엔트 정령의 벨트", '딥 다이버 벨트','블루 파이렛 벨트', "익스펜션 서플라이 벨트",
	"옭아매는 공포", "고고함의 백색 벨트", "썬더 레지스트 벨트", "별을 담는 벨트", "가이드 라인 벨트", "음율에 담은 소망", "순수한 자연의 이치", "불변의 부유석 벨트", "대지를 수호하는 윙 벨트", "하이테크 서플라이 벨트",
	"마주할 수 없는 부러진 긍지", "파괴된 신념", "천재 기술자의 멀티툴 벨트", "드래곤 스케빈저", "얼터레이션 다이얼 벨트", "파워 플랜트", "옥화의 망령 요갑", "마땅한 본분",'쿨링 서포트 벨트',
	'영면을 위한 준비','상처를 동여맨 다짐' , '기억으로 새겨진 별자리', '상상을 담은 엔지니어의 작업 벨트','안개를 탐구하는 자의 벨트',
	'디보티드 나이트 벨트','약속을 다짐하는 벨트','근위대의 맹세 요갑'

];

const accNames = [ 
	'불가침의 영역 - 목걸이',"숲속의 마녀 목걸이","블루 베릴 네클레스", "엔트 정령의 목걸이",'딥 다이버 네클레스', '블루 파이렛 네클레스', "냉혹한 현실의 목걸이", "머신 컨트롤러 리모트", "디젯 퓨즈 초크", "고양된 분노의 목걸이", "잠겨진 영역", "기사의 속죄",  "약동하는 생명의 고동",
	"푸른 자연의 씨앗", "절대감각의 방위구", "임펄스 트리거", "잔잔한 선율", "검은 별", "솔저 호너 네클리스", "퍼스트 스페이드 - 노블레스", "수호룡의 비호 - 용기", "골렘의 심장 목걸이", "기품의 금빛 장신구",
	"테크놀로지 바디캠 네클리스", "죄어오는 풍화된 악의" , "아토믹 코어 네클레스" , "올곧은 자의 이념" ,  "폭룡왕의 지배 - 공포", "운명이 이끄는 방향",
	'기계 공학의 심장 목걸이','안개를 탐구하는 자의 목걸이','적을 향한 신중','기록자의 목걸이','근위대의 맹세 목 보호대',
	
	'불가침의 영역 - 팔찌',"숲속의 마녀 팔찌","블루 베릴 암릿", "엔트 정령의 팔찌",'딥 다이버 워치','블루 파이렛 암릿', "흑화의 구속 팔찌", "리플레이서", "아크 블라스터 소스", "억제된 마력의 팔찌", "수확하는 옥수", "기사의 구원",
	"생명이 담긴 가죽 토시", "작은 풀잎의 순수함", "이온화조정 팔찌", "미니어쳐 헤드셋 암릿", "화음하는 음색", "포인트 레이더 암릿", "구속된 자유", "세컨드 스페이드 - 어쏘러티", "수호룡의 비호 - 자비",
	"골렘의 핵 팔찌", "찬란한 금장 팔찌", "전술 드론 콘트롤러 암릿", "무뎌지는 둔화된 변화" , "파워 네비게이트 팩" , "오랜 시간 품어온 소원" , "폭룡왕의 지배 - 무력", "찰나를 스치는 흐름",
	'시공을 담은 팔찌','그릇된 힘에 잠식된 팔찌','새겨지는 맹세','전령에 깃든 축복','근위대의 맹세 수갑',

	'불가침의 영역 - 반지',"숲속의 마녀 반지", "블루 베릴 링", "엔트 정령의 반지",'딥 다이버 네비게이션 링','블루 파이렛 링', "핏빛의 결정 반지","원터치 스마트 리모콘", "오버커런트 마그넷 링", "멈추지 않는 운명",
	"이동하는 요새", "기사의 긍지", "빛을 발하는 생명", "개화하는 신비의 꽃", "자기장 탐지링", "어댑터블 투톤 링", "반짝이는 음율", "레드 라이프 링", "고통의 상처",
	"서드 스페이드 - 데스", "수호룡의 비호 - 축복", "골렘의 중추석 반지", "골드 윙 반지", "전술 레이더망 링", "에너지 서치 링" , "뜨거운 열망의 증표", "뚜렷해지는 소멸된 사념" , "폭룡왕의 지배 - 압제", "세계가 담긴 축",
	'밤을 바라보는 공학자의 시선','안개를 탐구하는 자의 반지','압도하는 용맹','파수꾼의 증표','근위대의 맹세 증표'

	];


const speNames = [
	'불가침의 영역 - 보조장비',"숲속의 마녀 바구니", "블루 베릴 퍼퓸", "엔트 정령의 성배",'딥 다이버 오리발','블루 파이렛 프로텍터', "내면의 얼굴","터치 컨트롤 패널", "데카 가이던스 디바이스", "죽음을 부르는 관", "불길한 데칼코마니 석판", "여명의 성배", "생명의 근원이 담긴 배낭",
	"찰랑이는 생명수", "굴착 강화 파츠", "홀로그램 콜", "꿈같은 환호성", "버츄얼 사이트 글래스", "파괴된 생명", "올 오어 원 매직박스", "용살자의 증표 - 용골 뿔피리", "미지의 문명 - 마스크 스톤", "정의의 기사 가면",
	"공중형 : 전술 프롭 드론", "드러나는 흐릿한 지식",'옵티컬 컴뱃 글래스',
	'무모하지만 합리적인 작전','생명이 담긴 잔','멈추어진 지식','샤를의 가면','잠식된 신수의 비통한 면사포','전진하는 기세','청연을 지키는 힘','근위대의 맹세 지령서',

	
	'불가침의 영역 - 마법석',"숲속의 마녀 정령석", "블루 베릴 젬스톤", "엔트 정령의 심장",'딥 다이버 마스크', '블루 파이렛 쥬얼',"피어오르는 광기","미니 배터리 팩", "도데카 홀로그램", "어둠을 먹는 심장", "억류된 혼의 령주", "자정의 성역",
	"생명이 깃든 초록빛 액체", "자연에 녹아드는 이슬", "미지의 황금비석", "잿빛의 묘상석", "두근거리는 열정", "언비튼 메달", "저주받은 마음", "거짓 속의 거짓", "용살자의 증표 - 용심 가공석",
	"미지의 문명 - 스타 스톤", "평화를 위한 투쟁", "지상형 : 전술 차륜 드론", "선회하는 흐려진 혜안",'완성형 동력 제어장치',
	'승리가 약속된 시간','숨결을 삼킨 용옥','잊혀진 기록','기계 공학의 정수','잠식된 고원의 정수','수호하는 인내','기록자의 보석','근위대의 맹세 등불',
	
	'불가침의 영역 - 귀걸이',"숲속의 마녀 귀걸이", "블루 베릴 이어링", "엔트 정령의 귀걸이",'딥 다이버 이어링', '블루 파이렛 이어링', "저주받은 굴레", "부스팅 펄스 튜브", "엔데카 코어 칩", "폭풍을 삼킨 에너지",
	"배회하는 혼령의 향로", "황혼의 성단",  "생명을 키우는 코발트 스틱", "청명한 아침의 새싹", "인력의 법칙", "아크로매틱 룸버스", "마음을 전달하는 소리", "오퍼레이션 오더", "타락한 영혼",
	"시각의 관점", "용살자의 증표 - 용린 귀걸이", "미지의 문명 - 트윈 스톤", "하늘에 휘날리는 깃털", "폭발형 : 소형 전술 플레어", "들이치는 희미한 탄식",'초소형 GPS',
	'영원을 끝낼 오랜 염원','눈물이 스민 보석','아득한 각인','시간 공학의 시간','잠식된 신수의 눈물','포기하지 않는 용기','계곡을 감시하는 표식','근위대의 맹세 충심'
	];


	let talismanList = {
		'네메시스' : ['카펠라의 성흔 : 리인포스 베리어','리인포스 베리어','온전한 탈리스만[블랙 미러]','성흔의 탈리스만[블랙 미러]','카펠라의 성흔 : 쉐도우 템페스트','쉐도우 템페스트','온전한 탈리스만[데들리 케이프]','성흔의 탈리스만[데들리 케이프]','카펠라의 성흔 : 익스터미네이트','익스터미네이트','온전한 탈리스만[디스트로이어]','성흔의 탈리스만[디스트로이어]','카펠라의 성흔 : 쉐도우 스웜','쉐도우 스웜','온전한 탈리스만[스타폴]','성흔의 탈리스만[스타폴]','카펠라의 성흔 : 오퍼드 세크리파이스','오퍼드 세크리파이스','온전한 탈리스만[이몰레이션]','성흔의 탈리스만[이몰레이션]','카펠라의 성흔 : 폴리그라마 레이','성흔의 탈리스만[데우스 엑스 레이]','카펠라의 성흔 : 위대한 성전의 검','성흔의 탈리스만[지하드]'],
		'디어사이드' : ['카펠라의 성흔 : 광란의 여왕','광란의 여왕','온전한 탈리스만[혈화난무]','성흔의 탈리스만[혈화난무]','카펠라의 성흔 : 마인의 군대','마인의 군대','온전한 탈리스만[혈지군무]','성흔의 탈리스만[혈지군무]','카펠라의 성흔 : 콜 다이무스','콜 다이무스','온전한 탈리스만[검마격살]','성흔의 탈리스만[검마격살]','카펠라의 성흔 : 마인 감옥','마인 감옥','온전한 탈리스만[역천의 프놈]','성흔의 탈리스만[역천의 프놈]','카펠라의 성흔 : 혈마연격','혈마연격','온전한 탈리스만[혈마검]','성흔의 탈리스만[혈마검]','카펠라의 성흔 : 메가 슬래쉬','성흔의 탈리스만[암연격 : 기가슬래쉬]','카펠라의 성흔 : 처형외도 : 연계','성흔의 탈리스만[비인외도 : 극]'],
		'검제' : ['카펠라의 성흔 : 일화통암','일화통암','온전한 탈리스만[난화검]','성흔의 탈리스만[난화검]','카펠라의 성흔 : 파적공','파적공','온전한 탈리스만[폭멸장]','성흔의 탈리스만[폭멸장]','카펠라의 성흔 : 만리일섬','만리일섬','온전한 탈리스만[혜성만리]','성흔의 탈리스만[혜성만리]','카펠라의 성흔 : 일몰여평섬','일몰여평섬','온전한 탈리스만[십자검]','성흔의 탈리스만[십자검]','카펠라의 성흔 : 무형장','무형장','온전한 탈리스만[백보신장]','성흔의 탈리스만[백보신장]','카펠라의 성흔 : 무비일검','성흔의 탈리스만[연화검무]','카펠라의 성흔 : 이형환위섬','성흔의 탈리스만[연화섬]'],
		'마제스티' : ['카펠라의 성흔 : 문플래쉬','문플래쉬','온전한 탈리스만[반월]','성흔의 탈리스만[반월]','카펠라의 성흔 : 폭축집중','폭축집중','온전한 탈리스만[폭명기검]','성흔의 탈리스만[폭명기검]','카펠라의 성흔 : 데빌슬레이어','데빌슬레이어','온전한 탈리스만[악즉참]','성흔의 탈리스만[악즉참]','카펠라의 성흔 : 피어싱 블레이드','피어싱 블레이드','온전한 탈리스만[용성락]','성흔의 탈리스만[용성락]','카펠라의 성흔 : 엑스트라 익스플로젼','엑스트라 익스플로젼','온전한 탈리스만[참마검]','성흔의 탈리스만[참마검]','카펠라의 성흔 : 블레이드 템페스트','성흔의 탈리스만[마검방출]','카펠라의 성흔 : 올 포 원','성흔의 탈리스만[극 귀검술 : 파검무]'],
		'가이아' : ['카펠라의 성흔 : 뱅가드 스플래쉬','뱅가드 스플래쉬','온전한 탈리스만[뱅가드 스트랏슈]','성흔의 탈리스만[뱅가드 스트랏슈]','카펠라의 성흔 : 자연의 바람','자연의 바람','온전한 탈리스만[런지 에볼루션]','성흔의 탈리스만[런지 에볼루션]','카펠라의 성흔 : 뉴트럴 헌팅','뉴트럴 헌팅','온전한 탈리스만[분쇄]','성흔의 탈리스만[분쇄]','카펠라의 성흔 : 생명의 온기','생명의 온기','온전한 탈리스만[생명의 전조]','성흔의 탈리스만[생명의 전조]','카펠라의 성흔 : 에이션트 유니언','에이션트 유니언','온전한 탈리스만[신뢰의 돌파]','성흔의 탈리스만[신뢰의 돌파]','카펠라의 성흔 : 위드 투 시드','성흔의 탈리스만[체인 스트라이크]','카펠라의 성흔 : 앵거 오브 카멜리나','성흔의 탈리스만[자연의 분노]'],
		'갓파더' : ['카펠라의 성흔 : 시크릿 서비스','시크릿 서비스','온전한 탈리스만[알파 스트라이크]','성흔의 탈리스만[알파 스트라이크]','카펠라의 성흔 : 광풍노도','광풍노도','온전한 탈리스만[휩쓸기]','성흔의 탈리스만[휩쓸기]','카펠라의 성흔 : 황혼의 검사','황혼의 검사','온전한 탈리스만[최후의 일격]','성흔의 탈리스만[최후의 일격]','카펠라의 성흔 : 와일드 헌트','와일드 헌트','온전한 탈리스만[포인트 블랭크]','성흔의 탈리스만[포인트 블랭크]','카펠라의 성흔 : 천벌','천벌','온전한 탈리스만[헬릭스 다이브]','성흔의 탈리스만[헬릭스 다이브]','카펠라의 성흔 : 리트릿 파이어','성흔의 탈리스만[택티컬 오펜시브]','카펠라의 성흔 : 롤랑 스페셜','성흔의 탈리스만[벽파일섬]'],
		'검신' : ['카펠라의 성흔 : 명가의 손놀림','명가의 손놀림','온전한 탈리스만[발도]','성흔의 탈리스만[발도]','카펠라의 성흔 : 맹룡선풍참','맹룡선풍참','온전한 탈리스만[맹룡단공참]','성흔의 탈리스만[맹룡단공참]','카펠라의 성흔 : 그림자 유희','그림자 유희','온전한 탈리스만[환영검무]','성흔의 탈리스만[환영검무]','카펠라의 성흔 : 유성의 심판','유성의 심판','온전한 탈리스만[극 귀검술 : 유성락]','성흔의 탈리스만[극 귀검술 : 유성락]','카펠라의 성흔 : 쾌속발도','쾌속발도','온전한 탈리스만[극초발도]','성흔의 탈리스만[극초발도]','카펠라의 성흔 : 일합잔상','성흔의 탈리스만[극 귀검술 : 심검]','카펠라의 성흔 : 최후일각','성흔의 탈리스만[극 발검술 : 섬단]'],
		'그랜드 마스터' : ['카펠라의 성흔 : 뇌천파쇄','뇌천파쇄','온전한 탈리스만[매그넘설트]','성흔의 탈리스만[매그넘설트]','카펠라의 성흔 : 사이클론 스파이크','사이클론 스파이크','온전한 탈리스만[롤링 스파이크]','성흔의 탈리스만[롤링 스파이크]','카펠라의 성흔 : 와일드 크러셔','와일드 크러셔','온전한 탈리스만[와일드 캐넌 스파이크]','성흔의 탈리스만[와일드 캐넌 스파이크]','카펠라의 성흔 : 콤비네이션 러쉬','콤비네이션 러쉬','온전한 탈리스만[피어스]','성흔의 탈리스만[피어스]','카펠라의 성흔 : 강맹격파','강맹격파','온전한 탈리스만[한놈만 팬다!!]','성흔의 탈리스만[한놈만 팬다!!]','카펠라의 성흔 : 완전제압','성흔의 탈리스만[흑진광풍]','카펠라의 성흔 : 페이스 투 페이스','성흔의 탈리스만[오의 : 질풍노도]'],
		'그림리퍼' : ['카펠라의 성흔 : 칼날폭풍','칼날폭풍','온전한 탈리스만[칼날돌풍]','성흔의 탈리스만[칼날돌풍]','카펠라의 성흔 : 춤추는 그림자','춤추는 그림자','온전한 탈리스만[파이널 디스트럭션]','성흔의 탈리스만[파이널 디스트럭션]','카펠라의 성흔 : 암살의 정석 : 침착','암살의 정석 : 침착','온전한 탈리스만[암살]','성흔의 탈리스만[암살]','카펠라의 성흔 : 퍼펙트 어쌔신','퍼펙트 어쌔신','온전한 탈리스만[단검투척]','성흔의 탈리스만[단검투척]','카펠라의 성흔 : 버스트 스팅','버스트 스팅','온전한 탈리스만[섀도우 스팅]','성흔의 탈리스만[섀도우 스팅]','카펠라의 성흔 : 석세시브 데스','성흔의 탈리스만[죽음의 연무]','카펠라의 성흔 : 섀도우 블리츠','성흔의 탈리스만[페이탈 블리츠]'],
		'다크나이트(자각2)' : ['카펠라의 성흔 : 다크 데몰리션','다크 데몰리션','온전한 탈리스만[다크 폴]','성흔의 탈리스만[다크 폴]','카펠라의 성흔 : 다크 블리스터링 소드','다크 블리스터링 소드','온전한 탈리스만[다크 플레임 소드]','성흔의 탈리스만[다크 플레임 소드]','카펠라의 성흔 : 언리미티드 콤보','언리미티드 콤보','온전한 탈리스만[일루젼 슬래쉬]','성흔의 탈리스만[일루젼 슬래쉬]','카펠라의 성흔 : 스플리트 세컨드','성흔의 탈리스만[팬텀 소드]','카펠라의 성흔 : 다크 리버레이션','다크 리버레이션','온전한 탈리스만[다크 웨이브 폴]','성흔의 탈리스만[다크 웨이브 폴]','카펠라의 성흔 : 모멘트 익스플로전','모멘트 익스플로전','온전한 탈리스만[차지 익스플로전]','성흔의 탈리스만[차지 익스플로전]','카펠라의 성흔 : 디멘션 버스트','성흔의 탈리스만[다크 버스트]'],
		'다크로드' : ['카펠라의 성흔 : 역병아귀','역병아귀','온전한 탈리스만[역병의 라사]','성흔의 탈리스만[역병의 라사]','카펠라의 성흔 : 영혼분쇄','영혼분쇄','온전한 탈리스만[귀참 : 광폭]','성흔의 탈리스만[귀참 : 광폭]','카펠라의 성흔 : 흑염 : 일섬','흑염 : 일섬','온전한 탈리스만[흑염검]','성흔의 탈리스만[흑염검]','카펠라의 성흔 : 파고드는 고통','파고드는 고통','온전한 탈리스만[귀참 : 나락]','성흔의 탈리스만[귀참 : 나락]','카펠라의 성흔 : 백귀야행','백귀야행','온전한 탈리스만[툼스톤 스웜프]','성흔의 탈리스만[툼스톤 스웜프]','카펠라의 성흔 : 망령의 진격','성흔의 탈리스만[블레이드 팬텀]','카펠라의 성흔 : 망령의 송곳니','성흔의 탈리스만[검귀강령 : 식]'],
		'듀란달' : ['카펠라의 성흔 : 미라지 크로스','미라지 크로스','온전한 탈리스만[공아]','성흔의 탈리스만[공아]','카펠라의 성흔 : 더 슈퍼셀','더 슈퍼셀','온전한 탈리스만[메조싸이클론]','성흔의 탈리스만[메조싸이클론]','카펠라의 성흔 : 초뇌격 극혈섬','초뇌격 극혈섬','온전한 탈리스만[뇌격점혈섬]','성흔의 탈리스만[뇌격점혈섬]','카펠라의 성흔 : 레피드 스피어 벌컨','레피드 스피어 벌컨','온전한 탈리스만[롤링 벌컨]','성흔의 탈리스만[롤링 벌컨]','카펠라의 성흔 : 크레센트 문 슬래셔','크레센트 문 슬래셔','온전한 탈리스만[삼일참월]','성흔의 탈리스만[삼일참월]','카펠라의 성흔 : 미라지 디스로케이션','성흔의 탈리스만[쿼드러플 드라이브]','카펠라의 성흔 : 서든 프로미넌스','성흔의 탈리스만[미스트 플레어]'],
		'드레드노트' : ['카펠라의 성흔 : 콤비네이션 너클','콤비네이션 너클','온전한 탈리스만[드래곤 스매쉬]','성흔의 탈리스만[드래곤 스매쉬]','카펠라의 성흔 : 용제비무 : 천뢰','용제비무 : 천뢰','온전한 탈리스만[태도 용제무쌍]','성흔의 탈리스만[태도 용제무쌍]','카펠라의 성흔 : 드래곤 테이머','드래곤 테이머','온전한 탈리스만[폭풍의 숨결]','성흔의 탈리스만[폭풍의 숨결]','카펠라의 성흔 : 용악쇄','용악쇄','온전한 탈리스만[용왕파천]','성흔의 탈리스만[용왕파천]','카펠라의 성흔 : 굉룡패천격','굉룡패천격','온전한 탈리스만[비룡천상]','성흔의 탈리스만[비룡천상]','카펠라의 성흔 : 초토화','성흔의 탈리스만[미티어 버스트]','카펠라의 성흔 : 천위강룡제','성흔의 탈리스만[용제출진]'],
		'디스트로이어' : ['카펠라의 성흔 : 디 오버워크','디 오버워크','온전한 탈리스만[FM-31 그레네이드 런처]','성흔의 탈리스만[FM-31 그레네이드 런처]','카펠라의 성흔 : 콜 더 퀀텀 스톰','콜 더 퀀텀 스톰','온전한 탈리스만[양자 폭탄]','성흔의 탈리스만[양자 폭탄]','카펠라의 성흔 : 더 서클 아웃버스트','더 서클 아웃버스트','온전한 탈리스만[X-1 익스트루더]','성흔의 탈리스만[X-1 익스트루더]','카펠라의 성흔 : 에드 온 레이저','에드 온 레이저','온전한 탈리스만[플라즈마 부스터]','성흔의 탈리스만[플라즈마 부스터]','카펠라의 성흔 : 스커드 익스플로젼','스커드 익스플로젼','온전한 탈리스만[FM-92 스팅어 SW]','성흔의 탈리스만[FM-92 스팅어 SW]','카펠라의 성흔 : 어스퀘이크 크러쉬','성흔의 탈리스만[사이즈믹 웨이브]','카펠라의 성흔 : 에어 버스트-7','성흔의 탈리스만[MSC-7]'],
		'레이븐' : ['카펠라의 성흔 : 엑셀레이션 리볼버','엑셀레이션 리볼버','온전한 탈리스만[난사]','성흔의 탈리스만[난사]','카펠라의 성흔 : 와이드 아웃','와이드 아웃','온전한 탈리스만[멀티 헤드샷]','성흔의 탈리스만[멀티 헤드샷]','카펠라의 성흔 : 더블 그라인더','더블 그라인더','온전한 탈리스만[더블 건호크]','성흔의 탈리스만[더블 건호크]','카펠라의 성흔 : 먹이사냥','먹이사냥','온전한 탈리스만[데들리 어프로치]','성흔의 탈리스만[데들리 어프로치]','카펠라의 성흔 : 매거진 핸들링','매거진 핸들링','온전한 탈리스만[제압 사격]','성흔의 탈리스만[제압 사격]','카펠라의 성흔 : 듀얼 리액션','성흔의 탈리스만[패스트 드로우]','카펠라의 성흔 : 불릿 스톰','성흔의 탈리스만[와이프 아웃]'],
		'레퀴엠' : ['카펠라의 성흔 : 잠행의 정수','잠행의 정수','온전한 탈리스만[암월비보]','성흔의 탈리스만[암월비보]','카펠라의 성흔 : 임무 - 목표 말살','임무 - 목표 말살','온전한 탈리스만[목표 사격]','성흔의 탈리스만[목표 사격]','카펠라의 성흔 : 블레이드 스웜프','블레이드 스웜프','온전한 탈리스만[블레이드 팰컨]','성흔의 탈리스만[블레이드 팰컨]','카펠라의 성흔 : 엑스트라 듀티','엑스트라 듀티','온전한 탈리스만[목표 포착]','성흔의 탈리스만[목표 포착]','카펠라의 성흔 : 슈퍼문 페이즈 쉬프트','슈퍼문 페이즈 쉬프트','온전한 탈리스만[문페이즈]','성흔의 탈리스만[문페이즈]','카펠라의 성흔 : 죽음의 올가미','성흔의 탈리스만[월광진혼곡]','카펠라의 성흔 : 임무 - 인멸','성흔의 탈리스만[섬멸]'],
		'리디머' : ['카펠라의 성흔 : 엔드리스 그리드','엔드리스 그리드','온전한 탈리스만[그리드 더 러커]','성흔의 탈리스만[그리드 더 러커]','카펠라의 성흔 : 어나이어레이터','어나이어레이터','온전한 탈리스만[사이드 매서커]','성흔의 탈리스만[사이드 매서커]','카펠라의 성흔 : 컨트롤 더 라스','컨트롤 더 라스','온전한 탈리스만[스커드 더 라스]','성흔의 탈리스만[스커드 더 라스]','카펠라의 성흔 : 패시네이션','패시네이션','온전한 탈리스만[엔비어스 키스]','성흔의 탈리스만[엔비어스 키스]','카펠라의 성흔 : 크루얼 인테이크','크루얼 인테이크','온전한 탈리스만[글러트니 바밋]','성흔의 탈리스만[글러트니 바밋]','카펠라의 성흔 : 익스트리미스트','성흔의 탈리스만[세컨드 소울]','카펠라의 성흔 : 컨퓨즈 티어','성흔의 탈리스만[서드 립]'],
		'마신' : ['카펠라의 성흔 : 더 블랙 볼트','더 블랙 볼트','온전한 탈리스만[천공검]','성흔의 탈리스만[천공검]','카펠라의 성흔 : 트레직 로켓','트레직 로켓','온전한 탈리스만[제노사이드 크러쉬]','성흔의 탈리스만[제노사이드 크러쉬]','카펠라의 성흔 : 서든 피어','서든 피어','온전한 탈리스만[파이널 어택]','성흔의 탈리스만[파이널 어택]','카펠라의 성흔 : 인투 더 헬','인투 더 헬','온전한 탈리스만[검은 충격]','성흔의 탈리스만[검은 충격]','카펠라의 성흔 : 나이트메어','나이트메어','온전한 탈리스만[미드나잇 카니발]','성흔의 탈리스만[미드나잇 카니발]','카펠라의 성흔 : 레인보우 판타지아','성흔의 탈리스만[매시브 파이어]','카펠라의 성흔 : 엔들리스 워','성흔의 탈리스만[대난투]'],
		'명왕' : ['카펠라의 성흔 : 백스트리트 태클','백스트리트 태클','온전한 탈리스만[그라운드 태클]','성흔의 탈리스만[그라운드 태클]','카펠라의 성흔 : 베놈 익스플로전','베놈 익스플로전','온전한 탈리스만[베놈 마인]','성흔의 탈리스만[베놈 마인]','카펠라의 성흔 : 와일리 트릭', '와일리 트릭', '온전한 탈리스만[비셔스 래리어트]', '성흔의 탈리스만[비셔스 래리어트]','카펠라의 성흔 : 킥 애스','킥 애스','온전한 탈리스만[더티 배럴]','성흔의 탈리스만[더티 배럴]','카펠라의 성흔 : 스카페이스','스카페이스','온전한 탈리스만[광폭혈쇄]','성흔의 탈리스만[광폭혈쇄]','카펠라의 성흔 : 버닝 드라이브','성흔의 탈리스만[체인 드라이브]','카펠라의 성흔 : 아발란체','성흔의 탈리스만[만천화우]'],
		'뱀파이어 로드' : ['카펠라의 성흔 : 블러드 필드','블러드 필드','온전한 탈리스만[토먼트]','성흔의 탈리스만[토먼트]','카펠라의 성흔 : 블러드 소울 번','블러드 소울 번','온전한 탈리스만[소울 스틸]','성흔의 탈리스만[소울 스틸]','카펠라의 성흔 : 커럽션 오브 다크블러드','커럽션 오브 다크블러드','온전한 탈리스만[커럽션]','성흔의 탈리스만[커럽션]','카펠라의 성흔 : 스웜프 오브 헬독','스웜프 오브 헬독','온전한 탈리스만[럼블독]','성흔의 탈리스만[럼블독]','카펠라의 성흔 : 블러드 둠','블러드 둠','온전한 탈리스만[머로더]','성흔의 탈리스만[머로더]','카펠라의 성흔 : 헬 하운드','성흔의 탈리스만[팬텀 베일]','카펠라의 성흔 : 페이탈 레이크','성흔의 탈리스만[페이탈리티]'],
		'벤데타' : ['카펠라의 성흔 : 슈터 칵테일','슈터 칵테일','온전한 탈리스만[셰이커 블래스트]','성흔의 탈리스만[셰이커 블래스트]','카펠라의 성흔 : 콜 오브 나이트','콜 오브 나이트','온전한 탈리스만[나이트폴]','성흔의 탈리스만[나이트폴]','카펠라의 성흔 : 크레센도','크레센도','온전한 탈리스만[라스트 오더]','성흔의 탈리스만[라스트 오더]','카펠라의 성흔 : 블랙잭','블랙잭','온전한 탈리스만[오버페이스]','성흔의 탈리스만[오버페이스]','카펠라의 성흔 : 패스트 트래커','패스트 트래커','온전한 탈리스만[불스아이]','성흔의 탈리스만[불스아이]','카펠라의 성흔 : 오버 더 락','성흔의 탈리스만[스트레이트 샷]','카펠라의 성흔 : 퍼들','성흔의 탈리스만[카타르시스]'],
		'블러드 이블' : ['카펠라의 성흔 : 광전사의 분노','광전사의 분노','온전한 탈리스만[격노]','성흔의 탈리스만[격노]','카펠라의 성흔 : 멈추지 않는 갈증','멈추지 않는 갈증','온전한 탈리스만[블러드 소드]','성흔의 탈리스만[블러드 소드]','카펠라의 성흔 : 블러드 퀘이크','블러드 퀘이크','온전한 탈리스만[아웃레이지 브레이크]','성흔의 탈리스만[아웃레이지 브레이크]','카펠라의 성흔 : 포스 오브 블러드','포스 오브 블러드','온전한 탈리스만[블러드 스내치]','성흔의 탈리스만[블러드 스내치]','카펠라의 성흔 : 인세인 브루탈리티','인세인 브루탈리티','온전한 탈리스만[브루탈 크러셔]','성흔의 탈리스만[브루탈 크러셔]','카펠라의 성흔 : 끓어오르는 피의 욕망','성흔의 탈리스만[블러드 붐]','카펠라의 성흔 : 피의 축제','성흔의 탈리스만[페이탈 블러드]'],
		'세라핌' : ['카펠라의 성흔 : 천상에 울려퍼지는 아리아','천상에 울려퍼지는 아리아','온전한 탈리스만[용맹의 아리아]','성흔의 탈리스만[용맹의 아리아]','카펠라의 성흔 : 미라클 오브 썬더','미라클 오브 썬더','온전한 탈리스만[정화의 번개]','성흔의 탈리스만[정화의 번개]','카펠라의 성흔 : 그랜드 세인트 크로스','그랜드 세인트 크로스','온전한 탈리스만[그랜드 크로스 크래쉬]','성흔의 탈리스만[그랜드 크로스 크래쉬]','카펠라의 성흔 : 찬란한 빛의 구원자','찬란한 빛의 구원자','온전한 탈리스만[리펜텐스 스매시]','성흔의 탈리스만[리펜텐스 스매시]','카펠라의 성흔 : 천상의 활력소','천상의 활력소','온전한 탈리스만[대천사의 축복]','성흔의 탈리스만[대천사의 축복]','카펠라의 성흔 : 우리엘의 가호','성흔의 탈리스만[우리엘의 결계]','카펠라의 성흔 : 미카엘의 성광구','성흔의 탈리스만[미라클 샤인]'],
		'세이비어' : ['카펠라의 성흔 : 죄의 추적자','죄의 추적자','온전한 탈리스만[컨페션]','성흔의 탈리스만[컨페션]','카펠라의 성흔 : 고해성사','고해성사','온전한 탈리스만[이스트레인지먼트]','성흔의 탈리스만[이스트레인지먼트]','카펠라의 성흔 : 가중처벌','가중처벌','온전한 탈리스만[컨실리에이션]','성흔의 탈리스만[컨실리에이션]','카펠라의 성흔 : 제압의 빛','제압의 빛','온전한 탈리스만[인테그레이션]','성흔의 탈리스만[인테그레이션]','카펠라의 성흔 : 천상의 심판','천상의 심판','온전한 탈리스만[멘데이트 오브 헤븐]','성흔의 탈리스만[멘데이트 오브 헤븐]','카펠라의 성흔 : 회개의 빛무리','성흔의 탈리스만[옵스트럭션]','카펠라의 성흔 : 빛의 해방자','성흔의 탈리스만[인게이지먼트]'],
		'세인트' : ['카펠라의 성흔 : 앱솔루트 배리어','앱솔루트 배리어','온전한 탈리스만[플래시 글로브]','성흔의 탈리스만[플래시 글로브]','카펠라의 성흔 : 라이프 오브 배슬','라이프 오브 배슬','온전한 탈리스만[참회의 망치]','성흔의 탈리스만[참회의 망치]','카펠라의 성흔 : 미카엘라의 심판','미카엘라의 심판','온전한 탈리스만[정의의 심판]','성흔의 탈리스만[정의의 심판]','카펠라의 성흔 : 기적의 침례자','기적의 침례자','온전한 탈리스만[미러클 스플리터]','성흔의 탈리스만[미러클 스플리터]','카펠라의 성흔 : 찬란한 빛의 조력자','찬란한 빛의 조력자','온전한 탈리스만[운명의 창]','성흔의 탈리스만[운명의 창]','카펠라의 성흔 : 생츄어리 오브 헤븐','성흔의 탈리스만[홀리 생츄어리]','카펠라의 성흔 : 디바인 프로텍트','성흔의 탈리스만[디바인 플래쉬]'],
		'셀레스티얼' : ['카펠라의 성흔 : 스테빌라이저','스테빌라이저','온전한 탈리스만[기어 드라이브]','성흔의 탈리스만[기어 드라이브]','카펠라의 성흔 : 스펙터클 스콜','스펙터클 스콜','온전한 탈리스만[안개폭우]','성흔의 탈리스만[안개폭우]','카펠라의 성흔 : 신무변조','신무변조','온전한 탈리스만[신무병장 유성]','성흔의 탈리스만[신무병장 유성]','카펠라의 성흔 : 엑스트라 옵션','엑스트라 옵션','온전한 탈리스만[스크램블 앵커]','성흔의 탈리스만[스크램블 앵커]','카펠라의 성흔 : 요화맹진','요화맹진','온전한 탈리스만[셋 업 : 신무병장 요수광란]','성흔의 탈리스만[셋 업 : 신무병장 요수광란]','카펠라의 성흔 : 스톰 브레이크','성흔의 탈리스만[헤이즈 스톰]','카펠라의 성흔 : 축연','성흔의 탈리스만[셋 업 : 불꽃놀이]'],
		'스톰 트루퍼' : ['카펠라의 성흔 : M102-CERM 탄약','M102-CERM 탄약','온전한 탈리스만[FM-31 그레네이드 런처]','성흔의 탈리스만[FM-31 그레네이드 런처]','카펠라의 성흔 : 라인 배커','라인 배커','온전한 탈리스만[양자 폭탄]','성흔의 탈리스만[양자 폭탄]','카펠라의 성흔 : X-2.Sub 급속 냉각 파츠','X-2.Sub 급속 냉각 파츠','온전한 탈리스만[X-2 헬리오스]','성흔의 탈리스만[X-2 헬리오스]','카펠라의 성흔 : 고출력 플라즈마','고출력 플라즈마','온전한 탈리스만[플라즈마 부스터]','성흔의 탈리스만[플라즈마 부스터]','카펠라의 성흔 : MIBC유도장치','MIBC유도장치','온전한 탈리스만[FM-92 mk2 랜서 SW]','성흔의 탈리스만[FM-92 mk2 랜서 SW]','카펠라의 성흔 : 라이트웨이트','성흔의 탈리스만[FSC-7]','카펠라의 성흔 : PT-15 테라 모듈','성흔의 탈리스만[PT-15 프로토타입]'],
		'시라누이' : ['카펠라의 성흔 : 업화의 구슬','업화의 구슬','온전한 탈리스만[열화천도]','성흔의 탈리스만[열화천도]','카펠라의 성흔 : 거대 두꺼비의 전설','거대 두꺼비의 전설','온전한 탈리스만[두꺼비유염탄]','성흔의 탈리스만[두꺼비유염탄]','카펠라의 성흔 : 불을 먹고 만개하는 꽃','불을 먹고 만개하는 꽃','온전한 탈리스만[비기 : 염무개화]','성흔의 탈리스만[비기 : 염무개화]','카펠라의 성흔 : 인술 비전 : 신출귀몰','인술 비전 : 신출귀몰','온전한 탈리스만[암기 : 비연수리검]','성흔의 탈리스만[암기 : 비연수리검]','카펠라의 성흔 : 염화 나선 수리검','염화 나선 수리검','온전한 탈리스만[마환 풍마수리검]','성흔의 탈리스만[마환 풍마수리검]','카펠라의 성흔 : 흉사지염옥','성흔의 탈리스만[야마타오로치]','카펠라의 성흔 : 작열하는 광명의 태양','성흔의 탈리스만[아마테라스]'],
		'아슈타르테' : ['카펠라의 성흔 : 언리밋 프레스','언리밋 프레스','온전한 탈리스만[체이서 프레스]','성흔의 탈리스만[체이서 프레스]','카펠라의 성흔 : 패황유성권','패황유성권','온전한 탈리스만[강습유성타]','성흔의 탈리스만[강습유성타]','카펠라의 성흔 : 황룡유희','황룡유희','온전한 탈리스만[황룡천공]','성흔의 탈리스만[황룡천공]','카펠라의 성흔 : 홈런 퀸','홈런 퀸','온전한 탈리스만[트윙클 스매쉬]','성흔의 탈리스만[트윙클 스매쉬]','카펠라의 성흔 : 황룡재림','황룡재림','온전한 탈리스만[황룡강림]','성흔의 탈리스만[황룡강림]','카펠라의 성흔 : 빅 뱅 클러스터','성흔의 탈리스만[체이서 클러스터]','카펠라의 성흔 : 섬멸의 춤','성흔의 탈리스만[사도의 춤]'],
		'아이올로스' : ['카펠라의 성흔 : 스톰 익스플로전','스톰 익스플로전','온전한 탈리스만[폭풍의 눈]','성흔의 탈리스만[폭풍의 눈]','카펠라의 성흔 : 메가 토네이도','메가 토네이도','온전한 탈리스만[휘몰아치는 바람]','성흔의 탈리스만[휘몰아치는 바람]','카펠라의 성흔 : 소닉붐','소닉붐','온전한 탈리스만[스톰 스트라이크]','성흔의 탈리스만[스톰 스트라이크]','카펠라의 성흔 : 격풍회류','격풍회류','온전한 탈리스만[질풍가도]','성흔의 탈리스만[질풍가도]','카펠라의 성흔 : 레이징 템페스트','레이징 템페스트','온전한 탈리스만[스톰 퀘이커]','성흔의 탈리스만[스톰 퀘이커]','카펠라의 성흔 : 굉풍격','성흔의 탈리스만[스파이럴 프레스]','카펠라의 성흔 : 스네이크 게일','성흔의 탈리스만[바이올런트 스톰]'],
		'악귀나찰' : ['카펠라의 성흔 : 분쇄참 : 혼','분쇄참 : 혼','온전한 탈리스만[혼백참]','성흔의 탈리스만[혼백참]','카펠라의 성흔 : 회천 : 광폭','회천 : 광폭','온전한 탈리스만[원귀 : 회천]','성흔의 탈리스만[원귀 : 회천]','카펠라의 성흔 : 천쇄참수','천쇄참수','온전한 탈리스만[악귀참수]','성흔의 탈리스만[악귀참수]','카펠라의 성흔 : 최후의 집행귀','최후의 집행귀','온전한 탈리스만[원귀 : 격살]','성흔의 탈리스만[원귀 : 격살]','카펠라의 성흔 : 나락참 : 극','나락참 : 극','온전한 탈리스만[합격 : 나락참]','성흔의 탈리스만[합격 : 나락참]','카펠라의 성흔 : 원귀 : 흑풍섬(逆)','성흔의 탈리스만[원귀 : 신풍]','카펠라의 성흔 : 귀멸악섬','성흔의 탈리스만[악귀연무]'],
		'알키오네' : ['카펠라의 성흔 : 할로우 아웃','할로우 아웃','온전한 탈리스만[버티컬 스파이럴]','성흔의 탈리스만[버티컬 스파이럴]','카펠라의 성흔 : 프리 무브먼트','프리 무브먼트','온전한 탈리스만[소닉 어설트]','성흔의 탈리스만[소닉 어설트]','카펠라의 성흔 : 엔드 쓰라이스','엔드 쓰라이스','온전한 탈리스만[엑셀 스트라이크]','성흔의 탈리스만[엑셀 스트라이크]','카펠라의 성흔 : 로테이팅 나이프','로테이팅 나이프','온전한 탈리스만[체인소 러시]','성흔의 탈리스만[체인소 러시]','카펠라의 성흔 : 임팩트 스파이럴','임팩트 스파이럴','온전한 탈리스만[어슬랜트 스파이럴]','성흔의 탈리스만[어슬랜트 스파이럴]','카펠라의 성흔 : 고스트 레이드','성흔의 탈리스만[엑조틱 램펀트]','카펠라의 성흔 : 브루탈 어썰트','성흔의 탈리스만[디스트리뷰션]'],
		'어센션' : ['카펠라의 성흔 : 임계점 조작','임계점 조작','온전한 탈리스만[차원 : 시공폭뢰]','성흔의 탈리스만[차원 : 시공폭뢰]','카펠라의 성흔 : 고통의 안내자','고통의 안내자','온전한 탈리스만[괴리 : 패러사이트 스웜]','성흔의 탈리스만[괴리 : 패러사이트 스웜]','카펠라의 성흔 : 그라비톤 블래스트','그라비톤 블래스트','온전한 탈리스만[차원 : 포지트론 블래스트]','성흔의 탈리스만[차원 : 포지트론 블래스트]','카펠라의 성흔 : 역장왜곡해석','역장왜곡해석','온전한 탈리스만[차원 : 패러다임 디토네이션]','성흔의 탈리스만[차원 : 패러다임 디토네이션]','카펠라의 성흔 : 애프터 케어','애프터 케어','온전한 탈리스만[괴리 : 칙 잇]','성흔의 탈리스만[괴리 : 칙 잇]','카펠라의 성흔 : 각인영창','성흔의 탈리스만[차원 : 싱귤래리티 매니플레이션]','카펠라의 성흔 : 저편의 단말마','성흔의 탈리스만[괴리 : 저편의 자장가]'],
		'언터처블' : ['카펠라의 성흔 : 오프레션 월','오프레션 월','온전한 탈리스만[오프레션]','성흔의 탈리스만[오프레션]','카펠라의 성흔 : 크래셔블 퀘이크','크래셔블 퀘이크','온전한 탈리스만[디스트러블 퀘이크]','성흔의 탈리스만[디스트러블 퀘이크]','카펠라의 성흔 : 스페셜 프레전트','스페셜 프레전트','온전한 탈리스만[서프라이즈 프레전트]','성흔의 탈리스만[서프라이즈 프레전트]','카펠라의 성흔 : 퍼펙트 스핀','퍼펙트 스핀','온전한 탈리스만[퍼펙트 배팅]','성흔의 탈리스만[퍼펙트 배팅]','카펠라의 성흔 : 레이지 온 파이어','레이지 온 파이어','온전한 탈리스만[겟 온 파이어]','성흔의 탈리스만[겟 온 파이어]','카펠라의 성흔 : 익스트림 스매쉬','성흔의 탈리스만[바이올런트 스매쉬]','카펠라의 성흔 : 굿 초이스','성흔의 탈리스만[더 나인 배럴]'],
		'얼티밋 디바' : ['카펠라의 성흔 : 액셀레이트 스파이어','액셀레이트 스파이어','온전한 탈리스만[스파이어]','성흔의 탈리스만[스파이어]','카펠라의 성흔 : 래피드 무브 싸이클론','래피드 무브 싸이클론','온전한 탈리스만[수플렉스 싸이클론]','성흔의 탈리스만[수플렉스 싸이클론]','카펠라의 성흔 : 하이퍼 캐넌 스파이크','하이퍼 캐넌 스파이크','온전한 탈리스만[와일드 캐넌 스파이크]','성흔의 탈리스만[와일드 캐넌 스파이크]','카펠라의 성흔 : 스카이 엠프레스 클러치','스카이 엠프레스 클러치','온전한 탈리스만[에어클러치]','성흔의 탈리스만[에어클러치]','카펠라의 성흔 : 맥스 허리케인 어택','맥스 허리케인 어택','온전한 탈리스만[싸이클론 어택]','성흔의 탈리스만[싸이클론 어택]','카펠라의 성흔 : 자이언트 허리케인','성흔의 탈리스만[자이언트 스윙]','카펠라의 성흔 : 익스트림 소닉 붐','성흔의 탈리스만[토르베지노]'],
		'에레보스' : ['카펠라의 성흔 : 스파이럴 볼텍스','스파이럴 볼텍스','온전한 탈리스만[스파이럴 러쉬]','성흔의 탈리스만[스파이럴 러쉬]','카펠라의 성흔 : 작렬하는 흑뢰','작렬하는 흑뢰','온전한 탈리스만[다크 스타]','성흔의 탈리스만[다크 스타]','카펠라의 성흔 : 극 : 멸광폭살','극 : 멸광폭살','온전한 탈리스만[흑광폭살]','성흔의 탈리스만[흑광폭살]','카펠라의 성흔 : 조여오는 어둠','조여오는 어둠','온전한 탈리스만[다크 디비젼]','성흔의 탈리스만[다크 디비젼]','카펠라의 성흔 : 일점집중','일점집중','온전한 탈리스만[바이올런트 피어스]','성흔의 탈리스만[바이올런트 피어스]','카펠라의 성흔 : 공허의 저편','성흔의 탈리스만[폴링 데스]','카펠라의 성흔 : 데들리 모스','성흔의 탈리스만[다크 임펄스]'],
		'엑스마키나' : ['카펠라의 성흔 : 새비지 테크닉','새비지 테크닉','온전한 탈리스만[부스트 : 브리칭 다운]','성흔의 탈리스만[부스트 : 브리칭 다운]','카펠라의 성흔 : 스테이블 택틱스','스테이블 택틱스','온전한 탈리스만[스코치 택틱스]','성흔의 탈리스만[스코치 택틱스]','카펠라의 성흔 : 이모션 킬러','이모션 킬러','온전한 탈리스만[다이렉트 피드백]','성흔의 탈리스만[다이렉트 피드백]','카펠라의 성흔 : 패트리어트 킥','패트리어트 킥','온전한 탈리스만[부스트 : 다이너마이트 킥]','성흔의 탈리스만[부스트 : 다이너마이트 킥]','카펠라의 성흔 : 앱솔루트 포트리스','앱솔루트 포트리스','온전한 탈리스만[포트리스 블리츠]','성흔의 탈리스만[포트리스 블리츠]','카펠라의 성흔 : 리스키 토이','성흔의 탈리스만[AT-SO Walker]','카펠라의 성흔 : 세이프티 액시던트','성흔의 탈리스만[레이시온 크리트]'],
		'염제 폐월수화' : ['카펠라의 성흔 : 대지일갈','대지일갈','온전한 탈리스만[사자후]','성흔의 탈리스만[사자후]','카펠라의 성흔 : 기공방출 : 쾌','기공방출 : 쾌','온전한 탈리스만[기공장]','성흔의 탈리스만[기공장]','카펠라의 성흔 : 나선의 대칭점','나선의 대칭점','온전한 탈리스만[광충노도]','성흔의 탈리스만[광충노도]','카펠라의 성흔 : 진 : 광룡강림','진 : 광룡강림','온전한 탈리스만[광룡십삼장]','성흔의 탈리스만[광룡십삼장]','카펠라의 성흔 : 천지일갈','천지일갈','온전한 탈리스만[태사자후]','성흔의 탈리스만[태사자후]','카펠라의 성흔 : 세이지 일루전','성흔의 탈리스만[세인트 일루전]','카펠라의 성흔 : 환영기공파','성흔의 탈리스만[흡기공탄]'],
		'염황 광풍제월' : ['카펠라의 성흔 : 음공의 대가','음공의 대가','온전한 탈리스만[사자후]','성흔의 탈리스만[사자후]','카펠라의 성흔 : 나선기공장','나선기공장','온전한 탈리스만[기공장]','성흔의 탈리스만[기공장]','카펠라의 성흔 : 호랑이 울음','호랑이 울음','온전한 탈리스만[기호지세]','성흔의 탈리스만[기호지세]','카펠라의 성흔 : 샤이닝 스피어','샤이닝 스피어','온전한 탈리스만[넨 스피어]','성흔의 탈리스만[넨 스피어]','카펠라의 성흔 : 청천벽력','청천벽력','온전한 탈리스만[기공환]','성흔의 탈리스만[기공환]','카펠라의 성흔 : 제황뇌격권','성흔의 탈리스만[제황나선격]','카펠라의 성흔 : 월광진폭','성흔의 탈리스만[월광대지]'],
		'오버마인드' : ['카펠라의 성흔 : 썬더 엘리미네이트','썬더 엘리미네이트','온전한 탈리스만[썬더 스트라이크]','성흔의 탈리스만[썬더 스트라이크]','카펠라의 성흔 : 다크 매터','다크 매터','온전한 탈리스만[나이트 할로우]','성흔의 탈리스만[나이트 할로우]','카펠라의 성흔 : 포스 오브 오버마인드','포스 오브 오버마인드','온전한 탈리스만[핼로윈 버스터]','성흔의 탈리스만[핼로윈 버스터]','카펠라의 성흔 : 서프레션 이펙트','서프레션 이펙트','온전한 탈리스만[엘레멘탈 커튼]','성흔의 탈리스만[엘레멘탈 커튼]','카펠라의 성흔 : 플레임 게이저','플레임 게이저','온전한 탈리스만[엘레멘탈 퀘이크]','성흔의 탈리스만[엘레멘탈 퀘이크]','카펠라의 성흔 : 트랜센던스','성흔의 탈리스만[초월의 크리스탈]','카펠라의 성흔 : 디 임팩트','성흔의 탈리스만[더 게이트]'],
		'오블리비언' : ['카펠라의 성흔 : 서클 익스플로젼','서클 익스플로젼','온전한 탈리스만[플레임 서클]','성흔의 탈리스만[플레임 서클]','카펠라의 성흔 : 일렉트릭 트레인','일렉트릭 트레인','온전한 탈리스만[라이트닝 월]','성흔의 탈리스만[라이트닝 월]','카펠라의 성흔 : 엘레멘탈 오브','엘레멘탈 오브','온전한 탈리스만[엘레멘탈 레인]','성흔의 탈리스만[엘레멘탈 레인]','카펠라의 성흔 : 듀플리케이트','듀플리케이트','온전한 탈리스만[컨센트레이트]','성흔의 탈리스만[컨센트레이트]','카펠라의 성흔 : 엘레멘탈 게더링','엘레멘탈 게더링','온전한 탈리스만[엘레멘탈 스트라이크]','성흔의 탈리스만[엘레멘탈 스트라이크]','카펠라의 성흔 : 원소 폭풍','성흔의 탈리스만[엘레멘탈 필드]','카펠라의 성흔 : 데들리 거터 볼','성흔의 탈리스만[컨버전스 캐넌]'],
		'옵티머스' : ['카펠라의 성흔 : 얼티밋 게일 포스','얼티밋 게일 포스','온전한 탈리스만[공중 전폭 메카 : 게일 포스]','성흔의 탈리스만[공중 전폭 메카 : 게일 포스]','카펠라의 성흔 : 폭격 개시','폭격 개시','온전한 탈리스만[메카 드롭]','성흔의 탈리스만[메카 드롭]','카펠라의 성흔 : 스패로우 : 요격 모드','스패로우 : 요격 모드','온전한 탈리스만[스패로우 팩토리]','성흔의 탈리스만[스패로우 팩토리]','카펠라의 성흔 : 이볼빙 시스템','이볼빙 시스템','온전한 탈리스만[HS-12 플라즈마]','카펠라의 성흔 : 스피닝 블레이드','성흔의 탈리스만[HS-12 플라즈마]','스피닝 블레이드','온전한 탈리스만[프리스비]','성흔의 탈리스만[프리스비]','카펠라의 성흔 : 에너지 순환 시스템','성흔의 탈리스만[G-S.P. 팔콘]','카펠라의 성흔 : 네오디뮴 마그넷','성흔의 탈리스만[마그네틱 필드]'],
		'용독문주' : ['카펠라의 성흔 : 백스트리트 킥','백스트리트 킥','온전한 탈리스만[그라운드 킥]','성흔의 탈리스만[그라운드 킥]','카펠라의 성흔 : 바이퍼 마인','바이퍼 마인','온전한 탈리스만[베놈 마인]','성흔의 탈리스만[베놈 마인]','카펠라의 성흔 : 대방출','대방출','온전한 탈리스만[정크 스핀]','성흔의 탈리스만[정크 스핀]','카펠라의 성흔 : 들끓는 독수','들끓는 독수','온전한 탈리스만[맹독 일발화약성]','성흔의 탈리스만[맹독 일발화약성]','카펠라의 성흔 : 버스터 봄','버스터 봄','온전한 탈리스만[블록 봄]','성흔의 탈리스만[블록 봄]','카펠라의 성흔 : 비각술','성흔의 탈리스만[비기 : 폭독]','카펠라의 성흔 : 오버 더 리미트','성흔의 탈리스만[만병지왕 - 파진포]'],
		'워로드' : ['카펠라의 성흔 : 나선마환창','나선마환창','온전한 탈리스만[드레드보어]','성흔의 탈리스만[드레드보어]','카펠라의 성흔 : 광풍마창','광풍마창','온전한 탈리스만[블레이드 스톰]','성흔의 탈리스만[블레이드 스톰]','카펠라의 성흔 : 사이클론 데모닉 스피어','사이클론 데모닉 스피어','온전한 탈리스만[디베스테이트]','성흔의 탈리스만[디베스테이트]','카펠라의 성흔 : 파워 스트라이크','파워 스트라이크','온전한 탈리스만[런지 스트라이크]','성흔의 탈리스만[런지 스트라이크]','카펠라의 성흔 : 둠 슬래쉬','둠 슬래쉬','온전한 탈리스만[둠 글레이브]','성흔의 탈리스만[둠 글레이브]','카펠라의 성흔 : 크루얼 매서커','성흔의 탈리스만[데들리 매서커]','카펠라의 성흔 : 버스트 스매쉬','성흔의 탈리스만[버스트 슬래쉬]'],
		'이모탈' : ['카펠라의 성흔 : 데스 클로 디포메이션','데스 클로 디포메이션','온전한 탈리스만[스피닝 디포메이션]','성흔의 탈리스만[스피닝 디포메이션]','카펠라의 성흔 : 악몽의 씨앗','악몽의 씨앗','온전한 탈리스만[데빌 피스트]','성흔의 탈리스만[데빌 피스트]','카펠라의 성흔 : 오버파워 피날레','오버파워 피날레','온전한 탈리스만[어둠의 권능]','성흔의 탈리스만[어둠의 권능]','카펠라의 성흔 : 팬텀 게이트','팬텀 게이트','온전한 탈리스만[복마전]','성흔의 탈리스만[복마전]','카펠라의 성흔 : 디제스터 랩소디','디제스터 랩소디','온전한 탈리스만[재앙]','성흔의 탈리스만[재앙]','카펠라의 성흔 : 악의의 절규','성흔의 탈리스만[다크 하울링]','카펠라의 성흔 : 언스토퍼블 레이지','성흔의 탈리스만[파멸의 강타]'],
		'이클립스' : ['카펠라의 성흔 : 대마법사 루이즈','대마법사 루이즈','온전한 탈리스만[계약소환 : 루이즈 언니!]','성흔의 탈리스만[계약소환 : 루이즈 언니!]','카펠라의 성흔 : 스피릿 디비젼','스피릿 디비젼','온전한 탈리스만[정령소환 : 정령왕 에체베리아]','성흔의 탈리스만[정령소환 : 정령왕 에체베리아]','카펠라의 성흔 : 진정한 타우킹','진정한 타우킹','온전한 탈리스만[계약소환 : 타우킹 쿠루타]','성흔의 탈리스만[계약소환 : 타우킹 쿠루타]','카펠라의 성흔 : 정령의 속삭임','정령의 속삭임','온전한 탈리스만[정령소환 : 융합소환 헤일롬]','성흔의 탈리스만[정령소환 : 융합소환 헤일롬]','카펠라의 성흔 : 극한의 분노','극한의 분노','온전한 탈리스만[분노의 난타]','성흔의 탈리스만[분노의 난타]','카펠라의 성흔 : 검은 달의 광기','성흔의 탈리스만[블랙 루나틱]','카펠라의 성흔 : 델라리온의 마물','성흔의 탈리스만[전설소환 : 월영]'],
		'이터널' : ['카펠라의 성흔 : 냉기의 조율자','냉기의 조율자','온전한 탈리스만[아이스 빅 해머]','성흔의 탈리스만[아이스 빅 해머]','카펠라의 성흔 : 프리즈 브레이크','프리즈 브레이크','온전한 탈리스만[아이스맨]','성흔의 탈리스만[아이스맨]','카펠라의 성흔 : 빙룡승천파','빙룡승천파','온전한 탈리스만[블리자드 스톰]','성흔의 탈리스만[블리자드 스톰]','카펠라의 성흔 : 창백한 쐐기','창백한 쐐기','온전한 탈리스만[아이스 오브]','성흔의 탈리스만[아이스 오브]','카펠라의 성흔 : 서릿발','서릿발','온전한 탈리스만[아이스 필드]','성흔의 탈리스만[아이스 필드]','카펠라의 성흔 : 아이스버그 하보크','성흔의 탈리스만[샤드 매그넘]','카펠라의 성흔 : 아몰포스 웨이브','성흔의 탈리스만[오버프리즈 익스텐션]'],
		'인다라천' : ['카펠라의 성흔 : 절제된 파동검','절제된 파동검','온전한 탈리스만[무쌍파]','성흔의 탈리스만[무쌍파]','카펠라의 성흔 : 파동연격','파동연격','온전한 탈리스만[작열 파동진]','성흔의 탈리스만[작열 파동진]','카펠라의 성흔 : 광명부동진','광명부동진','온전한 탈리스만[부동명왕진]','성흔의 탈리스만[부동명왕진]','카펠라의 성흔 : 극빙검: 절대 영도','극빙검: 절대 영도','온전한 탈리스만[극파동검 빙인]','성흔의 탈리스만[극파동검 빙인]','카펠라의 성흔 : 극화진폭검','극화진폭검','온전한 탈리스만[극파동검 폭염]','성흔의 탈리스만[극파동검 폭염]','카펠라의 성흔 : 파동폭뢰','성흔의 탈리스만[파동검 : 인다라망]','카펠라의 성흔 : 뇌운천진','성흔의 탈리스만[천뇌 바주라]'],
		'인페르노' : ['카펠라의 성흔 : 단호한 심판','단호한 심판','온전한 탈리스만[즉결심판]','성흔의 탈리스만[즉결심판]','카펠라의 성흔 : 왜곡된 자비','왜곡된 자비','온전한 탈리스만[고결한 격노]','성흔의 탈리스만[고결한 격노]','카펠라의 성흔 : 이교도의 최후','이교도의 최후','온전한 탈리스만[참수]','성흔의 탈리스만[참수]','카펠라의 성흔 : 율법의 전도자','율법의 전도자','온전한 탈리스만[업화의 소용돌이]','성흔의 탈리스만[업화의 소용돌이]','카펠라의 성흔 : 즉각처분', '즉각처분', '온전한 탈리스만[역죄단멸]', '성흔의 탈리스만[역죄단멸]','카펠라의 성흔 : 멸악대천벌','성흔의 탈리스만[신벌]','카펠라의 성흔 : 분쇄차륜형','성흔의 탈리스만[차륜형]'],
		'저스티스' : ['카펠라의 성흔 : 코크 스크류 허리케인','코크 스크류 허리케인','온전한 탈리스만[코크 스크류 블로우]','성흔의 탈리스만[코크 스크류 블로우]','카펠라의 성흔 : 신격권의 계승자','신격권의 계승자','온전한 탈리스만[헤븐리 컴비네이션]','성흔의 탈리스만[헤븐리 컴비네이션]','카펠라의 성흔 : 태풍의 눈','태풍의 눈','온전한 탈리스만[허리케인 롤]','성흔의 탈리스만[허리케인 롤]','카펠라의 성흔 : 압도적인 힘','압도적인 힘','온전한 탈리스만[개틀링 펀치]','성흔의 탈리스만[개틀링 펀치]','카펠라의 성흔 : 디바인 체이서','디바인 체이서','온전한 탈리스만[데몰리션 펀치]','성흔의 탈리스만[데몰리션 펀치]','카펠라의 성흔 : 사상 최강의 펀치','성흔의 탈리스만[핵 펀치]','카펠라의 성흔 : 격동하는 대지','성흔의 탈리스만[아토믹 쵸퍼]'],
		'제노사이더' : ['카펠라의 성흔 : 바투 서포트','바투 서포트','온전한 탈리스만[번 로커스트]','성흔의 탈리스만[번 로커스트]','카펠라의 성흔 : 초지니 : 강습','초지니 : 강습','온전한 탈리스만[페니트레잇 헤드]','성흔의 탈리스만[페니트레잇 헤드]','카펠라의 성흔 : 카타스트로피','카타스트로피','온전한 탈리스만[스케일 그라인더]','성흔의 탈리스만[스케일 그라인더]','카펠라의 성흔 : 산군투멸','산군투멸','온전한 탈리스만[팔티잔 스큐어]','성흔의 탈리스만[팔티잔 스큐어]','카펠라의 성흔 : 타이얼러스','타이얼러스','온전한 탈리스만[어스웜 헌트]','성흔의 탈리스만[어스웜 헌트]','카펠라의 성흔 : 헌팅 샬리트','성흔의 탈리스만[에이븐 헌팅]','카펠라의 성흔 : 세컨드 그라인더','성흔의 탈리스만[데보닝 슬래셔]'],
		'지니위즈' : ['카펠라의 성흔 : 개량형 플로레 컬라이더','개량형 플로레 컬라이더','온전한 탈리스만[플로레 컬라이더]','성흔의 탈리스만[플로레 컬라이더]','카펠라의 성흔 : 후끈한 열기 폭풍','후끈한 열기 폭풍','온전한 탈리스만[성난 불길 가열로]','성흔의 탈리스만[성난 불길 가열로]','카펠라의 성흔 : 메가 드릴 Mk-2','메가 드릴 Mk-2','온전한 탈리스만[메가 드릴]','성흔의 탈리스만[메가 드릴]','카펠라의 성흔 : 압축 파리채 풀스윙','압축 파리채 풀스윙','온전한 탈리스만[대왕 파리채]','성흔의 탈리스만[대왕 파리채]','카펠라의 성흔 : 자가 발전형 전기토끼','자가 발전형 전기토끼','온전한 탈리스만[플로레 전기토끼]','성흔의 탈리스만[플로레 전기토끼]','카펠라의 성흔 : 따끔한 맛 특제 빙수','성흔의 탈리스만[잭프로스트 빙수]','카펠라의 성흔 : 비터스위트 롤리팝','성흔의 탈리스만[롤리팝 크러쉬]'],
		'천선낭랑' : ['카펠라의 성흔 : 심혼폭류','심혼폭류','온전한 탈리스만[심혼흡성부]','성흔의 탈리스만[심혼흡성부]','카펠라의 성흔 : 무진어주술','무진어주술','온전한 탈리스만[백팔염주]','성흔의 탈리스만[백팔염주]','카펠라의 성흔 : 주박술의 극의','주박술의 극의','온전한 탈리스만[부동주박진]','성흔의 탈리스만[부동주박진]','카펠라의 성흔 : 나포마귀진','나포마귀진','온전한 탈리스만[시폭염화부]','성흔의 탈리스만[시폭염화부]','카펠라의 성흔 : 신룡승천제','신룡승천제','온전한 탈리스만[탈명대염진]','성흔의 탈리스만[탈명대염진]','카펠라의 성흔 : 마혼폭염진','성흔의 탈리스만[마혼부 : 퇴마음양옥]','카펠라의 성흔 : 벽력부태주','성흔의 탈리스만[염폭모대주]'],
		'카이저' : ['카펠라의 성흔 : 일격일살','일격일살','온전한 탈리스만[원인치 펀치]','성흔의 탈리스만[원인치 펀치]','카펠라의 성흔 : 섬광의 춤','섬광의 춤','온전한 탈리스만[라이트닝 댄스]','성흔의 탈리스만[라이트닝 댄스]','카펠라의 성흔 : 슈페리어 비트','슈페리어 비트','온전한 탈리스만[비트 드라이브]','성흔의 탈리스만[비트 드라이브]','카펠라의 성흔 : 파쇄권 : 멸','파쇄권 : 멸','온전한 탈리스만[파쇄권]','성흔의 탈리스만[파쇄권]','카펠라의 성흔 : 섬극연환오의','섬극연환오의','온전한 탈리스만[일주연환격]','성흔의 탈리스만[일주연환격]','카펠라의 성흔 : 맹호복초','성흔의 탈리스만[호격권]','카펠라의 성흔 : 두발당성','성흔의 탈리스만[무영각]'],
		'커맨더' : ['카펠라의 성흔 : 래피드 익스팅션','래피드 익스팅션','온전한 탈리스만[C4]','성흔의 탈리스만[C4]','카펠라의 성흔 : 빅 밤','빅 밤','온전한 탈리스만[네이팜 탄]','성흔의 탈리스만[네이팜 탄]','카펠라의 성흔 : 대룡저격소총 ADO-22', '대룡저격소총 ADO-22','온전한 탈리스만[리턴드 스나이퍼]','성흔의 탈리스만[리턴드 스나이퍼]','카펠라의 성흔 : 스쿼시 그라비티','스쿼시 그라비티','온전한 탈리스만[G-61 중력류탄]','성흔의 탈리스만[G-61 중력류탄]','카펠라의 성흔 : 이오나이저','이오나이저','온전한 탈리스만[고전압탄 : 맥스웰]','성흔의 탈리스만[고전압탄 : 맥스웰]','카펠라의 성흔 : 킵 어시스턴스','성흔의 탈리스만[데인저 클로즈]','카펠라의 성흔 : G-13EV 열압력 반응류탄','성흔의 탈리스만[G-38ARG 반응류탄]'],
		'크리에이터(자각2)' : ['카펠라의 성흔 : 수호의 양면성','수호의 양면성','온전한 탈리스만[플레임 허리케인]','성흔의 탈리스만[플레임 허리케인]','카펠라의 성흔 : 갈래 바람','갈래 바람','온전한 탈리스만[윈드 프레스]','성흔의 탈리스만[윈드 프레스]','카펠라의 성흔 : 스프링 클리닝','성흔의 탈리스만[윈드 스톰]','카펠라의 성흔 : 블랙홀','블랙홀','온전한 탈리스만[웜홀]','성흔의 탈리스만[웜홀]','카펠라의 성흔 : 혹한의 길','혹한의 길','온전한 탈리스만[빙하시대]','성흔의 탈리스만[빙하시대]','카펠라의 성흔 : 소울 링크스','소울 링크스','온전한 탈리스만[링크]','성흔의 탈리스만[링크]','카펠라의 성흔 : 타임 엑셀레이트','성흔의 탈리스만[타임 포워드]'],
		'크림슨 로제' : ['카펠라의 성흔 : 블레이드 댄싱','블레이드 댄싱','온전한 탈리스만[권총의 춤]','성흔의 탈리스만[권총의 춤]','카펠라의 성흔 : 스타일리쉬 헤드샷','스타일리쉬 헤드샷','온전한 탈리스만[멀티 헤드샷]','성흔의 탈리스만[멀티 헤드샷]','카펠라의 성흔 : 스피닝 탑 건호크','스피닝 탑 건호크','온전한 탈리스만[더블 건호크]','성흔의 탈리스만[더블 건호크]','카펠라의 성흔 : 블러디 스타','블러디 스타','온전한 탈리스만[블러디 스파이크]','성흔의 탈리스만[블러디 스파이크]','카펠라의 성흔 : 블레이드 스톰','블레이드 스톰','온전한 탈리스만[제압 사격]','성흔의 탈리스만[제압 사격]','카펠라의 성흔 : 핀 포인트','성흔의 탈리스만[킬 포인트]','카펠라의 성흔 : 윈드 디바이더','성흔의 탈리스만[체인 디바이더]'],
		'타나토스' : ['카펠라의 성흔 : 살의','살의','온전한 탈리스만[학살의 발라크르 강령]','성흔의 탈리스만[학살의 발라크르 강령]','카펠라의 성흔 : 위압','위압','온전한 탈리스만[발라크르의 야망]','성흔의 탈리스만[발라크르의 야망]','카펠라의 성흔 : 갈퀴손','갈퀴손','온전한 탈리스만[기요틴]','성흔의 탈리스만[기요틴]','카펠라의 성흔 : 응어리','응어리','온전한 탈리스만[망자의 집념]','성흔의 탈리스만[망자의 집념]','카펠라의 성흔 : 미련','미련','온전한 탈리스만[헤이트풀]','성흔의 탈리스만[헤이트풀]','카펠라의 성흔 : 추억거리','성흔의 탈리스만[블랙 아라크니아]','카펠라의 성흔 : 굶주린 집행자','성흔의 탈리스만[익스큐서너 슬래쉬]'],
		'태을선인' : ['카펠라의 성흔 : 백호 - 合','백호 - 合','온전한 탈리스만[공의 식신 - 백호]','성흔의 탈리스만[공의 식신 - 백호]','카펠라의 성흔 : 대회전격 - 熱','대회전격 - 熱','온전한 탈리스만[대회전격]','성흔의 탈리스만[대회전격]','카펠라의 성흔 : 무쌍격 - 蒼','무쌍격 - 蒼','온전한 탈리스만[무쌍격]','성흔의 탈리스만[무쌍격]','카펠라의 성흔 : 창룡 - 波','창룡 - 波','온전한 탈리스만[해의 식신 - 창룡]','성흔의 탈리스만[해의 식신 - 창룡]','카펠라의 성흔 : 역린격 - 地','역린격 - 地','온전한 탈리스만[역린격]','성흔의 탈리스만[역린격]','카펠라의 성흔 : 혼신의 일격 - 劈','성흔의 탈리스만[역전의 칠조격]','카펠라의 성흔 : 황룡부 - 滅','성흔의 탈리스만[황룡진]'],
		'트렌드세터' : ['카펠라의 성흔 : 기분파','기분파','온전한 탈리스만[다 카포]','성흔의 탈리스만[다 카포]','카펠라의 성흔 : 포르티시모','포르티시모','온전한 탈리스만[파워 포르테]','성흔의 탈리스만[파워 포르테]','카펠라의 성흔 : 최고의 무대','최고의 무대','온전한 탈리스만[사운드 오브 뮤즈]','성흔의 탈리스만[사운드 오브 뮤즈]','카펠라의 성흔 : 에너제틱 비트','에너제틱 비트','온전한 탈리스만[드랍 더 비트]','성흔의 탈리스만[드랍 더 비트]','카펠라의 성흔 : 쇼맨십','쇼맨십','온전한 탈리스만[눈부신 광경]','성흔의 탈리스만[눈부신 광경]','카펠라의 성흔 : 퍼펙트 오프닝','성흔의 탈리스만[스페셜 오프닝]','카펠라의 성흔 : 시즌 그리팅','성흔의 탈리스만[빛의 변주]'],
		'패스파인더' : ['카펠라의 성흔 : 익세시브 차징','익세시브 차징','온전한 탈리스만[어트랙트 에뮤니션]','성흔의 탈리스만[어트랙트 에뮤니션]','카펠라의 성흔 : 페이탈 슬래쉬','페이탈 슬래쉬','온전한 탈리스만[라이트닝 슬래쉬]','성흔의 탈리스만[라이트닝 슬래쉬]','카펠라의 성흔 : 에너지 업히브','에너지 업히브','온전한 탈리스만[디바이드 어쓰]','성흔의 탈리스만[디바이드 어쓰]','카펠라의 성흔 : 에너지 리액션','에너지 리액션','온전한 탈리스만[에너지 바운스]','성흔의 탈리스만[에너지 바운스]','카펠라의 성흔 : 래피드 디스펄션','래피드 디스펄션','온전한 탈리스만[디스펄션]','성흔의 탈리스만[디스펄션]','카펠라의 성흔 : 라이트 램페이지','성흔의 탈리스만[라이트 브랜디쉬]','카펠라의 성흔 : 오버서플라이','성흔의 탈리스만[CEAB-2]'],
		'패황' : ['카펠라의 성흔 : 폭렬! 홍염 질풍각','폭렬! 홍염 질풍각','온전한 탈리스만[홍염 질풍각]','성흔의 탈리스만[홍염 질풍각]','카펠라의 성흔 : 눈보다 빠르게','눈보다 빠르게','온전한 탈리스만[라이트닝 댄스]','성흔의 탈리스만[라이트닝 댄스]','카펠라의 성흔 : 불꽃 맹습','불꽃 맹습','온전한 탈리스만[헥토파스칼 킥]','성흔의 탈리스만[헥토파스칼 킥]','카펠라의 성흔 : 한마리 제비처럼','한마리 제비처럼','온전한 탈리스만[비연선풍]','성흔의 탈리스만[비연선풍]','카펠라의 성흔 : 태산 가르기','태산 가르기','온전한 탈리스만[스핀 킥]','성흔의 탈리스만[스핀 킥]','카펠라의 성흔 : 꺼지지 않는 불','성흔의 탈리스만[낙화]','카펠라의 성흔 : 멸절','성흔의 탈리스만[아토믹 캐넌]'],
		'프라임' : ['카펠라의 성흔 : 얼티밋 템페스터','얼티밋 템페스터','온전한 탈리스만[공중 전투 메카 : 템페스터]','성흔의 탈리스만[공중 전투 메카 : 템페스터]','카펠라의 성흔 : 언카운터블 메카 드롭','언카운터블 메카 드롭','온전한 탈리스만[메카 드롭]','성흔의 탈리스만[메카 드롭]','카펠라의 성흔 : 스패로우 : 격추 모드','스패로우 : 격추 모드','온전한 탈리스만[스패로우 팩토리]','성흔의 탈리스만[스패로우 팩토리]','카펠라의 성흔 : 카운트다운 스타트','카운트다운 스타트','온전한 탈리스만[Ez-10 카운터어택]','성흔의 탈리스만[Ez-10 카운터어택]','카펠라의 성흔 : 능동 방호 체계','능동 방호 체계','온전한 탈리스만[Ex-S 바이퍼 Zero]','성흔의 탈리스만[Ex-S 바이퍼 Zero]','카펠라의 성흔 : 커버링 파이어','성흔의 탈리스만[TX-80 디멘션 러너]','카펠라의 성흔 : 프리즈!','성흔의 탈리스만[TX-45 A-Team]'],
		'프레이야' : ['카펠라의 성흔 : 일렉트릭쇼크','일렉트릭쇼크','온전한 탈리스만[C4]','성흔의 탈리스만[C4]','카펠라의 성흔 : 빅마마','빅마마','온전한 탈리스만[네이팜 탄]','성흔의 탈리스만[네이팜 탄]','카펠라의 성흔 : 노 머시','노 머시','온전한 탈리스만[록 온 서포트]','성흔의 탈리스만[록 온 서포트]','카펠라의 성흔 : 매시브 그라비티','매시브 그라비티','온전한 탈리스만[G-61 중력류탄]','성흔의 탈리스만[G-61 중력류탄]','카펠라의 성흔 : 그랜드 플로우','그랜드 플로우','온전한 탈리스만[초진공탄 : 첼리]','성흔의 탈리스만[초진공탄 : 첼리]','카펠라의 성흔 : 스트랫 제트','성흔의 탈리스만[오픈 파이어]','카펠라의 성흔 : 마그넷 컴프레서','성흔의 탈리스만[포토빌라이저]'],
		'헤카테' : ['카펠라의 성흔 : 흑마술의 대가','흑마술의 대가','온전한 탈리스만[점핑 베어 프레스]','성흔의 탈리스만[점핑 베어 프레스]','카펠라의 성흔 : 퍼펫 왈츠','퍼펫 왈츠','온전한 탈리스만[데스티니 퍼펫]','성흔의 탈리스만[데스티니 퍼펫]','카펠라의 성흔 : 주인 의식','주인 의식','온전한 탈리스만[매드 더 빅]','성흔의 탈리스만[매드 더 빅]','카펠라의 성흔 : 일어나, 춤 출 시간이야','일어나, 춤 출 시간이야','온전한 탈리스만[헥스]','성흔의 탈리스만[헥스]','카펠라의 성흔 : 흑암의 구원자','흑암의 구원자','온전한 탈리스만[테러블 로어]','성흔의 탈리스만[테러블 로어]','카펠라의 성흔 : 마녀의 정원','성흔의 탈리스만[고통의 정원]','카펠라의 성흔 : 차별주의자','성흔의 탈리스만[커스 오브 테러베어]'],
		'메이븐' : ['카펠라의 성흔 : 플레어 데토네이터','플레어 데토네이터','온전한 탈리스만[플레어]','성흔의 탈리스만[플레어]','카펠라의 성흔 : 볼트 스콜','볼트 스콜','온전한 탈리스만[볼트 레인]','성흔의 탈리스만[볼트 레인]','카펠라의 성흔 : 핀포인트 쓰리','핀포인트 쓰리','온전한 탈리스만[쓰리 인 어 베드]','성흔의 탈리스만[쓰리 인 어 베드]','카펠라의 성흔 : 기어 부스터','기어 부스터','온전한 탈리스만[조인트 : 카펫 바밍]','성흔의 탈리스만[조인트 : 카펫 바밍]','카펠라의 성흔 : 부메랑 커터','부메랑 커터','온전한 탈리스만[팔케 : 윙 커터]','성흔의 탈리스만[팔케 : 윙 커터]','카펠라의 성흔 : 페이탈 피어싱','성흔의 탈리스만[F.D. 피어서]','카펠라의 성흔 : 택티컬 무브먼트','성흔의 탈리스만[팔케 : 체이싱클러]'],
		'프레데터' : ['카펠라의 성흔 : 고블 몬스터','고블 몬스터','온전한 탈리스만[디바우러]','성흔의 탈리스만[디바우러]','카펠라의 성흔 : 괴생풍체','괴생풍체','온전한 탈리스만[도깨비 바람]','성흔의 탈리스만[도깨비 바람]','카펠라의 성흔 : 심플 헌팅','심플 헌팅','온전한 탈리스만[크루얼 슬러터]','성흔의 탈리스만[크루얼 슬러터]','카펠라의 성흔 : 광란의 축제','광란의 축제','온전한 탈리스만[요요만찬]','성흔의 탈리스만[요요만찬]','카펠라의 성흔 : 내추럴 매드니스','내추럴 매드니스','온전한 탈리스만[램페이지]','성흔의 탈리스만[램페이지]','카펠라의 성흔 : 빠르게, 고통 없이','성흔의 탈리스만[발골]','카펠라의 성흔 : 핵 앤 펀치','성흔의 탈리스만[피스 피어서]',]
	
	}
var lv105ItemId = {};
var lv105OptionMap = {};
var lv105CustomOptionMap = [];
var lv105CustomOptionToNumberMap = {};
var lv105WeaponOptionMap = [];
var lv105WeaponThirdOptionMap = [];


lv105ItemId['감시자 : 간직해 온 이상'] = '4d825cd7fd245aa231bbfb1fc2a72ef5';
lv105ItemId['감시자 : 고뇌하는 의지'] = '26a2107ed0092ce8f6468492c87fbd43';
lv105ItemId['감시자 : 선택받은 기억'] = 'a3d4edcaa3d986a041ffae635face6a8';
lv105ItemId['감시자 : 수호를 향한 신념'] = 'f29e21bd74e3ef1aea6957252ab579aa';
lv105ItemId['감시자 : 자각하는 책임'] = 'bb8342997cf4dc376ea72c4e29f60af6';
lv105ItemId['감시자 : 추앙받던 은총'] = '66be17fd1be2bbad7a54b1b534de26cc';
lv105ItemId['검객의 유산'] = 'a7b9678dc2f36a83a86f0816c190f565';
lv105ItemId['결속된 영혼'] = '9daf6049cb3f8a37de2aa7d886c77c1c';
lv105ItemId['고도 : 깊어지는 어둠'] = 'd02308e799a7843cbdc8c7ab284f2fa5';
lv105ItemId['고도 : 남은 자의 결연'] = '0a87e9d6ae64ba5a42a6d5bd2f7ad668';
lv105ItemId['고도 : 놓지 않는 이성'] = '3dffaa62c8e0115c070e3cd4ea3ee9a1';
lv105ItemId['고도 : 본색을 숨긴 공포'] = 'e55d759e84300f278ed0e399e0523d38';
lv105ItemId['고도 : 울림 속의 묵념'] = 'c8472f600fbe7031ebd5aaa0b51b8ab5';
lv105ItemId['고도 : 이어지는 경계'] = 'f8cba626750c5467ddd07d6da553d293';
lv105ItemId['광폭화 제어 장치'] = '4171acf806efc2da5e945a75dbe834ae';
lv105ItemId['광휘 : 고귀한 사명'] = '078ddee1101ba2dd3f62f7b2841a260d';
lv105ItemId['광휘 : 멈출 수 없는 의지'] = '7dc30234ecc008bc2ddc550ad8fb22a5';
lv105ItemId['광휘 : 전해지는 지혜'] = 'ea1a209be12a78a2a6c872e75ab0970c';
lv105ItemId['괴이 : 각인된 포효'] = 'fbbfd5544c70159de9d83c3c51e7cc88';
lv105ItemId['괴이 : 돋아난 심연'] = 'b4731f24e006e2cadff17e84421089bf';
lv105ItemId['괴이 : 얽히는 추억'] = '899743caa109f3bcff2a273f07517d3a';
lv105ItemId['괴이 : 움켜쥔 무위'] = '85c47c44067632835017093d6d96ade9';
lv105ItemId['괴이 : 확산되는 파문'] = 'f01aa235ce5faed6cd44636fe6b69f6c';
lv105ItemId['괴이 : 휘감는 영면'] = '716addc1e4a89443aab7b9f71ee759f2';
lv105ItemId['금룡 : 무한한 빛의 결계'] = '52688ab6a97da9368e86343d0a3ac4a5';
lv105ItemId['금룡 : 찬란한 용의 비늘'] = 'eeb469cdd183e41318837d2dbd98dd42';
lv105ItemId['금룡 : 화려한 힘의 기원'] = '5285d14664a9aa41559ca68f440c3909';
lv105ItemId['기민한 움직임'] = '1c518dcf5ae8f01e901f27421f1b0702';
lv105ItemId['냉기에 갇힌 원망'] = '940475e59fba15aa9e6540adcff78177';
lv105ItemId['달콤한 향기의 속삭임'] = '510203e11edce8f1b2e84c7dd0b935d2';
lv105ItemId['대류 : 몰아치기 전의 고요함'] = '44e90b09a8bbab556f4958feb6168663';
lv105ItemId['대류 : 변덕스러운 흐름'] = '0fb48a898584f41764bbd49b14f88d87';
lv105ItemId['대류 : 제어할 수 없는 돌풍'] = '3c8e697eee47723378749a502136174f';
lv105ItemId['대류 : 주어진 바람의 무게'] = 'be92b91c14836f2d5e0c5f899793a07d';
lv105ItemId['대류 : 휩쓸려 지나간 흔적'] = 'bd35b37823d4109d3d62fea1b25fad5c';
lv105ItemId['대지 : 바스러지는 발걸음'] = '6bbbaaa2dc770708ac755462d33c2c18';
lv105ItemId['대지 : 삶과 죽음의 교차'] = '84d9918a4fb186deff8fe8678e236b60';
lv105ItemId['대지 : 생명이 돋아나는 땅'] = 'cfb10e82f068a4afa752d8e15fb1349c';
lv105ItemId['대지 : 죽음이 내려앉는 시간'] = '3b0a259d06f696b63f1ce3eaca4abc52';
lv105ItemId['대지 : 피어나는 계절'] = 'bcafa147938ebfc88bea72187b0d8fa7';
lv105ItemId['뒤집힌 기억'] = 'a787c880f6ad2698ebb1320c7c0a8e0e';
lv105ItemId['레드 래빗'] = '1f7f967345b533ce9b4f7f38c04c0b08';
lv105ItemId['마나 오버플로우'] = '8858b038e52bc20c40097c18805ee81b';
lv105ItemId['망각의 마석'] = '37fb8e1ed41b0400e58be97f5b6bd261';
lv105ItemId['멈추지 않는 질주'] = '0e0df26eef643d98594b4b8fdb74e8f4';
lv105ItemId['멸망의 안식처'] = 'de513dd8bc55e2fa5de67e897709433f';
lv105ItemId['무지한 영역의 통로'] = '66c973500e7b63eef10de74c9c9f8510';
lv105ItemId['무한한 가능성의 탐구'] = 'e314f90155e6e2f19294f896ef03cac0';
lv105ItemId['무한한 가능성의 흐름'] = '73155da713b69edbde6a6d0f7a2bc4fe';
lv105ItemId['무한한 가능성의 흔적'] = 'd3a1f96f8a684f7923aba3cdf343df31';
lv105ItemId['미지의 세계의 균형'] = '05a749e3d4d0c5ce5dc5fb11c5b207f1';
lv105ItemId['보호를 담은 괴'] = '53a02e917af9ccf63720982e561db8f0';
lv105ItemId['부화하는 악령의 씨앗'] = 'd9856ddc373ec13093e3bacab7fbdd6b';
lv105ItemId['불신 : 길을 잃은 언행'] = '600e4ac41839e7513bea8e93a91fecf1';
lv105ItemId['불신 : 독을 품은 미소'] = '28e3dd012353304ca2e6017984546c5f';
lv105ItemId['불신 : 망각한 은혜'] = '4849dabc54d2e5336b8a9048e8e328c1';
lv105ItemId['불신 : 버려진 신의'] = '5d808ff147134e463ef9b8b1bcd1bba4';
lv105ItemId['불신 : 비틀린 진심'] = '48f2e55ed7075156e61c27f04ecde350';
lv105ItemId['불신 : 빛바랜 믿음'] = '07b69ea3a8a7f6ca40b5daf7304f4421';
lv105ItemId['사신의 함정'] = '64ab0451e9b8e4e5b37450da182b4e25';
lv105ItemId['서리에 묻힌 분노'] = '9f10c7c2d430df8422ea1b87f99580a9';
lv105ItemId['설경 : 고요한 단말마'] = 'd52deb46528d91d333306bac701226ec';
lv105ItemId['설경 : 남겨지지 않는 발자국'] = 'd82f1b5562bfca6f5003640175742d0f';
lv105ItemId['설경 : 스며드는 한기'] = 'd8f457479508ba3159430e137d99f52f';
lv105ItemId['설경 : 얼어붙는 감각'] = 'dc62f7c8c83de81a91b6fe07e64a0a84';
lv105ItemId['설경 : 정할 수 없는 운명'] = 'eba306ed78063847b3943dc44b1d40e9';
lv105ItemId['세계의 경계'] = 'ff791742c08a4589461ddaa319589fd5';
lv105ItemId['소울 트레이싱 디바이스'] = '909155d518545f4005fcb62b3158a047';
lv105ItemId['수평선 위 균형'] = '377ce08f2f6beb587f3ecf5164c8413f';
lv105ItemId['수호 : 혼'] = '8420a1aa819f2ee3d28e57b3b2c763a6';
lv105ItemId['슈페리어 : 매직 서클로'] = '323e201088055e1ed622be84b7479103';
lv105ItemId['슈페리어 : 오리진 오브 더 마이어'] = 'a6baa41de89c3a9bf280b65ca5b24b58';
lv105ItemId['슈페리어 : 코스믹 이어링'] = '70558b17d3d49fc8599f8e0d751bce9d';
lv105ItemId['스킬 펄 익스텐션'] = 'ecb31f0a8239243305710ec93f2c598c';
lv105ItemId['시간을 거스르는 기억'] = '66176cbac65acfe9dcf49dea8622a45a';
lv105ItemId['심연의 결집 : 무정형의 힘'] = '1ba6221619f88691ed8f0c3adba4db7a';
lv105ItemId['심연의 결집 : 무한한 수축'] = '2950e00eced48e19bf4c161b3bc7df7e';
lv105ItemId['심연의 결집 : 정의되지 않는 존재'] = '56422d257499f7c60c7dad02d0f43db2';
lv105ItemId['얼음 송곳니'] = 'eeade52e7c2f2c008785dd34388bbd99';
lv105ItemId['열화 : 끝없는 타오름'] = '3d70c6e7769d3f0279393bb3af1af587';
lv105ItemId['열화 : 내디딘 마지막 발걸음'] = 'b0f88a406788da99debe08ec96f0dbd8';
lv105ItemId['열화 : 밀려오는 불길'] = 'd61a06e03d9d71e7e796feb0979873fe';
lv105ItemId['열화 : 엉겨붙은 의지'] = '6bab8cc6a330eb195775b22765a9c319';
lv105ItemId['열화 : 흔들린 불꽃의 이성'] = '8c61142a45d62fe0d7026dbcac044810';
lv105ItemId['영광의 재림'] = '3e57e786cca74683a7e92fccf6f7f741';
lv105ItemId['영원의 조각 - 귀걸이'] = 'ea6e0484b3204f0df7107cd81070a3cd';
lv105ItemId['영원의 조각 - 마법석'] = '155d9286caca793c05c1184c933c42df';
lv105ItemId['영원의 조각 - 보조장비'] = '4df7dc8ab50887e87f5bd81fd5e5778c';
lv105ItemId['영혼의 구속 : 거스를 수 없는 봉인'] = 'e0e357b5cce04625dc8b0538bd5c8d01';
lv105ItemId['영혼의 구속 : 무거운 업보'] = '6e1dc4f395dd54d7750ac47b2631518c';
lv105ItemId['영혼의 구속 : 지배하는 권능'] = '332409131f5fcbb418bff7a0a5dd8a13';
lv105ItemId['우레 : 곤두서는 감각'] = '2c786522e7909a0a3addd7aa129bcc41';
lv105ItemId['우레 : 구름을 향한 발돋움'] = '64670f9c086ab784afb049fc0e5cbf23';
lv105ItemId['우레 : 대지를 뒤덮는 암운'] = '9037932df635f5cc5a6fc7f036e0d569';
lv105ItemId['우레 : 뒤따르는 고동'] = 'a50ec36485ce4d6b1fc569cca0710113';
lv105ItemId['우레 : 하늘에 새겨진 흉터'] = '10e021b102c7d24e0fb75a7fd1e2cbd7';
lv105ItemId['월드 아이돌 링'] = '07fe8b0e336f5186b11a014d6a69f189';
lv105ItemId['이단을 지명하는 검지'] = '56292c3d584c123b342da8171215c438';
lv105ItemId['인비시블 스톤'] = '765d0b7f42669f4c9e1a813d52863250';
lv105ItemId['일렉트릭 플레임'] = '8c6a0eedd97a32b4203388dcb3fc4cfd';
lv105ItemId['저항을 담은 철'] = '1cde5f6e7871d7cfd910100a9e06a3ba';
lv105ItemId['종말의 예고'] = '6e650fff81f46f2457054629f87d51f9';
lv105ItemId['종언을 고하는 서약'] = 'bdcea96b000a4f3eb5b2f60d1ba1ac64';
lv105ItemId['죄악의 지배 : 절규가 매달리는 공포'] = '49e7e73cd1ec8573ed0c0ba9c127b350';
lv105ItemId['죄악의 지배 : 절망을 뒤덮는 공포'] = '47b419903a0b8542416e528e1079e29e';
lv105ItemId['죄악의 지배 : 태초가 선사하는 공포'] = '5f8b29a66f1c13f6e3a47169af5cc598';
lv105ItemId['지나간 과오'] = '565a1d18209279337579c5796a19aedf';
lv105ItemId['지면 파괴자'] = '4f4fa43a8e6f8091ffdc04b7845b630e';
lv105ItemId['지배자의 위엄'] = 'dedbcb9684579c9e99d228a9f0d165d5';
lv105ItemId['지식의 향연'] = '9b88b948e24bae282dba6392452882fe';
lv105ItemId['진룡 : 무거운 철의 투지'] = '6255f92b7b56a7ddd76f566a47ada800';
lv105ItemId['진룡 : 압도하는 힘의 원천'] = 'cc64795c07f57cb9e52967063276f795';
lv105ItemId['진룡 : 잔혹한 용의 발톱'] = '568018f4d9ed013f352d412331ab67ba';
lv105ItemId['천계 연합군 : 꺾이지 않는 의지'] = 'cb41d2e497f1a7ac1ae2711670da9861';
lv105ItemId['천계 연합군 : 모든 이를 위한 기도'] = '3b62a91abc21df1a2609af53cb15d432';
lv105ItemId['천계 연합군 : 밝게 빛나는 희망'] = 'f0683ab71a21f9166ccb3423fa0a928c';
lv105ItemId['천상의 보호'] = '5c87ab8919400f37343fd2e2da930bcd';
lv105ItemId['추억의 속삭임'] = '8dffa40f0c682d6e808f2140f0831113';
lv105ItemId['터져나오는 악몽'] = 'cdb47e18592c7f6716413a71f8bf5a70';
lv105ItemId['투지 : 담대한 용기'] = '5fd08201e842e5eb26a8f94a6dab9cb7';
lv105ItemId['투지 : 변화의 바람'] = '1f3d0b1aa0426ca82905a0c24e2c5d57';
lv105ItemId['투지 : 빛나는 투혼'] = 'dd30b01ee7eb145353ad8a618dba0f18';
lv105ItemId['투지 : 성장을 향한 여정'] = '259526d69a41cbe60eb692cc57ed194e';
lv105ItemId['투지 : 연마된 노력'] = 'a17effef25c4e6caee0a002f6183bb5c';
lv105ItemId['투지 : 영광의 증표'] = '9c122f8552e47daec3bdefaa91b97090';
lv105ItemId['펠 로스 제국의 영광'] = '8c7a6525c6a5f61d63f18a7f2aa74720';
lv105ItemId['펼쳐지는 예언'] = 'aa5345277950a9ba09c571039f5aa00f';
lv105ItemId['평화로운 과거'] = '5de13e3bb024510cc700975be9928b65';
lv105ItemId['포식 : 사그라진 고동'] = 'b040382ef0a0f361de63a451ee415dc3';
lv105ItemId['포식 : 자비없는 습격'] = '18ddc7fb78f1230f14ee4654cc22cc20';
lv105ItemId['포식 : 조여오는 올가미'] = '3ce72504c5b4353c5fb82a74e7ddebaf';
lv105ItemId['포식 : 추적하는 사냥꾼'] = '06a75e0f3cf3b8ad358289b3faf79303';
lv105ItemId['포식 : 헤집어진 상처'] = 'ff659fcd422f693eb4914bd45ce74eb2';
lv105ItemId['피할 수 없는 일격'] = '49746865f150ca990dd032e591e2b7d2';
lv105ItemId['해방 : 내비치는 환란'] = 'd0379f8a1b8e8f6cba32f8ef30294b65';
lv105ItemId['해방 : 불신의 씨앗'] = 'ea4821ac132a0ebe1722294bbb8f93b8';
lv105ItemId['해방 : 비상하는 악몽'] = 'ceaa203630116b7c0b8f4cad5ae08986';
lv105ItemId['해방 : 시작되는 멸망'] = 'ecae7777222a69b076d418caf0c49b26';
lv105ItemId['해방 : 찬란한 종말'] = '9fe8c007456d2ffda625e739aa3b09d3';
lv105ItemId['해방 : 황홀한 배신'] = '3eaa2b7272d89b12e860251af0002e76';
lv105ItemId['향기로운 환희'] = '0f98a8d74d0771f359cc890ca877da8c';
lv105ItemId['혈독초'] = '76518617ef377f3c3fbf16d39c8ef6ac';
lv105ItemId['혼돈이 담긴 소용돌이'] = 'eefff0bf3d8457e02df3106af779e9f8';
lv105ItemId['화룡 : 끓어오르는 용의 숨결'] = '73f46c965770a5680576ad52219da4a5';
lv105ItemId['화룡 : 타오르는 불의 분노'] = '6f388dd09d7149044abfd92fed63b4a6';
lv105ItemId['화룡 : 폭발하는 힘의 근원'] = '0e79738a52d19e87cf910fc16537887b';

lv105OptionMap["감시자 : 간직해 온 이상"] = "최종 데미지 3% 증가<br><br>물리/마법 방어력 +14,000";
lv105OptionMap["감시자 : 고뇌하는 의지"] = "최종 데미지 6% 증가<br>스킬 범위 5% 증가<br>75, 80Lv 스킬 범위 18% 증가";
lv105OptionMap["감시자 : 선택받은 기억"] = "최종 데미지 3% 증가<br><br>HP MAX +1,200, MP MAX +1,890";
lv105OptionMap["감시자 : 수호를 향한 신념"] = "최종 데미지 3% 증가<br><br>모든 속성 저항 +20";
lv105OptionMap["감시자 : 자각하는 책임"] = "최종 데미지 6% 증가<br>스킬 범위 5% 증가<br>45, 60, 70Lv 스킬 범위 18% 증가";
lv105OptionMap["감시자 : 추앙받던 은총"] = "최종 데미지 6% 증가<br>스킬 범위 5% 증가<br>1~40Lv 스킬 범위 18% 증가";
lv105OptionMap["검객의 유산"] = "공격력 증가 + 1200.0%<br><br>최종 데미지 6% 증가<br><br>공격 시 '빈틈 가르기' 시전 (쿨타임 3초)<br>- 총 공격력 증가의 1,800%만큼 피해<br><br>공격 시 5초 동안 빙결 상태 이상 부여 (쿨타임 2초)";
lv105OptionMap["고도 : 깊어지는 어둠"] = "최종 데미지 6% 증가<br><br>HP MAX 5% 수치의 [충전형 보호막] 부여";
lv105OptionMap["고도 : 남은 자의 결연"] = "최종 데미지 3% 증가<br><br>1초마다 MP 0.75% 회복";
lv105OptionMap["고도 : 놓지 않는 이성"] = "최종 데미지 6% 증가<br><br>HP MAX 5% 수치의 [충전형 보호막] 부여";
lv105OptionMap["고도 : 본색을 숨긴 공포"] = "최종 데미지 6% 증가<br><br>HP MAX 5% 수치의 [충전형 보호막] 부여";
lv105OptionMap["고도 : 울림 속의 묵념"] = "최종 데미지 3% 증가<br><br>공격 시 MP 1,500 회복 (쿨타임 1초)";
lv105OptionMap["고도 : 이어지는 경계"] = "최종 데미지 3% 증가<br><br>장비의 MP 회복 효과 10% 증가";
lv105OptionMap["광폭화 제어 장치"] = "최종 데미지 7% 증가<br>적의 남은 HP가 50% 미만일 때 최종 데미지 4% 증가";
lv105OptionMap["광휘 : 고귀한 사명"] = "최종 데미지 3% 증가<br><br>HP MAX 3% 수치의 [충전형 보호막] 부여";
lv105OptionMap["광휘 : 멈출 수 없는 의지"] = "최종 데미지 3% 증가<br><br>HP MAX 3% 수치의 [충전형 보호막] 부여";
lv105OptionMap["광휘 : 전해지는 지혜"] = "최종 데미지 3% 증가<br><br>HP MAX 3% 수치의 [충전형 보호막] 부여";
lv105OptionMap["괴이 : 각인된 포효"] = "최종 데미지 7% 증가";
lv105OptionMap["괴이 : 돋아난 심연"] = "최종 데미지 3% 증가<br><br>공격 시 충격파 발생 (쿨타임 0.1초)<br>- 적의 무력화 게이지량 -0.1<br>- 총 공격력 증가의 36%만큼 피해";
lv105OptionMap["괴이 : 얽히는 추억"] = "최종 데미지 7% 증가";
lv105OptionMap["괴이 : 움켜쥔 무위"] = "최종 데미지 3% 증가<br><br>공격 시 충격파 발생 (쿨타임 0.5초)<br>- 총 공격력 증가의 200%만큼 피해";
lv105OptionMap["괴이 : 확산되는 파문"] = "최종 데미지 7% 증가";
lv105OptionMap["괴이 : 휘감는 영면"] = "최종 데미지 3% 증가<br><br>공격 시 충격파 발생 (쿨타임 8초)<br>- 적의 무력화 게이지량 -3<br>- 적에게 5초 동안 기절 상태 이상 부여<br>- 총 공격력 증가의 1920%만큼 피해";
lv105OptionMap["금룡 : 무한한 빛의 결계"] = "공격 시 적에게 테서렉트 붐 시전 (쿨타임 3초)<br>- 균등한 확률로 1, 3, 5회 시전<br>- 총 공격력 증가의 400%만큼 피해";
lv105OptionMap["금룡 : 찬란한 용의 비늘"] = "공격 시 적에게 호리젠탈 큐브 시전 (쿨타임 3초)<br>- 균등한 확률로 1, 3, 5회 시전<br>- 총 공격력 증가의 400%만큼 피해";
lv105OptionMap["금룡 : 화려한 힘의 기원"] = "공격 시 적에게 버티컬 필러 시전 (쿨타임 3초)<br>- 균등한 확률로 1, 3, 5회 시전<br>- 총 공격력 증가의 400%만큼 피해";
lv105OptionMap["기민한 움직임"] = "최종 데미지 9% 증가<br><br>이동 속도 +10%<br><br>HP 1분당 920.4 회복";
lv105OptionMap["냉기에 갇힌 원망"] = "최종 데미지 5% 증가<br>모든 속성 강화 +35<br><br>[원망이 담긴 냉기]<br>적에게 피해를 입히고 빙결 상태로 만드는 빙룡 생성<br>- 빙룡 피해량 600%<br><br>공격 시 5초 동안 빙결 상태 이상 부여 (쿨타...";
lv105OptionMap["달콤한 향기의 속삭임"] = "최종 데미지 10% 증가<br><br>슈퍼 아머 부여<br><br>공격 시 적에게 5초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 1초)<br><br>이동 속도 +5%";
lv105OptionMap["대류 : 몰아치기 전의 고요함"] = "최종 데미지 3% 증가<br>스킬 쿨타임 4% 감소 (각성기 제외)";
lv105OptionMap["대류 : 변덕스러운 흐름"] = "최종 데미지 3% 증가<br>스킬 쿨타임 4% 감소 (각성기 제외)";
lv105OptionMap["대류 : 제어할 수 없는 돌풍"] = "최종 데미지 3% 증가<br>스킬 쿨타임 4% 감소 (각성기 제외)";
lv105OptionMap["대류 : 주어진 바람의 무게"] = "최종 데미지 3% 증가<br>스킬 쿨타임 4% 감소 (각성기 제외)";
lv105OptionMap["대류 : 휩쓸려 지나간 흔적"] = "최종 데미지 3% 증가<br>스킬 쿨타임 4% 감소 (각성기 제외)";
lv105OptionMap["대지 : 바스러지는 발걸음"] = "최종 데미지 5% 증가<br>회피율 +8%";
lv105OptionMap["대지 : 삶과 죽음의 교차"] = "최종 데미지 5% 증가<br>물리/마법 크리티컬 히트 +10%";
lv105OptionMap["대지 : 생명이 돋아나는 땅"] = "최종 데미지 5% 증가<br>모든 속도 +10%";
lv105OptionMap["대지 : 죽음이 내려앉는 시간"] = "최종 데미지 5% 증가<br>HP MAX +5%";
lv105OptionMap["대지 : 피어나는 계절"] = "최종 데미지 5% 증가<br>MP MAX +5%";
lv105OptionMap["뒤집힌 기억"] = "공격력 증가 + 500.0%<br><br>스킬 쿨타임 15% 감소 (각성기 제외)<br><br>캐스팅 시 8% 확률로 자신에게 Lv10 신성한 빛 시전";
lv105OptionMap["레드 래빗"] = "스킬 쿨타임 7% 감소 (각성기 제외)<br>모든 속성 강화 +7<br><br>[붉은 토끼의 선물]<br>스킬 시전 시 7.77% 확률로 스킬 공격력 77% 증가";
lv105OptionMap["마나 오버플로우"] = "최종 데미지 9% 증가<br><br>스킬 MP 소모량 +100%<br><br>1초마다 MP 2% 회복";
lv105OptionMap["망각의 마석"] = "최종 데미지 10% 증가<br><br>[스틱스 강에 흘려보낸 기억]<br>쿨타임 1초 이상 스킬 시전 시 1% 확률로 스킬 1개의 쿨타임 초기화 (버퍼 제외)";
lv105OptionMap["멈추지 않는 질주"] = "최종 데미지 9% 증가<br><br>이동 속도 +15%";
lv105OptionMap["멸망의 안식처"] = "최종 데미지 10% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>물리/마법 피해 감소 +15%";
lv105OptionMap["무지한 영역의 통로"] = "최종 데미지 9% 증가<br><br>500px 내 파티원 물리/마법 피해 감소 +15%";
lv105OptionMap["무한한 가능성의 탐구"] = "최종 데미지 4% 증가<br>50, 85, 100Lv 스킬 공격력 8% 증가<br>50, 85, 100Lv 스킬 쿨타임 회복 속도 +15% (버퍼 제외)";
lv105OptionMap["무한한 가능성의 흐름"] = "최종 데미지 4% 증가<br>50, 85, 100Lv 스킬 공격력 8% 증가<br>50, 85, 100Lv 스킬 쿨타임 회복 속도 +15% (버퍼 제외)";
lv105OptionMap["무한한 가능성의 흔적"] = "최종 데미지 4% 증가<br>50, 85, 100Lv 스킬 공격력 8% 증가<br>50, 85, 100Lv 스킬 쿨타임 회복 속도 +15% (버퍼 제외)";
lv105OptionMap["미지의 세계의 균형"] = "최종 데미지 9% 증가<br><br>모든 속도 +5%<br>물리/마법 피해 감소 +25%";
lv105OptionMap["보호를 담은 괴"] = "최종 데미지 9% 증가<br><br>HP MAX +2,000<br>MP MAX +2,000";
lv105OptionMap["부화하는 악령의 씨앗"] = "최종 데미지 4% 증가<br>50, 85, 100Lv 스킬 공격력 11% 증가<br>50, 85, 100Lv 스킬 쿨타임 회복 속도 +15% (버퍼 제외)";
lv105OptionMap["불신 : 길을 잃은 언행"] = "최종 데미지 3% 증가<br><br>공격 시 '언행의 파동' 시전 (쿨타임 3초)<br>- 총 공격력 증가의 1,500%만큼 피해<br><br>공격 시 5초 동안 빙결 상태 이상 부여 (쿨타임 2초)";
lv105OptionMap["불신 : 독을 품은 미소"] = "최종 데미지 3% 증가<br><br>공격 시 '독의 균열' 시전 (쿨타임 1초)<br>- 총 공격력 증가의 500%만큼 피해<br><br>공격 시 5초 동안 기절 상태 이상 부여 (쿨타임 2초)";
lv105OptionMap["불신 : 망각한 은혜"] = "최종 데미지 3% 증가<br><br>공격 시 랜덤한 적에게 '망각의 검' 3개 시전 (쿨타임 3초)<br>- 총 공격력 증가의 400%만큼 피해";
lv105OptionMap["불신 : 버려진 신의"] = "최종 데미지 3% 증가<br><br>공격 시 '버려진 상처' 시전 (쿨타임 2초)<br>- 총 공격력 증가의 1,000%만큼 피해<br><br>공격 시 5초 동안 구속 상태 이상 부여 (쿨타임 2초)";
lv105OptionMap["불신 : 비틀린 진심"] = "최종 데미지 3% 증가<br><br>공격 시 랜덤한 적에게 '비틀린 검' 3개 시전 (쿨타임 3초)<br>- 총 공격력 증가의 400%만큼 피해";
lv105OptionMap["불신 : 빛바랜 믿음"] = "최종 데미지 3% 증가<br><br>공격 시 랜덤한 적에게 '빛바랜 포효' 시전 (쿨타임 3초)<br>- 총 공격력 증가의 1,200%만큼 피해";
lv105OptionMap["사신의 함정"] = "최종 데미지 10% 증가<br><br>스킬 범위 7% 증가<br>HP MAX +5%";
lv105OptionMap["서리에 묻힌 분노"] = "최종 데미지 5% 증가<br>모든 속성 강화 +35<br><br>[분노가 담긴 냉기]<br>적에게 피해를 입히고 빙결 상태로 만드는 빙룡 생성<br>- 빙룡 피해량 600%<br><br>공격 시 5초 동안 빙결 상태 이상 부여 (쿨타...";
lv105OptionMap["설경 : 고요한 단말마"] = "최종 데미지 5% 증가<br>HP/MP MAX +500";
lv105OptionMap["설경 : 남겨지지 않는 발자국"] = "최종 데미지 5% 증가<br>이동 속도 +15%";
lv105OptionMap["설경 : 스며드는 한기"] = "최종 데미지 5% 증가<br>모든 속도 +10%";
lv105OptionMap["설경 : 얼어붙는 감각"] = "최종 데미지 5% 증가<br>물리/마법 방어력 +10,000<br>공격 시 HP/MP 1% 회복 (쿨타임 3초)";
lv105OptionMap["설경 : 정할 수 없는 운명"] = "최종 데미지 5% 증가<br>물리/마법 크리티컬 히트 +5%<br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)";
lv105OptionMap["세계의 경계"] = "스킬 쿨타임 18% 감소 (각성기 제외)<br><br>모든 속성 저항 +5";
lv105OptionMap["소울 트레이싱 디바이스"] = "최종 데미지 11% 증가<br><br>[소울 드레인]<br>15초 마다 영혼을 흡수<br>- MP 15% 회복";
lv105OptionMap["수평선 위 균형"] = "최종 데미지 8% 증가<br><br>[천발일중]<br>스킬 시전 시 0.1% 확률로 스킬 공격력 1000% 증가";
lv105OptionMap["수호 : 혼"] = "최종 데미지 10% 증가<br><br>[거울 갑옷]<br>피격 시 아래 효과 적용<br>- 3초 이내 공격 시 받은 피해의 30% 회복 (쿨타임 3초)<br>- 반격을 가해 데미지를 입힘<br> * 반격 데미지 : 15,000%";
lv105OptionMap["슈페리어 : 매직 서클로"] = "공격 시 대지의 태동을 시전 (쿨타임 1초)<br>- 적에게 5초 동안 기절 상태 이상 부여<br>- 총 공격력 증가의 500%만큼 피해";
lv105OptionMap["슈페리어 : 오리진 오브 더 마이어"] = "공격 시 생명의 율동 시전 (쿨타임 2초)<br>- 적에게 5초 동안 구속 상태 이상 부여<br>- 총 공격력 증가의 1,000%만큼 피해";
lv105OptionMap["슈페리어 : 코스믹 이어링"] = "공격 시 물결의 파동을 시전 (쿨타임 3초)<br>- 적에게 5초 동안 빙결 상태 이상 부여<br>- 총 공격력 증가의 1,500%만큼 피해";
lv105OptionMap["스킬 펄 익스텐션"] = "최종 데미지 9% 증가<br><br>스킬 범위 23% 증가";
lv105OptionMap["시간을 거스르는 기억"] = "[시간 역행자]<br>스킬 사용 시 10% 확률로 사용한 스킬 쿨타임 초기화 (각성기 제외)<br>- 동일한 스킬 연속 초기화 가능";
lv105OptionMap["심연의 결집 : 무정형의 힘"] = "최종 데미지 3% 증가<br>스킬 범위 5% 증가<br>75, 80Lv 스킬 범위 15% 증가";
lv105OptionMap["심연의 결집 : 무한한 수축"] = "최종 데미지 3% 증가<br>스킬 범위 5% 증가<br>45, 60, 70Lv 스킬 범위 15% 증가";
lv105OptionMap["심연의 결집 : 정의되지 않는 존재"] = "최종 데미지 3% 증가<br>스킬 범위 5% 증가<br>1~40Lv 스킬 범위 15% 증가";
lv105OptionMap["얼음 송곳니"] = "공격력 증가 + 1200.0%<br><br>최종 데미지 5% 증가<br>공격 시 '얼음 송곳니' 시전 (쿨타임 3초)<br>- 총 공격력 증가의 1,800%만큼 피해<br><br>공격 시 5초 동안 빙결 상태 이상 부여 (쿨타임 2초)";
lv105OptionMap["열화 : 끝없는 타오름"] = "최종 데미지 5% 증가<br>둔화 내성 +10%<br>저주 내성 +10%<br>구속 내성 +10%";
lv105OptionMap["열화 : 내디딘 마지막 발걸음"] = "최종 데미지 5% 증가<br>이동 속도 +10%";
lv105OptionMap["열화 : 밀려오는 불길"] = "최종 데미지 5% 증가<br>둔화 내성 +10%<br>저주 내성 +10%<br>구속 내성 +10%";
lv105OptionMap["열화 : 엉겨붙은 의지"] = "최종 데미지 5% 증가<br>둔화 내성 +10%<br>저주 내성 +10%<br>구속 내성 +10%";
lv105OptionMap["열화 : 흔들린 불꽃의 이성"] = "최종 데미지 5% 증가<br>중독 내성 +15%";
lv105OptionMap["영광의 재림"] = "최종 데미지 10% 증가<br><br>火(화), 水(수), 明(명), 暗(암) 공격 속성";
lv105OptionMap["영혼의 구속 : 거스를 수 없는 봉인"] = "최종 데미지 4% 증가";
lv105OptionMap["영혼의 구속 : 무거운 업보"] = "최종 데미지 4% 증가";
lv105OptionMap["영혼의 구속 : 지배하는 권능"] = "최종 데미지 4% 증가";
lv105OptionMap["우레 : 곤두서는 감각"] = "물리/마법 피해 감소 +6%<br>공격 시 한 명의 적에게 우레 강타 시전 (쿨타임 3초)<br>- 총 공격력 증가의 1,500%만큼 피해";
lv105OptionMap["우레 : 구름을 향한 발돋움"] = "이동 속도 +12%<br>공격 시 한 명의 적에게 우레 강타 시전 (쿨타임 3초)<br>- 총 공격력 증가의 1,500%만큼 피해";
lv105OptionMap["우레 : 대지를 뒤덮는 암운"] = "공격 속도 +15%<br>캐스팅 속도 +20%<br>공격 시 한 명의 적에게 우레 강타 시전 (쿨타임 3초)<br>- 총 공격력 증가의 1,500%만큼 피해";
lv105OptionMap["우레 : 뒤따르는 고동"] = "HP/MP MAX +1,500<br>공격 시 한 명의 적에게 우레 강타 시전 (쿨타임 3초)<br>- 총 공격력 증가의 1,500%만큼 피해";
lv105OptionMap["우레 : 하늘에 새겨진 흉터"] = "물리/마법 크리티컬 히트 +10%<br>공격 시 한 명의 적에게 우레 강타 시전 (쿨타임 3초)<br>- 총 공격력 증가의 1,500%만큼 피해";
lv105OptionMap["월드 아이돌 링"] = "[내게 집중해!]<br>- 최종 데미지 5% 증가<br>- 스킬 쿨타임 10% 감소 (각성기 제외)<br>- 슈퍼 아머 부여<br>- 스포트라이트 발생";
lv105OptionMap["이단을 지명하는 검지"] = "[이단 척결]<br>적을 이단으로 규정<br>이단자를 공격 시 최종 데미지 11.5% 증가";
lv105OptionMap["일렉트릭 플레임"] = "최종 데미지 10% 증가<br><br>[폭발 약속 - 화상/감전]<br>화상/감전 부여 시 해당 상태 이상과 동일한 데미지 폭발<br>- 전환 옵션으로 부여 시 미발동";
lv105OptionMap["저항을 담은 철"] = "최종 데미지 9% 증가<br><br>모든 속성 저항 +25";
lv105OptionMap["종말의 예고"] = "최종 데미지 10% 증가<br><br>이동 속도 +20%";
lv105OptionMap["종언을 고하는 서약"] = "최종 데미지 10% 증가<br><br>모든 상태 이상 내성 +5%<br>적중률 +10%";
lv105OptionMap["죄악의 지배 : 절규가 매달리는 공포"] = "최종 데미지 3% 증가<br><br>이동 속도 +4%<br>모든 속성 저항 +8";
lv105OptionMap["죄악의 지배 : 절망을 뒤덮는 공포"] = "최종 데미지 3% 증가<br><br>공격 속도 +4%<br>캐스팅 속도 +6%<br>MP 1분당 348 회복";
lv105OptionMap["죄악의 지배 : 태초가 선사하는 공포"] = "최종 데미지 3% 증가<br><br>물리/마법 방어력 +7,000<br>HP 1분당 230.1 회복";
lv105OptionMap["지나간 과오"] = "최종 데미지 10% 증가<br><br>스킬 범위 7% 증가<br>물리/마법 방어력 +10,000";
lv105OptionMap["지면 파괴자"] = "공격력 증가 + 1200.0%<br><br>최종 데미지 5% 증가<br><br>공격 시 '지면 폭류' 시전 (쿨타임 2초)<br>- 총 공격력 증가의 1,200%만큼 피해";
lv105OptionMap["지배자의 위엄"] = "최종 데미지 9% 증가<br><br>스킬 MP 소모량 -30%<br><br>공격 시 5% 확률로 30초 동안 '기록된 신수' 소환 (쿨타임 30초)<br>- 총 공격력 증가의 600%만큼 피해";
lv105OptionMap["지식의 향연"] = "최종 데미지 10% 증가<br><br>MP 소모량의 20%만큼 MP 회복";
lv105OptionMap["진룡 : 무거운 철의 투지"] = "공격 시 충격파 발생 (쿨타임 0.5초)<br>- 총 공격력의 120%만큼 피해<br><br>공격 시 적에게 3초 동안 기절 상태 이상 부여 (쿨타임 8초)<br><br>공격 시 적의 무력화 게이지량 -1.5 (쿨타임 8초)";
lv105OptionMap["진룡 : 압도하는 힘의 원천"] = "공격 시 충격파 발생 (쿨타임 0.5초)<br>- 총 공격력 증가의 160%만큼 피해";
lv105OptionMap["진룡 : 잔혹한 용의 발톱"] = "공격 시 충격파 발생 (쿨타임 1초)<br>- 적의 무력화 게이지량 -0.5<br>- 총 공격력 증가의 240%만큼 피해";
lv105OptionMap["천계 연합군 : 모든 이를 위한 기도"] = "물리/마법 방어력 +14,000";
lv105OptionMap["천계 연합군 : 밝게 빛나는 희망"] = "모든 속성 저항 +20";
lv105OptionMap["천상의 보호"] = "최종 데미지 9% 증가<br><br>[불사의 주문]<br>사망 시 1초 동안 불사의 주술 발동 (쿨타임 120초)<br>- HP 1% 회복<br>- 다른 HP 회복 효과 및 데미지 무효화<br><br>HP MAX +1,000<br>MP MAX +1,000";
lv105OptionMap["추억의 속삭임"] = "최종 데미지 10% 증가<br><br>[오감의 황홀경]<br>융합된 장비의 강화/증폭 수치가 10에서 1 증가할 때마다 최종 데미지 1% 증가 (최대 2중첩)<br><br>이동 속도 +5%";
lv105OptionMap["터져나오는 악몽"] = "최종 데미지 4% 증가<br>50, 85, 100Lv 스킬 공격력 11% 증가<br>50, 85, 100Lv 스킬 쿨타임 회복 속도 +15% (버퍼 제외)";
lv105OptionMap["투지 : 담대한 용기"] = "최종 데미지 3% 증가<br>스킬 쿨타임 5% 감소 (각성기 제외)";
lv105OptionMap["투지 : 변화의 바람"] = "최종 데미지 6% 증가<br><br>물리/마법 방어력 +8,000<br>HP 1분당 230.1 회복";
lv105OptionMap["투지 : 빛나는 투혼"] = "최종 데미지 6% 증가<br><br>이동 속도 +4%<br>모든 속성 저항 +10";
lv105OptionMap["투지 : 성장을 향한 여정"] = "최종 데미지 6% 증가<br><br>공격 속도 +4%<br>캐스팅 속도 +6%<br>MP 1분당 464 회복";
lv105OptionMap["투지 : 연마된 노력"] = "최종 데미지 3% 증가<br>스킬 쿨타임 5% 감소 (각성기 제외)";
lv105OptionMap["투지 : 영광의 증표"] = "최종 데미지 3% 증가<br>스킬 쿨타임 5% 감소 (각성기 제외)";
lv105OptionMap["펠 로스 제국의 영광"] = "최종 데미지 11.5% 증가";
lv105OptionMap["펼쳐지는 예언"] = "공격력 증가 + 1200.0%<br><br>최종 데미지 6% 증가<br><br>공격 시 '원소 폭발' 시전 (쿨타임 1초)<br>- 총 공격력 증가의 600%만큼 피해<br><br>공격 시 5초 동안 기절 상태 이상 부여 (쿨타임 2초)";
lv105OptionMap["평화로운 과거"] = "최종 데미지 10% 증가<br><br>5초 동안 HP MAX 10% 수치의 보호막 부여 (쿨타임 5초)";
lv105OptionMap["포식 : 사그라진 고동"] = "공격 시 적에게 5초 동안 구속 상태 이상 부여 (쿨타임 10초)<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>둔화 내성 +10%<br>저주 내성 +10%<br>구속 내성 +10%";
lv105OptionMap["포식 : 자비없는 습격"] = "공격 시 MP 16% 회복 (쿨타임 15초)<br><br>공격 시 적에게 20초 동안 감전/중독 상태 이상 부여 (쿨타임 5초)<br><br>둔화 내성 +10%<br>저주 내성 +10%<br>구속 내성 +10%";
lv105OptionMap["포식 : 조여오는 올가미"] = "모든 속성 강화 +25<br><br>공격 시 적에게 20초 동안 출혈/화상 상태 이상 부여 (쿨타임 5초)<br><br>둔화 내성 +10%<br>저주 내성 +10%<br>구속 내성 +10%";
lv105OptionMap["포식 : 추적하는 사냥꾼"] = "공격 시 적에게 5초 동안 둔화 또는 구속 상태 이상 부여 (쿨타임 8초)<br><br>이동 속도 +10%";
lv105OptionMap["포식 : 헤집어진 상처"] = "중독 내성 +15%";
lv105OptionMap["피할 수 없는 일격"] = "공격력 증가 + 1200.0%<br><br>최종 데미지 6% 증가<br><br>공격 시 적에게 '피할 수 없는 일격'을 시전 (쿨타임 2초)<br>- 총 공격력 증가의 1,200%만큼 피해<br><br>공격 시 5초 동안 구속 상태 이상 부여 (쿨타임 2초)";
lv105OptionMap["해방 : 내비치는 환란"] = "최종 데미지 5% 증가<br><br>출혈/중독/화상/감전 피해 +4%<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 5초)";
lv105OptionMap["해방 : 불신의 씨앗"] = "최종 데미지 3% 증가<br>50, 100Lv 스킬 공격력 10% 증가";
lv105OptionMap["해방 : 비상하는 악몽"] = "최종 데미지 3% 증가<br>50, 85Lv 스킬 공격력 10% 증가";
lv105OptionMap["해방 : 시작되는 멸망"] = "최종 데미지 3% 증가<br>85, 100Lv 스킬 공격력 10% 증가";
lv105OptionMap["해방 : 찬란한 종말"] = "최종 데미지 6% 증가<br><br>[폭발 약속 - 중독/화상]<br>중독/화상 부여 시 해당 상태 이상과 동일한 데미지 폭발<br>- 전환 옵션으로 부여 시 미발동<br><br>공격 시 적에게 10초 동안 출혈/감전 상태 ...";
lv105OptionMap["해방 : 황홀한 배신"] = "최종 데미지 6% 증가<br><br>[폭발 약속 - 출혈/감전]<br>출혈/감전 부여 시 해당 상태 이상과 동일한 데미지 폭발<br>- 전환 옵션으로 부여 시 미발동<br><br>공격 시 적에게 10초 동안 중독/화상 상태 ...";
lv105OptionMap["향기로운 환희"] = "최종 데미지 10% 증가<br><br>[오감의 황홀경]<br>융합된 장비의 강화/증폭 수치가 10에서 1 증가할 때마다 최종 데미지 1% 증가 (최대 2중첩)<br><br>공격 속도 +5%<br>캐스팅 속도 +7.5%";
lv105OptionMap["혈독초"] = "최종 데미지 10% 증가<br><br>[폭발 약속 - 출혈/중독]<br>출혈/중독 부여 시 해당 상태 이상과 동일한 데미지 폭발<br>- 전환 옵션으로 부여 시 미발동";
lv105OptionMap["혼돈이 담긴 소용돌이"] = "85Lv 스킬 공격력 30% 증가<br>85Lv 스킬 쿨타임 회복속도 +100% (버퍼 제외)";
lv105OptionMap["화룡 : 끓어오르는 용의 숨결"] = "스킬 쿨타임 4% 감소 (각성기 제외)";
lv105OptionMap["화룡 : 타오르는 불의 분노"] = "스킬 쿨타임 4% 감소 (각성기 제외)<br><br>[타오르는 용인의 분노]<br>분노가 발하는 불꽃을 몸에 둘러 공격력 증가 +130.2%<br>- 화룡 융합 장비 3종 장착 필요";
lv105OptionMap["화룡 : 폭발하는 힘의 근원"] = "스킬 쿨타임 4% 감소 (각성기 제외)";
lv105OptionMap["결속된 영혼"] = "최종 데미지 8% 증가<br><br>[결속된 영혼]<br>채팅으로 `결속` 입력 시 황금 큐브 조각 1개를 소모해 '결속된 영혼' 적용 (쿨타임 30초)<br>- 2초간 무적 및 행동 불가";
lv105OptionMap["인비시블 스톤"] = "최종 데미지 10% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>회피율 +20%";

lv105ItemId['결의의 전쟁 부츠'] = '54434a07c7440d002caf63348564e792';
lv105ItemId['무결한 마음'] = '4a2c34a19752cc33ea05719a1a1af22b';
lv105ItemId['무한한 영감'] = '6a9b0de6edb0cf582458e60e4d4e0ad4';
lv105ItemId['무회'] = '99595a440c8bc4d4e3ecd2b31bcb9ae8';
lv105ItemId['미지의 골격 링'] = 'c5f00b9187c24835bdee14435ad0e60b';
lv105ItemId['미지의 관절 신발'] = 'ab074e516b0ecdbb6a2a51ac9c6fdd6f';
lv105ItemId['미지의 근섬유 상의'] = '630cee68d05a48d75241a2001fa1e420';
lv105ItemId['미지의 금속 각반'] = 'ccb37b54a27cce218ebfb9f0330cb77e';
lv105ItemId['미지의 기계 장치 벨트'] = '5d7f67ad1702fb304e99ad183a246ae1';
lv105ItemId['미지의 눈동자 네클리스'] = '88a2212d316e3961c647ffd60b437dad';
lv105ItemId['미지의 바이탈 시그널'] = '8499c250c24f9d8f53016d0f6870d669';
lv105ItemId['미지의 보석 숄더'] = '899f1f5e8a407d8bf933a53b3fe97a9f';
lv105ItemId['미지의 비늘 암릿'] = 'b585194bfa558e19d8d0f60be4a9f0b5';
lv105ItemId['미지의 에너지 스피어'] = '8b84ff87d5622d23c662f8b1c3ba9174';
lv105ItemId['미지의 파워 사이드'] = 'ded72f0a644f4635622f9d13295ae5ef';
lv105ItemId['순례자를 억압하는 시련'] = '21a80519f85b65c37ac649993feef9fd';
lv105ItemId['순례자를 얽매는 고난'] = 'cc7b53fb9baf760f36a676093fed4e9c';
lv105ItemId['순례자를 짓누르는 무게'] = 'b8612c9cb653813d5374509814c8302a';
lv105ItemId['순례자의 길을 비추는 빛'] = '54647a0b31116f5de1280cbe8ac4c331';
lv105ItemId['순례자의 나무 요대'] = '6a566161ad56225743a4a963f0c98988';
lv105ItemId['순례자의 나아가는 의지'] = '74121e6767adeff56c70aebc4d33951d';
lv105ItemId['순례자의 미래를 보는 시선'] = 'fbc47f171d1c439a6facb8556b6c760b';
lv105ItemId['순례자의 사막 부츠'] = 'ce0ad0fc10f1a250fa65b04064bc6fd0';
lv105ItemId['순례자의 어깨 보호대'] = 'fc1f196449a6cb926c3cb5d5ed34311f';
lv105ItemId['순례자의 카고 바지'] = 'b6c01a4d3c205f2efd69f1e854807dc4';
lv105ItemId['순례자의 해진 바람 막이'] = 'c1e0b48b93bfa5f2827061e436d8eae3';
lv105ItemId['신뢰의 황금 복대'] = '0c6f3021af852992193db36566518f34';
lv105ItemId['연합의 지휘자 망토'] = 'f48b32aeb5d299962d22594c330659e5';
lv105ItemId['전략가의 정교한 전투 바지'] = '9c2146d90b3e650fcebeca17933191a4';
lv105ItemId['조화의 이치'] = 'af771a91c229122b3e063f0ba9742404';
lv105ItemId['지휘관의 명주 상의'] = 'c36306968cbf790e10a71788db04eabc';
lv105ItemId['총사령관의 결단'] = '2aa9f3d419d4fbaa00aa105e60bac5dc';
lv105ItemId['총사령관의 독도법 나침반'] = '256e2b481f5219ee3cb0f06bd50a8914';
lv105ItemId['총사령관의 비장의 한발'] = '49741786ddd9f079e4e5ad031b495829';
lv105ItemId['총사령관의 섬멸 작전'] = '5d20e2e5b42a7d1c4c4b91f00548540c';
lv105ItemId['총사령관의 의무'] = 'abbc3650c2f9c8d339de83f1d6013b70';
lv105ItemId['총사령관의 전술 지도'] = 'f5c175720b3cb28f324a6ba587ee3987';
lv105ItemId['총사령관의 전우가 남긴 유품'] = '5d7769bb38e6ce5a3ceac9b27472972a';
lv105ItemId['총사령관의 제복 상의'] = '50ad1eb38bbd4eba87cdaeba1d9cde6a';
lv105ItemId['총사령관의 제복 하의'] = '2cb99a73be07bcb60d475f0d486609cf';
lv105ItemId['총사령관의 지휘'] = '5b83936113ac64b18c31a4f4a6908119';
lv105ItemId['총사령관의 초심'] = 'c668543905d27252dd1e54850593ba2f';
lv105ItemId['포지 코어 펜던트'] = '11407b3eb8e4290c674061ddf02de3ef';
lv105ItemId['흔적이 새겨진 돌'] = 'bf9650ac2229688d3e00aaed8dd513ed';



lv105ItemId['土 : 넘어선 기억의 건틀릿'] = '704842f9852360c8007f338c4319f5be';
lv105ItemId['土 : 넘어선 기억의 광검'] = '4dbf7f91fccb84db96db3fc53319cd37';
lv105ItemId['土 : 넘어선 기억의 광창'] = '26f7840af40316d24c864aac531f0986';
lv105ItemId['土 : 넘어선 기억의 권투글러브'] = 'e883c0472aa5a66d1b66e7bc150eba78';
lv105ItemId['土 : 넘어선 기억의 낫'] = '7d412f1f9f7f900bb2d5c3f24fc1bc58';
lv105ItemId['土 : 넘어선 기억의 너클'] = 'eb35a01e97dbba2a59d88d846d414a60';
lv105ItemId['土 : 넘어선 기억의 단검'] = '540a184eb490d058bd355601f36ba260';
lv105ItemId['土 : 넘어선 기억의 대검'] = '66a9b96961e5999d54bd524426050e49';
lv105ItemId['土 : 넘어선 기억의 도'] = '9aa4ae5de7e4cf95f1522a1d89505994';
lv105ItemId['土 : 넘어선 기억의 둔기'] = '6e4929dd2730a68442b4adea21935cdb';
lv105ItemId['土 : 넘어선 기억의 로드'] = 'debf0b2fe38e19499acddbddeed07f40';
lv105ItemId['土 : 넘어선 기억의 리볼버'] = '902326b28cf6d2d5775a218141cf80c1';
lv105ItemId['土 : 넘어선 기억의 머스켓'] = '503952bc2b7e33ea1926377199fec7e0';
lv105ItemId['土 : 넘어선 기억의 미늘창'] = '3aa80fed82833661c4966c54bf41586b';
lv105ItemId['土 : 넘어선 기억의 배틀액스'] = '48d81a028321d2e1c5ebeed24ccad908';
lv105ItemId['土 : 넘어선 기억의 보우건'] = '38e38fc1c6fddc390a04ddfb3f1d5d65';
lv105ItemId['土 : 넘어선 기억의 봉'] = '47bd4b051ccb9c04a0e83ce362898d5e';
lv105ItemId['土 : 넘어선 기억의 빗자루'] = 'f6f0526d1568046d39174c61a2cc06bc';
lv105ItemId['土 : 넘어선 기억의 선현궁'] = '6e288e134cb28d38151b219787d31f79';
lv105ItemId['土 : 넘어선 기억의 소검'] = '568aee24928857e7ccc197d7917bd6c0';
lv105ItemId['土 : 넘어선 기억의 소태도'] = '3db37b3f747d314fad4a56913c6f0a7a';
lv105ItemId['土 : 넘어선 기억의 스태프'] = '6f384722166cbee12300281df31a1553';
lv105ItemId['土 : 넘어선 기억의 십자가'] = '8f827da7da29df6ab1871ba001cf0d00';
lv105ItemId['土 : 넘어선 기억의 쌍검'] = 'd18d63803627cedf535987a910b6d32f';
lv105ItemId['土 : 넘어선 기억의 에테리얼 보우'] = '55c2e4d94f1f3849448181f0389fb58a';
lv105ItemId['土 : 넘어선 기억의 염주'] = '06d38ace60c3a7baa758c75a684d5c32';
lv105ItemId['土 : 넘어선 기억의 완드'] = '248eeddf6622c10a644616b1f4dcb878';
lv105ItemId['土 : 넘어선 기억의 자동권총'] = 'd715920f7944afcf3dffc3ef2c5ab746';
lv105ItemId['土 : 넘어선 기억의 장궁'] = '8bc3a7a3467e2205fd1736801a54e26a';
lv105ItemId['土 : 넘어선 기억의 장도'] = '5785ead30b5f395fa069676e5f9c29d6';
lv105ItemId['土 : 넘어선 기억의 장창'] = 'f754736d89863dc4ec648bd868826603';
lv105ItemId['土 : 넘어선 기억의 중검'] = '941f2b0ee459613dd8e06c65fc1ddf11';
lv105ItemId['土 : 넘어선 기억의 차크라 웨펀'] = '52f66e2e8eaed2b33256da597631e651';
lv105ItemId['土 : 넘어선 기억의 창'] = '2f2de6e57230feab2898fc1929770c4a';
lv105ItemId['土 : 넘어선 기억의 코어 블레이드'] = '8f72d7790fded77f9916c3e31016d8bf';
lv105ItemId['土 : 넘어선 기억의 크로스슈터'] = '98fa0e70dac6a704d0a5dec69a7d2e7d';
lv105ItemId['土 : 넘어선 기억의 클로'] = '91edad554c4949671d32d337d9f6346f';
lv105ItemId['土 : 넘어선 기억의 토템'] = 'ebeecdc130d59f8058d16f7ad087851c';
lv105ItemId['土 : 넘어선 기억의 통파'] = '10c52deca5de0f459e65b8da4b39376e';
lv105ItemId['土 : 넘어선 기억의 투창'] = '3330933d504af18ae3a058e4c30a9207';
lv105ItemId['土 : 넘어선 기억의 핸드캐넌'] = '807f9a2613b5abe50cddc8b7ea68c1bc';
lv105ItemId['土 : 따뜻한 봄날의 건틀릿'] = '558856facaa3e9d8aabd81d6fb537588';
lv105ItemId['土 : 따뜻한 봄날의 광검'] = '5db5c8b513dc624e9d90485e64f2527c';
lv105ItemId['土 : 따뜻한 봄날의 광창'] = '4dd1cded7569dc1516abc4b60287c8b3';
lv105ItemId['土 : 따뜻한 봄날의 권투글러브'] = 'e3889fb8ab9cdb48f8066b440700f787';
lv105ItemId['土 : 따뜻한 봄날의 낫'] = '290da6fb4325cf1b7291be3a57227026';
lv105ItemId['土 : 따뜻한 봄날의 단검'] = '1cef38001b9c878eefed22ede03df748';
lv105ItemId['土 : 따뜻한 봄날의 대검'] = 'ff6e3034e7f6cf8779b225584110c49f';
lv105ItemId['土 : 따뜻한 봄날의 도'] = 'fb4084ff6999a07359ad9cadafc2d566';
lv105ItemId['土 : 따뜻한 봄날의 둔기'] = '92aefba8d94b5b4d5bba485d8571e117';
lv105ItemId['土 : 따뜻한 봄날의 로드'] = 'a7bdc439100dd14b439ca6f41816ce8c';
lv105ItemId['土 : 따뜻한 봄날의 리볼버'] = '33b9a73186a7cc4990fa5bedd0b62024';
lv105ItemId['土 : 따뜻한 봄날의 머스켓'] = '432acec7b6a1d302ace4102e400427d8';
lv105ItemId['土 : 따뜻한 봄날의 미늘창'] = 'b2a4b75ded42fab7ba54f3042fc8c746';
lv105ItemId['土 : 따뜻한 봄날의 배틀액스'] = 'd63d5b09a79119e83c878773257d39c1';
lv105ItemId['土 : 따뜻한 봄날의 보우건'] = 'd688c8fe9d99676767f38bfb22d9049a';
lv105ItemId['土 : 따뜻한 봄날의 봉'] = '9922e6ea3ef4c8613afc704b8464dd12';
lv105ItemId['土 : 따뜻한 봄날의 빗자루'] = '615096a948ef9eec1f17fe87732f9710';
lv105ItemId['土 : 따뜻한 봄날의 선현궁'] = '710b30fe33ffc837f475162a5b311168';
lv105ItemId['土 : 따뜻한 봄날의 소검'] = '3bd373b07f150a68ef21dd4d635846df';
lv105ItemId['土 : 따뜻한 봄날의 소태도'] = '48a9465fe6067e540f66ef11fc4853f8';
lv105ItemId['土 : 따뜻한 봄날의 스태프'] = '2ede3498576ed5e5e742f17ecab6c804';
lv105ItemId['土 : 따뜻한 봄날의 십자가'] = '9310d3964ffbbc878e0abb7a7844bf45';
lv105ItemId['土 : 따뜻한 봄날의 쌍검'] = 'b01dc49a4a0ef904d0aa062424d8ba8d';
lv105ItemId['土 : 따뜻한 봄날의 에테리얼 보우'] = '77d6a78f5f37409bf818c873ede46856';
lv105ItemId['土 : 따뜻한 봄날의 염주'] = '498aa847e398b5938855a407f666a831';
lv105ItemId['土 : 따뜻한 봄날의 완드'] = 'c38bf15b0eabb5c3869c4ec1aa21f617';
lv105ItemId['土 : 따뜻한 봄날의 자동권총'] = '4f466716ced614322ad2c9d28c5b4262';
lv105ItemId['土 : 따뜻한 봄날의 장궁'] = '9272470724bfd570fdfed1139c208886';
lv105ItemId['土 : 따뜻한 봄날의 장도'] = '85bffe20b93b5b4493fa2814a5f8d23c';
lv105ItemId['土 : 따뜻한 봄날의 장창'] = '425cc95528bf8c9a89dfa9e169f4cce8';
lv105ItemId['土 : 따뜻한 봄날의 중검'] = '360d049628c2285e6ba033c75fb962d6';
lv105ItemId['土 : 따뜻한 봄날의 차크라 웨펀'] = '5e4107f9f89ba7a42a4045271d83bb00';
lv105ItemId['土 : 따뜻한 봄날의 창'] = '52d150d345f59471a4d45d755212a9bf';
lv105ItemId['土 : 따뜻한 봄날의 코어 블레이드'] = 'ecfd74b03c1627dabc28b50e7865e94c';
lv105ItemId['土 : 따뜻한 봄날의 크로스슈터'] = 'f2ef86db88f280e6999600998f0c5816';
lv105ItemId['土 : 따뜻한 봄날의 클로'] = 'b8e563ee274873d34f907fbf7f2f286e';
lv105ItemId['土 : 따뜻한 봄날의 토템'] = 'c600b03d34c2e97effc4ad4cee5cf217';
lv105ItemId['土 : 따뜻한 봄날의 통파'] = '3476501835ab240d269d7f9ace9e5acc';
lv105ItemId['土 : 따뜻한 봄날의 투창'] = '04fd11ff1bc5aa280c3e0a6250cd5ab0';
lv105ItemId['土 : 따뜻한 봄날의 핸드캐넌'] = '037a1087d4e0ec3fef968b1b45c712d7';
lv105ItemId['木 : 그늘진 새벽의 건틀릿'] = '09a8dd234a6d634966ac5747a4b5c898';
lv105ItemId['木 : 그늘진 새벽의 광검'] = '2a272673f0588b83777ac8fb0456ea9d';
lv105ItemId['木 : 그늘진 새벽의 광창'] = '31140e6d5303c68b9f5a1915228ff87b';
lv105ItemId['木 : 그늘진 새벽의 권투글러브'] = '7a9f8a225a109bc29d6b6d1c90c05f56';
lv105ItemId['木 : 그늘진 새벽의 낫'] = 'b5b37b9d734ad322e604ebf3d47c687a';
lv105ItemId['木 : 그늘진 새벽의 너클'] = 'd337001ff1db936e658b123e58fbd03a';
lv105ItemId['木 : 그늘진 새벽의 단검'] = '052034ff0eb8174210c72b2e63bbeeea';
lv105ItemId['木 : 그늘진 새벽의 대검'] = 'f13834672310e5d98fad61c76f1167d9';
lv105ItemId['木 : 그늘진 새벽의 도'] = '0ea4e9935592973fa9af6e7bd7d310a1';
lv105ItemId['木 : 그늘진 새벽의 둔기'] = '52df3b0ac44eca075e74c02c7dc50ec3';
lv105ItemId['木 : 그늘진 새벽의 로드'] = '7fe76ff34883214344b9abf64f413658';
lv105ItemId['木 : 그늘진 새벽의 리볼버'] = '534b21ff061f87fbd8804113d4605e2e';
lv105ItemId['木 : 그늘진 새벽의 머스켓'] = '94aae131c10dff3313407de4798508f7';
lv105ItemId['木 : 그늘진 새벽의 미늘창'] = 'bd41dadb543e0e5e88796da21b5f2197';
lv105ItemId['木 : 그늘진 새벽의 배틀액스'] = 'e318ea802ea99fe63594d0d56a7f6062';
lv105ItemId['木 : 그늘진 새벽의 보우건'] = 'edc099cecc33e462c13e71a514eeb4b0';
lv105ItemId['木 : 그늘진 새벽의 봉'] = '57186d7a3912ff473d5ca89285e146f5';
lv105ItemId['木 : 그늘진 새벽의 빗자루'] = '6f054aafac9f043fd64fb9bffe34aea1';
lv105ItemId['木 : 그늘진 새벽의 선현궁'] = '06319d4f709374b0ba13fd0191f7afb3';
lv105ItemId['木 : 그늘진 새벽의 소검'] = 'cc94599a13d1644e5cdd2457ddff24ba';
lv105ItemId['木 : 그늘진 새벽의 소태도'] = '3c2c2533ff8f25eb67cc69ef05b7645e';
lv105ItemId['木 : 그늘진 새벽의 스태프'] = '5573f99d4d72958d59e6f6670a707a3f';
lv105ItemId['木 : 그늘진 새벽의 십자가'] = '6bab3b2dfaa500ad3d606f3d8c40668d';
lv105ItemId['木 : 그늘진 새벽의 쌍검'] = '95dbe5dbcf8533870cf41928ec9012e5';
lv105ItemId['木 : 그늘진 새벽의 에테리얼 보우'] = '5ce77ab723b0d541d9716365c63a6466';
lv105ItemId['木 : 그늘진 새벽의 염주'] = '1b5ff14f6cb1d9ac310b452a3870dc02';
lv105ItemId['木 : 그늘진 새벽의 완드'] = '77098b13ea84b2b86d7658e6faa0cf0d';
lv105ItemId['木 : 그늘진 새벽의 자동권총'] = '64d2280bdf5e7d9f5a6bc3a634e2d73e';
lv105ItemId['木 : 그늘진 새벽의 장궁'] = '211a39c4246a204612d26b258c543cb1';
lv105ItemId['木 : 그늘진 새벽의 장도'] = 'a75fb84bccdf63c794d057304fc7c25b';
lv105ItemId['木 : 그늘진 새벽의 장창'] = '3506a203bb7045d1ab508bf0f8f3a223';
lv105ItemId['木 : 그늘진 새벽의 중검'] = '1bb5feec88ab9d853f6a94d57db3925d';
lv105ItemId['木 : 그늘진 새벽의 차크라 웨펀'] = '2220e9c0e6126989157886076e1abc42';
lv105ItemId['木 : 그늘진 새벽의 창'] = 'bb4d9fe6ca17dda1e0ba598f3951cc48';
lv105ItemId['木 : 그늘진 새벽의 코어 블레이드'] = '786fa46eb0972b3f2d898c57043d736e';
lv105ItemId['木 : 그늘진 새벽의 크로스슈터'] = '3e18195b38ad59e0b48047cda99b44bc';
lv105ItemId['木 : 그늘진 새벽의 클로'] = '1d1cd60bf3820c1612e160234026f690';
lv105ItemId['木 : 그늘진 새벽의 토템'] = '2c1e4bc241657354a14f5593c4f48be6';
lv105ItemId['木 : 그늘진 새벽의 통파'] = '6682bdb5e82710c40d0ad6285fa85dd5';
lv105ItemId['木 : 그늘진 새벽의 투창'] = '656c61a6645cf63e686678156ed27e07';
lv105ItemId['木 : 그늘진 새벽의 핸드캐넌'] = '1a57393a1f313e2173285c73873668d7';
lv105ItemId['木 : 넘어선 기억의 건틀릿'] = '12d440a02654dccc1a7fc0530661b929';
lv105ItemId['木 : 넘어선 기억의 광검'] = '224601cc08eb5962d4b4bbfff0147c75';
lv105ItemId['木 : 넘어선 기억의 광창'] = 'cfd3dc9cdf6b0df78bb02138cd4f47e2';
lv105ItemId['木 : 넘어선 기억의 권투글러브'] = '10ab6c5f0ee507be71d453039b5fc2ec';
lv105ItemId['木 : 넘어선 기억의 낫'] = '5cdc830e5415a8ff3e6f889bb2d8346f';
lv105ItemId['木 : 넘어선 기억의 너클'] = 'b34cf5a099b3ada495599ede866e6d6e';
lv105ItemId['木 : 넘어선 기억의 단검'] = 'b8b03512443591a1dc69bd62ea121cab';
lv105ItemId['木 : 넘어선 기억의 대검'] = '8da5c361d0767f2bf540bed6a0af789e';
lv105ItemId['木 : 넘어선 기억의 도'] = '962192e3d7c1675610c5c0fbabc3083b';
lv105ItemId['木 : 넘어선 기억의 둔기'] = '0b5d2507e46f7260a443a78d3dcf006b';
lv105ItemId['木 : 넘어선 기억의 로드'] = '27429582d1a2dce4381ba501b75813d2';
lv105ItemId['木 : 넘어선 기억의 리볼버'] = 'b5856f1bd84f8998e151325d5720271a';
lv105ItemId['木 : 넘어선 기억의 머스켓'] = 'fc00b451911f097530e5ccd44d145e7f';
lv105ItemId['木 : 넘어선 기억의 미늘창'] = 'b7a0a906078209eb91d9c1425f3e3135';
lv105ItemId['木 : 넘어선 기억의 배틀액스'] = '559fa27b1d4c041bf82b9e083be5eb6f';
lv105ItemId['木 : 넘어선 기억의 보우건'] = '84d111139c100721a6dba6e22a9bea37';
lv105ItemId['木 : 넘어선 기억의 봉'] = 'c9cf0c2b42260ef3d8959df4afefc92f';
lv105ItemId['木 : 넘어선 기억의 빗자루'] = '001c97f13be64bcd77e10d612d7854dc';
lv105ItemId['木 : 넘어선 기억의 선현궁'] = 'b12fda909a18733ad9d4f45b3c45c132';
lv105ItemId['木 : 넘어선 기억의 소검'] = '34afb47b51c625a73463c3c865a8339c';
lv105ItemId['木 : 넘어선 기억의 소태도'] = '4d166490c2dca7d6eb19997efa8250e0';
lv105ItemId['木 : 넘어선 기억의 스태프'] = '64c72c8845090ac6a794688a22c228a5';
lv105ItemId['木 : 넘어선 기억의 십자가'] = 'a024b1db47add200eed095c3de9a95e3';
lv105ItemId['木 : 넘어선 기억의 쌍검'] = '9d4d90cf2d4779006501178e89759813';
lv105ItemId['木 : 넘어선 기억의 에테리얼 보우'] = '2b448a959ad7f0205968aee89f994969';
lv105ItemId['木 : 넘어선 기억의 염주'] = 'dc2fb79409bb5e1eeb2b046abc7f8314';
lv105ItemId['木 : 넘어선 기억의 완드'] = '436b8b77bd1d8d5654a4c60badd3884c';
lv105ItemId['木 : 넘어선 기억의 자동권총'] = '2b06f353c0e9c80b8343b269cc0c458a';
lv105ItemId['木 : 넘어선 기억의 장궁'] = 'd8414a876b7c0ef6faa0d9fa61c35dbb';
lv105ItemId['木 : 넘어선 기억의 장도'] = '0ba00ca0e3bcf41a95fbc6ca4d540afd';
lv105ItemId['木 : 넘어선 기억의 장창'] = 'f99ae3be6d978acca003986cf35f5491';
lv105ItemId['木 : 넘어선 기억의 중검'] = 'b9313df3b8099da968b93b269592fba4';
lv105ItemId['木 : 넘어선 기억의 차크라 웨펀'] = '6dbcfbed7c1bfa5436f1f429dee6cb8e';
lv105ItemId['木 : 넘어선 기억의 창'] = 'f314b778d47164c1d6d6ffa7c9502363';
lv105ItemId['木 : 넘어선 기억의 코어 블레이드'] = '9926e6f09fe1380ce0825877036489de';
lv105ItemId['木 : 넘어선 기억의 크로스슈터'] = 'fc17ebc92df69de623ad066e26c63846';
lv105ItemId['木 : 넘어선 기억의 클로'] = 'a8691acca985e9e21b39fb21a1eeaea9';
lv105ItemId['木 : 넘어선 기억의 토템'] = '62b42334e083c844e496cc0dc28f0828';
lv105ItemId['木 : 넘어선 기억의 통파'] = 'bb92e0b1123d6ce08787af93e0ca42bd';
lv105ItemId['木 : 넘어선 기억의 투창'] = 'f9e299a4001a6302db2d34d9f0c17bd5';
lv105ItemId['木 : 넘어선 기억의 핸드캐넌'] = '7811d81c8fe73e1767b6bc268d0eb0e1';
lv105ItemId['水 : 넘어선 기억의 건틀릿'] = '435f91befbb12afb3628722e3682af3d';
lv105ItemId['水 : 넘어선 기억의 광검'] = 'a27374acf81bb93e7294d7a67839cb29';
lv105ItemId['水 : 넘어선 기억의 광창'] = 'efd28017c156caab0da572da37983aae';
lv105ItemId['水 : 넘어선 기억의 권투글러브'] = 'fcd14f10252377393530bcf16abd82d1';
lv105ItemId['水 : 넘어선 기억의 낫'] = '71d7600dd052d738c4ead9d1cba77080';
lv105ItemId['水 : 넘어선 기억의 너클'] = 'a3397519407caf2b409baf7dac338699';
lv105ItemId['水 : 넘어선 기억의 단검'] = '0808e91d8576721bbd8a45726b3472a0';
lv105ItemId['水 : 넘어선 기억의 대검'] = 'd344023551269672661bc287b66bf77e';
lv105ItemId['水 : 넘어선 기억의 도'] = 'd498f2f192596084ddd888090877b9df';
lv105ItemId['水 : 넘어선 기억의 둔기'] = '02617f034eae36ba4a6a20696f2e3fc2';
lv105ItemId['水 : 넘어선 기억의 로드'] = 'dd0e447e4ad67ee569ccc315090dffb5';
lv105ItemId['水 : 넘어선 기억의 리볼버'] = '93e6e1d4095ea21db92530e1ad44efe0';
lv105ItemId['水 : 넘어선 기억의 머스켓'] = '18bfb7fc0e97eefd20574862362b6091';
lv105ItemId['水 : 넘어선 기억의 미늘창'] = '91e4dd4b98459f5d7f1f3b49e23adb38';
lv105ItemId['水 : 넘어선 기억의 배틀액스'] = '1fc0d075624285bf50c151125e15a9a4';
lv105ItemId['水 : 넘어선 기억의 보우건'] = '12d78d4ea24e4f132b2db7c607b0ca98';
lv105ItemId['水 : 넘어선 기억의 봉'] = '59ffa3dee455045581ee1baf95f8db81';
lv105ItemId['水 : 넘어선 기억의 빗자루'] = 'c1fa2ec5540b6df6ca78710b9d40b67c';
lv105ItemId['水 : 넘어선 기억의 선현궁'] = '40e2f54f9bd6c6ef8151cb00a38badc5';
lv105ItemId['水 : 넘어선 기억의 소검'] = 'a3d955508749efaac7e06cea34bc8cc4';
lv105ItemId['水 : 넘어선 기억의 소태도'] = 'ce27e3b7f8ba69dcf39e8d2fc494cee8';
lv105ItemId['水 : 넘어선 기억의 스태프'] = 'b124f503d1339fa032e53db275ccf843';
lv105ItemId['水 : 넘어선 기억의 십자가'] = 'bc3058f686c3cf09cac4763addd1d0dd';
lv105ItemId['水 : 넘어선 기억의 쌍검'] = '5d32b6381fd03b6883fc65548698c2a4';
lv105ItemId['水 : 넘어선 기억의 에테리얼 보우'] = '554e21e09629cde196d11f8bf97a2b68';
lv105ItemId['水 : 넘어선 기억의 염주'] = 'b9505afe6cb320d2fb7183805f0f2ba2';
lv105ItemId['水 : 넘어선 기억의 완드'] = '216b02947f53a2cdf6179292943e9146';
lv105ItemId['水 : 넘어선 기억의 자동권총'] = 'd530613dccd6282e05e2bc5d85238475';
lv105ItemId['水 : 넘어선 기억의 장궁'] = '360833ec2fbf29c8d02ba491cfe56e13';
lv105ItemId['水 : 넘어선 기억의 장도'] = '1a2e9fab5fca88f0cdf9316a1fe861b4';
lv105ItemId['水 : 넘어선 기억의 장창'] = 'a451f04830228c8a1dff43f79286345b';
lv105ItemId['水 : 넘어선 기억의 중검'] = '7c55873016df9fbd3aef43d235499a93';
lv105ItemId['水 : 넘어선 기억의 차크라 웨펀'] = '56762877db9b5d8e415d7f634e45aa78';
lv105ItemId['水 : 넘어선 기억의 창'] = '5a81ab35bf3714a6e82ed6aa17e3c76f';
lv105ItemId['水 : 넘어선 기억의 코어 블레이드'] = '4b4a18165e5ee2167769f91517347d42';
lv105ItemId['水 : 넘어선 기억의 크로스슈터'] = 'a97415c94d184ac721560f415307b5a0';
lv105ItemId['水 : 넘어선 기억의 클로'] = 'b0618bf32e7aebd577b9da613db25cf3';
lv105ItemId['水 : 넘어선 기억의 토템'] = 'df4c451df7bcebf66ea277ec38d9ac51';
lv105ItemId['水 : 넘어선 기억의 통파'] = 'b734372029e1c278787d5fe27e123e6e';
lv105ItemId['水 : 넘어선 기억의 투창'] = '79fbc5079d7a790901bddc097bb8a605';
lv105ItemId['水 : 넘어선 기억의 핸드캐넌'] = '4517d34cae57237bf6ef0d829483b06b';
lv105ItemId['水 : 오염된 눈의 건틀릿'] = '765abe1851ad96e90caefe3654420184';
lv105ItemId['水 : 오염된 눈의 광검'] = 'e437d72f7230383dac0ec266ac61e3f5';
lv105ItemId['水 : 오염된 눈의 광창'] = '8a0938701f68654326594f2c82db1d0a';
lv105ItemId['水 : 오염된 눈의 권투글러브'] = '39f90317639ecea7f1731e0c3f40de08';
lv105ItemId['水 : 오염된 눈의 낫'] = '3abb918132b3f693459f5a8fefb1a3d0';
lv105ItemId['水 : 오염된 눈의 너클'] = 'cf1b2ac0e06145cb458b427824f39ad9';
lv105ItemId['水 : 오염된 눈의 단검'] = 'f3234cb52896aad09a7dfa520ef3add6';
lv105ItemId['水 : 오염된 눈의 대검'] = '57d846c2d60ac94add2942e59cbcd331';
lv105ItemId['水 : 오염된 눈의 도'] = '5278fbd2f853963b34ec64bd434516da';
lv105ItemId['水 : 오염된 눈의 둔기'] = '3ac90238fcc60dc00c47f07c2991bd89';
lv105ItemId['水 : 오염된 눈의 로드'] = 'dcb9c1e1693ac86dbf117b71f025b0ff';
lv105ItemId['水 : 오염된 눈의 리볼버'] = 'a0255d6d8a27cfa7936e5cc59b94052a';
lv105ItemId['水 : 오염된 눈의 머스켓'] = 'ae8af035601203e3b9b4380a6968c5b7';
lv105ItemId['水 : 오염된 눈의 미늘창'] = '95f5907f178b3d70372faddfe9256cd3';
lv105ItemId['水 : 오염된 눈의 배틀액스'] = '7b67b16374cedee759af617e98c3a0a5';
lv105ItemId['水 : 오염된 눈의 보우건'] = 'cbfbddf057e5c3588bf645774e36ecd5';
lv105ItemId['水 : 오염된 눈의 봉'] = '58745c520972dc8b096846eda5c3fd32';
lv105ItemId['水 : 오염된 눈의 빗자루'] = 'a1f70e219bfb4ca5ba4e9c631173e555';
lv105ItemId['水 : 오염된 눈의 선현궁'] = 'ee0a915103b945065f5cb27494640239';
lv105ItemId['水 : 오염된 눈의 소검'] = '4f0f6cb2f8fcf087ac20898cc4c0c495';
lv105ItemId['水 : 오염된 눈의 소태도'] = '354f75a0fdd2f9397fb3de16933c49e9';
lv105ItemId['水 : 오염된 눈의 스태프'] = '964c1da569f4fc32f20f7f88f8391f5e';
lv105ItemId['水 : 오염된 눈의 십자가'] = 'd65986b84e939ed7bafbe1b7efd209b6';
lv105ItemId['水 : 오염된 눈의 쌍검'] = 'd521d7983cc193bc664f2a51d43126ad';
lv105ItemId['水 : 오염된 눈의 에테리얼 보우'] = '1491a708bc1151dae09e183c293796e3';
lv105ItemId['水 : 오염된 눈의 염주'] = 'c5ad0f735fc17db48494e26336a4fccf';
lv105ItemId['水 : 오염된 눈의 완드'] = '5af8d155eee2d6a286702930acb80448';
lv105ItemId['水 : 오염된 눈의 자동권총'] = '3e4a0543e3f4d6d06ff453559191cce3';
lv105ItemId['水 : 오염된 눈의 장궁'] = '6c04d3c600276480fb480c6f94218a68';
lv105ItemId['水 : 오염된 눈의 장도'] = 'c4cc38df8890c38c3c4aea89232e5627';
lv105ItemId['水 : 오염된 눈의 장창'] = '1fa24e936e16c217fbb8130d3534a64d';
lv105ItemId['水 : 오염된 눈의 중검'] = '3066b0804c406b28e20c020800e68844';
lv105ItemId['水 : 오염된 눈의 차크라 웨펀'] = '4ae843f6ee26f8dec36b7e3df0ba1c4a';
lv105ItemId['水 : 오염된 눈의 창'] = '059c2d2f542cb0f6b9fbbdbbe44d7f52';
lv105ItemId['水 : 오염된 눈의 코어 블레이드'] = '04dac2602137b021660e28ed3b2410be';
lv105ItemId['水 : 오염된 눈의 크로스슈터'] = 'a733ebc01aedf4c02daec7679bd49dcc';
lv105ItemId['水 : 오염된 눈의 클로'] = '4b13af2662f3861baa384bc6274a3d77';
lv105ItemId['水 : 오염된 눈의 토템'] = '3250d19475edb81b964c4af2e24ab709';
lv105ItemId['水 : 오염된 눈의 통파'] = 'd782522b2a1f14f2ae59945d12c94f5f';
lv105ItemId['水 : 오염된 눈의 투창'] = 'dd32415aa0cef57bef2fc766c8a89700';
lv105ItemId['水 : 오염된 눈의 핸드캐넌'] = '2f6b07aa11ee3d4abda084388e39c894';
lv105ItemId['火 : 넘어선 기억의 건틀릿'] = 'a6d6b90f094848c180966594e3ca85ad';
lv105ItemId['火 : 넘어선 기억의 광검'] = 'bf7630ca64aef6f49773649eb45e9b30';
lv105ItemId['火 : 넘어선 기억의 광창'] = 'b9732d0a5c23b0096a89ce1ab576fc72';
lv105ItemId['火 : 넘어선 기억의 권투글러브'] = '660805c83fed1fcb2b2e37c46f192a69';
lv105ItemId['火 : 넘어선 기억의 낫'] = 'c77fe6bab8f0f2a63794f2d657c506ea';
lv105ItemId['火 : 넘어선 기억의 너클'] = '6d94a480ae697a9cf09b622b8bb4198a';
lv105ItemId['火 : 넘어선 기억의 단검'] = '8098c61736001f23fc97a01741fda935';
lv105ItemId['火 : 넘어선 기억의 대검'] = '7e7c4ee859b3d5caf168af6410c8a819';
lv105ItemId['火 : 넘어선 기억의 도'] = '909a2b5343cae0ed60d7eeee7710e066';
lv105ItemId['火 : 넘어선 기억의 둔기'] = '5c6ac37a42449cf23df02a8666d5f711';
lv105ItemId['火 : 넘어선 기억의 로드'] = '3c90d8660b4e744dc2d5094b338350ed';
lv105ItemId['火 : 넘어선 기억의 리볼버'] = '1630f908fb51c420a9ad273a4f32abe1';
lv105ItemId['火 : 넘어선 기억의 머스켓'] = '5af3604305d5c211b955fc6acee224ee';
lv105ItemId['火 : 넘어선 기억의 미늘창'] = '0c970197eb98978e45ead3198ead4475';
lv105ItemId['火 : 넘어선 기억의 배틀액스'] = 'df181f0cdd451e180a54a222ec293fd6';
lv105ItemId['火 : 넘어선 기억의 보우건'] = 'b33e4866a6a51c1c33993f204cc60ffa';
lv105ItemId['火 : 넘어선 기억의 봉'] = 'a95e4aa46c08d11556555e1fadc889ea';
lv105ItemId['火 : 넘어선 기억의 빗자루'] = '57aee931910b5cbb623e83473cf53a19';
lv105ItemId['火 : 넘어선 기억의 선현궁'] = '6386563c9874031e0a8c79e32a4739bd';
lv105ItemId['火 : 넘어선 기억의 소검'] = 'c6799fb0d78da3ddd933298cb5cf975a';
lv105ItemId['火 : 넘어선 기억의 소태도'] = '401ecc9f73e6c1c4dcbf82e904913dd6';
lv105ItemId['火 : 넘어선 기억의 스태프'] = '5cb63d1fa2002ab08f4f316df8aced1b';
lv105ItemId['火 : 넘어선 기억의 십자가'] = 'b7158c9bb19b4779a7bdbb1f4d9882c2';
lv105ItemId['火 : 넘어선 기억의 쌍검'] = '20a68b47a3e91c25ad8a9eb115fafd84';
lv105ItemId['火 : 넘어선 기억의 에테리얼 보우'] = 'd0f8b853b265ab1d9c75d850ff5c8c08';
lv105ItemId['火 : 넘어선 기억의 염주'] = '5e0b13576848d94aafdbf65bf826c683';
lv105ItemId['火 : 넘어선 기억의 완드'] = '8af7ee57d3ac6ba3206b05ea666c4e65';
lv105ItemId['火 : 넘어선 기억의 자동권총'] = 'b41e140763e42749a4caf502b53d2bc8';
lv105ItemId['火 : 넘어선 기억의 장궁'] = '66be90fcf0e4ad36f640403e9b3b55a3';
lv105ItemId['火 : 넘어선 기억의 장도'] = 'afb3fc181ff3e5fe2b883140ba8c7f68';
lv105ItemId['火 : 넘어선 기억의 장창'] = '0ce85d7d6eeb465a966995c941a1c46c';
lv105ItemId['火 : 넘어선 기억의 중검'] = 'd3a89f0e31c9908017104268f2f1b8f4';
lv105ItemId['火 : 넘어선 기억의 차크라 웨펀'] = '922fe5704824a5c4b5d37771dffe6ffe';
lv105ItemId['火 : 넘어선 기억의 창'] = '7ac4000fff7ee6d0bc253061ab8080c1';
lv105ItemId['火 : 넘어선 기억의 코어 블레이드'] = '4032e23d51986b830d122f330ac0d822';
lv105ItemId['火 : 넘어선 기억의 크로스슈터'] = '02576f07402e57b504e3c65e29fa4737';
lv105ItemId['火 : 넘어선 기억의 클로'] = '1ba03746896dd135cbc08fc93c9e6ecd';
lv105ItemId['火 : 넘어선 기억의 토템'] = '90de7ee2417755ec4e030a536eb724e8';
lv105ItemId['火 : 넘어선 기억의 통파'] = '666d2203b4d5587d18d01e1b2a4fd206';
lv105ItemId['火 : 넘어선 기억의 투창'] = '83a6915585753fe87510230f582d2ab6';
lv105ItemId['火 : 넘어선 기억의 핸드캐넌'] = 'fe7e8d77add23a700f7088ced75beed2';
lv105ItemId['火 : 불타는 고난의 소검'] = 'ec3dda572fb7cf2cea2aeb0f4df9bf6c';
lv105ItemId['火 : 불타는 고난의 건틀릿'] = 'fad859c197698c6e84b631c15933ef60';
lv105ItemId['火 : 불타는 고난의 광검'] = '42c7c12aa21ebe298e8020ca5ccf9ddf';
lv105ItemId['火 : 불타는 고난의 광창'] = '9258c1847db8b6afcad23fdf78abbebf';
lv105ItemId['火 : 불타는 고난의 권투글러브'] = '5dee362efcc7fe5135bd7b62a2ab31f6';
lv105ItemId['火 : 불타는 고난의 낫'] = 'bec61f5c35e680af964f2a9b32c436d9';
lv105ItemId['火 : 불타는 고난의 너클'] = 'f9a521e0c61ce64a56d50bc1a5b44264';
lv105ItemId['火 : 불타는 고난의 단검'] = '271d14a664ef0dd34e9d66abf9385c12';
lv105ItemId['火 : 불타는 고난의 대검'] = '6f1060f0474e320c30d7c4a3d82fa5ac';
lv105ItemId['火 : 불타는 고난의 도'] = '529933dbb3bffc5983febac606a1bacc';
lv105ItemId['火 : 불타는 고난의 둔기'] = '4ecfb0bb3f42b108201ca00b7cf76540';
lv105ItemId['火 : 불타는 고난의 로드'] = '7f4c0e5061eac0a2802d7f29683f11c3';
lv105ItemId['火 : 불타는 고난의 리볼버'] = '18e66972b107b187d0abfb59febbb619';
lv105ItemId['火 : 불타는 고난의 머스켓'] = 'b82174302b08f1e99b6ec9e0d8d81b4f';
lv105ItemId['火 : 불타는 고난의 미늘창'] = '9cf8c3cb42040c47f7e6e91a3f33fbd9';
lv105ItemId['火 : 불타는 고난의 배틀액스'] = 'eaca03bd451510e3852956247852dead';
lv105ItemId['火 : 불타는 고난의 보우건'] = 'a7acb4957f0bdcf3252ca493027ef27a';
lv105ItemId['火 : 불타는 고난의 봉'] = '4df33b0d5b7b754015551caa972eff49';
lv105ItemId['火 : 불타는 고난의 빗자루'] = '57af51d98bb2560538368b113f22a414';
lv105ItemId['火 : 불타는 고난의 선현궁'] = '407b647669edadb317a0ae3531ddee93';
lv105ItemId['火 : 불타는 고난의 소태도'] = '9e4da9e097f8d3a8d4434acb556b9874';
lv105ItemId['火 : 불타는 고난의 스태프'] = '2e3b76bd194b5269140fad021ee09a4e';
lv105ItemId['火 : 불타는 고난의 십자가'] = 'ac0eee03f425398a1843f4183387a33f';
lv105ItemId['火 : 불타는 고난의 쌍검'] = 'e899287512beea161bf46d5197abb594';
lv105ItemId['火 : 불타는 고난의 에테리얼 보우'] = '58e5ef73aeb98e003dcabbb1746a0545';
lv105ItemId['火 : 불타는 고난의 염주'] = '46a9c80d2f95a56efb1be8219e629172';
lv105ItemId['火 : 불타는 고난의 완드'] = 'cf33fd66557c90e2a19424bcc3a40060';
lv105ItemId['火 : 불타는 고난의 자동권총'] = '11363ff30f374214d9d247962dd0c485';
lv105ItemId['火 : 불타는 고난의 장궁'] = '7b6b7f324887be34244fbdfc567ffac6';
lv105ItemId['火 : 불타는 고난의 장도'] = '48792db26d5e476639f65ae447e28259';
lv105ItemId['火 : 불타는 고난의 장창'] = '2d6c4034b73c03f3778e85de58a1181f';
lv105ItemId['火 : 불타는 고난의 중검'] = 'feeb367077703819906319585e246ca8';
lv105ItemId['火 : 불타는 고난의 차크라 웨펀'] = '32fca24928d4a0f775f0a034b48f84e2';
lv105ItemId['火 : 불타는 고난의 창'] = '926023c0f1e01865f50ab0173a3a56b1';
lv105ItemId['火 : 불타는 고난의 코어 블레이드'] = 'bf24101435399c34f8a8cf639b48c3e4';
lv105ItemId['火 : 불타는 고난의 크로스슈터'] = '59de5b2446dfd6009073a50b93f9b74f';
lv105ItemId['火 : 불타는 고난의 클로'] = 'fb4fd34ad390962f3789ceebf40074fe';
lv105ItemId['火 : 불타는 고난의 토템'] = 'd4abc6832bb1f2842490064ff3afa0ed';
lv105ItemId['火 : 불타는 고난의 통파'] = 'e6284122fb058fa60c67b9424c128af0';
lv105ItemId['火 : 불타는 고난의 투창'] = '74d2b1f96e7e41faae21f68c59c00056';
lv105ItemId['火 : 불타는 고난의 핸드캐넌'] = 'd4dfd3e29fb6e28ccd862533c26ef01b';
lv105ItemId['부조화 : 무너진 경계의 건틀릿'] = '141553611b0a9706bb561267a34df2b8';
lv105ItemId['부조화 : 무너진 경계의 광검'] = '4bdd9822f916fb8889f32c64c8d41d36';
lv105ItemId['부조화 : 무너진 경계의 광창'] = '154fd33882d01feb0bf3c0c0fb88c2ae';
lv105ItemId['부조화 : 무너진 경계의 권투글러브'] = '37a4a7d41e3c31b0ab4a24246e182b15';
lv105ItemId['부조화 : 무너진 경계의 낫'] = 'ca267d589066ec64ea9e26d1bd577ea2';
lv105ItemId['부조화 : 무너진 경계의 너클'] = '75906054792e5e5c72e0d504b4667076';
lv105ItemId['부조화 : 무너진 경계의 단검'] = '42764a8ba2bcb7dd0c4edfc1e3af74b7';
lv105ItemId['부조화 : 무너진 경계의 대검'] = '1a4e7241a5d3d7d1780b0b855757856d';
lv105ItemId['부조화 : 무너진 경계의 도'] = '96f5b15a85950fc04458f8168f6251a2';
lv105ItemId['부조화 : 무너진 경계의 둔기'] = 'adeb2dd45af1c84e3c4600b39024dd50';
lv105ItemId['부조화 : 무너진 경계의 로드'] = '9926ee601ab79234fedffb7bcf5d0ec9';
lv105ItemId['부조화 : 무너진 경계의 리볼버'] = '045864725ede270045f5fabade423b50';
lv105ItemId['부조화 : 무너진 경계의 머스켓'] = '75dde36ed02d072496ee303b41ea6c1f';
lv105ItemId['부조화 : 무너진 경계의 미늘창'] = '94b8f7bc93da11cf4c876efb9a2d95ab';
lv105ItemId['부조화 : 무너진 경계의 배틀액스'] = 'cd5c89bc2b3fbd93713f59f3e189371f';
lv105ItemId['부조화 : 무너진 경계의 보우건'] = '83d414f6f931b5390b2e0773ce17a5c5';
lv105ItemId['부조화 : 무너진 경계의 봉'] = 'a5d78e2c13ab2536b15f546e5d52bb0a';
lv105ItemId['부조화 : 무너진 경계의 빗자루'] = 'c5aa2295410bf637c2fc02a5d2bc28ef';
lv105ItemId['부조화 : 무너진 경계의 선현궁'] = 'ed26f48a100d69a09976993cd4e3c18b';
lv105ItemId['부조화 : 무너진 경계의 소검'] = '7a190fe45033de260985e558ecd02915';
lv105ItemId['부조화 : 무너진 경계의 소태도'] = 'a18b1b9e4a2bc69aac71677a5c1d0801';
lv105ItemId['부조화 : 무너진 경계의 스태프'] = '8ba14e71568cd8fa78624298c56ef4f1';
lv105ItemId['부조화 : 무너진 경계의 십자가'] = '92909e904b7344e119ec6f6acb2d837b';
lv105ItemId['부조화 : 무너진 경계의 쌍검'] = 'd6dfd4676eab41724f5b19ee8178d34c';
lv105ItemId['부조화 : 무너진 경계의 에테리얼 보우'] = 'd7742e522c835353a3257b060b6d4c26';
lv105ItemId['부조화 : 무너진 경계의 염주'] = '8928435ff64b18375508a23b6014f7fe';
lv105ItemId['부조화 : 무너진 경계의 완드'] = '7c7aa70779f359f8f9213b8d41170452';
lv105ItemId['부조화 : 무너진 경계의 자동권총'] = '08e93d1a49159801d27e38ccc0c6270d';
lv105ItemId['부조화 : 무너진 경계의 장궁'] = 'bf958ec321d159ac4d86a6920a43513c';
lv105ItemId['부조화 : 무너진 경계의 장도'] = 'e0c509a2fe0b3207c6844a6679fbed3d';
lv105ItemId['부조화 : 무너진 경계의 장창'] = 'f87d5e99b52b3b424aefd20f512165d5';
lv105ItemId['부조화 : 무너진 경계의 중검'] = '257c01addac0ee06cc47459b0cd71670';
lv105ItemId['부조화 : 무너진 경계의 차크라 웨펀'] = 'ae75c723b626b97995015d9c6c6f7541';
lv105ItemId['부조화 : 무너진 경계의 창'] = '8ece5cf3175d99a4e1c39126fa90a9ca';
lv105ItemId['부조화 : 무너진 경계의 코어 블레이드'] = '8f738cafd8822ac4344164f4a4bc917d';
lv105ItemId['부조화 : 무너진 경계의 크로스슈터'] = 'd3ce9b45b5ff35b72825c5bc8edf40c0';
lv105ItemId['부조화 : 무너진 경계의 클로'] = '5378b86890eb643073061741687d61c3';
lv105ItemId['부조화 : 무너진 경계의 토템'] = '76cb71bc4c23c32e0a3f6986f35eae5d';
lv105ItemId['부조화 : 무너진 경계의 통파'] = '0bc63a13516c5678f772d01f8bc0434d';
lv105ItemId['부조화 : 무너진 경계의 투창'] = 'df1def3ed1c214812425e7f1e142d361';
lv105ItemId['부조화 : 무너진 경계의 핸드캐넌'] = '19ad2882eea72608322721d7d6e41891';
lv105ItemId['金 : 각인된 상처의 건틀릿'] = '978e12740ee7a2733e8230e9211e751d';
lv105ItemId['金 : 각인된 상처의 광검'] = '66961462c4589e9928889befe7e15d47';
lv105ItemId['金 : 각인된 상처의 광창'] = 'dc7bc3c6036dccb904e2e17a7b63b1f2';
lv105ItemId['金 : 각인된 상처의 권투글러브'] = 'e08310a7ebc1bca74c42bde881c19b63';
lv105ItemId['金 : 각인된 상처의 낫'] = '51ec6a2e2cd8f4ce04fceb9f9225a98b';
lv105ItemId['金 : 각인된 상처의 너클'] = '6ec474e7b2ac06127f47f2d563e7da9f';
lv105ItemId['金 : 각인된 상처의 단검'] = '0a7a412f4e5fc788b3156e54f801677f';
lv105ItemId['金 : 각인된 상처의 대검'] = '61bef886bd3c7c09800c0f7270cdf1ea';
lv105ItemId['金 : 각인된 상처의 도'] = 'd7310f705d3db6e111a629a46349d57f';
lv105ItemId['金 : 각인된 상처의 둔기'] = '7f1240e56d6e885172647ce39ac1dea1';
lv105ItemId['金 : 각인된 상처의 로드'] = 'de95c1a5fedb4d70ab953c77a6089165';
lv105ItemId['金 : 각인된 상처의 리볼버'] = 'f96237460cd35dfbe55a3c2cb3772215';
lv105ItemId['金 : 각인된 상처의 머스켓'] = '0ee6b724c4b119bc37fabe5a98f226ca';
lv105ItemId['金 : 각인된 상처의 미늘창'] = 'b0cfdbe466335bbb9596a65dbf3e2bc1';
lv105ItemId['金 : 각인된 상처의 배틀액스'] = 'bb1922f9bdf76f212be3f3e13f793b0c';
lv105ItemId['金 : 각인된 상처의 보우건'] = '8c1a2d6ae582852ae72e6f02163bfde0';
lv105ItemId['金 : 각인된 상처의 봉'] = 'fd45f450991b41834d3641db23b9e00b';
lv105ItemId['金 : 각인된 상처의 빗자루'] = '5e9da34d8591ef0b948a7a168ddd0053';
lv105ItemId['金 : 각인된 상처의 선현궁'] = '1a0b2b2f1183741cbf999cba9e59ff4f';
lv105ItemId['金 : 각인된 상처의 소검'] = '7a551778e5909015d32a3f3e647b7a88';
lv105ItemId['金 : 각인된 상처의 소태도'] = '18d4d09247473f64d0e7ff386a7df7f3';
lv105ItemId['金 : 각인된 상처의 스태프'] = '4c60f57c36fca83cad063499da69c06c';
lv105ItemId['金 : 각인된 상처의 십자가'] = '47c98490afe03e2da109c496550e7ad3';
lv105ItemId['金 : 각인된 상처의 쌍검'] = '66e6c3034cb627606525568fda9a3486';
lv105ItemId['金 : 각인된 상처의 에테리얼 보우'] = '75aec3921a1dbfe025b728a73e908c26';
lv105ItemId['金 : 각인된 상처의 염주'] = '88ca25f1eafed86c961718acc93eea01';
lv105ItemId['金 : 각인된 상처의 완드'] = '0f29ea264e947349eb8a91eb53d572f9';
lv105ItemId['金 : 각인된 상처의 자동권총'] = 'be6dab6e754fb866b418b4cc97cea28a';
lv105ItemId['金 : 각인된 상처의 장궁'] = '98e401799a3d4522ded7cab6baeb6bc6';
lv105ItemId['金 : 각인된 상처의 장도'] = '30f45309c09840680809e834956d3f60';
lv105ItemId['金 : 각인된 상처의 장창'] = '062b224d9a9a5d1762bc5a75fa987eef';
lv105ItemId['金 : 각인된 상처의 중검'] = '067cd6d6e62e7ee029ef3b7c3773163d';
lv105ItemId['金 : 각인된 상처의 차크라 웨펀'] = 'a242a1f0b63718802666d261bff5d755';
lv105ItemId['金 : 각인된 상처의 창'] = 'd9a96f59ddce1e63cdb1966e3c1b3dfc';
lv105ItemId['金 : 각인된 상처의 코어 블레이드'] = 'c02383c732f25a519fcb01fa116e6b0c';
lv105ItemId['金 : 각인된 상처의 크로스슈터'] = 'b58159c2e39aa2917a95a52b68dcf0de';
lv105ItemId['金 : 각인된 상처의 클로'] = 'e40e696b1899661556a303bd5834fc3f';
lv105ItemId['金 : 각인된 상처의 토템'] = '665d2adb9806b904ac5ad532731d0827';
lv105ItemId['金 : 각인된 상처의 통파'] = '3eca7b7e8992f26440f24987e47bf667';
lv105ItemId['金 : 각인된 상처의 투창'] = '76ef8656789960942b6d8324580a0781';
lv105ItemId['金 : 각인된 상처의 핸드캐넌'] = '5e096721586f33d9c6e41f03c9f009dc';
lv105ItemId['金 : 넘어선 기억의 건틀릿'] = 'b83934482f055746b95e98393e6e08ab';
lv105ItemId['金 : 넘어선 기억의 광검'] = 'e493025b759fb433b2b952570db3fd6d';
lv105ItemId['金 : 넘어선 기억의 광창'] = '402f295b84319c43763206f69acda587';
lv105ItemId['金 : 넘어선 기억의 권투글러브'] = 'fde3a2c07a4551ab562f49248a4848ce';
lv105ItemId['金 : 넘어선 기억의 낫'] = '770668e3e44b948a0429283f9dfea9f2';
lv105ItemId['金 : 넘어선 기억의 너클'] = 'b56878f068414a0584b70a242efcffcd';
lv105ItemId['金 : 넘어선 기억의 단검'] = '839678fdc43179bca99fa4c16c067dfc';
lv105ItemId['金 : 넘어선 기억의 대검'] = '7bd7fa4c5509bda7aabdc0a70be99148';
lv105ItemId['金 : 넘어선 기억의 도'] = '9d8ff6bb2823d7e807c582d8a03f2327';
lv105ItemId['金 : 넘어선 기억의 둔기'] = 'a54a7f9906c1348b33da8519e36897b0';
lv105ItemId['金 : 넘어선 기억의 로드'] = 'd382cd39310893f6592311640ccafc5a';
lv105ItemId['金 : 넘어선 기억의 리볼버'] = 'ff96016894e3afa658612f2e290ed7a0';
lv105ItemId['金 : 넘어선 기억의 머스켓'] = '79560b0db6ec743348bc3ea63f903d75';
lv105ItemId['金 : 넘어선 기억의 미늘창'] = '2d80e5938337f74b0eb9779bad058eb3';
lv105ItemId['金 : 넘어선 기억의 배틀액스'] = '1e9572003ec1c49f73cc810f7789daac';
lv105ItemId['金 : 넘어선 기억의 보우건'] = '5067a962d69b4307e0ee1f8aa2521018';
lv105ItemId['金 : 넘어선 기억의 봉'] = '0c90233c8d44a0f818db26a0bca08983';
lv105ItemId['金 : 넘어선 기억의 빗자루'] = 'ed07db78b088adc26b8d3fc905ccba2e';
lv105ItemId['金 : 넘어선 기억의 선현궁'] = '75953453e5c21051d2617e8bbd847b0d';
lv105ItemId['金 : 넘어선 기억의 소검'] = '0714355c00408f9262ee6e09fdec2d81';
lv105ItemId['金 : 넘어선 기억의 소태도'] = '1d58a8fec768d51eb05473b97cab6b69';
lv105ItemId['金 : 넘어선 기억의 스태프'] = '445d24c01446183814e4b9eae7dbed0f';
lv105ItemId['金 : 넘어선 기억의 십자가'] = '2a9eeb007e4f7c44181ba0c25f45b2c3';
lv105ItemId['金 : 넘어선 기억의 쌍검'] = 'ca5809d052779af7eaf2b1427e971660';
lv105ItemId['金 : 넘어선 기억의 에테리얼 보우'] = '665e392f36f9d28b4fd8eac2c752edf7';
lv105ItemId['金 : 넘어선 기억의 염주'] = '103d86e4bea85eb0a58c29435b4c000a';
lv105ItemId['金 : 넘어선 기억의 완드'] = '9ef6a0df8c4554718754d287a4481492';
lv105ItemId['金 : 넘어선 기억의 자동권총'] = '40f80a983b1a7f1e21c55470cb22b8f3';
lv105ItemId['金 : 넘어선 기억의 장궁'] = '7f86242a6e0394a00c7139ec16b0bd57';
lv105ItemId['金 : 넘어선 기억의 장도'] = 'e253c50fa838d86dd10b39e5dc80c405';
lv105ItemId['金 : 넘어선 기억의 장창'] = 'c9d4a78c1956745d356aa1906aa60a08';
lv105ItemId['金 : 넘어선 기억의 중검'] = 'c9755d473692e5b0d2d879dd34373194';
lv105ItemId['金 : 넘어선 기억의 차크라 웨펀'] = 'e6aa289b8e96e31465098c9e69c62324';
lv105ItemId['金 : 넘어선 기억의 창'] = 'ab72d8c0a926ea460c9a4570565559a0';
lv105ItemId['金 : 넘어선 기억의 코어 블레이드'] = 'f6d090e8ee22eb39ce5d1c08c007a724';
lv105ItemId['金 : 넘어선 기억의 크로스슈터'] = '0a47d413817128747b7bfbf8fbde4870';
lv105ItemId['金 : 넘어선 기억의 클로'] = '7414fd89c50da9eee3870c444eb62af9';
lv105ItemId['金 : 넘어선 기억의 토템'] = 'fd635c97347ff11c3e958653675ed51b';
lv105ItemId['金 : 넘어선 기억의 통파'] = '84c1ac886b694a9ddf918269cd0f5bca';
lv105ItemId['金 : 넘어선 기억의 투창'] = 'ec853724274e4c69eb009d218edb4190';
lv105ItemId['金 : 넘어선 기억의 핸드캐넌'] = 'cfba54fd9ea44f5787c8b1cdd54d853b';


lv105ItemId['엔트 정령의 귀걸이'] = '2e1b235fd35aa26bda52427c07e795ec';
lv105ItemId['엔트 정령의 목걸이'] = '53dff026d8600429a488ad25095b9692';
lv105ItemId['엔트 정령의 반지'] = '71be2ffba89052de0994976e173030c0';
lv105ItemId['엔트 정령의 벨트'] = '0b097b274b5161c94a2d8ac0e4fa9b0f';
lv105ItemId['엔트 정령의 상의'] = '3067ecee4c801accfe4bb0ac87e125bd';
lv105ItemId['엔트 정령의 성배'] = '573399fba4997923936a56fe15d50d6f';
lv105ItemId['엔트 정령의 신발'] = '6ddc12ba86e14aaa9620cfc0eec38c57';
lv105ItemId['엔트 정령의 심장'] = 'a18edcc238802a9f6b3e494ea30ef1ca';
lv105ItemId['엔트 정령의 어깨'] = '1a7240e61f61fd56e6a0106e68021032';
lv105ItemId['엔트 정령의 팔찌'] = '9e3029a34e4c0a4ec4b4a064cf1485e5';
lv105ItemId['엔트 정령의 하의'] = '01a493387c7dcbb2c66a1aa54b7354f7';

lv105ItemId['숲속의 마녀 귀걸이'] = '089aa9cba8ce4d68f3101c827f3daa38';
lv105ItemId['숲속의 마녀 상의'] = '096631070847893ce9d522215b3c4054';
lv105ItemId['숲속의 마녀 벨트'] = '1077ecd6be9f88f0274ddeb54c996a4d';
lv105ItemId['숲속의 마녀 목걸이'] = '14a424fbc1bbd0b45e39732bbc065572';
lv105ItemId['숲속의 마녀 하의'] = '1d2ba73eb876ae3a51cd984957e3a8c6';
lv105ItemId['숲속의 마녀 신발'] = '20d7eb7c17183bff06758a2f6df63bb6';
lv105ItemId['숲속의 마녀 팔찌'] = '2727b58be5152b2fba6d5a8c80337d5e';
lv105ItemId['숲속의 마녀 망토'] = '30f70bd3ff991f7343c166d8a8819eed';
lv105ItemId['숲속의 마녀 반지'] = 'a6b0fdcae5a5021307ed340c71a788e9';
lv105ItemId['숲속의 마녀 바구니'] = 'be6c1e5923222563027a392c859ae995';
lv105ItemId['숲속의 마녀 정령석'] = 'f53f14f757d1389fcdc2213f146e488b';

lv105ItemId['블루 베릴 네클레스'] = 'd4611183c02aaf2c96da65125778985f';
lv105ItemId['블루 베릴 링'] = '61b11bf9de582675393ec342fac6b7b8';
lv105ItemId['블루 베릴 벨트'] = '304f5bce8bff48088d9070625a4b1c3d';
lv105ItemId['블루 베릴 보호대'] = '0b0e6d14ab144ca08c7c80ab71848056';
lv105ItemId['블루 베릴 부츠'] = '370f6b09c57e68bc02850026255ddd97';
lv105ItemId['블루 베릴 아머'] = '26874c5e8731893d0510d19130b69c82';
lv105ItemId['블루 베릴 암릿'] = 'a4d57b6413617409456b7fae42ba8023';
lv105ItemId['블루 베릴 이어링'] = '13784e929edd0cb8cfdcb38d629cfc79';
lv105ItemId['블루 베릴 젬스톤'] = 'a239e1e2c6d3074bc15bdb9bbac3abd1';
lv105ItemId['블루 베릴 퍼퓸'] = 'e2ab555a05e8de69133e0bfa4ab5bfd1';
lv105ItemId['블루 베릴 하의'] = '4a3100f32c0eb8ed71eac2d81228b91f';

lv105ItemId['딥 다이버 벨트'] = '894a396a5fd25b43790be007a737debc';
lv105ItemId['딥 다이버 팬츠'] = '0af27a16319b2c4c785b5f338ec68655';
lv105ItemId['딥 다이버 워치'] = 'c7629a5b062cf8131e4c3bfe6ca8d5ef';
lv105ItemId['딥 다이버 슈즈'] = '4108075250068c0fd1b2f7b0c55da1ed';
lv105ItemId['딥 다이버 슈트'] = '6f0af148b4a6b44f9f9810b4fc28fe5a';
lv105ItemId['딥 다이버 오리발'] = '289a755f5262c80cc04b20d58756ee45';
lv105ItemId['딥 다이버 네비게이션 링'] = 'd48cae439d3d23c1d60885b45c7ff15b';
lv105ItemId['딥 다이버 네클레스'] = '7d0175039686c0a3e06665f95cdb3184';
lv105ItemId['딥 다이버 에어팩'] = '021e7b3d6301e8ea9758e09346cf126f';
lv105ItemId['딥 다이버 마스크'] = '05c7d8fece7ee8469c76fa0e0cbf935e';
lv105ItemId['딥 다이버 이어링'] = '93aacfaa1296200fc022bababcb68d5d';

lv105ItemId['불가침의 영역 - 하의'] = '0c69e26f193f13dceb622af48de4ba44';
lv105ItemId['불가침의 영역 - 어깨'] = '153d20b882ff15e51e3bdefd1226af60';
lv105ItemId['불가침의 영역 - 벨트'] = '1ccac9965353062c04b7da144244b544';
lv105ItemId['불가침의 영역 - 신발'] = '32c0a6e8e69fc1d3b77fbbf50a9ec671';
lv105ItemId['불가침의 영역 - 귀걸이'] = '385396a185ab79722e1e3ec4a9344619';
lv105ItemId['불가침의 영역 - 마법석'] = '3d34177c6442f0fd32ebaa1b8f1068f6';
lv105ItemId['불가침의 영역 - 상의'] = '4d8237d7bec29aaa94b9c44731490496';
lv105ItemId['불가침의 영역 - 반지'] = '61f1671e5e802efb3325f653ffbb28da';
lv105ItemId['불가침의 영역 - 보조장비'] = '68c290f2443b61eb70e7366a381d5bb9';
lv105ItemId['불가침의 영역 - 목걸이'] = 'afcd6aee7169edbb6fb4520db62d50b5';
lv105ItemId['불가침의 영역 - 팔찌'] = 'ba905b122ba9521b13111441dbea7ba2';

lv105ItemId['근위대의 맹세 목 보호대'] = '04720413b4adb4cddb7d90b66d25471f';
lv105ItemId['근위대의 맹세 각갑'] = '0573652d493d3d4194516c0807e37139';
lv105ItemId['근위대의 맹세 상박갑'] = '380a9f33e13bf185bdb3e10f4fb68651';
lv105ItemId['근위대의 맹세 증표'] = '4c601b1e1f0f8c1fddccd90ae4d38eb2';
lv105ItemId['근위대의 맹세 등불'] = '59cb34cf4cb68fec6e94040f6ac3031a';
lv105ItemId['근위대의 맹세 수갑'] = '7741aebd4cfba1b54440836704ae27b3';
lv105ItemId['근위대의 맹세 충심'] = '81d7f2a7d56b600356f343d5cdd6c622';
lv105ItemId['근위대의 맹세 하갑'] = 'aafa34c4bef0583cfbe336cedef4a665';
lv105ItemId['근위대의 맹세 지령서'] = 'c1e23b7e053f781152fe2e92f9c79623';
lv105ItemId['근위대의 맹세 갑주'] = 'eb17e589124c27d407fdd6993d205682';
lv105ItemId['근위대의 맹세 요갑'] = 'f2042ff7d303f6b44558be157e2b35a1';

lv105ItemId['블루 파이렛 네클레스'] = '43becacf3bc6bfb0714f4cee57346072';
lv105ItemId['블루 파이렛 링'] = '0f25e5c0797899fa20e58f295d91921d';
lv105ItemId['블루 파이렛 벨트'] = '0b22848f6f53b49ad23bd077f4dff30e';
lv105ItemId['블루 파이렛 부츠'] = '1d0ab68427be010617a02d9eacea9f3d';
lv105ItemId['블루 파이렛 블라우스'] = '2815227d30a1498bc852e920cdf0f1b5';
lv105ItemId['블루 파이렛 숄더'] = '10c4e1128318369ec2daa9934f895146';
lv105ItemId['블루 파이렛 암릿'] = '2d0bd692d5b3da604f79ca2b8c3dbc4d';
lv105ItemId['블루 파이렛 이어링'] = '6547bc02d151463ac16d61b245343a12';
lv105ItemId['블루 파이렛 쥬얼'] = '230468939cda0724e65eb55cd3817731';
lv105ItemId['블루 파이렛 팬츠'] = '0625dad93263198d0f9ce078a94510f4';
lv105ItemId['블루 파이렛 프로텍터'] = '2070fd2353455e0bca3094b23a3ffeba';

lv105ItemId['불가침의 영역 - 광검'] = '0469d88bf1362c05c1b3ad70c013367c';
lv105ItemId['불가침의 영역 - 봉'] = '057a5accce8ab9d9c90fcf5f02b51797';
lv105ItemId['불가침의 영역 - 보우건'] = '0db184c929a76cb5ba5af91c51f04a72';
lv105ItemId['불가침의 영역 - 소태도'] = '0f475ef2f44a28c3ff0464b135933d4e';
lv105ItemId['불가침의 영역 - 머스켓'] = '12b29a4c9bedb47ba40ec52c43309662';
lv105ItemId['불가침의 영역 - 통파'] = '1799afe4c3f677f0ce05bd4b5ad136b0';
lv105ItemId['불가침의 영역 - 창'] = '1d1dd6b928665b37309c905b3dcc215d';
lv105ItemId['불가침의 영역 - 클로'] = '1f95f7980f58c0d51f8d1df5d1a1e77b';
lv105ItemId['불가침의 영역 - 낫'] = '22f7904512dbefe4a97d4881d4db1a61';
lv105ItemId['불가침의 영역 - 선현궁'] = '2686f28fa28a61d39a7fee8dfd04aed5';
lv105ItemId['불가침의 영역 - 너클'] = '2d093f0423cd164870d49346438cf359';
lv105ItemId['불가침의 영역 - 장궁'] = '37ee433a07a88aacc4fba00c356b4115';
lv105ItemId['불가침의 영역 - 자동권총'] = '3fb93c81e07cef5b2bbd2a2f018ff2ad';
lv105ItemId['불가침의 영역 - 중검'] = '4be82c2c36fdefe11267eecce4d49901';
lv105ItemId['불가침의 영역 - 투창'] = '4d1273fc38c0cb068a3f735c3f358027';
lv105ItemId['불가침의 영역 - 소검'] = '4fc0ce254ae4c711ca4476a50e7ee92e';
lv105ItemId['불가침의 영역 - 빗자루'] = '5468f31bf24b421de643fb8879e8af15';
lv105ItemId['불가침의 영역 - 장창'] = '62842343c592305ea860db6fe09232c0';
lv105ItemId['불가침의 영역 - 십자가'] = '68cb4a64d6bcc557c8a6b8f696d9c433';
lv105ItemId['불가침의 영역 - 권투글러브'] = '78104fdf5a700efc2e2661c4bcb39aee';
lv105ItemId['불가침의 영역 - 쌍검'] = '84909008d7aed86fc39fc1ddb10f24cd';
lv105ItemId['불가침의 영역 - 코어 블레이드'] = '930e631cb4a9d1d79388afe40b6e03ff';
lv105ItemId['불가침의 영역 - 건틀릿'] = '999af014587bc3d9a53a94d0e16905af';
lv105ItemId['불가침의 영역 - 핸드캐넌'] = '9ccc7e7588663f2931732af12ea0aff0';
lv105ItemId['불가침의 영역 - 대검'] = '9e6e8142fdd6818d364f2e93b9daddca';
lv105ItemId['불가침의 영역 - 배틀액스'] = 'a04a64e9f2deda3a979b962ecb1b7489';
lv105ItemId['불가침의 영역 - 토템'] = 'a66923253a25246da52f02a839eb37f1';
lv105ItemId['불가침의 영역 - 완드'] = 'a86bb4586a2373fc905f8b2fc2c2fd8e';
lv105ItemId['불가침의 영역 - 둔기'] = 'abfe04018c3f70c9b09c2a765f53412d';
lv105ItemId['불가침의 영역 - 리볼버'] = 'b092723dfea1806f897cff0241f7b76a';
lv105ItemId['불가침의 영역 - 미늘창'] = 'c4e16e120cd705c4cb952a0367131619';
lv105ItemId['불가침의 영역 - 도'] = 'ccb3696eba4e3be0c63f11127631f4d7';
lv105ItemId['불가침의 영역 - 단검'] = 'cde2e123e1a27ad139f87e25011344bb';
lv105ItemId['불가침의 영역 - 장도'] = 'd1960903f36a829d69185b9546ba245c';
lv105ItemId['불가침의 영역 - 스태프'] = 'd25a411fb1b825a7bb801365aab984a8';
lv105ItemId['불가침의 영역 - 염주'] = 'd6e746ebaabee4a89b84baf10bd866af';
lv105ItemId['불가침의 영역 - 광창'] = 'e26f91ef2d62e26d6566f6d12e156cd4';
lv105ItemId['불가침의 영역 - 로드'] = 'f780622440752096c7e5d5709042eac3';
lv105ItemId['불가침의 영역 - 차크라 웨펀'] = 'fe76ef4830e99d6daf7bf5213274a771';

lv105ItemId['폭룡왕의 지배 - 공포'] = 'c9478e22a6d836cdce8a73cf61d2ec64';
lv105OptionMap['폭룡왕의 지배 - 공포'] = {};
lv105OptionMap['폭룡왕의 지배 - 공포'] = '최종 데미지 5% 증가<br>모든 속성 강화 +20<br><br>HP MAX 15% 수치의 [충전형 보호막] 부여<br>[충전형 보호막]의 회복 대기 시간 -1초<br>장비 보호막 MAX 8% 증가<br><br>공격 속도 +20%<br>캐스팅 속도 +30%<br>이동 속도 +20%<br>HP MAX +600@@7396@@1973';

lv105ItemId['성장하는 모험가의 도'] = '9fbd3472424bcf574e888c4f3f0f249a';
lv105OptionMap['성장하는 모험가의 도'] = {};
lv105OptionMap['성장하는 모험가의 도'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['도데카 홀로그램'] = '2ed40a5e4bc69485f9acd316fc5891af';
lv105OptionMap['도데카 홀로그램'] = {};
lv105OptionMap['도데카 홀로그램'] = '최종 데미지 5% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br><br>물리/마법 크리티컬 히트 +7%<br>HP 1분당 460.2 회복<br>모든 상태 이상 내성 -10%@@5240@@1953';

lv105ItemId['폭룡왕의 지배 - 공포'] = 'c9478e22a6d836cdce8a73cf61d2ec64';
lv105OptionMap['폭룡왕의 지배 - 공포'] = {};
lv105OptionMap['폭룡왕의 지배 - 공포'] = '최종 데미지 5% 증가<br>모든 속성 강화 +20<br><br>HP MAX 15% 수치의 [충전형 보호막] 부여<br>[충전형 보호막]의 회복 대기 시간 -1초<br>장비 보호막 MAX 8% 증가<br><br>공격 속도 +20%<br>캐스팅 속도 +30%<br>이동 속도 +20%<br>HP MAX +600@@7396@@1973';

lv105ItemId['성장하는 모험가의 도'] = '9fbd3472424bcf574e888c4f3f0f249a';
lv105OptionMap['성장하는 모험가의 도'] = {};
lv105OptionMap['성장하는 모험가의 도'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['도데카 홀로그램'] = '2ed40a5e4bc69485f9acd316fc5891af';
lv105OptionMap['도데카 홀로그램'] = {};
lv105OptionMap['도데카 홀로그램'] = '최종 데미지 5% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br><br>물리/마법 크리티컬 히트 +7%<br>HP 1분당 460.2 회복<br>모든 상태 이상 내성 -10%@@5240@@1953';

lv105ItemId['내딛은 자의 리볼버'] = 'ada9cce75f351749e25dc15fba68291a';
lv105OptionMap['내딛은 자의 리볼버'] = {};
lv105OptionMap['내딛은 자의 리볼버'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['드래곤 라이더'] = '13e6904c6bbc52eee71b9c72df37a309';
lv105OptionMap['드래곤 라이더'] = {};
lv105OptionMap['드래곤 라이더'] = '최종 데미지 8% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 속성 저항 +15@@5066@@1928';

lv105ItemId['아득한 각인'] = '52ec0bfd326d5475b588c893fb08d34f';
lv105OptionMap['아득한 각인'] = {};
lv105OptionMap['아득한 각인'] = '50, 85, 100Lv 스킬 공격력 20% 증가<br>모든 속성 강화 +20<br><br>[명계의 각인]<br>스킬 시전 시 7.77% 확률로 스킬 공격력이 100% 증가하여 적을 명계로 인도<br><br>공격 속도 +30%<br>캐스팅 속도 +30%<br>이동 속도 +30%<br>적중률 +10%@@5780@@1963';

lv105ItemId['결전의 핸드캐넌 - 레인저(여)'] = 'c8a0a781eb6256b6df28e1470b810af1';
lv105OptionMap['결전의 핸드캐넌 - 레인저(여)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 레인저(여)'] = '이동사격 탄환을 파열시켜 더욱 넓은 범위 공격<br>- 공격력 30% 증가<br>- 발사 수 50% 증가<br>- 쿨타임 20% 감소<br>- 공격 범위 10% 증가<br><br>이동사격 중 아래의 효과 적용<br>- 스킬 시전 시 쿨타임 즉시 적용<br>- 공격 속도 +30%<br>- 이동 속도 +30%<br>- 던전 방 이동 시 스킬 유지@@7708@@2016';

lv105ItemId['생명이 깃든 초록빛 액체'] = '27b3353a4472f64e74bcdd8b85f32fe3';
lv105OptionMap['생명이 깃든 초록빛 액체'] = {};
lv105OptionMap['생명이 깃든 초록빛 액체'] = '최종 데미지 10% 증가<br><br>[속성 친화 - 명]<br>명속성 저항이 높아질수록 물리/마법 피해 감소<br>- 적의 공격에 명속성 부여<br><br>피격 시 5초 동안 HP 5% 회복 (쿨타임 7초)<br><br>HP MAX +600<br>명속성 저항 +35@@5604@@1948';

lv105ItemId['개화하는 신비의 꽃'] = '1bd4db237a0fcdb205f8fd0a2a610a72';
lv105OptionMap['개화하는 신비의 꽃'] = {};
lv105OptionMap['개화하는 신비의 꽃'] = '공격력 증가 +222.3%<br>최종 데미지 7% 증가<br>모든 속성 강화 +10<br><br>암흑 상태 적 공격 시 30초 동안 최종 데미지 10% 증가<br><br>공격 시 적에게 5초 동안 암흑 상태 이상 부여 (쿨타임 8초)<br><br>암흑 지속 시간 +2초@@4984@@1968';

lv105ItemId['혼돈을 두른 장막'] = '07d1f5aea662f1b0b8eae7317b6924d9';
lv105OptionMap['혼돈을 두른 장막'] = {};
lv105OptionMap['혼돈을 두른 장막'] = '최종 데미지 10% 증가<br><br>HP MAX +600<br>물리/마법 방어력 +7,000@@5066@@1958';

lv105ItemId['근원을 삼킨 클로'] = '078ef4c689fdf4ffcf6ffcc2d09f5dae';
lv105OptionMap['근원을 삼킨 클로'] = {};
lv105OptionMap['근원을 삼킨 클로'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['성장하는 모험가의 십자 자수 토시'] = '429ea115bb74308b9c2441784cc841de';
lv105OptionMap['성장하는 모험가의 십자 자수 토시'] = {};
lv105OptionMap['성장하는 모험가의 십자 자수 토시'] = '최종 데미지 7% 증가@@5336@@1986';

lv105ItemId['플래티넘 클래스 재킷'] = '01f668b6a225cfd1681a1ebfc40245fe';
lv105OptionMap['플래티넘 클래스 재킷'] = {};
lv105OptionMap['플래티넘 클래스 재킷'] = '최종 데미지 8% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5604@@1612';

lv105ItemId['폭룡왕의 지배 - 압제'] = '496c183699f3e4c7fb0c940fbd2ae225';
lv105OptionMap['폭룡왕의 지배 - 압제'] = {};
lv105OptionMap['폭룡왕의 지배 - 압제'] = '최종 데미지 11% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@5604@@1900';

lv105ItemId['데저트 컨실멘트 숄더'] = '0e6cd9f319acde9826e59e9cbf81e89d';
lv105OptionMap['데저트 컨실멘트 숄더'] = {};
lv105OptionMap['데저트 컨실멘트 숄더'] = '최종 데미지 2% 증가<br>화상 피해 +20%<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>자신에게 해제되지 않는 화상 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +10%<br>공격 속도 +5%<br>캐스팅 속도 +7.5%<br>HP 1분당 460.2 회복@@6144@@1968';

lv105ItemId['얼어붙은 저항의 권투글러브'] = 'd03717b42cc2ceedd01ad461cb2b46c1';
lv105OptionMap['얼어붙은 저항의 권투글러브'] = {};
lv105OptionMap['얼어붙은 저항의 권투글러브'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 도 - 다크템플러'] = '804199496caa0bf2584f52316a9e7b33';
lv105OptionMap['결전의 도 - 다크템플러'] = {};
lv105OptionMap['결전의 도 - 다크템플러'] = '이보브 영혼 최대 흡수 개수 +30<br><br>아래 스킬의 추가 영혼 흡수 개수 +5<br>- 리버레이트 - 매드니스<br>- 디스트로이어<br>- 라케르쏜즈<br><br>리버레이트 - 벅샷 발사하는 에너지 1개당 소모되는 영혼의 수 +2<br>- 타겟을 잡는 최대 거리 20% 증가<br><br>리버레이트 - 벅샷으로 소모한 영혼의 수 2개마다 아래 스킬의 남은 쿨타임 1% 감소 (베스퍼스 효과 미적용)<br>- 디스트로이어<br>- 데우스 엑스 레이<br>- 지하드<br>- 라케르쏜즈@@5336@@2016';

lv105ItemId['광폭화된 전의의 쌍검'] = '3673e0266b980053cbbc3497af7d229b';
lv105OptionMap['광폭화된 전의의 쌍검'] = {};
lv105OptionMap['광폭화된 전의의 쌍검'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 소검 - 팔라딘'] = '92058059afc937f35a80a3236248d1da';
lv105OptionMap['결전의 소검 - 팔라딘'] = {};
lv105OptionMap['결전의 소검 - 팔라딘'] = '세라픽 페더를 소모하여 이스트레인지먼트 시전 시 추가 효과 발동<br>- 세라픽 페더 1장당 공격력 4% 증가<br>- 세라픽 페더 1장당 스킬 범위 3% 증가<br>- 적에게 기절 상태 이상 부여<br><br>세라픽 페더를 소모하여 위핑 웨이브 시전 시 추가 효과 발동<br>- 끌어들인 적을 2초 동안 홀딩<br>- 몰이 효과 범위 6단계 적용<br>- 몰이 효과 범위 20% 증가<br>- 세라픽 페더 최대 2개만 소모<br><br>위핑 웨이브 강화<br>- 스킬 범위 20% 증가<br>- 타격 성공 시 세라픽 페더 습득량 +2<br>- 도발시킨 적 앞에서 방어 상태 유지 시 세라픽 페더 획득 시간 50% 감소@@9484@@2016';

lv105ItemId['결전의 통파 - 그래플러(여)'] = '2e8c00dc0d922666a7d8a0873867ab65';
lv105OptionMap['결전의 통파 - 그래플러(여)'] = {};
lv105OptionMap['결전의 통파 - 그래플러(여)'] = '전직 스킬로 공격 시 테크니컬 포인트 획득 (최대 20점)<br>- 1~30Lv : 1점<br>- 35~45Lv : 2점<br>- 60~80Lv : 2점<br>- 50, 85, 95Lv : 5점<br>- 100Lv : 10점<br>- 체인 커넥션 연계/공중 스킬 사용 시 1점 추가<br><br>테크니컬 포인트에 따라 효과 발동<br>- 11점 이상 : 50, 85, 100Lv 스킬 적중 시 모든 스킬 남은 쿨타임 30% 감소 (각성기 제외 / 모든 점수 소모)<br>- 6점 이상 : 잡기 스킬 공격 실패 시 해당 스킬 쿨타임을 3초로 감소 (3점 소모)<br>- 1점 이상 : 체인 커넥션 스킬 연계 시 체인 커넥션 회복 시간 3초 감소@@5336@@2016';

lv105ItemId['내딛은 자의 중검'] = '8983f120c217e07f29c6ba716430a546';
lv105OptionMap['내딛은 자의 중검'] = {};
lv105OptionMap['내딛은 자의 중검'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['사멸하는 신뢰의 봉'] = '2e0cccb1e0f7277209a73818db650f4c';
lv105OptionMap['사멸하는 신뢰의 봉'] = {};
lv105OptionMap['사멸하는 신뢰의 봉'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['포인트 레이더 암릿'] = 'b544581f62bba1bc5d85f9f091795e5c';
lv105OptionMap['포인트 레이더 암릿'] = {};
lv105OptionMap['포인트 레이더 암릿'] = '최종 데미지 7.1% 증가<br>화상 피해 전환 +50%<br>화상 피해 +20%<br><br>물리/마법 피해의 50%를 화상 피해로 전환<br><br>화상 내성 +1%@@3165@@1953';

lv105ItemId['결전의 로드 - 배틀메이지'] = 'd408810c89ef0653038a5dcf04806149';
lv105OptionMap['결전의 로드 - 배틀메이지'] = {};
lv105OptionMap['결전의 로드 - 배틀메이지'] = '체이서 공격력 및 사출 개수 증가<br>- 한 번에 사출되는 체이서 개수 +2<br>- 공격력 45% 감소<br>- 50콤보 이상일 때 체이서 자동 사출<br>- 체이서 최대 생성 개수 +1<br>- 체이서 크기 비율 20% 증가<br>- 스킬 MP 소모량 -70%<br><br>한 번에 생성되는 체이서 개수 +1@@4744@@2016';

lv105ItemId['결전의 통파 - 넨마스터(남)'] = 'b206fb9dc51e2ae52069f3275df569bf';
lv105OptionMap['결전의 통파 - 넨마스터(남)'] = {};
lv105OptionMap['결전의 통파 - 넨마스터(남)'] = '기호지세의 넨수가 포효 후 폭발하도록 변경<br>- 포효 공격 다단히트 수 : 4<br>- 포효 공격력 : 기호지세 다단히트 공격력의 100%<br>- 폭발 공격력 : 기호지세 폭발 공격력의 100%<br>- 포효에 적중된 적을 끌어당김<br>- 스킬 범위 20% 증가<br><br>광호천지파의 나선의 넨이 스스로 회전 후 폭발<br>- 시전 속도 20% 증가<br>- 구체 및 폭발 범위 10% 증가@@10076@@2016';

lv105ItemId['계곡을 감시하는 주시'] = '0974cda9a6eae319db5c07cf9874f812';
lv105OptionMap['계곡을 감시하는 주시'] = {};
lv105OptionMap['계곡을 감시하는 주시'] = '최종 데미지 8% 증가<br><br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +10%<br>MP MAX +5%<br>물리/마법 방어력 +7,000@@6414@@1928';

lv105ItemId['근원을 삼킨 십자가'] = '1aa5c0faf9bcd968077f5787ac3a430b';
lv105OptionMap['근원을 삼킨 십자가'] = {};
lv105OptionMap['근원을 삼킨 십자가'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['얼어붙은 저항의 소태도'] = '3d2cf51aafc3c4f8fe383b18effe0148';
lv105OptionMap['얼어붙은 저항의 소태도'] = {};
lv105OptionMap['얼어붙은 저항의 소태도'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['광폭화된 전의의 크로스슈터'] = '091ad464f28521eac38a4e628dd2ec71';
lv105OptionMap['광폭화된 전의의 크로스슈터'] = {};
lv105OptionMap['광폭화된 전의의 크로스슈터'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@711@@1916';

lv105ItemId['결전의 스태프 - 엘레멘탈마스터'] = '2fd39f1e445a619c3cfb3bcc7e38cd5a';
lv105OptionMap['결전의 스태프 - 엘레멘탈마스터'] = {};
lv105OptionMap['결전의 스태프 - 엘레멘탈마스터'] = '상급 원소 스킬 및 일부 최상급 원소 스킬 1개를 룬에 저장 후 해방<br>- [장비 옵션 조작키] 입력 후 스킬 사용 시 60초 동안 룬에 저장<br>- [장비 옵션 조작키] 재입력 시 저장된 스킬 해방 (쿨타임 0.1초)<br>- 해방 스킬 공격력 : 원본 스킬의 15%<br>- 저장 스킬 쿨타임 : 원본 스킬의 15%<br>- 최대 충전 상태로 저장<br><br>메모라이즈 캐스팅 속도 상승률 +15%<br><br><저장 가능 스킬><br>- 썬더 콜링<br>- 아크틱 피스트<br>- 나이트 할로우<br>- 핼로윈 버스터<br>- 엘레멘탈 커튼<br>- 엘레멘탈 퀘이크<br>- 초월의 크리스탈@@11264@@2016';

lv105ItemId['성장하는 모험가의 둔기'] = '3d1a4e9685d31368f2edfe883d7c839e';
lv105OptionMap['성장하는 모험가의 둔기'] = {};
lv105OptionMap['성장하는 모험가의 둔기'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['결전의 너클 - 그래플러(남)'] = '0db0dc25c296e69b735d529dee97e9a8';
lv105OptionMap['결전의 너클 - 그래플러(남)'] = {};
lv105OptionMap['결전의 너클 - 그래플러(남)'] = '롤링 스파이크를 메치기 시 지진이 발생하는 최대 2회 충전 스킬로 변경<br>- 지진 공격력 : 메치기 공격력의 50%<br>- 충전 쿨타임 : 12초<br>- 후딜레이를 캔슬하여 전직 스킬 시전 가능<br><br>아래의 스킬 잡기 실패 시 해당 스킬 쿨타임 5초로 감소 (쿨타임 10초)<br>- 브레이크 다운<br>- 스냅 샷<br>- 블로우 스트라이크(지상 사용)<br>- 롤링 스파이크<br>- 나의 공격리듬은!(지상 사용)<br>- 한놈만 팬다!!<br>- 흑진광풍@@5928@@2016';

lv105ItemId['하이테크 서플라이 벨트'] = '3402f770e2fe1a9a60d71be7d9d43834';
lv105OptionMap['하이테크 서플라이 벨트'] = {};
lv105OptionMap['하이테크 서플라이 벨트'] = '최종 데미지 12% 증가<br><br>HP MAX 10% 수치의 [충전형 보호막] 부여<br><br>HP MAX +600<br>물리/마법 피해 감소 +30%<br>HP 1분당 460.2 회복@@6414@@1948';

lv105ItemId['내딛는 용기'] = '0a40b67be1eed7397bf62a271d2751d1';
lv105OptionMap['내딛는 용기'] = {};
lv105OptionMap['내딛는 용기'] = '공격력 증가 +326.0%<br>최종 데미지 9% 증가<br>1~25Lv 스킬 Lv +2<br><br>이동 속도 +8%<br>적중률 +10%@@6414@@1920';

lv105ItemId['얼어붙은 저항의 둔기'] = '037e23579234acfbdde69c2af4dc8296';
lv105OptionMap['얼어붙은 저항의 둔기'] = {};
lv105OptionMap['얼어붙은 저항의 둔기'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['안개를 탐구하는 자의 목걸이'] = '0c77648fb81aa8283afdec229904dbf1';
lv105OptionMap['안개를 탐구하는 자의 목걸이'] = {};
lv105OptionMap['안개를 탐구하는 자의 목걸이'] = '최종 데미지 9% 증가<br>모든 속성 강화 +18<br><br>대시 시 30초 동안 슈퍼 아머 부여 (쿨타임 5초)<br><br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +10%<br>회피율 +20%@@5254@@1938';

lv105ItemId['무대의 화려함'] = '03bacfa3c23b56f447a5cd18851219f0';
lv105OptionMap['무대의 화려함'] = {};
lv105OptionMap['무대의 화려함'] = '최종 데미지 8% 증가<br><br>백스텝 시전 중 이동 속도 +200%<br><br>물리/마법 피해 감소 +10%<br>적중률 +10%<br>회피율 +8%@@4796@@1928';

lv105ItemId['사멸하는 신뢰의 배틀액스'] = '0f19356167fe5e85729478ae6bb546df';
lv105OptionMap['사멸하는 신뢰의 배틀액스'] = {};
lv105OptionMap['사멸하는 신뢰의 배틀액스'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['익스펜션 서플라이 벨트'] = '0f3d772eab81c1cc12ff4d62ac76eb2d';
lv105OptionMap['익스펜션 서플라이 벨트'] = {};
lv105OptionMap['익스펜션 서플라이 벨트'] = '최종 데미지 7% 증가<br>스킬 쿨타임 회복 속도 +15% (각성기 제외)<br><br>공격 속도 +45%<br>캐스팅 속도 +45%<br>이동 속도 +45%<br>화속성 저항 +30@@5336@@1948';

lv105ItemId['무너진 세계의 틈'] = '2566ad9a3d73caaeea358c6a9ac34c6e';
lv105OptionMap['무너진 세계의 틈'] = {};
lv105OptionMap['무너진 세계의 틈'] = '최종 데미지 8% 증가<br><br>[차원 이동]<br>[장비 옵션 조작키] 입력 시 40초 동안 차원의 틈을 드러냄 (최대 2개 / 쿨타임 20초)<br>- 차원의 틈에서 점프 시 다른 차원의 틈으로 이동 (쿨타임 3초)<br>- 이동 후 30초 동안 물리/마법 피해 감소 +30%<br><br>물리/마법 크리티컬 히트 +5%<br>물리/마법 피해 감소 +12%<br>HP 1분당 460.2 회복@@6144@@1928';

lv105ItemId['저주받은 마음'] = '2e08fce12a4fbcd995f02b62b95115bd';
lv105OptionMap['저주받은 마음'] = {};
lv105OptionMap['저주받은 마음'] = '최종 데미지 15% 증가<br><br>[꺾이지 않는 마음]<br>피격으로 인한 스킬 캔슬 무시 (쿨타임 10초)<br>- 홀딩 제외<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>HP MAX +600@@5066@@1948';

lv105ItemId['결전의 둔기 - 소드마스터'] = '22de6ad396b22d6d111ce7b982a3c29e';
lv105OptionMap['결전의 둔기 - 소드마스터'] = {};
lv105OptionMap['결전의 둔기 - 소드마스터'] = '궁극의 마검 레바테인으로 악즉참 시전<br>- 악즉참 항상 최대 충전<br>- 악즉참 공격력 15% 감소<br><br>레바테인에 의한 악즉참은 적에게 4초 동안 상흔을 부여<br>- 상흔이 생성된 적에게 반월 또는 섬광 적중 시 악즉참 남은 쿨타임 10% 감소@@8892@@2016';

lv105ItemId['성장하는 모험가의 소검'] = 'b034849626b44304920cc3be6b31f01f';
lv105OptionMap['성장하는 모험가의 소검'] = {};
lv105OptionMap['성장하는 모험가의 소검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['내딛은 자의 낫'] = '3b7f183dd10bf8f5965b74ca6cfb09c0';
lv105OptionMap['내딛은 자의 낫'] = {};
lv105OptionMap['내딛은 자의 낫'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['광폭화된 전의의 빗자루'] = '09c3fca8cc26bfd72b0bc114aeeafe60';
lv105OptionMap['광폭화된 전의의 빗자루'] = {};
lv105OptionMap['광폭화된 전의의 빗자루'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['상상을 담은 엔지니어 팬츠'] = '0ab040cef3e52b3d4b8025bbe82a8ca4';
lv105OptionMap['상상을 담은 엔지니어 팬츠'] = {};
lv105OptionMap['상상을 담은 엔지니어 팬츠'] = '최종 데미지 10% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>MP MAX +945@@5066@@1948';

lv105ItemId['결전의 창 - 빙결사'] = '7afd2dfb9ff0fa43adbb9e9bf8dfdf12';
lv105OptionMap['결전의 창 - 빙결사'] = {};
lv105OptionMap['결전의 창 - 빙결사'] = '아이스 크래프트 얼음창이 얼음 무기에 피격된 모든 적을 추적하여 꿰뚫음<br>- 얼음창 공격력 반영률 20% 추가 증가<br>- 얼음창 폭발 삭제<br>- 얼음창이 10% 확률로 적에게 2초 동안 빙결 상태 이상 부여<br><br>핀포인트 러쉬 시전 시 전방의 가장 강력한 적을 추적하여 주변의 적을 함께 공격<br>- 추적 범위 : 500px@@711@@2016';

lv105ItemId['세컨드 스페이드 - 어쏘러티'] = '8ead84aa6b74d38b4a851a001d28a0c9';
lv105OptionMap['세컨드 스페이드 - 어쏘러티'] = {};
lv105OptionMap['세컨드 스페이드 - 어쏘러티'] = '최종 데미지 12% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@4528@@1612';

lv105ItemId['주체할 수 없는 낡은 규칙'] = '2ec6efb126d88e85da31c34bb2b71b6f';
lv105OptionMap['주체할 수 없는 낡은 규칙'] = {};
lv105OptionMap['주체할 수 없는 낡은 규칙'] = '스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@3988@@1968';

lv105ItemId['성장하는 모험가의 창'] = '10581b6a08fd331d22accdca5907a68b';
lv105OptionMap['성장하는 모험가의 창'] = {};
lv105OptionMap['성장하는 모험가의 창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['결전의 스태프 - 디멘션 워커'] = '67720e014b1efab7aedd2568f32e3bc3';
lv105OptionMap['결전의 스태프 - 디멘션 워커'] = {};
lv105OptionMap['결전의 스태프 - 디멘션 워커'] = '차원 : 시공폭뢰 스킬 강화<br>- 공격력 15% 증가<br>- 폭발 속도 50% 증가<br>- 스킬 범위 25% 증가<br>- 사상경계의 저편 효과 적용 시 전방에 다수의 차원 폭발 생성<br><br>니알리가 스킬 시전 시 분신이 대신 시전 (각성기 제외)<br><br>아래 스킬 쿨타임 10% 감소<br>- 괴리 : 할리퀸<br>- 괴리 : 패러사이트 스웜<br>- 괴리 : 칙 잇<br>- 괴리 : 저편의 자장가@@4744@@2016';

lv105ItemId['안개의 뜻을 따르는 자의 하의'] = '22febcac741c9b615a711bd0d038bdc7';
lv105OptionMap['안개의 뜻을 따르는 자의 하의'] = {};
lv105OptionMap['안개의 뜻을 따르는 자의 하의'] = '최종 데미지 8% 증가<br><br>홀딩 무력화 게이지 감소량 +200%<br><br>물리/마법 방어력 +7,000<br>이동 속도 +8%@@5604@@1928';

lv105ItemId['광폭화된 전의의 낫'] = '3548bcbb89a3dbb8b5fe5777d29c469c';
lv105OptionMap['광폭화된 전의의 낫'] = {};
lv105OptionMap['광폭화된 전의의 낫'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 장궁 - 트래블러'] = 'abe5ca37399400f26a4e849c43ed22f8';
lv105OptionMap['결전의 장궁 - 트래블러'] = {};
lv105OptionMap['결전의 장궁 - 트래블러'] = '신무병장 유성 스킬 쿨타임 15% 감소<br><br>신무병장 폴라리스 강화<br>- 탐색 최대 수 : +5회<br>- 오로라 최대 지속 시간 : +7초<br>- 피니시 발동 후에도 스킬 지속 (피니시는 지속 시간 중 최대 1회 발동 가능)<br>- 피니시 발동 후 추가 입력 시 스킬 종료<br><br>셋 업 : 맹격의 궁술 스킬 공격력 증가율 5% 추가 증가<br>셋 업 : 섬공의 궁술 스킬 쿨타임 감소율 5% 추가 증가<br><br>캠프파이어 강화<br>- HP 회복 범위 20% 증가<br>- 스튜 HP 회복량 50% 증가@@5928@@1856';

lv105ItemId['결전의 투창 - 다크 랜서'] = '06ad5e66a95d76786a0fbe2ab06a707c';
lv105OptionMap['결전의 투창 - 다크 랜서'] = {};
lv105OptionMap['결전의 투창 - 다크 랜서'] = '잠식 상태의 적 1마리당 1초마다 흑뢰의 기운 10% 획득<br>- 잠식 상태의 적이 없을 경우 1초마다 5% 감소<br><br>보스 몬스터 공격 시 흑뢰의 기운 1% 획득<br><br>흑뢰의 기운 100% 달성 시 15초 동안 다크 스타 강화<br>- 흑뢰 창이 동시에 낙하<br>- 흑뢰 마장창 추가 낙하<br>- 흑뢰 마장창 공격력 : 다크 스타 다단 히트 공격력의 1,000%@@10076@@2016';

lv105ItemId['결전의 리볼버 - 메카닉(남)'] = '22092d81763b2d925bae50c7d324a426';
lv105OptionMap['결전의 리볼버 - 메카닉(남)'] = {};
lv105OptionMap['결전의 리볼버 - 메카닉(남)'] = '로봇 전폭으로 로봇 폭발 시 R-러너 생성<br>-  R-러너 공격력 : RX-78 랜드러너 공격력의 50%<br>- 로봇 전폭의 폭발 데미지 변화율 효과 적용<br>- 최대 10개 생성<br><br><R-러너 생성 가능 스킬><br>- RX-78 랜드러너<br>- Ez-8 카운트 다운<br>- RX-60 트랩 러너<br>- 공중 전투 메카 : 템페스터<br>- 메카 드롭<br>- 스패로우 팩토리<br>- Ez-10 카운터 어택<br>- TX-45 A-Team<br><br>RX-78 랜드러너 공격력 5% 증가@@9484@@2016';

lv105ItemId['사멸하는 신뢰의 창'] = '0db7f6332a7340d24164b38d7d210b3e';
lv105OptionMap['사멸하는 신뢰의 창'] = {};
lv105OptionMap['사멸하는 신뢰의 창'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['새겨지는 맹세'] = '9e62c0bc9a8b159a6fbff2ce8b0b8f9a';
lv105OptionMap['새겨지는 맹세'] = {};
lv105OptionMap['새겨지는 맹세'] = '최종 데미지 16.5% 증가<br>모든 속성 강화 +30<br><br>회피율 +8%<br>스킬 MP 소모량 -7%@@6144@@1958';

lv105ItemId['사멸하는 신뢰의 둔기'] = '8bc24f3b326da2ba31d06f3c48a1cb00';
lv105OptionMap['사멸하는 신뢰의 둔기'] = {};
lv105OptionMap['사멸하는 신뢰의 둔기'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['어둠을 먹는 심장'] = '621ccfd41ac3245f90a75a2dba932f1f';
lv105OptionMap['어둠을 먹는 심장'] = {};
lv105OptionMap['어둠을 먹는 심장'] = '피격 시 MP 10% 회복 (쿨타임 30초)<br><br>공격 속도 +30%<br>캐스팅 속도 +40%<br>이동 속도 +30%<br>MP MAX +945<br>물리/마법 피해 감소 +10%<br>MP 1분당 348 회복@@6144@@1948';

lv105ItemId['천지를 흔드는 석갑'] = '009fe4e6aa1d6c387d5c56284a9c6d06';
lv105OptionMap['천지를 흔드는 석갑'] = {};
lv105OptionMap['천지를 흔드는 석갑'] = '모든 속성 강화 +10<br>중독 피해 +20%<br><br>[소환]<br>[장비 옵션 조작키] 입력 시 파티원 1명을 내 위치로 소환  (쿨타임 10초)<br>- 소환된 파티원은 20초 동안 이동 속도 +20%<br>- 파티원이 무적 또는 슈퍼아머인 경우 선택 불가<br><br>공격 시 모든 적에게 15초 동안 중독 상태 이상 부여 (쿨타임 10초)<br><br>물리/마법 방어력 +7,000<br>중독 내성 +10%<br>회피율 +8%@@5604@@1968';

lv105ItemId['마주할 수 없는 부러진 긍지'] = '078b7780d03944e9e3847fd471fa0dcd';
lv105OptionMap['마주할 수 없는 부러진 긍지'] = {};
lv105OptionMap['마주할 수 없는 부러진 긍지'] = '최종 데미지 7% 증가<br>감전 피해 +15%<br><br>감전 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)<br><br>물리/마법 크리티컬 히트 +5%<br>감전 내성 +10%@@6414@@1958';

lv105ItemId['스팀펑크 소닉 디스럽터'] = '06ba0b3d7d939b21ceb08306f0579a17';
lv105OptionMap['스팀펑크 소닉 디스럽터'] = {};
lv105OptionMap['스팀펑크 소닉 디스럽터'] = '공격력 증가 +133.4%<br>최종 데미지 13% 증가<br>스킬 쿨타임 회복 속도 +10% (각성기 제외)<br><br>석화 지속 시간 +3초<br><br>물리/마법 크리티컬 히트 +7%<br>모든 상태 이상 내성 -10%@@4972@@1953';

lv105ItemId['근원을 삼킨 미늘창'] = '08a1ea5583085b8463c8883d954497cf';
lv105OptionMap['근원을 삼킨 미늘창'] = {};
lv105OptionMap['근원을 삼킨 미늘창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['하이테크 바디 프로텍트 숄더'] = '003ba9ed5b693c349cad3fb7c2ad42ff';
lv105OptionMap['하이테크 바디 프로텍트 숄더'] = {};
lv105OptionMap['하이테크 바디 프로텍트 숄더'] = '최종 데미지 10% 증가<br><br>자신에게 해제되지 않는 감전 상태 이상 부여<br><br>피격 후 3초 안에 반격 시, 직전에 입은 피해의 70% 회복 (쿨타임 3초)<br><br>HP MAX +600<br>적중률 +10%@@5604@@1958';

lv105ItemId['얼어붙은 저항의 소검'] = 'a11e977751e27bb41e77404898981952';
lv105OptionMap['얼어붙은 저항의 소검'] = {};
lv105OptionMap['얼어붙은 저항의 소검'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['상상을 담은 엔지니어의 작업 벨트'] = '00bbdf258df9e3c390b1c46f39ba325e';
lv105OptionMap['상상을 담은 엔지니어의 작업 벨트'] = {};
lv105OptionMap['상상을 담은 엔지니어의 작업 벨트'] = '최종 데미지 12% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%<br>모든 속성 저항 +15@@6952@@1928';

lv105ItemId['아픔을 감싸는 위엄'] = '0fc3bff272f60ed5d4d999f52366438c';
lv105OptionMap['아픔을 감싸는 위엄'] = {};
lv105OptionMap['아픔을 감싸는 위엄'] = '최종 데미지 11% 증가<br>모든 속성 강화 +18<br><br>MP MAX +945@@4528@@1900';

lv105ItemId['용살자의 증표 - 용심 가공석'] = '09db8d8812162f16cc31df0903af3ed4';
lv105OptionMap['용살자의 증표 - 용심 가공석'] = {};
lv105OptionMap['용살자의 증표 - 용심 가공석'] = '최종 데미지 10% 증가<br>70Lv 액티브 스킬 Lv +10<br>- 제외 : 영원한 소유, 대천사의 축복<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@4796@@1938';

lv105ItemId['사멸하는 신뢰의 소검'] = '944c114b30c6b4ca0e31af02f6580f0f';
lv105OptionMap['사멸하는 신뢰의 소검'] = {};
lv105OptionMap['사멸하는 신뢰의 소검'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['끝을 바라보는 시선'] = '074bfbabfb1869d7b65f71911a488ad4';
lv105OptionMap['끝을 바라보는 시선'] = {};
lv105OptionMap['끝을 바라보는 시선'] = '최종 데미지 11% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%<br>회피율 +8%@@5066@@1968';

lv105ItemId['오버커런트 마그넷 링'] = '3987cd6773aa22eb97957dbf1de92285';
lv105OptionMap['오버커런트 마그넷 링'] = {};
lv105OptionMap['오버커런트 마그넷 링'] = '공격력 증가 +222.3%<br>최종 데미지 7% 증가<br>모든 속성 강화 +10<br><br>기절 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>공격 시 적에게 5초 동안 기절 상태 이상 부여 (쿨타임 8초)<br><br>기절 지속 시간 +2초@@4984@@1968';

lv105ItemId['천재 기술자의 보호 마스크'] = '435b31746441376a525d943d1e5fcf39';
lv105OptionMap['천재 기술자의 보호 마스크'] = {};
lv105OptionMap['천재 기술자의 보호 마스크'] = '최종 데미지 11% 증가<br><br>30초마다 HP/MP 30% 회복<br><br>물리/마법 방어력 +7,000<br>회피율 +8%@@5604@@1968';

lv105ItemId['성장하는 모험가의 토템'] = '73735b83803ec43f342b2d58744cb0d3';
lv105OptionMap['성장하는 모험가의 토템'] = {};
lv105OptionMap['성장하는 모험가의 토템'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['안개의 뜻을 따르는 자의 신발'] = '128cf233613acb4853d141280688cb28';
lv105OptionMap['안개의 뜻을 따르는 자의 신발'] = {};
lv105OptionMap['안개의 뜻을 따르는 자의 신발'] = '최종 데미지 13% 증가<br>홀딩 무력화 게이지 감소량 +100%<br><br>[약점 공략]<br>무력화 게이지량이 90% 이하인 적에게 부여 시도하는 모든 무력화형 상태 이상을 약점으로 적용<br><br>이동 속도 +8%<br>적중률 +10%@@6144@@1958';

lv105ItemId['결전의 리볼버 - 스핏파이어(남)'] = '0de8c4db9ddf8c15b769144f5b37b9d7';
lv105OptionMap['결전의 리볼버 - 스핏파이어(남)'] = {};
lv105OptionMap['결전의 리볼버 - 스핏파이어(남)'] = '버스터 샷이 가장 강한 적에게 지원 포격을 발사하도록 변경<br>- 다른 스킬 사용 중 모션 없이 시전 가능<br><br>오버차지 적용 중 평타 10회 공격 시 버스터샷 강화 탄약 충전 (최대 3개)<br>- 버스터샷 시전 시 강화 탄약 소모<br>- 탄약 1개 소모 : 공격력 15% 증가<br>- 탄약 2개 소모 : 공격력 40% 증가<br>- 탄약 3개 소모 : 공격력 80% 증가<br>- 던전 입장 시 탄약 1개 장착@@7112@@2016';

lv105ItemId['어릴적 꿈꿔온 무대'] = '08f0e19b36c8d5e4c4a9bd9f153c9deb';
lv105OptionMap['어릴적 꿈꿔온 무대'] = {};
lv105OptionMap['어릴적 꿈꿔온 무대'] = '최종 데미지 8% 증가<br><br>[꿈의 부활]<br>자신의 죽음을 꿈으로 만들며 잠듦 (쿨타임 300초)<br>- 잠들 때 HP 10% 회복<br>- 수면 상태일 때 피격 시 HP 15% 회복 (쿨타임 1초)<br>- 수면 상태 동안 피해를 받아도 꿈에서 깨지 않음<br><br>카운터 피격 시 물리/마법 피해 감소 +20%<br>HP 1분당 460.2 회복@@6952@@1948';

lv105ItemId['상상이 실현된 자유'] = '053083cff601b208098e3277886d8a5e';
lv105OptionMap['상상이 실현된 자유'] = {};
lv105OptionMap['상상이 실현된 자유'] = '최종 데미지 11% 증가<br><br>모든 속성 저항 +8<br>모든 상태 이상 내성 +30%<br>HP 1분당 460.2 회복@@6144@@1948';

lv105ItemId['잠식된 신수의 눈물'] = '0efac1f99dc08500fd61a9143374ce12';
lv105OptionMap['잠식된 신수의 눈물'] = {};
lv105OptionMap['잠식된 신수의 눈물'] = '최종 데미지 12% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +13%<br>캐스팅 속도 +17%<br>이동 속도 +5%<br>모든 상태 이상 내성 +10%@@6682@@1928';

lv105ItemId['근원을 삼킨 중검'] = '12776c72a541cd265e4df92fb300c7b5';
lv105OptionMap['근원을 삼킨 중검'] = {};
lv105OptionMap['근원을 삼킨 중검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['사멸하는 신뢰의 선현궁'] = '005aa96c3e2ae344a8ba86625febbcec';
lv105OptionMap['사멸하는 신뢰의 선현궁'] = {};
lv105OptionMap['사멸하는 신뢰의 선현궁'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['성장하는 모험가의 미늘창'] = 'c5451228dd344931504f59662d509488';
lv105OptionMap['성장하는 모험가의 미늘창'] = {};
lv105OptionMap['성장하는 모험가의 미늘창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 광창 - 다크 랜서'] = '2f9e97914447b5c4d42da63fee73f10f';
lv105OptionMap['결전의 광창 - 다크 랜서'] = {};
lv105OptionMap['결전의 광창 - 다크 랜서'] = '잠식 상태의 적 1마리당 1초마다 흑뢰의 기운 10% 획득<br>- 잠식 상태의 적이 없을 경우 1초마다 5% 감소<br><br>보스 몬스터 공격 시 흑뢰의 기운 1% 획득<br><br>흑뢰의 기운 100% 달성 시 15초 동안 다크 스타 강화<br>- 흑뢰 창이 동시에 낙하<br>- 흑뢰 마장창 추가 낙하<br>- 흑뢰 마장창 공격력 : 다크 스타 다단 히트 공격력의 1,000%@@10076@@2016';

lv105ItemId['시각의 관점'] = '922575ac85da5f999815024b6b2ec2a7';
lv105OptionMap['시각의 관점'] = {};
lv105OptionMap['시각의 관점'] = '[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>공격 시 MP 1,500 회복 (쿨타임 1초)<br><br>물리/마법 피해 감소 +10%<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@4528@@1612';

lv105ItemId['광폭화된 전의의 토템'] = '060a7fc85bab63b33152af7420789ec0';
lv105OptionMap['광폭화된 전의의 토템'] = {};
lv105OptionMap['광폭화된 전의의 토템'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['광폭화된 전의의 자동권총'] = '4fd35011dca1fcc168a9a3a668569062';
lv105OptionMap['광폭화된 전의의 자동권총'] = {};
lv105OptionMap['광폭화된 전의의 자동권총'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 소검 - 소드마스터'] = '5b6c824cbcc1dca38056596f6aa125c5';
lv105OptionMap['결전의 소검 - 소드마스터'] = {};
lv105OptionMap['결전의 소검 - 소드마스터'] = '궁극의 마검 레바테인으로 악즉참 시전<br>- 악즉참 항상 최대 충전<br>- 악즉참 공격력 15% 감소<br><br>레바테인에 의한 악즉참은 적에게 4초 동안 상흔을 부여<br>- 상흔이 생성된 적에게 반월 또는 섬광 적중 시 악즉참 남은 쿨타임 10% 감소@@8892@@2016';

lv105ItemId['흑화의 구속 팔찌'] = '5ca915f03d8a5a625465366e58bdc67f';
lv105OptionMap['흑화의 구속 팔찌'] = {};
lv105OptionMap['흑화의 구속 팔찌'] = '최종 데미지 7.1% 증가<br>출혈 피해 전환 +50%<br>출혈 피해 +20%<br><br>물리/마법 피해의 50%를 출혈 피해로 전환<br><br>출혈 내성 +1%@@3165@@1953';

lv105ItemId['황혼의 성단'] = '7b590f832a97ee8eacc17a54e3fe2d4a';
lv105OptionMap['황혼의 성단'] = {};
lv105OptionMap['황혼의 성단'] = '공격력 증가 +444.6%<br>최종 데미지 5% 증가<br>모든 속성 강화 +20<br><br>보스 몬스터 공격 시 HP 1% 회복 (쿨타임 0.5초)<br><br>공격 속도 +8%<br>캐스팅 속도 +12%@@6048@@1963';

lv105ItemId['결전의 투창 - 뱅가드'] = '748d312e9293c039299ef1a7209cc37e';
lv105OptionMap['결전의 투창 - 뱅가드'] = {};
lv105OptionMap['결전의 투창 - 뱅가드'] = '스매쉬의 극한에 도달하여 임팩트 스매쉬 강화<br>- 임팩트 스매쉬를 캔슬하여 스킬 시전 시 해당 스킬 쿨타임 20% 감소 (각성기 제외)<br>- 스택 횟수 +1<br>- 적중 시 스택 회복 감소 시간 +0.3초<br>- 공격 속도 버프 추가량 +10%@@5928@@2016';

lv105ItemId['옵티컬 컴뱃 글래스'] = 'a3e7f82fa4fb23f2e7eda9e1fadbe8ec';
lv105OptionMap['옵티컬 컴뱃 글래스'] = {};
lv105OptionMap['옵티컬 컴뱃 글래스'] = '최종 데미지 12% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +5%<br>캐스팅 속도 +7.5%<br>이동 속도 +5%<br>HP MAX +600<br>적중률 +10%@@4528@@1958';

lv105ItemId['자연에 녹아드는 이슬'] = '37522f61ef37c5b5251900c3345af96a';
lv105OptionMap['자연에 녹아드는 이슬'] = {};
lv105OptionMap['자연에 녹아드는 이슬'] = '최종 데미지 10% 증가<br><br>[속성 친화 - 암]<br>암속성 저항이 높아질수록 물리/마법 피해 감소<br>- 적의 공격에 암속성 부여<br><br>피격 시 5초 동안 HP 5% 회복 (쿨타임 7초)<br><br>HP MAX +600<br>암속성 저항 +35@@5604@@1948';

lv105ItemId['내딛은 자의 둔기'] = '620a5d6e3a573b289aa8a7571b7a9e54';
lv105OptionMap['내딛은 자의 둔기'] = {};
lv105OptionMap['내딛은 자의 둔기'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['오퍼레이션 오더'] = '04455f6f37f540bf6ad6027b26b049d3';
lv105OptionMap['오퍼레이션 오더'] = {};
lv105OptionMap['오퍼레이션 오더'] = '화속성 강화 +70<br><br>물리/마법 크리티컬 히트 +15%<br>화속성 저항 +10@@4528@@1958';

lv105ItemId['잠식된 신수의 그릇된 망토'] = '1ed7ede2a860cd3989a9a059916313f3';
lv105OptionMap['잠식된 신수의 그릇된 망토'] = {};
lv105OptionMap['잠식된 신수의 그릇된 망토'] = '최종 데미지 11% 증가<br><br>물리/마법 피해 감소 +10%<br>모든 속성 저항 +8<br>회피율 +8%@@6144@@1928';

lv105ItemId['결전의 리볼버 - 레인저(남)'] = '0d7a3b3ae556ab5f2f74a57c1c8faa24';
lv105OptionMap['결전의 리볼버 - 레인저(남)'] = {};
lv105OptionMap['결전의 리볼버 - 레인저(남)'] = '더블 건호크 강화<br>- 세 번째 건호크 잡기 성공 시 두 번째 건호크 투척 이후 사용한 스킬들의 남은 쿨타임 20% 감소<br>- 크기 10% 증가<br>- 시전 시 4초 동안 공격 속도 +30%<br><br>스타일리쉬 캔슬 가능 최대 횟수 +1<br><br>장착 시 스펙트럼 생성@@6520@@2016';

lv105ItemId['피어나는 자연의 삶'] = '3e63f9705e6592a52814f399c85e37d4';
lv105OptionMap['피어나는 자연의 삶'] = {};
lv105OptionMap['피어나는 자연의 삶'] = '공격력 증가 +133.4%<br>최종 데미지 2% 증가<br>수속성 강화 +20<br><br>[아이스 포스]<br>공격 시 수속성 강화 수치에 따라 수속성 원소 공격 시전 (쿨타임 1.5초)<br>- 100 이상 150 미만 : 아이스 니들<br>- 150 이상 250 미만 : 아이스 브레스<br>- 250 이상 : 블리자드<br>- 각각 총 공격력 증가의 720% / 920% / 1,480%만큼 피해<br>- 블리자드는 적에게 10초 동안 빙결 상태 이상 부여 (쿨타임 30초)<br><br>빙결 지속 시간 +3초<br><br>공격 속도 +10%<br>캐스팅 속도 +15%<br>수속성 저항 +20@@6414@@1978';

lv105ItemId['결전의 도 - 팔라딘'] = '61b5576a6e84ee3aa0db081e59731f61';
lv105OptionMap['결전의 도 - 팔라딘'] = {};
lv105OptionMap['결전의 도 - 팔라딘'] = '세라픽 페더를 소모하여 이스트레인지먼트 시전 시 추가 효과 발동<br>- 세라픽 페더 1장당 공격력 4% 증가<br>- 세라픽 페더 1장당 스킬 범위 3% 증가<br>- 적에게 기절 상태 이상 부여<br><br>세라픽 페더를 소모하여 위핑 웨이브 시전 시 추가 효과 발동<br>- 끌어들인 적을 2초 동안 홀딩<br>- 몰이 효과 범위 6단계 적용<br>- 몰이 효과 범위 20% 증가<br>- 세라픽 페더 최대 2개만 소모<br><br>위핑 웨이브 강화<br>- 스킬 범위 20% 증가<br>- 타격 성공 시 세라픽 페더 습득량 +2<br>- 도발시킨 적 앞에서 방어 상태 유지 시 세라픽 페더 획득 시간 50% 감소@@9484@@2016';

lv105ItemId['구속된 자유'] = 'c3755118b4e3b8ee50a3bfc6f8c027c9';
lv105OptionMap['구속된 자유'] = {};
lv105OptionMap['구속된 자유'] = '공격력 증가 +518.7%<br>최종 데미지 10% 증가<br>모든 속성 강화 +35<br><br>스킬의 무색 큐브 조각 소모량 8배 증가 (최대 16개)<br><br>물리/마법 크리티컬 히트 +7%<br>모든 상태 이상 내성 -10%@@5416@@1998';

lv105ItemId['광폭화된 전의의 둔기'] = '1b8a2818f8b58e17ac0cd15296725059';
lv105OptionMap['광폭화된 전의의 둔기'] = {};
lv105OptionMap['광폭화된 전의의 둔기'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['탐험가의 노련한 코트'] = '05eff736fa865f538981e7a3ea3bfd67';
lv105OptionMap['탐험가의 노련한 코트'] = {};
lv105OptionMap['탐험가의 노련한 코트'] = '모든 속성 강화 +10<br>출혈 피해 +20%<br><br>[장비 옵션 조작키] 입력 시 맵에 떨어져 있는 아이템을 캐릭터 발밑으로 이동 (쿨타임 30초)<br><br>공격 시 모든 적에게 15초 동안 출혈 상태 이상 부여 (쿨타임 10초)<br><br>이동 속도 +8%<br>HP MAX +300<br>MP MAX +473<br>출혈 내성 +10%<br>인벤토리 무게 한도 +5kg@@5604@@1968';

lv105ItemId['청연을 수호하는 의지'] = '01ad86b791f9959359e04d3be6178189';
lv105OptionMap['청연을 수호하는 의지'] = {};
lv105OptionMap['청연을 수호하는 의지'] = '최종 데미지 9% 증가<br>화상 피해 +10%<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>화상 지속 시간 -20%<br><br>화속성 저항 +10@@5066@@1938';

lv105ItemId['광폭화된 전의의 소검'] = '248568cf372ebcfe5a17912ab99544b0';
lv105OptionMap['광폭화된 전의의 소검'] = {};
lv105OptionMap['광폭화된 전의의 소검'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 중검 - 히트맨'] = '89f9c7e0396a7dc61351afd4f8fbc6b5';
lv105OptionMap['결전의 중검 - 히트맨'] = {};
lv105OptionMap['결전의 중검 - 히트맨'] = '전직 스킬의 후딜레이를 캔슬하여 데드 식스를 시전 가능<br>후딜레이 캔슬 시 아래의 효과 적용<br>- 캔슬한 스킬 남은 쿨타임 20% 감소<br>- 데드 식스 남은 쿨타임 50% 감소<br>- 8초 동안 공격 속도 +7% (최대 3중첩)@@10076@@2016';

lv105ItemId['사멸하는 신뢰의 스태프'] = '348638a2372850c483c1d18678a0edba';
lv105OptionMap['사멸하는 신뢰의 스태프'] = {};
lv105OptionMap['사멸하는 신뢰의 스태프'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 보우건 - 런처(여)'] = 'f39096a1750add0ca33e055ea14a11fa';
lv105OptionMap['결전의 보우건 - 런처(여)'] = {};
lv105OptionMap['결전의 보우건 - 런처(여)'] = '화염 강타를 화염을 압축하여 발사하는 최대 3회 충전 스킬로 변경<br>- 공격력 : 총 공격력의 50%<br>- 충전 쿨타임 : 4초<br><br>M-3 화염방사기 시전 시 화염을 압축하여 발사<br><br>UHT-03 플레게톤 이동 속도 비율 +10%@@9484@@2016';

lv105ItemId['근원을 삼킨 권투글러브'] = '0932d17f069b22230bf17ad018e8cb62';
lv105OptionMap['근원을 삼킨 권투글러브'] = {};
lv105OptionMap['근원을 삼킨 권투글러브'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 크로스슈터 - 헌터'] = '46462699782057c40835c9ddb9d5be96';
lv105OptionMap['결전의 크로스슈터 - 헌터'] = {};
lv105OptionMap['결전의 크로스슈터 - 헌터'] = '[매시브 피어서 강화]<br>피니셔 스킬 시전 시 다음에 사용하는 매시브 피어서 1회 강화<br>- 공격력 30% 증가<br>- 세컨드 부스트 충격파 범위 15% 증가<br>- 매시브 피어서로 강화 불가<br> <br>매시브 피어서 적중 시 마커 스택 미소모, 마커 효과를 5스택으로 고정<br> <br>2000px 내 적에게 쌓은 마커 5스택마다 매시브 피어서 스킬 쿨타임 초기화<br> <br>매시브 피어서 적중한 적에게 마커 1스택을 쌓음@@948@@2016';

lv105ItemId['내딛은 자의 소검'] = 'd46979700e78a28246f18e3284e2f142';
lv105OptionMap['내딛은 자의 소검'] = {};
lv105OptionMap['내딛은 자의 소검'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['얼어붙은 저항의 에테리얼 보우'] = '264b23e4e3c013050adfd15cf38d6890';
lv105OptionMap['얼어붙은 저항의 에테리얼 보우'] = {};
lv105OptionMap['얼어붙은 저항의 에테리얼 보우'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@592@@1836';

lv105ItemId['언리밋 사이버네틱'] = '11457e80ad591fa85c2be95dacfb953a';
lv105OptionMap['언리밋 사이버네틱'] = {};
lv105OptionMap['언리밋 사이버네틱'] = '스킬 쿨타임 15% 감소 (각성기 제외)<br><br>모든 적에게 5초 동안 저주 상태 이상 부여 (쿨타임 20초)<br><br>이동 속도 +8%<br>회피율 +8%@@5874@@1948';

lv105ItemId['파수꾼의 증표'] = '6dd7ac62edd60e331721359ee55b8ad3';
lv105OptionMap['파수꾼의 증표'] = {};
lv105OptionMap['파수꾼의 증표'] = '최종 데미지 10% 증가<br><br>[인형극]<br>D011-Risa가 캐릭터를 따라다님<br>- "컨퓨즈드 코어 슈트" 장착 필요<br><br>HP MAX +600<br>물리/마법 크리티컬 히트 +5%@@6144@@1928';

lv105ItemId['결전의 보우건 - 레인저(남)'] = '6dce09b076fc82b012a2d73fc9046dc6';
lv105OptionMap['결전의 보우건 - 레인저(남)'] = {};
lv105OptionMap['결전의 보우건 - 레인저(남)'] = '더블 건호크 강화<br>- 세 번째 건호크 잡기 성공 시 두 번째 건호크 투척 이후 사용한 스킬들의 남은 쿨타임 20% 감소<br>- 크기 10% 증가<br>- 시전 시 4초 동안 공격 속도 +30%<br><br>스타일리쉬 캔슬 가능 최대 횟수 +1<br><br>장착 시 스펙트럼 생성@@6520@@2016';

lv105ItemId['결전의 광창 - 뱅가드'] = '0f64a59dc76c3a3988b07b61eafe48aa';
lv105OptionMap['결전의 광창 - 뱅가드'] = {};
lv105OptionMap['결전의 광창 - 뱅가드'] = '스매쉬의 극한에 도달하여 임팩트 스매쉬 강화<br>- 임팩트 스매쉬를 캔슬하여 스킬 시전 시 해당 스킬 쿨타임 20% 감소 (각성기 제외)<br>- 스택 횟수 +1<br>- 적중 시 스택 회복 감소 시간 +0.3초<br>- 공격 속도 버프 추가량 +10%@@5928@@2016';

lv105ItemId['결전의 도 - 소울브링어'] = '0f1a5e1b4a4b94958d2722a9945371b9';
lv105OptionMap['결전의 도 - 소울브링어'] = {};
lv105OptionMap['결전의 도 - 소울브링어'] = '명계의 힘으로 흑염의 칼라가 명계의 불꽃을 발현<br>불꽃 최대 중첩 수 +2<br><br>아래 스킬들이 명계의 불꽃을 발현<br>명계의 불꽃이 붙은 대상을 특정 스킬로 공격 시 불꽃 폭발<br>- 폭발 공격력 :  흑염의 칼라 불꽃 공격력의 300%<br>- 불꽃 중첩당 폭발 공격력 10% 증가<br><br><명계의 불꽃 발현 스킬><br>- 흑염의 칼라<br>- 냉기의 사야<br>- 역병의 라사<br>- 툼스톤<br>- 툼스톤 스웜프<br>- 블레이드 팬텀<br><br><명계의 불꽃 폭발 스킬><br>- 귀참<br>- 달빛 베기<br>- 귀참 : 광폭<br>- 귀영섬<br>- 귀참 : 나락<br>- 흑염검<br>- 검귀강령 : 식<br>- 탐식의 크레바스@@5928@@2016';

lv105ItemId['결전의 봉 - 소환사'] = '3e1720a5fd9a9adb8326f459a966a6ba';
lv105OptionMap['결전의 봉 - 소환사'] = {};
lv105OptionMap['결전의 봉 - 소환사'] = '상급 정령들과 정령왕 에체베리아의 이클립스 하이브 추가 조작 패턴 공격력 20% 증가<br><br>이클립스 하이브 강화<br>- 추가 조작 패턴 캐스팅 모션 삭제 <br>- 데드 멀커 : 블랙스웜 홀딩 추가<br>- 글레어린 : 썬더콜링이 적을 끌어모음<br>- 아퀘리스 : 헵타 아이스 미사일이 지상 폭격<br>- 플레임 헐크 : 화염폭검술이 회오리를 생성하고 적을 끌어모음<br>- 에체베리아 : 초고밀도 레이저가 다수 레이저의 지상 폭격으로 변경@@8300@@2016';

lv105ItemId['광폭화된 전의의 건틀릿'] = 'a11b69d31a2032a8505a10e639e865a6';
lv105OptionMap['광폭화된 전의의 건틀릿'] = {};
lv105OptionMap['광폭화된 전의의 건틀릿'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['수호하는 인내'] = '168ad8e204aca0ebd4fe71c2fad71b0f';
lv105OptionMap['수호하는 인내'] = {};
lv105OptionMap['수호하는 인내'] = '최종 데미지 13% 증가<br><br>HP MAX +5%<br>HP MAX +600@@6144@@1958';

lv105ItemId['결전의 머스켓 - 레인저(남)'] = '2689557c3f125474b4df96f7a33f6433';
lv105OptionMap['결전의 머스켓 - 레인저(남)'] = {};
lv105OptionMap['결전의 머스켓 - 레인저(남)'] = '더블 건호크 강화<br>- 세 번째 건호크 잡기 성공 시 두 번째 건호크 투척 이후 사용한 스킬들의 남은 쿨타임 20% 감소<br>- 크기 10% 증가<br>- 시전 시 4초 동안 공격 속도 +30%<br><br>스타일리쉬 캔슬 가능 최대 횟수 +1<br><br>장착 시 스펙트럼 생성@@6520@@2016';

lv105ItemId['근원을 삼킨 완드'] = 'c7705c82d68209fa32c59b7a9d960e5b';
lv105OptionMap['근원을 삼킨 완드'] = {};
lv105OptionMap['근원을 삼킨 완드'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['얼어붙은 저항의 보우건'] = '045d1b54412a36fe7cc4a59d1e9a8a09';
lv105OptionMap['얼어붙은 저항의 보우건'] = {};
lv105OptionMap['얼어붙은 저항의 보우건'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['샤를의 가면'] = '6a3fd6aea652c8cba810beff4aad182e';
lv105OptionMap['샤를의 가면'] = {};
lv105OptionMap['샤를의 가면'] = '최종 데미지 35% 감소 (각성기 제외)<br>스킬 쿨타임 50% 감소 (각성기 제외)<br><br>공격 시 적에게 10초 동안 출혈/중독 상태 이상 부여 (쿨타임 3초)@@6170@@1872';

lv105ItemId['결전의 중검 - 요원'] = '1758dfbdf2e9ad3a171f60f89dbcf426';
lv105OptionMap['결전의 중검 - 요원'] = {};
lv105OptionMap['결전의 중검 - 요원'] = '암살목표의 표식이 부여된 적에게 일부 스킬 공격 시 15초 동안 총상 / 절상 부여 (최대 3중첩)<br>- 총상 부여 스킬 : 조준 사격, 목표 사격<br>- 절상 부여 스킬 : 쌍현참, 암월비보<br><br>총상 / 절상 상태의 적을 아래의 스킬로 공격 시 총상 / 절상이 해제되며 스킬 공격력 증가<br>- 문페이즈 : 총상 1중첩당 8%<br>- 섬멸 : 절상 1중첩당 8%<br>- 월광진혼곡 : 총상 / 절상 1중첩당 8%<br><br>아래의 스킬 쿨타임 15% 감소<br>- 쌍현참<br>- 조준 사격<br>- 암월비보<br>- 목표 사격<br><br>문페이즈 크기 10% 증가@@7112@@2016';

lv105ItemId['골렘의 중추석 반지'] = '639dedaa8b9218023da86ff44f80662f';
lv105OptionMap['골렘의 중추석 반지'] = {};
lv105OptionMap['골렘의 중추석 반지'] = '최종 데미지 10% 증가<br><br>물리/마법 방어력 +12,000<br>적중률 +10%<br>회피율 +8%@@6414@@1938';

lv105ItemId['포이즈드 나이트 팬츠'] = '0446ffe6652d40bdd06aa5b4b63e78e9';
lv105OptionMap['포이즈드 나이트 팬츠'] = {};
lv105OptionMap['포이즈드 나이트 팬츠'] = '최종 데미지 10% 증가<br>75~80Lv 스킬 공격력 5% 증가<br>75~80Lv 스킬 범위 15% 증가@@4824@@1900';

lv105ItemId['두근거리는 열정'] = '1e7ade53a86f1d3589082304002ce4bc';
lv105OptionMap['두근거리는 열정'] = {};
lv105OptionMap['두근거리는 열정'] = '최종 데미지 10% 증가<br><br>물리/마법 피해 감소 +10%<br>물리/마법 방어력 +7,000<br>HP 1분당 46.02 회복<br>수면 내성 -20%@@4796@@1948';

lv105ItemId['결전의 차크라 웨펀 - 쿠노이치'] = '2a2608e0ea07d330a222712bf0dd447d';
lv105OptionMap['결전의 차크라 웨펀 - 쿠노이치'] = {};
lv105OptionMap['결전의 차크라 웨펀 - 쿠노이치'] = '인법 : 잔영 남기기의 잔영 소모 1개당 다음에 재생할 육도윤회의 잔영 공격력 비율 1.5% 추가 증가 (최대 12중첩)<br><br>인법 : 육도윤회 시전 시 5초 동안 슈퍼아머 상태가 되며 스킬 쿨타임 회복 속도 +50% (쿨타임 20초 / 각성기 제외)<br><br>인법 : 육도윤회 스킬 종료 시 잔영 5 회복 (쿨타임 10초)@@8300@@2016';

lv105ItemId['결전의 클로 - 스트라이커(여)'] = 'ab9aa27481df2c416b0223939902fcc5';
lv105OptionMap['결전의 클로 - 스트라이커(여)'] = {};
lv105OptionMap['결전의 클로 - 스트라이커(여)'] = '라이트닝 댄스 이동 횟수가 4회 감소하고 1:1 공격 시 대상의 좌우를 이동하며 공격<br>- 공격력 15% 감소<br>- 공격 속도 +30%<br>- 이동 범위 5% 증가<br>- 공격 시 적에게 감전 상태 이상 부여 (쿨타임 15초)<br><br>라이트닝 댄스 공격 시 충격파 생성<br>- 직접 타격 대상은 충격파 피격 제외<br>- 1:1 공격일 경우 충격파 미생성@@9484@@2016';

lv105ItemId['약속을 지키는 발걸음'] = '0345e0bed61cbd915303f995f013fb3e';
lv105OptionMap['약속을 지키는 발걸음'] = {};
lv105OptionMap['약속을 지키는 발걸음'] = '최종 데미지 15.5% 증가<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>"영면을 위한 준비" 장비의 [얼렸다 녹였다] 쿨타임 제거<br><br>화상 지속 시간 +4초<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5중첩)<br><br>일반 몬스터 공격 시 모든 적에게 빙결 상태 이상 부여 (쿨타임 1초)@@5524@@1948';

lv105ItemId['디젯 퓨즈 초크'] = '449297ab52b15a0363fd98f02b1af4fe';
lv105OptionMap['디젯 퓨즈 초크'] = {};
lv105OptionMap['디젯 퓨즈 초크'] = '최종 데미지 8% 증가<br><br>공격 시 적의 무력화 게이지량 10 감소 (쿨타임 3초, 버퍼 미적용)<br><br>무력화 게이지가 파괴된 적 공격 시 아래의 효과 적용<br>- 파괴 횟수에 따라 무력화 게이지 감소량 +20% (최대 5중첩)<br>- 마지막으로 적용된 무력화형 상태 이상을 약점 상태 이상으로 적용<br><br>HP MAX +600<br>물리/마법 방어력 +7,000@@6682@@1920';

lv105ItemId['찰랑이는 생명수'] = '19324f712cecd5acf393a66395cecfc7';
lv105OptionMap['찰랑이는 생명수'] = {};
lv105OptionMap['찰랑이는 생명수'] = '최종 데미지 9% 증가<br><br>[아이스 스트라이크]<br>빙결 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>[얼음 걸음]<br>발걸음에 냉기의 기운을 담아 이동 시 3초 동안 빙하 지대 생성<br>- 빙하 지대는 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 8초)<br><br>빙결 상태 적 공격 시 냉기 폭발 발생 (쿨타임 0.5초)<br>- 총 공격력 증가의 40%만큼 피해<br><br>HP MAX +600@@4714@@1930';

lv105ItemId['근원을 삼킨 보우건'] = '5677fd1686c2b78b22ea36beb98d41ca';
lv105OptionMap['근원을 삼킨 보우건'] = {};
lv105OptionMap['근원을 삼킨 보우건'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['근원을 삼킨 자동권총'] = '448226c65d254dece58cde5970fb0ae5';
lv105OptionMap['근원을 삼킨 자동권총'] = {};
lv105OptionMap['근원을 삼킨 자동권총'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['미지의 문명 - 트윈 스톤'] = 'dd57a830c1b71e58aa9a33feae415f8f';
lv105OptionMap['미지의 문명 - 트윈 스톤'] = {};
lv105OptionMap['미지의 문명 - 트윈 스톤'] = '수속성 강화 +70<br><br>물리/마법 크리티컬 히트 +15%<br>수속성 저항 +10@@4528@@1958';

lv105ItemId['결전의 십자가 - 미스트리스'] = '3d600aa6d433993b4380b5e5aa94d37c';
lv105OptionMap['결전의 십자가 - 미스트리스'] = {};
lv105OptionMap['결전의 십자가 - 미스트리스'] = '타락의 칠죄종 변신 중 죄를 깨우는 손길 공격 시 변신 지속 시간 +12초<br>- 최대 변신 지속 시간 초과 불가능<br><br>글러트니 바밋 시전 시 자신을 중심으로 적을 흡수하고 폭발<br>- 공격력 30% 증가<br>- 죄업 스택 3개 이상일 때 시전 시 죄업 스택을 3개 소모하고 분신이 대신 시전<br><br>죄업 스택이 3개 이상일 때 무거운 죄업 시전 시 죄업 스택을 3개 소모하고 10초 동안 물리/마법 피해 감소 +15%<br><br>일곱 개의 대죄 적용 중 무거운 죄업 적 홀딩 시간 +1초<br><br>죄를 깨우는 손길 죄업 스택 증가량 +2@@7708@@2016';

lv105ItemId['결전의 보우건 - 스핏파이어(남)'] = '8cc7d6d2657108cd8c0726005079afb4';
lv105OptionMap['결전의 보우건 - 스핏파이어(남)'] = {};
lv105OptionMap['결전의 보우건 - 스핏파이어(남)'] = '버스터 샷이 가장 강한 적에게 지원 포격을 발사하도록 변경<br>- 다른 스킬 사용 중 모션 없이 시전 가능<br><br>오버차지 적용 중 평타 10회 공격 시 버스터샷 강화 탄약 충전 (최대 3개)<br>- 버스터샷 시전 시 강화 탄약 소모<br>- 탄약 1개 소모 : 공격력 15% 증가<br>- 탄약 2개 소모 : 공격력 40% 증가<br>- 탄약 3개 소모 : 공격력 80% 증가<br>- 던전 입장 시 탄약 1개 장착@@7112@@2016';

lv105ItemId['수확하는 옥수'] = '75e882cdd821aa67748eb24ff1f5be59';
lv105OptionMap['수확하는 옥수'] = {};
lv105OptionMap['수확하는 옥수'] = '최종 데미지 8% 증가<br>스킬 쿨타임 회복 속도 +25% (각성기 제외)<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>적중률 +10%@@4528@@1968';

lv105ItemId['메탈기어 암 숄더'] = '2c3d242ad16ba75b314d3c6909fbad1a';
lv105OptionMap['메탈기어 암 숄더'] = {};
lv105OptionMap['메탈기어 암 숄더'] = '최종 데미지 3% 증가<br>스킬 쿨타임 회복 속도 +25% (각성기 제외)<br><br>물리/마법 크리티컬 히트 +5%<br>MP MAX +5%@@3450@@1948';

lv105ItemId['인력의 법칙'] = '6779c7e0993f89359b6681fa74891486';
lv105OptionMap['인력의 법칙'] = {};
lv105OptionMap['인력의 법칙'] = '최종 데미지 14% 증가<br><br>대시 공격 시 전방으로 빠르게 질주 (쿨타임 5초)<br><br>이동 속도 +28%<br>회피율 +8%@@5604@@1958';

lv105ItemId['결전의 핸드캐넌 - 메카닉(여)'] = '2408ac00b53923d0db2c3d961005c98d';
lv105OptionMap['결전의 핸드캐넌 - 메카닉(여)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 메카닉(여)'] = 'G-S.P. 팔콘 쿨타임 20% 감소<br><br>G-1 코로나 상태에서 롤링썬더 또는 랩터로 트랜스폼 시 8초 동안 홀로그램 G-1 코로나 생성<br>- 공격력 : G-1 코로나 공격력의 50%<br>- G 마그네틱 시전 시 홀로그램 G-1 코로나가 대신 시전<br><br>트랜스폼 : G-3 랩터가 강화 레이저 발사<br>- 강화 레이저 발사@@8300@@2016';

lv105ItemId['성장하는 모험가의 쌍검'] = '8378afb3020b9381933dfea97719db1d';
lv105OptionMap['성장하는 모험가의 쌍검'] = {};
lv105OptionMap['성장하는 모험가의 쌍검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['성장하는 모험가의 인장 반지'] = '8ca6d486809b36bb94eb6a464a4ebbe2';
lv105OptionMap['성장하는 모험가의 인장 반지'] = {};
lv105OptionMap['성장하는 모험가의 인장 반지'] = '최종 데미지 7% 증가@@5336@@1986';

lv105ItemId['결전의 대검 - 엘븐나이트'] = '7b08ff253db0cf304c8733df798a92db';
lv105OptionMap['결전의 대검 - 엘븐나이트'] = {};
lv105OptionMap['결전의 대검 - 엘븐나이트'] = '체인러쉬 콤보 6중첩 상태에서 체인 스트라이크 시전 시 8초 동안 피버 타임<br>- 체인러쉬 공격력 500% 증가<br>- 체인 게이지의 모든 영역을 성공 영역으로 변경<br>- 1~30Lv 스킬 쿨타임 70% 감소<br>- 1~30Lv 스킬 공격력 50% 감소 (체인러쉬 제외)<br><br>체인러쉬 시전 시 1~30Lv 스킬 남은 쿨타임 10% 감소@@5928@@2016';

lv105ItemId['미니어쳐 헤드셋 암릿'] = '006fece0fcb87360c4ca10a575490cf4';
lv105OptionMap['미니어쳐 헤드셋 암릿'] = {};
lv105OptionMap['미니어쳐 헤드셋 암릿'] = '기본기 숙련 최종 데미지 44% 증가<br>15~30Lv 스킬 공격력 21% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>적중률 +10%@@5066@@1948';

lv105ItemId['결전의 권투글러브 - 스트라이커(남)'] = '0ed8b87bd17aa861de8940cd5e583706';
lv105OptionMap['결전의 권투글러브 - 스트라이커(남)'] = {};
lv105OptionMap['결전의 권투글러브 - 스트라이커(남)'] = '이중개방의 효과가 10초 동안 유지되는 맹렬한 화염으로 변경되며 최종 데미지 45% 증가 (각성기 제외)<br>- 이중개방 강화 공격력 효과 미적용<br><br>화염의 힘으로 머슬시프트 강화<br>- 캔슬 공격 시 이중개방 남은 쿨타임 2% 감소<br>- 캔슬 시 30초 동안 전직 계열 스킬 공격력 증가율 +2%<br>- 캔슬 횟수 +2@@7112@@2016';

lv105ItemId['결전의 소검 - 아수라'] = '7c3b4c751b306d1b52ac1a7a5a15e3a9';
lv105OptionMap['결전의 소검 - 아수라'] = {};
lv105OptionMap['결전의 소검 - 아수라'] = '파동검 폭염이 빠르게 폭발<br>- 폭발 위치 변경<br>- 폭발 간격 시간 30% 감소<br>- 폭발 간격 거리 90% 감소<br>- 폭발 크기 45% 증가<br>- 3타 폭발 크기 70% 증가<br><br>뇌신의 힘을 받아 아래의 스킬 시전 중 작열 파동진을 모션 없이 시전 가능<br>- 파동검 폭염<br>- 부동명왕진<br>- 파동검 : 인다라망<br><br>살의의 파동 파동 영역 20% 증가<br><br>작열 파동진 스킬 범위 25% 증가@@10672@@2016';

lv105ItemId['결전의 염주 - 크루세이더(남)'] = '505aec8e464bb2a88c7519803bb78a87';
lv105OptionMap['결전의 염주 - 크루세이더(남)'] = {};
lv105OptionMap['결전의 염주 - 크루세이더(남)'] = '영광의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>슬로우 힐 스킬 범위 20% 증가<br><br>정의의 심판 시전 시 빛무리를 소환하여 범위 내 적 10명을 추적 후 정의의 창을 발사하여 폭발<br>- 창 공격력 : 빛의 칼날 공격력의 1600%<br>- 폭발 공격력 : 마법진 최대 유지 시 피니쉬 공격력의 100%<br>- 다른 스킬 시전 중 시전 가능<br><br>미러클 스플리터 스킬 공격력 20% 증가<br>- 돌진 거리 70% 감소<br><br>디플렉트 월 HP 400% 증가<br>- 이동하지 않음<br><br>싱글 플레이 시 독립 공격력 20% 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['사멸하는 신뢰의 건틀릿'] = '5331449c2d9e332be4c6de7cd1271bbb';
lv105OptionMap['사멸하는 신뢰의 건틀릿'] = {};
lv105OptionMap['사멸하는 신뢰의 건틀릿'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['사멸하는 신뢰의 중검'] = '081a5d093b1185df85e2acb4aca90a35';
lv105OptionMap['사멸하는 신뢰의 중검'] = {};
lv105OptionMap['사멸하는 신뢰의 중검'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 둔기 - 엘븐나이트'] = '452a01be805c3cbf13183f5a51c6cc45';
lv105OptionMap['결전의 둔기 - 엘븐나이트'] = {};
lv105OptionMap['결전의 둔기 - 엘븐나이트'] = '체인러쉬 콤보 6중첩 상태에서 체인 스트라이크 시전 시 8초 동안 피버 타임<br>- 체인러쉬 공격력 500% 증가<br>- 체인 게이지의 모든 영역을 성공 영역으로 변경<br>- 1~30Lv 스킬 쿨타임 70% 감소<br>- 1~30Lv 스킬 공격력 50% 감소 (체인러쉬 제외)<br><br>체인러쉬 시전 시 1~30Lv 스킬 남은 쿨타임 10% 감소@@5928@@2016';

lv105ItemId['고통의 상처'] = '0f80d093bd920a6c75076ae987e9c157';
lv105OptionMap['고통의 상처'] = {};
lv105OptionMap['고통의 상처'] = '최종 데미지 12% 증가<br><br>장비로 인한 HP 회복 효과 30% 증가<br><br>HP MAX +600<br>HP 1분당 460.2 회복@@4258@@1996';

lv105ItemId['결전의 투창 - 드래고니안 랜서'] = 'f898fc77d94ad7a778ac3a745305e312';
lv105OptionMap['결전의 투창 - 드래고니안 랜서'] = {};
lv105OptionMap['결전의 투창 - 드래고니안 랜서'] = '서번트 랜스 스킬로 공격한 적에게 사냥 표식을 생성하고 사냥 1단계 부여<br>사냥 표식 20개마다 사냥 단계 상승 (최대 3단계)<br>아래 스킬 시전 시 사냥 단계를 소모하고 해당 스킬 공격력 증가<br>- 데보닝 슬래셔<br>- 팔티잔 스큐어<br>- 날개 꿰기<br><br><사냥 단계별 공격력 증가량><br>- 1단계 : 10%<br>- 2단계 : 20%<br>- 3단계 : 30%<br><br><사냥 표식 부여량><br>- 서번트 랜스 : 타격당 3 스택<br>- 서번트 랜스 : 어썰트 : 타격당 10 스택<br>- 서번트 랜스 : 인게이저 : 타격당 4 스택<br><br>서번트 랜스 마법창 재생성 시간 30% 감소<br>서번트 랜스 재발사 쿨타임 30% 감소@@8300@@2016';

lv105ItemId['결전의 낫 - 어벤저'] = '90165e0906a6d8f78f87c371605031ed';
lv105OptionMap['결전의 낫 - 어벤저'] = {};
lv105OptionMap['결전의 낫 - 어벤저'] = '악마화 상태에서 악마 게이지 30 이상일 때 악마 게이지 소모 스킬 공격 시 40을 추가 소모하여 데빌 클로 발동 (각성기 제외)<br>- 데빌 스트라이커 공격력의 2,000%<br><br>악마화 평타 공격 및 스킬의 악마 게이지 회복량 10% 증가<br>고통의 희열 악마 게이지 회복량 10% 증가<br><br>악마화 공격 속도 증가량 20% 추가 증가, 이동 속도 증가량 10% 추가 증가@@7112@@2016';

lv105ItemId['네오 화이트 클래스 팬츠'] = '0ea532d9cf26195ad6990f5ba8c13632';
lv105OptionMap['네오 화이트 클래스 팬츠'] = {};
lv105OptionMap['네오 화이트 클래스 팬츠'] = '최종 데미지 8% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 <br>100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5604@@1612';

lv105ItemId['생명이 담긴 가죽 토시'] = '9b5bb81d4e3ce0af441fc1f3b30d55a1';
lv105OptionMap['생명이 담긴 가죽 토시'] = {};
lv105OptionMap['생명이 담긴 가죽 토시'] = '최종 데미지 7.1% 증가<br>감전 피해 전환 +50%<br>감전 피해 +20%<br><br>물리/마법 피해의 50%를 감전 피해로 전환<br><br>감전 내성 +1%@@3165@@1953';

lv105ItemId['얼어붙은 저항의 중검'] = '4650decdc9691504b9a5f652a2de709d';
lv105OptionMap['얼어붙은 저항의 중검'] = {};
lv105OptionMap['얼어붙은 저항의 중검'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 로드 - 마도학자'] = 'b3e2763a1991dc85e6d8a7c66f6bbadc';
lv105OptionMap['결전의 로드 - 마도학자'] = {};
lv105OptionMap['결전의 로드 - 마도학자'] = '롤리팝 크러쉬가 더욱 많은 사탕 인형 생성<br>- 최대 사탕 생성 개수 +5<br>- 피격개체당 사탕인형 생성 수 +2<br>- 생성된 사탕인형 1개당 롤리팝 크러쉬의 남은 쿨타임 3% 감소<br>- 사탕인형 공격력 20% 감소<br><br>폭발하지 않은 사탕 인형을 흡수하여 다음 롤리팝 크러쉬 때 생성 (최대 15개)<br>- 사탕당 흡수 1번 가능<br>- 추가 생성된 사탕 인형은 최대 사탕 생성 개수에 미포함@@8300@@2016';

lv105ItemId['완성형 동력 제어장치'] = '2849ef416e30404723f0f5689b9aaa2b';
lv105OptionMap['완성형 동력 제어장치'] = {};
lv105OptionMap['완성형 동력 제어장치'] = '최종 데미지 14% 증가<br><br>이동 속도 +8%<br>모든 속성 저항 +10<br>적중률 +10%@@3988@@1938';

lv105ItemId['결전의 도 - 카오스'] = '410a627dce856ccbb225e9428a602fca';
lv105OptionMap['결전의 도 - 카오스'] = {};
lv105OptionMap['결전의 도 - 카오스'] = '제노사이드 크러쉬 시전 시 데몬들을 폭발시키는 대신 아텐의 화신이 데몬들의 환영을 소환하고 폭발시키도록 변경<br>- 공격력 10% 증가<br>- 스킬 범위 20% 증가<br>- 시전 중 미드나잇 카니발 시전 가능<br>- 데몬이 없을 경우 즉시 모든 데몬 소환@@10076@@2016';

lv105ItemId['결전의 배틀액스 - 크루세이더(남)'] = '3ea64c37072a08922e343425c2929c9d';
lv105OptionMap['결전의 배틀액스 - 크루세이더(남)'] = {};
lv105OptionMap['결전의 배틀액스 - 크루세이더(남)'] = '영광의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>슬로우 힐 스킬 범위 20% 증가<br><br>정의의 심판 시전 시 빛무리를 소환하여 범위 내 적 10명을 추적 후 정의의 창을 발사하여 폭발<br>- 창 공격력 : 빛의 칼날 공격력의 1600%<br>- 폭발 공격력 : 마법진 최대 유지 시 피니쉬 공격력의 100%<br>- 다른 스킬 시전 중 시전 가능<br><br>미러클 스플리터 스킬 공격력 20% 증가<br>- 돌진 거리 70% 감소<br><br>디플렉트 월 HP 400% 증가<br>- 이동하지 않음<br><br>싱글 플레이 시 독립 공격력 20% 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['결전의 소검 - 버서커'] = '67c6a6945910f1475705eabfa68091f9';
lv105OptionMap['결전의 소검 - 버서커'] = {};
lv105OptionMap['결전의 소검 - 버서커'] = '피에 굶주려 갈증 시전 시 HP 10%를 소모하고 혈기 3개 획득 (최대 10스택)<br>- 갈증 충전 기능 삭제<br>- 다른 스킬 시전 중 갈증 시전 가능<br>- 던전 입장 시 혈기 1개 획득<br><br>블러드 소드, 격노 시전 시 혈기 1개를 소모하여 강화<br>- 공격력 30% 증가<br>- 스킬 범위 15% 증가<br><br>블러드 소드, 격노 시전 시 HP 5% 회복@@9484@@2016';

lv105ItemId['사멸하는 신뢰의 십자가'] = 'dc0f8b9734ab0c1236fbd7cff016412a';
lv105OptionMap['사멸하는 신뢰의 십자가'] = {};
lv105OptionMap['사멸하는 신뢰의 십자가'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['기사의 구원'] = '9cf0c255457ecfd203a79238574ee94b';
lv105OptionMap['기사의 구원'] = {};
lv105OptionMap['기사의 구원'] = '최종 데미지 15% 증가<br><br>점프 상태에서 [경매장 키] 입력 시 즉시 낙하<br><br>이동 속도 +20%<br>점프력 +100<br>적중률 +10%<br>회피율 +8%@@6414@@1910';

lv105ItemId['근원을 삼킨 단검'] = '1512194117e77293ff88fabaf6f06e6e';
lv105OptionMap['근원을 삼킨 단검'] = {};
lv105OptionMap['근원을 삼킨 단검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['사멸하는 신뢰의 코어 블레이드'] = '9dddae23098f16e24baabeac1718d3be';
lv105OptionMap['사멸하는 신뢰의 코어 블레이드'] = {};
lv105OptionMap['사멸하는 신뢰의 코어 블레이드'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['옭아매는 공포'] = '0d364fbe6c14575e23c927a85bffe11d';
lv105OptionMap['옭아매는 공포'] = {};
lv105OptionMap['옭아매는 공포'] = '1~30Lv 스킬 공격력 12% 증가<br><br>무색 큐브 조각을 소모하는 스킬 공격력 증가<br>- 1개 ~ 9개 : 2%<br>- 10개 ~ 15개 : 15%<br>- 16개 이상 : 20%<br><br>무색 큐브 조각을 소모하지 않는 스킬의 무색 큐브 조각 소모량 +2<br><br>물리/마법 크리티컬 히트 +5%<br>스킬 MP 소모량 -7%@@4528@@1986';

lv105ItemId['결전의 도 - 베가본드'] = '45c1554ea1e99c8ff2899be137511f61';
lv105OptionMap['결전의 도 - 베가본드'] = {};
lv105OptionMap['결전의 도 - 베가본드'] = '십자검 시전 시 전방의 가장 강력한 적을 추적한 후 수평, 수직 베기 동시 시전<br><br>전직 스킬의 후딜레이를 캔슬하여 십자검, 엽해소옥 시전 가능<br><br>아래의 스킬 쿨타임 20% 감소<br>- 일화도강<br>- 십자검<br>- 연화섬<br>- 엽해소옥@@8892@@2016';

lv105ItemId['옥화의 망령 흉갑'] = '30b66937d2112e5e344c70709dba078d';
lv105OptionMap['옥화의 망령 흉갑'] = {};
lv105OptionMap['옥화의 망령 흉갑'] = '최종 데미지 8% 증가<br><br>[천공의 지배]<br>점프 상태에서 [장비 옵션 조작키] 입력 시 10초 동안 천공을 지배하며 회오리 바람 소환 (쿨타임 20초)<br>- 주변의 적을 끌어모음<br>- 슈퍼 아머 부여<br>- 회오리 바람은 총 공격력 증가의 2,880%만큼 피해<br>- [장비 옵션 조작키] 재입력 시 지상으로 착지<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +10%<br>캐스팅 속도 +15%<br>이동 속도 +8%@@6144@@1958';

lv105ItemId['결전의 소검 - 검귀'] = 'c24505fa6eb1233a2a5f5a373fbfb827';
lv105OptionMap['결전의 소검 - 검귀'] = {};
lv105OptionMap['결전의 소검 - 검귀'] = '검술 , 합격 계열 스킬 공격 시 귀신보 쿨타임 초기화<br>- 검술 계열 스킬을 귀신보로 발동하는 경우 제외<br><br>귀신보 공격력 20% 증가<br><br>귀신보 Y축 공격 범위 10% 증가@@9484@@2016';

lv105ItemId['결전의 둔기 - 베가본드'] = '66dfb0fd641afb47e08d6988322672db';
lv105OptionMap['결전의 둔기 - 베가본드'] = {};
lv105OptionMap['결전의 둔기 - 베가본드'] = '십자검 시전 시 전방의 가장 강력한 적을 추적한 후 수평, 수직 베기 동시 시전<br><br>전직 스킬의 후딜레이를 캔슬하여 십자검, 엽해소옥 시전 가능<br><br>아래의 스킬 쿨타임 20% 감소<br>- 일화도강<br>- 십자검<br>- 연화섬<br>- 엽해소옥@@8892@@2016';

lv105ItemId['대지를 딛는 부츠'] = '269dff5b07191288ef2ed0bb94628937';
lv105OptionMap['대지를 딛는 부츠'] = {};
lv105OptionMap['대지를 딛는 부츠'] = '최종 데미지 17% 증가<br><br>10초마다 HP MAX 10% 수치의 보호막 부여<br><br>점프하여 착지 시 충격파 발생<br>- 충격파 발생 시 적에게 3초 동안 기절 상태 이상 부여 (쿨타임 20초)<br>- 총 공격력 증가의 2,200%만큼 피해<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%@@6952@@1920';

lv105ItemId['얼어붙은 저항의 낫'] = '0fdc480ad4114b29fb1c56d0e64d71f2';
lv105OptionMap['얼어붙은 저항의 낫'] = {};
lv105OptionMap['얼어붙은 저항의 낫'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 낫 - 이단심판관'] = 'a03894821d876669117ecf652226f554';
lv105OptionMap['결전의 낫 - 이단심판관'] = {};
lv105OptionMap['결전의 낫 - 이단심판관'] = '세례의 불길, 정화의 불꽃 스킬 쿨타임 20% 감소<br><br>정화의 불꽃 시전 시 500px 내 모든 적에게 폭발 발생<br><br>플레게의 정수를 최대 5회 충전 스킬로 변경<br>- 충전 쿨타임 : 8초<br>- 소각 발생 시 충전 시간 1초 감소@@5336@@2016';

lv105ItemId['폭발형 : 소형 전술 플레어'] = '20391270b9a8696cfa779fde0ab8f75a';
lv105OptionMap['폭발형 : 소형 전술 플레어'] = {};
lv105OptionMap['폭발형 : 소형 전술 플레어'] = '최종 데미지 10% 증가<br><br>공격 속도 +30%<br>캐스팅 속도 +30%<br>이동 속도 +53%<br>MP 1분당 348 회복@@6144@@1958';

lv105ItemId['결전의 건틀릿 - 그래플러(남)'] = '30ce27a418e877d62bc8157dd0b156e2';
lv105OptionMap['결전의 건틀릿 - 그래플러(남)'] = {};
lv105OptionMap['결전의 건틀릿 - 그래플러(남)'] = '롤링 스파이크를 메치기 시 지진이 발생하는 최대 2회 충전 스킬로 변경<br>- 지진 공격력 : 메치기 공격력의 50%<br>- 충전 쿨타임 : 12초<br>- 후딜레이를 캔슬하여 전직 스킬 시전 가능<br><br>아래의 스킬 잡기 실패 시 해당 스킬 쿨타임 5초로 감소 (쿨타임 10초)<br>- 브레이크 다운<br>- 스냅 샷<br>- 블로우 스트라이크(지상 사용)<br>- 롤링 스파이크<br>- 나의 공격리듬은!(지상 사용)<br>- 한놈만 팬다!!<br>- 흑진광풍@@5928@@2016';

lv105ItemId['성장하는 모험가의 보우건'] = 'cac209703fd7501382002dd058608ebc';
lv105OptionMap['성장하는 모험가의 보우건'] = {};
lv105OptionMap['성장하는 모험가의 보우건'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['천재 기술자의 멀티툴 벨트'] = '12fde00e523a6e1e3a389a27d08b1655';
lv105OptionMap['천재 기술자의 멀티툴 벨트'] = {};
lv105OptionMap['천재 기술자의 멀티툴 벨트'] = '공격력 증가 +666.9%<br>최종 데미지 5% 증가<br><br>스킬 MP 소모량 +100%<br><br>공격 시 MP 3,500 회복 (쿨타임 1초)<br><br>MP MAX +945@@4917@@1963';

lv105ItemId['결전의 스태프 - 스위프트 마스터'] = '2a2fd3b6363682c92598911b2953abc4';
lv105OptionMap['결전의 스태프 - 스위프트 마스터'] = {};
lv105OptionMap['결전의 스태프 - 스위프트 마스터'] = '소닉 무브를 최대 3회 충전 스킬로 변경<br><br>소닉 무브 시전 시 회오리 바람 생성<br>- 소닉 무브 회오리 바람 공격력 : 회오리 바람 공격력의 50%<br><br>회오리 바람 크기 10% 증가<br><br>전직 스킬을 캔슬하여 소닉 무브, 대진공, 칼날 바람 시전 시 풍흔 1스택 획득 (최대 15스택)<br>- 풍흔이 15스택일 때 스톰 스트라이크 시전 시 모든 풍흔을 소모하여 공격력 50% 증가@@7112@@2016';

lv105ItemId['브레이브 나이트 아머'] = '191ffb70b6cbc90b3ddf25c77aae0125';
lv105OptionMap['브레이브 나이트 아머'] = {};
lv105OptionMap['브레이브 나이트 아머'] = '최종 데미지 4% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br><br>공격 시 HP 2,200 회복 (쿨타임 1초)<br><br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +10%<br>스킬 MP 소모량 -7%@@5187@@1923';

lv105ItemId['잠식된 고원의 정수'] = '2d13a998687ba418f6e707564e592295';
lv105OptionMap['잠식된 고원의 정수'] = {};
lv105OptionMap['잠식된 고원의 정수'] = '최종 데미지 8% 증가<br>모든 속성 강화 +18<br><br>공격 속도 +5%<br>캐스팅 속도 +5%<br>이동 속도 +5%<br>MP MAX +945<br>모든 속성 저항 +10<br>회피율 +8%@@5066@@1928';

lv105ItemId['얼어붙은 저항의 창'] = '4d417208dfe59d33c94229fc833aea64';
lv105OptionMap['얼어붙은 저항의 창'] = {};
lv105OptionMap['얼어붙은 저항의 창'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['성장하는 모험가의 장궁'] = '87e98be0ba8ca3fc3acc4f3005e121e9';
lv105OptionMap['성장하는 모험가의 장궁'] = {};
lv105OptionMap['성장하는 모험가의 장궁'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['결전의 로드 - 디멘션 워커'] = 'c400a12f63e9fe5284dba2c1f02bcd96';
lv105OptionMap['결전의 로드 - 디멘션 워커'] = {};
lv105OptionMap['결전의 로드 - 디멘션 워커'] = '차원 : 시공폭뢰 스킬 강화<br>- 공격력 15% 증가<br>- 폭발 속도 50% 증가<br>- 스킬 범위 25% 증가<br>- 사상경계의 저편 효과 적용 시 전방에 다수의 차원 폭발 생성<br><br>니알리가 스킬 시전 시 분신이 대신 시전 (각성기 제외)<br><br>아래 스킬 쿨타임 10% 감소<br>- 괴리 : 할리퀸<br>- 괴리 : 패러사이트 스웜<br>- 괴리 : 칙 잇<br>- 괴리 : 저편의 자장가@@4744@@2016';

lv105ItemId['얼티밋 제너레이터'] = '11b464f53794d4e94d1b674cc22cd28e';
lv105OptionMap['얼티밋 제너레이터'] = {};
lv105OptionMap['얼티밋 제너레이터'] = '최종 데미지 8% 증가<br><br>[거대화]<br>[장비 옵션 조작키] 입력 시 25초 동안 거대화 (쿨타임 60초)<br>- 공격 불가 상태가 되며 대시 / 점프 시 충격파 발생<br>- 물리/마법 피해 감소 +20%<br>- [장비 옵션 조작키] 재입력 시 거대화 해제<br>- 대시 및 점프 충격파는 각각 총 공격력 증가의 288%, 1,440%만큼 피해<br><br>공격 시 HP 2,200 / MP 3,500회복 (쿨타임 1초)<br><br>물리/마법 방어력 +10,000@@4768@@1910';

lv105ItemId['성장하는 모험가의 염주'] = 'd6abc476a79da1823a1d1c38af9aa5bd';
lv105OptionMap['성장하는 모험가의 염주'] = {};
lv105OptionMap['성장하는 모험가의 염주'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['반짝이는 음율'] = 'b6df9f390bc57f5bcb2fd49a6065c536';
lv105OptionMap['반짝이는 음율'] = {};
lv105OptionMap['반짝이는 음율'] = '최종 데미지 6% 증가<br>모든 속성 강화 +20<br><br>장비로 인한 MP 회복 효과 30% 증가<br><br>적중률 +10%@@6048@@1943';

lv105ItemId['성장하는 모험가의 소태도'] = 'f64d23e36bfa2e296eb042fe0c24419a';
lv105OptionMap['성장하는 모험가의 소태도'] = {};
lv105OptionMap['성장하는 모험가의 소태도'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['어비스 리액터'] = '141c2b6d589c4de31bcffcdc34c0eab1';
lv105OptionMap['어비스 리액터'] = {};
lv105OptionMap['어비스 리액터'] = '최종 데미지 9% 증가<br>火(화), 水(수), 明(명), 暗(암) 공격 속성<br><br>물리/마법 크리티컬 히트 +10%<br>적중률 +10%<br>MP 1분당 348 회복@@6952@@1948';

lv105ItemId['뚜렷해지는 소멸된 사념'] = '509508d2bdb68a333a7e095ab6f86011';
lv105OptionMap['뚜렷해지는 소멸된 사념'] = {};
lv105OptionMap['뚜렷해지는 소멸된 사념'] = '최종 데미지 5% 증가<br><br>1초마다 HP/MP 2% 회복<br><br>HP가 20% 이하일 때 피격 시 모든 MP를 소모하여 HP로 회복 (쿨타임 60초)<br><br>물리/마법 방어력 +7,000<br>MP MAX +945@@6414@@1958';

lv105ItemId['결전의 단검 - 섀도우댄서'] = '9868d3015d89cd7ce587efdb5a1da58e';
lv105OptionMap['결전의 단검 - 섀도우댄서'] = {};
lv105OptionMap['결전의 단검 - 섀도우댄서'] = '백어택 공격 시 그림자의 흔적 획득<br>- 2스택 획득 (최대 50스택)<br>- 정면 공격 시 3스택 감소<br><br>페이탈 블리츠 배후 적출 시 그림자의 흔적을 모두 소모하여 공격력 강화<br>- 흔적 1개당 공격력 1% 증가 (최대 50%) <br><br>페이탈 블리츠 시전 시 15초 동안 은신하여 스킬 쿨타임 회복 속도 +12% (각성기 제외)@@4744@@2016';

lv105ItemId['결전의 대검 - 소드마스터'] = '39db2360df46dc646076d040b8d58525';
lv105OptionMap['결전의 대검 - 소드마스터'] = {};
lv105OptionMap['결전의 대검 - 소드마스터'] = '궁극의 마검 레바테인으로 악즉참 시전<br>- 악즉참 항상 최대 충전<br>- 악즉참 공격력 15% 감소<br><br>레바테인에 의한 악즉참은 적에게 4초 동안 상흔을 부여<br>- 상흔이 생성된 적에게 반월 또는 섬광 적중 시 악즉참 남은 쿨타임 10% 감소@@8892@@2016';

lv105ItemId['근원을 삼킨 핸드캐넌'] = '0615c137045d4eafc47fcb65decfa2ea';
lv105OptionMap['근원을 삼킨 핸드캐넌'] = {};
lv105OptionMap['근원을 삼킨 핸드캐넌'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['폭주하는 육신'] = '35f097a5dc75fe7700fcf9533fc1c77a';
lv105OptionMap['폭주하는 육신'] = {};
lv105OptionMap['폭주하는 육신'] = '최종 데미지 17% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>물리/마법 방어력 +23,000@@6414@@1968';

lv105ItemId['결전의 클로 - 넨마스터(남)'] = 'a9577313f6f5e61ffabb75b0220832b1';
lv105OptionMap['결전의 클로 - 넨마스터(남)'] = {};
lv105OptionMap['결전의 클로 - 넨마스터(남)'] = '기호지세의 넨수가 포효 후 폭발하도록 변경<br>- 포효 공격 다단히트 수 : 4<br>- 포효 공격력 : 기호지세 다단히트 공격력의 100%<br>- 폭발 공격력 : 기호지세 폭발 공격력의 100%<br>- 포효에 적중된 적을 끌어당김<br>- 스킬 범위 20% 증가<br><br>광호천지파의 나선의 넨이 스스로 회전 후 폭발<br>- 시전 속도 20% 증가<br>- 구체 및 폭발 범위 10% 증가@@10076@@2016';

lv105ItemId['얼어붙은 저항의 건틀릿'] = 'ffdbb906addec7e0051fae3867be2201';
lv105OptionMap['얼어붙은 저항의 건틀릿'] = {};
lv105OptionMap['얼어붙은 저항의 건틀릿'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['피어오르는 광기'] = '50e34031bfb458d7a43ed56d2a36db1d';
lv105OptionMap['피어오르는 광기'] = {};
lv105OptionMap['피어오르는 광기'] = '스킬 쿨타임 회복 속도 +50% (각성기 제외)<br><br>물리/마법 크리티컬 히트 +7%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 상태 이상 내성 -10%@@3894@@1887';

lv105ItemId['결전의 너클 - 넨마스터(여)'] = '115606560c7ff48b2704f09809306e91';
lv105OptionMap['결전의 너클 - 넨마스터(여)'] = {};
lv105OptionMap['결전의 너클 - 넨마스터(여)'] = '축염포 충전 시간 및 쿨타임 삭제<br><br>넨탄의 쿨타임이 증가하는 대신 공격력과 크기가 증가<br>- 공격력 120% 증가<br>- 크기 20% 증가<br>- 쿨타임 4초 증가<br>- 넨마스터 전직 쿨타임 감소 미적용<br><br>넨탄 시전 시 아래의 효과 적용<br>- 흡기공탄의 남은 쿨타임 5% 감소<br>- 10% 확률로 넨탄 공격력 500% 증가@@8300@@2016';

lv105ItemId['어둠에 삼켜진 돌굽'] = '18387f5bef5b22094ebd9f392a507af0';
lv105OptionMap['어둠에 삼켜진 돌굽'] = {};
lv105OptionMap['어둠에 삼켜진 돌굽'] = '최종 데미지 5% 증가<br>스킬 쿨타임 회복 속도 +15% (각성기 제외)<br>중독 피해 +10%<br><br>물리/마법 크리티컬 히트 +12%<br>공격 속도 +15%<br>캐스팅 속도 +15%<br>이동 속도 +15%<br>모든 상태 이상 내성 -10%@@5510@@1953';

lv105ItemId['얼어붙은 저항의 크로스슈터'] = '24f76915873f37f849f8e28069da7677';
lv105OptionMap['얼어붙은 저항의 크로스슈터'] = {};
lv105OptionMap['얼어붙은 저항의 크로스슈터'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@592@@1836';

lv105ItemId['결전의 봉 - 스위프트 마스터'] = '89a2eb7d8fdaa202745b99df2f939a48';
lv105OptionMap['결전의 봉 - 스위프트 마스터'] = {};
lv105OptionMap['결전의 봉 - 스위프트 마스터'] = '소닉 무브를 최대 3회 충전 스킬로 변경<br><br>소닉 무브 시전 시 회오리 바람 생성<br>- 소닉 무브 회오리 바람 공격력 : 회오리 바람 공격력의 50%<br><br>회오리 바람 크기 10% 증가<br><br>전직 스킬을 캔슬하여 소닉 무브, 대진공, 칼날 바람 시전 시 풍흔 1스택 획득 (최대 15스택)<br>- 풍흔이 15스택일 때 스톰 스트라이크 시전 시 모든 풍흔을 소모하여 공격력 50% 증가@@7112@@2016';

lv105ItemId['골렘의 핵 팔찌'] = '2ddf1185d8199e0bf081e9d7421aea50';
lv105OptionMap['골렘의 핵 팔찌'] = {};
lv105OptionMap['골렘의 핵 팔찌'] = '최종 데미지 7.1% 증가<br>중독 피해 전환 +50%<br>중독 피해 +20%<br><br>물리/마법 피해의 50%를 중독 피해로 전환<br><br>중독 내성 +1%@@3705@@1953';

lv105ItemId['하이테크 고기동 강화 부츠'] = '08d689cebec0eee38147356abced9204';
lv105OptionMap['하이테크 고기동 강화 부츠'] = {};
lv105OptionMap['하이테크 고기동 강화 부츠'] = '최종 데미지 11% 증가<br>모든 속성 강화 +20<br><br>무색 큐브 조각을 소모하는 스킬의 커맨드 사용 효과 +400% (각성기 제외)<br><br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +10%<br>물리/마법 피해 감소 +12%<br>스킬 MP 모소량 -7%@@5780@@1935';

lv105ItemId['검은 별'] = 'bbd070d70a5168fabecaef168427487d';
lv105OptionMap['검은 별'] = {};
lv105OptionMap['검은 별'] = '모든 속성 강화 +20<br><br>HP가 50% 이하일 때 최종 데미지 11% 증가<br><br>공격 속도 +20%<br>캐스팅 속도 +20%<br>이동 속도 +20%<br>적중률 +10%@@4972@@1953';

lv105ItemId['근원을 삼킨 너클'] = '324df7e3ad8c062fd488c3da3f71fff3';
lv105OptionMap['근원을 삼킨 너클'] = {};
lv105OptionMap['근원을 삼킨 너클'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['근원을 삼킨 코어 블레이드'] = '4908e24a2decd256dc00d945b2d5aa15';
lv105OptionMap['근원을 삼킨 코어 블레이드'] = {};
lv105OptionMap['근원을 삼킨 코어 블레이드'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['성장하는 모험가의 배틀액스'] = 'd06c3519a697aef91ff36b9880da4828';
lv105OptionMap['성장하는 모험가의 배틀액스'] = {};
lv105OptionMap['성장하는 모험가의 배틀액스'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['결전의 도 - 검귀'] = '5262287485a370d1d41ae4760c36cc92';
lv105OptionMap['결전의 도 - 검귀'] = {};
lv105OptionMap['결전의 도 - 검귀'] = '검술 , 합격 계열 스킬 공격 시 귀신보 쿨타임 초기화<br>- 검술 계열 스킬을 귀신보로 발동하는 경우 제외<br><br>귀신보 공격력 20% 증가<br><br>귀신보 Y축 공격 범위 10% 증가@@9484@@2016';

lv105ItemId['냉혹한 현실의 목걸이'] = '689669772092589ba47a7d6adbb10023';
lv105OptionMap['냉혹한 현실의 목걸이'] = {};
lv105OptionMap['냉혹한 현실의 목걸이'] = '최종 데미지 10% 증가<br><br>공격 시 화속성 폭발 발생 (쿨타임 5초)<br>- 총 공격력 증가 수치의 480%만큼 피해<br>- 자신에게는 1의 피해<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@6414@@1958';

lv105ItemId['순수한 자연의 이치'] = '02465c3bbbf1757e04d79122a4703f2b';
lv105OptionMap['순수한 자연의 이치'] = {};
lv105OptionMap['순수한 자연의 이치'] = '명속성 강화 +20<br><br>감전 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>[썬더 포스]<br>공격 시 명속성 강화 수치에 따라 명속성 원소 공격 시전 (쿨타임 1.5초)<br>- 100 이상 150 미만 : 라이트 익스플로전<br>- 150 이상 250 미만 : 라이트닝<br>- 250 이상 : 썬더 스톰<br>- 각각 총 공격력 증가의 720% / 920% / 1,480%만큼 피해<br>- 썬더 스톰은 적에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)<br><br>공격 속도 +10%<br>캐스팅 속도 +15%<br>명속성 저항 +20@@6414@@1978';

lv105ItemId['버츄얼 사이트 글래스'] = '0d416780271ff2281e5e8d023c8deafe';
lv105OptionMap['버츄얼 사이트 글래스'] = {};
lv105OptionMap['버츄얼 사이트 글래스'] = '최종 데미지 9.2% 증가<br><br>[화염 걸음]<br>발걸음에 불꽃의 기운을 담아 이동 시 3초 동안 화염 지대 생성<br>- 화염 지대는 적에게 15초 동안 화상 상태 이상 부여 (쿨타임 10초)<br>- 총 공격력 증가의 4%만큼 피해<br><br>화상 상태 적 공격 시 화염 폭발 발생 (쿨타임 0.5초)<br>- 총 공격력 증가의 28%만큼 피해<br><br>HP MAX +600<br>화상 내성 +1%@@5673@@1958';

lv105ItemId['포기하지 않는 용기'] = '7f3e3187979bdc24c6cbb65c5ec33c78';
lv105OptionMap['포기하지 않는 용기'] = {};
lv105OptionMap['포기하지 않는 용기'] = '최종 데미지 13% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>이동 속도 +5%<br>물리/마법 피해 감소 +10%<br>적중률 +10%@@5874@@1948';

lv105ItemId['광폭화된 전의의 권투글러브'] = '0639461c09c1925e37ce4a53a618a153';
lv105OptionMap['광폭화된 전의의 권투글러브'] = {};
lv105OptionMap['광폭화된 전의의 권투글러브'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['얼어붙은 저항의 코어 블레이드'] = 'bc467f82e958e85dc0c986e9c74a3763';
lv105OptionMap['얼어붙은 저항의 코어 블레이드'] = {};
lv105OptionMap['얼어붙은 저항의 코어 블레이드'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['굴착 강화 파츠'] = '5def29f2984302609613b48985a677ca';
lv105OptionMap['굴착 강화 파츠'] = {};
lv105OptionMap['굴착 강화 파츠'] = '스킬 쿨타임 10% 감소 (각성기 제외)<br>모든 속성 강화 +10<br><br>[소울 웨펀]<br>평타, 점프 공격, 대시 공격 시 검격을 가하는 소울 웨펀 소환<br>- 스킬 시전 시 검격 가능 횟수 +5<br>- 검격 가능 횟수가 50일 때 모든 횟수를 소모하여 강력한 검격<br>- 검격과 강력한 검격은 각각 총 공격력 증가 수치의 60%, 2,400%만큼 피해<br><br>공격 시 적에게 10초 동안 감전 상태 이상 부여 (쿨타임 3초)<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +25%<br>캐스팅 속도 +40%@@7100@@1953';

lv105ItemId['근원을 삼킨 로드'] = '029448375a5e13be023b99a532f2494a';
lv105OptionMap['근원을 삼킨 로드'] = {};
lv105OptionMap['근원을 삼킨 로드'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['결전의 보우건 - 스핏파이어(여)'] = '1ab52689243e42f22c4525db17988adb';
lv105OptionMap['결전의 보우건 - 스핏파이어(여)'] = {};
lv105OptionMap['결전의 보우건 - 스핏파이어(여)'] = 'G-14 파열류탄, G-35L 섬광류탄, G-18C 빙결류탄 스킬 강화<br>- 최대 장전 수 +2<br>- 점프 상태에서 시전 시 니트로 모터를 소모하지 않음<br><br><br>보유한 유탄을 소모하여 포토빌라이저 강화<br>- 소모한 유탄 1개당 공격력 2% 증가 (최대 30%)<br>- 스탠바이-레디 상태에서는 최대 강화 수치로 발사@@8300@@2016';

lv105ItemId['얼어붙은 저항의 쌍검'] = '4c3a67bf4ec92686fcdf1480943d01c7';
lv105OptionMap['얼어붙은 저항의 쌍검'] = {};
lv105OptionMap['얼어붙은 저항의 쌍검'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 광검 - 베가본드'] = 'ca69d4bfe6e7da0127dad64083b02263';
lv105OptionMap['결전의 광검 - 베가본드'] = {};
lv105OptionMap['결전의 광검 - 베가본드'] = '십자검 시전 시 전방의 가장 강력한 적을 추적한 후 수평, 수직 베기 동시 시전<br><br>전직 스킬의 후딜레이를 캔슬하여 십자검, 엽해소옥 시전 가능<br><br>아래의 스킬 쿨타임 20% 감소<br>- 일화도강<br>- 십자검<br>- 연화섬<br>- 엽해소옥@@8892@@2016';

lv105ItemId['사멸하는 신뢰의 자동권총'] = '0b22fd4183997ad8a45d468ffe9260a4';
lv105OptionMap['사멸하는 신뢰의 자동권총'] = {};
lv105OptionMap['사멸하는 신뢰의 자동권총'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['그릇된 힘에 잠식된 팔찌'] = '22a3394bf0b96eb24b0df260e2504216';
lv105OptionMap['그릇된 힘에 잠식된 팔찌'] = {};
lv105OptionMap['그릇된 힘에 잠식된 팔찌'] = '최종 데미지 16% 증가<br><br>보스/네임드 몬스터 공격 시 무력화 게이지량 -1% (버퍼 제외)<br>- 적의 무력화 게이지량이 90% 이상일 때만 발동<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>물리/마법 크리티컬 히트 +5%@@5604@@1910';

lv105ItemId['내딛은 자의 투창'] = 'a6ce3570d897bc4ba6b2ff7d9cbb05cc';
lv105OptionMap['내딛은 자의 투창'] = {};
lv105OptionMap['내딛은 자의 투창'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['드래곤 슬레이어'] = '02ec8b60e2cfa5d4c84d831329b70c4f';
lv105OptionMap['드래곤 슬레이어'] = {};
lv105OptionMap['드래곤 슬레이어'] = '최종 데미지 9% 증가<br>火(화), 水(수), 明(명), 暗(암) 공격 속성<br><br>모든 속성 저항 +25<br>적중률 +10%<br>MP 1분당 348 회복@@5874@@1948';

lv105ItemId['결전의 쌍검 - 쿠노이치'] = '239efe44b7f1ff35e697807dc1752b5f';
lv105OptionMap['결전의 쌍검 - 쿠노이치'] = {};
lv105OptionMap['결전의 쌍검 - 쿠노이치'] = '인법 : 잔영 남기기의 잔영 소모 1개당 다음에 재생할 육도윤회의 잔영 공격력 비율 1.5% 추가 증가 (최대 12중첩)<br><br>인법 : 육도윤회 시전 시 5초 동안 슈퍼아머 상태가 되며 스킬 쿨타임 회복 속도 +50% (쿨타임 20초 / 각성기 제외)<br><br>인법 : 육도윤회 스킬 종료 시 잔영 5 회복 (쿨타임 10초)@@8300@@2016';

lv105ItemId['결전의 건틀릿 - 넨마스터(여)'] = '2ee9940f4371cd412d6c9afbcaa2b652';
lv105OptionMap['결전의 건틀릿 - 넨마스터(여)'] = {};
lv105OptionMap['결전의 건틀릿 - 넨마스터(여)'] = '축염포 충전 시간 및 쿨타임 삭제<br><br>넨탄의 쿨타임이 증가하는 대신 공격력과 크기가 증가<br>- 공격력 120% 증가<br>- 크기 20% 증가<br>- 쿨타임 4초 증가<br>- 넨마스터 전직 쿨타임 감소 미적용<br><br>넨탄 시전 시 아래의 효과 적용<br>- 흡기공탄의 남은 쿨타임 5% 감소<br>- 10% 확률로 넨탄 공격력 500% 증가@@8300@@2016';

lv105ItemId['서드 스페이드 - 데스'] = 'ce8195cda83c59c9cdeef2a427d8cf80';
lv105OptionMap['서드 스페이드 - 데스'] = {};
lv105OptionMap['서드 스페이드 - 데스'] = '최종 데미지 2% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@4528@@1612';

lv105ItemId['성장하는 모험가의 머스켓'] = '3fb2bf4a6434d0c4c4d4f7993a2433de';
lv105OptionMap['성장하는 모험가의 머스켓'] = {};
lv105OptionMap['성장하는 모험가의 머스켓'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['아크로매틱 룸버스'] = '6a46f336b09d7feee2d8fe38e0eff1fb';
lv105OptionMap['아크로매틱 룸버스'] = {};
lv105OptionMap['아크로매틱 룸버스'] = '최종 데미지 10% 증가<br>무색 큐브 조각을 소모하지 않는 스킬 쿨타임 30% 감소<br>무색 큐브 조각을 소모하는 스킬 쿨타임 15% 증가 (각성기 제외)<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%@@4528@@1920';

lv105ItemId['일렉트릭 프루프 부츠'] = '0894bcf22c00b54e90b9f9f0676ee7d5';
lv105OptionMap['일렉트릭 프루프 부츠'] = {};
lv105OptionMap['일렉트릭 프루프 부츠'] = '최종 데미지 17% 증가<br><br>감전 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 번개가 떨어지며 적에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)<br>- 총 공격력 증가의 84%만큼 피해<br><br>자신에게 해제되지 않는 감전 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +7%<br>공격 속도 +20%<br>캐스팅 속도 +30%<br>이동 속도 +20%<br>모든 상태 이상 내성 -10%@@6858@@1963';

lv105ItemId['내딛은 자의 빗자루'] = 'dfab100a5c6b2f4acdb734ead0215aa9';
lv105OptionMap['내딛은 자의 빗자루'] = {};
lv105OptionMap['내딛은 자의 빗자루'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 클로 - 그래플러(여)'] = '9e8e8b65ab8e1e08b5032b48fb76d703';
lv105OptionMap['결전의 클로 - 그래플러(여)'] = {};
lv105OptionMap['결전의 클로 - 그래플러(여)'] = '전직 스킬로 공격 시 테크니컬 포인트 획득 (최대 20점)<br>- 1~30Lv : 1점<br>- 35~45Lv : 2점<br>- 60~80Lv : 2점<br>- 50, 85, 95Lv : 5점<br>- 100Lv : 10점<br>- 체인 커넥션 연계/공중 스킬 사용 시 1점 추가<br><br>테크니컬 포인트에 따라 효과 발동<br>- 11점 이상 : 50, 85, 100Lv 스킬 적중 시 모든 스킬 남은 쿨타임 30% 감소 (각성기 제외 / 모든 점수 소모)<br>- 6점 이상 : 잡기 스킬 공격 실패 시 해당 스킬 쿨타임을 3초로 감소 (3점 소모)<br>- 1점 이상 : 체인 커넥션 스킬 연계 시 체인 커넥션 회복 시간 3초 감소@@5336@@2016';

lv105ItemId['시공을 담은 팔찌'] = '665737a792279162791824571c97e921';
lv105OptionMap['시공을 담은 팔찌'] = {};
lv105OptionMap['시공을 담은 팔찌'] = '최종 데미지 18% 증가<br><br>HP MAX 15% 수치의 [충전형 보호막] 부여<br><br>이동 속도 +8%<br>회피율 +8%@@5604@@1928';

lv105ItemId['결전의 봉 - 블러드 메이지'] = 'd271891aca11ea3ab70aac9016fbd4f4';
lv105OptionMap['결전의 봉 - 블러드 메이지'] = {};
lv105OptionMap['결전의 봉 - 블러드 메이지'] = '커럽션 공격 시 블러드비스 2개 획득 (최대 5개)<br>- 쉬카리, 토먼트 시전 시 블러드비스 1개를 소모하여 각각 페이탈 쉬카리, 블러드먼트로 강화<br>- 던전 입장 시 블러드비스 1개 획득<br><br>페이탈 쉬카리<br>- 쉬카리 공격력 50% 증가<br>- 1히트 스킬로 변경<br>- 500px 범위 내 모든 적 공격<br><br>블러드먼트<br>- 토먼트 공격력 50% 증가<br>- 시전 후 이동 가능<br><br>커럽션 쿨타임 10% 감소, 시전 속도 30% 증가@@8892@@2016';

lv105ItemId['결전의 소태도 - 트러블 슈터'] = '5276d097bd8252dbdd443f0e046fc22b';
lv105OptionMap['결전의 소태도 - 트러블 슈터'] = {};
lv105OptionMap['결전의 소태도 - 트러블 슈터'] = '번 투 슬래쉬 시전 시 3초 동안 전직 스킬 공격력 20% 증가 (각성기 제외)<br>- 후딜레이를 캔슬하여 다른 전직 스킬 사용 가능<br>- 다른 전직 스킬의 후딜레이를 캔슬하여 번 투 슬래쉬 사용 가능<br>- 추가 공격 입력 시간 3초로 증가<br>- 스킬 종료 후 쿨타임 적용<br><br>번 투 슬래쉬가 타격하지 않아도 폭발 발생<br>- 스킬 쿨타임 35% 감소<br>- 스킬 범위 10% 증가@@6520@@2016';

lv105ItemId['결전의 둔기 - 팔라딘'] = 'a2707825051426a7b2adc1f2f4b89bfa';
lv105OptionMap['결전의 둔기 - 팔라딘'] = {};
lv105OptionMap['결전의 둔기 - 팔라딘'] = '세라픽 페더를 소모하여 이스트레인지먼트 시전 시 추가 효과 발동<br>- 세라픽 페더 1장당 공격력 4% 증가<br>- 세라픽 페더 1장당 스킬 범위 3% 증가<br>- 적에게 기절 상태 이상 부여<br><br>세라픽 페더를 소모하여 위핑 웨이브 시전 시 추가 효과 발동<br>- 끌어들인 적을 2초 동안 홀딩<br>- 몰이 효과 범위 6단계 적용<br>- 몰이 효과 범위 20% 증가<br>- 세라픽 페더 최대 2개만 소모<br><br>위핑 웨이브 강화<br>- 스킬 범위 20% 증가<br>- 타격 성공 시 세라픽 페더 습득량 +2<br>- 도발시킨 적 앞에서 방어 상태 유지 시 세라픽 페더 획득 시간 50% 감소@@9484@@2016';

lv105ItemId['사멸하는 신뢰의 미늘창'] = '9bbe22c6f825015c5ef431d644562475';
lv105OptionMap['사멸하는 신뢰의 미늘창'] = {};
lv105OptionMap['사멸하는 신뢰의 미늘창'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 소검 - 블레이드'] = 'eb617a561085dcf5c393448c882711b3';
lv105OptionMap['결전의 소검 - 블레이드'] = {};
lv105OptionMap['결전의 소검 - 블레이드'] = '납도술 연계 시 납도술 스킬과 연계한 스킬의 남은 쿨타임 15% 감소<br>- 각성기로 납도술 연계 시 미적용<br><br>러스티 네일 베기 공격력이 삭제되고, 후딜레이를 캔슬하여 전직 스킬 시전 가능<br>- 베기 공격력을 발차기 공격력에 합산<br><br>전직 스킬 후딜레이를 캔슬하여 러스티 네일 시전 가능<br>- 셰이커 블래스트, 각성기 제외@@8892@@2016';

lv105ItemId['얼어붙은 저항의 핸드캐넌'] = 'a50e2c96065b63d3677a379e70c7bd3a';
lv105OptionMap['얼어붙은 저항의 핸드캐넌'] = {};
lv105OptionMap['얼어붙은 저항의 핸드캐넌'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['안개를 탐구하는 자의 벨트'] = '0cce78269c2c6f6eb97533321d9fba72';
lv105OptionMap['안개를 탐구하는 자의 벨트'] = {};
lv105OptionMap['안개를 탐구하는 자의 벨트'] = '최종 데미지 13% 증가<br><br>이동 속도 +8%<br>회피율 +8%@@6144@@1928';

lv105ItemId['결전의 십자가 - 인파이터'] = 'b7e64d8ceb31f4243679d358c5786ba6';
lv105OptionMap['결전의 십자가 - 인파이터'] = {};
lv105OptionMap['결전의 십자가 - 인파이터'] = '정의 집행 : 레미디오스의 성좌의 링크 스킬이 빅뱅 펀치일 때, 신의 분노를 최대 3회 충전 스킬로 변경<br>- 연타 공격력 100% 증가<br>- 연타 횟수 9회 감소<br>- 피니쉬 타격 및 폭발 삭제<br>- 적을 추적하여 공격<br>- 충전 쿨타임 : 40초<br>- 추적 범위 : 780px<br>- MP 소모량 -30%<br>- 무색 큐브 조각 소모량 5개 감소<br>- 머신건 잽 시전 시 신의 분노 남은 쿨타임 12% 감소<br>- 신의 분노를 캔슬하여 전직 스킬 시전 가능<br>- 전직 스킬을 캔슬하여 신의 분노 시전 시 드라이아웃 스택을 소모하지 않음@@5928@@2016';

lv105ItemId['압도하는 용맹'] = '556975e8852f6f596c81b1b4d337b33d';
lv105OptionMap['압도하는 용맹'] = {};
lv105OptionMap['압도하는 용맹'] = '최종 데미지 10% 증가<br><br>[심판의 시간]<br>"커맨드 ↑ ↓ + [장비 옵션 조작키] 입력 시 보스 몬스터를 10초 동안 심판하며 데미지 축적 (쿨타임 20초)<br>- "심판" 종료 시 축적된 데미지 폭발<br>- 커맨드 재입력 시 "심판" 즉시 종료<br>- 심판의 시간 동안 모든 속도 +75%<br><br>이동 속도 +8%<br>적중률 +10%@@5066@@1938';

lv105ItemId['이동하는 요새'] = '7b119545dc3d59270672162bea7fec98';
lv105OptionMap['이동하는 요새'] = {};
lv105OptionMap['이동하는 요새'] = '최종 데미지 5% 증가<br>스킬 쿨타임 회복 속도 +10% (각성기 제외)<br><br>자신에게 해제되지 않는 출혈 상태 이상 부여<br><br>물리/마법 피해 감소 +10%<br>물리/마법 방어력 +7,000<br>HP 1분당 460.2 회복@@5604@@1948';

lv105ItemId['거짓 속의 거짓'] = '8a568268075336a993c97e2d780144dc';
lv105OptionMap['거짓 속의 거짓'] = {};
lv105OptionMap['거짓 속의 거짓'] = '최종 데미지 8% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@3180@@1612';

lv105ItemId['얼어붙은 저항의 도'] = '1749e1e16483211fedba20d38463c8a6';
lv105OptionMap['얼어붙은 저항의 도'] = {};
lv105OptionMap['얼어붙은 저항의 도'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['죄어오는 풍화된 악의'] = '2df3b394f3d4b03153524b2d22502c78';
lv105OptionMap['죄어오는 풍화된 악의'] = {};
lv105OptionMap['죄어오는 풍화된 악의'] = '최종 데미지 10% 증가<br><br>공격 시 암속성 폭발 발생 (쿨타임 5초)<br>- 총 공격력 증가 수치의 480%만큼 피해<br>- 자신에게는 1의 피해<br><br>물리/마법 방어력 +7,000<br>암속성 저항 +10@@6414@@1958';

lv105ItemId['결전의 리볼버 - 런처(남)'] = '63e64ecdb9f4947ef2eb6b441b8dee89';
lv105OptionMap['결전의 리볼버 - 런처(남)'] = {};
lv105OptionMap['결전의 리볼버 - 런처(남)'] = '레이저 라이플을 최대 3회 충전 스킬로 변경<br>- 공격력 20% 증가<br>- 크기 40% 증가<br>- 충전 쿨타임 : 7초<br>- 충전 레이저 라이플 충전 시간 50% 감소<br>- 적중 시 적에게 10초 동안 전자기장 부여<br><br>FM-92 스팅어 강화<br>- 공격력 15% 증가<br>- 폭발 범위 25% 증가<br>- 전자기장이 부여된 적 존재 시 대상을 추적하는 강화 고폭탄 발사@@8892@@2016';

lv105ItemId['결전의 머스켓 - 스핏파이어(여)'] = '747e728e0d47c02d0516f4effd110815';
lv105OptionMap['결전의 머스켓 - 스핏파이어(여)'] = {};
lv105OptionMap['결전의 머스켓 - 스핏파이어(여)'] = 'G-14 파열류탄, G-35L 섬광류탄, G-18C 빙결류탄 스킬 강화<br>- 최대 장전 수 +2<br>- 점프 상태에서 시전 시 니트로 모터를 소모하지 않음<br><br><br>보유한 유탄을 소모하여 포토빌라이저 강화<br>- 소모한 유탄 1개당 공격력 2% 증가 (최대 30%)<br>- 스탠바이-레디 상태에서는 최대 강화 수치로 발사@@8300@@2016';

lv105ItemId['사멸하는 신뢰의 염주'] = '2ed512870203da041e070f8b3d2c85da';
lv105OptionMap['사멸하는 신뢰의 염주'] = {};
lv105OptionMap['사멸하는 신뢰의 염주'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['내딛은 자의 통파'] = '2241528d6e25034bccfc29969dc2f28b';
lv105OptionMap['내딛은 자의 통파'] = {};
lv105OptionMap['내딛은 자의 통파'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['광폭화된 전의의 선현궁'] = '85476270194a715e0ffd4021b7cd66fc';
lv105OptionMap['광폭화된 전의의 선현궁'] = {};
lv105OptionMap['광폭화된 전의의 선현궁'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['마음을 전달하는 소리'] = '3cdc983849f497683a8453a28ece9d8f';
lv105OptionMap['마음을 전달하는 소리'] = {};
lv105OptionMap['마음을 전달하는 소리'] = '최종 데미지 12% 증가<br><br>[얼음땡!]<br>"얼음" /  "땡" 채팅 입력을 통해 캐릭터 빙결 및 빙결 해제 가능<br>- 빙결 상태 동안 물리/마법 피해 감소 +30%<br>- 빙결 상태가 될 때 HP 20% 회복<br><br>HP MAX +600<br>물리/마법 방어력 +7,000@@7760@@1910';

lv105ItemId['결전의 대검 - 다크나이트'] = '06ffc203717f4083cdd7bd51b254010d';
lv105OptionMap['결전의 대검 - 다크나이트'] = {};
lv105OptionMap['결전의 대검 - 다크나이트'] = '타임 스톱 최대 콤보 스택 1단계 증가<br>- 콤보 스택 6단계 공격력 : 220%<br><br>시간에 간섭하여 타임로드 효과 강화<br>- 다른 콤보 슬롯 스킬 사용 시 타임 스톱 스택 획득<br>- 확장 콤보 슬롯 스킬 시전 시 기존 콤보 스킬 유지<br>- 타임 로드 효과 지속 시간 +2초@@10672@@2016';

lv105ItemId['자유를 수호하는 윙 숄더'] = '03beca8db56d3881b0724f276e76804d';
lv105OptionMap['자유를 수호하는 윙 숄더'] = {};
lv105OptionMap['자유를 수호하는 윙 숄더'] = '최종 데미지 5% 증가<br>화속성 강화 +20<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 속도 +30%<br>캐스팅 속도 +30%<br>이동 속도 +30%<br>물리/마법 피해 감소 +15%<br>화속성 저항 +10@@6414@@1958';

lv105ItemId['결전의 머스켓 - 런처(여)'] = 'bf460ad935564441b6a2099e07eab9d0';
lv105OptionMap['결전의 머스켓 - 런처(여)'] = {};
lv105OptionMap['결전의 머스켓 - 런처(여)'] = '화염 강타를 화염을 압축하여 발사하는 최대 3회 충전 스킬로 변경<br>- 공격력 : 총 공격력의 50%<br>- 충전 쿨타임 : 4초<br><br>M-3 화염방사기 시전 시 화염을 압축하여 발사<br><br>UHT-03 플레게톤 이동 속도 비율 +10%@@9484@@2016';

lv105ItemId['눈물이 스민 보석'] = '1d95be2285b7f8da2d9e9f82074d4d3d';
lv105OptionMap['눈물이 스민 보석'] = {};
lv105OptionMap['눈물이 스민 보석'] = '최종 데미지 17% 증가<br><br>자신에게 20초 동안 해제되지 않는 상태 이상 1개 부여 (쿨타임 4초)<br>- 출혈/중독/화상/감전 중 1개 부여<br><br>물리/마법 방어력 +7,000<br>MP 1분당 348 회복@@3720@@1900';

lv105ItemId['근원을 삼킨 통파'] = 'b73de65023d879bcdb81d762c269f5df';
lv105OptionMap['근원을 삼킨 통파'] = {};
lv105OptionMap['근원을 삼킨 통파'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['얼어붙은 저항의 장궁'] = '26341e036783969742f28dc6162a1126';
lv105OptionMap['얼어붙은 저항의 장궁'] = {};
lv105OptionMap['얼어붙은 저항의 장궁'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['얼어붙은 저항의 염주'] = '75b134e5a13cb1fe378aee7855440774';
lv105OptionMap['얼어붙은 저항의 염주'] = {};
lv105OptionMap['얼어붙은 저항의 염주'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['근원을 삼킨 소태도'] = '6a6037f75d70b3b4dc9d238f7ca80a90';
lv105OptionMap['근원을 삼킨 소태도'] = {};
lv105OptionMap['근원을 삼킨 소태도'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['내딛은 자의 로드'] = '11290ea142ab1fe61bcc022c9ceff487';
lv105OptionMap['내딛은 자의 로드'] = {};
lv105OptionMap['내딛은 자의 로드'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 건틀릿 - 그래플러(여)'] = '500e12a878b183db8241adf2c63285bf';
lv105OptionMap['결전의 건틀릿 - 그래플러(여)'] = {};
lv105OptionMap['결전의 건틀릿 - 그래플러(여)'] = '전직 스킬로 공격 시 테크니컬 포인트 획득 (최대 20점)<br>- 1~30Lv : 1점<br>- 35~45Lv : 2점<br>- 60~80Lv : 2점<br>- 50, 85, 95Lv : 5점<br>- 100Lv : 10점<br>- 체인 커넥션 연계/공중 스킬 사용 시 1점 추가<br><br>테크니컬 포인트에 따라 효과 발동<br>- 11점 이상 : 50, 85, 100Lv 스킬 적중 시 모든 스킬 남은 쿨타임 30% 감소 (각성기 제외 / 모든 점수 소모)<br>- 6점 이상 : 잡기 스킬 공격 실패 시 해당 스킬 쿨타임을 3초로 감소 (3점 소모)<br>- 1점 이상 : 체인 커넥션 스킬 연계 시 체인 커넥션 회복 시간 3초 감소@@5336@@2016';

lv105ItemId['사멸하는 신뢰의 장궁'] = '37ced3ff14363a62e727f40055a37859';
lv105OptionMap['사멸하는 신뢰의 장궁'] = {};
lv105OptionMap['사멸하는 신뢰의 장궁'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['수호룡의 비호 - 자비'] = '921b77c0ea5e92e34070c2a7ce3c17a2';
lv105OptionMap['수호룡의 비호 - 자비'] = {};
lv105OptionMap['수호룡의 비호 - 자비'] = '모든 속성 강화 +30<br>35Lv 액티브 스킬 Lv +10<br>- 제외 : 갈증, 컨제스트, 도발, 섀도우 박서, 잔영 남기기<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@5604@@1938';

lv105ItemId['광폭화된 전의의 코어 블레이드'] = '1d567e425f1ebcf772abac8a003fcf99';
lv105OptionMap['광폭화된 전의의 코어 블레이드'] = {};
lv105OptionMap['광폭화된 전의의 코어 블레이드'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 쌍검 - 섀도우댄서'] = '2c21f9b4654f49a9b573448549f64bea';
lv105OptionMap['결전의 쌍검 - 섀도우댄서'] = {};
lv105OptionMap['결전의 쌍검 - 섀도우댄서'] = '백어택 공격 시 그림자의 흔적 획득<br>- 2스택 획득 (최대 50스택)<br>- 정면 공격 시 3스택 감소<br><br>페이탈 블리츠 배후 적출 시 그림자의 흔적을 모두 소모하여 공격력 강화<br>- 흔적 1개당 공격력 1% 증가 (최대 50%) <br><br>페이탈 블리츠 시전 시 15초 동안 은신하여 스킬 쿨타임 회복 속도 +12% (각성기 제외)@@4744@@2016';

lv105ItemId['근원을 삼킨 에테리얼 보우'] = '76fe7d81412e8ab1dcc965d2b54bfbb5';
lv105OptionMap['근원을 삼킨 에테리얼 보우'] = {};
lv105OptionMap['근원을 삼킨 에테리얼 보우'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>회피율 +8%@@533@@1996';

lv105ItemId['리버시블 레더 코트'] = '0df9f4333903017711ff4972c52eba4f';
lv105OptionMap['리버시블 레더 코트'] = {};
lv105OptionMap['리버시블 레더 코트'] = '최종 데미지 9% 증가<br><br>[영웅의 기백]<br>사망 시 MP를 모두 소진할 때까지 죽음을 억제 (쿨타임 300초)<br>- 피격 시 HP 대신 MP 소모<br>- MP 1초당 2% 감소<br>- 모든 HP 회복 효과 미적용<br><br>공격 시 MP 3,500 회복 (쿨타임 1초)<br><br>공격 속도 +5%<br>캐스팅 속도 +7.5%<br>MP MAX +945@@6803@@1933';

lv105ItemId['광폭화된 전의의 중검'] = 'a2228b8113971f828040bfd891fe9e14';
lv105OptionMap['광폭화된 전의의 중검'] = {};
lv105OptionMap['광폭화된 전의의 중검'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['광폭화된 전의의 스태프'] = 'a7d20c36dba10300dd94b232a622cd9e';
lv105OptionMap['광폭화된 전의의 스태프'] = {};
lv105OptionMap['광폭화된 전의의 스태프'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 소검 - 데몬슬레이어'] = '7f3c930eb14e9da1661d4bdf262e208c';
lv105OptionMap['결전의 소검 - 데몬슬레이어'] = {};
lv105OptionMap['결전의 소검 - 데몬슬레이어'] = '사복검 - 발을 최대 3회 충전 스킬로 변경<br>- 솟아오르는 검 개수 +3<br>- 전직 스킬 공격 시 1회 충전 (사복검 - 발 제외)<br>- 자동 충전 불가능<br><br>전직 스킬의 후딜레이를 캔슬하여 사복검 - 발 시전 가능 (각성기 제외)<br><br>사복검 - 발 후딜레이를 캔슬하여 전직 스킬 시전 가능@@5928@@2016';

lv105ItemId['결전의 창 - 소환사'] = 'ed13ebee16b76c049c95ba38c6d26806';
lv105OptionMap['결전의 창 - 소환사'] = {};
lv105OptionMap['결전의 창 - 소환사'] = '상급 정령들과 정령왕 에체베리아의 이클립스 하이브 추가 조작 패턴 공격력 20% 증가<br><br>이클립스 하이브 강화<br>- 추가 조작 패턴 캐스팅 모션 삭제 <br>- 데드 멀커 : 블랙스웜 홀딩 추가<br>- 글레어린 : 썬더콜링이 적을 끌어모음<br>- 아퀘리스 : 헵타 아이스 미사일이 지상 폭격<br>- 플레임 헐크 : 화염폭검술이 회오리를 생성하고 적을 끌어모음<br>- 에체베리아 : 초고밀도 레이저가 다수 레이저의 지상 폭격으로 변경@@8300@@2016';

lv105ItemId['신비함의 백색 구두'] = '121a37f1c49eeaa00c7deefa8cf570eb';
lv105OptionMap['신비함의 백색 구두'] = {};
lv105OptionMap['신비함의 백색 구두'] = '최종 데미지 11% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5604@@1612';

lv105ItemId['지치지 않는 여정의 하의'] = '01d323c464b2e620b17f32c37f028e52';
lv105OptionMap['지치지 않는 여정의 하의'] = {};
lv105OptionMap['지치지 않는 여정의 하의'] = '최종 데미지 7% 증가<br>스킬 범위 27% 증가<br><br>이동 속도 +18%<br>HP MAX +600@@4796@@1948';

lv105ItemId['결전의 염주 - 크루세이더(여)'] = '2dddc4b985e9b179fc95adbbdff8c93c';
lv105OptionMap['결전의 염주 - 크루세이더(여)'] = {};
lv105OptionMap['결전의 염주 - 크루세이더(여)'] = '용맹의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>치유의 기도 스킬 범위 20% 증가<br><br>홀리 플래쉬가 적을 관통하여 300px 전진 후 폭발<br>- 공격력 100% 증가<br>- 스킬 범위 10% 증가<br><br>홀리 플래쉬 적중 시 그랜드 크로스 크래쉬, 리펜텐스 스매시의 남은 쿨타임 2% 감소<br><br>빛의 은총 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['근원을 삼킨 투창'] = 'a868646ab7f047097eb79a68fae37c9b';
lv105OptionMap['근원을 삼킨 투창'] = {};
lv105OptionMap['근원을 삼킨 투창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['성장하는 모험가의 훈장'] = '0db29d590f7ac771b444405148e105de';
lv105OptionMap['성장하는 모험가의 훈장'] = {};
lv105OptionMap['성장하는 모험가의 훈장'] = '최종 데미지 7% 증가@@5336@@1986';

lv105ItemId['풍운과 안개를 걷는 빛'] = '09df23f5ef3e5d7f6e20e5e11eb2c60c';
lv105OptionMap['풍운과 안개를 걷는 빛'] = {};
lv105OptionMap['풍운과 안개를 걷는 빛'] = '최종 데미지 8% 증가<br><br>물리/마법 피해의 50%를 30초 동안 지속되는 피해로 전환<br><br>슈퍼 아머 부여<br><br>HP MAX +600<br>물리/마법 방어력 +7,000@@5604@@1928';

lv105ItemId['쉐도우블랙 슈트'] = '3af6eed57291395dac316f71be7ba33c';
lv105OptionMap['쉐도우블랙 슈트'] = {};
lv105OptionMap['쉐도우블랙 슈트'] = '공격력 증가 +133.4%<br>최종 데미지 2% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br><br>[저주받은 허수아비]<br>[장비 옵션 조작키] 입력 시 저주를 내리는 허수아비 소환 (쿨타임 10초)<br>- 영역 내 적에게 30초 동안 저주 상태 이상 부여<br>- 저주 지속 시간 +3초<br><br>공격 속도 +8%<br>캐스팅 속도 +12%@@5336@@1958';

lv105ItemId['내딛은 자의 차크라 웨펀'] = '03ecea39f6a29ada17e6b3cfc193324e';
lv105OptionMap['내딛은 자의 차크라 웨펀'] = {};
lv105OptionMap['내딛은 자의 차크라 웨펀'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 로드 - 크리에이터'] = '9296d3f97f5e0850e15420e8bfe73964';
lv105OptionMap['결전의 로드 - 크리에이터'] = {};
lv105OptionMap['결전의 로드 - 크리에이터'] = '웜홀 시전 시 종말의 시계를 소환하여 격리된 시간 영역을 전개<br>영역은 5초 후 또는 스킬 키 추가 입력 시 붕괴되며 폭발<br>종말의 시계를 화염, 냉기, 바람 계열 스킬로 2히트 시 폭발 공격력 증가<br>- 폭발 데미지 : 웜홀 공격력 100%<br>- 2히트당 폭발 공격력 5% 증가 (최대 50%)<br><br>윈드 스톰 강화<br>- 끌어들이는 범위 30% 증가<br>- 게이지 소모량 35% 감소<br><br>던전에서 무기 장착 시 윈드 스톰 게이지 0부터 회복 시작@@8300@@2016';

lv105ItemId['얼터레이션 다이얼 벨트'] = '0d6513f78b59a163573f0ebf0cd0e5e5';
lv105OptionMap['얼터레이션 다이얼 벨트'] = {};
lv105OptionMap['얼터레이션 다이얼 벨트'] = '공격력 증가 +%.1f%<br>최종 데미지 10% 증가<br>모든 속성 강화 +20<br><br>공격 시 10초 동안 HP/MP 30% 회복 (쿨타임 60초)<br><br>석화 지속 시간 +3초<br><br>물리/마법 피해 감소 +10%@@6048@@1953';

lv105ItemId['천재 기술자의 전문 작업복 상의'] = '074aba3de9a06488cba89bcd74ca4c66';
lv105OptionMap['천재 기술자의 전문 작업복 상의'] = {};
lv105OptionMap['천재 기술자의 전문 작업복 상의'] = '최종 데미지 4% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br><br>공격 시 500px 내 폭발 발생 (쿨타임 10초)<br>- 폭발은 총 공격력 증가의 1,300%만큼 피해<br><br>공격 시 HP 2,200 회복 (쿨타임 1초)<br><br>HP MAX +600@@5187@@1953';

lv105ItemId['쿨링 서포트 벨트'] = '07c78673d1b8909e6d1789fe0e881b8c';
lv105OptionMap['쿨링 서포트 벨트'] = {};
lv105OptionMap['쿨링 서포트 벨트'] = '최종 데미지 6% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br>스킬 범위 20% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>MP 1분당 348 회복@@5604@@1948';

lv105ItemId['억류된 혼의 령주'] = '05528a5369db4309b9b6791780c07a97';
lv105OptionMap['억류된 혼의 령주'] = {};
lv105OptionMap['억류된 혼의 령주'] = '[베스트 콜렉터]<br>장착 및 보유한 크리쳐가 20마리 이상일 때 캐릭터 모션에 따른 스펙트럼 발생<br>- 최종 데미지 12% 증가<br>- 스킬 쿨타임 8% 감소 (각성기 제외)<br><br>공격 속도 +15%<br>캐스팅 속도 +15%<br>이동 속도 +23%<br>HP MAX +600@@4528@@1958';

lv105ItemId['결전의 장궁 - 뮤즈'] = '1b88cd6f8edddd7fc2b04dbd160f124a';
lv105OptionMap['결전의 장궁 - 뮤즈'] = {};
lv105OptionMap['결전의 장궁 - 뮤즈'] = '러블리 템포 효과 8% 증가<br>- 장착 시 효과 해제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>밤과 꿈 스킬 범위 20% 증가<br><br>사운드 오브 뮤즈 시전 시 스포트라이트가 추가 발생<br>- 공격력 20% 증가<br>- 스킬 범위 15% 증가<br>- 스킬 적중 시 컬쳐 쇼크 쿨타임 초기화<br><br>컬쳐 쇼크 강화<br>- 스킬 종료 시 2초 동안 추가 파동 5회 발생<br>- 추가 파동 공격력 : 켤쳐 쇼크 세 번째 파동 공격력의 30%<br>- 스킬 범위 20% 증가<br><br>센세이션 싱글 플레이 시 독립 공격력 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['근원을 삼킨 머스켓'] = 'ac7715f119ec6514299afbf0f2221f42';
lv105OptionMap['근원을 삼킨 머스켓'] = {};
lv105OptionMap['근원을 삼킨 머스켓'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['성장하는 모험가의 대검'] = '81cd47289d2b8ef98159e52e26f760a6';
lv105OptionMap['성장하는 모험가의 대검'] = {};
lv105OptionMap['성장하는 모험가의 대검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['결전의 낫 - 크루세이더(남)'] = '0502245a7a34f41e6179e3b887a6c252';
lv105OptionMap['결전의 낫 - 크루세이더(남)'] = {};
lv105OptionMap['결전의 낫 - 크루세이더(남)'] = '영광의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>슬로우 힐 스킬 범위 20% 증가<br><br>정의의 심판 시전 시 빛무리를 소환하여 범위 내 적 10명을 추적 후 정의의 창을 발사하여 폭발<br>- 창 공격력 : 빛의 칼날 공격력의 1600%<br>- 폭발 공격력 : 마법진 최대 유지 시 피니쉬 공격력의 100%<br>- 다른 스킬 시전 중 시전 가능<br><br>미러클 스플리터 스킬 공격력 20% 증가<br>- 돌진 거리 70% 감소<br><br>디플렉트 월 HP 400% 증가<br>- 이동하지 않음<br><br>싱글 플레이 시 독립 공격력 20% 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['결전의 장창 - 듀얼리스트'] = '31b39e36062cd50cf36d6d08ced56e03';
lv105OptionMap['결전의 장창 - 듀얼리스트'] = {};
lv105OptionMap['결전의 장창 - 듀얼리스트'] = '아래 스킬 적중 시 뇌격점혈섬을 강화하는 극 점혈 획득 (최대 30개)<br>- 레조넌스 임페일 : 타격당 10개<br>- 쿼드러플 드라이브 : 타격당 5개<br>- 파동연환창 : 타격당 4개<br>- 트윈 펀토 : 타격당 2개<br>- 퀵 펀토 : 타격당 1개<br><br>보유한 극 점혈 1개당 뇌격점혈섬 강화<br>- 스킬 쿨타임 회복 속도 : +1%<br>- 공격 속도 : +1%<br><br>뇌격점혈섬 공격 시 모든 극 점혈을 소모하여 극 점혈 1개당 창끝 점혈 히트 수 추가 적용<br><br>뇌격점혈섬 공격 속도 +10%@@10672@@2016';

lv105ItemId['절망의 발소리'] = '4b4c1f0e0a9dac8f09a6d8ca3d51ece0';
lv105OptionMap['절망의 발소리'] = {};
lv105OptionMap['절망의 발소리'] = '공격력 증가 +400.1%<br>최종 데미지 17% 증가<br><br>장비로 인한 보호막 MAX 20% 증가<br><br>물리/마법 방어력 +7,000<br>회피율 +8%@@5874@@1958';

lv105ItemId['내딛은 자의 클로'] = '24c0ba594b7d1f143de556c727e9e6cd';
lv105OptionMap['내딛은 자의 클로'] = {};
lv105OptionMap['내딛은 자의 클로'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['근원을 삼킨 광창'] = '60e8b7e1489d1764fc11d6872d47b049';
lv105OptionMap['근원을 삼킨 광창'] = {};
lv105OptionMap['근원을 삼킨 광창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['성장하는 모험가의 광검'] = '3a819d58992533a7614a3ce021518ad1';
lv105OptionMap['성장하는 모험가의 광검'] = {};
lv105OptionMap['성장하는 모험가의 광검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['결전의 토템 - 크루세이더(여)'] = 'a9086f82e0a8ae1f7b2f54ba47e60c4f';
lv105OptionMap['결전의 토템 - 크루세이더(여)'] = {};
lv105OptionMap['결전의 토템 - 크루세이더(여)'] = '용맹의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>치유의 기도 스킬 범위 20% 증가<br><br>홀리 플래쉬가 적을 관통하여 300px 전진 후 폭발<br>- 공격력 100% 증가<br>- 스킬 범위 10% 증가<br><br>홀리 플래쉬 적중 시 그랜드 크로스 크래쉬, 리펜텐스 스매시의 남은 쿨타임 2% 감소<br><br>빛의 은총 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['성장하는 모험가의 낫'] = '3fbf40627e1182230c694fac4a38e8ca';
lv105OptionMap['성장하는 모험가의 낫'] = {};
lv105OptionMap['성장하는 모험가의 낫'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['근원을 삼킨 낫'] = 'df25bbdf1a270ab3ee3959e35db676bf';
lv105OptionMap['근원을 삼킨 낫'] = {};
lv105OptionMap['근원을 삼킨 낫'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['결전의 너클 - 그래플러(여)'] = 'a6d69424877c757046c00418be7873d3';
lv105OptionMap['결전의 너클 - 그래플러(여)'] = {};
lv105OptionMap['결전의 너클 - 그래플러(여)'] = '전직 스킬로 공격 시 테크니컬 포인트 획득 (최대 20점)<br>- 1~30Lv : 1점<br>- 35~45Lv : 2점<br>- 60~80Lv : 2점<br>- 50, 85, 95Lv : 5점<br>- 100Lv : 10점<br>- 체인 커넥션 연계/공중 스킬 사용 시 1점 추가<br><br>테크니컬 포인트에 따라 효과 발동<br>- 11점 이상 : 50, 85, 100Lv 스킬 적중 시 모든 스킬 남은 쿨타임 30% 감소 (각성기 제외 / 모든 점수 소모)<br>- 6점 이상 : 잡기 스킬 공격 실패 시 해당 스킬 쿨타임을 3초로 감소 (3점 소모)<br>- 1점 이상 : 체인 커넥션 스킬 연계 시 체인 커넥션 회복 시간 3초 감소@@5336@@2016';

lv105ItemId['스톰라이더'] = '137e00b2550a315e44eb5cf7d119aa0e';
lv105OptionMap['스톰라이더'] = {};
lv105OptionMap['스톰라이더'] = '최종 데미지 3% 증가<br>스킬 쿨타임 회복 속도 +10% (각성기 제외)<br><br>스킬로 소모한 MP의 10%만큼 HP 회복<br><br>물리/마법 방어력 +9,000<br>적중률 +10%<br>스킬 MP 소모량 -7%@@6414@@1958';

lv105ItemId['화려한 청색의 음율'] = '08192ecf513c7a8b18063be883a6356b';
lv105OptionMap['화려한 청색의 음율'] = {};
lv105OptionMap['화려한 청색의 음율'] = '스킬 쿨타임 회복 속도 +50% (각성기 제외)<br>- "오랜 시간 품어온 소원" 장비 장착 필요<br><br>퀵스탠딩 쿨타임 50% 감소<br>퀵스탠딩 유지 시 1초당 HP 1% 회복 (최대 4초)<br><br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +18%<br>물리/마법 방어력 +7,000<br>수면 내성 -10%@@5604@@1948';

lv105ItemId['결전의 장창 - 드래고니안 랜서'] = '9738428e14e973e1cb5fcbadc4389d57';
lv105OptionMap['결전의 장창 - 드래고니안 랜서'] = {};
lv105OptionMap['결전의 장창 - 드래고니안 랜서'] = '서번트 랜스 스킬로 공격한 적에게 사냥 표식을 생성하고 사냥 1단계 부여<br>사냥 표식 20개마다 사냥 단계 상승 (최대 3단계)<br>아래 스킬 시전 시 사냥 단계를 소모하고 해당 스킬 공격력 증가<br>- 데보닝 슬래셔<br>- 팔티잔 스큐어<br>- 날개 꿰기<br><br><사냥 단계별 공격력 증가량><br>- 1단계 : 10%<br>- 2단계 : 20%<br>- 3단계 : 30%<br><br><사냥 표식 부여량><br>- 서번트 랜스 : 타격당 3 스택<br>- 서번트 랜스 : 어썰트 : 타격당 10 스택<br>- 서번트 랜스 : 인게이저 : 타격당 4 스택<br><br>서번트 랜스 마법창 재생성 시간 30% 감소<br>서번트 랜스 재발사 쿨타임 30% 감소@@8300@@2016';

lv105ItemId['미니 배터리 팩'] = '74c8c97345511afa9a6f00623d2b0525';
lv105OptionMap['미니 배터리 팩'] = {};
lv105OptionMap['미니 배터리 팩'] = '최종 데미지 11% 증가<br><br>스킬 MP 소모량 +100%<br><br>적중률 +10%<br>회피율 +8%@@5066@@1948';

lv105ItemId['결전의 소검 - 카오스'] = '237f141ec341f5e72717e87bd2d71a5c';
lv105OptionMap['결전의 소검 - 카오스'] = {};
lv105OptionMap['결전의 소검 - 카오스'] = '제노사이드 크러쉬 시전 시 데몬들을 폭발시키는 대신 아텐의 화신이 데몬들의 환영을 소환하고 폭발시키도록 변경<br>- 공격력 10% 증가<br>- 스킬 범위 20% 증가<br>- 시전 중 미드나잇 카니발 시전 가능<br>- 데몬이 없을 경우 즉시 모든 데몬 소환@@10076@@2016';

lv105ItemId['광폭화된 전의의 클로'] = '3bf589fcfe964ddebf48055beba386cb';
lv105OptionMap['광폭화된 전의의 클로'] = {};
lv105OptionMap['광폭화된 전의의 클로'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 대검 - 검귀'] = '642c5153a4621094812cfe3551f0bc3d';
lv105OptionMap['결전의 대검 - 검귀'] = {};
lv105OptionMap['결전의 대검 - 검귀'] = '검술 , 합격 계열 스킬 공격 시 귀신보 쿨타임 초기화<br>- 검술 계열 스킬을 귀신보로 발동하는 경우 제외<br><br>귀신보 공격력 20% 증가<br><br>귀신보 Y축 공격 범위 10% 증가@@9484@@2016';

lv105ItemId['전진하는 기세'] = '61fdddd58174d6e02950f37df0b13ff8';
lv105OptionMap['전진하는 기세'] = {};
lv105OptionMap['전진하는 기세'] = '40~45Lv 스킬 공격력 5% 증가<br>스킬 쿨타임 회복 속도 +15% (각성기 제외)<br>스킬 범위 16% 증가<br>40~45Lv 스킬 범위 15% 증가@@6440@@1900';

lv105ItemId['결전의 통파 - 스트리트파이터(여)'] = '4a99f631df3de58795a2e312259e4cf2';
lv105OptionMap['결전의 통파 - 스트리트파이터(여)'] = {};
lv105OptionMap['결전의 통파 - 스트리트파이터(여)'] = '특정 스킬 공격 시 즉폭의 혈이 발생하여 자신의 출혈/중독/화상/감전 데미지 폭발<br><즉폭의 혈 발생 스킬><br>- 일발화약성 훅 공격<br>- 맹독 일발화약성 훅 공격<br>- 마운트 마지막 충격파<br><br>아래의 스킬 상태 이상 1개당 보너스 공격력 5% 추가 증가<br>- 일발화약성<br>- 맹독 일발화약성<br>- 크레이지 발칸@@9484@@2016';

lv105ItemId['얼어붙은 저항의 리볼버'] = '2bf7a249a7e6076c7ae3ccb4f73db5ec';
lv105OptionMap['얼어붙은 저항의 리볼버'] = {};
lv105OptionMap['얼어붙은 저항의 리볼버'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 보우건 - 어썰트'] = '4cdfa69d26d931d7cf2e4fe27fb21140';
lv105OptionMap['결전의 보우건 - 어썰트'] = {};
lv105OptionMap['결전의 보우건 - 어썰트'] = '부스트 스킬로 전직 스킬 캔슬 시 전력 1스택 획득 (최대 5스택)<br><br>아래 스킬 시전 시 모든 전력을 소모하고, 소모한 전력당 공격력 10% 증가<br>- 레이시온 크리트<br>- 부스트 : 다이너마이트 킥<br><br>부스트 : 다이너마이트 킥 적중 시 적을 1.5초 동안 홀딩<br>- 소모한 전력 1스택당 홀딩 시간 0.5초 증가@@9484@@2016';

lv105ItemId['내딛은 자의 단검'] = '5607105ab414e758323c79d5d2af85c2';
lv105OptionMap['내딛은 자의 단검'] = {};
lv105OptionMap['내딛은 자의 단검'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['성장하는 모험가의 장창'] = 'd8e83a86b9a49045900a67a8a8bc8dbe';
lv105OptionMap['성장하는 모험가의 장창'] = {};
lv105OptionMap['성장하는 모험가의 장창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['용살자의 증표 - 용린 귀걸이'] = '3657d79470a96195bc2d3da8fe2153ba';
lv105OptionMap['용살자의 증표 - 용린 귀걸이'] = {};
lv105OptionMap['용살자의 증표 - 용린 귀걸이'] = '모든 속성 강화 +30<br>75Lv 액티브 스킬 Lv +10<br>- 제외 : 초월의 룬, 디바인 플래쉬, 광풍 나선력<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +5%<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@4662@@1933';

lv105ItemId['초소형 GPS'] = '4fce013abf06e5834a391e150732f410';
lv105OptionMap['초소형 GPS'] = {};
lv105OptionMap['초소형 GPS'] = '최종 데미지 12% 증가<br>무력화 게이지 감소량 +45%<br><br>물리/마법 피해 감소 +15%<br>물리/마법 방어력 +7,000<br>공격 속도 +8%<br>캐스팅 속도 +12%@@4258@@1948';

lv105ItemId['멈추지 않는 운명'] = '61276b1795ab47f1e8813b8600c01b5a';
lv105OptionMap['멈추지 않는 운명'] = {};
lv105OptionMap['멈추지 않는 운명'] = '최종 데미지 7% 증가<br><br>[아이스 스트라이크]<br>빙결 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>[만년설]<br>불에 녹지 않는 빙결 부여<br>- 화상으로 해제 불가능한 빙결 부여<br>- 공격 시 모든 적에게 10초 동안 빙결 상태 이상 부여 (쿨타임 5초)<br><br>MP MAX +945@@4714@@1930';

lv105ItemId['결전의 대검 - 웨펀마스터'] = 'ac3f8ab19415488a165df4ec2bcef270';
lv105OptionMap['결전의 대검 - 웨펀마스터'] = {};
lv105OptionMap['결전의 대검 - 웨펀마스터'] = '초식을 발전시켜 이기어검술 스킬 강화<br>- 지속 시간 +20초<br>- 기본기 숙련에 영향받는 스킬 적중 시 지속 시간 +0.5초 (최대 지속 시간 초과 불가능)<br>- 꿰뚫는검 사출 개수 +1<br>- 꿰뚫는검 사출 회수 시간 감소<br><br>장착 해제 시 이기어검술 지속 시간 종료@@8892@@2016';

lv105ItemId['결전의 십자가 - 퇴마사'] = '10bb56529f396bb4993bb68c808c9c85';
lv105OptionMap['결전의 십자가 - 퇴마사'] = {};
lv105OptionMap['결전의 십자가 - 퇴마사'] = '공의 식신 - 백호 스킬 공격력 20% 증가<br>난격의 매 타격이 충격파 생성<br>- 쿨타임 20% 감소<br>- 난격 횟수 100% 증가<br>- 막타 삭제<br>- 막타 공격력은 난격 공격력에 합산<br><br>광명의 챠크라 적용 중 무쌍격 스킬 공격력 20% 증가<br>무쌍격 범위 20% 증가<br><br>대시 중 무쌍격 시전 시 전방으로 돌진하며 시전<br>@@4148@@2016';

lv105ItemId['공중형 : 전술 프롭 드론'] = '26da5fc2e592517bdb6fb3f982683d12';
lv105OptionMap['공중형 : 전술 프롭 드론'] = {};
lv105OptionMap['공중형 : 전술 프롭 드론'] = '최종 데미지 9% 증가<br><br>[드론 서포트]<br>공격 시 전투 드론, 피격 시 구급 드론을 호출하여 지원 상자 투하 (쿨타임 30초)<br>- 전투 : 30초 동안 모든 속도 +15%<br>- 구급 : HP/MP 30% 회복<br><br>HP MAX +600<br>MP MAX +945@@5874@@1928';

lv105ItemId['사멸하는 신뢰의 핸드캐넌'] = '1d394e3fe9357db2e25ea6f72b055fa8';
lv105OptionMap['사멸하는 신뢰의 핸드캐넌'] = {};
lv105OptionMap['사멸하는 신뢰의 핸드캐넌'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['아머드 파워 상의'] = '10bb328ff8d2533d04135d3881272119';
lv105OptionMap['아머드 파워 상의'] = {};
lv105OptionMap['아머드 파워 상의'] = '모든 속성 강화 +10<br>화상 피해 +20%<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 화상 상태 이상 부여 (쿨타임 10초)<br><br>물리/마법 크리티컬 히트 +5%<br>화상 내성 +10%@@5604@@1968';

lv105ItemId['사멸하는 신뢰의 쌍검'] = '22c1ca322b0215230fb1f8a1af00ac84';
lv105OptionMap['사멸하는 신뢰의 쌍검'] = {};
lv105OptionMap['사멸하는 신뢰의 쌍검'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 건틀릿 - 스트리트파이터(여)'] = '96d7379276065c67345edd1b5c8cf8ca';
lv105OptionMap['결전의 건틀릿 - 스트리트파이터(여)'] = {};
lv105OptionMap['결전의 건틀릿 - 스트리트파이터(여)'] = '특정 스킬 공격 시 즉폭의 혈이 발생하여 자신의 출혈/중독/화상/감전 데미지 폭발<br><즉폭의 혈 발생 스킬><br>- 일발화약성 훅 공격<br>- 맹독 일발화약성 훅 공격<br>- 마운트 마지막 충격파<br><br>아래의 스킬 상태 이상 1개당 보너스 공격력 5% 추가 증가<br>- 일발화약성<br>- 맹독 일발화약성<br>- 크레이지 발칸@@9484@@2016';

lv105ItemId['성장하는 모험가의 광창'] = '9a2bf378d8605a9d82dcc1dd9ac4f37b';
lv105OptionMap['성장하는 모험가의 광창'] = {};
lv105OptionMap['성장하는 모험가의 광창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 건틀릿 - 스트라이커(남)'] = 'cc24df88d49730bf0805b3e9bb41ee68';
lv105OptionMap['결전의 건틀릿 - 스트라이커(남)'] = {};
lv105OptionMap['결전의 건틀릿 - 스트라이커(남)'] = '이중개방의 효과가 10초 동안 유지되는 맹렬한 화염으로 변경되며 최종 데미지 45% 증가 (각성기 제외)<br>- 이중개방 강화 공격력 효과 미적용<br><br>화염의 힘으로 머슬시프트 강화<br>- 캔슬 공격 시 이중개방 남은 쿨타임 2% 감소<br>- 캔슬 시 30초 동안 전직 계열 스킬 공격력 증가율 +2%<br>- 캔슬 횟수 +2@@7112@@2016';

lv105ItemId['얼어붙은 저항의 자동권총'] = '3bc41b949d1c40c272c5f1c493109f76';
lv105OptionMap['얼어붙은 저항의 자동권총'] = {};
lv105OptionMap['얼어붙은 저항의 자동권총'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 너클 - 스트리트파이터(여)'] = 'b698400ac22995040b7e2a518dc13a49';
lv105OptionMap['결전의 너클 - 스트리트파이터(여)'] = {};
lv105OptionMap['결전의 너클 - 스트리트파이터(여)'] = '특정 스킬 공격 시 즉폭의 혈이 발생하여 자신의 출혈/중독/화상/감전 데미지 폭발<br><즉폭의 혈 발생 스킬><br>- 일발화약성 훅 공격<br>- 맹독 일발화약성 훅 공격<br>- 마운트 마지막 충격파<br><br>아래의 스킬 상태 이상 1개당 보너스 공격력 5% 추가 증가<br>- 일발화약성<br>- 맹독 일발화약성<br>- 크레이지 발칸@@9484@@2016';

lv105ItemId['결전의 염주 - 무녀'] = '20bfc50379daf3c0e2556c140f241e97';
lv105OptionMap['결전의 염주 - 무녀'] = {};
lv105OptionMap['결전의 염주 - 무녀'] = '염주 사출, 목환자경 타격 1회당 신력 염주알 1개 획득 (최대 25개)<br><br>아래 스킬 시전 시 신력 염주알을 소모하여 강화<br>- 부동주박진 : 염주 회전 수 1회 증가 (8개 소모)<br>- 백팔염주 : 염주 사출 개수 10개 증가 (10개 소모)<br>- 마혼부 : 퇴마음양옥 : 튕기는 횟수 3회 증가 (10개 소모)<br>- 염폭모대주 : 염주알 낙하 수 1 증가 (15개 소모)<br><br>염주 사출, 목환자경 시전 1회당 백팔염주, 부동주박진 스킬 쿨타임 회복 속도 +1%<br>- 백팔염주, 부동주박진 시전 시 효과 초기화@@4148@@2016';

lv105ItemId['수호룡의 비호 - 축복'] = '1d31aaae6d692a76a6da48a79c66a3f9';
lv105OptionMap['수호룡의 비호 - 축복'] = {};
lv105OptionMap['수호룡의 비호 - 축복'] = '모든 속성 강화 +30<br>45Lv 액티브 스킬 Lv +10<br>- 제외 : 힐 윈드, 재생의 아리아, 달콤한 칸타빌레<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@5874@@1938';

lv105ItemId['기사의 긍지'] = '1160d523e09c57173a62f1b337eb6b67';
lv105OptionMap['기사의 긍지'] = {};
lv105OptionMap['기사의 긍지'] = '공격력 증가 +222.3%<br>최종 데미지 7% 증가<br>모든 속성 강화 +10<br><br>[아이스 스트라이크]<br>빙결 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>공격 시 적에게 5초 동안 빙결 이상 부여 (쿨타임 8초)<br><br>빙결 지속 시간 +2초@@4984@@1968';

lv105ItemId['부스팅 펄스 튜브'] = '43c511645914d15da2464c26ec863f27';
lv105OptionMap['부스팅 펄스 튜브'] = {};
lv105OptionMap['부스팅 펄스 튜브'] = '최종 데미지 17% 증가<br>- "천재 기술자의 두터운 보호부츠" 장착 필요<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@3720@@1900';

lv105ItemId['어댑터블 투톤 링'] = '0c175759e1f145159065cc56257897b9';
lv105OptionMap['어댑터블 투톤 링'] = {};
lv105OptionMap['어댑터블 투톤 링'] = '스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>[음양의 기운]<br>공격 시 15초 동안 음양의 기운을 얻을 수 있는 구슬 생성 (쿨타임 1초)<br>- 검은색 구슬 : 공격 속도 +24%, 캐스팅 속도 +36%<br>- 흰색 구슬 : HP MAX 10% 수치의 보호막 부여<br><br>물리/마법 크리티컬 히트 +5%<br>이동 속도 +28%@@4258@@1958';

lv105ItemId['결전의 자동권총 - 런처(남)'] = '3edbb7aaf77b82062c47ae7ca4c79f41';
lv105OptionMap['결전의 자동권총 - 런처(남)'] = {};
lv105OptionMap['결전의 자동권총 - 런처(남)'] = '레이저 라이플을 최대 3회 충전 스킬로 변경<br>- 공격력 20% 증가<br>- 크기 40% 증가<br>- 충전 쿨타임 : 7초<br>- 충전 레이저 라이플 충전 시간 50% 감소<br>- 적중 시 적에게 10초 동안 전자기장 부여<br><br>FM-92 스팅어 강화<br>- 공격력 15% 증가<br>- 폭발 범위 25% 증가<br>- 전자기장이 부여된 적 존재 시 대상을 추적하는 강화 고폭탄 발사@@8892@@2016';

lv105ItemId['빛을 발하는 생명'] = '9600cd6aedd9194d8b86859d716e5a16';
lv105OptionMap['빛을 발하는 생명'] = {};
lv105OptionMap['빛을 발하는 생명'] = '공격력 증가 +222.3%<br>최종 데미지 7.1% 증가<br>모든 속성 강화 +10<br><br>공격 시 적에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)<br><br>감전 지속 시간 -10%<br><br>감전 내성 +1%@@5133@@1968';

lv105ItemId['근원을 삼킨 장창'] = '37eafad773bde4cb28fa253a88324ead';
lv105OptionMap['근원을 삼킨 장창'] = {};
lv105OptionMap['근원을 삼킨 장창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['데카 가이던스 디바이스'] = '9a66757f985f48fc84a8199f1855445f';
lv105OptionMap['데카 가이던스 디바이스'] = {};
lv105OptionMap['데카 가이던스 디바이스'] = '최종 데미지 4% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@2910@@1872';

lv105ItemId['악몽을 이겨낸 승전보'] = '011a747e591102a8e85c0546fb475051';
lv105OptionMap['악몽을 이겨낸 승전보'] = {};
lv105OptionMap['악몽을 이겨낸 승전보'] = '최종 데미지 8% 증가<br><br>슈퍼 아머 부여<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@5336@@1900';

lv105ItemId['근원을 삼킨 크로스슈터'] = '2441a033626fd6ff582ca3baea933c6f';
lv105OptionMap['근원을 삼킨 크로스슈터'] = {};
lv105OptionMap['근원을 삼킨 크로스슈터'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>회피율 +8%@@533@@1996';

lv105ItemId['세계가 담긴 축'] = '0c359ea137a9b7da80b16a569e23b148';
lv105OptionMap['세계가 담긴 축'] = {};
lv105OptionMap['세계가 담긴 축'] = '최종 데미지 11% 증가<br><br>MP MAX +945<br>모든 속성 저항 +8@@5604@@1948';

lv105ItemId['결전의 자동권총 - 메카닉(남)'] = '02aeb092653b521b9dbd798de2b1f3c5';
lv105OptionMap['결전의 자동권총 - 메카닉(남)'] = {};
lv105OptionMap['결전의 자동권총 - 메카닉(남)'] = '로봇 전폭으로 로봇 폭발 시 R-러너 생성<br>-  R-러너 공격력 : RX-78 랜드러너 공격력의 50%<br>- 로봇 전폭의 폭발 데미지 변화율 효과 적용<br>- 최대 10개 생성<br><br><R-러너 생성 가능 스킬><br>- RX-78 랜드러너<br>- Ez-8 카운트 다운<br>- RX-60 트랩 러너<br>- 공중 전투 메카 : 템페스터<br>- 메카 드롭<br>- 스패로우 팩토리<br>- Ez-10 카운터 어택<br>- TX-45 A-Team<br><br>RX-78 랜드러너 공격력 5% 증가@@9484@@2016';

lv105ItemId['계곡을 감시하는 활보'] = '1437d06dccd505e8f3b2ffea670bc91d';
lv105OptionMap['계곡을 감시하는 활보'] = {};
lv105OptionMap['계곡을 감시하는 활보'] = '최종 데미지 10% 증가<br><br>공격 시 MP 3,500 회복 (쿨타임 1초)<br><br>MP MAX +5%<br>물리/마법 피해 감소 +10%@@4917@@1915';

lv105ItemId['근원을 삼킨 장도'] = '35cfd571fa61fa852d9266736ece7603';
lv105OptionMap['근원을 삼킨 장도'] = {};
lv105OptionMap['근원을 삼킨 장도'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['계곡을 감시하는 표식'] = 'a48e106a655177d89a8b400fde1dc7d1';
lv105OptionMap['계곡을 감시하는 표식'] = {};
lv105OptionMap['계곡을 감시하는 표식'] = '최종 데미지 14% 증가<br>1~70Lv 스킬 범위 15% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>물리/마법 방어력 +7,000@@5604@@1910';

lv105ItemId['벤타블랙 팬츠'] = '38680847217ae3b8d46abd30108eab42';
lv105OptionMap['벤타블랙 팬츠'] = {};
lv105OptionMap['벤타블랙 팬츠'] = '최종 데미지 15% 증가<br>스킬 쿨타임 15% 증가<br>기본기 숙련 공격력 53% 증가<br><br>공격 속도 +23%<br>캐스팅 속도 +27%<br>적중률 +10%@@4258@@1958';

lv105ItemId['근원을 삼킨 건틀릿'] = '2363487ed2bf6dbd38a098a5a65e39d0';
lv105OptionMap['근원을 삼킨 건틀릿'] = {};
lv105OptionMap['근원을 삼킨 건틀릿'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['근원을 삼킨 리볼버'] = '2e5e9ad15b651a3f458f528093d59092';
lv105OptionMap['근원을 삼킨 리볼버'] = {};
lv105OptionMap['근원을 삼킨 리볼버'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 도 - 아수라'] = '97f153e0d0b60823a039417404a7ba00';
lv105OptionMap['결전의 도 - 아수라'] = {};
lv105OptionMap['결전의 도 - 아수라'] = '파동검 폭염이 빠르게 폭발<br>- 폭발 위치 변경<br>- 폭발 간격 시간 30% 감소<br>- 폭발 간격 거리 90% 감소<br>- 폭발 크기 45% 증가<br>- 3타 폭발 크기 70% 증가<br><br>뇌신의 힘을 받아 아래의 스킬 시전 중 작열 파동진을 모션 없이 시전 가능<br>- 파동검 폭염<br>- 부동명왕진<br>- 파동검 : 인다라망<br><br>살의의 파동 파동 영역 20% 증가<br><br>작열 파동진 스킬 범위 25% 증가@@10672@@2016';

lv105ItemId['결전의 자동권총 - 스핏파이어(여)'] = '8d01f652eb32e1bf898dee98c12a65b6';
lv105OptionMap['결전의 자동권총 - 스핏파이어(여)'] = {};
lv105OptionMap['결전의 자동권총 - 스핏파이어(여)'] = 'G-14 파열류탄, G-35L 섬광류탄, G-18C 빙결류탄 스킬 강화<br>- 최대 장전 수 +2<br>- 점프 상태에서 시전 시 니트로 모터를 소모하지 않음<br><br><br>보유한 유탄을 소모하여 포토빌라이저 강화<br>- 소모한 유탄 1개당 공격력 2% 증가 (최대 30%)<br>- 스탠바이-레디 상태에서는 최대 강화 수치로 발사@@8300@@2016';

lv105ItemId['기계 공학의 심장 목걸이'] = '260b2a403c8d3ad0579ab4322a09dd02';
lv105OptionMap['기계 공학의 심장 목걸이'] = {};
lv105OptionMap['기계 공학의 심장 목걸이'] = '최종 데미지 10% 증가<br>모든 속성 강화 +20<br><br>스킬로 인한 MP 회복량 +200%<br><br>MP MAX +945@@4972@@1943';

lv105ItemId['마땅한 본분'] = '1751cf4529331916565fffaef9d9c2d7';
lv105OptionMap['마땅한 본분'] = {};
lv105OptionMap['마땅한 본분'] = '최종 데미지 14% 증가<br>모든 속성 강화 +20<br><br>적중률 +10%<br>스킬 MP 소모량 -7%@@3450@@1958';

lv105ItemId['결전의 둔기 - 버서커'] = '4765bd863fa341012babf0f14c05541b';
lv105OptionMap['결전의 둔기 - 버서커'] = {};
lv105OptionMap['결전의 둔기 - 버서커'] = '피에 굶주려 갈증 시전 시 HP 10%를 소모하고 혈기 3개 획득 (최대 10스택)<br>- 갈증 충전 기능 삭제<br>- 다른 스킬 시전 중 갈증 시전 가능<br>- 던전 입장 시 혈기 1개 획득<br><br>블러드 소드, 격노 시전 시 혈기 1개를 소모하여 강화<br>- 공격력 30% 증가<br>- 스킬 범위 15% 증가<br><br>블러드 소드, 격노 시전 시 HP 5% 회복@@9484@@2016';

lv105ItemId['격동하는 마음'] = '073a49a592516fef6eb217c24fc85f75';
lv105OptionMap['격동하는 마음'] = {};
lv105OptionMap['격동하는 마음'] = '최종 데미지 8% 증가<br><br>[불사의 주술]<br>사망 시 5초 동안 불사의 주술 발동 (쿨타임 300초)<br>- HP 10% 회복<br>- 다른 HP 회복 효과 및 데미지 무효화<br>- 주술 종료 시 HP MAX 30% 제한<br><br>공격 시 HP 2,200 회복 (쿨타임 1초)<br><br>물리/마법 크리티컬 히트 +5%<br>카운터 피격 시 물리/마법 피해 감소 +20%@@5995@@1963';

lv105ItemId['결전의 십자가 - 크루세이더(남)'] = '46921b3b77f967c86442173fcf778bf7';
lv105OptionMap['결전의 십자가 - 크루세이더(남)'] = {};
lv105OptionMap['결전의 십자가 - 크루세이더(남)'] = '영광의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>슬로우 힐 스킬 범위 20% 증가<br><br>정의의 심판 시전 시 빛무리를 소환하여 범위 내 적 10명을 추적 후 정의의 창을 발사하여 폭발<br>- 창 공격력 : 빛의 칼날 공격력의 1600%<br>- 폭발 공격력 : 마법진 최대 유지 시 피니쉬 공격력의 100%<br>- 다른 스킬 시전 중 시전 가능<br><br>미러클 스플리터 스킬 공격력 20% 증가<br>- 돌진 거리 70% 감소<br><br>디플렉트 월 HP 400% 증가<br>- 이동하지 않음<br><br>싱글 플레이 시 독립 공격력 20% 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['사멸하는 신뢰의 보우건'] = '50ee4a1d7ae1531dc49db48ea4630cf9';
lv105OptionMap['사멸하는 신뢰의 보우건'] = {};
lv105OptionMap['사멸하는 신뢰의 보우건'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['옥화의 망령 각갑'] = '1984f8a7c0a85f9773d7a43b3230bc1e';
lv105OptionMap['옥화의 망령 각갑'] = {};
lv105OptionMap['옥화의 망령 각갑'] = '공격력 증가 +340.9%<br>최종 데미지 17% 증가<br><br>공격 속도 +24%<br>캐스팅 속도 +24%<br>이동 속도 +8%<br>회피율 +8%@@5604@@1968';

lv105ItemId['패셔네이트 나이트 부츠'] = '02d91a14d367009b8e748b8b2e14d995';
lv105OptionMap['패셔네이트 나이트 부츠'] = {};
lv105OptionMap['패셔네이트 나이트 부츠'] = '최종 데미지 22% 증가<br>- "억제된 마력의 팔찌" 장비 장착 필요<br><br>공격 시 MP 2,500 회복 (쿨타임 1초)<br><br>물리/마법 크리티컬 히트 +7%<br>MP MAX +945<br>모든 상태 이상 내성 -10%@@5510@@1943';

lv105ItemId['결전의 스태프 - 크리에이터'] = '9d23c89793a87a5f97818577537f6c9c';
lv105OptionMap['결전의 스태프 - 크리에이터'] = {};
lv105OptionMap['결전의 스태프 - 크리에이터'] = '웜홀 시전 시 종말의 시계를 소환하여 격리된 시간 영역을 전개<br>영역은 5초 후 또는 스킬 키 추가 입력 시 붕괴되며 폭발<br>종말의 시계를 화염, 냉기, 바람 계열 스킬로 2히트 시 폭발 공격력 증가<br>- 폭발 데미지 : 웜홀 공격력 100%<br>- 2히트당 폭발 공격력 5% 증가 (최대 50%)<br><br>윈드 스톰 강화<br>- 끌어들이는 범위 30% 증가<br>- 게이지 소모량 35% 감소<br><br>던전에서 무기 장착 시 윈드 스톰 게이지 0부터 회복 시작@@8300@@2016';

lv105ItemId['결전의 둔기 - 아수라'] = '63f9e8b03eb9f32112f9d95f16f936fb';
lv105OptionMap['결전의 둔기 - 아수라'] = {};
lv105OptionMap['결전의 둔기 - 아수라'] = '파동검 폭염이 빠르게 폭발<br>- 폭발 위치 변경<br>- 폭발 간격 시간 30% 감소<br>- 폭발 간격 거리 90% 감소<br>- 폭발 크기 45% 증가<br>- 3타 폭발 크기 70% 증가<br><br>뇌신의 힘을 받아 아래의 스킬 시전 중 작열 파동진을 모션 없이 시전 가능<br>- 파동검 폭염<br>- 부동명왕진<br>- 파동검 : 인다라망<br><br>살의의 파동 파동 영역 20% 증가<br><br>작열 파동진 스킬 범위 25% 증가@@10672@@2016';

lv105ItemId['약동하는 생명의 고동'] = '0130e39616fcc336151677713e49d375';
lv105OptionMap['약동하는 생명의 고동'] = {};
lv105OptionMap['약동하는 생명의 고동'] = '최종 데미지 10% 증가<br><br>공격 시 명속성 폭발 발생 (쿨타임 5초)<br>- 총 공격력 증가 수치의 480%만큼 피해<br>- 자신에게는 1의 피해<br><br>물리/마법 방어력 +7,000<br>명속성 저항 +10@@6414@@1958';

lv105ItemId['빛을 잃은 진실'] = '1c275071f4ce209aa05f915ef840bf88';
lv105OptionMap['빛을 잃은 진실'] = {};
lv105OptionMap['빛을 잃은 진실'] = '최종 데미지 2% 증가<br>출혈 피해 +20%<br><br>출혈 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>자신에게 해제되지 않는 출혈 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +10%<br>공격 속도 +5%<br>캐스팅 속도 +7.5%<br>HP 1분당 460.2 회복@@5604@@1968';

lv105ItemId['컨퓨즈드 코어 슈트'] = '012e5eaf2e5c3db2e9cb777c56d72fa4';
lv105OptionMap['컨퓨즈드 코어 슈트'] = {};
lv105OptionMap['컨퓨즈드 코어 슈트'] = '모든 속성 강화 +10<br>출혈/중독/화상/감전 피해 +15%<br><br>[D011-Risa]<br>스킬 시전 시 20초 동안 D011-Risa 소환 (최대 5개)<br>- D011-Risa는 가장 강한 적에게 부여된 모든 상태 이상을 부여받음<br><br>물리/마법 크리티컬 히트 +5%<br>모든 상태 이상 내성 +10%<br>스킬 MP 소모량 -7%@@5604@@1938';

lv105ItemId['얼어붙은 저항의 머스켓'] = '3eee5135fb253ece29f63cdca9474640';
lv105OptionMap['얼어붙은 저항의 머스켓'] = {};
lv105OptionMap['얼어붙은 저항의 머스켓'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['내딛은 자의 완드'] = '0e7020331c4d15c119ff741261cdc040';
lv105OptionMap['내딛은 자의 완드'] = {};
lv105OptionMap['내딛은 자의 완드'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['성장하는 모험가의 장도'] = '111aa6534802424a632d5993dc81edaf';
lv105OptionMap['성장하는 모험가의 장도'] = {};
lv105OptionMap['성장하는 모험가의 장도'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['내딛은 자의 선현궁'] = '0539a0591e1471d73ca1e104d71b0509';
lv105OptionMap['내딛은 자의 선현궁'] = {};
lv105OptionMap['내딛은 자의 선현궁'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['시간 공학의 시간'] = '122b99260ca516d3d8cfc1a4e690b8da';
lv105OptionMap['시간 공학의 시간'] = {};
lv105OptionMap['시간 공학의 시간'] = '최종 데미지 14% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>물리/마법 피해 감소 +15%<br>적중률 +10%@@5604@@1910';

lv105ItemId['결전의 통파 - 스트라이커(여)'] = 'd3f2a29104bd7b826ab291cd14db7c90';
lv105OptionMap['결전의 통파 - 스트라이커(여)'] = {};
lv105OptionMap['결전의 통파 - 스트라이커(여)'] = '라이트닝 댄스 이동 횟수가 4회 감소하고 1:1 공격 시 대상의 좌우를 이동하며 공격<br>- 공격력 15% 감소<br>- 공격 속도 +30%<br>- 이동 범위 5% 증가<br>- 공격 시 적에게 감전 상태 이상 부여 (쿨타임 15초)<br><br>라이트닝 댄스 공격 시 충격파 생성<br>- 직접 타격 대상은 충격파 피격 제외<br>- 1:1 공격일 경우 충격파 미생성@@9484@@2016';

lv105ItemId['별을 담는 벨트'] = '0adff49f605d822d72d2afc73cbfa192';
lv105OptionMap['별을 담는 벨트'] = {};
lv105OptionMap['별을 담는 벨트'] = '최종 데미지 13% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%<br>물리/마법 피해 감소 +15%@@6144@@1928';

lv105ItemId['결전의 도 - 데몬슬레이어'] = '7d3a90fda94dbafdd77478206651cd18';
lv105OptionMap['결전의 도 - 데몬슬레이어'] = {};
lv105OptionMap['결전의 도 - 데몬슬레이어'] = '사복검 - 발을 최대 3회 충전 스킬로 변경<br>- 솟아오르는 검 개수 +3<br>- 전직 스킬 공격 시 1회 충전 (사복검 - 발 제외)<br>- 자동 충전 불가능<br><br>전직 스킬의 후딜레이를 캔슬하여 사복검 - 발 시전 가능 (각성기 제외)<br><br>사복검 - 발 후딜레이를 캔슬하여 전직 스킬 시전 가능@@5928@@2016';

lv105ItemId['고귀한 신의'] = '476df057a837cdca0b902c8f3bf86677';
lv105OptionMap['고귀한 신의'] = {};
lv105OptionMap['고귀한 신의'] = '최종 데미지 8% 증가<br><br>[차원 개방]<br>[장비 옵션 조작키] 입력 시 2초 동안 차원 개방 (최대 2회)<br>- 차원 진입 시 무적<br>- 차원 소멸 시 10초 동안 모든 속도 +30%<br><br>HP MAX +30%<br>비 카운터 피격 시 물리/마법 피해 감소 +20%<br>물리/마법 방어력 +7,000<br>모든 상태 이상 내성 +15%@@6414@@1968';

lv105ItemId['결전의 소검 - 소울브링어'] = '735db1f81960b02f623d4e4fe80ee749';
lv105OptionMap['결전의 소검 - 소울브링어'] = {};
lv105OptionMap['결전의 소검 - 소울브링어'] = '명계의 힘으로 흑염의 칼라가 명계의 불꽃을 발현<br>불꽃 최대 중첩 수 +2<br><br>아래 스킬들이 명계의 불꽃을 발현<br>명계의 불꽃이 붙은 대상을 특정 스킬로 공격 시 불꽃 폭발<br>- 폭발 공격력 :  흑염의 칼라 불꽃 공격력의 300%<br>- 불꽃 중첩당 폭발 공격력 10% 증가<br><br><명계의 불꽃 발현 스킬><br>- 흑염의 칼라<br>- 냉기의 사야<br>- 역병의 라사<br>- 툼스톤<br>- 툼스톤 스웜프<br>- 블레이드 팬텀<br><br><명계의 불꽃 폭발 스킬><br>- 귀참<br>- 달빛 베기<br>- 귀참 : 광폭<br>- 귀영섬<br>- 귀참 : 나락<br>- 흑염검<br>- 검귀강령 : 식<br>- 탐식의 크레바스@@5928@@2016';

lv105ItemId['결전의 둔기 - 드래곤나이트'] = '39c51d3039dd2ff45f07c958af8a17a6';
lv105OptionMap['결전의 둔기 - 드래곤나이트'] = {};
lv105OptionMap['결전의 둔기 - 드래곤나이트'] = '태도 용제무쌍을 최대 2회 충전 스킬로 변경<br>- 공격력 40% 감소<br>- 충전 쿨타임 : 10초<br><br>태도 용제무쌍 시전 시 10초 동안 스킬 공격력 5% 증가 (각성기 제외)<br><br>태도 용제무쌍 시전 중 아스트라 스킬 시전 시 남은 쿨타임 10% 감소<br>- 쿨타임 감소 효과는 용제무쌍 1회당 1번만 적용<br><br>아래의 스킬 쿨타임 10% 감소<br>- 파이어 브레스<br>- 아스트라 소환<br>- 드래곤 팽<br>- 폭풍의 숨결<br>- 비룡천상@@5928@@2016';

lv105ItemId['결전의 리볼버 - 메카닉(여)'] = '24f233af3580404a498565154e8bd54d';
lv105OptionMap['결전의 리볼버 - 메카닉(여)'] = {};
lv105OptionMap['결전의 리볼버 - 메카닉(여)'] = 'G-S.P. 팔콘 쿨타임 20% 감소<br><br>G-1 코로나 상태에서 롤링썬더 또는 랩터로 트랜스폼 시 8초 동안 홀로그램 G-1 코로나 생성<br>- 공격력 : G-1 코로나 공격력의 50%<br>- G 마그네틱 시전 시 홀로그램 G-1 코로나가 대신 시전<br><br>트랜스폼 : G-3 랩터가 강화 레이저 발사<br>- 강화 레이저 발사@@8300@@2016';

lv105ItemId['결전의 대검 - 드래곤나이트'] = '29616411b897148672537df5d4ef2c46';
lv105OptionMap['결전의 대검 - 드래곤나이트'] = {};
lv105OptionMap['결전의 대검 - 드래곤나이트'] = '태도 용제무쌍을 최대 2회 충전 스킬로 변경<br>- 공격력 40% 감소<br>- 충전 쿨타임 : 10초<br><br>태도 용제무쌍 시전 시 10초 동안 스킬 공격력 5% 증가 (각성기 제외)<br><br>태도 용제무쌍 시전 중 아스트라 스킬 시전 시 남은 쿨타임 10% 감소<br>- 쿨타임 감소 효과는 용제무쌍 1회당 1번만 적용<br><br>아래의 스킬 쿨타임 10% 감소<br>- 파이어 브레스<br>- 아스트라 소환<br>- 드래곤 팽<br>- 폭풍의 숨결<br>- 비룡천상@@5928@@2016';

lv105ItemId['옥화의 망령 요갑'] = '1de39f4a48b3524cf768479c8f4c7b19';
lv105OptionMap['옥화의 망령 요갑'] = {};
lv105OptionMap['옥화의 망령 요갑'] = '최종 데미지 12% 증가<br><br>"와"를 포함한 채팅 입력 시 40초 동안 로봇 prototype-0 소환 (쿨타임 40초)<br><br>1초마다 HP/MP 1,000 회복<br><br>이동 속도 +8%<br>물리/마법 방어력 +7,000@@6414@@1948';

lv105ItemId['결전의 광검 - 다크나이트'] = '157f98722489ea9d279e08e7fa47df9b';
lv105OptionMap['결전의 광검 - 다크나이트'] = {};
lv105OptionMap['결전의 광검 - 다크나이트'] = '타임 스톱 최대 콤보 스택 1단계 증가<br>- 콤보 스택 6단계 공격력 : 220%<br><br>시간에 간섭하여 타임로드 효과 강화<br>- 다른 콤보 슬롯 스킬 사용 시 타임 스톱 스택 획득<br>- 확장 콤보 슬롯 스킬 시전 시 기존 콤보 스킬 유지<br>- 타임 로드 효과 지속 시간 +2초@@10672@@2016';

lv105ItemId['결전의 너클 - 스트라이커(여)'] = '2ff8c928b81beb13537e9ff6693637a1';
lv105OptionMap['결전의 너클 - 스트라이커(여)'] = {};
lv105OptionMap['결전의 너클 - 스트라이커(여)'] = '라이트닝 댄스 이동 횟수가 4회 감소하고 1:1 공격 시 대상의 좌우를 이동하며 공격<br>- 공격력 15% 감소<br>- 공격 속도 +30%<br>- 이동 범위 5% 증가<br>- 공격 시 적에게 감전 상태 이상 부여 (쿨타임 15초)<br><br>라이트닝 댄스 공격 시 충격파 생성<br>- 직접 타격 대상은 충격파 피격 제외<br>- 1:1 공격일 경우 충격파 미생성@@9484@@2016';

lv105ItemId['광폭화된 전의의 단검'] = '5fe4e4dba6975588ce4d13de87d0f18f';
lv105OptionMap['광폭화된 전의의 단검'] = {};
lv105OptionMap['광폭화된 전의의 단검'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['뜨거운 열망의 증표'] = '363127da64902fd62e8c5fb9595a196e';
lv105OptionMap['뜨거운 열망의 증표'] = {};
lv105OptionMap['뜨거운 열망의 증표'] = '최종 데미지 12% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>회피율 +8%@@3988@@1948';

lv105ItemId['결전의 도 - 버서커'] = '40a62a63d927e2d2dfe206834730b004';
lv105OptionMap['결전의 도 - 버서커'] = {};
lv105OptionMap['결전의 도 - 버서커'] = '피에 굶주려 갈증 시전 시 HP 10%를 소모하고 혈기 3개 획득 (최대 10스택)<br>- 갈증 충전 기능 삭제<br>- 다른 스킬 시전 중 갈증 시전 가능<br>- 던전 입장 시 혈기 1개 획득<br><br>블러드 소드, 격노 시전 시 혈기 1개를 소모하여 강화<br>- 공격력 30% 증가<br>- 스킬 범위 15% 증가<br><br>블러드 소드, 격노 시전 시 HP 5% 회복@@9484@@2016';

lv105ItemId['성장하는 모험가의 리볼버'] = 'e7b2174a268978c6dcf32b4d9ed6ff97';
lv105OptionMap['성장하는 모험가의 리볼버'] = {};
lv105OptionMap['성장하는 모험가의 리볼버'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 창 - 배틀메이지'] = '468f8dd1e00ee18ab307acb1f673bb6d';
lv105OptionMap['결전의 창 - 배틀메이지'] = {};
lv105OptionMap['결전의 창 - 배틀메이지'] = '체이서 공격력 및 사출 개수 증가<br>- 한 번에 사출되는 체이서 개수 +2<br>- 공격력 45% 감소<br>- 50콤보 이상일 때 체이서 자동 사출<br>- 체이서 최대 생성 개수 +1<br>- 체이서 크기 비율 20% 증가<br>- 스킬 MP 소모량 -70%<br><br>한 번에 생성되는 체이서 개수 +1@@4744@@2016';

lv105ItemId['광폭화된 전의의 도'] = '11bd2af6fdaf1ddd2de93ba3df1b81bd';
lv105OptionMap['광폭화된 전의의 도'] = {};
lv105OptionMap['광폭화된 전의의 도'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['내딛은 자의 배틀액스'] = '69d49ab7c461be614f16693bbf40a908';
lv105OptionMap['내딛은 자의 배틀액스'] = {};
lv105OptionMap['내딛은 자의 배틀액스'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['얼어붙은 저항의 토템'] = '4346828ef37a59de03e23acfb22bd12f';
lv105OptionMap['얼어붙은 저항의 토템'] = {};
lv105OptionMap['얼어붙은 저항의 토템'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 봉 - 엘레멘탈마스터'] = 'c487e8cb65a34e592a552c1c1ee8fb8f';
lv105OptionMap['결전의 봉 - 엘레멘탈마스터'] = {};
lv105OptionMap['결전의 봉 - 엘레멘탈마스터'] = '상급 원소 스킬 및 일부 최상급 원소 스킬 1개를 룬에 저장 후 해방<br>- [장비 옵션 조작키] 입력 후 스킬 사용 시 60초 동안 룬에 저장<br>- [장비 옵션 조작키] 재입력 시 저장된 스킬 해방 (쿨타임 0.1초)<br>- 해방 스킬 공격력 : 원본 스킬의 15%<br>- 저장 스킬 쿨타임 : 원본 스킬의 15%<br>- 최대 충전 상태로 저장<br><br>메모라이즈 캐스팅 속도 상승률 +15%<br><br><저장 가능 스킬><br>- 썬더 콜링<br>- 아크틱 피스트<br>- 나이트 할로우<br>- 핼로윈 버스터<br>- 엘레멘탈 커튼<br>- 엘레멘탈 퀘이크<br>- 초월의 크리스탈@@11264@@2016';

lv105ItemId['결전의 핸드캐넌 - 런처(남)'] = '1a99827c2a0cff7e1471aaadc5ff7bd9';
lv105OptionMap['결전의 핸드캐넌 - 런처(남)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 런처(남)'] = '레이저 라이플을 최대 3회 충전 스킬로 변경<br>- 공격력 20% 증가<br>- 크기 40% 증가<br>- 충전 쿨타임 : 7초<br>- 충전 레이저 라이플 충전 시간 50% 감소<br>- 적중 시 적에게 10초 동안 전자기장 부여<br><br>FM-92 스팅어 강화<br>- 공격력 15% 증가<br>- 폭발 범위 25% 증가<br>- 전자기장이 부여된 적 존재 시 대상을 추적하는 강화 고폭탄 발사@@8892@@2016';

lv105ItemId['내딛은 자의 스태프'] = '73a28ec611b186727d40ebd3afb76ec7';
lv105OptionMap['내딛은 자의 스태프'] = {};
lv105OptionMap['내딛은 자의 스태프'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['사멸하는 신뢰의 토템'] = '0a5314a47ad830cf5ad27e8a72ec7824';
lv105OptionMap['사멸하는 신뢰의 토템'] = {};
lv105OptionMap['사멸하는 신뢰의 토템'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['운명이 이끄는 방향'] = '5fd5a123d59b3959ff6e427a68124e91';
lv105OptionMap['운명이 이끄는 방향'] = {};
lv105OptionMap['운명이 이끄는 방향'] = '최종 데미지 9% 증가<br>모든 속성 강화 +20<br><br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +10%<br>적중률 +10%@@6318@@1943';

lv105ItemId['결전의 소검 - 다크나이트'] = 'b8017b856700f06e157c70f2b631a655';
lv105OptionMap['결전의 소검 - 다크나이트'] = {};
lv105OptionMap['결전의 소검 - 다크나이트'] = '타임 스톱 최대 콤보 스택 1단계 증가<br>- 콤보 스택 6단계 공격력 : 220%<br><br>시간에 간섭하여 타임로드 효과 강화<br>- 다른 콤보 슬롯 스킬 사용 시 타임 스톱 스택 획득<br>- 확장 콤보 슬롯 스킬 시전 시 기존 콤보 스킬 유지<br>- 타임 로드 효과 지속 시간 +2초@@10672@@2016';

lv105ItemId['여명의 성배'] = '4f7aa31ae87f542fdc8570c3c1bd9077';
lv105OptionMap['여명의 성배'] = {};
lv105OptionMap['여명의 성배'] = '커맨드로 스킬 시전 시 입력한 방향키의 개수에 따라 최종 데미지 증가 (각성기 제외)<br>- 방향키 1개 : 최종 데미지 7% 증가<br>- 방향키 2개 : 최종 데미지 9% 증가<br>- 방향키 3개 : 최종 데미지 11% 증가<br>- 방향키 4개 : 최종 데미지 15% 증가<br><br>공격 속도 +15%<br>캐스팅 속도 +15%<br>이동 속도 +15%<br>적중률 +10%<br>회피율 +8%@@5066@@1928';

lv105ItemId['결전의 미늘창 - 뱅가드'] = 'd11a805f7e437d61f3f9a836ca21f99a';
lv105OptionMap['결전의 미늘창 - 뱅가드'] = {};
lv105OptionMap['결전의 미늘창 - 뱅가드'] = '스매쉬의 극한에 도달하여 임팩트 스매쉬 강화<br>- 임팩트 스매쉬를 캔슬하여 스킬 시전 시 해당 스킬 쿨타임 20% 감소 (각성기 제외)<br>- 스택 횟수 +1<br>- 적중 시 스택 회복 감소 시간 +0.3초<br>- 공격 속도 버프 추가량 +10%@@5928@@2016';

lv105ItemId['결전의 도 - 엘븐나이트'] = 'bc80f5597f9fb96abdb75d933e8fadc8';
lv105OptionMap['결전의 도 - 엘븐나이트'] = {};
lv105OptionMap['결전의 도 - 엘븐나이트'] = '체인러쉬 콤보 6중첩 상태에서 체인 스트라이크 시전 시 8초 동안 피버 타임<br>- 체인러쉬 공격력 500% 증가<br>- 체인 게이지의 모든 영역을 성공 영역으로 변경<br>- 1~30Lv 스킬 쿨타임 70% 감소<br>- 1~30Lv 스킬 공격력 50% 감소 (체인러쉬 제외)<br><br>체인러쉬 시전 시 1~30Lv 스킬 남은 쿨타임 10% 감소@@5928@@2016';

lv105ItemId['오염된 빙석 견갑'] = '0288215d10faa4a1f3797cc2dc7b7bc3';
lv105OptionMap['오염된 빙석 견갑'] = {};
lv105OptionMap['오염된 빙석 견갑'] = '최종 데미지 2% 증가<br>중독 피해 +20%<br><br>중독 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>자신에게 해제되지 않는 중독 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +10%<br>공격 속도 +5%<br>캐스팅 속도 +7.5%<br>HP 1분당 460.2 회복@@6682@@1968';

lv105ItemId['엑셀러레이터'] = '0e33a738601501eeb46ec7dbe539e84f';
lv105OptionMap['엑셀러레이터'] = {};
lv105OptionMap['엑셀러레이터'] = '최종 데미지 11% 증가<br>스킬 쿨타임 15% 감소 (각성기 제외)<br><br>공격 시 MP 3,500 회복 (쿨타임 1초)<br><br>MP MAX +4,196<br>MP 1분당 348 회복@@4917@@1943';

lv105ItemId['로보티카 컴뱃 팬츠'] = '0106839ef87aba76428ec574c78b167c';
lv105OptionMap['로보티카 컴뱃 팬츠'] = {};
lv105OptionMap['로보티카 컴뱃 팬츠'] = '모든 속성 강화 +18<br><br>장착한 장비의 강화, 증폭 수치의 총합 6마다 아래의 효과 적용 (최대 24중첩)<br>- 최종 데미지 0.4% 증가<br>- 적용 : 무기, 방어구, 악세서리, 특수장비<br>- 제외 : 휘장, 보조무기<br><br>해당 장비의 강화, 증폭 수치 1마다 모든 속도 +2% (최대 12중첩)<br><br>물리/마법 방어력 +7,000@@3988@@1948';

lv105ItemId['순환하는 자연의 섭리'] = '04e57598f0ca64f8fd687e528c8718d5';
lv105OptionMap['순환하는 자연의 섭리'] = {};
lv105OptionMap['순환하는 자연의 섭리'] = '공격력 증가 +133.4%<br>최종 데미지 2% 증가<br>암속성 강화 +20<br><br>[다크 포스]<br>공격 시 암속성 강화 수치에 따라 암속성 원소 공격 시전 (쿨타임 1.5초)<br>- 100 이상 150 미만 : 다크 익스플로전<br>- 150 이상 250 미만 : 다크 볼<br>- 250 이상 : 블랙홀<br>- 각각 총 공격력 증가의 720% / 920% / 1,480%만큼 피해<br>- 블랙홀은 적에게 10초 동안 암흑 상태 이상 부여 (쿨타임 30초)<br><br>암흑 지속 시간 +3초<br><br>공격 속도 +10%<br>캐스팅 속도 +15%<br>암속성 저항 +20@@6414@@1978';

lv105ItemId['생명이 담긴 잔'] = '3a2a4a8b1ab39b690ce28d875198e3b9';
lv105OptionMap['생명이 담긴 잔'] = {};
lv105OptionMap['생명이 담긴 잔'] = '최종 데미지 7% 증가<br>모든 속성 강화 +18<br><br>물리/마법 피해의 30%가 10초 동안 지속되는 피해로 전환<br><br>이동 속도 +8%@@5874@@1900';

lv105ItemId['결전의 너클 - 스트라이커(남)'] = '925b44e9984d42f3e8423c382f51d71b';
lv105OptionMap['결전의 너클 - 스트라이커(남)'] = {};
lv105OptionMap['결전의 너클 - 스트라이커(남)'] = '이중개방의 효과가 10초 동안 유지되는 맹렬한 화염으로 변경되며 최종 데미지 45% 증가 (각성기 제외)<br>- 이중개방 강화 공격력 효과 미적용<br><br>화염의 힘으로 머슬시프트 강화<br>- 캔슬 공격 시 이중개방 남은 쿨타임 2% 감소<br>- 캔슬 시 30초 동안 전직 계열 스킬 공격력 증가율 +2%<br>- 캔슬 횟수 +2@@7112@@2016';

lv105ItemId['기계 공학의 정수'] = 'bd078bcf3483881e156487c87f203cf8';
lv105OptionMap['기계 공학의 정수'] = {};
lv105OptionMap['기계 공학의 정수'] = '최종 데미지 13% 증가<br>火(화), 水(수), 明(명), 暗(암) 공격 속성<br><br>HP MAX +600<br>MP MAX +945@@4528@@1928';

lv105ItemId['불변의 부유석 벨트'] = '04840f0d826d355a48114c2abc02f4b8';
lv105OptionMap['불변의 부유석 벨트'] = {};
lv105OptionMap['불변의 부유석 벨트'] = '스킬 쿨타임 회복 속도 +25% (각성기 제외)<br>모든 속성 강화 +10<br><br>중독 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>자신에게 해제되지 않는 중독 상태 이상 부여<br><br>공격 시 적에게 10초 동안 중독 상태 이상 부여 (쿨타임 5초)<br><br>물리/마법 크리티컬 히트 +7%<br>모든 상태 이상 내성 -10%@@6318@@1925';

lv105ItemId['사멸하는 신뢰의 로드'] = '426aed8519e9d7432d793526a234b323';
lv105OptionMap['사멸하는 신뢰의 로드'] = {};
lv105OptionMap['사멸하는 신뢰의 로드'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['전술 레이더망 링'] = '713ad2ea1d4741fc6ec2244d3f04c498';
lv105OptionMap['전술 레이더망 링'] = {};
lv105OptionMap['전술 레이더망 링'] = '스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>[전술적 초기화]<br>스킬 시전 시 10% 확률로 스킬 1개의 쿨타임 초기화 (쿨타임 20초)<br>- 남은 쿨타임 20초 이내 스킬 대상<br>- 각성기 제외<br><br>MP 1분당 348 회복<br>MP MAX +945@@3450@@1890';

lv105ItemId['결전의 창 - 엘레멘탈마스터'] = '224bc1e5d0ee3b948ba4cbba96fc072d';
lv105OptionMap['결전의 창 - 엘레멘탈마스터'] = {};
lv105OptionMap['결전의 창 - 엘레멘탈마스터'] = '상급 원소 스킬 및 일부 최상급 원소 스킬 1개를 룬에 저장 후 해방<br>- [장비 옵션 조작키] 입력 후 스킬 사용 시 60초 동안 룬에 저장<br>- [장비 옵션 조작키] 재입력 시 저장된 스킬 해방 (쿨타임 0.1초)<br>- 해방 스킬 공격력 : 원본 스킬의 15%<br>- 저장 스킬 쿨타임 : 원본 스킬의 15%<br>- 최대 충전 상태로 저장<br><br>메모라이즈 캐스팅 속도 상승률 +15%<br><br><저장 가능 스킬><br>- 썬더 콜링<br>- 아크틱 피스트<br>- 나이트 할로우<br>- 핼로윈 버스터<br>- 엘레멘탈 커튼<br>- 엘레멘탈 퀘이크<br>- 초월의 크리스탈@@11264@@2016';

lv105ItemId['결전의 중검 - 스페셜리스트'] = '907f2b190f961b18baa18d3856a86f2f';
lv105OptionMap['결전의 중검 - 스페셜리스트'] = {};
lv105OptionMap['결전의 중검 - 스페셜리스트'] = '인텐션 스택 사용 시 인텐션 스택 1개를 추가 소모하여 5초 동안 스킬 쿨타임 20% 감소 (각성기 제외)<br><br>CEAB-2를 인텐션 스택으로 강화 가능<br>- 강화 시 최대 충전 공격력 40% 증가<br>- 강화 시 다단히트 수 60% 충전 상태로 발동<br><br>인텐션 기본 스택 수 +2@@7112@@2016';

lv105ItemId['결전의 십자가 - 무녀'] = 'dbb83e8054536635100ea97c936ab59a';
lv105OptionMap['결전의 십자가 - 무녀'] = {};
lv105OptionMap['결전의 십자가 - 무녀'] = '염주 사출, 목환자경 타격 1회당 신력 염주알 1개 획득 (최대 25개)<br><br>아래 스킬 시전 시 신력 염주알을 소모하여 강화<br>- 부동주박진 : 염주 회전 수 1회 증가 (8개 소모)<br>- 백팔염주 : 염주 사출 개수 10개 증가 (10개 소모)<br>- 마혼부 : 퇴마음양옥 : 튕기는 횟수 3회 증가 (10개 소모)<br>- 염폭모대주 : 염주알 낙하 수 1 증가 (15개 소모)<br><br>염주 사출, 목환자경 시전 1회당 백팔염주, 부동주박진 스킬 쿨타임 회복 속도 +1%<br>- 백팔염주, 부동주박진 시전 시 효과 초기화@@4148@@2016';

lv105ItemId['찰나를 스치는 흐름'] = 'd3b603c289e9e4dfcfe1b4da7ce9b4ab';
lv105OptionMap['찰나를 스치는 흐름'] = {};
lv105OptionMap['찰나를 스치는 흐름'] = '최종 데미지 19% 증가<br><br>스킬 시전 시 2초 동안 HP MAX 15% 수치의 보호막 부여 (쿨타임 0.5초)<br><br>이동 속도 +8%<br>회피율 +8%@@5604@@1948';

lv105ItemId['사멸하는 신뢰의 통파'] = '8b177f87e19dd77e07f5f718df036683';
lv105OptionMap['사멸하는 신뢰의 통파'] = {};
lv105OptionMap['사멸하는 신뢰의 통파'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['내딛은 자의 창'] = '3bb2cc2b8f1e91174a050cf7a74d6266';
lv105OptionMap['내딛은 자의 창'] = {};
lv105OptionMap['내딛은 자의 창'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['잿빛의 묘상석'] = '52c46a5e0c405db41e025cbd575f0866';
lv105OptionMap['잿빛의 묘상석'] = {};
lv105OptionMap['잿빛의 묘상석'] = '15~35Lv 스킬 공격력 25% 증가<br>15~35Lv 스킬 쿨타임 회복 속도 +10%<br><br>공격 시 30초 동안 슈퍼 아머 부여 (쿨타임 8초)<br><br>공격 속도 +8%<br>캐스팅 속도 +12%@@4676@@1933';

lv105ItemId['내딛은 자의 대검'] = 'a0f1b0185e2de0a730876a2e4b30efc4';
lv105OptionMap['내딛은 자의 대검'] = {};
lv105OptionMap['내딛은 자의 대검'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['기억으로 새겨진 별자리'] = '12cfb54a17c730a0a6cd82ceb8cb8774';
lv105OptionMap['기억으로 새겨진 별자리'] = {};
lv105OptionMap['기억으로 새겨진 별자리'] = '최종 데미지 16% 증가<br><br>회피율 +8%<br>MP 1분당 348 회복@@3450@@1948';

lv105ItemId['하늘에 휘날리는 깃털'] = '50f6813155b213d33ce36306c911b34f';
lv105OptionMap['하늘에 휘날리는 깃털'] = {};
lv105OptionMap['하늘에 휘날리는 깃털'] = '최종 데미지 11.3% 증가<br><br>[혈관 파열]<br>보스, 네임드, 챔피언 몬스터 공격 시 남은 HP 1% 감소<br>- 10, 50, 250, 1,250, 6,250회 공격 시 발동<br>- 버퍼 제외<br><br>공격 시 적에게 출혈 상태 이상 부여 (지속 10초 / 쿨타임 3초)<br><br>공격 속도 +15%<br>캐스팅 속도 +22.5%<br>이동 속도 +15%<br>출혈 내성 +21%@@3935@@1963';

lv105ItemId['멈추어진 지식'] = 'ff8dc0de507cda263fe533e95d30d29b';
lv105OptionMap['멈추어진 지식'] = {};
lv105OptionMap['멈추어진 지식'] = '최종 데미지 7% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +35%<br>캐스팅 속도 +35%<br>이동 속도 +35%<br>HP MAX +5%@@5336@@1938';

lv105ItemId['사멸하는 신뢰의 에테리얼 보우'] = '5efc1f60d661b3e50a031ce7dc4ab07a';
lv105OptionMap['사멸하는 신뢰의 에테리얼 보우'] = {};
lv105OptionMap['사멸하는 신뢰의 에테리얼 보우'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@711@@1916';

lv105ItemId['내딛은 자의 광검'] = '417e31d6a9cf9bd48d0cd42a6e4aadde';
lv105OptionMap['내딛은 자의 광검'] = {};
lv105OptionMap['내딛은 자의 광검'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['드래곤 헌터'] = '12498edcb8dbab9229c0024410b59fb2';
lv105OptionMap['드래곤 헌터'] = {};
lv105OptionMap['드래곤 헌터'] = '최종 데미지 11% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 속성 저항 +15@@5874@@1928';

lv105ItemId['결전의 빗자루 - 크리에이터'] = '576189d2e238c4753f28837461c46af2';
lv105OptionMap['결전의 빗자루 - 크리에이터'] = {};
lv105OptionMap['결전의 빗자루 - 크리에이터'] = '웜홀 시전 시 종말의 시계를 소환하여 격리된 시간 영역을 전개<br>영역은 5초 후 또는 스킬 키 추가 입력 시 붕괴되며 폭발<br>종말의 시계를 화염, 냉기, 바람 계열 스킬로 2히트 시 폭발 공격력 증가<br>- 폭발 데미지 : 웜홀 공격력 100%<br>- 2히트당 폭발 공격력 5% 증가 (최대 50%)<br><br>윈드 스톰 강화<br>- 끌어들이는 범위 30% 증가<br>- 게이지 소모량 35% 감소<br><br>던전에서 무기 장착 시 윈드 스톰 게이지 0부터 회복 시작@@8300@@2016';

lv105ItemId['머신 컨트롤러 리모트'] = '18b5de1ae3b5c7bb16b0ffd6191488e6';
lv105OptionMap['머신 컨트롤러 리모트'] = {};
lv105OptionMap['머신 컨트롤러 리모트'] = '최종 데미지 8% 증가<br>모든 속성 강화 +20<br><br>공격 시 MP 0.1% 회복 (쿨타임 0.1초)<br>적 처치 시 MP 10% 회복<br><br>스킬 MP 소모량 +50%<br><br>공격 속도 +8%<br>캐스팅 속도 +12%@@5510@@1963';

lv105ItemId['결전의 자동권총 - 어썰트'] = '2143340e4c8b7f7a02daa311e542ac1f';
lv105OptionMap['결전의 자동권총 - 어썰트'] = {};
lv105OptionMap['결전의 자동권총 - 어썰트'] = '부스트 스킬로 전직 스킬 캔슬 시 전력 1스택 획득 (최대 5스택)<br><br>아래 스킬 시전 시 모든 전력을 소모하고, 소모한 전력당 공격력 10% 증가<br>- 레이시온 크리트<br>- 부스트 : 다이너마이트 킥<br><br>부스트 : 다이너마이트 킥 적중 시 적을 1.5초 동안 홀딩<br>- 소모한 전력 1스택당 홀딩 시간 0.5초 증가@@9484@@2016';

lv105ItemId['천재 기술자의 두터운 보호부츠'] = '3458db2479c54055db35b1ac96d8c038';
lv105OptionMap['천재 기술자의 두터운 보호부츠'] = {};
lv105OptionMap['천재 기술자의 두터운 보호부츠'] = '공격력 증가 +326.0%<br>모든 속성 강화 +15<br><br>스킬 MP 소모량 옵션 수치 총합의 5%만큼 최종 데미지 증가 (최대 25%)<br><br>스킬 MP 소모량 +100%<br><br>이동 속도 +8%<br>MP MAX +945<br>물리/마법 피해 감소 +20%@@2910@@1968';

lv105ItemId['얼어붙은 저항의 너클'] = '749303a7afac95b807efc7f156776006';
lv105OptionMap['얼어붙은 저항의 너클'] = {};
lv105OptionMap['얼어붙은 저항의 너클'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['광폭화된 전의의 완드'] = '1e51692d272690bb2ff600fe2d2fc055';
lv105OptionMap['광폭화된 전의의 완드'] = {};
lv105OptionMap['광폭화된 전의의 완드'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['광폭화된 전의의 창'] = 'dd9c91d5fdef18b3a7d44c97165b27f3';
lv105OptionMap['광폭화된 전의의 창'] = {};
lv105OptionMap['광폭화된 전의의 창'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['얼어붙은 저항의 장창'] = '2990114431703591f3de76b8e2588a5d';
lv105OptionMap['얼어붙은 저항의 장창'] = {};
lv105OptionMap['얼어붙은 저항의 장창'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['상처를 동여맨 다짐'] = '1ffdd960fc9489800ae1248e3be38927';
lv105OptionMap['상처를 동여맨 다짐'] = {};
lv105OptionMap['상처를 동여맨 다짐'] = '최종 데미지 12% 증가<br>모든 속성 강화 +6<br><br>공격 속도 +6%<br>캐스팅 속도 +6%<br>이동 속도 +6%<br>HP MAX +600<br>MP MAX +945@@6952@@1900';

lv105ItemId['지상형 : 전술 차륜 드론'] = '55c669c5fb7a64523cd22273e1e1581a';
lv105OptionMap['지상형 : 전술 차륜 드론'] = {};
lv105OptionMap['지상형 : 전술 차륜 드론'] = '최종 데미지 13% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +10%<br>적중률 +10%@@5066@@1948';

lv105ItemId['성장하는 모험가의 너클'] = 'bbdd2abada69f82ed1347b63998fab3e';
lv105OptionMap['성장하는 모험가의 너클'] = {};
lv105OptionMap['성장하는 모험가의 너클'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 장도 - 트러블 슈터'] = '221006deed81b0bfff47a40d10f899c3';
lv105OptionMap['결전의 장도 - 트러블 슈터'] = {};
lv105OptionMap['결전의 장도 - 트러블 슈터'] = '번 투 슬래쉬 시전 시 3초 동안 전직 스킬 공격력 20% 증가 (각성기 제외)<br>- 후딜레이를 캔슬하여 다른 전직 스킬 사용 가능<br>- 다른 전직 스킬의 후딜레이를 캔슬하여 번 투 슬래쉬 사용 가능<br>- 추가 공격 입력 시간 3초로 증가<br>- 스킬 종료 후 쿨타임 적용<br><br>번 투 슬래쉬가 타격하지 않아도 폭발 발생<br>- 스킬 쿨타임 35% 감소<br>- 스킬 범위 10% 증가@@652@@2016';

lv105ItemId['결전의 로드 - 엘레멘탈마스터'] = '26a82288632c9a13f6a906bddf5d98b9';
lv105OptionMap['결전의 로드 - 엘레멘탈마스터'] = {};
lv105OptionMap['결전의 로드 - 엘레멘탈마스터'] = '상급 원소 스킬 및 일부 최상급 원소 스킬 1개를 룬에 저장 후 해방<br>- [장비 옵션 조작키] 입력 후 스킬 사용 시 60초 동안 룬에 저장<br>- [장비 옵션 조작키] 재입력 시 저장된 스킬 해방 (쿨타임 0.1초)<br>- 해방 스킬 공격력 : 원본 스킬의 15%<br>- 저장 스킬 쿨타임 : 원본 스킬의 15%<br>- 최대 충전 상태로 저장<br><br>메모라이즈 캐스팅 속도 상승률 +15%<br><br><저장 가능 스킬><br>- 썬더 콜링<br>- 아크틱 피스트<br>- 나이트 할로우<br>- 핼로윈 버스터<br>- 엘레멘탈 커튼<br>- 엘레멘탈 퀘이크<br>- 초월의 크리스탈@@11264@@2016';

lv105ItemId['성장하는 모험가의 흑요석 귀걸이'] = '2715a57f2f632c8c728ce1597fa7eafc';
lv105OptionMap['성장하는 모험가의 흑요석 귀걸이'] = {};
lv105OptionMap['성장하는 모험가의 흑요석 귀걸이'] = '최종 데미지 7% 증가@@5336@@1986';

lv105ItemId['파워 네비게이트 팩'] = '50818a08dc0a6f7c055fccb685617515';
lv105OptionMap['파워 네비게이트 팩'] = {};
lv105OptionMap['파워 네비게이트 팩'] = '공격력 증가 +385.3%<br>최종 데미지 9% 증가<br>스킬 쿨타임 15% 감소 (각성기 제외)<br><br>HP MAX 65% 수치의 [충전형 보호막] 부여<br><br>HP MAX 65% 제한<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>물리/마법 방어력 +14,000<br>모든 속성 저항 +8@@5066@@1968';

lv105ItemId['성장하는 모험가의 건틀릿'] = '047b973f8dcb11f086b05b69728321be';
lv105OptionMap['성장하는 모험가의 건틀릿'] = {};
lv105OptionMap['성장하는 모험가의 건틀릿'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 토템 - 인파이터'] = '000e812409655055955746677a1d2fcd';
lv105OptionMap['결전의 토템 - 인파이터'] = {};
lv105OptionMap['결전의 토템 - 인파이터'] = '정의 집행 : 레미디오스의 성좌의 링크 스킬이 빅뱅 펀치일 때, 신의 분노를 최대 3회 충전 스킬로 변경<br>- 연타 공격력 100% 증가<br>- 연타 횟수 9회 감소<br>- 피니쉬 타격 및 폭발 삭제<br>- 적을 추적하여 공격<br>- 충전 쿨타임 : 40초<br>- 추적 범위 : 780px<br>- MP 소모량 -30%<br>- 무색 큐브 조각 소모량 5개 감소<br>- 머신건 잽 시전 시 신의 분노 남은 쿨타임 12% 감소<br>- 신의 분노를 캔슬하여 전직 스킬 시전 가능<br>- 전직 스킬을 캔슬하여 신의 분노 시전 시 드라이아웃 스택을 소모하지 않음@@5928@@2016';

lv105ItemId['결전의 봉 - 빙결사'] = '1f74d0daff3085ab774780c5efb20a40';
lv105OptionMap['결전의 봉 - 빙결사'] = {};
lv105OptionMap['결전의 봉 - 빙결사'] = '아이스 크래프트 얼음창이 얼음 무기에 피격된 모든 적을 추적하여 꿰뚫음<br>- 얼음창 공격력 반영률 20% 추가 증가<br>- 얼음창 폭발 삭제<br>- 얼음창이 10% 확률로 적에게 2초 동안 빙결 상태 이상 부여<br><br>핀포인트 러쉬 시전 시 전방의 가장 강력한 적을 추적하여 주변의 적을 함께 공격<br>- 추적 범위 : 500px@@7112@@2016';

lv105ItemId['성장하는 모험가의 로드'] = 'ee332d1a4bde4105ec33851043b3877c';
lv105OptionMap['성장하는 모험가의 로드'] = {};
lv105OptionMap['성장하는 모험가의 로드'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['평화를 위한 투쟁'] = '5e082f686e62f05d6630303ddc92d955';
lv105OptionMap['평화를 위한 투쟁'] = {};
lv105OptionMap['평화를 위한 투쟁'] = '최종 데미지 12% 증가<br><br>공격 속도 +20%<br>캐스팅 속도 +30%<br>이동 속도 +20%<br>HP MAX +600<br>물리/마법 방어력 +7,000@@5604@@1920';

lv105ItemId['사멸하는 신뢰의 너클'] = '19d4b7857d2908823d798d051b3d9d82';
lv105OptionMap['사멸하는 신뢰의 너클'] = {};
lv105OptionMap['사멸하는 신뢰의 너클'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 로드 - 소환사'] = '51a12f046b61e2ba06dabc8c98b88aa6';
lv105OptionMap['결전의 로드 - 소환사'] = {};
lv105OptionMap['결전의 로드 - 소환사'] = '상급 정령들과 정령왕 에체베리아의 이클립스 하이브 추가 조작 패턴 공격력 20% 증가<br><br>이클립스 하이브 강화<br>- 추가 조작 패턴 캐스팅 모션 삭제 <br>- 데드 멀커 : 블랙스웜 홀딩 추가<br>- 글레어린 : 썬더콜링이 적을 끌어모음<br>- 아퀘리스 : 헵타 아이스 미사일이 지상 폭격<br>- 플레임 헐크 : 화염폭검술이 회오리를 생성하고 적을 끌어모음<br>- 에체베리아 : 초고밀도 레이저가 다수 레이저의 지상 폭격으로 변경@@8300@@2016';

lv105ItemId['디보티드 나이트 벨트'] = '144e7c14cac2b62a43f4ff4052400607';
lv105OptionMap['디보티드 나이트 벨트'] = {};
lv105OptionMap['디보티드 나이트 벨트'] = '최종 데미지 11% 증가<br>모든 속성 강화 +18<br><br>물리/마법 방어력 +7,000@@5066@@1928';

lv105ItemId['침식되는 이성'] = '2766b493412bd6d14191ee3471c3a8e3';
lv105OptionMap['침식되는 이성'] = {};
lv105OptionMap['침식되는 이성'] = '화속성 강화 +70<br><br>화속성 저항 +20@@3450@@1958';

lv105ItemId['결전의 광검 - 웨펀마스터'] = '09e0ada505d32faf730ba8af86fc083e';
lv105OptionMap['결전의 광검 - 웨펀마스터'] = {};
lv105OptionMap['결전의 광검 - 웨펀마스터'] = '초식을 발전시켜 이기어검술 스킬 강화<br>- 지속 시간 +20초<br>- 기본기 숙련에 영향받는 스킬 적중 시 지속 시간 +0.5초 (최대 지속 시간 초과 불가능)<br>- 꿰뚫는검 사출 개수 +1<br>- 꿰뚫는검 사출 회수 시간 감소<br><br>장착 해제 시 이기어검술 지속 시간 종료@@8892@@2016';

lv105ItemId['결전의 통파 - 스트라이커(남)'] = '6e42ba6ebf9822bd180816d9cf7212c6';
lv105OptionMap['결전의 통파 - 스트라이커(남)'] = {};
lv105OptionMap['결전의 통파 - 스트라이커(남)'] = '이중개방의 효과가 10초 동안 유지되는 맹렬한 화염으로 변경되며 최종 데미지 45% 증가 (각성기 제외)<br>- 이중개방 강화 공격력 효과 미적용<br><br>화염의 힘으로 머슬시프트 강화<br>- 캔슬 공격 시 이중개방 남은 쿨타임 2% 감소<br>- 캔슬 시 30초 동안 전직 계열 스킬 공격력 증가율 +2%<br>- 캔슬 횟수 +2@@7112@@2016';

lv105ItemId['결전의 둔기 - 소울브링어'] = '90e8048f28ebf193250959ba2a13fb42';
lv105OptionMap['결전의 둔기 - 소울브링어'] = {};
lv105OptionMap['결전의 둔기 - 소울브링어'] = '명계의 힘으로 흑염의 칼라가 명계의 불꽃을 발현<br>불꽃 최대 중첩 수 +2<br><br>아래 스킬들이 명계의 불꽃을 발현<br>명계의 불꽃이 붙은 대상을 특정 스킬로 공격 시 불꽃 폭발<br>- 폭발 공격력 :  흑염의 칼라 불꽃 공격력의 300%<br>- 불꽃 중첩당 폭발 공격력 10% 증가<br><br><명계의 불꽃 발현 스킬><br>- 흑염의 칼라<br>- 냉기의 사야<br>- 역병의 라사<br>- 툼스톤<br>- 툼스톤 스웜프<br>- 블레이드 팬텀<br><br><명계의 불꽃 폭발 스킬><br>- 귀참<br>- 달빛 베기<br>- 귀참 : 광폭<br>- 귀영섬<br>- 귀참 : 나락<br>- 흑염검<br>- 검귀강령 : 식<br>- 탐식의 크레바스@@5928@@2016';

lv105ItemId['데저트 테크놀로지 팬츠'] = '0f77f44b38735de095fd831f1355ae72';
lv105OptionMap['데저트 테크놀로지 팬츠'] = {};
lv105OptionMap['데저트 테크놀로지 팬츠'] = '화속성 강화 +60<br><br>공격 시 5초 동안 불씨 1개 생성 (쿨타임 0.1초)<br>- 불씨 5개마다 폭발 발생<br>- 불씨는 총 공격력 증가의 96%만큼 피해<br><br>화속성 저항 +35@@4528@@1928';

lv105ItemId['하이테크 전술지휘 아머'] = '1bfbafce29f0cd7176651f550db99488';
lv105OptionMap['하이테크 전술지휘 아머'] = {};
lv105OptionMap['하이테크 전술지휘 아머'] = '최종 데미지 9% 증가<br><br>[텔레포트]<br>[장비 옵션 조작키] 입력 시 텔레포트 (쿨타임 20초)<br>- 방향키 입력으로 방향 조절 가능<br>- 텔레포트 후 30초 동안 이동 속도 +30%<br><br>물리/마법 방어력 +7,000<br>회피율 +8%@@6952@@1968';

lv105ItemId['냉정한 지략가의 상의'] = '11565e1c31918e8923f4e28155fd460c';
lv105OptionMap['냉정한 지략가의 상의'] = {};
lv105OptionMap['냉정한 지략가의 상의'] = '최종 데미지 3% 증가<br>스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>적중률 +10%@@4796@@1890';

lv105ItemId['결전의 자동권총 - 런처(여)'] = '05745d68e9473e9d2c1e4159eac2a0fb';
lv105OptionMap['결전의 자동권총 - 런처(여)'] = {};
lv105OptionMap['결전의 자동권총 - 런처(여)'] = '화염 강타를 화염을 압축하여 발사하는 최대 3회 충전 스킬로 변경<br>- 공격력 : 총 공격력의 50%<br>- 충전 쿨타임 : 4초<br><br>M-3 화염방사기 시전 시 화염을 압축하여 발사<br><br>UHT-03 플레게톤 이동 속도 비율 +10%@@9484@@2016';

lv105ItemId['결전의 머스켓 - 레인저(여)'] = '2fec7c9a99d899e284bceab13ff01cbd';
lv105OptionMap['결전의 머스켓 - 레인저(여)'] = {};
lv105OptionMap['결전의 머스켓 - 레인저(여)'] = '이동사격 탄환을 파열시켜 더욱 넓은 범위 공격<br>- 공격력 30% 증가<br>- 발사 수 50% 증가<br>- 쿨타임 20% 감소<br>- 공격 범위 10% 증가<br><br>이동사격 중 아래의 효과 적용<br>- 스킬 시전 시 쿨타임 즉시 적용<br>- 공격 속도 +30%<br>- 이동 속도 +30%<br>- 던전 방 이동 시 스킬 유지@@7708@@2016';

lv105ItemId['광폭화된 전의의 배틀액스'] = 'ac9c61a97c420c6f1e47c5e86811c351';
lv105OptionMap['광폭화된 전의의 배틀액스'] = {};
lv105OptionMap['광폭화된 전의의 배틀액스'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['드래곤 패스파인더'] = '2221336bfa1aa8af207b82aec4ee3a40';
lv105OptionMap['드래곤 패스파인더'] = {};
lv105OptionMap['드래곤 패스파인더'] = '최종 데미지 17% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%<br>모든 속성 저항 +15@@5874@@1928';

lv105ItemId['광폭화된 전의의 보우건'] = '369b843da71e9f7338daa7fd295788b7';
lv105OptionMap['광폭화된 전의의 보우건'] = {};
lv105OptionMap['광폭화된 전의의 보우건'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['굳건한 믿음'] = '0df27e01e5151bc1d62185ff804a5bf8';
lv105OptionMap['굳건한 믿음'] = {};
lv105OptionMap['굳건한 믿음'] = '수속성 강화 +70<br><br>수속성 저항 +20@@3450@@1958';

lv105ItemId['결전의 낫 - 퇴마사'] = '1844fa3f66460e65dcb532740d53e661';
lv105OptionMap['결전의 낫 - 퇴마사'] = {};
lv105OptionMap['결전의 낫 - 퇴마사'] = '공의 식신 - 백호 스킬 공격력 20% 증가<br>난격의 매 타격이 충격파 생성<br>- 쿨타임 20% 감소<br>- 난격 횟수 100% 증가<br>- 막타 삭제<br>- 막타 공격력은 난격 공격력에 합산<br><br>광명의 챠크라 적용 중 무쌍격 스킬 공격력 20% 증가<br>무쌍격 범위 20% 증가<br><br>대시 중 무쌍격 시전 시 전방으로 돌진하며 시전<br>@@4148@@2016';

lv105ItemId['결전의 둔기 - 웨펀마스터'] = 'c74f3067c5168ecb28ab2c755cdb3171';
lv105OptionMap['결전의 둔기 - 웨펀마스터'] = {};
lv105OptionMap['결전의 둔기 - 웨펀마스터'] = '초식을 발전시켜 이기어검술 스킬 강화<br>- 지속 시간 +20초<br>- 기본기 숙련에 영향받는 스킬 적중 시 지속 시간 +0.5초 (최대 지속 시간 초과 불가능)<br>- 꿰뚫는검 사출 개수 +1<br>- 꿰뚫는검 사출 회수 시간 감소<br><br>장착 해제 시 이기어검술 지속 시간 종료@@8892@@2016';

lv105ItemId['얼어붙은 저항의 차크라 웨펀'] = '25049e5f6710ca788e9bfe80de96243d';
lv105OptionMap['얼어붙은 저항의 차크라 웨펀'] = {};
lv105OptionMap['얼어붙은 저항의 차크라 웨펀'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['광폭화된 전의의 로드'] = '0e78fea02eee9858b5a549d6cb154829';
lv105OptionMap['광폭화된 전의의 로드'] = {};
lv105OptionMap['광폭화된 전의의 로드'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 통파 - 그래플러(남)'] = '05d51461904c5ead623660f8ac219737';
lv105OptionMap['결전의 통파 - 그래플러(남)'] = {};
lv105OptionMap['결전의 통파 - 그래플러(남)'] = '롤링 스파이크를 메치기 시 지진이 발생하는 최대 2회 충전 스킬로 변경<br>- 지진 공격력 : 메치기 공격력의 50%<br>- 충전 쿨타임 : 12초<br>- 후딜레이를 캔슬하여 전직 스킬 시전 가능<br><br>아래의 스킬 잡기 실패 시 해당 스킬 쿨타임 5초로 감소 (쿨타임 10초)<br>- 브레이크 다운<br>- 스냅 샷<br>- 블로우 스트라이크(지상 사용)<br>- 롤링 스파이크<br>- 나의 공격리듬은!(지상 사용)<br>- 한놈만 팬다!!<br>- 흑진광풍@@5928@@2016';

lv105ItemId['근원을 삼킨 봉'] = 'd26f10ac26a500afb133bb586afe9f03';
lv105OptionMap['근원을 삼킨 봉'] = {};
lv105OptionMap['근원을 삼킨 봉'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['결전의 봉 - 마도학자'] = '6ad587f14b4f0aba5faaba218bdc2325';
lv105OptionMap['결전의 봉 - 마도학자'] = {};
lv105OptionMap['결전의 봉 - 마도학자'] = '롤리팝 크러쉬가 더욱 많은 사탕 인형 생성<br>- 최대 사탕 생성 개수 +5<br>- 피격개체당 사탕인형 생성 수 +2<br>- 생성된 사탕인형 1개당 롤리팝 크러쉬의 남은 쿨타임 3% 감소<br>- 사탕인형 공격력 20% 감소<br><br>폭발하지 않은 사탕 인형을 흡수하여 다음 롤리팝 크러쉬 때 생성 (최대 15개)<br>- 사탕당 흡수 1번 가능<br>- 추가 생성된 사탕 인형은 최대 사탕 생성 개수에 미포함@@8300@@2016';

lv105ItemId['사이버틱 스피드 부츠'] = '088092996a8d1deaa9ed991383553ebd';
lv105OptionMap['사이버틱 스피드 부츠'] = {};
lv105OptionMap['사이버틱 스피드 부츠'] = '최종 데미지 20% 증가<br>- 장비로 공격 속도가 140% 이상 증가 시 10% 추가 증가<br>- 방어구, 악세서리, 특수장비, 아바타, 엠블렘, 젬, 크리쳐, 칭호, 아티팩트 포함<br><br>HP 1분당 460.2 회복<br>MP 1분당 348 회복<br>스킬 MP 소모량 -50%@@2642@@1968';

lv105ItemId['광폭화된 전의의 통파'] = '5ac89d6b2850553b3b7b66632961cd35';
lv105OptionMap['광폭화된 전의의 통파'] = {};
lv105OptionMap['광폭화된 전의의 통파'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 핸드캐넌 - 스핏파이어(여)'] = 'fe6d5167ce12c67caec0c124d8275e8d';
lv105OptionMap['결전의 핸드캐넌 - 스핏파이어(여)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 스핏파이어(여)'] = 'G-14 파열류탄, G-35L 섬광류탄, G-18C 빙결류탄 스킬 강화<br>- 최대 장전 수 +2<br>- 점프 상태에서 시전 시 니트로 모터를 소모하지 않음<br><br><br>보유한 유탄을 소모하여 포토빌라이저 강화<br>- 소모한 유탄 1개당 공격력 2% 증가 (최대 30%)<br>- 스탠바이-레디 상태에서는 최대 강화 수치로 발사@@8300@@2016';

lv105ItemId['근원을 삼킨 대검'] = '6d090e525eae8f91c70edd6fc47b1da0';
lv105OptionMap['근원을 삼킨 대검'] = {};
lv105OptionMap['근원을 삼킨 대검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['내딛은 자의 십자가'] = '44d9a5f031cd9e7c2aebc5e505ce76f7';
lv105OptionMap['내딛은 자의 십자가'] = {};
lv105OptionMap['내딛은 자의 십자가'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['과거를 뒤로 한 전진'] = '0b956e1734d551c828d2b980ef8a615a';
lv105OptionMap['과거를 뒤로 한 전진'] = {};
lv105OptionMap['과거를 뒤로 한 전진'] = '공격력 증가 +370.5%<br>최종 데미지 16% 증가<br><br>[작은 거인의 발걸음]<br>석화 상태에도 굳지 않는 작은 거인이 되어 전진<br>- 석화 면역<br>- 슈퍼 아머 부여<br><br>공격 시 적에게 10초 동안 석화 상태 이상 부여 (쿨타임 30초)<br><br>물리/마법 크리티컬 히트 +7%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 상태 이상 내성 -10%@@6318@@1915';

lv105ItemId['결전의 리볼버 - 레인저(여)'] = '5fe372670cc3f3ebbfe00aedebde2426';
lv105OptionMap['결전의 리볼버 - 레인저(여)'] = {};
lv105OptionMap['결전의 리볼버 - 레인저(여)'] = '이동사격 탄환을 파열시켜 더욱 넓은 범위 공격<br>- 공격력 30% 증가<br>- 발사 수 50% 증가<br>- 쿨타임 20% 감소<br>- 공격 범위 10% 증가<br><br>이동사격 중 아래의 효과 적용<br>- 스킬 시전 시 쿨타임 즉시 적용<br>- 공격 속도 +30%<br>- 이동 속도 +30%<br>- 던전 방 이동 시 스킬 유지@@7708@@2016';

lv105ItemId['결전의 도 - 드래곤나이트'] = '72fb2882c258513c17fc80b515c3637e';
lv105OptionMap['결전의 도 - 드래곤나이트'] = {};
lv105OptionMap['결전의 도 - 드래곤나이트'] = '태도 용제무쌍을 최대 2회 충전 스킬로 변경<br>- 공격력 40% 감소<br>- 충전 쿨타임 : 10초<br><br>태도 용제무쌍 시전 시 10초 동안 스킬 공격력 5% 증가 (각성기 제외)<br><br>태도 용제무쌍 시전 중 아스트라 스킬 시전 시 남은 쿨타임 10% 감소<br>- 쿨타임 감소 효과는 용제무쌍 1회당 1번만 적용<br><br>아래의 스킬 쿨타임 10% 감소<br>- 파이어 브레스<br>- 아스트라 소환<br>- 드래곤 팽<br>- 폭풍의 숨결<br>- 비룡천상@@5928@@2016';

lv105ItemId['결전의 코어 블레이드 - 스페셜리스트'] = '4b049b5bffde050b54a26554f3d7eed0';
lv105OptionMap['결전의 코어 블레이드 - 스페셜리스트'] = {};
lv105OptionMap['결전의 코어 블레이드 - 스페셜리스트'] = '인텐션 스택 사용 시 인텐션 스택 1개를 추가 소모하여 5초 동안 스킬 쿨타임 20% 감소 (각성기 제외)<br><br>CEAB-2를 인텐션 스택으로 강화 가능<br>- 강화 시 최대 충전 공격력 40% 증가<br>- 강화 시 다단히트 수 60% 충전 상태로 발동<br><br>인텐션 기본 스택 수 +2@@7112@@2016';

lv105ItemId['근원을 삼킨 광검'] = '1666f7be2f22b01b4bbb559c7bc51c99';
lv105OptionMap['근원을 삼킨 광검'] = {};
lv105OptionMap['근원을 삼킨 광검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['결전의 미늘창 - 다크 랜서'] = '79fdc1d3a83bf8b12a010ac93debabc4';
lv105OptionMap['결전의 미늘창 - 다크 랜서'] = {};
lv105OptionMap['결전의 미늘창 - 다크 랜서'] = '잠식 상태의 적 1마리당 1초마다 흑뢰의 기운 10% 획득<br>- 잠식 상태의 적이 없을 경우 1초마다 5% 감소<br><br>보스 몬스터 공격 시 흑뢰의 기운 1% 획득<br><br>흑뢰의 기운 100% 달성 시 15초 동안 다크 스타 강화<br>- 흑뢰 창이 동시에 낙하<br>- 흑뢰 마장창 추가 낙하<br>- 흑뢰 마장창 공격력 : 다크 스타 다단 히트 공격력의 1,000%@@10076@@2016';

lv105ItemId['결전의 쌍검 - 사령술사'] = 'a5f481a2dd775c27dfd5b5afaa092493';
lv105OptionMap['결전의 쌍검 - 사령술사'] = {};
lv105OptionMap['결전의 쌍검 - 사령술사'] = '리빙데드를 최대 3회 충전 스킬로 변경<br><br>레디아 블랙옥스가 직접 폭발<br>- 공격력 : 리빙데드 총 공격력의 45%<br>- 충전 쿨타임 : 15초<br>- 적에게 5초 동안 공포의 낙인 생성<br>- 추가 조작 기능 삭제<br><br>공포의 낙인이 생성된 적을 아래의 스킬로 공격 시 해당 스킬 남은 쿨타임 10% 감소<br>- 망자의 집념<br>- 블랙 아라크네아<br>- 익스큐셔너 슬래쉬@@889@@2016';

lv105ItemId['광폭화된 전의의 투창'] = '02bd7d3ce763965e3270a9715144a0fb';
lv105OptionMap['광폭화된 전의의 투창'] = {};
lv105OptionMap['광폭화된 전의의 투창'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['소망을 전하는 편지'] = '4b49b55d52c244e5858170d64bd81251';
lv105OptionMap['소망을 전하는 편지'] = {};
lv105OptionMap['소망을 전하는 편지'] = '공격력 증가 +296.4%<br>최종 데미지 17% 증가<br><br>[섀도우 워커]<br>퀵 스탠딩 강화<br>- 무적 지속 시간 +1초<br>- 2초 동안 그림자 이동 가능<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%<br>수면 내성 -20%@@3988@@1948';

lv105ItemId['결전의 권투글러브 - 스트라이커(여)'] = '56115476ef3b4a33cee00054fa917ceb';
lv105OptionMap['결전의 권투글러브 - 스트라이커(여)'] = {};
lv105OptionMap['결전의 권투글러브 - 스트라이커(여)'] = '라이트닝 댄스 이동 횟수가 4회 감소하고 1:1 공격 시 대상의 좌우를 이동하며 공격<br>- 공격력 15% 감소<br>- 공격 속도 +30%<br>- 이동 범위 5% 증가<br>- 공격 시 적에게 감전 상태 이상 부여 (쿨타임 15초)<br><br>라이트닝 댄스 공격 시 충격파 생성<br>- 직접 타격 대상은 충격파 피격 제외<br>- 1:1 공격일 경우 충격파 미생성@@9484@@2016';

lv105ItemId['결전의 십자가 - 크루세이더(여)'] = '8620e5d2347bda29f8c92d61611c8034';
lv105OptionMap['결전의 십자가 - 크루세이더(여)'] = {};
lv105OptionMap['결전의 십자가 - 크루세이더(여)'] = '용맹의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>치유의 기도 스킬 범위 20% 증가<br><br>홀리 플래쉬가 적을 관통하여 300px 전진 후 폭발<br>- 공격력 100% 증가<br>- 스킬 범위 10% 증가<br><br>홀리 플래쉬 적중 시 그랜드 크로스 크래쉬, 리펜텐스 스매시의 남은 쿨타임 2% 감소<br><br>빛의 은총 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['결전의 코어 블레이드 - 히트맨'] = '6e8d003d4047dc03103ba3a14804bf58';
lv105OptionMap['결전의 코어 블레이드 - 히트맨'] = {};
lv105OptionMap['결전의 코어 블레이드 - 히트맨'] = '전직 스킬의 후딜레이를 캔슬하여 데드 식스를 시전 가능<br>후딜레이 캔슬 시 아래의 효과 적용<br>- 캔슬한 스킬 남은 쿨타임 20% 감소<br>- 데드 식스 남은 쿨타임 50% 감소<br>- 8초 동안 공격 속도 +7% (최대 3중첩)@@10076@@2016';

lv105ItemId['결전의 대검 - 팔라딘'] = '7576c4169f959c162739e7cf78772f70';
lv105OptionMap['결전의 대검 - 팔라딘'] = {};
lv105OptionMap['결전의 대검 - 팔라딘'] = '세라픽 페더를 소모하여 이스트레인지먼트 시전 시 추가 효과 발동<br>- 세라픽 페더 1장당 공격력 4% 증가<br>- 세라픽 페더 1장당 스킬 범위 3% 증가<br>- 적에게 기절 상태 이상 부여<br><br>세라픽 페더를 소모하여 위핑 웨이브 시전 시 추가 효과 발동<br>- 끌어들인 적을 2초 동안 홀딩<br>- 몰이 효과 범위 6단계 적용<br>- 몰이 효과 범위 20% 증가<br>- 세라픽 페더 최대 2개만 소모<br><br>위핑 웨이브 강화<br>- 스킬 범위 20% 증가<br>- 타격 성공 시 세라픽 페더 습득량 +2<br>- 도발시킨 적 앞에서 방어 상태 유지 시 세라픽 페더 획득 시간 50% 감소@@9484@@2016';

lv105ItemId['결전의 낫 - 크루세이더(여)'] = '4aa4baff4d565aed0461738bcc9d05d3';
lv105OptionMap['결전의 낫 - 크루세이더(여)'] = {};
lv105OptionMap['결전의 낫 - 크루세이더(여)'] = '용맹의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>치유의 기도 스킬 범위 20% 증가<br><br>홀리 플래쉬가 적을 관통하여 300px 전진 후 폭발<br>- 공격력 100% 증가<br>- 스킬 범위 10% 증가<br><br>홀리 플래쉬 적중 시 그랜드 크로스 크래쉬, 리펜텐스 스매시의 남은 쿨타임 2% 감소<br><br>빛의 은총 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['사멸하는 신뢰의 머스켓'] = 'a9b24a528488e6e347ce8fde7cee307e';
lv105OptionMap['사멸하는 신뢰의 머스켓'] = {};
lv105OptionMap['사멸하는 신뢰의 머스켓'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['사멸하는 신뢰의 낫'] = '22e326fbbe477d98abf5417ecd651e42';
lv105OptionMap['사멸하는 신뢰의 낫'] = {};
lv105OptionMap['사멸하는 신뢰의 낫'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['자정의 성역'] = '863442932f7ba5fa35412794d34cb184';
lv105OptionMap['자정의 성역'] = {};
lv105OptionMap['자정의 성역'] = '최종 데미지 10% 증가<br><br>스킬 쿨타임 회복 속도 +12% (각성기 제외)<br>- "구속된 자유" 장비 장착 필요<br><br>공격 속도 +10%<br>캐스팅 속도 +15%<br>물리/마법 방어력 +7,000<br>적중률 +10%@@5066@@1928';

lv105ItemId['내딛은 자의 핸드캐넌'] = '70d6abf6827fb77bb5319382b0a834c5';
lv105OptionMap['내딛은 자의 핸드캐넌'] = {};
lv105OptionMap['내딛은 자의 핸드캐넌'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['사멸하는 신뢰의 소태도'] = '02b8fe0f1731d5d63c29308d8c54a638';
lv105OptionMap['사멸하는 신뢰의 소태도'] = {};
lv105OptionMap['사멸하는 신뢰의 소태도'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['얼어붙은 저항의 장도'] = '6e3da1f981efe4d2484e45cc83886220';
lv105OptionMap['얼어붙은 저항의 장도'] = {};
lv105OptionMap['얼어붙은 저항의 장도'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['옥화의 망령 대퇴갑'] = '24db159d4b8a9470b1ef591e926c7f87';
lv105OptionMap['옥화의 망령 대퇴갑'] = {};
lv105OptionMap['옥화의 망령 대퇴갑'] = '[패션 스타]<br>인벤토리에 아바타 120개 이상 보유 시 스포트라이트 발생<br>- 최종 데미지 6% 증가<br>- 스킬 쿨타임 8% 감소 (각성기 제외)<br>- 슈퍼 아머 부여<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%@@5066@@1948';

lv105ItemId['성장하는 모험가의 통파'] = '62d8a50e0066c4091055a8889fe29276';
lv105OptionMap['성장하는 모험가의 통파'] = {};
lv105OptionMap['성장하는 모험가의 통파'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['사멸하는 신뢰의 장도'] = 'b41585776eab8404a00273e8ff0bdcef';
lv105OptionMap['사멸하는 신뢰의 장도'] = {};
lv105OptionMap['사멸하는 신뢰의 장도'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['HEM 리인포스 부츠'] = '012a1830b9d48b6342ac1d0db83a564e';
lv105OptionMap['HEM 리인포스 부츠'] = {};
lv105OptionMap['HEM 리인포스 부츠'] = '최종 데미지 16% 증가<br>모든 속성 강화 +20<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 화상 상태 이상 부여 (쿨타임 10초)@@6414@@1968';

lv105ItemId['사멸하는 신뢰의 권투글러브'] = '35ac5b148df41da5a7872fe1065bb1aa';
lv105OptionMap['사멸하는 신뢰의 권투글러브'] = {};
lv105OptionMap['사멸하는 신뢰의 권투글러브'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 창 - 블러드 메이지'] = '6a6933f394d6a53e94d2799be63ee4c3';
lv105OptionMap['결전의 창 - 블러드 메이지'] = {};
lv105OptionMap['결전의 창 - 블러드 메이지'] = '커럽션 공격 시 블러드비스 2개 획득 (최대 5개)<br>- 쉬카리, 토먼트 시전 시 블러드비스 1개를 소모하여 각각 페이탈 쉬카리, 블러드먼트로 강화<br>- 던전 입장 시 블러드비스 1개 획득<br><br>페이탈 쉬카리<br>- 쉬카리 공격력 50% 증가<br>- 1히트 스킬로 변경<br>- 500px 범위 내 모든 적 공격<br><br>블러드먼트<br>- 토먼트 공격력 50% 증가<br>- 시전 후 이동 가능<br><br>커럽션 쿨타임 10% 감소, 시전 속도 30% 증가@@8892@@2016';

lv105ItemId['결전의 둔기 - 카오스'] = '1b4f1ff4b4e42c8af24c799d2fd7146c';
lv105OptionMap['결전의 둔기 - 카오스'] = {};
lv105OptionMap['결전의 둔기 - 카오스'] = '제노사이드 크러쉬 시전 시 데몬들을 폭발시키는 대신 아텐의 화신이 데몬들의 환영을 소환하고 폭발시키도록 변경<br>- 공격력 10% 증가<br>- 스킬 범위 20% 증가<br>- 시전 중 미드나잇 카니발 시전 가능<br>- 데몬이 없을 경우 즉시 모든 데몬 소환@@10076@@2016';

lv105ItemId['결전의 대검 - 아수라'] = '854c267305cba5c5c4f4ac5239f0f398';
lv105OptionMap['결전의 대검 - 아수라'] = {};
lv105OptionMap['결전의 대검 - 아수라'] = '파동검 폭염이 빠르게 폭발<br>- 폭발 위치 변경<br>- 폭발 간격 시간 30% 감소<br>- 폭발 간격 거리 90% 감소<br>- 폭발 크기 45% 증가<br>- 3타 폭발 크기 70% 증가<br><br>뇌신의 힘을 받아 아래의 스킬 시전 중 작열 파동진을 모션 없이 시전 가능<br>- 파동검 폭염<br>- 부동명왕진<br>- 파동검 : 인다라망<br><br>살의의 파동 파동 영역 20% 증가<br><br>작열 파동진 스킬 범위 25% 증가@@10672@@2016';

lv105ItemId['근원을 삼킨 배틀액스'] = '2796412cca82ab643b6d4a8f2d3a5568';
lv105OptionMap['근원을 삼킨 배틀액스'] = {};
lv105OptionMap['근원을 삼킨 배틀액스'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['내딛은 자의 너클'] = '50f05690de35cf411cc5f65d2263bd24';
lv105OptionMap['내딛은 자의 너클'] = {};
lv105OptionMap['내딛은 자의 너클'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['광폭화된 전의의 에테리얼 보우'] = '6c2008961e368d6e62d63b1d5f43511d';
lv105OptionMap['광폭화된 전의의 에테리얼 보우'] = {};
lv105OptionMap['광폭화된 전의의 에테리얼 보우'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@711@@1916';

lv105ItemId['음율에 담은 소망'] = '0992070f0ba429eed7b48d50bcaa46a2';
lv105OptionMap['음율에 담은 소망'] = {};
lv105OptionMap['음율에 담은 소망'] = '최종 데미지 9% 증가<br>스킬 쿨타임 10% 감소 (각성기 제외)<br><br>HP MAX +600<br>물리/마법 방어력 +7,000<br>수면 내성 -10%@@6414@@1958';

lv105ItemId['경계를 넘어선 차원'] = '00f58a48b4f8c5ba9073796c8e4308f8';
lv105OptionMap['경계를 넘어선 차원'] = {};
lv105OptionMap['경계를 넘어선 차원'] = '최종 데미지 13% 증가<br>모든 속성 강화 +10<br><br>스킬 범위 옵션 수치의 총합이 23% 이상일 때 모든 TP 스킬 Lv +1<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 속성 저항 +8@@6682@@1920';

lv105ItemId['원터치 스마트 리모콘'] = '001b114bde67db7c3f0dc31c69df6119';
lv105OptionMap['원터치 스마트 리모콘'] = {};
lv105OptionMap['원터치 스마트 리모콘'] = '최종 데미지 10% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +20%@@4796@@1938';

lv105ItemId['결전의 빗자루 - 마도학자'] = '34ae751ee9ea1311c57b8a81d3ae2830';
lv105OptionMap['결전의 빗자루 - 마도학자'] = {};
lv105OptionMap['결전의 빗자루 - 마도학자'] = '롤리팝 크러쉬가 더욱 많은 사탕 인형 생성<br>- 최대 사탕 생성 개수 +5<br>- 피격개체당 사탕인형 생성 수 +2<br>- 생성된 사탕인형 1개당 롤리팝 크러쉬의 남은 쿨타임 3% 감소<br>- 사탕인형 공격력 20% 감소<br><br>폭발하지 않은 사탕 인형을 흡수하여 다음 롤리팝 크러쉬 때 생성 (최대 15개)<br>- 사탕당 흡수 1번 가능<br>- 추가 생성된 사탕 인형은 최대 사탕 생성 개수에 미포함@@8300@@2016';

lv105ItemId['성장하는 모험가의 투창'] = '69f511056a27932a43f2e8066fedd9c7';
lv105OptionMap['성장하는 모험가의 투창'] = {};
lv105OptionMap['성장하는 모험가의 투창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['파괴된 신념'] = '05c1ea0f0055f45f990ca4a3c8eeacd4';
lv105OptionMap['파괴된 신념'] = {};
lv105OptionMap['파괴된 신념'] = '최종 데미지 12% 증가<br><br>출혈 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 출혈 상태 이상 부여 (쿨타임 10초)<br><br>자신에게 해제되지 않는 출혈 상태 이상 부여<br><br>HP MAX +600@@5874@@1948';

lv105ItemId['엔데카 코어 칩'] = '193e16cf78fb893d6d1942e9c2b53f93';
lv105OptionMap['엔데카 코어 칩'] = {};
lv105OptionMap['엔데카 코어 칩'] = '최종 데미지 12% 증가<br><br>[스톤 스트라이크]<br>석화 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>[메두사의 눈]<br>석화 상태의 적이 받는 데미지가 누적되며 석화 종료 시 누적된 데미지 폭발<br><br>적 처치 시 HP 5% 회복<br><br>공격 속도 +8%<br>캐스팅 속도 +12%@@2560@@1900';

lv105ItemId['광폭화된 전의의 너클'] = '934afea1d49e946955ceec90b311528e';
lv105OptionMap['광폭화된 전의의 너클'] = {};
lv105OptionMap['광폭화된 전의의 너클'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 배틀액스 - 어벤저'] = '1e8e2fed72bf5230d2912a18a94d4f39';
lv105OptionMap['결전의 배틀액스 - 어벤저'] = {};
lv105OptionMap['결전의 배틀액스 - 어벤저'] = '악마화 상태에서 악마 게이지 30 이상일 때 악마 게이지 소모 스킬 공격 시 40을 추가 소모하여 데빌 클로 발동 (각성기 제외)<br>- 데빌 스트라이커 공격력의 2,000%<br><br>악마화 평타 공격 및 스킬의 악마 게이지 회복량 10% 증가<br>고통의 희열 악마 게이지 회복량 10% 증가<br><br>악마화 공격 속도 증가량 20% 추가 증가, 이동 속도 증가량 10% 추가 증가@@7112@@2016';

lv105ItemId['전령에 깃든 축복'] = '0a4894b6738f02981163c797415e0c2f';
lv105OptionMap['전령에 깃든 축복'] = {};
lv105OptionMap['전령에 깃든 축복'] = '최종 데미지 18% 증가 <br><br>물리/마법 방어력 +43,000<br>공격 속도 +8%<br>캐스팅 속도 +12%@@5874@@1928';

lv105ItemId['결전의 스태프 - 빙결사'] = '29596e90c345114b1780480718cd1650';
lv105OptionMap['결전의 스태프 - 빙결사'] = {};
lv105OptionMap['결전의 스태프 - 빙결사'] = '아이스 크래프트 얼음창이 얼음 무기에 피격된 모든 적을 추적하여 꿰뚫음<br>- 얼음창 공격력 반영률 20% 추가 증가<br>- 얼음창 폭발 삭제<br>- 얼음창이 10% 확률로 적에게 2초 동안 빙결 상태 이상 부여<br><br>핀포인트 러쉬 시전 시 전방의 가장 강력한 적을 추적하여 주변의 적을 함께 공격<br>- 추적 범위 : 500px@@7112@@2016';

lv105ItemId['검은 발자국'] = '0600576a470adb7226ea5e00e964d806';
lv105OptionMap['검은 발자국'] = {};
lv105OptionMap['검은 발자국'] = '15~30Lv 스킬 공격력 18% 증가<br>기본기 숙련 최종 데미지 52% 증가<br><br>공격 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +600<br>적중률 +10%@@5066@@1968';

lv105ItemId['광폭화된 전의의 봉'] = '5b1a6ef0c4c2fb25f32b7ded51ec490d';
lv105OptionMap['광폭화된 전의의 봉'] = {};
lv105OptionMap['광폭화된 전의의 봉'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['천재 기술자의 멀티박스 팬츠'] = '3a90df330ecc42b3aa3ceca52c5b94cd';
lv105OptionMap['천재 기술자의 멀티박스 팬츠'] = {};
lv105OptionMap['천재 기술자의 멀티박스 팬츠'] = '최종 데미지 8% 증가<br><br>대기, 걷기 시 카모플라쥬 상태가 되어 회피율 +45% (쿨타임 5초)<br>- 대시, 피격 시 버프 해제<br><br>이동 속도 +8%<br>회피율 +8%@@4796@@1928';

lv105ItemId['성장하는 모험가의 빗자루'] = '9cb896df60d0ad702f53a27d6b034231';
lv105OptionMap['성장하는 모험가의 빗자루'] = {};
lv105OptionMap['성장하는 모험가의 빗자루'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['레드 라이프 링'] = 'b3db860f95b59d021023cc82d70ec77a';
lv105OptionMap['레드 라이프 링'] = {};
lv105OptionMap['레드 라이프 링'] = '공격력 증가 +222.3%<br>최종 데미지 7.1% 증가<br>모든 속성 강화 +10<br><br>공격 시 적에게 15초 동안 화상 상태 이상 부여 (쿨타임 10초)<br><br>화상 지속 시간 -10%<br><br>화상 내성 +1%@@5133@@1968';

lv105ItemId['소망을 향한 움직임'] = '0db7afd06a14268eb3ebeee3423ea30c';
lv105OptionMap['소망을 향한 움직임'] = {};
lv105OptionMap['소망을 향한 움직임'] = '최종 데미지 16% 증가<br>모든 속성 강화 +20<br><br>출혈/중독/화상/감전 지속 시간 -20%<br><br>물리/마법 피해 감소 +10%<br>스킬 MP 소모량 -7%@@7126@@1963';

lv105ItemId['결전의 자동권총 - 레인저(남)'] = '811a860dc0599b4408132207a0aaa601';
lv105OptionMap['결전의 자동권총 - 레인저(남)'] = {};
lv105OptionMap['결전의 자동권총 - 레인저(남)'] = '더블 건호크 강화<br>- 세 번째 건호크 잡기 성공 시 두 번째 건호크 투척 이후 사용한 스킬들의 남은 쿨타임 20% 감소<br>- 크기 10% 증가<br>- 시전 시 4초 동안 공격 속도 +30%<br><br>스타일리쉬 캔슬 가능 최대 횟수 +1<br><br>장착 시 스펙트럼 생성@@6520@@2016';

lv105ItemId['결전의 염주 - 인파이터'] = '1c209be775ef3c1269e0e94ecc49f963';
lv105OptionMap['결전의 염주 - 인파이터'] = {};
lv105OptionMap['결전의 염주 - 인파이터'] = '정의 집행 : 레미디오스의 성좌의 링크 스킬이 빅뱅 펀치일 때, 신의 분노를 최대 3회 충전 스킬로 변경<br>- 연타 공격력 100% 증가<br>- 연타 횟수 9회 감소<br>- 피니쉬 타격 및 폭발 삭제<br>- 적을 추적하여 공격<br>- 충전 쿨타임 : 40초<br>- 추적 범위 : 780px<br>- MP 소모량 -30%<br>- 무색 큐브 조각 소모량 5개 감소<br>- 머신건 잽 시전 시 신의 분노 남은 쿨타임 12% 감소<br>- 신의 분노를 캔슬하여 전직 스킬 시전 가능<br>- 전직 스킬을 캔슬하여 신의 분노 시전 시 드라이아웃 스택을 소모하지 않음@@5928@@2016';

lv105ItemId['썬더 레지스트 벨트'] = '0690d318a85803d80cc38993a9de0351';
lv105OptionMap['썬더 레지스트 벨트'] = {};
lv105OptionMap['썬더 레지스트 벨트'] = '최종 데미지 10% 증가<br>모든 속성 강화 +20<br><br>감전 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)<br><br>명속성 저항 +10@@6414@@1958';

lv105ItemId['정의의 기사 가면'] = '0a25a53d462bafd167baee17ffd8c187';
lv105OptionMap['정의의 기사 가면'] = {};
lv105OptionMap['정의의 기사 가면'] = '스킬 쿨타임 12% 감소 (각성기 제외)<br><br>점프 상태에서 점프 키를 0.5초 동안 입력 시 5초 동안 비행 (쿨타임 30초)<br>- 방향키 연속 입력 시 공중 대시 (최대 2회)<br><br>슈퍼 아머 부여<br><br>이동 속도 +12%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20<br>물리/마법 피해 감소 +15%@@5336@@1938';

lv105ItemId['얼어붙은 저항의 광검'] = 'e2028378a2f0a0a9e80ced44de270294';
lv105OptionMap['얼어붙은 저항의 광검'] = {};
lv105OptionMap['얼어붙은 저항의 광검'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['내딛은 자의 미늘창'] = '13feaea7e8625db8baca42f5cbf3816d';
lv105OptionMap['내딛은 자의 미늘창'] = {};
lv105OptionMap['내딛은 자의 미늘창'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['하늘을 수호하는 윙 부츠'] = '039682c96bb1e461e20f0669fde8ef91';
lv105OptionMap['하늘을 수호하는 윙 부츠'] = {};
lv105OptionMap['하늘을 수호하는 윙 부츠'] = '최종 데미지 17% 증가<br><br>공중에서 피격 시 [경매장 키] 입력을 통해 체공 자세 회복 (쿨타임 10초)<br><br>물리/마법 크리티컬 히트 +5%<br>이동 속도 +8%<br>물리/마법 방어력 +20%@@5604@@1920';

lv105ItemId['청명한 아침의 새싹'] = 'a7bd1a1ffcc468e619147cecdd3687e7';
lv105OptionMap['청명한 아침의 새싹'] = {};
lv105OptionMap['청명한 아침의 새싹'] = '암속성 강화 +70<br><br>물리/마법 크리티컬 히트 +15%<br>암속성 저항 +10@@4528@@1958';

lv105ItemId['결전의 둔기 - 다크나이트'] = '458550af432dd0956885cd5afd271568';
lv105OptionMap['결전의 둔기 - 다크나이트'] = {};
lv105OptionMap['결전의 둔기 - 다크나이트'] = '타임 스톱 최대 콤보 스택 1단계 증가<br>- 콤보 스택 6단계 공격력 : 220%<br><br>시간에 간섭하여 타임로드 효과 강화<br>- 다른 콤보 슬롯 스킬 사용 시 타임 스톱 스택 획득<br>- 확장 콤보 슬롯 스킬 시전 시 기존 콤보 스킬 유지<br>- 타임 로드 효과 지속 시간 +2초@@10672@@2016';

lv105ItemId['리플레이서'] = '2dc6d6233b142a632d4808a452f4c636';
lv105OptionMap['리플레이서'] = {};
lv105OptionMap['리플레이서'] = '최종 데미지 12.5% 증가<br>스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>스킬 MP 소모량 +300%<br><br>물리/마법 크리티컬 히트 +10%<br>MP MAX +945<br>MP 1분당 348 회복@@3988@@1948';

lv105ItemId['언비튼 메달'] = '9f30cf8ea43c6090797cb210d0d7723c';
lv105OptionMap['언비튼 메달'] = {};
lv105OptionMap['언비튼 메달'] = '최종 데미지 10% 증가<br><br>[속성 친화 - 화]<br>화속성 저항이 높아질수록 물리/마법 피해 감소<br>- 적의 공격에 화속성 부여<br><br>피격 시 5초 동안 HP 5% 회복 (쿨타임 7초)<br><br>HP MAX +600<br>화속성 저항 +35@@5604@@1948';

lv105ItemId['얼어붙은 저항의 대검'] = '110c9b5864d309ad2f7a974fc1fae73c';
lv105OptionMap['얼어붙은 저항의 대검'] = {};
lv105OptionMap['얼어붙은 저항의 대검'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 대검 - 다크템플러'] = '0dca697fc78d15efb39f705c449710d1';
lv105OptionMap['결전의 대검 - 다크템플러'] = {};
lv105OptionMap['결전의 대검 - 다크템플러'] = '이보브 영혼 최대 흡수 개수 +30<br><br>아래 스킬의 추가 영혼 흡수 개수 +5<br>- 리버레이트 - 매드니스<br>- 디스트로이어<br>- 라케르쏜즈<br><br>리버레이트 - 벅샷 발사하는 에너지 1개당 소모되는 영혼의 수 +2<br>- 타겟을 잡는 최대 거리 20% 증가<br><br>리버레이트 - 벅샷으로 소모한 영혼의 수 2개마다 아래 스킬의 남은 쿨타임 1% 감소 (베스퍼스 효과 미적용)<br>- 디스트로이어<br>- 데우스 엑스 레이<br>- 지하드<br>- 라케르쏜즈@@5336@@2016';

lv105ItemId['승리가 약속된 시간'] = '2ff5eb9a81962b2904314e9cdf0dd191';
lv105OptionMap['승리가 약속된 시간'] = {};
lv105OptionMap['승리가 약속된 시간'] = '최종 데미지 13% 증가<br><br>슈퍼 아머 부여<br><br>MP MAX +945<br>이동 속도 +8%@@5604@@1920';

lv105ItemId['결전의 봉 - 인챈트리스'] = '7e1129a75bb19f45f5a6dd0fd33b2f6d';
lv105OptionMap['결전의 봉 - 인챈트리스'] = {};
lv105OptionMap['결전의 봉 - 인챈트리스'] = '금단의 저주 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>꼼꼼한 바느질 스킬 범위 20% 증가<br><br>테러블 로어 캐스팅 모션 삭제<br>테러블 로어 몹몰이 범위 10% 증가<br><br>커스 오브 테러베어의 브레스에 피격된 적에게 저주의 기운을 부여하고 폭발<br>- 폭발 피해 : 브레스 총 공격력의 50%<br><br>어둠에 피는 장미의 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['얼어붙은 저항의 봉'] = '1f3fccd21542e2807bb4ffcb2376b89e';
lv105OptionMap['얼어붙은 저항의 봉'] = {};
lv105OptionMap['얼어붙은 저항의 봉'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 핸드캐넌 - 메카닉(남)'] = '231c0aee1dc71de63569c7ef4e54a17c';
lv105OptionMap['결전의 핸드캐넌 - 메카닉(남)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 메카닉(남)'] = '로봇 전폭으로 로봇 폭발 시 R-러너 생성<br>-  R-러너 공격력 : RX-78 랜드러너 공격력의 50%<br>- 로봇 전폭의 폭발 데미지 변화율 효과 적용<br>- 최대 10개 생성<br><br><R-러너 생성 가능 스킬><br>- RX-78 랜드러너<br>- Ez-8 카운트 다운<br>- RX-60 트랩 러너<br>- 공중 전투 메카 : 템페스터<br>- 메카 드롭<br>- 스패로우 팩토리<br>- Ez-10 카운터 어택<br>- TX-45 A-Team<br><br>RX-78 랜드러너 공격력 5% 증가@@948@@2016';

lv105ItemId['결전의 단검 - 쿠노이치'] = 'ffdf1bc662081056dc02c63339a89a98';
lv105OptionMap['결전의 단검 - 쿠노이치'] = {};
lv105OptionMap['결전의 단검 - 쿠노이치'] = '인법 : 잔영 남기기의 잔영 소모 1개당 다음에 재생할 육도윤회의 잔영 공격력 비율 1.5% 추가 증가 (최대 12중첩)<br><br>인법 : 육도윤회 시전 시 5초 동안 슈퍼아머 상태가 되며 스킬 쿨타임 회복 속도 +50% (쿨타임 20초 / 각성기 제외)<br><br>인법 : 육도윤회 스킬 종료 시 잔영 5 회복 (쿨타임 10초)@@8300@@2016';

lv105ItemId['결전의 소검 - 웨펀마스터'] = '7bffb0a3d9cf2447e1e9fad52804d170';
lv105OptionMap['결전의 소검 - 웨펀마스터'] = {};
lv105OptionMap['결전의 소검 - 웨펀마스터'] = '초식을 발전시켜 이기어검술 스킬 강화<br>- 지속 시간 +20초<br>- 기본기 숙련에 영향받는 스킬 적중 시 지속 시간 +0.5초 (최대 지속 시간 초과 불가능)<br>- 꿰뚫는검 사출 개수 +1<br>- 꿰뚫는검 사출 회수 시간 감소<br><br>장착 해제 시 이기어검술 지속 시간 종료@@8892@@2016';

lv105ItemId['근원을 삼킨 쌍검'] = 'a6166434e4244480a0eef198296b848f';
lv105OptionMap['근원을 삼킨 쌍검'] = {};
lv105OptionMap['근원을 삼킨 쌍검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['결전의 둔기 - 다크템플러'] = 'f651b5d92a6fe953c2875b2ec69d6ecc';
lv105OptionMap['결전의 둔기 - 다크템플러'] = {};
lv105OptionMap['결전의 둔기 - 다크템플러'] = '이보브 영혼 최대 흡수 개수 +30<br><br>아래 스킬의 추가 영혼 흡수 개수 +5<br>- 리버레이트 - 매드니스<br>- 디스트로이어<br>- 라케르쏜즈<br><br>리버레이트 - 벅샷 발사하는 에너지 1개당 소모되는 영혼의 수 +2<br>- 타겟을 잡는 최대 거리 20% 증가<br><br>리버레이트 - 벅샷으로 소모한 영혼의 수 2개마다 아래 스킬의 남은 쿨타임 1% 감소 (베스퍼스 효과 미적용)<br>- 디스트로이어<br>- 데우스 엑스 레이<br>- 지하드<br>- 라케르쏜즈@@5336@@2016';

lv105ItemId['미지의 황금비석'] = 'ae71fa231a05101bf87dec168c442ce0';
lv105OptionMap['미지의 황금비석'] = {};
lv105OptionMap['미지의 황금비석'] = '최종 데미지 12% 증가<br><br>[고스트 익스플로전]<br>공격 시 적을 추적하여 자폭하는 유령 소환 (쿨타임 0.5초)<br>- 30% 확률로 2마리 소환<br>- 총 공격력 증가의 200%만큼 피해<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 상태 이상 내성 +16%@@4528@@1900';

lv105ItemId['내딛은 자의 봉'] = '7250e255b07bad50d2e735d92a0ab0b3';
lv105OptionMap['내딛은 자의 봉'] = {};
lv105OptionMap['내딛은 자의 봉'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['얼어붙은 저항의 빗자루'] = '007d4718131eabb2359f574c77d1fdf6';
lv105OptionMap['얼어붙은 저항의 빗자루'] = {};
lv105OptionMap['얼어붙은 저항의 빗자루'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['잠식된 신수의 비통한 면사포'] = '82b3b79ba9fc4ec0c86ef1ff667a55ca';
lv105OptionMap['잠식된 신수의 비통한 면사포'] = {};
lv105OptionMap['잠식된 신수의 비통한 면사포'] = '최종 데미지 9% 증가<br><br>적중률 +10%<br>회피율 +8%@@6144@@1928';

lv105ItemId['죽음을 부르는 관'] = '2bc8c21e84f66d43dd2cef1da39c17e5';
lv105OptionMap['죽음을 부르는 관'] = {};
lv105OptionMap['죽음을 부르는 관'] = '최종 데미지 9% 증가<br><br>이동 속도 +8%<br>물리/마법 피해 감소 +10%<br>회피율 +8%@@6414@@1948';

lv105ItemId['결전의 대검 - 베가본드'] = '0c75d8d42a7acfb0d3aca07d1ce962b0';
lv105OptionMap['결전의 대검 - 베가본드'] = {};
lv105OptionMap['결전의 대검 - 베가본드'] = '십자검 시전 시 전방의 가장 강력한 적을 추적한 후 수평, 수직 베기 동시 시전<br><br>전직 스킬의 후딜레이를 캔슬하여 십자검, 엽해소옥 시전 가능<br><br>아래의 스킬 쿨타임 20% 감소<br>- 일화도강<br>- 십자검<br>- 연화섬<br>- 엽해소옥@@8892@@2016';

lv105ItemId['평화를 수호하는 윙 레깅스'] = '0094922d216a36242addfb2acb38d113';
lv105OptionMap['평화를 수호하는 윙 레깅스'] = {};
lv105OptionMap['평화를 수호하는 윙 레깅스'] = '최종 데미지 8% 증가<br><br>공격 시 1.5초 동안 HP MAX 10% 수치의 보호막 부여 (쿨타임 0.5초)<br><br>공격 시 MP 1% 회복 (쿨타임 1초)<br><br>HP MAX +600<br>스킬 MP 소모량 -7%@@5336@@1938';

lv105ItemId['사멸하는 신뢰의 광창'] = '097c3ce239a5794c872d49a7e3c5bc19';
lv105OptionMap['사멸하는 신뢰의 광창'] = {};
lv105OptionMap['사멸하는 신뢰의 광창'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['절대감각의 방위구'] = '9f86e896dc473690eaa072bd093325f5';
lv105OptionMap['절대감각의 방위구'] = {};
lv105OptionMap['절대감각의 방위구'] = '최종 데미지 10% 증가<br>도약 스킬 Lv +5<br><br>점프 공격 시 즉시 착지하며 충격파 생성 (쿨타임 5초)<br>- 총 공격력 증가의 3,840%만큼 피해<br><br>슈퍼 아머 부여<br><br>이동 속도 +12%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@6952@@1938';

lv105ItemId['기록자의 목걸이'] = '98d28e09d7f849e963761f2f309a0c54';
lv105OptionMap['기록자의 목걸이'] = {};
lv105OptionMap['기록자의 목걸이'] = '최종 데미지 11% 증가<br>- "구속된 자유" 장비 장착 필요<br><br>"죽음에 잠식된 갑주" 장비의 [광폭화] 최대 지속 시간 +5초<br><br>암속성 저항 +10<br>스킬 MP 소모량 -7%@@6144@@1928';

lv105ItemId['사멸하는 신뢰의 장창'] = '1220bfb6fe0872656ac31c2646d37c4e';
lv105OptionMap['사멸하는 신뢰의 장창'] = {};
lv105OptionMap['사멸하는 신뢰의 장창'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['내딛은 자의 장도'] = '4aa0dc4f0df3e363e5d0861979317c32';
lv105OptionMap['내딛은 자의 장도'] = {};
lv105OptionMap['내딛은 자의 장도'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['얼어붙은 저항의 광창'] = '0c3c2fbb379774e3df4bc5fda87f53ad';
lv105OptionMap['얼어붙은 저항의 광창'] = {};
lv105OptionMap['얼어붙은 저항의 광창'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['하이테크 전술보조 각반'] = '084129f7a8aaa34e9ad0dd52ea3a6422';
lv105OptionMap['하이테크 전술보조 각반'] = {};
lv105OptionMap['하이테크 전술보조 각반'] = '최종 데미지 8% 증가<br><br>[가드 카운터]<br>방어 스킬로 적의 공격을 막을 경우 2초 동안 공격한 적 홀딩<br>- 방어 스킬 : 가드, 검막, 인듀어, 무기 막기, 방패 방어, 디바인 실드, 건가드<br>- 스킬 시전 후 0.5초 이내에 방어 시 적용<br><br>물리/마법 방어력 +7,000<br>카운터 피격 시 물리/마법 피해 감소 +20%@@5604@@1928';

lv105ItemId['약속을 다짐하는 벨트'] = '21f9e3644fb6e9ae771234b2417a91b0';
lv105OptionMap['약속을 다짐하는 벨트'] = {};
lv105OptionMap['약속을 다짐하는 벨트'] = '최종 데미지 10% 증가<br><br>[폭발 약속 - 강화]<br>출혈/중독/화상/감전 부여 시 해당 상태 이상과 동일한 데미지를 10% 증가시켜 폭발<br>- 전환 옵션으로 부여 시 미발동<br><br>물리/마법 피해 감소 +15%<br>모든 속성 저항 +8<br>스킬 MP 소모량 -7%@@6144@@1928';

lv105ItemId['움직이는 쇠약한 집착'] = '32eb0e0193b575d9abfee5d92ef05794';
lv105OptionMap['움직이는 쇠약한 집착'] = {};
lv105OptionMap['움직이는 쇠약한 집착'] = '공격력 증가 +326.0%<br>최종 데미지 9% 증가<br>스킬 쿨타임 15% 감소 (각성기 제외)<br><br>자신에게 해제되지 않는 감전 상태 이상 부여<br><br>이동 속도 +8%<br>HP MAX +600<br>모든 속성 저항 +15@@5604@@1910';

lv105ItemId['결전의 창 - 엘레멘탈 바머'] = '49418a31533297efceba8a13c6966fa4';
lv105OptionMap['결전의 창 - 엘레멘탈 바머'] = {};
lv105OptionMap['결전의 창 - 엘레멘탈 바머'] = '엘레멘탈 캐넌 시전 시 속성 마법구 발사 대신 전방에 어비스 캐넌 폭발<br>- 공격력 50% 증가<br>- 쿨타임 5초 증가<br>- 다른 스킬 시전 중 시전 가능<br>- 엘레멘탈 포스 1개당 공격력 10% 추가 증가<br><br>엘레멘탈 캐넌 시전 시 5초 동안 스킬 쿨타임 회복 속도 증가 (각성기 제외)<br>- 소모한 엘레멘탈 포스 1개당 6%<br><br>컨버전스 캐넌, 컨버전스 리액트 시전 시 엘레멘탈 캐넌 쿨타임 초기화@@5336@@2016';

lv105ItemId['드러나는 흐릿한 지식'] = '7c81e61369494012cb55d790d17f980b';
lv105OptionMap['드러나는 흐릿한 지식'] = {};
lv105OptionMap['드러나는 흐릿한 지식'] = '최종 데미지 7% 증가<br><br>물리/마법 크리티컬 히트 +12%<br>HP MAX +600<br>암속성 저항 +50<br>모든 상태 이상 내성 -10%@@5510@@1963';

lv105ItemId['폭풍을 삼킨 에너지'] = 'aea53f965f746802698373ca7f0935a7';
lv105OptionMap['폭풍을 삼킨 에너지'] = {};
lv105OptionMap['폭풍을 삼킨 에너지'] = '최종 데미지 13% 증가<br><br>소모품으로 인한 MP 회복 효과 50% 증가<br>스킬 MP 소모량 -20%<br><br>MP MAX +4,196<br>적중률 +10%<br>회피율 +8%@@5066@@1920';

lv105ItemId['이온화조정 팔찌'] = '29a27871765c9140b5beb3f00dfacef7';
lv105OptionMap['이온화조정 팔찌'] = {};
lv105OptionMap['이온화조정 팔찌'] = '최종 데미지 15% 증가<br><br>[긴급 탈출]<br>피격 중 점프 키 입력 시 긴급 탈출 (쿨타임 10초)<br>- 방향키 입력으로 위치 조정 가능<br>- HP 10% 회복<br><br>물리/마법 크리티컬 히트 +10%<br>이동 속도 +8%<br>물리/마법 방어력 +7,000<br>회피율 +10%@@4528@@1928';

lv105ItemId['결전의 로드 - 스위프트 마스터'] = 'da90a29538d3ead791797af61ca99cca';
lv105OptionMap['결전의 로드 - 스위프트 마스터'] = {};
lv105OptionMap['결전의 로드 - 스위프트 마스터'] = '소닉 무브를 최대 3회 충전 스킬로 변경<br><br>소닉 무브 시전 시 회오리 바람 생성<br>- 소닉 무브 회오리 바람 공격력 : 회오리 바람 공격력의 50%<br><br>회오리 바람 크기 10% 증가<br><br>전직 스킬을 캔슬하여 소닉 무브, 대진공, 칼날 바람 시전 시 풍흔 1스택 획득 (최대 15스택)<br>- 풍흔이 15스택일 때 스톰 스트라이크 시전 시 모든 풍흔을 소모하여 공격력 50% 증가@@7112@@2016';

lv105ItemId['결전의 장도 - 히트맨'] = '15957ff21ae61d6723c252f26dbc8d57';
lv105OptionMap['결전의 장도 - 히트맨'] = {};
lv105OptionMap['결전의 장도 - 히트맨'] = '전직 스킬의 후딜레이를 캔슬하여 데드 식스를 시전 가능<br>후딜레이 캔슬 시 아래의 효과 적용<br>- 캔슬한 스킬 남은 쿨타임 20% 감소<br>- 데드 식스 남은 쿨타임 50% 감소<br>- 8초 동안 공격 속도 +7% (최대 3중첩)@@10076@@2016';

lv105ItemId['내딛은 자의 크로스슈터'] = '0e6948d4d2219c2555065e2530d51596';
lv105OptionMap['내딛은 자의 크로스슈터'] = {};
lv105OptionMap['내딛은 자의 크로스슈터'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@474@@1856';

lv105ItemId['사멸하는 신뢰의 리볼버'] = 'a88de4d904c129edcae31c7eba68db6f';
lv105OptionMap['사멸하는 신뢰의 리볼버'] = {};
lv105OptionMap['사멸하는 신뢰의 리볼버'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['드래곤 스케빈저'] = '0dc6f055906735f2f2eb23670e3284dd';
lv105OptionMap['드래곤 스케빈저'] = {};
lv105OptionMap['드래곤 스케빈저'] = '최종 데미지 14% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 속성 저항 +15@@5874@@1928';

lv105ItemId['광폭화된 전의의 차크라 웨펀'] = '5c855d9b70be93f41aae4952f1419602';
lv105OptionMap['광폭화된 전의의 차크라 웨펀'] = {};
lv105OptionMap['광폭화된 전의의 차크라 웨펀'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 클로 - 스트리트파이터(여)'] = '4ec7ed0abae414664bddba6bfa5ea11b';
lv105OptionMap['결전의 클로 - 스트리트파이터(여)'] = {};
lv105OptionMap['결전의 클로 - 스트리트파이터(여)'] = '특정 스킬 공격 시 즉폭의 혈이 발생하여 자신의 출혈/중독/화상/감전 데미지 폭발<br><즉폭의 혈 발생 스킬><br>- 일발화약성 훅 공격<br>- 맹독 일발화약성 훅 공격<br>- 마운트 마지막 충격파<br><br>아래의 스킬 상태 이상 1개당 보너스 공격력 5% 추가 증가<br>- 일발화약성<br>- 맹독 일발화약성<br>- 크레이지 발칸@@9484@@2016';

lv105ItemId['광폭화된 전의의 십자가'] = 'adbaa50f8f7365b5d5c8707acaf445d6';
lv105OptionMap['광폭화된 전의의 십자가'] = {};
lv105OptionMap['광폭화된 전의의 십자가'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['사멸하는 신뢰의 대검'] = '3cac041bd6de56770fe8bf8ef295fd8d';
lv105OptionMap['사멸하는 신뢰의 대검'] = {};
lv105OptionMap['사멸하는 신뢰의 대검'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['성장하는 모험가의 완드'] = '89a7d821e63bd35d6b06982adf5b23a1';
lv105OptionMap['성장하는 모험가의 완드'] = {};
lv105OptionMap['성장하는 모험가의 완드'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['임펄스 트리거'] = '88fce89d89665d2948dde039042c91bc';
lv105OptionMap['임펄스 트리거'] = {};
lv105OptionMap['임펄스 트리거'] = '최종 데미지 10% 증가<br><br>공격 속도 +50%<br>물리/마법 방어력 +7,000<br>적중률 +10%@@4796@@1948';

lv105ItemId['결전의 로드 - 엘레멘탈 바머'] = 'a5003957bec135c52aeee7d221253ec7';
lv105OptionMap['결전의 로드 - 엘레멘탈 바머'] = {};
lv105OptionMap['결전의 로드 - 엘레멘탈 바머'] = '엘레멘탈 캐넌 시전 시 속성 마법구 발사 대신 전방에 어비스 캐넌 폭발<br>- 공격력 50% 증가<br>- 쿨타임 5초 증가<br>- 다른 스킬 시전 중 시전 가능<br>- 엘레멘탈 포스 1개당 공격력 10% 추가 증가<br><br>엘레멘탈 캐넌 시전 시 5초 동안 스킬 쿨타임 회복 속도 증가 (각성기 제외)<br>- 소모한 엘레멘탈 포스 1개당 6%<br><br>컨버전스 캐넌, 컨버전스 리액트 시전 시 엘레멘탈 캐넌 쿨타임 초기화@@5336@@2016';

lv105ItemId['죽음에 잠식된 갑주'] = '17e2b07728ff9a4ba2ba0bf62a97e7f8';
lv105OptionMap['죽음에 잠식된 갑주'] = {};
lv105OptionMap['죽음에 잠식된 갑주'] = '공격력 증가 +296.4%<br>최종 데미지 5% 증가<br><br>[최후의 광폭화]<br>사망 시 죽음을 미루고 5초 동안 광폭화<br>- 스킬로 무색 큐브 조각을 1개 소모할 때마다 광폭화 시간 +0.3초<br>- 광폭화 종료 시 사망<br><br>공격 속도 +15%<br>캐스팅 속도 +22.5%<br>HP MAX +600<br>스킬 MP 소모량 -7%@@4528@@1928';

lv105ItemId['결전의 대검 - 버서커'] = '9466d47afba4763d850785e89b8b392c';
lv105OptionMap['결전의 대검 - 버서커'] = {};
lv105OptionMap['결전의 대검 - 버서커'] = '피에 굶주려 갈증 시전 시 HP 10%를 소모하고 혈기 3개 획득 (최대 10스택)<br>- 갈증 충전 기능 삭제<br>- 다른 스킬 시전 중 갈증 시전 가능<br>- 던전 입장 시 혈기 1개 획득<br><br>블러드 소드, 격노 시전 시 혈기 1개를 소모하여 강화<br>- 공격력 30% 증가<br>- 스킬 범위 15% 증가<br><br>블러드 소드, 격노 시전 시 HP 5% 회복@@9484@@2016';

lv105ItemId['기품의 금빛 장신구'] = '91e668c19b5b1957befc8f4ca4dd5098';
lv105OptionMap['기품의 금빛 장신구'] = {};
lv105OptionMap['기품의 금빛 장신구'] = '공격력 증가 +370.5%<br>최종 데미지 8% 증가<br><br>자신에게 해제되지 않는 화상 상태 이상 부여<br><br>공격 시 적에게 5초 동안 화상 상태 이상 부여 (쿨타임 1초)<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +20%@@4528@@1948';

lv105ItemId['사멸하는 신뢰의 광검'] = '3f9a8679d69773a3551139dcc5774af6';
lv105OptionMap['사멸하는 신뢰의 광검'] = {};
lv105OptionMap['사멸하는 신뢰의 광검'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 선현궁 - 뮤즈'] = '642355a70a2098c18db0efaa9798453b';
lv105OptionMap['결전의 선현궁 - 뮤즈'] = {};
lv105OptionMap['결전의 선현궁 - 뮤즈'] = '러블리 템포 효과 8% 증가<br>- 장착 시 효과 해제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>밤과 꿈 스킬 범위 20% 증가<br><br>사운드 오브 뮤즈 시전 시 스포트라이트가 추가 발생<br>- 공격력 20% 증가<br>- 스킬 범위 15% 증가<br>- 스킬 적중 시 컬쳐 쇼크 쿨타임 초기화<br><br>컬쳐 쇼크 강화<br>- 스킬 종료 시 2초 동안 추가 파동 5회 발생<br>- 추가 파동 공격력 : 켤쳐 쇼크 세 번째 파동 공격력의 30%<br>- 스킬 범위 20% 증가<br><br>센세이션 싱글 플레이 시 독립 공격력 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['숨쉬는 자연의 생명'] = '0aa9c73bdb86ed17356234f2c0af1fc2';
lv105OptionMap['숨쉬는 자연의 생명'] = {};
lv105OptionMap['숨쉬는 자연의 생명'] = '모든 속성 강화 +10<br><br>[자연의 분노]<br>공격 시 4가지 자연의 분노 중 1개 시전 (쿨타임 0.5초)<br>- 화 : 파이어 플레어<br>- 수 : 아이스 픽<br>- 명 : 라이트닝 스트라이크<br>- 암 : 다크 봄<br>- 총 공격력 증가의 300%만큼 피해<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 화상 상태 이상 부여 (쿨타임 10초)<br><br>적중률 +10%@@6414@@1978';

lv105ItemId['성장하는 모험가의 어깨'] = 'null';
lv105OptionMap['성장하는 모험가의 어깨'] = {};
lv105OptionMap['성장하는 모험가의 어깨'] = '최종 데미지 7% 증가@@533@@1986';

lv105ItemId['근원을 삼킨 장궁'] = '7ad671f02a8d3463a78376c2d1fd99d9';
lv105OptionMap['근원을 삼킨 장궁'] = {};
lv105OptionMap['근원을 삼킨 장궁'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['광폭화된 전의의 미늘창'] = '9ca408ace0634c3cc9e64245203c15aa';
lv105OptionMap['광폭화된 전의의 미늘창'] = {};
lv105OptionMap['광폭화된 전의의 미늘창'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['근원을 삼킨 염주'] = '855126e0e5b8d9b26a5ffc04a25f53b2';
lv105OptionMap['근원을 삼킨 염주'] = {};
lv105OptionMap['근원을 삼킨 염주'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['근원을 삼킨 빗자루'] = '02f481e54abd852261d984dcd6ce3932';
lv105OptionMap['근원을 삼킨 빗자루'] = {};
lv105OptionMap['근원을 삼킨 빗자루'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['결전의 광창 - 드래고니안 랜서'] = 'a04fe730e35aa4470c3cb6231163b9f6';
lv105OptionMap['결전의 광창 - 드래고니안 랜서'] = {};
lv105OptionMap['결전의 광창 - 드래고니안 랜서'] = '서번트 랜스 스킬로 공격한 적에게 사냥 표식을 생성하고 사냥 1단계 부여<br>사냥 표식 20개마다 사냥 단계 상승 (최대 3단계)<br>아래 스킬 시전 시 사냥 단계를 소모하고 해당 스킬 공격력 증가<br>- 데보닝 슬래셔<br>- 팔티잔 스큐어<br>- 날개 꿰기<br><br><사냥 단계별 공격력 증가량><br>- 1단계 : 10%<br>- 2단계 : 20%<br>- 3단계 : 30%<br><br><사냥 표식 부여량><br>- 서번트 랜스 : 타격당 3 스택<br>- 서번트 랜스 : 어썰트 : 타격당 10 스택<br>- 서번트 랜스 : 인게이저 : 타격당 4 스택<br><br>서번트 랜스 마법창 재생성 시간 30% 감소<br>서번트 랜스 재발사 쿨타임 30% 감소@@8300@@2016';

lv105ItemId['안개를 탐구하는 자의 반지'] = '4ec2f2608a8b9fa537b46c667a335eaa';
lv105OptionMap['안개를 탐구하는 자의 반지'] = {};
lv105OptionMap['안개를 탐구하는 자의 반지'] = '최종 데미지 2% 증가<br>스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>물리/마법 크리티컬 히트 +5%<br>이동 속도 +15%<br>모든 속성 저항 +8@@4528@@1928';

lv105ItemId['결전의 리볼버 - 어썰트'] = 'f9936ac6d67a93aa3458fe018d07999c';
lv105OptionMap['결전의 리볼버 - 어썰트'] = {};
lv105OptionMap['결전의 리볼버 - 어썰트'] = '부스트 스킬로 전직 스킬 캔슬 시 전력 1스택 획득 (최대 5스택)<br><br>아래 스킬 시전 시 모든 전력을 소모하고, 소모한 전력당 공격력 10% 증가<br>- 레이시온 크리트<br>- 부스트 : 다이너마이트 킥<br><br>부스트 : 다이너마이트 킥 적중 시 적을 1.5초 동안 홀딩<br>- 소모한 전력 1스택당 홀딩 시간 0.5초 증가@@9484@@2016';

lv105ItemId['결전의 배틀액스 - 미스트리스'] = 'ecb64a1bd140ee3022dcfc520786e173';
lv105OptionMap['결전의 배틀액스 - 미스트리스'] = {};
lv105OptionMap['결전의 배틀액스 - 미스트리스'] = '타락의 칠죄종 변신 중 죄를 깨우는 손길 공격 시 변신 지속 시간 +12초<br>- 최대 변신 지속 시간 초과 불가능<br><br>글러트니 바밋 시전 시 자신을 중심으로 적을 흡수하고 폭발<br>- 공격력 30% 증가<br>- 죄업 스택 3개 이상일 때 시전 시 죄업 스택을 3개 소모하고 분신이 대신 시전<br><br>죄업 스택이 3개 이상일 때 무거운 죄업 시전 시 죄업 스택을 3개 소모하고 10초 동안 물리/마법 피해 감소 +15%<br><br>일곱 개의 대죄 적용 중 무거운 죄업 적 홀딩 시간 +1초<br><br>죄를 깨우는 손길 죄업 스택 증가량 +2@@7708@@2016';

lv105ItemId['불길한 데칼코마니 석판'] = '4ebe756da29721b7a93c7752320de8a9';
lv105OptionMap['불길한 데칼코마니 석판'] = {};
lv105OptionMap['불길한 데칼코마니 석판'] = '최종 데미지 8% 증가<br><br>슈퍼 아머 부여<br><br>점프 중 한 번 더 점프 가능<br><br>이동 속도 +12%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5874@@1928';

lv105ItemId['흙으로 빚은 자연'] = '28c36859083988e74ef3da8109085a67';
lv105OptionMap['흙으로 빚은 자연'] = {};
lv105OptionMap['흙으로 빚은 자연'] = '화속성 강화 +20<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>[파이어 포스]<br>공격 시 화속성 강화 수치에 따라 화속성 원소 공격 시전 (쿨타임 1.5초)<br>- 100 이상 150 미만 : 익스플로전<br>- 150 이상 250 미만 : 파이어 로드<br>- 250 이상 : 메테오<br>- 각각 총 공격력 증가의 720% / 920% / 1,480%만큼 피해<br>- 메테오는 적에게 15초 동안 화상 상태 이상 부여 (쿨타임 10초)<br><br>공격 속도 +10%<br>캐스팅 속도 +15%<br>화속성 저항 +20@@6414@@1978';

lv105ItemId['내딛은 자의 장창'] = '8db657419606f61f99d65a33160b6f2d';
lv105OptionMap['내딛은 자의 장창'] = {};
lv105OptionMap['내딛은 자의 장창'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['승리로 이끄는 정의'] = '1790007e83e6f513e5bcfc51b8e8bfd9';
lv105OptionMap['승리로 이끄는 정의'] = {};
lv105OptionMap['승리로 이끄는 정의'] = '최종 데미지 8% 증가<br><br>약점 무력화형 상태 이상의 무력화 게이지 감소량 +200%<br><br>물리/마법 방어력 +7,000<br>이동 속도 +8%@@5604@@1968';

lv105ItemId['결전의 투창 - 듀얼리스트'] = '9bc2ac16cd6c8f7caa357a08a3b1c85f';
lv105OptionMap['결전의 투창 - 듀얼리스트'] = {};
lv105OptionMap['결전의 투창 - 듀얼리스트'] = '아래 스킬 적중 시 뇌격점혈섬을 강화하는 극 점혈 획득 (최대 30개)<br>- 레조넌스 임페일 : 타격당 10개<br>- 쿼드러플 드라이브 : 타격당 5개<br>- 파동연환창 : 타격당 4개<br>- 트윈 펀토 : 타격당 2개<br>- 퀵 펀토 : 타격당 1개<br><br>보유한 극 점혈 1개당 뇌격점혈섬 강화<br>- 스킬 쿨타임 회복 속도 : +1%<br>- 공격 속도 : +1%<br><br>뇌격점혈섬 공격 시 모든 극 점혈을 소모하여 극 점혈 1개당 창끝 점혈 히트 수 추가 적용<br><br>뇌격점혈섬 공격 속도 +10%@@10672@@2016';

lv105ItemId['적을 향한 신중'] = '6d2b1d80e4200295f8e32740d5a387db';
lv105OptionMap['적을 향한 신중'] = {};
lv105OptionMap['적을 향한 신중'] = '최종 데미지 10% 증가<br>60~70Lv 스킬 공격력 5% 증가<br>스킬 범위 16% 증가<br>60~70Lv 스킬 범위 15% 증가@@6170@@1900';

lv105ItemId['밤을 바라보는 공학자의 시선'] = '0dd1e558c78677765dede8e08bb2c5e4';
lv105OptionMap['밤을 바라보는 공학자의 시선'] = {};
lv105OptionMap['밤을 바라보는 공학자의 시선'] = '50, 85, 100Lv 스킬 공격력 20% 증가<br>50, 85, 100Lv 스킬 쿨타임 회복 속도 +40% (버퍼 제외)<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@6144@@1928';

lv105ItemId['성장하는 모험가의 코어 블레이드'] = 'd407bf4caa6122f513a18adab9cad03a';
lv105OptionMap['성장하는 모험가의 코어 블레이드'] = {};
lv105OptionMap['성장하는 모험가의 코어 블레이드'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['결전의 로드 - 블러드 메이지'] = '0da043770fc6ef048065e0f8d38c2b08';
lv105OptionMap['결전의 로드 - 블러드 메이지'] = {};
lv105OptionMap['결전의 로드 - 블러드 메이지'] = '커럽션 공격 시 블러드비스 2개 획득 (최대 5개)<br>- 쉬카리, 토먼트 시전 시 블러드비스 1개를 소모하여 각각 페이탈 쉬카리, 블러드먼트로 강화<br>- 던전 입장 시 블러드비스 1개 획득<br><br>페이탈 쉬카리<br>- 쉬카리 공격력 50% 증가<br>- 1히트 스킬로 변경<br>- 500px 범위 내 모든 적 공격<br><br>블러드먼트<br>- 토먼트 공격력 50% 증가<br>- 시전 후 이동 가능<br><br>커럽션 쿨타임 10% 감소, 시전 속도 30% 증가@@8892@@2016';

lv105ItemId['내딛은 자의 광창'] = '67380c52402dda23f18393eb2334ca14';
lv105OptionMap['내딛은 자의 광창'] = {};
lv105OptionMap['내딛은 자의 광창'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['광폭화된 전의의 장도'] = '252dc06234336c1b28ff8e4aac7d69ec';
lv105OptionMap['광폭화된 전의의 장도'] = {};
lv105OptionMap['광폭화된 전의의 장도'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['아크 블라스터 소스'] = '7767981f839a0083a3d734199cb0deda';
lv105OptionMap['아크 블라스터 소스'] = {};
lv105OptionMap['아크 블라스터 소스'] = '공격력 증가 +222.3%<br>최종 데미지 16% 증가<br><br>[스톤 스트라이크]<br>석화 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>공격 시 HP 3,000 회복 (쿨타임 1초)<br><br>석화 지속 시간 +2초@@4176@@1920';

lv105ItemId['결전의 빗자루 - 인챈트리스'] = '8663fef10ebdd5b493afcbdccb49fee7';
lv105OptionMap['결전의 빗자루 - 인챈트리스'] = {};
lv105OptionMap['결전의 빗자루 - 인챈트리스'] = '금단의 저주 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>꼼꼼한 바느질 스킬 범위 20% 증가<br><br>테러블 로어 캐스팅 모션 삭제<br>테러블 로어 몹몰이 범위 10% 증가<br><br>커스 오브 테러베어의 브레스에 피격된 적에게 저주의 기운을 부여하고 폭발<br>- 폭발 피해 : 브레스 총 공격력의 50%<br><br>어둠에 피는 장미의 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['결전의 보우건 - 레인저(여)'] = '9bd5f78cfb8a894f67a121ec93a67fd4';
lv105OptionMap['결전의 보우건 - 레인저(여)'] = {};
lv105OptionMap['결전의 보우건 - 레인저(여)'] = '이동사격 탄환을 파열시켜 더욱 넓은 범위 공격<br>- 공격력 30% 증가<br>- 발사 수 50% 증가<br>- 쿨타임 20% 감소<br>- 공격 범위 10% 증가<br><br>이동사격 중 아래의 효과 적용<br>- 스킬 시전 시 쿨타임 즉시 적용<br>- 공격 속도 +30%<br>- 이동 속도 +30%<br>- 던전 방 이동 시 스킬 유지@@7708@@2016';

lv105ItemId['상상을 담은 엔지니어 블라우스'] = '028892800eb702856938f285fdd60f3c';
lv105OptionMap['상상을 담은 엔지니어 블라우스'] = {};
lv105OptionMap['상상을 담은 엔지니어 블라우스'] = '최종 데미지 8% 증가<br><br>스킬을 시전하는 동안 이동 속도 +300%<br><br>모든 파티원에게 슈퍼 아머 부여<br><br>물리/마법 방어력 +7,000<br>HP MAX +600@@6414@@1948';

lv105ItemId['결전의 배틀액스 - 크루세이더(여)'] = '894b8df37a7c03a500ed128080af7b39';
lv105OptionMap['결전의 배틀액스 - 크루세이더(여)'] = {};
lv105OptionMap['결전의 배틀액스 - 크루세이더(여)'] = '용맹의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>치유의 기도 스킬 범위 20% 증가<br><br>홀리 플래쉬가 적을 관통하여 300px 전진 후 폭발<br>- 공격력 100% 증가<br>- 스킬 범위 10% 증가<br><br>홀리 플래쉬 적중 시 그랜드 크로스 크래쉬, 리펜텐스 스매시의 남은 쿨타임 2% 감소<br><br>빛의 은총 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['광폭화된 전의의 광창'] = '63fffa269b95fe4b5c20731d06ab5912';
lv105OptionMap['광폭화된 전의의 광창'] = {};
lv105OptionMap['광폭화된 전의의 광창'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['오랜 시간 품어온 소원'] = '84627aee4e995e9117796f1a9f28a0ed';
lv105OptionMap['오랜 시간 품어온 소원'] = {};
lv105OptionMap['오랜 시간 품어온 소원'] = '[불면증]<br>오랜 시간 품어온 소원을 이루기 위해 잠들지 않으며 최종 데미지 28.5% 증가<br>- 최종 데미지 증가 옵션은 장비로 인한 수면 내성 수치의 총합이 -70% 이하일 때 적용<br><br>물리/마법 크리티컬 히트 +7%<br>회피율 +8%<br>모든 상태 이상 내성 -10%<br>수면 내성 -10%@@4972@@1963';

lv105ItemId['홀로그램 콜'] = 'bc7befe3a3f672448b2e10215b5b5ba7';
lv105OptionMap['홀로그램 콜'] = {};
lv105OptionMap['홀로그램 콜'] = '1~30Lv 스킬 공격력 12% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%<br>물리/마법 피해 감소 +15%@@6144@@1910';

lv105ItemId['내딛은 자의 보우건'] = '4bc8858c272fe936826c18581a72c91f';
lv105OptionMap['내딛은 자의 보우건'] = {};
lv105OptionMap['내딛은 자의 보우건'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 도 - 다크나이트'] = '267af8e18d9912e5b4c03ac638a3cdf0';
lv105OptionMap['결전의 도 - 다크나이트'] = {};
lv105OptionMap['결전의 도 - 다크나이트'] = '타임 스톱 최대 콤보 스택 1단계 증가<br>- 콤보 스택 6단계 공격력 : 220%<br><br>시간에 간섭하여 타임로드 효과 강화<br>- 다른 콤보 슬롯 스킬 사용 시 타임 스톱 스택 획득<br>- 확장 콤보 슬롯 스킬 시전 시 기존 콤보 스킬 유지<br>- 타임 로드 효과 지속 시간 +2초@@10672@@2016';

lv105ItemId['맹렬한 위세'] = '13e64a09c0c8b91949ec6dac3afb5687';
lv105OptionMap['맹렬한 위세'] = {};
lv105OptionMap['맹렬한 위세'] = '출혈 피해 +15%<br><br>출혈 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5중첩)<br><br>공격 시 모든 적에게 10초 동안 출혈 상태 이상 부여 (쿨타임 5초)<br><br>공격 시 HP 2200 회복 (쿨타임 1초)<br><br>출혈 내성 +10%@@4647@@1953';

lv105ItemId['작은 풀잎의 순수함'] = '5bdaab4a7412cf680281d3e047f39320';
lv105OptionMap['작은 풀잎의 순수함'] = {};
lv105OptionMap['작은 풀잎의 순수함'] = '공격력 증가 +222.3%<br>최종 데미지 15% 증가<br><br>기절 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>공격 시 EMP 쇼크 발생 (쿨타임 1.5초)<br>- 총 공격력 증가의 800%만큼 피해<br><br>감전 상태 적 공격 시 5초 동안 기절 상태 이상 부여 (쿨타임 8초)<br><br>기절 지속 시간 +2초@@3906@@1948';

lv105ItemId['골렘의 심장 목걸이'] = '8750590d2eaddaea7633c7d66f9e705d';
lv105OptionMap['골렘의 심장 목걸이'] = {};
lv105OptionMap['골렘의 심장 목걸이'] = '최종 데미지 10% 증가<br><br>공격 시 수속성 폭발 발생 (쿨타임 5초)<br>- 총 공격력 증가 수치의 480%만큼 피해<br>- 자신에게는 1의 피해<br><br>물리/마법 방어력 +7,000<br>수속성 저항 +10@@6414@@1958';

lv105ItemId['에너지 서치 링'] = 'b7afabb43e296c24f9e5d53fcb4f63bb';
lv105OptionMap['에너지 서치 링'] = {};
lv105OptionMap['에너지 서치 링'] = '최종 데미지 12% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>모든 속성 저항 +8@@3988@@1948';

lv105ItemId['올 오어 원 매직박스'] = '4bf9eb661b8da8fa5be7351ee8703e83';
lv105OptionMap['올 오어 원 매직박스'] = {};
lv105OptionMap['올 오어 원 매직박스'] = '[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5604@@1612';

lv105ItemId['결전의 완드 - 사령술사'] = '79837453cbc46356f60990bb5bf96c27';
lv105OptionMap['결전의 완드 - 사령술사'] = {};
lv105OptionMap['결전의 완드 - 사령술사'] = '리빙데드를 최대 3회 충전 스킬로 변경<br><br>레디아 블랙옥스가 직접 폭발<br>- 공격력 : 리빙데드 총 공격력의 45%<br>- 충전 쿨타임 : 15초<br>- 적에게 5초 동안 공포의 낙인 생성<br>- 추가 조작 기능 삭제<br><br>공포의 낙인이 생성된 적을 아래의 스킬로 공격 시 해당 스킬 남은 쿨타임 10% 감소<br>- 망자의 집념<br>- 블랙 아라크네아<br>- 익스큐셔너 슬래쉬@@8892@@2016';

lv105ItemId['성장하는 모험가의 단검'] = 'b56039fa99903c5a719b1be6b77d9d12';
lv105OptionMap['성장하는 모험가의 단검'] = {};
lv105OptionMap['성장하는 모험가의 단검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['미지의 문명 - 스타 스톤'] = '06ca5053e2255d7c4074147e3866ccb0';
lv105OptionMap['미지의 문명 - 스타 스톤'] = {};
lv105OptionMap['미지의 문명 - 스타 스톤'] = '최종 데미지 10% 증가<br><br>[속성 친화 - 수]<br>수속성 저항이 높아질수록 물리/마법 피해 감소<br>- 적의 공격에 수속성 부여<br><br>피격 시 5초 동안 HP 5% 회복 (쿨타임 7초)<br><br>HP MAX +600<br>수속성 저항 +35@@5604@@1948';

lv105ItemId['근원을 삼킨 둔기'] = '191e9656ce662ce2fd612c84531b522a';
lv105OptionMap['근원을 삼킨 둔기'] = {};
lv105OptionMap['근원을 삼킨 둔기'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['핏빛의 결정 반지'] = '1a17f4ecc09e755ede2b92d8ad399654';
lv105OptionMap['핏빛의 결정 반지'] = {};
lv105OptionMap['핏빛의 결정 반지'] = '공격력 증가 +444.6%<br>출혈 피해 +25%<br><br>자신이 부여하는 출혈 지속 시간 -10%@@3368@@1973';

lv105ItemId['결전의 스태프 - 인챈트리스'] = '3c58781938be47582b5de02eb6fb8b0c';
lv105OptionMap['결전의 스태프 - 인챈트리스'] = {};
lv105OptionMap['결전의 스태프 - 인챈트리스'] = '금단의 저주 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>꼼꼼한 바느질 스킬 범위 20% 증가<br><br>테러블 로어 캐스팅 모션 삭제<br>테러블 로어 몹몰이 범위 10% 증가<br><br>커스 오브 테러베어의 브레스에 피격된 적에게 저주의 기운을 부여하고 폭발<br>- 폭발 피해 : 브레스 총 공격력의 50%<br><br>어둠에 피는 장미의 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['청연을 지키는 힘'] = '0372425a57695fedd81d3b5aeba336f0';
lv105OptionMap['청연을 지키는 힘'] = {};
lv105OptionMap['청연을 지키는 힘'] = '공격력 증가 +251.9%<br>모든 속성 강화 +15<br><br>공격 시 안개 벼락 1회 시전 (쿨타임 1초)<br>- 10% 확률로 강화된 안개 벼락 시전<br>- 안개 벼락과 강화된 안개 벼락은 각각 총 공격력 증가의 1,000%, 1,480%만큼 피해<br><br>공격 속도 +18%<br>캐스팅 속도 +22%<br>이동 속도 +18%@@7222@@1928';

lv105ItemId['골드 윙 반지'] = 'b92733a01bd3e559a2ed7c61306f2946';
lv105OptionMap['골드 윙 반지'] = {};
lv105OptionMap['골드 윙 반지'] = '최종 데미지 5% 증가<br>모든 속성 강화 +20<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>물리/마법 피해 감소 +20%@@6858@@1963';

lv105ItemId['근원을 삼킨 소검'] = '6250a5bf265d32114371e2c22bb33358';
lv105OptionMap['근원을 삼킨 소검'] = {};
lv105OptionMap['근원을 삼킨 소검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['아토믹 코어 네클레스'] = '064715eb64c51a8cd840e001563219e3';
lv105OptionMap['아토믹 코어 네클레스'] = {};
lv105OptionMap['아토믹 코어 네클레스'] = '스킬 쿨타임 회복 속도 +25% (각성기 제외)<br>모든 속성 강화 +20<br><br>공격 속도 +15%<br>캐스팅 속도 +15%<br>이동 속도 +15%<br>HP 1분당 460.2 회복@@4702@@1943';

lv105ItemId['얼어붙은 저항의 단검'] = '5647b4e8e27a21a02659fb4393846b2b';
lv105OptionMap['얼어붙은 저항의 단검'] = {};
lv105OptionMap['얼어붙은 저항의 단검'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['광폭화된 전의의 대검'] = 'cab73346f3cf368f50a4d633c809106c';
lv105OptionMap['광폭화된 전의의 대검'] = {};
lv105OptionMap['광폭화된 전의의 대검'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['얼어붙은 저항의 미늘창'] = '63b5bfa2d972514442c28c7e1793a8d2';
lv105OptionMap['얼어붙은 저항의 미늘창'] = {};
lv105OptionMap['얼어붙은 저항의 미늘창'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['억제된 마력의 팔찌'] = '6354671471743553243cab7ccc79a748';
lv105OptionMap['억제된 마력의 팔찌'] = {};
lv105OptionMap['억제된 마력의 팔찌'] = '공격력 증가 +385.3%<br>최종 데미지 15% 증가<br><br>[금단의 마력 계약]<br>금지되어 있는 마력 계약을 체결하여 피를 잃고 마력과 생명을 연결<br>- 스킬 시전, 피격 시 HP 대신 MP 소모<br>- HP 회복량의 25%만큼 MP 회복<br>- HP MAX 99% 제한<br><br>MP MAX +4,196<br>물리/마법 방어력 +7,000<br>MP 1분당 348 회복@@5604@@1968';

lv105ItemId['라이트 어댑터 팬츠'] = '0b148001bead9337b695187e71f1d4da';
lv105OptionMap['라이트 어댑터 팬츠'] = {};
lv105OptionMap['라이트 어댑터 팬츠'] = '명속성 강화 +70<br><br>명속성 저항 +20@@3450@@1958';

lv105ItemId['결전의 중검 - 트러블 슈터'] = '390e559eb1669365a4513ad85d7c951a';
lv105OptionMap['결전의 중검 - 트러블 슈터'] = {};
lv105OptionMap['결전의 중검 - 트러블 슈터'] = '번 투 슬래쉬 시전 시 3초 동안 전직 스킬 공격력 20% 증가 (각성기 제외)<br>- 후딜레이를 캔슬하여 다른 전직 스킬 사용 가능<br>- 다른 전직 스킬의 후딜레이를 캔슬하여 번 투 슬래쉬 사용 가능<br>- 추가 공격 입력 시간 3초로 증가<br>- 스킬 종료 후 쿨타임 적용<br><br>번 투 슬래쉬가 타격하지 않아도 폭발 발생<br>- 스킬 쿨타임 35% 감소<br>- 스킬 범위 10% 증가@@6520@@2016';

lv105ItemId['결전의 창 - 인챈트리스'] = '33eb6b2f0ce84edb6213a1d9dd919447';
lv105OptionMap['결전의 창 - 인챈트리스'] = {};
lv105OptionMap['결전의 창 - 인챈트리스'] = '금단의 저주 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>꼼꼼한 바느질 스킬 범위 20% 증가<br><br>테러블 로어 캐스팅 모션 삭제<br>테러블 로어 몹몰이 범위 10% 증가<br><br>커스 오브 테러베어의 브레스에 피격된 적에게 저주의 기운을 부여하고 폭발<br>- 폭발 피해 : 브레스 총 공격력의 50%<br><br>어둠에 피는 장미의 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['무모하지만 합리적인 작전'] = '74ec24105f67cf9c71ce72dbc3dbf8af';
lv105OptionMap['무모하지만 합리적인 작전'] = {};
lv105OptionMap['무모하지만 합리적인 작전'] = '최종 데미지 9% 증가<br><br>물리/마법 피해 감소 +10%<br>물리/마법 방어력 +7,000<br>MP 1분당 348 회복@@4258@@1938';

lv105ItemId['성장하는 모험가의 상의'] = 'null';
lv105OptionMap['성장하는 모험가의 상의'] = {};
lv105OptionMap['성장하는 모험가의 상의'] = '최종 데미지 7% 증가@@533@@1986';

lv105ItemId['화음하는 음색'] = '53cb49e33ff3519e7c3bc1ffe3988521';
lv105OptionMap['화음하는 음색'] = {};
lv105OptionMap['화음하는 음색'] = '최종 데미지 15% 증가<br>모든 속성 강화 +25<br><br>스킬, 장비로 인한 HP 회복 효과 20% 증가<br><br>HP MAX +600<br>MP MAX +945@@4528@@1928';

lv105ItemId['폭룡왕의 지배 - 무력'] = '81da1fa194cefcf0166b728e7b8a2e56';
lv105OptionMap['폭룡왕의 지배 - 무력'] = {};
lv105OptionMap['폭룡왕의 지배 - 무력'] = '최종 데미지 15% 증가<br>모든 속성 강화 +30<br><br>3초마다 HP/MP 1% 회복<br><br>HP MAX +600<br>MP MAX +945@@5066@@1900';

lv105ItemId['결전의 클로 - 스트리트파이터(남)'] = '0b5e3974d5fdd96aa6074dd8a300b440';
lv105OptionMap['결전의 클로 - 스트리트파이터(남)'] = {};
lv105OptionMap['결전의 클로 - 스트리트파이터(남)'] = '크레이지 발칸 시전 시 화염병 폭탄을 내려치도록 변경<br>- 마운트 마지막 충격파 삭제<br>- 주변 적에게 잡힌 적과 동일한 데미지 적용<br>- 잡기 실패 시에도 발동<br><br>크레이지 발칸 상태 이상 보너스 추가<br>- 공격 범위 : 1개당 10%<br>- 쿨타임 감소 : 1개당 10%@@9484@@2016';

lv105ItemId['고고함의 백색 벨트'] = '19776f2e9cd9be54e84609e4c0aa1105';
lv105OptionMap['고고함의 백색 벨트'] = {};
lv105OptionMap['고고함의 백색 벨트'] = '최종 데미지 8% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5604@@1612';

lv105ItemId['안개의 뜻을 따르는 자의 상의'] = '638f3a2d3a65f23610cb096c9fb5bade';
lv105OptionMap['안개의 뜻을 따르는 자의 상의'] = {};
lv105OptionMap['안개의 뜻을 따르는 자의 상의'] = '최종 데미지 10% 증가<br>무력화 게이지 감소량 +10%<br><br>HP MAX +600<br>물리/마법 방어력 +7,000@@6144@@1928';

lv105ItemId['결전의 장도 - 스페셜리스트'] = 'e2198cef076a9381b12f723e0c748a4b';
lv105OptionMap['결전의 장도 - 스페셜리스트'] = {};
lv105OptionMap['결전의 장도 - 스페셜리스트'] = '인텐션 스택 사용 시 인텐션 스택 1개를 추가 소모하여 5초 동안 스킬 쿨타임 20% 감소 (각성기 제외)<br><br>CEAB-2를 인텐션 스택으로 강화 가능<br>- 강화 시 최대 충전 공격력 40% 증가<br>- 강화 시 다단히트 수 60% 충전 상태로 발동<br><br>인텐션 기본 스택 수 +2@@711@@2016';

lv105ItemId['성장하는 모험가의 선현궁'] = 'd2aa5c56e52f97a454cc7f328cd82299';
lv105OptionMap['성장하는 모험가의 선현궁'] = {};
lv105OptionMap['성장하는 모험가의 선현궁'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['광폭화된 전의의 머스켓'] = '56b10da13bec7688c9168a47f5df0f93';
lv105OptionMap['광폭화된 전의의 머스켓'] = {};
lv105OptionMap['광폭화된 전의의 머스켓'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 배틀액스 - 인파이터'] = '014cd73bfc684e61421da10a080dcaf2';
lv105OptionMap['결전의 배틀액스 - 인파이터'] = {};
lv105OptionMap['결전의 배틀액스 - 인파이터'] = '정의 집행 : 레미디오스의 성좌의 링크 스킬이 빅뱅 펀치일 때, 신의 분노를 최대 3회 충전 스킬로 변경<br>- 연타 공격력 100% 증가<br>- 연타 횟수 9회 감소<br>- 피니쉬 타격 및 폭발 삭제<br>- 적을 추적하여 공격<br>- 충전 쿨타임 : 40초<br>- 추적 범위 : 780px<br>- MP 소모량 -30%<br>- 무색 큐브 조각 소모량 5개 감소<br>- 머신건 잽 시전 시 신의 분노 남은 쿨타임 12% 감소<br>- 신의 분노를 캔슬하여 전직 스킬 시전 가능<br>- 전직 스킬을 캔슬하여 신의 분노 시전 시 드라이아웃 스택을 소모하지 않음@@5928@@2016';

lv105ItemId['얼어붙은 저항의 완드'] = '0a8a82bdf02854692d464d9e972c4e74';
lv105OptionMap['얼어붙은 저항의 완드'] = {};
lv105OptionMap['얼어붙은 저항의 완드'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 로드 - 인챈트리스'] = '500f04533c294a1acb2adde21aa93ebe';
lv105OptionMap['결전의 로드 - 인챈트리스'] = {};
lv105OptionMap['결전의 로드 - 인챈트리스'] = '금단의 저주 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>꼼꼼한 바느질 스킬 범위 20% 증가<br><br>테러블 로어 캐스팅 모션 삭제<br>테러블 로어 몹몰이 범위 10% 증가<br><br>커스 오브 테러베어의 브레스에 피격된 적에게 저주의 기운을 부여하고 폭발<br>- 폭발 피해 : 브레스 총 공격력의 50%<br><br>어둠에 피는 장미의 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['결전의 단검 - 로그'] = '5068d459dd3fd8c8b1d2ec9e0d27e19a';
lv105OptionMap['결전의 단검 - 로그'] = {};
lv105OptionMap['결전의 단검 - 로그'] = '허리케인을 최대 3회 충전 스킬로 변경<br>- 타격당 습득 히트포인트 100% 증가<br>- 이동속도 30% 증가<br>- 끌어들이는 힘 30% 증가<br><br>허리케인 시전 중 히트엔드 시전 시 아래의 효과 적용<br>- 남은 허리케인의 타격 데미지를 합산하여 공격<br>- 가장 강한 적에게 남은 단검 발사<br><br>데스 허리케인의 단검이 적을 관통하도록 변경되고, 발사 수 10개 감소@@9484@@2016';

lv105ItemId['상상을 담은 엔지니어의 작업 신발'] = '0855ec32a4da2091b3f010c152629074';
lv105OptionMap['상상을 담은 엔지니어의 작업 신발'] = {};
lv105OptionMap['상상을 담은 엔지니어의 작업 신발'] = '최종 데미지 19% 증가<br><br>이동 속도 +8%<br>물리/마법 피해 감소 +20%<br>적중률 +10%@@6144@@1948';

lv105ItemId['무뎌지는 둔화된 변화'] = 'cbd037a31b281da9b5064c9bae6df1c4';
lv105OptionMap['무뎌지는 둔화된 변화'] = {};
lv105OptionMap['무뎌지는 둔화된 변화'] = '최종 데미지 14% 증가<br>모든 속성 강화 +30<br><br>자신에게 해제되지 않는 화상 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +5%<br>HP MAX +600@@5066@@1948';

lv105ItemId['결전의 소태도 - 요원'] = '1941358bb6904f91b3fd8f890c329057';
lv105OptionMap['결전의 소태도 - 요원'] = {};
lv105OptionMap['결전의 소태도 - 요원'] = '암살목표의 표식이 부여된 적에게 일부 스킬 공격 시 15초 동안 총상 / 절상 부여 (최대 3중첩)<br>- 총상 부여 스킬 : 조준 사격, 목표 사격<br>- 절상 부여 스킬 : 쌍현참, 암월비보<br><br>총상 / 절상 상태의 적을 아래의 스킬로 공격 시 총상 / 절상이 해제되며 스킬 공격력 증가<br>- 문페이즈 : 총상 1중첩당 8%<br>- 섬멸 : 절상 1중첩당 8%<br>- 월광진혼곡 : 총상 / 절상 1중첩당 8%<br><br>아래의 스킬 쿨타임 15% 감소<br>- 쌍현참<br>- 조준 사격<br>- 암월비보<br>- 목표 사격<br><br>문페이즈 크기 10% 증가@@7112@@2016';

lv105ItemId['내딛은 자의 토템'] = 'aaee94f9149957353a543c5eda83c696';
lv105OptionMap['내딛은 자의 토템'] = {};
lv105OptionMap['내딛은 자의 토템'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['블랙 캣 헬멧'] = '0d6217278b7a421abee79e93a76e6e25';
lv105OptionMap['블랙 캣 헬멧'] = {};
lv105OptionMap['블랙 캣 헬멧'] = '1~30Lv 스킬 공격력 10% 증가<br>기본기 숙련 최종 데미지 20% 증가<br><br>평타, 점프 공격, 대시 공격 시 10% 확률로 공격력 300% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>적중률 +10%@@4528@@1968';

lv105ItemId['결전의 봉 - 배틀메이지'] = 'a647592eff952cb3b11fd101aff01dbc';
lv105OptionMap['결전의 봉 - 배틀메이지'] = {};
lv105OptionMap['결전의 봉 - 배틀메이지'] = '체이서 공격력 및 사출 개수 증가<br>- 한 번에 사출되는 체이서 개수 +2<br>- 공격력 45% 감소<br>- 50콤보 이상일 때 체이서 자동 사출<br>- 체이서 최대 생성 개수 +1<br>- 체이서 크기 비율 20% 증가<br>- 스킬 MP 소모량 -70%<br><br>한 번에 생성되는 체이서 개수 +1@@4744@@2016';

lv105ItemId['결전의 자동권총 - 레인저(여)'] = '39df90eef8bea220563e52e3e04174ec';
lv105OptionMap['결전의 자동권총 - 레인저(여)'] = {};
lv105OptionMap['결전의 자동권총 - 레인저(여)'] = '이동사격 탄환을 파열시켜 더욱 넓은 범위 공격<br>- 공격력 30% 증가<br>- 발사 수 50% 증가<br>- 쿨타임 20% 감소<br>- 공격 범위 10% 증가<br><br>이동사격 중 아래의 효과 적용<br>- 스킬 시전 시 쿨타임 즉시 적용<br>- 공격 속도 +30%<br>- 이동 속도 +30%<br>- 던전 방 이동 시 스킬 유지@@7708@@2016';

lv105ItemId['매니퓰레이션'] = '1447a94cebb0339fe3219d8002116e3a';
lv105OptionMap['매니퓰레이션'] = {};
lv105OptionMap['매니퓰레이션'] = '최종 데미지 13% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>스킬 MP 소모량 7% 감소@@4528@@1996';

lv105ItemId['자기장 탐지링'] = '49ff8f4240b7f3ec42d6989f4fc57f09';
lv105OptionMap['자기장 탐지링'] = {};
lv105OptionMap['자기장 탐지링'] = '최종 데미지 9% 증가<br><br>[인력 탐지]<br>다른 파티원이 "자기장 탐지링" 장착 시 최종 데미지 4% 증가<br><br>물리/마법 방어력 +7,000<br>회피율 +8%@@4258@@1920';

lv105ItemId['성장하는 모험가의 낡은 메달'] = '074990bbe6162ee6b0468bc7cca0321f';
lv105OptionMap['성장하는 모험가의 낡은 메달'] = {};
lv105OptionMap['성장하는 모험가의 낡은 메달'] = '최종 데미지 7% 증가@@5336@@1986';

lv105ItemId['성장하는 모험가의 클로'] = '4b1f419bf14185a22bb26e893ab5c0f4';
lv105OptionMap['성장하는 모험가의 클로'] = {};
lv105OptionMap['성장하는 모험가의 클로'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 로드 - 빙결사'] = '8a1e26a24e419baa7510834ccec77899';
lv105OptionMap['결전의 로드 - 빙결사'] = {};
lv105OptionMap['결전의 로드 - 빙결사'] = '아이스 크래프트 얼음창이 얼음 무기에 피격된 모든 적을 추적하여 꿰뚫음<br>- 얼음창 공격력 반영률 20% 추가 증가<br>- 얼음창 폭발 삭제<br>- 얼음창이 10% 확률로 적에게 2초 동안 빙결 상태 이상 부여<br><br>핀포인트 러쉬 시전 시 전방의 가장 강력한 적을 추적하여 주변의 적을 함께 공격<br>- 추적 범위 : 500px@@7112@@2016';

lv105ItemId['파워 플랜트'] = '2bd0eccd126fcdde214f75cb15f04776';
lv105OptionMap['파워 플랜트'] = {};
lv105OptionMap['파워 플랜트'] = '최종 데미지 8% 증가<br>스킬 쿨타임 회복 속도 +15% (각성기 제외)<br><br>공격 시 HP/MP 2,000 회복 (쿨타임 1초)<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>스킬 MP 소모량 -7%@@6414@@1968';

lv105ItemId['근원을 삼킨 도'] = 'dd76519f50159d14d778cd5ef8d0a1db';
lv105OptionMap['근원을 삼킨 도'] = {};
lv105OptionMap['근원을 삼킨 도'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['유쾌함 속의 열망'] = '26c58098c8d61c0cbf06b068b4e349a7';
lv105OptionMap['유쾌함 속의 열망'] = {};
lv105OptionMap['유쾌함 속의 열망'] = '최종 데미지 11% 증가<br><br>[주사위 굴리기]<br>30초마다 주사위를 굴려 나온 눈 1마다 이동 속도 +2%<br>- 공격 시 주사위 눈 +1<br><br>물리/마법 방어력 +10,000<br>적중률 +10%<br>HP 1분당 460.2 회복@@5066@@1968';

lv105ItemId['광폭화된 전의의 장창'] = '3600e78ae1c052e321d1b3fb26f718b9';
lv105OptionMap['광폭화된 전의의 장창'] = {};
lv105OptionMap['광폭화된 전의의 장창'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['성장하는 모험가의 차크라 웨펀'] = '2bddd6d828eff7f77ed64a20226cf61e';
lv105OptionMap['성장하는 모험가의 차크라 웨펀'] = {};
lv105OptionMap['성장하는 모험가의 차크라 웨펀'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['들이치는 희미한 탄식'] = '5670a44246fc1610b6d1fef45f9041ac';
lv105OptionMap['들이치는 희미한 탄식'] = {};
lv105OptionMap['들이치는 희미한 탄식'] = '최종 데미지 15% 증가<br><br>물리/마법 방어력 +7,000<br>MP 1분당 348 회복@@5874@@1958';

lv105ItemId['옥화의 망령 상박갑'] = '0581f407983a4e2b5cc34ef7d988454d';
lv105OptionMap['옥화의 망령 상박갑'] = {};
lv105OptionMap['옥화의 망령 상박갑'] = '최종 데미지 11% 증가<br><br>[배후 암살]<br>- 가장 강한 적에게 징표 생성<br>- 백스텝 시전 시 적의 후방으로 이동 (쿨타임 10초)<br><br>물리/마법 방어력 +7,000<br>모든 상태 이상 내성 +10%<br>회피율 +8%@@5604@@1920';

lv105ItemId['배회하는 혼령의 향로'] = '0f63eccb7d33ad89e615c2da1239c874';
lv105OptionMap['배회하는 혼령의 향로'] = {};
lv105OptionMap['배회하는 혼령의 향로'] = '최종 데미지 10% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br><br>대기, 걷기 시 이동 속도 +200% / Y축 이동 속도 +50%<br><br>물리/마법 방어력 +7,000<br>회피율 +8%@@4528@@1948';

lv105ItemId['결전의 단검 - 사령술사'] = '448c44507db3fec8174c441d565777ad';
lv105OptionMap['결전의 단검 - 사령술사'] = {};
lv105OptionMap['결전의 단검 - 사령술사'] = '리빙데드를 최대 3회 충전 스킬로 변경<br><br>레디아 블랙옥스가 직접 폭발<br>- 공격력 : 리빙데드 총 공격력의 45%<br>- 충전 쿨타임 : 15초<br>- 적에게 5초 동안 공포의 낙인 생성<br>- 추가 조작 기능 삭제<br><br>공포의 낙인이 생성된 적을 아래의 스킬로 공격 시 해당 스킬 남은 쿨타임 10% 감소<br>- 망자의 집념<br>- 블랙 아라크네아<br>- 익스큐셔너 슬래쉬@@8892@@2016';

lv105ItemId['얼어붙은 저항의 십자가'] = 'ccd204a27f657ff93889436ee63203e6';
lv105OptionMap['얼어붙은 저항의 십자가'] = {};
lv105OptionMap['얼어붙은 저항의 십자가'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['근원을 삼킨 토템'] = 'a4f4624a3387a0e163190394d7abc300';
lv105OptionMap['근원을 삼킨 토템'] = {};
lv105OptionMap['근원을 삼킨 토템'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['내딛은 자의 코어 블레이드'] = 'a9bc9fa75ada95efc911668bc5f2ddf7';
lv105OptionMap['내딛은 자의 코어 블레이드'] = {};
lv105OptionMap['내딛은 자의 코어 블레이드'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['사멸하는 신뢰의 클로'] = '42e58da97a81890214fcddcd31c1845a';
lv105OptionMap['사멸하는 신뢰의 클로'] = {};
lv105OptionMap['사멸하는 신뢰의 클로'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['성장하는 모험가의 권투글러브'] = '64c087735a02dd6304fe6cadf2d12772';
lv105OptionMap['성장하는 모험가의 권투글러브'] = {};
lv105OptionMap['성장하는 모험가의 권투글러브'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['푸른 자연의 씨앗'] = '44dfc7da6982301231dcd9ee9c22e979';
lv105OptionMap['푸른 자연의 씨앗'] = {};
lv105OptionMap['푸른 자연의 씨앗'] = '모든 속성 강화 +20<br>화상-빙결 연계로 인한 화상 피해 +35%<br><br>화상 지속 시간 +4초<br><br>공격 시 모든 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 5초)<br><br>수속성 저항 +10@@5604@@1948';

lv105ItemId['선회하는 흐려진 혜안'] = '2bba3d7f981f1755ad97b4684bae84a8';
lv105OptionMap['선회하는 흐려진 혜안'] = {};
lv105OptionMap['선회하는 흐려진 혜안'] = '최종 데미지 13% 증가<br><br>[배터리 폭탄]<br>공격 시 과열되어 폭발하는 배터리를 몸에 장착<br>- 5회 공격 시 배터리 과열<br>- 과열된 배터리는 3초 후 폭발<br>- 총 공격력 증가의 2,000%만큼 피해<br><br>물리/마법 크리티컬 히트 +7%<br>공격 속도 +20%<br>캐스팅 속도 +20%<br>이동 속도 +20%<br>회피율 +8%<br>HP 1분당 460.2 회복@@5604@@1948';

lv105ItemId['결전의 머스켓 - 어썰트'] = '50557d454a23c6bb31d2e5e3b83c2d42';
lv105OptionMap['결전의 머스켓 - 어썰트'] = {};
lv105OptionMap['결전의 머스켓 - 어썰트'] = '부스트 스킬로 전직 스킬 캔슬 시 전력 1스택 획득 (최대 5스택)<br><br>아래 스킬 시전 시 모든 전력을 소모하고, 소모한 전력당 공격력 10% 증가<br>- 레이시온 크리트<br>- 부스트 : 다이너마이트 킥<br><br>부스트 : 다이너마이트 킥 적중 시 적을 1.5초 동안 홀딩<br>- 소모한 전력 1스택당 홀딩 시간 0.5초 증가@@9484@@2016';

lv105ItemId['고양된 분노의 목걸이'] = '0ecc3af18fef87bd5615acb3178be133';
lv105OptionMap['고양된 분노의 목걸이'] = {};
lv105OptionMap['고양된 분노의 목걸이'] = '최종 데미지 9% 증가<br>모든 속성 강화 +20<br><br>200콤보 달성 시 보스/네임드 몬스터의 남은 HP 1% 감소 (쿨타임 30초 / 버퍼 제외)<br>- 최대 5% 감소 가능<br><br>MP 1분당 348 회복@@5240@@1953';

lv105ItemId['얼어붙은 저항의 클로'] = '68f2293dfc4082d20ea4db0cca59cce0';
lv105OptionMap['얼어붙은 저항의 클로'] = {};
lv105OptionMap['얼어붙은 저항의 클로'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['페이션트 나이트 숄더'] = '3ba0e13cc20622664bae1846c072de80';
lv105OptionMap['페이션트 나이트 숄더'] = {};
lv105OptionMap['페이션트 나이트 숄더'] = '최종 데미지 10% 증가<br>30~35Lv 스킬 공격력 5% 증가<br>스킬 범위 16% 증가<br>30~35Lv 스킬 범위 15% 증가@@6440@@1900';

lv105ItemId['깨지지 않는 빛바랜 신념'] = '1f4842f48c789643edfa8e2460c642c2';
lv105OptionMap['깨지지 않는 빛바랜 신념'] = {};
lv105OptionMap['깨지지 않는 빛바랜 신념'] = '암속성 강화 +70<br><br>암속성 저항 +20@@3450@@1958';

lv105ItemId['결전의 창 - 스위프트 마스터'] = '7e03eb08f1398d3e1aec56147fe58d68';
lv105OptionMap['결전의 창 - 스위프트 마스터'] = {};
lv105OptionMap['결전의 창 - 스위프트 마스터'] = '소닉 무브를 최대 3회 충전 스킬로 변경<br><br>소닉 무브 시전 시 회오리 바람 생성<br>- 소닉 무브 회오리 바람 공격력 : 회오리 바람 공격력의 50%<br><br>회오리 바람 크기 10% 증가<br><br>전직 스킬을 캔슬하여 소닉 무브, 대진공, 칼날 바람 시전 시 풍흔 1스택 획득 (최대 15스택)<br>- 풍흔이 15스택일 때 스톰 스트라이크 시전 시 모든 풍흔을 소모하여 공격력 50% 증가@@7112@@2016';

lv105ItemId['잠겨진 영역'] = 'd6fd0607a7a56808824dfcd65267862c';
lv105OptionMap['잠겨진 영역'] = {};
lv105OptionMap['잠겨진 영역'] = '모든 속성 강화 +20<br><br>[황금 만능주의]<br>인벤토리 및 금고에 보관 중인 골드가 4억 골드 이상일 때 최종 데미지 9% 증가<br><br>물리/마법 크리티컬 히트 +17%<br>공격 속도 +15%<br>캐스팅 속도 +15%<br>이동 속도 +15%<br>모든 상태 이상 내성 -10%@@5416@@1978';

lv105ItemId['결전의 대검 - 소울브링어'] = '0a5eabd58bd39deba79e7e85a8728375';
lv105OptionMap['결전의 대검 - 소울브링어'] = {};
lv105OptionMap['결전의 대검 - 소울브링어'] = '명계의 힘으로 흑염의 칼라가 명계의 불꽃을 발현<br>불꽃 최대 중첩 수 +2<br><br>아래 스킬들이 명계의 불꽃을 발현<br>명계의 불꽃이 붙은 대상을 특정 스킬로 공격 시 불꽃 폭발<br>- 폭발 공격력 :  흑염의 칼라 불꽃 공격력의 300%<br>- 불꽃 중첩당 폭발 공격력 10% 증가<br><br><명계의 불꽃 발현 스킬><br>- 흑염의 칼라<br>- 냉기의 사야<br>- 역병의 라사<br>- 툼스톤<br>- 툼스톤 스웜프<br>- 블레이드 팬텀<br><br><명계의 불꽃 폭발 스킬><br>- 귀참<br>- 달빛 베기<br>- 귀참 : 광폭<br>- 귀영섬<br>- 귀참 : 나락<br>- 흑염검<br>- 검귀강령 : 식<br>- 탐식의 크레바스@@5928@@2016';

lv105ItemId['미지의 문명 - 마스크 스톤'] = '72e6886ad80d5ae4e6576105da6e03c0';
lv105OptionMap['미지의 문명 - 마스크 스톤'] = {};
lv105OptionMap['미지의 문명 - 마스크 스톤'] = '최종 데미지 7% 증가<br><br>물리/마법 크리티컬 히트 +12%<br>HP MAX +600<br>수속성 저항 +50<br>모든 상태 이상 내성 -10%@@5510@@1963';

lv105ItemId['생명을 키우는 코발트 스틱'] = '88003cc317d46270bf0166fc09b0aaf0';
lv105OptionMap['생명을 키우는 코발트 스틱'] = {};
lv105OptionMap['생명을 키우는 코발트 스틱'] = '명속성 강화 +70<br><br>물리/마법 크리티컬 히트 +15%<br>명속성 저항 +10@@4528@@1958';

lv105ItemId['결전의 핸드캐넌 - 런처(여)'] = '418cbae359038b267bcf35ee02d86c31';
lv105OptionMap['결전의 핸드캐넌 - 런처(여)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 런처(여)'] = '화염 강타를 화염을 압축하여 발사하는 최대 3회 충전 스킬로 변경<br>- 공격력 : 총 공격력의 50%<br>- 충전 쿨타임 : 4초<br><br>M-3 화염방사기 시전 시 화염을 압축하여 발사<br><br>UHT-03 플레게톤 이동 속도 비율 +10%@@9484@@2016';

lv105ItemId['결전의 대검 - 데몬슬레이어'] = '7ae5468e0d81b999be9aa6d864f044ff';
lv105OptionMap['결전의 대검 - 데몬슬레이어'] = {};
lv105OptionMap['결전의 대검 - 데몬슬레이어'] = '사복검 - 발을 최대 3회 충전 스킬로 변경<br>- 솟아오르는 검 개수 +3<br>- 전직 스킬 공격 시 1회 충전 (사복검 - 발 제외)<br>- 자동 충전 불가능<br><br>전직 스킬의 후딜레이를 캔슬하여 사복검 - 발 시전 가능 (각성기 제외)<br><br>사복검 - 발 후딜레이를 캔슬하여 전직 스킬 시전 가능@@5928@@2016';

lv105ItemId['용살자의 증표 - 용골 뿔피리'] = 'a16a3c99ec8fa5222816a51bf4bc6ef9';
lv105OptionMap['용살자의 증표 - 용골 뿔피리'] = {};
lv105OptionMap['용살자의 증표 - 용골 뿔피리'] = '모든 속성 강화 +30<br>60Lv 액티브 스킬 Lv +10<br>- 제외 : 팬 서비스<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@4796@@1938';

lv105ItemId['수호룡의 비호 - 용기'] = '2238cdafe92a638f92eadb691d2256c2';
lv105OptionMap['수호룡의 비호 - 용기'] = {};
lv105OptionMap['수호룡의 비호 - 용기'] = '모든 속성 강화 +30<br>40Lv 액티브 스킬 Lv +10<br>- 제외 : 뇌명 : 사나운 빛의 넨수, 사랑의 응급조치, 생명의 원천, 부활, 인법 : 육도윤회<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@5874@@1938';

lv105ItemId['파괴된 생명'] = '26c3a6db42a57df6da985df3d91db2c3';
lv105OptionMap['파괴된 생명'] = {};
lv105OptionMap['파괴된 생명'] = '최종 데미지 10% 증가<br><br>물리/마법 크리티컬 히트 +20%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>적중률 +10%@@4528@@1958';

lv105ItemId['결전의 쌍검 - 로그'] = '04d64b6425d01213cfe416b6c99179d3';
lv105OptionMap['결전의 쌍검 - 로그'] = {};
lv105OptionMap['결전의 쌍검 - 로그'] = '허리케인을 최대 3회 충전 스킬로 변경<br>- 타격당 습득 히트포인트 100% 증가<br>- 이동속도 30% 증가<br>- 끌어들이는 힘 30% 증가<br><br>허리케인 시전 중 히트엔드 시전 시 아래의 효과 적용<br>- 남은 허리케인의 타격 데미지를 합산하여 공격<br>- 가장 강한 적에게 남은 단검 발사<br><br>데스 허리케인의 단검이 적을 관통하도록 변경되고, 발사 수 10개 감소@@9484@@2016';

lv105ItemId['광폭화된 전의의 광검'] = '24a87f1198a995fa632b7e7c750da61b';
lv105OptionMap['광폭화된 전의의 광검'] = {};
lv105OptionMap['광폭화된 전의의 광검'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 자동권총 - 스핏파이어(남)'] = '840199110b3c7c698e143b83656fa77b';
lv105OptionMap['결전의 자동권총 - 스핏파이어(남)'] = {};
lv105OptionMap['결전의 자동권총 - 스핏파이어(남)'] = '버스터 샷이 가장 강한 적에게 지원 포격을 발사하도록 변경<br>- 다른 스킬 사용 중 모션 없이 시전 가능<br><br>오버차지 적용 중 평타 10회 공격 시 버스터샷 강화 탄약 충전 (최대 3개)<br>- 버스터샷 시전 시 강화 탄약 소모<br>- 탄약 1개 소모 : 공격력 15% 증가<br>- 탄약 2개 소모 : 공격력 40% 증가<br>- 탄약 3개 소모 : 공격력 80% 증가<br>- 던전 입장 시 탄약 1개 장착@@7112@@2016';

lv105ItemId['사멸하는 신뢰의 단검'] = 'c9e2d847732827147b8ef409db37d86a';
lv105OptionMap['사멸하는 신뢰의 단검'] = {};
lv105OptionMap['사멸하는 신뢰의 단검'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['전술 드론 콘트롤러 암릿'] = '4a8730f1b3efec704f3c4e00517cccec';
lv105OptionMap['전술 드론 콘트롤러 암릿'] = {};
lv105OptionMap['전술 드론 콘트롤러 암릿'] = '최종 데미지 12% 증가<br>스킬 쿨타임 12% 감소 (각성기 제외)<br>모든 속성 강화 +20<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%@@5066@@1948';

lv105ItemId['광폭화된 전의의 리볼버'] = '95a9cdefaebc92f751b369638b78f7a6';
lv105OptionMap['광폭화된 전의의 리볼버'] = {};
lv105OptionMap['광폭화된 전의의 리볼버'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 창 - 마도학자'] = '2473e3f85f9f5829380e42cd8bf2c0a1';
lv105OptionMap['결전의 창 - 마도학자'] = {};
lv105OptionMap['결전의 창 - 마도학자'] = '롤리팝 크러쉬가 더욱 많은 사탕 인형 생성<br>- 최대 사탕 생성 개수 +5<br>- 피격개체당 사탕인형 생성 수 +2<br>- 생성된 사탕인형 1개당 롤리팝 크러쉬의 남은 쿨타임 3% 감소<br>- 사탕인형 공격력 20% 감소<br><br>폭발하지 않은 사탕 인형을 흡수하여 다음 롤리팝 크러쉬 때 생성 (최대 15개)<br>- 사탕당 흡수 1번 가능<br>- 추가 생성된 사탕 인형은 최대 사탕 생성 개수에 미포함@@8300@@2016';

lv105ItemId['결전의 스태프 - 소환사'] = '143dcbb8a1c8b459bcd274c747d0258a';
lv105OptionMap['결전의 스태프 - 소환사'] = {};
lv105OptionMap['결전의 스태프 - 소환사'] = '상급 정령들과 정령왕 에체베리아의 이클립스 하이브 추가 조작 패턴 공격력 20% 증가<br><br>이클립스 하이브 강화<br>- 추가 조작 패턴 캐스팅 모션 삭제 <br>- 데드 멀커 : 블랙스웜 홀딩 추가<br>- 글레어린 : 썬더콜링이 적을 끌어모음<br>- 아퀘리스 : 헵타 아이스 미사일이 지상 폭격<br>- 플레임 헐크 : 화염폭검술이 회오리를 생성하고 적을 끌어모음<br>- 에체베리아 : 초고밀도 레이저가 다수 레이저의 지상 폭격으로 변경@@8300@@2016';

lv105ItemId['결전의 둔기 - 데몬슬레이어'] = '63246c6f2b9a33a4b0c430210c902e11';
lv105OptionMap['결전의 둔기 - 데몬슬레이어'] = {};
lv105OptionMap['결전의 둔기 - 데몬슬레이어'] = '사복검 - 발을 최대 3회 충전 스킬로 변경<br>- 솟아오르는 검 개수 +3<br>- 전직 스킬 공격 시 1회 충전 (사복검 - 발 제외)<br>- 자동 충전 불가능<br><br>전직 스킬의 후딜레이를 캔슬하여 사복검 - 발 시전 가능 (각성기 제외)<br><br>사복검 - 발 후딜레이를 캔슬하여 전직 스킬 시전 가능@@5928@@2016';

lv105ItemId['결전의 너클 - 넨마스터(남)'] = '21a04e5c7fa1137266c1f8978d4278a9';
lv105OptionMap['결전의 너클 - 넨마스터(남)'] = {};
lv105OptionMap['결전의 너클 - 넨마스터(남)'] = '기호지세의 넨수가 포효 후 폭발하도록 변경<br>- 포효 공격 다단히트 수 : 4<br>- 포효 공격력 : 기호지세 다단히트 공격력의 100%<br>- 폭발 공격력 : 기호지세 폭발 공격력의 100%<br>- 포효에 적중된 적을 끌어당김<br>- 스킬 범위 20% 증가<br><br>광호천지파의 나선의 넨이 스스로 회전 후 폭발<br>- 시전 속도 20% 증가<br>- 구체 및 폭발 범위 10% 증가@@10076@@2016';

lv105ItemId['결전의 클로 - 넨마스터(여)'] = '463cf3632f67db7dcc59202c46fa6ccb';
lv105OptionMap['결전의 클로 - 넨마스터(여)'] = {};
lv105OptionMap['결전의 클로 - 넨마스터(여)'] = '축염포 충전 시간 및 쿨타임 삭제<br><br>넨탄의 쿨타임이 증가하는 대신 공격력과 크기가 증가<br>- 공격력 120% 증가<br>- 크기 20% 증가<br>- 쿨타임 4초 증가<br>- 넨마스터 전직 쿨타임 감소 미적용<br><br>넨탄 시전 시 아래의 효과 적용<br>- 흡기공탄의 남은 쿨타임 5% 감소<br>- 10% 확률로 넨탄 공격력 500% 증가@@8300@@2016';

lv105ItemId['결전의 소검 - 다크템플러'] = '34b6dcd93f7123148f98faf7d6b5b2a9';
lv105OptionMap['결전의 소검 - 다크템플러'] = {};
lv105OptionMap['결전의 소검 - 다크템플러'] = '이보브 영혼 최대 흡수 개수 +30<br><br>아래 스킬의 추가 영혼 흡수 개수 +5<br>- 리버레이트 - 매드니스<br>- 디스트로이어<br>- 라케르쏜즈<br><br>리버레이트 - 벅샷 발사하는 에너지 1개당 소모되는 영혼의 수 +2<br>- 타겟을 잡는 최대 거리 20% 증가<br><br>리버레이트 - 벅샷으로 소모한 영혼의 수 2개마다 아래 스킬의 남은 쿨타임 1% 감소 (베스퍼스 효과 미적용)<br>- 디스트로이어<br>- 데우스 엑스 레이<br>- 지하드<br>- 라케르쏜즈@@5336@@2016';

lv105ItemId['가이드 라인 벨트'] = '119c2ed5d3abb1ef627611f9fa91a598';
lv105OptionMap['가이드 라인 벨트'] = {};
lv105OptionMap['가이드 라인 벨트'] = '무색 큐브 조각을 소모하지 않는 스킬 공격력 20% 증가<br><br>공격 시 MP 3,500 회복 (쿨타임 1초)<br><br>물리/마법 피해 감소 +15%<br>적중률 +10%<br>스킬 MP 소모량 -30%@@4917@@1963';

lv105ItemId['퍼스트 스페이드 - 노블레스'] = '76497fb8b232f1a56e96de7511ed2b0d';
lv105OptionMap['퍼스트 스페이드 - 노블레스'] = {};
lv105OptionMap['퍼스트 스페이드 - 노블레스'] = '최종 데미지 3% 증가<br><br>[화이트 매지션]<br>해당 장비의 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@4528@@1612';

lv105ItemId['사멸하는 신뢰의 도'] = '4592ae18eb1fed6a789c4fd589e3c956';
lv105OptionMap['사멸하는 신뢰의 도'] = {};
lv105OptionMap['사멸하는 신뢰의 도'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['내면의 얼굴'] = 'a10fb66fa4ac53012cdce77fb3cbbeee';
lv105OptionMap['내면의 얼굴'] = {};
lv105OptionMap['내면의 얼굴'] = '최종 데미지 7% 증가<br><br>물리/마법 크리티컬 히트 +12%<br>HP MAX +600<br>화속성 저항 +50<br>모든 상태 이상 내성 -10%@@5510@@1963';

lv105ItemId['근원을 삼킨 스태프'] = '38c0dffd2566095aa462a8962e9f9737';
lv105OptionMap['근원을 삼킨 스태프'] = {};
lv105OptionMap['근원을 삼킨 스태프'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['결전의 보우건 - 메카닉(여)'] = 'e393e217944dc85e61c668e3427013a8';
lv105OptionMap['결전의 보우건 - 메카닉(여)'] = {};
lv105OptionMap['결전의 보우건 - 메카닉(여)'] = 'G-S.P. 팔콘 쿨타임 20% 감소<br><br>G-1 코로나 상태에서 롤링썬더 또는 랩터로 트랜스폼 시 8초 동안 홀로그램 G-1 코로나 생성<br>- 공격력 : G-1 코로나 공격력의 50%<br>- G 마그네틱 시전 시 홀로그램 G-1 코로나가 대신 시전<br><br>트랜스폼 : G-3 랩터가 강화 레이저 발사<br>- 강화 레이저 발사@@8300@@2016';

lv105ItemId['생명의 근원이 담긴 배낭'] = 'b9d2c7586b86aa09a3536ebd6c9358ba';
lv105OptionMap['생명의 근원이 담긴 배낭'] = {};
lv105OptionMap['생명의 근원이 담긴 배낭'] = '최종 데미지 7% 증가<br><br>물리/마법 크리티컬 히트 +12%<br>HP MAX +600<br>명속성 저항 +50<br>모든 상태 이상 내성 -10%@@5510@@1963';

lv105ItemId['결전의 통파 - 넨마스터(여)'] = 'd3d58921b8ea6b802815489a495178c5';
lv105OptionMap['결전의 통파 - 넨마스터(여)'] = {};
lv105OptionMap['결전의 통파 - 넨마스터(여)'] = '축염포 충전 시간 및 쿨타임 삭제<br><br>넨탄의 쿨타임이 증가하는 대신 공격력과 크기가 증가<br>- 공격력 120% 증가<br>- 크기 20% 증가<br>- 쿨타임 4초 증가<br>- 넨마스터 전직 쿨타임 감소 미적용<br><br>넨탄 시전 시 아래의 효과 적용<br>- 흡기공탄의 남은 쿨타임 5% 감소<br>- 10% 확률로 넨탄 공격력 500% 증가@@8300@@2016';

lv105ItemId['근력 보강 숄더'] = '69a9cabeca43c5507f8864f6278b4a37';
lv105OptionMap['근력 보강 숄더'] = {};
lv105OptionMap['근력 보강 숄더'] = '최종 데미지 10% 증가<br>스킬 범위 10% 증가<br><br>드랍된 아이템 획득 시 30초 동안 이동 속도 +30%<br><br>HP MAX +300<br>MP MAX +473<br>인벤 토리 무게 한도 +5kg<br>MP 1분당 348 회복@@5604@@1880';

lv105ItemId['찬란한 금장 팔찌'] = '4e5ca11095e1e33029953df70116511c';
lv105OptionMap['찬란한 금장 팔찌'] = {};
lv105OptionMap['찬란한 금장 팔찌'] = '최종 데미지 16.5% 증가<br>모든 속성 강화 +20<br><br>자신에게 해제되지 않는 출혈/중독/화상/감전 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +7%<br>HP MAX +600<br>물리/마법 피해 감소 +20%<br>모든 상태 이상 내성 -10%@@6048@@1953';

lv105ItemId['성장하는 모험가의 중검'] = '4484dbb116e845955a7561ef94e3815f';
lv105OptionMap['성장하는 모험가의 중검'] = {};
lv105OptionMap['성장하는 모험가의 중검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['내딛은 자의 건틀릿'] = '7eb82ddbf965aa8b977a3c90a35efa73';
lv105OptionMap['내딛은 자의 건틀릿'] = {};
lv105OptionMap['내딛은 자의 건틀릿'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 배틀액스 - 퇴마사'] = '94a46963183c21867e518abda4f2a818';
lv105OptionMap['결전의 배틀액스 - 퇴마사'] = {};
lv105OptionMap['결전의 배틀액스 - 퇴마사'] = '공의 식신 - 백호 스킬 공격력 20% 증가<br>난격의 매 타격이 충격파 생성<br>- 쿨타임 20% 감소<br>- 난격 횟수 100% 증가<br>- 막타 삭제<br>- 막타 공격력은 난격 공격력에 합산<br><br>광명의 챠크라 적용 중 무쌍격 스킬 공격력 20% 증가<br>무쌍격 범위 20% 증가<br><br>대시 중 무쌍격 시전 시 전방으로 돌진하며 시전<br>@@4148@@2016';

lv105ItemId['내딛은 자의 장궁'] = '5216a77d6c998cb20bd7d39c229d91bf';
lv105OptionMap['내딛은 자의 장궁'] = {};
lv105OptionMap['내딛은 자의 장궁'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 클로 - 그래플러(남)'] = '601b43064e7ea208a7d3c556016816a7';
lv105OptionMap['결전의 클로 - 그래플러(남)'] = {};
lv105OptionMap['결전의 클로 - 그래플러(남)'] = '롤링 스파이크를 메치기 시 지진이 발생하는 최대 2회 충전 스킬로 변경<br>- 지진 공격력 : 메치기 공격력의 50%<br>- 충전 쿨타임 : 12초<br>- 후딜레이를 캔슬하여 전직 스킬 시전 가능<br><br>아래의 스킬 잡기 실패 시 해당 스킬 쿨타임 5초로 감소 (쿨타임 10초)<br>- 브레이크 다운<br>- 스냅 샷<br>- 블로우 스트라이크(지상 사용)<br>- 롤링 스파이크<br>- 나의 공격리듬은!(지상 사용)<br>- 한놈만 팬다!!<br>- 흑진광풍@@5928@@2016';

lv105ItemId['내딛은 자의 소태도'] = '563b50408d9395d1e5fd3833a31745a4';
lv105OptionMap['내딛은 자의 소태도'] = {};
lv105OptionMap['내딛은 자의 소태도'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['얼어붙은 저항의 통파'] = '65bff3906d27dc5cd8a1515ec981a4cc';
lv105OptionMap['얼어붙은 저항의 통파'] = {};
lv105OptionMap['얼어붙은 저항의 통파'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['올곧은 자의 이념'] = '51546ea687dc28b4d0cc9a67dc101585';
lv105OptionMap['올곧은 자의 이념'] = {};
lv105OptionMap['올곧은 자의 이념'] = '공격력 증가 +355.7%<br>모든 속성 강화 +18<br>장비로 발동하는 오브젝트 데미지 +30%<br><br>공격 시 모든 적을 불태움 (쿨타임 5초)<br>- 총 공격력 증가의 140%만큼 피해<br><br>모든 속성 저항 +8@@4258@@1968';

lv105ItemId['성장하는 모험가의 핸드캐넌'] = '0b6386e655857ecd1bfbc075143ec5ba';
lv105OptionMap['성장하는 모험가의 핸드캐넌'] = {};
lv105OptionMap['성장하는 모험가의 핸드캐넌'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['광폭화된 전의의 염주'] = '76f30342aa24e7f2cfa4627290ba77d1';
lv105OptionMap['광폭화된 전의의 염주'] = {};
lv105OptionMap['광폭화된 전의의 염주'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['얼어붙은 저항의 로드'] = '677b9aab4987adb68551e1874450a58d';
lv105OptionMap['얼어붙은 저항의 로드'] = {};
lv105OptionMap['얼어붙은 저항의 로드'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 광창 - 듀얼리스트'] = '1373627f085196fab83b9a2347e54925';
lv105OptionMap['결전의 광창 - 듀얼리스트'] = {};
lv105OptionMap['결전의 광창 - 듀얼리스트'] = '아래 스킬 적중 시 뇌격점혈섬을 강화하는 극 점혈 획득 (최대 30개)<br>- 레조넌스 임페일 : 타격당 10개<br>- 쿼드러플 드라이브 : 타격당 5개<br>- 파동연환창 : 타격당 4개<br>- 트윈 펀토 : 타격당 2개<br>- 퀵 펀토 : 타격당 1개<br><br>보유한 극 점혈 1개당 뇌격점혈섬 강화<br>- 스킬 쿨타임 회복 속도 : +1%<br>- 공격 속도 : +1%<br><br>뇌격점혈섬 공격 시 모든 극 점혈을 소모하여 극 점혈 1개당 창끝 점혈 히트 수 추가 적용<br><br>뇌격점혈섬 공격 속도 +10%@@10672@@2016';

lv105ItemId['솔저 호너 네클리스'] = '1be7fe216b60a08ad268bec2556544c1';
lv105OptionMap['솔저 호너 네클리스'] = {};
lv105OptionMap['솔저 호너 네클리스'] = '스킬 쿨타임 회복 속도 +20% (각성기 제외)<br>화속성 강화 +20<br>장비 오브젝트 데미지 +10%<br><br>공격 시 화속성 폭발 발생<br>- 총 공격력 증가의 600%만큼 피해<br><br>화속성 저항 +10@@5336@@1948';

lv105ItemId['결전의 스태프 - 엘레멘탈 바머'] = '3f672dde6aca44fe372be407fac0f8e0';
lv105OptionMap['결전의 스태프 - 엘레멘탈 바머'] = {};
lv105OptionMap['결전의 스태프 - 엘레멘탈 바머'] = '엘레멘탈 캐넌 시전 시 속성 마법구 발사 대신 전방에 어비스 캐넌 폭발<br>- 공격력 50% 증가<br>- 쿨타임 5초 증가<br>- 다른 스킬 시전 중 시전 가능<br>- 엘레멘탈 포스 1개당 공격력 10% 추가 증가<br><br>엘레멘탈 캐넌 시전 시 5초 동안 스킬 쿨타임 회복 속도 증가 (각성기 제외)<br>- 소모한 엘레멘탈 포스 1개당 6%<br><br>컨버전스 캐넌, 컨버전스 리액트 시전 시 엘레멘탈 캐넌 쿨타임 초기화@@5336@@2016';

lv105ItemId['내딛은 자의 염주'] = '0f23308f100c89df8feca4277bbf3f2c';
lv105OptionMap['내딛은 자의 염주'] = {};
lv105OptionMap['내딛은 자의 염주'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['광폭화된 전의의 장궁'] = 'f154d57f15d20b311870ceeb66aa3a19';
lv105OptionMap['광폭화된 전의의 장궁'] = {};
lv105OptionMap['광폭화된 전의의 장궁'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 봉 - 엘레멘탈 바머'] = 'bbf9ba5e5ad830228ab466e2bc6f4e5d';
lv105OptionMap['결전의 봉 - 엘레멘탈 바머'] = {};
lv105OptionMap['결전의 봉 - 엘레멘탈 바머'] = '엘레멘탈 캐넌 시전 시 속성 마법구 발사 대신 전방에 어비스 캐넌 폭발<br>- 공격력 50% 증가<br>- 쿨타임 5초 증가<br>- 다른 스킬 시전 중 시전 가능<br>- 엘레멘탈 포스 1개당 공격력 10% 추가 증가<br><br>엘레멘탈 캐넌 시전 시 5초 동안 스킬 쿨타임 회복 속도 증가 (각성기 제외)<br>- 소모한 엘레멘탈 포스 1개당 6%<br><br>컨버전스 캐넌, 컨버전스 리액트 시전 시 엘레멘탈 캐넌 쿨타임 초기화@@5336@@2016';

lv105ItemId['결전의 핸드캐넌 - 어썰트'] = '78783ed45208bdbd02dbb2cd020fa064';
lv105OptionMap['결전의 핸드캐넌 - 어썰트'] = {};
lv105OptionMap['결전의 핸드캐넌 - 어썰트'] = '부스트 스킬로 전직 스킬 캔슬 시 전력 1스택 획득 (최대 5스택)<br><br>아래 스킬 시전 시 모든 전력을 소모하고, 소모한 전력당 공격력 10% 증가<br>- 레이시온 크리트<br>- 부스트 : 다이너마이트 킥<br><br>부스트 : 다이너마이트 킥 적중 시 적을 1.5초 동안 홀딩<br>- 소모한 전력 1스택당 홀딩 시간 0.5초 증가@@9484@@2016';

lv105ItemId['내딛은 자의 권투글러브'] = '1d1ede995cbc19ceb939051a9fac5583';
lv105OptionMap['내딛은 자의 권투글러브'] = {};
lv105OptionMap['내딛은 자의 권투글러브'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 머스켓 - 메카닉(여)'] = 'c6f76bcf8eb42c6aa6736f8a5502ee1f';
lv105OptionMap['결전의 머스켓 - 메카닉(여)'] = {};
lv105OptionMap['결전의 머스켓 - 메카닉(여)'] = 'G-S.P. 팔콘 쿨타임 20% 감소<br><br>G-1 코로나 상태에서 롤링썬더 또는 랩터로 트랜스폼 시 8초 동안 홀로그램 G-1 코로나 생성<br>- 공격력 : G-1 코로나 공격력의 50%<br>- G 마그네틱 시전 시 홀로그램 G-1 코로나가 대신 시전<br><br>트랜스폼 : G-3 랩터가 강화 레이저 발사<br>- 강화 레이저 발사@@8300@@2016';

lv105ItemId['결전의 자동권총 - 메카닉(여)'] = '2a14c81dded018d148023cb3e66ff9d4';
lv105OptionMap['결전의 자동권총 - 메카닉(여)'] = {};
lv105OptionMap['결전의 자동권총 - 메카닉(여)'] = 'G-S.P. 팔콘 쿨타임 20% 감소<br><br>G-1 코로나 상태에서 롤링썬더 또는 랩터로 트랜스폼 시 8초 동안 홀로그램 G-1 코로나 생성<br>- 공격력 : G-1 코로나 공격력의 50%<br>- G 마그네틱 시전 시 홀로그램 G-1 코로나가 대신 시전<br><br>트랜스폼 : G-3 랩터가 강화 레이저 발사<br>- 강화 레이저 발사@@8300@@2016';

lv105ItemId['성장하는 모험가의 신발'] = 'null';
lv105OptionMap['성장하는 모험가의 신발'] = {};
lv105OptionMap['성장하는 모험가의 신발'] = '최종 데미지 7% 증가@@533@@1986';

lv105ItemId['결전의 핸드캐넌 - 레인저(남)'] = 'c4953cbcf674a6907ee8603f393c8827';
lv105OptionMap['결전의 핸드캐넌 - 레인저(남)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 레인저(남)'] = '더블 건호크 강화<br>- 세 번째 건호크 잡기 성공 시 두 번째 건호크 투척 이후 사용한 스킬들의 남은 쿨타임 20% 감소<br>- 크기 10% 증가<br>- 시전 시 4초 동안 공격 속도 +30%<br><br>스타일리쉬 캔슬 가능 최대 횟수 +1<br><br>장착 시 스펙트럼 생성@@6520@@2016';

lv105ItemId['기록자의 보석'] = '1db1bc5440c65d2810da83815bfbe7ae';
lv105OptionMap['기록자의 보석'] = {};
lv105OptionMap['기록자의 보석'] = '최종 데미지 12% 증가<br><br>회복형 소모품 사용 시 [충전형 보호막] 자동 충전 시작<br><br>장비 보호막 MAX 20% 증가<br><br>HP MAX +5%<br>회피율 +8%@@4528@@1928';

lv105ItemId['테크놀로지 바디캠 네클리스'] = '2348462dcc1d179d1994bc141e05576e';
lv105OptionMap['테크놀로지 바디캠 네클리스'] = {};
lv105OptionMap['테크놀로지 바디캠 네클리스'] = '모든 속성 강화 +20<br><br>커맨드로 스킬 시전 시 퀵슬롯 등록 여부에 따라 아래의 효과 적용<br>- 퀵슬롯 등록 : 최종 데미지 10% 증가<br>- 퀵슬롯 미등록 : 스킬 쿨타임 15% 감소 (각성기 제외)<br><br>물리/마법 크리티컬 히트 +7%<br>모든 상태 이상 내성 -10%@@4876@@1958';

lv105ItemId['사멸하는 신뢰의 완드'] = '0ec87587e9f714bc481290512c275612';
lv105OptionMap['사멸하는 신뢰의 완드'] = {};
lv105OptionMap['사멸하는 신뢰의 완드'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['천상을 수호하는 윙 아머'] = '060b80f5a6fb8820c7bf56f2c2946c78';
lv105OptionMap['천상을 수호하는 윙 아머'] = {};
lv105OptionMap['천상을 수호하는 윙 아머'] = '최종 데미지 8% 증가<br><br>[아이기스의 방패]<br>[장비 옵션 조작키] 입력 시 5초 동안 아이기스의 방패 소환 (쿨타임 45초)<br>- 방패 HP : HP MAX의 30%<br>- 원거리 공격 방어 및 적 이동 제한<br><br>공격 시 HP 2% + 2,200 회복 (쿨타임 1초)<br><br>HP MAX +600<br>물리/마법 방어력 +20%@@4647@@1963';

lv105ItemId['결전의 리볼버 - 런처(여)'] = '253bdeac9d6fd3a136ff260d0fb04424';
lv105OptionMap['결전의 리볼버 - 런처(여)'] = {};
lv105OptionMap['결전의 리볼버 - 런처(여)'] = '화염 강타를 화염을 압축하여 발사하는 최대 3회 충전 스킬로 변경<br>- 공격력 : 총 공격력의 50%<br>- 충전 쿨타임 : 4초<br><br>M-3 화염방사기 시전 시 화염을 압축하여 발사<br><br>UHT-03 플레게톤 이동 속도 비율 +10%@@9484@@2016';

lv105ItemId['근원을 삼킨 선현궁'] = '83574c5d07f2e4cd596ee751bca6fd83';
lv105OptionMap['근원을 삼킨 선현궁'] = {};
lv105OptionMap['근원을 삼킨 선현궁'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['대지를 수호하는 윙 벨트'] = '462d8d3f41b8815abb7c39a9f0dc5d08';
lv105OptionMap['대지를 수호하는 윙 벨트'] = {};
lv105OptionMap['대지를 수호하는 윙 벨트'] = '최종 데미지 13% 증가<br><br>[빛의 영역]<br>공격 시 빛의 영역 생성 (쿨타임 1초)<br>- 영역 내 물리/마법 피해 감소 +10%<br>- 영역 밖으로 이동 시 영역 소멸<br><br>물리/마법 크리티컬 히트 +5%<br>HP MAX +10%<br>HP MAX +600<br>모든 상태 이상 내성 +10%@@6144@@1928';

lv105ItemId['희생으로 맞이한 여명'] = '3388f154175b71535e1c0ce8ad86261c';
lv105OptionMap['희생으로 맞이한 여명'] = {};
lv105OptionMap['희생으로 맞이한 여명'] = '최종 데미지 9% 증가<br><br>HP MAX 10% 수치의 [충전형 보호막] 부여<br><br>HP MAX +600<br>HP 1분당 460.2 회복@@6414@@1900';

lv105ItemId['결전의 건틀릿 - 넨마스터(남)'] = '1924ba14919413dce197a4ed198173a4';
lv105OptionMap['결전의 건틀릿 - 넨마스터(남)'] = {};
lv105OptionMap['결전의 건틀릿 - 넨마스터(남)'] = '기호지세의 넨수가 포효 후 폭발하도록 변경<br>- 포효 공격 다단히트 수 : 4<br>- 포효 공격력 : 기호지세 다단히트 공격력의 100%<br>- 폭발 공격력 : 기호지세 폭발 공격력의 100%<br>- 포효에 적중된 적을 끌어당김<br>- 스킬 범위 20% 증가<br><br>광호천지파의 나선의 넨이 스스로 회전 후 폭발<br>- 시전 속도 20% 증가<br>- 구체 및 폭발 범위 10% 증가@@10076@@2016';

lv105ItemId['꿈같은 환호성'] = '148b87e4558b32884365ac2566cb2c6e';
lv105OptionMap['꿈같은 환호성'] = {};
lv105OptionMap['꿈같은 환호성'] = '스킬 쿨타임 회복 속도 +50% (각성기 제외)<br>- "오랜 시간 품어온 소원" 장비 장착 필요<br><br>회피율 +8%<br>HP MAX +600<br>수면 내성 -10%@@4528@@1948';

lv105ItemId['성장하는 모험가의 십자가'] = '653d0aa92c0c7b46ecb96d64dba020f0';
lv105OptionMap['성장하는 모험가의 십자가'] = {};
lv105OptionMap['성장하는 모험가의 십자가'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['헥타곤 임펄스 브릭'] = '0a17d035a66e06375a4a75a0db91dc93';
lv105OptionMap['헥타곤 임펄스 브릭'] = {};
lv105OptionMap['헥타곤 임펄스 브릭'] = '공격력 증가 +133.4%<br>최종 데미지 10% 증가<br><br>자신이 적에게 적용한 석화 상태 이상의 받는 데미지 감소 효과 제거<br><br>공격 시 모든 적에게 10초 동안 석화 상태 이상 부여 (쿨타임 15초)<br><br>석화 지속 시간 +3초<br><br>공격 속도 +8%<br>캐스팅 속도 +12%@@5604@@1938';

lv105ItemId['얼어붙은 저항의 배틀액스'] = '3d2aca331b2f570727197e7bf91b4f5f';
lv105OptionMap['얼어붙은 저항의 배틀액스'] = {};
lv105OptionMap['얼어붙은 저항의 배틀액스'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 토템 - 미스트리스'] = '5cc548a086e5ed84cb5daaeb82aff916';
lv105OptionMap['결전의 토템 - 미스트리스'] = {};
lv105OptionMap['결전의 토템 - 미스트리스'] = '타락의 칠죄종 변신 중 죄를 깨우는 손길 공격 시 변신 지속 시간 +12초<br>- 최대 변신 지속 시간 초과 불가능<br><br>글러트니 바밋 시전 시 자신을 중심으로 적을 흡수하고 폭발<br>- 공격력 30% 증가<br>- 죄업 스택 3개 이상일 때 시전 시 죄업 스택을 3개 소모하고 분신이 대신 시전<br><br>죄업 스택이 3개 이상일 때 무거운 죄업 시전 시 죄업 스택을 3개 소모하고 10초 동안 물리/마법 피해 감소 +15%<br><br>일곱 개의 대죄 적용 중 무거운 죄업 적 홀딩 시간 +1초<br><br>죄를 깨우는 손길 죄업 스택 증가량 +2@@770@@2016';

lv105ItemId['광폭화된 전의의 소태도'] = 'b4112b72d753f92f85e313d8520e5bff';
lv105OptionMap['광폭화된 전의의 소태도'] = {};
lv105OptionMap['광폭화된 전의의 소태도'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 대검 - 블레이드'] = '874692777f390e64557799e571db8bc9';
lv105OptionMap['결전의 대검 - 블레이드'] = {};
lv105OptionMap['결전의 대검 - 블레이드'] = '납도술 연계 시 납도술 스킬과 연계한 스킬의 남은 쿨타임 15% 감소<br>- 각성기로 납도술 연계 시 미적용<br><br>러스티 네일 베기 공격력이 삭제되고, 후딜레이를 캔슬하여 전직 스킬 시전 가능<br>- 베기 공격력을 발차기 공격력에 합산<br><br>전직 스킬 후딜레이를 캔슬하여 러스티 네일 시전 가능<br>- 셰이커 블래스트, 각성기 제외@@8892@@2016';

lv105ItemId['결전의 염주 - 퇴마사'] = '2b62ac04c964b8728956d31abcfa8ebb';
lv105OptionMap['결전의 염주 - 퇴마사'] = {};
lv105OptionMap['결전의 염주 - 퇴마사'] = '공의 식신 - 백호 스킬 공격력 20% 증가<br>난격의 매 타격이 충격파 생성<br>- 쿨타임 20% 감소<br>- 난격 횟수 100% 증가<br>- 막타 삭제<br>- 막타 공격력은 난격 공격력에 합산<br><br>광명의 챠크라 적용 중 무쌍격 스킬 공격력 20% 증가<br>무쌍격 범위 20% 증가<br><br>대시 중 무쌍격 시전 시 전방으로 돌진하며 시전<br>@@4148@@2016';

lv105ItemId['결전의 도 - 소드마스터'] = '2af3ea54e71846d695947854b95b1b2a';
lv105OptionMap['결전의 도 - 소드마스터'] = {};
lv105OptionMap['결전의 도 - 소드마스터'] = '궁극의 마검 레바테인으로 악즉참 시전<br>- 악즉참 항상 최대 충전<br>- 악즉참 공격력 15% 감소<br><br>레바테인에 의한 악즉참은 적에게 4초 동안 상흔을 부여<br>- 상흔이 생성된 적에게 반월 또는 섬광 적중 시 악즉참 남은 쿨타임 10% 감소@@8892@@2016';

lv105ItemId['근원을 삼킨 창'] = 'abaae57b08eb4baee77b1fd3e84bc010';
lv105OptionMap['근원을 삼킨 창'] = {};
lv105OptionMap['근원을 삼킨 창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['미련이 남은 녹슨 원혼'] = '225aceaa501f2440a54587bed8c80e63';
lv105OptionMap['미련이 남은 녹슨 원혼'] = {};
lv105OptionMap['미련이 남은 녹슨 원혼'] = '모든 속성 강화 +10<br>감전 피해 +20%<br><br>[소형화]<br>[장비 옵션 조작키] 입력 시 25초 동안 소형화 (쿨타임 60초)<br>- 이동 속도 +30%<br>- 회피율 +75%<br>- 1초마다 HP/MP 1% 회복<br>- 스킬 및 기본 공격 불가<br>- [장비 옵션 조작키] 재입력 시 소형화 해제<br><br>공격 시 모든 적에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)<br><br>HP MAX +600<br>물리/마법 방어력 +7,000<br>감전 내성 +10%@@5604@@1968';

lv105ItemId['성장하는 모험가의 하의'] = 'null';
lv105OptionMap['성장하는 모험가의 하의'] = {};
lv105OptionMap['성장하는 모험가의 하의'] = '최종 데미지 7% 증가@@533@@1986';

lv105ItemId['얼어붙은 저항의 선현궁'] = 'a9a986b6ffdd2b4ea6b232d36f7e80b5';
lv105OptionMap['얼어붙은 저항의 선현궁'] = {};
lv105OptionMap['얼어붙은 저항의 선현궁'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['광폭화된 전의의 핸드캐넌'] = '37d92832be9e76c3dd8b538f52274029';
lv105OptionMap['광폭화된 전의의 핸드캐넌'] = {};
lv105OptionMap['광폭화된 전의의 핸드캐넌'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 건틀릿 - 스트라이커(여)'] = '5a2122f47ef77b69e4bba75617b7d42e';
lv105OptionMap['결전의 건틀릿 - 스트라이커(여)'] = {};
lv105OptionMap['결전의 건틀릿 - 스트라이커(여)'] = '라이트닝 댄스 이동 횟수가 4회 감소하고 1:1 공격 시 대상의 좌우를 이동하며 공격<br>- 공격력 15% 감소<br>- 공격 속도 +30%<br>- 이동 범위 5% 증가<br>- 공격 시 적에게 감전 상태 이상 부여 (쿨타임 15초)<br><br>라이트닝 댄스 공격 시 충격파 생성<br>- 직접 타격 대상은 충격파 피격 제외<br>- 1:1 공격일 경우 충격파 미생성@@9484@@2016';

lv105ItemId['결전의 건틀릿 - 스트리트파이터(남)'] = 'fcf77bb54c098f0ef1be7e88a348ea14';
lv105OptionMap['결전의 건틀릿 - 스트리트파이터(남)'] = {};
lv105OptionMap['결전의 건틀릿 - 스트리트파이터(남)'] = '크레이지 발칸 시전 시 화염병 폭탄을 내려치도록 변경<br>- 마운트 마지막 충격파 삭제<br>- 주변 적에게 잡힌 적과 동일한 데미지 적용<br>- 잡기 실패 시에도 발동<br><br>크레이지 발칸 상태 이상 보너스 추가<br>- 공격 범위 : 1개당 10%<br>- 쿨타임 감소 : 1개당 10%@@9484@@2016';

lv105ItemId['결전의 장도 - 요원'] = 'b4865a4b6f96e85aa59c55063ecc4e45';
lv105OptionMap['결전의 장도 - 요원'] = {};
lv105OptionMap['결전의 장도 - 요원'] = '암살목표의 표식이 부여된 적에게 일부 스킬 공격 시 15초 동안 총상 / 절상 부여 (최대 3중첩)<br>- 총상 부여 스킬 : 조준 사격, 목표 사격<br>- 절상 부여 스킬 : 쌍현참, 암월비보<br><br>총상 / 절상 상태의 적을 아래의 스킬로 공격 시 총상 / 절상이 해제되며 스킬 공격력 증가<br>- 문페이즈 : 총상 1중첩당 8%<br>- 섬멸 : 절상 1중첩당 8%<br>- 월광진혼곡 : 총상 / 절상 1중첩당 8%<br><br>아래의 스킬 쿨타임 15% 감소<br>- 쌍현참<br>- 조준 사격<br>- 암월비보<br>- 목표 사격<br><br>문페이즈 크기 10% 증가@@7112@@2016';

lv105ItemId['사멸하는 신뢰의 빗자루'] = '20c715318bdb05654b25ac290152e3de';
lv105OptionMap['사멸하는 신뢰의 빗자루'] = {};
lv105OptionMap['사멸하는 신뢰의 빗자루'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['영면을 위한 준비'] = '02c6ecf0687b280befa6087e9899f913';
lv105OptionMap['영면을 위한 준비'] = {};
lv105OptionMap['영면을 위한 준비'] = '화상 피해 +15%<br><br>[얼렸다 녹였다]<br>적에게 빙결 시도 시 화상-빙결 연계 (쿨타임 3초)<br>- 적에게 누적된 화상 피해 폭발<br>- 빙결시킨 적에게 즉시 화상 부여<br><br>화상 지속 시간 +50%<br>화상 지속 시간 +2초<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>MP MAX +945<br>물리/마법 피해 감소 +15%@@4528@@1958';

lv105ItemId['성장하는 모험가의 흑요석 팬던트'] = '4746f1587697bbc9979797d347445079';
lv105OptionMap['성장하는 모험가의 흑요석 팬던트'] = {};
lv105OptionMap['성장하는 모험가의 흑요석 팬던트'] = '최종 데미지 7% 증가@@5336@@1986';

lv105ItemId['결전의 너클 - 스트리트파이터(남)'] = '473900960d250a328c3bd9856896f5e4';
lv105OptionMap['결전의 너클 - 스트리트파이터(남)'] = {};
lv105OptionMap['결전의 너클 - 스트리트파이터(남)'] = '크레이지 발칸 시전 시 화염병 폭탄을 내려치도록 변경<br>- 마운트 마지막 충격파 삭제<br>- 주변 적에게 잡힌 적과 동일한 데미지 적용<br>- 잡기 실패 시에도 발동<br><br>크레이지 발칸 상태 이상 보너스 추가<br>- 공격 범위 : 1개당 10%<br>- 쿨타임 감소 : 1개당 10%@@9484@@2016';

lv105ItemId['성장하는 모험가의 스태프'] = '7250627f3795d6f0d03a4d027c2b616e';
lv105OptionMap['성장하는 모험가의 스태프'] = {};
lv105OptionMap['성장하는 모험가의 스태프'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['성장하는 모험가의 자동권총'] = 'b3c1f730c87adfc55089e50dafc29244';
lv105OptionMap['성장하는 모험가의 자동권총'] = {};
lv105OptionMap['성장하는 모험가의 자동권총'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 십자가 - 이단심판관'] = 'f400202e744f2c8db76b273654c6d410';
lv105OptionMap['결전의 십자가 - 이단심판관'] = {};
lv105OptionMap['결전의 십자가 - 이단심판관'] = '세례의 불길, 정화의 불꽃 스킬 쿨타임 20% 감소<br><br>정화의 불꽃 시전 시 500px 내 모든 적에게 폭발 발생<br><br>플레게의 정수를 최대 5회 충전 스킬로 변경<br>- 충전 쿨타임 : 8초<br>- 소각 발생 시 충전 시간 1초 감소@@5336@@2016';

lv105ItemId['터치 컨트롤 패널'] = '3bab4b93da1f7d2fcc6da6c98015eae1';
lv105OptionMap['터치 컨트롤 패널'] = {};
lv105OptionMap['터치 컨트롤 패널'] = '최종 데미지 8% 증가<br><br>물리/마법 크리티컬 히트 +10%<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5336@@1948';

lv105ItemId['결전의 낫 - 미스트리스'] = 'c41e6ff8cf0cf837071a34e88f7103ec';
lv105OptionMap['결전의 낫 - 미스트리스'] = {};
lv105OptionMap['결전의 낫 - 미스트리스'] = '타락의 칠죄종 변신 중 죄를 깨우는 손길 공격 시 변신 지속 시간 +12초<br>- 최대 변신 지속 시간 초과 불가능<br><br>글러트니 바밋 시전 시 자신을 중심으로 적을 흡수하고 폭발<br>- 공격력 30% 증가<br>- 죄업 스택 3개 이상일 때 시전 시 죄업 스택을 3개 소모하고 분신이 대신 시전<br><br>죄업 스택이 3개 이상일 때 무거운 죄업 시전 시 죄업 스택을 3개 소모하고 10초 동안 물리/마법 피해 감소 +15%<br><br>일곱 개의 대죄 적용 중 무거운 죄업 적 홀딩 시간 +1초<br><br>죄를 깨우는 손길 죄업 스택 증가량 +2@@7708@@2016';

lv105ItemId['결전의 빗자루 - 디멘션워커'] = '3b7d6bb2b872859e8881cf914d07da74';
lv105OptionMap['결전의 빗자루 - 디멘션워커'] = {};
lv105OptionMap['결전의 빗자루 - 디멘션워커'] = '차원 : 시공폭뢰 스킬 강화<br>- 공격력 15% 증가<br>- 폭발 속도 50% 증가<br>- 스킬 범위 25% 증가<br>- 사상경계의 저편 효과 적용 시 전방에 다수의 차원 폭발 생성<br><br>니알리가 스킬 시전 시 분신이 대신 시전 (각성기 제외)<br><br>아래 스킬 쿨타임 10% 감소<br>- 괴리 : 할리퀸<br>- 괴리 : 패러사이트 스웜<br>- 괴리 : 칙 잇<br>- 괴리 : 저편의 자장가@@4744@@2016';

lv105ItemId['내딛은 자의 도'] = 'b8a51d7c294187e0062c59dc2a57bed8';
lv105OptionMap['내딛은 자의 도'] = {};
lv105OptionMap['내딛은 자의 도'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['사멸하는 신뢰의 차크라 웨펀'] = '170a5711a2a7ab3e2120effd4c474fce';
lv105OptionMap['사멸하는 신뢰의 차크라 웨펀'] = {};
lv105OptionMap['사멸하는 신뢰의 차크라 웨펀'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 배틀액스 - 이단심판관'] = '44dab88fff2bc28386f5d2dbdb37ef25';
lv105OptionMap['결전의 배틀액스 - 이단심판관'] = {};
lv105OptionMap['결전의 배틀액스 - 이단심판관'] = '세례의 불길, 정화의 불꽃 스킬 쿨타임 20% 감소<br><br>정화의 불꽃 시전 시 500px 내 모든 적에게 폭발 발생<br><br>플레게의 정수를 최대 5회 충전 스킬로 변경<br>- 충전 쿨타임 : 8초<br>- 소각 발생 시 충전 시간 1초 감소@@5336@@2016';

lv105ItemId['압도하는 힘의 하갑'] = '03b4a29839141e826a739630698a8560';
lv105OptionMap['압도하는 힘의 하갑'] = {};
lv105OptionMap['압도하는 힘의 하갑'] = '중독 피해 +15%<br><br>중독 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 중독 상태 이상 부여 (쿨타임 10초)<br><br>물리/마법 크티리컬 히트 +5%<br>중독 내성 +10%@@6682@@1958';

lv105ItemId['결전의 낫 - 인파이터'] = '3f06f384d1f20baf1dc792b747c1c242';
lv105OptionMap['결전의 낫 - 인파이터'] = {};
lv105OptionMap['결전의 낫 - 인파이터'] = '정의 집행 : 레미디오스의 성좌의 링크 스킬이 빅뱅 펀치일 때, 신의 분노를 최대 3회 충전 스킬로 변경<br>- 연타 공격력 100% 증가<br>- 연타 횟수 9회 감소<br>- 피니쉬 타격 및 폭발 삭제<br>- 적을 추적하여 공격<br>- 충전 쿨타임 : 40초<br>- 추적 범위 : 780px<br>- MP 소모량 -30%<br>- 무색 큐브 조각 소모량 5개 감소<br>- 머신건 잽 시전 시 신의 분노 남은 쿨타임 12% 감소<br>- 신의 분노를 캔슬하여 전직 스킬 시전 가능<br>- 전직 스킬을 캔슬하여 신의 분노 시전 시 드라이아웃 스택을 소모하지 않음@@5928@@2016';

lv105ItemId['타락한 영혼'] = 'b16348f86d01e99a541a2af2a21a015b';
lv105OptionMap['타락한 영혼'] = {};
lv105OptionMap['타락한 영혼'] = '무색 큐브 조각을 소모하는 스킬에 아래의 효과 적용<br>- 스킬 공격력 10% 증가<br>- 스킬 쿨타임 10% 감소 (각성기 제외)<br>- 무색 큐브 조각 소모량 +2<br><br>MP MAX +945<br>적중률 +10%<br>스킬 MP 소모량 -10%@@4796@@1968';

lv105ItemId['자신감의 백색 망토'] = '41e55f046345b4dbf4976f39231a0d22';
lv105OptionMap['자신감의 백색 망토'] = {};
lv105OptionMap['자신감의 백색 망토'] = '최종 데미지 6% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5604@@1612';

lv105ItemId['성장하는 모험가의 봉'] = 'e7dc56f8d29becd9f92fb9d79d0f11e4';
lv105OptionMap['성장하는 모험가의 봉'] = {};
lv105OptionMap['성장하는 모험가의 봉'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['결전의 스태프 - 마도학자'] = 'eb2650736a327fe97951f7231d24f133';
lv105OptionMap['결전의 스태프 - 마도학자'] = {};
lv105OptionMap['결전의 스태프 - 마도학자'] = '롤리팝 크러쉬가 더욱 많은 사탕 인형 생성<br>- 최대 사탕 생성 개수 +5<br>- 피격개체당 사탕인형 생성 수 +2<br>- 생성된 사탕인형 1개당 롤리팝 크러쉬의 남은 쿨타임 3% 감소<br>- 사탕인형 공격력 20% 감소<br><br>폭발하지 않은 사탕 인형을 흡수하여 다음 롤리팝 크러쉬 때 생성 (최대 15개)<br>- 사탕당 흡수 1번 가능<br>- 추가 생성된 사탕 인형은 최대 사탕 생성 개수에 미포함@@8300@@2016';

lv105ItemId['내딛은 자의 머스켓'] = '84a5d6b97b2de355d5848670489e514d';
lv105OptionMap['내딛은 자의 머스켓'] = {};
lv105OptionMap['내딛은 자의 머스켓'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['잔잔한 선율'] = '4adcce602ca7ac179ba3048f17bc2e0e';
lv105OptionMap['잔잔한 선율'] = {};
lv105OptionMap['잔잔한 선율'] = '최종 데미지 10% 증가<br>모든 속성 강화 +20<br><br>HP MAX +600<br>물리/마법 방어력 +7,000<br>모든 상태 이상 내성 +20%<br>수면 내성 -30%@@4258@@1938';

lv105ItemId['결전의 대검 - 카오스'] = '18aeaccd9800c3f36be53350f8f4afe1';
lv105OptionMap['결전의 대검 - 카오스'] = {};
lv105OptionMap['결전의 대검 - 카오스'] = '제노사이드 크러쉬 시전 시 데몬들을 폭발시키는 대신 아텐의 화신이 데몬들의 환영을 소환하고 폭발시키도록 변경<br>- 공격력 10% 증가<br>- 스킬 범위 20% 증가<br>- 시전 중 미드나잇 카니발 시전 가능<br>- 데몬이 없을 경우 즉시 모든 데몬 소환@@10076@@2016';

lv105ItemId['결전의 도 - 웨펀마스터'] = '0ce695e99bbdfb5d18876006870f97a6';
lv105OptionMap['결전의 도 - 웨펀마스터'] = {};
lv105OptionMap['결전의 도 - 웨펀마스터'] = '초식을 발전시켜 이기어검술 스킬 강화<br>- 지속 시간 +20초<br>- 기본기 숙련에 영향받는 스킬 적중 시 지속 시간 +0.5초 (최대 지속 시간 초과 불가능)<br>- 꿰뚫는검 사출 개수 +1<br>- 꿰뚫는검 사출 회수 시간 감소<br><br>장착 해제 시 이기어검술 지속 시간 종료@@8892@@2016';

lv105ItemId['내딛은 자의 자동권총'] = 'a2fa0a5c521791fd4faf4ddb6bf2e9c0';
lv105OptionMap['내딛은 자의 자동권총'] = {};
lv105OptionMap['내딛은 자의 자동권총'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['얼어붙은 저항의 투창'] = 'd2c8dcbc4aa446744be9b725085d28c4';
lv105OptionMap['얼어붙은 저항의 투창'] = {};
lv105OptionMap['얼어붙은 저항의 투창'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['마그네틱 서치 스캔'] = '2eef901281c1b3194a4ef35f4796b6b9';
lv105OptionMap['마그네틱 서치 스캔'] = {};
lv105OptionMap['마그네틱 서치 스캔'] = '최종 데미지 2% 증가<br>감전 피해 +20%<br><br>감전 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>자신에게 해제되지 않는 감전 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +10%<br>공격 속도 +5%<br>캐스팅 속도 +7.5%<br>HP 1분당 460.2 회복@@6144@@1968';

lv105ItemId['사멸하는 신뢰의 크로스슈터'] = '4b1425c06d48988979d9948b6da92a39';
lv105OptionMap['사멸하는 신뢰의 크로스슈터'] = {};
lv105OptionMap['사멸하는 신뢰의 크로스슈터'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@711@@1916';

lv105ItemId['내딛은 자의 쌍검'] = '9ce3446034dd0166b1624e41959b5df5';
lv105OptionMap['내딛은 자의 쌍검'] = {};
lv105OptionMap['내딛은 자의 쌍검'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['손에 닿은 기억'] = '13de6a9bdef797aefc576a1c67dbee20';
lv105OptionMap['손에 닿은 기억'] = {};
lv105OptionMap['손에 닿은 기억'] = '최종 데미지 7% 증가<br>스킬 쿨타임 회복 속도 +10% (각성기 제외)<br><br>슈퍼아머 부여<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +5%<br>MP 1분당 348 회복@@4796@@1920';

lv105ItemId['결전의 낫 - 무녀'] = '562d41525b98e819666aa0a4087e0374';
lv105OptionMap['결전의 낫 - 무녀'] = {};
lv105OptionMap['결전의 낫 - 무녀'] = '염주 사출, 목환자경 타격 1회당 신력 염주알 1개 획득 (최대 25개)<br><br>아래 스킬 시전 시 신력 염주알을 소모하여 강화<br>- 부동주박진 : 염주 회전 수 1회 증가 (8개 소모)<br>- 백팔염주 : 염주 사출 개수 10개 증가 (10개 소모)<br>- 마혼부 : 퇴마음양옥 : 튕기는 횟수 3회 증가 (10개 소모)<br>- 염폭모대주 : 염주알 낙하 수 1 증가 (15개 소모)<br><br>염주 사출, 목환자경 시전 1회당 백팔염주, 부동주박진 스킬 쿨타임 회복 속도 +1%<br>- 백팔염주, 부동주박진 시전 시 효과 초기화@@4148@@2016';

lv105ItemId['결전의 토템 - 크루세이더(남)'] = '1027d9e19be0d970f772bd52f2fd79fb';
lv105OptionMap['결전의 토템 - 크루세이더(남)'] = {};
lv105OptionMap['결전의 토템 - 크루세이더(남)'] = '영광의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>슬로우 힐 스킬 범위 20% 증가<br><br>정의의 심판 시전 시 빛무리를 소환하여 범위 내 적 10명을 추적 후 정의의 창을 발사하여 폭발<br>- 창 공격력 : 빛의 칼날 공격력의 1600%<br>- 폭발 공격력 : 마법진 최대 유지 시 피니쉬 공격력의 100%<br>- 다른 스킬 시전 중 시전 가능<br><br>미러클 스플리터 스킬 공격력 20% 증가<br>- 돌진 거리 70% 감소<br><br>디플렉트 월 HP 400% 증가<br>- 이동하지 않음<br><br>싱글 플레이 시 독립 공격력 20% 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['영원을 끝낼 오랜 염원'] = '1854a7d3b785db5363030143746a4a29';
lv105OptionMap['영원을 끝낼 오랜 염원'] = {};
lv105OptionMap['영원을 끝낼 오랜 염원'] = '최종 데미지 14% 증가<br><br>[폭독]<br>화기를 만나면 폭발하는 독을 사용하며 3초마다 적에게 화기 부여<br>- 화상 부여 시 중독 폭발<br>- 10초 동안 화상 부여 (쿨타임 3초)<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>MP 1분당 348 회복@@5484@@1943';

lv105ItemId['사멸하는 신뢰의 투창'] = '3c2ab144a4393cb3d995e71324300597';
lv105OptionMap['사멸하는 신뢰의 투창'] = {};
lv105OptionMap['사멸하는 신뢰의 투창'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 에테리얼 보우 - 비질란테'] = '7018c6e09c2ed663bcb00ff3d0e425b9';
lv105OptionMap['결전의 에테리얼 보우 - 비질란테'] = {};
lv105OptionMap['결전의 에테리얼 보우 - 비질란테'] = '[요기 발산]<br>요수화/인간화 시 요기를 발산하여 공격하며, 요기 게이지에 따라 공격력 강화<br>- 요기 공격력 : 요수조 인간형 베기 공격력의 100%<br>- 요수화/인간화 시 요기 게이지 +5<br> <br><요기 게이지별 요기 공격력 증가량><br>- 31 ~ 70 : 30%<br>- 71 ~ 100 : 50%<br> <br>요기 게이지 충전량 +20%<br>- 요수화/인간화 시 제외<br> <br>아래 스킬들의 요기 소모량 -5<br>- 커팅 섀도우<br>- 페인 브링어<br>- 넥 펀처<br>- 디바우러<br>- 크루얼 슬러터<br>- 램페이지<br>- 피스 피어서@@592@@2016';

lv105ItemId['결전의 머스켓 - 런처(남)'] = '6a7b3ca276ecee8a013261f0a10eef25';
lv105OptionMap['결전의 머스켓 - 런처(남)'] = {};
lv105OptionMap['결전의 머스켓 - 런처(남)'] = '레이저 라이플을 최대 3회 충전 스킬로 변경<br>- 공격력 20% 증가<br>- 크기 40% 증가<br>- 충전 쿨타임 : 7초<br>- 충전 레이저 라이플 충전 시간 50% 감소<br>- 적중 시 적에게 10초 동안 전자기장 부여<br><br>FM-92 스팅어 강화<br>- 공격력 15% 증가<br>- 폭발 범위 25% 증가<br>- 전자기장이 부여된 적 존재 시 대상을 추적하는 강화 고폭탄 발사@@8892@@2016';

lv105ItemId['내딛은 자의 에테리얼 보우'] = '4e691d0378c25546edcb62c6cfe18a74';
lv105OptionMap['내딛은 자의 에테리얼 보우'] = {};
lv105OptionMap['내딛은 자의 에테리얼 보우'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@474@@1856';

lv105ItemId['결전의 도 - 블레이드'] = '758bc0a7f3af620b2dd22984d3ce2f1b';
lv105OptionMap['결전의 도 - 블레이드'] = {};
lv105OptionMap['결전의 도 - 블레이드'] = '납도술 연계 시 납도술 스킬과 연계한 스킬의 남은 쿨타임 15% 감소<br>- 각성기로 납도술 연계 시 미적용<br><br>러스티 네일 베기 공격력이 삭제되고, 후딜레이를 캔슬하여 전직 스킬 시전 가능<br>- 베기 공격력을 발차기 공격력에 합산<br><br>전직 스킬 후딜레이를 캔슬하여 러스티 네일 시전 가능<br>- 셰이커 블래스트, 각성기 제외@@8892@@2016';

lv105ItemId['저주받은 굴레'] = 'ce1cf7f9b7c8a799800af70201d0d04c';
lv105OptionMap['저주받은 굴레'] = {};
lv105OptionMap['저주받은 굴레'] = '최종 데미지 7.1% 증가<br><br>자신이 출혈 상태일 때 스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>HP 회복 효과 50% 증가<br><br>물리/마법 방어력 +7,000<br>출혈 내성 +1%@@3787@@1948';

lv105ItemId['기사의 속죄'] = '403dcc99cc1fb678bd7e191597e6ca0c';
lv105OptionMap['기사의 속죄'] = {};
lv105OptionMap['기사의 속죄'] = '최종 데미지 10% 증가<br><br>대시하는 동안 회피율 +20%<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +20%<br>캐스팅 속도 +30%<br>이동 속도 +20%<br>물리/마법 피해 감소 +10%<br>물리/마법 방어력 +7,000@@5604@@1928';

lv105ItemId['성장하는 모험가의 허리'] = 'null';
lv105OptionMap['성장하는 모험가의 허리'] = {};
lv105OptionMap['성장하는 모험가의 허리'] = '최종 데미지 7% 증가@@533@@1986';

lv105ItemId['잊혀진 기록'] = '770802db344f63c63a4627be40d10d58';
lv105OptionMap['잊혀진 기록'] = {};
lv105OptionMap['잊혀진 기록'] = '최종 데미지 14% 증가<br><br>MP MAX +945<br>이동 속도 +8%@@5336@@1948';

lv105ItemId['숨결을 삼킨 용옥'] = 'bcd71527f886ea4900bde373340ae966';
lv105OptionMap['숨결을 삼킨 용옥'] = {};
lv105OptionMap['숨결을 삼킨 용옥'] = '최종 데미지 13% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>물리/마법 피해 감소 +12%<br>HP 1분당 460.2 회복@@5604@@1900';

lv105ItemId['근원을 삼킨 차크라 웨펀'] = '083ebd835ad489da04df5117c1e5fb08';
lv105OptionMap['근원을 삼킨 차크라 웨펀'] = {};
lv105OptionMap['근원을 삼킨 차크라 웨펀'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['얼어붙은 저항의 스태프'] = '37e55a21aac666db444646c09d9c664d';
lv105OptionMap['얼어붙은 저항의 스태프'] = {};
lv105OptionMap['얼어붙은 저항의 스태프'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 머스켓 - 스핏파이어(남)'] = '5f13dc8d42577ef9032cb961ce034f9a';
lv105OptionMap['결전의 머스켓 - 스핏파이어(남)'] = {};
lv105OptionMap['결전의 머스켓 - 스핏파이어(남)'] = '버스터 샷이 가장 강한 적에게 지원 포격을 발사하도록 변경<br>- 다른 스킬 사용 중 모션 없이 시전 가능<br><br>오버차지 적용 중 평타 10회 공격 시 버스터샷 강화 탄약 충전 (최대 3개)<br>- 버스터샷 시전 시 강화 탄약 소모<br>- 탄약 1개 소모 : 공격력 15% 증가<br>- 탄약 2개 소모 : 공격력 40% 증가<br>- 탄약 3개 소모 : 공격력 80% 증가<br>- 던전 입장 시 탄약 1개 장착@@7112@@2016';

lv105ItemId['내딛은 자의 리볼버'] = 'ada9cce75f351749e25dc15fba68291a';
lv105OptionMap['내딛은 자의 리볼버'] = {};
lv105OptionMap['내딛은 자의 리볼버'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['드래곤 라이더'] = '13e6904c6bbc52eee71b9c72df37a309';
lv105OptionMap['드래곤 라이더'] = {};
lv105OptionMap['드래곤 라이더'] = '최종 데미지 8% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 속성 저항 +15@@5066@@1928';

lv105ItemId['아득한 각인'] = '52ec0bfd326d5475b588c893fb08d34f';
lv105OptionMap['아득한 각인'] = {};
lv105OptionMap['아득한 각인'] = '50, 85, 100Lv 스킬 공격력 20% 증가<br>모든 속성 강화 +20<br><br>[명계의 각인]<br>스킬 시전 시 7.77% 확률로 스킬 공격력이 100% 증가하여 적을 명계로 인도<br><br>공격 속도 +30%<br>캐스팅 속도 +30%<br>이동 속도 +30%<br>적중률 +10%@@5780@@1963';

lv105ItemId['결전의 핸드캐넌 - 레인저(여)'] = 'c8a0a781eb6256b6df28e1470b810af1';
lv105OptionMap['결전의 핸드캐넌 - 레인저(여)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 레인저(여)'] = '이동사격 탄환을 파열시켜 더욱 넓은 범위 공격<br>- 공격력 30% 증가<br>- 발사 수 50% 증가<br>- 쿨타임 20% 감소<br>- 공격 범위 10% 증가<br><br>이동사격 중 아래의 효과 적용<br>- 스킬 시전 시 쿨타임 즉시 적용<br>- 공격 속도 +30%<br>- 이동 속도 +30%<br>- 던전 방 이동 시 스킬 유지@@7708@@2016';

lv105ItemId['생명이 깃든 초록빛 액체'] = '27b3353a4472f64e74bcdd8b85f32fe3';
lv105OptionMap['생명이 깃든 초록빛 액체'] = {};
lv105OptionMap['생명이 깃든 초록빛 액체'] = '최종 데미지 10% 증가<br><br>[속성 친화 - 명]<br>명속성 저항이 높아질수록 물리/마법 피해 감소<br>- 적의 공격에 명속성 부여<br><br>피격 시 5초 동안 HP 5% 회복 (쿨타임 7초)<br><br>HP MAX +600<br>명속성 저항 +35@@5604@@1948';

lv105ItemId['개화하는 신비의 꽃'] = '1bd4db237a0fcdb205f8fd0a2a610a72';
lv105OptionMap['개화하는 신비의 꽃'] = {};
lv105OptionMap['개화하는 신비의 꽃'] = '공격력 증가 +222.3%<br>최종 데미지 7% 증가<br>모든 속성 강화 +10<br><br>암흑 상태 적 공격 시 30초 동안 최종 데미지 10% 증가<br><br>공격 시 적에게 5초 동안 암흑 상태 이상 부여 (쿨타임 8초)<br><br>암흑 지속 시간 +2초@@4984@@1968';

lv105ItemId['혼돈을 두른 장막'] = '07d1f5aea662f1b0b8eae7317b6924d9';
lv105OptionMap['혼돈을 두른 장막'] = {};
lv105OptionMap['혼돈을 두른 장막'] = '최종 데미지 10% 증가<br><br>HP MAX +600<br>물리/마법 방어력 +7,000@@5066@@1958';

lv105ItemId['근원을 삼킨 클로'] = '078ef4c689fdf4ffcf6ffcc2d09f5dae';
lv105OptionMap['근원을 삼킨 클로'] = {};
lv105OptionMap['근원을 삼킨 클로'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['성장하는 모험가의 십자 자수 토시'] = '429ea115bb74308b9c2441784cc841de';
lv105OptionMap['성장하는 모험가의 십자 자수 토시'] = {};
lv105OptionMap['성장하는 모험가의 십자 자수 토시'] = '최종 데미지 7% 증가@@5336@@1986';

lv105ItemId['플래티넘 클래스 재킷'] = '01f668b6a225cfd1681a1ebfc40245fe';
lv105OptionMap['플래티넘 클래스 재킷'] = {};
lv105OptionMap['플래티넘 클래스 재킷'] = '최종 데미지 8% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5604@@1612';

lv105ItemId['폭룡왕의 지배 - 압제'] = '496c183699f3e4c7fb0c940fbd2ae225';
lv105OptionMap['폭룡왕의 지배 - 압제'] = {};
lv105OptionMap['폭룡왕의 지배 - 압제'] = '최종 데미지 11% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@5604@@1900';

lv105ItemId['데저트 컨실멘트 숄더'] = '0e6cd9f319acde9826e59e9cbf81e89d';
lv105OptionMap['데저트 컨실멘트 숄더'] = {};
lv105OptionMap['데저트 컨실멘트 숄더'] = '최종 데미지 2% 증가<br>화상 피해 +20%<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>자신에게 해제되지 않는 화상 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +10%<br>공격 속도 +5%<br>캐스팅 속도 +7.5%<br>HP 1분당 460.2 회복@@6144@@1968';

lv105ItemId['얼어붙은 저항의 권투글러브'] = 'd03717b42cc2ceedd01ad461cb2b46c1';
lv105OptionMap['얼어붙은 저항의 권투글러브'] = {};
lv105OptionMap['얼어붙은 저항의 권투글러브'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 도 - 다크템플러'] = '804199496caa0bf2584f52316a9e7b33';
lv105OptionMap['결전의 도 - 다크템플러'] = {};
lv105OptionMap['결전의 도 - 다크템플러'] = '이보브 영혼 최대 흡수 개수 +30<br><br>아래 스킬의 추가 영혼 흡수 개수 +5<br>- 리버레이트 - 매드니스<br>- 디스트로이어<br>- 라케르쏜즈<br><br>리버레이트 - 벅샷 발사하는 에너지 1개당 소모되는 영혼의 수 +2<br>- 타겟을 잡는 최대 거리 20% 증가<br><br>리버레이트 - 벅샷으로 소모한 영혼의 수 2개마다 아래 스킬의 남은 쿨타임 1% 감소 (베스퍼스 효과 미적용)<br>- 디스트로이어<br>- 데우스 엑스 레이<br>- 지하드<br>- 라케르쏜즈@@5336@@2016';

lv105ItemId['광폭화된 전의의 쌍검'] = '3673e0266b980053cbbc3497af7d229b';
lv105OptionMap['광폭화된 전의의 쌍검'] = {};
lv105OptionMap['광폭화된 전의의 쌍검'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 소검 - 팔라딘'] = '92058059afc937f35a80a3236248d1da';
lv105OptionMap['결전의 소검 - 팔라딘'] = {};
lv105OptionMap['결전의 소검 - 팔라딘'] = '세라픽 페더를 소모하여 이스트레인지먼트 시전 시 추가 효과 발동<br>- 세라픽 페더 1장당 공격력 4% 증가<br>- 세라픽 페더 1장당 스킬 범위 3% 증가<br>- 적에게 기절 상태 이상 부여<br><br>세라픽 페더를 소모하여 위핑 웨이브 시전 시 추가 효과 발동<br>- 끌어들인 적을 2초 동안 홀딩<br>- 몰이 효과 범위 6단계 적용<br>- 몰이 효과 범위 20% 증가<br>- 세라픽 페더 최대 2개만 소모<br><br>위핑 웨이브 강화<br>- 스킬 범위 20% 증가<br>- 타격 성공 시 세라픽 페더 습득량 +2<br>- 도발시킨 적 앞에서 방어 상태 유지 시 세라픽 페더 획득 시간 50% 감소@@9484@@2016';

lv105ItemId['결전의 통파 - 그래플러(여)'] = '2e8c00dc0d922666a7d8a0873867ab65';
lv105OptionMap['결전의 통파 - 그래플러(여)'] = {};
lv105OptionMap['결전의 통파 - 그래플러(여)'] = '전직 스킬로 공격 시 테크니컬 포인트 획득 (최대 20점)<br>- 1~30Lv : 1점<br>- 35~45Lv : 2점<br>- 60~80Lv : 2점<br>- 50, 85, 95Lv : 5점<br>- 100Lv : 10점<br>- 체인 커넥션 연계/공중 스킬 사용 시 1점 추가<br><br>테크니컬 포인트에 따라 효과 발동<br>- 11점 이상 : 50, 85, 100Lv 스킬 적중 시 모든 스킬 남은 쿨타임 30% 감소 (각성기 제외 / 모든 점수 소모)<br>- 6점 이상 : 잡기 스킬 공격 실패 시 해당 스킬 쿨타임을 3초로 감소 (3점 소모)<br>- 1점 이상 : 체인 커넥션 스킬 연계 시 체인 커넥션 회복 시간 3초 감소@@5336@@2016';

lv105ItemId['내딛은 자의 중검'] = '8983f120c217e07f29c6ba716430a546';
lv105OptionMap['내딛은 자의 중검'] = {};
lv105OptionMap['내딛은 자의 중검'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['사멸하는 신뢰의 봉'] = '2e0cccb1e0f7277209a73818db650f4c';
lv105OptionMap['사멸하는 신뢰의 봉'] = {};
lv105OptionMap['사멸하는 신뢰의 봉'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['포인트 레이더 암릿'] = 'b544581f62bba1bc5d85f9f091795e5c';
lv105OptionMap['포인트 레이더 암릿'] = {};
lv105OptionMap['포인트 레이더 암릿'] = '최종 데미지 7.1% 증가<br>화상 피해 전환 +50%<br>화상 피해 +20%<br><br>물리/마법 피해의 50%를 화상 피해로 전환<br><br>화상 내성 +1%@@3165@@1953';

lv105ItemId['결전의 로드 - 배틀메이지'] = 'd408810c89ef0653038a5dcf04806149';
lv105OptionMap['결전의 로드 - 배틀메이지'] = {};
lv105OptionMap['결전의 로드 - 배틀메이지'] = '체이서 공격력 및 사출 개수 증가<br>- 한 번에 사출되는 체이서 개수 +2<br>- 공격력 45% 감소<br>- 50콤보 이상일 때 체이서 자동 사출<br>- 체이서 최대 생성 개수 +1<br>- 체이서 크기 비율 20% 증가<br>- 스킬 MP 소모량 -70%<br><br>한 번에 생성되는 체이서 개수 +1@@4744@@2016';

lv105ItemId['결전의 통파 - 넨마스터(남)'] = 'b206fb9dc51e2ae52069f3275df569bf';
lv105OptionMap['결전의 통파 - 넨마스터(남)'] = {};
lv105OptionMap['결전의 통파 - 넨마스터(남)'] = '기호지세의 넨수가 포효 후 폭발하도록 변경<br>- 포효 공격 다단히트 수 : 4<br>- 포효 공격력 : 기호지세 다단히트 공격력의 100%<br>- 폭발 공격력 : 기호지세 폭발 공격력의 100%<br>- 포효에 적중된 적을 끌어당김<br>- 스킬 범위 20% 증가<br><br>광호천지파의 나선의 넨이 스스로 회전 후 폭발<br>- 시전 속도 20% 증가<br>- 구체 및 폭발 범위 10% 증가@@10076@@2016';

lv105ItemId['계곡을 감시하는 주시'] = '0974cda9a6eae319db5c07cf9874f812';
lv105OptionMap['계곡을 감시하는 주시'] = {};
lv105OptionMap['계곡을 감시하는 주시'] = '최종 데미지 8% 증가<br><br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +10%<br>MP MAX +5%<br>물리/마법 방어력 +7,000@@6414@@1928';

lv105ItemId['근원을 삼킨 십자가'] = '1aa5c0faf9bcd968077f5787ac3a430b';
lv105OptionMap['근원을 삼킨 십자가'] = {};
lv105OptionMap['근원을 삼킨 십자가'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['얼어붙은 저항의 소태도'] = '3d2cf51aafc3c4f8fe383b18effe0148';
lv105OptionMap['얼어붙은 저항의 소태도'] = {};
lv105OptionMap['얼어붙은 저항의 소태도'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 스태프 - 엘레멘탈마스터'] = '2fd39f1e445a619c3cfb3bcc7e38cd5a';
lv105OptionMap['결전의 스태프 - 엘레멘탈마스터'] = {};
lv105OptionMap['결전의 스태프 - 엘레멘탈마스터'] = '상급 원소 스킬 및 일부 최상급 원소 스킬 1개를 룬에 저장 후 해방<br>- [장비 옵션 조작키] 입력 후 스킬 사용 시 60초 동안 룬에 저장<br>- [장비 옵션 조작키] 재입력 시 저장된 스킬 해방 (쿨타임 0.1초)<br>- 해방 스킬 공격력 : 원본 스킬의 15%<br>- 저장 스킬 쿨타임 : 원본 스킬의 15%<br>- 최대 충전 상태로 저장<br><br>메모라이즈 캐스팅 속도 상승률 +15%<br><br><저장 가능 스킬><br>- 썬더 콜링<br>- 아크틱 피스트<br>- 나이트 할로우<br>- 핼로윈 버스터<br>- 엘레멘탈 커튼<br>- 엘레멘탈 퀘이크<br>- 초월의 크리스탈@@11264@@2016';

lv105ItemId['성장하는 모험가의 둔기'] = '3d1a4e9685d31368f2edfe883d7c839e';
lv105OptionMap['성장하는 모험가의 둔기'] = {};
lv105OptionMap['성장하는 모험가의 둔기'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['결전의 너클 - 그래플러(남)'] = '0db0dc25c296e69b735d529dee97e9a8';
lv105OptionMap['결전의 너클 - 그래플러(남)'] = {};
lv105OptionMap['결전의 너클 - 그래플러(남)'] = '롤링 스파이크를 메치기 시 지진이 발생하는 최대 2회 충전 스킬로 변경<br>- 지진 공격력 : 메치기 공격력의 50%<br>- 충전 쿨타임 : 12초<br>- 후딜레이를 캔슬하여 전직 스킬 시전 가능<br><br>아래의 스킬 잡기 실패 시 해당 스킬 쿨타임 5초로 감소 (쿨타임 10초)<br>- 브레이크 다운<br>- 스냅 샷<br>- 블로우 스트라이크(지상 사용)<br>- 롤링 스파이크<br>- 나의 공격리듬은!(지상 사용)<br>- 한놈만 팬다!!<br>- 흑진광풍@@5928@@2016';

lv105ItemId['하이테크 서플라이 벨트'] = '3402f770e2fe1a9a60d71be7d9d43834';
lv105OptionMap['하이테크 서플라이 벨트'] = {};
lv105OptionMap['하이테크 서플라이 벨트'] = '최종 데미지 12% 증가<br><br>HP MAX 10% 수치의 [충전형 보호막] 부여<br><br>HP MAX +600<br>물리/마법 피해 감소 +30%<br>HP 1분당 460.2 회복@@6414@@1948';

lv105ItemId['내딛는 용기'] = '0a40b67be1eed7397bf62a271d2751d1';
lv105OptionMap['내딛는 용기'] = {};
lv105OptionMap['내딛는 용기'] = '공격력 증가 +326.0%<br>최종 데미지 9% 증가<br>1~25Lv 스킬 Lv +2<br><br>이동 속도 +8%<br>적중률 +10%@@6414@@1920';

lv105ItemId['얼어붙은 저항의 둔기'] = '037e23579234acfbdde69c2af4dc8296';
lv105OptionMap['얼어붙은 저항의 둔기'] = {};
lv105OptionMap['얼어붙은 저항의 둔기'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['안개를 탐구하는 자의 목걸이'] = '0c77648fb81aa8283afdec229904dbf1';
lv105OptionMap['안개를 탐구하는 자의 목걸이'] = {};
lv105OptionMap['안개를 탐구하는 자의 목걸이'] = '최종 데미지 9% 증가<br>모든 속성 강화 +18<br><br>대시 시 30초 동안 슈퍼 아머 부여 (쿨타임 5초)<br><br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +10%<br>회피율 +20%@@5254@@1938';

lv105ItemId['무대의 화려함'] = '03bacfa3c23b56f447a5cd18851219f0';
lv105OptionMap['무대의 화려함'] = {};
lv105OptionMap['무대의 화려함'] = '최종 데미지 8% 증가<br><br>백스텝 시전 중 이동 속도 +200%<br><br>물리/마법 피해 감소 +10%<br>적중률 +10%<br>회피율 +8%@@4796@@1928';

lv105ItemId['사멸하는 신뢰의 배틀액스'] = '0f19356167fe5e85729478ae6bb546df';
lv105OptionMap['사멸하는 신뢰의 배틀액스'] = {};
lv105OptionMap['사멸하는 신뢰의 배틀액스'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['익스펜션 서플라이 벨트'] = '0f3d772eab81c1cc12ff4d62ac76eb2d';
lv105OptionMap['익스펜션 서플라이 벨트'] = {};
lv105OptionMap['익스펜션 서플라이 벨트'] = '최종 데미지 7% 증가<br>스킬 쿨타임 회복 속도 +15% (각성기 제외)<br><br>공격 속도 +45%<br>캐스팅 속도 +45%<br>이동 속도 +45%<br>화속성 저항 +30@@5336@@1948';

lv105ItemId['무너진 세계의 틈'] = '2566ad9a3d73caaeea358c6a9ac34c6e';
lv105OptionMap['무너진 세계의 틈'] = {};
lv105OptionMap['무너진 세계의 틈'] = '최종 데미지 8% 증가<br><br>[차원 이동]<br>[장비 옵션 조작키] 입력 시 40초 동안 차원의 틈을 드러냄 (최대 2개 / 쿨타임 20초)<br>- 차원의 틈에서 점프 시 다른 차원의 틈으로 이동 (쿨타임 3초)<br>- 이동 후 30초 동안 물리/마법 피해 감소 +30%<br><br>물리/마법 크리티컬 히트 +5%<br>물리/마법 피해 감소 +12%<br>HP 1분당 460.2 회복@@6144@@1928';

lv105ItemId['저주받은 마음'] = '2e08fce12a4fbcd995f02b62b95115bd';
lv105OptionMap['저주받은 마음'] = {};
lv105OptionMap['저주받은 마음'] = '최종 데미지 15% 증가<br><br>[꺾이지 않는 마음]<br>피격으로 인한 스킬 캔슬 무시 (쿨타임 10초)<br>- 홀딩 제외<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>HP MAX +600@@5066@@1948';

lv105ItemId['결전의 둔기 - 소드마스터'] = '22de6ad396b22d6d111ce7b982a3c29e';
lv105OptionMap['결전의 둔기 - 소드마스터'] = {};
lv105OptionMap['결전의 둔기 - 소드마스터'] = '궁극의 마검 레바테인으로 악즉참 시전<br>- 악즉참 항상 최대 충전<br>- 악즉참 공격력 15% 감소<br><br>레바테인에 의한 악즉참은 적에게 4초 동안 상흔을 부여<br>- 상흔이 생성된 적에게 반월 또는 섬광 적중 시 악즉참 남은 쿨타임 10% 감소@@8892@@2016';

lv105ItemId['성장하는 모험가의 소검'] = 'b034849626b44304920cc3be6b31f01f';
lv105OptionMap['성장하는 모험가의 소검'] = {};
lv105OptionMap['성장하는 모험가의 소검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['내딛은 자의 낫'] = '3b7f183dd10bf8f5965b74ca6cfb09c0';
lv105OptionMap['내딛은 자의 낫'] = {};
lv105OptionMap['내딛은 자의 낫'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['광폭화된 전의의 빗자루'] = '09c3fca8cc26bfd72b0bc114aeeafe60';
lv105OptionMap['광폭화된 전의의 빗자루'] = {};
lv105OptionMap['광폭화된 전의의 빗자루'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['상상을 담은 엔지니어 팬츠'] = '0ab040cef3e52b3d4b8025bbe82a8ca4';
lv105OptionMap['상상을 담은 엔지니어 팬츠'] = {};
lv105OptionMap['상상을 담은 엔지니어 팬츠'] = '최종 데미지 10% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>MP MAX +945@@5066@@1948';

lv105ItemId['세컨드 스페이드 - 어쏘러티'] = '8ead84aa6b74d38b4a851a001d28a0c9';
lv105OptionMap['세컨드 스페이드 - 어쏘러티'] = {};
lv105OptionMap['세컨드 스페이드 - 어쏘러티'] = '최종 데미지 12% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@4528@@1612';

lv105ItemId['주체할 수 없는 낡은 규칙'] = '2ec6efb126d88e85da31c34bb2b71b6f';
lv105OptionMap['주체할 수 없는 낡은 규칙'] = {};
lv105OptionMap['주체할 수 없는 낡은 규칙'] = '스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@3988@@1968';

lv105ItemId['성장하는 모험가의 창'] = '10581b6a08fd331d22accdca5907a68b';
lv105OptionMap['성장하는 모험가의 창'] = {};
lv105OptionMap['성장하는 모험가의 창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['결전의 스태프 - 디멘션 워커'] = '67720e014b1efab7aedd2568f32e3bc3';
lv105OptionMap['결전의 스태프 - 디멘션 워커'] = {};
lv105OptionMap['결전의 스태프 - 디멘션 워커'] = '차원 : 시공폭뢰 스킬 강화<br>- 공격력 15% 증가<br>- 폭발 속도 50% 증가<br>- 스킬 범위 25% 증가<br>- 사상경계의 저편 효과 적용 시 전방에 다수의 차원 폭발 생성<br><br>니알리가 스킬 시전 시 분신이 대신 시전 (각성기 제외)<br><br>아래 스킬 쿨타임 10% 감소<br>- 괴리 : 할리퀸<br>- 괴리 : 패러사이트 스웜<br>- 괴리 : 칙 잇<br>- 괴리 : 저편의 자장가@@4744@@2016';

lv105ItemId['안개의 뜻을 따르는 자의 하의'] = '22febcac741c9b615a711bd0d038bdc7';
lv105OptionMap['안개의 뜻을 따르는 자의 하의'] = {};
lv105OptionMap['안개의 뜻을 따르는 자의 하의'] = '최종 데미지 8% 증가<br><br>홀딩 무력화 게이지 감소량 +200%<br><br>물리/마법 방어력 +7,000<br>이동 속도 +8%@@5604@@1928';

lv105ItemId['광폭화된 전의의 낫'] = '3548bcbb89a3dbb8b5fe5777d29c469c';
lv105OptionMap['광폭화된 전의의 낫'] = {};
lv105OptionMap['광폭화된 전의의 낫'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 장궁 - 트래블러'] = 'abe5ca37399400f26a4e849c43ed22f8';
lv105OptionMap['결전의 장궁 - 트래블러'] = {};
lv105OptionMap['결전의 장궁 - 트래블러'] = '신무병장 유성 스킬 쿨타임 15% 감소<br><br>신무병장 폴라리스 강화<br>- 탐색 최대 수 : +5회<br>- 오로라 최대 지속 시간 : +7초<br>- 피니시 발동 후에도 스킬 지속 (피니시는 지속 시간 중 최대 1회 발동 가능)<br>- 피니시 발동 후 추가 입력 시 스킬 종료<br><br>셋 업 : 맹격의 궁술 스킬 공격력 증가율 5% 추가 증가<br>셋 업 : 섬공의 궁술 스킬 쿨타임 감소율 5% 추가 증가<br><br>캠프파이어 강화<br>- HP 회복 범위 20% 증가<br>- 스튜 HP 회복량 50% 증가@@5928@@1856';

lv105ItemId['결전의 투창 - 다크 랜서'] = '06ad5e66a95d76786a0fbe2ab06a707c';
lv105OptionMap['결전의 투창 - 다크 랜서'] = {};
lv105OptionMap['결전의 투창 - 다크 랜서'] = '잠식 상태의 적 1마리당 1초마다 흑뢰의 기운 10% 획득<br>- 잠식 상태의 적이 없을 경우 1초마다 5% 감소<br><br>보스 몬스터 공격 시 흑뢰의 기운 1% 획득<br><br>흑뢰의 기운 100% 달성 시 15초 동안 다크 스타 강화<br>- 흑뢰 창이 동시에 낙하<br>- 흑뢰 마장창 추가 낙하<br>- 흑뢰 마장창 공격력 : 다크 스타 다단 히트 공격력의 1,000%@@10076@@2016';

lv105ItemId['결전의 리볼버 - 메카닉(남)'] = '22092d81763b2d925bae50c7d324a426';
lv105OptionMap['결전의 리볼버 - 메카닉(남)'] = {};
lv105OptionMap['결전의 리볼버 - 메카닉(남)'] = '로봇 전폭으로 로봇 폭발 시 R-러너 생성<br>-  R-러너 공격력 : RX-78 랜드러너 공격력의 50%<br>- 로봇 전폭의 폭발 데미지 변화율 효과 적용<br>- 최대 10개 생성<br><br><R-러너 생성 가능 스킬><br>- RX-78 랜드러너<br>- Ez-8 카운트 다운<br>- RX-60 트랩 러너<br>- 공중 전투 메카 : 템페스터<br>- 메카 드롭<br>- 스패로우 팩토리<br>- Ez-10 카운터 어택<br>- TX-45 A-Team<br><br>RX-78 랜드러너 공격력 5% 증가@@9484@@2016';

lv105ItemId['사멸하는 신뢰의 창'] = '0db7f6332a7340d24164b38d7d210b3e';
lv105OptionMap['사멸하는 신뢰의 창'] = {};
lv105OptionMap['사멸하는 신뢰의 창'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['새겨지는 맹세'] = '9e62c0bc9a8b159a6fbff2ce8b0b8f9a';
lv105OptionMap['새겨지는 맹세'] = {};
lv105OptionMap['새겨지는 맹세'] = '최종 데미지 16.5% 증가<br>모든 속성 강화 +30<br><br>회피율 +8%<br>스킬 MP 소모량 -7%@@6144@@1958';

lv105ItemId['사멸하는 신뢰의 둔기'] = '8bc24f3b326da2ba31d06f3c48a1cb00';
lv105OptionMap['사멸하는 신뢰의 둔기'] = {};
lv105OptionMap['사멸하는 신뢰의 둔기'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['어둠을 먹는 심장'] = '621ccfd41ac3245f90a75a2dba932f1f';
lv105OptionMap['어둠을 먹는 심장'] = {};
lv105OptionMap['어둠을 먹는 심장'] = '피격 시 MP 10% 회복 (쿨타임 30초)<br><br>공격 속도 +30%<br>캐스팅 속도 +40%<br>이동 속도 +30%<br>MP MAX +945<br>물리/마법 피해 감소 +10%<br>MP 1분당 348 회복@@6144@@1948';

lv105ItemId['천지를 흔드는 석갑'] = '009fe4e6aa1d6c387d5c56284a9c6d06';
lv105OptionMap['천지를 흔드는 석갑'] = {};
lv105OptionMap['천지를 흔드는 석갑'] = '모든 속성 강화 +10<br>중독 피해 +20%<br><br>[소환]<br>[장비 옵션 조작키] 입력 시 파티원 1명을 내 위치로 소환  (쿨타임 10초)<br>- 소환된 파티원은 20초 동안 이동 속도 +20%<br>- 파티원이 무적 또는 슈퍼아머인 경우 선택 불가<br><br>공격 시 모든 적에게 15초 동안 중독 상태 이상 부여 (쿨타임 10초)<br><br>물리/마법 방어력 +7,000<br>중독 내성 +10%<br>회피율 +8%@@5604@@1968';

lv105ItemId['마주할 수 없는 부러진 긍지'] = '078b7780d03944e9e3847fd471fa0dcd';
lv105OptionMap['마주할 수 없는 부러진 긍지'] = {};
lv105OptionMap['마주할 수 없는 부러진 긍지'] = '최종 데미지 7% 증가<br>감전 피해 +15%<br><br>감전 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)<br><br>물리/마법 크리티컬 히트 +5%<br>감전 내성 +10%@@6414@@1958';

lv105ItemId['스팀펑크 소닉 디스럽터'] = '06ba0b3d7d939b21ceb08306f0579a17';
lv105OptionMap['스팀펑크 소닉 디스럽터'] = {};
lv105OptionMap['스팀펑크 소닉 디스럽터'] = '공격력 증가 +133.4%<br>최종 데미지 13% 증가<br>스킬 쿨타임 회복 속도 +10% (각성기 제외)<br><br>석화 지속 시간 +3초<br><br>물리/마법 크리티컬 히트 +7%<br>모든 상태 이상 내성 -10%@@4972@@1953';

lv105ItemId['근원을 삼킨 미늘창'] = '08a1ea5583085b8463c8883d954497cf';
lv105OptionMap['근원을 삼킨 미늘창'] = {};
lv105OptionMap['근원을 삼킨 미늘창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['하이테크 바디 프로텍트 숄더'] = '003ba9ed5b693c349cad3fb7c2ad42ff';
lv105OptionMap['하이테크 바디 프로텍트 숄더'] = {};
lv105OptionMap['하이테크 바디 프로텍트 숄더'] = '최종 데미지 10% 증가<br><br>자신에게 해제되지 않는 감전 상태 이상 부여<br><br>피격 후 3초 안에 반격 시, 직전에 입은 피해의 70% 회복 (쿨타임 3초)<br><br>HP MAX +600<br>적중률 +10%@@5604@@1958';

lv105ItemId['얼어붙은 저항의 소검'] = 'a11e977751e27bb41e77404898981952';
lv105OptionMap['얼어붙은 저항의 소검'] = {};
lv105OptionMap['얼어붙은 저항의 소검'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['상상을 담은 엔지니어의 작업 벨트'] = '00bbdf258df9e3c390b1c46f39ba325e';
lv105OptionMap['상상을 담은 엔지니어의 작업 벨트'] = {};
lv105OptionMap['상상을 담은 엔지니어의 작업 벨트'] = '최종 데미지 12% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%<br>모든 속성 저항 +15@@6952@@1928';

lv105ItemId['아픔을 감싸는 위엄'] = '0fc3bff272f60ed5d4d999f52366438c';
lv105OptionMap['아픔을 감싸는 위엄'] = {};
lv105OptionMap['아픔을 감싸는 위엄'] = '최종 데미지 11% 증가<br>모든 속성 강화 +18<br><br>MP MAX +945@@4528@@1900';

lv105ItemId['용살자의 증표 - 용심 가공석'] = '09db8d8812162f16cc31df0903af3ed4';
lv105OptionMap['용살자의 증표 - 용심 가공석'] = {};
lv105OptionMap['용살자의 증표 - 용심 가공석'] = '최종 데미지 10% 증가<br>70Lv 액티브 스킬 Lv +10<br>- 제외 : 영원한 소유, 대천사의 축복<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@4796@@1938';

lv105ItemId['사멸하는 신뢰의 소검'] = '944c114b30c6b4ca0e31af02f6580f0f';
lv105OptionMap['사멸하는 신뢰의 소검'] = {};
lv105OptionMap['사멸하는 신뢰의 소검'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['끝을 바라보는 시선'] = '074bfbabfb1869d7b65f71911a488ad4';
lv105OptionMap['끝을 바라보는 시선'] = {};
lv105OptionMap['끝을 바라보는 시선'] = '최종 데미지 11% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%<br>회피율 +8%@@5066@@1968';

lv105ItemId['오버커런트 마그넷 링'] = '3987cd6773aa22eb97957dbf1de92285';
lv105OptionMap['오버커런트 마그넷 링'] = {};
lv105OptionMap['오버커런트 마그넷 링'] = '공격력 증가 +222.3%<br>최종 데미지 7% 증가<br>모든 속성 강화 +10<br><br>기절 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>공격 시 적에게 5초 동안 기절 상태 이상 부여 (쿨타임 8초)<br><br>기절 지속 시간 +2초@@4984@@1968';

lv105ItemId['천재 기술자의 보호 마스크'] = '435b31746441376a525d943d1e5fcf39';
lv105OptionMap['천재 기술자의 보호 마스크'] = {};
lv105OptionMap['천재 기술자의 보호 마스크'] = '최종 데미지 11% 증가<br><br>30초마다 HP/MP 30% 회복<br><br>물리/마법 방어력 +7,000<br>회피율 +8%@@5604@@1968';

lv105ItemId['성장하는 모험가의 토템'] = '73735b83803ec43f342b2d58744cb0d3';
lv105OptionMap['성장하는 모험가의 토템'] = {};
lv105OptionMap['성장하는 모험가의 토템'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['안개의 뜻을 따르는 자의 신발'] = '128cf233613acb4853d141280688cb28';
lv105OptionMap['안개의 뜻을 따르는 자의 신발'] = {};
lv105OptionMap['안개의 뜻을 따르는 자의 신발'] = '최종 데미지 13% 증가<br>홀딩 무력화 게이지 감소량 +100%<br><br>[약점 공략]<br>무력화 게이지량이 90% 이하인 적에게 부여 시도하는 모든 무력화형 상태 이상을 약점으로 적용<br><br>이동 속도 +8%<br>적중률 +10%@@6144@@1958';

lv105ItemId['결전의 리볼버 - 스핏파이어(남)'] = '0de8c4db9ddf8c15b769144f5b37b9d7';
lv105OptionMap['결전의 리볼버 - 스핏파이어(남)'] = {};
lv105OptionMap['결전의 리볼버 - 스핏파이어(남)'] = '버스터 샷이 가장 강한 적에게 지원 포격을 발사하도록 변경<br>- 다른 스킬 사용 중 모션 없이 시전 가능<br><br>오버차지 적용 중 평타 10회 공격 시 버스터샷 강화 탄약 충전 (최대 3개)<br>- 버스터샷 시전 시 강화 탄약 소모<br>- 탄약 1개 소모 : 공격력 15% 증가<br>- 탄약 2개 소모 : 공격력 40% 증가<br>- 탄약 3개 소모 : 공격력 80% 증가<br>- 던전 입장 시 탄약 1개 장착@@7112@@2016';

lv105ItemId['어릴적 꿈꿔온 무대'] = '08f0e19b36c8d5e4c4a9bd9f153c9deb';
lv105OptionMap['어릴적 꿈꿔온 무대'] = {};
lv105OptionMap['어릴적 꿈꿔온 무대'] = '최종 데미지 8% 증가<br><br>[꿈의 부활]<br>자신의 죽음을 꿈으로 만들며 잠듦 (쿨타임 300초)<br>- 잠들 때 HP 10% 회복<br>- 수면 상태일 때 피격 시 HP 15% 회복 (쿨타임 1초)<br>- 수면 상태 동안 피해를 받아도 꿈에서 깨지 않음<br><br>카운터 피격 시 물리/마법 피해 감소 +20%<br>HP 1분당 460.2 회복@@6952@@1948';

lv105ItemId['상상이 실현된 자유'] = '053083cff601b208098e3277886d8a5e';
lv105OptionMap['상상이 실현된 자유'] = {};
lv105OptionMap['상상이 실현된 자유'] = '최종 데미지 11% 증가<br><br>모든 속성 저항 +8<br>모든 상태 이상 내성 +30%<br>HP 1분당 460.2 회복@@6144@@1948';

lv105ItemId['잠식된 신수의 눈물'] = '0efac1f99dc08500fd61a9143374ce12';
lv105OptionMap['잠식된 신수의 눈물'] = {};
lv105OptionMap['잠식된 신수의 눈물'] = '최종 데미지 12% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +13%<br>캐스팅 속도 +17%<br>이동 속도 +5%<br>모든 상태 이상 내성 +10%@@6682@@1928';

lv105ItemId['근원을 삼킨 중검'] = '12776c72a541cd265e4df92fb300c7b5';
lv105OptionMap['근원을 삼킨 중검'] = {};
lv105OptionMap['근원을 삼킨 중검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['사멸하는 신뢰의 선현궁'] = '005aa96c3e2ae344a8ba86625febbcec';
lv105OptionMap['사멸하는 신뢰의 선현궁'] = {};
lv105OptionMap['사멸하는 신뢰의 선현궁'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['성장하는 모험가의 미늘창'] = 'c5451228dd344931504f59662d509488';
lv105OptionMap['성장하는 모험가의 미늘창'] = {};
lv105OptionMap['성장하는 모험가의 미늘창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 광창 - 다크 랜서'] = '2f9e97914447b5c4d42da63fee73f10f';
lv105OptionMap['결전의 광창 - 다크 랜서'] = {};
lv105OptionMap['결전의 광창 - 다크 랜서'] = '잠식 상태의 적 1마리당 1초마다 흑뢰의 기운 10% 획득<br>- 잠식 상태의 적이 없을 경우 1초마다 5% 감소<br><br>보스 몬스터 공격 시 흑뢰의 기운 1% 획득<br><br>흑뢰의 기운 100% 달성 시 15초 동안 다크 스타 강화<br>- 흑뢰 창이 동시에 낙하<br>- 흑뢰 마장창 추가 낙하<br>- 흑뢰 마장창 공격력 : 다크 스타 다단 히트 공격력의 1,000%@@10076@@2016';

lv105ItemId['시각의 관점'] = '922575ac85da5f999815024b6b2ec2a7';
lv105OptionMap['시각의 관점'] = {};
lv105OptionMap['시각의 관점'] = '[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>공격 시 MP 1,500 회복 (쿨타임 1초)<br><br>물리/마법 피해 감소 +10%<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@4528@@1612';

lv105ItemId['광폭화된 전의의 토템'] = '060a7fc85bab63b33152af7420789ec0';
lv105OptionMap['광폭화된 전의의 토템'] = {};
lv105OptionMap['광폭화된 전의의 토템'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['광폭화된 전의의 자동권총'] = '4fd35011dca1fcc168a9a3a668569062';
lv105OptionMap['광폭화된 전의의 자동권총'] = {};
lv105OptionMap['광폭화된 전의의 자동권총'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 소검 - 소드마스터'] = '5b6c824cbcc1dca38056596f6aa125c5';
lv105OptionMap['결전의 소검 - 소드마스터'] = {};
lv105OptionMap['결전의 소검 - 소드마스터'] = '궁극의 마검 레바테인으로 악즉참 시전<br>- 악즉참 항상 최대 충전<br>- 악즉참 공격력 15% 감소<br><br>레바테인에 의한 악즉참은 적에게 4초 동안 상흔을 부여<br>- 상흔이 생성된 적에게 반월 또는 섬광 적중 시 악즉참 남은 쿨타임 10% 감소@@8892@@2016';

lv105ItemId['흑화의 구속 팔찌'] = '5ca915f03d8a5a625465366e58bdc67f';
lv105OptionMap['흑화의 구속 팔찌'] = {};
lv105OptionMap['흑화의 구속 팔찌'] = '최종 데미지 7.1% 증가<br>출혈 피해 전환 +50%<br>출혈 피해 +20%<br><br>물리/마법 피해의 50%를 출혈 피해로 전환<br><br>출혈 내성 +1%@@3165@@1953';

lv105ItemId['황혼의 성단'] = '7b590f832a97ee8eacc17a54e3fe2d4a';
lv105OptionMap['황혼의 성단'] = {};
lv105OptionMap['황혼의 성단'] = '공격력 증가 +444.6%<br>최종 데미지 5% 증가<br>모든 속성 강화 +20<br><br>보스 몬스터 공격 시 HP 1% 회복 (쿨타임 0.5초)<br><br>공격 속도 +8%<br>캐스팅 속도 +12%@@6048@@1963';

lv105ItemId['결전의 투창 - 뱅가드'] = '748d312e9293c039299ef1a7209cc37e';
lv105OptionMap['결전의 투창 - 뱅가드'] = {};
lv105OptionMap['결전의 투창 - 뱅가드'] = '스매쉬의 극한에 도달하여 임팩트 스매쉬 강화<br>- 임팩트 스매쉬를 캔슬하여 스킬 시전 시 해당 스킬 쿨타임 20% 감소 (각성기 제외)<br>- 스택 횟수 +1<br>- 적중 시 스택 회복 감소 시간 +0.3초<br>- 공격 속도 버프 추가량 +10%@@5928@@2016';

lv105ItemId['옵티컬 컴뱃 글래스'] = 'a3e7f82fa4fb23f2e7eda9e1fadbe8ec';
lv105OptionMap['옵티컬 컴뱃 글래스'] = {};
lv105OptionMap['옵티컬 컴뱃 글래스'] = '최종 데미지 12% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +5%<br>캐스팅 속도 +7.5%<br>이동 속도 +5%<br>HP MAX +600<br>적중률 +10%@@4528@@1958';

lv105ItemId['자연에 녹아드는 이슬'] = '37522f61ef37c5b5251900c3345af96a';
lv105OptionMap['자연에 녹아드는 이슬'] = {};
lv105OptionMap['자연에 녹아드는 이슬'] = '최종 데미지 10% 증가<br><br>[속성 친화 - 암]<br>암속성 저항이 높아질수록 물리/마법 피해 감소<br>- 적의 공격에 암속성 부여<br><br>피격 시 5초 동안 HP 5% 회복 (쿨타임 7초)<br><br>HP MAX +600<br>암속성 저항 +35@@5604@@1948';

lv105ItemId['내딛은 자의 둔기'] = '620a5d6e3a573b289aa8a7571b7a9e54';
lv105OptionMap['내딛은 자의 둔기'] = {};
lv105OptionMap['내딛은 자의 둔기'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['오퍼레이션 오더'] = '04455f6f37f540bf6ad6027b26b049d3';
lv105OptionMap['오퍼레이션 오더'] = {};
lv105OptionMap['오퍼레이션 오더'] = '화속성 강화 +70<br><br>물리/마법 크리티컬 히트 +15%<br>화속성 저항 +10@@4528@@1958';

lv105ItemId['잠식된 신수의 그릇된 망토'] = '1ed7ede2a860cd3989a9a059916313f3';
lv105OptionMap['잠식된 신수의 그릇된 망토'] = {};
lv105OptionMap['잠식된 신수의 그릇된 망토'] = '최종 데미지 11% 증가<br><br>물리/마법 피해 감소 +10%<br>모든 속성 저항 +8<br>회피율 +8%@@6144@@1928';

lv105ItemId['결전의 리볼버 - 레인저(남)'] = '0d7a3b3ae556ab5f2f74a57c1c8faa24';
lv105OptionMap['결전의 리볼버 - 레인저(남)'] = {};
lv105OptionMap['결전의 리볼버 - 레인저(남)'] = '더블 건호크 강화<br>- 세 번째 건호크 잡기 성공 시 두 번째 건호크 투척 이후 사용한 스킬들의 남은 쿨타임 20% 감소<br>- 크기 10% 증가<br>- 시전 시 4초 동안 공격 속도 +30%<br><br>스타일리쉬 캔슬 가능 최대 횟수 +1<br><br>장착 시 스펙트럼 생성@@6520@@2016';

lv105ItemId['피어나는 자연의 삶'] = '3e63f9705e6592a52814f399c85e37d4';
lv105OptionMap['피어나는 자연의 삶'] = {};
lv105OptionMap['피어나는 자연의 삶'] = '공격력 증가 +133.4%<br>최종 데미지 2% 증가<br>수속성 강화 +20<br><br>[아이스 포스]<br>공격 시 수속성 강화 수치에 따라 수속성 원소 공격 시전 (쿨타임 1.5초)<br>- 100 이상 150 미만 : 아이스 니들<br>- 150 이상 250 미만 : 아이스 브레스<br>- 250 이상 : 블리자드<br>- 각각 총 공격력 증가의 720% / 920% / 1,480%만큼 피해<br>- 블리자드는 적에게 10초 동안 빙결 상태 이상 부여 (쿨타임 30초)<br><br>빙결 지속 시간 +3초<br><br>공격 속도 +10%<br>캐스팅 속도 +15%<br>수속성 저항 +20@@6414@@1978';

lv105ItemId['결전의 도 - 팔라딘'] = '61b5576a6e84ee3aa0db081e59731f61';
lv105OptionMap['결전의 도 - 팔라딘'] = {};
lv105OptionMap['결전의 도 - 팔라딘'] = '세라픽 페더를 소모하여 이스트레인지먼트 시전 시 추가 효과 발동<br>- 세라픽 페더 1장당 공격력 4% 증가<br>- 세라픽 페더 1장당 스킬 범위 3% 증가<br>- 적에게 기절 상태 이상 부여<br><br>세라픽 페더를 소모하여 위핑 웨이브 시전 시 추가 효과 발동<br>- 끌어들인 적을 2초 동안 홀딩<br>- 몰이 효과 범위 6단계 적용<br>- 몰이 효과 범위 20% 증가<br>- 세라픽 페더 최대 2개만 소모<br><br>위핑 웨이브 강화<br>- 스킬 범위 20% 증가<br>- 타격 성공 시 세라픽 페더 습득량 +2<br>- 도발시킨 적 앞에서 방어 상태 유지 시 세라픽 페더 획득 시간 50% 감소@@9484@@2016';

lv105ItemId['구속된 자유'] = 'c3755118b4e3b8ee50a3bfc6f8c027c9';
lv105OptionMap['구속된 자유'] = {};
lv105OptionMap['구속된 자유'] = '공격력 증가 +518.7%<br>최종 데미지 10% 증가<br>모든 속성 강화 +35<br><br>스킬의 무색 큐브 조각 소모량 8배 증가 (최대 16개)<br><br>물리/마법 크리티컬 히트 +7%<br>모든 상태 이상 내성 -10%@@5416@@1998';

lv105ItemId['광폭화된 전의의 둔기'] = '1b8a2818f8b58e17ac0cd15296725059';
lv105OptionMap['광폭화된 전의의 둔기'] = {};
lv105OptionMap['광폭화된 전의의 둔기'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['탐험가의 노련한 코트'] = '05eff736fa865f538981e7a3ea3bfd67';
lv105OptionMap['탐험가의 노련한 코트'] = {};
lv105OptionMap['탐험가의 노련한 코트'] = '모든 속성 강화 +10<br>출혈 피해 +20%<br><br>[장비 옵션 조작키] 입력 시 맵에 떨어져 있는 아이템을 캐릭터 발밑으로 이동 (쿨타임 30초)<br><br>공격 시 모든 적에게 15초 동안 출혈 상태 이상 부여 (쿨타임 10초)<br><br>이동 속도 +8%<br>HP MAX +300<br>MP MAX +473<br>출혈 내성 +10%<br>인벤토리 무게 한도 +5kg@@5604@@1968';

lv105ItemId['청연을 수호하는 의지'] = '01ad86b791f9959359e04d3be6178189';
lv105OptionMap['청연을 수호하는 의지'] = {};
lv105OptionMap['청연을 수호하는 의지'] = '최종 데미지 9% 증가<br>화상 피해 +10%<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>화상 지속 시간 -20%<br><br>화속성 저항 +10@@5066@@1938';

lv105ItemId['광폭화된 전의의 소검'] = '248568cf372ebcfe5a17912ab99544b0';
lv105OptionMap['광폭화된 전의의 소검'] = {};
lv105OptionMap['광폭화된 전의의 소검'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 중검 - 히트맨'] = '89f9c7e0396a7dc61351afd4f8fbc6b5';
lv105OptionMap['결전의 중검 - 히트맨'] = {};
lv105OptionMap['결전의 중검 - 히트맨'] = '전직 스킬의 후딜레이를 캔슬하여 데드 식스를 시전 가능<br>후딜레이 캔슬 시 아래의 효과 적용<br>- 캔슬한 스킬 남은 쿨타임 20% 감소<br>- 데드 식스 남은 쿨타임 50% 감소<br>- 8초 동안 공격 속도 +7% (최대 3중첩)@@10076@@2016';

lv105ItemId['사멸하는 신뢰의 스태프'] = '348638a2372850c483c1d18678a0edba';
lv105OptionMap['사멸하는 신뢰의 스태프'] = {};
lv105OptionMap['사멸하는 신뢰의 스태프'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 보우건 - 런처(여)'] = 'f39096a1750add0ca33e055ea14a11fa';
lv105OptionMap['결전의 보우건 - 런처(여)'] = {};
lv105OptionMap['결전의 보우건 - 런처(여)'] = '화염 강타를 화염을 압축하여 발사하는 최대 3회 충전 스킬로 변경<br>- 공격력 : 총 공격력의 50%<br>- 충전 쿨타임 : 4초<br><br>M-3 화염방사기 시전 시 화염을 압축하여 발사<br><br>UHT-03 플레게톤 이동 속도 비율 +10%@@9484@@2016';

lv105ItemId['근원을 삼킨 권투글러브'] = '0932d17f069b22230bf17ad018e8cb62';
lv105OptionMap['근원을 삼킨 권투글러브'] = {};
lv105OptionMap['근원을 삼킨 권투글러브'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['내딛은 자의 소검'] = 'd46979700e78a28246f18e3284e2f142';
lv105OptionMap['내딛은 자의 소검'] = {};
lv105OptionMap['내딛은 자의 소검'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['언리밋 사이버네틱'] = '11457e80ad591fa85c2be95dacfb953a';
lv105OptionMap['언리밋 사이버네틱'] = {};
lv105OptionMap['언리밋 사이버네틱'] = '스킬 쿨타임 15% 감소 (각성기 제외)<br><br>모든 적에게 5초 동안 저주 상태 이상 부여 (쿨타임 20초)<br><br>이동 속도 +8%<br>회피율 +8%@@5874@@1948';

lv105ItemId['파수꾼의 증표'] = '6dd7ac62edd60e331721359ee55b8ad3';
lv105OptionMap['파수꾼의 증표'] = {};
lv105OptionMap['파수꾼의 증표'] = '최종 데미지 10% 증가<br><br>[인형극]<br>D011-Risa가 캐릭터를 따라다님<br>- "컨퓨즈드 코어 슈트" 장착 필요<br><br>HP MAX +600<br>물리/마법 크리티컬 히트 +5%@@6144@@1928';

lv105ItemId['결전의 보우건 - 레인저(남)'] = '6dce09b076fc82b012a2d73fc9046dc6';
lv105OptionMap['결전의 보우건 - 레인저(남)'] = {};
lv105OptionMap['결전의 보우건 - 레인저(남)'] = '더블 건호크 강화<br>- 세 번째 건호크 잡기 성공 시 두 번째 건호크 투척 이후 사용한 스킬들의 남은 쿨타임 20% 감소<br>- 크기 10% 증가<br>- 시전 시 4초 동안 공격 속도 +30%<br><br>스타일리쉬 캔슬 가능 최대 횟수 +1<br><br>장착 시 스펙트럼 생성@@6520@@2016';

lv105ItemId['결전의 광창 - 뱅가드'] = '0f64a59dc76c3a3988b07b61eafe48aa';
lv105OptionMap['결전의 광창 - 뱅가드'] = {};
lv105OptionMap['결전의 광창 - 뱅가드'] = '스매쉬의 극한에 도달하여 임팩트 스매쉬 강화<br>- 임팩트 스매쉬를 캔슬하여 스킬 시전 시 해당 스킬 쿨타임 20% 감소 (각성기 제외)<br>- 스택 횟수 +1<br>- 적중 시 스택 회복 감소 시간 +0.3초<br>- 공격 속도 버프 추가량 +10%@@5928@@2016';

lv105ItemId['결전의 도 - 소울브링어'] = '0f1a5e1b4a4b94958d2722a9945371b9';
lv105OptionMap['결전의 도 - 소울브링어'] = {};
lv105OptionMap['결전의 도 - 소울브링어'] = '명계의 힘으로 흑염의 칼라가 명계의 불꽃을 발현<br>불꽃 최대 중첩 수 +2<br><br>아래 스킬들이 명계의 불꽃을 발현<br>명계의 불꽃이 붙은 대상을 특정 스킬로 공격 시 불꽃 폭발<br>- 폭발 공격력 :  흑염의 칼라 불꽃 공격력의 300%<br>- 불꽃 중첩당 폭발 공격력 10% 증가<br><br><명계의 불꽃 발현 스킬><br>- 흑염의 칼라<br>- 냉기의 사야<br>- 역병의 라사<br>- 툼스톤<br>- 툼스톤 스웜프<br>- 블레이드 팬텀<br><br><명계의 불꽃 폭발 스킬><br>- 귀참<br>- 달빛 베기<br>- 귀참 : 광폭<br>- 귀영섬<br>- 귀참 : 나락<br>- 흑염검<br>- 검귀강령 : 식<br>- 탐식의 크레바스@@5928@@2016';

lv105ItemId['결전의 봉 - 소환사'] = '3e1720a5fd9a9adb8326f459a966a6ba';
lv105OptionMap['결전의 봉 - 소환사'] = {};
lv105OptionMap['결전의 봉 - 소환사'] = '상급 정령들과 정령왕 에체베리아의 이클립스 하이브 추가 조작 패턴 공격력 20% 증가<br><br>이클립스 하이브 강화<br>- 추가 조작 패턴 캐스팅 모션 삭제 <br>- 데드 멀커 : 블랙스웜 홀딩 추가<br>- 글레어린 : 썬더콜링이 적을 끌어모음<br>- 아퀘리스 : 헵타 아이스 미사일이 지상 폭격<br>- 플레임 헐크 : 화염폭검술이 회오리를 생성하고 적을 끌어모음<br>- 에체베리아 : 초고밀도 레이저가 다수 레이저의 지상 폭격으로 변경@@8300@@2016';

lv105ItemId['광폭화된 전의의 건틀릿'] = 'a11b69d31a2032a8505a10e639e865a6';
lv105OptionMap['광폭화된 전의의 건틀릿'] = {};
lv105OptionMap['광폭화된 전의의 건틀릿'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['수호하는 인내'] = '168ad8e204aca0ebd4fe71c2fad71b0f';
lv105OptionMap['수호하는 인내'] = {};
lv105OptionMap['수호하는 인내'] = '최종 데미지 13% 증가<br><br>HP MAX +5%<br>HP MAX +600@@6144@@1958';

lv105ItemId['결전의 머스켓 - 레인저(남)'] = '2689557c3f125474b4df96f7a33f6433';
lv105OptionMap['결전의 머스켓 - 레인저(남)'] = {};
lv105OptionMap['결전의 머스켓 - 레인저(남)'] = '더블 건호크 강화<br>- 세 번째 건호크 잡기 성공 시 두 번째 건호크 투척 이후 사용한 스킬들의 남은 쿨타임 20% 감소<br>- 크기 10% 증가<br>- 시전 시 4초 동안 공격 속도 +30%<br><br>스타일리쉬 캔슬 가능 최대 횟수 +1<br><br>장착 시 스펙트럼 생성@@6520@@2016';

lv105ItemId['근원을 삼킨 완드'] = 'c7705c82d68209fa32c59b7a9d960e5b';
lv105OptionMap['근원을 삼킨 완드'] = {};
lv105OptionMap['근원을 삼킨 완드'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['얼어붙은 저항의 보우건'] = '045d1b54412a36fe7cc4a59d1e9a8a09';
lv105OptionMap['얼어붙은 저항의 보우건'] = {};
lv105OptionMap['얼어붙은 저항의 보우건'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['샤를의 가면'] = '6a3fd6aea652c8cba810beff4aad182e';
lv105OptionMap['샤를의 가면'] = {};
lv105OptionMap['샤를의 가면'] = '최종 데미지 35% 감소 (각성기 제외)<br>스킬 쿨타임 50% 감소 (각성기 제외)<br><br>공격 시 적에게 10초 동안 출혈/중독 상태 이상 부여 (쿨타임 3초)@@6170@@1872';

lv105ItemId['결전의 중검 - 요원'] = '1758dfbdf2e9ad3a171f60f89dbcf426';
lv105OptionMap['결전의 중검 - 요원'] = {};
lv105OptionMap['결전의 중검 - 요원'] = '암살목표의 표식이 부여된 적에게 일부 스킬 공격 시 15초 동안 총상 / 절상 부여 (최대 3중첩)<br>- 총상 부여 스킬 : 조준 사격, 목표 사격<br>- 절상 부여 스킬 : 쌍현참, 암월비보<br><br>총상 / 절상 상태의 적을 아래의 스킬로 공격 시 총상 / 절상이 해제되며 스킬 공격력 증가<br>- 문페이즈 : 총상 1중첩당 8%<br>- 섬멸 : 절상 1중첩당 8%<br>- 월광진혼곡 : 총상 / 절상 1중첩당 8%<br><br>아래의 스킬 쿨타임 15% 감소<br>- 쌍현참<br>- 조준 사격<br>- 암월비보<br>- 목표 사격<br><br>문페이즈 크기 10% 증가@@7112@@2016';

lv105ItemId['골렘의 중추석 반지'] = '639dedaa8b9218023da86ff44f80662f';
lv105OptionMap['골렘의 중추석 반지'] = {};
lv105OptionMap['골렘의 중추석 반지'] = '최종 데미지 10% 증가<br><br>물리/마법 방어력 +12,000<br>적중률 +10%<br>회피율 +8%@@6414@@1938';

lv105ItemId['포이즈드 나이트 팬츠'] = '0446ffe6652d40bdd06aa5b4b63e78e9';
lv105OptionMap['포이즈드 나이트 팬츠'] = {};
lv105OptionMap['포이즈드 나이트 팬츠'] = '최종 데미지 10% 증가<br>75~80Lv 스킬 공격력 5% 증가<br>75~80Lv 스킬 범위 15% 증가@@4824@@1900';

lv105ItemId['두근거리는 열정'] = '1e7ade53a86f1d3589082304002ce4bc';
lv105OptionMap['두근거리는 열정'] = {};
lv105OptionMap['두근거리는 열정'] = '최종 데미지 10% 증가<br><br>물리/마법 피해 감소 +10%<br>물리/마법 방어력 +7,000<br>HP 1분당 46.02 회복<br>수면 내성 -20%@@4796@@1948';

lv105ItemId['결전의 차크라 웨펀 - 쿠노이치'] = '2a2608e0ea07d330a222712bf0dd447d';
lv105OptionMap['결전의 차크라 웨펀 - 쿠노이치'] = {};
lv105OptionMap['결전의 차크라 웨펀 - 쿠노이치'] = '인법 : 잔영 남기기의 잔영 소모 1개당 다음에 재생할 육도윤회의 잔영 공격력 비율 1.5% 추가 증가 (최대 12중첩)<br><br>인법 : 육도윤회 시전 시 5초 동안 슈퍼아머 상태가 되며 스킬 쿨타임 회복 속도 +50% (쿨타임 20초 / 각성기 제외)<br><br>인법 : 육도윤회 스킬 종료 시 잔영 5 회복 (쿨타임 10초)@@8300@@2016';

lv105ItemId['결전의 클로 - 스트라이커(여)'] = 'ab9aa27481df2c416b0223939902fcc5';
lv105OptionMap['결전의 클로 - 스트라이커(여)'] = {};
lv105OptionMap['결전의 클로 - 스트라이커(여)'] = '라이트닝 댄스 이동 횟수가 4회 감소하고 1:1 공격 시 대상의 좌우를 이동하며 공격<br>- 공격력 15% 감소<br>- 공격 속도 +30%<br>- 이동 범위 5% 증가<br>- 공격 시 적에게 감전 상태 이상 부여 (쿨타임 15초)<br><br>라이트닝 댄스 공격 시 충격파 생성<br>- 직접 타격 대상은 충격파 피격 제외<br>- 1:1 공격일 경우 충격파 미생성@@9484@@2016';

lv105ItemId['약속을 지키는 발걸음'] = '0345e0bed61cbd915303f995f013fb3e';
lv105OptionMap['약속을 지키는 발걸음'] = {};
lv105OptionMap['약속을 지키는 발걸음'] = '최종 데미지 15.5% 증가<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>"영면을 위한 준비" 장비의 [얼렸다 녹였다] 쿨타임 제거<br><br>화상 지속 시간 +4초<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5중첩)<br><br>일반 몬스터 공격 시 모든 적에게 빙결 상태 이상 부여 (쿨타임 1초)@@5524@@1948';

lv105ItemId['디젯 퓨즈 초크'] = '449297ab52b15a0363fd98f02b1af4fe';
lv105OptionMap['디젯 퓨즈 초크'] = {};
lv105OptionMap['디젯 퓨즈 초크'] = '최종 데미지 8% 증가<br><br>공격 시 적의 무력화 게이지량 10 감소 (쿨타임 3초, 버퍼 미적용)<br><br>무력화 게이지가 파괴된 적 공격 시 아래의 효과 적용<br>- 파괴 횟수에 따라 무력화 게이지 감소량 +20% (최대 5중첩)<br>- 마지막으로 적용된 무력화형 상태 이상을 약점 상태 이상으로 적용<br><br>HP MAX +600<br>물리/마법 방어력 +7,000@@6682@@1920';

lv105ItemId['찰랑이는 생명수'] = '19324f712cecd5acf393a66395cecfc7';
lv105OptionMap['찰랑이는 생명수'] = {};
lv105OptionMap['찰랑이는 생명수'] = '최종 데미지 9% 증가<br><br>[아이스 스트라이크]<br>빙결 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>[얼음 걸음]<br>발걸음에 냉기의 기운을 담아 이동 시 3초 동안 빙하 지대 생성<br>- 빙하 지대는 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 8초)<br><br>빙결 상태 적 공격 시 냉기 폭발 발생 (쿨타임 0.5초)<br>- 총 공격력 증가의 40%만큼 피해<br><br>HP MAX +600@@4714@@1930';

lv105ItemId['근원을 삼킨 보우건'] = '5677fd1686c2b78b22ea36beb98d41ca';
lv105OptionMap['근원을 삼킨 보우건'] = {};
lv105OptionMap['근원을 삼킨 보우건'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['근원을 삼킨 자동권총'] = '448226c65d254dece58cde5970fb0ae5';
lv105OptionMap['근원을 삼킨 자동권총'] = {};
lv105OptionMap['근원을 삼킨 자동권총'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['미지의 문명 - 트윈 스톤'] = 'dd57a830c1b71e58aa9a33feae415f8f';
lv105OptionMap['미지의 문명 - 트윈 스톤'] = {};
lv105OptionMap['미지의 문명 - 트윈 스톤'] = '수속성 강화 +70<br><br>물리/마법 크리티컬 히트 +15%<br>수속성 저항 +10@@4528@@1958';

lv105ItemId['결전의 십자가 - 미스트리스'] = '3d600aa6d433993b4380b5e5aa94d37c';
lv105OptionMap['결전의 십자가 - 미스트리스'] = {};
lv105OptionMap['결전의 십자가 - 미스트리스'] = '타락의 칠죄종 변신 중 죄를 깨우는 손길 공격 시 변신 지속 시간 +12초<br>- 최대 변신 지속 시간 초과 불가능<br><br>글러트니 바밋 시전 시 자신을 중심으로 적을 흡수하고 폭발<br>- 공격력 30% 증가<br>- 죄업 스택 3개 이상일 때 시전 시 죄업 스택을 3개 소모하고 분신이 대신 시전<br><br>죄업 스택이 3개 이상일 때 무거운 죄업 시전 시 죄업 스택을 3개 소모하고 10초 동안 물리/마법 피해 감소 +15%<br><br>일곱 개의 대죄 적용 중 무거운 죄업 적 홀딩 시간 +1초<br><br>죄를 깨우는 손길 죄업 스택 증가량 +2@@7708@@2016';

lv105ItemId['결전의 보우건 - 스핏파이어(남)'] = '8cc7d6d2657108cd8c0726005079afb4';
lv105OptionMap['결전의 보우건 - 스핏파이어(남)'] = {};
lv105OptionMap['결전의 보우건 - 스핏파이어(남)'] = '버스터 샷이 가장 강한 적에게 지원 포격을 발사하도록 변경<br>- 다른 스킬 사용 중 모션 없이 시전 가능<br><br>오버차지 적용 중 평타 10회 공격 시 버스터샷 강화 탄약 충전 (최대 3개)<br>- 버스터샷 시전 시 강화 탄약 소모<br>- 탄약 1개 소모 : 공격력 15% 증가<br>- 탄약 2개 소모 : 공격력 40% 증가<br>- 탄약 3개 소모 : 공격력 80% 증가<br>- 던전 입장 시 탄약 1개 장착@@7112@@2016';

lv105ItemId['수확하는 옥수'] = '75e882cdd821aa67748eb24ff1f5be59';
lv105OptionMap['수확하는 옥수'] = {};
lv105OptionMap['수확하는 옥수'] = '최종 데미지 8% 증가<br>스킬 쿨타임 회복 속도 +25% (각성기 제외)<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>적중률 +10%@@4528@@1968';

lv105ItemId['메탈기어 암 숄더'] = '2c3d242ad16ba75b314d3c6909fbad1a';
lv105OptionMap['메탈기어 암 숄더'] = {};
lv105OptionMap['메탈기어 암 숄더'] = '최종 데미지 3% 증가<br>스킬 쿨타임 회복 속도 +25% (각성기 제외)<br><br>물리/마법 크리티컬 히트 +5%<br>MP MAX +5%@@3450@@1948';

lv105ItemId['인력의 법칙'] = '6779c7e0993f89359b6681fa74891486';
lv105OptionMap['인력의 법칙'] = {};
lv105OptionMap['인력의 법칙'] = '최종 데미지 14% 증가<br><br>대시 공격 시 전방으로 빠르게 질주 (쿨타임 5초)<br><br>이동 속도 +28%<br>회피율 +8%@@5604@@1958';

lv105ItemId['결전의 핸드캐넌 - 메카닉(여)'] = '2408ac00b53923d0db2c3d961005c98d';
lv105OptionMap['결전의 핸드캐넌 - 메카닉(여)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 메카닉(여)'] = 'G-S.P. 팔콘 쿨타임 20% 감소<br><br>G-1 코로나 상태에서 롤링썬더 또는 랩터로 트랜스폼 시 8초 동안 홀로그램 G-1 코로나 생성<br>- 공격력 : G-1 코로나 공격력의 50%<br>- G 마그네틱 시전 시 홀로그램 G-1 코로나가 대신 시전<br><br>트랜스폼 : G-3 랩터가 강화 레이저 발사<br>- 강화 레이저 발사@@8300@@2016';

lv105ItemId['성장하는 모험가의 쌍검'] = '8378afb3020b9381933dfea97719db1d';
lv105OptionMap['성장하는 모험가의 쌍검'] = {};
lv105OptionMap['성장하는 모험가의 쌍검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['성장하는 모험가의 인장 반지'] = '8ca6d486809b36bb94eb6a464a4ebbe2';
lv105OptionMap['성장하는 모험가의 인장 반지'] = {};
lv105OptionMap['성장하는 모험가의 인장 반지'] = '최종 데미지 7% 증가@@5336@@1986';

lv105ItemId['결전의 대검 - 엘븐나이트'] = '7b08ff253db0cf304c8733df798a92db';
lv105OptionMap['결전의 대검 - 엘븐나이트'] = {};
lv105OptionMap['결전의 대검 - 엘븐나이트'] = '체인러쉬 콤보 6중첩 상태에서 체인 스트라이크 시전 시 8초 동안 피버 타임<br>- 체인러쉬 공격력 500% 증가<br>- 체인 게이지의 모든 영역을 성공 영역으로 변경<br>- 1~30Lv 스킬 쿨타임 70% 감소<br>- 1~30Lv 스킬 공격력 50% 감소 (체인러쉬 제외)<br><br>체인러쉬 시전 시 1~30Lv 스킬 남은 쿨타임 10% 감소@@5928@@2016';

lv105ItemId['미니어쳐 헤드셋 암릿'] = '006fece0fcb87360c4ca10a575490cf4';
lv105OptionMap['미니어쳐 헤드셋 암릿'] = {};
lv105OptionMap['미니어쳐 헤드셋 암릿'] = '기본기 숙련 최종 데미지 44% 증가<br>15~30Lv 스킬 공격력 21% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>적중률 +10%@@5066@@1948';

lv105ItemId['결전의 권투글러브 - 스트라이커(남)'] = '0ed8b87bd17aa861de8940cd5e583706';
lv105OptionMap['결전의 권투글러브 - 스트라이커(남)'] = {};
lv105OptionMap['결전의 권투글러브 - 스트라이커(남)'] = '이중개방의 효과가 10초 동안 유지되는 맹렬한 화염으로 변경되며 최종 데미지 45% 증가 (각성기 제외)<br>- 이중개방 강화 공격력 효과 미적용<br><br>화염의 힘으로 머슬시프트 강화<br>- 캔슬 공격 시 이중개방 남은 쿨타임 2% 감소<br>- 캔슬 시 30초 동안 전직 계열 스킬 공격력 증가율 +2%<br>- 캔슬 횟수 +2@@7112@@2016';

lv105ItemId['결전의 소검 - 아수라'] = '7c3b4c751b306d1b52ac1a7a5a15e3a9';
lv105OptionMap['결전의 소검 - 아수라'] = {};
lv105OptionMap['결전의 소검 - 아수라'] = '파동검 폭염이 빠르게 폭발<br>- 폭발 위치 변경<br>- 폭발 간격 시간 30% 감소<br>- 폭발 간격 거리 90% 감소<br>- 폭발 크기 45% 증가<br>- 3타 폭발 크기 70% 증가<br><br>뇌신의 힘을 받아 아래의 스킬 시전 중 작열 파동진을 모션 없이 시전 가능<br>- 파동검 폭염<br>- 부동명왕진<br>- 파동검 : 인다라망<br><br>살의의 파동 파동 영역 20% 증가<br><br>작열 파동진 스킬 범위 25% 증가@@10672@@2016';

lv105ItemId['결전의 염주 - 크루세이더(남)'] = '505aec8e464bb2a88c7519803bb78a87';
lv105OptionMap['결전의 염주 - 크루세이더(남)'] = {};
lv105OptionMap['결전의 염주 - 크루세이더(남)'] = '영광의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>슬로우 힐 스킬 범위 20% 증가<br><br>정의의 심판 시전 시 빛무리를 소환하여 범위 내 적 10명을 추적 후 정의의 창을 발사하여 폭발<br>- 창 공격력 : 빛의 칼날 공격력의 1600%<br>- 폭발 공격력 : 마법진 최대 유지 시 피니쉬 공격력의 100%<br>- 다른 스킬 시전 중 시전 가능<br><br>미러클 스플리터 스킬 공격력 20% 증가<br>- 돌진 거리 70% 감소<br><br>디플렉트 월 HP 400% 증가<br>- 이동하지 않음<br><br>싱글 플레이 시 독립 공격력 20% 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['사멸하는 신뢰의 건틀릿'] = '5331449c2d9e332be4c6de7cd1271bbb';
lv105OptionMap['사멸하는 신뢰의 건틀릿'] = {};
lv105OptionMap['사멸하는 신뢰의 건틀릿'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['사멸하는 신뢰의 중검'] = '081a5d093b1185df85e2acb4aca90a35';
lv105OptionMap['사멸하는 신뢰의 중검'] = {};
lv105OptionMap['사멸하는 신뢰의 중검'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 둔기 - 엘븐나이트'] = '452a01be805c3cbf13183f5a51c6cc45';
lv105OptionMap['결전의 둔기 - 엘븐나이트'] = {};
lv105OptionMap['결전의 둔기 - 엘븐나이트'] = '체인러쉬 콤보 6중첩 상태에서 체인 스트라이크 시전 시 8초 동안 피버 타임<br>- 체인러쉬 공격력 500% 증가<br>- 체인 게이지의 모든 영역을 성공 영역으로 변경<br>- 1~30Lv 스킬 쿨타임 70% 감소<br>- 1~30Lv 스킬 공격력 50% 감소 (체인러쉬 제외)<br><br>체인러쉬 시전 시 1~30Lv 스킬 남은 쿨타임 10% 감소@@5928@@2016';

lv105ItemId['고통의 상처'] = '0f80d093bd920a6c75076ae987e9c157';
lv105OptionMap['고통의 상처'] = {};
lv105OptionMap['고통의 상처'] = '최종 데미지 12% 증가<br><br>장비로 인한 HP 회복 효과 30% 증가<br><br>HP MAX +600<br>HP 1분당 460.2 회복@@4258@@1996';

lv105ItemId['결전의 투창 - 드래고니안 랜서'] = 'f898fc77d94ad7a778ac3a745305e312';
lv105OptionMap['결전의 투창 - 드래고니안 랜서'] = {};
lv105OptionMap['결전의 투창 - 드래고니안 랜서'] = '서번트 랜스 스킬로 공격한 적에게 사냥 표식을 생성하고 사냥 1단계 부여<br>사냥 표식 20개마다 사냥 단계 상승 (최대 3단계)<br>아래 스킬 시전 시 사냥 단계를 소모하고 해당 스킬 공격력 증가<br>- 데보닝 슬래셔<br>- 팔티잔 스큐어<br>- 날개 꿰기<br><br><사냥 단계별 공격력 증가량><br>- 1단계 : 10%<br>- 2단계 : 20%<br>- 3단계 : 30%<br><br><사냥 표식 부여량><br>- 서번트 랜스 : 타격당 3 스택<br>- 서번트 랜스 : 어썰트 : 타격당 10 스택<br>- 서번트 랜스 : 인게이저 : 타격당 4 스택<br><br>서번트 랜스 마법창 재생성 시간 30% 감소<br>서번트 랜스 재발사 쿨타임 30% 감소@@8300@@2016';

lv105ItemId['결전의 낫 - 어벤저'] = '90165e0906a6d8f78f87c371605031ed';
lv105OptionMap['결전의 낫 - 어벤저'] = {};
lv105OptionMap['결전의 낫 - 어벤저'] = '악마화 상태에서 악마 게이지 30 이상일 때 악마 게이지 소모 스킬 공격 시 40을 추가 소모하여 데빌 클로 발동 (각성기 제외)<br>- 데빌 스트라이커 공격력의 2,000%<br><br>악마화 평타 공격 및 스킬의 악마 게이지 회복량 10% 증가<br>고통의 희열 악마 게이지 회복량 10% 증가<br><br>악마화 공격 속도 증가량 20% 추가 증가, 이동 속도 증가량 10% 추가 증가@@7112@@2016';

lv105ItemId['네오 화이트 클래스 팬츠'] = '0ea532d9cf26195ad6990f5ba8c13632';
lv105OptionMap['네오 화이트 클래스 팬츠'] = {};
lv105OptionMap['네오 화이트 클래스 팬츠'] = '최종 데미지 8% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 <br>100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5604@@1612';

lv105ItemId['생명이 담긴 가죽 토시'] = '9b5bb81d4e3ce0af441fc1f3b30d55a1';
lv105OptionMap['생명이 담긴 가죽 토시'] = {};
lv105OptionMap['생명이 담긴 가죽 토시'] = '최종 데미지 7.1% 증가<br>감전 피해 전환 +50%<br>감전 피해 +20%<br><br>물리/마법 피해의 50%를 감전 피해로 전환<br><br>감전 내성 +1%@@3165@@1953';

lv105ItemId['얼어붙은 저항의 중검'] = '4650decdc9691504b9a5f652a2de709d';
lv105OptionMap['얼어붙은 저항의 중검'] = {};
lv105OptionMap['얼어붙은 저항의 중검'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 로드 - 마도학자'] = 'b3e2763a1991dc85e6d8a7c66f6bbadc';
lv105OptionMap['결전의 로드 - 마도학자'] = {};
lv105OptionMap['결전의 로드 - 마도학자'] = '롤리팝 크러쉬가 더욱 많은 사탕 인형 생성<br>- 최대 사탕 생성 개수 +5<br>- 피격개체당 사탕인형 생성 수 +2<br>- 생성된 사탕인형 1개당 롤리팝 크러쉬의 남은 쿨타임 3% 감소<br>- 사탕인형 공격력 20% 감소<br><br>폭발하지 않은 사탕 인형을 흡수하여 다음 롤리팝 크러쉬 때 생성 (최대 15개)<br>- 사탕당 흡수 1번 가능<br>- 추가 생성된 사탕 인형은 최대 사탕 생성 개수에 미포함@@8300@@2016';

lv105ItemId['완성형 동력 제어장치'] = '2849ef416e30404723f0f5689b9aaa2b';
lv105OptionMap['완성형 동력 제어장치'] = {};
lv105OptionMap['완성형 동력 제어장치'] = '최종 데미지 14% 증가<br><br>이동 속도 +8%<br>모든 속성 저항 +10<br>적중률 +10%@@3988@@1938';

lv105ItemId['결전의 도 - 카오스'] = '410a627dce856ccbb225e9428a602fca';
lv105OptionMap['결전의 도 - 카오스'] = {};
lv105OptionMap['결전의 도 - 카오스'] = '제노사이드 크러쉬 시전 시 데몬들을 폭발시키는 대신 아텐의 화신이 데몬들의 환영을 소환하고 폭발시키도록 변경<br>- 공격력 10% 증가<br>- 스킬 범위 20% 증가<br>- 시전 중 미드나잇 카니발 시전 가능<br>- 데몬이 없을 경우 즉시 모든 데몬 소환@@10076@@2016';

lv105ItemId['결전의 배틀액스 - 크루세이더(남)'] = '3ea64c37072a08922e343425c2929c9d';
lv105OptionMap['결전의 배틀액스 - 크루세이더(남)'] = {};
lv105OptionMap['결전의 배틀액스 - 크루세이더(남)'] = '영광의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>슬로우 힐 스킬 범위 20% 증가<br><br>정의의 심판 시전 시 빛무리를 소환하여 범위 내 적 10명을 추적 후 정의의 창을 발사하여 폭발<br>- 창 공격력 : 빛의 칼날 공격력의 1600%<br>- 폭발 공격력 : 마법진 최대 유지 시 피니쉬 공격력의 100%<br>- 다른 스킬 시전 중 시전 가능<br><br>미러클 스플리터 스킬 공격력 20% 증가<br>- 돌진 거리 70% 감소<br><br>디플렉트 월 HP 400% 증가<br>- 이동하지 않음<br><br>싱글 플레이 시 독립 공격력 20% 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['결전의 소검 - 버서커'] = '67c6a6945910f1475705eabfa68091f9';
lv105OptionMap['결전의 소검 - 버서커'] = {};
lv105OptionMap['결전의 소검 - 버서커'] = '피에 굶주려 갈증 시전 시 HP 10%를 소모하고 혈기 3개 획득 (최대 10스택)<br>- 갈증 충전 기능 삭제<br>- 다른 스킬 시전 중 갈증 시전 가능<br>- 던전 입장 시 혈기 1개 획득<br><br>블러드 소드, 격노 시전 시 혈기 1개를 소모하여 강화<br>- 공격력 30% 증가<br>- 스킬 범위 15% 증가<br><br>블러드 소드, 격노 시전 시 HP 5% 회복@@9484@@2016';

lv105ItemId['사멸하는 신뢰의 십자가'] = 'dc0f8b9734ab0c1236fbd7cff016412a';
lv105OptionMap['사멸하는 신뢰의 십자가'] = {};
lv105OptionMap['사멸하는 신뢰의 십자가'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['기사의 구원'] = '9cf0c255457ecfd203a79238574ee94b';
lv105OptionMap['기사의 구원'] = {};
lv105OptionMap['기사의 구원'] = '최종 데미지 15% 증가<br><br>점프 상태에서 [경매장 키] 입력 시 즉시 낙하<br><br>이동 속도 +20%<br>점프력 +100<br>적중률 +10%<br>회피율 +8%@@6414@@1910';

lv105ItemId['근원을 삼킨 단검'] = '1512194117e77293ff88fabaf6f06e6e';
lv105OptionMap['근원을 삼킨 단검'] = {};
lv105OptionMap['근원을 삼킨 단검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['사멸하는 신뢰의 코어 블레이드'] = '9dddae23098f16e24baabeac1718d3be';
lv105OptionMap['사멸하는 신뢰의 코어 블레이드'] = {};
lv105OptionMap['사멸하는 신뢰의 코어 블레이드'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['옭아매는 공포'] = '0d364fbe6c14575e23c927a85bffe11d';
lv105OptionMap['옭아매는 공포'] = {};
lv105OptionMap['옭아매는 공포'] = '1~30Lv 스킬 공격력 12% 증가<br><br>무색 큐브 조각을 소모하는 스킬 공격력 증가<br>- 1개 ~ 9개 : 2%<br>- 10개 ~ 15개 : 15%<br>- 16개 이상 : 20%<br><br>무색 큐브 조각을 소모하지 않는 스킬의 무색 큐브 조각 소모량 +2<br><br>물리/마법 크리티컬 히트 +5%<br>스킬 MP 소모량 -7%@@4528@@1986';

lv105ItemId['결전의 도 - 베가본드'] = '45c1554ea1e99c8ff2899be137511f61';
lv105OptionMap['결전의 도 - 베가본드'] = {};
lv105OptionMap['결전의 도 - 베가본드'] = '십자검 시전 시 전방의 가장 강력한 적을 추적한 후 수평, 수직 베기 동시 시전<br><br>전직 스킬의 후딜레이를 캔슬하여 십자검, 엽해소옥 시전 가능<br><br>아래의 스킬 쿨타임 20% 감소<br>- 일화도강<br>- 십자검<br>- 연화섬<br>- 엽해소옥@@8892@@2016';

lv105ItemId['옥화의 망령 흉갑'] = '30b66937d2112e5e344c70709dba078d';
lv105OptionMap['옥화의 망령 흉갑'] = {};
lv105OptionMap['옥화의 망령 흉갑'] = '최종 데미지 8% 증가<br><br>[천공의 지배]<br>점프 상태에서 [장비 옵션 조작키] 입력 시 10초 동안 천공을 지배하며 회오리 바람 소환 (쿨타임 20초)<br>- 주변의 적을 끌어모음<br>- 슈퍼 아머 부여<br>- 회오리 바람은 총 공격력 증가의 2,880%만큼 피해<br>- [장비 옵션 조작키] 재입력 시 지상으로 착지<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +10%<br>캐스팅 속도 +15%<br>이동 속도 +8%@@6144@@1958';

lv105ItemId['결전의 소검 - 검귀'] = 'c24505fa6eb1233a2a5f5a373fbfb827';
lv105OptionMap['결전의 소검 - 검귀'] = {};
lv105OptionMap['결전의 소검 - 검귀'] = '검술 , 합격 계열 스킬 공격 시 귀신보 쿨타임 초기화<br>- 검술 계열 스킬을 귀신보로 발동하는 경우 제외<br><br>귀신보 공격력 20% 증가<br><br>귀신보 Y축 공격 범위 10% 증가@@9484@@2016';

lv105ItemId['결전의 둔기 - 베가본드'] = '66dfb0fd641afb47e08d6988322672db';
lv105OptionMap['결전의 둔기 - 베가본드'] = {};
lv105OptionMap['결전의 둔기 - 베가본드'] = '십자검 시전 시 전방의 가장 강력한 적을 추적한 후 수평, 수직 베기 동시 시전<br><br>전직 스킬의 후딜레이를 캔슬하여 십자검, 엽해소옥 시전 가능<br><br>아래의 스킬 쿨타임 20% 감소<br>- 일화도강<br>- 십자검<br>- 연화섬<br>- 엽해소옥@@8892@@2016';

lv105ItemId['대지를 딛는 부츠'] = '269dff5b07191288ef2ed0bb94628937';
lv105OptionMap['대지를 딛는 부츠'] = {};
lv105OptionMap['대지를 딛는 부츠'] = '최종 데미지 17% 증가<br><br>10초마다 HP MAX 10% 수치의 보호막 부여<br><br>점프하여 착지 시 충격파 발생<br>- 충격파 발생 시 적에게 3초 동안 기절 상태 이상 부여 (쿨타임 20초)<br>- 총 공격력 증가의 2,200%만큼 피해<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%@@6952@@1920';

lv105ItemId['얼어붙은 저항의 낫'] = '0fdc480ad4114b29fb1c56d0e64d71f2';
lv105OptionMap['얼어붙은 저항의 낫'] = {};
lv105OptionMap['얼어붙은 저항의 낫'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 낫 - 이단심판관'] = 'a03894821d876669117ecf652226f554';
lv105OptionMap['결전의 낫 - 이단심판관'] = {};
lv105OptionMap['결전의 낫 - 이단심판관'] = '세례의 불길, 정화의 불꽃 스킬 쿨타임 20% 감소<br><br>정화의 불꽃 시전 시 500px 내 모든 적에게 폭발 발생<br><br>플레게의 정수를 최대 5회 충전 스킬로 변경<br>- 충전 쿨타임 : 8초<br>- 소각 발생 시 충전 시간 1초 감소@@5336@@2016';

lv105ItemId['폭발형 : 소형 전술 플레어'] = '20391270b9a8696cfa779fde0ab8f75a';
lv105OptionMap['폭발형 : 소형 전술 플레어'] = {};
lv105OptionMap['폭발형 : 소형 전술 플레어'] = '최종 데미지 10% 증가<br><br>공격 속도 +30%<br>캐스팅 속도 +30%<br>이동 속도 +53%<br>MP 1분당 348 회복@@6144@@1958';

lv105ItemId['결전의 건틀릿 - 그래플러(남)'] = '30ce27a418e877d62bc8157dd0b156e2';
lv105OptionMap['결전의 건틀릿 - 그래플러(남)'] = {};
lv105OptionMap['결전의 건틀릿 - 그래플러(남)'] = '롤링 스파이크를 메치기 시 지진이 발생하는 최대 2회 충전 스킬로 변경<br>- 지진 공격력 : 메치기 공격력의 50%<br>- 충전 쿨타임 : 12초<br>- 후딜레이를 캔슬하여 전직 스킬 시전 가능<br><br>아래의 스킬 잡기 실패 시 해당 스킬 쿨타임 5초로 감소 (쿨타임 10초)<br>- 브레이크 다운<br>- 스냅 샷<br>- 블로우 스트라이크(지상 사용)<br>- 롤링 스파이크<br>- 나의 공격리듬은!(지상 사용)<br>- 한놈만 팬다!!<br>- 흑진광풍@@5928@@2016';

lv105ItemId['성장하는 모험가의 보우건'] = 'cac209703fd7501382002dd058608ebc';
lv105OptionMap['성장하는 모험가의 보우건'] = {};
lv105OptionMap['성장하는 모험가의 보우건'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['천재 기술자의 멀티툴 벨트'] = '12fde00e523a6e1e3a389a27d08b1655';
lv105OptionMap['천재 기술자의 멀티툴 벨트'] = {};
lv105OptionMap['천재 기술자의 멀티툴 벨트'] = '공격력 증가 +666.9%<br>최종 데미지 5% 증가<br><br>스킬 MP 소모량 +100%<br><br>공격 시 MP 3,500 회복 (쿨타임 1초)<br><br>MP MAX +945@@4917@@1963';

lv105ItemId['결전의 스태프 - 스위프트 마스터'] = '2a2fd3b6363682c92598911b2953abc4';
lv105OptionMap['결전의 스태프 - 스위프트 마스터'] = {};
lv105OptionMap['결전의 스태프 - 스위프트 마스터'] = '소닉 무브를 최대 3회 충전 스킬로 변경<br><br>소닉 무브 시전 시 회오리 바람 생성<br>- 소닉 무브 회오리 바람 공격력 : 회오리 바람 공격력의 50%<br><br>회오리 바람 크기 10% 증가<br><br>전직 스킬을 캔슬하여 소닉 무브, 대진공, 칼날 바람 시전 시 풍흔 1스택 획득 (최대 15스택)<br>- 풍흔이 15스택일 때 스톰 스트라이크 시전 시 모든 풍흔을 소모하여 공격력 50% 증가@@7112@@2016';

lv105ItemId['브레이브 나이트 아머'] = '191ffb70b6cbc90b3ddf25c77aae0125';
lv105OptionMap['브레이브 나이트 아머'] = {};
lv105OptionMap['브레이브 나이트 아머'] = '최종 데미지 4% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br><br>공격 시 HP 2,200 회복 (쿨타임 1초)<br><br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +10%<br>스킬 MP 소모량 -7%@@5187@@1923';

lv105ItemId['잠식된 고원의 정수'] = '2d13a998687ba418f6e707564e592295';
lv105OptionMap['잠식된 고원의 정수'] = {};
lv105OptionMap['잠식된 고원의 정수'] = '최종 데미지 8% 증가<br>모든 속성 강화 +18<br><br>공격 속도 +5%<br>캐스팅 속도 +5%<br>이동 속도 +5%<br>MP MAX +945<br>모든 속성 저항 +10<br>회피율 +8%@@5066@@1928';

lv105ItemId['얼어붙은 저항의 창'] = '4d417208dfe59d33c94229fc833aea64';
lv105OptionMap['얼어붙은 저항의 창'] = {};
lv105OptionMap['얼어붙은 저항의 창'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['성장하는 모험가의 장궁'] = '87e98be0ba8ca3fc3acc4f3005e121e9';
lv105OptionMap['성장하는 모험가의 장궁'] = {};
lv105OptionMap['성장하는 모험가의 장궁'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['결전의 로드 - 디멘션 워커'] = 'c400a12f63e9fe5284dba2c1f02bcd96';
lv105OptionMap['결전의 로드 - 디멘션 워커'] = {};
lv105OptionMap['결전의 로드 - 디멘션 워커'] = '차원 : 시공폭뢰 스킬 강화<br>- 공격력 15% 증가<br>- 폭발 속도 50% 증가<br>- 스킬 범위 25% 증가<br>- 사상경계의 저편 효과 적용 시 전방에 다수의 차원 폭발 생성<br><br>니알리가 스킬 시전 시 분신이 대신 시전 (각성기 제외)<br><br>아래 스킬 쿨타임 10% 감소<br>- 괴리 : 할리퀸<br>- 괴리 : 패러사이트 스웜<br>- 괴리 : 칙 잇<br>- 괴리 : 저편의 자장가@@4744@@2016';

lv105ItemId['얼티밋 제너레이터'] = '11b464f53794d4e94d1b674cc22cd28e';
lv105OptionMap['얼티밋 제너레이터'] = {};
lv105OptionMap['얼티밋 제너레이터'] = '최종 데미지 8% 증가<br><br>[거대화]<br>[장비 옵션 조작키] 입력 시 25초 동안 거대화 (쿨타임 60초)<br>- 공격 불가 상태가 되며 대시 / 점프 시 충격파 발생<br>- 물리/마법 피해 감소 +20%<br>- [장비 옵션 조작키] 재입력 시 거대화 해제<br>- 대시 및 점프 충격파는 각각 총 공격력 증가의 288%, 1,440%만큼 피해<br><br>공격 시 HP 2,200 / MP 3,500회복 (쿨타임 1초)<br><br>물리/마법 방어력 +10,000@@4768@@1910';

lv105ItemId['성장하는 모험가의 염주'] = 'd6abc476a79da1823a1d1c38af9aa5bd';
lv105OptionMap['성장하는 모험가의 염주'] = {};
lv105OptionMap['성장하는 모험가의 염주'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['반짝이는 음율'] = 'b6df9f390bc57f5bcb2fd49a6065c536';
lv105OptionMap['반짝이는 음율'] = {};
lv105OptionMap['반짝이는 음율'] = '최종 데미지 6% 증가<br>모든 속성 강화 +20<br><br>장비로 인한 MP 회복 효과 30% 증가<br><br>적중률 +10%@@6048@@1943';

lv105ItemId['성장하는 모험가의 소태도'] = 'f64d23e36bfa2e296eb042fe0c24419a';
lv105OptionMap['성장하는 모험가의 소태도'] = {};
lv105OptionMap['성장하는 모험가의 소태도'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['어비스 리액터'] = '141c2b6d589c4de31bcffcdc34c0eab1';
lv105OptionMap['어비스 리액터'] = {};
lv105OptionMap['어비스 리액터'] = '최종 데미지 9% 증가<br>火(화), 水(수), 明(명), 暗(암) 공격 속성<br><br>물리/마법 크리티컬 히트 +10%<br>적중률 +10%<br>MP 1분당 348 회복@@6952@@1948';

lv105ItemId['뚜렷해지는 소멸된 사념'] = '509508d2bdb68a333a7e095ab6f86011';
lv105OptionMap['뚜렷해지는 소멸된 사념'] = {};
lv105OptionMap['뚜렷해지는 소멸된 사념'] = '최종 데미지 5% 증가<br><br>1초마다 HP/MP 2% 회복<br><br>HP가 20% 이하일 때 피격 시 모든 MP를 소모하여 HP로 회복 (쿨타임 60초)<br><br>물리/마법 방어력 +7,000<br>MP MAX +945@@6414@@1958';

lv105ItemId['결전의 단검 - 섀도우댄서'] = '9868d3015d89cd7ce587efdb5a1da58e';
lv105OptionMap['결전의 단검 - 섀도우댄서'] = {};
lv105OptionMap['결전의 단검 - 섀도우댄서'] = '백어택 공격 시 그림자의 흔적 획득<br>- 2스택 획득 (최대 50스택)<br>- 정면 공격 시 3스택 감소<br><br>페이탈 블리츠 배후 적출 시 그림자의 흔적을 모두 소모하여 공격력 강화<br>- 흔적 1개당 공격력 1% 증가 (최대 50%) <br><br>페이탈 블리츠 시전 시 15초 동안 은신하여 스킬 쿨타임 회복 속도 +12% (각성기 제외)@@4744@@2016';

lv105ItemId['결전의 대검 - 소드마스터'] = '39db2360df46dc646076d040b8d58525';
lv105OptionMap['결전의 대검 - 소드마스터'] = {};
lv105OptionMap['결전의 대검 - 소드마스터'] = '궁극의 마검 레바테인으로 악즉참 시전<br>- 악즉참 항상 최대 충전<br>- 악즉참 공격력 15% 감소<br><br>레바테인에 의한 악즉참은 적에게 4초 동안 상흔을 부여<br>- 상흔이 생성된 적에게 반월 또는 섬광 적중 시 악즉참 남은 쿨타임 10% 감소@@8892@@2016';

lv105ItemId['근원을 삼킨 핸드캐넌'] = '0615c137045d4eafc47fcb65decfa2ea';
lv105OptionMap['근원을 삼킨 핸드캐넌'] = {};
lv105OptionMap['근원을 삼킨 핸드캐넌'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['폭주하는 육신'] = '35f097a5dc75fe7700fcf9533fc1c77a';
lv105OptionMap['폭주하는 육신'] = {};
lv105OptionMap['폭주하는 육신'] = '최종 데미지 17% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>물리/마법 방어력 +23,000@@6414@@1968';

lv105ItemId['결전의 클로 - 넨마스터(남)'] = 'a9577313f6f5e61ffabb75b0220832b1';
lv105OptionMap['결전의 클로 - 넨마스터(남)'] = {};
lv105OptionMap['결전의 클로 - 넨마스터(남)'] = '기호지세의 넨수가 포효 후 폭발하도록 변경<br>- 포효 공격 다단히트 수 : 4<br>- 포효 공격력 : 기호지세 다단히트 공격력의 100%<br>- 폭발 공격력 : 기호지세 폭발 공격력의 100%<br>- 포효에 적중된 적을 끌어당김<br>- 스킬 범위 20% 증가<br><br>광호천지파의 나선의 넨이 스스로 회전 후 폭발<br>- 시전 속도 20% 증가<br>- 구체 및 폭발 범위 10% 증가@@10076@@2016';

lv105ItemId['얼어붙은 저항의 건틀릿'] = 'ffdbb906addec7e0051fae3867be2201';
lv105OptionMap['얼어붙은 저항의 건틀릿'] = {};
lv105OptionMap['얼어붙은 저항의 건틀릿'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['피어오르는 광기'] = '50e34031bfb458d7a43ed56d2a36db1d';
lv105OptionMap['피어오르는 광기'] = {};
lv105OptionMap['피어오르는 광기'] = '스킬 쿨타임 회복 속도 +50% (각성기 제외)<br><br>물리/마법 크리티컬 히트 +7%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 상태 이상 내성 -10%@@3894@@1887';

lv105ItemId['결전의 너클 - 넨마스터(여)'] = '115606560c7ff48b2704f09809306e91';
lv105OptionMap['결전의 너클 - 넨마스터(여)'] = {};
lv105OptionMap['결전의 너클 - 넨마스터(여)'] = '축염포 충전 시간 및 쿨타임 삭제<br><br>넨탄의 쿨타임이 증가하는 대신 공격력과 크기가 증가<br>- 공격력 120% 증가<br>- 크기 20% 증가<br>- 쿨타임 4초 증가<br>- 넨마스터 전직 쿨타임 감소 미적용<br><br>넨탄 시전 시 아래의 효과 적용<br>- 흡기공탄의 남은 쿨타임 5% 감소<br>- 10% 확률로 넨탄 공격력 500% 증가@@8300@@2016';

lv105ItemId['어둠에 삼켜진 돌굽'] = '18387f5bef5b22094ebd9f392a507af0';
lv105OptionMap['어둠에 삼켜진 돌굽'] = {};
lv105OptionMap['어둠에 삼켜진 돌굽'] = '최종 데미지 5% 증가<br>스킬 쿨타임 회복 속도 +15% (각성기 제외)<br>중독 피해 +10%<br><br>물리/마법 크리티컬 히트 +12%<br>공격 속도 +15%<br>캐스팅 속도 +15%<br>이동 속도 +15%<br>모든 상태 이상 내성 -10%@@5510@@1953';

lv105ItemId['결전의 봉 - 스위프트 마스터'] = '89a2eb7d8fdaa202745b99df2f939a48';
lv105OptionMap['결전의 봉 - 스위프트 마스터'] = {};
lv105OptionMap['결전의 봉 - 스위프트 마스터'] = '소닉 무브를 최대 3회 충전 스킬로 변경<br><br>소닉 무브 시전 시 회오리 바람 생성<br>- 소닉 무브 회오리 바람 공격력 : 회오리 바람 공격력의 50%<br><br>회오리 바람 크기 10% 증가<br><br>전직 스킬을 캔슬하여 소닉 무브, 대진공, 칼날 바람 시전 시 풍흔 1스택 획득 (최대 15스택)<br>- 풍흔이 15스택일 때 스톰 스트라이크 시전 시 모든 풍흔을 소모하여 공격력 50% 증가@@7112@@2016';

lv105ItemId['골렘의 핵 팔찌'] = '2ddf1185d8199e0bf081e9d7421aea50';
lv105OptionMap['골렘의 핵 팔찌'] = {};
lv105OptionMap['골렘의 핵 팔찌'] = '최종 데미지 7.1% 증가<br>중독 피해 전환 +50%<br>중독 피해 +20%<br><br>물리/마법 피해의 50%를 중독 피해로 전환<br><br>중독 내성 +1%@@3705@@1953';

lv105ItemId['하이테크 고기동 강화 부츠'] = '08d689cebec0eee38147356abced9204';
lv105OptionMap['하이테크 고기동 강화 부츠'] = {};
lv105OptionMap['하이테크 고기동 강화 부츠'] = '최종 데미지 11% 증가<br>모든 속성 강화 +20<br><br>무색 큐브 조각을 소모하는 스킬의 커맨드 사용 효과 +400% (각성기 제외)<br><br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +10%<br>물리/마법 피해 감소 +12%<br>스킬 MP 모소량 -7%@@5780@@1935';

lv105ItemId['검은 별'] = 'bbd070d70a5168fabecaef168427487d';
lv105OptionMap['검은 별'] = {};
lv105OptionMap['검은 별'] = '모든 속성 강화 +20<br><br>HP가 50% 이하일 때 최종 데미지 11% 증가<br><br>공격 속도 +20%<br>캐스팅 속도 +20%<br>이동 속도 +20%<br>적중률 +10%@@4972@@1953';

lv105ItemId['근원을 삼킨 너클'] = '324df7e3ad8c062fd488c3da3f71fff3';
lv105OptionMap['근원을 삼킨 너클'] = {};
lv105OptionMap['근원을 삼킨 너클'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['근원을 삼킨 코어 블레이드'] = '4908e24a2decd256dc00d945b2d5aa15';
lv105OptionMap['근원을 삼킨 코어 블레이드'] = {};
lv105OptionMap['근원을 삼킨 코어 블레이드'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['성장하는 모험가의 배틀액스'] = 'd06c3519a697aef91ff36b9880da4828';
lv105OptionMap['성장하는 모험가의 배틀액스'] = {};
lv105OptionMap['성장하는 모험가의 배틀액스'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['결전의 도 - 검귀'] = '5262287485a370d1d41ae4760c36cc92';
lv105OptionMap['결전의 도 - 검귀'] = {};
lv105OptionMap['결전의 도 - 검귀'] = '검술 , 합격 계열 스킬 공격 시 귀신보 쿨타임 초기화<br>- 검술 계열 스킬을 귀신보로 발동하는 경우 제외<br><br>귀신보 공격력 20% 증가<br><br>귀신보 Y축 공격 범위 10% 증가@@9484@@2016';

lv105ItemId['냉혹한 현실의 목걸이'] = '689669772092589ba47a7d6adbb10023';
lv105OptionMap['냉혹한 현실의 목걸이'] = {};
lv105OptionMap['냉혹한 현실의 목걸이'] = '최종 데미지 10% 증가<br><br>공격 시 화속성 폭발 발생 (쿨타임 5초)<br>- 총 공격력 증가 수치의 480%만큼 피해<br>- 자신에게는 1의 피해<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@6414@@1958';

lv105ItemId['순수한 자연의 이치'] = '02465c3bbbf1757e04d79122a4703f2b';
lv105OptionMap['순수한 자연의 이치'] = {};
lv105OptionMap['순수한 자연의 이치'] = '명속성 강화 +20<br><br>감전 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>[썬더 포스]<br>공격 시 명속성 강화 수치에 따라 명속성 원소 공격 시전 (쿨타임 1.5초)<br>- 100 이상 150 미만 : 라이트 익스플로전<br>- 150 이상 250 미만 : 라이트닝<br>- 250 이상 : 썬더 스톰<br>- 각각 총 공격력 증가의 720% / 920% / 1,480%만큼 피해<br>- 썬더 스톰은 적에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)<br><br>공격 속도 +10%<br>캐스팅 속도 +15%<br>명속성 저항 +20@@6414@@1978';

lv105ItemId['버츄얼 사이트 글래스'] = '0d416780271ff2281e5e8d023c8deafe';
lv105OptionMap['버츄얼 사이트 글래스'] = {};
lv105OptionMap['버츄얼 사이트 글래스'] = '최종 데미지 9.2% 증가<br><br>[화염 걸음]<br>발걸음에 불꽃의 기운을 담아 이동 시 3초 동안 화염 지대 생성<br>- 화염 지대는 적에게 15초 동안 화상 상태 이상 부여 (쿨타임 10초)<br>- 총 공격력 증가의 4%만큼 피해<br><br>화상 상태 적 공격 시 화염 폭발 발생 (쿨타임 0.5초)<br>- 총 공격력 증가의 28%만큼 피해<br><br>HP MAX +600<br>화상 내성 +1%@@5673@@1958';

lv105ItemId['포기하지 않는 용기'] = '7f3e3187979bdc24c6cbb65c5ec33c78';
lv105OptionMap['포기하지 않는 용기'] = {};
lv105OptionMap['포기하지 않는 용기'] = '최종 데미지 13% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>이동 속도 +5%<br>물리/마법 피해 감소 +10%<br>적중률 +10%@@5874@@1948';

lv105ItemId['광폭화된 전의의 권투글러브'] = '0639461c09c1925e37ce4a53a618a153';
lv105OptionMap['광폭화된 전의의 권투글러브'] = {};
lv105OptionMap['광폭화된 전의의 권투글러브'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['얼어붙은 저항의 코어 블레이드'] = 'bc467f82e958e85dc0c986e9c74a3763';
lv105OptionMap['얼어붙은 저항의 코어 블레이드'] = {};
lv105OptionMap['얼어붙은 저항의 코어 블레이드'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['굴착 강화 파츠'] = '5def29f2984302609613b48985a677ca';
lv105OptionMap['굴착 강화 파츠'] = {};
lv105OptionMap['굴착 강화 파츠'] = '스킬 쿨타임 10% 감소 (각성기 제외)<br>모든 속성 강화 +10<br><br>[소울 웨펀]<br>평타, 점프 공격, 대시 공격 시 검격을 가하는 소울 웨펀 소환<br>- 스킬 시전 시 검격 가능 횟수 +5<br>- 검격 가능 횟수가 50일 때 모든 횟수를 소모하여 강력한 검격<br>- 검격과 강력한 검격은 각각 총 공격력 증가 수치의 60%, 2,400%만큼 피해<br><br>공격 시 적에게 10초 동안 감전 상태 이상 부여 (쿨타임 3초)<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +25%<br>캐스팅 속도 +40%@@7100@@1953';

lv105ItemId['근원을 삼킨 로드'] = '029448375a5e13be023b99a532f2494a';
lv105OptionMap['근원을 삼킨 로드'] = {};
lv105OptionMap['근원을 삼킨 로드'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['결전의 보우건 - 스핏파이어(여)'] = '1ab52689243e42f22c4525db17988adb';
lv105OptionMap['결전의 보우건 - 스핏파이어(여)'] = {};
lv105OptionMap['결전의 보우건 - 스핏파이어(여)'] = 'G-14 파열류탄, G-35L 섬광류탄, G-18C 빙결류탄 스킬 강화<br>- 최대 장전 수 +2<br>- 점프 상태에서 시전 시 니트로 모터를 소모하지 않음<br><br><br>보유한 유탄을 소모하여 포토빌라이저 강화<br>- 소모한 유탄 1개당 공격력 2% 증가 (최대 30%)<br>- 스탠바이-레디 상태에서는 최대 강화 수치로 발사@@8300@@2016';

lv105ItemId['얼어붙은 저항의 쌍검'] = '4c3a67bf4ec92686fcdf1480943d01c7';
lv105OptionMap['얼어붙은 저항의 쌍검'] = {};
lv105OptionMap['얼어붙은 저항의 쌍검'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 광검 - 베가본드'] = 'ca69d4bfe6e7da0127dad64083b02263';
lv105OptionMap['결전의 광검 - 베가본드'] = {};
lv105OptionMap['결전의 광검 - 베가본드'] = '십자검 시전 시 전방의 가장 강력한 적을 추적한 후 수평, 수직 베기 동시 시전<br><br>전직 스킬의 후딜레이를 캔슬하여 십자검, 엽해소옥 시전 가능<br><br>아래의 스킬 쿨타임 20% 감소<br>- 일화도강<br>- 십자검<br>- 연화섬<br>- 엽해소옥@@8892@@2016';

lv105ItemId['사멸하는 신뢰의 자동권총'] = '0b22fd4183997ad8a45d468ffe9260a4';
lv105OptionMap['사멸하는 신뢰의 자동권총'] = {};
lv105OptionMap['사멸하는 신뢰의 자동권총'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['그릇된 힘에 잠식된 팔찌'] = '22a3394bf0b96eb24b0df260e2504216';
lv105OptionMap['그릇된 힘에 잠식된 팔찌'] = {};
lv105OptionMap['그릇된 힘에 잠식된 팔찌'] = '최종 데미지 16% 증가<br><br>보스/네임드 몬스터 공격 시 무력화 게이지량 -1% (버퍼 제외)<br>- 적의 무력화 게이지량이 90% 이상일 때만 발동<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>물리/마법 크리티컬 히트 +5%@@5604@@1910';

lv105ItemId['내딛은 자의 투창'] = 'a6ce3570d897bc4ba6b2ff7d9cbb05cc';
lv105OptionMap['내딛은 자의 투창'] = {};
lv105OptionMap['내딛은 자의 투창'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['드래곤 슬레이어'] = '02ec8b60e2cfa5d4c84d831329b70c4f';
lv105OptionMap['드래곤 슬레이어'] = {};
lv105OptionMap['드래곤 슬레이어'] = '최종 데미지 9% 증가<br>火(화), 水(수), 明(명), 暗(암) 공격 속성<br><br>모든 속성 저항 +25<br>적중률 +10%<br>MP 1분당 348 회복@@5874@@1948';

lv105ItemId['결전의 쌍검 - 쿠노이치'] = '239efe44b7f1ff35e697807dc1752b5f';
lv105OptionMap['결전의 쌍검 - 쿠노이치'] = {};
lv105OptionMap['결전의 쌍검 - 쿠노이치'] = '인법 : 잔영 남기기의 잔영 소모 1개당 다음에 재생할 육도윤회의 잔영 공격력 비율 1.5% 추가 증가 (최대 12중첩)<br><br>인법 : 육도윤회 시전 시 5초 동안 슈퍼아머 상태가 되며 스킬 쿨타임 회복 속도 +50% (쿨타임 20초 / 각성기 제외)<br><br>인법 : 육도윤회 스킬 종료 시 잔영 5 회복 (쿨타임 10초)@@8300@@2016';

lv105ItemId['결전의 건틀릿 - 넨마스터(여)'] = '2ee9940f4371cd412d6c9afbcaa2b652';
lv105OptionMap['결전의 건틀릿 - 넨마스터(여)'] = {};
lv105OptionMap['결전의 건틀릿 - 넨마스터(여)'] = '축염포 충전 시간 및 쿨타임 삭제<br><br>넨탄의 쿨타임이 증가하는 대신 공격력과 크기가 증가<br>- 공격력 120% 증가<br>- 크기 20% 증가<br>- 쿨타임 4초 증가<br>- 넨마스터 전직 쿨타임 감소 미적용<br><br>넨탄 시전 시 아래의 효과 적용<br>- 흡기공탄의 남은 쿨타임 5% 감소<br>- 10% 확률로 넨탄 공격력 500% 증가@@8300@@2016';

lv105ItemId['서드 스페이드 - 데스'] = 'ce8195cda83c59c9cdeef2a427d8cf80';
lv105OptionMap['서드 스페이드 - 데스'] = {};
lv105OptionMap['서드 스페이드 - 데스'] = '최종 데미지 2% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@4528@@1612';

lv105ItemId['성장하는 모험가의 머스켓'] = '3fb2bf4a6434d0c4c4d4f7993a2433de';
lv105OptionMap['성장하는 모험가의 머스켓'] = {};
lv105OptionMap['성장하는 모험가의 머스켓'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['아크로매틱 룸버스'] = '6a46f336b09d7feee2d8fe38e0eff1fb';
lv105OptionMap['아크로매틱 룸버스'] = {};
lv105OptionMap['아크로매틱 룸버스'] = '최종 데미지 10% 증가<br>무색 큐브 조각을 소모하지 않는 스킬 쿨타임 30% 감소<br>무색 큐브 조각을 소모하는 스킬 쿨타임 15% 증가 (각성기 제외)<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%@@4528@@1920';

lv105ItemId['일렉트릭 프루프 부츠'] = '0894bcf22c00b54e90b9f9f0676ee7d5';
lv105OptionMap['일렉트릭 프루프 부츠'] = {};
lv105OptionMap['일렉트릭 프루프 부츠'] = '최종 데미지 17% 증가<br><br>감전 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 번개가 떨어지며 적에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)<br>- 총 공격력 증가의 84%만큼 피해<br><br>자신에게 해제되지 않는 감전 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +7%<br>공격 속도 +20%<br>캐스팅 속도 +30%<br>이동 속도 +20%<br>모든 상태 이상 내성 -10%@@6858@@1963';

lv105ItemId['내딛은 자의 빗자루'] = 'dfab100a5c6b2f4acdb734ead0215aa9';
lv105OptionMap['내딛은 자의 빗자루'] = {};
lv105OptionMap['내딛은 자의 빗자루'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 클로 - 그래플러(여)'] = '9e8e8b65ab8e1e08b5032b48fb76d703';
lv105OptionMap['결전의 클로 - 그래플러(여)'] = {};
lv105OptionMap['결전의 클로 - 그래플러(여)'] = '전직 스킬로 공격 시 테크니컬 포인트 획득 (최대 20점)<br>- 1~30Lv : 1점<br>- 35~45Lv : 2점<br>- 60~80Lv : 2점<br>- 50, 85, 95Lv : 5점<br>- 100Lv : 10점<br>- 체인 커넥션 연계/공중 스킬 사용 시 1점 추가<br><br>테크니컬 포인트에 따라 효과 발동<br>- 11점 이상 : 50, 85, 100Lv 스킬 적중 시 모든 스킬 남은 쿨타임 30% 감소 (각성기 제외 / 모든 점수 소모)<br>- 6점 이상 : 잡기 스킬 공격 실패 시 해당 스킬 쿨타임을 3초로 감소 (3점 소모)<br>- 1점 이상 : 체인 커넥션 스킬 연계 시 체인 커넥션 회복 시간 3초 감소@@5336@@2016';

lv105ItemId['시공을 담은 팔찌'] = '665737a792279162791824571c97e921';
lv105OptionMap['시공을 담은 팔찌'] = {};
lv105OptionMap['시공을 담은 팔찌'] = '최종 데미지 18% 증가<br><br>HP MAX 15% 수치의 [충전형 보호막] 부여<br><br>이동 속도 +8%<br>회피율 +8%@@5604@@1928';

lv105ItemId['결전의 봉 - 블러드 메이지'] = 'd271891aca11ea3ab70aac9016fbd4f4';
lv105OptionMap['결전의 봉 - 블러드 메이지'] = {};
lv105OptionMap['결전의 봉 - 블러드 메이지'] = '커럽션 공격 시 블러드비스 2개 획득 (최대 5개)<br>- 쉬카리, 토먼트 시전 시 블러드비스 1개를 소모하여 각각 페이탈 쉬카리, 블러드먼트로 강화<br>- 던전 입장 시 블러드비스 1개 획득<br><br>페이탈 쉬카리<br>- 쉬카리 공격력 50% 증가<br>- 1히트 스킬로 변경<br>- 500px 범위 내 모든 적 공격<br><br>블러드먼트<br>- 토먼트 공격력 50% 증가<br>- 시전 후 이동 가능<br><br>커럽션 쿨타임 10% 감소, 시전 속도 30% 증가@@8892@@2016';

lv105ItemId['결전의 소태도 - 트러블 슈터'] = '5276d097bd8252dbdd443f0e046fc22b';
lv105OptionMap['결전의 소태도 - 트러블 슈터'] = {};
lv105OptionMap['결전의 소태도 - 트러블 슈터'] = '번 투 슬래쉬 시전 시 3초 동안 전직 스킬 공격력 20% 증가 (각성기 제외)<br>- 후딜레이를 캔슬하여 다른 전직 스킬 사용 가능<br>- 다른 전직 스킬의 후딜레이를 캔슬하여 번 투 슬래쉬 사용 가능<br>- 추가 공격 입력 시간 3초로 증가<br>- 스킬 종료 후 쿨타임 적용<br><br>번 투 슬래쉬가 타격하지 않아도 폭발 발생<br>- 스킬 쿨타임 35% 감소<br>- 스킬 범위 10% 증가@@6520@@2016';

lv105ItemId['결전의 둔기 - 팔라딘'] = 'a2707825051426a7b2adc1f2f4b89bfa';
lv105OptionMap['결전의 둔기 - 팔라딘'] = {};
lv105OptionMap['결전의 둔기 - 팔라딘'] = '세라픽 페더를 소모하여 이스트레인지먼트 시전 시 추가 효과 발동<br>- 세라픽 페더 1장당 공격력 4% 증가<br>- 세라픽 페더 1장당 스킬 범위 3% 증가<br>- 적에게 기절 상태 이상 부여<br><br>세라픽 페더를 소모하여 위핑 웨이브 시전 시 추가 효과 발동<br>- 끌어들인 적을 2초 동안 홀딩<br>- 몰이 효과 범위 6단계 적용<br>- 몰이 효과 범위 20% 증가<br>- 세라픽 페더 최대 2개만 소모<br><br>위핑 웨이브 강화<br>- 스킬 범위 20% 증가<br>- 타격 성공 시 세라픽 페더 습득량 +2<br>- 도발시킨 적 앞에서 방어 상태 유지 시 세라픽 페더 획득 시간 50% 감소@@9484@@2016';

lv105ItemId['사멸하는 신뢰의 미늘창'] = '9bbe22c6f825015c5ef431d644562475';
lv105OptionMap['사멸하는 신뢰의 미늘창'] = {};
lv105OptionMap['사멸하는 신뢰의 미늘창'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 소검 - 블레이드'] = 'eb617a561085dcf5c393448c882711b3';
lv105OptionMap['결전의 소검 - 블레이드'] = {};
lv105OptionMap['결전의 소검 - 블레이드'] = '납도술 연계 시 납도술 스킬과 연계한 스킬의 남은 쿨타임 15% 감소<br>- 각성기로 납도술 연계 시 미적용<br><br>러스티 네일 베기 공격력이 삭제되고, 후딜레이를 캔슬하여 전직 스킬 시전 가능<br>- 베기 공격력을 발차기 공격력에 합산<br><br>전직 스킬 후딜레이를 캔슬하여 러스티 네일 시전 가능<br>- 셰이커 블래스트, 각성기 제외@@8892@@2016';

lv105ItemId['얼어붙은 저항의 핸드캐넌'] = 'a50e2c96065b63d3677a379e70c7bd3a';
lv105OptionMap['얼어붙은 저항의 핸드캐넌'] = {};
lv105OptionMap['얼어붙은 저항의 핸드캐넌'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['안개를 탐구하는 자의 벨트'] = '0cce78269c2c6f6eb97533321d9fba72';
lv105OptionMap['안개를 탐구하는 자의 벨트'] = {};
lv105OptionMap['안개를 탐구하는 자의 벨트'] = '최종 데미지 13% 증가<br><br>이동 속도 +8%<br>회피율 +8%@@6144@@1928';

lv105ItemId['결전의 십자가 - 인파이터'] = 'b7e64d8ceb31f4243679d358c5786ba6';
lv105OptionMap['결전의 십자가 - 인파이터'] = {};
lv105OptionMap['결전의 십자가 - 인파이터'] = '정의 집행 : 레미디오스의 성좌의 링크 스킬이 빅뱅 펀치일 때, 신의 분노를 최대 3회 충전 스킬로 변경<br>- 연타 공격력 100% 증가<br>- 연타 횟수 9회 감소<br>- 피니쉬 타격 및 폭발 삭제<br>- 적을 추적하여 공격<br>- 충전 쿨타임 : 40초<br>- 추적 범위 : 780px<br>- MP 소모량 -30%<br>- 무색 큐브 조각 소모량 5개 감소<br>- 머신건 잽 시전 시 신의 분노 남은 쿨타임 12% 감소<br>- 신의 분노를 캔슬하여 전직 스킬 시전 가능<br>- 전직 스킬을 캔슬하여 신의 분노 시전 시 드라이아웃 스택을 소모하지 않음@@5928@@2016';

lv105ItemId['압도하는 용맹'] = '556975e8852f6f596c81b1b4d337b33d';
lv105OptionMap['압도하는 용맹'] = {};
lv105OptionMap['압도하는 용맹'] = '최종 데미지 10% 증가<br><br>[심판의 시간]<br>"커맨드 ↑ ↓ + [장비 옵션 조작키] 입력 시 보스 몬스터를 10초 동안 심판하며 데미지 축적 (쿨타임 20초)<br>- "심판" 종료 시 축적된 데미지 폭발<br>- 커맨드 재입력 시 "심판" 즉시 종료<br>- 심판의 시간 동안 모든 속도 +75%<br><br>이동 속도 +8%<br>적중률 +10%@@5066@@1938';

lv105ItemId['이동하는 요새'] = '7b119545dc3d59270672162bea7fec98';
lv105OptionMap['이동하는 요새'] = {};
lv105OptionMap['이동하는 요새'] = '최종 데미지 5% 증가<br>스킬 쿨타임 회복 속도 +10% (각성기 제외)<br><br>자신에게 해제되지 않는 출혈 상태 이상 부여<br><br>물리/마법 피해 감소 +10%<br>물리/마법 방어력 +7,000<br>HP 1분당 460.2 회복@@5604@@1948';

lv105ItemId['거짓 속의 거짓'] = '8a568268075336a993c97e2d780144dc';
lv105OptionMap['거짓 속의 거짓'] = {};
lv105OptionMap['거짓 속의 거짓'] = '최종 데미지 8% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@3180@@1612';

lv105ItemId['얼어붙은 저항의 도'] = '1749e1e16483211fedba20d38463c8a6';
lv105OptionMap['얼어붙은 저항의 도'] = {};
lv105OptionMap['얼어붙은 저항의 도'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['죄어오는 풍화된 악의'] = '2df3b394f3d4b03153524b2d22502c78';
lv105OptionMap['죄어오는 풍화된 악의'] = {};
lv105OptionMap['죄어오는 풍화된 악의'] = '최종 데미지 10% 증가<br><br>공격 시 암속성 폭발 발생 (쿨타임 5초)<br>- 총 공격력 증가 수치의 480%만큼 피해<br>- 자신에게는 1의 피해<br><br>물리/마법 방어력 +7,000<br>암속성 저항 +10@@6414@@1958';

lv105ItemId['결전의 리볼버 - 런처(남)'] = '63e64ecdb9f4947ef2eb6b441b8dee89';
lv105OptionMap['결전의 리볼버 - 런처(남)'] = {};
lv105OptionMap['결전의 리볼버 - 런처(남)'] = '레이저 라이플을 최대 3회 충전 스킬로 변경<br>- 공격력 20% 증가<br>- 크기 40% 증가<br>- 충전 쿨타임 : 7초<br>- 충전 레이저 라이플 충전 시간 50% 감소<br>- 적중 시 적에게 10초 동안 전자기장 부여<br><br>FM-92 스팅어 강화<br>- 공격력 15% 증가<br>- 폭발 범위 25% 증가<br>- 전자기장이 부여된 적 존재 시 대상을 추적하는 강화 고폭탄 발사@@8892@@2016';

lv105ItemId['결전의 머스켓 - 스핏파이어(여)'] = '747e728e0d47c02d0516f4effd110815';
lv105OptionMap['결전의 머스켓 - 스핏파이어(여)'] = {};
lv105OptionMap['결전의 머스켓 - 스핏파이어(여)'] = 'G-14 파열류탄, G-35L 섬광류탄, G-18C 빙결류탄 스킬 강화<br>- 최대 장전 수 +2<br>- 점프 상태에서 시전 시 니트로 모터를 소모하지 않음<br><br><br>보유한 유탄을 소모하여 포토빌라이저 강화<br>- 소모한 유탄 1개당 공격력 2% 증가 (최대 30%)<br>- 스탠바이-레디 상태에서는 최대 강화 수치로 발사@@8300@@2016';

lv105ItemId['사멸하는 신뢰의 염주'] = '2ed512870203da041e070f8b3d2c85da';
lv105OptionMap['사멸하는 신뢰의 염주'] = {};
lv105OptionMap['사멸하는 신뢰의 염주'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['내딛은 자의 통파'] = '2241528d6e25034bccfc29969dc2f28b';
lv105OptionMap['내딛은 자의 통파'] = {};
lv105OptionMap['내딛은 자의 통파'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['광폭화된 전의의 선현궁'] = '85476270194a715e0ffd4021b7cd66fc';
lv105OptionMap['광폭화된 전의의 선현궁'] = {};
lv105OptionMap['광폭화된 전의의 선현궁'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['마음을 전달하는 소리'] = '3cdc983849f497683a8453a28ece9d8f';
lv105OptionMap['마음을 전달하는 소리'] = {};
lv105OptionMap['마음을 전달하는 소리'] = '최종 데미지 12% 증가<br><br>[얼음땡!]<br>"얼음" /  "땡" 채팅 입력을 통해 캐릭터 빙결 및 빙결 해제 가능<br>- 빙결 상태 동안 물리/마법 피해 감소 +30%<br>- 빙결 상태가 될 때 HP 20% 회복<br><br>HP MAX +600<br>물리/마법 방어력 +7,000@@7760@@1910';

lv105ItemId['결전의 대검 - 다크나이트'] = '06ffc203717f4083cdd7bd51b254010d';
lv105OptionMap['결전의 대검 - 다크나이트'] = {};
lv105OptionMap['결전의 대검 - 다크나이트'] = '타임 스톱 최대 콤보 스택 1단계 증가<br>- 콤보 스택 6단계 공격력 : 220%<br><br>시간에 간섭하여 타임로드 효과 강화<br>- 다른 콤보 슬롯 스킬 사용 시 타임 스톱 스택 획득<br>- 확장 콤보 슬롯 스킬 시전 시 기존 콤보 스킬 유지<br>- 타임 로드 효과 지속 시간 +2초@@10672@@2016';

lv105ItemId['자유를 수호하는 윙 숄더'] = '03beca8db56d3881b0724f276e76804d';
lv105OptionMap['자유를 수호하는 윙 숄더'] = {};
lv105OptionMap['자유를 수호하는 윙 숄더'] = '최종 데미지 5% 증가<br>화속성 강화 +20<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 속도 +30%<br>캐스팅 속도 +30%<br>이동 속도 +30%<br>물리/마법 피해 감소 +15%<br>화속성 저항 +10@@6414@@1958';

lv105ItemId['결전의 머스켓 - 런처(여)'] = 'bf460ad935564441b6a2099e07eab9d0';
lv105OptionMap['결전의 머스켓 - 런처(여)'] = {};
lv105OptionMap['결전의 머스켓 - 런처(여)'] = '화염 강타를 화염을 압축하여 발사하는 최대 3회 충전 스킬로 변경<br>- 공격력 : 총 공격력의 50%<br>- 충전 쿨타임 : 4초<br><br>M-3 화염방사기 시전 시 화염을 압축하여 발사<br><br>UHT-03 플레게톤 이동 속도 비율 +10%@@9484@@2016';

lv105ItemId['눈물이 스민 보석'] = '1d95be2285b7f8da2d9e9f82074d4d3d';
lv105OptionMap['눈물이 스민 보석'] = {};
lv105OptionMap['눈물이 스민 보석'] = '최종 데미지 17% 증가<br><br>자신에게 20초 동안 해제되지 않는 상태 이상 1개 부여 (쿨타임 4초)<br>- 출혈/중독/화상/감전 중 1개 부여<br><br>물리/마법 방어력 +7,000<br>MP 1분당 348 회복@@3720@@1900';

lv105ItemId['근원을 삼킨 통파'] = 'b73de65023d879bcdb81d762c269f5df';
lv105OptionMap['근원을 삼킨 통파'] = {};
lv105OptionMap['근원을 삼킨 통파'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['얼어붙은 저항의 장궁'] = '26341e036783969742f28dc6162a1126';
lv105OptionMap['얼어붙은 저항의 장궁'] = {};
lv105OptionMap['얼어붙은 저항의 장궁'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['얼어붙은 저항의 염주'] = '75b134e5a13cb1fe378aee7855440774';
lv105OptionMap['얼어붙은 저항의 염주'] = {};
lv105OptionMap['얼어붙은 저항의 염주'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['근원을 삼킨 소태도'] = '6a6037f75d70b3b4dc9d238f7ca80a90';
lv105OptionMap['근원을 삼킨 소태도'] = {};
lv105OptionMap['근원을 삼킨 소태도'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['내딛은 자의 로드'] = '11290ea142ab1fe61bcc022c9ceff487';
lv105OptionMap['내딛은 자의 로드'] = {};
lv105OptionMap['내딛은 자의 로드'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 건틀릿 - 그래플러(여)'] = '500e12a878b183db8241adf2c63285bf';
lv105OptionMap['결전의 건틀릿 - 그래플러(여)'] = {};
lv105OptionMap['결전의 건틀릿 - 그래플러(여)'] = '전직 스킬로 공격 시 테크니컬 포인트 획득 (최대 20점)<br>- 1~30Lv : 1점<br>- 35~45Lv : 2점<br>- 60~80Lv : 2점<br>- 50, 85, 95Lv : 5점<br>- 100Lv : 10점<br>- 체인 커넥션 연계/공중 스킬 사용 시 1점 추가<br><br>테크니컬 포인트에 따라 효과 발동<br>- 11점 이상 : 50, 85, 100Lv 스킬 적중 시 모든 스킬 남은 쿨타임 30% 감소 (각성기 제외 / 모든 점수 소모)<br>- 6점 이상 : 잡기 스킬 공격 실패 시 해당 스킬 쿨타임을 3초로 감소 (3점 소모)<br>- 1점 이상 : 체인 커넥션 스킬 연계 시 체인 커넥션 회복 시간 3초 감소@@5336@@2016';

lv105ItemId['사멸하는 신뢰의 장궁'] = '37ced3ff14363a62e727f40055a37859';
lv105OptionMap['사멸하는 신뢰의 장궁'] = {};
lv105OptionMap['사멸하는 신뢰의 장궁'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['수호룡의 비호 - 자비'] = '921b77c0ea5e92e34070c2a7ce3c17a2';
lv105OptionMap['수호룡의 비호 - 자비'] = {};
lv105OptionMap['수호룡의 비호 - 자비'] = '모든 속성 강화 +30<br>35Lv 액티브 스킬 Lv +10<br>- 제외 : 갈증, 컨제스트, 도발, 섀도우 박서, 잔영 남기기<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@5604@@1938';

lv105ItemId['광폭화된 전의의 코어 블레이드'] = '1d567e425f1ebcf772abac8a003fcf99';
lv105OptionMap['광폭화된 전의의 코어 블레이드'] = {};
lv105OptionMap['광폭화된 전의의 코어 블레이드'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 쌍검 - 섀도우댄서'] = '2c21f9b4654f49a9b573448549f64bea';
lv105OptionMap['결전의 쌍검 - 섀도우댄서'] = {};
lv105OptionMap['결전의 쌍검 - 섀도우댄서'] = '백어택 공격 시 그림자의 흔적 획득<br>- 2스택 획득 (최대 50스택)<br>- 정면 공격 시 3스택 감소<br><br>페이탈 블리츠 배후 적출 시 그림자의 흔적을 모두 소모하여 공격력 강화<br>- 흔적 1개당 공격력 1% 증가 (최대 50%) <br><br>페이탈 블리츠 시전 시 15초 동안 은신하여 스킬 쿨타임 회복 속도 +12% (각성기 제외)@@4744@@2016';

lv105ItemId['리버시블 레더 코트'] = '0df9f4333903017711ff4972c52eba4f';
lv105OptionMap['리버시블 레더 코트'] = {};
lv105OptionMap['리버시블 레더 코트'] = '최종 데미지 9% 증가<br><br>[영웅의 기백]<br>사망 시 MP를 모두 소진할 때까지 죽음을 억제 (쿨타임 300초)<br>- 피격 시 HP 대신 MP 소모<br>- MP 1초당 2% 감소<br>- 모든 HP 회복 효과 미적용<br><br>공격 시 MP 3,500 회복 (쿨타임 1초)<br><br>공격 속도 +5%<br>캐스팅 속도 +7.5%<br>MP MAX +945@@6803@@1933';

lv105ItemId['광폭화된 전의의 중검'] = 'a2228b8113971f828040bfd891fe9e14';
lv105OptionMap['광폭화된 전의의 중검'] = {};
lv105OptionMap['광폭화된 전의의 중검'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['광폭화된 전의의 스태프'] = 'a7d20c36dba10300dd94b232a622cd9e';
lv105OptionMap['광폭화된 전의의 스태프'] = {};
lv105OptionMap['광폭화된 전의의 스태프'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 소검 - 데몬슬레이어'] = '7f3c930eb14e9da1661d4bdf262e208c';
lv105OptionMap['결전의 소검 - 데몬슬레이어'] = {};
lv105OptionMap['결전의 소검 - 데몬슬레이어'] = '사복검 - 발을 최대 3회 충전 스킬로 변경<br>- 솟아오르는 검 개수 +3<br>- 전직 스킬 공격 시 1회 충전 (사복검 - 발 제외)<br>- 자동 충전 불가능<br><br>전직 스킬의 후딜레이를 캔슬하여 사복검 - 발 시전 가능 (각성기 제외)<br><br>사복검 - 발 후딜레이를 캔슬하여 전직 스킬 시전 가능@@5928@@2016';

lv105ItemId['결전의 창 - 소환사'] = 'ed13ebee16b76c049c95ba38c6d26806';
lv105OptionMap['결전의 창 - 소환사'] = {};
lv105OptionMap['결전의 창 - 소환사'] = '상급 정령들과 정령왕 에체베리아의 이클립스 하이브 추가 조작 패턴 공격력 20% 증가<br><br>이클립스 하이브 강화<br>- 추가 조작 패턴 캐스팅 모션 삭제 <br>- 데드 멀커 : 블랙스웜 홀딩 추가<br>- 글레어린 : 썬더콜링이 적을 끌어모음<br>- 아퀘리스 : 헵타 아이스 미사일이 지상 폭격<br>- 플레임 헐크 : 화염폭검술이 회오리를 생성하고 적을 끌어모음<br>- 에체베리아 : 초고밀도 레이저가 다수 레이저의 지상 폭격으로 변경@@8300@@2016';

lv105ItemId['신비함의 백색 구두'] = '121a37f1c49eeaa00c7deefa8cf570eb';
lv105OptionMap['신비함의 백색 구두'] = {};
lv105OptionMap['신비함의 백색 구두'] = '최종 데미지 11% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5604@@1612';

lv105ItemId['지치지 않는 여정의 하의'] = '01d323c464b2e620b17f32c37f028e52';
lv105OptionMap['지치지 않는 여정의 하의'] = {};
lv105OptionMap['지치지 않는 여정의 하의'] = '최종 데미지 7% 증가<br>스킬 범위 27% 증가<br><br>이동 속도 +18%<br>HP MAX +600@@4796@@1948';

lv105ItemId['결전의 염주 - 크루세이더(여)'] = '2dddc4b985e9b179fc95adbbdff8c93c';
lv105OptionMap['결전의 염주 - 크루세이더(여)'] = {};
lv105OptionMap['결전의 염주 - 크루세이더(여)'] = '용맹의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>치유의 기도 스킬 범위 20% 증가<br><br>홀리 플래쉬가 적을 관통하여 300px 전진 후 폭발<br>- 공격력 100% 증가<br>- 스킬 범위 10% 증가<br><br>홀리 플래쉬 적중 시 그랜드 크로스 크래쉬, 리펜텐스 스매시의 남은 쿨타임 2% 감소<br><br>빛의 은총 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['근원을 삼킨 투창'] = 'a868646ab7f047097eb79a68fae37c9b';
lv105OptionMap['근원을 삼킨 투창'] = {};
lv105OptionMap['근원을 삼킨 투창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['성장하는 모험가의 훈장'] = '0db29d590f7ac771b444405148e105de';
lv105OptionMap['성장하는 모험가의 훈장'] = {};
lv105OptionMap['성장하는 모험가의 훈장'] = '최종 데미지 7% 증가@@5336@@1986';

lv105ItemId['풍운과 안개를 걷는 빛'] = '09df23f5ef3e5d7f6e20e5e11eb2c60c';
lv105OptionMap['풍운과 안개를 걷는 빛'] = {};
lv105OptionMap['풍운과 안개를 걷는 빛'] = '최종 데미지 8% 증가<br><br>물리/마법 피해의 50%를 30초 동안 지속되는 피해로 전환<br><br>슈퍼 아머 부여<br><br>HP MAX +600<br>물리/마법 방어력 +7,000@@5604@@1928';

lv105ItemId['쉐도우블랙 슈트'] = '3af6eed57291395dac316f71be7ba33c';
lv105OptionMap['쉐도우블랙 슈트'] = {};
lv105OptionMap['쉐도우블랙 슈트'] = '공격력 증가 +133.4%<br>최종 데미지 2% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br><br>[저주받은 허수아비]<br>[장비 옵션 조작키] 입력 시 저주를 내리는 허수아비 소환 (쿨타임 10초)<br>- 영역 내 적에게 30초 동안 저주 상태 이상 부여<br>- 저주 지속 시간 +3초<br><br>공격 속도 +8%<br>캐스팅 속도 +12%@@5336@@1958';

lv105ItemId['내딛은 자의 차크라 웨펀'] = '03ecea39f6a29ada17e6b3cfc193324e';
lv105OptionMap['내딛은 자의 차크라 웨펀'] = {};
lv105OptionMap['내딛은 자의 차크라 웨펀'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 로드 - 크리에이터'] = '9296d3f97f5e0850e15420e8bfe73964';
lv105OptionMap['결전의 로드 - 크리에이터'] = {};
lv105OptionMap['결전의 로드 - 크리에이터'] = '웜홀 시전 시 종말의 시계를 소환하여 격리된 시간 영역을 전개<br>영역은 5초 후 또는 스킬 키 추가 입력 시 붕괴되며 폭발<br>종말의 시계를 화염, 냉기, 바람 계열 스킬로 2히트 시 폭발 공격력 증가<br>- 폭발 데미지 : 웜홀 공격력 100%<br>- 2히트당 폭발 공격력 5% 증가 (최대 50%)<br><br>윈드 스톰 강화<br>- 끌어들이는 범위 30% 증가<br>- 게이지 소모량 35% 감소<br><br>던전에서 무기 장착 시 윈드 스톰 게이지 0부터 회복 시작@@8300@@2016';

lv105ItemId['얼터레이션 다이얼 벨트'] = '0d6513f78b59a163573f0ebf0cd0e5e5';
lv105OptionMap['얼터레이션 다이얼 벨트'] = {};
lv105OptionMap['얼터레이션 다이얼 벨트'] = '공격력 증가 +%.1f%<br>최종 데미지 10% 증가<br>모든 속성 강화 +20<br><br>공격 시 10초 동안 HP/MP 30% 회복 (쿨타임 60초)<br><br>석화 지속 시간 +3초<br><br>물리/마법 피해 감소 +10%@@6048@@1953';

lv105ItemId['천재 기술자의 전문 작업복 상의'] = '074aba3de9a06488cba89bcd74ca4c66';
lv105OptionMap['천재 기술자의 전문 작업복 상의'] = {};
lv105OptionMap['천재 기술자의 전문 작업복 상의'] = '최종 데미지 4% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br><br>공격 시 500px 내 폭발 발생 (쿨타임 10초)<br>- 폭발은 총 공격력 증가의 1,300%만큼 피해<br><br>공격 시 HP 2,200 회복 (쿨타임 1초)<br><br>HP MAX +600@@5187@@1953';

lv105ItemId['쿨링 서포트 벨트'] = '07c78673d1b8909e6d1789fe0e881b8c';
lv105OptionMap['쿨링 서포트 벨트'] = {};
lv105OptionMap['쿨링 서포트 벨트'] = '최종 데미지 6% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br>스킬 범위 20% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>MP 1분당 348 회복@@5604@@1948';

lv105ItemId['억류된 혼의 령주'] = '05528a5369db4309b9b6791780c07a97';
lv105OptionMap['억류된 혼의 령주'] = {};
lv105OptionMap['억류된 혼의 령주'] = '[베스트 콜렉터]<br>장착 및 보유한 크리쳐가 20마리 이상일 때 캐릭터 모션에 따른 스펙트럼 발생<br>- 최종 데미지 12% 증가<br>- 스킬 쿨타임 8% 감소 (각성기 제외)<br><br>공격 속도 +15%<br>캐스팅 속도 +15%<br>이동 속도 +23%<br>HP MAX +600@@4528@@1958';

lv105ItemId['결전의 장궁 - 뮤즈'] = '1b88cd6f8edddd7fc2b04dbd160f124a';
lv105OptionMap['결전의 장궁 - 뮤즈'] = {};
lv105OptionMap['결전의 장궁 - 뮤즈'] = '러블리 템포 효과 8% 증가<br>- 장착 시 효과 해제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>밤과 꿈 스킬 범위 20% 증가<br><br>사운드 오브 뮤즈 시전 시 스포트라이트가 추가 발생<br>- 공격력 20% 증가<br>- 스킬 범위 15% 증가<br>- 스킬 적중 시 컬쳐 쇼크 쿨타임 초기화<br><br>컬쳐 쇼크 강화<br>- 스킬 종료 시 2초 동안 추가 파동 5회 발생<br>- 추가 파동 공격력 : 켤쳐 쇼크 세 번째 파동 공격력의 30%<br>- 스킬 범위 20% 증가<br><br>센세이션 싱글 플레이 시 독립 공격력 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['근원을 삼킨 머스켓'] = 'ac7715f119ec6514299afbf0f2221f42';
lv105OptionMap['근원을 삼킨 머스켓'] = {};
lv105OptionMap['근원을 삼킨 머스켓'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['성장하는 모험가의 대검'] = '81cd47289d2b8ef98159e52e26f760a6';
lv105OptionMap['성장하는 모험가의 대검'] = {};
lv105OptionMap['성장하는 모험가의 대검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['결전의 낫 - 크루세이더(남)'] = '0502245a7a34f41e6179e3b887a6c252';
lv105OptionMap['결전의 낫 - 크루세이더(남)'] = {};
lv105OptionMap['결전의 낫 - 크루세이더(남)'] = '영광의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>슬로우 힐 스킬 범위 20% 증가<br><br>정의의 심판 시전 시 빛무리를 소환하여 범위 내 적 10명을 추적 후 정의의 창을 발사하여 폭발<br>- 창 공격력 : 빛의 칼날 공격력의 1600%<br>- 폭발 공격력 : 마법진 최대 유지 시 피니쉬 공격력의 100%<br>- 다른 스킬 시전 중 시전 가능<br><br>미러클 스플리터 스킬 공격력 20% 증가<br>- 돌진 거리 70% 감소<br><br>디플렉트 월 HP 400% 증가<br>- 이동하지 않음<br><br>싱글 플레이 시 독립 공격력 20% 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['결전의 장창 - 듀얼리스트'] = '31b39e36062cd50cf36d6d08ced56e03';
lv105OptionMap['결전의 장창 - 듀얼리스트'] = {};
lv105OptionMap['결전의 장창 - 듀얼리스트'] = '아래 스킬 적중 시 뇌격점혈섬을 강화하는 극 점혈 획득 (최대 30개)<br>- 레조넌스 임페일 : 타격당 10개<br>- 쿼드러플 드라이브 : 타격당 5개<br>- 파동연환창 : 타격당 4개<br>- 트윈 펀토 : 타격당 2개<br>- 퀵 펀토 : 타격당 1개<br><br>보유한 극 점혈 1개당 뇌격점혈섬 강화<br>- 스킬 쿨타임 회복 속도 : +1%<br>- 공격 속도 : +1%<br><br>뇌격점혈섬 공격 시 모든 극 점혈을 소모하여 극 점혈 1개당 창끝 점혈 히트 수 추가 적용<br><br>뇌격점혈섬 공격 속도 +10%@@10672@@2016';

lv105ItemId['절망의 발소리'] = '4b4c1f0e0a9dac8f09a6d8ca3d51ece0';
lv105OptionMap['절망의 발소리'] = {};
lv105OptionMap['절망의 발소리'] = '공격력 증가 +400.1%<br>최종 데미지 17% 증가<br><br>장비로 인한 보호막 MAX 20% 증가<br><br>물리/마법 방어력 +7,000<br>회피율 +8%@@5874@@1958';

lv105ItemId['내딛은 자의 클로'] = '24c0ba594b7d1f143de556c727e9e6cd';
lv105OptionMap['내딛은 자의 클로'] = {};
lv105OptionMap['내딛은 자의 클로'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['근원을 삼킨 광창'] = '60e8b7e1489d1764fc11d6872d47b049';
lv105OptionMap['근원을 삼킨 광창'] = {};
lv105OptionMap['근원을 삼킨 광창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['성장하는 모험가의 광검'] = '3a819d58992533a7614a3ce021518ad1';
lv105OptionMap['성장하는 모험가의 광검'] = {};
lv105OptionMap['성장하는 모험가의 광검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['결전의 토템 - 크루세이더(여)'] = 'a9086f82e0a8ae1f7b2f54ba47e60c4f';
lv105OptionMap['결전의 토템 - 크루세이더(여)'] = {};
lv105OptionMap['결전의 토템 - 크루세이더(여)'] = '용맹의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>치유의 기도 스킬 범위 20% 증가<br><br>홀리 플래쉬가 적을 관통하여 300px 전진 후 폭발<br>- 공격력 100% 증가<br>- 스킬 범위 10% 증가<br><br>홀리 플래쉬 적중 시 그랜드 크로스 크래쉬, 리펜텐스 스매시의 남은 쿨타임 2% 감소<br><br>빛의 은총 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['성장하는 모험가의 낫'] = '3fbf40627e1182230c694fac4a38e8ca';
lv105OptionMap['성장하는 모험가의 낫'] = {};
lv105OptionMap['성장하는 모험가의 낫'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['근원을 삼킨 낫'] = 'df25bbdf1a270ab3ee3959e35db676bf';
lv105OptionMap['근원을 삼킨 낫'] = {};
lv105OptionMap['근원을 삼킨 낫'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['결전의 너클 - 그래플러(여)'] = 'a6d69424877c757046c00418be7873d3';
lv105OptionMap['결전의 너클 - 그래플러(여)'] = {};
lv105OptionMap['결전의 너클 - 그래플러(여)'] = '전직 스킬로 공격 시 테크니컬 포인트 획득 (최대 20점)<br>- 1~30Lv : 1점<br>- 35~45Lv : 2점<br>- 60~80Lv : 2점<br>- 50, 85, 95Lv : 5점<br>- 100Lv : 10점<br>- 체인 커넥션 연계/공중 스킬 사용 시 1점 추가<br><br>테크니컬 포인트에 따라 효과 발동<br>- 11점 이상 : 50, 85, 100Lv 스킬 적중 시 모든 스킬 남은 쿨타임 30% 감소 (각성기 제외 / 모든 점수 소모)<br>- 6점 이상 : 잡기 스킬 공격 실패 시 해당 스킬 쿨타임을 3초로 감소 (3점 소모)<br>- 1점 이상 : 체인 커넥션 스킬 연계 시 체인 커넥션 회복 시간 3초 감소@@5336@@2016';

lv105ItemId['스톰라이더'] = '137e00b2550a315e44eb5cf7d119aa0e';
lv105OptionMap['스톰라이더'] = {};
lv105OptionMap['스톰라이더'] = '최종 데미지 3% 증가<br>스킬 쿨타임 회복 속도 +10% (각성기 제외)<br><br>스킬로 소모한 MP의 10%만큼 HP 회복<br><br>물리/마법 방어력 +9,000<br>적중률 +10%<br>스킬 MP 소모량 -7%@@6414@@1958';

lv105ItemId['화려한 청색의 음율'] = '08192ecf513c7a8b18063be883a6356b';
lv105OptionMap['화려한 청색의 음율'] = {};
lv105OptionMap['화려한 청색의 음율'] = '스킬 쿨타임 회복 속도 +50% (각성기 제외)<br>- "오랜 시간 품어온 소원" 장비 장착 필요<br><br>퀵스탠딩 쿨타임 50% 감소<br>퀵스탠딩 유지 시 1초당 HP 1% 회복 (최대 4초)<br><br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +18%<br>물리/마법 방어력 +7,000<br>수면 내성 -10%@@5604@@1948';

lv105ItemId['결전의 장창 - 드래고니안 랜서'] = '9738428e14e973e1cb5fcbadc4389d57';
lv105OptionMap['결전의 장창 - 드래고니안 랜서'] = {};
lv105OptionMap['결전의 장창 - 드래고니안 랜서'] = '서번트 랜스 스킬로 공격한 적에게 사냥 표식을 생성하고 사냥 1단계 부여<br>사냥 표식 20개마다 사냥 단계 상승 (최대 3단계)<br>아래 스킬 시전 시 사냥 단계를 소모하고 해당 스킬 공격력 증가<br>- 데보닝 슬래셔<br>- 팔티잔 스큐어<br>- 날개 꿰기<br><br><사냥 단계별 공격력 증가량><br>- 1단계 : 10%<br>- 2단계 : 20%<br>- 3단계 : 30%<br><br><사냥 표식 부여량><br>- 서번트 랜스 : 타격당 3 스택<br>- 서번트 랜스 : 어썰트 : 타격당 10 스택<br>- 서번트 랜스 : 인게이저 : 타격당 4 스택<br><br>서번트 랜스 마법창 재생성 시간 30% 감소<br>서번트 랜스 재발사 쿨타임 30% 감소@@8300@@2016';

lv105ItemId['미니 배터리 팩'] = '74c8c97345511afa9a6f00623d2b0525';
lv105OptionMap['미니 배터리 팩'] = {};
lv105OptionMap['미니 배터리 팩'] = '최종 데미지 11% 증가<br><br>스킬 MP 소모량 +100%<br><br>적중률 +10%<br>회피율 +8%@@5066@@1948';

lv105ItemId['결전의 소검 - 카오스'] = '237f141ec341f5e72717e87bd2d71a5c';
lv105OptionMap['결전의 소검 - 카오스'] = {};
lv105OptionMap['결전의 소검 - 카오스'] = '제노사이드 크러쉬 시전 시 데몬들을 폭발시키는 대신 아텐의 화신이 데몬들의 환영을 소환하고 폭발시키도록 변경<br>- 공격력 10% 증가<br>- 스킬 범위 20% 증가<br>- 시전 중 미드나잇 카니발 시전 가능<br>- 데몬이 없을 경우 즉시 모든 데몬 소환@@10076@@2016';

lv105ItemId['광폭화된 전의의 클로'] = '3bf589fcfe964ddebf48055beba386cb';
lv105OptionMap['광폭화된 전의의 클로'] = {};
lv105OptionMap['광폭화된 전의의 클로'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 대검 - 검귀'] = '642c5153a4621094812cfe3551f0bc3d';
lv105OptionMap['결전의 대검 - 검귀'] = {};
lv105OptionMap['결전의 대검 - 검귀'] = '검술 , 합격 계열 스킬 공격 시 귀신보 쿨타임 초기화<br>- 검술 계열 스킬을 귀신보로 발동하는 경우 제외<br><br>귀신보 공격력 20% 증가<br><br>귀신보 Y축 공격 범위 10% 증가@@9484@@2016';

lv105ItemId['전진하는 기세'] = '61fdddd58174d6e02950f37df0b13ff8';
lv105OptionMap['전진하는 기세'] = {};
lv105OptionMap['전진하는 기세'] = '40~45Lv 스킬 공격력 5% 증가<br>스킬 쿨타임 회복 속도 +15% (각성기 제외)<br>스킬 범위 16% 증가<br>40~45Lv 스킬 범위 15% 증가@@6440@@1900';

lv105ItemId['결전의 통파 - 스트리트파이터(여)'] = '4a99f631df3de58795a2e312259e4cf2';
lv105OptionMap['결전의 통파 - 스트리트파이터(여)'] = {};
lv105OptionMap['결전의 통파 - 스트리트파이터(여)'] = '특정 스킬 공격 시 즉폭의 혈이 발생하여 자신의 출혈/중독/화상/감전 데미지 폭발<br><즉폭의 혈 발생 스킬><br>- 일발화약성 훅 공격<br>- 맹독 일발화약성 훅 공격<br>- 마운트 마지막 충격파<br><br>아래의 스킬 상태 이상 1개당 보너스 공격력 5% 추가 증가<br>- 일발화약성<br>- 맹독 일발화약성<br>- 크레이지 발칸@@9484@@2016';

lv105ItemId['얼어붙은 저항의 리볼버'] = '2bf7a249a7e6076c7ae3ccb4f73db5ec';
lv105OptionMap['얼어붙은 저항의 리볼버'] = {};
lv105OptionMap['얼어붙은 저항의 리볼버'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 보우건 - 어썰트'] = '4cdfa69d26d931d7cf2e4fe27fb21140';
lv105OptionMap['결전의 보우건 - 어썰트'] = {};
lv105OptionMap['결전의 보우건 - 어썰트'] = '부스트 스킬로 전직 스킬 캔슬 시 전력 1스택 획득 (최대 5스택)<br><br>아래 스킬 시전 시 모든 전력을 소모하고, 소모한 전력당 공격력 10% 증가<br>- 레이시온 크리트<br>- 부스트 : 다이너마이트 킥<br><br>부스트 : 다이너마이트 킥 적중 시 적을 1.5초 동안 홀딩<br>- 소모한 전력 1스택당 홀딩 시간 0.5초 증가@@9484@@2016';

lv105ItemId['내딛은 자의 단검'] = '5607105ab414e758323c79d5d2af85c2';
lv105OptionMap['내딛은 자의 단검'] = {};
lv105OptionMap['내딛은 자의 단검'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['성장하는 모험가의 장창'] = 'd8e83a86b9a49045900a67a8a8bc8dbe';
lv105OptionMap['성장하는 모험가의 장창'] = {};
lv105OptionMap['성장하는 모험가의 장창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['용살자의 증표 - 용린 귀걸이'] = '3657d79470a96195bc2d3da8fe2153ba';
lv105OptionMap['용살자의 증표 - 용린 귀걸이'] = {};
lv105OptionMap['용살자의 증표 - 용린 귀걸이'] = '모든 속성 강화 +30<br>75Lv 액티브 스킬 Lv +10<br>- 제외 : 초월의 룬, 디바인 플래쉬, 광풍 나선력<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +5%<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@4662@@1933';

lv105ItemId['초소형 GPS'] = '4fce013abf06e5834a391e150732f410';
lv105OptionMap['초소형 GPS'] = {};
lv105OptionMap['초소형 GPS'] = '최종 데미지 12% 증가<br>무력화 게이지 감소량 +45%<br><br>물리/마법 피해 감소 +15%<br>물리/마법 방어력 +7,000<br>공격 속도 +8%<br>캐스팅 속도 +12%@@4258@@1948';

lv105ItemId['멈추지 않는 운명'] = '61276b1795ab47f1e8813b8600c01b5a';
lv105OptionMap['멈추지 않는 운명'] = {};
lv105OptionMap['멈추지 않는 운명'] = '최종 데미지 7% 증가<br><br>[아이스 스트라이크]<br>빙결 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>[만년설]<br>불에 녹지 않는 빙결 부여<br>- 화상으로 해제 불가능한 빙결 부여<br>- 공격 시 모든 적에게 10초 동안 빙결 상태 이상 부여 (쿨타임 5초)<br><br>MP MAX +945@@4714@@1930';

lv105ItemId['결전의 대검 - 웨펀마스터'] = 'ac3f8ab19415488a165df4ec2bcef270';
lv105OptionMap['결전의 대검 - 웨펀마스터'] = {};
lv105OptionMap['결전의 대검 - 웨펀마스터'] = '초식을 발전시켜 이기어검술 스킬 강화<br>- 지속 시간 +20초<br>- 기본기 숙련에 영향받는 스킬 적중 시 지속 시간 +0.5초 (최대 지속 시간 초과 불가능)<br>- 꿰뚫는검 사출 개수 +1<br>- 꿰뚫는검 사출 회수 시간 감소<br><br>장착 해제 시 이기어검술 지속 시간 종료@@8892@@2016';

lv105ItemId['결전의 십자가 - 퇴마사'] = '10bb56529f396bb4993bb68c808c9c85';
lv105OptionMap['결전의 십자가 - 퇴마사'] = {};
lv105OptionMap['결전의 십자가 - 퇴마사'] = '공의 식신 - 백호 스킬 공격력 20% 증가<br>공의 식신 - 백호 스킬 범위 20% 증가<br><br>난격의 매 타격이 충격파 생성<br>- 쿨타임 20% 감소<br>- 난격 횟수 100% 증가<br>- 막타 삭제<br>- 막타 공격력은 난격 공격력에 합산<br><br>무쌍격 범위 20% 증가<br><br>대시 중 무쌍격 시전 시 전방으로 돌진하며 시전<br><br>공의 식신 - 백호 구슬 크기 비율 50% 증가@@4148@@2016';

lv105ItemId['공중형 : 전술 프롭 드론'] = '26da5fc2e592517bdb6fb3f982683d12';
lv105OptionMap['공중형 : 전술 프롭 드론'] = {};
lv105OptionMap['공중형 : 전술 프롭 드론'] = '최종 데미지 9% 증가<br><br>[드론 서포트]<br>공격 시 전투 드론, 피격 시 구급 드론을 호출하여 지원 상자 투하 (쿨타임 30초)<br>- 전투 : 30초 동안 모든 속도 +15%<br>- 구급 : HP/MP 30% 회복<br><br>HP MAX +600<br>MP MAX +945@@5874@@1928';

lv105ItemId['사멸하는 신뢰의 핸드캐넌'] = '1d394e3fe9357db2e25ea6f72b055fa8';
lv105OptionMap['사멸하는 신뢰의 핸드캐넌'] = {};
lv105OptionMap['사멸하는 신뢰의 핸드캐넌'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['아머드 파워 상의'] = '10bb328ff8d2533d04135d3881272119';
lv105OptionMap['아머드 파워 상의'] = {};
lv105OptionMap['아머드 파워 상의'] = '모든 속성 강화 +10<br>화상 피해 +20%<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 화상 상태 이상 부여 (쿨타임 10초)<br><br>물리/마법 크리티컬 히트 +5%<br>화상 내성 +10%@@5604@@1968';

lv105ItemId['사멸하는 신뢰의 쌍검'] = '22c1ca322b0215230fb1f8a1af00ac84';
lv105OptionMap['사멸하는 신뢰의 쌍검'] = {};
lv105OptionMap['사멸하는 신뢰의 쌍검'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 건틀릿 - 스트리트파이터(여)'] = '96d7379276065c67345edd1b5c8cf8ca';
lv105OptionMap['결전의 건틀릿 - 스트리트파이터(여)'] = {};
lv105OptionMap['결전의 건틀릿 - 스트리트파이터(여)'] = '특정 스킬 공격 시 즉폭의 혈이 발생하여 자신의 출혈/중독/화상/감전 데미지 폭발<br><즉폭의 혈 발생 스킬><br>- 일발화약성 훅 공격<br>- 맹독 일발화약성 훅 공격<br>- 마운트 마지막 충격파<br><br>아래의 스킬 상태 이상 1개당 보너스 공격력 5% 추가 증가<br>- 일발화약성<br>- 맹독 일발화약성<br>- 크레이지 발칸@@9484@@2016';

lv105ItemId['성장하는 모험가의 광창'] = '9a2bf378d8605a9d82dcc1dd9ac4f37b';
lv105OptionMap['성장하는 모험가의 광창'] = {};
lv105OptionMap['성장하는 모험가의 광창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 건틀릿 - 스트라이커(남)'] = 'cc24df88d49730bf0805b3e9bb41ee68';
lv105OptionMap['결전의 건틀릿 - 스트라이커(남)'] = {};
lv105OptionMap['결전의 건틀릿 - 스트라이커(남)'] = '이중개방의 효과가 10초 동안 유지되는 맹렬한 화염으로 변경되며 최종 데미지 45% 증가 (각성기 제외)<br>- 이중개방 강화 공격력 효과 미적용<br><br>화염의 힘으로 머슬시프트 강화<br>- 캔슬 공격 시 이중개방 남은 쿨타임 2% 감소<br>- 캔슬 시 30초 동안 전직 계열 스킬 공격력 증가율 +2%<br>- 캔슬 횟수 +2@@7112@@2016';

lv105ItemId['얼어붙은 저항의 자동권총'] = '3bc41b949d1c40c272c5f1c493109f76';
lv105OptionMap['얼어붙은 저항의 자동권총'] = {};
lv105OptionMap['얼어붙은 저항의 자동권총'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 너클 - 스트리트파이터(여)'] = 'b698400ac22995040b7e2a518dc13a49';
lv105OptionMap['결전의 너클 - 스트리트파이터(여)'] = {};
lv105OptionMap['결전의 너클 - 스트리트파이터(여)'] = '특정 스킬 공격 시 즉폭의 혈이 발생하여 자신의 출혈/중독/화상/감전 데미지 폭발<br><즉폭의 혈 발생 스킬><br>- 일발화약성 훅 공격<br>- 맹독 일발화약성 훅 공격<br>- 마운트 마지막 충격파<br><br>아래의 스킬 상태 이상 1개당 보너스 공격력 5% 추가 증가<br>- 일발화약성<br>- 맹독 일발화약성<br>- 크레이지 발칸@@9484@@2016';

lv105ItemId['결전의 염주 - 무녀'] = '20bfc50379daf3c0e2556c140f241e97';
lv105OptionMap['결전의 염주 - 무녀'] = {};
lv105OptionMap['결전의 염주 - 무녀'] = '염주 사출, 목환자경 타격 1회당 신력 염주알 1개 획득 (최대 25개)<br><br>아래 스킬 시전 시 신력 염주알을 소모하여 강화<br>- 부동주박진 : 염주 회전 수 1회 증가 (8개 소모)<br>- 백팔염주 : 염주 사출 개수 10개 증가 (10개 소모)<br>- 마혼부 : 퇴마음양옥 : 튕기는 횟수 3회 증가 (10개 소모)<br>- 염폭모대주 : 염주알 낙하 수 1 증가 (15개 소모)<br><br>염주 사출, 목환자경 시전 1회당 백팔염주, 부동주박진 스킬 쿨타임 회복 속도 +1%<br>- 백팔염주, 부동주박진 시전 시 효과 초기화@@4148@@2016';

lv105ItemId['수호룡의 비호 - 축복'] = '1d31aaae6d692a76a6da48a79c66a3f9';
lv105OptionMap['수호룡의 비호 - 축복'] = {};
lv105OptionMap['수호룡의 비호 - 축복'] = '모든 속성 강화 +30<br>45Lv 액티브 스킬 Lv +10<br>- 제외 : 힐 윈드, 재생의 아리아, 달콤한 칸타빌레<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@5874@@1938';

lv105ItemId['기사의 긍지'] = '1160d523e09c57173a62f1b337eb6b67';
lv105OptionMap['기사의 긍지'] = {};
lv105OptionMap['기사의 긍지'] = '공격력 증가 +222.3%<br>최종 데미지 7% 증가<br>모든 속성 강화 +10<br><br>[아이스 스트라이크]<br>빙결 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>공격 시 적에게 5초 동안 빙결 이상 부여 (쿨타임 8초)<br><br>빙결 지속 시간 +2초@@4984@@1968';

lv105ItemId['부스팅 펄스 튜브'] = '43c511645914d15da2464c26ec863f27';
lv105OptionMap['부스팅 펄스 튜브'] = {};
lv105OptionMap['부스팅 펄스 튜브'] = '최종 데미지 17% 증가<br>- "천재 기술자의 두터운 보호부츠" 장착 필요<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@3720@@1900';

lv105ItemId['어댑터블 투톤 링'] = '0c175759e1f145159065cc56257897b9';
lv105OptionMap['어댑터블 투톤 링'] = {};
lv105OptionMap['어댑터블 투톤 링'] = '스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>[음양의 기운]<br>공격 시 15초 동안 음양의 기운을 얻을 수 있는 구슬 생성 (쿨타임 1초)<br>- 검은색 구슬 : 공격 속도 +24%, 캐스팅 속도 +36%<br>- 흰색 구슬 : HP MAX 10% 수치의 보호막 부여<br><br>물리/마법 크리티컬 히트 +5%<br>이동 속도 +28%@@4258@@1958';

lv105ItemId['결전의 자동권총 - 런처(남)'] = '3edbb7aaf77b82062c47ae7ca4c79f41';
lv105OptionMap['결전의 자동권총 - 런처(남)'] = {};
lv105OptionMap['결전의 자동권총 - 런처(남)'] = '레이저 라이플을 최대 3회 충전 스킬로 변경<br>- 공격력 20% 증가<br>- 크기 40% 증가<br>- 충전 쿨타임 : 7초<br>- 충전 레이저 라이플 충전 시간 50% 감소<br>- 적중 시 적에게 10초 동안 전자기장 부여<br><br>FM-92 스팅어 강화<br>- 공격력 15% 증가<br>- 폭발 범위 25% 증가<br>- 전자기장이 부여된 적 존재 시 대상을 추적하는 강화 고폭탄 발사@@8892@@2016';

lv105ItemId['빛을 발하는 생명'] = '9600cd6aedd9194d8b86859d716e5a16';
lv105OptionMap['빛을 발하는 생명'] = {};
lv105OptionMap['빛을 발하는 생명'] = '공격력 증가 +222.3%<br>최종 데미지 7.1% 증가<br>모든 속성 강화 +10<br><br>공격 시 적에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)<br><br>감전 지속 시간 -10%<br><br>감전 내성 +1%@@5133@@1968';

lv105ItemId['근원을 삼킨 장창'] = '37eafad773bde4cb28fa253a88324ead';
lv105OptionMap['근원을 삼킨 장창'] = {};
lv105OptionMap['근원을 삼킨 장창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['데카 가이던스 디바이스'] = '9a66757f985f48fc84a8199f1855445f';
lv105OptionMap['데카 가이던스 디바이스'] = {};
lv105OptionMap['데카 가이던스 디바이스'] = '최종 데미지 4% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@2910@@1872';

lv105ItemId['악몽을 이겨낸 승전보'] = '011a747e591102a8e85c0546fb475051';
lv105OptionMap['악몽을 이겨낸 승전보'] = {};
lv105OptionMap['악몽을 이겨낸 승전보'] = '최종 데미지 8% 증가<br><br>슈퍼 아머 부여<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@5336@@1900';

lv105ItemId['세계가 담긴 축'] = '0c359ea137a9b7da80b16a569e23b148';
lv105OptionMap['세계가 담긴 축'] = {};
lv105OptionMap['세계가 담긴 축'] = '최종 데미지 11% 증가<br><br>MP MAX +945<br>모든 속성 저항 +8@@5604@@1948';

lv105ItemId['결전의 자동권총 - 메카닉(남)'] = '02aeb092653b521b9dbd798de2b1f3c5';
lv105OptionMap['결전의 자동권총 - 메카닉(남)'] = {};
lv105OptionMap['결전의 자동권총 - 메카닉(남)'] = '로봇 전폭으로 로봇 폭발 시 R-러너 생성<br>-  R-러너 공격력 : RX-78 랜드러너 공격력의 50%<br>- 로봇 전폭의 폭발 데미지 변화율 효과 적용<br>- 최대 10개 생성<br><br><R-러너 생성 가능 스킬><br>- RX-78 랜드러너<br>- Ez-8 카운트 다운<br>- RX-60 트랩 러너<br>- 공중 전투 메카 : 템페스터<br>- 메카 드롭<br>- 스패로우 팩토리<br>- Ez-10 카운터 어택<br>- TX-45 A-Team<br><br>RX-78 랜드러너 공격력 5% 증가@@9484@@2016';

lv105ItemId['계곡을 감시하는 활보'] = '1437d06dccd505e8f3b2ffea670bc91d';
lv105OptionMap['계곡을 감시하는 활보'] = {};
lv105OptionMap['계곡을 감시하는 활보'] = '최종 데미지 10% 증가<br><br>공격 시 MP 3,500 회복 (쿨타임 1초)<br><br>MP MAX +5%<br>물리/마법 피해 감소 +10%@@4917@@1915';

lv105ItemId['근원을 삼킨 장도'] = '35cfd571fa61fa852d9266736ece7603';
lv105OptionMap['근원을 삼킨 장도'] = {};
lv105OptionMap['근원을 삼킨 장도'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['계곡을 감시하는 표식'] = 'a48e106a655177d89a8b400fde1dc7d1';
lv105OptionMap['계곡을 감시하는 표식'] = {};
lv105OptionMap['계곡을 감시하는 표식'] = '최종 데미지 14% 증가<br>1~70Lv 스킬 범위 15% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>물리/마법 방어력 +7,000@@5604@@1910';

lv105ItemId['벤타블랙 팬츠'] = '38680847217ae3b8d46abd30108eab42';
lv105OptionMap['벤타블랙 팬츠'] = {};
lv105OptionMap['벤타블랙 팬츠'] = '최종 데미지 15% 증가<br>스킬 쿨타임 15% 증가<br>기본기 숙련 공격력 53% 증가<br><br>공격 속도 +23%<br>캐스팅 속도 +27%<br>적중률 +10%@@4258@@1958';

lv105ItemId['근원을 삼킨 건틀릿'] = '2363487ed2bf6dbd38a098a5a65e39d0';
lv105OptionMap['근원을 삼킨 건틀릿'] = {};
lv105OptionMap['근원을 삼킨 건틀릿'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['근원을 삼킨 리볼버'] = '2e5e9ad15b651a3f458f528093d59092';
lv105OptionMap['근원을 삼킨 리볼버'] = {};
lv105OptionMap['근원을 삼킨 리볼버'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 도 - 아수라'] = '97f153e0d0b60823a039417404a7ba00';
lv105OptionMap['결전의 도 - 아수라'] = {};
lv105OptionMap['결전의 도 - 아수라'] = '파동검 폭염이 빠르게 폭발<br>- 폭발 위치 변경<br>- 폭발 간격 시간 30% 감소<br>- 폭발 간격 거리 90% 감소<br>- 폭발 크기 45% 증가<br>- 3타 폭발 크기 70% 증가<br><br>뇌신의 힘을 받아 아래의 스킬 시전 중 작열 파동진을 모션 없이 시전 가능<br>- 파동검 폭염<br>- 부동명왕진<br>- 파동검 : 인다라망<br><br>살의의 파동 파동 영역 20% 증가<br><br>작열 파동진 스킬 범위 25% 증가@@10672@@2016';

lv105ItemId['결전의 자동권총 - 스핏파이어(여)'] = '8d01f652eb32e1bf898dee98c12a65b6';
lv105OptionMap['결전의 자동권총 - 스핏파이어(여)'] = {};
lv105OptionMap['결전의 자동권총 - 스핏파이어(여)'] = 'G-14 파열류탄, G-35L 섬광류탄, G-18C 빙결류탄 스킬 강화<br>- 최대 장전 수 +2<br>- 점프 상태에서 시전 시 니트로 모터를 소모하지 않음<br><br><br>보유한 유탄을 소모하여 포토빌라이저 강화<br>- 소모한 유탄 1개당 공격력 2% 증가 (최대 30%)<br>- 스탠바이-레디 상태에서는 최대 강화 수치로 발사@@8300@@2016';

lv105ItemId['기계 공학의 심장 목걸이'] = '260b2a403c8d3ad0579ab4322a09dd02';
lv105OptionMap['기계 공학의 심장 목걸이'] = {};
lv105OptionMap['기계 공학의 심장 목걸이'] = '최종 데미지 10% 증가<br>모든 속성 강화 +20<br><br>스킬로 인한 MP 회복량 +200%<br><br>MP MAX +945@@4972@@1943';

lv105ItemId['마땅한 본분'] = '1751cf4529331916565fffaef9d9c2d7';
lv105OptionMap['마땅한 본분'] = {};
lv105OptionMap['마땅한 본분'] = '최종 데미지 14% 증가<br>모든 속성 강화 +20<br><br>적중률 +10%<br>스킬 MP 소모량 -7%@@3450@@1958';

lv105ItemId['결전의 둔기 - 버서커'] = '4765bd863fa341012babf0f14c05541b';
lv105OptionMap['결전의 둔기 - 버서커'] = {};
lv105OptionMap['결전의 둔기 - 버서커'] = '피에 굶주려 갈증 시전 시 HP 10%를 소모하고 혈기 3개 획득 (최대 10스택)<br>- 갈증 충전 기능 삭제<br>- 다른 스킬 시전 중 갈증 시전 가능<br>- 던전 입장 시 혈기 1개 획득<br><br>블러드 소드, 격노 시전 시 혈기 1개를 소모하여 강화<br>- 공격력 30% 증가<br>- 스킬 범위 15% 증가<br><br>블러드 소드, 격노 시전 시 HP 5% 회복@@9484@@2016';

lv105ItemId['격동하는 마음'] = '073a49a592516fef6eb217c24fc85f75';
lv105OptionMap['격동하는 마음'] = {};
lv105OptionMap['격동하는 마음'] = '최종 데미지 8% 증가<br><br>[불사의 주술]<br>사망 시 5초 동안 불사의 주술 발동 (쿨타임 300초)<br>- HP 10% 회복<br>- 다른 HP 회복 효과 및 데미지 무효화<br>- 주술 종료 시 HP MAX 30% 제한<br><br>공격 시 HP 2,200 회복 (쿨타임 1초)<br><br>물리/마법 크리티컬 히트 +5%<br>카운터 피격 시 물리/마법 피해 감소 +20%@@5995@@1963';

lv105ItemId['결전의 십자가 - 크루세이더(남)'] = '46921b3b77f967c86442173fcf778bf7';
lv105OptionMap['결전의 십자가 - 크루세이더(남)'] = {};
lv105OptionMap['결전의 십자가 - 크루세이더(남)'] = '영광의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>슬로우 힐 스킬 범위 20% 증가<br><br>정의의 심판 시전 시 빛무리를 소환하여 범위 내 적 10명을 추적 후 정의의 창을 발사하여 폭발<br>- 창 공격력 : 빛의 칼날 공격력의 1600%<br>- 폭발 공격력 : 마법진 최대 유지 시 피니쉬 공격력의 100%<br>- 다른 스킬 시전 중 시전 가능<br><br>미러클 스플리터 스킬 공격력 20% 증가<br>- 돌진 거리 70% 감소<br><br>디플렉트 월 HP 400% 증가<br>- 이동하지 않음<br><br>싱글 플레이 시 독립 공격력 20% 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['사멸하는 신뢰의 보우건'] = '50ee4a1d7ae1531dc49db48ea4630cf9';
lv105OptionMap['사멸하는 신뢰의 보우건'] = {};
lv105OptionMap['사멸하는 신뢰의 보우건'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['옥화의 망령 각갑'] = '1984f8a7c0a85f9773d7a43b3230bc1e';
lv105OptionMap['옥화의 망령 각갑'] = {};
lv105OptionMap['옥화의 망령 각갑'] = '공격력 증가 +340.9%<br>최종 데미지 17% 증가<br><br>공격 속도 +24%<br>캐스팅 속도 +24%<br>이동 속도 +8%<br>회피율 +8%@@5604@@1968';

lv105ItemId['패셔네이트 나이트 부츠'] = '02d91a14d367009b8e748b8b2e14d995';
lv105OptionMap['패셔네이트 나이트 부츠'] = {};
lv105OptionMap['패셔네이트 나이트 부츠'] = '최종 데미지 22% 증가<br>- "억제된 마력의 팔찌" 장비 장착 필요<br><br>공격 시 MP 2,500 회복 (쿨타임 1초)<br><br>물리/마법 크리티컬 히트 +7%<br>MP MAX +945<br>모든 상태 이상 내성 -10%@@5510@@1943';

lv105ItemId['결전의 스태프 - 크리에이터'] = '9d23c89793a87a5f97818577537f6c9c';
lv105OptionMap['결전의 스태프 - 크리에이터'] = {};
lv105OptionMap['결전의 스태프 - 크리에이터'] = '웜홀 시전 시 종말의 시계를 소환하여 격리된 시간 영역을 전개<br>영역은 5초 후 또는 스킬 키 추가 입력 시 붕괴되며 폭발<br>종말의 시계를 화염, 냉기, 바람 계열 스킬로 2히트 시 폭발 공격력 증가<br>- 폭발 데미지 : 웜홀 공격력 100%<br>- 2히트당 폭발 공격력 5% 증가 (최대 50%)<br><br>윈드 스톰 강화<br>- 끌어들이는 범위 30% 증가<br>- 게이지 소모량 35% 감소<br><br>던전에서 무기 장착 시 윈드 스톰 게이지 0부터 회복 시작@@8300@@2016';

lv105ItemId['결전의 둔기 - 아수라'] = '63f9e8b03eb9f32112f9d95f16f936fb';
lv105OptionMap['결전의 둔기 - 아수라'] = {};
lv105OptionMap['결전의 둔기 - 아수라'] = '파동검 폭염이 빠르게 폭발<br>- 폭발 위치 변경<br>- 폭발 간격 시간 30% 감소<br>- 폭발 간격 거리 90% 감소<br>- 폭발 크기 45% 증가<br>- 3타 폭발 크기 70% 증가<br><br>뇌신의 힘을 받아 아래의 스킬 시전 중 작열 파동진을 모션 없이 시전 가능<br>- 파동검 폭염<br>- 부동명왕진<br>- 파동검 : 인다라망<br><br>살의의 파동 파동 영역 20% 증가<br><br>작열 파동진 스킬 범위 25% 증가@@10672@@2016';

lv105ItemId['약동하는 생명의 고동'] = '0130e39616fcc336151677713e49d375';
lv105OptionMap['약동하는 생명의 고동'] = {};
lv105OptionMap['약동하는 생명의 고동'] = '최종 데미지 10% 증가<br><br>공격 시 명속성 폭발 발생 (쿨타임 5초)<br>- 총 공격력 증가 수치의 480%만큼 피해<br>- 자신에게는 1의 피해<br><br>물리/마법 방어력 +7,000<br>명속성 저항 +10@@6414@@1958';

lv105ItemId['빛을 잃은 진실'] = '1c275071f4ce209aa05f915ef840bf88';
lv105OptionMap['빛을 잃은 진실'] = {};
lv105OptionMap['빛을 잃은 진실'] = '최종 데미지 2% 증가<br>출혈 피해 +20%<br><br>출혈 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>자신에게 해제되지 않는 출혈 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +10%<br>공격 속도 +5%<br>캐스팅 속도 +7.5%<br>HP 1분당 460.2 회복@@5604@@1968';

lv105ItemId['컨퓨즈드 코어 슈트'] = '012e5eaf2e5c3db2e9cb777c56d72fa4';
lv105OptionMap['컨퓨즈드 코어 슈트'] = {};
lv105OptionMap['컨퓨즈드 코어 슈트'] = '모든 속성 강화 +10<br>출혈/중독/화상/감전 피해 +15%<br><br>[D011-Risa]<br>스킬 시전 시 20초 동안 D011-Risa 소환 (최대 5개)<br>- D011-Risa는 가장 강한 적에게 부여된 모든 상태 이상을 부여받음<br><br>물리/마법 크리티컬 히트 +5%<br>모든 상태 이상 내성 +10%<br>스킬 MP 소모량 -7%@@5604@@1938';

lv105ItemId['얼어붙은 저항의 머스켓'] = '3eee5135fb253ece29f63cdca9474640';
lv105OptionMap['얼어붙은 저항의 머스켓'] = {};
lv105OptionMap['얼어붙은 저항의 머스켓'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['내딛은 자의 완드'] = '0e7020331c4d15c119ff741261cdc040';
lv105OptionMap['내딛은 자의 완드'] = {};
lv105OptionMap['내딛은 자의 완드'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['성장하는 모험가의 장도'] = '111aa6534802424a632d5993dc81edaf';
lv105OptionMap['성장하는 모험가의 장도'] = {};
lv105OptionMap['성장하는 모험가의 장도'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['내딛은 자의 선현궁'] = '0539a0591e1471d73ca1e104d71b0509';
lv105OptionMap['내딛은 자의 선현궁'] = {};
lv105OptionMap['내딛은 자의 선현궁'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['시간 공학의 시간'] = '122b99260ca516d3d8cfc1a4e690b8da';
lv105OptionMap['시간 공학의 시간'] = {};
lv105OptionMap['시간 공학의 시간'] = '최종 데미지 14% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>물리/마법 피해 감소 +15%<br>적중률 +10%@@5604@@1910';

lv105ItemId['결전의 통파 - 스트라이커(여)'] = 'd3f2a29104bd7b826ab291cd14db7c90';
lv105OptionMap['결전의 통파 - 스트라이커(여)'] = {};
lv105OptionMap['결전의 통파 - 스트라이커(여)'] = '라이트닝 댄스 이동 횟수가 4회 감소하고 1:1 공격 시 대상의 좌우를 이동하며 공격<br>- 공격력 15% 감소<br>- 공격 속도 +30%<br>- 이동 범위 5% 증가<br>- 공격 시 적에게 감전 상태 이상 부여 (쿨타임 15초)<br><br>라이트닝 댄스 공격 시 충격파 생성<br>- 직접 타격 대상은 충격파 피격 제외<br>- 1:1 공격일 경우 충격파 미생성@@9484@@2016';

lv105ItemId['별을 담는 벨트'] = '0adff49f605d822d72d2afc73cbfa192';
lv105OptionMap['별을 담는 벨트'] = {};
lv105OptionMap['별을 담는 벨트'] = '최종 데미지 13% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%<br>물리/마법 피해 감소 +15%@@6144@@1928';

lv105ItemId['결전의 도 - 데몬슬레이어'] = '7d3a90fda94dbafdd77478206651cd18';
lv105OptionMap['결전의 도 - 데몬슬레이어'] = {};
lv105OptionMap['결전의 도 - 데몬슬레이어'] = '사복검 - 발을 최대 3회 충전 스킬로 변경<br>- 솟아오르는 검 개수 +3<br>- 전직 스킬 공격 시 1회 충전 (사복검 - 발 제외)<br>- 자동 충전 불가능<br><br>전직 스킬의 후딜레이를 캔슬하여 사복검 - 발 시전 가능 (각성기 제외)<br><br>사복검 - 발 후딜레이를 캔슬하여 전직 스킬 시전 가능@@5928@@2016';

lv105ItemId['고귀한 신의'] = '476df057a837cdca0b902c8f3bf86677';
lv105OptionMap['고귀한 신의'] = {};
lv105OptionMap['고귀한 신의'] = '최종 데미지 8% 증가<br><br>[차원 개방]<br>[장비 옵션 조작키] 입력 시 2초 동안 차원 개방 (최대 2회)<br>- 차원 진입 시 무적<br>- 차원 소멸 시 10초 동안 모든 속도 +30%<br><br>HP MAX +30%<br>비 카운터 피격 시 물리/마법 피해 감소 +20%<br>물리/마법 방어력 +7,000<br>모든 상태 이상 내성 +15%@@6414@@1968';

lv105ItemId['결전의 소검 - 소울브링어'] = '735db1f81960b02f623d4e4fe80ee749';
lv105OptionMap['결전의 소검 - 소울브링어'] = {};
lv105OptionMap['결전의 소검 - 소울브링어'] = '명계의 힘으로 흑염의 칼라가 명계의 불꽃을 발현<br>불꽃 최대 중첩 수 +2<br><br>아래 스킬들이 명계의 불꽃을 발현<br>명계의 불꽃이 붙은 대상을 특정 스킬로 공격 시 불꽃 폭발<br>- 폭발 공격력 :  흑염의 칼라 불꽃 공격력의 300%<br>- 불꽃 중첩당 폭발 공격력 10% 증가<br><br><명계의 불꽃 발현 스킬><br>- 흑염의 칼라<br>- 냉기의 사야<br>- 역병의 라사<br>- 툼스톤<br>- 툼스톤 스웜프<br>- 블레이드 팬텀<br><br><명계의 불꽃 폭발 스킬><br>- 귀참<br>- 달빛 베기<br>- 귀참 : 광폭<br>- 귀영섬<br>- 귀참 : 나락<br>- 흑염검<br>- 검귀강령 : 식<br>- 탐식의 크레바스@@5928@@2016';

lv105ItemId['결전의 둔기 - 드래곤나이트'] = '39c51d3039dd2ff45f07c958af8a17a6';
lv105OptionMap['결전의 둔기 - 드래곤나이트'] = {};
lv105OptionMap['결전의 둔기 - 드래곤나이트'] = '태도 용제무쌍을 최대 2회 충전 스킬로 변경<br>- 공격력 40% 감소<br>- 충전 쿨타임 : 10초<br><br>태도 용제무쌍 시전 시 10초 동안 스킬 공격력 5% 증가 (각성기 제외)<br><br>태도 용제무쌍 시전 중 아스트라 스킬 시전 시 남은 쿨타임 10% 감소<br>- 쿨타임 감소 효과는 용제무쌍 1회당 1번만 적용<br><br>아래의 스킬 쿨타임 10% 감소<br>- 파이어 브레스<br>- 아스트라 소환<br>- 드래곤 팽<br>- 폭풍의 숨결<br>- 비룡천상@@5928@@2016';

lv105ItemId['결전의 리볼버 - 메카닉(여)'] = '24f233af3580404a498565154e8bd54d';
lv105OptionMap['결전의 리볼버 - 메카닉(여)'] = {};
lv105OptionMap['결전의 리볼버 - 메카닉(여)'] = 'G-S.P. 팔콘 쿨타임 20% 감소<br><br>G-1 코로나 상태에서 롤링썬더 또는 랩터로 트랜스폼 시 8초 동안 홀로그램 G-1 코로나 생성<br>- 공격력 : G-1 코로나 공격력의 50%<br>- G 마그네틱 시전 시 홀로그램 G-1 코로나가 대신 시전<br><br>트랜스폼 : G-3 랩터가 강화 레이저 발사<br>- 강화 레이저 발사@@8300@@2016';

lv105ItemId['결전의 대검 - 드래곤나이트'] = '29616411b897148672537df5d4ef2c46';
lv105OptionMap['결전의 대검 - 드래곤나이트'] = {};
lv105OptionMap['결전의 대검 - 드래곤나이트'] = '태도 용제무쌍을 최대 2회 충전 스킬로 변경<br>- 공격력 40% 감소<br>- 충전 쿨타임 : 10초<br><br>태도 용제무쌍 시전 시 10초 동안 스킬 공격력 5% 증가 (각성기 제외)<br><br>태도 용제무쌍 시전 중 아스트라 스킬 시전 시 남은 쿨타임 10% 감소<br>- 쿨타임 감소 효과는 용제무쌍 1회당 1번만 적용<br><br>아래의 스킬 쿨타임 10% 감소<br>- 파이어 브레스<br>- 아스트라 소환<br>- 드래곤 팽<br>- 폭풍의 숨결<br>- 비룡천상@@5928@@2016';

lv105ItemId['옥화의 망령 요갑'] = '1de39f4a48b3524cf768479c8f4c7b19';
lv105OptionMap['옥화의 망령 요갑'] = {};
lv105OptionMap['옥화의 망령 요갑'] = '최종 데미지 12% 증가<br><br>"와"를 포함한 채팅 입력 시 40초 동안 로봇 prototype-0 소환 (쿨타임 40초)<br><br>1초마다 HP/MP 1,000 회복<br><br>이동 속도 +8%<br>물리/마법 방어력 +7,000@@6414@@1948';

lv105ItemId['결전의 광검 - 다크나이트'] = '157f98722489ea9d279e08e7fa47df9b';
lv105OptionMap['결전의 광검 - 다크나이트'] = {};
lv105OptionMap['결전의 광검 - 다크나이트'] = '타임 스톱 최대 콤보 스택 1단계 증가<br>- 콤보 스택 6단계 공격력 : 220%<br><br>시간에 간섭하여 타임로드 효과 강화<br>- 다른 콤보 슬롯 스킬 사용 시 타임 스톱 스택 획득<br>- 확장 콤보 슬롯 스킬 시전 시 기존 콤보 스킬 유지<br>- 타임 로드 효과 지속 시간 +2초@@10672@@2016';

lv105ItemId['결전의 너클 - 스트라이커(여)'] = '2ff8c928b81beb13537e9ff6693637a1';
lv105OptionMap['결전의 너클 - 스트라이커(여)'] = {};
lv105OptionMap['결전의 너클 - 스트라이커(여)'] = '라이트닝 댄스 이동 횟수가 4회 감소하고 1:1 공격 시 대상의 좌우를 이동하며 공격<br>- 공격력 15% 감소<br>- 공격 속도 +30%<br>- 이동 범위 5% 증가<br>- 공격 시 적에게 감전 상태 이상 부여 (쿨타임 15초)<br><br>라이트닝 댄스 공격 시 충격파 생성<br>- 직접 타격 대상은 충격파 피격 제외<br>- 1:1 공격일 경우 충격파 미생성@@9484@@2016';

lv105ItemId['광폭화된 전의의 단검'] = '5fe4e4dba6975588ce4d13de87d0f18f';
lv105OptionMap['광폭화된 전의의 단검'] = {};
lv105OptionMap['광폭화된 전의의 단검'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['뜨거운 열망의 증표'] = '363127da64902fd62e8c5fb9595a196e';
lv105OptionMap['뜨거운 열망의 증표'] = {};
lv105OptionMap['뜨거운 열망의 증표'] = '최종 데미지 12% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>회피율 +8%@@3988@@1948';

lv105ItemId['결전의 도 - 버서커'] = '40a62a63d927e2d2dfe206834730b004';
lv105OptionMap['결전의 도 - 버서커'] = {};
lv105OptionMap['결전의 도 - 버서커'] = '피에 굶주려 갈증 시전 시 HP 10%를 소모하고 혈기 3개 획득 (최대 10스택)<br>- 갈증 충전 기능 삭제<br>- 다른 스킬 시전 중 갈증 시전 가능<br>- 던전 입장 시 혈기 1개 획득<br><br>블러드 소드, 격노 시전 시 혈기 1개를 소모하여 강화<br>- 공격력 30% 증가<br>- 스킬 범위 15% 증가<br><br>블러드 소드, 격노 시전 시 HP 5% 회복@@9484@@2016';

lv105ItemId['성장하는 모험가의 리볼버'] = 'e7b2174a268978c6dcf32b4d9ed6ff97';
lv105OptionMap['성장하는 모험가의 리볼버'] = {};
lv105OptionMap['성장하는 모험가의 리볼버'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 창 - 배틀메이지'] = '468f8dd1e00ee18ab307acb1f673bb6d';
lv105OptionMap['결전의 창 - 배틀메이지'] = {};
lv105OptionMap['결전의 창 - 배틀메이지'] = '체이서 공격력 및 사출 개수 증가<br>- 한 번에 사출되는 체이서 개수 +2<br>- 공격력 45% 감소<br>- 50콤보 이상일 때 체이서 자동 사출<br>- 체이서 최대 생성 개수 +1<br>- 체이서 크기 비율 20% 증가<br>- 스킬 MP 소모량 -70%<br><br>한 번에 생성되는 체이서 개수 +1@@4744@@2016';

lv105ItemId['광폭화된 전의의 도'] = '11bd2af6fdaf1ddd2de93ba3df1b81bd';
lv105OptionMap['광폭화된 전의의 도'] = {};
lv105OptionMap['광폭화된 전의의 도'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['내딛은 자의 배틀액스'] = '69d49ab7c461be614f16693bbf40a908';
lv105OptionMap['내딛은 자의 배틀액스'] = {};
lv105OptionMap['내딛은 자의 배틀액스'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['얼어붙은 저항의 토템'] = '4346828ef37a59de03e23acfb22bd12f';
lv105OptionMap['얼어붙은 저항의 토템'] = {};
lv105OptionMap['얼어붙은 저항의 토템'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 봉 - 엘레멘탈마스터'] = 'c487e8cb65a34e592a552c1c1ee8fb8f';
lv105OptionMap['결전의 봉 - 엘레멘탈마스터'] = {};
lv105OptionMap['결전의 봉 - 엘레멘탈마스터'] = '상급 원소 스킬 및 일부 최상급 원소 스킬 1개를 룬에 저장 후 해방<br>- [장비 옵션 조작키] 입력 후 스킬 사용 시 60초 동안 룬에 저장<br>- [장비 옵션 조작키] 재입력 시 저장된 스킬 해방 (쿨타임 0.1초)<br>- 해방 스킬 공격력 : 원본 스킬의 15%<br>- 저장 스킬 쿨타임 : 원본 스킬의 15%<br>- 최대 충전 상태로 저장<br><br>메모라이즈 캐스팅 속도 상승률 +15%<br><br><저장 가능 스킬><br>- 썬더 콜링<br>- 아크틱 피스트<br>- 나이트 할로우<br>- 핼로윈 버스터<br>- 엘레멘탈 커튼<br>- 엘레멘탈 퀘이크<br>- 초월의 크리스탈@@11264@@2016';

lv105ItemId['결전의 핸드캐넌 - 런처(남)'] = '1a99827c2a0cff7e1471aaadc5ff7bd9';
lv105OptionMap['결전의 핸드캐넌 - 런처(남)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 런처(남)'] = '레이저 라이플을 최대 3회 충전 스킬로 변경<br>- 공격력 20% 증가<br>- 크기 40% 증가<br>- 충전 쿨타임 : 7초<br>- 충전 레이저 라이플 충전 시간 50% 감소<br>- 적중 시 적에게 10초 동안 전자기장 부여<br><br>FM-92 스팅어 강화<br>- 공격력 15% 증가<br>- 폭발 범위 25% 증가<br>- 전자기장이 부여된 적 존재 시 대상을 추적하는 강화 고폭탄 발사@@8892@@2016';

lv105ItemId['내딛은 자의 스태프'] = '73a28ec611b186727d40ebd3afb76ec7';
lv105OptionMap['내딛은 자의 스태프'] = {};
lv105OptionMap['내딛은 자의 스태프'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['사멸하는 신뢰의 토템'] = '0a5314a47ad830cf5ad27e8a72ec7824';
lv105OptionMap['사멸하는 신뢰의 토템'] = {};
lv105OptionMap['사멸하는 신뢰의 토템'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['운명이 이끄는 방향'] = '5fd5a123d59b3959ff6e427a68124e91';
lv105OptionMap['운명이 이끄는 방향'] = {};
lv105OptionMap['운명이 이끄는 방향'] = '최종 데미지 9% 증가<br>모든 속성 강화 +20<br><br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +10%<br>적중률 +10%@@6318@@1943';

lv105ItemId['결전의 소검 - 다크나이트'] = 'b8017b856700f06e157c70f2b631a655';
lv105OptionMap['결전의 소검 - 다크나이트'] = {};
lv105OptionMap['결전의 소검 - 다크나이트'] = '타임 스톱 최대 콤보 스택 1단계 증가<br>- 콤보 스택 6단계 공격력 : 220%<br><br>시간에 간섭하여 타임로드 효과 강화<br>- 다른 콤보 슬롯 스킬 사용 시 타임 스톱 스택 획득<br>- 확장 콤보 슬롯 스킬 시전 시 기존 콤보 스킬 유지<br>- 타임 로드 효과 지속 시간 +2초@@10672@@2016';

lv105ItemId['여명의 성배'] = '4f7aa31ae87f542fdc8570c3c1bd9077';
lv105OptionMap['여명의 성배'] = {};
lv105OptionMap['여명의 성배'] = '커맨드로 스킬 시전 시 입력한 방향키의 개수에 따라 최종 데미지 증가 (각성기 제외)<br>- 방향키 1개 : 최종 데미지 7% 증가<br>- 방향키 2개 : 최종 데미지 9% 증가<br>- 방향키 3개 : 최종 데미지 11% 증가<br>- 방향키 4개 : 최종 데미지 15% 증가<br><br>공격 속도 +15%<br>캐스팅 속도 +15%<br>이동 속도 +15%<br>적중률 +10%<br>회피율 +8%@@5066@@1928';

lv105ItemId['결전의 미늘창 - 뱅가드'] = 'd11a805f7e437d61f3f9a836ca21f99a';
lv105OptionMap['결전의 미늘창 - 뱅가드'] = {};
lv105OptionMap['결전의 미늘창 - 뱅가드'] = '스매쉬의 극한에 도달하여 임팩트 스매쉬 강화<br>- 임팩트 스매쉬를 캔슬하여 스킬 시전 시 해당 스킬 쿨타임 20% 감소 (각성기 제외)<br>- 스택 횟수 +1<br>- 적중 시 스택 회복 감소 시간 +0.3초<br>- 공격 속도 버프 추가량 +10%@@5928@@2016';

lv105ItemId['결전의 도 - 엘븐나이트'] = 'bc80f5597f9fb96abdb75d933e8fadc8';
lv105OptionMap['결전의 도 - 엘븐나이트'] = {};
lv105OptionMap['결전의 도 - 엘븐나이트'] = '체인러쉬 콤보 6중첩 상태에서 체인 스트라이크 시전 시 8초 동안 피버 타임<br>- 체인러쉬 공격력 500% 증가<br>- 체인 게이지의 모든 영역을 성공 영역으로 변경<br>- 1~30Lv 스킬 쿨타임 70% 감소<br>- 1~30Lv 스킬 공격력 50% 감소 (체인러쉬 제외)<br><br>체인러쉬 시전 시 1~30Lv 스킬 남은 쿨타임 10% 감소@@5928@@2016';

lv105ItemId['오염된 빙석 견갑'] = '0288215d10faa4a1f3797cc2dc7b7bc3';
lv105OptionMap['오염된 빙석 견갑'] = {};
lv105OptionMap['오염된 빙석 견갑'] = '최종 데미지 2% 증가<br>중독 피해 +20%<br><br>중독 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>자신에게 해제되지 않는 중독 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +10%<br>공격 속도 +5%<br>캐스팅 속도 +7.5%<br>HP 1분당 460.2 회복@@6682@@1968';

lv105ItemId['엑셀러레이터'] = '0e33a738601501eeb46ec7dbe539e84f';
lv105OptionMap['엑셀러레이터'] = {};
lv105OptionMap['엑셀러레이터'] = '최종 데미지 11% 증가<br>스킬 쿨타임 15% 감소 (각성기 제외)<br><br>공격 시 MP 3,500 회복 (쿨타임 1초)<br><br>MP MAX +4,196<br>MP 1분당 348 회복@@4917@@1943';

lv105ItemId['로보티카 컴뱃 팬츠'] = '0106839ef87aba76428ec574c78b167c';
lv105OptionMap['로보티카 컴뱃 팬츠'] = {};
lv105OptionMap['로보티카 컴뱃 팬츠'] = '모든 속성 강화 +18<br><br>장착한 장비의 강화, 증폭 수치의 총합 6마다 아래의 효과 적용 (최대 24중첩)<br>- 최종 데미지 0.4% 증가<br>- 적용 : 무기, 방어구, 악세서리, 특수장비<br>- 제외 : 휘장, 보조무기<br><br>해당 장비의 강화, 증폭 수치 1마다 모든 속도 +2% (최대 12중첩)<br><br>물리/마법 방어력 +7,000@@3988@@1948';

lv105ItemId['순환하는 자연의 섭리'] = '04e57598f0ca64f8fd687e528c8718d5';
lv105OptionMap['순환하는 자연의 섭리'] = {};
lv105OptionMap['순환하는 자연의 섭리'] = '공격력 증가 +133.4%<br>최종 데미지 2% 증가<br>암속성 강화 +20<br><br>[다크 포스]<br>공격 시 암속성 강화 수치에 따라 암속성 원소 공격 시전 (쿨타임 1.5초)<br>- 100 이상 150 미만 : 다크 익스플로전<br>- 150 이상 250 미만 : 다크 볼<br>- 250 이상 : 블랙홀<br>- 각각 총 공격력 증가의 720% / 920% / 1,480%만큼 피해<br>- 블랙홀은 적에게 10초 동안 암흑 상태 이상 부여 (쿨타임 30초)<br><br>암흑 지속 시간 +3초<br><br>공격 속도 +10%<br>캐스팅 속도 +15%<br>암속성 저항 +20@@6414@@1978';

lv105ItemId['생명이 담긴 잔'] = '3a2a4a8b1ab39b690ce28d875198e3b9';
lv105OptionMap['생명이 담긴 잔'] = {};
lv105OptionMap['생명이 담긴 잔'] = '최종 데미지 7% 증가<br>모든 속성 강화 +18<br><br>물리/마법 피해의 30%가 10초 동안 지속되는 피해로 전환<br><br>이동 속도 +8%@@5874@@1900';

lv105ItemId['결전의 너클 - 스트라이커(남)'] = '925b44e9984d42f3e8423c382f51d71b';
lv105OptionMap['결전의 너클 - 스트라이커(남)'] = {};
lv105OptionMap['결전의 너클 - 스트라이커(남)'] = '이중개방의 효과가 10초 동안 유지되는 맹렬한 화염으로 변경되며 최종 데미지 45% 증가 (각성기 제외)<br>- 이중개방 강화 공격력 효과 미적용<br><br>화염의 힘으로 머슬시프트 강화<br>- 캔슬 공격 시 이중개방 남은 쿨타임 2% 감소<br>- 캔슬 시 30초 동안 전직 계열 스킬 공격력 증가율 +2%<br>- 캔슬 횟수 +2@@7112@@2016';

lv105ItemId['기계 공학의 정수'] = 'bd078bcf3483881e156487c87f203cf8';
lv105OptionMap['기계 공학의 정수'] = {};
lv105OptionMap['기계 공학의 정수'] = '최종 데미지 13% 증가<br>火(화), 水(수), 明(명), 暗(암) 공격 속성<br><br>HP MAX +600<br>MP MAX +945@@4528@@1928';

lv105ItemId['불변의 부유석 벨트'] = '04840f0d826d355a48114c2abc02f4b8';
lv105OptionMap['불변의 부유석 벨트'] = {};
lv105OptionMap['불변의 부유석 벨트'] = '스킬 쿨타임 회복 속도 +25% (각성기 제외)<br>모든 속성 강화 +10<br><br>중독 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>자신에게 해제되지 않는 중독 상태 이상 부여<br><br>공격 시 적에게 10초 동안 중독 상태 이상 부여 (쿨타임 5초)<br><br>물리/마법 크리티컬 히트 +7%<br>모든 상태 이상 내성 -10%@@6318@@1925';

lv105ItemId['사멸하는 신뢰의 로드'] = '426aed8519e9d7432d793526a234b323';
lv105OptionMap['사멸하는 신뢰의 로드'] = {};
lv105OptionMap['사멸하는 신뢰의 로드'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['전술 레이더망 링'] = '713ad2ea1d4741fc6ec2244d3f04c498';
lv105OptionMap['전술 레이더망 링'] = {};
lv105OptionMap['전술 레이더망 링'] = '스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>[전술적 초기화]<br>스킬 시전 시 10% 확률로 스킬 1개의 쿨타임 초기화 (쿨타임 20초)<br>- 남은 쿨타임 20초 이내 스킬 대상<br>- 각성기 제외<br><br>MP 1분당 348 회복<br>MP MAX +945@@3450@@1890';

lv105ItemId['결전의 창 - 엘레멘탈마스터'] = '32a01e757b779723f617ca28f8b1f78d';
lv105OptionMap['결전의 창 - 엘레멘탈마스터'] = {};
lv105OptionMap['결전의 창 - 엘레멘탈마스터'] = '상급 원소 스킬 및 일부 최상급 원소 스킬 1개를 룬에 저장 후 해방<br>- [장비 옵션 조작키] 입력 후 스킬 사용 시 60초 동안 룬에 저장<br>- [장비 옵션 조작키] 재입력 시 저장된 스킬 해방 (쿨타임 0.1초)<br>- 해방 스킬 공격력 : 원본 스킬의 15%<br>- 저장 스킬 쿨타임 : 원본 스킬의 15%<br>- 최대 충전 상태로 저장<br><br>메모라이즈 캐스팅 속도 상승률 +15%<br><br><저장 가능 스킬><br>- 썬더 콜링<br>- 아크틱 피스트<br>- 나이트 할로우<br>- 핼로윈 버스터<br>- 엘레멘탈 커튼<br>- 엘레멘탈 퀘이크<br>- 초월의 크리스탈@@11264@@2016';

lv105ItemId['결전의 중검 - 스페셜리스트'] = '907f2b190f961b18baa18d3856a86f2f';
lv105OptionMap['결전의 중검 - 스페셜리스트'] = {};
lv105OptionMap['결전의 중검 - 스페셜리스트'] = '인텐션 스택 사용 시 인텐션 스택 1개를 추가 소모하여 5초 동안 스킬 쿨타임 20% 감소 (각성기 제외)<br><br>CEAB-2를 인텐션 스택으로 강화 가능<br>- 강화 시 최대 충전 공격력 40% 증가<br>- 강화 시 다단히트 수 60% 충전 상태로 발동<br><br>인텐션 기본 스택 수 +2@@7112@@2016';

lv105ItemId['결전의 십자가 - 무녀'] = 'dbb83e8054536635100ea97c936ab59a';
lv105OptionMap['결전의 십자가 - 무녀'] = {};
lv105OptionMap['결전의 십자가 - 무녀'] = '염주 사출, 목환자경 타격 1회당 신력 염주알 1개 획득 (최대 25개)<br><br>아래 스킬 시전 시 신력 염주알을 소모하여 강화<br>- 부동주박진 : 염주 회전 수 1회 증가 (8개 소모)<br>- 백팔염주 : 염주 사출 개수 10개 증가 (10개 소모)<br>- 마혼부 : 퇴마음양옥 : 튕기는 횟수 3회 증가 (10개 소모)<br>- 염폭모대주 : 염주알 낙하 수 1 증가 (15개 소모)<br><br>염주 사출, 목환자경 시전 1회당 백팔염주, 부동주박진 스킬 쿨타임 회복 속도 +1%<br>- 백팔염주, 부동주박진 시전 시 효과 초기화@@4148@@2016';

lv105ItemId['찰나를 스치는 흐름'] = 'd3b603c289e9e4dfcfe1b4da7ce9b4ab';
lv105OptionMap['찰나를 스치는 흐름'] = {};
lv105OptionMap['찰나를 스치는 흐름'] = '최종 데미지 19% 증가<br><br>스킬 시전 시 2초 동안 HP MAX 15% 수치의 보호막 부여 (쿨타임 0.5초)<br><br>이동 속도 +8%<br>회피율 +8%@@5604@@1948';

lv105ItemId['사멸하는 신뢰의 통파'] = '8b177f87e19dd77e07f5f718df036683';
lv105OptionMap['사멸하는 신뢰의 통파'] = {};
lv105OptionMap['사멸하는 신뢰의 통파'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['내딛은 자의 창'] = '3bb2cc2b8f1e91174a050cf7a74d6266';
lv105OptionMap['내딛은 자의 창'] = {};
lv105OptionMap['내딛은 자의 창'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['잿빛의 묘상석'] = '52c46a5e0c405db41e025cbd575f0866';
lv105OptionMap['잿빛의 묘상석'] = {};
lv105OptionMap['잿빛의 묘상석'] = '15~35Lv 스킬 공격력 25% 증가<br>15~35Lv 스킬 쿨타임 회복 속도 +10%<br><br>공격 시 30초 동안 슈퍼 아머 부여 (쿨타임 8초)<br><br>공격 속도 +8%<br>캐스팅 속도 +12%@@4676@@1933';

lv105ItemId['내딛은 자의 대검'] = 'a0f1b0185e2de0a730876a2e4b30efc4';
lv105OptionMap['내딛은 자의 대검'] = {};
lv105OptionMap['내딛은 자의 대검'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['기억으로 새겨진 별자리'] = '12cfb54a17c730a0a6cd82ceb8cb8774';
lv105OptionMap['기억으로 새겨진 별자리'] = {};
lv105OptionMap['기억으로 새겨진 별자리'] = '최종 데미지 16% 증가<br><br>회피율 +8%<br>MP 1분당 348 회복@@3450@@1948';

lv105ItemId['하늘에 휘날리는 깃털'] = '50f6813155b213d33ce36306c911b34f';
lv105OptionMap['하늘에 휘날리는 깃털'] = {};
lv105OptionMap['하늘에 휘날리는 깃털'] = '최종 데미지 11.3% 증가<br><br>[혈관 파열]<br>보스, 네임드, 챔피언 몬스터 공격 시 남은 HP 1% 감소<br>- 10, 50, 250, 1,250, 6,250회 공격 시 발동<br>- 버퍼 제외<br><br>공격 시 적에게 출혈 상태 이상 부여 (지속 10초 / 쿨타임 3초)<br><br>공격 속도 +15%<br>캐스팅 속도 +22.5%<br>이동 속도 +15%<br>출혈 내성 +21%@@3935@@1963';

lv105ItemId['멈추어진 지식'] = 'ff8dc0de507cda263fe533e95d30d29b';
lv105OptionMap['멈추어진 지식'] = {};
lv105OptionMap['멈추어진 지식'] = '최종 데미지 7% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +35%<br>캐스팅 속도 +35%<br>이동 속도 +35%<br>HP MAX +5%@@5336@@1938';

lv105ItemId['내딛은 자의 광검'] = '417e31d6a9cf9bd48d0cd42a6e4aadde';
lv105OptionMap['내딛은 자의 광검'] = {};
lv105OptionMap['내딛은 자의 광검'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['드래곤 헌터'] = '12498edcb8dbab9229c0024410b59fb2';
lv105OptionMap['드래곤 헌터'] = {};
lv105OptionMap['드래곤 헌터'] = '최종 데미지 11% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 속성 저항 +15@@5874@@1928';

lv105ItemId['결전의 빗자루 - 크리에이터'] = '576189d2e238c4753f28837461c46af2';
lv105OptionMap['결전의 빗자루 - 크리에이터'] = {};
lv105OptionMap['결전의 빗자루 - 크리에이터'] = '웜홀 시전 시 종말의 시계를 소환하여 격리된 시간 영역을 전개<br>영역은 5초 후 또는 스킬 키 추가 입력 시 붕괴되며 폭발<br>종말의 시계를 화염, 냉기, 바람 계열 스킬로 2히트 시 폭발 공격력 증가<br>- 폭발 데미지 : 웜홀 공격력 100%<br>- 2히트당 폭발 공격력 5% 증가 (최대 50%)<br><br>윈드 스톰 강화<br>- 끌어들이는 범위 30% 증가<br>- 게이지 소모량 35% 감소<br><br>던전에서 무기 장착 시 윈드 스톰 게이지 0부터 회복 시작@@8300@@2016';

lv105ItemId['머신 컨트롤러 리모트'] = '18b5de1ae3b5c7bb16b0ffd6191488e6';
lv105OptionMap['머신 컨트롤러 리모트'] = {};
lv105OptionMap['머신 컨트롤러 리모트'] = '최종 데미지 8% 증가<br>모든 속성 강화 +20<br><br>공격 시 MP 0.1% 회복 (쿨타임 0.1초)<br>적 처치 시 MP 10% 회복<br><br>스킬 MP 소모량 +50%<br><br>공격 속도 +8%<br>캐스팅 속도 +12%@@5510@@1963';

lv105ItemId['결전의 자동권총 - 어썰트'] = '2143340e4c8b7f7a02daa311e542ac1f';
lv105OptionMap['결전의 자동권총 - 어썰트'] = {};
lv105OptionMap['결전의 자동권총 - 어썰트'] = '부스트 스킬로 전직 스킬 캔슬 시 전력 1스택 획득 (최대 5스택)<br><br>아래 스킬 시전 시 모든 전력을 소모하고, 소모한 전력당 공격력 10% 증가<br>- 레이시온 크리트<br>- 부스트 : 다이너마이트 킥<br><br>부스트 : 다이너마이트 킥 적중 시 적을 1.5초 동안 홀딩<br>- 소모한 전력 1스택당 홀딩 시간 0.5초 증가@@9484@@2016';

lv105ItemId['천재 기술자의 두터운 보호부츠'] = '3458db2479c54055db35b1ac96d8c038';
lv105OptionMap['천재 기술자의 두터운 보호부츠'] = {};
lv105OptionMap['천재 기술자의 두터운 보호부츠'] = '공격력 증가 +326.0%<br>모든 속성 강화 +15<br><br>스킬 MP 소모량 옵션 수치 총합의 5%만큼 최종 데미지 증가 (최대 25%)<br><br>스킬 MP 소모량 +100%<br><br>이동 속도 +8%<br>MP MAX +945<br>물리/마법 피해 감소 +20%@@2910@@1968';

lv105ItemId['얼어붙은 저항의 너클'] = '749303a7afac95b807efc7f156776006';
lv105OptionMap['얼어붙은 저항의 너클'] = {};
lv105OptionMap['얼어붙은 저항의 너클'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['광폭화된 전의의 완드'] = '1e51692d272690bb2ff600fe2d2fc055';
lv105OptionMap['광폭화된 전의의 완드'] = {};
lv105OptionMap['광폭화된 전의의 완드'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['광폭화된 전의의 창'] = 'dd9c91d5fdef18b3a7d44c97165b27f3';
lv105OptionMap['광폭화된 전의의 창'] = {};
lv105OptionMap['광폭화된 전의의 창'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['얼어붙은 저항의 장창'] = '2990114431703591f3de76b8e2588a5d';
lv105OptionMap['얼어붙은 저항의 장창'] = {};
lv105OptionMap['얼어붙은 저항의 장창'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['상처를 동여맨 다짐'] = '1ffdd960fc9489800ae1248e3be38927';
lv105OptionMap['상처를 동여맨 다짐'] = {};
lv105OptionMap['상처를 동여맨 다짐'] = '최종 데미지 12% 증가<br>모든 속성 강화 +6<br><br>공격 속도 +6%<br>캐스팅 속도 +6%<br>이동 속도 +6%<br>HP MAX +600<br>MP MAX +945@@6952@@1900';

lv105ItemId['지상형 : 전술 차륜 드론'] = '55c669c5fb7a64523cd22273e1e1581a';
lv105OptionMap['지상형 : 전술 차륜 드론'] = {};
lv105OptionMap['지상형 : 전술 차륜 드론'] = '최종 데미지 13% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +10%<br>캐스팅 속도 +10%<br>이동 속도 +10%<br>적중률 +10%@@5066@@1948';

lv105ItemId['성장하는 모험가의 너클'] = 'bbdd2abada69f82ed1347b63998fab3e';
lv105OptionMap['성장하는 모험가의 너클'] = {};
lv105OptionMap['성장하는 모험가의 너클'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 장도 - 트러블 슈터'] = '221006deed81b0bfff47a40d10f899c3';
lv105OptionMap['결전의 장도 - 트러블 슈터'] = {};
lv105OptionMap['결전의 장도 - 트러블 슈터'] = '번 투 슬래쉬 시전 시 3초 동안 전직 스킬 공격력 20% 증가 (각성기 제외)<br>- 후딜레이를 캔슬하여 다른 전직 스킬 사용 가능<br>- 다른 전직 스킬의 후딜레이를 캔슬하여 번 투 슬래쉬 사용 가능<br>- 추가 공격 입력 시간 3초로 증가<br>- 스킬 종료 후 쿨타임 적용<br><br>번 투 슬래쉬가 타격하지 않아도 폭발 발생<br>- 스킬 쿨타임 35% 감소<br>- 스킬 범위 10% 증가@@652@@2016';

lv105ItemId['결전의 로드 - 엘레멘탈마스터'] = '26a82288632c9a13f6a906bddf5d98b9';
lv105OptionMap['결전의 로드 - 엘레멘탈마스터'] = {};
lv105OptionMap['결전의 로드 - 엘레멘탈마스터'] = '상급 원소 스킬 및 일부 최상급 원소 스킬 1개를 룬에 저장 후 해방<br>- [장비 옵션 조작키] 입력 후 스킬 사용 시 60초 동안 룬에 저장<br>- [장비 옵션 조작키] 재입력 시 저장된 스킬 해방 (쿨타임 0.1초)<br>- 해방 스킬 공격력 : 원본 스킬의 15%<br>- 저장 스킬 쿨타임 : 원본 스킬의 15%<br>- 최대 충전 상태로 저장<br><br>메모라이즈 캐스팅 속도 상승률 +15%<br><br><저장 가능 스킬><br>- 썬더 콜링<br>- 아크틱 피스트<br>- 나이트 할로우<br>- 핼로윈 버스터<br>- 엘레멘탈 커튼<br>- 엘레멘탈 퀘이크<br>- 초월의 크리스탈@@11264@@2016';

lv105ItemId['성장하는 모험가의 흑요석 귀걸이'] = '2715a57f2f632c8c728ce1597fa7eafc';
lv105OptionMap['성장하는 모험가의 흑요석 귀걸이'] = {};
lv105OptionMap['성장하는 모험가의 흑요석 귀걸이'] = '최종 데미지 7% 증가@@5336@@1986';

lv105ItemId['파워 네비게이트 팩'] = '50818a08dc0a6f7c055fccb685617515';
lv105OptionMap['파워 네비게이트 팩'] = {};
lv105OptionMap['파워 네비게이트 팩'] = '공격력 증가 +385.3%<br>최종 데미지 9% 증가<br>스킬 쿨타임 15% 감소 (각성기 제외)<br><br>HP MAX 65% 수치의 [충전형 보호막] 부여<br><br>HP MAX 65% 제한<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>물리/마법 방어력 +14,000<br>모든 속성 저항 +8@@5066@@1968';

lv105ItemId['성장하는 모험가의 건틀릿'] = '047b973f8dcb11f086b05b69728321be';
lv105OptionMap['성장하는 모험가의 건틀릿'] = {};
lv105OptionMap['성장하는 모험가의 건틀릿'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 토템 - 인파이터'] = '000e812409655055955746677a1d2fcd';
lv105OptionMap['결전의 토템 - 인파이터'] = {};
lv105OptionMap['결전의 토템 - 인파이터'] = '정의 집행 : 레미디오스의 성좌의 링크 스킬이 빅뱅 펀치일 때, 신의 분노를 최대 3회 충전 스킬로 변경<br>- 연타 공격력 100% 증가<br>- 연타 횟수 9회 감소<br>- 피니쉬 타격 및 폭발 삭제<br>- 적을 추적하여 공격<br>- 충전 쿨타임 : 40초<br>- 추적 범위 : 780px<br>- MP 소모량 -30%<br>- 무색 큐브 조각 소모량 5개 감소<br>- 머신건 잽 시전 시 신의 분노 남은 쿨타임 12% 감소<br>- 신의 분노를 캔슬하여 전직 스킬 시전 가능<br>- 전직 스킬을 캔슬하여 신의 분노 시전 시 드라이아웃 스택을 소모하지 않음@@5928@@2016';

lv105ItemId['결전의 봉 - 빙결사'] = '1f74d0daff3085ab774780c5efb20a40';
lv105OptionMap['결전의 봉 - 빙결사'] = {};
lv105OptionMap['결전의 봉 - 빙결사'] = '아이스 크래프트 얼음창이 얼음 무기에 피격된 모든 적을 추적하여 꿰뚫음<br>- 얼음창 공격력 반영률 20% 추가 증가<br>- 얼음창 폭발 삭제<br>- 얼음창이 10% 확률로 적에게 2초 동안 빙결 상태 이상 부여<br><br>핀포인트 러쉬 시전 시 전방의 가장 강력한 적을 추적하여 주변의 적을 함께 공격<br>- 추적 범위 : 500px@@7112@@2016';

lv105ItemId['성장하는 모험가의 로드'] = 'ee332d1a4bde4105ec33851043b3877c';
lv105OptionMap['성장하는 모험가의 로드'] = {};
lv105OptionMap['성장하는 모험가의 로드'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['평화를 위한 투쟁'] = '5e082f686e62f05d6630303ddc92d955';
lv105OptionMap['평화를 위한 투쟁'] = {};
lv105OptionMap['평화를 위한 투쟁'] = '최종 데미지 12% 증가<br><br>공격 속도 +20%<br>캐스팅 속도 +30%<br>이동 속도 +20%<br>HP MAX +600<br>물리/마법 방어력 +7,000@@5604@@1920';

lv105ItemId['사멸하는 신뢰의 너클'] = '19d4b7857d2908823d798d051b3d9d82';
lv105OptionMap['사멸하는 신뢰의 너클'] = {};
lv105OptionMap['사멸하는 신뢰의 너클'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 로드 - 소환사'] = '51a12f046b61e2ba06dabc8c98b88aa6';
lv105OptionMap['결전의 로드 - 소환사'] = {};
lv105OptionMap['결전의 로드 - 소환사'] = '상급 정령들과 정령왕 에체베리아의 이클립스 하이브 추가 조작 패턴 공격력 20% 증가<br><br>이클립스 하이브 강화<br>- 추가 조작 패턴 캐스팅 모션 삭제 <br>- 데드 멀커 : 블랙스웜 홀딩 추가<br>- 글레어린 : 썬더콜링이 적을 끌어모음<br>- 아퀘리스 : 헵타 아이스 미사일이 지상 폭격<br>- 플레임 헐크 : 화염폭검술이 회오리를 생성하고 적을 끌어모음<br>- 에체베리아 : 초고밀도 레이저가 다수 레이저의 지상 폭격으로 변경@@8300@@2016';

lv105ItemId['디보티드 나이트 벨트'] = '144e7c14cac2b62a43f4ff4052400607';
lv105OptionMap['디보티드 나이트 벨트'] = {};
lv105OptionMap['디보티드 나이트 벨트'] = '최종 데미지 11% 증가<br>모든 속성 강화 +18<br><br>물리/마법 방어력 +7,000@@5066@@1928';

lv105ItemId['침식되는 이성'] = '2766b493412bd6d14191ee3471c3a8e3';
lv105OptionMap['침식되는 이성'] = {};
lv105OptionMap['침식되는 이성'] = '화속성 강화 +70<br><br>화속성 저항 +20@@3450@@1958';

lv105ItemId['결전의 광검 - 웨펀마스터'] = '09e0ada505d32faf730ba8af86fc083e';
lv105OptionMap['결전의 광검 - 웨펀마스터'] = {};
lv105OptionMap['결전의 광검 - 웨펀마스터'] = '초식을 발전시켜 이기어검술 스킬 강화<br>- 지속 시간 +20초<br>- 기본기 숙련에 영향받는 스킬 적중 시 지속 시간 +0.5초 (최대 지속 시간 초과 불가능)<br>- 꿰뚫는검 사출 개수 +1<br>- 꿰뚫는검 사출 회수 시간 감소<br><br>장착 해제 시 이기어검술 지속 시간 종료@@8892@@2016';

lv105ItemId['결전의 통파 - 스트라이커(남)'] = '6e42ba6ebf9822bd180816d9cf7212c6';
lv105OptionMap['결전의 통파 - 스트라이커(남)'] = {};
lv105OptionMap['결전의 통파 - 스트라이커(남)'] = '이중개방의 효과가 10초 동안 유지되는 맹렬한 화염으로 변경되며 최종 데미지 45% 증가 (각성기 제외)<br>- 이중개방 강화 공격력 효과 미적용<br><br>화염의 힘으로 머슬시프트 강화<br>- 캔슬 공격 시 이중개방 남은 쿨타임 2% 감소<br>- 캔슬 시 30초 동안 전직 계열 스킬 공격력 증가율 +2%<br>- 캔슬 횟수 +2@@7112@@2016';

lv105ItemId['결전의 둔기 - 소울브링어'] = '90e8048f28ebf193250959ba2a13fb42';
lv105OptionMap['결전의 둔기 - 소울브링어'] = {};
lv105OptionMap['결전의 둔기 - 소울브링어'] = '명계의 힘으로 흑염의 칼라가 명계의 불꽃을 발현<br>불꽃 최대 중첩 수 +2<br><br>아래 스킬들이 명계의 불꽃을 발현<br>명계의 불꽃이 붙은 대상을 특정 스킬로 공격 시 불꽃 폭발<br>- 폭발 공격력 :  흑염의 칼라 불꽃 공격력의 300%<br>- 불꽃 중첩당 폭발 공격력 10% 증가<br><br><명계의 불꽃 발현 스킬><br>- 흑염의 칼라<br>- 냉기의 사야<br>- 역병의 라사<br>- 툼스톤<br>- 툼스톤 스웜프<br>- 블레이드 팬텀<br><br><명계의 불꽃 폭발 스킬><br>- 귀참<br>- 달빛 베기<br>- 귀참 : 광폭<br>- 귀영섬<br>- 귀참 : 나락<br>- 흑염검<br>- 검귀강령 : 식<br>- 탐식의 크레바스@@5928@@2016';

lv105ItemId['데저트 테크놀로지 팬츠'] = '0f77f44b38735de095fd831f1355ae72';
lv105OptionMap['데저트 테크놀로지 팬츠'] = {};
lv105OptionMap['데저트 테크놀로지 팬츠'] = '화속성 강화 +60<br><br>공격 시 5초 동안 불씨 1개 생성 (쿨타임 0.1초)<br>- 불씨 5개마다 폭발 발생<br>- 불씨는 총 공격력 증가의 96%만큼 피해<br><br>화속성 저항 +35@@4528@@1928';

lv105ItemId['하이테크 전술지휘 아머'] = '1bfbafce29f0cd7176651f550db99488';
lv105OptionMap['하이테크 전술지휘 아머'] = {};
lv105OptionMap['하이테크 전술지휘 아머'] = '최종 데미지 9% 증가<br><br>[텔레포트]<br>[장비 옵션 조작키] 입력 시 텔레포트 (쿨타임 20초)<br>- 방향키 입력으로 방향 조절 가능<br>- 텔레포트 후 30초 동안 이동 속도 +30%<br><br>물리/마법 방어력 +7,000<br>회피율 +8%@@6952@@1968';

lv105ItemId['냉정한 지략가의 상의'] = '11565e1c31918e8923f4e28155fd460c';
lv105OptionMap['냉정한 지략가의 상의'] = {};
lv105OptionMap['냉정한 지략가의 상의'] = '최종 데미지 3% 증가<br>스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>적중률 +10%@@4796@@1890';

lv105ItemId['결전의 자동권총 - 런처(여)'] = '4b92971b1254bb165b7af589fe3069b7';
lv105OptionMap['결전의 자동권총 - 런처(여)'] = {};
lv105OptionMap['결전의 자동권총 - 런처(여)'] = '화염 강타를 화염을 압축하여 발사하는 최대 3회 충전 스킬로 변경<br>- 공격력 : 총 공격력의 50%<br>- 충전 쿨타임 : 4초<br><br>M-3 화염방사기 시전 시 화염을 압축하여 발사<br><br>UHT-03 플레게톤 이동 속도 비율 +10%@@9484@@2016';

lv105ItemId['결전의 머스켓 - 레인저(여)'] = '2fec7c9a99d899e284bceab13ff01cbd';
lv105OptionMap['결전의 머스켓 - 레인저(여)'] = {};
lv105OptionMap['결전의 머스켓 - 레인저(여)'] = '이동사격 탄환을 파열시켜 더욱 넓은 범위 공격<br>- 공격력 30% 증가<br>- 발사 수 50% 증가<br>- 쿨타임 20% 감소<br>- 공격 범위 10% 증가<br><br>이동사격 중 아래의 효과 적용<br>- 스킬 시전 시 쿨타임 즉시 적용<br>- 공격 속도 +30%<br>- 이동 속도 +30%<br>- 던전 방 이동 시 스킬 유지@@7708@@2016';

lv105ItemId['광폭화된 전의의 배틀액스'] = 'ac9c61a97c420c6f1e47c5e86811c351';
lv105OptionMap['광폭화된 전의의 배틀액스'] = {};
lv105OptionMap['광폭화된 전의의 배틀액스'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['드래곤 패스파인더'] = '2221336bfa1aa8af207b82aec4ee3a40';
lv105OptionMap['드래곤 패스파인더'] = {};
lv105OptionMap['드래곤 패스파인더'] = '최종 데미지 17% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%<br>모든 속성 저항 +15@@5874@@1928';

lv105ItemId['광폭화된 전의의 보우건'] = '369b843da71e9f7338daa7fd295788b7';
lv105OptionMap['광폭화된 전의의 보우건'] = {};
lv105OptionMap['광폭화된 전의의 보우건'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['굳건한 믿음'] = '0df27e01e5151bc1d62185ff804a5bf8';
lv105OptionMap['굳건한 믿음'] = {};
lv105OptionMap['굳건한 믿음'] = '수속성 강화 +70<br><br>수속성 저항 +20@@3450@@1958';

lv105ItemId['결전의 낫 - 퇴마사'] = '1844fa3f66460e65dcb532740d53e661';
lv105OptionMap['결전의 낫 - 퇴마사'] = {};
lv105OptionMap['결전의 낫 - 퇴마사'] = '공의 식신 - 백호 스킬 공격력 20% 증가<br>공의 식신 - 백호 스킬 범위 20% 증가<br><br>난격의 매 타격이 충격파 생성<br>- 쿨타임 20% 감소<br>- 난격 횟수 100% 증가<br>- 막타 삭제<br>- 막타 공격력은 난격 공격력에 합산<br><br>무쌍격 범위 20% 증가<br><br>대시 중 무쌍격 시전 시 전방으로 돌진하며 시전<br><br>공의 식신 - 백호 구슬 크기 비율 50% 증가@@4148@@2016';

lv105ItemId['결전의 둔기 - 웨펀마스터'] = 'c74f3067c5168ecb28ab2c755cdb3171';
lv105OptionMap['결전의 둔기 - 웨펀마스터'] = {};
lv105OptionMap['결전의 둔기 - 웨펀마스터'] = '초식을 발전시켜 이기어검술 스킬 강화<br>- 지속 시간 +20초<br>- 기본기 숙련에 영향받는 스킬 적중 시 지속 시간 +0.5초 (최대 지속 시간 초과 불가능)<br>- 꿰뚫는검 사출 개수 +1<br>- 꿰뚫는검 사출 회수 시간 감소<br><br>장착 해제 시 이기어검술 지속 시간 종료@@8892@@2016';

lv105ItemId['얼어붙은 저항의 차크라 웨펀'] = '25049e5f6710ca788e9bfe80de96243d';
lv105OptionMap['얼어붙은 저항의 차크라 웨펀'] = {};
lv105OptionMap['얼어붙은 저항의 차크라 웨펀'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['광폭화된 전의의 로드'] = '0e78fea02eee9858b5a549d6cb154829';
lv105OptionMap['광폭화된 전의의 로드'] = {};
lv105OptionMap['광폭화된 전의의 로드'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 통파 - 그래플러(남)'] = '05d51461904c5ead623660f8ac219737';
lv105OptionMap['결전의 통파 - 그래플러(남)'] = {};
lv105OptionMap['결전의 통파 - 그래플러(남)'] = '롤링 스파이크를 메치기 시 지진이 발생하는 최대 2회 충전 스킬로 변경<br>- 지진 공격력 : 메치기 공격력의 50%<br>- 충전 쿨타임 : 12초<br>- 후딜레이를 캔슬하여 전직 스킬 시전 가능<br><br>아래의 스킬 잡기 실패 시 해당 스킬 쿨타임 5초로 감소 (쿨타임 10초)<br>- 브레이크 다운<br>- 스냅 샷<br>- 블로우 스트라이크(지상 사용)<br>- 롤링 스파이크<br>- 나의 공격리듬은!(지상 사용)<br>- 한놈만 팬다!!<br>- 흑진광풍@@5928@@2016';

lv105ItemId['근원을 삼킨 봉'] = 'd26f10ac26a500afb133bb586afe9f03';
lv105OptionMap['근원을 삼킨 봉'] = {};
lv105OptionMap['근원을 삼킨 봉'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['결전의 봉 - 마도학자'] = '6ad587f14b4f0aba5faaba218bdc2325';
lv105OptionMap['결전의 봉 - 마도학자'] = {};
lv105OptionMap['결전의 봉 - 마도학자'] = '롤리팝 크러쉬가 더욱 많은 사탕 인형 생성<br>- 최대 사탕 생성 개수 +5<br>- 피격개체당 사탕인형 생성 수 +2<br>- 생성된 사탕인형 1개당 롤리팝 크러쉬의 남은 쿨타임 3% 감소<br>- 사탕인형 공격력 20% 감소<br><br>폭발하지 않은 사탕 인형을 흡수하여 다음 롤리팝 크러쉬 때 생성 (최대 15개)<br>- 사탕당 흡수 1번 가능<br>- 추가 생성된 사탕 인형은 최대 사탕 생성 개수에 미포함@@8300@@2016';

lv105ItemId['사이버틱 스피드 부츠'] = '088092996a8d1deaa9ed991383553ebd';
lv105OptionMap['사이버틱 스피드 부츠'] = {};
lv105OptionMap['사이버틱 스피드 부츠'] = '최종 데미지 20% 증가<br>- 장비로 공격 속도가 140% 이상 증가 시 10% 추가 증가<br>- 방어구, 악세서리, 특수장비, 아바타, 엠블렘, 젬, 크리쳐, 칭호, 아티팩트 포함<br><br>HP 1분당 460.2 회복<br>MP 1분당 348 회복<br>스킬 MP 소모량 -50%@@2642@@1968';

lv105ItemId['광폭화된 전의의 통파'] = '5ac89d6b2850553b3b7b66632961cd35';
lv105OptionMap['광폭화된 전의의 통파'] = {};
lv105OptionMap['광폭화된 전의의 통파'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 핸드캐넌 - 스핏파이어(여)'] = 'fe6d5167ce12c67caec0c124d8275e8d';
lv105OptionMap['결전의 핸드캐넌 - 스핏파이어(여)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 스핏파이어(여)'] = 'G-14 파열류탄, G-35L 섬광류탄, G-18C 빙결류탄 스킬 강화<br>- 최대 장전 수 +2<br>- 점프 상태에서 시전 시 니트로 모터를 소모하지 않음<br><br><br>보유한 유탄을 소모하여 포토빌라이저 강화<br>- 소모한 유탄 1개당 공격력 2% 증가 (최대 30%)<br>- 스탠바이-레디 상태에서는 최대 강화 수치로 발사@@8300@@2016';

lv105ItemId['근원을 삼킨 대검'] = '6d090e525eae8f91c70edd6fc47b1da0';
lv105OptionMap['근원을 삼킨 대검'] = {};
lv105OptionMap['근원을 삼킨 대검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['내딛은 자의 십자가'] = '44d9a5f031cd9e7c2aebc5e505ce76f7';
lv105OptionMap['내딛은 자의 십자가'] = {};
lv105OptionMap['내딛은 자의 십자가'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['과거를 뒤로 한 전진'] = '0b956e1734d551c828d2b980ef8a615a';
lv105OptionMap['과거를 뒤로 한 전진'] = {};
lv105OptionMap['과거를 뒤로 한 전진'] = '공격력 증가 +370.5%<br>최종 데미지 16% 증가<br><br>[작은 거인의 발걸음]<br>석화 상태에도 굳지 않는 작은 거인이 되어 전진<br>- 석화 면역<br>- 슈퍼 아머 부여<br><br>공격 시 적에게 10초 동안 석화 상태 이상 부여 (쿨타임 30초)<br><br>물리/마법 크리티컬 히트 +7%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 상태 이상 내성 -10%@@6318@@1915';

lv105ItemId['결전의 리볼버 - 레인저(여)'] = '5fe372670cc3f3ebbfe00aedebde2426';
lv105OptionMap['결전의 리볼버 - 레인저(여)'] = {};
lv105OptionMap['결전의 리볼버 - 레인저(여)'] = '이동사격 탄환을 파열시켜 더욱 넓은 범위 공격<br>- 공격력 30% 증가<br>- 발사 수 50% 증가<br>- 쿨타임 20% 감소<br>- 공격 범위 10% 증가<br><br>이동사격 중 아래의 효과 적용<br>- 스킬 시전 시 쿨타임 즉시 적용<br>- 공격 속도 +30%<br>- 이동 속도 +30%<br>- 던전 방 이동 시 스킬 유지@@7708@@2016';

lv105ItemId['결전의 도 - 드래곤나이트'] = '72fb2882c258513c17fc80b515c3637e';
lv105OptionMap['결전의 도 - 드래곤나이트'] = {};
lv105OptionMap['결전의 도 - 드래곤나이트'] = '태도 용제무쌍을 최대 2회 충전 스킬로 변경<br>- 공격력 40% 감소<br>- 충전 쿨타임 : 10초<br><br>태도 용제무쌍 시전 시 10초 동안 스킬 공격력 5% 증가 (각성기 제외)<br><br>태도 용제무쌍 시전 중 아스트라 스킬 시전 시 남은 쿨타임 10% 감소<br>- 쿨타임 감소 효과는 용제무쌍 1회당 1번만 적용<br><br>아래의 스킬 쿨타임 10% 감소<br>- 파이어 브레스<br>- 아스트라 소환<br>- 드래곤 팽<br>- 폭풍의 숨결<br>- 비룡천상@@5928@@2016';

lv105ItemId['결전의 코어 블레이드 - 스페셜리스트'] = '4b049b5bffde050b54a26554f3d7eed0';
lv105OptionMap['결전의 코어 블레이드 - 스페셜리스트'] = {};
lv105OptionMap['결전의 코어 블레이드 - 스페셜리스트'] = '인텐션 스택 사용 시 인텐션 스택 1개를 추가 소모하여 5초 동안 스킬 쿨타임 20% 감소 (각성기 제외)<br><br>CEAB-2를 인텐션 스택으로 강화 가능<br>- 강화 시 최대 충전 공격력 40% 증가<br>- 강화 시 다단히트 수 60% 충전 상태로 발동<br><br>인텐션 기본 스택 수 +2@@7112@@2016';

lv105ItemId['근원을 삼킨 광검'] = '1666f7be2f22b01b4bbb559c7bc51c99';
lv105OptionMap['근원을 삼킨 광검'] = {};
lv105OptionMap['근원을 삼킨 광검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['결전의 미늘창 - 다크 랜서'] = '79fdc1d3a83bf8b12a010ac93debabc4';
lv105OptionMap['결전의 미늘창 - 다크 랜서'] = {};
lv105OptionMap['결전의 미늘창 - 다크 랜서'] = '잠식 상태의 적 1마리당 1초마다 흑뢰의 기운 10% 획득<br>- 잠식 상태의 적이 없을 경우 1초마다 5% 감소<br><br>보스 몬스터 공격 시 흑뢰의 기운 1% 획득<br><br>흑뢰의 기운 100% 달성 시 15초 동안 다크 스타 강화<br>- 흑뢰 창이 동시에 낙하<br>- 흑뢰 마장창 추가 낙하<br>- 흑뢰 마장창 공격력 : 다크 스타 다단 히트 공격력의 1,000%@@10076@@2016';

lv105ItemId['결전의 쌍검 - 사령술사'] = 'a5f481a2dd775c27dfd5b5afaa092493';
lv105OptionMap['결전의 쌍검 - 사령술사'] = {};
lv105OptionMap['결전의 쌍검 - 사령술사'] = '리빙데드를 최대 3회 충전 스킬로 변경<br><br>레디아 블랙옥스가 직접 폭발<br>- 공격력 : 리빙데드 총 공격력의 45%<br>- 충전 쿨타임 : 15초<br>- 적에게 5초 동안 공포의 낙인 생성<br>- 추가 조작 기능 삭제<br><br>공포의 낙인이 생성된 적을 아래의 스킬로 공격 시 해당 스킬 남은 쿨타임 10% 감소<br>- 망자의 집념<br>- 블랙 아라크네아<br>- 익스큐셔너 슬래쉬@@889@@2016';

lv105ItemId['광폭화된 전의의 투창'] = '02bd7d3ce763965e3270a9715144a0fb';
lv105OptionMap['광폭화된 전의의 투창'] = {};
lv105OptionMap['광폭화된 전의의 투창'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['소망을 전하는 편지'] = '4b49b55d52c244e5858170d64bd81251';
lv105OptionMap['소망을 전하는 편지'] = {};
lv105OptionMap['소망을 전하는 편지'] = '공격력 증가 +296.4%<br>최종 데미지 17% 증가<br><br>[섀도우 워커]<br>퀵 스탠딩 강화<br>- 무적 지속 시간 +1초<br>- 2초 동안 그림자 이동 가능<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%<br>수면 내성 -20%@@3988@@1948';

lv105ItemId['결전의 권투글러브 - 스트라이커(여)'] = '56115476ef3b4a33cee00054fa917ceb';
lv105OptionMap['결전의 권투글러브 - 스트라이커(여)'] = {};
lv105OptionMap['결전의 권투글러브 - 스트라이커(여)'] = '라이트닝 댄스 이동 횟수가 4회 감소하고 1:1 공격 시 대상의 좌우를 이동하며 공격<br>- 공격력 15% 감소<br>- 공격 속도 +30%<br>- 이동 범위 5% 증가<br>- 공격 시 적에게 감전 상태 이상 부여 (쿨타임 15초)<br><br>라이트닝 댄스 공격 시 충격파 생성<br>- 직접 타격 대상은 충격파 피격 제외<br>- 1:1 공격일 경우 충격파 미생성@@9484@@2016';

lv105ItemId['결전의 십자가 - 크루세이더(여)'] = '8620e5d2347bda29f8c92d61611c8034';
lv105OptionMap['결전의 십자가 - 크루세이더(여)'] = {};
lv105OptionMap['결전의 십자가 - 크루세이더(여)'] = '용맹의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>치유의 기도 스킬 범위 20% 증가<br><br>홀리 플래쉬가 적을 관통하여 300px 전진 후 폭발<br>- 공격력 100% 증가<br>- 스킬 범위 10% 증가<br><br>홀리 플래쉬 적중 시 그랜드 크로스 크래쉬, 리펜텐스 스매시의 남은 쿨타임 2% 감소<br><br>빛의 은총 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['결전의 코어 블레이드 - 히트맨'] = '6e8d003d4047dc03103ba3a14804bf58';
lv105OptionMap['결전의 코어 블레이드 - 히트맨'] = {};
lv105OptionMap['결전의 코어 블레이드 - 히트맨'] = '전직 스킬의 후딜레이를 캔슬하여 데드 식스를 시전 가능<br>후딜레이 캔슬 시 아래의 효과 적용<br>- 캔슬한 스킬 남은 쿨타임 20% 감소<br>- 데드 식스 남은 쿨타임 50% 감소<br>- 8초 동안 공격 속도 +7% (최대 3중첩)@@10076@@2016';

lv105ItemId['결전의 대검 - 팔라딘'] = '7576c4169f959c162739e7cf78772f70';
lv105OptionMap['결전의 대검 - 팔라딘'] = {};
lv105OptionMap['결전의 대검 - 팔라딘'] = '세라픽 페더를 소모하여 이스트레인지먼트 시전 시 추가 효과 발동<br>- 세라픽 페더 1장당 공격력 4% 증가<br>- 세라픽 페더 1장당 스킬 범위 3% 증가<br>- 적에게 기절 상태 이상 부여<br><br>세라픽 페더를 소모하여 위핑 웨이브 시전 시 추가 효과 발동<br>- 끌어들인 적을 2초 동안 홀딩<br>- 몰이 효과 범위 6단계 적용<br>- 몰이 효과 범위 20% 증가<br>- 세라픽 페더 최대 2개만 소모<br><br>위핑 웨이브 강화<br>- 스킬 범위 20% 증가<br>- 타격 성공 시 세라픽 페더 습득량 +2<br>- 도발시킨 적 앞에서 방어 상태 유지 시 세라픽 페더 획득 시간 50% 감소@@9484@@2016';

lv105ItemId['결전의 낫 - 크루세이더(여)'] = '4aa4baff4d565aed0461738bcc9d05d3';
lv105OptionMap['결전의 낫 - 크루세이더(여)'] = {};
lv105OptionMap['결전의 낫 - 크루세이더(여)'] = '용맹의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>치유의 기도 스킬 범위 20% 증가<br><br>홀리 플래쉬가 적을 관통하여 300px 전진 후 폭발<br>- 공격력 100% 증가<br>- 스킬 범위 10% 증가<br><br>홀리 플래쉬 적중 시 그랜드 크로스 크래쉬, 리펜텐스 스매시의 남은 쿨타임 2% 감소<br><br>빛의 은총 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['사멸하는 신뢰의 머스켓'] = 'a9b24a528488e6e347ce8fde7cee307e';
lv105OptionMap['사멸하는 신뢰의 머스켓'] = {};
lv105OptionMap['사멸하는 신뢰의 머스켓'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['사멸하는 신뢰의 낫'] = '22e326fbbe477d98abf5417ecd651e42';
lv105OptionMap['사멸하는 신뢰의 낫'] = {};
lv105OptionMap['사멸하는 신뢰의 낫'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['자정의 성역'] = '863442932f7ba5fa35412794d34cb184';
lv105OptionMap['자정의 성역'] = {};
lv105OptionMap['자정의 성역'] = '최종 데미지 10% 증가<br><br>스킬 쿨타임 회복 속도 +12% (각성기 제외)<br>- "구속된 자유" 장비 장착 필요<br><br>공격 속도 +10%<br>캐스팅 속도 +15%<br>물리/마법 방어력 +7,000<br>적중률 +10%@@5066@@1928';

lv105ItemId['내딛은 자의 핸드캐넌'] = '70d6abf6827fb77bb5319382b0a834c5';
lv105OptionMap['내딛은 자의 핸드캐넌'] = {};
lv105OptionMap['내딛은 자의 핸드캐넌'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['사멸하는 신뢰의 소태도'] = '02b8fe0f1731d5d63c29308d8c54a638';
lv105OptionMap['사멸하는 신뢰의 소태도'] = {};
lv105OptionMap['사멸하는 신뢰의 소태도'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['얼어붙은 저항의 장도'] = '6e3da1f981efe4d2484e45cc83886220';
lv105OptionMap['얼어붙은 저항의 장도'] = {};
lv105OptionMap['얼어붙은 저항의 장도'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['옥화의 망령 대퇴갑'] = '24db159d4b8a9470b1ef591e926c7f87';
lv105OptionMap['옥화의 망령 대퇴갑'] = {};
lv105OptionMap['옥화의 망령 대퇴갑'] = '[패션 스타]<br>인벤토리에 아바타 120개 이상 보유 시 스포트라이트 발생<br>- 최종 데미지 6% 증가<br>- 스킬 쿨타임 8% 감소 (각성기 제외)<br>- 슈퍼 아머 부여<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%@@5066@@1948';

lv105ItemId['성장하는 모험가의 통파'] = '62d8a50e0066c4091055a8889fe29276';
lv105OptionMap['성장하는 모험가의 통파'] = {};
lv105OptionMap['성장하는 모험가의 통파'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['사멸하는 신뢰의 장도'] = 'b41585776eab8404a00273e8ff0bdcef';
lv105OptionMap['사멸하는 신뢰의 장도'] = {};
lv105OptionMap['사멸하는 신뢰의 장도'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['사멸하는 신뢰의 권투글러브'] = '35ac5b148df41da5a7872fe1065bb1aa';
lv105OptionMap['사멸하는 신뢰의 권투글러브'] = {};
lv105OptionMap['사멸하는 신뢰의 권투글러브'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 창 - 블러드 메이지'] = '6a6933f394d6a53e94d2799be63ee4c3';
lv105OptionMap['결전의 창 - 블러드 메이지'] = {};
lv105OptionMap['결전의 창 - 블러드 메이지'] = '커럽션 공격 시 블러드비스 2개 획득 (최대 5개)<br>- 쉬카리, 토먼트 시전 시 블러드비스 1개를 소모하여 각각 페이탈 쉬카리, 블러드먼트로 강화<br>- 던전 입장 시 블러드비스 1개 획득<br><br>페이탈 쉬카리<br>- 쉬카리 공격력 50% 증가<br>- 1히트 스킬로 변경<br>- 500px 범위 내 모든 적 공격<br><br>블러드먼트<br>- 토먼트 공격력 50% 증가<br>- 시전 후 이동 가능<br><br>커럽션 쿨타임 10% 감소, 시전 속도 30% 증가@@8892@@2016';

lv105ItemId['결전의 둔기 - 카오스'] = '1b4f1ff4b4e42c8af24c799d2fd7146c';
lv105OptionMap['결전의 둔기 - 카오스'] = {};
lv105OptionMap['결전의 둔기 - 카오스'] = '제노사이드 크러쉬 시전 시 데몬들을 폭발시키는 대신 아텐의 화신이 데몬들의 환영을 소환하고 폭발시키도록 변경<br>- 공격력 10% 증가<br>- 스킬 범위 20% 증가<br>- 시전 중 미드나잇 카니발 시전 가능<br>- 데몬이 없을 경우 즉시 모든 데몬 소환@@10076@@2016';

lv105ItemId['결전의 대검 - 아수라'] = '854c267305cba5c5c4f4ac5239f0f398';
lv105OptionMap['결전의 대검 - 아수라'] = {};
lv105OptionMap['결전의 대검 - 아수라'] = '파동검 폭염이 빠르게 폭발<br>- 폭발 위치 변경<br>- 폭발 간격 시간 30% 감소<br>- 폭발 간격 거리 90% 감소<br>- 폭발 크기 45% 증가<br>- 3타 폭발 크기 70% 증가<br><br>뇌신의 힘을 받아 아래의 스킬 시전 중 작열 파동진을 모션 없이 시전 가능<br>- 파동검 폭염<br>- 부동명왕진<br>- 파동검 : 인다라망<br><br>살의의 파동 파동 영역 20% 증가<br><br>작열 파동진 스킬 범위 25% 증가@@10672@@2016';

lv105ItemId['근원을 삼킨 배틀액스'] = '2796412cca82ab643b6d4a8f2d3a5568';
lv105OptionMap['근원을 삼킨 배틀액스'] = {};
lv105OptionMap['근원을 삼킨 배틀액스'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['내딛은 자의 너클'] = '50f05690de35cf411cc5f65d2263bd24';
lv105OptionMap['내딛은 자의 너클'] = {};
lv105OptionMap['내딛은 자의 너클'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['음율에 담은 소망'] = '0992070f0ba429eed7b48d50bcaa46a2';
lv105OptionMap['음율에 담은 소망'] = {};
lv105OptionMap['음율에 담은 소망'] = '최종 데미지 9% 증가<br>스킬 쿨타임 10% 감소 (각성기 제외)<br><br>HP MAX +600<br>물리/마법 방어력 +7,000<br>수면 내성 -10%@@6414@@1958';

lv105ItemId['경계를 넘어선 차원'] = '00f58a48b4f8c5ba9073796c8e4308f8';
lv105OptionMap['경계를 넘어선 차원'] = {};
lv105OptionMap['경계를 넘어선 차원'] = '최종 데미지 13% 증가<br>모든 속성 강화 +10<br><br>스킬 범위 옵션 수치의 총합이 23% 이상일 때 모든 TP 스킬 Lv +1<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 속성 저항 +8@@6682@@1920';

lv105ItemId['원터치 스마트 리모콘'] = '001b114bde67db7c3f0dc31c69df6119';
lv105OptionMap['원터치 스마트 리모콘'] = {};
lv105OptionMap['원터치 스마트 리모콘'] = '최종 데미지 10% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +20%@@4796@@1938';

lv105ItemId['결전의 빗자루 - 마도학자'] = '34ae751ee9ea1311c57b8a81d3ae2830';
lv105OptionMap['결전의 빗자루 - 마도학자'] = {};
lv105OptionMap['결전의 빗자루 - 마도학자'] = '롤리팝 크러쉬가 더욱 많은 사탕 인형 생성<br>- 최대 사탕 생성 개수 +5<br>- 피격개체당 사탕인형 생성 수 +2<br>- 생성된 사탕인형 1개당 롤리팝 크러쉬의 남은 쿨타임 3% 감소<br>- 사탕인형 공격력 20% 감소<br><br>폭발하지 않은 사탕 인형을 흡수하여 다음 롤리팝 크러쉬 때 생성 (최대 15개)<br>- 사탕당 흡수 1번 가능<br>- 추가 생성된 사탕 인형은 최대 사탕 생성 개수에 미포함@@8300@@2016';

lv105ItemId['성장하는 모험가의 투창'] = '69f511056a27932a43f2e8066fedd9c7';
lv105OptionMap['성장하는 모험가의 투창'] = {};
lv105OptionMap['성장하는 모험가의 투창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +5%<br>수속성 저항 +10@@5336@@1996';

lv105ItemId['파괴된 신념'] = '05c1ea0f0055f45f990ca4a3c8eeacd4';
lv105OptionMap['파괴된 신념'] = {};
lv105OptionMap['파괴된 신념'] = '최종 데미지 12% 증가<br><br>출혈 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 출혈 상태 이상 부여 (쿨타임 10초)<br><br>자신에게 해제되지 않는 출혈 상태 이상 부여<br><br>HP MAX +600@@5874@@1948';

lv105ItemId['엔데카 코어 칩'] = '193e16cf78fb893d6d1942e9c2b53f93';
lv105OptionMap['엔데카 코어 칩'] = {};
lv105OptionMap['엔데카 코어 칩'] = '최종 데미지 12% 증가<br><br>[스톤 스트라이크]<br>석화 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>[메두사의 눈]<br>석화 상태의 적이 받는 데미지가 누적되며 석화 종료 시 누적된 데미지 폭발<br><br>적 처치 시 HP 5% 회복<br><br>공격 속도 +8%<br>캐스팅 속도 +12%@@2560@@1900';

lv105ItemId['광폭화된 전의의 너클'] = '934afea1d49e946955ceec90b311528e';
lv105OptionMap['광폭화된 전의의 너클'] = {};
lv105OptionMap['광폭화된 전의의 너클'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 배틀액스 - 어벤저'] = '1e8e2fed72bf5230d2912a18a94d4f39';
lv105OptionMap['결전의 배틀액스 - 어벤저'] = {};
lv105OptionMap['결전의 배틀액스 - 어벤저'] = '악마화 상태에서 악마 게이지 30 이상일 때 악마 게이지 소모 스킬 공격 시 40을 추가 소모하여 데빌 클로 발동 (각성기 제외)<br>- 데빌 스트라이커 공격력의 2,000%<br><br>악마화 평타 공격 및 스킬의 악마 게이지 회복량 10% 증가<br>고통의 희열 악마 게이지 회복량 10% 증가<br><br>악마화 공격 속도 증가량 20% 추가 증가, 이동 속도 증가량 10% 추가 증가@@7112@@2016';

lv105ItemId['전령에 깃든 축복'] = '0a4894b6738f02981163c797415e0c2f';
lv105OptionMap['전령에 깃든 축복'] = {};
lv105OptionMap['전령에 깃든 축복'] = '최종 데미지 18% 증가 <br><br>물리/마법 방어력 +43,000<br>공격 속도 +8%<br>캐스팅 속도 +12%@@5874@@1928';

lv105ItemId['결전의 스태프 - 빙결사'] = '29596e90c345114b1780480718cd1650';
lv105OptionMap['결전의 스태프 - 빙결사'] = {};
lv105OptionMap['결전의 스태프 - 빙결사'] = '아이스 크래프트 얼음창이 얼음 무기에 피격된 모든 적을 추적하여 꿰뚫음<br>- 얼음창 공격력 반영률 20% 추가 증가<br>- 얼음창 폭발 삭제<br>- 얼음창이 10% 확률로 적에게 2초 동안 빙결 상태 이상 부여<br><br>핀포인트 러쉬 시전 시 전방의 가장 강력한 적을 추적하여 주변의 적을 함께 공격<br>- 추적 범위 : 500px@@7112@@2016';

lv105ItemId['검은 발자국'] = '0600576a470adb7226ea5e00e964d806';
lv105OptionMap['검은 발자국'] = {};
lv105OptionMap['검은 발자국'] = '15~30Lv 스킬 공격력 18% 증가<br>기본기 숙련 최종 데미지 52% 증가<br><br>공격 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +600<br>적중률 +10%@@5066@@1968';

lv105ItemId['광폭화된 전의의 봉'] = '5b1a6ef0c4c2fb25f32b7ded51ec490d';
lv105OptionMap['광폭화된 전의의 봉'] = {};
lv105OptionMap['광폭화된 전의의 봉'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['천재 기술자의 멀티박스 팬츠'] = '3a90df330ecc42b3aa3ceca52c5b94cd';
lv105OptionMap['천재 기술자의 멀티박스 팬츠'] = {};
lv105OptionMap['천재 기술자의 멀티박스 팬츠'] = '최종 데미지 8% 증가<br><br>대기, 걷기 시 카모플라쥬 상태가 되어 회피율 +45% (쿨타임 5초)<br>- 대시, 피격 시 버프 해제<br><br>이동 속도 +8%<br>회피율 +8%@@4796@@1928';

lv105ItemId['성장하는 모험가의 빗자루'] = '9cb896df60d0ad702f53a27d6b034231';
lv105OptionMap['성장하는 모험가의 빗자루'] = {};
lv105OptionMap['성장하는 모험가의 빗자루'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['레드 라이프 링'] = 'b3db860f95b59d021023cc82d70ec77a';
lv105OptionMap['레드 라이프 링'] = {};
lv105OptionMap['레드 라이프 링'] = '공격력 증가 +222.3%<br>최종 데미지 7.1% 증가<br>모든 속성 강화 +10<br><br>공격 시 적에게 15초 동안 화상 상태 이상 부여 (쿨타임 10초)<br><br>화상 지속 시간 -10%<br><br>화상 내성 +1%@@5133@@1968';

lv105ItemId['소망을 향한 움직임'] = '0db7afd06a14268eb3ebeee3423ea30c';
lv105OptionMap['소망을 향한 움직임'] = {};
lv105OptionMap['소망을 향한 움직임'] = '최종 데미지 16% 증가<br>모든 속성 강화 +20<br><br>출혈/중독/화상/감전 지속 시간 -20%<br><br>물리/마법 피해 감소 +10%<br>스킬 MP 소모량 -7%@@7126@@1963';

lv105ItemId['결전의 자동권총 - 레인저(남)'] = '811a860dc0599b4408132207a0aaa601';
lv105OptionMap['결전의 자동권총 - 레인저(남)'] = {};
lv105OptionMap['결전의 자동권총 - 레인저(남)'] = '더블 건호크 강화<br>- 세 번째 건호크 잡기 성공 시 두 번째 건호크 투척 이후 사용한 스킬들의 남은 쿨타임 20% 감소<br>- 크기 10% 증가<br>- 시전 시 4초 동안 공격 속도 +30%<br><br>스타일리쉬 캔슬 가능 최대 횟수 +1<br><br>장착 시 스펙트럼 생성@@6520@@2016';

lv105ItemId['결전의 염주 - 인파이터'] = '1c209be775ef3c1269e0e94ecc49f963';
lv105OptionMap['결전의 염주 - 인파이터'] = {};
lv105OptionMap['결전의 염주 - 인파이터'] = '정의 집행 : 레미디오스의 성좌의 링크 스킬이 빅뱅 펀치일 때, 신의 분노를 최대 3회 충전 스킬로 변경<br>- 연타 공격력 100% 증가<br>- 연타 횟수 9회 감소<br>- 피니쉬 타격 및 폭발 삭제<br>- 적을 추적하여 공격<br>- 충전 쿨타임 : 40초<br>- 추적 범위 : 780px<br>- MP 소모량 -30%<br>- 무색 큐브 조각 소모량 5개 감소<br>- 머신건 잽 시전 시 신의 분노 남은 쿨타임 12% 감소<br>- 신의 분노를 캔슬하여 전직 스킬 시전 가능<br>- 전직 스킬을 캔슬하여 신의 분노 시전 시 드라이아웃 스택을 소모하지 않음@@5928@@2016';

lv105ItemId['썬더 레지스트 벨트'] = '0690d318a85803d80cc38993a9de0351';
lv105OptionMap['썬더 레지스트 벨트'] = {};
lv105OptionMap['썬더 레지스트 벨트'] = '최종 데미지 10% 증가<br>모든 속성 강화 +20<br><br>감전 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)<br><br>명속성 저항 +10@@6414@@1958';

lv105ItemId['정의의 기사 가면'] = '0a25a53d462bafd167baee17ffd8c187';
lv105OptionMap['정의의 기사 가면'] = {};
lv105OptionMap['정의의 기사 가면'] = '스킬 쿨타임 12% 감소 (각성기 제외)<br><br>점프 상태에서 점프 키를 0.5초 동안 입력 시 5초 동안 비행 (쿨타임 30초)<br>- 방향키 연속 입력 시 공중 대시 (최대 2회)<br><br>슈퍼 아머 부여<br><br>이동 속도 +12%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20<br>물리/마법 피해 감소 +15%@@5336@@1938';

lv105ItemId['얼어붙은 저항의 광검'] = 'e2028378a2f0a0a9e80ced44de270294';
lv105OptionMap['얼어붙은 저항의 광검'] = {};
lv105OptionMap['얼어붙은 저항의 광검'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['내딛은 자의 미늘창'] = '13feaea7e8625db8baca42f5cbf3816d';
lv105OptionMap['내딛은 자의 미늘창'] = {};
lv105OptionMap['내딛은 자의 미늘창'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['하늘을 수호하는 윙 부츠'] = '039682c96bb1e461e20f0669fde8ef91';
lv105OptionMap['하늘을 수호하는 윙 부츠'] = {};
lv105OptionMap['하늘을 수호하는 윙 부츠'] = '최종 데미지 17% 증가<br><br>공중에서 피격 시 [경매장 키] 입력을 통해 체공 자세 회복 (쿨타임 10초)<br><br>물리/마법 크리티컬 히트 +5%<br>이동 속도 +8%<br>물리/마법 방어력 +20%@@5604@@1920';

lv105ItemId['청명한 아침의 새싹'] = 'a7bd1a1ffcc468e619147cecdd3687e7';
lv105OptionMap['청명한 아침의 새싹'] = {};
lv105OptionMap['청명한 아침의 새싹'] = '암속성 강화 +70<br><br>물리/마법 크리티컬 히트 +15%<br>암속성 저항 +10@@4528@@1958';

lv105ItemId['결전의 둔기 - 다크나이트'] = '458550af432dd0956885cd5afd271568';
lv105OptionMap['결전의 둔기 - 다크나이트'] = {};
lv105OptionMap['결전의 둔기 - 다크나이트'] = '타임 스톱 최대 콤보 스택 1단계 증가<br>- 콤보 스택 6단계 공격력 : 220%<br><br>시간에 간섭하여 타임로드 효과 강화<br>- 다른 콤보 슬롯 스킬 사용 시 타임 스톱 스택 획득<br>- 확장 콤보 슬롯 스킬 시전 시 기존 콤보 스킬 유지<br>- 타임 로드 효과 지속 시간 +2초@@10672@@2016';

lv105ItemId['리플레이서'] = '2dc6d6233b142a632d4808a452f4c636';
lv105OptionMap['리플레이서'] = {};
lv105OptionMap['리플레이서'] = '최종 데미지 12.5% 증가<br>스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>스킬 MP 소모량 +300%<br><br>물리/마법 크리티컬 히트 +10%<br>MP MAX +945<br>MP 1분당 348 회복@@3988@@1948';

lv105ItemId['언비튼 메달'] = '9f30cf8ea43c6090797cb210d0d7723c';
lv105OptionMap['언비튼 메달'] = {};
lv105OptionMap['언비튼 메달'] = '최종 데미지 10% 증가<br><br>[속성 친화 - 화]<br>화속성 저항이 높아질수록 물리/마법 피해 감소<br>- 적의 공격에 화속성 부여<br><br>피격 시 5초 동안 HP 5% 회복 (쿨타임 7초)<br><br>HP MAX +600<br>화속성 저항 +35@@5604@@1948';

lv105ItemId['얼어붙은 저항의 대검'] = '110c9b5864d309ad2f7a974fc1fae73c';
lv105OptionMap['얼어붙은 저항의 대검'] = {};
lv105OptionMap['얼어붙은 저항의 대검'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 대검 - 다크템플러'] = '0dca697fc78d15efb39f705c449710d1';
lv105OptionMap['결전의 대검 - 다크템플러'] = {};
lv105OptionMap['결전의 대검 - 다크템플러'] = '이보브 영혼 최대 흡수 개수 +30<br><br>아래 스킬의 추가 영혼 흡수 개수 +5<br>- 리버레이트 - 매드니스<br>- 디스트로이어<br>- 라케르쏜즈<br><br>리버레이트 - 벅샷 발사하는 에너지 1개당 소모되는 영혼의 수 +2<br>- 타겟을 잡는 최대 거리 20% 증가<br><br>리버레이트 - 벅샷으로 소모한 영혼의 수 2개마다 아래 스킬의 남은 쿨타임 1% 감소 (베스퍼스 효과 미적용)<br>- 디스트로이어<br>- 데우스 엑스 레이<br>- 지하드<br>- 라케르쏜즈@@5336@@2016';

lv105ItemId['승리가 약속된 시간'] = '2ff5eb9a81962b2904314e9cdf0dd191';
lv105OptionMap['승리가 약속된 시간'] = {};
lv105OptionMap['승리가 약속된 시간'] = '최종 데미지 13% 증가<br><br>슈퍼 아머 부여<br><br>MP MAX +945<br>이동 속도 +8%@@5604@@1920';

lv105ItemId['결전의 봉 - 인챈트리스'] = '7e1129a75bb19f45f5a6dd0fd33b2f6d';
lv105OptionMap['결전의 봉 - 인챈트리스'] = {};
lv105OptionMap['결전의 봉 - 인챈트리스'] = '금단의 저주 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>꼼꼼한 바느질 스킬 범위 20% 증가<br><br>테러블 로어 캐스팅 모션 삭제<br>테러블 로어 몹몰이 범위 10% 증가<br><br>커스 오브 테러베어의 브레스에 피격된 적에게 저주의 기운을 부여하고 폭발<br>- 폭발 피해 : 브레스 총 공격력의 50%<br><br>어둠에 피는 장미의 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['얼어붙은 저항의 봉'] = '1f3fccd21542e2807bb4ffcb2376b89e';
lv105OptionMap['얼어붙은 저항의 봉'] = {};
lv105OptionMap['얼어붙은 저항의 봉'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 핸드캐넌 - 메카닉(남)'] = '231c0aee1dc71de63569c7ef4e54a17c';
lv105OptionMap['결전의 핸드캐넌 - 메카닉(남)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 메카닉(남)'] = '로봇 전폭으로 로봇 폭발 시 R-러너 생성<br>-  R-러너 공격력 : RX-78 랜드러너 공격력의 50%<br>- 로봇 전폭의 폭발 데미지 변화율 효과 적용<br>- 최대 10개 생성<br><br><R-러너 생성 가능 스킬><br>- RX-78 랜드러너<br>- Ez-8 카운트 다운<br>- RX-60 트랩 러너<br>- 공중 전투 메카 : 템페스터<br>- 메카 드롭<br>- 스패로우 팩토리<br>- Ez-10 카운터 어택<br>- TX-45 A-Team<br><br>RX-78 랜드러너 공격력 5% 증가@@948@@2016';

lv105ItemId['결전의 단검 - 쿠노이치'] = 'ffdf1bc662081056dc02c63339a89a98';
lv105OptionMap['결전의 단검 - 쿠노이치'] = {};
lv105OptionMap['결전의 단검 - 쿠노이치'] = '인법 : 잔영 남기기의 잔영 소모 1개당 다음에 재생할 육도윤회의 잔영 공격력 비율 1.5% 추가 증가 (최대 12중첩)<br><br>인법 : 육도윤회 시전 시 5초 동안 슈퍼아머 상태가 되며 스킬 쿨타임 회복 속도 +50% (쿨타임 20초 / 각성기 제외)<br><br>인법 : 육도윤회 스킬 종료 시 잔영 5 회복 (쿨타임 10초)@@8300@@2016';

lv105ItemId['결전의 소검 - 웨펀마스터'] = '7bffb0a3d9cf2447e1e9fad52804d170';
lv105OptionMap['결전의 소검 - 웨펀마스터'] = {};
lv105OptionMap['결전의 소검 - 웨펀마스터'] = '초식을 발전시켜 이기어검술 스킬 강화<br>- 지속 시간 +20초<br>- 기본기 숙련에 영향받는 스킬 적중 시 지속 시간 +0.5초 (최대 지속 시간 초과 불가능)<br>- 꿰뚫는검 사출 개수 +1<br>- 꿰뚫는검 사출 회수 시간 감소<br><br>장착 해제 시 이기어검술 지속 시간 종료@@8892@@2016';

lv105ItemId['근원을 삼킨 쌍검'] = 'a6166434e4244480a0eef198296b848f';
lv105OptionMap['근원을 삼킨 쌍검'] = {};
lv105OptionMap['근원을 삼킨 쌍검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['결전의 둔기 - 다크템플러'] = 'f651b5d92a6fe953c2875b2ec69d6ecc';
lv105OptionMap['결전의 둔기 - 다크템플러'] = {};
lv105OptionMap['결전의 둔기 - 다크템플러'] = '이보브 영혼 최대 흡수 개수 +30<br><br>아래 스킬의 추가 영혼 흡수 개수 +5<br>- 리버레이트 - 매드니스<br>- 디스트로이어<br>- 라케르쏜즈<br><br>리버레이트 - 벅샷 발사하는 에너지 1개당 소모되는 영혼의 수 +2<br>- 타겟을 잡는 최대 거리 20% 증가<br><br>리버레이트 - 벅샷으로 소모한 영혼의 수 2개마다 아래 스킬의 남은 쿨타임 1% 감소 (베스퍼스 효과 미적용)<br>- 디스트로이어<br>- 데우스 엑스 레이<br>- 지하드<br>- 라케르쏜즈@@5336@@2016';

lv105ItemId['미지의 황금비석'] = 'ae71fa231a05101bf87dec168c442ce0';
lv105OptionMap['미지의 황금비석'] = {};
lv105OptionMap['미지의 황금비석'] = '최종 데미지 12% 증가<br><br>[고스트 익스플로전]<br>공격 시 적을 추적하여 자폭하는 유령 소환 (쿨타임 0.5초)<br>- 30% 확률로 2마리 소환<br>- 총 공격력 증가의 200%만큼 피해<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 상태 이상 내성 +16%@@4528@@1900';

lv105ItemId['내딛은 자의 봉'] = '7250e255b07bad50d2e735d92a0ab0b3';
lv105OptionMap['내딛은 자의 봉'] = {};
lv105OptionMap['내딛은 자의 봉'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['얼어붙은 저항의 빗자루'] = '007d4718131eabb2359f574c77d1fdf6';
lv105OptionMap['얼어붙은 저항의 빗자루'] = {};
lv105OptionMap['얼어붙은 저항의 빗자루'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['잠식된 신수의 비통한 면사포'] = '82b3b79ba9fc4ec0c86ef1ff667a55ca';
lv105OptionMap['잠식된 신수의 비통한 면사포'] = {};
lv105OptionMap['잠식된 신수의 비통한 면사포'] = '최종 데미지 9% 증가<br><br>적중률 +10%<br>회피율 +8%@@6144@@1928';

lv105ItemId['죽음을 부르는 관'] = '2bc8c21e84f66d43dd2cef1da39c17e5';
lv105OptionMap['죽음을 부르는 관'] = {};
lv105OptionMap['죽음을 부르는 관'] = '최종 데미지 9% 증가<br><br>이동 속도 +8%<br>물리/마법 피해 감소 +10%<br>회피율 +8%@@6414@@1948';

lv105ItemId['결전의 대검 - 베가본드'] = '0c75d8d42a7acfb0d3aca07d1ce962b0';
lv105OptionMap['결전의 대검 - 베가본드'] = {};
lv105OptionMap['결전의 대검 - 베가본드'] = '십자검 시전 시 전방의 가장 강력한 적을 추적한 후 수평, 수직 베기 동시 시전<br><br>전직 스킬의 후딜레이를 캔슬하여 십자검, 엽해소옥 시전 가능<br><br>아래의 스킬 쿨타임 20% 감소<br>- 일화도강<br>- 십자검<br>- 연화섬<br>- 엽해소옥@@8892@@2016';

lv105ItemId['평화를 수호하는 윙 레깅스'] = '0094922d216a36242addfb2acb38d113';
lv105OptionMap['평화를 수호하는 윙 레깅스'] = {};
lv105OptionMap['평화를 수호하는 윙 레깅스'] = '최종 데미지 8% 증가<br><br>공격 시 1.5초 동안 HP MAX 10% 수치의 보호막 부여 (쿨타임 0.5초)<br><br>공격 시 MP 1% 회복 (쿨타임 1초)<br><br>HP MAX +600<br>스킬 MP 소모량 -7%@@5336@@1938';

lv105ItemId['사멸하는 신뢰의 광창'] = '097c3ce239a5794c872d49a7e3c5bc19';
lv105OptionMap['사멸하는 신뢰의 광창'] = {};
lv105OptionMap['사멸하는 신뢰의 광창'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['절대감각의 방위구'] = '9f86e896dc473690eaa072bd093325f5';
lv105OptionMap['절대감각의 방위구'] = {};
lv105OptionMap['절대감각의 방위구'] = '최종 데미지 10% 증가<br>도약 스킬 Lv +5<br><br>점프 공격 시 즉시 착지하며 충격파 생성 (쿨타임 5초)<br>- 총 공격력 증가의 3,840%만큼 피해<br><br>슈퍼 아머 부여<br><br>이동 속도 +12%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@6952@@1938';

lv105ItemId['기록자의 목걸이'] = '98d28e09d7f849e963761f2f309a0c54';
lv105OptionMap['기록자의 목걸이'] = {};
lv105OptionMap['기록자의 목걸이'] = '최종 데미지 11% 증가<br>- "구속된 자유" 장비 장착 필요<br><br>"죽음에 잠식된 갑주" 장비의 [광폭화] 최대 지속 시간 +5초<br><br>암속성 저항 +10<br>스킬 MP 소모량 -7%@@6144@@1928';

lv105ItemId['사멸하는 신뢰의 장창'] = '1220bfb6fe0872656ac31c2646d37c4e';
lv105OptionMap['사멸하는 신뢰의 장창'] = {};
lv105OptionMap['사멸하는 신뢰의 장창'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['내딛은 자의 장도'] = '4aa0dc4f0df3e363e5d0861979317c32';
lv105OptionMap['내딛은 자의 장도'] = {};
lv105OptionMap['내딛은 자의 장도'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['얼어붙은 저항의 광창'] = '0c3c2fbb379774e3df4bc5fda87f53ad';
lv105OptionMap['얼어붙은 저항의 광창'] = {};
lv105OptionMap['얼어붙은 저항의 광창'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['하이테크 전술보조 각반'] = '084129f7a8aaa34e9ad0dd52ea3a6422';
lv105OptionMap['하이테크 전술보조 각반'] = {};
lv105OptionMap['하이테크 전술보조 각반'] = '최종 데미지 8% 증가<br><br>[가드 카운터]<br>방어 스킬로 적의 공격을 막을 경우 2초 동안 공격한 적 홀딩<br>- 방어 스킬 : 가드, 검막, 인듀어, 무기 막기, 방패 방어, 디바인 실드, 건가드<br>- 스킬 시전 후 0.5초 이내에 방어 시 적용<br><br>물리/마법 방어력 +7,000<br>카운터 피격 시 물리/마법 피해 감소 +20%@@5604@@1928';

lv105ItemId['약속을 다짐하는 벨트'] = '21f9e3644fb6e9ae771234b2417a91b0';
lv105OptionMap['약속을 다짐하는 벨트'] = {};
lv105OptionMap['약속을 다짐하는 벨트'] = '최종 데미지 10% 증가<br><br>[폭발 약속 - 강화]<br>출혈/중독/화상/감전 부여 시 해당 상태 이상과 동일한 데미지를 10% 증가시켜 폭발<br>- 전환 옵션으로 부여 시 미발동<br><br>물리/마법 피해 감소 +15%<br>모든 속성 저항 +8<br>스킬 MP 소모량 -7%@@6144@@1928';

lv105ItemId['움직이는 쇠약한 집착'] = '32eb0e0193b575d9abfee5d92ef05794';
lv105OptionMap['움직이는 쇠약한 집착'] = {};
lv105OptionMap['움직이는 쇠약한 집착'] = '공격력 증가 +326.0%<br>최종 데미지 9% 증가<br>스킬 쿨타임 15% 감소 (각성기 제외)<br><br>자신에게 해제되지 않는 감전 상태 이상 부여<br><br>이동 속도 +8%<br>HP MAX +600<br>모든 속성 저항 +15@@5604@@1910';

lv105ItemId['결전의 창 - 엘레멘탈 바머'] = '49418a31533297efceba8a13c6966fa4';
lv105OptionMap['결전의 창 - 엘레멘탈 바머'] = {};
lv105OptionMap['결전의 창 - 엘레멘탈 바머'] = '엘레멘탈 캐넌 시전 시 속성 마법구 발사 대신 전방에 어비스 캐넌 폭발<br>- 공격력 50% 증가<br>- 쿨타임 5초 증가<br>- 다른 스킬 시전 중 시전 가능<br>- 엘레멘탈 포스 1개당 공격력 10% 추가 증가<br><br>엘레멘탈 캐넌 시전 시 5초 동안 스킬 쿨타임 회복 속도 증가 (각성기 제외)<br>- 소모한 엘레멘탈 포스 1개당 6%<br><br>컨버전스 캐넌, 컨버전스 리액트 시전 시 엘레멘탈 캐넌 쿨타임 초기화@@5336@@2016';

lv105ItemId['드러나는 흐릿한 지식'] = '7c81e61369494012cb55d790d17f980b';
lv105OptionMap['드러나는 흐릿한 지식'] = {};
lv105OptionMap['드러나는 흐릿한 지식'] = '최종 데미지 7% 증가<br><br>물리/마법 크리티컬 히트 +12%<br>HP MAX +600<br>암속성 저항 +50<br>모든 상태 이상 내성 -10%@@5510@@1963';

lv105ItemId['폭풍을 삼킨 에너지'] = 'aea53f965f746802698373ca7f0935a7';
lv105OptionMap['폭풍을 삼킨 에너지'] = {};
lv105OptionMap['폭풍을 삼킨 에너지'] = '최종 데미지 13% 증가<br><br>소모품으로 인한 MP 회복 효과 50% 증가<br>스킬 MP 소모량 -20%<br><br>MP MAX +4,196<br>적중률 +10%<br>회피율 +8%@@5066@@1920';

lv105ItemId['이온화조정 팔찌'] = '29a27871765c9140b5beb3f00dfacef7';
lv105OptionMap['이온화조정 팔찌'] = {};
lv105OptionMap['이온화조정 팔찌'] = '최종 데미지 15% 증가<br><br>[긴급 탈출]<br>피격 중 점프 키 입력 시 긴급 탈출 (쿨타임 10초)<br>- 방향키 입력으로 위치 조정 가능<br>- HP 10% 회복<br><br>물리/마법 크리티컬 히트 +10%<br>이동 속도 +8%<br>물리/마법 방어력 +7,000<br>회피율 +10%@@4528@@1928';

lv105ItemId['결전의 로드 - 스위프트 마스터'] = 'da90a29538d3ead791797af61ca99cca';
lv105OptionMap['결전의 로드 - 스위프트 마스터'] = {};
lv105OptionMap['결전의 로드 - 스위프트 마스터'] = '소닉 무브를 최대 3회 충전 스킬로 변경<br><br>소닉 무브 시전 시 회오리 바람 생성<br>- 소닉 무브 회오리 바람 공격력 : 회오리 바람 공격력의 50%<br><br>회오리 바람 크기 10% 증가<br><br>전직 스킬을 캔슬하여 소닉 무브, 대진공, 칼날 바람 시전 시 풍흔 1스택 획득 (최대 15스택)<br>- 풍흔이 15스택일 때 스톰 스트라이크 시전 시 모든 풍흔을 소모하여 공격력 50% 증가@@7112@@2016';

lv105ItemId['결전의 장도 - 히트맨'] = '15957ff21ae61d6723c252f26dbc8d57';
lv105OptionMap['결전의 장도 - 히트맨'] = {};
lv105OptionMap['결전의 장도 - 히트맨'] = '전직 스킬의 후딜레이를 캔슬하여 데드 식스를 시전 가능<br>후딜레이 캔슬 시 아래의 효과 적용<br>- 캔슬한 스킬 남은 쿨타임 20% 감소<br>- 데드 식스 남은 쿨타임 50% 감소<br>- 8초 동안 공격 속도 +7% (최대 3중첩)@@10076@@2016';

lv105ItemId['사멸하는 신뢰의 리볼버'] = 'a88de4d904c129edcae31c7eba68db6f';
lv105OptionMap['사멸하는 신뢰의 리볼버'] = {};
lv105OptionMap['사멸하는 신뢰의 리볼버'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['드래곤 스케빈저'] = '0dc6f055906735f2f2eb23670e3284dd';
lv105OptionMap['드래곤 스케빈저'] = {};
lv105OptionMap['드래곤 스케빈저'] = '최종 데미지 14% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>모든 속성 저항 +15@@5874@@1928';

lv105ItemId['광폭화된 전의의 차크라 웨펀'] = '5c855d9b70be93f41aae4952f1419602';
lv105OptionMap['광폭화된 전의의 차크라 웨펀'] = {};
lv105OptionMap['광폭화된 전의의 차크라 웨펀'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 클로 - 스트리트파이터(여)'] = '4ec7ed0abae414664bddba6bfa5ea11b';
lv105OptionMap['결전의 클로 - 스트리트파이터(여)'] = {};
lv105OptionMap['결전의 클로 - 스트리트파이터(여)'] = '특정 스킬 공격 시 즉폭의 혈이 발생하여 자신의 출혈/중독/화상/감전 데미지 폭발<br><즉폭의 혈 발생 스킬><br>- 일발화약성 훅 공격<br>- 맹독 일발화약성 훅 공격<br>- 마운트 마지막 충격파<br><br>아래의 스킬 상태 이상 1개당 보너스 공격력 5% 추가 증가<br>- 일발화약성<br>- 맹독 일발화약성<br>- 크레이지 발칸@@9484@@2016';

lv105ItemId['광폭화된 전의의 십자가'] = 'adbaa50f8f7365b5d5c8707acaf445d6';
lv105OptionMap['광폭화된 전의의 십자가'] = {};
lv105OptionMap['광폭화된 전의의 십자가'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['사멸하는 신뢰의 대검'] = '3cac041bd6de56770fe8bf8ef295fd8d';
lv105OptionMap['사멸하는 신뢰의 대검'] = {};
lv105OptionMap['사멸하는 신뢰의 대검'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['성장하는 모험가의 완드'] = '89a7d821e63bd35d6b06982adf5b23a1';
lv105OptionMap['성장하는 모험가의 완드'] = {};
lv105OptionMap['성장하는 모험가의 완드'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['임펄스 트리거'] = '88fce89d89665d2948dde039042c91bc';
lv105OptionMap['임펄스 트리거'] = {};
lv105OptionMap['임펄스 트리거'] = '최종 데미지 10% 증가<br><br>공격 속도 +50%<br>물리/마법 방어력 +7,000<br>적중률 +10%@@4796@@1948';

lv105ItemId['결전의 로드 - 엘레멘탈 바머'] = 'a5003957bec135c52aeee7d221253ec7';
lv105OptionMap['결전의 로드 - 엘레멘탈 바머'] = {};
lv105OptionMap['결전의 로드 - 엘레멘탈 바머'] = '엘레멘탈 캐넌 시전 시 속성 마법구 발사 대신 전방에 어비스 캐넌 폭발<br>- 공격력 50% 증가<br>- 쿨타임 5초 증가<br>- 다른 스킬 시전 중 시전 가능<br>- 엘레멘탈 포스 1개당 공격력 10% 추가 증가<br><br>엘레멘탈 캐넌 시전 시 5초 동안 스킬 쿨타임 회복 속도 증가 (각성기 제외)<br>- 소모한 엘레멘탈 포스 1개당 6%<br><br>컨버전스 캐넌, 컨버전스 리액트 시전 시 엘레멘탈 캐넌 쿨타임 초기화@@5336@@2016';

lv105ItemId['죽음에 잠식된 갑주'] = '17e2b07728ff9a4ba2ba0bf62a97e7f8';
lv105OptionMap['죽음에 잠식된 갑주'] = {};
lv105OptionMap['죽음에 잠식된 갑주'] = '공격력 증가 +296.4%<br>최종 데미지 5% 증가<br><br>[최후의 광폭화]<br>사망 시 죽음을 미루고 5초 동안 광폭화<br>- 스킬로 무색 큐브 조각을 1개 소모할 때마다 광폭화 시간 +0.3초<br>- 광폭화 종료 시 사망<br><br>공격 속도 +15%<br>캐스팅 속도 +22.5%<br>HP MAX +600<br>스킬 MP 소모량 -7%@@4528@@1928';

lv105ItemId['결전의 대검 - 버서커'] = '9466d47afba4763d850785e89b8b392c';
lv105OptionMap['결전의 대검 - 버서커'] = {};
lv105OptionMap['결전의 대검 - 버서커'] = '피에 굶주려 갈증 시전 시 HP 10%를 소모하고 혈기 3개 획득 (최대 10스택)<br>- 갈증 충전 기능 삭제<br>- 다른 스킬 시전 중 갈증 시전 가능<br>- 던전 입장 시 혈기 1개 획득<br><br>블러드 소드, 격노 시전 시 혈기 1개를 소모하여 강화<br>- 공격력 30% 증가<br>- 스킬 범위 15% 증가<br><br>블러드 소드, 격노 시전 시 HP 5% 회복@@9484@@2016';

lv105ItemId['기품의 금빛 장신구'] = '91e668c19b5b1957befc8f4ca4dd5098';
lv105OptionMap['기품의 금빛 장신구'] = {};
lv105OptionMap['기품의 금빛 장신구'] = '공격력 증가 +370.5%<br>최종 데미지 8% 증가<br><br>자신에게 해제되지 않는 화상 상태 이상 부여<br><br>공격 시 적에게 5초 동안 화상 상태 이상 부여 (쿨타임 1초)<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +20%@@4528@@1948';

lv105ItemId['사멸하는 신뢰의 광검'] = '3f9a8679d69773a3551139dcc5774af6';
lv105OptionMap['사멸하는 신뢰의 광검'] = {};
lv105OptionMap['사멸하는 신뢰의 광검'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 선현궁 - 뮤즈'] = '642355a70a2098c18db0efaa9798453b';
lv105OptionMap['결전의 선현궁 - 뮤즈'] = {};
lv105OptionMap['결전의 선현궁 - 뮤즈'] = '러블리 템포 효과 8% 증가<br>- 장착 시 효과 해제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>밤과 꿈 스킬 범위 20% 증가<br><br>사운드 오브 뮤즈 시전 시 스포트라이트가 추가 발생<br>- 공격력 20% 증가<br>- 스킬 범위 15% 증가<br>- 스킬 적중 시 컬쳐 쇼크 쿨타임 초기화<br><br>컬쳐 쇼크 강화<br>- 스킬 종료 시 2초 동안 추가 파동 5회 발생<br>- 추가 파동 공격력 : 켤쳐 쇼크 세 번째 파동 공격력의 30%<br>- 스킬 범위 20% 증가<br><br>센세이션 싱글 플레이 시 독립 공격력 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['숨쉬는 자연의 생명'] = '0aa9c73bdb86ed17356234f2c0af1fc2';
lv105OptionMap['숨쉬는 자연의 생명'] = {};
lv105OptionMap['숨쉬는 자연의 생명'] = '모든 속성 강화 +10<br><br>[자연의 분노]<br>공격 시 4가지 자연의 분노 중 1개 시전 (쿨타임 0.5초)<br>- 화 : 파이어 플레어<br>- 수 : 아이스 픽<br>- 명 : 라이트닝 스트라이크<br>- 암 : 다크 봄<br>- 총 공격력 증가의 300%만큼 피해<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 화상 상태 이상 부여 (쿨타임 10초)<br><br>적중률 +10%@@6414@@1978';

lv105ItemId['성장하는 모험가의 어깨'] = 'null';
lv105OptionMap['성장하는 모험가의 어깨'] = {};
lv105OptionMap['성장하는 모험가의 어깨'] = '최종 데미지 7% 증가@@533@@1986';

lv105ItemId['근원을 삼킨 장궁'] = '7ad671f02a8d3463a78376c2d1fd99d9';
lv105OptionMap['근원을 삼킨 장궁'] = {};
lv105OptionMap['근원을 삼킨 장궁'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['광폭화된 전의의 미늘창'] = '9ca408ace0634c3cc9e64245203c15aa';
lv105OptionMap['광폭화된 전의의 미늘창'] = {};
lv105OptionMap['광폭화된 전의의 미늘창'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['근원을 삼킨 염주'] = '855126e0e5b8d9b26a5ffc04a25f53b2';
lv105OptionMap['근원을 삼킨 염주'] = {};
lv105OptionMap['근원을 삼킨 염주'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['근원을 삼킨 빗자루'] = '02f481e54abd852261d984dcd6ce3932';
lv105OptionMap['근원을 삼킨 빗자루'] = {};
lv105OptionMap['근원을 삼킨 빗자루'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['결전의 광창 - 드래고니안 랜서'] = 'a04fe730e35aa4470c3cb6231163b9f6';
lv105OptionMap['결전의 광창 - 드래고니안 랜서'] = {};
lv105OptionMap['결전의 광창 - 드래고니안 랜서'] = '서번트 랜스 스킬로 공격한 적에게 사냥 표식을 생성하고 사냥 1단계 부여<br>사냥 표식 20개마다 사냥 단계 상승 (최대 3단계)<br>아래 스킬 시전 시 사냥 단계를 소모하고 해당 스킬 공격력 증가<br>- 데보닝 슬래셔<br>- 팔티잔 스큐어<br>- 날개 꿰기<br><br><사냥 단계별 공격력 증가량><br>- 1단계 : 10%<br>- 2단계 : 20%<br>- 3단계 : 30%<br><br><사냥 표식 부여량><br>- 서번트 랜스 : 타격당 3 스택<br>- 서번트 랜스 : 어썰트 : 타격당 10 스택<br>- 서번트 랜스 : 인게이저 : 타격당 4 스택<br><br>서번트 랜스 마법창 재생성 시간 30% 감소<br>서번트 랜스 재발사 쿨타임 30% 감소@@8300@@2016';

lv105ItemId['안개를 탐구하는 자의 반지'] = '4ec2f2608a8b9fa537b46c667a335eaa';
lv105OptionMap['안개를 탐구하는 자의 반지'] = {};
lv105OptionMap['안개를 탐구하는 자의 반지'] = '최종 데미지 2% 증가<br>스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>물리/마법 크리티컬 히트 +5%<br>이동 속도 +15%<br>모든 속성 저항 +8@@4528@@1928';

lv105ItemId['결전의 리볼버 - 어썰트'] = 'f9936ac6d67a93aa3458fe018d07999c';
lv105OptionMap['결전의 리볼버 - 어썰트'] = {};
lv105OptionMap['결전의 리볼버 - 어썰트'] = '부스트 스킬로 전직 스킬 캔슬 시 전력 1스택 획득 (최대 5스택)<br><br>아래 스킬 시전 시 모든 전력을 소모하고, 소모한 전력당 공격력 10% 증가<br>- 레이시온 크리트<br>- 부스트 : 다이너마이트 킥<br><br>부스트 : 다이너마이트 킥 적중 시 적을 1.5초 동안 홀딩<br>- 소모한 전력 1스택당 홀딩 시간 0.5초 증가@@9484@@2016';

lv105ItemId['결전의 배틀액스 - 미스트리스'] = 'ecb64a1bd140ee3022dcfc520786e173';
lv105OptionMap['결전의 배틀액스 - 미스트리스'] = {};
lv105OptionMap['결전의 배틀액스 - 미스트리스'] = '타락의 칠죄종 변신 중 죄를 깨우는 손길 공격 시 변신 지속 시간 +12초<br>- 최대 변신 지속 시간 초과 불가능<br><br>글러트니 바밋 시전 시 자신을 중심으로 적을 흡수하고 폭발<br>- 공격력 30% 증가<br>- 죄업 스택 3개 이상일 때 시전 시 죄업 스택을 3개 소모하고 분신이 대신 시전<br><br>죄업 스택이 3개 이상일 때 무거운 죄업 시전 시 죄업 스택을 3개 소모하고 10초 동안 물리/마법 피해 감소 +15%<br><br>일곱 개의 대죄 적용 중 무거운 죄업 적 홀딩 시간 +1초<br><br>죄를 깨우는 손길 죄업 스택 증가량 +2@@7708@@2016';

lv105ItemId['불길한 데칼코마니 석판'] = '4ebe756da29721b7a93c7752320de8a9';
lv105OptionMap['불길한 데칼코마니 석판'] = {};
lv105OptionMap['불길한 데칼코마니 석판'] = '최종 데미지 8% 증가<br><br>슈퍼 아머 부여<br><br>점프 중 한 번 더 점프 가능<br><br>이동 속도 +12%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5874@@1928';

lv105ItemId['흙으로 빚은 자연'] = '28c36859083988e74ef3da8109085a67';
lv105OptionMap['흙으로 빚은 자연'] = {};
lv105OptionMap['흙으로 빚은 자연'] = '화속성 강화 +20<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>[파이어 포스]<br>공격 시 화속성 강화 수치에 따라 화속성 원소 공격 시전 (쿨타임 1.5초)<br>- 100 이상 150 미만 : 익스플로전<br>- 150 이상 250 미만 : 파이어 로드<br>- 250 이상 : 메테오<br>- 각각 총 공격력 증가의 720% / 920% / 1,480%만큼 피해<br>- 메테오는 적에게 15초 동안 화상 상태 이상 부여 (쿨타임 10초)<br><br>공격 속도 +10%<br>캐스팅 속도 +15%<br>화속성 저항 +20@@6414@@1978';

lv105ItemId['내딛은 자의 장창'] = '8db657419606f61f99d65a33160b6f2d';
lv105OptionMap['내딛은 자의 장창'] = {};
lv105OptionMap['내딛은 자의 장창'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['승리로 이끄는 정의'] = '1790007e83e6f513e5bcfc51b8e8bfd9';
lv105OptionMap['승리로 이끄는 정의'] = {};
lv105OptionMap['승리로 이끄는 정의'] = '최종 데미지 8% 증가<br><br>약점 무력화형 상태 이상의 무력화 게이지 감소량 +200%<br><br>물리/마법 방어력 +7,000<br>이동 속도 +8%@@5604@@1968';

lv105ItemId['결전의 투창 - 듀얼리스트'] = '9bc2ac16cd6c8f7caa357a08a3b1c85f';
lv105OptionMap['결전의 투창 - 듀얼리스트'] = {};
lv105OptionMap['결전의 투창 - 듀얼리스트'] = '아래 스킬 적중 시 뇌격점혈섬을 강화하는 극 점혈 획득 (최대 30개)<br>- 레조넌스 임페일 : 타격당 10개<br>- 쿼드러플 드라이브 : 타격당 5개<br>- 파동연환창 : 타격당 4개<br>- 트윈 펀토 : 타격당 2개<br>- 퀵 펀토 : 타격당 1개<br><br>보유한 극 점혈 1개당 뇌격점혈섬 강화<br>- 스킬 쿨타임 회복 속도 : +1%<br>- 공격 속도 : +1%<br><br>뇌격점혈섬 공격 시 모든 극 점혈을 소모하여 극 점혈 1개당 창끝 점혈 히트 수 추가 적용<br><br>뇌격점혈섬 공격 속도 +10%@@10672@@2016';

lv105ItemId['적을 향한 신중'] = '6d2b1d80e4200295f8e32740d5a387db';
lv105OptionMap['적을 향한 신중'] = {};
lv105OptionMap['적을 향한 신중'] = '최종 데미지 10% 증가<br>60~70Lv 스킬 공격력 5% 증가<br>스킬 범위 16% 증가<br>60~70Lv 스킬 범위 15% 증가@@6170@@1900';

lv105ItemId['밤을 바라보는 공학자의 시선'] = '0dd1e558c78677765dede8e08bb2c5e4';
lv105OptionMap['밤을 바라보는 공학자의 시선'] = {};
lv105OptionMap['밤을 바라보는 공학자의 시선'] = '50, 85, 100Lv 스킬 공격력 20% 증가<br>50, 85, 100Lv 스킬 쿨타임 회복 속도 +40% (버퍼 제외)<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@6144@@1928';

lv105ItemId['성장하는 모험가의 코어 블레이드'] = 'd407bf4caa6122f513a18adab9cad03a';
lv105OptionMap['성장하는 모험가의 코어 블레이드'] = {};
lv105OptionMap['성장하는 모험가의 코어 블레이드'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['결전의 로드 - 블러드 메이지'] = '0da043770fc6ef048065e0f8d38c2b08';
lv105OptionMap['결전의 로드 - 블러드 메이지'] = {};
lv105OptionMap['결전의 로드 - 블러드 메이지'] = '커럽션 공격 시 블러드비스 2개 획득 (최대 5개)<br>- 쉬카리, 토먼트 시전 시 블러드비스 1개를 소모하여 각각 페이탈 쉬카리, 블러드먼트로 강화<br>- 던전 입장 시 블러드비스 1개 획득<br><br>페이탈 쉬카리<br>- 쉬카리 공격력 50% 증가<br>- 1히트 스킬로 변경<br>- 500px 범위 내 모든 적 공격<br><br>블러드먼트<br>- 토먼트 공격력 50% 증가<br>- 시전 후 이동 가능<br><br>커럽션 쿨타임 10% 감소, 시전 속도 30% 증가@@8892@@2016';

lv105ItemId['내딛은 자의 광창'] = '67380c52402dda23f18393eb2334ca14';
lv105OptionMap['내딛은 자의 광창'] = {};
lv105OptionMap['내딛은 자의 광창'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['광폭화된 전의의 장도'] = '252dc06234336c1b28ff8e4aac7d69ec';
lv105OptionMap['광폭화된 전의의 장도'] = {};
lv105OptionMap['광폭화된 전의의 장도'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['아크 블라스터 소스'] = '7767981f839a0083a3d734199cb0deda';
lv105OptionMap['아크 블라스터 소스'] = {};
lv105OptionMap['아크 블라스터 소스'] = '공격력 증가 +222.3%<br>최종 데미지 16% 증가<br><br>[스톤 스트라이크]<br>석화 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>공격 시 HP 3,000 회복 (쿨타임 1초)<br><br>석화 지속 시간 +2초@@4176@@1920';

lv105ItemId['결전의 빗자루 - 인챈트리스'] = '8663fef10ebdd5b493afcbdccb49fee7';
lv105OptionMap['결전의 빗자루 - 인챈트리스'] = {};
lv105OptionMap['결전의 빗자루 - 인챈트리스'] = '금단의 저주 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>꼼꼼한 바느질 스킬 범위 20% 증가<br><br>테러블 로어 캐스팅 모션 삭제<br>테러블 로어 몹몰이 범위 10% 증가<br><br>커스 오브 테러베어의 브레스에 피격된 적에게 저주의 기운을 부여하고 폭발<br>- 폭발 피해 : 브레스 총 공격력의 50%<br><br>어둠에 피는 장미의 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['결전의 보우건 - 레인저(여)'] = '9bd5f78cfb8a894f67a121ec93a67fd4';
lv105OptionMap['결전의 보우건 - 레인저(여)'] = {};
lv105OptionMap['결전의 보우건 - 레인저(여)'] = '이동사격 탄환을 파열시켜 더욱 넓은 범위 공격<br>- 공격력 30% 증가<br>- 발사 수 50% 증가<br>- 쿨타임 20% 감소<br>- 공격 범위 10% 증가<br><br>이동사격 중 아래의 효과 적용<br>- 스킬 시전 시 쿨타임 즉시 적용<br>- 공격 속도 +30%<br>- 이동 속도 +30%<br>- 던전 방 이동 시 스킬 유지@@7708@@2016';

lv105ItemId['상상을 담은 엔지니어 블라우스'] = '028892800eb702856938f285fdd60f3c';
lv105OptionMap['상상을 담은 엔지니어 블라우스'] = {};
lv105OptionMap['상상을 담은 엔지니어 블라우스'] = '최종 데미지 8% 증가<br><br>스킬을 시전하는 동안 이동 속도 +300%<br><br>모든 파티원에게 슈퍼 아머 부여<br><br>물리/마법 방어력 +7,000<br>HP MAX +600@@6414@@1948';

lv105ItemId['결전의 배틀액스 - 크루세이더(여)'] = '894b8df37a7c03a500ed128080af7b39';
lv105OptionMap['결전의 배틀액스 - 크루세이더(여)'] = {};
lv105OptionMap['결전의 배틀액스 - 크루세이더(여)'] = '용맹의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>치유의 기도 스킬 범위 20% 증가<br><br>홀리 플래쉬가 적을 관통하여 300px 전진 후 폭발<br>- 공격력 100% 증가<br>- 스킬 범위 10% 증가<br><br>홀리 플래쉬 적중 시 그랜드 크로스 크래쉬, 리펜텐스 스매시의 남은 쿨타임 2% 감소<br><br>빛의 은총 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['광폭화된 전의의 광창'] = '63fffa269b95fe4b5c20731d06ab5912';
lv105OptionMap['광폭화된 전의의 광창'] = {};
lv105OptionMap['광폭화된 전의의 광창'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['오랜 시간 품어온 소원'] = '84627aee4e995e9117796f1a9f28a0ed';
lv105OptionMap['오랜 시간 품어온 소원'] = {};
lv105OptionMap['오랜 시간 품어온 소원'] = '[불면증]<br>오랜 시간 품어온 소원을 이루기 위해 잠들지 않으며 최종 데미지 28.5% 증가<br>- 최종 데미지 증가 옵션은 장비로 인한 수면 내성 수치의 총합이 -70% 이하일 때 적용<br><br>물리/마법 크리티컬 히트 +7%<br>회피율 +8%<br>모든 상태 이상 내성 -10%<br>수면 내성 -10%@@4972@@1963';

lv105ItemId['홀로그램 콜'] = 'bc7befe3a3f672448b2e10215b5b5ba7';
lv105OptionMap['홀로그램 콜'] = {};
lv105OptionMap['홀로그램 콜'] = '1~30Lv 스킬 공격력 12% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +8%<br>물리/마법 피해 감소 +15%@@6144@@1910';

lv105ItemId['내딛은 자의 보우건'] = '4bc8858c272fe936826c18581a72c91f';
lv105OptionMap['내딛은 자의 보우건'] = {};
lv105OptionMap['내딛은 자의 보우건'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 도 - 다크나이트'] = '267af8e18d9912e5b4c03ac638a3cdf0';
lv105OptionMap['결전의 도 - 다크나이트'] = {};
lv105OptionMap['결전의 도 - 다크나이트'] = '타임 스톱 최대 콤보 스택 1단계 증가<br>- 콤보 스택 6단계 공격력 : 220%<br><br>시간에 간섭하여 타임로드 효과 강화<br>- 다른 콤보 슬롯 스킬 사용 시 타임 스톱 스택 획득<br>- 확장 콤보 슬롯 스킬 시전 시 기존 콤보 스킬 유지<br>- 타임 로드 효과 지속 시간 +2초@@10672@@2016';

lv105ItemId['맹렬한 위세'] = '13e64a09c0c8b91949ec6dac3afb5687';
lv105OptionMap['맹렬한 위세'] = {};
lv105OptionMap['맹렬한 위세'] = '출혈 피해 +15%<br><br>출혈 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5중첩)<br><br>공격 시 모든 적에게 10초 동안 출혈 상태 이상 부여 (쿨타임 5초)<br><br>공격 시 HP 2200 회복 (쿨타임 1초)<br><br>출혈 내성 +10%@@4647@@1953';

lv105ItemId['작은 풀잎의 순수함'] = '5bdaab4a7412cf680281d3e047f39320';
lv105OptionMap['작은 풀잎의 순수함'] = {};
lv105OptionMap['작은 풀잎의 순수함'] = '공격력 증가 +222.3%<br>최종 데미지 15% 증가<br><br>기절 상태 적 공격 시 30초 동안 최종 데미지 15% 증가<br><br>공격 시 EMP 쇼크 발생 (쿨타임 1.5초)<br>- 총 공격력 증가의 800%만큼 피해<br><br>감전 상태 적 공격 시 5초 동안 기절 상태 이상 부여 (쿨타임 8초)<br><br>기절 지속 시간 +2초@@3906@@1948';

lv105ItemId['골렘의 심장 목걸이'] = '8750590d2eaddaea7633c7d66f9e705d';
lv105OptionMap['골렘의 심장 목걸이'] = {};
lv105OptionMap['골렘의 심장 목걸이'] = '최종 데미지 10% 증가<br><br>공격 시 수속성 폭발 발생 (쿨타임 5초)<br>- 총 공격력 증가 수치의 480%만큼 피해<br>- 자신에게는 1의 피해<br><br>물리/마법 방어력 +7,000<br>수속성 저항 +10@@6414@@1958';

lv105ItemId['에너지 서치 링'] = 'b7afabb43e296c24f9e5d53fcb4f63bb';
lv105OptionMap['에너지 서치 링'] = {};
lv105OptionMap['에너지 서치 링'] = '최종 데미지 12% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>모든 속성 저항 +8@@3988@@1948';

lv105ItemId['올 오어 원 매직박스'] = '4bf9eb661b8da8fa5be7351ee8703e83';
lv105OptionMap['올 오어 원 매직박스'] = {};
lv105OptionMap['올 오어 원 매직박스'] = '[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5604@@1612';

lv105ItemId['결전의 완드 - 사령술사'] = '79837453cbc46356f60990bb5bf96c27';
lv105OptionMap['결전의 완드 - 사령술사'] = {};
lv105OptionMap['결전의 완드 - 사령술사'] = '리빙데드를 최대 3회 충전 스킬로 변경<br><br>레디아 블랙옥스가 직접 폭발<br>- 공격력 : 리빙데드 총 공격력의 45%<br>- 충전 쿨타임 : 15초<br>- 적에게 5초 동안 공포의 낙인 생성<br>- 추가 조작 기능 삭제<br><br>공포의 낙인이 생성된 적을 아래의 스킬로 공격 시 해당 스킬 남은 쿨타임 10% 감소<br>- 망자의 집념<br>- 블랙 아라크네아<br>- 익스큐셔너 슬래쉬@@8892@@2016';

lv105ItemId['성장하는 모험가의 단검'] = 'b56039fa99903c5a719b1be6b77d9d12';
lv105OptionMap['성장하는 모험가의 단검'] = {};
lv105OptionMap['성장하는 모험가의 단검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['미지의 문명 - 스타 스톤'] = '06ca5053e2255d7c4074147e3866ccb0';
lv105OptionMap['미지의 문명 - 스타 스톤'] = {};
lv105OptionMap['미지의 문명 - 스타 스톤'] = '최종 데미지 10% 증가<br><br>[속성 친화 - 수]<br>수속성 저항이 높아질수록 물리/마법 피해 감소<br>- 적의 공격에 수속성 부여<br><br>피격 시 5초 동안 HP 5% 회복 (쿨타임 7초)<br><br>HP MAX +600<br>수속성 저항 +35@@5604@@1948';

lv105ItemId['근원을 삼킨 둔기'] = '191e9656ce662ce2fd612c84531b522a';
lv105OptionMap['근원을 삼킨 둔기'] = {};
lv105OptionMap['근원을 삼킨 둔기'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['핏빛의 결정 반지'] = '1a17f4ecc09e755ede2b92d8ad399654';
lv105OptionMap['핏빛의 결정 반지'] = {};
lv105OptionMap['핏빛의 결정 반지'] = '공격력 증가 +444.6%<br>출혈 피해 +25%<br><br>자신이 부여하는 출혈 지속 시간 -10%@@3368@@1973';

lv105ItemId['결전의 스태프 - 인챈트리스'] = '3c58781938be47582b5de02eb6fb8b0c';
lv105OptionMap['결전의 스태프 - 인챈트리스'] = {};
lv105OptionMap['결전의 스태프 - 인챈트리스'] = '금단의 저주 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>꼼꼼한 바느질 스킬 범위 20% 증가<br><br>테러블 로어 캐스팅 모션 삭제<br>테러블 로어 몹몰이 범위 10% 증가<br><br>커스 오브 테러베어의 브레스에 피격된 적에게 저주의 기운을 부여하고 폭발<br>- 폭발 피해 : 브레스 총 공격력의 50%<br><br>어둠에 피는 장미의 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['청연을 지키는 힘'] = '0372425a57695fedd81d3b5aeba336f0';
lv105OptionMap['청연을 지키는 힘'] = {};
lv105OptionMap['청연을 지키는 힘'] = '공격력 증가 +251.9%<br>모든 속성 강화 +15<br><br>공격 시 안개 벼락 1회 시전 (쿨타임 1초)<br>- 10% 확률로 강화된 안개 벼락 시전<br>- 안개 벼락과 강화된 안개 벼락은 각각 총 공격력 증가의 1,000%, 1,480%만큼 피해<br><br>공격 속도 +18%<br>캐스팅 속도 +22%<br>이동 속도 +18%@@7222@@1928';

lv105ItemId['골드 윙 반지'] = 'b92733a01bd3e559a2ed7c61306f2946';
lv105OptionMap['골드 윙 반지'] = {};
lv105OptionMap['골드 윙 반지'] = '최종 데미지 5% 증가<br>모든 속성 강화 +20<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>물리/마법 피해 감소 +20%@@6858@@1963';

lv105ItemId['근원을 삼킨 소검'] = '6250a5bf265d32114371e2c22bb33358';
lv105OptionMap['근원을 삼킨 소검'] = {};
lv105OptionMap['근원을 삼킨 소검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['아토믹 코어 네클레스'] = '064715eb64c51a8cd840e001563219e3';
lv105OptionMap['아토믹 코어 네클레스'] = {};
lv105OptionMap['아토믹 코어 네클레스'] = '스킬 쿨타임 회복 속도 +25% (각성기 제외)<br>모든 속성 강화 +20<br><br>공격 속도 +15%<br>캐스팅 속도 +15%<br>이동 속도 +15%<br>HP 1분당 460.2 회복@@4702@@1943';

lv105ItemId['얼어붙은 저항의 단검'] = '5647b4e8e27a21a02659fb4393846b2b';
lv105OptionMap['얼어붙은 저항의 단검'] = {};
lv105OptionMap['얼어붙은 저항의 단검'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['광폭화된 전의의 대검'] = 'cab73346f3cf368f50a4d633c809106c';
lv105OptionMap['광폭화된 전의의 대검'] = {};
lv105OptionMap['광폭화된 전의의 대검'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['얼어붙은 저항의 미늘창'] = '63b5bfa2d972514442c28c7e1793a8d2';
lv105OptionMap['얼어붙은 저항의 미늘창'] = {};
lv105OptionMap['얼어붙은 저항의 미늘창'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['억제된 마력의 팔찌'] = '6354671471743553243cab7ccc79a748';
lv105OptionMap['억제된 마력의 팔찌'] = {};
lv105OptionMap['억제된 마력의 팔찌'] = '공격력 증가 +385.3%<br>최종 데미지 15% 증가<br><br>[금단의 마력 계약]<br>금지되어 있는 마력 계약을 체결하여 피를 잃고 마력과 생명을 연결<br>- 스킬 시전, 피격 시 HP 대신 MP 소모<br>- HP 회복량의 25%만큼 MP 회복<br>- HP MAX 99% 제한<br><br>MP MAX +4,196<br>물리/마법 방어력 +7,000<br>MP 1분당 348 회복@@5604@@1968';

lv105ItemId['라이트 어댑터 팬츠'] = '0b148001bead9337b695187e71f1d4da';
lv105OptionMap['라이트 어댑터 팬츠'] = {};
lv105OptionMap['라이트 어댑터 팬츠'] = '명속성 강화 +70<br><br>명속성 저항 +20@@3450@@1958';

lv105ItemId['결전의 중검 - 트러블 슈터'] = '390e559eb1669365a4513ad85d7c951a';
lv105OptionMap['결전의 중검 - 트러블 슈터'] = {};
lv105OptionMap['결전의 중검 - 트러블 슈터'] = '번 투 슬래쉬 시전 시 3초 동안 전직 스킬 공격력 20% 증가 (각성기 제외)<br>- 후딜레이를 캔슬하여 다른 전직 스킬 사용 가능<br>- 다른 전직 스킬의 후딜레이를 캔슬하여 번 투 슬래쉬 사용 가능<br>- 추가 공격 입력 시간 3초로 증가<br>- 스킬 종료 후 쿨타임 적용<br><br>번 투 슬래쉬가 타격하지 않아도 폭발 발생<br>- 스킬 쿨타임 35% 감소<br>- 스킬 범위 10% 증가@@6520@@2016';

lv105ItemId['결전의 창 - 인챈트리스'] = '33eb6b2f0ce84edb6213a1d9dd919447';
lv105OptionMap['결전의 창 - 인챈트리스'] = {};
lv105OptionMap['결전의 창 - 인챈트리스'] = '금단의 저주 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>꼼꼼한 바느질 스킬 범위 20% 증가<br><br>테러블 로어 캐스팅 모션 삭제<br>테러블 로어 몹몰이 범위 10% 증가<br><br>커스 오브 테러베어의 브레스에 피격된 적에게 저주의 기운을 부여하고 폭발<br>- 폭발 피해 : 브레스 총 공격력의 50%<br><br>어둠에 피는 장미의 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['무모하지만 합리적인 작전'] = '74ec24105f67cf9c71ce72dbc3dbf8af';
lv105OptionMap['무모하지만 합리적인 작전'] = {};
lv105OptionMap['무모하지만 합리적인 작전'] = '최종 데미지 9% 증가<br><br>물리/마법 피해 감소 +10%<br>물리/마법 방어력 +7,000<br>MP 1분당 348 회복@@4258@@1938';

lv105ItemId['성장하는 모험가의 상의'] = 'null';
lv105OptionMap['성장하는 모험가의 상의'] = {};
lv105OptionMap['성장하는 모험가의 상의'] = '최종 데미지 7% 증가@@533@@1986';

lv105ItemId['화음하는 음색'] = '53cb49e33ff3519e7c3bc1ffe3988521';
lv105OptionMap['화음하는 음색'] = {};
lv105OptionMap['화음하는 음색'] = '최종 데미지 15% 증가<br>모든 속성 강화 +25<br><br>스킬, 장비로 인한 HP 회복 효과 20% 증가<br><br>HP MAX +600<br>MP MAX +945@@4528@@1928';

lv105ItemId['폭룡왕의 지배 - 무력'] = '81da1fa194cefcf0166b728e7b8a2e56';
lv105OptionMap['폭룡왕의 지배 - 무력'] = {};
lv105OptionMap['폭룡왕의 지배 - 무력'] = '최종 데미지 15% 증가<br>모든 속성 강화 +30<br><br>3초마다 HP/MP 1% 회복<br><br>HP MAX +600<br>MP MAX +945@@5066@@1900';

lv105ItemId['결전의 클로 - 스트리트파이터(남)'] = '0b5e3974d5fdd96aa6074dd8a300b440';
lv105OptionMap['결전의 클로 - 스트리트파이터(남)'] = {};
lv105OptionMap['결전의 클로 - 스트리트파이터(남)'] = '크레이지 발칸 시전 시 화염병 폭탄을 내려치도록 변경<br>- 마운트 마지막 충격파 삭제<br>- 주변 적에게 잡힌 적과 동일한 데미지 적용<br>- 잡기 실패 시에도 발동<br><br>크레이지 발칸 상태 이상 보너스 추가<br>- 공격 범위 : 1개당 10%<br>- 쿨타임 감소 : 1개당 10%@@9484@@2016';

lv105ItemId['고고함의 백색 벨트'] = '19776f2e9cd9be54e84609e4c0aa1105';
lv105OptionMap['고고함의 백색 벨트'] = {};
lv105OptionMap['고고함의 백색 벨트'] = '최종 데미지 8% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5604@@1612';

lv105ItemId['안개의 뜻을 따르는 자의 상의'] = '638f3a2d3a65f23610cb096c9fb5bade';
lv105OptionMap['안개의 뜻을 따르는 자의 상의'] = {};
lv105OptionMap['안개의 뜻을 따르는 자의 상의'] = '최종 데미지 10% 증가<br>무력화 게이지 감소량 +10%<br><br>HP MAX +600<br>물리/마법 방어력 +7,000@@6144@@1928';

lv105ItemId['결전의 장도 - 스페셜리스트'] = 'e2198cef076a9381b12f723e0c748a4b';
lv105OptionMap['결전의 장도 - 스페셜리스트'] = {};
lv105OptionMap['결전의 장도 - 스페셜리스트'] = '인텐션 스택 사용 시 인텐션 스택 1개를 추가 소모하여 5초 동안 스킬 쿨타임 20% 감소 (각성기 제외)<br><br>CEAB-2를 인텐션 스택으로 강화 가능<br>- 강화 시 최대 충전 공격력 40% 증가<br>- 강화 시 다단히트 수 60% 충전 상태로 발동<br><br>인텐션 기본 스택 수 +2@@711@@2016';

lv105ItemId['성장하는 모험가의 선현궁'] = 'd2aa5c56e52f97a454cc7f328cd82299';
lv105OptionMap['성장하는 모험가의 선현궁'] = {};
lv105OptionMap['성장하는 모험가의 선현궁'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['광폭화된 전의의 머스켓'] = '56b10da13bec7688c9168a47f5df0f93';
lv105OptionMap['광폭화된 전의의 머스켓'] = {};
lv105OptionMap['광폭화된 전의의 머스켓'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 배틀액스 - 인파이터'] = '014cd73bfc684e61421da10a080dcaf2';
lv105OptionMap['결전의 배틀액스 - 인파이터'] = {};
lv105OptionMap['결전의 배틀액스 - 인파이터'] = '정의 집행 : 레미디오스의 성좌의 링크 스킬이 빅뱅 펀치일 때, 신의 분노를 최대 3회 충전 스킬로 변경<br>- 연타 공격력 100% 증가<br>- 연타 횟수 9회 감소<br>- 피니쉬 타격 및 폭발 삭제<br>- 적을 추적하여 공격<br>- 충전 쿨타임 : 40초<br>- 추적 범위 : 780px<br>- MP 소모량 -30%<br>- 무색 큐브 조각 소모량 5개 감소<br>- 머신건 잽 시전 시 신의 분노 남은 쿨타임 12% 감소<br>- 신의 분노를 캔슬하여 전직 스킬 시전 가능<br>- 전직 스킬을 캔슬하여 신의 분노 시전 시 드라이아웃 스택을 소모하지 않음@@5928@@2016';

lv105ItemId['얼어붙은 저항의 완드'] = '0a8a82bdf02854692d464d9e972c4e74';
lv105OptionMap['얼어붙은 저항의 완드'] = {};
lv105OptionMap['얼어붙은 저항의 완드'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 로드 - 인챈트리스'] = '500f04533c294a1acb2adde21aa93ebe';
lv105OptionMap['결전의 로드 - 인챈트리스'] = {};
lv105OptionMap['결전의 로드 - 인챈트리스'] = '금단의 저주 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>꼼꼼한 바느질 스킬 범위 20% 증가<br><br>테러블 로어 캐스팅 모션 삭제<br>테러블 로어 몹몰이 범위 10% 증가<br><br>커스 오브 테러베어의 브레스에 피격된 적에게 저주의 기운을 부여하고 폭발<br>- 폭발 피해 : 브레스 총 공격력의 50%<br><br>어둠에 피는 장미의 독립 공격력 증가량 20% 추가 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['결전의 단검 - 로그'] = '5068d459dd3fd8c8b1d2ec9e0d27e19a';
lv105OptionMap['결전의 단검 - 로그'] = {};
lv105OptionMap['결전의 단검 - 로그'] = '허리케인을 최대 3회 충전 스킬로 변경<br>- 타격당 습득 히트포인트 100% 증가<br>- 이동속도 30% 증가<br>- 끌어들이는 힘 30% 증가<br><br>허리케인 시전 중 히트엔드 시전 시 아래의 효과 적용<br>- 남은 허리케인의 타격 데미지를 합산하여 공격<br>- 가장 강한 적에게 남은 단검 발사<br><br>데스 허리케인의 단검이 적을 관통하도록 변경되고, 발사 수 10개 감소@@9484@@2016';

lv105ItemId['상상을 담은 엔지니어의 작업 신발'] = '0855ec32a4da2091b3f010c152629074';
lv105OptionMap['상상을 담은 엔지니어의 작업 신발'] = {};
lv105OptionMap['상상을 담은 엔지니어의 작업 신발'] = '최종 데미지 19% 증가<br><br>이동 속도 +8%<br>물리/마법 피해 감소 +20%<br>적중률 +10%@@6144@@1948';

lv105ItemId['무뎌지는 둔화된 변화'] = 'cbd037a31b281da9b5064c9bae6df1c4';
lv105OptionMap['무뎌지는 둔화된 변화'] = {};
lv105OptionMap['무뎌지는 둔화된 변화'] = '최종 데미지 14% 증가<br>모든 속성 강화 +30<br><br>자신에게 해제되지 않는 화상 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +5%<br>HP MAX +600@@5066@@1948';

lv105ItemId['결전의 소태도 - 요원'] = '1941358bb6904f91b3fd8f890c329057';
lv105OptionMap['결전의 소태도 - 요원'] = {};
lv105OptionMap['결전의 소태도 - 요원'] = '암살목표의 표식이 부여된 적에게 일부 스킬 공격 시 15초 동안 총상 / 절상 부여 (최대 3중첩)<br>- 총상 부여 스킬 : 조준 사격, 목표 사격<br>- 절상 부여 스킬 : 쌍현참, 암월비보<br><br>총상 / 절상 상태의 적을 아래의 스킬로 공격 시 총상 / 절상이 해제되며 스킬 공격력 증가<br>- 문페이즈 : 총상 1중첩당 8%<br>- 섬멸 : 절상 1중첩당 8%<br>- 월광진혼곡 : 총상 / 절상 1중첩당 8%<br><br>아래의 스킬 쿨타임 15% 감소<br>- 쌍현참<br>- 조준 사격<br>- 암월비보<br>- 목표 사격<br><br>문페이즈 크기 10% 증가@@7112@@2016';

lv105ItemId['내딛은 자의 토템'] = 'aaee94f9149957353a543c5eda83c696';
lv105OptionMap['내딛은 자의 토템'] = {};
lv105OptionMap['내딛은 자의 토템'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['블랙 캣 헬멧'] = '0d6217278b7a421abee79e93a76e6e25';
lv105OptionMap['블랙 캣 헬멧'] = {};
lv105OptionMap['블랙 캣 헬멧'] = '1~30Lv 스킬 공격력 10% 증가<br>기본기 숙련 최종 데미지 20% 증가<br><br>평타, 점프 공격, 대시 공격 시 10% 확률로 공격력 300% 증가<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>적중률 +10%@@4528@@1968';

lv105ItemId['결전의 봉 - 배틀메이지'] = 'a647592eff952cb3b11fd101aff01dbc';
lv105OptionMap['결전의 봉 - 배틀메이지'] = {};
lv105OptionMap['결전의 봉 - 배틀메이지'] = '체이서 공격력 및 사출 개수 증가<br>- 한 번에 사출되는 체이서 개수 +2<br>- 공격력 45% 감소<br>- 50콤보 이상일 때 체이서 자동 사출<br>- 체이서 최대 생성 개수 +1<br>- 체이서 크기 비율 20% 증가<br>- 스킬 MP 소모량 -70%<br><br>한 번에 생성되는 체이서 개수 +1@@4744@@2016';

lv105ItemId['결전의 자동권총 - 레인저(여)'] = '39df90eef8bea220563e52e3e04174ec';
lv105OptionMap['결전의 자동권총 - 레인저(여)'] = {};
lv105OptionMap['결전의 자동권총 - 레인저(여)'] = '이동사격 탄환을 파열시켜 더욱 넓은 범위 공격<br>- 공격력 30% 증가<br>- 발사 수 50% 증가<br>- 쿨타임 20% 감소<br>- 공격 범위 10% 증가<br><br>이동사격 중 아래의 효과 적용<br>- 스킬 시전 시 쿨타임 즉시 적용<br>- 공격 속도 +30%<br>- 이동 속도 +30%<br>- 던전 방 이동 시 스킬 유지@@7708@@2016';

lv105ItemId['매니퓰레이션'] = '1447a94cebb0339fe3219d8002116e3a';
lv105OptionMap['매니퓰레이션'] = {};
lv105OptionMap['매니퓰레이션'] = '최종 데미지 13% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>스킬 MP 소모량 7% 감소@@4528@@1996';

lv105ItemId['자기장 탐지링'] = '49ff8f4240b7f3ec42d6989f4fc57f09';
lv105OptionMap['자기장 탐지링'] = {};
lv105OptionMap['자기장 탐지링'] = '최종 데미지 9% 증가<br><br>[인력 탐지]<br>다른 파티원이 "자기장 탐지링" 장착 시 최종 데미지 4% 증가<br><br>물리/마법 방어력 +7,000<br>회피율 +8%@@4258@@1920';

lv105ItemId['성장하는 모험가의 낡은 메달'] = '074990bbe6162ee6b0468bc7cca0321f';
lv105OptionMap['성장하는 모험가의 낡은 메달'] = {};
lv105OptionMap['성장하는 모험가의 낡은 메달'] = '최종 데미지 7% 증가@@5336@@1986';

lv105ItemId['성장하는 모험가의 클로'] = '4b1f419bf14185a22bb26e893ab5c0f4';
lv105OptionMap['성장하는 모험가의 클로'] = {};
lv105OptionMap['성장하는 모험가의 클로'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 로드 - 빙결사'] = '8a1e26a24e419baa7510834ccec77899';
lv105OptionMap['결전의 로드 - 빙결사'] = {};
lv105OptionMap['결전의 로드 - 빙결사'] = '아이스 크래프트 얼음창이 얼음 무기에 피격된 모든 적을 추적하여 꿰뚫음<br>- 얼음창 공격력 반영률 20% 추가 증가<br>- 얼음창 폭발 삭제<br>- 얼음창이 10% 확률로 적에게 2초 동안 빙결 상태 이상 부여<br><br>핀포인트 러쉬 시전 시 전방의 가장 강력한 적을 추적하여 주변의 적을 함께 공격<br>- 추적 범위 : 500px@@7112@@2016';

lv105ItemId['파워 플랜트'] = '2bd0eccd126fcdde214f75cb15f04776';
lv105OptionMap['파워 플랜트'] = {};
lv105OptionMap['파워 플랜트'] = '최종 데미지 8% 증가<br>스킬 쿨타임 회복 속도 +15% (각성기 제외)<br><br>공격 시 HP/MP 2,000 회복 (쿨타임 1초)<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>스킬 MP 소모량 -7%@@6414@@1968';

lv105ItemId['근원을 삼킨 도'] = 'dd76519f50159d14d778cd5ef8d0a1db';
lv105OptionMap['근원을 삼킨 도'] = {};
lv105OptionMap['근원을 삼킨 도'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>명속성 저항 +10<br>HP 1분당 460.2 회복@@5336@@1996';

lv105ItemId['유쾌함 속의 열망'] = '26c58098c8d61c0cbf06b068b4e349a7';
lv105OptionMap['유쾌함 속의 열망'] = {};
lv105OptionMap['유쾌함 속의 열망'] = '최종 데미지 11% 증가<br><br>[주사위 굴리기]<br>30초마다 주사위를 굴려 나온 눈 1마다 이동 속도 +2%<br>- 공격 시 주사위 눈 +1<br><br>물리/마법 방어력 +10,000<br>적중률 +10%<br>HP 1분당 460.2 회복@@5066@@1968';

lv105ItemId['광폭화된 전의의 장창'] = '3600e78ae1c052e321d1b3fb26f718b9';
lv105OptionMap['광폭화된 전의의 장창'] = {};
lv105OptionMap['광폭화된 전의의 장창'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['성장하는 모험가의 차크라 웨펀'] = '2bddd6d828eff7f77ed64a20226cf61e';
lv105OptionMap['성장하는 모험가의 차크라 웨펀'] = {};
lv105OptionMap['성장하는 모험가의 차크라 웨펀'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['들이치는 희미한 탄식'] = '5670a44246fc1610b6d1fef45f9041ac';
lv105OptionMap['들이치는 희미한 탄식'] = {};
lv105OptionMap['들이치는 희미한 탄식'] = '최종 데미지 15% 증가<br><br>물리/마법 방어력 +7,000<br>MP 1분당 348 회복@@5874@@1958';

lv105ItemId['옥화의 망령 상박갑'] = '0581f407983a4e2b5cc34ef7d988454d';
lv105OptionMap['옥화의 망령 상박갑'] = {};
lv105OptionMap['옥화의 망령 상박갑'] = '최종 데미지 11% 증가<br><br>[배후 암살]<br>- 가장 강한 적에게 징표 생성<br>- 백스텝 시전 시 적의 후방으로 이동 (쿨타임 10초)<br><br>물리/마법 방어력 +7,000<br>모든 상태 이상 내성 +10%<br>회피율 +8%@@5604@@1920';

lv105ItemId['배회하는 혼령의 향로'] = '0f63eccb7d33ad89e615c2da1239c874';
lv105OptionMap['배회하는 혼령의 향로'] = {};
lv105OptionMap['배회하는 혼령의 향로'] = '최종 데미지 10% 증가<br>스킬 쿨타임 회복 속도 +20% (각성기 제외)<br><br>대기, 걷기 시 이동 속도 +200% / Y축 이동 속도 +50%<br><br>물리/마법 방어력 +7,000<br>회피율 +8%@@4528@@1948';

lv105ItemId['결전의 단검 - 사령술사'] = '448c44507db3fec8174c441d565777ad';
lv105OptionMap['결전의 단검 - 사령술사'] = {};
lv105OptionMap['결전의 단검 - 사령술사'] = '리빙데드를 최대 3회 충전 스킬로 변경<br><br>레디아 블랙옥스가 직접 폭발<br>- 공격력 : 리빙데드 총 공격력의 45%<br>- 충전 쿨타임 : 15초<br>- 적에게 5초 동안 공포의 낙인 생성<br>- 추가 조작 기능 삭제<br><br>공포의 낙인이 생성된 적을 아래의 스킬로 공격 시 해당 스킬 남은 쿨타임 10% 감소<br>- 망자의 집념<br>- 블랙 아라크네아<br>- 익스큐셔너 슬래쉬@@8892@@2016';

lv105ItemId['얼어붙은 저항의 십자가'] = 'ccd204a27f657ff93889436ee63203e6';
lv105OptionMap['얼어붙은 저항의 십자가'] = {};
lv105OptionMap['얼어붙은 저항의 십자가'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['근원을 삼킨 토템'] = 'a4f4624a3387a0e163190394d7abc300';
lv105OptionMap['근원을 삼킨 토템'] = {};
lv105OptionMap['근원을 삼킨 토템'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['내딛은 자의 코어 블레이드'] = 'a9bc9fa75ada95efc911668bc5f2ddf7';
lv105OptionMap['내딛은 자의 코어 블레이드'] = {};
lv105OptionMap['내딛은 자의 코어 블레이드'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['사멸하는 신뢰의 클로'] = '42e58da97a81890214fcddcd31c1845a';
lv105OptionMap['사멸하는 신뢰의 클로'] = {};
lv105OptionMap['사멸하는 신뢰의 클로'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['성장하는 모험가의 권투글러브'] = '64c087735a02dd6304fe6cadf2d12772';
lv105OptionMap['성장하는 모험가의 권투글러브'] = {};
lv105OptionMap['성장하는 모험가의 권투글러브'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +945<br>암속성 저항 +10@@5336@@1996';

lv105ItemId['푸른 자연의 씨앗'] = '44dfc7da6982301231dcd9ee9c22e979';
lv105OptionMap['푸른 자연의 씨앗'] = {};
lv105OptionMap['푸른 자연의 씨앗'] = '모든 속성 강화 +20<br>화상-빙결 연계로 인한 화상 피해 +35%<br><br>화상 지속 시간 +4초<br><br>공격 시 모든 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 5초)<br><br>수속성 저항 +10@@5604@@1948';

lv105ItemId['선회하는 흐려진 혜안'] = '2bba3d7f981f1755ad97b4684bae84a8';
lv105OptionMap['선회하는 흐려진 혜안'] = {};
lv105OptionMap['선회하는 흐려진 혜안'] = '최종 데미지 13% 증가<br><br>[배터리 폭탄]<br>공격 시 과열되어 폭발하는 배터리를 몸에 장착<br>- 5회 공격 시 배터리 과열<br>- 과열된 배터리는 3초 후 폭발<br>- 총 공격력 증가의 2,000%만큼 피해<br><br>물리/마법 크리티컬 히트 +7%<br>공격 속도 +20%<br>캐스팅 속도 +20%<br>이동 속도 +20%<br>회피율 +8%<br>HP 1분당 460.2 회복@@5604@@1948';

lv105ItemId['결전의 머스켓 - 어썰트'] = '50557d454a23c6bb31d2e5e3b83c2d42';
lv105OptionMap['결전의 머스켓 - 어썰트'] = {};
lv105OptionMap['결전의 머스켓 - 어썰트'] = '부스트 스킬로 전직 스킬 캔슬 시 전력 1스택 획득 (최대 5스택)<br><br>아래 스킬 시전 시 모든 전력을 소모하고, 소모한 전력당 공격력 10% 증가<br>- 레이시온 크리트<br>- 부스트 : 다이너마이트 킥<br><br>부스트 : 다이너마이트 킥 적중 시 적을 1.5초 동안 홀딩<br>- 소모한 전력 1스택당 홀딩 시간 0.5초 증가@@9484@@2016';

lv105ItemId['고양된 분노의 목걸이'] = '0ecc3af18fef87bd5615acb3178be133';
lv105OptionMap['고양된 분노의 목걸이'] = {};
lv105OptionMap['고양된 분노의 목걸이'] = '최종 데미지 9% 증가<br>모든 속성 강화 +20<br><br>200콤보 달성 시 보스/네임드 몬스터의 남은 HP 1% 감소 (쿨타임 30초 / 버퍼 제외)<br>- 최대 5% 감소 가능<br><br>MP 1분당 348 회복@@5240@@1953';

lv105ItemId['얼어붙은 저항의 클로'] = '68f2293dfc4082d20ea4db0cca59cce0';
lv105OptionMap['얼어붙은 저항의 클로'] = {};
lv105OptionMap['얼어붙은 저항의 클로'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['페이션트 나이트 숄더'] = '3ba0e13cc20622664bae1846c072de80';
lv105OptionMap['페이션트 나이트 숄더'] = {};
lv105OptionMap['페이션트 나이트 숄더'] = '최종 데미지 10% 증가<br>30~35Lv 스킬 공격력 5% 증가<br>스킬 범위 16% 증가<br>30~35Lv 스킬 범위 15% 증가@@6440@@1900';

lv105ItemId['깨지지 않는 빛바랜 신념'] = '1f4842f48c789643edfa8e2460c642c2';
lv105OptionMap['깨지지 않는 빛바랜 신념'] = {};
lv105OptionMap['깨지지 않는 빛바랜 신념'] = '암속성 강화 +70<br><br>암속성 저항 +20@@3450@@1958';

lv105ItemId['결전의 창 - 스위프트 마스터'] = '7e03eb08f1398d3e1aec56147fe58d68';
lv105OptionMap['결전의 창 - 스위프트 마스터'] = {};
lv105OptionMap['결전의 창 - 스위프트 마스터'] = '소닉 무브를 최대 3회 충전 스킬로 변경<br><br>소닉 무브 시전 시 회오리 바람 생성<br>- 소닉 무브 회오리 바람 공격력 : 회오리 바람 공격력의 50%<br><br>회오리 바람 크기 10% 증가<br><br>전직 스킬을 캔슬하여 소닉 무브, 대진공, 칼날 바람 시전 시 풍흔 1스택 획득 (최대 15스택)<br>- 풍흔이 15스택일 때 스톰 스트라이크 시전 시 모든 풍흔을 소모하여 공격력 50% 증가@@7112@@2016';

lv105ItemId['잠겨진 영역'] = 'd6fd0607a7a56808824dfcd65267862c';
lv105OptionMap['잠겨진 영역'] = {};
lv105OptionMap['잠겨진 영역'] = '모든 속성 강화 +20<br><br>[황금 만능주의]<br>인벤토리 및 금고에 보관 중인 골드가 4억 골드 이상일 때 최종 데미지 9% 증가<br><br>물리/마법 크리티컬 히트 +17%<br>공격 속도 +15%<br>캐스팅 속도 +15%<br>이동 속도 +15%<br>모든 상태 이상 내성 -10%@@5416@@1978';

lv105ItemId['결전의 대검 - 소울브링어'] = '0a5eabd58bd39deba79e7e85a8728375';
lv105OptionMap['결전의 대검 - 소울브링어'] = {};
lv105OptionMap['결전의 대검 - 소울브링어'] = '명계의 힘으로 흑염의 칼라가 명계의 불꽃을 발현<br>불꽃 최대 중첩 수 +2<br><br>아래 스킬들이 명계의 불꽃을 발현<br>명계의 불꽃이 붙은 대상을 특정 스킬로 공격 시 불꽃 폭발<br>- 폭발 공격력 :  흑염의 칼라 불꽃 공격력의 300%<br>- 불꽃 중첩당 폭발 공격력 10% 증가<br><br><명계의 불꽃 발현 스킬><br>- 흑염의 칼라<br>- 냉기의 사야<br>- 역병의 라사<br>- 툼스톤<br>- 툼스톤 스웜프<br>- 블레이드 팬텀<br><br><명계의 불꽃 폭발 스킬><br>- 귀참<br>- 달빛 베기<br>- 귀참 : 광폭<br>- 귀영섬<br>- 귀참 : 나락<br>- 흑염검<br>- 검귀강령 : 식<br>- 탐식의 크레바스@@5928@@2016';

lv105ItemId['미지의 문명 - 마스크 스톤'] = '72e6886ad80d5ae4e6576105da6e03c0';
lv105OptionMap['미지의 문명 - 마스크 스톤'] = {};
lv105OptionMap['미지의 문명 - 마스크 스톤'] = '최종 데미지 7% 증가<br><br>물리/마법 크리티컬 히트 +12%<br>HP MAX +600<br>수속성 저항 +50<br>모든 상태 이상 내성 -10%@@5510@@1963';

lv105ItemId['생명을 키우는 코발트 스틱'] = '88003cc317d46270bf0166fc09b0aaf0';
lv105OptionMap['생명을 키우는 코발트 스틱'] = {};
lv105OptionMap['생명을 키우는 코발트 스틱'] = '명속성 강화 +70<br><br>물리/마법 크리티컬 히트 +15%<br>명속성 저항 +10@@4528@@1958';

lv105ItemId['결전의 핸드캐넌 - 런처(여)'] = '418cbae359038b267bcf35ee02d86c31';
lv105OptionMap['결전의 핸드캐넌 - 런처(여)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 런처(여)'] = '화염 강타를 화염을 압축하여 발사하는 최대 3회 충전 스킬로 변경<br>- 공격력 : 총 공격력의 50%<br>- 충전 쿨타임 : 4초<br><br>M-3 화염방사기 시전 시 화염을 압축하여 발사<br><br>UHT-03 플레게톤 이동 속도 비율 +10%@@9484@@2016';

lv105ItemId['결전의 대검 - 데몬슬레이어'] = '7ae5468e0d81b999be9aa6d864f044ff';
lv105OptionMap['결전의 대검 - 데몬슬레이어'] = {};
lv105OptionMap['결전의 대검 - 데몬슬레이어'] = '사복검 - 발을 최대 3회 충전 스킬로 변경<br>- 솟아오르는 검 개수 +3<br>- 전직 스킬 공격 시 1회 충전 (사복검 - 발 제외)<br>- 자동 충전 불가능<br><br>전직 스킬의 후딜레이를 캔슬하여 사복검 - 발 시전 가능 (각성기 제외)<br><br>사복검 - 발 후딜레이를 캔슬하여 전직 스킬 시전 가능@@5928@@2016';

lv105ItemId['용살자의 증표 - 용골 뿔피리'] = 'a16a3c99ec8fa5222816a51bf4bc6ef9';
lv105OptionMap['용살자의 증표 - 용골 뿔피리'] = {};
lv105OptionMap['용살자의 증표 - 용골 뿔피리'] = '모든 속성 강화 +30<br>60Lv 액티브 스킬 Lv +10<br>- 제외 : 팬 서비스<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@4796@@1938';

lv105ItemId['수호룡의 비호 - 용기'] = '2238cdafe92a638f92eadb691d2256c2';
lv105OptionMap['수호룡의 비호 - 용기'] = {};
lv105OptionMap['수호룡의 비호 - 용기'] = '모든 속성 강화 +30<br>40Lv 액티브 스킬 Lv +10<br>- 제외 : 뇌명 : 사나운 빛의 넨수, 사랑의 응급조치, 생명의 원천, 부활, 인법 : 육도윤회<br><br>물리/마법 크리티컬 히트 +5%<br>적중률 +10%@@5874@@1938';

lv105ItemId['파괴된 생명'] = '26c3a6db42a57df6da985df3d91db2c3';
lv105OptionMap['파괴된 생명'] = {};
lv105OptionMap['파괴된 생명'] = '최종 데미지 10% 증가<br><br>물리/마법 크리티컬 히트 +20%<br>공격 속도 +8%<br>캐스팅 속도 +12%<br>적중률 +10%@@4528@@1958';

lv105ItemId['결전의 쌍검 - 로그'] = '04d64b6425d01213cfe416b6c99179d3';
lv105OptionMap['결전의 쌍검 - 로그'] = {};
lv105OptionMap['결전의 쌍검 - 로그'] = '허리케인을 최대 3회 충전 스킬로 변경<br>- 타격당 습득 히트포인트 100% 증가<br>- 이동속도 30% 증가<br>- 끌어들이는 힘 30% 증가<br><br>허리케인 시전 중 히트엔드 시전 시 아래의 효과 적용<br>- 남은 허리케인의 타격 데미지를 합산하여 공격<br>- 가장 강한 적에게 남은 단검 발사<br><br>데스 허리케인의 단검이 적을 관통하도록 변경되고, 발사 수 10개 감소@@9484@@2016';

lv105ItemId['광폭화된 전의의 광검'] = '24a87f1198a995fa632b7e7c750da61b';
lv105OptionMap['광폭화된 전의의 광검'] = {};
lv105OptionMap['광폭화된 전의의 광검'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 자동권총 - 스핏파이어(남)'] = '840199110b3c7c698e143b83656fa77b';
lv105OptionMap['결전의 자동권총 - 스핏파이어(남)'] = {};
lv105OptionMap['결전의 자동권총 - 스핏파이어(남)'] = '버스터 샷이 가장 강한 적에게 지원 포격을 발사하도록 변경<br>- 다른 스킬 사용 중 모션 없이 시전 가능<br><br>오버차지 적용 중 평타 10회 공격 시 버스터샷 강화 탄약 충전 (최대 3개)<br>- 버스터샷 시전 시 강화 탄약 소모<br>- 탄약 1개 소모 : 공격력 15% 증가<br>- 탄약 2개 소모 : 공격력 40% 증가<br>- 탄약 3개 소모 : 공격력 80% 증가<br>- 던전 입장 시 탄약 1개 장착@@7112@@2016';

lv105ItemId['사멸하는 신뢰의 단검'] = 'c9e2d847732827147b8ef409db37d86a';
lv105OptionMap['사멸하는 신뢰의 단검'] = {};
lv105OptionMap['사멸하는 신뢰의 단검'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['전술 드론 콘트롤러 암릿'] = '4a8730f1b3efec704f3c4e00517cccec';
lv105OptionMap['전술 드론 콘트롤러 암릿'] = {};
lv105OptionMap['전술 드론 콘트롤러 암릿'] = '최종 데미지 12% 증가<br>스킬 쿨타임 12% 감소 (각성기 제외)<br>모든 속성 강화 +20<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +8%<br>캐스팅 속도 +12%@@5066@@1948';

lv105ItemId['광폭화된 전의의 리볼버'] = '95a9cdefaebc92f751b369638b78f7a6';
lv105OptionMap['광폭화된 전의의 리볼버'] = {};
lv105OptionMap['광폭화된 전의의 리볼버'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 창 - 마도학자'] = '2473e3f85f9f5829380e42cd8bf2c0a1';
lv105OptionMap['결전의 창 - 마도학자'] = {};
lv105OptionMap['결전의 창 - 마도학자'] = '롤리팝 크러쉬가 더욱 많은 사탕 인형 생성<br>- 최대 사탕 생성 개수 +5<br>- 피격개체당 사탕인형 생성 수 +2<br>- 생성된 사탕인형 1개당 롤리팝 크러쉬의 남은 쿨타임 3% 감소<br>- 사탕인형 공격력 20% 감소<br><br>폭발하지 않은 사탕 인형을 흡수하여 다음 롤리팝 크러쉬 때 생성 (최대 15개)<br>- 사탕당 흡수 1번 가능<br>- 추가 생성된 사탕 인형은 최대 사탕 생성 개수에 미포함@@8300@@2016';

lv105ItemId['결전의 스태프 - 소환사'] = '143dcbb8a1c8b459bcd274c747d0258a';
lv105OptionMap['결전의 스태프 - 소환사'] = {};
lv105OptionMap['결전의 스태프 - 소환사'] = '상급 정령들과 정령왕 에체베리아의 이클립스 하이브 추가 조작 패턴 공격력 20% 증가<br><br>이클립스 하이브 강화<br>- 추가 조작 패턴 캐스팅 모션 삭제 <br>- 데드 멀커 : 블랙스웜 홀딩 추가<br>- 글레어린 : 썬더콜링이 적을 끌어모음<br>- 아퀘리스 : 헵타 아이스 미사일이 지상 폭격<br>- 플레임 헐크 : 화염폭검술이 회오리를 생성하고 적을 끌어모음<br>- 에체베리아 : 초고밀도 레이저가 다수 레이저의 지상 폭격으로 변경@@8300@@2016';

lv105ItemId['결전의 둔기 - 데몬슬레이어'] = '63246c6f2b9a33a4b0c430210c902e11';
lv105OptionMap['결전의 둔기 - 데몬슬레이어'] = {};
lv105OptionMap['결전의 둔기 - 데몬슬레이어'] = '사복검 - 발을 최대 3회 충전 스킬로 변경<br>- 솟아오르는 검 개수 +3<br>- 전직 스킬 공격 시 1회 충전 (사복검 - 발 제외)<br>- 자동 충전 불가능<br><br>전직 스킬의 후딜레이를 캔슬하여 사복검 - 발 시전 가능 (각성기 제외)<br><br>사복검 - 발 후딜레이를 캔슬하여 전직 스킬 시전 가능@@5928@@2016';

lv105ItemId['결전의 너클 - 넨마스터(남)'] = '21a04e5c7fa1137266c1f8978d4278a9';
lv105OptionMap['결전의 너클 - 넨마스터(남)'] = {};
lv105OptionMap['결전의 너클 - 넨마스터(남)'] = '기호지세의 넨수가 포효 후 폭발하도록 변경<br>- 포효 공격 다단히트 수 : 4<br>- 포효 공격력 : 기호지세 다단히트 공격력의 100%<br>- 폭발 공격력 : 기호지세 폭발 공격력의 100%<br>- 포효에 적중된 적을 끌어당김<br>- 스킬 범위 20% 증가<br><br>광호천지파의 나선의 넨이 스스로 회전 후 폭발<br>- 시전 속도 20% 증가<br>- 구체 및 폭발 범위 10% 증가@@10076@@2016';

lv105ItemId['결전의 클로 - 넨마스터(여)'] = '463cf3632f67db7dcc59202c46fa6ccb';
lv105OptionMap['결전의 클로 - 넨마스터(여)'] = {};
lv105OptionMap['결전의 클로 - 넨마스터(여)'] = '축염포 충전 시간 및 쿨타임 삭제<br><br>넨탄의 쿨타임이 증가하는 대신 공격력과 크기가 증가<br>- 공격력 120% 증가<br>- 크기 20% 증가<br>- 쿨타임 4초 증가<br>- 넨마스터 전직 쿨타임 감소 미적용<br><br>넨탄 시전 시 아래의 효과 적용<br>- 흡기공탄의 남은 쿨타임 5% 감소<br>- 10% 확률로 넨탄 공격력 500% 증가@@8300@@2016';

lv105ItemId['결전의 소검 - 다크템플러'] = '34b6dcd93f7123148f98faf7d6b5b2a9';
lv105OptionMap['결전의 소검 - 다크템플러'] = {};
lv105OptionMap['결전의 소검 - 다크템플러'] = '이보브 영혼 최대 흡수 개수 +30<br><br>아래 스킬의 추가 영혼 흡수 개수 +5<br>- 리버레이트 - 매드니스<br>- 디스트로이어<br>- 라케르쏜즈<br><br>리버레이트 - 벅샷 발사하는 에너지 1개당 소모되는 영혼의 수 +2<br>- 타겟을 잡는 최대 거리 20% 증가<br><br>리버레이트 - 벅샷으로 소모한 영혼의 수 2개마다 아래 스킬의 남은 쿨타임 1% 감소 (베스퍼스 효과 미적용)<br>- 디스트로이어<br>- 데우스 엑스 레이<br>- 지하드<br>- 라케르쏜즈@@5336@@2016';

lv105ItemId['가이드 라인 벨트'] = '119c2ed5d3abb1ef627611f9fa91a598';
lv105OptionMap['가이드 라인 벨트'] = {};
lv105OptionMap['가이드 라인 벨트'] = '무색 큐브 조각을 소모하지 않는 스킬 공격력 20% 증가<br><br>공격 시 MP 3,500 회복 (쿨타임 1초)<br><br>물리/마법 피해 감소 +15%<br>적중률 +10%<br>스킬 MP 소모량 -30%@@4917@@1963';

lv105ItemId['퍼스트 스페이드 - 노블레스'] = '76497fb8b232f1a56e96de7511ed2b0d';
lv105OptionMap['퍼스트 스페이드 - 노블레스'] = {};
lv105OptionMap['퍼스트 스페이드 - 노블레스'] = '최종 데미지 3% 증가<br><br>[화이트 매지션]<br>해당 장비의 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@4528@@1612';

lv105ItemId['사멸하는 신뢰의 도'] = '4592ae18eb1fed6a789c4fd589e3c956';
lv105OptionMap['사멸하는 신뢰의 도'] = {};
lv105OptionMap['사멸하는 신뢰의 도'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['내면의 얼굴'] = 'a10fb66fa4ac53012cdce77fb3cbbeee';
lv105OptionMap['내면의 얼굴'] = {};
lv105OptionMap['내면의 얼굴'] = '최종 데미지 7% 증가<br><br>물리/마법 크리티컬 히트 +12%<br>HP MAX +600<br>화속성 저항 +50<br>모든 상태 이상 내성 -10%@@5510@@1963';

lv105ItemId['근원을 삼킨 스태프'] = '38c0dffd2566095aa462a8962e9f9737';
lv105OptionMap['근원을 삼킨 스태프'] = {};
lv105OptionMap['근원을 삼킨 스태프'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['결전의 보우건 - 메카닉(여)'] = 'e393e217944dc85e61c668e3427013a8';
lv105OptionMap['결전의 보우건 - 메카닉(여)'] = {};
lv105OptionMap['결전의 보우건 - 메카닉(여)'] = 'G-S.P. 팔콘 쿨타임 20% 감소<br><br>G-1 코로나 상태에서 롤링썬더 또는 랩터로 트랜스폼 시 8초 동안 홀로그램 G-1 코로나 생성<br>- 공격력 : G-1 코로나 공격력의 50%<br>- G 마그네틱 시전 시 홀로그램 G-1 코로나가 대신 시전<br><br>트랜스폼 : G-3 랩터가 강화 레이저 발사<br>- 강화 레이저 발사@@8300@@2016';

lv105ItemId['생명의 근원이 담긴 배낭'] = 'b9d2c7586b86aa09a3536ebd6c9358ba';
lv105OptionMap['생명의 근원이 담긴 배낭'] = {};
lv105OptionMap['생명의 근원이 담긴 배낭'] = '최종 데미지 7% 증가<br><br>물리/마법 크리티컬 히트 +12%<br>HP MAX +600<br>명속성 저항 +50<br>모든 상태 이상 내성 -10%@@5510@@1963';

lv105ItemId['결전의 통파 - 넨마스터(여)'] = 'd3d58921b8ea6b802815489a495178c5';
lv105OptionMap['결전의 통파 - 넨마스터(여)'] = {};
lv105OptionMap['결전의 통파 - 넨마스터(여)'] = '축염포 충전 시간 및 쿨타임 삭제<br><br>넨탄의 쿨타임이 증가하는 대신 공격력과 크기가 증가<br>- 공격력 120% 증가<br>- 크기 20% 증가<br>- 쿨타임 4초 증가<br>- 넨마스터 전직 쿨타임 감소 미적용<br><br>넨탄 시전 시 아래의 효과 적용<br>- 흡기공탄의 남은 쿨타임 5% 감소<br>- 10% 확률로 넨탄 공격력 500% 증가@@8300@@2016';

lv105ItemId['근력 보강 숄더'] = '69a9cabeca43c5507f8864f6278b4a37';
lv105OptionMap['근력 보강 숄더'] = {};
lv105OptionMap['근력 보강 숄더'] = '최종 데미지 10% 증가<br>스킬 범위 10% 증가<br><br>드랍된 아이템 획득 시 30초 동안 이동 속도 +30%<br><br>HP MAX +300<br>MP MAX +473<br>인벤 토리 무게 한도 +5kg<br>MP 1분당 348 회복@@5604@@1880';

lv105ItemId['찬란한 금장 팔찌'] = '4e5ca11095e1e33029953df70116511c';
lv105OptionMap['찬란한 금장 팔찌'] = {};
lv105OptionMap['찬란한 금장 팔찌'] = '최종 데미지 16.5% 증가<br>모든 속성 강화 +20<br><br>자신에게 해제되지 않는 출혈/중독/화상/감전 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +7%<br>HP MAX +600<br>물리/마법 피해 감소 +20%<br>모든 상태 이상 내성 -10%@@6048@@1953';

lv105ItemId['성장하는 모험가의 중검'] = '4484dbb116e845955a7561ef94e3815f';
lv105OptionMap['성장하는 모험가의 중검'] = {};
lv105OptionMap['성장하는 모험가의 중검'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>MP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['내딛은 자의 건틀릿'] = '7eb82ddbf965aa8b977a3c90a35efa73';
lv105OptionMap['내딛은 자의 건틀릿'] = {};
lv105OptionMap['내딛은 자의 건틀릿'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 배틀액스 - 퇴마사'] = '94a46963183c21867e518abda4f2a818';
lv105OptionMap['결전의 배틀액스 - 퇴마사'] = {};
lv105OptionMap['결전의 배틀액스 - 퇴마사'] = '공의 식신 - 백호 스킬 공격력 20% 증가<br>난격의 매 타격이 충격파 생성<br>- 쿨타임 20% 감소<br>- 난격 횟수 100% 증가<br>- 막타 삭제<br>- 막타 공격력은 난격 공격력에 합산<br><br>광명의 챠크라 적용 중 무쌍격 스킬 공격력 20% 증가<br>무쌍격 범위 20% 증가<br><br>대시 중 무쌍격 시전 시 전방으로 돌진하며 시전<br>@@4148@@2016';

lv105ItemId['내딛은 자의 장궁'] = '5216a77d6c998cb20bd7d39c229d91bf';
lv105OptionMap['내딛은 자의 장궁'] = {};
lv105OptionMap['내딛은 자의 장궁'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 클로 - 그래플러(남)'] = '601b43064e7ea208a7d3c556016816a7';
lv105OptionMap['결전의 클로 - 그래플러(남)'] = {};
lv105OptionMap['결전의 클로 - 그래플러(남)'] = '롤링 스파이크를 메치기 시 지진이 발생하는 최대 2회 충전 스킬로 변경<br>- 지진 공격력 : 메치기 공격력의 50%<br>- 충전 쿨타임 : 12초<br>- 후딜레이를 캔슬하여 전직 스킬 시전 가능<br><br>아래의 스킬 잡기 실패 시 해당 스킬 쿨타임 5초로 감소 (쿨타임 10초)<br>- 브레이크 다운<br>- 스냅 샷<br>- 블로우 스트라이크(지상 사용)<br>- 롤링 스파이크<br>- 나의 공격리듬은!(지상 사용)<br>- 한놈만 팬다!!<br>- 흑진광풍@@5928@@2016';

lv105ItemId['내딛은 자의 소태도'] = '563b50408d9395d1e5fd3833a31745a4';
lv105OptionMap['내딛은 자의 소태도'] = {};
lv105OptionMap['내딛은 자의 소태도'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['얼어붙은 저항의 통파'] = '65bff3906d27dc5cd8a1515ec981a4cc';
lv105OptionMap['얼어붙은 저항의 통파'] = {};
lv105OptionMap['얼어붙은 저항의 통파'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['올곧은 자의 이념'] = '51546ea687dc28b4d0cc9a67dc101585';
lv105OptionMap['올곧은 자의 이념'] = {};
lv105OptionMap['올곧은 자의 이념'] = '공격력 증가 +355.7%<br>모든 속성 강화 +18<br>장비로 발동하는 오브젝트 데미지 +30%<br><br>공격 시 모든 적을 불태움 (쿨타임 5초)<br>- 총 공격력 증가의 140%만큼 피해<br><br>모든 속성 저항 +8@@4258@@1968';

lv105ItemId['성장하는 모험가의 핸드캐넌'] = '0b6386e655857ecd1bfbc075143ec5ba';
lv105OptionMap['성장하는 모험가의 핸드캐넌'] = {};
lv105OptionMap['성장하는 모험가의 핸드캐넌'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['광폭화된 전의의 염주'] = '76f30342aa24e7f2cfa4627290ba77d1';
lv105OptionMap['광폭화된 전의의 염주'] = {};
lv105OptionMap['광폭화된 전의의 염주'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['얼어붙은 저항의 로드'] = '677b9aab4987adb68551e1874450a58d';
lv105OptionMap['얼어붙은 저항의 로드'] = {};
lv105OptionMap['얼어붙은 저항의 로드'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 광창 - 듀얼리스트'] = '1373627f085196fab83b9a2347e54925';
lv105OptionMap['결전의 광창 - 듀얼리스트'] = {};
lv105OptionMap['결전의 광창 - 듀얼리스트'] = '아래 스킬 적중 시 뇌격점혈섬을 강화하는 극 점혈 획득 (최대 30개)<br>- 레조넌스 임페일 : 타격당 10개<br>- 쿼드러플 드라이브 : 타격당 5개<br>- 파동연환창 : 타격당 4개<br>- 트윈 펀토 : 타격당 2개<br>- 퀵 펀토 : 타격당 1개<br><br>보유한 극 점혈 1개당 뇌격점혈섬 강화<br>- 스킬 쿨타임 회복 속도 : +1%<br>- 공격 속도 : +1%<br><br>뇌격점혈섬 공격 시 모든 극 점혈을 소모하여 극 점혈 1개당 창끝 점혈 히트 수 추가 적용<br><br>뇌격점혈섬 공격 속도 +10%@@10672@@2016';

lv105ItemId['솔저 호너 네클리스'] = '1be7fe216b60a08ad268bec2556544c1';
lv105OptionMap['솔저 호너 네클리스'] = {};
lv105OptionMap['솔저 호너 네클리스'] = '스킬 쿨타임 회복 속도 +20% (각성기 제외)<br>화속성 강화 +20<br>장비 오브젝트 데미지 +10%<br><br>공격 시 화속성 폭발 발생<br>- 총 공격력 증가의 600%만큼 피해<br><br>화속성 저항 +10@@5336@@1948';

lv105ItemId['결전의 스태프 - 엘레멘탈 바머'] = '3f672dde6aca44fe372be407fac0f8e0';
lv105OptionMap['결전의 스태프 - 엘레멘탈 바머'] = {};
lv105OptionMap['결전의 스태프 - 엘레멘탈 바머'] = '엘레멘탈 캐넌 시전 시 속성 마법구 발사 대신 전방에 어비스 캐넌 폭발<br>- 공격력 50% 증가<br>- 쿨타임 5초 증가<br>- 다른 스킬 시전 중 시전 가능<br>- 엘레멘탈 포스 1개당 공격력 10% 추가 증가<br><br>엘레멘탈 캐넌 시전 시 5초 동안 스킬 쿨타임 회복 속도 증가 (각성기 제외)<br>- 소모한 엘레멘탈 포스 1개당 6%<br><br>컨버전스 캐넌, 컨버전스 리액트 시전 시 엘레멘탈 캐넌 쿨타임 초기화@@5336@@2016';

lv105ItemId['내딛은 자의 염주'] = '0f23308f100c89df8feca4277bbf3f2c';
lv105OptionMap['내딛은 자의 염주'] = {};
lv105OptionMap['내딛은 자의 염주'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['광폭화된 전의의 장궁'] = 'f154d57f15d20b311870ceeb66aa3a19';
lv105OptionMap['광폭화된 전의의 장궁'] = {};
lv105OptionMap['광폭화된 전의의 장궁'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 봉 - 엘레멘탈 바머'] = 'bbf9ba5e5ad830228ab466e2bc6f4e5d';
lv105OptionMap['결전의 봉 - 엘레멘탈 바머'] = {};
lv105OptionMap['결전의 봉 - 엘레멘탈 바머'] = '엘레멘탈 캐넌 시전 시 속성 마법구 발사 대신 전방에 어비스 캐넌 폭발<br>- 공격력 50% 증가<br>- 쿨타임 5초 증가<br>- 다른 스킬 시전 중 시전 가능<br>- 엘레멘탈 포스 1개당 공격력 10% 추가 증가<br><br>엘레멘탈 캐넌 시전 시 5초 동안 스킬 쿨타임 회복 속도 증가 (각성기 제외)<br>- 소모한 엘레멘탈 포스 1개당 6%<br><br>컨버전스 캐넌, 컨버전스 리액트 시전 시 엘레멘탈 캐넌 쿨타임 초기화@@5336@@2016';

lv105ItemId['결전의 핸드캐넌 - 어썰트'] = '78783ed45208bdbd02dbb2cd020fa064';
lv105OptionMap['결전의 핸드캐넌 - 어썰트'] = {};
lv105OptionMap['결전의 핸드캐넌 - 어썰트'] = '부스트 스킬로 전직 스킬 캔슬 시 전력 1스택 획득 (최대 5스택)<br><br>아래 스킬 시전 시 모든 전력을 소모하고, 소모한 전력당 공격력 10% 증가<br>- 레이시온 크리트<br>- 부스트 : 다이너마이트 킥<br><br>부스트 : 다이너마이트 킥 적중 시 적을 1.5초 동안 홀딩<br>- 소모한 전력 1스택당 홀딩 시간 0.5초 증가@@9484@@2016';

lv105ItemId['내딛은 자의 권투글러브'] = '1d1ede995cbc19ceb939051a9fac5583';
lv105OptionMap['내딛은 자의 권투글러브'] = {};
lv105OptionMap['내딛은 자의 권투글러브'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['결전의 머스켓 - 메카닉(여)'] = 'c6f76bcf8eb42c6aa6736f8a5502ee1f';
lv105OptionMap['결전의 머스켓 - 메카닉(여)'] = {};
lv105OptionMap['결전의 머스켓 - 메카닉(여)'] = 'G-S.P. 팔콘 쿨타임 20% 감소<br><br>G-1 코로나 상태에서 롤링썬더 또는 랩터로 트랜스폼 시 8초 동안 홀로그램 G-1 코로나 생성<br>- 공격력 : G-1 코로나 공격력의 50%<br>- G 마그네틱 시전 시 홀로그램 G-1 코로나가 대신 시전<br><br>트랜스폼 : G-3 랩터가 강화 레이저 발사<br>- 강화 레이저 발사@@8300@@2016';

lv105ItemId['결전의 자동권총 - 메카닉(여)'] = '2a14c81dded018d148023cb3e66ff9d4';
lv105OptionMap['결전의 자동권총 - 메카닉(여)'] = {};
lv105OptionMap['결전의 자동권총 - 메카닉(여)'] = 'G-S.P. 팔콘 쿨타임 20% 감소<br><br>G-1 코로나 상태에서 롤링썬더 또는 랩터로 트랜스폼 시 8초 동안 홀로그램 G-1 코로나 생성<br>- 공격력 : G-1 코로나 공격력의 50%<br>- G 마그네틱 시전 시 홀로그램 G-1 코로나가 대신 시전<br><br>트랜스폼 : G-3 랩터가 강화 레이저 발사<br>- 강화 레이저 발사@@8300@@2016';

lv105ItemId['성장하는 모험가의 신발'] = 'null';
lv105OptionMap['성장하는 모험가의 신발'] = {};
lv105OptionMap['성장하는 모험가의 신발'] = '최종 데미지 7% 증가@@533@@1986';

lv105ItemId['결전의 핸드캐넌 - 레인저(남)'] = 'c4953cbcf674a6907ee8603f393c8827';
lv105OptionMap['결전의 핸드캐넌 - 레인저(남)'] = {};
lv105OptionMap['결전의 핸드캐넌 - 레인저(남)'] = '더블 건호크 강화<br>- 세 번째 건호크 잡기 성공 시 두 번째 건호크 투척 이후 사용한 스킬들의 남은 쿨타임 20% 감소<br>- 크기 10% 증가<br>- 시전 시 4초 동안 공격 속도 +30%<br><br>스타일리쉬 캔슬 가능 최대 횟수 +1<br><br>장착 시 스펙트럼 생성@@6520@@2016';

lv105ItemId['기록자의 보석'] = '1db1bc5440c65d2810da83815bfbe7ae';
lv105OptionMap['기록자의 보석'] = {};
lv105OptionMap['기록자의 보석'] = '최종 데미지 12% 증가<br><br>회복형 소모품 사용 시 [충전형 보호막] 자동 충전 시작<br><br>장비 보호막 MAX 20% 증가<br><br>HP MAX +5%<br>회피율 +8%@@4528@@1928';

lv105ItemId['테크놀로지 바디캠 네클리스'] = '2348462dcc1d179d1994bc141e05576e';
lv105OptionMap['테크놀로지 바디캠 네클리스'] = {};
lv105OptionMap['테크놀로지 바디캠 네클리스'] = '모든 속성 강화 +20<br><br>커맨드로 스킬 시전 시 퀵슬롯 등록 여부에 따라 아래의 효과 적용<br>- 퀵슬롯 등록 : 최종 데미지 10% 증가<br>- 퀵슬롯 미등록 : 스킬 쿨타임 15% 감소 (각성기 제외)<br><br>물리/마법 크리티컬 히트 +7%<br>모든 상태 이상 내성 -10%@@4876@@1958';

lv105ItemId['사멸하는 신뢰의 완드'] = '0ec87587e9f714bc481290512c275612';
lv105OptionMap['사멸하는 신뢰의 완드'] = {};
lv105OptionMap['사멸하는 신뢰의 완드'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['천상을 수호하는 윙 아머'] = '060b80f5a6fb8820c7bf56f2c2946c78';
lv105OptionMap['천상을 수호하는 윙 아머'] = {};
lv105OptionMap['천상을 수호하는 윙 아머'] = '최종 데미지 8% 증가<br><br>[아이기스의 방패]<br>[장비 옵션 조작키] 입력 시 5초 동안 아이기스의 방패 소환 (쿨타임 45초)<br>- 방패 HP : HP MAX의 30%<br>- 원거리 공격 방어 및 적 이동 제한<br><br>공격 시 HP 2% + 2,200 회복 (쿨타임 1초)<br><br>HP MAX +600<br>물리/마법 방어력 +20%@@4647@@1963';

lv105ItemId['결전의 리볼버 - 런처(여)'] = '253bdeac9d6fd3a136ff260d0fb04424';
lv105OptionMap['결전의 리볼버 - 런처(여)'] = {};
lv105OptionMap['결전의 리볼버 - 런처(여)'] = '화염 강타를 화염을 압축하여 발사하는 최대 3회 충전 스킬로 변경<br>- 공격력 : 총 공격력의 50%<br>- 충전 쿨타임 : 4초<br><br>M-3 화염방사기 시전 시 화염을 압축하여 발사<br><br>UHT-03 플레게톤 이동 속도 비율 +10%@@9484@@2016';

lv105ItemId['근원을 삼킨 선현궁'] = '83574c5d07f2e4cd596ee751bca6fd83';
lv105OptionMap['근원을 삼킨 선현궁'] = {};
lv105OptionMap['근원을 삼킨 선현궁'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>회피율 +8%@@5336@@1996';

lv105ItemId['대지를 수호하는 윙 벨트'] = '462d8d3f41b8815abb7c39a9f0dc5d08';
lv105OptionMap['대지를 수호하는 윙 벨트'] = {};
lv105OptionMap['대지를 수호하는 윙 벨트'] = '최종 데미지 13% 증가<br><br>[빛의 영역]<br>공격 시 빛의 영역 생성 (쿨타임 1초)<br>- 영역 내 물리/마법 피해 감소 +10%<br>- 영역 밖으로 이동 시 영역 소멸<br><br>물리/마법 크리티컬 히트 +5%<br>HP MAX +10%<br>HP MAX +600<br>모든 상태 이상 내성 +10%@@6144@@1928';

lv105ItemId['희생으로 맞이한 여명'] = '3388f154175b71535e1c0ce8ad86261c';
lv105OptionMap['희생으로 맞이한 여명'] = {};
lv105OptionMap['희생으로 맞이한 여명'] = '최종 데미지 9% 증가<br><br>HP MAX 10% 수치의 [충전형 보호막] 부여<br><br>HP MAX +600<br>HP 1분당 460.2 회복@@6414@@1900';

lv105ItemId['결전의 건틀릿 - 넨마스터(남)'] = '1924ba14919413dce197a4ed198173a4';
lv105OptionMap['결전의 건틀릿 - 넨마스터(남)'] = {};
lv105OptionMap['결전의 건틀릿 - 넨마스터(남)'] = '기호지세의 넨수가 포효 후 폭발하도록 변경<br>- 포효 공격 다단히트 수 : 4<br>- 포효 공격력 : 기호지세 다단히트 공격력의 100%<br>- 폭발 공격력 : 기호지세 폭발 공격력의 100%<br>- 포효에 적중된 적을 끌어당김<br>- 스킬 범위 20% 증가<br><br>광호천지파의 나선의 넨이 스스로 회전 후 폭발<br>- 시전 속도 20% 증가<br>- 구체 및 폭발 범위 10% 증가@@10076@@2016';

lv105ItemId['꿈같은 환호성'] = '148b87e4558b32884365ac2566cb2c6e';
lv105OptionMap['꿈같은 환호성'] = {};
lv105OptionMap['꿈같은 환호성'] = '스킬 쿨타임 회복 속도 +50% (각성기 제외)<br>- "오랜 시간 품어온 소원" 장비 장착 필요<br><br>회피율 +8%<br>HP MAX +600<br>수면 내성 -10%@@4528@@1948';

lv105ItemId['성장하는 모험가의 십자가'] = '653d0aa92c0c7b46ecb96d64dba020f0';
lv105OptionMap['성장하는 모험가의 십자가'] = {};
lv105OptionMap['성장하는 모험가의 십자가'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +300<br>MP MAX +473<br>적중률 +10%<br>인벤토리 무게 한도 +5kg@@5336@@1996';

lv105ItemId['헥타곤 임펄스 브릭'] = '0a17d035a66e06375a4a75a0db91dc93';
lv105OptionMap['헥타곤 임펄스 브릭'] = {};
lv105OptionMap['헥타곤 임펄스 브릭'] = '공격력 증가 +133.4%<br>최종 데미지 10% 증가<br><br>자신이 적에게 적용한 석화 상태 이상의 받는 데미지 감소 효과 제거<br><br>공격 시 모든 적에게 10초 동안 석화 상태 이상 부여 (쿨타임 15초)<br><br>석화 지속 시간 +3초<br><br>공격 속도 +8%<br>캐스팅 속도 +12%@@5604@@1938';

lv105ItemId['얼어붙은 저항의 배틀액스'] = '3d2aca331b2f570727197e7bf91b4f5f';
lv105OptionMap['얼어붙은 저항의 배틀액스'] = {};
lv105OptionMap['얼어붙은 저항의 배틀액스'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['HEM 리인포스 부츠'] = '012a1830b9d48b6342ac1d0db83a564e';
lv105OptionMap['HEM 리인포스 부츠'] = {};
lv105OptionMap['HEM 리인포스 부츠'] = '최종 데미지 16% 증가<br>모든 속성 강화 +20<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 화상 상태 이상 부여 (쿨타임 10초)@@6414@@1968';

lv105ItemId['결전의 토템 - 미스트리스'] = '5cc548a086e5ed84cb5daaeb82aff916';
lv105OptionMap['결전의 토템 - 미스트리스'] = {};
lv105OptionMap['결전의 토템 - 미스트리스'] = '타락의 칠죄종 변신 중 죄를 깨우는 손길 공격 시 변신 지속 시간 +12초<br>- 최대 변신 지속 시간 초과 불가능<br><br>글러트니 바밋 시전 시 자신을 중심으로 적을 흡수하고 폭발<br>- 공격력 30% 증가<br>- 죄업 스택 3개 이상일 때 시전 시 죄업 스택을 3개 소모하고 분신이 대신 시전<br><br>죄업 스택이 3개 이상일 때 무거운 죄업 시전 시 죄업 스택을 3개 소모하고 10초 동안 물리/마법 피해 감소 +15%<br><br>일곱 개의 대죄 적용 중 무거운 죄업 적 홀딩 시간 +1초<br><br>죄를 깨우는 손길 죄업 스택 증가량 +2@@770@@2016';

lv105ItemId['광폭화된 전의의 소태도'] = 'b4112b72d753f92f85e313d8520e5bff';
lv105OptionMap['광폭화된 전의의 소태도'] = {};
lv105OptionMap['광폭화된 전의의 소태도'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 대검 - 블레이드'] = '874692777f390e64557799e571db8bc9';
lv105OptionMap['결전의 대검 - 블레이드'] = {};
lv105OptionMap['결전의 대검 - 블레이드'] = '납도술 연계 시 납도술 스킬과 연계한 스킬의 남은 쿨타임 15% 감소<br>- 각성기로 납도술 연계 시 미적용<br><br>러스티 네일 베기 공격력이 삭제되고, 후딜레이를 캔슬하여 전직 스킬 시전 가능<br>- 베기 공격력을 발차기 공격력에 합산<br><br>전직 스킬 후딜레이를 캔슬하여 러스티 네일 시전 가능<br>- 셰이커 블래스트, 각성기 제외@@8892@@2016';

lv105ItemId['결전의 염주 - 퇴마사'] = '2b62ac04c964b8728956d31abcfa8ebb';
lv105OptionMap['결전의 염주 - 퇴마사'] = {};
lv105OptionMap['결전의 염주 - 퇴마사'] = '공의 식신 - 백호 스킬 공격력 20% 증가<br>난격의 매 타격이 충격파 생성<br>- 쿨타임 20% 감소<br>- 난격 횟수 100% 증가<br>- 막타 삭제<br>- 막타 공격력은 난격 공격력에 합산<br><br>광명의 챠크라 적용 중 무쌍격 스킬 공격력 20% 증가<br>무쌍격 범위 20% 증가<br><br>대시 중 무쌍격 시전 시 전방으로 돌진하며 시전<br>@@4148@@2016';

lv105ItemId['결전의 도 - 소드마스터'] = '2af3ea54e71846d695947854b95b1b2a';
lv105OptionMap['결전의 도 - 소드마스터'] = {};
lv105OptionMap['결전의 도 - 소드마스터'] = '궁극의 마검 레바테인으로 악즉참 시전<br>- 악즉참 항상 최대 충전<br>- 악즉참 공격력 15% 감소<br><br>레바테인에 의한 악즉참은 적에게 4초 동안 상흔을 부여<br>- 상흔이 생성된 적에게 반월 또는 섬광 적중 시 악즉참 남은 쿨타임 10% 감소@@8892@@2016';

lv105ItemId['근원을 삼킨 창'] = 'abaae57b08eb4baee77b1fd3e84bc010';
lv105OptionMap['근원을 삼킨 창'] = {};
lv105OptionMap['근원을 삼킨 창'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['미련이 남은 녹슨 원혼'] = '225aceaa501f2440a54587bed8c80e63';
lv105OptionMap['미련이 남은 녹슨 원혼'] = {};
lv105OptionMap['미련이 남은 녹슨 원혼'] = '모든 속성 강화 +10<br>감전 피해 +20%<br><br>[소형화]<br>[장비 옵션 조작키] 입력 시 25초 동안 소형화 (쿨타임 60초)<br>- 이동 속도 +30%<br>- 회피율 +75%<br>- 1초마다 HP/MP 1% 회복<br>- 스킬 및 기본 공격 불가<br>- [장비 옵션 조작키] 재입력 시 소형화 해제<br><br>공격 시 모든 적에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)<br><br>HP MAX +600<br>물리/마법 방어력 +7,000<br>감전 내성 +10%@@5604@@1968';

lv105ItemId['성장하는 모험가의 하의'] = 'null';
lv105OptionMap['성장하는 모험가의 하의'] = {};
lv105OptionMap['성장하는 모험가의 하의'] = '최종 데미지 7% 증가@@533@@1986';

lv105ItemId['얼어붙은 저항의 선현궁'] = 'a9a986b6ffdd2b4ea6b232d36f7e80b5';
lv105OptionMap['얼어붙은 저항의 선현궁'] = {};
lv105OptionMap['얼어붙은 저항의 선현궁'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['광폭화된 전의의 핸드캐넌'] = '37d92832be9e76c3dd8b538f52274029';
lv105OptionMap['광폭화된 전의의 핸드캐넌'] = {};
lv105OptionMap['광폭화된 전의의 핸드캐넌'] = '모든 속성 강화 +30<br>장비 오브젝트 데미지 +20%<br>스킬 범위 24% 증가<br><br>스킬 범위 옵션 수치의 총합이 48% 이상일 때 최종 데미지 8% 증가<br><br>공격 속도 +20%<br>이동 속도 +20%<br>캐스팅 속도 +30%<br>HP MAX +5%<br>MP MAX +5%@@7112@@1916';

lv105ItemId['결전의 건틀릿 - 스트라이커(여)'] = '5a2122f47ef77b69e4bba75617b7d42e';
lv105OptionMap['결전의 건틀릿 - 스트라이커(여)'] = {};
lv105OptionMap['결전의 건틀릿 - 스트라이커(여)'] = '라이트닝 댄스 이동 횟수가 4회 감소하고 1:1 공격 시 대상의 좌우를 이동하며 공격<br>- 공격력 15% 감소<br>- 공격 속도 +30%<br>- 이동 범위 5% 증가<br>- 공격 시 적에게 감전 상태 이상 부여 (쿨타임 15초)<br><br>라이트닝 댄스 공격 시 충격파 생성<br>- 직접 타격 대상은 충격파 피격 제외<br>- 1:1 공격일 경우 충격파 미생성@@9484@@2016';

lv105ItemId['결전의 건틀릿 - 스트리트파이터(남)'] = 'fcf77bb54c098f0ef1be7e88a348ea14';
lv105OptionMap['결전의 건틀릿 - 스트리트파이터(남)'] = {};
lv105OptionMap['결전의 건틀릿 - 스트리트파이터(남)'] = '크레이지 발칸 시전 시 화염병 폭탄을 내려치도록 변경<br>- 마운트 마지막 충격파 삭제<br>- 주변 적에게 잡힌 적과 동일한 데미지 적용<br>- 잡기 실패 시에도 발동<br><br>크레이지 발칸 상태 이상 보너스 추가<br>- 공격 범위 : 1개당 10%<br>- 쿨타임 감소 : 1개당 10%@@9484@@2016';

lv105ItemId['결전의 장도 - 요원'] = 'b4865a4b6f96e85aa59c55063ecc4e45';
lv105OptionMap['결전의 장도 - 요원'] = {};
lv105OptionMap['결전의 장도 - 요원'] = '암살목표의 표식이 부여된 적에게 일부 스킬 공격 시 15초 동안 총상 / 절상 부여 (최대 3중첩)<br>- 총상 부여 스킬 : 조준 사격, 목표 사격<br>- 절상 부여 스킬 : 쌍현참, 암월비보<br><br>총상 / 절상 상태의 적을 아래의 스킬로 공격 시 총상 / 절상이 해제되며 스킬 공격력 증가<br>- 문페이즈 : 총상 1중첩당 8%<br>- 섬멸 : 절상 1중첩당 8%<br>- 월광진혼곡 : 총상 / 절상 1중첩당 8%<br><br>아래의 스킬 쿨타임 15% 감소<br>- 쌍현참<br>- 조준 사격<br>- 암월비보<br>- 목표 사격<br><br>문페이즈 크기 10% 증가@@7112@@2016';

lv105ItemId['사멸하는 신뢰의 빗자루'] = '20c715318bdb05654b25ac290152e3de';
lv105OptionMap['사멸하는 신뢰의 빗자루'] = {};
lv105OptionMap['사멸하는 신뢰의 빗자루'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['영면을 위한 준비'] = '02c6ecf0687b280befa6087e9899f913';
lv105OptionMap['영면을 위한 준비'] = {};
lv105OptionMap['영면을 위한 준비'] = '화상 피해 +15%<br><br>[얼렸다 녹였다]<br>적에게 빙결 시도 시 화상-빙결 연계 (쿨타임 3초)<br>- 적에게 누적된 화상 피해 폭발<br>- 빙결시킨 적에게 즉시 화상 부여<br><br>화상 지속 시간 +50%<br>화상 지속 시간 +2초<br><br>화상 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>MP MAX +945<br>물리/마법 피해 감소 +15%@@4528@@1958';

lv105ItemId['성장하는 모험가의 흑요석 팬던트'] = '4746f1587697bbc9979797d347445079';
lv105OptionMap['성장하는 모험가의 흑요석 팬던트'] = {};
lv105OptionMap['성장하는 모험가의 흑요석 팬던트'] = '최종 데미지 7% 증가@@5336@@1986';

lv105ItemId['결전의 너클 - 스트리트파이터(남)'] = '473900960d250a328c3bd9856896f5e4';
lv105OptionMap['결전의 너클 - 스트리트파이터(남)'] = {};
lv105OptionMap['결전의 너클 - 스트리트파이터(남)'] = '크레이지 발칸 시전 시 화염병 폭탄을 내려치도록 변경<br>- 마운트 마지막 충격파 삭제<br>- 주변 적에게 잡힌 적과 동일한 데미지 적용<br>- 잡기 실패 시에도 발동<br><br>크레이지 발칸 상태 이상 보너스 추가<br>- 공격 범위 : 1개당 10%<br>- 쿨타임 감소 : 1개당 10%@@9484@@2016';

lv105ItemId['성장하는 모험가의 스태프'] = '7250627f3795d6f0d03a4d027c2b616e';
lv105OptionMap['성장하는 모험가의 스태프'] = {};
lv105OptionMap['성장하는 모험가의 스태프'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['성장하는 모험가의 자동권총'] = 'b3c1f730c87adfc55089e50dafc29244';
lv105OptionMap['성장하는 모험가의 자동권총'] = {};
lv105OptionMap['성장하는 모험가의 자동권총'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>물리/마법 방어력 +7,000<br>화속성 저항 +10@@5336@@1996';

lv105ItemId['결전의 십자가 - 이단심판관'] = 'f400202e744f2c8db76b273654c6d410';
lv105OptionMap['결전의 십자가 - 이단심판관'] = {};
lv105OptionMap['결전의 십자가 - 이단심판관'] = '세례의 불길, 정화의 불꽃 스킬 쿨타임 20% 감소<br><br>정화의 불꽃 시전 시 500px 내 모든 적에게 폭발 발생<br><br>플레게의 정수를 최대 5회 충전 스킬로 변경<br>- 충전 쿨타임 : 8초<br>- 소각 발생 시 충전 시간 1초 감소@@5336@@2016';

lv105ItemId['터치 컨트롤 패널'] = '3bab4b93da1f7d2fcc6da6c98015eae1';
lv105OptionMap['터치 컨트롤 패널'] = {};
lv105OptionMap['터치 컨트롤 패널'] = '최종 데미지 8% 증가<br><br>물리/마법 크리티컬 히트 +10%<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5336@@1948';

lv105ItemId['결전의 낫 - 미스트리스'] = 'c41e6ff8cf0cf837071a34e88f7103ec';
lv105OptionMap['결전의 낫 - 미스트리스'] = {};
lv105OptionMap['결전의 낫 - 미스트리스'] = '타락의 칠죄종 변신 중 죄를 깨우는 손길 공격 시 변신 지속 시간 +12초<br>- 최대 변신 지속 시간 초과 불가능<br><br>글러트니 바밋 시전 시 자신을 중심으로 적을 흡수하고 폭발<br>- 공격력 30% 증가<br>- 죄업 스택 3개 이상일 때 시전 시 죄업 스택을 3개 소모하고 분신이 대신 시전<br><br>죄업 스택이 3개 이상일 때 무거운 죄업 시전 시 죄업 스택을 3개 소모하고 10초 동안 물리/마법 피해 감소 +15%<br><br>일곱 개의 대죄 적용 중 무거운 죄업 적 홀딩 시간 +1초<br><br>죄를 깨우는 손길 죄업 스택 증가량 +2@@7708@@2016';

lv105ItemId['결전의 빗자루 - 디멘션워커'] = '3b7d6bb2b872859e8881cf914d07da74';
lv105OptionMap['결전의 빗자루 - 디멘션워커'] = {};
lv105OptionMap['결전의 빗자루 - 디멘션워커'] = '차원 : 시공폭뢰 스킬 강화<br>- 공격력 15% 증가<br>- 폭발 속도 50% 증가<br>- 스킬 범위 25% 증가<br>- 사상경계의 저편 효과 적용 시 전방에 다수의 차원 폭발 생성<br><br>니알리가 스킬 시전 시 분신이 대신 시전 (각성기 제외)<br><br>아래 스킬 쿨타임 10% 감소<br>- 괴리 : 할리퀸<br>- 괴리 : 패러사이트 스웜<br>- 괴리 : 칙 잇<br>- 괴리 : 저편의 자장가@@4744@@2016';

lv105ItemId['내딛은 자의 도'] = 'b8a51d7c294187e0062c59dc2a57bed8';
lv105OptionMap['내딛은 자의 도'] = {};
lv105OptionMap['내딛은 자의 도'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['사멸하는 신뢰의 차크라 웨펀'] = '170a5711a2a7ab3e2120effd4c474fce';
lv105OptionMap['사멸하는 신뢰의 차크라 웨펀'] = {};
lv105OptionMap['사멸하는 신뢰의 차크라 웨펀'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 배틀액스 - 이단심판관'] = '44dab88fff2bc28386f5d2dbdb37ef25';
lv105OptionMap['결전의 배틀액스 - 이단심판관'] = {};
lv105OptionMap['결전의 배틀액스 - 이단심판관'] = '세례의 불길, 정화의 불꽃 스킬 쿨타임 20% 감소<br><br>정화의 불꽃 시전 시 500px 내 모든 적에게 폭발 발생<br><br>플레게의 정수를 최대 5회 충전 스킬로 변경<br>- 충전 쿨타임 : 8초<br>- 소각 발생 시 충전 시간 1초 감소@@5336@@2016';

lv105ItemId['압도하는 힘의 하갑'] = '03b4a29839141e826a739630698a8560';
lv105OptionMap['압도하는 힘의 하갑'] = {};
lv105OptionMap['압도하는 힘의 하갑'] = '중독 피해 +15%<br><br>중독 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>공격 시 모든 적에게 15초 동안 중독 상태 이상 부여 (쿨타임 10초)<br><br>물리/마법 크티리컬 히트 +5%<br>중독 내성 +10%@@6682@@1958';

lv105ItemId['결전의 낫 - 인파이터'] = '3f06f384d1f20baf1dc792b747c1c242';
lv105OptionMap['결전의 낫 - 인파이터'] = {};
lv105OptionMap['결전의 낫 - 인파이터'] = '정의 집행 : 레미디오스의 성좌의 링크 스킬이 빅뱅 펀치일 때, 신의 분노를 최대 3회 충전 스킬로 변경<br>- 연타 공격력 100% 증가<br>- 연타 횟수 9회 감소<br>- 피니쉬 타격 및 폭발 삭제<br>- 적을 추적하여 공격<br>- 충전 쿨타임 : 40초<br>- 추적 범위 : 780px<br>- MP 소모량 -30%<br>- 무색 큐브 조각 소모량 5개 감소<br>- 머신건 잽 시전 시 신의 분노 남은 쿨타임 12% 감소<br>- 신의 분노를 캔슬하여 전직 스킬 시전 가능<br>- 전직 스킬을 캔슬하여 신의 분노 시전 시 드라이아웃 스택을 소모하지 않음@@5928@@2016';

lv105ItemId['타락한 영혼'] = 'b16348f86d01e99a541a2af2a21a015b';
lv105OptionMap['타락한 영혼'] = {};
lv105OptionMap['타락한 영혼'] = '무색 큐브 조각을 소모하는 스킬에 아래의 효과 적용<br>- 스킬 공격력 10% 증가<br>- 스킬 쿨타임 10% 감소 (각성기 제외)<br>- 무색 큐브 조각 소모량 +2<br><br>MP MAX +945<br>적중률 +10%<br>스킬 MP 소모량 -10%@@4796@@1968';

lv105ItemId['자신감의 백색 망토'] = '41e55f046345b4dbf4976f39231a0d22';
lv105OptionMap['자신감의 백색 망토'] = {};
lv105OptionMap['자신감의 백색 망토'] = '최종 데미지 6% 증가<br><br>[화이트 매지션]<br>해당 장비에 부여된 마법 부여 효과 100% 증가<br>- 제외 : 스킬 레벨 증가 효과, 모험가 명성 증가 효과<br><br>HP MAX +200<br>MP MAX +315<br>HP 1분당 460.2 회복<br>MP 1분당 348 회복@@5604@@1612';

lv105ItemId['성장하는 모험가의 봉'] = 'e7dc56f8d29becd9f92fb9d79d0f11e4';
lv105OptionMap['성장하는 모험가의 봉'] = {};
lv105OptionMap['성장하는 모험가의 봉'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +600<br>MP 1분당 348 회복@@5336@@1996';

lv105ItemId['결전의 스태프 - 마도학자'] = 'eb2650736a327fe97951f7231d24f133';
lv105OptionMap['결전의 스태프 - 마도학자'] = {};
lv105OptionMap['결전의 스태프 - 마도학자'] = '롤리팝 크러쉬가 더욱 많은 사탕 인형 생성<br>- 최대 사탕 생성 개수 +5<br>- 피격개체당 사탕인형 생성 수 +2<br>- 생성된 사탕인형 1개당 롤리팝 크러쉬의 남은 쿨타임 3% 감소<br>- 사탕인형 공격력 20% 감소<br><br>폭발하지 않은 사탕 인형을 흡수하여 다음 롤리팝 크러쉬 때 생성 (최대 15개)<br>- 사탕당 흡수 1번 가능<br>- 추가 생성된 사탕 인형은 최대 사탕 생성 개수에 미포함@@8300@@2016';

lv105ItemId['내딛은 자의 머스켓'] = '84a5d6b97b2de355d5848670489e514d';
lv105OptionMap['내딛은 자의 머스켓'] = {};
lv105OptionMap['내딛은 자의 머스켓'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['잔잔한 선율'] = '4adcce602ca7ac179ba3048f17bc2e0e';
lv105OptionMap['잔잔한 선율'] = {};
lv105OptionMap['잔잔한 선율'] = '최종 데미지 10% 증가<br>모든 속성 강화 +20<br><br>HP MAX +600<br>물리/마법 방어력 +7,000<br>모든 상태 이상 내성 +20%<br>수면 내성 -30%@@4258@@1938';

lv105ItemId['결전의 대검 - 카오스'] = '18aeaccd9800c3f36be53350f8f4afe1';
lv105OptionMap['결전의 대검 - 카오스'] = {};
lv105OptionMap['결전의 대검 - 카오스'] = '제노사이드 크러쉬 시전 시 데몬들을 폭발시키는 대신 아텐의 화신이 데몬들의 환영을 소환하고 폭발시키도록 변경<br>- 공격력 10% 증가<br>- 스킬 범위 20% 증가<br>- 시전 중 미드나잇 카니발 시전 가능<br>- 데몬이 없을 경우 즉시 모든 데몬 소환@@10076@@2016';

lv105ItemId['결전의 도 - 웨펀마스터'] = '0ce695e99bbdfb5d18876006870f97a6';
lv105OptionMap['결전의 도 - 웨펀마스터'] = {};
lv105OptionMap['결전의 도 - 웨펀마스터'] = '초식을 발전시켜 이기어검술 스킬 강화<br>- 지속 시간 +20초<br>- 기본기 숙련에 영향받는 스킬 적중 시 지속 시간 +0.5초 (최대 지속 시간 초과 불가능)<br>- 꿰뚫는검 사출 개수 +1<br>- 꿰뚫는검 사출 회수 시간 감소<br><br>장착 해제 시 이기어검술 지속 시간 종료@@8892@@2016';

lv105ItemId['내딛은 자의 자동권총'] = 'a2fa0a5c521791fd4faf4ddb6bf2e9c0';
lv105OptionMap['내딛은 자의 자동권총'] = {};
lv105OptionMap['내딛은 자의 자동권총'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['얼어붙은 저항의 투창'] = 'd2c8dcbc4aa446744be9b725085d28c4';
lv105OptionMap['얼어붙은 저항의 투창'] = {};
lv105OptionMap['얼어붙은 저항의 투창'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['마그네틱 서치 스캔'] = '2eef901281c1b3194a4ef35f4796b6b9';
lv105OptionMap['마그네틱 서치 스캔'] = {};
lv105OptionMap['마그네틱 서치 스캔'] = '최종 데미지 2% 증가<br>감전 피해 +20%<br><br>감전 상태인 대상 하나마다 공격력 증가 +71.2% (최대 5 중첩)<br><br>자신에게 해제되지 않는 감전 상태 이상 부여<br><br>물리/마법 크리티컬 히트 +10%<br>공격 속도 +5%<br>캐스팅 속도 +7.5%<br>HP 1분당 460.2 회복@@6144@@1968';

lv105ItemId['내딛은 자의 쌍검'] = '9ce3446034dd0166b1624e41959b5df5';
lv105OptionMap['내딛은 자의 쌍검'] = {};
lv105OptionMap['내딛은 자의 쌍검'] = '1~100Lv 스킬 Lv +2<br><br>50, 85, 100Lv 액티브 스킬 공격력 30% 증가<br>50, 85, 100Lv 액티브 스킬 쿨타임 20% 감소<br>(버퍼 캐릭터는 적용되지 않습니다.)<br><br>장착 해제 시 마나번 디버프가 발생하여 1초 마다 MP 10%, 5,000 감소<br>- 내딛은 자의 무기 재장착 시 마나번 해제<br>- 마나번 디버프는 코인을 포함한 부활 후에도 계속 적용@@4744@@1856';

lv105ItemId['손에 닿은 기억'] = '13de6a9bdef797aefc576a1c67dbee20';
lv105OptionMap['손에 닿은 기억'] = {};
lv105OptionMap['손에 닿은 기억'] = '최종 데미지 7% 증가<br>스킬 쿨타임 회복 속도 +10% (각성기 제외)<br><br>슈퍼아머 부여<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>이동 속도 +5%<br>MP 1분당 348 회복@@4796@@1920';

lv105ItemId['결전의 낫 - 무녀'] = '562d41525b98e819666aa0a4087e0374';
lv105OptionMap['결전의 낫 - 무녀'] = {};
lv105OptionMap['결전의 낫 - 무녀'] = '염주 사출, 목환자경 타격 1회당 신력 염주알 1개 획득 (최대 25개)<br><br>아래 스킬 시전 시 신력 염주알을 소모하여 강화<br>- 부동주박진 : 염주 회전 수 1회 증가 (8개 소모)<br>- 백팔염주 : 염주 사출 개수 10개 증가 (10개 소모)<br>- 마혼부 : 퇴마음양옥 : 튕기는 횟수 3회 증가 (10개 소모)<br>- 염폭모대주 : 염주알 낙하 수 1 증가 (15개 소모)<br><br>염주 사출, 목환자경 시전 1회당 백팔염주, 부동주박진 스킬 쿨타임 회복 속도 +1%<br>- 백팔염주, 부동주박진 시전 시 효과 초기화@@4148@@2016';

lv105ItemId['결전의 토템 - 크루세이더(남)'] = '1027d9e19be0d970f772bd52f2fd79fb';
lv105OptionMap['결전의 토템 - 크루세이더(남)'] = {};
lv105OptionMap['결전의 토템 - 크루세이더(남)'] = '영광의 축복 효과 8% 증가<br>- 장착 해제 시 효과 삭제<br>- 버프 강화 무기 슬롯에 장비 등록 시 미적용<br><br>슬로우 힐 스킬 범위 20% 증가<br><br>정의의 심판 시전 시 빛무리를 소환하여 범위 내 적 10명을 추적 후 정의의 창을 발사하여 폭발<br>- 창 공격력 : 빛의 칼날 공격력의 1600%<br>- 폭발 공격력 : 마법진 최대 유지 시 피니쉬 공격력의 100%<br>- 다른 스킬 시전 중 시전 가능<br><br>미러클 스플리터 스킬 공격력 20% 증가<br>- 돌진 거리 70% 감소<br><br>디플렉트 월 HP 400% 증가<br>- 이동하지 않음<br><br>싱글 플레이 시 독립 공격력 20% 증가<br><br>장착 해제 시 버퍼 50Lv 스킬 버프 효과 50% 감소@@7708@@1856';

lv105ItemId['영원을 끝낼 오랜 염원'] = '1854a7d3b785db5363030143746a4a29';
lv105OptionMap['영원을 끝낼 오랜 염원'] = {};
lv105OptionMap['영원을 끝낼 오랜 염원'] = '최종 데미지 14% 증가<br><br>[폭독]<br>화기를 만나면 폭발하는 독을 사용하며 3초마다 적에게 화기 부여<br>- 화상 부여 시 중독 폭발<br>- 10초 동안 화상 부여 (쿨타임 3초)<br><br>공격 속도 +8%<br>캐스팅 속도 +12%<br>MP 1분당 348 회복@@5484@@1943';

lv105ItemId['사멸하는 신뢰의 투창'] = '3c2ab144a4393cb3d995e71324300597';
lv105OptionMap['사멸하는 신뢰의 투창'] = {};
lv105OptionMap['사멸하는 신뢰의 투창'] = '최종 데미지 10% 증가<br><br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 부여 (쿨타임 3초)<br><br>3초마다 HP/MP 1% 회복<br><br>모든 상태 이상 내성 +20%@@7112@@1916';

lv105ItemId['결전의 머스켓 - 런처(남)'] = '6a7b3ca276ecee8a013261f0a10eef25';
lv105OptionMap['결전의 머스켓 - 런처(남)'] = {};
lv105OptionMap['결전의 머스켓 - 런처(남)'] = '레이저 라이플을 최대 3회 충전 스킬로 변경<br>- 공격력 20% 증가<br>- 크기 40% 증가<br>- 충전 쿨타임 : 7초<br>- 충전 레이저 라이플 충전 시간 50% 감소<br>- 적중 시 적에게 10초 동안 전자기장 부여<br><br>FM-92 스팅어 강화<br>- 공격력 15% 증가<br>- 폭발 범위 25% 증가<br>- 전자기장이 부여된 적 존재 시 대상을 추적하는 강화 고폭탄 발사@@8892@@2016';

lv105ItemId['결전의 도 - 블레이드'] = '758bc0a7f3af620b2dd22984d3ce2f1b';
lv105OptionMap['결전의 도 - 블레이드'] = {};
lv105OptionMap['결전의 도 - 블레이드'] = '납도술 연계 시 납도술 스킬과 연계한 스킬의 남은 쿨타임 15% 감소<br>- 각성기로 납도술 연계 시 미적용<br><br>러스티 네일 베기 공격력이 삭제되고, 후딜레이를 캔슬하여 전직 스킬 시전 가능<br>- 베기 공격력을 발차기 공격력에 합산<br><br>전직 스킬 후딜레이를 캔슬하여 러스티 네일 시전 가능<br>- 셰이커 블래스트, 각성기 제외@@8892@@2016';

lv105ItemId['저주받은 굴레'] = 'ce1cf7f9b7c8a799800af70201d0d04c';
lv105OptionMap['저주받은 굴레'] = {};
lv105OptionMap['저주받은 굴레'] = '최종 데미지 7.1% 증가<br><br>자신이 출혈 상태일 때 스킬 쿨타임 회복 속도 +30% (각성기 제외)<br><br>HP 회복 효과 50% 증가<br><br>물리/마법 방어력 +7,000<br>출혈 내성 +1%@@3787@@1948';

lv105ItemId['기사의 속죄'] = '403dcc99cc1fb678bd7e191597e6ca0c';
lv105OptionMap['기사의 속죄'] = {};
lv105OptionMap['기사의 속죄'] = '최종 데미지 10% 증가<br><br>대시하는 동안 회피율 +20%<br><br>물리/마법 크리티컬 히트 +5%<br>공격 속도 +20%<br>캐스팅 속도 +30%<br>이동 속도 +20%<br>물리/마법 피해 감소 +10%<br>물리/마법 방어력 +7,000@@5604@@1928';

lv105ItemId['성장하는 모험가의 허리'] = 'null';
lv105OptionMap['성장하는 모험가의 허리'] = {};
lv105OptionMap['성장하는 모험가의 허리'] = '최종 데미지 7% 증가@@533@@1986';

lv105ItemId['잊혀진 기록'] = '770802db344f63c63a4627be40d10d58';
lv105OptionMap['잊혀진 기록'] = {};
lv105OptionMap['잊혀진 기록'] = '최종 데미지 14% 증가<br><br>MP MAX +945<br>이동 속도 +8%@@5336@@1948';

lv105ItemId['숨결을 삼킨 용옥'] = 'bcd71527f886ea4900bde373340ae966';
lv105OptionMap['숨결을 삼킨 용옥'] = {};
lv105OptionMap['숨결을 삼킨 용옥'] = '최종 데미지 13% 증가<br><br>물리/마법 크리티컬 히트 +5%<br>물리/마법 피해 감소 +12%<br>HP 1분당 460.2 회복@@5604@@1900';

lv105ItemId['근원을 삼킨 차크라 웨펀'] = '083ebd835ad489da04df5117c1e5fb08';
lv105OptionMap['근원을 삼킨 차크라 웨펀'] = {};
lv105OptionMap['근원을 삼킨 차크라 웨펀'] = '최종 데미지 10% 증가<br>모든 스킬 Lv +1 (각성기 제외)<br><br>HP MAX +5%<br>이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20@@5336@@1996';

lv105ItemId['얼어붙은 저항의 스태프'] = '37e55a21aac666db444646c09d9c664d';
lv105OptionMap['얼어붙은 저항의 스태프'] = {};
lv105OptionMap['얼어붙은 저항의 스태프'] = '스킬 쿨타임 회복 속도 +40% (각성기 제외)<br>水(수) 공격 속성<br><br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환<br><br>공격 시 적의 약점 상태 이상에 빙결 추가 부여<br><br>공격 시 적에게 5초 동안 빙결 상태 이상 부여 (쿨타임 3초)<br><br>수속성 저항 +15<br>빙결 내성 +40%<br><br>장착 해제 시 마나번 디버프 발생<br>- 1초마다 MP 10% / 5,000 감소<br>- 얼어붙은 저항의 무기 재장착 시 마나번 해제@@5928@@1836';

lv105ItemId['결전의 머스켓 - 스핏파이어(남)'] = '5f13dc8d42577ef9032cb961ce034f9a';
lv105OptionMap['결전의 머스켓 - 스핏파이어(남)'] = {};
lv105OptionMap['결전의 머스켓 - 스핏파이어(남)'] = '버스터 샷이 가장 강한 적에게 지원 포격을 발사하도록 변경<br>- 다른 스킬 사용 중 모션 없이 시전 가능<br><br>오버차지 적용 중 평타 10회 공격 시 버스터샷 강화 탄약 충전 (최대 3개)<br>- 버스터샷 시전 시 강화 탄약 소모<br>- 탄약 1개 소모 : 공격력 15% 증가<br>- 탄약 2개 소모 : 공격력 40% 증가<br>- 탄약 3개 소모 : 공격력 80% 증가<br>- 던전 입장 시 탄약 1개 장착@@7112@@2016';

//커스텀부
lv105CustomOptionMap[1] = "최종 데미지 2% 증가<br>100Lv 액티브 스킬 공격력 20% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>100Lv 액티브 스킬 공격력 20% 증가"] = 1;
		
		lv105CustomOptionMap[2] = "공격력 증가 + N%<br>무력화 게이지 감소량 +30%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>무력화 게이지 감소량 +30%"] = 2;
		
		lv105CustomOptionMap[3] = "15Lv 스킬 시전 시 2초 동안 슈퍼 아머 부여 (쿨타임 10초)<br>공격 속도 +15%<br>캐스팅 속도 +22.5%";
		lv105CustomOptionToNumberMap["15Lv 스킬 시전 시 2초 동안 슈퍼 아머 부여 (쿨타임 10초)<br>공격 속도 +15%<br>캐스팅 속도 +22.5%"] = 3;
		
		lv105CustomOptionMap[4] = "35Lv 스킬 쿨타임 회복 속도 +40%";
		lv105CustomOptionToNumberMap["35Lv 스킬 쿨타임 회복 속도 +40%"] = 4;
		
		lv105CustomOptionMap[5] = "20Lv 스킬 시전 시 2초 동안 슈퍼 아머 부여 (쿨타임 10초)<br>공격 속도 +15%<br>캐스팅 속도 +22.5%";
		lv105CustomOptionToNumberMap["20Lv 스킬 시전 시 2초 동안 슈퍼 아머 부여 (쿨타임 10초)<br>공격 속도 +15%<br>캐스팅 속도 +22.5%"] = 5;
		
		lv105CustomOptionMap[6] = "40Lv 스킬 쿨타임 회복 속도 +40%";
		lv105CustomOptionToNumberMap["40Lv 스킬 쿨타임 회복 속도 +40%"] = 6;
		
		lv105CustomOptionMap[7] = "25Lv 스킬 시전 시 2초 동안 슈퍼 아머 부여 (쿨타임 10초)<br>공격 속도 +15%<br>캐스팅 속도 +22.5%";
		lv105CustomOptionToNumberMap["25Lv 스킬 시전 시 2초 동안 슈퍼 아머 부여 (쿨타임 10초)<br>공격 속도 +15%<br>캐스팅 속도 +22.5%"] = 7;
		
		lv105CustomOptionMap[8] = "45Lv 스킬 쿨타임 회복 속도 +40%";
		lv105CustomOptionToNumberMap["45Lv 스킬 쿨타임 회복 속도 +40%"] = 8;
		
		lv105CustomOptionMap[9] = "공격력 증가 + N%<br>무력화 게이지 감소량 +10%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>무력화 게이지 감소량 +10%"] = 9;
		
		lv105CustomOptionMap[10] = "물리/마법 피해 감소 +30%";
		lv105CustomOptionToNumberMap["물리/마법 피해 감소 +30%"] = 10;
		
		lv105CustomOptionMap[11] = "모든 속성 강화 +30";
		lv105CustomOptionToNumberMap["모든 속성 강화 +30"] = 11;
		
		lv105CustomOptionMap[12] = "30Lv 스킬 시전 시 2초 동안 슈퍼 아머 부여 (쿨타임 10초)<br>공격 속도 +15%<br>캐스팅 속도 +22.5%";
		lv105CustomOptionToNumberMap["30Lv 스킬 시전 시 2초 동안 슈퍼 아머 부여 (쿨타임 10초)<br>공격 속도 +15%<br>캐스팅 속도 +22.5%"] = 12;
		
		lv105CustomOptionMap[13] = "60Lv 스킬 쿨타임 회복 속도 +40%";
		lv105CustomOptionToNumberMap["60Lv 스킬 쿨타임 회복 속도 +40%"] = 13;
		
		lv105CustomOptionMap[14] = "최종 데미지 8% 증가<br>공격 속도 +20%<br>캐스팅 속도 +30%<br>이동 속도 +20%";
		lv105CustomOptionToNumberMap["최종 데미지 8% 증가<br>공격 속도 +20%<br>캐스팅 속도 +30%<br>이동 속도 +20%"] = 14;
		
		lv105CustomOptionMap[15] = "공격력 증가 + N%<br>물리/마법 피해 감소 +20%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>물리/마법 피해 감소 +20%"] = 15;
		
		lv105CustomOptionMap[16] = "공격력 증가 + N%<br>모든 속도 +20%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>모든 속도 +20%"] = 16;
		
		lv105CustomOptionMap[17] = "70Lv 스킬 쿨타임 회복 속도 +40%";
		lv105CustomOptionToNumberMap["70Lv 스킬 쿨타임 회복 속도 +40%"] = 17;
		
		lv105CustomOptionMap[18] = "35Lv 스킬 공격력 20% 증가<br>35Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["35Lv 스킬 공격력 20% 증가<br>35Lv 스킬 쿨타임 15% 증가"] = 18;
		
		lv105CustomOptionMap[19] = "75Lv 스킬 쿨타임 회복 속도 +40%";
		lv105CustomOptionToNumberMap["75Lv 스킬 쿨타임 회복 속도 +40%"] = 19;
		
		lv105CustomOptionMap[20] = "35Lv 스킬 공격력 20% 증가<br>40Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["35Lv 스킬 공격력 20% 증가<br>40Lv 스킬 쿨타임 15% 증가"] = 20;
		
		lv105CustomOptionMap[21] = "40Lv 스킬 공격력 20% 증가<br>40Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["40Lv 스킬 공격력 20% 증가<br>40Lv 스킬 쿨타임 15% 증가"] = 21;
		
		lv105CustomOptionMap[22] = "80Lv 스킬 쿨타임 회복 속도 +40%";
		lv105CustomOptionToNumberMap["80Lv 스킬 쿨타임 회복 속도 +40%"] = 22;
		
		lv105CustomOptionMap[23] = "40Lv 스킬 공격력 20% 증가<br>45Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["40Lv 스킬 공격력 20% 증가<br>45Lv 스킬 쿨타임 15% 증가"] = 23;
		
		lv105CustomOptionMap[24] = "45Lv 스킬 공격력 20% 증가<br>45Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["45Lv 스킬 공격력 20% 증가<br>45Lv 스킬 쿨타임 15% 증가"] = 24;
		
		lv105CustomOptionMap[25] = "감전 상태인 대상 하나마다 공격력 증가 + N% (최대 5중첩)";
		lv105CustomOptionToNumberMap["감전 상태인 대상 하나마다 공격력 증가 + N% (최대 5중첩)"] = 25;
		
		lv105CustomOptionMap[26] = "공격력 증가 + N%<br>구속 지속 시간 +3초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>구속 지속 시간 +3초"] = 26;
		
		lv105CustomOptionMap[27] = "공격력 증가 + N%<br>기절 지속 시간 +3초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>기절 지속 시간 +3초"] = 27;
		
		lv105CustomOptionMap[28] = "공격력 증가 + N%<br>둔화 지속 시간 +3초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>둔화 지속 시간 +3초"] = 28;
		
		lv105CustomOptionMap[29] = "공격력 증가 + N%<br>빙결 지속 시간 +3초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>빙결 지속 시간 +3초"] = 29;
		
		lv105CustomOptionMap[30] = "공격력 증가 + N%<br>석화 지속 시간 +3초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>석화 지속 시간 +3초"] = 30;
		
		lv105CustomOptionMap[31] = "공격력 증가 + N%<br>수면 지속 시간 +3초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>수면 지속 시간 +3초"] = 31;
		
		lv105CustomOptionMap[32] = "공격력 증가 + N%<br>암흑 지속 시간 +3초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>암흑 지속 시간 +3초"] = 32;
		
		lv105CustomOptionMap[33] = "공격력 증가 + N%<br>저주 지속 시간 +3초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>저주 지속 시간 +3초"] = 33;
		
		lv105CustomOptionMap[34] = "공격 속도 +16%<br>캐스팅 속도 +24%";
		lv105CustomOptionToNumberMap["공격 속도 +16%<br>캐스팅 속도 +24%"] = 34;
		
		lv105CustomOptionMap[35] = "명속성 강화 +40<br>HP MAX +200";
		lv105CustomOptionToNumberMap["명속성 강화 +40<br>HP MAX +200"] = 35;
		
		lv105CustomOptionMap[36] = "최종 데미지 6% 증가<br>HP MAX +200";
		lv105CustomOptionToNumberMap["최종 데미지 6% 증가<br>HP MAX +200"] = 36;
		
		lv105CustomOptionMap[37] = "모든 속성 저항 +16";
		lv105CustomOptionToNumberMap["모든 속성 저항 +16"] = 37;
		
		lv105CustomOptionMap[38] = "물리/마법 방어력 +8,000";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +8,000"] = 38;
		
		lv105CustomOptionMap[39] = "물리/마법 크리티컬 히트 +12%";
		lv105CustomOptionToNumberMap["물리/마법 크리티컬 히트 +12%"] = 39;
		
		lv105CustomOptionMap[40] = "수속성 강화 +40<br>HP MAX +200";
		lv105CustomOptionToNumberMap["수속성 강화 +40<br>HP MAX +200"] = 40;
		
		lv105CustomOptionMap[41] = "스킬 쿨타임 15% 감소 (각성기 제외)";
		lv105CustomOptionToNumberMap["스킬 쿨타임 15% 감소 (각성기 제외)"] = 41;
		
		lv105CustomOptionMap[42] = "암속성 강화 +40<br>HP MAX +200";
		lv105CustomOptionToNumberMap["암속성 강화 +40<br>HP MAX +200"] = 42;
		
		lv105CustomOptionMap[43] = "공격력 증가 + 444.6%";
		lv105CustomOptionToNumberMap["공격력 증가 + 444.6%"] = 43;
		
		lv105CustomOptionMap[44] = "화속성 강화 +40<br>HP MAX +200";
		lv105CustomOptionToNumberMap["화속성 강화 +40<br>HP MAX +200"] = 44;
		
		lv105CustomOptionMap[45] = "중독 상태인 대상 하나마다 공격력 증가 + N% (최대 5중첩)";
		lv105CustomOptionToNumberMap["중독 상태인 대상 하나마다 공격력 증가 + N% (최대 5중첩)"] = 45;
		
		lv105CustomOptionMap[46] = "출혈 상태인 대상 하나마다 공격력 증가 + N% (최대 5중첩)";
		lv105CustomOptionToNumberMap["출혈 상태인 대상 하나마다 공격력 증가 + N% (최대 5중첩)"] = 46;
		
		lv105CustomOptionMap[47] = "공격력 증가 + N%<br>혼란 지속 시간 +3초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>혼란 지속 시간 +3초"] = 47;
		
		lv105CustomOptionMap[48] = "화상 상태인 대상 하나마다 공격력 증가 + N% (최대 5중첩)";
		lv105CustomOptionToNumberMap["화상 상태인 대상 하나마다 공격력 증가 + N% (최대 5중첩)"] = 48;
		
		lv105CustomOptionMap[49] = "공격력 증가 + N%<br>물리/마법 방어력 +5,000";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>물리/마법 방어력 +5,000"] = 49;
		
		lv105CustomOptionMap[50] = "공격력 증가 + 355.7%";
		lv105CustomOptionToNumberMap["공격력 증가 + 355.7%"] = 50;
		
		lv105CustomOptionMap[51] = "60Lv 스킬 공격력 20% 증가<br>60Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["60Lv 스킬 공격력 20% 증가<br>60Lv 스킬 쿨타임 15% 증가"] = 51;
		
		lv105CustomOptionMap[52] = "95Lv 스킬 쿨타임 회복 속도 +40%";
		lv105CustomOptionToNumberMap["95Lv 스킬 쿨타임 회복 속도 +40%"] = 52;
		
		lv105CustomOptionMap[53] = "45Lv 스킬 공격력 20% 증가<br>60Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["45Lv 스킬 공격력 20% 증가<br>60Lv 스킬 쿨타임 15% 증가"] = 53;
		
		lv105CustomOptionMap[54] = "60Lv 스킬 공격력 20% 증가<br>70Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["60Lv 스킬 공격력 20% 증가<br>70Lv 스킬 쿨타임 15% 증가"] = 54;
		
		lv105CustomOptionMap[55] = "70Lv 스킬 공격력 20% 증가<br>70Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["70Lv 스킬 공격력 20% 증가<br>70Lv 스킬 쿨타임 15% 증가"] = 55;
		
		lv105CustomOptionMap[56] = "70Lv 스킬 공격력 20% 증가<br>75Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["70Lv 스킬 공격력 20% 증가<br>75Lv 스킬 쿨타임 15% 증가"] = 56;
		
		lv105CustomOptionMap[57] = "75Lv 스킬 공격력 20% 증가<br>75Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["75Lv 스킬 공격력 20% 증가<br>75Lv 스킬 쿨타임 15% 증가"] = 57;
		
		lv105CustomOptionMap[58] = "75Lv 스킬 공격력 20% 증가<br>80Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["75Lv 스킬 공격력 20% 증가<br>80Lv 스킬 쿨타임 15% 증가"] = 58;
		
		lv105CustomOptionMap[59] = "80Lv 스킬 공격력 20% 증가<br>80Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["80Lv 스킬 공격력 20% 증가<br>80Lv 스킬 쿨타임 15% 증가"] = 59;
		
		lv105CustomOptionMap[60] = "최종 데미지 2% 증가<br>85Lv 액티브 스킬 공격력 20% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>85Lv 액티브 스킬 공격력 20% 증가"] = 60;
		
		lv105CustomOptionMap[61] = "80Lv 스킬 공격력 20% 증가<br>95Lv 스킬 쿨타임 15% 증가";
		lv105CustomOptionToNumberMap["80Lv 스킬 공격력 20% 증가<br>95Lv 스킬 쿨타임 15% 증가"] = 61;
		
		lv105CustomOptionMap[62] = "HP 1분당 460.2 회복";
		lv105CustomOptionToNumberMap["HP 1분당 460.2 회복"] = 62;
		
		lv105CustomOptionMap[63] = "HP가 40% 이하일 때 최종 데미지 8% 증가<br>공격 시 HP 1,100 / MP 1,750 회복 (쿨타임 1초)<br>물리/마법 방어력 +25,000";
		lv105CustomOptionToNumberMap["HP가 40% 이하일 때 최종 데미지 8% 증가<br>공격 시 HP 1,100 / MP 1,750 회복 (쿨타임 1초)<br>물리/마법 방어력 +25,000"] = 63;
		
		lv105CustomOptionMap[64] = "물리/마법 방어력 +14,000<br>명속성 저항 +40";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +14,000<br>명속성 저항 +40"] = 64;
		
		lv105CustomOptionMap[65] = "공격력 증가 + N%<br>수속성 저항 +40";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>수속성 저항 +40"] = 65;
		
		lv105CustomOptionMap[66] = "HP가 40% 이상일 때 최종 데미지 3% 증가<br>가장 높은 출혈/중독/화상/감전 피해 수치가 20% 미만일 때 최종 데미지 4% 증가";
		lv105CustomOptionToNumberMap["HP가 40% 이상일 때 최종 데미지 3% 증가<br>가장 높은 출혈/중독/화상/감전 피해 수치가 20% 미만일 때 최종 데미지 4% 증가"] = 66;
		
		lv105CustomOptionMap[67] = "공격 속도 +15%<br>캐스팅 속도 +22.5%<br>암속성 저항 +40";
		lv105CustomOptionToNumberMap["공격 속도 +15%<br>캐스팅 속도 +22.5%<br>암속성 저항 +40"] = 67;
		
		lv105CustomOptionMap[68] = "최종 데미지 5% 증가<br>화속성 저항 +40";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>화속성 저항 +40"] = 68;
		
		lv105CustomOptionMap[69] = "HP MAX +1,200";
		lv105CustomOptionToNumberMap["HP MAX +1,200"] = 69;
		
		lv105CustomOptionMap[70] = "HP MAX +5%";
		lv105CustomOptionToNumberMap["HP MAX +5%"] = 70;
		
		lv105CustomOptionMap[71] = "HP MAX +600";
		lv105CustomOptionToNumberMap["HP MAX +600"] = 71;
		
		lv105CustomOptionMap[72] = "MP 1분당 348 회복";
		lv105CustomOptionToNumberMap["MP 1분당 348 회복"] = 72;
		
		lv105CustomOptionMap[73] = "MP MAX +1,890";
		lv105CustomOptionToNumberMap["MP MAX +1,890"] = 73;
		
		lv105CustomOptionMap[74] = "MP MAX +5%";
		lv105CustomOptionToNumberMap["MP MAX +5%"] = 74;
		
		lv105CustomOptionMap[75] = "MP MAX +945";
		lv105CustomOptionToNumberMap["MP MAX +945"] = 75;
		
		lv105CustomOptionMap[76] = "MP가 30% 이하일 때 무색 큐브 조각 10개를 소모하여 MP 30% 회복 (쿨타임 10초)";
		lv105CustomOptionToNumberMap["MP가 30% 이하일 때 무색 큐브 조각 10개를 소모하여 MP 30% 회복 (쿨타임 10초)"] = 76;
		
		lv105CustomOptionMap[77] = "최종 데미지 5% 증가<br>물리/마법 방어력 +5,000";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>물리/마법 방어력 +5,000"] = 77;
		
		lv105CustomOptionMap[465] = "공격력 증가 + N%<br>모든 속성 저항 +3";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>모든 속성 저항 +3"] = 465;
		
		lv105CustomOptionMap[79] = "감전 피해 +15%<br>감전 내성 +1%";
		lv105CustomOptionToNumberMap["감전 피해 +15%<br>감전 내성 +1%"] = 79;
		
		lv105CustomOptionMap[80] = "중독 피해 +15%<br>중독 내성 +1%";
		lv105CustomOptionToNumberMap["중독 피해 +15%<br>중독 내성 +1%"] = 80;
		
		lv105CustomOptionMap[81] = "출혈 피해 +15%<br>출혈 내성 +1%";
		lv105CustomOptionToNumberMap["출혈 피해 +15%<br>출혈 내성 +1%"] = 81;
		
		lv105CustomOptionMap[82] = "화상 피해 +15%<br>화상 내성 +1%";
		lv105CustomOptionToNumberMap["화상 피해 +15%<br>화상 내성 +1%"] = 82;
		
		lv105CustomOptionMap[83] = "공격력 증가 + N%<br>모든 속도 +24%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>모든 속도 +24%"] = 83;
		
		lv105CustomOptionMap[84] = "물리/마법 피해의 30%를 감전 피해로 전환<br>감전 내성 +40%";
		lv105CustomOptionToNumberMap["물리/마법 피해의 30%를 감전 피해로 전환<br>감전 내성 +40%"] = 84;
		
		lv105CustomOptionMap[85] = "물리/마법 방어력 +4,000<br>감전 내성 +20%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +4,000<br>감전 내성 +20%"] = 85;
		
		lv105CustomOptionMap[86] = "감전 피해 +5%<br>감전 내성 +5%";
		lv105CustomOptionToNumberMap["감전 피해 +5%<br>감전 내성 +5%"] = 86;
		
		lv105CustomOptionMap[87] = "감전 피해 +10%";
		lv105CustomOptionToNumberMap["감전 피해 +10%"] = 87;
		
		lv105CustomOptionMap[88] = "감전 피해 +30%<br>물리/마법 피해 감소 -20%<br>감전 내성 +20%";
		lv105CustomOptionToNumberMap["감전 피해 +30%<br>물리/마법 피해 감소 -20%<br>감전 내성 +20%"] = 88;
		
		lv105CustomOptionMap[89] = "최종 데미지 5% 증가<br>감전 내성 +1%";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>감전 내성 +1%"] = 89;
		
		lv105CustomOptionMap[90] = "최종 데미지 2% 증가<br>곤충 타입의 적 공격 시 최종 데미지 5% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>곤충 타입의 적 공격 시 최종 데미지 5% 증가"] = 90;
		
		lv105CustomOptionMap[91] = "공격 속도 +8%<br>캐스팅 속도 +12%";
		lv105CustomOptionToNumberMap["공격 속도 +8%<br>캐스팅 속도 +12%"] = 91;
		
		lv105CustomOptionMap[92] = "공격 속도 +30%";
		lv105CustomOptionToNumberMap["공격 속도 +30%"] = 92;
		
		lv105CustomOptionMap[93] = "공격 시 30초 동안 슈퍼 아머 부여 (쿨타임 8초)";
		lv105CustomOptionToNumberMap["공격 시 30초 동안 슈퍼 아머 부여 (쿨타임 8초)"] = 93;
		
		lv105CustomOptionMap[94] = "공격 시 HP 2,200 회복 (쿨타임 1초)";
		lv105CustomOptionToNumberMap["공격 시 HP 2,200 회복 (쿨타임 1초)"] = 94;
		
		lv105CustomOptionMap[95] = "공격 시 HP 5% 회복 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 HP 5% 회복 (쿨타임 3초)"] = 95;
		
		lv105CustomOptionMap[96] = "공격 시 MP 3,500 회복 (쿨타임 1초)";
		lv105CustomOptionToNumberMap["공격 시 MP 3,500 회복 (쿨타임 1초)"] = 96;
		
		lv105CustomOptionMap[97] = "공격 시 MP 5% 회복 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 MP 5% 회복 (쿨타임 3초)"] = 97;
		
		lv105CustomOptionMap[98] = "35~40Lv 액티브 스킬 Lv +3";
		lv105CustomOptionToNumberMap["35~40Lv 액티브 스킬 Lv +3"] = 98;
		
		lv105CustomOptionMap[99] = "45, 60Lv 액티브 스킬 Lv +3";
		lv105CustomOptionToNumberMap["45, 60Lv 액티브 스킬 Lv +3"] = 99;
		
		lv105CustomOptionMap[100] = "70~75Lv 액티브 스킬 Lv +3";
		lv105CustomOptionToNumberMap["70~75Lv 액티브 스킬 Lv +3"] = 100;
		
		lv105CustomOptionMap[101] = "80, 95Lv 액티브 스킬 Lv +3";
		lv105CustomOptionToNumberMap["80, 95Lv 액티브 스킬 Lv +3"] = 101;
		
		lv105CustomOptionMap[102] = "감전 피해 +15%<br>HP MAX +300";
		lv105CustomOptionToNumberMap["감전 피해 +15%<br>HP MAX +300"] = 102;
		
		lv105CustomOptionMap[103] = "명속성 강화 +25";
		lv105CustomOptionToNumberMap["명속성 강화 +25"] = 103;
		
		lv105CustomOptionMap[104] = "물리/마법 크리티컬 히트 +20%";
		lv105CustomOptionToNumberMap["물리/마법 크리티컬 히트 +20%"] = 104;
		
		lv105CustomOptionMap[105] = "수속성 강화 +25";
		lv105CustomOptionToNumberMap["수속성 강화 +25"] = 105;
		
		lv105CustomOptionMap[106] = "최종 데미지 6% 증가<br>이동 속도 +5%";
		lv105CustomOptionToNumberMap["최종 데미지 6% 증가<br>이동 속도 +5%"] = 106;
		
		lv105CustomOptionMap[107] = "암속성 강화 +25";
		lv105CustomOptionToNumberMap["암속성 강화 +25"] = 107;
		
		lv105CustomOptionMap[108] = "중독 피해 +15%<br>HP MAX +300";
		lv105CustomOptionToNumberMap["중독 피해 +15%<br>HP MAX +300"] = 108;
		
		lv105CustomOptionMap[109] = "출혈 피해 +15%<br>HP MAX +300";
		lv105CustomOptionToNumberMap["출혈 피해 +15%<br>HP MAX +300"] = 109;
		
		lv105CustomOptionMap[110] = "화상 피해 +15%<br>HP MAX +300";
		lv105CustomOptionToNumberMap["화상 피해 +15%<br>HP MAX +300"] = 110;
		
		lv105CustomOptionMap[111] = "화속성 강화 +25";
		lv105CustomOptionToNumberMap["화속성 강화 +25"] = 111;
		
		lv105CustomOptionMap[112] = "최종 데미지 4% 증가<br>화속성 강화 +25";
		lv105CustomOptionToNumberMap["최종 데미지 4% 증가<br>화속성 강화 +25"] = 112;
		
		lv105CustomOptionMap[113] = "공격 시 적에게 10초 동안 감전 상태 이상 부여 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 적에게 10초 동안 감전 상태 이상 부여 (쿨타임 3초)"] = 113;
		
		lv105CustomOptionMap[114] = "공격 시 적에게 10초 동안 구속 상태 이상 부여 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 적에게 10초 동안 구속 상태 이상 부여 (쿨타임 3초)"] = 114;
		
		lv105CustomOptionMap[115] = "공격 시 적에게 10초 동안 기절 상태 이상 부여 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 적에게 10초 동안 기절 상태 이상 부여 (쿨타임 3초)"] = 115;
		
		lv105CustomOptionMap[116] = "공격 시 적에게 10초 동안 둔화 상태 이상 부여 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 적에게 10초 동안 둔화 상태 이상 부여 (쿨타임 3초)"] = 116;
		
		lv105CustomOptionMap[117] = "공격 시 적에게 10초 동안 빙결 상태 이상 부여 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 적에게 10초 동안 빙결 상태 이상 부여 (쿨타임 3초)"] = 117;
		
		lv105CustomOptionMap[118] = "공격 시 적에게 10초 동안 석화 상태 이상 부여 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 적에게 10초 동안 석화 상태 이상 부여 (쿨타임 3초)"] = 118;
		
		lv105CustomOptionMap[119] = "공격 시 적에게 10초 동안 수면 상태 이상 부여 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 적에게 10초 동안 수면 상태 이상 부여 (쿨타임 3초)"] = 119;
		
		lv105CustomOptionMap[120] = "공격 시 적에게 10초 동안 암흑 상태 이상 부여 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 적에게 10초 동안 암흑 상태 이상 부여 (쿨타임 3초)"] = 120;
		
		lv105CustomOptionMap[121] = "공격 시 적에게 10초 동안 저주 상태 이상 부여 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 적에게 10초 동안 저주 상태 이상 부여 (쿨타임 3초)"] = 121;
		
		lv105CustomOptionMap[122] = "공격 시 적에게 10초 동안 중독 상태 이상 부여 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 적에게 10초 동안 중독 상태 이상 부여 (쿨타임 3초)"] = 122;
		
		lv105CustomOptionMap[123] = "공격 시 적에게 10초 동안 출혈 상태 이상 부여 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 적에게 10초 동안 출혈 상태 이상 부여 (쿨타임 3초)"] = 123;
		
		lv105CustomOptionMap[124] = "공격 시 적에게 10초 동안 혼란 상태 이상 부여 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 적에게 10초 동안 혼란 상태 이상 부여 (쿨타임 3초)"] = 124;
		
		lv105CustomOptionMap[125] = "공격 시 적에게 10초 동안 화상 상태 이상 부여 (쿨타임 3초)";
		lv105CustomOptionToNumberMap["공격 시 적에게 10초 동안 화상 상태 이상 부여 (쿨타임 3초)"] = 125;
		
		lv105CustomOptionMap[126] = "최종 데미지 4% 증가<br>수속성 강화 +25";
		lv105CustomOptionToNumberMap["최종 데미지 4% 증가<br>수속성 강화 +25"] = 126;
		
		lv105CustomOptionMap[127] = "공격력 증가 + N%<br>물리/마법 방어력 +5,000";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>물리/마법 방어력 +5,000"] = 127;
		
		lv105CustomOptionMap[128] = "최종 데미지 4% 증가<br>암속성 강화 +25";
		lv105CustomOptionToNumberMap["최종 데미지 4% 증가<br>암속성 강화 +25"] = 128;
		
		lv105CustomOptionMap[129] = "최종 데미지 4% 증가<br>명속성 강화 +25";
		lv105CustomOptionToNumberMap["최종 데미지 4% 증가<br>명속성 강화 +25"] = 129;
		
		lv105CustomOptionMap[130] = "무색 큐브 조각을 15개 이상 소모하는 스킬 시전 시 30초 동안 최종 데미지 15% 증가<br>구속 내성 +40%";
		lv105CustomOptionToNumberMap["무색 큐브 조각을 15개 이상 소모하는 스킬 시전 시 30초 동안 최종 데미지 15% 증가<br>구속 내성 +40%"] = 130;
		
		lv105CustomOptionMap[131] = "물리/마법 방어력 +4,000<br>구속 내성 +20%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +4,000<br>구속 내성 +20%"] = 131;
		
		lv105CustomOptionMap[132] = "구속 상태 적 공격 시 30초 동안 최종 데미지 10% 증가";
		lv105CustomOptionToNumberMap["구속 상태 적 공격 시 30초 동안 최종 데미지 10% 증가"] = 132;
		
		lv105CustomOptionMap[133] = "최종 데미지 2% 증가<br>기계 타입의 적 공격 시 최종 데미지 5% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>기계 타입의 적 공격 시 최종 데미지 5% 증가"] = 133;
		
		lv105CustomOptionMap[134] = "모든 속성 강화 +24";
		lv105CustomOptionToNumberMap["모든 속성 강화 +24"] = 134;
		
		lv105CustomOptionMap[135] = "출혈/중독/화상/감전 피해 +14%";
		lv105CustomOptionToNumberMap["출혈/중독/화상/감전 피해 +14%"] = 135;
		
		lv105CustomOptionMap[136] = "피격 시 20초 동안 HP MAX 20% 수치의 보호막 부여 (쿨타임 10초)<br>기절 내성 +40%";
		lv105CustomOptionToNumberMap["피격 시 20초 동안 HP MAX 20% 수치의 보호막 부여 (쿨타임 10초)<br>기절 내성 +40%"] = 136;
		
		lv105CustomOptionMap[137] = "물리/마법 방어력 +4,000<br>기절 내성 +20%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +4,000<br>기절 내성 +20%"] = 137;
		
		lv105CustomOptionMap[138] = "기절 상태 적 공격 시 30초 동안 최종 데미지 15% 증가";
		lv105CustomOptionToNumberMap["기절 상태 적 공격 시 30초 동안 최종 데미지 15% 증가"] = 138;
		
		lv105CustomOptionMap[139] = "최종 데미지 2% 증가<br>노블레스 코드 던전 입장 시 최종 데미지 10% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>노블레스 코드 던전 입장 시 최종 데미지 10% 증가"] = 139;
		
		lv105CustomOptionMap[140] = "대시 시 30초 동안 슈퍼 아머 부여 (쿨타임 5초)";
		lv105CustomOptionToNumberMap["대시 시 30초 동안 슈퍼 아머 부여 (쿨타임 5초)"] = 140;
		
		lv105CustomOptionMap[141] = "감전 피해 전환 +10%<br>감전 내성 +10%";
		lv105CustomOptionToNumberMap["감전 피해 전환 +10%<br>감전 내성 +10%"] = 141;
		
		lv105CustomOptionMap[142] = "중독 피해 전환 +10%<br>중독 내성 +10%";
		lv105CustomOptionToNumberMap["중독 피해 전환 +10%<br>중독 내성 +10%"] = 142;
		
		lv105CustomOptionMap[143] = "출혈 피해 전환 +10%<br>출혈 내성 +10%";
		lv105CustomOptionToNumberMap["출혈 피해 전환 +10%<br>출혈 내성 +10%"] = 143;
		
		lv105CustomOptionMap[144] = "화상 피해 전환 +10%<br>화상 내성 +10%";
		lv105CustomOptionToNumberMap["화상 피해 전환 +10%<br>화상 내성 +10%"] = 144;
		
		lv105CustomOptionMap[145] = "모든 속성 강화 +20<br>무력화 게이지 감소량 +3%";
		lv105CustomOptionToNumberMap["모든 속성 강화 +20<br>무력화 게이지 감소량 +3%"] = 145;
		
		lv105CustomOptionMap[146] = "감전 피해 +5%";
		lv105CustomOptionToNumberMap["감전 피해 +5%"] = 146;
		
		lv105CustomOptionMap[147] = "중독 피해 +5%";
		lv105CustomOptionToNumberMap["중독 피해 +5%"] = 147;
		
		lv105CustomOptionMap[148] = "출혈 피해 +5%";
		lv105CustomOptionToNumberMap["출혈 피해 +5%"] = 148;
		
		lv105CustomOptionMap[149] = "화상 피해 +5%";
		lv105CustomOptionToNumberMap["화상 피해 +5%"] = 149;
		
		lv105CustomOptionMap[150] = "공격력 증가 + N%<br>물리/마법 피해 감소 +20%<br>둔화 내성 +40%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>물리/마법 피해 감소 +20%<br>둔화 내성 +40%"] = 150;
		
		lv105CustomOptionMap[151] = "물리/마법 방어력 +4,000<br>둔화 내성 +20%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +4,000<br>둔화 내성 +20%"] = 151;
		
		lv105CustomOptionMap[152] = "둔화 상태 적 공격 시 30초 동안 최종 데미지 10% 증가";
		lv105CustomOptionToNumberMap["둔화 상태 적 공격 시 30초 동안 최종 데미지 10% 증가"] = 152;
		
		lv105CustomOptionMap[153] = "최종 데미지 2% 증가<br>마이스터의 실험실 던전 입장 시 최종 데미지 10% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>마이스터의 실험실 던전 입장 시 최종 데미지 10% 증가"] = 153;
		
		lv105CustomOptionMap[154] = "공격력 증가 + N%<br>HP MAX +300";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>HP MAX +300"] = 154;
		
		lv105CustomOptionMap[155] = "명속성 강화 +35<br>모든 상태 이상 내성 -10%";
		lv105CustomOptionToNumberMap["명속성 강화 +35<br>모든 상태 이상 내성 -10%"] = 155;
		
		lv105CustomOptionMap[156] = "명속성 강화 +20";
		lv105CustomOptionToNumberMap["명속성 강화 +20"] = 156;
		
		lv105CustomOptionMap[157] = "명속성 저항 +10";
		lv105CustomOptionToNumberMap["명속성 저항 +10"] = 157;
		
		lv105CustomOptionMap[158] = "모든 상태 이상 내성 +20%";
		lv105CustomOptionToNumberMap["모든 상태 이상 내성 +20%"] = 158;
		
		lv105CustomOptionMap[159] = "모든 속성 강화 +18";
		lv105CustomOptionToNumberMap["모든 속성 강화 +18"] = 159;
		
		lv105CustomOptionMap[160] = "모든 속성 강화 +20";
		lv105CustomOptionToNumberMap["모든 속성 강화 +20"] = 160;
		
		lv105CustomOptionMap[161] = "모든 속성 저항 +8";
		lv105CustomOptionToNumberMap["모든 속성 저항 +8"] = 161;
		
		lv105CustomOptionMap[162] = "15Lv 스킬 공격력 5% 증가<br>15Lv 스킬 범위 15% 증가";
		lv105CustomOptionToNumberMap["15Lv 스킬 공격력 5% 증가<br>15Lv 스킬 범위 15% 증가"] = 162;
		
		lv105CustomOptionMap[163] = "20Lv 스킬 공격력 20% 증가";
		lv105CustomOptionToNumberMap["20Lv 스킬 공격력 20% 증가"] = 163;
		
		lv105CustomOptionMap[164] = "20Lv 스킬 공격력 5% 증가<br>20Lv 스킬 범위 15% 증가";
		lv105CustomOptionToNumberMap["20Lv 스킬 공격력 5% 증가<br>20Lv 스킬 범위 15% 증가"] = 164;
		
		lv105CustomOptionMap[165] = "20Lv 스킬 쿨타임 20% 감소";
		lv105CustomOptionToNumberMap["20Lv 스킬 쿨타임 20% 감소"] = 165;
		
		lv105CustomOptionMap[166] = "25Lv 스킬 공격력 20% 증가";
		lv105CustomOptionToNumberMap["25Lv 스킬 공격력 20% 증가"] = 166;
		
		lv105CustomOptionMap[167] = "25Lv 스킬 공격력 5% 증가<br>25Lv 스킬 범위 15% 증가";
		lv105CustomOptionToNumberMap["25Lv 스킬 공격력 5% 증가<br>25Lv 스킬 범위 15% 증가"] = 167;
		
		lv105CustomOptionMap[168] = "25Lv 스킬 쿨타임 20% 감소";
		lv105CustomOptionToNumberMap["25Lv 스킬 쿨타임 20% 감소"] = 168;
		
		lv105CustomOptionMap[169] = "30Lv 스킬 공격력 20% 증가";
		lv105CustomOptionToNumberMap["30Lv 스킬 공격력 20% 증가"] = 169;
		
		lv105CustomOptionMap[170] = "30Lv 스킬 공격력 5% 증가<br>30Lv 스킬 범위 15% 증가";
		lv105CustomOptionToNumberMap["30Lv 스킬 공격력 5% 증가<br>30Lv 스킬 범위 15% 증가"] = 170;
		
		lv105CustomOptionMap[171] = "30Lv 스킬 쿨타임 20% 감소";
		lv105CustomOptionToNumberMap["30Lv 스킬 쿨타임 20% 감소"] = 171;
		
		lv105CustomOptionMap[172] = "35Lv 스킬 공격력 20% 증가";
		lv105CustomOptionToNumberMap["35Lv 스킬 공격력 20% 증가"] = 172;
		
		lv105CustomOptionMap[173] = "35Lv 스킬 공격력 5% 증가<br>35Lv 스킬 범위 15% 증가";
		lv105CustomOptionToNumberMap["35Lv 스킬 공격력 5% 증가<br>35Lv 스킬 범위 15% 증가"] = 173;
		
		lv105CustomOptionMap[174] = "35Lv 스킬 쿨타임 20% 감소";
		lv105CustomOptionToNumberMap["35Lv 스킬 쿨타임 20% 감소"] = 174;
		
		lv105CustomOptionMap[175] = "30~40Lv 스킬 공격력 5% 증가<br>35Lv 액티브 스킬 Lv +1";
		lv105CustomOptionToNumberMap["30~40Lv 스킬 공격력 5% 증가<br>35Lv 액티브 스킬 Lv +1"] = 175;
		
		lv105CustomOptionMap[176] = "40Lv 스킬 공격력 20% 증가";
		lv105CustomOptionToNumberMap["40Lv 스킬 공격력 20% 증가"] = 176;
		
		lv105CustomOptionMap[177] = "40Lv 스킬 공격력 5% 증가<br>40Lv 스킬 범위 15% 증가";
		lv105CustomOptionToNumberMap["40Lv 스킬 공격력 5% 증가<br>40Lv 스킬 범위 15% 증가"] = 177;
		
		lv105CustomOptionMap[178] = "40Lv 스킬 쿨타임 20% 감소";
		lv105CustomOptionToNumberMap["40Lv 스킬 쿨타임 20% 감소"] = 178;
		
		lv105CustomOptionMap[179] = "30~40Lv 스킬 공격력 5% 증가<br>40Lv 액티브 스킬 Lv +1";
		lv105CustomOptionToNumberMap["30~40Lv 스킬 공격력 5% 증가<br>40Lv 액티브 스킬 Lv +1"] = 179;
		
		lv105CustomOptionMap[180] = "45Lv 스킬 공격력 20% 증가";
		lv105CustomOptionToNumberMap["45Lv 스킬 공격력 20% 증가"] = 180;
		
		lv105CustomOptionMap[181] = "45Lv 스킬 공격력 5% 증가<br>45Lv 스킬 범위 15% 증가";
		lv105CustomOptionToNumberMap["45Lv 스킬 공격력 5% 증가<br>45Lv 스킬 범위 15% 증가"] = 181;
		
		lv105CustomOptionMap[182] = "45Lv 스킬 쿨타임 20% 감소";
		lv105CustomOptionToNumberMap["45Lv 스킬 쿨타임 20% 감소"] = 182;
		
		lv105CustomOptionMap[183] = "45, 60, 70Lv 스킬 공격력 5% 증가<br>45Lv 액티브 스킬 Lv +1";
		lv105CustomOptionToNumberMap["45, 60, 70Lv 스킬 공격력 5% 증가<br>45Lv 액티브 스킬 Lv +1"] = 183;
		
		lv105CustomOptionMap[184] = "60Lv 스킬 공격력 20% 증가";
		lv105CustomOptionToNumberMap["60Lv 스킬 공격력 20% 증가"] = 184;
		
		lv105CustomOptionMap[185] = "60Lv 스킬 공격력 5% 증가<br>60Lv 스킬 범위 15% 증가";
		lv105CustomOptionToNumberMap["60Lv 스킬 공격력 5% 증가<br>60Lv 스킬 범위 15% 증가"] = 185;
		
		lv105CustomOptionMap[186] = "60Lv 스킬 쿨타임 20% 감소";
		lv105CustomOptionToNumberMap["60Lv 스킬 쿨타임 20% 감소"] = 186;
		
		lv105CustomOptionMap[187] = "스킬 MP 소모량 옵션 수치 총합의 1%만큼 최종 데미지 증가 (최대 10%)";
		lv105CustomOptionToNumberMap["스킬 MP 소모량 옵션 수치 총합의 1%만큼 최종 데미지 증가 (최대 10%)"] = 187;
		
		lv105CustomOptionMap[188] = "70Lv 스킬 공격력 20% 증가";
		lv105CustomOptionToNumberMap["70Lv 스킬 공격력 20% 증가"] = 188;
		
		lv105CustomOptionMap[189] = "70Lv 스킬 공격력 5% 증가<br>70Lv 스킬 범위 15% 증가";
		lv105CustomOptionToNumberMap["70Lv 스킬 공격력 5% 증가<br>70Lv 스킬 범위 15% 증가"] = 189;
		
		lv105CustomOptionMap[190] = "70Lv 스킬 쿨타임 20% 감소";
		lv105CustomOptionToNumberMap["70Lv 스킬 쿨타임 20% 감소"] = 190;
		
		lv105CustomOptionMap[191] = "스킬 MP 소모량 +100%<br>스킬로 소모한 MP의 10% 회복";
		lv105CustomOptionToNumberMap["스킬 MP 소모량 +100%<br>스킬로 소모한 MP의 10% 회복"] = 191;
		
		lv105CustomOptionMap[192] = "75Lv 스킬 공격력 20% 증가";
		lv105CustomOptionToNumberMap["75Lv 스킬 공격력 20% 증가"] = 192;
		
		lv105CustomOptionMap[193] = "75Lv 스킬 공격력 5% 증가<br>75Lv 스킬 범위 15% 증가";
		lv105CustomOptionToNumberMap["75Lv 스킬 공격력 5% 증가<br>75Lv 스킬 범위 15% 증가"] = 193;
		
		lv105CustomOptionMap[194] = "75Lv 스킬 쿨타임 20% 감소";
		lv105CustomOptionToNumberMap["75Lv 스킬 쿨타임 20% 감소"] = 194;
		
		lv105CustomOptionMap[195] = "75, 80, 95Lv 스킬 공격력 5% 증가<br>75Lv 액티브 스킬 Lv +1";
		lv105CustomOptionToNumberMap["75, 80, 95Lv 스킬 공격력 5% 증가<br>75Lv 액티브 스킬 Lv +1"] = 195;
		
		lv105CustomOptionMap[196] = "80Lv 스킬 공격력 20% 증가";
		lv105CustomOptionToNumberMap["80Lv 스킬 공격력 20% 증가"] = 196;
		
		lv105CustomOptionMap[197] = "80Lv 스킬 공격력 5% 증가<br>80Lv 스킬 범위 15% 증가";
		lv105CustomOptionToNumberMap["80Lv 스킬 공격력 5% 증가<br>80Lv 스킬 범위 15% 증가"] = 197;
		
		lv105CustomOptionMap[198] = "80Lv 스킬 쿨타임 20% 감소";
		lv105CustomOptionToNumberMap["80Lv 스킬 쿨타임 20% 감소"] = 198;
		
		lv105CustomOptionMap[199] = "75, 80, 95Lv 스킬 공격력 5% 증가<br>80Lv 액티브 스킬 Lv +1";
		lv105CustomOptionToNumberMap["75, 80, 95Lv 스킬 공격력 5% 증가<br>80Lv 액티브 스킬 Lv +1"] = 199;
		
		lv105CustomOptionMap[200] = "95Lv 스킬 공격력 20% 증가";
		lv105CustomOptionToNumberMap["95Lv 스킬 공격력 20% 증가"] = 200;
		
		lv105CustomOptionMap[201] = "95Lv 스킬 쿨타임 20% 감소";
		lv105CustomOptionToNumberMap["95Lv 스킬 쿨타임 20% 감소"] = 201;
		
		lv105CustomOptionMap[202] = "75, 80, 95Lv 스킬 공격력 5% 증가<br>95Lv 액티브 스킬 Lv +1";
		lv105CustomOptionToNumberMap["75, 80, 95Lv 스킬 공격력 5% 증가<br>95Lv 액티브 스킬 Lv +1"] = 202;
		
		lv105CustomOptionMap[203] = "공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>구속 지속 시간 +1초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>구속 지속 시간 +1초"] = 203;
		
		lv105CustomOptionMap[204] = "공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>기절 지속 시간 +1초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>기절 지속 시간 +1초"] = 204;
		
		lv105CustomOptionMap[205] = "공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>둔화 지속 시간 +1초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>둔화 지속 시간 +1초"] = 205;
		
		lv105CustomOptionMap[206] = "공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>빙결 지속 시간 +1초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>빙결 지속 시간 +1초"] = 206;
		
		lv105CustomOptionMap[207] = "공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>석화 지속 시간 +1초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>석화 지속 시간 +1초"] = 207;
		
		lv105CustomOptionMap[208] = "공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>수면 지속 시간 +1초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>수면 지속 시간 +1초"] = 208;
		
		lv105CustomOptionMap[209] = "공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>암흑 지속 시간 +1초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>암흑 지속 시간 +1초"] = 209;
		
		lv105CustomOptionMap[210] = "공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>저주 지속 시간 +1초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>저주 지속 시간 +1초"] = 210;
		
		lv105CustomOptionMap[211] = "공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>혼란 지속 시간 +1초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>무력화 게이지 감소량 +10%<br>혼란 지속 시간 +1초"] = 211;
		
		lv105CustomOptionMap[212] = "공격력 증가 + N%<br>무력화 게이지 감소량 +20%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>무력화 게이지 감소량 +20%"] = 212;
		
		lv105CustomOptionMap[213] = "무색 큐브 조각을 소모하지 않는 스킬 공격력 15% 증가";
		lv105CustomOptionToNumberMap["무색 큐브 조각을 소모하지 않는 스킬 공격력 15% 증가"] = 213;
		
		lv105CustomOptionMap[214] = "물리/마법 방어력 +7,000";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +7,000"] = 214;
		
		lv105CustomOptionMap[215] = "물리/마법 방어력 +5%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +5%"] = 215;
		
		lv105CustomOptionMap[216] = "물리/마법 크리티컬 히트 +5%";
		lv105CustomOptionToNumberMap["물리/마법 크리티컬 히트 +5%"] = 216;
		
		lv105CustomOptionMap[217] = "물리/마법 크리티컬 히트 +7%<br>모든 상태 이상 내성 -10%";
		lv105CustomOptionToNumberMap["물리/마법 크리티컬 히트 +7%<br>모든 상태 이상 내성 -10%"] = 217;
		
		lv105CustomOptionMap[218] = "백어택 공격 시 최종 데미지 8% 증가";
		lv105CustomOptionToNumberMap["백어택 공격 시 최종 데미지 8% 증가"] = 218;
		
		lv105CustomOptionMap[219] = "백어택 피격 시 물리/마법 피해 감소 +20%";
		lv105CustomOptionToNumberMap["백어택 피격 시 물리/마법 피해 감소 +20%"] = 219;
		
		lv105CustomOptionMap[220] = "감전 피해 +13%";
		lv105CustomOptionToNumberMap["감전 피해 +13%"] = 220;
		
		lv105CustomOptionMap[221] = "공격 속도 +20%<br>캐스팅 속도 +30%";
		lv105CustomOptionToNumberMap["공격 속도 +20%<br>캐스팅 속도 +30%"] = 221;
		
		lv105CustomOptionMap[222] = "명속성 강화 +23";
		lv105CustomOptionToNumberMap["명속성 강화 +23"] = 222;
		
		lv105CustomOptionMap[223] = "수속성 강화 +23";
		lv105CustomOptionToNumberMap["수속성 강화 +23"] = 223;
		
		lv105CustomOptionMap[224] = "암속성 강화 +23";
		lv105CustomOptionToNumberMap["암속성 강화 +23"] = 224;
		
		lv105CustomOptionMap[225] = "중독 피해 +13%<br>중독 내성 +5%";
		lv105CustomOptionToNumberMap["중독 피해 +13%<br>중독 내성 +5%"] = 225;
		
		lv105CustomOptionMap[226] = "출혈 피해 +13%";
		lv105CustomOptionToNumberMap["출혈 피해 +13%"] = 226;
		
		lv105CustomOptionMap[227] = "공격력 증가 + N%<br>물리/마법 방어력 +5,000";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>물리/마법 방어력 +5,000"] = 227;
		
		lv105CustomOptionMap[228] = "화상 피해 +13%";
		lv105CustomOptionToNumberMap["화상 피해 +13%"] = 228;
		
		lv105CustomOptionMap[229] = "화속성 강화 +23";
		lv105CustomOptionToNumberMap["화속성 강화 +23"] = 229;
		
		lv105CustomOptionMap[230] = "모든 속성 강화 +25";
		lv105CustomOptionToNumberMap["모든 속성 강화 +25"] = 230;
		
		lv105CustomOptionMap[231] = "최종 데미지 6% 증가<br>무력화 게이지 감소량 +10%";
		lv105CustomOptionToNumberMap["최종 데미지 6% 증가<br>무력화 게이지 감소량 +10%"] = 231;
		
		lv105CustomOptionMap[232] = "물리/마법 피해 감소 +15%";
		lv105CustomOptionToNumberMap["물리/마법 피해 감소 +15%"] = 232;
		
		lv105CustomOptionMap[233] = "최종 데미지 2% 증가<br>비 카운터 피격 시 물리/마법 피해 감소 +10%";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>비 카운터 피격 시 물리/마법 피해 감소 +10%"] = 233;
		
		lv105CustomOptionMap[234] = "비카운터 피격 시 물리/마법 피해 감소 +20%";
		lv105CustomOptionToNumberMap["비카운터 피격 시 물리/마법 피해 감소 +20%"] = 234;
		
		lv105CustomOptionMap[235] = "가장 높은 상태 이상 내성 수치가 50% 이상일 때 스킬 쿨타임 회복 속도 +30% (각성기 제외)<br>빙결 내성 +40%";
		lv105CustomOptionToNumberMap["가장 높은 상태 이상 내성 수치가 50% 이상일 때 스킬 쿨타임 회복 속도 +30% (각성기 제외)<br>빙결 내성 +40%"] = 235;
		
		lv105CustomOptionMap[236] = "물리/마법 방어력 +4,000<br>빙결 내성 +20%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +4,000<br>빙결 내성 +20%"] = 236;
		
		lv105CustomOptionMap[237] = "[아이스 스트라이크]<br>빙결 상태 적 공격 시 30초 동안 최종 데미지 15% 증가";
		lv105CustomOptionToNumberMap["[아이스 스트라이크]<br>빙결 상태 적 공격 시 30초 동안 최종 데미지 15% 증가"] = 237;
		
		lv105CustomOptionMap[238] = "물리/마법 피해 감소 +10%<br>석화 내성 +40%";
		lv105CustomOptionToNumberMap["물리/마법 피해 감소 +10%<br>석화 내성 +40%"] = 238;
		
		lv105CustomOptionMap[239] = "물리/마법 방어력 +4,000<br>석화 내성 +20%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +4,000<br>석화 내성 +20%"] = 239;
		
		lv105CustomOptionMap[240] = "[스톤 스트라이크]<br>석화 상태 적 공격 시 30초 동안 최종 데미지15% 증가";
		lv105CustomOptionToNumberMap["[스톤 스트라이크]<br>석화 상태 적 공격 시 30초 동안 최종 데미지15% 증가"] = 240;
		
		lv105CustomOptionMap[241] = "최종 데미지 5% 증가<br>모든 속도 +20%";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>모든 속도 +20%"] = 241;
		
		lv105CustomOptionMap[242] = "최종 데미지 7% 증가<br>소모품 효과 유지 시간 +20%<br>모든 상태 이상 내성 +2.5%<br>수면 내성 -2.5%";
		lv105CustomOptionToNumberMap["최종 데미지 7% 증가<br>소모품 효과 유지 시간 +20%<br>모든 상태 이상 내성 +2.5%<br>수면 내성 -2.5%"] = 242;
		
		lv105CustomOptionMap[243] = "수면 내성 +40%";
		lv105CustomOptionToNumberMap["수면 내성 +40%"] = 243;
		
		lv105CustomOptionMap[244] = "물리/마법 방어력 +4,000<br>수면 내성 +20%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +4,000<br>수면 내성 +20%"] = 244;
		
		lv105CustomOptionMap[245] = "수면 상태 적 공격 시 30초 동안 최종 데미지 15% 증가";
		lv105CustomOptionToNumberMap["수면 상태 적 공격 시 30초 동안 최종 데미지 15% 증가"] = 245;
		
		lv105CustomOptionMap[246] = "수속성 강화 +35<br>모든 상태 이상 내성 -10%";
		lv105CustomOptionToNumberMap["수속성 강화 +35<br>모든 상태 이상 내성 -10%"] = 246;
		
		lv105CustomOptionMap[247] = "수속성 강화 +20";
		lv105CustomOptionToNumberMap["수속성 강화 +20"] = 247;
		
		lv105CustomOptionMap[248] = "수속성 저항 +10";
		lv105CustomOptionToNumberMap["수속성 저항 +10"] = 248;
		
		lv105CustomOptionMap[249] = "무색 큐브 조각을 10개 이상 소모하는 스킬 시전 시 30초 동안 스킬 쿨타임 회복 속도 +30% (각성기 제외)";
		lv105CustomOptionToNumberMap["무색 큐브 조각을 10개 이상 소모하는 스킬 시전 시 30초 동안 스킬 쿨타임 회복 속도 +30% (각성기 제외)"] = 249;
		
		lv105CustomOptionMap[250] = "무색 큐브 조각을 10개 이상 소모하는 스킬 시전 시 30초 동안 최종 데미지 5% 증가";
		lv105CustomOptionToNumberMap["무색 큐브 조각을 10개 이상 소모하는 스킬 시전 시 30초 동안 최종 데미지 5% 증가"] = 250;
		
		lv105CustomOptionMap[251] = "공격 속도 +30%<br>캐스팅 속도 +45%";
		lv105CustomOptionToNumberMap["공격 속도 +30%<br>캐스팅 속도 +45%"] = 251;
		
		lv105CustomOptionMap[252] = "스킬 MP 소모량 -7%";
		lv105CustomOptionToNumberMap["스킬 MP 소모량 -7%"] = 252;
		
		lv105CustomOptionMap[253] = "스킬 퀵슬롯의 빈 슬롯 1개마다 최종 데미지 2% 증가 (최대 6중첩)";
		lv105CustomOptionToNumberMap["스킬 퀵슬롯의 빈 슬롯 1개마다 최종 데미지 2% 증가 (최대 6중첩)"] = 253;
		
		lv105CustomOptionMap[254] = "스킬 커맨드 사용 효과 +100% (각성기 제외)";
		lv105CustomOptionToNumberMap["스킬 커맨드 사용 효과 +100% (각성기 제외)"] = 254;
		
		lv105CustomOptionMap[255] = "최종 데미지 2% 증가<br>식물 타입의 적 공격 시 최종 데미지 5% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>식물 타입의 적 공격 시 최종 데미지 5% 증가"] = 255;
		
		lv105CustomOptionMap[256] = "최종 데미지 2% 증가<br>악마 타입의 적 공격 시 최종 데미지 5% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>악마 타입의 적 공격 시 최종 데미지 5% 증가"] = 256;
		
		lv105CustomOptionMap[257] = "암속성 강화 +35<br>모든 상태 이상 내성 -10%";
		lv105CustomOptionToNumberMap["암속성 강화 +35<br>모든 상태 이상 내성 -10%"] = 257;
		
		lv105CustomOptionMap[258] = "암속성 강화 +20";
		lv105CustomOptionToNumberMap["암속성 강화 +20"] = 258;
		
		lv105CustomOptionMap[259] = "암속성 저항 +10";
		lv105CustomOptionToNumberMap["암속성 저항 +10"] = 259;
		
		lv105CustomOptionMap[260] = "최종 데미지 5% 증가<br>- '오랜 시간 품어온 소원' 장비 장착 필요<br>모든 속도 +15%<br>암흑 내성 +40%";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>- '오랜 시간 품어온 소원' 장비 장착 필요<br>모든 속도 +15%<br>암흑 내성 +40%"] = 260;
		
		lv105CustomOptionMap[261] = "물리/마법 방어력 +4,000<br>암흑 내성 +20%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +4,000<br>암흑 내성 +20%"] = 261;
		
		lv105CustomOptionMap[262] = "암흑 상태 적 공격 시 30초 동안 최종 데미지 10% 증가";
		lv105CustomOptionToNumberMap["암흑 상태 적 공격 시 30초 동안 최종 데미지 10% 증가"] = 262;
		
		lv105CustomOptionMap[263] = "최종 데미지 2% 증가<br>야수 타입의 적 공격 시 최종 데미지 5% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>야수 타입의 적 공격 시 최종 데미지 5% 증가"] = 263;
		
		lv105CustomOptionMap[264] = "최종 데미지 2% 증가<br>언데드 타입의 적 공격 시 최종 데미지 5% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>언데드 타입의 적 공격 시 최종 데미지 5% 증가"] = 264;
		
		lv105CustomOptionMap[265] = "최종 데미지 2% 증가<br>용족 타입의 적 공격 시 최종 데미지 5% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>용족 타입의 적 공격 시 최종 데미지 5% 증가"] = 265;
		
		lv105CustomOptionMap[266] = "이동 속도 +8%";
		lv105CustomOptionToNumberMap["이동 속도 +8%"] = 266;
		
		lv105CustomOptionMap[267] = "이동 속도 +30%";
		lv105CustomOptionToNumberMap["이동 속도 +30%"] = 267;
		
		lv105CustomOptionMap[268] = "최종 데미지 2% 증가<br>인간 타입의 적 공격 시 최종 데미지 5% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>인간 타입의 적 공격 시 최종 데미지 5% 증가"] = 268;
		
		lv105CustomOptionMap[269] = "인벤토리 무게 한도 +5kg<br>HP MAX +300<br>MP MAX +473";
		lv105CustomOptionToNumberMap["인벤토리 무게 한도 +5kg<br>HP MAX +300<br>MP MAX +473"] = 269;
		
		lv105CustomOptionMap[270] = "상태 이상 지속 시간 +20%<br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 중 1개 부여 (쿨타임 2초)";
		lv105CustomOptionToNumberMap["상태 이상 지속 시간 +20%<br>공격 시 적에게 10초 동안 출혈/중독/화상/감전 상태 이상 중 1개 부여 (쿨타임 2초)"] = 270;
		
		lv105CustomOptionMap[271] = "최종 데미지 4% 증가<br>35Lv 스킬 쿨타임 회복 속도 +30%<br>자신에게 해제되지 않는 중독 상태 이상 부여";
		lv105CustomOptionToNumberMap["최종 데미지 4% 증가<br>35Lv 스킬 쿨타임 회복 속도 +30%<br>자신에게 해제되지 않는 중독 상태 이상 부여"] = 271;
		
		lv105CustomOptionMap[272] = "최종 데미지 2% 증가<br>40Lv 스킬 쿨타임 회복 속도 +30%<br>자신에게 해제되지 않는 화상 상태 이상 부여";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>40Lv 스킬 쿨타임 회복 속도 +30%<br>자신에게 해제되지 않는 화상 상태 이상 부여"] = 272;
		
		lv105CustomOptionMap[273] = "최종 데미지 4% 증가<br>45Lv 스킬 쿨타임 회복 속도 +30%<br>자신에게 해제되지 않는 중독 상태 이상 부여";
		lv105CustomOptionToNumberMap["최종 데미지 4% 증가<br>45Lv 스킬 쿨타임 회복 속도 +30%<br>자신에게 해제되지 않는 중독 상태 이상 부여"] = 273;
		
		lv105CustomOptionMap[274] = "최종 데미지 4% 증가<br>60Lv 스킬 쿨타임 회복 속도 +30%<br>자신에게 해제되지 않는 중독 상태 이상 부여";
		lv105CustomOptionToNumberMap["최종 데미지 4% 증가<br>60Lv 스킬 쿨타임 회복 속도 +30%<br>자신에게 해제되지 않는 중독 상태 이상 부여"] = 274;
		
		lv105CustomOptionMap[275] = "최종 데미지 2% 증가<br>70Lv 스킬 쿨타임 회복 속도 +30%<br>자신에게 해제되지 않는 화상 상태 이상 부여";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>70Lv 스킬 쿨타임 회복 속도 +30%<br>자신에게 해제되지 않는 화상 상태 이상 부여"] = 275;
		
		lv105CustomOptionMap[276] = "최종 데미지 2% 증가<br>75Lv 스킬 쿨타임 회복 속도 +30%<br>자신에게 해제되지 않는 화상 상태 이상 부여";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>75Lv 스킬 쿨타임 회복 속도 +30%<br>자신에게 해제되지 않는 화상 상태 이상 부여"] = 276;
		
		lv105CustomOptionMap[277] = "최종 데미지 2% 증가<br>80Lv 스킬 쿨타임 회복 속도 +30%";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>80Lv 스킬 쿨타임 회복 속도 +30%"] = 277;
		
		lv105CustomOptionMap[278] = "최종 데미지 2% 증가<br>95Lv 스킬 쿨타임 회복 속도 +30%";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>95Lv 스킬 쿨타임 회복 속도 +30%"] = 278;
		
		lv105CustomOptionMap[279] = "명속성 강화 +35";
		lv105CustomOptionToNumberMap["명속성 강화 +35"] = 279;
		
		lv105CustomOptionMap[280] = "수속성 강화 +35";
		lv105CustomOptionToNumberMap["수속성 강화 +35"] = 280;
		
		lv105CustomOptionMap[281] = "암속성 강화 +35";
		lv105CustomOptionToNumberMap["암속성 강화 +35"] = 281;
		
		lv105CustomOptionMap[282] = "화속성 강화 +35";
		lv105CustomOptionToNumberMap["화속성 강화 +35"] = 282;
		
		lv105CustomOptionMap[283] = "가장 높은 상태 이상 내성 수치가 50% 이상일 때 아래의 효과 적용<br>- 공격 속도 +20%<br>- 캐스팅 속도 +30%";
		lv105CustomOptionToNumberMap["가장 높은 상태 이상 내성 수치가 50% 이상일 때 아래의 효과 적용<br>- 공격 속도 +20%<br>- 캐스팅 속도 +30%"] = 283;
		
		lv105CustomOptionMap[284] = "모든 상태 이상 내성 +30%";
		lv105CustomOptionToNumberMap["모든 상태 이상 내성 +30%"] = 284;
		
		lv105CustomOptionMap[285] = "이동 속도 +40%";
		lv105CustomOptionToNumberMap["이동 속도 +40%"] = 285;
		
		lv105CustomOptionMap[286] = "모든 속성 저항 수치의 합이 250 이상일 때 감전 피해 +15%";
		lv105CustomOptionToNumberMap["모든 속성 저항 수치의 합이 250 이상일 때 감전 피해 +15%"] = 286;
		
		lv105CustomOptionMap[287] = "모든 속성 저항 수치의 합이 250 이상일 때 중독 피해 +15%";
		lv105CustomOptionToNumberMap["모든 속성 저항 수치의 합이 250 이상일 때 중독 피해 +15%"] = 287;
		
		lv105CustomOptionMap[288] = "모든 속성 저항 수치의 합이 250 이상일 때 출혈 피해 +15%";
		lv105CustomOptionToNumberMap["모든 속성 저항 수치의 합이 250 이상일 때 출혈 피해 +15%"] = 288;
		
		lv105CustomOptionMap[289] = "모든 속성 저항 수치의 합이 250 이상일 때 화상 피해 +15%";
		lv105CustomOptionToNumberMap["모든 속성 저항 수치의 합이 250 이상일 때 화상 피해 +15%"] = 289;
		
		lv105CustomOptionMap[290] = "HP MAX +2,000<br>MP MAX +2,000";
		lv105CustomOptionToNumberMap["HP MAX +2,000<br>MP MAX +2,000"] = 290;
		
		lv105CustomOptionMap[291] = "공격력 증가 + N%<br>HP MAX +600";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>HP MAX +600"] = 291;
		
		lv105CustomOptionMap[292] = "물리/마법 크리티컬 히트 +15%";
		lv105CustomOptionToNumberMap["물리/마법 크리티컬 히트 +15%"] = 292;
		
		lv105CustomOptionMap[293] = "공격력 증가 + N%<br>감전 지속 시간 -10%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>감전 지속 시간 -10%"] = 293;
		
		lv105CustomOptionMap[294] = "공격력 증가 + N%<br>구속 지속 시간 +2초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>구속 지속 시간 +2초"] = 294;
		
		lv105CustomOptionMap[295] = "공격력 증가 + N%<br>기절 지속 시간 +2초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>기절 지속 시간 +2초"] = 295;
		
		lv105CustomOptionMap[296] = "공격력 증가 + N%<br>둔화 지속 시간 +2초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>둔화 지속 시간 +2초"] = 296;
		
		lv105CustomOptionMap[297] = "공격력 증가 + N%<br>빙결 지속 시간 +2초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>빙결 지속 시간 +2초"] = 297;
		
		lv105CustomOptionMap[298] = "공격력 증가 + N%<br>석화 지속 시간 +2초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>석화 지속 시간 +2초"] = 298;
		
		lv105CustomOptionMap[299] = "공격력 증가 + N%<br>수면 지속 시간 +2초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>수면 지속 시간 +2초"] = 299;
		
		lv105CustomOptionMap[300] = "공격력 증가 + N%<br>암흑 지속 시간 +2초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>암흑 지속 시간 +2초"] = 300;
		
		lv105CustomOptionMap[301] = "공격력 증가 + N%<br>저주 지속 시간 +2초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>저주 지속 시간 +2초"] = 301;
		
		lv105CustomOptionMap[302] = "공격력 증가 + N%<br>중독 지속 시간 -10%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>중독 지속 시간 -10%"] = 302;
		
		lv105CustomOptionMap[303] = "공격력 증가 + N%<br>출혈 지속 시간 -10%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>출혈 지속 시간 -10%"] = 303;
		
		lv105CustomOptionMap[304] = "공격력 증가 + N%<br>혼란 지속 시간 +2초";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>혼란 지속 시간 +2초"] = 304;
		
		lv105CustomOptionMap[305] = "공격력 증가 + N%<br>화상 지속 시간 -10%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>화상 지속 시간 -10%"] = 305;
		
		lv105CustomOptionMap[306] = "장비 보호막 MAX 20% 증가";
		lv105CustomOptionToNumberMap["장비 보호막 MAX 20% 증가"] = 306;
		
		lv105CustomOptionMap[307] = "[영멸의 광폭화]<br>죽음에 잠식되어 1초마다 HP가 10% 감소하는 대신 광폭화 강화<br>- 최종 데미지 2% 증가<br>- 광폭화 최대 유지 시간 +3초<br>- '죽음에 잠식된 갑주' 장착 필요<br>저주 내성 +40%";
		lv105CustomOptionToNumberMap["[영멸의 광폭화]<br>죽음에 잠식되어 1초마다 HP가 10% 감소하는 대신 광폭화 강화<br>- 최종 데미지 2% 증가<br>- 광폭화 최대 유지 시간 +3초<br>- '죽음에 잠식된 갑주' 장착 필요<br>저주 내성 +40%"] = 307;
		
		lv105CustomOptionMap[308] = "물리/마법 방어력 +4,000<br>저주 내성 +20%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +4,000<br>저주 내성 +20%"] = 308;
		
		lv105CustomOptionMap[309] = "저주 상태 적 공격 시 30초 동안 최종 데미지 10% 증가";
		lv105CustomOptionToNumberMap["저주 상태 적 공격 시 30초 동안 최종 데미지 10% 증가"] = 309;
		
		lv105CustomOptionMap[310] = "최종 데미지 5% 증가<br>물리/마법 방어력 +4,000";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>물리/마법 방어력 +4,000"] = 310;
		
		lv105CustomOptionMap[311] = "최종 데미지 5% 증가<br>이동 속도 +5%";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>이동 속도 +5%"] = 311;
		
		lv105CustomOptionMap[312] = "공격 속도 +30%<br>캐스팅 속도 +45%<br>이동 속도 +10%";
		lv105CustomOptionToNumberMap["공격 속도 +30%<br>캐스팅 속도 +45%<br>이동 속도 +10%"] = 312;
		
		lv105CustomOptionMap[313] = "명속성 강화 +40";
		lv105CustomOptionToNumberMap["명속성 강화 +40"] = 313;
		
		lv105CustomOptionMap[314] = "물리/마법 방어력 +14,000";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +14,000"] = 314;
		
		lv105CustomOptionMap[315] = "무색 큐브 조각을 소모하는 스킬 공격력 10% 증가<br>물리/마법 크리티컬 히트 +20%";
		lv105CustomOptionToNumberMap["무색 큐브 조각을 소모하는 스킬 공격력 10% 증가<br>물리/마법 크리티컬 히트 +20%"] = 315;
		
		lv105CustomOptionMap[316] = "수속성 강화 +40";
		lv105CustomOptionToNumberMap["수속성 강화 +40"] = 316;
		
		lv105CustomOptionMap[317] = "암속성 강화 +40";
		lv105CustomOptionToNumberMap["암속성 강화 +40"] = 317;
		
		lv105CustomOptionMap[318] = "3콤보마다 이동 속도 +3% (최대 10중첩)";
		lv105CustomOptionToNumberMap["3콤보마다 이동 속도 +3% (최대 10중첩)"] = 318;
		
		lv105CustomOptionMap[319] = "공격력 증가 + 415.0%";
		lv105CustomOptionToNumberMap["공격력 증가 + 415.0%"] = 319;
		
		lv105CustomOptionMap[320] = "화속성 강화 +40";
		lv105CustomOptionToNumberMap["화속성 강화 +40"] = 320;
		
		lv105CustomOptionMap[321] = "회피율 +30%";
		lv105CustomOptionToNumberMap["회피율 +30%"] = 321;
		
		lv105CustomOptionMap[322] = "45, 60, 70Lv 스킬 공격력 5% 증가<br>70Lv 액티브 스킬 Lv +1";
		lv105CustomOptionToNumberMap["45, 60, 70Lv 스킬 공격력 5% 증가<br>70Lv 액티브 스킬 Lv +1"] = 322;
		
		lv105CustomOptionMap[323] = "45, 60, 70Lv 스킬 공격력 5% 증가<br>60Lv 액티브 스킬 Lv +1";
		lv105CustomOptionToNumberMap["45, 60, 70Lv 스킬 공격력 5% 증가<br>60Lv 액티브 스킬 Lv +1"] = 323;
		
		lv105CustomOptionMap[324] = "스킬 범위 옵션 수치의 총합이 30% 이상일 때 최종 데미지 9% 증가";
		lv105CustomOptionToNumberMap["스킬 범위 옵션 수치의 총합이 30% 이상일 때 최종 데미지 9% 증가"] = 324;
		
		lv105CustomOptionMap[325] = "모든 TP 스킬Lv +1<br>스킬 범위 10% 증가";
		lv105CustomOptionToNumberMap["모든 TP 스킬Lv +1<br>스킬 범위 10% 증가"] = 325;
		
		lv105CustomOptionMap[326] = "35Lv 스킬 쿨타임 회복 속도 +15%";
		lv105CustomOptionToNumberMap["35Lv 스킬 쿨타임 회복 속도 +15%"] = 326;
		
		lv105CustomOptionMap[327] = "40Lv 스킬 쿨타임 회복 속도 +15%";
		lv105CustomOptionToNumberMap["40Lv 스킬 쿨타임 회복 속도 +15%"] = 327;
		
		lv105CustomOptionMap[328] = "45Lv 스킬 쿨타임 회복 속도 +15%";
		lv105CustomOptionToNumberMap["45Lv 스킬 쿨타임 회복 속도 +15%"] = 328;
		
		lv105CustomOptionMap[329] = "최종 데미지 5% 증가<br>60Lv 스킬 쿨타임 회복 속도 +15%";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>60Lv 스킬 쿨타임 회복 속도 +15%"] = 329;
		
		lv105CustomOptionMap[330] = "70Lv 스킬 쿨타임 회복 속도 +15%";
		lv105CustomOptionToNumberMap["70Lv 스킬 쿨타임 회복 속도 +15%"] = 330;
		
		lv105CustomOptionMap[331] = "최종 데미지 5% 증가<br>75Lv 스킬 쿨타임 회복 속도 +15%";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>75Lv 스킬 쿨타임 회복 속도 +15%"] = 331;
		
		lv105CustomOptionMap[332] = "80Lv 스킬 쿨타임 회복 속도 +15%";
		lv105CustomOptionToNumberMap["80Lv 스킬 쿨타임 회복 속도 +15%"] = 332;
		
		lv105CustomOptionMap[333] = "최종 데미지 5% 증가<br>95Lv 스킬 쿨타임 회복 속도 +15%";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>95Lv 스킬 쿨타임 회복 속도 +15%"] = 333;
		
		lv105CustomOptionMap[334] = "공격력 증가 + N%<br>물리/마법 방어력 +5,000";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>물리/마법 방어력 +5,000"] = 334;
		
		lv105CustomOptionMap[335] = "적중률 +10%";
		lv105CustomOptionToNumberMap["적중률 +10%"] = 335;
		
		lv105CustomOptionMap[336] = "적중률 +20%";
		lv105CustomOptionToNumberMap["적중률 +20%"] = 336;
		
		lv105CustomOptionMap[337] = "점프 시 30초 동안 슈퍼 아머 부여 (쿨타임 5초)";
		lv105CustomOptionToNumberMap["점프 시 30초 동안 슈퍼 아머 부여 (쿨타임 5초)"] = 337;
		
		lv105CustomOptionMap[338] = "이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20";
		lv105CustomOptionToNumberMap["이동 속도 +4%<br>점프 상태 동안 이동 속도 +30%<br>점프력 +20"] = 338;
		
		lv105CustomOptionMap[339] = "최종 데미지 2% 증가<br>정령 타입의 적 공격 시 최종 데미지 5% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>정령 타입의 적 공격 시 최종 데미지 5% 증가"] = 339;
		
		lv105CustomOptionMap[340] = "정면 공격 시 최종 데미지 8% 증가";
		lv105CustomOptionToNumberMap["정면 공격 시 최종 데미지 8% 증가"] = 340;
		
		lv105CustomOptionMap[341] = "정면 피격 시 물리/마법 피해 감소 +20%";
		lv105CustomOptionToNumberMap["정면 피격 시 물리/마법 피해 감소 +20%"] = 341;
		
		lv105CustomOptionMap[342] = "모든 속도 +10%<br>중독 내성 +40%";
		lv105CustomOptionToNumberMap["모든 속도 +10%<br>중독 내성 +40%"] = 342;
		
		lv105CustomOptionMap[343] = "물리/마법 방어력 +4,000<br>중독 내성 +20%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +4,000<br>중독 내성 +20%"] = 343;
		
		lv105CustomOptionMap[344] = "중독 피해 +5%<br>중독 내성 +5%";
		lv105CustomOptionToNumberMap["중독 피해 +5%<br>중독 내성 +5%"] = 344;
		
		lv105CustomOptionMap[345] = "중독 피해 +10%";
		lv105CustomOptionToNumberMap["중독 피해 +10%"] = 345;
		
		lv105CustomOptionMap[346] = "중독 피해 +30%<br>물리/마법 피해 감소 -20%<br>중독 내성 +20%";
		lv105CustomOptionToNumberMap["중독 피해 +30%<br>물리/마법 피해 감소 -20%<br>중독 내성 +20%"] = 346;
		
		lv105CustomOptionMap[347] = "최종 데미지 5% 증가<br>중독 내성 +1%";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>중독 내성 +1%"] = 347;
		
		lv105CustomOptionMap[348] = "최종 데미지 2% 증가<br>천사 타입의 적 공격 시 최종 데미지 5% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>천사 타입의 적 공격 시 최종 데미지 5% 증가"] = 348;
		
		lv105CustomOptionMap[349] = "최종 데미지 6% 증가<br>피격으로 HP가 10% 이상 감소 시 20초 동안 HP MAX 30% 수치의 보호막 부여 (쿨타임 15초)";
		lv105CustomOptionToNumberMap["최종 데미지 6% 증가<br>피격으로 HP가 10% 이상 감소 시 20초 동안 HP MAX 30% 수치의 보호막 부여 (쿨타임 15초)"] = 349;
		
		lv105CustomOptionMap[350] = "싱글 플레이 시 최종 데미지 10% 증가<br>출혈 내성 +40%";
		lv105CustomOptionToNumberMap["싱글 플레이 시 최종 데미지 10% 증가<br>출혈 내성 +40%"] = 350;
		
		lv105CustomOptionMap[351] = "물리/마법 방어력 +4,000<br>출혈 내성 +20%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +4,000<br>출혈 내성 +20%"] = 351;
		
		lv105CustomOptionMap[352] = "출혈 피해 +5%<br>출혈 내성 +5%";
		lv105CustomOptionToNumberMap["출혈 피해 +5%<br>출혈 내성 +5%"] = 352;
		
		lv105CustomOptionMap[353] = "출혈 피해 +10%";
		lv105CustomOptionToNumberMap["출혈 피해 +10%"] = 353;
		
		lv105CustomOptionMap[354] = "출혈 피해 +30%<br>물리/마법 피해 감소 -20%<br>출혈 내성 +20%";
		lv105CustomOptionToNumberMap["출혈 피해 +30%<br>물리/마법 피해 감소 -20%<br>출혈 내성 +20%"] = 354;
		
		lv105CustomOptionMap[355] = "최종 데미지 5% 증가<br>출혈 내성 +1%";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>출혈 내성 +1%"] = 355;
		
		lv105CustomOptionMap[356] = "카운터 공격 시 쿨타임 적용 중인 1~30Lv 스킬 중 1개를 균등한 확률로 쿨타임 초기화 (쿨타임 5초)<br>- 동일 스킬은 연속으로 적용되지 않습니다.";
		lv105CustomOptionToNumberMap["카운터 공격 시 쿨타임 적용 중인 1~30Lv 스킬 중 1개를 균등한 확률로 쿨타임 초기화 (쿨타임 5초)<br>- 동일 스킬은 연속으로 적용되지 않습니다."] = 356;
		
		lv105CustomOptionMap[357] = "최종 데미지 8% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 8% 증가"] = 357;
		
		lv105CustomOptionMap[358] = "카운터 피격 시 물리/마법 피해 감소 +20%";
		lv105CustomOptionToNumberMap["카운터 피격 시 물리/마법 피해 감소 +20%"] = 358;
		
		lv105CustomOptionMap[359] = "캐스팅 속도 +45%";
		lv105CustomOptionToNumberMap["캐스팅 속도 +45%"] = 359;
		
		lv105CustomOptionMap[360] = "커맨드로 스킬 시전 시 해당 스킬 공격력 12% 증가 (각성기 제외)";
		lv105CustomOptionToNumberMap["커맨드로 스킬 시전 시 해당 스킬 공격력 12% 증가 (각성기 제외)"] = 360;
		
		lv105CustomOptionMap[361] = "감전 피해 +15%<br>물리/마법 방어력 +2,000";
		lv105CustomOptionToNumberMap["감전 피해 +15%<br>물리/마법 방어력 +2,000"] = 361;
		
		lv105CustomOptionMap[362] = "명속성 강화 +25<br>HP MAX +200";
		lv105CustomOptionToNumberMap["명속성 강화 +25<br>HP MAX +200"] = 362;
		
		lv105CustomOptionMap[363] = "수속성 강화 +25<br>HP MAX +200";
		lv105CustomOptionToNumberMap["수속성 강화 +25<br>HP MAX +200"] = 363;
		
		lv105CustomOptionMap[364] = "암속성 강화 +25<br>HP MAX +200";
		lv105CustomOptionToNumberMap["암속성 강화 +25<br>HP MAX +200"] = 364;
		
		lv105CustomOptionMap[365] = "중독 피해 +15%<br>물리/마법 방어력 +2,000";
		lv105CustomOptionToNumberMap["중독 피해 +15%<br>물리/마법 방어력 +2,000"] = 365;
		
		lv105CustomOptionMap[366] = "출혈 피해 +15%<br>물리/마법 방어력 +2,000";
		lv105CustomOptionToNumberMap["출혈 피해 +15%<br>물리/마법 방어력 +2,000"] = 366;
		
		lv105CustomOptionMap[367] = "화상 피해 +15%<br>물리/마법 방어력 +2,000";
		lv105CustomOptionToNumberMap["화상 피해 +15%<br>물리/마법 방어력 +2,000"] = 367;
		
		lv105CustomOptionMap[368] = "화속성 강화 +25<br>HP MAX +200";
		lv105CustomOptionToNumberMap["화속성 강화 +25<br>HP MAX +200"] = 368;
		
		lv105CustomOptionMap[369] = "감전 피해 +20%";
		lv105CustomOptionToNumberMap["감전 피해 +20%"] = 369;
		
		lv105CustomOptionMap[370] = "명속성 강화 +25<br>명속성 저항 +2";
		lv105CustomOptionToNumberMap["명속성 강화 +25<br>명속성 저항 +2"] = 370;
		
		lv105CustomOptionMap[371] = "수속성 강화 +25<br>수속성 저항 +2";
		lv105CustomOptionToNumberMap["수속성 강화 +25<br>수속성 저항 +2"] = 371;
		
		lv105CustomOptionMap[372] = "암속성 강화 +25<br>암속성 저항 +2";
		lv105CustomOptionToNumberMap["암속성 강화 +25<br>암속성 저항 +2"] = 372;
		
		lv105CustomOptionMap[373] = "중독 피해 +20%";
		lv105CustomOptionToNumberMap["중독 피해 +20%"] = 373;
		
		lv105CustomOptionMap[374] = "출혈 피해 +20%";
		lv105CustomOptionToNumberMap["출혈 피해 +20%"] = 374;
		
		lv105CustomOptionMap[375] = "화상 피해 +20%";
		lv105CustomOptionToNumberMap["화상 피해 +20%"] = 375;
		
		lv105CustomOptionMap[376] = "화속성 강화 +25<br>화속성 저항 +2";
		lv105CustomOptionToNumberMap["화속성 강화 +25<br>화속성 저항 +2"] = 376;
		
		lv105CustomOptionMap[377] = "최종 데미지 2% 증가<br>파괴된 죽은 자의 성 던전 입장 시 최종 데미지 10% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>파괴된 죽은 자의 성 던전 입장 시 최종 데미지 10% 증가"] = 377;
		
		lv105CustomOptionMap[378] = "평타 마지막 공격 시 30초 동안 아래의 효과 적용 (최대 1중첩, 쿨타임 10초)<br>- 공격 속도 +20%<br>- 캐스팅 속도 +30%";
		lv105CustomOptionToNumberMap["평타 마지막 공격 시 30초 동안 아래의 효과 적용 (최대 1중첩, 쿨타임 10초)<br>- 공격 속도 +20%<br>- 캐스팅 속도 +30%"] = 378;
		
		lv105CustomOptionMap[379] = "공격력 증가 + 237.1%";
		lv105CustomOptionToNumberMap["공격력 증가 + 237.1%"] = 379;
		
		lv105CustomOptionMap[380] = "모든 속성 강화 +15<br>공격 시 적의 무력화 게이지량 -5 (쿨타임 3초 / 버퍼 제외)";
		lv105CustomOptionToNumberMap["모든 속성 강화 +15<br>공격 시 적의 무력화 게이지량 -5 (쿨타임 3초 / 버퍼 제외)"] = 380;
		
		lv105CustomOptionMap[381] = "공격력 증가 + N%<br>공격 시 적의 무력화 게이지량 -5 (쿨타임 3초 / 버퍼 제외)";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>공격 시 적의 무력화 게이지량 -5 (쿨타임 3초 / 버퍼 제외)"] = 381;
		
		lv105CustomOptionMap[382] = "피격 시 30초 동안 슈퍼 아머 부여 (쿨타임 5초)";
		lv105CustomOptionToNumberMap["피격 시 30초 동안 슈퍼 아머 부여 (쿨타임 5초)"] = 382;
		
		lv105CustomOptionMap[383] = "명속성 저항 +20";
		lv105CustomOptionToNumberMap["명속성 저항 +20"] = 383;
		
		lv105CustomOptionMap[384] = "수속성 저항 +20";
		lv105CustomOptionToNumberMap["수속성 저항 +20"] = 384;
		
		lv105CustomOptionMap[385] = "암속성 저항 +20";
		lv105CustomOptionToNumberMap["암속성 저항 +20"] = 385;
		
		lv105CustomOptionMap[386] = "화속성 저항 +20";
		lv105CustomOptionToNumberMap["화속성 저항 +20"] = 386;
		
		lv105CustomOptionMap[387] = "물리/마법 피해 감소 +25%";
		lv105CustomOptionToNumberMap["물리/마법 피해 감소 +25%"] = 387;
		
		lv105CustomOptionMap[388] = "물리/마법 피해 감소 +14%";
		lv105CustomOptionToNumberMap["물리/마법 피해 감소 +14%"] = 388;
		
		lv105CustomOptionMap[389] = "공격력 증가 + N%<br>물리/마법 방어력 +5,000";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>물리/마법 방어력 +5,000"] = 389;
		
		lv105CustomOptionMap[390] = "모든 속성 강화 +15<br>혼란 내성 +40%";
		lv105CustomOptionToNumberMap["모든 속성 강화 +15<br>혼란 내성 +40%"] = 390;
		
		lv105CustomOptionMap[391] = "물리/마법 방어력 +4,000<br>혼란 내성 +20%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +4,000<br>혼란 내성 +20%"] = 391;
		
		lv105CustomOptionMap[392] = "혼란 상태 적 공격 시 30초 동안 최종 데미지 10% 증가";
		lv105CustomOptionToNumberMap["혼란 상태 적 공격 시 30초 동안 최종 데미지 10% 증가"] = 392;
		
		lv105CustomOptionMap[393] = "최종 데미지 8% 증가<br>- '새겨지는 맹세' 장비 장착 필요<br>모든 속도 +10%<br>화상 내성 +40%";
		lv105CustomOptionToNumberMap["최종 데미지 8% 증가<br>- '새겨지는 맹세' 장비 장착 필요<br>모든 속도 +10%<br>화상 내성 +40%"] = 393;
		
		lv105CustomOptionMap[394] = "물리/마법 방어력 +4,000<br>화상 내성 +20%";
		lv105CustomOptionToNumberMap["물리/마법 방어력 +4,000<br>화상 내성 +20%"] = 394;
		
		lv105CustomOptionMap[395] = "화상 피해 +5%<br>화상 내성 +5%";
		lv105CustomOptionToNumberMap["화상 피해 +5%<br>화상 내성 +5%"] = 395;
		
		lv105CustomOptionMap[396] = "화상 피해 +10%";
		lv105CustomOptionToNumberMap["화상 피해 +10%"] = 396;
		
		lv105CustomOptionMap[397] = "화상 피해 +30%<br>물리/마법 피해 감소 -20%<br>화상 내성 +20%";
		lv105CustomOptionToNumberMap["화상 피해 +30%<br>물리/마법 피해 감소 -20%<br>화상 내성 +20%"] = 397;
		
		lv105CustomOptionMap[398] = "최종 데미지 5% 증가<br>화상 내성 +1%";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>화상 내성 +1%"] = 398;
		
		lv105CustomOptionMap[399] = "화속성 강화 +35<br>모든 상태 이상 내성 -10%";
		lv105CustomOptionToNumberMap["화속성 강화 +35<br>모든 상태 이상 내성 -10%"] = 399;
		
		lv105CustomOptionMap[400] = "화속성 강화 +20";
		lv105CustomOptionToNumberMap["화속성 강화 +20"] = 400;
		
		lv105CustomOptionMap[401] = "화속성 저항 +10";
		lv105CustomOptionToNumberMap["화속성 저항 +10"] = 401;
		
		lv105CustomOptionMap[402] = "회피율 +8%";
		lv105CustomOptionToNumberMap["회피율 +8%"] = 402;
		
		lv105CustomOptionMap[403] = "공격력 증가 + N%<br>물리/마법 피해 감소 +13%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>물리/마법 피해 감소 +13%"] = 403;
		
		lv105CustomOptionMap[404] = "최종 데미지 4% 증가<br>- '새겨지는 맹세' 장비 장착 필요<br>모든 속도 +5%<br>화상 내성 +20%";
		lv105CustomOptionToNumberMap["최종 데미지 4% 증가<br>- '새겨지는 맹세' 장비 장착 필요<br>모든 속도 +5%<br>화상 내성 +20%"] = 404;
		
		lv105CustomOptionMap[405] = "공격력 증가 + N%<br>약점 무력화형 상태 이상, 홀딩 무력화 게이지 감소량 +200%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>약점 무력화형 상태 이상, 홀딩 무력화 게이지 감소량 +200%"] = 405;
		
		lv105CustomOptionMap[406] = "중독 데미지 +15%<br>중독 내성 +10%";
		lv105CustomOptionToNumberMap["중독 데미지 +15%<br>중독 내성 +10%"] = 406;
		
		lv105CustomOptionMap[407] = "적에게 석화 상태 이상 부여 시도 시 60초 동안 공격력 증가 + N%<br>물리/마법 방어력 +5,000";
		lv105CustomOptionToNumberMap["적에게 석화 상태 이상 부여 시도 시 60초 동안 공격력 증가 + N%<br>물리/마법 방어력 +5,000"] = 407;
		
		lv105CustomOptionMap[408] = "최종 데미지 7% 증가<br>- '천재 기술자의 두터운 보호부츠' 장비 장착 필요";
		lv105CustomOptionToNumberMap["최종 데미지 7% 증가<br>- '천재 기술자의 두터운 보호부츠' 장비 장착 필요"] = 408;
		
		lv105CustomOptionMap[409] = "출혈 피해 +15%<br>[폭발 약속 - 출혈]<br>출혈 부여 시 출혈 피해 폭발<br>- 전환 옵션에 의한 부여 제외";
		lv105CustomOptionToNumberMap["출혈 피해 +15%<br>[폭발 약속 - 출혈]<br>출혈 부여 시 출혈 피해 폭발<br>- 전환 옵션에 의한 부여 제외"] = 409;
		
		lv105CustomOptionMap[410] = "감전 피해 +15%<br>[폭발 약속 - 감전]<br>감전 부여 시 감전 피해 폭발<br>- 전환 옵션에 의한 부여 제외";
		lv105CustomOptionToNumberMap["감전 피해 +15%<br>[폭발 약속 - 감전]<br>감전 부여 시 감전 피해 폭발<br>- 전환 옵션에 의한 부여 제외"] = 410;
		
		lv105CustomOptionMap[411] = "HP가 40% 이하일 때 최종 데미지 6% 증가<br>공격 시 HP 550 / MP 875 회복 (쿨타임 1초)<br>물리/마법 방어력 +15,000";
		lv105CustomOptionToNumberMap["HP가 40% 이하일 때 최종 데미지 6% 증가<br>공격 시 HP 550 / MP 875 회복 (쿨타임 1초)<br>물리/마법 방어력 +15,000"] = 411;
		
		lv105CustomOptionMap[412] = "화상 피해 +15%<br>화상 지속 시간 -20%";
		lv105CustomOptionToNumberMap["화상 피해 +15%<br>화상 지속 시간 -20%"] = 412;
		
		lv105CustomOptionMap[413] = "최종 데미지 4% 증가<br>소모품 효과 유지 시간 +10%<br>모든 상태 이상 내성 +1.5%<br>수면 내성 -1.5%";
		lv105CustomOptionToNumberMap["최종 데미지 4% 증가<br>소모품 효과 유지 시간 +10%<br>모든 상태 이상 내성 +1.5%<br>수면 내성 -1.5%"] = 413;
		
		lv105CustomOptionMap[414] = "화상-빙결 연계로 인한 화상 피해 +25%";
		lv105CustomOptionToNumberMap["화상-빙결 연계로 인한 화상 피해 +25%"] = 414;
		
		lv105CustomOptionMap[415] = "최종 데미지 5% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가"] = 415;
		
		lv105CustomOptionMap[416] = "최종 데미지 6% 증가<br>모든 속도 +10%";
		lv105CustomOptionToNumberMap["최종 데미지 6% 증가<br>모든 속도 +10%"] = 416;
		
		lv105CustomOptionMap[417] = "자신에게 부여된 상태 이상 1개마다 최종 데미지 1% 증가 (최대 4중첩)";
		lv105CustomOptionToNumberMap["자신에게 부여된 상태 이상 1개마다 최종 데미지 1% 증가 (최대 4중첩)"] = 417;
		
		lv105CustomOptionMap[418] = "최종 데미지 3% 증가<br>- '오랜 시간 품어온 소원' 장비 장착 필요<br>장비로 인한 MP 회복 효과 15% 증가<br>모든 속도 +10%<br>암흑 내성 +20%";
		lv105CustomOptionToNumberMap["최종 데미지 3% 증가<br>- '오랜 시간 품어온 소원' 장비 장착 필요<br>장비로 인한 MP 회복 효과 15% 증가<br>모든 속도 +10%<br>암흑 내성 +20%"] = 418;
		
		lv105CustomOptionMap[419] = "회피율 +10%";
		lv105CustomOptionToNumberMap["회피율 +10%"] = 419;
		
		lv105CustomOptionMap[420] = "무색 큐브 조각을 10개 이상 소모하는 스킬 시전 시 30초 동안 스킬 쿨타임 회복 속도 +15% (각성기 제외)";
		lv105CustomOptionToNumberMap["무색 큐브 조각을 10개 이상 소모하는 스킬 시전 시 30초 동안 스킬 쿨타임 회복 속도 +15% (각성기 제외)"] = 420;
		
		lv105CustomOptionMap[421] = "50, 85, 100Lv 스킬 공격력 11% 증가";
		lv105CustomOptionToNumberMap["50, 85, 100Lv 스킬 공격력 11% 증가"] = 421;
		
		lv105CustomOptionMap[422] = "최종 데미지 5% 증가<br>피격으로 HP가 10% 이상 감소 시 20초 동안 HP MAX 20% 수치의 보호막 부여 (쿨타임 15초)";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>피격으로 HP가 10% 이상 감소 시 20초 동안 HP MAX 20% 수치의 보호막 부여 (쿨타임 15초)"] = 422;
		
		lv105CustomOptionMap[423] = "스킬 범위 옵션 수치의 총합이 30% 이상일 때 최종 데미지 6% 증가";
		lv105CustomOptionToNumberMap["스킬 범위 옵션 수치의 총합이 30% 이상일 때 최종 데미지 6% 증가"] = 423;
		
		lv105CustomOptionMap[424] = "무색 큐브 조각을 소모하지 않는 스킬 공격력 10% 증가";
		lv105CustomOptionToNumberMap["무색 큐브 조각을 소모하지 않는 스킬 공격력 10% 증가"] = 424;
		
		lv105CustomOptionMap[425] = "공격력 증가 + N%<br>공격 시 적의 무력화 게이지량 -1";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>공격 시 적의 무력화 게이지량 -1"] = 425;
		
		lv105CustomOptionMap[426] = "무색 큐브 조각을 소모하지 않는 스킬 공격력 10% 증가<br>물리/마법 방어력 +5,000";
		lv105CustomOptionToNumberMap["무색 큐브 조각을 소모하지 않는 스킬 공격력 10% 증가<br>물리/마법 방어력 +5,000"] = 426;
		
		lv105CustomOptionMap[427] = "스킬 쿨타임 10% 감소 (각성기 제외)<br>- '경계를 넘어선 차원' 장비 장착 또는 '모든 TP 스킬 Lv +1' 옵션 필요";
		lv105CustomOptionToNumberMap["스킬 쿨타임 10% 감소 (각성기 제외)<br>- '경계를 넘어선 차원' 장비 장착 또는 '모든 TP 스킬 Lv +1' 옵션 필요"] = 427;
		
		lv105CustomOptionMap[428] = "공격력 증가 + N%<br>무력화 게이지 감소량 +10%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>무력화 게이지 감소량 +10%"] = 428;
		
		lv105CustomOptionMap[429] = "공격력 증가 + 296.4%";
		lv105CustomOptionToNumberMap["공격력 증가 + 296.4%"] = 429;
		
		lv105CustomOptionMap[430] = "모든 속성 강화 +15<br>모든 속도 +10%";
		lv105CustomOptionToNumberMap["모든 속성 강화 +15<br>모든 속도 +10%"] = 430;
		
		lv105CustomOptionMap[431] = "스킬 쿨타임 16% 감소 (각성기 제외)<br>- '천재 기술자의 두터운 보호부츠' 장비 장착 필요";
		lv105CustomOptionToNumberMap["스킬 쿨타임 16% 감소 (각성기 제외)<br>- '천재 기술자의 두터운 보호부츠' 장비 장착 필요"] = 431;
		
		lv105CustomOptionMap[432] = "무색 큐브 조각을 15개 이상 소모하는 스킬 공격력 10% 증가";
		lv105CustomOptionToNumberMap["무색 큐브 조각을 15개 이상 소모하는 스킬 공격력 10% 증가"] = 432;
		
		lv105CustomOptionMap[433] = "최종 데미지 6% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 6% 증가"] = 433;
		
		lv105CustomOptionMap[434] = "출혈 피해 +15%";
		lv105CustomOptionToNumberMap["출혈 피해 +15%"] = 434;
		
		lv105CustomOptionMap[435] = "최종 데미지 2% 증가<br>스킬 쿨타임 6% 감소 (각성기 제외)";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>스킬 쿨타임 6% 감소 (각성기 제외)"] = 435;
		
		lv105CustomOptionMap[436] = "최종 데미지 3% 증가<br>스킬 쿨타임 4% 감소 (각성기 제외)";
		lv105CustomOptionToNumberMap["최종 데미지 3% 증가<br>스킬 쿨타임 4% 감소 (각성기 제외)"] = 436;
		
		lv105CustomOptionMap[437] = "자신에게 부여된 상태 이상 1개마다 최종 데미지 2% 증가 (최대 4중첩)";
		lv105CustomOptionToNumberMap["자신에게 부여된 상태 이상 1개마다 최종 데미지 2% 증가 (최대 4중첩)"] = 437;
		
		lv105CustomOptionMap[438] = "무색 큐브 조각을 15개 이상 소모하는 스킬 시전 시 30초 동안 최종 데미지 6% 증가<br>모든 속도 +10%";
		lv105CustomOptionToNumberMap["무색 큐브 조각을 15개 이상 소모하는 스킬 시전 시 30초 동안 최종 데미지 6% 증가<br>모든 속도 +10%"] = 438;
		
		lv105CustomOptionMap[439] = "화상 피해 +20%<br>화상 내성 +5%";
		lv105CustomOptionToNumberMap["화상 피해 +20%<br>화상 내성 +5%"] = 439;
		
		lv105CustomOptionMap[440] = "공격력 증가 + 222.3%";
		lv105CustomOptionToNumberMap["공격력 증가 + 222.3%"] = 440;
		
		lv105CustomOptionMap[441] = "50, 85, 100Lv 스킬 공격력 8% 증가";
		lv105CustomOptionToNumberMap["50, 85, 100Lv 스킬 공격력 8% 증가"] = 441;
		
		lv105CustomOptionMap[442] = "공격력 증가 + N%<br>모든 상태이상 내성 +10%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>모든 상태이상 내성 +10%"] = 442;
		
		lv105CustomOptionMap[443] = "감전 피해 +20%<br>이동 속도 +5%";
		lv105CustomOptionToNumberMap["감전 피해 +20%<br>이동 속도 +5%"] = 443;
		
		lv105CustomOptionMap[444] = "화상-빙결 연계로 인한 화상 피해 +15%";
		lv105CustomOptionToNumberMap["화상-빙결 연계로 인한 화상 피해 +15%"] = 444;
		
		lv105CustomOptionMap[445] = "무색 큐브 조각을 소모하지 않는 스킬 공격력 10% 증가<br>이동 속도 +5%";
		lv105CustomOptionToNumberMap["무색 큐브 조각을 소모하지 않는 스킬 공격력 10% 증가<br>이동 속도 +5%"] = 445;
		
		lv105CustomOptionMap[446] = "화상 피해 +20%<br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환";
		lv105CustomOptionToNumberMap["화상 피해 +20%<br>[혹한의 일격]<br>[아이스 스트라이크] 옵션을 최종 데미지 5% 증가로 변환"] = 446;
		
		lv105CustomOptionMap[447] = "공격력 증가 + N%<br>이동 속도 +15%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>이동 속도 +15%"] = 447;
		
		lv105CustomOptionMap[448] = "스킬 쿨타임 회복 속도 +15% (각성기 제외)";
		lv105CustomOptionToNumberMap["스킬 쿨타임 회복 속도 +15% (각성기 제외)"] = 448;
		
		lv105CustomOptionMap[449] = "최종 데미지 6% 증가<br>물리/마법 방어력 +4,000";
		lv105CustomOptionToNumberMap["최종 데미지 6% 증가<br>물리/마법 방어력 +4,000"] = 449;
		
		lv105CustomOptionMap[450] = "최종 데미지 7% 증가<br>- '오랜 시간 품어온 소원' 장비 장착 필요<br>수면 내성 -20%";
		lv105CustomOptionToNumberMap["최종 데미지 7% 증가<br>- '오랜 시간 품어온 소원' 장비 장착 필요<br>수면 내성 -20%"] = 450;
		
		lv105CustomOptionMap[451] = "공격력 증가 + N%<br>- '오랜 시간 품어온 소원' 장비 장착 필요";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>- '오랜 시간 품어온 소원' 장비 장착 필요"] = 451;
		
		lv105CustomOptionMap[452] = "피격 시 10초 동안 HP MAX 10% 수치의 보호막 부여 (쿨타임 10초)";
		lv105CustomOptionToNumberMap["피격 시 10초 동안 HP MAX 10% 수치의 보호막 부여 (쿨타임 10초)"] = 452;
		
		lv105CustomOptionMap[453] = "스킬 범위 15% 증가";
		lv105CustomOptionToNumberMap["스킬 범위 15% 증가"] = 453;
		
		lv105CustomOptionMap[454] = "마법 방어율이 25% 이상일 때 스킬 쿨타임 회복 속도 +15% (각성기 제외)";
		lv105CustomOptionToNumberMap["마법 방어율이 25% 이상일 때 스킬 쿨타임 회복 속도 +15% (각성기 제외)"] = 454;
		
		lv105CustomOptionMap[455] = "최종 데미지 10% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 10% 증가"] = 455;
		
		lv105CustomOptionMap[456] = "무색 큐브 조각을 소모하지 않는 1~35Lv 스킬 공격력 10% 증가";
		lv105CustomOptionToNumberMap["무색 큐브 조각을 소모하지 않는 1~35Lv 스킬 공격력 10% 증가"] = 456;
		
		lv105CustomOptionMap[457] = "공격력 증가 + 251.9%";
		lv105CustomOptionToNumberMap["공격력 증가 + 251.9%"] = 457;
		
		lv105CustomOptionMap[458] = "스킬 쿨타임 10% 감소 (각성기 제외)";
		lv105CustomOptionToNumberMap["스킬 쿨타임 10% 감소 (각성기 제외)"] = 458;
		
		lv105CustomOptionMap[459] = "최종 데미지 4% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 4% 증가"] = 459;
		
		lv105CustomOptionMap[460] = "공격력 증가 + N%<br>물리/마법 피해 감소 +10%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>물리/마법 피해 감소 +10%"] = 460;
		
		lv105CustomOptionMap[461] = "[골렘의 일격]<br>[스톤 스트라이크] 옵션을 최종 데미지 5% 증가로 변환";
		lv105CustomOptionToNumberMap["[골렘의 일격]<br>[스톤 스트라이크] 옵션을 최종 데미지 5% 증가로 변환"] = 461;
		
		lv105CustomOptionMap[462] = "최종 데미지 4% 증가<br>스킬 범위 10% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 4% 증가<br>스킬 범위 10% 증가"] = 462;
		
		lv105CustomOptionMap[463] = "화상 피해 +15%<br>화상 내성 +10%";
		lv105CustomOptionToNumberMap["화상 피해 +15%<br>화상 내성 +10%"] = 463;
		
		lv105CustomOptionMap[464] = "공격력 증가 + N%<br>이동 속도 +5%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>이동 속도 +5%"] = 464;
		
		lv105CustomOptionMap[78] = "공격력 증가 + N% ";
		lv105CustomOptionToNumberMap["공격력 증가 + N% "] = 78;
		
		lv105CustomOptionMap[466] = "최종 데미지 2% 증가<br>솔리다리스 (상급) 던전 입장 시 최종 데미지 10% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 2% 증가<br>솔리다리스 (상급) 던전 입장 시 최종 데미지 10% 증가"] = 466;
		
		lv105CustomOptionMap[467] = "무색 큐브 조각을 소모하는 스킬 공격력 8% 증가";
		lv105CustomOptionToNumberMap["무색 큐브 조각을 소모하는 스킬 공격력 8% 증가"] = 467;
		
		lv105CustomOptionMap[468] = "50, 85, 100Lv 스킬 시전 시 8% 확률로 해당 스킬 공격력 88% 증가";
		lv105CustomOptionToNumberMap["50, 85, 100Lv 스킬 시전 시 8% 확률로 해당 스킬 공격력 88% 증가"] = 468;
		
		lv105CustomOptionMap[469] = "최종 데미지 4% 증가<br>- '과거를 뒤로 한 전진' 장비 장착 필요";
		lv105CustomOptionToNumberMap["최종 데미지 4% 증가<br>- '과거를 뒤로 한 전진' 장비 장착 필요"] = 469;
		
		lv105CustomOptionMap[470] = "회피율 +4%";
		lv105CustomOptionToNumberMap["회피율 +4%"] = 470;
		
		lv105CustomOptionMap[471] = "모든 속성 강화 +25<br>공격 시 HP 500 회복 (쿨타임 1초)";
		lv105CustomOptionToNumberMap["모든 속성 강화 +25<br>공격 시 HP 500 회복 (쿨타임 1초)"] = 471;
		
		lv105CustomOptionMap[472] = "모든 속성 강화 +25<br>공격 시 MP 500 회복 (쿨타임 1초)";
		lv105CustomOptionToNumberMap["모든 속성 강화 +25<br>공격 시 MP 500 회복 (쿨타임 1초)"] = 472;
		
		lv105CustomOptionMap[473] = "최종 데미지 3% 증가<br>적중률 +8%";
		lv105CustomOptionToNumberMap["최종 데미지 3% 증가<br>적중률 +8%"] = 473;
		
		lv105CustomOptionMap[474] = "최종 데미지 3% 증가<br>회피율 +8%";
		lv105CustomOptionToNumberMap["최종 데미지 3% 증가<br>회피율 +8%"] = 474;
		
		lv105CustomOptionMap[475] = "스킬 쿨타임 회복 속도 +15% (각성기 제외)<br>- '새겨지는 맹세' 장비 장착 필요";
		lv105CustomOptionToNumberMap["스킬 쿨타임 회복 속도 +15% (각성기 제외)<br>- '새겨지는 맹세' 장비 장착 필요"] = 475;
		
		lv105CustomOptionMap[476] = "중독 피해 +12%";
		lv105CustomOptionToNumberMap["중독 피해 +12%"] = 476;
		
		lv105CustomOptionMap[477] = "공격력 증가 + N%<br>적중률 +4%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>적중률 +4%"] = 477;
		
		lv105CustomOptionMap[478] = "공격력 증가 + N%<br>회피율 +4%";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>회피율 +4%"] = 478;
		
		lv105CustomOptionMap[479] = "장비 오브젝트 데미지 +10%";
		lv105CustomOptionToNumberMap["장비 오브젝트 데미지 +10%"] = 479;
		
		lv105CustomOptionMap[480] = "무력화형 상태 이상, 홀딩 무력화 게이지 감소량 +100%";
		lv105CustomOptionToNumberMap["무력화형 상태 이상, 홀딩 무력화 게이지 감소량 +100%"] = 480;
		
		lv105CustomOptionMap[481] = "최종 데미지 5% 증가<br>카운터 피격 시 물리/마법 피해 감소 +5%";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>카운터 피격 시 물리/마법 피해 감소 +5%"] = 481;
		
		lv105CustomOptionMap[482] = "출혈 피해 +15%<br>이동 속도 +5%";
		lv105CustomOptionToNumberMap["출혈 피해 +15%<br>이동 속도 +5%"] = 482;
		
		lv105CustomOptionMap[483] = "최종 데미지 5% 증가<br>백어택 피격 시 물리/마법 피해 감소 +5%";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>백어택 피격 시 물리/마법 피해 감소 +5%"] = 483;
		
		lv105CustomOptionMap[484] = "최종 데미지 5% 증가<br>정면 피격 시 물리/마법 피해 감소 +5%";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>정면 피격 시 물리/마법 피해 감소 +5%"] = 484;
		
		lv105CustomOptionMap[485] = "커맨드로 스킬 시전 시 30초 동안 슈퍼아머 부여";
		lv105CustomOptionToNumberMap["커맨드로 스킬 시전 시 30초 동안 슈퍼아머 부여"] = 485;
		
		lv105CustomOptionMap[486] = "최종 데미지 6% 증가<br>- '오랜 시간 품어온 소원' 장비 장착 필요";
		lv105CustomOptionToNumberMap["최종 데미지 6% 증가<br>- '오랜 시간 품어온 소원' 장비 장착 필요"] = 486;
		
		lv105CustomOptionMap[487] = "최종 데미지 6% 증가<br>- '경계를 넘어선 차원' 장비 장착 또는 '모든 TP 스킬 Lv +1' 옵션 필요<br>75~100Lv 스킬 공격력 6% 증가";
		lv105CustomOptionToNumberMap["최종 데미지 6% 증가<br>- '경계를 넘어선 차원' 장비 장착 또는 '모든 TP 스킬 Lv +1' 옵션 필요<br>75~100Lv 스킬 공격력 6% 증가"] = 487;
		
		lv105CustomOptionMap[488] = "장비, 소모품, 회복 스킬로 인한 HP 회복량의 50%만큼 보호막 회복<br>- 지속적으로 회복하는 형태의 스킬 제외";
		lv105CustomOptionToNumberMap["장비, 소모품, 회복 스킬로 인한 HP 회복량의 50%만큼 보호막 회복<br>- 지속적으로 회복하는 형태의 스킬 제외"] = 488;
		
		lv105CustomOptionMap[489] = "공격력 증가 + N%<br>슈퍼 아머 부여";
		lv105CustomOptionToNumberMap["공격력 증가 + N%<br>슈퍼 아머 부여"] = 489;
		
		lv105CustomOptionMap[490] = "최종 데미지 5% 증가<br>MP MAX +300";
		lv105CustomOptionToNumberMap["최종 데미지 5% 증가<br>MP MAX +300"] = 490;
		lv105WeaponOptionMap[0] = '최종 데미지 5% 증가<br>적중률 +5%';
	lv105WeaponOptionMap[1] = '최종 데미지 5% 증가<br>회피율 +5%';
	lv105WeaponOptionMap[2] = '최종 데미지 6% 증가<br>모든 속도 +10%';
	lv105WeaponOptionMap[3] = '최종 데미지 6% 증가<br>HP MAX +10%';
	lv105WeaponOptionMap[4] = '최종 데미지 6% 증가<br>공격 시 2초 동안 HP 2,200 회복 (쿨타임 2초)';
	lv105WeaponOptionMap[5] = '최종 데미지 6% 증가<br>공격 시 2초 동안 MP 1,750 회복 (쿨타임 2초)';
	lv105WeaponOptionMap[6] = '최종 데미지 6% 증가<br>공격 시 3초 동안 HP 2,200, MP 1,750 회복 (쿨타임 3초)';
	lv105WeaponOptionMap[7] = '최종 데미지 7% 증가<br>물리/마법 피해 감소 +3%';
	lv105WeaponOptionMap[8] = '최종 데미지 6% 증가<br>모든 스킬 범위 12% 증가';
	lv105WeaponOptionMap[9] = '최종 데미지 7% 증가<br>소모품, 장비, 스킬의 HP 회복 효과 10% 증가';
	lv105WeaponOptionMap[10] = '최종 데미지 7% 증가<br>스킬 MP 소모량 +100%';
	lv105WeaponOptionMap[11] = '최종 데미지 7% 증가<br>피격 시 HP 10% 회복 (쿨타임 30초)';
	lv105WeaponOptionMap[12] = '최종 데미지 7% 증가<br>피격 시 MP 10% 회복 (쿨타임 30초)';
	lv105WeaponOptionMap[13] = '최종 데미지 8% 증가 (각성기 제외)<br>스킬 쿨타임 10% 증가';
	lv105WeaponOptionMap[14] = '최종 데미지 6% 증가<br>점프력 +30';
	lv105WeaponOptionMap[15] = '최종 데미지 6% 증가<br>모든 속성 저항 +10';
	lv105WeaponOptionMap[16] = '최종 데미지 6% 증가<br>카운터 피격 시 물리/마법 피해 감소 +5%';
	lv105WeaponOptionMap[17] = '최종 데미지 6% 증가<br>물리/마법 크리티컬 히트 +3%';
	lv105WeaponOptionMap[18] = '모든 속성 강화 +30';
	lv105WeaponOptionMap[19] = '40~80Lv 스킬 쿨타임 회복 속도 +20% (각성기 제외)';
	lv105WeaponOptionMap[20] = '최종 데미지 3% 증가<br>1~45Lv 스킬 쿨타임 회복속도 +20%';
	lv105WeaponOptionMap[21] = '최종 데미지 2% 증가<br>60~80Lv 스킬 쿨타임 회복속도 +15%';
	lv105WeaponOptionMap[22] = '최종 데미지 4% 증가<br>95Lv 스킬 쿨타임 회복속도 +15%';
	lv105WeaponOptionMap[23] = '최종 데미지 5% 증가<br>1~20Lv 스킬 쿨타임 20% 감소';
	lv105WeaponOptionMap[24] = '최종 데미지 5% 증가<br>25, 30Lv 스킬 쿨타임 20% 감소';
	lv105WeaponOptionMap[25] = '최종 데미지 5% 증가<br>35, 40Lv 스킬 쿨타임 15% 감소';
	lv105WeaponOptionMap[26] = '최종 데미지 5% 증가<br>45Lv 스킬 쿨타임 15% 감소';
	lv105WeaponOptionMap[27] = '최종 데미지 5% 증가<br>60Lv 스킬 쿨타임 15% 감소';
	lv105WeaponOptionMap[28] = '최종 데미지 5% 증가<br>70Lv 스킬 쿨타임 15% 감소';
	lv105WeaponOptionMap[29] = '최종 데미지 5% 증가<br>75Lv 스킬 쿨타임 10% 감소';
	lv105WeaponOptionMap[30] = '최종 데미지 5% 증가<br>80Lv 스킬 쿨타임 10% 감소';
	lv105WeaponOptionMap[31] = '최종 데미지 4% 증가<br>95Lv 스킬 쿨타임 10% 감소';
	lv105WeaponOptionMap[32] = '인간 타입의 적 공격 시 스킬 공격력 8% 증가';
	lv105WeaponOptionMap[33] = '야수 타입의 적 공격 시 스킬 공격력 8% 증가';
	lv105WeaponOptionMap[34] = '식물 타입의 적 공격 시 스킬 공격력 8% 증가';
	lv105WeaponOptionMap[35] = '곤충 타입의 적 공격 시 스킬 공격력 8% 증가';
	lv105WeaponOptionMap[36] = '언데드 타입의 적 공격 시 스킬 공격력 8% 증가';
	lv105WeaponOptionMap[37] = '기계 타입의 적 공격 시 스킬 공격력 8% 증가';
	lv105WeaponOptionMap[38] = '악마 타입의 적 공격 시 스킬 공격력 8% 증가';
	lv105WeaponOptionMap[39] = '정령 타입의 적 공격 시 최종 데미지 8% 증가';
	lv105WeaponOptionMap[40] = '천사 타입의 적 공격 시 스킬 공격력 8% 증가';
	lv105WeaponOptionMap[41] = '용족 타입의 적 공격 시 최종 데미지 8% 증가';
	lv105WeaponOptionMap[42] = '장비 오브젝트 데미지 10% 증가<br>모든 속도 +15%';
	lv105WeaponOptionMap[43] = '최종 데미지 5% 증가<br>물리/마법 피해 감소 +8%';
	lv105WeaponOptionMap[44] = '스킬 쿨타임 10% 감소 (각성기 제외)<br>물리/마법 피해 감소 +7%';
	lv105WeaponOptionMap[45] = '최종 데미지 5% 증가<br>파티원 1명마다 이동 속도 +6% (최대 4중첩)';
	lv105WeaponOptionMap[46] = '최종 데미지 5% 증가<br>공격 속도 +16%';
	lv105WeaponOptionMap[47] = '모든 속성 강화 +25<br>모든 속도 +20%';
	lv105WeaponOptionMap[48] = '최종 데미지 5% 증가<br>파티원 1명마다 공격 속도 +6%, 캐스팅 속도 +9% (최대 4중첩)';
	lv105WeaponOptionMap[49] = '최종 데미지 5% 증가<br>공격 시 적에게 10초 동안 출혈 상태 이상 부여 (쿨타임 3초)';
	lv105WeaponOptionMap[50] = '최종 데미지 5% 증가<br>공격 시 적에게 10초 동안 감전 상태 이상 부여 (쿨타임 3초)';
	lv105WeaponOptionMap[51] = '최종 데미지 5% 증가<br>공격 시 적에게 10초 동안 중독 상태 이상 부여 (쿨타임 3초)';
	lv105WeaponOptionMap[52] = '최종 데미지 5% 증가<br>공격 시 적에게 10초 동안 화상 상태 이상 부여 (쿨타임 3초)';
	lv105WeaponOptionMap[53] = '화상 피해 +15%';
	lv105WeaponOptionMap[54] = '감전 피해 +15%';
	lv105WeaponOptionMap[55] = '출혈 피해 +15%';
	lv105WeaponOptionMap[56] = '중독 피해 +15%';
	lv105WeaponOptionMap[57] = '5 콤보마다 피해 증가 +1,698 (최대 6중첩)';
	lv105WeaponOptionMap[58] = '최종 데미지 6% 증가<br>물리/마법 방어력 +5,000';
	lv105WeaponOptionMap[59] = '스킬 쿨타임 10% 감소 (각성기 제외)';
	lv105WeaponOptionMap[60] = '최종 데미지 4% 증가<br>이동 속도 +5%';
	lv105WeaponOptionMap[61] = '최종 데미지 6% 증가<br>피격 시 15초 동안 HP MAX 10% 수치의 보호막 부여 (쿨타임 12초)';
	lv105WeaponOptionMap[62] = '최종 데미지 6% 증가<br>HP가 55% 이하일 때 장비의 보호막 MAX 10% 증가';
	lv105WeaponOptionMap[63] = '최종 데미지 6% 증가<br>MP가 55% 이하일 때 장비의 보호막 MAX 10% 증가';
	lv105WeaponOptionMap[64] = '최종 데미지 6% 증가<br>회피율 +4%';
	lv105WeaponOptionMap[65] = '최종 데미지 6% 증가<br>적중률 +4%';
	lv105WeaponOptionMap[66] = '최종 데미지 6% 증가<br>모든 속도 +15%';
	lv105WeaponOptionMap[67] = '최종 데미지 6% 증가<br>무력화 게이지 감소량 +25%';
	lv105WeaponOptionMap[68] = '최종 데미지 7% 증가<br>약점 무력화형 상태 이상 및 홀딩의 무력화 게이지 감소량 +50%';
	lv105WeaponOptionMap[69] = '최종 데미지 5% 증가<br>MP MAX +5%';
	lv105WeaponOptionMap[70] = '최종 데미지 5% 증가<br>HP MAX +5%';
	lv105WeaponOptionMap[71] = '최종 데미지 6% 증가<br>던전 입장 시 HP MAX 20% 제한<br>이동 속도 +15%';
	lv105WeaponOptionMap[72] = '최종 데미지 7% 증가<br>1분당 MP 348 회복';
	lv105WeaponOptionMap[73] = '최종 데미지 4% 증가';
	lv105WeaponOptionMap[74] = '최종 데미지 4% 증가<br>스킬 쿨타임 회복 속도 +5% (각성기 제외)';
	lv105WeaponOptionMap[75] = '최종 데미지 3% 증가<br>스킬 쿨타임 회복 속도 +10% (각성기 제외)';
	lv105WeaponOptionMap[76] = '최종 데미지 5% 증가<br>50Lv 스킬 공격력 10% 증가';
	lv105WeaponOptionMap[77] = '최종 데미지 5% 증가<br>85Lv 스킬 공격력 8% 증가';
	lv105WeaponOptionMap[78] = '최종 데미지 5% 증가<br>100Lv 스킬 공격력 5% 증가';
	lv105WeaponOptionMap[79] = '스킬 쿨타임 10% 감소 (각성기 제외)<br>스킬 범위 10% 증가';
	lv105WeaponThirdOptionMap[0] = '최종 데미지 2% 증가<br>적중률 +3%';
	lv105WeaponThirdOptionMap[1] = '최종 데미지 2% 증가<br>회피율 +3%';
	lv105WeaponThirdOptionMap[2] = '최종 데미지 3% 증가<br>모든 속도 +5%';
	lv105WeaponThirdOptionMap[3] = '쿨타임이 15초 이상인 스킬의 스킬 공격력 3% 증가';
	lv105WeaponThirdOptionMap[4] = '최종 데미지 3% 증가<br>공격 시 2초 동안 HP 1,100 회복 (쿨타임 2초)';
	lv105WeaponThirdOptionMap[5] = '최종 데미지 3% 증가<br>공격 시 2초 동안 MP 875 회복 (쿨타임 2초)';
	lv105WeaponThirdOptionMap[6] = '최종 데미지 3% 증가<br>공격 시 3초 동안 HP 1,100, MP 875 회복 (쿨타임 3초)';
	lv105WeaponThirdOptionMap[7] = '최종 데미지 4% 증가<br>물리/마법 피해 감소 +2%';
	lv105WeaponThirdOptionMap[8] = '최종 데미지 3% 증가<br>모든 스킬 범위 6% 증가';
	lv105WeaponThirdOptionMap[9] = '최종 데미지 4% 증가<br>소모품, 장비, 스킬의 HP 회복 효과 5% 증가';
	lv105WeaponThirdOptionMap[10] = '최종 데미지 4% 증가<br>스킬 MP 소모량 +100%';
	lv105WeaponThirdOptionMap[11] = '최종 데미지 4% 증가<br>피격 시 HP 5% 회복 (쿨타임 30초)';
	lv105WeaponThirdOptionMap[12] = '최종 데미지 4% 증가<br>피격 시 MP 5% 회복 (쿨타임 30초)';
	lv105WeaponThirdOptionMap[13] = '최종 데미지 5% 증가 (각성기 제외)<br>스킬 쿨타임 5% 증가';
	lv105WeaponThirdOptionMap[14] = '최종 데미지 3% 증가<br>점프력 +15';
	lv105WeaponThirdOptionMap[15] = '최종 데미지 3% 증가<br>모든 속성 저항 +5';
	lv105WeaponThirdOptionMap[16] = '최종 데미지 3% 증가<br>카운터 피격 시 물리/마법 피해 감소 +3%';
	lv105WeaponThirdOptionMap[17] = '최종 데미지 3% 증가<br>공격력 증가 +78.2%<br>물리/마법 크리티컬 히트 +1.5%';
	lv105WeaponThirdOptionMap[18] = '모든 속성 강화 +18';
	lv105WeaponThirdOptionMap[19] = '40~80Lv 스킬 쿨타임 회복 속도 +12% (각성기 제외)';
	lv105WeaponThirdOptionMap[20] = '최종 데미지 1% 증가<br>1~45Lv 스킬 쿨타임 회복속도 +12%';
	lv105WeaponThirdOptionMap[21] = '최종 데미지 1% 증가<br>60~80Lv 스킬 쿨타임 회복속도 +9%';
	lv105WeaponThirdOptionMap[22] = '최종 데미지 2% 증가<br>95Lv 스킬 쿨타임 회복속도 +9%';
	lv105WeaponThirdOptionMap[23] = '최종 데미지 3% 증가<br>1~20Lv 스킬 쿨타임 12% 감소';
	lv105WeaponThirdOptionMap[24] = '최종 데미지 3% 증가<br>25, 30Lv 스킬 쿨타임 12% 감소';
	lv105WeaponThirdOptionMap[25] = '최종 데미지 3% 증가<br>35, 40Lv 스킬 쿨타임 9% 감소';
	lv105WeaponThirdOptionMap[26] = '최종 데미지 3% 증가<br>45Lv 스킬 쿨타임 9% 감소';
	lv105WeaponThirdOptionMap[27] = '최종 데미지 3% 증가<br>60Lv 스킬 쿨타임 9% 감소';
	lv105WeaponThirdOptionMap[28] = '최종 데미지 3% 증가<br>70Lv 스킬 쿨타임 9% 감소';
	lv105WeaponThirdOptionMap[29] = '최종 데미지 3% 증가<br>75Lv 스킬 쿨타임 6% 감소';
	lv105WeaponThirdOptionMap[30] = '최종 데미지 3% 증가<br>80Lv 스킬 쿨타임 6% 감소';
	lv105WeaponThirdOptionMap[31] = '최종 데미지 2% 증가<br>95Lv 스킬 쿨타임 6% 감소';
	lv105WeaponThirdOptionMap[32] = '인간 타입의 적 공격 시 최종 데미지 4% 증가';
	lv105WeaponThirdOptionMap[33] = '야수 타입의 적 공격 시 최종 데미지 4% 증가';
	lv105WeaponThirdOptionMap[34] = '식물 타입의 적 공격 시 최종 데미지 4% 증가';
	lv105WeaponThirdOptionMap[35] = '곤충 타입의 적 공격 시 최종 데미지 4% 증가';
	lv105WeaponThirdOptionMap[36] = '언데드 타입의 적 공격 시 최종 데미지 4% 증가';
	lv105WeaponThirdOptionMap[37] = '기계 타입의 적 공격 시 최종 데미지 4% 증가';
	lv105WeaponThirdOptionMap[38] = '악마 타입의 적 공격 시 최종 데미지 4% 증가';
	lv105WeaponThirdOptionMap[39] = '정령 타입의 적 공격 시 최종 데미지 4% 증가';
	lv105WeaponThirdOptionMap[40] = '천사 타입의 적 공격 시 최종 데미지 4% 증가';
	lv105WeaponThirdOptionMap[41] = '용족 타입의 적 공격 시 최종 데미지 4% 증가';
	lv105WeaponThirdOptionMap[42] = '장비 오브젝트 데미지 6% 증가<br>모든 속도 +8%';
	lv105WeaponThirdOptionMap[43] = '최종 데미지 3% 증가<br>물리/마법 피해 감소 +4%';
	lv105WeaponThirdOptionMap[44] = '스킬 쿨타임 8% 감소 (각성기 제외)<br>물리/마법 피해 감소 +3%';
	lv105WeaponThirdOptionMap[45] = '최종 데미지 3% 증가<br>파티원 1명마다 이동 속도 +6% (최대 2중첩)';
	lv105WeaponThirdOptionMap[46] = '최종 데미지 3% 증가<br>공격 속도 +8%';
	lv105WeaponThirdOptionMap[47] = '모든 속성 강화 +15<br>모든 속도 +12%';
	lv105WeaponThirdOptionMap[48] = '최종 데미지 3% 증가<br>파티원 1명마다 공격 속도 +6%, 캐스팅 속도 +9% (최대 2중첩)';
	lv105WeaponThirdOptionMap[49] = '최종 데미지 3% 증가<br>공격 시 적에게 10초 동안 출혈 상태 이상 부여 (쿨타임 3초)';
	lv105WeaponThirdOptionMap[50] = '최종 데미지 3% 증가<br>공격 시 적에게 10초 동안 감전 상태 이상 부여 (쿨타임 3초)';
	lv105WeaponThirdOptionMap[51] = '최종 데미지 3% 증가<br>공격 시 적에게 10초 동안 중독 상태 이상 부여 (쿨타임 3초)';
	lv105WeaponThirdOptionMap[52] = '최종 데미지 3% 증가<br>공격 시 적에게 10초 동안 화상 상태 이상 부여 (쿨타임 3초)';
	lv105WeaponThirdOptionMap[53] = '화상 피해 +10%';
	lv105WeaponThirdOptionMap[54] = '감전 피해 +10%';
	lv105WeaponThirdOptionMap[55] = '출혈 피해 +10%';
	lv105WeaponThirdOptionMap[56] = '중독 피해 +10%';
	lv105WeaponThirdOptionMap[57] = '5 콤보마다 피해 증가 +1,018 (최대 6중첩)';
	lv105WeaponThirdOptionMap[58] = '최종 데미지 3% 증가<br>물리/마법 방어력 +2,500';
	lv105WeaponThirdOptionMap[59] = '스킬 쿨타임 6% 감소 (각성기 제외)';
	lv105WeaponThirdOptionMap[60] = '최종 데미지 3% 증가<br>이동 속도 +3%';
	lv105WeaponThirdOptionMap[61] = '최종 데미지 3% 증가<br>피격 시 15초 동안 HP MAX 5% 수치의 보호막 부여 (쿨타임 12초)';
	lv105WeaponThirdOptionMap[62] = '최종 데미지 3% 증가<br>HP가 55% 이하일 때 장비의 보호막 MAX 5% 증가';
	lv105WeaponThirdOptionMap[63] = '최종 데미지 3% 증가<br>MP가 55% 이하일 때 장비의 보호막 MAX 5% 증가';
	lv105WeaponThirdOptionMap[64] = '최종 데미지 3% 증가<br>회피율 +4%';
	lv105WeaponThirdOptionMap[65] = '최종 데미지 3% 증가<br>적중률 +4%';
	lv105WeaponThirdOptionMap[66] = '최종 데미지 3% 증가<br>모든 속도 +8%';
	lv105WeaponThirdOptionMap[67] = '최종 데미지 3% 증가<br>무력화 게이지 감소량 +15%';
	lv105WeaponThirdOptionMap[68] = '최종 데미지 3% 증가<br>약점 무력화형 상태 이상 및 홀딩의 무력화 게이지 감소량 +25%';
	lv105WeaponThirdOptionMap[69] = '최종 데미지 3% 증가<br>MP MAX +3%';
	lv105WeaponThirdOptionMap[70] = '최종 데미지 3% 증가<br>HP MAX +3%';
	lv105WeaponThirdOptionMap[71] = '최종 데미지 3% 증가<br>던전 입장 시 HP MAX 20% 제한<br>이동 속도 +8%';
	lv105WeaponThirdOptionMap[72] = '최종 데미지 4% 증가<br>1분당 MP 174 회복';
	lv105WeaponThirdOptionMap[73] = '최종 데미지 2% 증가';
	lv105WeaponThirdOptionMap[74] = '최종 데미지 2% 증가<br>스킬 쿨타임 회복 속도 +2% (각성기 제외)';
	lv105WeaponThirdOptionMap[75] = '최종 데미지 1% 증가<br>스킬 쿨타임 회복 속도 +8% (각성기 제외)';
	lv105WeaponThirdOptionMap[76] = '최종 데미지 3% 증가<br>50Lv 스킬 공격력 6% 증가';
	lv105WeaponThirdOptionMap[77] = '최종 데미지 3% 증가<br>85Lv 스킬 공격력 5% 증가';
	lv105WeaponThirdOptionMap[78] = '최종 데미지 3% 증가<br>100Lv 스킬 공격력 3% 증가';
	lv105WeaponThirdOptionMap[79] = '스킬 쿨타임 6% 감소 (각성기 제외)<br>스킬 범위 5% 증가';
	lv105WeaponThirdOptionMap[80] = '공격 시 60초 동안 피해 증가 +7627 (최대 1중첩)';
	

export { names, speNames, accNames, lv105OptionMap, lv105WeaponOptionMap, lv105CustomOptionMap, talismanList, lv105WeaponThirdOptionMap,lv105ItemId, legendStone }