import { createContext, useEffect, useState ,useMemo, memo } from 'react';
import { Routes, Route, Link, useNavigate, Outlet , useLocation} from 'react-router-dom'

import React from 'react';
import Ad from "../ad";


import trait from '../var/trait'
import {names,speNames,accNames,lv105OptionMap,lv105WeaponOptionMap,lv105CustomOptionMap,lv105WeaponThirdOptionMap,lv105ItemId, legendStone} from '../var/editVar';
import list from '../var/itemVar';
import skillImage from '../var/var';
import {editSetting,avatarOption,avatarSkillOption,avatarTypeList,emblemSkillOption,gradeToNum,runeSkill,talismanList,customEquip} from '../var/editSetting';

import {weaponImage,lv105WeaponImage,lv105EquipImage} from '../var/image';

var characterDataTemp = {};
var [characterData, setCharacterData] = [null,null];
var [present,setPresent]  = [null,null];
var [avaPresent,setAvaPresent]  = [null,null];

let [itemSearch,setItemSearch] =  [null,null];
let [itemSearchTemp,setItemSearchTemp] =  [null,null];
let optionArray = Array.from({length: 13}, (v, i) => 0);
let searchArray = Array.from({length: 13}, (v, i) => '');

let dStat = [100,25,100,100,100,100,100,100,100,100,100,100,200];
let dAttack = [90,0,110,30,110,15,30,0,0,0,110,0,0];
let dEle =  [15,6,0,0,0,0,0,35,35,35,12,35,15]; // 속강 스킬렙
let dESL = [0,0,1,2,0,2,3,1,1,1,0,0,0]; // 속강 스킬렙
//let dSkillDamage = [0,0,0,2,0,0,2,0,0,0,3,0,0]; // 스증,피증


let setItemList =[
    //상의
    [
        "지휘관의 명주 상의",
        "총사령관의 제복 상의",
        "미지의 근섬유 상의",
        "순례자의 해진 바람 막이"
    ],
    //하의
    [
        "전략가의 정교한 전투 바지",
        "총사령관의 제복 하의",
        "미지의 금속 각반",
        "순례자의 카고 바지"
    ],
    //어깨
    [
        "연합의 지휘자 망토",
        "총사령관의 의무",
        "미지의 보석 숄더",
        "순례자의 어깨 보호대"
    ],
    //벨트
    [
        "신뢰의 황금 복대",
        "총사령관의 지휘",
        "미지의 기계 장치 벨트",
        "순례자의 나무 요대"
    ],
    //신발
    [
        "결의의 전쟁 부츠",
        "총사령관의 결단",
        "미지의 관절 신발",
        "순례자의 사막 부츠"
    ],
    //팔찌
    [
        "무한한 영감",
        "총사령관의 섬멸 작전",
        "미지의 비늘 암릿",
        "순례자를 짓누르는 무게" 
    ],

    //목걸이
    [
        "포지 코어 펜던트",
        "총사령관의 비장의 한발",
        "미지의 눈동자 네클리스",
        "순례자를 얽매는 고난"

    ],
    //반지
    [
        "조화의 이치",
        "총사령관의 초심",
        "미지의 골격 링",
        "순례자를 억압하는 시련"
    ],
    //보장
    [
        "무회",
        "총사령관의 전술 지도",
        "미지의 에너지 스피어",
        "순례자의 미래를 보는 시선"
    ],
    //마법석
    [
        "무결한 마음",
        "총사령관의 독도법 나침반",
        "미지의 바이탈 시그널",
        "순례자의 길을 비추는 빛"

    ],
    //귀걸이
    [
        "흔적이 새겨진 돌",
        "총사령관의 전우가 남긴 유품",
        "미지의 파워 사이드",
        "순례자의 나아가는 의지" 
    ]


];

let setItemNames =[ "지휘관의 명주 상의",
"총사령관의 제복 상의",
"미지의 근섬유 상의",
"순례자의 해진 바람 막이","전략가의 정교한 전투 바지",
"총사령관의 제복 하의",
"미지의 금속 각반",
"순례자의 카고 바지","연합의 지휘자 망토",
"총사령관의 의무",
"미지의 보석 숄더",
"순례자의 어깨 보호대","신뢰의 황금 복대",
"총사령관의 지휘",
"미지의 기계 장치 벨트",
"순례자의 나무 요대","결의의 전쟁 부츠",
"총사령관의 결단",
"미지의 관절 신발",
"순례자의 사막 부츠", "무한한 영감",
"총사령관의 섬멸 작전",
"미지의 비늘 암릿",
"순례자를 짓누르는 무게" ,"포지 코어 펜던트",
"총사령관의 비장의 한발",
"미지의 눈동자 네클리스",
"순례자를 얽매는 고난","조화의 이치",
"총사령관의 초심",
"미지의 골격 링",
"순례자를 억압하는 시련", "무회",
"총사령관의 전술 지도",
"미지의 에너지 스피어",
"순례자의 미래를 보는 시선","무결한 마음",
"총사령관의 독도법 나침반",
"미지의 바이탈 시그널",
"순례자의 길을 비추는 빛","흔적이 새겨진 돌",
"총사령관의 전우가 남긴 유품",
"미지의 파워 사이드",
"순례자의 나아가는 의지" ];

//융합석

let fusionStoneList =[
    //반지
    [   
        '기민한 움직임',
        '얼음 송곳니',
        '세계의 경계',
        '무한한 가능성의 흐름',
        '보호를 담은 괴',
        '저항을 담은 철',
        '추억의 속삭임',
        '서리에 묻힌 분노',
        '망각의 마석',
        '부화하는 악령의 씨앗',
        '월드 아이돌 링',
        '이단을 지명하는 검지',
        '레드 래빗'

    ],
    //목걸이
    [   
        '멈추지 않는 질주',
        '광폭화 제어 장치',
        '지면 파괴자',
        '무한한 가능성의 흔적',
        '미지의 세계의 균형',
        '뒤집힌 기억',
        '향기로운 환희',
        '냉기에 갇힌 원망',
        '시간을 거스르는 기억',
        '터져나오는 악몽',
        '수호 : 혼',
        '펠 로스 제국의 영광',
        '소울 트레이싱 디바이스'
    ],
    //팔찌
    [
        '결속된 영혼',
        '무지한 영역의 통로',
        '마나 오버플로우',
        '무한한 가능성의 탐구',
        '스킬 펄 익스텐션',
        '지배자의 위엄'
        
    ],
    //보장
    [
        '사신의 함정',
        '인비시블 스톤',
        '수평선 위 균형',
        '혼돈이 담긴 소용돌이',
        '일렉트릭 플레임',
        '피할 수 없는 일격'
        , '영원의 조각 - 보조장비'       

    ],
    //마법석
    [
        '평화로운 과거',
        '천상의 보호',
        '영광의 재림',
        '지식의 향연',
        '혈독초',
        '펼쳐지는 예언'
        , '영원의 조각 - 마법석' 
    ],
    //귀걸이
    [
        '종말의 예고',
        '멸망의 안식처',
        '달콤한 향기의 속삭임',
        '지나간 과오',
        '종언을 고하는 서약',
        '검객의 유산'
        , '영원의 조각 - 귀걸이' 

    ]
]

let traitList = [
    [
        111,112,113,114,
        121,122,123,124,
        131,132,133,
        141,142,143
    ],
    [
        211,212,214,
        221,222,224,
        231,232,
        241,242,
    ],
    [
        312,314,
        321,323,324,
        332,
        341,343,
        351,353
    ],
    [
        412,414,
        421,422,423,424,
        431,432,433,
        442,
        451,452,453
    ]
];

let traitMaster = [
    [
        [7,10,7,5],
        [7,5,5,2],
        [1,7,5,0],
        [2,7,5,0]
    ],
    [
        [5,5,0,5],
        [5,5,0,2],
        [5,5,0,0],
        [3,3,0,0]
    ],
    [
        [0,5,0,5],
        [5,0,5,2],
        [0,1,0,0],
        [7,0,7,0],
        [5,0,5,0]
    ],
    [
        [0,7,0,5],
        [7,10,7,2],
        [7,1,0,0],
        [0,1,7,0],
        [7,1,7,0]
    ]
];

/*
let traitMaster = [
    [
        [5,7,5,5],
        [5,5,3,2],
        [1,5,5,0],
        [1,5,3,0]
    ],
    [
        [3,3,0,5],
        [3,3,0,2],
        [3,3,0,0],
        [1,1,0,0]
    ],
    [
        [0,5,0,5],
        [5,0,5,2],
        [0,1,0,0],
        [5,0,5,0],
        [1,0,1,0]
    ],
    [
        [0,5,0,5],
        [5,5,5,2],
        [5,1,0,0],
        [0,1,5,0],
        [5,1,5,0]
    ]
];
*/

let bStat = [110,25,110,100,100,100,100,100,100,100,100,100,200];
let bSkillLevel = [0,0,{'stat':4},{'stat':3},{'stat':3},{'stat':3},{'awake':3},{'stat':1},{'stat':1},{'stat':1},{'stat':3},{'stat':3},0];

let imageTemp = Math.round(Math.random() * 10000);

let [customArray,setCustomArray] = [null,null]; 
let [customSearch,setCustomSearch] = [null,null]; 
let [weaponOpSearch,setWeaponOpSearch] = [null,null]; 
let [weaponPlusArray,setWeaponPlusArray] = [null,null]; 
let [legendStoneArray,setLegendStoneArray] = [null,null]; 
let [legendStoneSelectArray,setLegendStoneSelectArray] = [null,null]; 
let [weaponPlusSelectArray,setWeaponPlusSelectArray] = [null,null]; 
let [customSelectArray,setCustomSelectArray] = [null,null]; 

let nick = "";
let init = false;

let itemCount = accNames.length / 3;
let speCount = speNames.length / 3;
let armourCount = names.length / 5;

let talismanSkillList = [];
let [selectTalisType,setSelectTalisType] = [null,null];
let [presentTalis,setPresentTalis] = [null,null];
let [selectTalisSkill,setSelectTalisSkill] = [null,null];
let [selectSpecType,setSelectSpecType] = [null,null];
let [selectWeaponType,setSelectWeaponType] = [null,null];
let [selectFusionType,setSelectFusionType] = [null,null];
let characterObj = null;
let navigate = null

let weaponListTemp = null;
let weaponList = null;
let statSkill = "";
let awakeSkill = "";
let [navigateValue,setNavigateValue]  = [null,null];
function EqupEdit()
{

    const location = useLocation();
    [selectWeaponType,setSelectWeaponType]  = useState(0);
    [selectFusionType,setSelectFusionType]  = useState(0);
    [present,setPresent] = useState(100);
    [avaPresent,setAvaPresent] = useState(100);
    [presentTalis,setPresentTalis] =  useState(100);
    [selectSpecType,setSelectSpecType] = useState(1);
    let equipArray = Array.from({length: 13}, (v, i) => i);
    navigate = useNavigate();
    [navigateValue,setNavigateValue] = useState();
    const character = { ...location.state };
    characterObj = character.character;



    [itemSearch,setItemSearch] = useState('');
    [itemSearchTemp,setItemSearchTemp] = useState('');
    
    if(characterObj == null)
    {
        
        if( localStorage.getItem('characterObj') == null)
        {
            window.history.replaceState("",null,"../error");
            window.location.reload();
        }
        
        characterObj = JSON.parse( localStorage.getItem('characterObj')).character;
    }
    else
    {
        localStorage.setItem('characterObj', JSON.stringify(character));
    }

    //console.log(characterObj);

    //선언부

    for(let loop = 0 ; loop < 13 ; loop++)
    {
        optionArray[loop] = [0,0,0,0];
    }
    for(let loop = 0 ; loop < 13 ; loop++)
    {
        searchArray[loop] = ['','','',''];
    }
    [customArray,setCustomArray] = useState(optionArray);
    [customSearch,setCustomSearch] = useState(searchArray);
    [weaponOpSearch,setWeaponOpSearch] = useState(['','','']);
    [weaponPlusArray,setWeaponPlusArray] = useState([0,0,0]);
    [legendStoneArray,setLegendStoneArray] = useState([[0,0,0],[0,0,0],[0,0,0]]);
    [legendStoneSelectArray,setLegendStoneSelectArray] = useState([[0,0,0],[0,0,0],[0,0,0]]);
    [weaponPlusSelectArray,setWeaponPlusSelectArray] = useState([0,0,0]);
    [customSelectArray,setCustomSelectArray] = useState([...optionArray]);

    if(nick != characterObj.name || !init)
    {
        nick = characterObj.name;
        init = true;
        
        selectTalisType = [0,0,0];
        selectTalisSkill = ['','',''];
        characterDataTemp = {};
        editSetting(characterObj,characterDataTemp,{talismanList,talismanSkillList,selectTalisType,selectTalisSkill,talismanToSkillName,setSelectSpecType,avatarTypeList});

    }

        
    switch(characterDataTemp['job'])
	{
		//귀검사 시작
		case "검신": case "眞 웨펀마스터":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검", "광검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "블러드 이블": case "眞 버서커":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "인다라천": case "眞 아수라":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "다크로드": case "眞 소울브링어":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		case "악귀나찰": case "眞 검귀":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
	
		case "트렌드세터": case "眞 뮤즈":
		{
			 weaponListTemp = ["선현궁"]
			weaponList = weaponListTemp;
			break;
		}
		case "셀레스티얼": case "眞 트래블러":
		{
			 weaponListTemp = ["장궁"];
			weaponList = weaponListTemp;
			break;
		}
        case "메이븐": case "眞 헌터":
		{
			 weaponListTemp = ["크로스슈터"];
			weaponList = weaponListTemp;
			break;
		}
        case "프레데터": case "眞 비질란테":
		{
			 weaponListTemp = ["에테리얼 보우"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		//귀검사 끝
		
		//여귀검사 시작
	
		
		case "마제스티": case "眞 소드마스터":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "네메시스": case "眞 다크템플러":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "검제": case "眞 베가본드":
		{	
			 weaponListTemp = ["소검", "도" ,"둔기", "대검", "광검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "디어사이드": case "眞 데몬슬레이어":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		case "벤데타": case "眞 블레이드":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
	
		//여귀검사 끝
		
		//여격투가 시작
		
		case "염제 폐월수화":
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "카이저":
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파", "권투글러브"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "용독문주": // 중호
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "얼티밋 디바": // 내계정
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		
		//여격투가 끝
		
		//남격투가 시작
		
		case "명왕":  case "眞 스트리트파이터": case "眞 스트리트 파이터":// 뱀파님
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		case "그랜드 마스터":  case "眞 그래플러": // 
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		case "패황":  case "眞 스트라이커":
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파", "권투글러브"];
			weaponList = weaponListTemp;
			break;
		}
		case "염황 광풍제월":  case "眞 넨마스터"://뱀파님
		{
			 weaponListTemp = ["너클", "건틀릿" ,"클로", "통파"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		//남격투가 끝
		
		//남거너 시작
		case "프라임": case "眞 메카닉":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "디스트로이어":  case "眞 런처":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "레이븐":   case "眞 레인저":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;	
		}
		
		case "커맨더": case "眞 스핏파이어":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		case "엑스마키나": case "眞 어썰트":// 뱀파님
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
	
	
		//남거너 끝
		//여거너 시작
		case "스톰 트루퍼":
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		case "프레이야":
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		case "크림슨 로제":
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		case "옵티머스":
		{
			 weaponListTemp = ["리볼버", "자동권총" ,"머스켓", "핸드캐넌", "보우건"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		//여거너 끝
		
		//여마법사 시작
		case "아슈타르테": case "眞 배틀메이지":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		
	
		case "이클립스": case "眞 소환사":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "오버마인드": case "眞 엘레멘탈마스터":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "지니위즈": case "眞 마도학자":// 내 계정
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭", "빗자루"];
			weaponList = weaponListTemp;
			break;
		}
		case "헤카테": case "眞 인챈트리스":// 내 계정
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭", "빗자루"];
			weaponList = weaponListTemp;
			break;
		}
		//여마법사 끝
		//남마법사 시작
	
		case "아이올로스":
		case "眞 스위프트 마스터":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
					
		case "뱀파이어 로드":
		case "眞 블러드 메이지":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
			
		}
		
		case "오블리비언":
		case "眞 엘레멘탈 바머":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		case "이터널":
		case "眞 빙결사":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭"];
			weaponList = weaponListTemp;
			break;
		}
		case "어센션": // 내꺼든 중호꺼든
		case "眞 디멘션워커":
			
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭", "빗자루"];
			weaponList = weaponListTemp;
			break;
		}
		
		//남마법사 끝
		//남프리스트 시작
		case "세인트":  case "眞 크루세이더":// 내꺼
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		case "저스티스":  case "眞 인파이터":
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		case "태을선인":  case "眞 퇴마사": // ?
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		case "이모탈":  case "眞 어벤저": // 뱀파님
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		//남프리스트 끝
		//여프리스트 시작
		
		case "세라핌": 
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		case "리디머": case "眞 미스트리스":
		{
			weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
	
		case "천선낭랑":  case "眞 무녀":
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "인페르노": case "眞 이단심판관":
		{
			 weaponListTemp = ["십자가", "염주" ,"토템", "낫", "배틀액스"];
			weaponList = weaponListTemp;
			break;
		}
		
		
		
		
		
		//여프리스트 끝
		//나이트 시작
		case "眞 엘븐나이트": case "가이아": // 내꺼
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		case "眞 카오스": case "마신": // ?
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "眞 드래곤나이트": case "드레드노트":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
	
		case "眞 팔라딘": case "세이비어":
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검"];
			weaponList = weaponListTemp;
			break;
		}
		
		//나이트 끝
		//여도적 시작
		case "알키오네": case "眞 로그":// 중호
		{
			 weaponListTemp = ["단검", "쌍검" ,"완드", "차크라 웨펀"];
			weaponList = weaponListTemp;
			break;
		}
		case "시라누이": case "眞 쿠노이치": // 내꺼
		{
			 weaponListTemp = ["단검", "쌍검" ,"완드", "차크라 웨펀"];
			weaponList = weaponListTemp;
			break;
		}
		case "그림리퍼": case "眞 섀도우댄서":
		{
			 weaponListTemp = ["단검", "쌍검" ,"완드", "차크라 웨펀"];
			weaponList = weaponListTemp;
			break;
		}
		case "타나토스": case "眞 사령술사":// ?
		{
			 weaponListTemp = ["단검", "쌍검" ,"완드", "차크라 웨펀"];
			weaponList = weaponListTemp;
			break;
		}
		//여도적 끝
		//마창사 시작
		case "듀란달": case "眞 듀얼리스트": // ?
		{
			 weaponListTemp = ["장창", "미늘창" ,"광창", "투창"];
			weaponList = weaponListTemp;
			break;
		}
		case "워로드": case "眞 뱅가드":
		{
			 weaponListTemp = ["장창", "미늘창" ,"광창", "투창"];
			weaponList = weaponListTemp;
			break;
		}
		case "에레보스":  case "眞 다크 랜서": // ..?
		{
			 weaponListTemp = ["장창", "미늘창" ,"광창", "투창"];
			weaponList = weaponListTemp;
			break;
		}
		case "제노사이더":  case "眞 드래고니안 랜서": // 볼배님
		{
			 weaponListTemp = ["장창", "미늘창" ,"광창", "투창"];
			weaponList = weaponListTemp;
			break;
		}
		//마창사 끝
		//외전 시작
		
		case "크리에이터(자각2)": case "眞 크리에이터":
		{
			 weaponListTemp = ["창", "봉" ,"로드", "스탭", "빗자루"];
			weaponList = weaponListTemp;
			break;
			
			
		}
		case "다크나이트(자각2)": case "眞 다크나이트":// 볼배님
		{
			 weaponListTemp = ["소검", "도" ,"둔기", "대검", "광검"];
			weaponList = weaponListTemp;
			break;
		}
		case "레퀴엠": case "眞 요원": // 참수님
		{
			 weaponListTemp = ["장도", "소태도" ,"중검", "코어블레이드"];
			weaponList = weaponListTemp;
			break;
		}
		case "언터처블": case "眞 트러블 슈터": // 참수님
		{
			 weaponListTemp = ["장도", "소태도" ,"중검", "코어블레이드"];
			weaponList = weaponListTemp;
			break;
		}
		case "패스파인더": case "眞 스페셜리스트": // 참수님
		{
			 weaponListTemp = ["장도", "소태도" ,"중검", "코어블레이드"];
			weaponList = weaponListTemp;
			break;
		}
		
		case "갓파더": case "眞 히트맨":
		{
			 weaponListTemp = ["장도", "소태도" ,"중검", "코어블레이드"];
			weaponList = weaponListTemp;
			break;
		}
		
	}

    let weaponCount = 0;
    for(let weapon of weaponList)
    {
        if(characterDataTemp['equipName0'].indexOf(weapon.replace("스탭","스태프")) != -1)
        {
            characterDataTemp['weaponType'] = weapon.replace("스탭","스태프");
            break;
        }
        weaponCount++;
    }

    useEffect(()=>{setSelectWeaponType(weaponCount);},[]);
    
    //console.log(characterObj.buffCal);
    if(characterObj.buffCal != null)
    {

        if(characterDataTemp['job'].indexOf("세라핌") != -1)
        {
            statSkill = "계시 : 아리아";
            awakeSkill = '신실한 열정';
        }
        else if(characterDataTemp['job'].indexOf("세인트") != -1)
        {
            statSkill = "수호의 은총";
            awakeSkill = '신념의 오라';
        }
        else if(characterDataTemp['job'].indexOf("트렌드세터") != -1)
        {
            statSkill = "센세이션";
            awakeSkill = '유명세';
        }
        else
        {
            statSkill = "퍼페티어";
            awakeSkill = '소악마';
        }

        characterDataTemp['isBuffer'] = true;
        dESL = bSkillLevel;
    }
    else
    {
        statSkill = "";
        awakeSkill = '';
        dESL = [0,0,1,2,0,2,3,1,1,1,0,0,0];
    }
    
    // //console.log("스탯 스킬 : " + statSkill);
    // //console.log("스탯 스킬 : " + dESL);
    
    //console.log("무기 : " + weaponList);
    
    [selectTalisType,setSelectTalisType] =  useState(selectTalisType);
    [selectTalisSkill,setSelectTalisSkill] =  useState(selectTalisSkill);
    [characterData, setCharacterData] = useState(characterDataTemp);
    //console.log(characterData);
    /*
    useEffect( () => { 
    change()
    },[characterDataTemp]);
    */
   
    useEffect( () => { 
        var self = window;
        self.__VM = self.__VM || [];
        
        self.__VM.push(function (admanager, scope) {
            scope.Config.get('billboard').display('ad-container-2');
            scope.Config.get('desktop_takeover').display('ad-container-1');
            scope.Config.verticalSticky().display();
    
        });

        return () =>
        {
            self.__VM = self.__VM || [];
            self.__VM.push((admanager, scope) => {
                admanager.removeAllPlacements();
                scope.Instances.pageManager.newPageSession();
            });
            
        };

            
    },[]);
    
    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     (window.top).__vm_add = (window.top).__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {
    //         //console.log("mounting2", placement); 

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358a82bf161d63ec3c8e8a");
    //         document.querySelector("#ad-container-2").appendChild(placement);
    //         (window.top).__vm_add.push(placement);
    //     });

            
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         //console.log("unmounting2", placement);
    //         (window.top).__vm_remove.push(placement);

    //     };

            
    // },[]);

      

    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     (window.top).__vm_add = (window.top).__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {
    //         //console.log("mounting", placement); 

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358a82bf161d63ec3c8e8a");
    //         placement.setAttribute("data-display-type", "hybrid-banner");
    //         document.querySelector("#ad-container-1").appendChild(placement);
    //         (window.top).__vm_add.push(placement);
    //        
    //     });
            
    //     return() => { 
    //         (window.top).__vm_remove = (window.top).__vm_remove || [];
    //         //console.log("unmounting", placement);
    //         (window.top).__vm_remove.push(placement);


    //     };

            
    // },[]);

    
    // useEffect( () => { 
        
    //     var placement = document.createElement("div");
    //     window.top.__vm_add = window.top.__vm_add || [];
    //     (function (success) {
    //         if (window.document.readyState !== "loading") {
    //             success();
    //         } else {
    //             window.document.addEventListener("DOMContentLoaded", function () {
    //                 success();
    //             });
    //         }
    //     })(function () {

    //         placement.setAttribute("class", "vm-placement");
    //         placement.setAttribute("data-id", "63358aa395a6d454fcf66d11");
    //         document.querySelector("#rich-media-placement").appendChild(placement);
    //         window.top.__vm_add.push(placement);
    //     });
            
    //     return() => { 
    //         (window.top).__vm_remove_category = (window.top).__vm_remove_category || [];
    //         (window.top).__vm_remove_category.push("richmedia_all");

    //         //console.log("unmounting", (window.top).__vm_remove_category);

    //     };

            
    // },[]);

        return(
            <section id="custom" name="장비커스텀">
            <div id="rich-media-placement"></div>
                <div class="cust container">
                <div id="ad-container-1" />
                    <div class="tabc-custom">
                        <div class="cus-custome">
                            <div class="tab-wrap" name="장비변경 탭">
                                <input type="radio" id="tab1" name="tabGroup2" class="tab" defaultChecked></input>
                                <label for="tab1"><span>장비</span></label>
                    
                                <input type="radio" id="tab2" name="tabGroup2" class="tab"></input>
                                <label for="tab2"><span>아바타</span></label>
                    
                                <input type="radio" id="tab3" name="tabGroup2" class="tab"></input>
                                <label for="tab3"><span>탈리스만</span></label>
                                <input type="radio" id="tab4" name="tabGroup2" class="tab"></input>
                                <label for="tab4"><span>장비특성</span></label>
                                
                                <div class="tab__content" name="장비">
                                    <div class="c_equip">
                                        <div class="caba">
                                            <div class="imgt">
                                                <img src={'https://img-api.neople.co.kr/df/servers/'+ characterObj.server +'/characters/'+ characterObj.id +'?zoom=1.jpg&temp='+imageTemp }/>
                                            </div>
                                        </div>
                                        
                                        
                                        <div class="cep">
                                            <ul>
                                                <PresentEquip number={3}/>
                                                <PresentEquip number={2}/>
                                                <PresentEquip number={4}/>
                                                <PresentEquip number={6}/>
                                                <PresentEquip number={5}/>
                                            
                                            </ul>
                                        </div>
                                        <div class="cep cepr">
                                            <ul>
                                                <PresentEquip number={0}/>
                                                { characterObj.buffCal != null ? <PlusTitle number={1}/> : <PresentEquip number={1}/> }
                                                
                                                
                                                <PresentEquip number={8}/>
                                                <PresentEquip number={7}/>
                                                <PresentEquip number={10}/>
                                                <PresentEquip number={9}/>
                                                <PresentEquip number={12}/>
                                                <PresentEquip number={11}/>
                                            </ul>
                                        </div>
                                        {
                                            characterObj.buffCal != null ? 
                                            <div class="cep cepr epuip-plus">
                                                <ul>
                                                    <PlusTitle number={115}/>
                                                    <PlusEquip number={113}/>
                                                    <PlusEquip number={114}/>

                                                </ul>
                                            </div>
                                            : <></>

                                        }
                                        {
                                            characterData.job == '검제' ? 
                                            <div class="cep cepr epuip-plus">
                                                <ul>
                                                    <SubWeapon number={116}/>
                                                </ul>
                                            </div>
                                            : <></>
                                        }
                                        

                                        <div class="cusabata_intro">
                                            <div class="cusaba_name">
                                                <span>{characterObj.name}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="cus_button" onClick={()=>{ submitSetting()}}>
                                        <div class="c_but">
                                            <span className='cbut-tag'  >
                                            적용
                                            </span>
                                        </div>
                                    </div>

                                    <div class="cus_de-cep">
                                        <div class="cset">
                                            <ul>
                                                <li onClick={()=>setPresent(100)}>105제</li>
                                                <li onClick={()=>setPresent(102)}>세트</li>
                                                <li onClick={()=>setPresent(101)}>융합장비</li>
                                                
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="cus_search e105s">
                                        <div class="e105_shc">
                                            <div class="searchbox" name="검색창">
                                                <input type="text" onChange={(event)=>setItemSearchTemp(event.target.value)} onKeyUp={(event)=>{ event.key == 'Enter' ? setItemSearch(event.target.value) : <></>}} class="form-control js-bound" placeholder="옵션검색" name="name" maxlength="11"></input>
                                            </div>
                                            
                                            <div class="searchbut105" onClick={()=>setItemSearch(itemSearchTemp)}>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="raid" style={present != 101 ? {display : 'none'} : {}}>

                                        <div class="cus_epdt accSpe apev2 e105ep apev2_pc_tabsplit">
                                            <div class="tabc">
                                                <div class="tab-wrap" name="105제 110제 전환 탭">
                                                        <input type="radio" id="tab41" name="tabGroup3" class="tab" ></input>
                                                        <label for="tab41"><span>105제</span></label>

                                                        <input type="radio" id="tab42" name="tabGroup3" class="tab" defaultChecked></input>
                                                        <label for="tab42"><span>110제</span></label>
                                                        
                                                        <input type="radio" id="tab43" name="tabGroup3" class="tab"></input>
                                                        <label for="tab43"><span>무기융합</span></label>
                                                        <div class="tab__content" name="105제">
                                                            <div class="cus_elst">
                                                                {/*
                                                                <div class="cus_eltit_v2 e-m-set"><span>105제</span>
                                                                </div>
                                                                */}
                                                                <div class="ape2-flex">

                                                                    <div class="ape2-split">
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>진룡</span></li>
                                                                            
                                                                            <li className={characterData['siroco'+8] == '진룡' ? " on" : ""} onClick={()=>change('siroco'+8,"진룡")}><div class="imgt raidItem" slot="108"><img name="진룡 : 압도하는 힘의 원천" src="https://dundam.xyz/img/item/cc64795c07f57cb9e52967063276f795"/></div></li>
                                                                            <li className={characterData['siroco'+7] == '진룡' ? " on" : ""} onClick={()=>change('siroco'+7,"진룡")}><div class="imgt raidItem" slot="107"><img name="진룡 : 잔혹한 용의 발톱" src="https://dundam.xyz/img/item/568018f4d9ed013f352d412331ab67ba"/></div></li>
                                                                            <li className={characterData['siroco'+9] == '진룡' ? " on" : ""} onClick={()=>change('siroco'+9,"진룡")}>
                                                                                <div class="imgt raidItem" slot="109">
                                                                                    <img name="진룡 : 무거운 철의 투지" src="https://dundam.xyz/img/item/6255f92b7b56a7ddd76f566a47ada800"/>
                                                                                </div>
                                                                               
                                                                            </li>
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>금룡</span></li>
                                                                            <li className={characterData['siroco'+8] == '금룡' ? " on" : ""} onClick={()=>change('siroco'+8,"금룡")}><div class="imgt raidItem" slot="108"><img name="금룡 : 화려한 힘의 기원" src="https://dundam.xyz/img/item/5285d14664a9aa41559ca68f440c3909"/></div></li>
                                                                            <li className={characterData['siroco'+7] == '금룡' ? " on" : ""} onClick={()=>change('siroco'+7,"금룡")}><div class="imgt raidItem" slot="107"><img name="금룡 : 찬란한 용의 비늘" src="https://dundam.xyz/img/item/eeb469cdd183e41318837d2dbd98dd42"/></div></li>
                                                                            <li className={characterData['siroco'+9] == '금룡' ? " on" : ""} onClick={()=>change('siroco'+9,"금룡")}><div class="imgt raidItem" slot="109"><img name="금룡 : 무한한 빛의 결계" src="https://dundam.xyz/img/item/52688ab6a97da9368e86343d0a3ac4a5"/></div></li>
                                                                            
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>화룡</span></li>
                                                                            <li className={characterData['siroco'+8] == '화룡' ? " on" : ""} onClick={()=>change('siroco'+8,"화룡")}><div class="imgt raidItem" slot="108"><img name="화룡 : 폭발하는 힘의 근원" src="https://dundam.xyz/img/item/0e79738a52d19e87cf910fc16537887b"/></div></li>
                                                                            <li className={characterData['siroco'+7] == '화룡' ? " on" : ""} onClick={()=>change('siroco'+7,"화룡")}><div class="imgt raidItem" slot="107"><img name="화룡 : 끓어오르는 용의 숨결" src="https://dundam.xyz/img/item/73f46c965770a5680576ad52219da4a5"/></div></li>
                                                                            <li className={characterData['siroco'+9] == '화룡' ? " on" : ""} onClick={()=>change('siroco'+9,"화룡")}><div class="imgt raidItem" slot="109"><img name="화룡 : 타오르는 불의 분노" src="https://dundam.xyz/img/item/6f388dd09d7149044abfd92fed63b4a6"/></div></li>
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>흑룡</span></li>
                                                                            <li className={characterData['siroco'+8] == '흑룡' ? " on" : ""} onClick={()=>change('siroco'+8,"흑룡")}><div class="imgt raidItem" slot="108"><img name="흑룡 : 어두운 힘의 근본" src="https://dundam.xyz/img/item/db3a4579b25e7dbdca33f56195ce259f"/></div></li>
                                                                            <li className={characterData['siroco'+7] == '흑룡' ? " on" : ""} onClick={()=>change('siroco'+7,"흑룡")}><div class="imgt raidItem" slot="107"><img name="흑룡 : 주시하는 용의 눈동자" src="https://dundam.xyz/img/item/27843216918f310d010f1014661c81bd"/></div></li>
                                                                            <li className={characterData['siroco'+9] == '흑룡' ? " on" : ""} onClick={()=>change('siroco'+9,"흑룡")}><div class="imgt raidItem" slot="109"><img name="흑룡 : 끝없는 암흑의 굴레" src="https://dundam.xyz/img/item/1fd082cd996a9eb02645be09c5e89d41"/></div></li>
                                                                         
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>천계</span></li>
                                                                            <li className={characterData['siroco'+8] == '천계' ? " on" : ""} onClick={()=>change('siroco'+8,"천계")}><div class="imgt raidItem" slot="108"><img name="천계 연합군 : 꺾이지 않는 의지" src="https://dundam.xyz/img/item/cb41d2e497f1a7ac1ae2711670da9861"/></div></li>
                                                                            <li className={characterData['siroco'+7] == '천계' ? " on" : ""} onClick={()=>change('siroco'+7,"천계")}><div class="imgt raidItem" slot="107"><img name="천계 연합군 : 모든 이를 위한 기도" src="https://dundam.xyz/img/item/3b62a91abc21df1a2609af53cb15d432"/></div></li>
                                                                            <li className={characterData['siroco'+9] == '천계' ? " on" : ""} onClick={()=>change('siroco'+9,"천계")}><div class="imgt raidItem" slot="109"><img name="천계 연합군 : 밝게 빛나는 희망" src="https://dundam.xyz/img/item/f0683ab71a21f9166ccb3423fa0a928c"/></div></li>
                                                                            
                                                                        </ul>
                                                                    </div>
                                                                
                                                                    <div class="ape2-split">

                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>슈페리어</span></li>
                                                                            <li className={characterData['siroco'+10] == '슈페' ? " on" : ""} onClick={()=>change('siroco'+10,"슈페")}><div class="imgt raidItem" slot="1010"><img name="슈페리어 : 오리진 오브 더 마이어" src="https://dundam.xyz/img/item/a6baa41de89c3a9bf280b65ca5b24b58"/></div></li>
                                                                            <li className={characterData['siroco'+11] == '슈페' ? " on" : ""} onClick={()=>change('siroco'+11,"슈페")}><div class="imgt raidItem" slot="1011"><img name="슈페리어 : 매직 서클로" src="https://dundam.xyz/img/item/323e201088055e1ed622be84b7479103"/></div></li>
                                                                            <li className={characterData['siroco'+12] == '슈페' ? " on" : ""} onClick={()=>change('siroco'+12,"슈페")}><div class="imgt raidItem" slot="1012"><img name="슈페리어 : 코스믹 이어링" src="https://dundam.xyz/img/item/70558b17d3d49fc8599f8e0d751bce9d"/></div></li>
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>심연의 결집</span></li>
                                                                            <li className={characterData['siroco'+10] == '심연' ? " on" : ""} onClick={()=>change('siroco'+10,"심연")}><div class="imgt raidItem" slot="1010"><img name="심연의 결집 : 정의되지 않는 존재" src="https://dundam.xyz/img/item/56422d257499f7c60c7dad02d0f43db2"/></div></li>
                                                                            <li className={characterData['siroco'+11] == '심연' ? " on" : ""} onClick={()=>change('siroco'+11,"심연")}><div class="imgt raidItem" slot="1011"><img name="심연의 결집 : 무한한 수축" src="https://dundam.xyz/img/item/2950e00eced48e19bf4c161b3bc7df7e"/></div></li>
                                                                            <li className={characterData['siroco'+12] == '심연' ? " on" : ""} onClick={()=>change('siroco'+12,"심연")}><div class="imgt raidItem" slot="1012"><img name="심연의 결집 : 무정형의 힘" src="https://dundam.xyz/img/item/1ba6221619f88691ed8f0c3adba4db7a"/></div></li>
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>영혼의 구속</span></li>
                                                                            <li className={characterData['siroco'+10] == '영혼' ? " on" : ""} onClick={()=>change('siroco'+10,"영혼")}><div class="imgt raidItem" slot="1010"><img name="영혼의 구속 : 지배하는 권능" src="https://dundam.xyz/img/item/332409131f5fcbb418bff7a0a5dd8a13"/></div></li>
                                                                            <li className={characterData['siroco'+11] == '영혼' ? " on" : ""} onClick={()=>change('siroco'+11,"영혼")}><div class="imgt raidItem" slot="1011"><img name="영혼의 구속 : 무거운 업보" src="https://dundam.xyz/img/item/6e1dc4f395dd54d7750ac47b2631518c"/></div></li>
                                                                            <li className={characterData['siroco'+12] == '영혼' ? " on" : ""} onClick={()=>change('siroco'+12,"영혼")}><div class="imgt raidItem" slot="1012"><img name="영혼의 구속 : 거스를 수 없는 봉인" src="https://dundam.xyz/img/item/e0e357b5cce04625dc8b0538bd5c8d01"/></div></li>
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>광휘</span></li>
                                                                            <li className={characterData['siroco'+10] == '광휘' ? " on" : ""} onClick={()=>change('siroco'+10,"광휘")}><div class="imgt raidItem" slot="1010"><img name="광휘 : 고귀한 사명" src="https://dundam.xyz/img/item/078ddee1101ba2dd3f62f7b2841a260d"/></div></li>
                                                                            <li className={characterData['siroco'+11] == '광휘' ? " on" : ""} onClick={()=>change('siroco'+11,"광휘")}><div class="imgt raidItem" slot="1011"><img name="광휘 : 멈출 수 없는 의지" src="https://dundam.xyz/img/item/7dc30234ecc008bc2ddc550ad8fb22a5"/></div></li>
                                                                            <li className={characterData['siroco'+12] == '광휘' ? " on" : ""} onClick={()=>change('siroco'+12,"광휘")}><div class="imgt raidItem" slot="1012"><img name="광휘 : 전해지는 지혜" src="https://dundam.xyz/img/item/ea1a209be12a78a2a6c872e75ab0970c"/></div></li>
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>죄악의 지배</span></li>
                                                                            <li className={characterData['siroco'+10] == '죄악' ? " on" : ""} onClick={()=>change('siroco'+10,"죄악")}><div class="imgt raidItem" slot="1010"><img name="죄악의 지배 : 절망을 뒤덮는 공포" src="https://dundam.xyz/img/item/47b419903a0b8542416e528e1079e29e"/></div></li>
                                                                            <li className={characterData['siroco'+11] == '죄악' ? " on" : ""} onClick={()=>change('siroco'+11,"죄악")}><div class="imgt raidItem" slot="1011"><img name="죄악의 지배 : 절규가 매달리는 공포" src="https://dundam.xyz/img/item/49e7e73cd1ec8573ed0c0ba9c127b350"/></div></li>
                                                                            <li className={characterData['siroco'+12] == '죄악' ? " on" : ""} onClick={()=>change('siroco'+12,"죄악")}><div class="imgt raidItem" slot="1012"><img name="죄악의 지배 : 태초가 선사하는 공포" src="https://dundam.xyz/img/item/5f8b29a66f1c13f6e3a47169af5cc598"/></div></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                <div class="ape2-block ape-bacal">
                                                                    <div class="ape2-no-split">
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>빙화</span></li>
                                                                            <li className={characterData['siroco'+2] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+2,"빙화")}><div class="imgt raidItem" slot="102"><img name="빙화 : 피어난 꽃잎" src="https://dundam.xyz/img/item/240853586bd8ae7296378e1a6b7745d5"/></div></li>
                                                                            <li className={characterData['siroco'+4] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+4,"빙화")}><div class="imgt raidItem" slot="104"><img name="빙화 : 가혹한 한기" src="https://dundam.xyz/img/item/5cdf68192c0f5838a3f3f0c0bef41ab4"/></div></li>
                                                                            <li className={characterData['siroco'+3] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+3,"빙화")}><div class="imgt raidItem" slot="103"><img name="빙화 : 얽히는 서리" src="https://dundam.xyz/img/item/aac73f110fc4ef6963f083cde4fc9850"/></div></li>
                                                                            <li className={characterData['siroco'+6] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+6,"빙화")}><div class="imgt raidItem" slot="106"><img name="빙화 : 살에는 빙설" src="https://dundam.xyz/img/item/1452dd4dde85795b5bcd8e9bd13fcbe6"/></div></li>
                                                                            <li className={characterData['siroco'+5] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+5,"빙화")}><div class="imgt raidItem" slot="105"><img name="빙화 : 펼쳐진 동토" src="https://dundam.xyz/img/item/0fd9a42095299db7c936b07544da52ac"/></div></li>
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>뇌광</span></li>
                                                                            <li className={characterData['siroco'+2] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+2,"뇌광")}><div class="imgt raidItem" slot="102"><img name="뇌광 : 천둥을 품은 용심" src="https://dundam.xyz/img/item/281c2640178dd5cbcc5c58b5f1bdcaa4"/></div></li>
                                                                            <li className={characterData['siroco'+4] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+4,"뇌광")}><div class="imgt raidItem" slot="104"><img name="뇌광 : 낙뢰를 내린 용각" src="https://dundam.xyz/img/item/311f77c82a2c5d84e7ef85dc9599e75e"/></div></li>
                                                                            <li className={characterData['siroco'+3] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+3,"뇌광")}><div class="imgt raidItem" slot="103"><img name="뇌광 : 우레를 감싼 용익" src="https://dundam.xyz/img/item/424a5caa037bcf098cb50b70bc3b3117"/></div></li>
                                                                            <li className={characterData['siroco'+6] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+6,"뇌광")}><div class="imgt raidItem" slot="106"><img name="뇌광 : 번개를 담은 용린" src="https://dundam.xyz/img/item/b4f01254eea851567184b39616d40b6e"/></div></li>
                                                                            <li className={characterData['siroco'+5] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+5,"뇌광")}><div class="imgt raidItem" slot="105"><img name="뇌광 : 벼락을 감은 용조" src="https://dundam.xyz/img/item/a3a08d4776befa1d152d2e4e5cbaa6b0"/></div></li>
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>교감</span></li>
                                                                            <li className={characterData['siroco'+2] == '교감' ? " on" : ""} onClick={()=>change('siroco'+2,"교감")}><div class="imgt raidItem" slot="102"><img name="교감 : 공존하는 생명" src="https://dundam.xyz/img/item/1bb154696f9191e6c0a4abdf74948d41"/></div></li>
                                                                            <li className={characterData['siroco'+4] == '교감' ? " on" : ""} onClick={()=>change('siroco'+4,"교감")}><div class="imgt raidItem" slot="104"><img name="교감 : 보호하는 온기" src="https://dundam.xyz/img/item/127f13f9aaf04f83c78f0134fb1b015d"/></div></li>
                                                                            <li className={characterData['siroco'+3] == '교감' ? " on" : ""} onClick={()=>change('siroco'+3,"교감")}><div class="imgt raidItem" slot="103"><img name="교감 : 감싸안는 햇살" src="https://dundam.xyz/img/item/26b72a48c54e98296ff3df20a52d4c16"/></div></li>
                                                                            <li className={characterData['siroco'+6] == '교감' ? " on" : ""} onClick={()=>change('siroco'+6,"교감")}><div class="imgt raidItem" slot="106"><img name="교감 : 내려앉는 이슬" src="https://dundam.xyz/img/item/33d022dd111c61b85fe68659ab1dffa4"/></div></li>
                                                                            <li className={characterData['siroco'+5] == '교감' ? " on" : ""} onClick={()=>change('siroco'+5,"교감")}><div class="imgt raidItem" slot="105"><img name="교감 : 동행하는 대지" src="https://dundam.xyz/img/item/c0665715c5c4b1d6de9f4ca60f130551"/></div></li>
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>포식</span></li>
                                                                            <li className={characterData['siroco'+2] == '포식' ? " on" : ""} onClick={()=>change('siroco'+2,"포식")}><div class="imgt raidItem" slot="102"><img name="포식 : 헤집어진 상처" src="https://dundam.xyz/img/item/5432b890b5d2f750c67951a8817c4823"/></div></li>
                                                                            <li className={characterData['siroco'+4] == '포식' ? " on" : ""} onClick={()=>change('siroco'+4,"포식")}><div class="imgt raidItem" slot="104"><img name="포식 : 사그라진 고동" src="https://dundam.xyz/img/item/33e8f7122831fa045f6e84313da64e31"/></div></li>
                                                                            <li className={characterData['siroco'+3] == '포식' ? " on" : ""} onClick={()=>change('siroco'+3,"포식")}><div class="imgt raidItem" slot="103"><img name="포식 : 자비없는 습격" src="https://dundam.xyz/img/item/18ddc7fb78f1230f14ee4654cc22cc20"/></div></li>
                                                                            <li className={characterData['siroco'+6] == '포식' ? " on" : ""} onClick={()=>change('siroco'+6,"포식")}><div class="imgt raidItem" slot="106"><img name="포식 : 조여오는 올가미" src="https://dundam.xyz/img/item/21c05b45eb65c07383b0cad551e0ae47"/></div></li>
                                                                            <li className={characterData['siroco'+5] == '포식' ? " on" : ""} onClick={()=>change('siroco'+5,"포식")}><div class="imgt raidItem" slot="105"><img name="포식 : 추적하는 사냥꾼" src="https://dundam.xyz/img/item/06a75e0f3cf3b8ad358289b3faf79303"/></div></li>
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>격노</span></li>
                                                                            <li className={characterData['siroco'+2] == '격노' ? " on" : ""} onClick={()=>change('siroco'+2,"격노")}><div class="imgt raidItem" slot="102"><img name="격노 : 최후에 삼킨 숨결" src="https://dundam.xyz/img/item/113436105b0365c4ee0ed0c542f30710"/></div></li>
                                                                            <li className={characterData['siroco'+4] == '격노' ? " on" : ""} onClick={()=>change('siroco'+4,"격노")}><div class="imgt raidItem" slot="104"><img name="격노 : 세상을 탐한 욕구" src="https://dundam.xyz/img/item/c11bb733312c6612d6fd2cd9196862c8"/></div></li>
                                                                            <li className={characterData['siroco'+3] == '격노' ? " on" : ""} onClick={()=>change('siroco'+3,"격노")}><div class="imgt raidItem" slot="103"><img name="격노 : 파괴를 행한 맹목" src="https://dundam.xyz/img/item/054774144a5aab6828500305f71425ec"/></div></li>
                                                                            <li className={characterData['siroco'+6] == '격노' ? " on" : ""} onClick={()=>change('siroco'+6,"격노")}><div class="imgt raidItem" slot="106"><img name="격노 : 무한히 증적한 식탐" src="https://dundam.xyz/img/item/5b3976c605e6d96878a9b94ad5fbd449"/></div></li>
                                                                            <li className={characterData['siroco'+5] == '격노' ? " on" : ""} onClick={()=>change('siroco'+5,"격노")}><div class="imgt raidItem" slot="105"><img name="격노 : 방향을 잃은 갈망" src="https://dundam.xyz/img/item/f2c4246d0b59619eea5d03bb5b76cc91"/></div></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="tab__content" name="110제">
                                                            <div class="cus_elst">
                                                            {/*
                                                                <div class="cus_eltit_v2 e-m-set">
                                                                    <span>110제</span>
                                                                </div>
                                                                            */}
                                                                <div class="cus_ellist">

                                                                    <div class="ems-sel-list">
                                                                        <div class="eslist-con-hug">
                                                                            <div class="eslist-con">
                                                                                <ul>
                                                                                    <li class={selectFusionType == 0 ? "is-active" :""} onClick ={()=>{setSelectFusionType(0) }}>
                                                                                        <span>기존</span>
                                                                                    </li>
                                                                                    <li class={selectFusionType == 1 ? "is-active" :""} onClick ={()=>{setSelectFusionType(1) }}>
                                                                                        <span>융합석</span>
                                                                                    </li>
                                                                                    <li class={selectFusionType == 2 ? "is-active" :""} onClick ={()=>{setSelectFusionType(2) }}>
                                                                                        <span>융합석 각인</span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                                {
                                                                    //신규 융합석 
                                                                    selectFusionType == 1 ? 
                                                                    <>
                                                                    <div class="ape2-flex">

                                                                        <div class="ape2-split">
                                                                            <ul class="setList">
                                                                                {
                                                                                    fusionStoneList[1].map((a,i)=>
                                                                                        {
                                                                                            return(
                                                                                                <>
                                                                                                    <ul>
                                                                                                        <li class="setName cus_etit">
                                                                                                            <span></span>
                                                                                                        </li>
                                                                                                        {fusionStoneList[2][i] != null ? <FusionStone slot='8' name={fusionStoneList[2][i]} image={lv105ItemId[fusionStoneList[2][i]]} /> : <li class="emptyItem"></li> }
                                                                                                        <FusionStone slot='7' name={fusionStoneList[1][i]} image={lv105ItemId[fusionStoneList[1][i]]} />
                                                                                                        <FusionStone slot='9' name={fusionStoneList[0][i]} image={lv105ItemId[fusionStoneList[0][i]]} />
                                                                                                       
                                                                                                     </ul>
                                                                                                </>
                                                                                            )
                                                                                        })
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                        <div class="ape2-split">

                                                                            <ul class="setList">
                                                                                
                                                                                {
                                                                                    fusionStoneList[3].map((a,i)=>
                                                                                    {
                                                                                        return(
                                                                                            <>
                                                                                                <ul>
                                                                                                    <li class="setName cus_etit">
                                                                                                        <span></span>
                                                                                                    </li>
                                                                                                    <FusionStone slot='10' name={fusionStoneList[3][i]} image={lv105ItemId[fusionStoneList[3][i]]} />
                                                                                                    <FusionStone slot='11' name={fusionStoneList[4][i]} image={lv105ItemId[fusionStoneList[4][i]]} />
                                                                                                    <FusionStone slot='12' name={fusionStoneList[5][i]} image={lv105ItemId[fusionStoneList[5][i]]} />
                                                                                                </ul>
                                                                                    
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                               
                                                                            
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    {characterData['siroco10'] == '영원의 조각 - 보조장비' ?  <LegendStone slot='10'/> : <></>}
                                                                    {characterData['siroco11'] == '영원의 조각 - 마법석' ?  <LegendStone slot='11'/> : <></>}
                                                                    {characterData['siroco12'] == '영원의 조각 - 귀걸이' ?  <LegendStone slot='12'/> : <></>}
                                                                    </>
                                                                    :
                                                                    <>
                                                                    </>
                                                                }
                                                                
                                                                
                                                                {
                                                                    //기존 융합석
                                                                    
                                                                    //기존 융합석
                                                                    selectFusionType == 0 ? 
                                                                    <>
                                                                    <div class="ape-bacal ape2-block ape-ast-hover-fix">
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>열화</span></li>
                                                                            <FusionEquip slot='2' setName="열화" name='열화 : 흔들린 불꽃의 이성' image="8c61142a45d62fe0d7026dbcac044810" />
                                                                            <FusionEquip slot='4' setName="열화" name='열화 : 엉겨붙은 의지' image="6bab8cc6a330eb195775b22765a9c319" />
                                                                            <FusionEquip slot='3' setName="열화" name='열화 : 밀려오는 불길' image="d61a06e03d9d71e7e796feb0979873fe" />
                                                                            <FusionEquip slot='6' setName="열화" name='열화 : 끝없는 타오름' image="3d70c6e7769d3f0279393bb3af1af587" />
                                                                            <FusionEquip slot='5' setName="열화" name='열화 : 내디딘 마지막 발걸음' image="b0f88a406788da99debe08ec96f0dbd8" />
                                                                            </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>설경</span></li>
                                                                            <FusionEquip slot='2' setName="설경" name='설경 : 스며드는 한기' image="d8f457479508ba3159430e137d99f52f" />
                                                                            <FusionEquip slot='4' setName="설경" name='설경 : 고요한 단말마' image="d52deb46528d91d333306bac701226ec" />
                                                                            <FusionEquip slot='3' setName="설경" name='설경 : 얼어붙는 감각' image="dc62f7c8c83de81a91b6fe07e64a0a84" />
                                                                            <FusionEquip slot='6' setName="설경" name='설경 : 정할 수 없는 운명' image="eba306ed78063847b3943dc44b1d40e9" />
                                                                            <FusionEquip slot='5' setName="설경" name='설경 : 남겨지지 않는 발자국' image="d82f1b5562bfca6f5003640175742d0f" />
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>대류</span></li>
                                                                            <FusionEquip slot='2' setName="대류" name='대류 : 몰아치기 전의 고요함' image="44e90b09a8bbab556f4958feb6168663" />
                                                                            <FusionEquip slot='4' setName="대류" name='대류 : 휩쓸려 지나간 흔적' image="bd35b37823d4109d3d62fea1b25fad5c" />
                                                                            <FusionEquip slot='3' setName="대류" name='대류 : 변덕스러운 흐름' image="0fb48a898584f41764bbd49b14f88d87" />
                                                                            <FusionEquip slot='6' setName="대류" name='대류 : 제어할 수 없는 돌풍' image="3c8e697eee47723378749a502136174f" />
                                                                            <FusionEquip slot='5' setName="대류" name='대류 : 주어진 바람의 무게' image="be92b91c14836f2d5e0c5f899793a07d" />
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>우레</span></li>
                                                                            <FusionEquip slot='2' setName="우레" name='우레 : 대지를 뒤덮는 암운' image="9037932df635f5cc5a6fc7f036e0d569" />
                                                                            <FusionEquip slot='4' setName="우레" name='우레 : 뒤따르는 고동' image="a50ec36485ce4d6b1fc569cca0710113" />
                                                                            <FusionEquip slot='3' setName="우레" name='우레 : 곤두서는 감각' image="2c786522e7909a0a3addd7aa129bcc41" />
                                                                            <FusionEquip slot='6' setName="우레" name='우레 : 하늘에 새겨진 흉터' image="10e021b102c7d24e0fb75a7fd1e2cbd7" />
                                                                            <FusionEquip slot='5' setName="우레" name='우레 : 구름을 향한 발돋움' image="64670f9c086ab784afb049fc0e5cbf23" />
                                                                        </ul>
                                                                        <ul class="setList">
                                                                            <li class="setName cus_etit"><span>대지</span></li>
                                                                            <FusionEquip slot='2' setName="대지" name='대지 : 생명이 돋아나는 땅' image="cfb10e82f068a4afa752d8e15fb1349c" />
                                                                            <FusionEquip slot='4' setName="대지" name='대지 : 죽음이 내려앉는 시간' image="3b0a259d06f696b63f1ce3eaca4abc52" />
                                                                            <FusionEquip slot='3' setName="대지" name='대지 : 피어나는 계절' image="bcafa147938ebfc88bea72187b0d8fa7" />
                                                                            <FusionEquip slot='6' setName="대지" name='대지 : 삶과 죽음의 교차' image="84d9918a4fb186deff8fe8678e236b60" />
                                                                            <FusionEquip slot='5' setName="대지" name='대지 : 바스러지는 발걸음' image="6bbbaaa2dc770708ac755462d33c2c18" />
                                                                        </ul>
                                                                    
                                                                    </div>
                                                                    <div class="ape2-flex ape2-block">

                                                                        <div class="ape2-split">
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>仙진룡</span></li>
                                                                                <li className={characterData['siroco'+8] == '진룡仙' ? " on" : ""} onClick={()=>change('siroco'+8,"진룡仙")}><div class="imgt raidItem" slot="108"><img name="진룡 : 압도하는 힘의 원천" src="https://dundam.xyz/img/item/cc64795c07f57cb9e52967063276f795"/></div></li>
                                                                                <li className={characterData['siroco'+7] == '진룡仙' ? " on" : ""} onClick={()=>change('siroco'+7,"진룡仙")}><div class="imgt raidItem" slot="107"><img name="진룡 : 잔혹한 용의 발톱" src="https://dundam.xyz/img/item/568018f4d9ed013f352d412331ab67ba"/></div></li>
                                                                                <li className={characterData['siroco'+9] == '진룡仙' ? " on" : ""} onClick={()=>change('siroco'+9,"진룡仙")}><div class="imgt raidItem" slot="109"><img name="진룡 : 무거운 철의 투지" src="https://dundam.xyz/img/item/6255f92b7b56a7ddd76f566a47ada800"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>仙금룡</span></li>
                                                                                <li className={characterData['siroco'+8] == '금룡仙' ? " on" : ""} onClick={()=>change('siroco'+8,"금룡仙")}><div class="imgt raidItem" slot="108"><img name="금룡 : 화려한 힘의 기원" src="https://dundam.xyz/img/item/5285d14664a9aa41559ca68f440c3909"/></div></li>
                                                                                <li className={characterData['siroco'+7] == '금룡仙' ? " on" : ""} onClick={()=>change('siroco'+7,"금룡仙")}><div class="imgt raidItem" slot="107"><img name="금룡 : 찬란한 용의 비늘" src="https://dundam.xyz/img/item/eeb469cdd183e41318837d2dbd98dd42"/></div></li>
                                                                                <li className={characterData['siroco'+9] == '금룡仙' ? " on" : ""} onClick={()=>change('siroco'+9,"금룡仙")}><div class="imgt raidItem" slot="109"><img name="금룡 : 무한한 빛의 결계" src="https://dundam.xyz/img/item/52688ab6a97da9368e86343d0a3ac4a5"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>仙화룡</span></li>
                                                                                <li className={characterData['siroco'+8] == '화룡仙' ? " on" : ""} onClick={()=>change('siroco'+8,"화룡仙")}><div class="imgt raidItem" slot="108"><img name="화룡 : 폭발하는 힘의 근원" src="https://dundam.xyz/img/item/0e79738a52d19e87cf910fc16537887b"/></div></li>
                                                                                <li className={characterData['siroco'+7] == '화룡仙' ? " on" : ""} onClick={()=>change('siroco'+7,"화룡仙")}><div class="imgt raidItem" slot="107"><img name="화룡 : 끓어오르는 용의 숨결" src="https://dundam.xyz/img/item/73f46c965770a5680576ad52219da4a5"/></div></li>
                                                                                <li className={characterData['siroco'+9] == '화룡仙' ? " on" : ""} onClick={()=>change('siroco'+9,"화룡仙")}><div class="imgt raidItem" slot="109"><img name="화룡 : 타오르는 불의 분노" src="https://dundam.xyz/img/item/6f388dd09d7149044abfd92fed63b4a6"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>仙흑룡</span></li>
                                                                                <li className={characterData['siroco'+8] == '흑룡仙' ? " on" : ""} onClick={()=>change('siroco'+8,"흑룡仙")}><div class="imgt raidItem" slot="108"><img name="흑룡 : 어두운 힘의 근본" src="https://dundam.xyz/img/item/db3a4579b25e7dbdca33f56195ce259f"/></div></li>
                                                                                <li className={characterData['siroco'+7] == '흑룡仙' ? " on" : ""} onClick={()=>change('siroco'+7,"흑룡仙")}><div class="imgt raidItem" slot="107"><img name="흑룡 : 주시하는 용의 눈동자" src="https://dundam.xyz/img/item/27843216918f310d010f1014661c81bd"/></div></li>
                                                                                <li className={characterData['siroco'+9] == '흑룡仙' ? " on" : ""} onClick={()=>change('siroco'+9,"흑룡仙")}><div class="imgt raidItem" slot="109"><img name="흑룡 : 끝없는 암흑의 굴레" src="https://dundam.xyz/img/item/1fd082cd996a9eb02645be09c5e89d41"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>仙천계</span></li>
                                                                                <li className={characterData['siroco'+8] == '천계仙' ? " on" : ""} onClick={()=>change('siroco'+8,"천계仙")}><div class="imgt raidItem" slot="108"><img name="천계 연합군 : 꺾이지 않는 의지" src="https://dundam.xyz/img/item/cb41d2e497f1a7ac1ae2711670da9861"/></div></li>
                                                                                <li className={characterData['siroco'+7] == '천계仙' ? " on" : ""} onClick={()=>change('siroco'+7,"천계仙")}><div class="imgt raidItem" slot="107"><img name="천계 연합군 : 모든 이를 위한 기도" src="https://dundam.xyz/img/item/3b62a91abc21df1a2609af53cb15d432"/></div></li>
                                                                                <li className={characterData['siroco'+9] == '천계仙' ? " on" : ""} onClick={()=>change('siroco'+9,"천계仙")}><div class="imgt raidItem" slot="109"><img name="천계 연합군 : 밝게 빛나는 희망" src="https://dundam.xyz/img/item/f0683ab71a21f9166ccb3423fa0a928c"/></div></li>
                                                                                
                                                                            </ul>

                                                                            {/* 110제  */}
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>괴이</span></li>
                                                                                <li className={characterData['siroco'+8] == '괴이' ? " on" : ""} onClick={()=>change('siroco'+8,"괴이")}><div class="imgt raidItem" slot="108"><img name="괴이 : 휘감는 영면" src="https://dundam.xyz/img/item/85c47c44067632835017093d6d96ade9"/></div></li>
                                                                                <li className={characterData['siroco'+7] == '괴이' ? " on" : ""} onClick={()=>change('siroco'+7,"괴이")}><div class="imgt raidItem" slot="107"><img name="괴이 : 움켜쥔 무위" src="https://dundam.xyz/img/item/b4731f24e006e2cadff17e84421089bf"/></div></li>
                                                                                <li className={characterData['siroco'+9] == '괴이' ? " on" : ""} onClick={()=>change('siroco'+9,"괴이")}><div class="imgt raidItem" slot="109"><img name="괴이 : 돋아난 심연" src="https://dundam.xyz/img/item/716addc1e4a89443aab7b9f71ee759f2"/></div></li>
                                                                                
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>불신</span></li>
                                                                                <li className={characterData['siroco'+8] == '불신' ? " on" : ""} onClick={()=>change('siroco'+8,"불신")}><div class="imgt raidItem" slot="108"><img name="불신 : 빛바랜 믿음" src="https://dundam.xyz/img/item/48f2e55ed7075156e61c27f04ecde350"/></div></li>
                                                                                <li className={characterData['siroco'+7] == '불신' ? " on" : ""} onClick={()=>change('siroco'+7,"불신")}><div class="imgt raidItem" slot="107"><img name="불신 : 비틀린 진심" src="https://dundam.xyz/img/item/4849dabc54d2e5336b8a9048e8e328c1"/></div></li>
                                                                                <li className={characterData['siroco'+9] == '불신' ? " on" : ""} onClick={()=>change('siroco'+9,"불신")}><div class="imgt raidItem" slot="109"><img name="불신 : 망각한 은혜" src="https://dundam.xyz/img/item/07b69ea3a8a7f6ca40b5daf7304f4421"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>투지</span></li>
                                                                                <li className={characterData['siroco'+8] == '투지' ? " on" : ""} onClick={()=>change('siroco'+8,"투지")}><div class="imgt raidItem" slot="108"><img name="투지 : 연마된 노력" src="https://dundam.xyz/img/item/5fd08201e842e5eb26a8f94a6dab9cb7"/></div></li>
                                                                                <li className={characterData['siroco'+7] == '투지' ? " on" : ""} onClick={()=>change('siroco'+7,"투지")}><div class="imgt raidItem" slot="107"><img name="투지 : 담대한 용기" src="https://dundam.xyz/img/item/9c122f8552e47daec3bdefaa91b97090"/></div></li>
                                                                                <li className={characterData['siroco'+9] == '투지' ? " on" : ""} onClick={()=>change('siroco'+9,"투지")}><div class="imgt raidItem" slot="109"><img name="투지 : 영광의 증표" src="https://dundam.xyz/img/item/a17effef25c4e6caee0a002f6183bb5c"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">

                                                                                <li class="setName cus_etit"><span>고도</span></li>
                                                                                <li className={characterData['siroco'+8] == '고도' ? " on" : ""} onClick={()=>change('siroco'+8,"고도")}><div class="imgt raidItem" slot="108"><img name="고도 : 이어지는 경계"     src="https://dundam.xyz/img/item/0a87e9d6ae64ba5a42a6d5bd2f7ad668"/></div></li>
                                                                                <li className={characterData['siroco'+7] == '고도' ? " on" : ""} onClick={()=>change('siroco'+7,"고도")}><div class="imgt raidItem" slot="107"><img name="고도 : 남은 자의 결연"    src="https://dundam.xyz/img/item/c8472f600fbe7031ebd5aaa0b51b8ab5"/></div></li>
                                                                                <li className={characterData['siroco'+9] == '고도' ? " on" : ""} onClick={()=>change('siroco'+9,"고도")}><div class="imgt raidItem" slot="109"><img name="고도 : 울림 속의 묵념"    src="https://dundam.xyz/img/item/f8cba626750c5467ddd07d6da553d293"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>감시자</span></li>
                                                                                <li className={characterData['siroco'+8] == '감시' ? " on" : ""} onClick={()=>change('siroco'+8,"감시")}><div class="imgt raidItem" slot="108"><img name="감시자 : 수호를 향한 신념"    src="https://dundam.xyz/img/item/a3d4edcaa3d986a041ffae635face6a8"/></div></li>
                                                                                <li className={characterData['siroco'+7] == '감시' ? " on" : ""} onClick={()=>change('siroco'+7,"감시")}><div class="imgt raidItem" slot="107"><img name="감시자 : 선택받은 기억"       src="https://dundam.xyz/img/item/4d825cd7fd245aa231bbfb1fc2a72ef5"/></div></li>
                                                                                <li className={characterData['siroco'+9] == '감시' ? " on" : ""} onClick={()=>change('siroco'+9,"감시")}><div class="imgt raidItem" slot="109"><img name="감시자 : 간직해 온 이상"      src="https://dundam.xyz/img/item/f29e21bd74e3ef1aea6957252ab579aa"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>해방</span></li>
                                                                                <li className={characterData['siroco'+8] == '해방' ? " on" : ""} onClick={()=>change('siroco'+8,"해방")}><div class="imgt raidItem" slot="108"><img name="해방 : 불신의 씨앗"   src="https://dundam.xyz/img/item/ecae7777222a69b076d418caf0c49b26"/></div></li>
                                                                                <li className={characterData['siroco'+7] == '해방' ? " on" : ""} onClick={()=>change('siroco'+7,"해방")}><div class="imgt raidItem" slot="107"><img name="해방 : 시작되는 멸망" src="https://dundam.xyz/img/item/ceaa203630116b7c0b8f4cad5ae08986"/></div></li>
                                                                                <li className={characterData['siroco'+9] == '해방' ? " on" : ""} onClick={()=>change('siroco'+9,"해방")}><div class="imgt raidItem" slot="109"><img name="해방 : 비상하는 악몽" src="https://dundam.xyz/img/item/ea4821ac132a0ebe1722294bbb8f93b8"/></div></li>
                                                                            </ul>
                                                                        </div>

                                                                        <div class="ape2-split">

                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit">
                                                                                    <span>仙슈페리어</span>
                                                                                </li>
                                                                                
                                                                                <li className={characterData['siroco'+10] == '슈페仙' ? " on" : ""} onClick={()=>change('siroco'+10,"슈페仙")}>
                                                                                    <div class="imgt raidItem" slot="1010">
                                                                                        <img name="슈페리어 : 오리진 오브 더 마이어" src="https://dundam.xyz/img/item/a6baa41de89c3a9bf280b65ca5b24b58"/>
                                                                                    </div>

                                                                                    {/* <div class="cus-addbox">
                                                                                        <div class="abtit"><img src="img/item/1ccac9965353062c04b7da144244b544"></img><span class="abt_name">불가침의 영역 - 벨트</span></div>

                                                                                        <div class="abop">
                                                                                        <div class="option"><span class="op_gold">피해 증가</span> + 6858 공격 시 300px 내 랜덤한 위치에 번개를 시전하여 모든 적과 자신에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)
                                                                                        - 상태 이상 내성 무시
                                                                                        - 스킬, 소모품으로 해제 불가
                                                                                        번개는 적의 방어력을 일부 적용받고 총 피해 증가 수치의 84%만큼 피해를 줌

                                                                                        자신이 감전 상태일 때 아래의 효과 적용
                                                                                        - 스킬 공격력 17% 증가
                                                                                        - 공격 속도 +20%
                                                                                        - 캐스팅 속도 +30%
                                                                                        - 이동 속도 +20%

                                                                                        500px 내 감전 상태인 대상 하나마다 피해 증가 +712 (최대 5중첩)

                                                                                        물리/마법 크리티컬 히트 +7%

                                                                                        모든 상태 이상 내성 -10%</div>
                                                                                        </div>
                                                                                    </div> */}
                                                                                </li>
                                                                                <li className={characterData['siroco'+11] == '슈페仙' ? " on" : ""} onClick={()=>change('siroco'+11,"슈페仙")}>
                                                                                    <div class="imgt raidItem" slot="1011">
                                                                                        <img name="슈페리어 : 매직 서클로" src="https://dundam.xyz/img/item/323e201088055e1ed622be84b7479103"/>
                                                                                    </div>
                                                                                    {/* <div class="cus-addbox">
                                                                                        <div class="abtit"><img src="img/item/1ccac9965353062c04b7da144244b544"></img><span class="abt_name">불가침의 영역 - 벨트</span></div>

                                                                                        <div class="abop">
                                                                                        <div class="option"><span class="op_gold">피해 증가</span> + 6858 공격 시 300px 내 랜덤한 위치에 번개를 시전하여 모든 적과 자신에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)
                                                                                        - 상태 이상 내성 무시
                                                                                        - 스킬, 소모품으로 해제 불가
                                                                                        번개는 적의 방어력을 일부 적용받고 총 피해 증가 수치의 84%만큼 피해를 줌

                                                                                        자신이 감전 상태일 때 아래의 효과 적용
                                                                                        - 스킬 공격력 17% 증가
                                                                                        - 공격 속도 +20%
                                                                                        - 캐스팅 속도 +30%
                                                                                        - 이동 속도 +20%

                                                                                        500px 내 감전 상태인 대상 하나마다 피해 증가 +712 (최대 5중첩)

                                                                                        물리/마법 크리티컬 히트 +7%

                                                                                        모든 상태 이상 내성 -10%</div>
                                                                                        </div>
                                                                                    </div> */}
                                                                                </li>
                                                                                <li className={characterData['siroco'+12] == '슈페仙' ? " on" : ""} onClick={()=>change('siroco'+12,"슈페仙")}>
                                                                                    <div class="imgt raidItem" slot="1012">
                                                                                        <img name="슈페리어 : 코스믹 이어링" src="https://dundam.xyz/img/item/70558b17d3d49fc8599f8e0d751bce9d"/>
                                                                                    </div>
                                                                                    {/* <div class="cus-addbox">
                                                                                        <div class="abtit"><img src="img/item/1ccac9965353062c04b7da144244b544"></img><span class="abt_name">불가침의 영역 - 벨트</span></div>

                                                                                        <div class="abop">
                                                                                        <div class="option"><span class="op_gold">피해 증가</span> + 6858 공격 시 300px 내 랜덤한 위치에 번개를 시전하여 모든 적과 자신에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)
                                                                                        - 상태 이상 내성 무시
                                                                                        - 스킬, 소모품으로 해제 불가
                                                                                        번개는 적의 방어력을 일부 적용받고 총 피해 증가 수치의 84%만큼 피해를 줌

                                                                                        자신이 감전 상태일 때 아래의 효과 적용
                                                                                        - 스킬 공격력 17% 증가
                                                                                        - 공격 속도 +20%
                                                                                        - 캐스팅 속도 +30%
                                                                                        - 이동 속도 +20%

                                                                                        500px 내 감전 상태인 대상 하나마다 피해 증가 +712 (최대 5중첩)

                                                                                        물리/마법 크리티컬 히트 +7%

                                                                                        모든 상태 이상 내성 -10%</div>
                                                                                        </div>
                                                                                    </div> */}
                                                                                    
                                                                                </li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>仙심연의 결집</span></li>
                                                                                <li className={characterData['siroco'+10] == '심연仙' ? " on" : ""} onClick={()=>change('siroco'+10,"심연仙")}><div class="imgt raidItem" slot="1010"><img name="심연의 결집 : 정의되지 않는 존재" src="https://dundam.xyz/img/item/56422d257499f7c60c7dad02d0f43db2"/></div></li>
                                                                                <li className={characterData['siroco'+11] == '심연仙' ? " on" : ""} onClick={()=>change('siroco'+11,"심연仙")}><div class="imgt raidItem" slot="1011"><img name="심연의 결집 : 무한한 수축" src="https://dundam.xyz/img/item/2950e00eced48e19bf4c161b3bc7df7e"/></div></li>
                                                                                <li className={characterData['siroco'+12] == '심연仙' ? " on" : ""} onClick={()=>change('siroco'+12,"심연仙")}><div class="imgt raidItem" slot="1012"><img name="심연의 결집 : 무정형의 힘" src="https://dundam.xyz/img/item/1ba6221619f88691ed8f0c3adba4db7a"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>仙영혼의 구속</span></li>
                                                                                <li className={characterData['siroco'+10] == '영혼仙' ? " on" : ""} onClick={()=>change('siroco'+10,"영혼仙")}><div class="imgt raidItem" slot="1010"><img name="영혼의 구속 : 지배하는 권능" src="https://dundam.xyz/img/item/332409131f5fcbb418bff7a0a5dd8a13"/></div></li>
                                                                                <li className={characterData['siroco'+11] == '영혼仙' ? " on" : ""} onClick={()=>change('siroco'+11,"영혼仙")}><div class="imgt raidItem" slot="1011"><img name="영혼의 구속 : 무거운 업보" src="https://dundam.xyz/img/item/6e1dc4f395dd54d7750ac47b2631518c"/></div></li>
                                                                                <li className={characterData['siroco'+12] == '영혼仙' ? " on" : ""} onClick={()=>change('siroco'+12,"영혼仙")}><div class="imgt raidItem" slot="1012"><img name="영혼의 구속 : 거스를 수 없는 봉인" src="https://dundam.xyz/img/item/e0e357b5cce04625dc8b0538bd5c8d01"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>仙광휘</span></li>
                                                                                <li className={characterData['siroco'+10] == '광휘仙' ? " on" : ""} onClick={()=>change('siroco'+10,"광휘仙")}><div class="imgt raidItem" slot="1010"><img name="광휘 : 고귀한 사명" src="https://dundam.xyz/img/item/078ddee1101ba2dd3f62f7b2841a260d"/></div></li>
                                                                                <li className={characterData['siroco'+11] == '광휘仙' ? " on" : ""} onClick={()=>change('siroco'+11,"광휘仙")}><div class="imgt raidItem" slot="1011"><img name="광휘 : 멈출 수 없는 의지" src="https://dundam.xyz/img/item/7dc30234ecc008bc2ddc550ad8fb22a5"/></div></li>
                                                                                <li className={characterData['siroco'+12] == '광휘仙' ? " on" : ""} onClick={()=>change('siroco'+12,"광휘仙")}><div class="imgt raidItem" slot="1012"><img name="광휘 : 전해지는 지혜" src="https://dundam.xyz/img/item/ea1a209be12a78a2a6c872e75ab0970c"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>仙죄악의 지배</span></li>
                                                                                <li className={characterData['siroco'+10] == '죄악仙' ? " on" : ""} onClick={()=>change('siroco'+10,"죄악仙")}><div class="imgt raidItem" slot="1010"><img name="죄악의 지배 : 절망을 뒤덮는 공포" src="https://dundam.xyz/img/item/47b419903a0b8542416e528e1079e29e"/></div></li>
                                                                                <li className={characterData['siroco'+11] == '죄악仙' ? " on" : ""} onClick={()=>change('siroco'+11,"죄악仙")}><div class="imgt raidItem" slot="1011"><img name="죄악의 지배 : 절규가 매달리는 공포" src="https://dundam.xyz/img/item/49e7e73cd1ec8573ed0c0ba9c127b350"/></div></li>
                                                                                <li className={characterData['siroco'+12] == '죄악仙' ? " on" : ""} onClick={()=>change('siroco'+12,"죄악仙")}><div class="imgt raidItem" slot="1012"><img name="죄악의 지배 : 태초가 선사하는 공포" src="https://dundam.xyz/img/item/5f8b29a66f1c13f6e3a47169af5cc598"/></div></li>
                                                                            </ul>

                                                                            {/* 110제  */}
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>괴이</span></li>
                                                                                <li className={characterData['siroco'+10] == '괴이' ? " on" : ""} onClick={()=>change('siroco'+10,"괴이")}><div class="imgt raidItem" slot="1010"><img name="괴이 : 얽히는 추억" src="https://dundam.xyz/img/item/899743caa109f3bcff2a273f07517d3a"/></div></li>
                                                                                <li className={characterData['siroco'+11] == '괴이' ? " on" : ""} onClick={()=>change('siroco'+11,"괴이")}><div class="imgt raidItem" slot="1011"><img name="괴이 : 확산되는 파문" src="https://dundam.xyz/img/item/f01aa235ce5faed6cd44636fe6b69f6c"/></div></li>
                                                                                <li className={characterData['siroco'+12] == '괴이' ? " on" : ""} onClick={()=>change('siroco'+12,"괴이")}><div class="imgt raidItem" slot="1012"><img name="괴이 : 각인된 포효" src="https://dundam.xyz/img/item/fbbfd5544c70159de9d83c3c51e7cc88"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>불신</span></li>
                                                                                <li className={characterData['siroco'+10] == '불신' ? " on" : ""} onClick={()=>change('siroco'+10,"불신")}><div class="imgt raidItem" slot="1010"><img name="불신 : 버려진 신의" src="https://dundam.xyz/img/item/5d808ff147134e463ef9b8b1bcd1bba4"/></div></li>
                                                                                <li className={characterData['siroco'+11] == '불신' ? " on" : ""} onClick={()=>change('siroco'+11,"불신")}><div class="imgt raidItem" slot="1011"><img name="불신 : 독을 품은 미소" src="https://dundam.xyz/img/item/28e3dd012353304ca2e6017984546c5f"/></div></li>
                                                                                <li className={characterData['siroco'+12] == '불신' ? " on" : ""} onClick={()=>change('siroco'+12,"불신")}><div class="imgt raidItem" slot="1012"><img name="불신 : 길을 잃은 언행" src="https://dundam.xyz/img/item/600e4ac41839e7513bea8e93a91fecf1"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>투지</span></li>
                                                                                <li className={characterData['siroco'+10] == '투지' ? " on" : ""} onClick={()=>change('siroco'+10,"투지")}><div class="imgt raidItem" slot="1010"><img name="투지 : 성장을 향한 여정" src="https://dundam.xyz/img/item/259526d69a41cbe60eb692cc57ed194e"/></div></li>
                                                                                <li className={characterData['siroco'+11] == '투지' ? " on" : ""} onClick={()=>change('siroco'+11,"투지")}><div class="imgt raidItem" slot="1011"><img name="투지 : 빛나는 투혼" src="https://dundam.xyz/img/item/dd30b01ee7eb145353ad8a618dba0f18"/></div></li>
                                                                                <li className={characterData['siroco'+12] == '투지' ? " on" : ""} onClick={()=>change('siroco'+12,"투지")}><div class="imgt raidItem" slot="1012"><img name="투지 : 변화의 바람" src="https://dundam.xyz/img/item/1f3d0b1aa0426ca82905a0c24e2c5d57"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>고도</span></li>
                                                                                <li className={characterData['siroco'+10] == '고도' ? " on" : ""} onClick={()=>change('siroco'+10,"고도")}><div class="imgt raidItem" slot="1010"><img name="고도 : 놓지 않는 이성" src="https://dundam.xyz/img/item/3dffaa62c8e0115c070e3cd4ea3ee9a1"/></div></li>
                                                                                <li className={characterData['siroco'+11] == '고도' ? " on" : ""} onClick={()=>change('siroco'+11,"고도")}><div class="imgt raidItem" slot="1011"><img name="고도 : 본색을 숨긴 공포" src="https://dundam.xyz/img/item/e55d759e84300f278ed0e399e0523d38"/></div></li>
                                                                                <li className={characterData['siroco'+12] == '고도' ? " on" : ""} onClick={()=>change('siroco'+12,"고도")}><div class="imgt raidItem" slot="1012"><img name="고도 : 깊어지는 어둠" src="https://dundam.xyz/img/item/d02308e799a7843cbdc8c7ab284f2fa5"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>감시자</span></li>
                                                                                <li className={characterData['siroco'+10] == '감시' ? " on" : ""} onClick={()=>change('siroco'+10,"감시")}><div class="imgt raidItem" slot="1010"><img name="감시자 : 추앙받던 은총" src="https://dundam.xyz/img/item/66be17fd1be2bbad7a54b1b534de26cc"/></div></li>
                                                                                <li className={characterData['siroco'+11] == '감시' ? " on" : ""} onClick={()=>change('siroco'+11,"감시")}><div class="imgt raidItem" slot="1011"><img name="감시자 : 자각하는 책임" src="https://dundam.xyz/img/item/bb8342997cf4dc376ea72c4e29f60af6"/></div></li>
                                                                                <li className={characterData['siroco'+12] == '감시' ? " on" : ""} onClick={()=>change('siroco'+12,"감시")}><div class="imgt raidItem" slot="1012"><img name="감시자 : 고뇌하는 의지" src="https://dundam.xyz/img/item/26a2107ed0092ce8f6468492c87fbd43"/></div></li>
                                                                            </ul>
                                                                            <ul class="setList">
                                                                                <li class="setName cus_etit"><span>해방</span></li>
                                                                                <li className={characterData['siroco'+10] == '해방' ? " on" : ""} onClick={()=>change('siroco'+10,"해방")}><div class="imgt raidItem" slot="1010"><img name="해방 : 황홀한 배신" src="https://dundam.xyz/img/item/3eaa2b7272d89b12e860251af0002e76"/></div></li>
                                                                                <li className={characterData['siroco'+11] == '해방' ? " on" : ""} onClick={()=>change('siroco'+11,"해방")}><div class="imgt raidItem" slot="1011"><img name="해방 : 찬란한 종말" src="https://dundam.xyz/img/item/9fe8c007456d2ffda625e739aa3b09d3"/></div></li>
                                                                                <li className={characterData['siroco'+12] == '해방' ? " on" : ""} onClick={()=>change('siroco'+12,"해방")}>
                                                                                    <div class="imgt raidItem" slot="1012">
                                                                                        <img name="해방 : 내비치는 환란" src="https://dundam.xyz/img/item/d0379f8a1b8e8f6cba32f8ef30294b65"/>
                                                                                    </div>
                                                                                    {/* <div class="cus-addbox">
                                                                                        <div class="abtit"><img src="img/item/1ccac9965353062c04b7da144244b544"></img><span class="abt_name">불가침의 영역 - 벨트</span></div>

                                                                                        <div class="abop">
                                                                                        <div class="option"><span class="op_gold">피해 증가</span> + 6858 공격 시 300px 내 랜덤한 위치에 번개를 시전하여 모든 적과 자신에게 15초 동안 감전 상태 이상 부여 (쿨타임 10초)
                                                                                        - 상태 이상 내성 무시
                                                                                        - 스킬, 소모품으로 해제 불가
                                                                                        번개는 적의 방어력을 일부 적용받고 총 피해 증가 수치의 84%만큼 피해를 줌

                                                                                        자신이 감전 상태일 때 아래의 효과 적용
                                                                                        - 스킬 공격력 17% 증가
                                                                                        - 공격 속도 +20%
                                                                                        - 캐스팅 속도 +30%
                                                                                        - 이동 속도 +20%

                                                                                        500px 내 감전 상태인 대상 하나마다 피해 증가 +712 (최대 5중첩)

                                                                                        물리/마법 크리티컬 히트 +7%

                                                                                        모든 상태 이상 내성 -10%</div>
                                                                                        </div>
                                                                                    </div> */}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    
                                                                    </>

                                                                    :
                                                                    <></>
                                                                }
                                                                {
                                                                    //융합석 각인 07-10 
                                                                    
                                                                    selectFusionType == 2 ? 
                                                                    <>
                                                                        <div class="fusion-stone-engraving-main">
                                                                            <div class="fsem-hug fsem">
                                                                                <div class="fsem-con">
                                                                                    {/* <div class="eset_legendstone_optionadd">
                                                                                        <div class="et-lston-h-name">
                                                                                            <span> 
                                                                                            <b>팔찌</b> 융합석 각인 
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                        <div class="fsem-content-part">
                                                                                            <div class="fsem-item">
                                                                                                <div class="abtit"><img src="https://img-api.neople.co.kr/df/items/53a02e917af9ccf63720982e561db8f0">
                                                                                                </img>
                                                                                                    <span class="abt_name">보호를 담은 괴</span></div>
                                                                                            </div>
                                                                                            <div class="fsem-item-engraving-set">
                                                                                                <div class="fieset-con">
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset">
                                                                                                                <div class="fiopset-ul">
                                                                                                                    <div class="fioset-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div class="fieset-op-tit">
                                                                                                            <span>1옵션</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                     
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset">
                                                                                                                    <div class="fiopset-ul">
                                                                                                                        <div class="fioset-li">
                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                <img src="img/engraving_f.png"></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div class="fioset-li">
                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                <img src="img/engraving_f.png"></img>

                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        <div class="fioset-li">
                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                <img src="img/engraving_f.png"></img>

                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="fieset-op-tit">
                                                                                                            <span>2옵션</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                       
                                                                                                    <div class="fieset-op">
                                                                                                  
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset">
                                                                                                                    <div class="fiopset-ul">
                                                                                                                        <div class="fioset-li">
                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                <img src="img/engraving_f.png"></img>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div class="fioset-li">
                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                <img src="img/engraving_f.png"></img>

                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                        <div class="fioset-li">
                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                <img src="img/engraving_f.png"></img>

                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div class="fieset-op-tit">
                                                                                                                <span>3옵션</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                      
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div> */}

                                                                                    {
                                                                                        Array.from({length: 6}, (v, i) => 0).map((a,i) =>{

                                                                                            let slotNumber = 0;
                                                                                            let slotName = "";

                                                                                            switch(i)
                                                                                            {
                                                                                                case 0: slotName = '팔찌'; slotNumber = 8; break;
                                                                                                case 1: slotName = '목걸이'; slotNumber = 7; break;
                                                                                                case 2: slotName = '보조장비'; slotNumber = 10; break;
                                                                                                case 3: slotName = '반지'; slotNumber = 9; break;
                                                                                                case 4: slotName = '귀걸이'; slotNumber = 12; break;
                                                                                                case 5: slotName = '마법석'; slotNumber = 11; break;
                                                                                            }
                                                                                            return(

                                                                                                <>
                                                                                                <div class="eset_legendstone_optionadd">
                                                                                                    <div class="et-lston-h-name">
                                                                                                        <span> 
                                                                                                        <b>{slotName}</b> 융합석 각인 
                                                                                                        </span>
                                                                                                        
                                                                                                    </div>
                                                                                                    <div class="fsem-content-part fsem_vertical_v2">
                                                                                                        <div class="fsem-item">
                                                                                                            <div class="abtit">
                                                                                                                <img src={"https://img-api.neople.co.kr/df/items/"+lv105ItemId[characterData['siroco'+(slotNumber)]]}>
                                                                                                                </img>
                                                                                                                <span class="abt_name">{characterData['siroco'+(slotNumber)]}</span></div>
                                                                                                            </div>

                                                                                                        <div class="fsem-item-engraving-set">
                                                                                                            <div class="fieset-con">
                                                                                                                {
                                                                                                                    Array.from({length: 3}, (a, loop) => 0).map((a,loop)=>
                                                                                                                    {
                                                                                                                        var image =  loop >= 1 ? "img/engraving_f.png" : 'img/engraving_s.png';
                                                                                                                        //img/engraving_f.png
                                                                                                                        return(
                                                                                                                        <div class="fieset-op">
                                                                                                                        
                                                                                                                            <div class="fieset-op-set">
                                                                                                                                <div class="fiopset_vertical">
                                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                                        <span>{loop+1}옵션</span>
                                                                                                                                    </div>
                                                                                                                                    <div class="fiopset_vertical-ul">
                                                                                                                                        {
                                                                                                                                            Array.from({length: 3}, (v, i) => 0).map((b,i)=>
                                                                                                                                            {
                                                                                                                                                if(characterData['siroco'+slotNumber+'up'+loop] != null)
                                                                                                                                                {
                                                                                                                                                    return(
                                                                                                                                                        <div onClick={()=>{characterData['siroco'+slotNumber+'up'+loop] == (i+1) ? change('siroco'+slotNumber+'up'+loop,0) : change('siroco'+slotNumber+'up'+loop,i+1) }} class={("fioset-vertical-li" + (characterData['siroco'+slotNumber+'up'+loop] >= i+1 ? " is-active" : "") )}>
                                                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                                                <img src={image}></img>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                                else
                                                                                                                                                {
                                                                                                                                                    return(
                                                                                                                                                        <div onClick={()=>{change('siroco'+slotNumber+'up'+loop,i+1)}}  class={("fioset-vertical-li")}>
                                                                                                                                                            <div class="fioset-first-engraving">
                                                                                                                                                                <img src={image}></img>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    )
                                                                                                                                                }
                                                                                                                                            })

                                                                                                                                        }
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        
                                                                                                                        </div>
                                                                                                                        )
                                                                                                                    }
                                                                                                                    )
                                                                                                                }
                                                                                                                {/* <div class="fieset-op">
                                                                                                                
                                                                                                                    <div class="fieset-op-set">
                                                                                                                        <div class="fiopset_vertical">
                                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                                <span>2옵션</span>
                                                                                                                            </div>

                                                                                                                            <div class="fiopset_vertical-ul">
                                                                                                                                <div class="fioset-vertical-li is-active">
                                                                                                                                    <div class="fioset-first-engraving">
                                                                                                                                        <img src="img/engraving_f.png"></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div class="fioset-vertical-li">
                                                                                                                                    <div class="fioset-first-engraving">
                                                                                                                                        <img src="img/engraving_f.png"></img>

                                                                                                                                    </div>

                                                                                                                                </div>
                                                                                                                                <div class="fioset-vertical-li">
                                                                                                                                    <div class="fioset-first-engraving">
                                                                                                                                        <img src="img/engraving_f.png"></img>

                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                
                                                                                                                </div>
                                                                                                                <div class="fieset-op">
                                                                                                                
                                                                                                                    <div class="fieset-op-set">
                                                                                                                        <div class="fiopset_vertical">
                                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                                <span>3옵션</span>
                                                                                                                            </div>
                                                                                                                            <div class="fiopset_vertical-ul">
                                                                                                                                <div class="fioset-vertical-li">
                                                                                                                                    <div class="fioset-first-engraving">
                                                                                                                                        <img src="img/engraving_f.png"></img>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                                <div class="fioset-vertical-li">
                                                                                                                                    <div class="fioset-first-engraving">
                                                                                                                                        <img src="img/engraving_f.png"></img>

                                                                                                                                    </div>

                                                                                                                                </div>
                                                                                                                                <div class="fioset-vertical-li">
                                                                                                                                    <div class="fioset-first-engraving">
                                                                                                                                        <img src="img/engraving_f.png"></img>

                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                
                                                                                                                </div> */}
                                                                                                                
                                                                                                                
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    
                                                                                                    </div>
                                                                                                </div>
                                                                                                
                                                                                                </>

                                                                                            )

                                                                                        })

                                                                                    }
                                                                                    
                                                                                    {/* <div class="eset_legendstone_optionadd">
                                                                                        <div class="et-lston-h-name">
                                                                                            <span> 
                                                                                            <b>팔찌</b> 융합석 각인 
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                        <div class="fsem-content-part fsem_vertical_v2">
                                                                                            <div class="fsem-item">
                                                                                                <div class="abtit">
                                                                                                    <img src="https://img-api.neople.co.kr/df/items/53a02e917af9ccf63720982e561db8f0">
                                                                                                    </img>
                                                                                                    <span class="abt_name">보호를 담은 괴</span></div>
                                                                                                </div>

                                                                                               <div class="fsem-item-engraving-set">
                                                                                                <div class="fieset-con">
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                    <span>1옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>2옵션</span>
                                                                                                                </div>

                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>3옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                      
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="eset_legendstone_optionadd">
                                                                                        <div class="et-lston-h-name">
                                                                                            <span> 
                                                                                            <b>팔찌</b> 융합석 각인 
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                        <div class="fsem-content-part fsem_vertical_v2">
                                                                                            <div class="fsem-item">
                                                                                                <div class="abtit">
                                                                                                    <img src="https://img-api.neople.co.kr/df/items/53a02e917af9ccf63720982e561db8f0">
                                                                                                    </img>
                                                                                                    <span class="abt_name">보호를 담은 괴</span></div>
                                                                                                </div>

                                                                                               <div class="fsem-item-engraving-set">
                                                                                                <div class="fieset-con">
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                    <span>1옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>2옵션</span>
                                                                                                                </div>

                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>3옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                      
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="eset_legendstone_optionadd">
                                                                                        <div class="et-lston-h-name">
                                                                                            <span> 
                                                                                            <b>팔찌</b> 융합석 각인 
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                        <div class="fsem-content-part fsem_vertical_v2">
                                                                                            <div class="fsem-item">
                                                                                                <div class="abtit">
                                                                                                    <img src="https://img-api.neople.co.kr/df/items/53a02e917af9ccf63720982e561db8f0">
                                                                                                    </img>
                                                                                                    <span class="abt_name">보호를 담은 괴</span></div>
                                                                                                </div>

                                                                                               <div class="fsem-item-engraving-set">
                                                                                                <div class="fieset-con">
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                    <span>1옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>2옵션</span>
                                                                                                                </div>

                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>3옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                      
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="eset_legendstone_optionadd">
                                                                                        <div class="et-lston-h-name">
                                                                                            <span> 
                                                                                            <b>팔찌</b> 융합석 각인 
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                        <div class="fsem-content-part fsem_vertical_v2">
                                                                                            <div class="fsem-item">
                                                                                                <div class="abtit">
                                                                                                    <img src="https://img-api.neople.co.kr/df/items/53a02e917af9ccf63720982e561db8f0">
                                                                                                    </img>
                                                                                                    <span class="abt_name">보호를 담은 괴</span></div>
                                                                                                </div>

                                                                                               <div class="fsem-item-engraving-set">
                                                                                                <div class="fieset-con">
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                    <span>1옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>2옵션</span>
                                                                                                                </div>

                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>3옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                      
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="eset_legendstone_optionadd">
                                                                                        <div class="et-lston-h-name">
                                                                                            <span> 
                                                                                            <b>팔찌</b> 융합석 각인 
                                                                                            </span>
                                                                                            
                                                                                        </div>
                                                                                        <div class="fsem-content-part fsem_vertical_v2">
                                                                                            <div class="fsem-item">
                                                                                                <div class="abtit">
                                                                                                    <img src="https://img-api.neople.co.kr/df/items/53a02e917af9ccf63720982e561db8f0">
                                                                                                    </img>
                                                                                                    <span class="abt_name">보호를 담은 괴</span></div>
                                                                                                </div>

                                                                                               <div class="fsem-item-engraving-set">
                                                                                                <div class="fieset-con">
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                            <div class="fiopset_vertical-tit">
                                                                                                                    <span>1옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_s.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>2옵션</span>
                                                                                                                </div>

                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                    <div class="fieset-op">
                                                                                                    
                                                                                                        <div class="fieset-op-set">
                                                                                                            <div class="fiopset_vertical">
                                                                                                                <div class="fiopset_vertical-tit">
                                                                                                                    <span>3옵션</span>
                                                                                                                </div>
                                                                                                                <div class="fiopset_vertical-ul">
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div class="fioset-vertical-li">
                                                                                                                        <div class="fioset-first-engraving">
                                                                                                                            <img src="img/engraving_f.png"></img>

                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                     
                                                                                                    </div>
                                                                                                      
                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                          
                                                                                        </div>
                                                                                    </div> */}
                                                                                    
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                        
                                                                        </div>
                                                                    
                                                                    </>

                                                                    :
                                                                    <></>
                                                                }
                                                                
                                                                
                                                            </div>

                                                        </div>
                                                        <div class="tab__content" name="무기융합">
                                                            <div class="esetting_tr cusp2 o105je">
                                                                <div class="estcont  cussa" id="weapon1">

                                                                    <div class="cus_105op" >
                                                                        <div class="option">
                                                                            {characterData['weapon1'] != null ? lv105WeaponOptionMap[characterData['weapon1'] - 1].replace(/<br>/gi, "\n") : ""}

                                                                        </div>

                                                                        <div class="cus_opsetbut" onClick={() => { weaponPlusChange(0, 1) }} >
                                                                            <div class="imgt">
                                                                                <img src="img/set1.svg" />
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                    <div class={"cus_opset" + (weaponPlusArray[0] == 1 ? " on" : "")}>
                                                                        <div class="cos_c">
                                                                            <div class="cossc">
                                                                                <div class="searchbox" name="검색창" >
                                                                                    <input type="text" onChange={(event) => weaponOpSearchChange(0, event.target.value)} class="form-control js-bound" placeholder="검색" name="name" maxlength="40" />
                                                                                </div>
                                                                                <div class="searchbut105">
                                                                                    <div class="imgt">
                                                                                        <img src="img/sr_search.svg" />
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="coscc">
                                                                                <ul>
                                                                                    {
                                                                                        lv105WeaponOptionMap ? lv105WeaponOptionMap.map((a, i) => {

                                                                                            if (optionSearch(a,weaponOpSearch[0]) != -1)
                                                                                                return (

                                                                                                    <li key={'weaponOPF' + i} className={weaponPlusSelectArray[0] == i + 1 ? "is-active" : ""} onClick={() => weaponPlusSelectArrayChange(0, i + 1)}>{a.replace(/<br>/gi, '\n')}</li>
                                                                                                )

                                                                                        }) : ""

                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                            <div class="cos_but">
                                                                                <div class="csbt" slot="1" onClick={() => { if(weaponPlusSelectArray[0] != 0) { change('weapon1', weaponPlusSelectArray[0]); weaponPlusChange(0, 0); } }} >
                                                                                    <span class="cbut-tag" >적용</span>
                                                                                </div>
                                                                                <div class="csbt csbtc" onClick={() => { weaponPlusChange(0, 0) }}>
                                                                                    <span class="cbut-tag">
                                                                                        <div class="imgt">
                                                                                            <img src="img/arrow_go_back2.svg" />
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div class="estcont  cussa" id="weapon2">

                                                                    <div class="cus_105op" >
                                                                        <div class="option">
                                                                            {characterData['weapon2'] != null ? lv105WeaponOptionMap[characterData['weapon2'] - 1].replace(/<br>/gi, "\n") : ""}
                                                                        </div>
                                                                        <div class="cus_opsetbut" onClick={() => { weaponPlusChange(1, 1) }}  >
                                                                            <div class="imgt">
                                                                                <img src="img/set1.svg" />
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                    <div class={"cus_opset" + (weaponPlusArray[1] == 1 ? " on" : "")}>
                                                                        <div class="cos_c">
                                                                            <div class="cossc">
                                                                                <div class="searchbox" name="검색창">
                                                                                    <input type="text" onChange={(event) => weaponOpSearchChange(1, event.target.value)} class="form-control js-bound" placeholder="검색" name="name" maxlength="40" />
                                                                                </div>
                                                                                <div class="searchbut105">
                                                                                    <div class="imgt">
                                                                                        <img src="img/sr_search.svg" />
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="coscc">
                                                                                <ul>
                                                                                    {

                                                                                        lv105WeaponOptionMap ? lv105WeaponOptionMap.map((a, i) => {
                                                                                            if (optionSearch(a,weaponOpSearch[1]) != -1)
                                                                                                return (
                                                                                                    <li key={'weaponOPS' + i} className={weaponPlusSelectArray[1] == i + 1 ? "is-active" : ""} onClick={() => weaponPlusSelectArrayChange(1, i + 1)}>{a.replace(/<br>/gi, '\n')}</li>
                                                                                                )

                                                                                        }) : ""

                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                            <div class="cos_but">
                                                                                <div class="csbt" slot="1" onClick={() => { if(weaponPlusSelectArray[1] != 0) { change('weapon2', weaponPlusSelectArray[1]); weaponPlusChange(1, 0); }}} >
                                                                                    <span class="cbut-tag" >적용</span>
                                                                                </div>
                                                                                <div class="csbt csbtc" onClick={() => { weaponPlusChange(1, 0) }}>
                                                                                    <span class="cbut-tag">
                                                                                        <div class="imgt">
                                                                                            <img src="img/arrow_go_back2.svg" />
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div class="estcont  cussa" id="weapon3">

                                                                    <div class="cus_105op" >
                                                                        <div class="option">

                                                                            {characterData['weapon3'] != null ? lv105WeaponThirdOptionMap[characterData['weapon3'] - 1].replace(/<br>/gi, "\n") : ""}
                                                                        </div>

                                                                        <div class="cus_opsetbut" onClick={() => { weaponPlusChange(2, 1) }} >
                                                                            <div class="imgt">
                                                                                <img src="img/set1.svg" />
                                                                            </div>

                                                                        </div>

                                                                    </div>

                                                                    <div class={"cus_opset" + (weaponPlusArray[2] == 1 ? " on" : "")}>
                                                                        <div class="cos_c">
                                                                            <div class="cossc">
                                                                                <div class="searchbox" name="검색창">
                                                                                    <input type="text" onChange={(event) => weaponOpSearchChange(2, event.target.value)} class="form-control js-bound" placeholder="검색" name="name" maxlength="40" />
                                                                                </div>
                                                                                <div class="searchbut105">
                                                                                    <div class="imgt">
                                                                                        <img src="img/sr_search.svg" />
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                            <div class="coscc">
                                                                                <ul>
                                                                                    {

                                                                                        lv105WeaponThirdOptionMap ? lv105WeaponThirdOptionMap.map((a, i) => {
                                                                                            if (optionSearch(a,weaponOpSearch[2]) != -1)
                                                                                                return (
                                                                                                    <li key={'weaponOPT' + i} className={weaponPlusSelectArray[2] == i + 1 ? "is-active" : ""} onClick={() => weaponPlusSelectArrayChange(2, i + 1)}>{a.replace(/<br>/gi, '\n')}</li>

                                                                                                )

                                                                                        }) : ""

                                                                                    }
                                                                                </ul>
                                                                            </div>
                                                                            <div class="cos_but">
                                                                                <div class="csbt" slot="1" onClick={() => { if(weaponPlusSelectArray[2] != 0) { change('weapon3', weaponPlusSelectArray[2]); weaponPlusChange(2, 0); }}} >
                                                                                    <span class="cbut-tag" >적용</span>
                                                                                </div>
                                                                                <div class="csbt csbtc" onClick={() => { weaponPlusChange(2, 0) }}>
                                                                                    <span class="cbut-tag">
                                                                                        <div class="imgt">
                                                                                            <img src="img/arrow_go_back2.svg" />
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>

                                                            </div>

                                                
                                                            
                                                        </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="cus_epdt accSpe">
                                            {/* <div class="cus_elst">
                                            
                                                <div class="cus_eltit">
                                                    <span>이스핀즈</span>
                                                </div>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">진룡</li>
                                                    <li className={characterData['siroco'+9] == '진룡' ? " on" : ""} onClick={()=>change('siroco'+9,"진룡")}><div class="imgt raidItem" slot="109"><img name="진룡 : 무거운 철의 투지" src="https://dundam.xyz/img/item/6255f92b7b56a7ddd76f566a47ada800"/></div></li>
                                                    <li className={characterData['siroco'+7] == '진룡' ? " on" : ""} onClick={()=>change('siroco'+7,"진룡")}><div class="imgt raidItem" slot="107"><img name="진룡 : 잔혹한 용의 발톱" src="https://dundam.xyz/img/item/568018f4d9ed013f352d412331ab67ba"/></div></li>
                                                    <li className={characterData['siroco'+8] == '진룡' ? " on" : ""} onClick={()=>change('siroco'+8,"진룡")}><div class="imgt raidItem" slot="108"><img name="진룡 : 압도하는 힘의 원천" src="https://dundam.xyz/img/item/cc64795c07f57cb9e52967063276f795"/></div></li>
                                                </ul>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">금룡</li>
                                                    <li className={characterData['siroco'+9] == '금룡' ? " on" : ""} onClick={()=>change('siroco'+9,"금룡")}><div class="imgt raidItem" slot="109"><img name="금룡 : 무한한 빛의 결계" src="https://dundam.xyz/img/item/52688ab6a97da9368e86343d0a3ac4a5"/></div></li>
                                                    <li className={characterData['siroco'+7] == '금룡' ? " on" : ""} onClick={()=>change('siroco'+7,"금룡")}><div class="imgt raidItem" slot="107"><img name="금룡 : 찬란한 용의 비늘" src="https://dundam.xyz/img/item/eeb469cdd183e41318837d2dbd98dd42"/></div></li>
                                                    <li className={characterData['siroco'+8] == '금룡' ? " on" : ""} onClick={()=>change('siroco'+8,"금룡")}><div class="imgt raidItem" slot="108"><img name="금룡 : 화려한 힘의 기원" src="https://dundam.xyz/img/item/5285d14664a9aa41559ca68f440c3909"/></div></li>
                                                </ul>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">화룡</li>
                                                    <li className={characterData['siroco'+9] == '화룡' ? " on" : ""} onClick={()=>change('siroco'+9,"화룡")}><div class="imgt raidItem" slot="109"><img name="화룡 : 타오르는 불의 분노" src="https://dundam.xyz/img/item/6f388dd09d7149044abfd92fed63b4a6"/></div></li>
                                                    <li className={characterData['siroco'+7] == '화룡' ? " on" : ""} onClick={()=>change('siroco'+7,"화룡")}><div class="imgt raidItem" slot="107"><img name="화룡 : 끓어오르는 용의 숨결" src="https://dundam.xyz/img/item/73f46c965770a5680576ad52219da4a5"/></div></li>
                                                    <li className={characterData['siroco'+8] == '화룡' ? " on" : ""} onClick={()=>change('siroco'+8,"화룡")}><div class="imgt raidItem" slot="108"><img name="화룡 : 폭발하는 힘의 근원" src="https://dundam.xyz/img/item/0e79738a52d19e87cf910fc16537887b"/></div></li>
                                                </ul>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">흑룡</li>
                                                    <li className={characterData['siroco'+9] == '흑룡' ? " on" : ""} onClick={()=>change('siroco'+9,"흑룡")}><div class="imgt raidItem" slot="109"><img name="흑룡 : 끝없는 암흑의 굴레" src="https://dundam.xyz/img/item/1fd082cd996a9eb02645be09c5e89d41"/></div></li>
                                                    <li className={characterData['siroco'+7] == '흑룡' ? " on" : ""} onClick={()=>change('siroco'+7,"흑룡")}><div class="imgt raidItem" slot="107"><img name="흑룡 : 주시하는 용의 눈동자" src="https://dundam.xyz/img/item/27843216918f310d010f1014661c81bd"/></div></li>
                                                    <li className={characterData['siroco'+8] == '흑룡' ? " on" : ""} onClick={()=>change('siroco'+8,"흑룡")}><div class="imgt raidItem" slot="108"><img name="흑룡 : 어두운 힘의 근본" src="https://dundam.xyz/img/item/db3a4579b25e7dbdca33f56195ce259f"/></div></li>
                                                </ul>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">천계</li>
                                                    <li className={characterData['siroco'+9] == '천계' ? " on" : ""} onClick={()=>change('siroco'+9,"천계")}><div class="imgt raidItem" slot="109"><img name="천계 연합군 : 밝게 빛나는 희망" src="https://dundam.xyz/img/item/f0683ab71a21f9166ccb3423fa0a928c"/></div></li>
                                                    <li className={characterData['siroco'+7] == '천계' ? " on" : ""} onClick={()=>change('siroco'+7,"천계")}><div class="imgt raidItem" slot="107"><img name="천계 연합군 : 모든 이를 위한 기도" src="https://dundam.xyz/img/item/3b62a91abc21df1a2609af53cb15d432"/></div></li>
                                                    <li className={characterData['siroco'+8] == '천계' ? " on" : ""} onClick={()=>change('siroco'+8,"천계")}><div class="imgt raidItem" slot="108"><img name="천계 연합군 : 꺾이지 않는 의지" src="https://dundam.xyz/img/item/cb41d2e497f1a7ac1ae2711670da9861"/></div></li>
                                                </ul>
                                            
                                                <div class="cus_eltit">
                                                    <span>차원회랑</span>
                                                </div>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">슈페리어</li>
                                                    <li className={characterData['siroco'+10] == '슈페' ? " on" : ""} onClick={()=>change('siroco'+10,"슈페")}><div class="imgt raidItem" slot="1010"><img name="슈페리어 : 오리진 오브 더 마이어" src="https://dundam.xyz/img/item/a6baa41de89c3a9bf280b65ca5b24b58"/></div></li>
                                                    <li className={characterData['siroco'+11] == '슈페' ? " on" : ""} onClick={()=>change('siroco'+11,"슈페")}><div class="imgt raidItem" slot="1011"><img name="슈페리어 : 매직 서클로" src="https://dundam.xyz/img/item/323e201088055e1ed622be84b7479103"/></div></li>
                                                    <li className={characterData['siroco'+12] == '슈페' ? " on" : ""} onClick={()=>change('siroco'+12,"슈페")}><div class="imgt raidItem" slot="1012"><img name="슈페리어 : 코스믹 이어링" src="https://dundam.xyz/img/item/70558b17d3d49fc8599f8e0d751bce9d"/></div></li>
                                                </ul>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">심연의 결집</li>
                                                    <li className={characterData['siroco'+10] == '심연' ? " on" : ""} onClick={()=>change('siroco'+10,"심연")}><div class="imgt raidItem" slot="1010"><img name="심연의 결집 : 정의되지 않는 존재" src="https://dundam.xyz/img/item/56422d257499f7c60c7dad02d0f43db2"/></div></li>
                                                    <li className={characterData['siroco'+11] == '심연' ? " on" : ""} onClick={()=>change('siroco'+11,"심연")}><div class="imgt raidItem" slot="1011"><img name="심연의 결집 : 무한한 수축" src="https://dundam.xyz/img/item/2950e00eced48e19bf4c161b3bc7df7e"/></div></li>
                                                    <li className={characterData['siroco'+12] == '심연' ? " on" : ""} onClick={()=>change('siroco'+12,"심연")}><div class="imgt raidItem" slot="1012"><img name="심연의 결집 : 무정형의 힘" src="https://dundam.xyz/img/item/1ba6221619f88691ed8f0c3adba4db7a"/></div></li>
                                                </ul>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">영혼의 구속</li>
                                                    <li className={characterData['siroco'+10] == '영혼' ? " on" : ""} onClick={()=>change('siroco'+10,"영혼")}><div class="imgt raidItem" slot="1010"><img name="영혼의 구속 : 지배하는 권능" src="https://dundam.xyz/img/item/332409131f5fcbb418bff7a0a5dd8a13"/></div></li>
                                                    <li className={characterData['siroco'+11] == '영혼' ? " on" : ""} onClick={()=>change('siroco'+11,"영혼")}><div class="imgt raidItem" slot="1011"><img name="영혼의 구속 : 무거운 업보" src="https://dundam.xyz/img/item/6e1dc4f395dd54d7750ac47b2631518c"/></div></li>
                                                    <li className={characterData['siroco'+12] == '영혼' ? " on" : ""} onClick={()=>change('siroco'+12,"영혼")}><div class="imgt raidItem" slot="1012"><img name="영혼의 구속 : 거스를 수 없는 봉인" src="https://dundam.xyz/img/item/e0e357b5cce04625dc8b0538bd5c8d01"/></div></li>
                                                </ul>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">광휘</li>
                                                    <li className={characterData['siroco'+10] == '광휘' ? " on" : ""} onClick={()=>change('siroco'+10,"광휘")}><div class="imgt raidItem" slot="1010"><img name="광휘 : 고귀한 사명" src="https://dundam.xyz/img/item/078ddee1101ba2dd3f62f7b2841a260d"/></div></li>
                                                    <li className={characterData['siroco'+11] == '광휘' ? " on" : ""} onClick={()=>change('siroco'+11,"광휘")}><div class="imgt raidItem" slot="1011"><img name="광휘 : 멈출 수 없는 의지" src="https://dundam.xyz/img/item/7dc30234ecc008bc2ddc550ad8fb22a5"/></div></li>
                                                    <li className={characterData['siroco'+12] == '광휘' ? " on" : ""} onClick={()=>change('siroco'+12,"광휘")}><div class="imgt raidItem" slot="1012"><img name="광휘 : 전해지는 지혜" src="https://dundam.xyz/img/item/ea1a209be12a78a2a6c872e75ab0970c"/></div></li>
                                                </ul>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">죄악의 지배</li>
                                                    <li className={characterData['siroco'+10] == '죄악' ? " on" : ""} onClick={()=>change('siroco'+10,"죄악")}><div class="imgt raidItem" slot="1010"><img name="죄악의 지배 : 절망을 뒤덮는 공포" src="https://dundam.xyz/img/item/47b419903a0b8542416e528e1079e29e"/></div></li>
                                                    <li className={characterData['siroco'+11] == '죄악' ? " on" : ""} onClick={()=>change('siroco'+11,"죄악")}><div class="imgt raidItem" slot="1011"><img name="죄악의 지배 : 절규가 매달리는 공포" src="https://dundam.xyz/img/item/49e7e73cd1ec8573ed0c0ba9c127b350"/></div></li>
                                                    <li className={characterData['siroco'+12] == '죄악' ? " on" : ""} onClick={()=>change('siroco'+12,"죄악")}><div class="imgt raidItem" slot="1012"><img name="죄악의 지배 : 태초가 선사하는 공포" src="https://dundam.xyz/img/item/5f8b29a66f1c13f6e3a47169af5cc598"/></div></li>
                                                </ul>
                                            </div> */}
                                          
                                            {/* <div class="cus_elst">
                                                <div class="cus_eltit">
                                                    <span>바칼</span>
                                                </div>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">빙화</li>
                                                    <li className={characterData['siroco'+2] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+2,"빙화")}><div class="imgt raidItem" slot="102"><img name="빙화 : 피어난 꽃잎" src="https://dundam.xyz/img/item/240853586bd8ae7296378e1a6b7745d5"/></div></li>
                                                    <li className={characterData['siroco'+4] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+4,"빙화")}><div class="imgt raidItem" slot="104"><img name="빙화 : 가혹한 한기" src="https://dundam.xyz/img/item/5cdf68192c0f5838a3f3f0c0bef41ab4"/></div></li>
                                                    <li className={characterData['siroco'+3] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+3,"빙화")}><div class="imgt raidItem" slot="103"><img name="빙화 : 얽히는 서리" src="https://dundam.xyz/img/item/aac73f110fc4ef6963f083cde4fc9850"/></div></li>
                                                    <li className={characterData['siroco'+6] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+6,"빙화")}><div class="imgt raidItem" slot="106"><img name="빙화 : 살에는 빙설" src="https://dundam.xyz/img/item/1452dd4dde85795b5bcd8e9bd13fcbe6"/></div></li>
                                                    <li className={characterData['siroco'+5] == '빙화' ? " on" : ""} onClick={()=>change('siroco'+5,"빙화")}><div class="imgt raidItem" slot="105"><img name="빙화 : 펼쳐진 동토" src="https://dundam.xyz/img/item/0fd9a42095299db7c936b07544da52ac"/></div></li>
                                                </ul>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">뇌광</li>
                                                    <li className={characterData['siroco'+2] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+2,"뇌광")}><div class="imgt raidItem" slot="102"><img name="뇌광 : 천둥을 품은 용심" src="https://dundam.xyz/img/item/281c2640178dd5cbcc5c58b5f1bdcaa4"/></div></li>
                                                    <li className={characterData['siroco'+4] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+4,"뇌광")}><div class="imgt raidItem" slot="104"><img name="뇌광 : 낙뢰를 내린 용각" src="https://dundam.xyz/img/item/311f77c82a2c5d84e7ef85dc9599e75e"/></div></li>
                                                    <li className={characterData['siroco'+3] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+3,"뇌광")}><div class="imgt raidItem" slot="103"><img name="뇌광 : 우레를 감싼 용익" src="https://dundam.xyz/img/item/424a5caa037bcf098cb50b70bc3b3117"/></div></li>
                                                    <li className={characterData['siroco'+6] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+6,"뇌광")}><div class="imgt raidItem" slot="106"><img name="뇌광 : 번개를 담은 용린" src="https://dundam.xyz/img/item/b4f01254eea851567184b39616d40b6e"/></div></li>
                                                    <li className={characterData['siroco'+5] == '뇌광' ? " on" : ""} onClick={()=>change('siroco'+5,"뇌광")}><div class="imgt raidItem" slot="105"><img name="뇌광 : 벼락을 감은 용조" src="https://dundam.xyz/img/item/a3a08d4776befa1d152d2e4e5cbaa6b0"/></div></li>
                                                </ul>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">교감</li>
                                                    <li className={characterData['siroco'+2] == '교감' ? " on" : ""} onClick={()=>change('siroco'+2,"교감")}><div class="imgt raidItem" slot="102"><img name="교감 : 공존하는 생명" src="https://dundam.xyz/img/item/1bb154696f9191e6c0a4abdf74948d41"/></div></li>
                                                    <li className={characterData['siroco'+4] == '교감' ? " on" : ""} onClick={()=>change('siroco'+4,"교감")}><div class="imgt raidItem" slot="104"><img name="교감 : 보호하는 온기" src="https://dundam.xyz/img/item/127f13f9aaf04f83c78f0134fb1b015d"/></div></li>
                                                    <li className={characterData['siroco'+3] == '교감' ? " on" : ""} onClick={()=>change('siroco'+3,"교감")}><div class="imgt raidItem" slot="103"><img name="교감 : 감싸안는 햇살" src="https://dundam.xyz/img/item/26b72a48c54e98296ff3df20a52d4c16"/></div></li>
                                                    <li className={characterData['siroco'+6] == '교감' ? " on" : ""} onClick={()=>change('siroco'+6,"교감")}><div class="imgt raidItem" slot="106"><img name="교감 : 내려앉는 이슬" src="https://dundam.xyz/img/item/33d022dd111c61b85fe68659ab1dffa4"/></div></li>
                                                    <li className={characterData['siroco'+5] == '교감' ? " on" : ""} onClick={()=>change('siroco'+5,"교감")}><div class="imgt raidItem" slot="105"><img name="교감 : 동행하는 대지" src="https://dundam.xyz/img/item/c0665715c5c4b1d6de9f4ca60f130551"/></div></li>
                                                </ul>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">포식</li>
                                                    <li className={characterData['siroco'+2] == '포식' ? " on" : ""} onClick={()=>change('siroco'+2,"포식")}><div class="imgt raidItem" slot="102"><img name="포식 : 헤집어진 상처" src="https://dundam.xyz/img/item/5432b890b5d2f750c67951a8817c4823"/></div></li>
                                                    <li className={characterData['siroco'+4] == '포식' ? " on" : ""} onClick={()=>change('siroco'+4,"포식")}><div class="imgt raidItem" slot="104"><img name="포식 : 사그라진 고동" src="https://dundam.xyz/img/item/33e8f7122831fa045f6e84313da64e31"/></div></li>
                                                    <li className={characterData['siroco'+3] == '포식' ? " on" : ""} onClick={()=>change('siroco'+3,"포식")}><div class="imgt raidItem" slot="103"><img name="포식 : 자비없는 습격" src="https://dundam.xyz/img/item/18ddc7fb78f1230f14ee4654cc22cc20"/></div></li>
                                                    <li className={characterData['siroco'+6] == '포식' ? " on" : ""} onClick={()=>change('siroco'+6,"포식")}><div class="imgt raidItem" slot="106"><img name="포식 : 조여오는 올가미" src="https://dundam.xyz/img/item/21c05b45eb65c07383b0cad551e0ae47"/></div></li>
                                                    <li className={characterData['siroco'+5] == '포식' ? " on" : ""} onClick={()=>change('siroco'+5,"포식")}><div class="imgt raidItem" slot="105"><img name="포식 : 추적하는 사냥꾼" src="https://dundam.xyz/img/item/06a75e0f3cf3b8ad358289b3faf79303"/></div></li>
                                                </ul>
                                                <ul class="setList">
                                                    <li class="setName cus_etit">격노</li>
                                                    <li className={characterData['siroco'+2] == '격노' ? " on" : ""} onClick={()=>change('siroco'+2,"격노")}><div class="imgt raidItem" slot="102"><img name="격노 : 최후에 삼킨 숨결" src="https://dundam.xyz/img/item/113436105b0365c4ee0ed0c542f30710"/></div></li>
                                                    <li className={characterData['siroco'+4] == '격노' ? " on" : ""} onClick={()=>change('siroco'+4,"격노")}><div class="imgt raidItem" slot="104"><img name="격노 : 세상을 탐한 욕구" src="https://dundam.xyz/img/item/c11bb733312c6612d6fd2cd9196862c8"/></div></li>
                                                    <li className={characterData['siroco'+3] == '격노' ? " on" : ""} onClick={()=>change('siroco'+3,"격노")}><div class="imgt raidItem" slot="103"><img name="격노 : 파괴를 행한 맹목" src="https://dundam.xyz/img/item/054774144a5aab6828500305f71425ec"/></div></li>
                                                    <li className={characterData['siroco'+6] == '격노' ? " on" : ""} onClick={()=>change('siroco'+6,"격노")}><div class="imgt raidItem" slot="106"><img name="격노 : 무한히 증적한 식탐" src="https://dundam.xyz/img/item/5b3976c605e6d96878a9b94ad5fbd449"/></div></li>
                                                    <li className={characterData['siroco'+5] == '격노' ? " on" : ""} onClick={()=>change('siroco'+5,"격노")}><div class="imgt raidItem" slot="105"><img name="격노 : 방향을 잃은 갈망" src="https://dundam.xyz/img/item/f2c4246d0b59619eea5d03bb5b76cc91"/></div></li>
                                                </ul>
                                            
                                        </div> */}

                                        

                                        

                                            {/*} 
                                            <div class="cus_eltit">
                                                <span>바칼 무기</span>
                                            </div>
                                            
                                                <div class="esetting_tr cusp2 o105je">
                                                        <div class="estcont  cussa" style="height: 450px;" id="weapon1">
                    
                                                            <div class="cus_105op" style="height: 430px;">
                                                                <div class="option"></div>
                                                                
                                                                <div class="cus_opsetbut" style="display: unset;">
                                                                    <div class="imgt">
                                                                        <img src="img/gear-wheel.svg"/>
                                                                    </div>

                                                                </div>
                                                                
                                                            </div>
                                                            
                                                            <div class="cus_opset">
                                                                <div class="cos_c">
                                                                    <div class="cossc" style="
                                                                            width: 80%;
                                                                            display: flex;
                                                                        ">
                                                                        <div class="searchbox" name="검색창" style="
                                                                            width: 85%;
                                                                        ">
                                                                            <input type="text" class="form-control js-bound" placeholder="검색" name="name" maxlength="40"/>
                                                                        </div>
                                                                        <div class="searchbut105">
                                                                            <div class="imgt">
                                                                                <img src="img/sr_search.svg"/>
                                                                            </div>
                                
                                                                        </div>
                                                                    </div>
                                                                    <div class="coscc">
                                                                    </div>
                                                                    <div class="cos_but">
                                                                        <div class="csbt" slot="1">
                                                                            <span class="cbut-tag">적용</span>
                                                                        </div>
                                                                        <div class="csbt csbtc">
                                                                            <span class="cbut-tag">
                                                                                <div class="imgt">
                                                                                    <img src="img/arrow_go_back2.svg"/>
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        <div class="estcont  cussa" style="height: 450px;" id="weapon2">
                    
                                                            <div class="cus_105op" style="height: 430px;">
                                                                <div class="option"></div>
                                                                <div class="cus_opsetbut" style="display: unset;">
                                                                    <div class="imgt">
                                                                        <img src="img/gear-wheel.svg"/>
                                                                    </div>

                                                                </div>
                                                                
                                                            </div>
                                                            
                                                            <div class="cus_opset">
                                                                <div class="cos_c">
                                                                    <div class="cossc" style="
                                                                            width: 80%;
                                                                            display: flex;
                                                                        ">
                                                                        <div class="searchbox" name="검색창" style="
                                                                            width: 85%;
                                                                        ">
                                                                            <input type="text" class="form-control js-bound" placeholder="검색" name="name" maxlength="40"/>
                                                                        </div>
                                                                        <div class="searchbut105">
                                                                            <div class="imgt">
                                                                                <img src="img/sr_search.svg"/>
                                                                            </div>
                                
                                                                        </div>
                                                                    </div>
                                                                    <div class="coscc">
                                                                    </div>
                                                                    <div class="cos_but">
                                                                        <div class="csbt" slot="1">
                                                                            <span class="cbut-tag">적용</span>
                                                                        </div>
                                                                        <div class="csbt csbtc">
                                                                            <span class="cbut-tag">
                                                                                <div class="imgt">
                                                                                    <img src="img/arrow_go_back2.svg"/>
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                
                                                <div class="esetting_tr cusp2 o105je">
                                                        <div class="estcont  cussa" style="height: 450px;" id="weapon3">
                    
                                                            <div class="cus_105op" style="height: 430px;">
                                                                <div class="option"></div>
                                                                
                                                                <div class="cus_opsetbut" >
                                                                    <div class="imgt">
                                                                        <img src="img/gear-wheel.svg"/>
                                                                    </div>

                                                                </div>
                                                                
                                                            </div>
                                                            
                                                            <div class="cus_opset">
                                                                <div class="cos_c">
                                                                    <div class="cossc" style="
                                                                            width: 80%;
                                                                            display: flex;
                                                                        ">
                                                                        <div class="searchbox" name="검색창" style="
                                                                            width: 85%;
                                                                        ">
                                                                            <input type="text" class="form-control js-bound" placeholder="검색" name="name" maxlength="40"/>
                                                                        </div>
                                                                        <div class="searchbut105">
                                                                            <div class="imgt">
                                                                                <img src="img/sr_search.svg"/>
                                                                            </div>
                                
                                                                        </div>
                                                                    </div>
                                                                    <div class="coscc">
                                                                    </div>
                                                                    <div class="cos_but">
                                                                        <div class="csbt" slot="1">
                                                                            <span class="cbut-tag">적용</span>
                                                                        </div>
                                                                        <div class="csbt csbtc">
                                                                            <span class="cbut-tag">
                                                                                <div class="imgt">
                                                                                    <img src="img/arrow_go_back2.svg"/>
                                                                                </div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                
                                            {*/}
                                        </div>
                                        {/* <div class="esetting_tr cusp2 o105je">
                                            <div class="estcont  cussa" id="weapon1">

                                                <div class="cus_105op" >
                                                    <div class="option">
                                                        {characterData['weapon1'] != null ? lv105WeaponOptionMap[characterData['weapon1'] - 1].replace(/<br>/gi, "\n") : ""}

                                                    </div>

                                                    <div class="cus_opsetbut" onClick={() => { weaponPlusChange(0, 1) }} >
                                                        <div class="imgt">
                                                            <img src="img/gear-wheel.svg" />
                                                        </div>

                                                    </div>

                                                </div>

                                                <div class={"cus_opset" + (weaponPlusArray[0] == 1 ? " on" : "")}>
                                                    <div class="cos_c">
                                                        <div class="cossc">
                                                            <div class="searchbox" name="검색창" >
                                                                <input type="text" onChange={(event) => weaponOpSearchChange(0, event.target.value)} class="form-control js-bound" placeholder="검색" name="name" maxlength="40" />
                                                            </div>
                                                            <div class="searchbut105">
                                                                <div class="imgt">
                                                                    <img src="img/sr_search.svg" />
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="coscc">
                                                            <ul>
                                                                {
                                                                    lv105WeaponOptionMap ? lv105WeaponOptionMap.map((a, i) => {

                                                                        if (optionSearch(a,weaponOpSearch[0]) != -1)
                                                                            return (

                                                                                <li key={'weaponOPF' + i} className={weaponPlusSelectArray[0] == i + 1 ? "is-active" : ""} onClick={() => weaponPlusSelectArrayChange(0, i + 1)}>{a.replace(/<br>/gi, '\n')}</li>
                                                                            )

                                                                    }) : ""

                                                                }
                                                            </ul>
                                                        </div>
                                                        <div class="cos_but">
                                                            <div class="csbt" slot="1" onClick={() => { if(weaponPlusSelectArray[0] != 0) { change('weapon1', weaponPlusSelectArray[0]); weaponPlusChange(0, 0); } }} >
                                                                <span class="cbut-tag" >적용</span>
                                                            </div>
                                                            <div class="csbt csbtc" onClick={() => { weaponPlusChange(0, 0) }}>
                                                                <span class="cbut-tag">
                                                                    <div class="imgt">
                                                                        <img src="img/arrow_go_back2.svg" />
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="estcont  cussa" id="weapon2">

                                                <div class="cus_105op" >
                                                    <div class="option">
                                                        {characterData['weapon2'] != null ? lv105WeaponOptionMap[characterData['weapon2'] - 1].replace(/<br>/gi, "\n") : ""}
                                                    </div>
                                                    <div class="cus_opsetbut" onClick={() => { weaponPlusChange(1, 1) }}  >
                                                        <div class="imgt">
                                                            <img src="img/gear-wheel.svg" />
                                                        </div>

                                                    </div>

                                                </div>

                                                <div class={"cus_opset" + (weaponPlusArray[1] == 1 ? " on" : "")}>
                                                    <div class="cos_c">
                                                        <div class="cossc">
                                                            <div class="searchbox" name="검색창">
                                                                <input type="text" onChange={(event) => weaponOpSearchChange(1, event.target.value)} class="form-control js-bound" placeholder="검색" name="name" maxlength="40" />
                                                            </div>
                                                            <div class="searchbut105">
                                                                <div class="imgt">
                                                                    <img src="img/sr_search.svg" />
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="coscc">
                                                            <ul>
                                                                {

                                                                    lv105WeaponOptionMap ? lv105WeaponOptionMap.map((a, i) => {
                                                                        if (optionSearch(a,weaponOpSearch[1]) != -1)
                                                                            return (
                                                                                <li key={'weaponOPS' + i} className={weaponPlusSelectArray[1] == i + 1 ? "is-active" : ""} onClick={() => weaponPlusSelectArrayChange(1, i + 1)}>{a.replace(/<br>/gi, '\n')}</li>
                                                                            )

                                                                    }) : ""

                                                                }
                                                            </ul>
                                                        </div>
                                                        <div class="cos_but">
                                                            <div class="csbt" slot="1" onClick={() => { if(weaponPlusSelectArray[1] != 0) { change('weapon2', weaponPlusSelectArray[1]); weaponPlusChange(1, 0); }}} >
                                                                <span class="cbut-tag" >적용</span>
                                                            </div>
                                                            <div class="csbt csbtc" onClick={() => { weaponPlusChange(1, 0) }}>
                                                                <span class="cbut-tag">
                                                                    <div class="imgt">
                                                                        <img src="img/arrow_go_back2.svg" />
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>

                                        <div class="esetting_tr cusp2 o105je">
                                            <div class="estcont  cussa" id="weapon3">

                                                <div class="cus_105op" >
                                                    <div class="option">

                                                        {characterData['weapon3'] != null ? lv105WeaponThirdOptionMap[characterData['weapon3'] - 1].replace(/<br>/gi, "\n") : ""}
                                                    </div>

                                                    <div class="cus_opsetbut" onClick={() => { weaponPlusChange(2, 1) }} >
                                                        <div class="imgt">
                                                            <img src="img/gear-wheel.svg" />
                                                        </div>

                                                    </div>

                                                </div>

                                                <div class={"cus_opset" + (weaponPlusArray[2] == 1 ? " on" : "")}>
                                                    <div class="cos_c">
                                                        <div class="cossc">
                                                            <div class="searchbox" name="검색창">
                                                                <input type="text" onChange={(event) => weaponOpSearchChange(2, event.target.value)} class="form-control js-bound" placeholder="검색" name="name" maxlength="40" />
                                                            </div>
                                                            <div class="searchbut105">
                                                                <div class="imgt">
                                                                    <img src="img/sr_search.svg" />
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="coscc">
                                                            <ul>
                                                                {

                                                                    lv105WeaponThirdOptionMap ? lv105WeaponThirdOptionMap.map((a, i) => {
                                                                        if (optionSearch(a,weaponOpSearch[2]) != -1)
                                                                            return (
                                                                                <li key={'weaponOPT' + i} className={weaponPlusSelectArray[2] == i + 1 ? "is-active" : ""} onClick={() => weaponPlusSelectArrayChange(2, i + 1)}>{a.replace(/<br>/gi, '\n')}</li>

                                                                            )

                                                                    }) : ""

                                                                }
                                                            </ul>
                                                        </div>
                                                        <div class="cos_but">
                                                            <div class="csbt" slot="1" onClick={() => { if(weaponPlusSelectArray[2] != 0) { change('weapon3', weaponPlusSelectArray[2]); weaponPlusChange(2, 0); }}} >
                                                                <span class="cbut-tag" >적용</span>
                                                            </div>
                                                            <div class="csbt csbtc" onClick={() => { weaponPlusChange(2, 0) }}>
                                                                <span class="cbut-tag">
                                                                    <div class="imgt">
                                                                        <img src="img/arrow_go_back2.svg" />
                                                                    </div>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    */}
                                    </div>
                                
                                    <div class="cus_epdt e105ep setItem" style={present != 102 ? {display : 'none'} : {}}>
                                        <div class="cus_elst">
                                            <div class="cus_eltit_v2 e-m-set">
                                                <span>기억</span>
                                            </div>
                                            {
                                            
                                                present == 102 ? 
                                                setItemList[0].map((a,i) => {
                                                    //console.log(setItemList[0]);
                                                    //console.log(a);
                                                    return(
                                                        <ul key={'armourSet'+i}>
                                                        <ItemList slot='2' name={setItemList[0][i]} />
                                                        <ItemList slot='4' name={setItemList[1][i]} />
                                                        <ItemList slot='3' name={setItemList[2][i]} />
                                                        <ItemList slot='6' name={setItemList[3][i]} />
                                                        <ItemList slot='5' name={setItemList[4][i]} />
                                                        <ItemList slot='8' name={setItemList[5][i]} />
                                                        </ul>
                                                    )
                                                })
                                                :
                                                <></>

                                            }
                                        </div>
                                        
                                        <div class="cus_elst">
                                            <div class="cus_eltit_v2 e-m-set">
                                                <span>흔적</span>
                                            </div>
                                            {
                                                
                                                present == 102 ? 
                                                setItemList[0].map((a,i) => {
                                                    //console.log(setItemList[0]);
                                                    //console.log(a);
                                                    return(
                                                        <ul key={'etcSet'+i}>
                                                        <ItemList slot='7' name={setItemList[6][i]} />
                                                        <ItemList slot='9' name={setItemList[7][i]} />
                                                        <ItemList slot='10' name={setItemList[8][i]} />
                                                        <ItemList slot='11' name={setItemList[9][i]} />
                                                        <ItemList slot='12' name={setItemList[10][i]} />
                                                        </ul>
                                                    )
                                                })
                                                :
                                                <></>

                                            }
                                        </div>
                                    </div>


                                    <div class="cus_epdt e105ep" style={present != 100 ? {display : 'none'} : {}}>
                                        <div class="cus_elst">
                                            <div class="cus_eltit_v2 e-m-set">
                                                <span>방어구</span>
                                            </div>

                                            {
                                                present == 100 ? names.map((a, i) =>
                                                    {
                                                        if(i < armourCount)
                                                        {
                                                            let totalCheck = false;
                                                            let check = [false,false,false,false,false];

                                                            //console.log(itemSearch.replace(/ /gi,'').replace(/속강/gi,'속성강화').replace(/스증/gi,'스킬공격력'));

                                                            for(let loop = 0 ; loop < 5 ; loop++)
                                                            {
                                                                if(names[i+armourCount*loop].replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'')) != -1)
                                                                {
                                                                    check[loop] = true;
                                                                    totalCheck = true;
                                                                }
                                                                

                                                                if(lv105OptionMap[names[i+armourCount*loop]] != null)
                                                                if(lv105OptionMap[names[i+armourCount*loop]].replace(/,/gi,'').replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'').replace(/속강/gi,'속성강화').replace(/스증/gi,'스킬공격력')) != -1)
                                                                {
                                                                    check[loop] = true;
                                                                    totalCheck = true;
                                                                }
                                                            }

                                                            if(totalCheck)
                                                            return(
                                                                    <ul key={'armour'+i}>
                                                                        {
                                                                         check[0] ? 
                                                                        
                                                                         <Equip name={names[i+armourCount*0]} slot={2}/>: <li></li>
                                                                        }
                                                                        {
                                                                         check[2] ? 
                                                                       
                                                                         <Equip name={names[i+armourCount*2]} slot={4}/>: <li></li>
                                                                        }
                                                                        {
                                                                         check[1] ? 
                                                                       
                                                                         <Equip name={names[i+armourCount*1]} slot={3}/>: <li></li>
                                                                        }
                                                                        
                                                                         {
                                                                         check[4] ?
                                                                        
                                                                         <Equip name={names[i+armourCount*4]} slot={6}/>: <li></li>
                                                                       
                                                                        }
                                                                        {
                                                                         check[3] ?
                                                                         
                                                                         <Equip name={names[i+armourCount*3]} slot={5}/>: <li></li>
                                                                       
                                                                        }
                                                                       
                                                                    </ul>

                                                            )
                                                        }
                                                    } 
                                                ) : <></>
                                            }
                                        </div>
                                        <div class="cus_elst">
                                            <div class="cus_eltit_v2 e-m-set">
                                                <span>악세서리</span>
                                            </div>

                                            {
                                                present == 100 ? accNames.map((a, i) =>
                                                    {
                                                        if(i < itemCount)
                                                        {
                                                            let totalCheck = false;
                                                            let check = [false,false,false];

                                                            for(let loop = 0 ; loop < 3 ; loop++)
                                                            {
                                                                if(names[i+itemCount*loop].replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'')) != -1)
                                                                {
                                                                    check[loop] = true;
                                                                    totalCheck = true;
                                                                }

                                                                if(lv105OptionMap[names[i+itemCount*loop]] != null)
                                                                if(lv105OptionMap[names[i+itemCount*loop]].replace(/,/gi,'').replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'').replace(/속강/gi,'속성강화').replace(/스증/gi,'스킬공격력')) != -1)
                                                                {
                                                                    check[loop] = true;
                                                                    totalCheck = true;
                                                                }
                                                            }

                                                            if(totalCheck)
                                                            return(
                                                                <ul key={i}>
                                                                    
                                                                    {
                                                                         check[1] ?
                                                                         <Equip name={accNames[i+itemCount * 1]} slot={8}/>: <li></li>
                                                                    }
                                                                    {
                                                                         check[0] ?
                                                                         <Equip name={accNames[i+itemCount * 0]} slot={7}/>: <li></li>
                                                                    }
                                                                    {
                                                                         check[2] ?
                                                                         <Equip name={accNames[i+itemCount * 2]} slot={9}/>: <li></li>
                                                                    }
                                                                    
                                                                   
                                                                </ul>

                                                            )
                                                        }
                                                        
                                                    } 
                                                ) : <></>
                                            }
                                        </div>
                                        <div class="cus_elst">
                                            <div class="cus_eltit_v2 e-m-set">
                                                <span>특수장비</span>
                                            </div>

                                            {
                                                present == 100 ? speNames.map((a, i) =>
                                                    {
                                                        if(i < speCount)
                                                        {
                                                            let totalCheck = false;
                                                            let check = [false,false,false];

                                                            for(let loop = 0 ; loop < 3 ; loop++)
                                                            {
                                                                if(names[i+speCount*loop].replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'')) != -1)
                                                                {
                                                                    check[loop] = true;
                                                                    totalCheck = true;
                                                                }

                                                                if(lv105OptionMap[names[i+speCount*loop]] != null)
                                                                if(lv105OptionMap[names[i+speCount*loop]].replace(/,/gi,'').replace(/ /gi,'').indexOf(itemSearch.replace(/ /gi,'').replace(/속강/gi,'속성강화').replace(/스증/gi,'스킬공격력')) != -1)
                                                                {
                                                                    check[loop] = true;
                                                                    totalCheck = true;
                                                                }
                                                            }

                                                            if(totalCheck)
                                                            return(
                                                                <ul key={i}>
                                                                     {
                                                                         check[0] ?
                                                                         <Equip name={speNames[i+speCount * 0]} slot={10}/>: <li></li>
                                                                    }
                                                                     {
                                                                         check[1] ?
                                                                         <Equip name={speNames[i+speCount * 1]} slot={11}/>: <li></li>
                                                                    }
                                                                    {
                                                                         check[2] ?
                                                                         <Equip name={speNames[i+speCount * 2]} slot={12}/>: <li></li>
                                                                    }
                                                            
                                                                </ul>

                                                            )
                                                        }
                                                    }
                                                ): <></>
                                            }
                                        </div>

                                        
                                    </div>
                                    <Setting id='0'/>
                                    {
                                        characterObj['buffCal'] == null ? <TitleSetting id='1'/> : <MainTitleSetting id='1'/>
                                    }
                                    
                                    <Setting id='2'/>
                                    <Setting id='3'/>
                                    <Setting id='4'/>
                                    <Setting id='5'/>
                                    <Setting id='6'/>
                                    <Setting id='7'/>
                                    <Setting id='8'/>
                                    <Setting id='9'/>
                                    <Setting id='10'/>
                                    <Setting id='11'/>
                                    <Setting id='12'/>
                                    {
                                        /* 13 */
                                    }
                                    
                                    <PlusTitleSetting id='115'/>
                                    <PlusSetting id='114'/>
                                    <PlusSetting id='113'/>

                                    {
                                        characterObj.job != '검제' ? 
                                                <SubWeaponSetting  id='116'/>
                                        : <></>
                                    }
                                    

                                    

                                </div>
                                
                                
                                <div class="tab__content" name="아바타">
                                    <div class="c_abata">
                                        <div class="c_equip sabat">
                                            <div class="caba">
                                                <div class="imgt">
                                                    <img src={'https://img-api.neople.co.kr/df/servers/'+ characterObj.server +'/characters/'+ characterObj.id +'?zoom=1.jpg'}/>
                                                </div>
                                            </div>
                                            <div class="cabap">
                                                <ul class="hwimo">
                                                    <li>
                                                        <span>
                                                            <div className= {avaPresent == 13 ? "on" : "" }>
                                                                <img number={3013}   src="advent.png" onClick={() => setAvaPresent(13)}/>
                                                            </div>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            <div className= {avaPresent == 11 ? "on" : "" }>
                                                                <img number={3011}  src="hwi.png" onClick={ () => setAvaPresent(11)}/>
                                                            </div>
                                                        </span>
                                                    </li>
                                                </ul>
                                                    
                                                <ul>
                                                    <li>
                                                        <span>
                                                            <div className= {avaPresent == 10 ? "on" : "" }>
                                                            <img number={3010}  src="https://dundam.xyz/img/item/76b6e9f3fa9703f447cbb8bd4898c0d8" onClick={ () => setAvaPresent(10)}/>
                                                            </div>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <div className= {avaPresent == 1 ? "on" : "" }>
                                                            <img number={301}  src="https://dundam.xyz/img/item/20d6374d6ab02076f7c32153568431c9" onClick={ () => setAvaPresent(1)}/>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className= {avaPresent == 0 ? "on" : "" }>
                                                            <img number={300}  src="https://dundam.xyz/img/item/f11f806769afaae8b05be488e20b6109" onClick={ () => setAvaPresent(0)}/>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className= {avaPresent == 2 ? "on" : "" }>
                                                            <img number={302}  src="https://dundam.xyz/img/item/324c85d935a5e9358825380bf2065845" onClick={ () => setAvaPresent(2)}/>
                                                        </div>
                                                    </li>
                                                </ul>
                                                
                                                <ul>
                                                    <li>
                                                        <span>
                                                            <div className= {avaPresent == 9 ? "on" : "" }>
                                                            <img number={309}  src="https://dundam.xyz/img/item/73f53737906283e27aef171d7d10dc0f" onClick={ () => setAvaPresent(9)}/>
                                                            </div>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <div className= {avaPresent == 6 ? "on" : "" }>
                                                            <img number={306}  src="https://dundam.xyz/img/item/b5ccbf772a3883e3171f682679e2b421" onClick={ () => setAvaPresent(6)}/>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className= {avaPresent == 3 ? "on" : "" }>
                                                            <img number={303}  src="https://dundam.xyz/img/item/5838c99d8d457c6422a7b8027ef9f7df" onClick={ () => setAvaPresent(3)}/>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className= {avaPresent == 8 ? "on" : "" }>
                                                            <img number={308}  src="https://dundam.xyz/img/item/f5bf71e62cfe6015cad07b3e0dfe2107" onClick={ () => setAvaPresent(8)}/>
                                                        </div>
                                                    </li>
                                                </ul>
                                                
                                                
                                                <ul>
                                                    <li>
                                                        <span>
                                                            <div className= {avaPresent == 12 ? "on" : "" }>
                                                            <img number={3012}  src="https://dundam.xyz/img/item/01abefd5d1958134dfb0af9a8e0c3177" onClick={ () => setAvaPresent(12)}/>
                                                            </div>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <div  className= {avaPresent == 7 ? "on" : "" }>
                                                            <img number={307}  src="https://dundam.xyz/img/item/c81e24d23286a4897987547c5236edf1" onClick= { () => setAvaPresent(7)}/>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className= {avaPresent == 4 ? "on" : "" }>
                                                            <img number={304}  src="https://dundam.xyz/img/item/2fd4d583eef28dfcca026403aa786fa9" onClick={ () => setAvaPresent(4)}/>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className= {avaPresent == 5 ? "on" : "" }>
                                                            <img number={305}  src="https://dundam.xyz/img/item/30b8500c4447878005d9d1f433dac030" onClick={ () => setAvaPresent(5)}/>
                                                        </div>
                                                    </li>
                                                </ul>
                                            
                                            </div>

                                        </div>
                                        <div class="cus_button" onClick={()=>{ submitSetting()}}>
                                            <div class="c_but">
                                                <span className='cbut-tag'  >
                                                적용
                                                </span>
                                            </div>
                                        </div>
                                        
                                    <AvatarSetting id={0} />
                                    <AvatarSetting id={1} />
                                    <AvatarSetting id={2} />
                                    <AvatarSetting id={3} />
                                    <AvatarSetting id={4} />
                                    <AvatarSetting id={5} />
                                    <AvatarSetting id={6} />
                                    <AvatarSetting id={7} />
                                    <AvatarSetting id={8} />
                                    <AvatarSetting id={9} />
                                    <AvatarSetting id={10} />
                                    <AvatarSetting id={11} />
                                    <AvatarSetting id={12} />
                                    <AvatarSetting id={13} />
                                    </div>
                                </div>

                                <div class="tab__content" name="탈리스만">
                                    <div class="c_talisman">
                                        <div class="c_tal_select">
                                                <div class="ctal_bag">
                                                    <div class="ctal_c ctcfirst" onClick={()=>{setPresentTalis(1)}}>
                                                        <ul>
                                                            <li class="ctalc_main">
                                                                <div class="imgt">
                                                                    <img src={skillImage[selectTalisSkill[0]]}></img>
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal1">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune1type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}}  src={skillImage[characterData["rune1Skill"]]}></img>
                                                                </div>
                                                                
                                                            </li>
                                                            <li class="ctalc_tal2">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune2type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}}  src={skillImage[characterData["rune2Skill"]]}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal3">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune3type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={skillImage[characterData["rune3Skill"]]}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="ctal_c ctcsecond" onClick={()=>{setPresentTalis(2)}}>
                                                        <ul>
                                                            <li class="ctalc_main">
                                                                <div class="imgt">
                                                                    <img src={skillImage[selectTalisSkill[1]]}></img>
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal1">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune4type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={skillImage[characterData["rune4Skill"]]}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal2">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune5type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={skillImage[characterData["rune5Skill"]]}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal3">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune6type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={skillImage[characterData["rune6Skill"]]}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="ctal_c ctcthird" onClick={()=>{setPresentTalis(3)}}>
                                                        <ul>
                                                            <li class="ctalc_main">
                                                                <div class="imgt">
                                                                    <img  src={skillImage[selectTalisSkill[2]]}></img>
                                                                
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal1">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune7type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={skillImage[characterData["rune7Skill"]]}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal2">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune8type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={skillImage[characterData["rune8Skill"]]}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                            <li class="ctalc_tal3">
                                                                <div class="imgt" style={{'background' : talismanColor(characterData["rune9type"])}}>
                                                                    <img style={{'mix-blend-mode' : 'luminosity'}} src={skillImage[characterData["rune9Skill"]]}></img>
                                                                    
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        <div class="cus_button" onClick={()=>{submitSetting()}}  >
                                            <div class="c_but">
                                                <span className='cbut-tag'  >
                                                적용
                                                </span>
                                            </div>
                                            
                                        </div>
                                        
                                        <TalismanSetting id={1}/>
                                        <TalismanSetting id={2}/>
                                        <TalismanSetting id={3}/>

                                    </div>
                                </div>
                                <div class="tab__content" name="장비특성">
                                    <div class="c_equip_feature">

                                        {/* <div class="bot-notifi">
                                            <div class="bnot-con">
                                                <div class="bont-head">
                                                    <div class="b-head-mark">
                                                    <span>!</span>
                                                    </div>
                                                </div>
                                                <div class="bnot-text">
                                                    <span>장비특성 데이터 수집 중으로 수치가 정확하지 않을 수 있습니다.</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div class="c_e_fea_hug new_feature_patch_v1">
                                            <div class="cefea-top">
                                                <div class="cfa-select">
                                                    <ul>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 1 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(1)}>
                                                                <img src={'/reSpec/1'+(selectSpecType == 1 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">규율</span>
                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 2 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(2)}>
                                                                <img src={'/reSpec/2'+(selectSpecType == 2 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">맹공</span>

                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 3 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(3)}>
                                                                <img src={'/reSpec/3'+(selectSpecType == 3 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">보호</span>

                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 4 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(4)}>
                                                                <img src={'/reSpec/4'+(selectSpecType == 4 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">구현</span>

                                                   
                                                        </li>
                                                    </ul>
                                                </div>
                                          
                                            </div>
                                            <div class="cefea-mid">
                                                <div class="cfa-skill-selcet">
                                                    <div class="cfa-select">
                                                        <ul>
                                                    
                                                            <li>
                                                                <Trait slot="11" type="spec0"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="12" type="spec0"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="13" type="spec0"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="14" type="spec0"/>
                                                            </li>
                                                        </ul>
                                           
                                                        <ul>
                                                    
                                                            <li>
                                                                <Trait slot="21" type="spec1"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="22" type="spec1"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="23" type="spec1"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="24" type="spec1"/>
                                                            </li>
                                                        </ul>

                                                        <ul>
                                                    
                                                            <li>
                                                                <Trait slot="31" type="spec2"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="32" type="spec2"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="33" type="spec2"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="34" type="spec2"/>
                                                            </li>
                                                        </ul>

                                                        <ul>
                                                    
                                                            <li>
                                                                <Trait slot="41" type="spec3"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="42" type="spec3"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="43" type="spec3"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="44" type="spec3"/>
                                                            </li>
                                                        </ul>

                                                        <ul>
                                                            <li>
                                                                <Trait slot="51" type="spec4"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="52" type="spec4"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="53" type="spec4"/>
                                                            </li>
                                                            <li>
                                                                <Trait slot="54" type="spec4"/>
                                                            </li>
                                                        </ul>
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div class="c_e_fea_hug">
                                            <div class="cefea-top">
                                                <div class="cfa-select">
                                                    <ul>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 1 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(1)}>
                                                                <img src={'/reSpec/1'+(selectSpecType == 1 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">상태이상</span>
                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 2 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(2)}>
                                                                <img src={'/reSpec/2'+(selectSpecType == 2 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">MP관리</span>

                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 3 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(3)}>
                                                                <img src={'/reSpec/3'+(selectSpecType == 3 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">유틸</span>

                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 4 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(4)}>
                                                                <img src={'/reSpec/4'+(selectSpecType == 4 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">스킬</span>

                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 5 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(5)}>
                                                                <img src={'/reSpec/5'+(selectSpecType == 5 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">컨트롤/생존</span>

                                                        </li>
                                                        <li>
                                                            <div class={"imgt " + (selectSpecType == 6 ? "cfa-selet-active" :"") } onClick={()=>setSelectSpecType(6)}>
                                                                <img src={'/reSpec/6'+(selectSpecType == 6 ? "active" :"")+'.png'}></img>
                                                            </div>
                                                            <span class="fts-tit">버퍼</span>

                                                        </li>
                                                    </ul>
                                                </div>
                                          
                                            </div>
                                            <div class="cefea-mid">
                                                <div class="cfa-skill-selcet">
                                                    <div class="cfa-select">
                                                        <ul>
                                                            <li>
                                                                <div class="csk-sel-tit">
                                                                    <span>LV.1</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">
                                                                    <div class="imgt" onClick={()=>{characterData['spec0'] != selectSpecType+'11' ? change("spec0",selectSpecType+'11') : change("spec0", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'11'+(characterData['spec0'] == selectSpecType+'11' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"11"] ? trait[selectSpecType+"11"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec0'] != selectSpecType+'12' ? change("spec0",selectSpecType+'12') : change("spec0", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'12'+(characterData['spec0'] == selectSpecType+'12' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                            <div class="ef-ab-cont">
                                                                                <span>{trait[selectSpecType+"12"] ? trait[selectSpecType+"12"] : "" }</span>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">
                                                                    <div class="imgt" onClick={()=>{characterData['spec0'] != selectSpecType+'13' ? change("spec0",selectSpecType+'13') : change("spec0", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'13'+(characterData['spec0'] == selectSpecType+'13' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"13"] ? trait[selectSpecType+"13"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">
                                                                    <div class="imgt" onClick={()=>{characterData['spec0'] != selectSpecType+'14' ? change("spec0",selectSpecType+'14') : change("spec0", 0) }}>
                                                                    <img src={'/reSpec/'+selectSpecType+'14'+(characterData['spec0'] == selectSpecType+'14' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"14"] ? trait[selectSpecType+"14"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul class="fea-2">
                                                            <li>
                                                                <div class="csk-sel-tit">
                                                                    <span>LV.120</span>
                                                                </div>
                                                            </li>
                                                            <li class="fea-2-c">
                                                                <div class="cfa-hover-side">
                                                                    <div class="imgt" onClick={()=>{characterData['spec4'] != selectSpecType+'41' ? change("spec4",selectSpecType+'41') : change("spec4", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'41'+(characterData['spec4'] == selectSpecType+'41' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"41"] ? trait[selectSpecType+"41"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li class="fea-2-c">
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec4'] != selectSpecType+'42' ? change("spec4",selectSpecType+'42') : change("spec4", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'42'+(characterData['spec4'] == selectSpecType+'42' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                            <div class="ef-ab-cont">
                                                                                <span>{trait[selectSpecType+"42"] ? trait[selectSpecType+"42"] : "" }</span>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                  
                                                        </ul>
                                                        <ul>
                                                            <li>
                                                                <div class="csk-sel-tit">
                                                                    <span>LV.240</span>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">
                                                                    <div class="imgt" onClick={()=>{characterData['spec1'] != selectSpecType+'21' ? change("spec1",selectSpecType+'21') : change("spec1", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'21'+(characterData['spec1'] == selectSpecType+'21' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"21"] ? trait[selectSpecType+"21"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec1'] != selectSpecType+'22' ? change("spec1",selectSpecType+'22') : change("spec1", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'22'+(characterData['spec1'] == selectSpecType+'22' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"22"] ? trait[selectSpecType+"22"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec1'] != selectSpecType+'23' ? change("spec1",selectSpecType+'23') : change("spec1", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'23'+(characterData['spec1'] == selectSpecType+'23' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"23"] ? trait[selectSpecType+"23"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec1'] != selectSpecType+'24' ? change("spec1",selectSpecType+'24') : change("spec1", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'24'+(characterData['spec1'] == selectSpecType+'24' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"24"] ? trait[selectSpecType+"24"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                        <ul class="fea-2">
                                                            <li>
                                                                <div class="csk-sel-tit">
                                                                    <span>LV.360</span>
                                                                </div>
                                                            </li>
                                                            <li class="fea-2-c">
                                                                <div class="cfa-hover-side">
                                                                    <div class="imgt" onClick={()=>{characterData['spec5'] != selectSpecType+'41' ? change("spec5",selectSpecType+'41') : change("spec5", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'41'+(characterData['spec5'] == selectSpecType+'41' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"41"] ? trait[selectSpecType+"41"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li class="fea-2-c">
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec5'] != selectSpecType+'42' ? change("spec5",selectSpecType+'42') : change("spec5", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'42'+(characterData['spec5'] == selectSpecType+'42' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                            <div class="ef-ab-cont">
                                                                                <span>{trait[selectSpecType+"42"] ? trait[selectSpecType+"42"] : "" }</span>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        
                                                        </ul>
                                                        <ul>
                                                            <li>
                                                                <div class="csk-sel-tit">
                                                                    <span>LV.480</span>
                                                                </div>
                                                            </li>
                                                         
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec2'] != selectSpecType+'31' ? change("spec2",selectSpecType+'31') : change("spec2", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'31'+(characterData['spec2'] == selectSpecType+'31' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                
                                                                     <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"31"] ? trait[selectSpecType+"31"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec2'] != selectSpecType+'32' ? change("spec2",selectSpecType+'32') : change("spec2", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'32'+(characterData['spec2'] == selectSpecType+'32' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"32"] ? trait[selectSpecType+"32"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec2'] != selectSpecType+'33' ? change("spec2",selectSpecType+'33') : change("spec2", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'33'+(characterData['spec2'] == selectSpecType+'33' ? "active" : "")+'.png'}></img>
                                                                    </div>
                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"33"] ? trait[selectSpecType+"33"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div class="cfa-hover-side">

                                                                    <div class="imgt" onClick={()=>{characterData['spec2'] != selectSpecType+'34' ? change("spec2",selectSpecType+'34') : change("spec2", 0) }}>
                                                                        <img src={'/reSpec/'+selectSpecType+'34'+(characterData['spec2'] == selectSpecType+'34' ? "active" : "")+'.png'}></img>
                                                                    </div>

                                                                    <div class="euip-fea-addbox">
                                                                        <div class="ef-ab-cont">
                                                                            <span>{trait[selectSpecType+"34"] ? trait[selectSpecType+"34"] : "" }</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </li>
                                                        </ul>
                                  
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div class="cus_button" onClick={()=>{ submitSetting()}}>
                                            <div class="c_but">
                                                <span className='cbut-tag'  >
                                                적용
                                                </span>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div id="ad-container-2" />
                </div>

            

            </section>
            
        )

        
}


/**
변수 변경 ex) 장비 이름 변경시 change('equipName0','불가침의 영역 - 소검');
@param {*} name 변경할 변수 이름 - equip : name,level,reinforceNum,reinforceType,enchant
@param {*} value 변경 값 
*/
function weaponPlusChange(index,value)
{
    
    let temp = [...weaponPlusArray];

    temp[index] = value;
    
    
    setWeaponPlusArray(temp);
}

function legendStoneChange(slot,index,value)
{
    
    let temp = legendStoneArray.map(v => [...v]);

    temp[slot-10][index] = value;
    
    
    setLegendStoneArray(temp);
}

function legendStoneSelectChange(slot,index,value)
{
    
    let temp = legendStoneSelectArray.map(v => [...v]);

    temp[slot-10][index] = value;
    
    
    setLegendStoneSelectArray(temp);
}



function weaponPlusSelectArrayChange(index,value)
{
    
    //console.log(index);
    //console.log(value);
    
    let temp = [...weaponPlusSelectArray];

    temp[index] = value;
    //console.log(temp);
    
    
    setWeaponPlusSelectArray(temp);
}


/**
변수 변경 ex) 장비 이름 변경시 change('equipName0','불가침의 영역 - 소검');
@param {*} name 변경할 변수 이름 - equip : name,level,reinforceNum,reinforceType,enchant
@param {*} value 변경 값 
*/
function customChange(id,index,value)
{
    
    let temp = {...customArray};

    temp[id][index] = value;
    
    
    setCustomArray(temp);
}

/**
변수 변경 ex) 장비 이름 변경시 change('equipName0','불가침의 영역 - 소검');
@param {*} name 변경할 변수 이름 - equip : name,level,reinforceNum,reinforceType,enchant
@param {*} value 변경 값 
*/
function customSelectChange(id,index,value)
{
    
    let temp = {...customSelectArray};

    temp[id][index] = value;
    
    
    setCustomSelectArray(temp);
}


function customSearchChange(id,index,value)
{
    
    let temp = {...customSearch};

    temp[id][index] = value;
    
    
    setCustomSearch(temp);
}



function weaponOpSearchChange(index,value)
{
    
    let temp = {...weaponOpSearch};

    temp[index] = value;
    
    
    setWeaponOpSearch(temp);
}


/**
변수 변경 ex) 장비 이름 변경시 change('equipName0','불가침의 영역 - 소검');
@param {*} name 변경할 변수 이름 - equip : name,level,reinforceNum,reinforceType,enchant
@param {*} value 변경 값 
*/
function change(name,value,index = 0)
{
    
    let temp = {...characterData};

    if(index == 0)
    {
        temp[name] = value;
    }
    
    if(name.indexOf('equipLevel') != -1)
    {
        if(temp['itemLevel'+name.replace('equipLevel','').replace('C0','')] == 110)
        {
            if(value >= 60)
            {
                temp[name] = 60;
            }
        }
        else
        {
            if(value >= 80)
            {
                temp[name] = 80;
            }
        }
    }

    if(name.indexOf('itemLevel') != -1)
    {
        //console.log(name);
        //console.log(value);
        if(value == 110)
        if(temp['equipLevel'+name.replace('itemLevel','')+'C0'] > 40)
        {
            temp['equipLevel'+name.replace('itemLevel','')+'C0'] = 40;
        }
    }

    if(name.indexOf('equipName1') != -1)
    {
        if(value.indexOf("피해 증가") != -1)
        {
            temp['equipValue1'] = 18;
        }
    }

    if(name.indexOf('equipName') != -1)
    {
        if(value.indexOf("불가침의 영역") != -1)
        {
            temp['itemLevel'+name.replace('equipName','')] = 105;
            temp['equipLevel'+name.replace('equipName','')+'C0'] = 80;
        }
    }

    setCharacterData(temp);
    

}

/**
장비 한 부위 모든 레벨 수정
@param {*} name 'equipLevel0' ~ 'equipLevel12' 
@param {*} value '원하는 레벨값'
*/
function lvAllChange(name,value)
{
    let temp = {...characterData};

    for(let loop = 0 ; loop < 4 ; loop++)
    {
        temp[name+'C'+loop] = value;
    }
    
    setCharacterData(temp);

}

/**
전체장비 모든 레벨 수정
@param {*} value '원하는 레벨값'
*/
function allEquipLvAllChange(value)
{
    
    let temp = {...characterData};
    for(let loop = 0 ; loop < 13 ; loop++)
    {
        if(loop == 1) continue;

        for(let lvloop = 0 ; lvloop < 4 ; lvloop++)
        {
            temp['equipLevel'+loop+'C'+lvloop] = value;
        }
    }
    
    setCharacterData(temp);

}

function ItemOption(props)
{
    return (
        
        <>
        {

            lv105OptionMap[props.name] != null ?                                           
            <div class="option">
                <span class="op_gold">피해 증가</span> + {lv105OptionMap[props.name] != null ? lv105OptionMap[props.name].split('@@')[1] : ''}
                <br/>
                {lv105OptionMap[props.name] != null ? lv105OptionMap[props.name].split('@@')[0].replace(/<br>/gi,'\n')  : ''}
            </div>
            : '' 
        }
        </>
    
                        
        
        
    )
}

function PresentEquip(props)
{

    let temp = characterData['equipName'+props.number];
    let number = lv105EquipImage[temp];

    //console.log(number);

    let x = 0;
    let y = 0;
    if(number != null)
    {
        x = (number-1) % 9;
        y = Math.trunc((number-1)/ 9) ;
    }
    //console.log(x);
    //console.log(y);

    return(
        <li onClick={ () => {setPresent(props.number)} } style={ present == props.number ? {border : '2px solid red'}  : {}} >
            <span>
                <div class="imgt">
                    {
                        // props.number > 1 ? <div  name={ temp} className={"weaponSprite"} loading="lazy" 
                        // style={{"zoom":"2.0982","background-image":"url('https://dundam.xyz/img/sprite/lv105EquipImage.png')"
                        //  , "width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"} }></div> : <></>
                    }

                    {
                        props.number != 1 ? <img src={'https://dundam.xyz/img/item/'+lv105ItemId[characterData['equipName'+props.number]]}  /> : <></>
                    }

                    {
                        props.number == 1 ? <img src={'https://dundam.xyz/img/item/'+titleToImage()}  /> : <></>
                    }

                    
                    
                </div>
                <div class="cus_stat">
                    <div class="cs_p">+{characterData['equipReinforceNum'+props.number]}</div>
                    <div class="cs_at">{characterData['siroco'+props.number] != null ? characterData['siroco'+props.number].charAt(0) : ""}</div>
                    <div class="cs_st">
                        <div class="csu_div">
                            <EnchantPreview id={props.number}/>
                        </div>
                        
                    </div>
                </div>
            </span>
        </li>
    )
}
function TitleSetting(props)
{
    
    return(

        <div class="cus_sest" style={props.id == present ? {'whiteSpace': 'pre-line'} : {display:'none'} }>

                <div class="esetting_tr cusper73 cusp6">
                    <div class="estcont">
                        <div class="cus_chkbut cusb8">
                        
                            <ul>
                                <li onClick={()=>change("equipName1","데미지 증가")} className={characterData['equipName1'] == "데미지 증가" ? "is-active" : ""}>데미지 증가</li>
                                <li onClick={()=>change("equipName1","크리티컬 증가 데미지")} className={characterData['equipName1'] == "크리티컬 증가 데미지" ? "is-active" : ""}>크리티컬 증가 데미지</li>
                            </ul>
                            <ul>
                                <li onClick={()=>change("equipName1","추가 데미지 증가")} className={characterData['equipName1'] == "추가 데미지 증가" ? "is-active" : ""}>추가 데미지 증가</li>
                                <li onClick={()=>change("equipName1","속성 강화")} className={characterData['equipName1'] == "속성 강화" ? "is-active" : ""}>속성 강화</li>
                            </ul>
                            <ul>
                                <li onClick={()=>{change("equipName1","피해 증가"); }} className={characterData['equipName1'] == "피해 증가" ? "is-active" : ""}>피해 증가</li>
                                <li onClick={()=>change("equipName1","세리아의 풍성한 선물")} className={characterData['equipName1'] == "세리아의 풍성한 선물" ? "is-active" : ""}>세리아의 풍성한 선물</li>
                            </ul>
                        </div>
                    </div>
                    <div class="estcont cjae">
                        <div class="cus_chkbut">
                            <ul>
                                <li>수치</li>
                            </ul>
                        </div>

                        <div class="cus_range">
                            <span id="rangeValue">{characterData['equipValue1']}</span>
                            <input class="crange" type="range" value={characterData['equipValue1']} min={characterData['equipName1'] == "피해 증가" ? 18 : 10} max={characterData['equipName1'] == "속성 강화" ? 35 : 18} step="1"
                            onChange={(event)=>change("equipValue1",event.target.value)}></input>
                        </div>
                        
                    </div>
                    
                    
                    
                    
                    
                </div>

                <div class="esetting_tr cusper73 cusp6">
                    <div class="estcont" >
                        <div class="cus_chkbut cusb8">
                        
                            <ul>
                                <li onClick={()=>{characterData['equip1Skill'] == "30"? change("equip1Skill","0") : change("equip1Skill","30")}} className={characterData['equip1Skill'] == "30" ? "is-active" : ""} lv="30">30Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "35"? change("equip1Skill","0") : change("equip1Skill","35")}} className={characterData['equip1Skill'] == "35" ? "is-active" : ""} lv="35">35Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "40"? change("equip1Skill","0") : change("equip1Skill","40")}} className={characterData['equip1Skill'] == "40" ? "is-active" : ""}lv="40">40Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "45"? change("equip1Skill","0") : change("equip1Skill","45")}} className={characterData['equip1Skill'] == "45" ? "is-active" : ""}lv="45">45Lv</li>
                            </ul>
                            <ul>
                                <li onClick={()=>{characterData['equip1Skill'] == "60"? change("equip1Skill","0") : change("equip1Skill","60")}} className={characterData['equip1Skill'] == "60" ? "is-active" : ""} lv="60">60Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "70"? change("equip1Skill","0") : change("equip1Skill","70")}} className={characterData['equip1Skill'] == "70" ? "is-active" : ""} lv="70">70Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "75"? change("equip1Skill","0") : change("equip1Skill","75")}} className={characterData['equip1Skill'] == "75" ? "is-active" : ""} lv="75">75Lv</li>
                                <li onClick={()=>{characterData['equip1Skill'] == "80"? change("equip1Skill","0") : change("equip1Skill","80")}} className={characterData['equip1Skill'] == "80" ? "is-active" : ""} lv="80">80Lv</li>
                            </ul>
                        </div>
                        
                    </div>
                    
                </div>
                    
                <div class="esetting_tr cusp2">
                    <div class="estcont  cussa">
                        <div class="cus_esttit">
                            <ul>
                                <li>스탯</li>
                            </ul>
                        </div>

                        <div class="cus_range">
                            <span id="rangeValue">{'\u00A0'}</span>
                            <div class="cus_rare">
                                
                                <input type="text" minlength="1" maxlength="2" value={characterData['equipEnchantStat'+props.id]}
                                onChange={ (event) => change('equipEnchantStat'+props.id,event.target.value)} >

                                </input>
                            </div>
                            <input class="crange" type="range" value={characterData['equipEnchantStat'+props.id]} min="0" max={dStat[props.id]}
                                 onChange={ (event) => change('equipEnchantStat'+props.id,event.target.value)} />
                        </div>
    
                    </div>
                    <div class="estcont  cussa">
                    </div>
                    <div class="estcont  cussa">
                        <div class="cus_esttit">
                            <ul>
                                <li>속강</li>
                            </ul>
                        </div>

                        <div class="cus_range">
                            <span id="rangeValue">{'\u00A0'}</span>
                            <div class="cus_rare">
                                
                                <input type="text" minlength="1" maxlength="3" value={characterData['equipEnchantEle'+props.id]}
                                onChange={ (event) => change('equipEnchantEle'+props.id,event.target.value)} >

                                </input>
                            </div>
                            <input class="crange" type="range" value={characterData['equipEnchantEle'+props.id]} min="0" max={dEle[props.id]}
                                 onChange={ (event) => change('equipEnchantEle'+props.id,event.target.value)} />
                        </div>
    

                    </div>
                </div>

        </div>
    )

}

//추가 장비

function SubWeaponSetting(props)
{
    return(
        <div class="esetting_tr cusp1" style={present == 116 ? {} : {display : 'none'}}>
            <div class="estcont">

                <div class="cus_chkbut">
                    <ul>
                        <li onClick={()=> change('subWeaponReinforceType','강화')} className={characterData['subWeaponReinforceType'] == '강화' ? 'is-active' : ''}>강화</li>
                        <li onClick={()=> change('subWeaponReinforceType','증폭')} className={characterData['subWeaponReinforceType'] == '증폭' ? 'is-active' : ''}>증폭</li>
                    </ul>
                </div>

                <div class="cus_range">
                <span id="rangeValue">{'\u00A0'}</span>
                    <div class="cus_rare">
                        <input type="text" minlength="1" maxlength="2" value={characterData['subWeaponReinforce']}
                        onChange={ (event) => change('subWeaponReinforce',event.target.value)} >

                        </input>
                    </div>
                    <input class="crange" type="range" value={characterData['subWeaponReinforce']} min="0" max="18"
                        onChange={ (event) => change('subWeaponReinforce',event.target.value)} >
                    </input>
                </div>
            </div>
        </div> 
        
    )
}
function SubWeapon(props)
{
    return(
        <li onClick={ () => {setPresent(props.number)} } style={ present == props.number ? {border : '2px solid red'}  : {}} >
            <span>
                <div class="imgt">
                    <img src={'https://dundam.xyz/img/item/1666f7be2f22b01b4bbb559c7bc51c99'}  />
                </div>
                <div class="cus_stat">
                    <div class="cs_p">+{characterData['subWeaponReinforce']}</div>
                </div>
            </span>


        </li>
    )
}
function PlusEquip(props)
{
    return(
        <li onClick={ () => {setPresent(props.number)} } style={ present == props.number ? {border : '2px solid red'}  : {}} >
            <span>
                <div class="imgt">
                    {
                        props.number == 113 ? <img src={'https://dundam.xyz/img/item/5838c99d8d457c6422a7b8027ef9f7df'}  /> : <></>
                    }
                    {
                        props.number == 114 ? <img src={'https://dundam.xyz/img/item/2fd4d583eef28dfcca026403aa786fa9'}  /> : <></>
                    }
                    
                </div>
                
            </span>
        </li>
    )
}

function PlusTitle(props)
{
    return(
        <li onClick={ () => {setPresent(props.number)} } style={ present == props.number ? {border : '2px solid red'}  : {}} >
            <span>
                <div class="imgt">
                    {
                        props.number == 115 ? <img src={'https://dundam.xyz/img/item/'+titleToImageBuffer(characterData['buffTitle'])}  /> : <></>
                    }
                    {
                        props.number == 1 ? <img src={'https://dundam.xyz/img/item/'+titleToImageBuffer(characterData['equipName1'])}  /> : <></>
                    }

                </div>
                
            </span>
        </li>
    )
}

function titleToImage(input)
{
    let name = characterData['equipName1'];
    let value = characterData['equipValue1'];
    
    
    if(name.indexOf('피해 증가') != -1 )
    {
        if(value == 18) return "c89a01593a155a3cbaaf272084d8fb57";
    }
    if(name.indexOf('세리아의 풍성한 선물') != -1 )
    {
        return "29f088750881775babb636fdfda1d705";
    }

    if(name.indexOf('데미지 증가') != -1 )
    {
        if(value == 10) return "ef0aeb22ce891560570c260f55f3e93a";
        if(value == 15) return "716318716889dede836f92308e652035";
    }
    
    if(name.indexOf('크리티컬 증가 데미지') != -1 )
    {
        if(value == 10) return "67854cb66148289ea8dadcd1a3246055";
    }

    if(name.indexOf('추가 데미지 증가') != -1 )
    {
        if(value == 10) return "3b6093bb111cafc013b566dbf8c60792";
    }
    
    if(name.indexOf('속성 강화') != -1 )
    {
        if(value == 35) return "1e4e58fc3af28a88cbef9da09b8e937d";
        if(value == 32) return "74b07e0f284788f0c30dd340e7dbb2ec";
        if(value == 16) return "3fe950db4b3565f8d1ef6f616a6ecefb";
    }


}

function titleToImageBuffer(input)
{
    if(input != null)   
    if(input.indexOf('성역의 경계') != -1)
    {
        return "ef0aeb22ce891560570c260f55f3e93a";
    }
    else if(input.indexOf('세리아의 풍성한 선물') != -1 )
    {
        return "29f088750881775babb636fdfda1d705";
    }
    else if(input.indexOf('30Lv') != -1)
    {
        return "9278f6124ab894a40cb52793561439e9";
    }
    else if(input.indexOf('50Lv') != -1)
    {
        return "4ebfb6cd76c82aefb2f0be124974a5fd";
    }
    else if(input.indexOf('버프력') != -1)
    {
        return "c89a01593a155a3cbaaf272084d8fb57";
    }
    else if(input.indexOf('스탯 + 100') != -1)
    {
        return "875c6c5e8d257a0d844ac968779d3988";
    }
    
}

function PlusTitleSetting(props)
{
    return(
        <div class="cus_set"  style={present == props.id ? {} : {display : 'none'}}>
            <div class="cus_esetting">
                    
                <div class="esetting_tr cusper73 cusp6">
                    <div class="estcont" >
                        <div class="cus_chkbut cusb8">
                        
                            <ul>
                                <li onClick={()=>{change("buffTitle","없음")}} className={characterData["buffTitle"] == '없음' ? "is-active" : ""}>없음</li>
                                <li onClick={()=>{change("buffTitle","스탯 + 100")}} className={characterData["buffTitle"] == '스탯 + 100' ? "is-active" : ""}>스탯 + 100</li>
                                <li onClick={()=>{change("buffTitle","30Lv + 1")}} className={characterData["buffTitle"] == '30Lv + 1' ? "is-active" : ""}>30Lv + 1</li>
                                <li onClick={()=>{change("buffTitle","30Lv + 2")}} className={characterData["buffTitle"] == '30Lv + 2' ? "is-active" : ""}>30Lv + 2</li>
                                <li onClick={()=>{change("buffTitle","성역의 경계")}} className={characterData["buffTitle"] == '성역의 경계' ? "is-active" : ""}>성역의 경계</li>
                                <li onClick={()=>{change("buffTitle","버프력 + 2%")}} className={characterData["buffTitle"] == '버프력 + 2%' ? "is-active" : ""}>버프력 + 2%</li>
                                <li onClick={()=>{change("buffTitle","세리아의 풍성한 선물")}} className={characterData["buffTitle"] == '세리아의 풍성한 선물' ? "is-active" : ""}>세리아의 풍성한 선물</li>
                            </ul>
                            
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
        </div>

    )
}

function MainTitleSetting(props)
{
    return(
        <div class="cus_set"  style={present == props.id ? {} : {display : 'none'}}>
            <div class="cus_esetting">
                    
                <div class="esetting_tr cusper73 cusp6">
                    <div class="estcont" >
                        <div class="cus_chkbut cusb8">
                        
                            <ul>
                                <li onClick={()=>{change("equipName1","없음")}} className={characterData["equipName1"] == '없음' ? "is-active" : ""}>없음</li>
                                <li onClick={()=>{change("equipName1","스탯 + 100")}} className={characterData["equipName1"] == '스탯 + 100' ? "is-active" : ""}>스탯 + 100</li>
                                <li onClick={()=>{change("equipName1","50Lv + 1")}} className={characterData["equipName1"] == '50Lv + 1' ? "is-active" : ""}>50Lv + 1</li>
                                <li onClick={()=>{change("equipName1","50Lv + 2")}} className={characterData["equipName1"] == '50Lv + 2' ? "is-active" : ""}>50Lv + 2</li>
                                <li onClick={()=>{change("equipName1","버프력 + 2%")}} className={characterData["equipName1"] == '버프력 + 2%' ? "is-active" : ""}>버프력 + 2%</li>
                                <li onClick={()=>{change("equipName1","세리아의 풍성한 선물")}} className={characterData["equipName1"] == '세리아의 풍성한 선물' ? "is-active" : ""}>세리아의 풍성한 선물</li>
                            </ul>
                            
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
        </div>

    )
}

function PlusSetting(props)
{
    return(
        <div class="c_abata" style={present == props.id ? {} : {display : 'none'}}>
            <div class="cus_set sabatcus" >
                {
                props.id == 113 || props.id == 114 ? 
                    <div class="saba_basic" name="아바타 기본형">
                        <div class="abat_tit">
                            <span>{avatarTypeList[props.id - 110]}</span>
                        </div>
                        {
                                props.id != 114 ? 
                                <div class="abat_select">
                                <select id="v212" value={characterData["abaOption"+props.id]}  onChange={(event) => change("abaOption"+props.id,event.target.value)}>
                                    
                                    <option value={'0'}>없음</option>
                                    <option value={'30Lv + 1'}>30Lv + 1</option>
                            
                                </select>
                            </div> : ""
                        }
                        

                        { (props.id == 113 || props.id == 114) ?
                            <div class="abat_select" >
                                <select id="v212" value={characterData["emblemSkill"+props.id]} onChange={(event) => change("emblemSkill"+props.id,event.target.value)}>
                                    
                                    <option value={'0'}>없음</option>
                                    <option value={'30Lv + 1'}>30Lv + 1</option>
                                

                                </select>
                            </div> : <></>
                        }
                        
                        <div class="cus_esetting abatnum">
                            <div class="esetting_tr cusp2">
                                <div class="estcont  cussa">
                                    <div class="cus_esttit">
                                        <ul>
                                            <li>스탯</li>
                                        </ul>
                                    </div>

                                    <div class="cus_range">
                                        <span id="rangeValue">{characterData['emblemStat0'+props.id]}</span>
                                        <input class="crange" type="range" value={characterData['emblemStat0'+props.id]} min="0" max="25" step="1" onInput={(event)=>change("emblemStat0"+props.id,event.target.value)} >
                                        </input>
                                    </div>
                                </div>
                                <div class="estcont  cussa">
                                    <div class="cus_esttit">
                                        <ul>
                                            <li>스탯</li>
                                        </ul>
                                    </div>

                                    <div class="cus_range">
                                        <span id="rangeValue">{characterData['emblemStat1'+props.id]}</span>
                                        <input class="crange" type="range" value={characterData['emblemStat1'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemStat1"+props.id,event.target.value)} >
                                        </input>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        

                    </div>
                : 
                <></>
                
        }
        
            </div>
        </div>

    )
}

function Setting(props)
{
    let dESLTemp = "";
    let dESLName = "";

    if(dESL[props.id] != null)
    {
        dESLTemp = '스증';
        dESLName = '스증';
    }


    if(dESL[props.id]['stat'] != null)
    {
        dESLTemp = statSkill;
        dESLName = 'stat';
    }
    else if(dESL[props.id]['awake'] != null)
    {
        dESLTemp = awakeSkill;
        dESLName = 'awake';
    }

    return(
        <div class="cus_sest" style={props.id == present ? {'whiteSpace': 'pre-line'} : {display:'none'} }>
            <div class="cus_esetting">
                {
                    props.id == 0 ? 
                    <div class="epiup-mainweapontype-select">
                        <div class="ems-content-hug">
                            <div class="ecslect-con">
                                <div class="ems-sel-tit">
                                    <span>무기선택</span>
                                </div>
                                <div class="ems-sel-list">
                                    <div class="eslist-con-hug">
                                        <div class="eslist-con">
                                            <ul>
                                                
                                                {
                                                    weaponList.map((a,i) =>
                                                    {
                                                        return(
                                                            <li class={selectWeaponType == i ? "is-active" : "" } onClick={()=> {setSelectWeaponType(i)}}><span>{a}</span></li>
                                                        )
                                                    })
                                                }
                                               
                                                

                                            </ul>
                                        </div>

                                    </div>

                                </div>
                                <WeaponList id={selectWeaponType}/>
                                {/* <WeaponList id='1'/>
                                <WeaponList id='2'/>
                                <WeaponList id='3'/>
                                <WeaponList id='4'/> */}

                                
                            </div>
                            
                        </div>
                    </div>
                    
                    
                    : <></>
                    
                }
                {
                    props.id != 0 ?
                    
                    <div class="esetting_tr cusp1">
                        <div class="estcont">

                            <div class="cus_chkbut cusbut-fix">
                                <ul>
                                    <li onClick={()=> change('equipReinforceType'+props.id,'강화')} className={characterData['equipReinforceType'+props.id] == '강화' ? 'is-active' : ''}>강화</li>
                                    <li onClick={()=> change('equipReinforceType'+props.id,'증폭')} className={characterData['equipReinforceType'+props.id] == '증폭' ? 'is-active' : ''}>증폭</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    <input type="text" minlength="1" maxlength="2" value={characterData['equipReinforceNum'+props.id]}
                                    onChange={ (event) => change('equipReinforceNum'+props.id,event.target.value)} >

                                    </input>
                                </div>
                                <input class="crange" type="range" value={characterData['equipReinforceNum'+props.id]} min="0" max="18"
                                 onChange={ (event) => change('equipReinforceNum'+props.id,event.target.value)} >
                                </input>
                            </div>
                     
                        </div>
                     
                    </div> : <></>

                }
                {
                    /*
                }
                <div class="esetting_tr est_twe cusp1">
                    <div class="estcont">
                        <div class="cus_chkbut">
                            <ul>
                                <li className={characterData['equipReinforceType'+props.id] == '강화' ? 'is-active' : ''}>강화</li>
                                <li className={characterData['equipReinforceType'+props.id] == '증폭' ? 'is-active' : ''}>증폭</li>
                                <li className={characterData['equipReinforceType'+props.id] == '개조' ? 'is-active' : ''}>개조</li>
                            </ul>
                        </div>

                        <div class="cus_range">
                            <span id="rangeValue">0</span>
                            <div class="cus_rare">
                                <input type="text" minlength="1" maxlength="3">

                                </input>
                                
                            </div>
                            <input class="crange" type="range" value="0" min="0" max="17"></input>
                            
                        </div>
                    
                    </div>
                    <div class="estcont">
                        <div class="cus_chkbut">
                            <ul>
                                <li className={characterData['equipReinforceType'+props.id] == '강화' ? 'is-active' : ''}>강화</li>
                                <li className={characterData['equipReinforceType'+props.id] == '증폭' ? 'is-active' : ''}>증폭</li>
                                <li className={characterData['equipReinforceType'+props.id] == '개조' ? 'is-active' : ''}>개조</li>
                            </ul>
                        </div>

                        <div class="cus_range">
                            <span id="rangeValue">0</span>
                            <div class="cus_rare">
                                <input type="text" minlength="1" maxlength="3">

                                </input>
                                
                            </div>
                            <input class="crange" type="range" value="0" min="0" max="17"></input>
                            
                        </div>
                    
                    </div>
                </div>
                {
                    */
                }
                {
                    props.id == 0 ? 
                    <div class="esetting_tr cusper73 cusp1">
                        <div class="estcont">
                            <div class="cus_chkbut">
                                <ul>
                                    <li onClick={()=> change('equipReinforceType'+props.id,'강화')} className={characterData['equipReinforceType'+props.id] == '강화' ? 'is-active' : ''}>강화</li>
                                    <li onClick={()=> change('equipReinforceType'+props.id,'증폭')} className={characterData['equipReinforceType'+props.id] == '증폭' ? 'is-active' : ''}>증폭</li>
                               </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    <input type="text" minlength="1" maxlength="2" value={characterData['equipReinforceNum'+props.id]}
                                    onChange={ (event) => change('equipReinforceNum'+props.id,event.target.value)} >

                                    </input>
                                    
                                </div>
                                <input class="crange" type="range" value={characterData['equipReinforceNum'+props.id]} min="0" max="18"
                                    onChange={ (event) => change('equipReinforceNum'+props.id,event.target.value)} />
                            </div>
        
                        </div>

                        <div class="estcont cjae">
                            <div class="cus_chkbut">
                                <ul>
                                    <li>재련</li>
                                </ul>
                            </div>
                            

                            <div class="cus_range">
                                
                            <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    <input value= {characterData['refine0']} type="text" minlength="1" maxlength="1" onChange={(event) => change('refine0',event.target.value)}>
                                       
                                    </input>
                                    
                                </div>
                                <input class="crange" type="range" value={characterData['refine0']} min="0" max="8" onChange={(event) => change('refine0',event.target.value)}></input>
                            </div>
                            
                        </div>
                    </div>
                : <></>
                }
                
                
                {
                    /* 
                }
                

                    <div class="esetting_tr cusp2">
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>스탯</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    
                                    <input type="text" minlength="1" maxlength="2" value={characterData['equipEnchantStat'+props.id]}
                                    onChange={ (event) => change('equipEnchantStat'+props.id,event.target.value)} >

                                    </input>
                                </div>
                                <input class="crange" type="range" value={characterData['equipEnchantStat'+props.id]} min="0" max={dStat[props.id]}
                                    onChange={ (event) => change('equipEnchantStat'+props.id,event.target.value)} />
                            </div>
        
                        </div>
                        <div class="estcont  cussa">
                            {
                                characterObj.buffCal == null && dAttack[props.id] != 0 ?  <>
                                <div class="cus_esttit">
                                    <ul>
                                        <li>공격력</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{'\u00A0'}</span>
                                    
                                    <div class="cus_rare">
                                        
                                        <input type="text" minlength="1" maxlength="3" value={characterData['equipEnchantAttack'+props.id]}
                                        onChange={ (event) => change('equipEnchantAttack'+props.id,event.target.value)} >

                                        </input>
                                    </div>
                                    <input class="crange" type="range" value={characterData['equipEnchantAttack'+props.id]} min="0" max={dAttack[props.id]}
                                        onChange={ (event) => change('equipEnchantAttack'+props.id,event.target.value)} />
                                </div>
                                </> : <></>
                            }
                            
        
                        </div>
                        <div class="estcont  cussa">
                            {
                                dESL[props.id] != 0 ?  
                                <>
                                <div class="cus_esttit">
                                    <ul>
                                        <li>
                                            {dESLTemp}
                                        </li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{'\u00A0'}</span>
                                    <div class="cus_rare">
                                        
                                        <input type="text" minlength="1" maxlength="3" value={characterData['equipEnchantEtc'+props.id]}
                                        onChange={ (event) => change('equipEnchantEtc'+props.id,event.target.value)} >

                                        </input>
                                    </div>
                                    <input class="crange" type="range" value={characterData['equipEnchantEtc'+props.id]} min="0" max={dESL[props.id][dESLName]}
                                            onChange={ (event) => change('equipEnchantEtc'+props.id,event.target.value)} />
                                </div> 
                                </>
                                : <></>
                            }
                        
        
                        </div>
                    </div>
                {
                    */
                }
                {/* 세로버젼 컨트롤러*/}

                <div class="esetting_tr cusp2 cusp2-vetical_v1">
                    <div class="estcont  cussa">
                        <div class="cus_tit_side">

                            <div class="cus_esttit">
                                    <ul>
                                        <li>
                                         스탯
                                        </li>
                                    </ul>
                            </div>
                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    
                                    <input type="text" minlength="1" maxlength="2" value={characterData['equipEnchantStat'+props.id]}
                                    onChange={ (event) => change('equipEnchantStat'+props.id,event.target.value)} >

                                    </input>
                                </div>
                                <input class="crange" type="range" value={characterData['equipEnchantStat'+props.id]} min="0" max={dStat[props.id]}
                                    onChange={ (event) => change('equipEnchantStat'+props.id,event.target.value)} />
                            </div>

                        </div>
                       

                    </div>
                    <div class="estcont  cussa">
                        {
                            characterObj.buffCal == null && dAttack[props.id] != 0 ?  <>
                            <div class="cus_esttit">
                                <ul>
                                    <li>공격력</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                
                                <div class="cus_rare">
                                    
                                    <input type="text" minlength="1" maxlength="3" value={characterData['equipEnchantAttack'+props.id]}
                                    onChange={ (event) => change('equipEnchantAttack'+props.id,event.target.value)} >

                                    </input>
                                </div>
                                <input class="crange" type="range" value={characterData['equipEnchantAttack'+props.id]} min="0" max={dAttack[props.id]}
                                    onChange={ (event) => change('equipEnchantAttack'+props.id,event.target.value)} />
                            </div>
                            </> : <></>
                        }
                    </div>
                    {
                            dEle[props.id] != 0 ?  
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>
                                        속강
                                        </li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{'\u00A0'}</span>
                                    <div class="cus_rare">
                                        
                                        <input type="text" minlength="1" maxlength="3" value={characterData['equipEnchantEle'+props.id]}
                                        onChange={ (event) => change('equipEnchantEle'+props.id,event.target.value)} >

                                        </input>
                                    </div>
                                    <input class="crange" type="range" value={characterData['equipEnchantEle'+props.id]} min="0" max={dEle[props.id]}
                                            onChange={ (event) => change('equipEnchantEle'+props.id,event.target.value)} />
                                </div> 
                            </div>
                            : <></>
                    }
                    {
                            dESL[props.id] != 0 ?  
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>
                                        {dESLTemp}
                                        </li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{'\u00A0'}</span>
                                    <div class="cus_rare">
                                        
                                        <input type="text" minlength="1" maxlength="3" value={characterData['equipEnchantEtc'+props.id]}
                                        onChange={ (event) => change('equipEnchantEtc'+props.id,event.target.value)} >

                                        </input>
                                    </div>
                                    <input class="crange" type="range" value={characterData['equipEnchantEtc'+props.id]} min="0" max={dESLTemp =="속강" || dESLTemp =="스증" 
                                    ?  dESL[props.id] : dESL[props.id][dESLName]}
                                            onChange={ (event) => change('equipEnchantEtc'+props.id,event.target.value)} />
                                </div> 
                            </div>
                            : <></>
                        }
                
                </div>

                {
                    //기억의 성단

                }
                {
                    props.id == 0 && characterData['equipName' + props.id].includes(" : ") ? 
                    <div class="esetting_tr cusper73 cusp1">
                        <div class="estcont">
                            <div class="cus_chkbut">
                                <ul>
                                    <li>기억의 성단</li>
                                </ul>
                            </div>
                            <div class="cus_range">
                                <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    <input type="text" minlength="1" maxlength="2" value={characterData['cluster']}
                                    onChange={ (event) => change('cluster',event.target.value)} >

                                    </input>
                                    
                                </div>
                                <input class="crange" type="range" value={characterData['cluster']} min="1" max="20"
                                    onChange={ (event) => change('cluster',event.target.value)} />
                            </div>
        
                        </div>

                        <div class="estcont cjae">
                            <div class="cus_chkbut">
                                <ul>
                                    <li>기억의 종착지</li>
                                </ul>
                            </div>
                            

                            <div class="cus_range">
                                
                            <span id="rangeValue">{'\u00A0'}</span>
                                <div class="cus_rare">
                                    <input value= {characterData['clusterEnd']} type="text" minlength="1" maxlength="1"
                                     onChange={(event) => change('clusterEnd',event.target.value)}>
                                       
                                    </input>
                                    
                                </div>
                                <input class="crange" type="range" value={characterData['clusterEnd']} min="0" max="5" 
                                onChange={(event) => change('clusterEnd',event.target.value)}></input>
                            </div>
                            
                        </div>
                    </div>
                : <></>
                }

                <div className={"esetting_tr cusp2 o105je level-unitied " + (lv105OptionMap[characterData['equipName' + props.id]] != null ? "option-no-custum" : "") }> 
                    {
                        props.id != 0 || (props.id == 0 && ( characterData['equipName' + props.id].includes("넘어선 기억의") || 
                        characterData['equipName' + props.id].includes("불가침의 영역") || !characterData['equipName' + props.id].includes(" : ") )) ?
                        <>
                        
                        {
                            props.id != 0 || (props.id == 0 && (!characterData['equipName' + props.id].includes(" : "))) ? 
                            <>
                            <div class="tr_o105_level">
                                <div class="cus_chkbut" style={{"width":"30%"}}>
                                        <ul>
                                            {
                                                (characterData['equipName' + props.id].includes("넘어선 기억의") || 
                                                characterData['equipName' + props.id].includes("불가침의 영역")) ? <></> :
                                                <>
                                                    <li style={{"height":"30px"}} onClick={()=>{change('itemLevel'+props.id, 105 )}} className={characterData['itemLevel'+props.id] == 105 ? "is-active" : ""}>105레벨</li>
                                                    <li style={{"height":"30px"}} onClick={()=>{change('itemLevel'+props.id, 110 ) }} className={characterData['itemLevel'+props.id] == 110 ? "is-active" : ""}>110레벨</li>
                                                    
                                                </>
                                                
                                            } 

                                            
                                            {
                                                props.id != 0 && lv105OptionMap[characterData['equipName' + props.id]] == null 
                                                && setItemNames.indexOf(characterData['equipName' + props.id]) == -1 ? 
                                                <li onClick={()=>{change('mistGear'+props.id, characterData['mistGear' + props.id] == 'true' ? 'false' : 'true' )}}
                                                className={(characterData['mistGear' + props.id] == 'true' ? "p-mystic is-active" : "p-mystic")}>
                                                        
                                                    <div class="mystic-but">
                                                        <div class="imgt">
                                                            
                                                            <img src="mist_gear_d.png" />
                                                                    
                                                        </div>
                                                    </div>
                                                    
                                                </li> 
                                                : <></>
                                            }
                                            
                                        </ul>
                                    </div>
                                <div class="cus_range" style={{"width":"0px","min-height":"30px","margin-top":"10px"}}>
                                
                                
                                </div>
                            </div>
                            </>
                            :
                            <></>
                        }

                        

                        <div class="tr_o105je" style={setItemNames.indexOf(characterData['equipName' + props.id]) != -1 ? {display:"none"}:{}}>
                            <div class="set_option_ul">
                                <div class="estcont  cussa">

                                    <div class="cus_105op">
                                        <div class="option">
                                            {
                                                lv105OptionMap[characterData['equipName' + props.id]] != null  ?
                                                    <>
                                                        <span class="up">피해 증가 + {lv105OptionMap[characterData['equipName' + props.id]].split("@@")[1]}</span>
                                                        <br />
                                                        {lv105OptionMap[characterData['equipName' + props.id]].split("@@")[0].replace(/<br>/gi, '\n')}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }

                                            {
                                                characterData['equipCustom' + props.id + "C" + 0] != null ?
                                                    <>
                                                        1옵션 <span class="up">피해 증가 + </span>
                                                        <br />
                                                        {lv105CustomOptionMap[characterData['equipCustom' + props.id + "C" + 0]].replace(/<br>/gi, '\n')}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }

                                        </div>

                                        {
                                            lv105OptionMap[characterData['equipName' + props.id]] == null ?
                                                <div class="cus_opsetbut">
                                                    <div class="imgt" onClick={() => customChange(props.id, 0, 1)}>
                                                    <img src="img/set1.svg" />
                                                    </div>

                                                </div> : <></>
                                        }
                                    </div>
                                    {lv105OptionMap[characterData['equipName' + props.id]] == null ? <CustomOptions id={props.id} index={0} /> : <></>}

                                </div>
                                <div class="estcont  cussa"
                                    style={lv105OptionMap[characterData['equipName' + props.id]] != null  ? { display: 'none' } : {}}>
                                    <div class="cus_105op">
                                        <div class="option">
                                            {
                                                lv105OptionMap[characterData['equipName' + props.id]] != null ?
                                                    <>
                                                   
                                                        2옵션 <span class="up">피해 증가 + {lv105OptionMap[characterData['equipName' + props.id]].split("@@")[1]}</span>
                                                        <br />
                                                        {lv105OptionMap[characterData['equipName' + props.id]].split("@@")[0]}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }

                                            
                                            {
                                                characterData['equipCustom' + props.id + "C" + 1] != null ?
                                                    <>
                                                        2옵션 <span class="up">피해 증가 + </span>
                                                        <br />
                                                        {lv105CustomOptionMap[characterData['equipCustom' + props.id + "C" + 1]].replace(/<br>/gi, '\n')}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }
                                        </div>


                                        {
                                            lv105OptionMap[characterData['equipName' + props.id]] == null ?
                                                <div class="cus_opsetbut">
                                                    <div class="imgt" onClick={() => customChange(props.id, 1, 1)}>
                                                    <img src="img/set1.svg" />
                                                    </div>

                                                </div> : <></>
                                        }
                                    </div>

                                    {lv105OptionMap[characterData['equipName' + props.id]] == null ? <CustomOptions id={props.id} index={1} /> : <></>}

                                </div>
                                <div class="estcont  cussa"
                                    style={lv105OptionMap[characterData['equipName' + props.id]] != null ? { display: 'none' } : {}}>
                                    <div class="cus_105op">
                                        <div class="option">
                                            {
                                                lv105OptionMap[characterData['equipName' + props.id]] != null ?
                                                    <>
                                                        3옵션 <span class="up">피해 증가 + {lv105OptionMap[characterData['equipName' + props.id]].split("@@")[1]}</span>
                                                        <br />
                                                        {lv105OptionMap[characterData['equipName' + props.id]].split("@@")[0]}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }

                                            {
                                                characterData['equipCustom' + props.id + "C" + 2] != null ?
                                                    <>
                                                        3옵션 <span class="up">피해 증가 + </span>
                                                        <br />
                                                        {lv105CustomOptionMap[characterData['equipCustom' + props.id + "C" + 2]].replace(/<br>/gi, '\n')}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }
                                        </div>


                                        {
                                            lv105OptionMap[characterData['equipName' + props.id]] == null ?
                                                <div class="cus_opsetbut">
                                                    <div class="imgt" onClick={() => customChange(props.id, 2, 1)}>
                                                    <img src="img/set1.svg" />
                                                    </div>

                                                </div> : <></>
                                        }
                                    </div>


                                    {lv105OptionMap[characterData['equipName' + props.id]] == null ? <CustomOptions id={props.id} index={2} /> : <></>}
                                </div>

                                <div class="estcont  cussa"
                                    style={lv105OptionMap[characterData['equipName' + props.id]] != null ? { display: 'none' } : {}}>
                                    <div class="cus_105op">
                                        <div class="option">
                                            {
                                                lv105OptionMap[characterData['equipName' + props.id]] != null ?
                                                    <>
                                                        4옵션 <span class="up">피해 증가 + {lv105OptionMap[characterData['equipName' + props.id]].split("@@")[1]}</span>
                                                        <br />
                                                        {lv105OptionMap[characterData['equipName' + props.id]].split("@@")[0]}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }

                                            {
                                                characterData['equipCustom' + props.id + "C" + 3] != null ?
                                                    <>
                                                        4옵션 <span class="up">피해 증가 + </span>
                                                        <br />
                                                        {lv105CustomOptionMap[characterData['equipCustom' + props.id + "C" + 3]].replace(/<br>/gi, '\n')}
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                            }

                                        </div>


                                        {
                                            lv105OptionMap[characterData['equipName' + props.id]] == null ?
                                                <div class="cus_opsetbut">
                                                    <div class="imgt" onClick={() => customChange(props.id, 3, 1)}>
                                                    <img src="img/set1.svg" />
                                                    </div>

                                                </div> : <></>
                                        }
                                    </div>
                                    {lv105OptionMap[characterData['equipName' + props.id]] == null ? <CustomOptions id={props.id} index={3} /> : <></>}



                                </div>  
                            </div>
                        </div>
                        </>


                        :<></>
                    }
                    
                </div>

            </div>
            
        </div>

    )
}
/*
*/
function AvatarSetting(props)
{
    return(
        <div class="cus_set sabatcus" style={avaPresent == props.id ? {} : {display : 'none'}}>
        {
            props.id == 13 ? 
            <div class="saba_adventure" name="모험단">
                <div class="abat_tit">
                    <span>모험단</span>
                </div>
                <div class="cus_esetting abatnum">
                    <div class="esetting_tr cusp2">
                        
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>레벨</li>
                                </ul>
                            </div>
            
                            <div class="cus_range">
                                <span id="rangeValue">{characterData['adventure']}</span>
                                <input class="crange" type="range" value={characterData['adventure']} min="0" max="50" step="1" onChange={(event)=>{change('adventure',event.target.value)}}>
                                </input>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div> : <></>
        }
        
        {
            props.id < 9 ? 
                <div class="saba_basic" name="아바타 기본형">
                    <div class="abat_tit">
                        <span>{avatarTypeList[props.id]}</span>
                    </div>
                    {
                        props.id != 8 ?  
                        <div class="abat_switch">
                            <div class="estcont">
                                <div class="cus_chkbut cusbonly">
                                    <ul>
                                        <li onClick={()=>change('abaRarity'+props.id,'상급')} className={characterData['abaRarity'+props.id] =='상급' ? "is-active":""} >상급</li>
                                        <li onClick={()=>change('abaRarity'+props.id,'레어')} className={characterData['abaRarity'+props.id] =='레어' ? "is-active":""}>레어</li>
                                    </ul>
                                </div>
                            </div>
                        </div> : ""

                    }
                    {
                         props.id != 8 ? 
                         <div class="abat_select">
                            <select id="v212" value={characterData["abaOption"+props.id]} onChange={(e)=>{change("abaOption"+props.id,e.target.value)}}>
                                <option value={''}>{''}</option>
                                {
                                    avatarOption[props.id] != null  && avaPresent == props.id  ? avatarOption[props.id][characterData['abaRarity'+props.id] =='레어' ? 1 : 0].map((a,i) => {
                                        return(
                                            <option value={a}>{a}</option>
                                        )
                                    }) : ""
                                    
                                }
                                
                                {
                                    props.id == 3 && avaPresent == props.id ? avatarSkillOption[characterData['job']].map((a,i) => {
                                        return(
                                            <option value={a}>{a}</option>
                                        )
                                    }) : ""
                                    
                                }
                        
                            </select>
                        </div> : ""
                    }
                    

                    { (props.id == 3 || props.id == 4) ?
                        <div class="abat_select" >
                            <select id="v212" value={characterData["emblemSkill"+props.id]} onChange={(event) => change("emblemSkill"+props.id,event.target.value)}>
                                
                                <option value={''}>{''}</option>
                                <option value={'모험가의 플래티넘 엠블렘'}>{'모험가의 플래티넘 엠블렘'}</option>

                                {
                                    avaPresent == props.id ? emblemSkillOption[characterData['job']].map((a,i) => {
                                        return(
                                            <option value={a}>{a}</option>
                                        )
                                    }) :<></>
                                    
                                }
                        
                            </select>
                        </div> : <></>
                    }
                    
                    <div class="cus_esetting abatnum">
                        <div class="esetting_tr cusp2">
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>스탯</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemStat0'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemStat0'+props.id]} min="0" max="25" step="1" onInput={(event)=>change("emblemStat0"+props.id,event.target.value)} >
                                    </input>
                                </div>
                            </div>
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>스탯</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemStat1'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemStat1'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemStat1"+props.id,event.target.value)} >
                                    </input>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="cus_esetting abatnum">
                        <div class="esetting_tr cusp2">
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>모든 속성 저항</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemResist0'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemResist0'+props.id]} min="0" max="5" step="1" onChange={(event)=>change("emblemResist0"+props.id,event.target.value)}  >
                                    </input>
                                </div>
                            </div>
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>모든 속성 저항</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemResist1'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemResist1'+props.id]} min="0" max="5" step="1" onChange={(event)=>change("emblemResist1"+props.id,event.target.value)}>
                                    </input>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="cus_esetting abatnum">
                        <div class="esetting_tr cusp2">
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>공격속도</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemAS0'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemAS0'+props.id]} min="0" max="1.5" step="0.1" onChange={(event)=>change("emblemAS0"+props.id,event.target.value)}>
                                    </input>
                                </div>
                            </div>
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>공격속도</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemAS1'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemAS1'+props.id]} min="0" max="1.5" step="0.1" onChange={(event)=>change("emblemAS1"+props.id,event.target.value)}>
                                    </input>
                                </div>
                                
                            </div>
                        </div>
                    </div>

                </div> 
            : 
            <></>
        }
        
        {
            props.id == 10 ? 
            <div class="saba_weapon" name="무기">
                <div class="abat_tit">
                    <span>무기 아바타</span>
                </div>
                <div class="abat_select">
                    <select id="v212" number="402" value={characterData['abaOption10']}
                    onChange={(event)=>change("abaOption10",event.target.value)}>
                        <option>스탯 55 증가</option>
                        <option>스탯 45 증가</option>
                        <option>없음</option>
                    </select>
                </div>
                <div class="cus_esetting abatnum">
                    <div class="esetting_tr cusp2">
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>스탯</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['emblemStat0'+props.id]}</span>
                                <input class="crange" type="range" value={characterData['emblemStat0'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemStat0"+props.id,event.target.value)} >
                                </input>
                            </div>
                        </div>
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>스탯</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['emblemStat1'+props.id]}</span>
                                <input class="crange" type="range" value={characterData['emblemStat1'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemStat1"+props.id,event.target.value)} >
                                </input>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="cus_esetting abatnum">
                    <div class="esetting_tr cusp2">
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>모든 속성 저항</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['emblemResist0'+props.id]}</span>
                                <input class="crange" type="range" value={characterData['emblemResist0'+props.id]} min="0" max="5" step="1" onChange={(event)=>change("emblemResist0"+props.id,event.target.value)}  >
                                </input>
                            </div>
                        </div>
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>모든 속성 저항</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['emblemResist1'+props.id]}</span>
                                <input class="crange" type="range" value={characterData['emblemResist1'+props.id]} min="0" max="5" step="1" onChange={(event)=>change("emblemResist1"+props.id,event.target.value)}>
                                </input>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="cus_esetting abatnum">
                    <div class="esetting_tr cusp2">
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>공격속도</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['emblemAS0'+props.id]}</span>
                                <input class="crange" type="range" value={characterData['emblemAS0'+props.id]} min="0" max="1.5" step="0.1" onChange={(event)=>change("emblemAS0"+props.id,event.target.value)}>
                                </input>
                            </div>
                        </div>
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>공격속도</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['emblemAS1'+props.id]}</span>
                                <input class="crange" type="range" value={characterData['emblemAS1'+props.id]} min="0" max="1.5" step="0.1" onChange={(event)=>change("emblemAS1"+props.id,event.target.value)}>
                                </input>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div class="esetting_tr cusper73 cusp6">
                    <div class="estcont">
                        <div class="cus_chkbut cusb8">
                            <ul>
                                <li onClick={()=>change('aba10Skill',40)} className={characterData['aba10Skill'] == 40 ? "is-active" : ""} >40Lv</li>
                                <li onClick={()=>change('aba10Skill',45)} className={characterData['aba10Skill'] == 45 ? "is-active" : ""}>45Lv</li>
                                <li onClick={()=>change('aba10Skill',60)} className={characterData['aba10Skill'] == 60 ? "is-active" : ""}>60Lv</li>
                            </ul>
                            <ul>
                                <li onClick={()=>change('aba10Skill',70)} className={characterData['aba10Skill'] == 70 ? "is-active" : ""}>70Lv</li>
                                <li onClick={()=>change('aba10Skill',75)} className={characterData['aba10Skill'] == 75 ? "is-active" : ""}>75Lv</li>
                                <li onClick={()=>change('aba10Skill',80)} className={characterData['aba10Skill'] == 80 ? "is-active" : ""}>80Lv</li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
            </div>
            :
            <>
            </>
        }

        {
            props.id == 9 ? 
            <div class="seba_ora">
                <div class="abat_tit">
                    <span>오라 아바타</span>
                </div>
                
                <div class="cus_esetting abatnum">
                    <div class="esetting_tr cusp2">
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>스탯</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['aba9Stat']}</span>
                                <input class="crange" type="range" value={characterData['aba9Stat']} min="0" max="134" step="1" 
                                onChange={(event)=>change("aba9Stat",event.target.value)}>
                                </input>
                            </div>
                            
                        </div>
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>공격력</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['aba9Attack']}</span>
                                <input class="crange" type="range" value={characterData['aba9Attack']} min="0" max="50" step="5" 
                                onChange={(event)=>change("aba9Attack",event.target.value)}>
                                </input>
                            </div>
                            
                        </div>
                        <div class="estcont  cussa">
                            <div class="cus_esttit">
                                <ul>
                                    <li>속강</li>
                                </ul>
                            </div>

                            <div class="cus_range">
                                <span id="rangeValue">{characterData['aba9Element']}</span>
                                <input class="crange" type="range" value={characterData['aba9Element']} min="0" max="40" step="5" 
                                onChange={(event)=>change("aba9Element",event.target.value)}>
                                </input>
                            </div>
                            
                        </div>
                    </div>
                    <div class="cus_esetting abatnum">
                        <div class="esetting_tr cusp2">
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>스탯</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemStat0'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemStat0'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemStat0"+props.id,event.target.value)} >
                                    </input>
                                </div>
                            </div>
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>스탯</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemStat1'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemStat1'+props.id]} min="0" max="25" step="1" onChange={(event)=>change("emblemStat1"+props.id,event.target.value)} >
                                    </input>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="cus_esetting abatnum">
                        <div class="esetting_tr cusp2">
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>모든 속성 저항</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemResist0'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemResist0'+props.id]} min="0" max="5" step="1" onChange={(event)=>change("emblemResist0"+props.id,event.target.value)}>
                                    </input>
                                </div>
                            </div>
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>모든 속성 저항</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemResist1'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemResist1'+props.id]} min="0" max="5" step="1" onChange={(event)=>change("emblemResist1"+props.id,event.target.value)}>
                                    </input>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div class="cus_esetting abatnum">
                        <div class="esetting_tr cusp2">
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>공격속도</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemAS0'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemAS0'+props.id]} min="0" max="1.5" step="0.1" onChange={(event)=>change("emblemAS0"+props.id,event.target.value)}>
                                    </input>
                                </div>
                            </div>
                            <div class="estcont  cussa">
                                <div class="cus_esttit">
                                    <ul>
                                        <li>공격속도</li>
                                    </ul>
                                </div>

                                <div class="cus_range">
                                    <span id="rangeValue">{characterData['emblemAS1'+props.id]}</span>
                                    <input class="crange" type="range" value={characterData['emblemAS1'+props.id]} min="0" max="1.5" step="0.1" onChange={(event)=>change("emblemAS1"+props.id,event.target.value)}>
                                    </input>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                        
                </div>
            </div>:<></>
            }
            {
            props.id == 11 ?

            <div class="seba_hwijang wide-mode">
                <div class="abat_tit">
                    <span>휘장</span>
                </div>
                <div class="hwijang_set" name="선택된 휘장젬">
                    <div class="hjang_selcet">
                        <div class="hjang_bg">
                            <div class="hjselect_hj" name="선택된 휘장">
                                <div class="imgt">
                                    <img src={"flag"+(gradeToNum[characterData['flag']])+".png"}/>
                                </div>
                            </div>
                            <div class="hjselect_jam h_1jam"  name="선택된 1번째 젬">
                                <div class="imgt">
                                    <img src={"gem1"+(gradeToNum[characterData['gem1']])+".png"}/>

                                </div>
                            </div>
                            <div class="hjselect_jam h_2jam" name="선택된 2번째 젬">
                                <div class="imgt">
                                    <img src={"gem2"+(gradeToNum[characterData['gem2']])+".png"}/>

                                </div>
                            </div>
                            <div class="hjselect_jam h_3jam" name="선택된 3번째 젬">
                                <div class="imgt">
                                    <img src={"gem3"+(gradeToNum[characterData['gem3']])+".png"}/>

                                </div>
                            </div>
                            <div class="hjselect_jam h_4jam" name="선택된 4번째 젬">
                                <div class="imgt">
                                    <img src={"gem4"+(gradeToNum[characterData['gem4']])+".png"}/>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="hjang_change">
                        <div class="hjang_setting">
                            <div class="set_hwij" name="휘장 선택">
                                <div className={"shwij_img " + (characterData["flag"] == "커먼" ? "is-active" : "" )} onClick={()=>change("flag","커먼")}>
                                    <div class="shwij_cover">
                                        <div class="imgt">
                                            <img src="flag0.png"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"shwij_img " + (characterData["flag"] == "언커먼" ? "is-active" : "") } onClick={()=>change("flag","언커먼")}>
                                    <div class="shwij_cover">
                                        <div class="imgt">
                                            <img src="flag1.png"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"shwij_img " + (characterData["flag"] == "레어" ? "is-active" : "") } onClick={()=>change("flag","레어")}>
                                    <div class="shwij_cover">
                                        <div class="imgt">
                                            <img src="flag2.png"/>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className={"shwij_img " + (characterData["flag"] == "유니크" ? "is-active" : "") } onClick={()=>change("flag","유니크")}>
                                    <div class="shwij_cover">
                                        <div class="imgt">
                                            <img src="flag3.png"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"shwij_img " + (characterData["flag"] == "레전더리" ? "is-active" : "") } onClick={()=>change("flag","레전더리")}>
                                    <div class="shwij_cover">
                                        <div class="imgt">
                                            <img src="flag4.png"/>
                                        </div>
                                    </div>
                                </div>
                                <div className={"shwij_img " + (characterData["flag"] == "에픽" ? "is-active" : "") } onClick={()=>change("flag","에픽")}>
                                    <div class="shwij_cover">
                                        <div class="imgt">
                                            <img src="flag5.png"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="estcont"  name="휘장 + 수치 조정">
                 
                            <div class="cus_range">
                                <span id="rangeValue">{characterData["flagReinforceNum"]}</span>
                                <input class="crange" type="range" value={characterData['flagReinforceNum']} min="0" max="12" step="1"
                            onChange={(event)=>change("flagReinforceNum",event.target.value)}></input>
                            </div>
                        </div>
                        <div class="hjangjam_setting"  name="젬 선택">
                            <GemSelect id='1'/>
                            <GemSelect id='2'/>
                            <GemSelect id='3'/>
                            <GemSelect id='4'/>
                        </div>
                    </div>
                </div>
        
            </div>:<></>
            }

            {
            props.id == 12 ? 
            <div class="seba_cri">
                <div class="abat_tit">
                    <span>크리쳐</span>
                </div>
                <div class="esetting_tr cusper73 cusp6">
                    <div class="estcont">
                        <div class="cus_chkbut cusb8">
                            <ul>
                                <li class={(characterData['creaName'] == "데미지 증가" ? "is-active" : "") } onClick={()=>change('creaName','데미지 증가')}><span>데미지 증가</span></li>
                                <li class={(characterData['creaName'] == "크리티컬 증가 데미지" ? "is-active" : "") } onClick={()=>change('creaName','크리티컬 증가 데미지')}><span>크리티컬 증가 데미지</span></li>
                            </ul>
                            <ul>
                                <li class={(characterData['creaName'] == "모든 공격력 증가" ? "is-active" : "") } onClick={()=>change('creaName','모든 공격력 증가')}><span>모든 공격력 증가</span></li>
                                <li class={(characterData['creaName'] == "정령왕" ? "is-active" : "") } onClick={()=>change('creaName','정령왕')}><span>정령왕</span></li>
                            </ul>
                            <ul>
                                <li class={(characterData['creaName'] == "물리 마법 독립 공격력 증가" ? "is-active" : "") } onClick={()=>change('creaName','물리 마법 독립 공격력 증가')}><span>물리 마법 독립 공격력 증가</span></li>
                                <li class={(characterData['creaName'] == "피해 증가" ? "is-active" : "") } onClick={()=>change('creaName','피해 증가')}><span>피해 증가</span></li>
                            </ul>
                            <ul>
                                <li class={(characterData['creaName'] == "없음" ? "is-active" : "") } onClick={()=>change('creaName','없음')}><span>없음</span></li>
                                <li></li>
                            </ul>
                            
                        </div>
                        
                    </div>
                
                    <div class="estcont cjae">
                        <div class="cus_chkbut">
                            <ul>
                                <li>수치</li>
                            </ul>
                        </div>

                        <div class="cus_range">
                            <span id="rangeValue">{characterData['creaValue']}</span>
                            <input class="crange" type="range" value={characterData['creaValue']} min="15" max="25" step="1"
                            onChange={(event)=>change("creaValue",event.target.value)}></input>
                        </div>
                        
                        
                    </div>
                </div>
                <div class="hjangjam_setting"  name="젬 선택">
                    <div class="set_hwijjam">
                        <div class="sethjam_tit">
                            <span class="crit_red">레드</span>
                        </div>
                        <div class="sethjam_img">
                            <div class="set_hug">
                                <div class="jam_move">
                                    <div className={"imgt " + (characterData["afti0"] == "커먼" ? "is-active" : "")} onClick={()=>{change("afti0","커먼")}}>
                                        <img src="red0.png"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sethjam_img">
                            <div class="set_hug">
                                <div class="jam_move">
                                    <div className={"imgt " + (characterData["afti0"] == "언커먼" ? "is-active" : "")} onClick={()=>{change("afti0","언커먼")}}>
                                        <img src="red1.png"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sethjam_img">
                            <div class="set_hug">
                                <div class="jam_move">
                                    <div className={"imgt " + (characterData["afti0"] == "레어" ? "is-active" : "")} onClick={()=>{change("afti0","레어")}}>
                                        <img src="red10.png"/>

                                    </div>
                                    <div className={"imgt " + (characterData["afti0"] == "레어모속" ? "is-active" : "")} onClick={()=>{change("afti0","레어모속")}}>
                                        <img src="red1.png"/>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sethjam_img">
                            <div class="set_hug">
                                <div class="jam_move">

                                    <div className={"imgt " + (characterData["afti0"] == "유니크" ? "is-active" : "")} onClick={()=>{change("afti0","유니크")}}>
                                        <img src="red3.png"/>

                                    </div>
                                    <div className={"imgt " + (characterData["afti0"] == "유니크모속" ? "is-active" : "")} onClick={()=>{change("afti0","유니크모속")}}>
                                        <img src="red3.png"/>

                                    </div>
                                </div>
                            </div>
                     
                        </div>
          
                    </div>
                    <div class="set_hwijjam">
                        <div class="sethjam_tit">
                            <span class="crit_blue">블루</span>
                        </div>
                        <div class="sethjam_img">
                            <div className={"imgt " + (characterData["afti1"] == "커먼" ? "is-active" : "")} onClick={()=>{change("afti1","커먼")}}>
                                <img src="blue0.png"/>

                            </div>
                        </div>
                        <div class="sethjam_img">
                            <div className={"imgt " + (characterData["afti1"] == "언커먼" ? "is-active" : "")} onClick={()=>{change("afti1","언커먼")}}>
                                <img src="blue1.png"/>

                            </div>
                        </div>
                        <div class="sethjam_img">
                            <div className={"imgt " + (characterData["afti1"] == "레어" ? "is-active" : "")} onClick={()=>{change("afti1","레어")}}>
                                <img src="blue2.png"/>

                            </div>
                        </div>
                        <div class="sethjam_img">
                            <div className={"imgt " + (characterData["afti1"] == "유니크" ? "is-active" : "")} onClick={()=>{change("afti1","유니크")}}> 
                                <img src="blue3.png"/>

                            </div>
                        </div>
            
                    </div>
                    <div class="set_hwijjam">
                        <div class="sethjam_tit ">
                            <span class="crit_green">그린</span>
                        </div>
                        <div class="sethjam_img">
                            <div className={"imgt " + (characterData["afti2"] == "커먼" ? "is-active" : "")} onClick={()=>{change("afti2","커먼")}}>
                                <img src="green0.png"/>

                            </div>
                        </div>
                        <div class="sethjam_img">
                            <div className={"imgt " + (characterData["afti2"] == "언커먼" ? "is-active" : "")} onClick={()=>{change("afti2","언커먼")}}>
                                <img src="green1.png"/>

                            </div>
                        </div>
                        <div class="sethjam_img">
                            <div className={"imgt " + (characterData["afti2"] == "레어" ? "is-active" : "")} onClick={()=>{change("afti2","레어")}}>
                                <img src="green2.png"/>

                            </div>
                        </div>
                        <div class="sethjam_img">
                            <div className={"imgt " + (characterData["afti2"] == "유니크" ? "is-active" : "")} onClick={()=>{change("afti2","유니크")}}>
                                <img src="green3.png"/>

                            </div>
                        </div>
          
                    </div>

                </div>
            </div>:<></>
        }
        </div>


    )
}

function CustomOptions(props)
{
    
    return(
        
        <div className={"cus_opset" + (customArray[props.id][props.index] == 1 ? " on" : "")}>
            <div class="cos_c">
                <div class="cossc">
                    <div class="searchbox" name="검색창">
                        <input type="text"  onChange={(event)=>{customSearchChange(props.id,props.index,event.target.value)}} class="form-control js-bound" placeholder="검색" name="name" maxlength="11"/>
                    </div>
                    <div class="searchbut105">
                        <div class="imgt">
                            <img src="sr_search.svg"/>
                        </div>

                    </div>
                </div>
                <div class="coscc">
                    <ul>
                        {
                            //&& customEquip[characterData['equipName'+props.id]] != null
                            customArray[props.id][props.index] == 1 && customEquip[characterData['equipName'+props.id]] == null ? lv105CustomOptionMap.map(
                                    (a, i) => {
                                        if(customSearch[props.id][props.index] == "")
                                        {
                                            return(
                                            <li key={"custom"+i} className={customSelectArray[props.id][props.index] == i ? "is-active" : ""} onClick={()=>{customSelectChange(props.id,props.index,i); }}>{a.replace(/<br>/gi,'\n')}</li>
                                            )
                                        }
                                        else if(optionSearch(a,customSearch[props.id][props.index]) != -1)
                                        {
                                            return(
                                            <li key={"custom"+i} className={customSelectArray[props.id][props.index] == i ? "is-active" : ""} onClick={()=>{customSelectChange(props.id,props.index,i); }}>{a.replace(/<br>/gi,'\n')}</li>
                                            )
                                        }
                                        
                                    }

                                ) : <></>

                                

                        }

                        {
                            
                             customArray[props.id][props.index] == 1 && customEquip[characterData['equipName'+props.id]] != null   ? customEquip[characterData['equipName'+props.id]].map(
                                   (a, i) => {
                                    //console.log(a);
                                        if(customSearch[props.id][props.index] == "")
                                        {
                                            return(
                                            <li key={"custom"+i} className={customSelectArray[props.id][props.index] == a ? "is-active" : ""} onClick={()=>{customSelectChange(props.id,props.index,a); }}>{lv105CustomOptionMap[a].replace(/<br>/gi,'\n')}</li>
                                            )
                                        }
                                        else if(optionSearch(lv105CustomOptionMap[a],customSearch[props.id][props.index]) != -1)
                                        {
                                            return(
                                            <li key={"custom"+i} className={customSelectArray[props.id][props.index] == a ? "is-active" : ""} onClick={()=>{customSelectChange(props.id,props.index,a); }}>{lv105CustomOptionMap[a].replace(/<br>/gi,'\n')}</li>
                                            )
                                        }
                                     }

                            ) : ""

                                

                        }
                        
                    </ul>
                </div>
                <div class="cos_but">
                    <div class="csbt" onClick={()=>{
                            if(customSelectArray[props.id][props.index] != 0)
                            {
                                customChange(props.id,props.index,0); 
                                change("equipCustom"+props.id+"C"+props.index, customSelectArray[props.id][props.index]);
                            }
                            }}>

                        <span class="cbut-tag" >적용</span>
                    </div>
                    <div class="csbt csbtc"  onClick={()=>customChange(props.id,props.index,0)}>
                        <span class="cbut-tag">
                            <div class="imgt">
                                <img src="arrow_go_back2.svg"/>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            
        </div>
    )

    
}

function TalismanSetting(props)
{
    return(
        <div class="set_talisman" style={presentTalis == props.id ? {}:{display:"none"}}>
            <div class="settal_setting">
                <div class="settal_attri">
                    <span>{props.id}번 탈리스만</span>
                </div>
                <div class="settal_img">
                    {
                        talismanSkillList.map( (a,i) => {
                            return(
                                <div class="talimg_cover">
                                    <div className={'imgt ' + (a == selectTalisSkill[props.id-1] ? 'on' : '')} onClick={()=>{let temp = [...selectTalisSkill]; temp[props.id-1] = a; setSelectTalisSkill(temp)}}>
                                        <img src={skillImage[a]}></img>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div class="estcont">
                    <div class="cus_chkbut cusbonly">
                        <ul>
                            <li className={selectTalisType[props.id-1] == 0 ? "is-active" : ""} onClick={() => {let temp = [...selectTalisType]; temp[props.id-1] = 0; setSelectTalisType(temp)}}>온전한 탈리스만</li>
                            <li className={selectTalisType[props.id-1] == 1 ? "is-active" : ""} onClick={() => {let temp = [...selectTalisType]; temp[props.id-1] = 1; setSelectTalisType(temp)}}>성흔의 탈리스만</li>
                            <li className={selectTalisType[props.id-1] == 2 ? "is-active" : ""} onClick={() => {let temp = [...selectTalisType]; temp[props.id-1] = 2; setSelectTalisType(temp)}}>마계대전 유니크</li>
                            <li className={selectTalisType[props.id-1] == 3 ? "is-active" : ""} onClick={() => {let temp = [...selectTalisType]; temp[props.id-1] = 3; setSelectTalisType(temp)}}>카펠라의 성흔</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="settal_setting talsetp">
                <div class="setal_subp">
                    <div class="settal_img">
                        <div class="imgt" style={{'background':talismanColor(characterData['rune'+((props.id-1) * 3 + 1)+'type'])}} >
                            <img style={{'mix-blend-mode' : 'luminosity'}} src={skillImage[characterData['rune'+((props.id-1) * 3 + 1)+"Skill"]]}></img>
                        </div>
                    </div>

                    <div class="settal_set">
                        <select id="v212" number="4011" value={characterData['rune'+((props.id-1) * 3 + 1)+"Skill"]}
                        onChange={(event)=>change('rune'+((props.id-1) * 3 + 1)+"Skill",event.target.value)}>
                            {
                                runeSkill[characterData["job"]] != null ? runeSkill[characterData["job"]].map( (a,i ) => {
                                    return(
                                        <option>{a}</option>
                                    )
                                }) : ""
                            }

                        </select>
                    </div>
                    <div class="estcont">
                            <div class="cus_chkbut cusbonly">
                                <ul>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 1)+'rarity'] == '갈라진' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'rarity','갈라진')}>갈라진</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 1)+'rarity'] == '빛바랜' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'rarity','빛바랜')}>빛바랜</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 1)+'rarity'] == '선명한' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'rarity','선명한')}>선명한</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 1)+'rarity'] == '화려한' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'rarity','화려한')}>화려한</li>
                                </ul>
                            </div>
                        </div>
                        <div class="estcont">
                            <div class="cus_chkbut cusb12 setal5">
                            
                            <ul>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 1)+'type'] == '테라코타' ? "is-active" :    "")+  " teracota"  }  
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'type',"테라코타")}>테라코타</li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 1)+'type'] == '서클 메이지' ? "is-active" : "")+  " scm"   }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'type',"서클 메이지")}>서클 메이지</li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 1)+'type'] == '수호자들' ? "is-active" :    "")+  " shjd"  }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'type',"수호자들")}>수호자들</li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 1)+'type'] == '고대 도서관' ? "is-active" : "")+  " dose"  }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'type',"고대 도서관")}>고대 도서관</li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 1)+'type'] == '세컨드 팩트' ? "is-active" : "")+  " sefect"    }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 1)+'type',"세컨드 팩트")}>세컨드 팩트</li>
                                </ul>
                                <ul class="setal_comment">
                                    <li>데미지 증가</li>
                                    <li>데미지감소<br/>쿨타임감소</li>
                                    <li>쿨타임감소</li>
                                    <li>기능성증가</li>
                                    <li>데미지증가<br/>쿨타임증가</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="setal_subp">
                        <div class="settal_img">
                            <div class="imgt" style={{'background':talismanColor(characterData['rune'+((props.id-1) * 3 + 2)+'type'])}}>
                                <img style={{'mix-blend-mode' : 'luminosity'}} src={skillImage[characterData['rune'+((props.id-1) * 3 + 2)+"Skill"] ]}></img>
                        
                            </div>
                        </div>
                        <div class="settal_set">
                            <select id="v212" number="4011" value={characterData['rune'+((props.id-1) * 3 + 2)+"Skill"]}
                            onChange={(event)=>change('rune'+((props.id-1) * 3 + 2)+"Skill",event.target.value)}>
                                {
                                    runeSkill[characterData["job"]] != null ? runeSkill[characterData["job"]].map( (a,i ) => {
                                        return(
                                            <option>{a}</option>
                                        )
                                    }) : ""
                                }

                            </select>
                        </div>
                        <div class="estcont">
                            <div class="cus_chkbut cusbonly">
                                <ul>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 2)+'rarity'] == '갈라진' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'rarity','갈라진')}>갈라진</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 2)+'rarity'] == '빛바랜' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'rarity','빛바랜')}>빛바랜</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 2)+'rarity'] == '선명한' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'rarity','선명한')}>선명한</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 2)+'rarity'] == '화려한' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'rarity','화려한')}>화려한</li>
                                </ul>
                            </div>
                        </div>
                        <div class="estcont">
                            <div class="cus_chkbut cusb12 setal5">
                            
                                <ul>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 2)+'type'] == '테라코타' ? "is-active" :    "")+  " teracota"  }  
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'type',"테라코타")}>테라코타</li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 2)+'type'] == '서클 메이지' ? "is-active" : "")+  " scm"   }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'type',"서클 메이지")}>서클 메이지</li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 2)+'type'] == '수호자들' ? "is-active" :    "")+  " shjd"  }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'type',"수호자들")}>수호자들</li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 2)+'type'] == '고대 도서관' ? "is-active" : "")+  " dose"  }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'type',"고대 도서관")}>고대 도서관</li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 2)+'type'] == '세컨드 팩트' ? "is-active" : "")+  " sefect"    }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 2)+'type',"세컨드 팩트")}>세컨드 팩트</li>
                                </ul>
                                <ul class="setal_comment">
                                    <li>데미지 증가</li>
                                    <li>데미지감소<br/>쿨타임감소</li>
                                    <li>쿨타임감소</li>
                                    <li>기능성증가</li>
                                    <li>데미지증가<br/>쿨타임증가</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="setal_subp">
                        <div class="settal_img">
                            <div class="imgt" style={{'background':talismanColor(characterData['rune'+((props.id-1) * 3 + 3)+'type'])}}>
                                <img style={{'mix-blend-mode' : 'luminosity'}} src={skillImage[characterData['rune'+((props.id-1) * 3 + 3)+"Skill"]]}></img>
                            
                            </div>
                        </div>
                        <div class="settal_set">
                            <select id="v212" number="4011" value={characterData['rune'+((props.id-1) * 3 + 3)+"Skill"]}
                            onChange={(event)=>change('rune'+((props.id-1) * 3 + 3)+"Skill",event.target.value)}>
                                {
                                    runeSkill[characterData["job"]] != null ? runeSkill[characterData["job"]].map( (a,i ) => {
                                        return(
                                            <option>{a}</option>
                                        )
                                    }) : ""
                                }

                            </select>
                        </div>
                        <div class="estcont">
                            <div class="cus_chkbut cusbonly">
                                <ul>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 3)+'rarity'] == '갈라진' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'rarity','갈라진')}>갈라진</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 3)+'rarity'] == '빛바랜' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'rarity','빛바랜')}>빛바랜</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 3)+'rarity'] == '선명한' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'rarity','선명한')}>선명한</li>
                                    <li className={characterData['rune'+((props.id-1) * 3 + 3)+'rarity'] == '화려한' ? "is-active" : ""} onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'rarity','화려한')}>화려한</li>
                                </ul>
                            </div>
                        </div>
                        <div class="estcont">
                            <div class="cus_chkbut cusb12 setal5">
                            
                                <ul>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 3)+'type'] == '테라코타' ? "is-active" :    "")+  " teracota"  }  
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'type',"테라코타")}>테라코타</li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 3)+'type'] == '서클 메이지' ? "is-active" : "")+  " scm"   }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'type',"서클 메이지")}>서클 메이지</li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 3)+'type'] == '수호자들' ? "is-active" :    "")+  " shjd"  }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'type',"수호자들")}>수호자들</li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 3)+'type'] == '고대 도서관' ? "is-active" : "")+  " dose"  }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'type',"고대 도서관")}>고대 도서관</li>
                                    <li className={(characterData['rune'+((props.id-1) * 3 + 3)+'type'] == '세컨드 팩트' ? "is-active" : "")+  " sefect"    }
                                    onClick={()=>change('rune'+((props.id-1) * 3 + 3)+'type',"세컨드 팩트")}>세컨드 팩트</li>
                                </ul>
                                <ul class="setal_comment">
                                    <li>데미지 증가</li>
                                    <li>데미지감소<br/>쿨타임감소</li>
                                    <li>쿨타임감소</li>
                                    <li>기능성증가</li>
                                    <li>데미지증가<br/>쿨타임증가</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

    function talismanToSkillName(job,talisName)
    {
        if(talisName == null)
        {
            return;
        }
        let skillName = '';
        if(talisName.indexOf('[') != -1)
        {
            skillName = talisName.split('[')[1].split(']')[0];
        }
        else
        {
            let stop = false;
            for(let loop = 0 ; loop < talismanList[job].length ; loop++)
            {
                if(talismanList[job][loop] == talisName)
                {
                    stop = true;
                }

                if(stop)
                if(talismanList[job][loop].indexOf('[') != -1)
                {
                    skillName = talismanList[job][loop].split('[')[1].split(']')[0];
                    break;
                }
            }
        }

        return skillName;
    }
    
    function talismanColor(talisType)
    {
        var color = "";
        switch(talisType)
        {
            case "테라코타" : color = 'purple'; break;
            case "서클 메이지" : color = 'green'; break;
            case "수호자들" : color = 'dodgerblue'; break;
            case "고대 도서관" : color = 'yellow'; break;
            case "세컨드 팩트" : color = 'red'; break;
        }
        return color;
    }
    function submitSetting()
    {
        init = false;
        
        for(let weapon of weaponList)
        {
            if(characterData['equipName0'].replace("스태프","스탭").replace(/ /gi,'').indexOf(weapon.replace(/ /gi,'')) != -1)
            {
                
                characterData['weaponType'] = weapon.replace("스태프","스탭");

            }
        }


        let temp = {...characterData};

        for(let talisLoop = 0 ; talisLoop < 3 ; talisLoop++)
        {
            let talisName = "";
            //console.log(selectTalisType[talisLoop] );
            
            if(selectTalisType[talisLoop] == 0)
            {
                talisName = "온전한 탈리스만["+selectTalisSkill[talisLoop]+']';
            }
            else if(selectTalisType[talisLoop] == 1)
            {
                talisName = "성흔의 탈리스만["+selectTalisSkill[talisLoop]+']';
            }
            else if(selectTalisType[talisLoop] == 2)
            {
                let stop = false;
                for(let loop = talismanList[characterData['job']].length -1 ; loop > 0 ; loop--)
                {
                    try
                    {
                        if(talismanList[characterData['job']][loop].split("[")[1].replace("]",'') == selectTalisSkill[talisLoop])
                        {
                            stop = true;
                        }
                    }
                    catch(e)
                    {

                    }
                    
                    if(stop && talismanList[characterData['job']][loop].indexOf('카펠라의 성흔') == -1 
                    && talismanList[characterData['job']][loop].indexOf('[') == -1)
                    {
                        talisName = talismanList[characterData['job']][loop];
                        break;
                    }
                }

                
                if(talisName == "" && stop)
                {
                    for(let loop = 0 ; loop < talismanList[characterData['job']].length -1  ; loop++)
                    {
                        if(stop && talismanList[characterData['job']][loop].indexOf('카펠라의 성흔') == -1
                        && talismanList[characterData['job']][loop].indexOf('[') == -1)
                        {
                            //console.log(talismanList[characterData['job']][loop] );
                            talisName = talismanList[characterData['job']][loop];
                            break;
                        }
                    }
                }
            }
            else if(selectTalisType[talisLoop] == 3)
            {
                let stop = false;
                for(let loop = talismanList[characterData['job']].length -1 ; loop > 0 ; loop--)
                {
                    //console.log(talismanList[characterData['job']][loop].indexOf('카펠라의 성흔') );
                    try
                    {
                        if(talismanList[characterData['job']][loop].split("[")[1].replace("]",'') == selectTalisSkill[talisLoop] )
                        {
                            stop = true;
                        }
                    }
                    catch(e)
                    {

                    }
                    

                    if(stop && talismanList[characterData['job']][loop].indexOf('카펠라의 성흔') != -1)
                    {
                        //console.log(talismanList[characterData['job']][loop] );
                        talisName = talismanList[characterData['job']][loop];
                        break;
                    }
                }

                if(talisName == "" && stop)
                {
                    for(let loop = 0 ; loop < talismanList[characterData['job']].length -1  ; loop++)
                    {
                        if(stop && talismanList[characterData['job']][loop].indexOf('카펠라의 성흔') != -1)
                        {
                            //console.log(talismanList[characterData['job']][loop] );
                            talisName = talismanList[characterData['job']][loop];
                            break;
                        }
                    }
                }
            }


            temp['talis'+talisLoop] = talisName;
            //console.log("탈리 : " + talisName);
        
        }

        //장비특성
        temp['specIndex'] = selectSpecType;

        navigate('../character?server='+characterObj.server+'&key='+characterObj.id,{state:{edit:temp}});
        setNavigateValue("character");

    }

    function GemSelect(props)
    {
        return(
            <div class="set_hwijjam">
                <div class="sethjam_tit">
                    <span>{props.id}</span>
                </div>
                <div class="sethjam_img">
                    <div className={"imgt " + (characterData["gem"+props.id] == "커먼" ? "is-active" : "")}  onClick={()=>change("gem"+props.id,"커먼")}>
                        <img src={"gem"+props.id+"0.png"}/>

                    </div>
                </div>
                <div class="sethjam_img">
                    <div className={"imgt " + (characterData["gem"+props.id] == "언커먼" ? "is-active" : "")}  onClick={()=>change("gem"+props.id,"언커먼")} >
                        <img src={"gem"+props.id+"1.png"}/>

                    </div>
                </div>
                <div class="sethjam_img">
                    <div className={"imgt " + (characterData["gem"+props.id] == "레어" ? "is-active" : "")} onClick={()=>change("gem"+props.id,"레어")} >
                        <img src={"gem"+props.id+"2.png"}/>

                    </div>
                </div>
                <div class="sethjam_img">
                    <div className={"imgt " + (characterData["gem"+props.id] == "유니크" ? "is-active" : "")}  onClick={()=>change("gem"+props.id,"유니크")}>
                        <img src={"gem"+props.id+"3.png"}/>

                    </div>
                </div>
                <div class="sethjam_img">
                    <div className={"imgt " + (characterData["gem"+props.id] == "레전더리" ? "is-active" : "")}  onClick={()=>change("gem"+props.id,"레전더리")}>
                        <img src={"gem"+props.id+"4.png"}/>

                    </div>
                </div>
            </div>
        )
    }
    function WeaponList(props)
    {
        if(weaponList[props.id] != null)
        {
            let weaponName = weaponList[props.id].replace("스탭","스태프");
            weaponName = weaponName.replace("코어블레이드","코어 블레이드");
            let gender = "";

            //console.log(characterObj);
            //console.log("아아아아");
            if(characterObj.baseJob.indexOf("격투가") != -1 || characterObj.baseJob.indexOf("거너") != -1  || characterDataTemp.jinJob.indexOf("크루세이더")  != -1 )
            if(characterObj.baseJob.indexOf("(남)") != -1)
            {
                if(!characterObj.baseJob.indexOf("어썰트") != -1)
                {
                    gender = "(남)";
                }
                
            }
            else if(characterObj.baseJob.indexOf("(여)") != -1)
            {
                gender = "(여)";
            }
            
            return(

                  <>  
                    <ul class="ems-choice-wapon">
                        
{/*                         
                        <li className={(characterData['equipName0'] == "근원을 삼킨 "+weaponName ? "on" : "" )}>
                            <div className="imgt" onClick={()=>{change("equipName0","근원을 삼킨 "+weaponName)}}>
                                <img name={"근원을 삼킨 "+weaponName} src={"img/item/" + lv105ItemId["근원을 삼킨 "+ weaponName] } loading="lazy"></img>
                            </div>
                        </li>
                        <li className={(characterData['equipName0'] == "얼어붙은 저항의 "+weaponName ) ? "on":""}>
                            <div className="imgt" onClick={()=>{change("equipName0","얼어붙은 저항의 "+weaponName)}}>
                                <img name={"얼어붙은 저항의 "+weaponName} src={"img/item/" + lv105ItemId["얼어붙은 저항의 "+ weaponName] } loading="lazy"></img>
                            </div>
                        </li>
                        <li className={(characterData['equipName0'] == "광폭화된 전의의 "+weaponName ) ? "on":""}>
                            <div className={"imgt"} onClick={()=>{change("equipName0","광폭화된 전의의 "+weaponName)}}>
                                <img name={"광폭화된 전의의 "+weaponName} src={"img/item/" + lv105ItemId["광폭화된 전의의 "+ weaponName] } loading="lazy"></img>
                            </div>
                        </li>
                        <li className={(characterData['equipName0'] == "사멸하는 신뢰의 "+weaponName ) ? "on":""}>
                            <div class="imgt" onClick={()=>{change("equipName0","사멸하는 신뢰의 "+weaponName)}}>
                                <img name={"사멸하는 신뢰의 "+weaponName} src={"img/item/" + lv105ItemId["사멸하는 신뢰의 "+ weaponName] } loading="lazy"></img>
                            </div>
                        </li>
                        <li className={(characterData['equipName0'] == "내딛은 자의 "+weaponName) ? "on":"" }>
                            <div class="imgt"  onClick={()=>{change("equipName0","내딛은 자의 "+weaponName)}}>
                                <img name={"내딛은 자의 "+weaponName} src={"img/item/" + lv105ItemId["내딛은 자의 "+weaponName] } loading="lazy"></img>
                            </div>
                        </li>
                         */}

                        <Lv105Weapon name={"근원을 삼킨 "+weaponName }/>
                        <Lv105Weapon name={"얼어붙은 저항의 "+weaponName }/>
                        <Lv105Weapon name={"내딛은 자의 "+weaponName }/>
                        <Lv105Weapon name={"광폭화된 전의의 "+weaponName }/>
                        <Lv105Weapon name={"사멸하는 신뢰의 "+weaponName }/>
                        <Lv105Weapon name={"결전의 "+weaponName+" - "+characterDataTemp['jinJob'].replace('眞 ','')+gender }/>
                            {/* <li className={(characterData['equipName0'] == "결전의 "+weaponName+" - "+characterDataTemp['jinJob'].replace('眞 ','')+gender ? "on" : "") }>
                                <div className="imgt"  onClick={()=>{change("equipName0","결전의 "+weaponName+" - "+characterDataTemp['jinJob'].replace('眞 ','')+gender)}}>
                                    <img name={"결전의 "+weaponName+" - "+characterDataTemp['jinJob'].replace('眞 ','')+gender} 
                                    src={"img/item/" + lv105ItemId[ "결전의 "+weaponName+" - "+characterDataTemp['jinJob'].replace('眞 ','')+gender] } loading="lazy"></img>
                                </div>
        
                            </li> */}
                    </ul>
                    {/* <ul>
                        <li className={(characterData['equipName0'] == "불가침의 영역 - "+ weaponName ? "on" : "" )}>
                            <div className={"imgt"}  onClick={()=>{change("equipName0","불가침의 영역 - "+ weaponName)}}>
                                <img name={"불가침의 영역 - "+ weaponName} src={"img/item/" + lv105ItemId["불가침의 영역 - "+ weaponName] } loading="lazy"></img>
                            </div>
                        </li>
                    </ul> */}
                    <ul class="ems-choice-wapon">
                            
                        <Weapon name={"火 : 불타는 고난의 "+weaponName}/>
                        <Weapon name={"水 : 오염된 눈의 "+weaponName}/>
                        <Weapon name={"木 : 그늘진 새벽의 "+weaponName}/>
                        <Weapon name={"金 : 각인된 상처의 "+weaponName}/>
                        <Weapon name={"土 : 따뜻한 봄날의 "+weaponName}/>
                        <Weapon name={"부조화 : 무너진 경계의 "+weaponName}/>
                        
                    </ul>
                    <div className="non-aggression-side">
                        <ul class="only-non-agression">
                            <li className={(characterData['equipName0'] == "불가침의 영역 - "+ weaponName ? "on" : "" )}>
                                <div className={"imgt"}  onClick={()=>{change("equipName0","불가침의 영역 - "+ weaponName)}}>
                                    <img name={"불가침의 영역 - "+ weaponName} src={"img/item/" + lv105ItemId["불가침의 영역 - "+ weaponName] } loading="lazy"></img>
                                </div>
                            </li>
                        </ul>
                        <ul>
                            
                            <Weapon name={"火 : 넘어선 기억의 "+weaponName}/>
                            <Weapon name={"水 : 넘어선 기억의 "+weaponName}/>
                            <Weapon name={"木 : 넘어선 기억의 "+weaponName}/>
                            <Weapon name={"金 : 넘어선 기억의 "+weaponName}/>
                            <Weapon name={"土 : 넘어선 기억의 "+weaponName}/>
                            
                            
                        </ul>
                    </div>
                </>
            )
        }
    }

    function optionSearch(option,search)
    {
        let optionString = option.replace(/ /gi,'').replace(/\+/gi,'').replace(/,/gi,'').toLowerCase();
        let searchString = search.replace(/ /gi,'').replace(/\+/gi,'').replace(/,/gi,'').replace(/ /gi, '').replace(/모속상/gi, '모든속성강화').replace(/속강/gi, '속성강화').replace(/스증/gi, '스킬공격력')
        .replace(/속저/gi, '속성저항').replace(/쿨회/gi, '쿨타임회복속도')
        .toLowerCase();
        
        return optionString.indexOf(searchString);
    }

    function EnchantPreview(props)
    {

        let img = '';
        let value = 0;
        if(characterData['equipEnchantEtc'+props.id] != 0)
        {
            value = characterData['equipEnchantEtc'+props.id]; 
            if(props.id == 3 || props.id == 6 || props.id == 5 || props.id == 9)
            {
                img = 'img/icon/281.png';
            }
            else
            {
                img = 'img/icon/108.png';
            }
        }
        else if(characterData['equipEnchantEle'+props.id] != 0)
        {
            value = characterData['equipEnchantEle'+props.id];
            img = 'img/icon/108.png';
        }
        else if(characterData['equipEnchantAttack'+props.id] != 0)
        {
            value = characterData['equipEnchantAttack'+props.id];
            img = 'img/icon/244.png';
        }
        else if(characterData['equipEnchantStat'+props.id] != 0)
        {
            value = characterData['equipEnchantStat'+props.id];
            img = 'img/icon/248.png';
        }
        

        return(
            <div class="csul">
                <div class="csil ctit"><img src={img}/></div>
                <div class="csil cusa">+{value}</div>
            </div>
        )
    }

    function Trait(props)
    {
        if(traitList[selectSpecType-1].includes(Number(selectSpecType+props.slot)))
        {
            
            return(
                <div class="cfa-hover-side">                              
                    <div class="imgt">
                        <img src={'/reSpec/'+selectSpecType+props.slot+(characterData["spec"+selectSpecType+props.slot] >= 1 ? "active" : "")+'.png'}></img>
                    </div>
                    {/* <div class="euip-fea-addbox">
                        <div class="ef-ab-cont">
                            <span>{trait[selectSpecType+props.slot] ? trait[selectSpecType+props.slot] : "" }</span>
                        </div>
                    </div> */}

                    <div class="euip-fea-masterpoint">
                        <div class="efmp-con">
                            <ul>
                                <li class="efmp-but efmp-b-minos" onClick={()=>change("spec"+selectSpecType+props.slot, 
                                characterData["spec"+selectSpecType+props.slot] - 1 <= 0 ? 0 : characterData["spec"+selectSpecType+props.slot] - 1 )}>
                                    <span>-</span>
                                </li>
        
                                <li class={characterData["spec"+selectSpecType+props.slot] >= (traitMaster[selectSpecType-1])[Number(props.slot[0])-1][Number(props.slot[1])-1]
                                  ? "efmp-stat-m" : "efmp-stat"  }>
                                    <span>{characterData["spec"+selectSpecType+props.slot]}</span>
                                </li>
                    
                                <li class="efmp-but efmp-b-plus" onClick={()=>change("spec"+selectSpecType+props.slot,
                                (traitMaster[selectSpecType-1])[Number(props.slot[0])-1][Number(props.slot[1])-1] <= characterData["spec"+selectSpecType+props.slot] + 1 ?
                                (traitMaster[selectSpecType-1])[Number(props.slot[0])-1][Number(props.slot[1])-1] : characterData["spec"+selectSpecType+props.slot] + 1 )}>
                                    <span>+</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            )
        }
        else
        {
            return(<></>)
        }

       
    }

    function FusionStone(props)
    {
        return(

            <li className={characterData['siroco'+props.slot] == props.name ? " on" : ""} onClick={()=>change('siroco'+props.slot,props.name)}>
                <div class="imgt raidItem" slot={"10"+props.slot}>
                    <img name={props.name} src={"https://img-api.neople.co.kr/df/items/"+props.image}/>
                </div>
                <div class="cus-addbox">
                    <div class="abtit"><img src={"https://img-api.neople.co.kr/df/items/"+props.image}></img><span class="abt_name">{props.name}</span></div>

                    <div class="abop">
                        <div class="option">
                            {
                                lv105OptionMap[props.name] != null ? <>{lv105OptionMap[props.name].replace(/<br>/gi, "\n")}</> :<></>
                            }
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    function FusionEquip(props)
    {
        return(
        <li className={characterData['siroco'+props.slot] == props.setName ? " on" : ""} onClick={()=>change('siroco'+props.slot,props.setName)}>
            <div class="imgt raidItem" slot={"10"+props.slot}>
                <img name={props.name} src={"https://img-api.neople.co.kr/df/items/"+props.image}/>
            </div>

           <div class="cus-addbox">
                <div class="abtit"><img src={"https://img-api.neople.co.kr/df/items/"+props.image}></img><span class="abt_name">{props.name}</span></div>

                <div class="abop">
                    <div class="option"
                    ></div>
                </div>
            </div>
        </li>
        )
    } 
    function ItemList(props)
    {
        
        return(
            <li className={characterData['equipName'+props.slot] == props.name ? 'on' : '' }>
                <div class="imgt">
                    <img src={'https://dundam.xyz/img/item/'+lv105ItemId[props.name] }  onClick={() => change('equipName'+props.slot,props.name)} />
                </div>
                {
                    lv105OptionMap[props.name] != null ? 
                    <div class="cus-detail-button" onClick={() => change('equipOption',props.name)}>
                        <span  >
                            <div class="imgt">
                                <img src='img/burger_line2.svg'></img>
                            </div>
                        </span>
                    
                    </div>
                    : <></>
                }
                

                {
                    characterData['equipOption'] != null ? characterData['equipOption'] == props.name ? 
                    <div class="cus-addbox">
                        
                            <div class="abtit">
                                <div class="imgt">
                                    <img src={'https://dundam.xyz/img/item/'+lv105ItemId[props.name] }  onClick={() => change('equipName'+props.slot,props.name)} />
                                </div>
                                <span class="abt_name">{props.name}</span>
                            
                            </div>
                            <div class="abop">
                                <ItemOption name={props.name} number={0}></ItemOption>
                            </div>
                            <div class="ab-close-button">
                                <div class="aclob-con" onClick={() => change('equipOption',"")}>
                                    <span>닫기</span>
                                </div>
                            </div>
                    </div> 
                    : "" : ""
                }
            </li>
        )
    }

    function Weapon(props)
    {
        /*
        
            <li className={(characterData['equipName0'] == props.name ? "on" : "" )}>
                <div className="imgt" onClick={()=>{change("equipName0", props.name)}}>
                    <img  name={ props.name}  src={"img/item/" + lv105ItemId[ props.name] } loading="lazy"></img>
                </div>
            </li>
        */
        let number = weaponImage[lv105ItemId[props.name]];

        let x = 0;
        let y = 0;
        if(number != null)
        {
            x = (number-1) % 9;
            y = Math.trunc((number-1)/ 9) ;
        }
        
        return(
            
            <li className={(characterData['equipName0'] == props.name ? "on" : "" )}>
                <div className="imgt" onClick={()=>{change("equipName0", props.name)}}>
                    <div  name={ props.name} className={"weaponSprite"} loading="lazy" style={{"width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"} }></div>
                </div>
            </li>
        )

    }

    function Lv105Weapon(props)
    {
        /*
        
            <li className={(characterData['equipName0'] == props.name ? "on" : "" )}>
                <div className="imgt" onClick={()=>{change("equipName0", props.name)}}>
                    <img  name={ props.name}  src={"img/item/" + lv105ItemId[ props.name] } loading="lazy"></img>
                </div>
            </li>
        */
        let temp = props.name;
        //console.log(temp);

        if(temp.includes("결전의 "))
        {
            temp = temp.split(' - ')[0];
        }
        //console.log(temp);

        let number = lv105WeaponImage[temp];

        //console.log(props.name)
        //console.log(number);

        let x = 0;
        let y = 0;
        if(number != null)
        {
            x = (number-1) % 9;
            y = Math.trunc((number-1)/ 9) ;
        }
        //console.log(x);
        //console.log(y);
        
        return(
            
            <li className={(characterData['equipName0'] == props.name ? "on" : "" )}>
                <div className="imgt" onClick={()=>{change("equipName0", props.name)}}>
                    <div  name={ props.name} className={"weaponSprite"} loading="lazy" 
                    style={{"background-image":"url('https://dundam.xyz/img/sprite/lv105WeaponImage.png')" , "width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"} }></div>
                </div>
            </li>
        )

    }

    function Equip(props)
    {

        let temp = props.name;
        let number = lv105EquipImage[temp];

        //console.log(props.name)
        //console.log(number);

        let x = 0;
        let y = 0;
        if(number != null)
        {
            x = (number-1) % 9;
            y = Math.trunc((number-1)/ 9) ;
        }
        //console.log(x);
        //console.log(y);

        return(
            <li className={characterData['equipName'+props.slot] == props.name ? 'on' : '' }>
                <div class="imgt"  onClick={() => change('equipName'+props.slot,props.name)}>
                    <div  name={ props.name} className={"weaponSprite"} loading="lazy" 
                    style={{"zoom":"1.35714","background-image":"url('https://dundam.xyz/img/sprite/lv105EquipImage.png')"
                     , "width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"} }></div>
                </div>
                <div class="cus-addbox">
                        <div class="abtit">
                            <div  name={ props.name} className={"weaponSprite"} loading="lazy" 
                            style={{"background-image":"url('https://dundam.xyz/img/sprite/lv105EquipImage.png')"
                            , "width":"28px","height":"28px","background-position":"-"+x * 28+"px -"+y * 28+"px"} }></div>
                            <span class="abt_name">{props.name}</span>
                        
                        </div>
                        <div class="abop">
                            <ItemOption name={props.name} number={0}></ItemOption>
                        </div>
                </div> 
            </li>
        )
    }

    function LegendStone(props)
    {
        let name = "";

        switch(Number(props.slot))
        {
            case 10 : name ='보조장비'; break;
            case 11 : name ='마법석'; break;
            case 12 : name ='귀걸이'; break;
        }
        
        return(
            <div class="eset_legendstone_optionadd">
                <div class="et-lston-h-name">
                    <span> <b>영원의 조각 - {name} </b>     옵션 설정</span>
                </div>
                <div class="esetting_tr cusp2 o105je">

                
                    {[0,0,0].map( (a,loop) =>
                    {
                        if(selectFusionType != 1)
                        {
                            return(<></>);
                        }
                        return(
                            <>
                            <div class="estcont cussa legendStone" id={'legendStone'+props.slot+"_"+loop}>
                                
                                <div class="cus_105op" >
                                    <div class="option">
                                        {characterData['legendStone'+props.slot+"_"+(loop+1)] != null ? legendStone[characterData['legendStone'+props.slot+"_"+(loop+1)] - 1].replace(/<br>/gi, "\n") : ""}

                                    </div>

                                    <div class="cus_opsetbut" onClick={() => { legendStoneChange(props.slot, loop, 1)}} >
                                        <div class="imgt">
                                            <img src="img/set1.svg" />
                                        </div>

                                    </div>

                                </div>
                                {
                                    legendStoneArray[props.slot-10][loop] == 1 ? 
                                    <>
                                    <div class={"cus_opset" + (legendStoneArray[props.slot-10][loop] == 1 ? " on" : "")}>
                                        <div class="cos_c">
                                            
                                            <div class="coscc">
                                                <ul>
                                                    {
                                                        legendStone.map((a, i) => {

                                                            return (

                                                                <li key={'legendStone'+ props.slot + loop} className={legendStoneSelectArray[props.slot-10][loop] == i + 1 
                                                                    ? "is-active" : ""} onClick={() => legendStoneSelectChange(props.slot,loop, i + 1)}>{a.replace(/<br>/gi, '\n')}
                                                                </li>
                                                            )

                                                        }) 
                                                    }
                                                </ul>
                                            </div>
                                            <div class="cos_but">
                                                <div class="csbt" slot="1" onClick={() => { if(legendStoneSelectArray[props.slot-10][loop] != 0) { change('legendStone'+props.slot+"_"+(loop+1), legendStoneSelectArray[props.slot-10][loop]);  legendStoneChange(props.slot, loop, 0); } }} >
                                                    <span class="cbut-tag" >적용</span>
                                                </div>
                                                <div class="csbt csbtc" onClick={() => { legendStoneChange(props.slot, loop, 0) }}>
                                                    <span class="cbut-tag">
                                                        <div class="imgt">
                                                            <img src="img/arrow_go_back2.svg" />
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    </>
                                    :
                                    <></>

                                }
                                
                            </div>
                            </>
                        )
                    })}

                </div>
                

            </div>

        )
    }



export default EqupEdit